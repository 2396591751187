import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'

enum AddressKind {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING',
}

enum TaxRegistrationKind {
  AE_TRN = 'AE_TRN',
  AU_ABN = 'AU_ABN',
  AU_ARN = 'AU_ARN',
  BG_UIC = 'BG_UIC',
  BR_CNPJ = 'BR_CNPJ',
  BR_CPF = 'BR_CPF',
  CA_BN = 'CA_BN',
  CA_GST_HST = 'CA_GST_HST',
  CA_PST_BC = 'CA_PST_BC',
  CA_PST_MB = 'CA_PST_MB',
  CA_PST_SK = 'CA_PST_SK',
  CA_QST = 'CA_QST',
  CH_VAT = 'CH_VAT',
  CL_TIN = 'CL_TIN',
  EG_TIN = 'EG_TIN',
  ES_CIF = 'ES_CIF',
  EU_OSS_VAT = 'EU_OSS_VAT',
  EU_VAT = 'EU_VAT',
  GB_VAT = 'GB_VAT',
  GE_VAT = 'GE_VAT',
  HK_BR = 'HK_BR',
  HU_TIN = 'HU_TIN',
  ID_NPWP = 'ID_NPWP',
  IL_VAT = 'IL_VAT',
  IN_GST = 'IN_GST',
  IS_VAT = 'IS_VAT',
  JP_CN = 'JP_CN',
  JP_RN = 'JP_RN',
  JP_TRN = 'JP_TRN',
  KE_PIN = 'KE_PIN',
  KR_BRN = 'KR_BRN',
  LI_UID = 'LI_UID',
  MX_RFC = 'MX_RFC',
  MY_FRP = 'MY_FRP',
  MY_ITN = 'MY_ITN',
  MY_SST = 'MY_SST',
  NO_VAT = 'NO_VAT',
  NZ_GST = 'NZ_GST',
  PH_TIN = 'PH_TIN',
  RU_INN = 'RU_INN',
  RU_KPP = 'RU_KPP',
  SA_VAT = 'SA_VAT',
  SG_GST = 'SG_GST',
  SG_UEN = 'SG_UEN',
  SI_TIN = 'SI_TIN',
  TH_VAT = 'TH_VAT',
  TR_TIN = 'TR_TIN',
  TW_VAT = 'TW_VAT',
  UA_VAT = 'UA_VAT',
  US_EIN = 'US_EIN',
  ZA_VAT = 'ZA_VAT'
}

const TAX_REGISTRATION_KIND_OPTIONS = [
  { label: 'Australian ABN', value: TaxRegistrationKind.AU_ABN },
  { label: 'Australian ARN', value: TaxRegistrationKind.AU_ARN },
  { label: 'Brazilian CNPJ', value: TaxRegistrationKind.BR_CNPJ },
  { label: 'Brazilian CPF', value: TaxRegistrationKind.BR_CPF },
  { label: 'British VAT', value: TaxRegistrationKind.GB_VAT },
  { label: 'Bulgarian UIC', value: TaxRegistrationKind.BG_UIC },
  { label: 'Canadian BN', value: TaxRegistrationKind.CA_BN },
  { label: 'Canadian GST/HST', value: TaxRegistrationKind.CA_GST_HST },
  { label: 'Canadian PST BC', value: TaxRegistrationKind.CA_PST_BC },
  { label: 'Canadian PST MB', value: TaxRegistrationKind.CA_PST_MB },
  { label: 'Canadian PST SK', value: TaxRegistrationKind.CA_PST_SK },
  { label: 'Canadian QST', value: TaxRegistrationKind.CA_QST },
  { label: 'Chilean TIN', value: TaxRegistrationKind.CL_TIN },
  { label: 'Egyptian TIN', value: TaxRegistrationKind.EG_TIN },
  { label: 'European OSS VAT', value: TaxRegistrationKind.EU_OSS_VAT },
  { label: 'European VAT', value: TaxRegistrationKind.EU_VAT },
  { label: 'Georgian VAT', value: TaxRegistrationKind.GE_VAT },
  { label: 'Hong Kong BR', value: TaxRegistrationKind.HK_BR },
  { label: 'Hungarian TIN', value: TaxRegistrationKind.HU_TIN },
  { label: 'Icelandic VAT', value: TaxRegistrationKind.IS_VAT },
  { label: 'Indian GST', value: TaxRegistrationKind.IN_GST },
  { label: 'Indonesian NPWP', value: TaxRegistrationKind.ID_NPWP },
  { label: 'Israeli VAT', value: TaxRegistrationKind.IL_VAT },
  { label: 'Japanese CN', value: TaxRegistrationKind.JP_CN },
  { label: 'Japanese RN', value: TaxRegistrationKind.JP_RN },
  { label: 'Japanese TRN', value: TaxRegistrationKind.JP_TRN },
  { label: 'Kenyan PIN', value: TaxRegistrationKind.KE_PIN },
  { label: 'Korean BRN', value: TaxRegistrationKind.KR_BRN },
  { label: 'Liechtenstein UID', value: TaxRegistrationKind.LI_UID },
  { label: 'Malaysian FRP', value: TaxRegistrationKind.MY_FRP },
  { label: 'Malaysian ITN', value: TaxRegistrationKind.MY_ITN },
  { label: 'Malaysian SST', value: TaxRegistrationKind.MY_SST },
  { label: 'Mexican RFC', value: TaxRegistrationKind.MX_RFC },
  { label: 'New Zealand GST', value: TaxRegistrationKind.NZ_GST },
  { label: 'Norwegian VAT', value: TaxRegistrationKind.NO_VAT },
  { label: 'Philippine TIN', value: TaxRegistrationKind.PH_TIN },
  { label: 'Russian INN', value: TaxRegistrationKind.RU_INN },
  { label: 'Russian KPP', value: TaxRegistrationKind.RU_KPP },
  { label: 'Saudi Arabian VAT', value: TaxRegistrationKind.SA_VAT },
  { label: 'Singapore GST', value: TaxRegistrationKind.SG_GST },
  { label: 'Singapore UEN', value: TaxRegistrationKind.SG_UEN },
  { label: 'Slovenian TIN', value: TaxRegistrationKind.SI_TIN },
  { label: 'South African VAT', value: TaxRegistrationKind.ZA_VAT },
  { label: 'Spanish CIF', value: TaxRegistrationKind.ES_CIF },
  { label: 'Swiss VAT', value: TaxRegistrationKind.CH_VAT },
  { label: 'Taiwanese VAT', value: TaxRegistrationKind.TW_VAT },
  { label: 'Thai VAT', value: TaxRegistrationKind.TH_VAT },
  { label: 'Turkish TIN', value: TaxRegistrationKind.TR_TIN },
  { label: 'U.S. EIN', value: TaxRegistrationKind.US_EIN },
  { label: 'Ukrainian VAT', value: TaxRegistrationKind.UA_VAT },
  { label: 'United Arab Emirates TRN', value: TaxRegistrationKind.AE_TRN }
]

const TAX_REGISTRATION_VALUE_PLACEHOLDER_MAP = {
  [TaxRegistrationKind.AE_TRN]: '123456789012345',
  [TaxRegistrationKind.AU_ABN]: '12 345 678 901',
  [TaxRegistrationKind.AU_ARN]: '123456789012',
  [TaxRegistrationKind.BG_UIC]: 'BG123456789',
  [TaxRegistrationKind.BR_CNPJ]: '12.345.678/9012-34',
  [TaxRegistrationKind.BR_CPF]: '123.456.789-01',
  [TaxRegistrationKind.CA_BN]: '123456789',
  [TaxRegistrationKind.CA_GST_HST]: '123456789',
  [TaxRegistrationKind.CA_PST_BC]: '123456789',
  [TaxRegistrationKind.CA_PST_MB]: '123456789',
  [TaxRegistrationKind.CA_PST_SK]: '123456789',
  [TaxRegistrationKind.CA_QST]: '123456789',
  [TaxRegistrationKind.CH_VAT]: 'CHE-123.456.789 MWST',
  [TaxRegistrationKind.CL_TIN]: '12345678-9',
  [TaxRegistrationKind.EG_TIN]: '123456789012',
  [TaxRegistrationKind.ES_CIF]: 'A12345678',
  [TaxRegistrationKind.EU_OSS_VAT]: 'ATU12345678',
  [TaxRegistrationKind.EU_VAT]: 'ATU12345678',
  [TaxRegistrationKind.GB_VAT]: '123 4567 89',
  [TaxRegistrationKind.GE_VAT]: '123456789',
  [TaxRegistrationKind.HK_BR]: '123456(7)',
  [TaxRegistrationKind.HU_TIN]: '12345678-9-10',
  [TaxRegistrationKind.ID_NPWP]: '01.234.567.8-912.345',
  [TaxRegistrationKind.IL_VAT]: '123456789',
  [TaxRegistrationKind.IN_GST]: '12ABCDE1234F1Z5',
  [TaxRegistrationKind.IS_VAT]: '123456-7890',
  [TaxRegistrationKind.JP_CN]: '1234567',
  [TaxRegistrationKind.JP_RN]: '1234567',
  [TaxRegistrationKind.JP_TRN]: '1234567890',
  [TaxRegistrationKind.KE_PIN]: '12345678A',
  [TaxRegistrationKind.KR_BRN]: '123-45-67890',
  [TaxRegistrationKind.LI_UID]: 'CHE-123.456.789 MWST',
  [TaxRegistrationKind.MX_RFC]: 'XAXX010101000',
  [TaxRegistrationKind.MY_FRP]: '123456789012',
  [TaxRegistrationKind.MY_ITN]: '123456789012',
  [TaxRegistrationKind.MY_SST]: '123456789012',
  [TaxRegistrationKind.NO_VAT]: '123456789',
  [TaxRegistrationKind.NZ_GST]: '123456789',
  [TaxRegistrationKind.PH_TIN]: '123-456-789-012',
  [TaxRegistrationKind.RU_INN]: '123456789012',
  [TaxRegistrationKind.RU_KPP]: '123456789',
  [TaxRegistrationKind.SA_VAT]: '123456789012',
  [TaxRegistrationKind.SG_GST]: '123456789',
  [TaxRegistrationKind.SG_UEN]: '123456789A',
  [TaxRegistrationKind.SI_TIN]: '12345678',
  [TaxRegistrationKind.TH_VAT]: '1234567890123',
  [TaxRegistrationKind.TR_TIN]: '1234567890',
  [TaxRegistrationKind.TW_VAT]: '12345678',
  [TaxRegistrationKind.UA_VAT]: '12345678',
  [TaxRegistrationKind.US_EIN]: '12-3456789',
  [TaxRegistrationKind.ZA_VAT]: '1234567890'
}

const ADDRESS_KIND_OPTIONS = [
  { label: 'Billing', value: AddressKind.BILLING },
  { label: 'Shipping', value: AddressKind.SHIPPING }
]

class BillingAccount extends BaseModel {
  static schema = object({
    name: string().required(),
    address: object({
      line1: string().required(),
      line2: string().required(),
      city: string().required(),
      state: string().required(),
      zip: string().required(),
      countryCode: string().required(),
      kind: string().required()
    }),
    taxRegistration: object({
      value: string(),
      kind: string()
    })
  })
}

export {
  AddressKind,
  TaxRegistrationKind,
  ADDRESS_KIND_OPTIONS,
  TAX_REGISTRATION_KIND_OPTIONS,
  TAX_REGISTRATION_VALUE_PLACEHOLDER_MAP
}
export default BillingAccount
