import { useContext } from 'react'
import isEmpty from 'lodash/isEmpty'

import { DataManagerContext, NestedDataManagerContext } from 'components/providers/DataManagerProvider'

function useDataManagerContext() {
  const state = useContext(DataManagerContext)
  if (isEmpty(state)) {
    throw new Error('useDataManagerContext must be used within a DataManagerProvider')
  }
  return state
}

function useNestedDataManagerContext() {
  const state = useContext(NestedDataManagerContext)
  if (isEmpty(state)) {
    throw new Error('useNestedDataManagerContext must be used within a NestedDataManagerProvider')
  }
  return state
}

export { useDataManagerContext, useNestedDataManagerContext }
