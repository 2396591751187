import rgba from 'lib/rgba'
import theme from 'styles/theme'
import { easings } from 'styles/primitives/transitions'
import type { DropShadowSize, ShadowSize, Transition, TransitionProps } from 'styles/theme'

export function fullScreen() {
  return {
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0
  }
}

export function size(width: number | string, height = width) {
  return { width, height }
}

export function centered(width: number, height: number) {
  return {
    ...size(width, height),

    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(height / 2),
    marginLeft: -(width / 2)
  }
}

export function backgroundCenter() {
  return {
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat'
  }
}

export function backgroundCover() {
  return {
    ...backgroundCenter(),
    backgroundSize: 'cover'
  }
}

export function backgroundContain() {
  return {
    ...backgroundCenter(),
    backgroundSize: 'contain'
  }
}

export function imageIcon() {
  return {
    ...backgroundCenter(),

    display: 'inline-block',
    verticalAlign: 'middle'
  }
}

export function innerBorder(color: string, width = 1) {
  return {
    boxShadow: `inset 0 0 0 ${width}px ${color}`
  }
}

export const textTruncate = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
} as const // https://github.com/modulz/stitches/issues/53#issuecomment-668112151

// Transitions

export function makeTransition({
  delay = 0,
  duration = 0.3,
  easing = 'ease',
  property = 'all'
}: TransitionProps): string {
  return `${property} ${duration}s ${easings[easing]} ${delay}s`
}

export function transition(selectedTransition: Transition, property?: string, duration?: number) {
  return {
    transition: makeTransition({
      ...theme.transitions[selectedTransition],
      ...(property ? { property } : {}),
      ...(duration ? { duration } : {})
    } as TransitionProps)
  }
}

// Shadows

export type ShadowProps = {
  inset?: boolean,
  offsetX?: number,
  offsetY?: number,
  blurRadius?: number,
  spreadRadius?: number,
  rgbVar?: string,
  opacity?: number
}

export function makeShadow({
  inset = false,
  offsetX = 0,
  offsetY = 0,
  blurRadius = 0,
  spreadRadius = 0,
  rgbVar = 'currentColor',
  opacity = 1
}: ShadowProps): string {
  const baseShadow = `${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius ? `${spreadRadius}px` : ''} ${rgba(rgbVar, opacity)}`

  return inset ? `inset ${baseShadow}` : baseShadow
}

export function shadow(shadowSize: ShadowSize, rgbVar?: string, opacity: number = 1) {
  const baseShadow = theme.shadows[shadowSize]

  if (typeof baseShadow === 'string') return shadow

  return {
    boxShadow: makeShadow({ ...baseShadow, rgbVar, opacity })
  }
}

export function dropShadow(dropShadowSize: DropShadowSize, rgbVar: string, opacity: number = 1) {
  const baseShadow = theme.filters.dropShadows[dropShadowSize]

  if (typeof baseShadow === 'string') return shadow

  return {
    filter: `drop-shadow(${makeShadow({ ...baseShadow, rgbVar, opacity })})`
  }
}

export function hidden() {
  return {
    border: 0,
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1
  }
}
