import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import GlobalContext from 'components/contexts/GlobalContext'
import reportError from 'lib/reportError'
import { Asset, useAssetQuery, usePrepareAssetMutation } from 'generated/schema'
import { SetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'

type UseAssetUploadProps = {
  attribute?: string,
  resource?: string,
  targetEnvironment?: string,
  onUploadStart?: (assetName: string, meta: Record<string, any>, key?: string) => void,
  onUploadEnd?: (meta: Record<string, any>, key?: string) => void,
  onUploadSuccess?: (asset: Asset, meta: Record<string, any>, key?: string) => void,
  key?: string
}

function useAssetUpload({
  attribute, resource, targetEnvironment, onUploadStart, onUploadEnd, onUploadSuccess, key
}: UseAssetUploadProps) {
  const [ isUploading, setIsUploading ] = useState(false)
  const [ prepareAsset ] = usePrepareAssetMutation()
  const { refetch } = useAssetQuery({ fetchPolicy: 'network-only', skip: true })
  const { openFailureAlert } = useContext(GlobalContext)!
  const setMediaFieldLoading = useContext(SetMediaFieldStateContext)

  const isMountedRef = useRef(true)

  useEffect(() => () => {
    isMountedRef.current = false
  }, [])

  const upload = useCallback(
    async (asset: Blob, assetName: string, meta) => {
      setIsUploading(true)

      onUploadStart?.(assetName, meta, key)
      setMediaFieldLoading?.(
        (current) => current.concat([ meta.fieldName ])
      )

      try {
        const { data } = await prepareAsset({
          variables: {
            input: {
              name: assetName,
              size: asset.size,
              mimeType: asset.type,
              attribute,
              resource,
              targetEnvironment
            }
          }
        })

        const uploadUrl = data?.prepareAsset.data?.upload?.url
        const headers = data?.prepareAsset.data?.upload?.headers
        if (!uploadUrl) throw new Error()

        const response = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': asset.type,
            ...headers
          },
          body: asset
        })

        if (response.status !== 200) throw new Error(response.statusText)

        // check for status and refetch if not uploaded
        const fetchAsset = async () => {
          const result = await refetch({ id: data.prepareAsset.id })

          if (!result?.data.asset || result.data.asset.uploadStatus === 'PENDING') {
            return
          }

          if (result.data.asset.uploadStatus === 'UPLOADED') {
            onUploadSuccess?.(result.data.asset, meta, key)
          }

          clearInterval(intervalId)
          isMountedRef.current && setIsUploading(false)
          onUploadEnd?.(meta, key)
          setMediaFieldLoading?.(
            (current) => current.filter((name) => name !== meta.fieldName)
          )
        }

        const intervalId = setInterval(fetchAsset, 3000)
        await fetchAsset()
      } catch (e: any) {
        reportError(e)
        isMountedRef.current && setIsUploading(false)
        onUploadEnd?.(meta, key)
        setMediaFieldLoading?.(
          (current) => current.filter((name) => name !== meta.fieldName)
        )
        openFailureAlert(e.message || 'Failed to upload asset.')
      }
    }, [
      attribute,
      onUploadEnd,
      onUploadStart,
      onUploadSuccess,
      openFailureAlert,
      prepareAsset,
      refetch,
      resource,
      setMediaFieldLoading,
      targetEnvironment,
      key
    ]
  )

  return { upload, isUploading }
}

export default useAssetUpload

export type { UseAssetUploadProps }
