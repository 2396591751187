import { useRef } from 'react'

function useMounted(mountOn: boolean) {
  const mountedRef = useRef(false)

  if (mountOn) {
    // First time only
    mountedRef.current = true
  }

  return mountedRef.current
}

export default useMounted
