import React from 'react'

import Text from 'components/typography/Text'
import type { TextProps } from 'components/typography/Text'

const NumberText: React.FC<TextProps> = ({ children, ...props }) => (
  <Text {...props}>{Number.parseFloat(children as string).toFixed(2)}</Text>
)

export default NumberText
