import merge from 'lodash/merge'
import type { Resolvers, TypePolicies } from '@apollo/client'

import customRecord from './customRecord'
import developerState from 'client/state/developer'
import installation from 'client/state/installation'
import preferencesState from 'client/state/preferences'
import prospectState from 'client/state/prospect'
import referrerState from 'client/state/referrer'
import sessionState from 'client/state/session'
import environmentSwitcherState from 'client/state/environmentSwitcher'
import installationSwitcherState from 'client/state/installationSwitcher'
import queryState from 'client/state/query'

export const defaultQueries = [
  developerState.defaults,
  preferencesState.defaults,
  prospectState.defaults,
  referrerState.defaults,
  sessionState.defaults,
  environmentSwitcherState.defaults,
  installationSwitcherState.defaults,
  queryState.defaults
]

export const resolvers: Resolvers = merge(
  developerState.resolvers,
  preferencesState.resolvers,
  prospectState.resolvers,
  referrerState.resolvers,
  sessionState.resolvers,
  environmentSwitcherState.resolvers,
  installationSwitcherState.resolvers,
  queryState.resolvers
)

export const typePolicies: TypePolicies = merge(
  customRecord.typePolicies,
  installation.typePolicies
)
