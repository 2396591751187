import React from 'react'
import { useHistory } from 'react-router-dom'

import CompleteOnboardingForm from 'components/forms/CompleteOnboardingForm'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useCompleteOnboardingMutation } from 'generated/schema'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import type { CompleteOnboardingInput } from 'generated/schema'

function CompleteOnboardingPage() {
  const history = useHistory()
  const [ completeOnboarding ] = useCompleteOnboardingMutation({
    onCompleted: () => history.push('/')
  })

  const currentAccount = useCurrentAccountContext()

  const handleSubmit = useSubmitHandler(completeOnboarding)
  const handleFormSubmit = (values: CompleteOnboardingInput) => handleSubmit(values)

  const initialValues = {
    custom: currentAccount?.custom || {}
  }

  return (
    <CompleteOnboardingForm
      initialValues={initialValues as CompleteOnboardingInput}
      onSubmit={handleFormSubmit}
    />
  )
}

export default CompleteOnboardingPage
