import React from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import { SidePaneFooter } from 'components/sidePane'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { View } from 'generated/schema'

type Params = ViewParams[Views.EDIT_VIEW]

const EditViewView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params
  const { name, app, resource } = initialValues as View

  const viewName = name || app?.name || resource?.name

  const handleSubmit = () => {}

  return (
    <>
      <DashboardEditorHeader
        subtitle="Edit View"
        heading={`View: ${viewName}`}
        onClose={onClose}
      />
      <Form
        initialValues={{ name }}
        onSubmit={handleSubmit}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default EditViewView
