const DATA_TABLE_CELL_COZY_HEIGHT = 80
const DATA_TABLE_CELL_COMPACT_HEIGHT = 50
const DATA_TABLE_CELL_PADDING = 20
const DATA_TABLE_ROW_BORDER_RADIUS = 6
const DATA_TABLE_ROW_BORDER_WIDTH = 1

export {
  DATA_TABLE_CELL_COZY_HEIGHT,
  DATA_TABLE_CELL_COMPACT_HEIGHT,
  DATA_TABLE_CELL_PADDING,
  DATA_TABLE_ROW_BORDER_RADIUS,
  DATA_TABLE_ROW_BORDER_WIDTH
}
