import React, { useContext } from 'react'
import { useRecoilValue } from 'recoil'

import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { OPERATIONS_LIST_LIMIT } from 'models/Resource'
import { Operation, useOperationsListQuery, Workspace } from 'generated/schema'
import { ViewParams, Views } from '../constants'

type Params = ViewParams[Views.RESOURCE_DETAILS]

const OperationsView = ({ workspaceId = '' }: { workspaceId?: string }) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, resource } = params! as Params

  const handleAddNewOperation = () => openDashboardEditorView({
    target: Views.CREATE_OPERATION,
    params: {
      app,
      resource,
      ...(workspaceId ? { workspace: currentWorkspace as Workspace } : {})
    }
  })

  const queryVariables = {
    filter: {
      appId: app ? { eq: app.id } : 'null',
      resourceId: 'null'
    },
    order: [ {
      position: 'asc'
    } ],
    limit: OPERATIONS_LIST_LIMIT
  }

  const {
    data: { operationsList = [] } = {},
    loading,
    error
  } = useOperationsListQuery({
    variables: queryVariables,
    skip: !app && !workspaceId
  })

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Operations
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={handleAddNewOperation}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={operationsList}
          loading={loading}
          error={error}
        >
          <Flex direction="column" gap={2}>
            {operationsList?.map((operation) => {
              const onEdit = () => openDashboardEditorView({
                target: Views.CREATE_OPERATION,
                params: {
                  resource,
                  app,
                  operation: operation as Operation,
                  ...(workspaceId ? { workspace: currentWorkspace as Workspace } : {})
                }
              })

              return (
                <MediaCard
                  compact
                  key={operation.id}
                  media=""
                  title={operation.name}
                  height={64}
                  width="full"
                  onClick={onEdit}
                  actions={[ {
                    description: '',
                    icon: 'arrow-right',
                    onClick: onEdit,
                    isIconAlwaysVisible: true
                  } ]}
                />
              )
            })}
          </Flex>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

export default OperationsView
