import React, { forwardRef, useEffect } from 'react'
import type { ElementType, PropsWithRef, Ref } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Flex from 'components/layout/Flex'
import useCombinedRefs from 'hooks/useCombinedRefs'
import { colorVars } from 'styles/primitives/colors'
import { styled } from 'styles/stitches'
import type { BaseLinkProps } from 'components/links/BaseLink'
import type { ButtonProps } from 'components/buttons/Button'

type CardOwnProps = {
  height?: number| '100%' | 'auto',
  width?: number | '100%' | 'auto',
  overflow?: boolean
}

type CardProps = CardOwnProps & BaseLinkProps & ButtonProps['onClick'] & ButtonProps['onKeyDown'] & StyledProps<typeof StyledCard>

const StyledCard = styled(Flex, {
  ...mixins.transition('simple'),

  backgroundColor: 'light100',
  borderRadius: 6,

  variants: {
    disabled: {
      true: {
        // TODO: fix this
        // ...mixins.shadow('none')
        boxShadow: 'none'
      },
      false: {
        ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1)
      }
    },
    mode: {
      normal: {},
      actionable: {
        cursor: 'pointer',

        '&:hover': {
          ...mixins.shadow('medium', colorVars.dark600rgb, 0.3)
        },

        '&:focus': {
          ...mixins.shadow('xSmall', colorVars.dark600rgb, 0.3)
        },

        '&:focus-within': {
          ...mixins.shadow('xSmall', colorVars.dark600rgb, 0.3)
        }
      }
    },
    padding: {
      compact: {
        padding: 12
      },
      normal: {
        padding: '16'
      },
      cozy: {
        padding: '32'
      },
      wide: {
        paddingBottom: '16',
        paddingTop: '16',
        paddingLeft: '32',
        paddingRight: '32'
      }
    },
    overflow: {
      true: {
        overflow: 'auto'
      }
    }
  }
})

StyledCard.compoundVariant({
  mode: 'actionable',
  disabled: true
},
{
  cursor: 'not-allowed',
  '&:hover': {
    boxShadow: 'none'
  },

  '&:focus': {
    boxShadow: 'none'
  },

  '&:focus-within': {
    boxShadow: 'none'
  }

})

const DEFAULT_ELEMENT: ElementType = 'div'

StyledCard.defaultProps = {
  disabled: false,
  padding: 'normal',
  mode: 'normal'
}

const Card = forwardRef(({
  as,
  height = 'auto',
  width = 'auto',
  autoFocus = false,
  disabled = false,
  ...others
}: CardProps,
ref: Ref<any>) => {
  const combinedRef = useCombinedRefs<HTMLDivElement | HTMLAnchorElement | null | any>(ref)

  const Element = (() => {
    if ('href' in others || 'to' in others) return BaseLink

    return DEFAULT_ELEMENT
  })()

  const isActionable = 'href' in others || 'to' in others || 'onClick' in others

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      others.onClick?.(e as unknown as React.MouseEvent<HTMLDivElement | HTMLAnchorElement>)
    }

    others?.onKeyDown?.(e)
  }

  useEffect(() => {
    if (!combinedRef.current) return

    if (autoFocus) combinedRef.current.focus()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  return (
    <StyledCard
      as={as || Element}
      style={{
        height,
        width
      }}
      disabled={disabled}
      direction="column"
      mode={isActionable ? 'actionable' : 'normal'}
      padding="normal"
      ref={combinedRef}
      {...others}
      {...(('onClick' in others) && { role: 'button', tabIndex: 0, onKeyDown })}
    />
  )
})

Card.displayName = 'Card'

export type { CardProps }

export default Card as (props: PropsWithRef<CardProps>) => JSX.Element
