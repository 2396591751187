import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'

import useSearch from 'hooks/useSearch'
import { ATTRIBUTES_LIST_LIMIT } from 'models/Resource'
import { FieldIdentifier } from 'models/Field'
import { InternalSearchRecordsDocument, InternalSearchRecordsQuery, InternalSearchRecordsQueryVariables, useAttributesListQuery } from 'generated/schema'

type UseSearchGenericRecordsOptions = {
  resourceId?: string,
  relationships?: readonly any[],
  filters?: any,
  order?: any[],
  limit?: number,
  page?: number,
  environmentId?: string,
  skip?: boolean
}

function useSearchGenericRecords({
  resourceId,
  relationships = [],
  filters,
  order,
  limit = 30,
  page,
  environmentId,
  skip
}: UseSearchGenericRecordsOptions) {
  const attributesResult = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: ATTRIBUTES_LIST_LIMIT
    },
    skip: !resourceId
  })

  const attributes = attributesResult.data?.attributesList || []

  const searchableAttributes = compact((attributes.map(
    (attr) => {
      if (!attr.isArray
        && attr.fieldType === FieldIdentifier.TEXT
      ) return camelCase(attr.identifier)
      return ''
    }
  ) || []))

  const searchRecordsVariables: InternalSearchRecordsQueryVariables = {
    input: {
      preview: true,
      resourceId,
      filter: filters,
      fields: attributes.length
        ? attributes.map((attr) => {
          if (attr.fieldType === 'reference-field') {
            const relationship = relationships?.find((rel) => rel.sourceAttributeId === attr.id)
            if (relationship) {
              return [
                { [relationship.identifier]: '*' },
                attr.identifier
              ]
            }
            return [ attr.identifier ]
          }
          return [ attr.identifier ]
        }).reduce((acc, val) => acc.concat(val), []) as any[]
        : [],
      order,
      limit,
      page,
      targetEnvironment: environmentId
    }
  }

  const searchResult = useSearch<InternalSearchRecordsQuery, InternalSearchRecordsQueryVariables>({
    query: InternalSearchRecordsDocument,
    queryOptions: {
      variables: searchRecordsVariables,
      skip
    },
    keys: searchableAttributes
  })

  return {
    attributesResult,
    searchResult
  }
}

export default useSearchGenericRecords
