import { colorVars } from 'styles/theme'

const SIDEBAR_BORDER_RADIUS = 8

const SIDEBAR_PRIMARY_BACKGROUND = `linear-gradient(0deg, ${colorVars.secondary400}, ${colorVars.primary400})`
const SIDEBAR_PRIMARY_BACKGROUND_COLOR = 'primary400'
const SIDEBAR_PRIMARY_BORDER_COLOR = 'primary100'
const SIDEBAR_PRIMARY_BORDER_COLOR_RGB = 'primary100rgb'
const SIDEBAR_PRIMARY_HEADER_HEIGHT = 198
const SIDEBAR_PRIMARY_HEADER_PADDING_BOTTOM = 18
const SIDEBAR_PRIMARY_HEADER_PADDING_TOP = 12
const SIDEBAR_PRIMARY_WIDTH = 210
const SIDEBAR_PRIMARY_WIDTH_COLLAPSED = 70

const SIDEBAR_SECONDARY_BACKGROUND_COLOR = 'light100'
const SIDEBAR_SECONDARY_BORDER_COLOR = 'light700'
const SIDEBAR_SECONDARY_HORIZONTAL_PADDING = 30
const SIDEBAR_SECONDARY_VERTICAL_PADDING = 118
const SIDEBAR_SECONDARY_WIDTH = 210
const SIDEBAR_SECONDARY_WIDTH_COLLAPSED = 15

const WORKSPACE_SIDEBAR_WIDTH = 68

export {
  SIDEBAR_BORDER_RADIUS,

  SIDEBAR_PRIMARY_BACKGROUND,
  SIDEBAR_PRIMARY_BACKGROUND_COLOR,
  SIDEBAR_PRIMARY_BORDER_COLOR,
  SIDEBAR_PRIMARY_BORDER_COLOR_RGB,
  SIDEBAR_PRIMARY_HEADER_HEIGHT,
  SIDEBAR_PRIMARY_HEADER_PADDING_BOTTOM,
  SIDEBAR_PRIMARY_HEADER_PADDING_TOP,
  SIDEBAR_PRIMARY_WIDTH,
  SIDEBAR_PRIMARY_WIDTH_COLLAPSED,

  SIDEBAR_SECONDARY_BACKGROUND_COLOR,
  SIDEBAR_SECONDARY_BORDER_COLOR,
  SIDEBAR_SECONDARY_HORIZONTAL_PADDING,
  SIDEBAR_SECONDARY_VERTICAL_PADDING,
  SIDEBAR_SECONDARY_WIDTH,
  SIDEBAR_SECONDARY_WIDTH_COLLAPSED,

  WORKSPACE_SIDEBAR_WIDTH
}
