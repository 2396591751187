import { styled } from 'styles/stitches'

const LIST_VERTICAL_MARGIN = 36

const List = styled('ul', {
  listStyle: 'none',
  marginBottom: LIST_VERTICAL_MARGIN,
  padding: 0
})

export default List
