import React from 'react'

import Box from 'components/layout/Box'
import SelectInput from 'components/inputs/SelectInput'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'
import type { Installation } from 'generated/schema'

interface Props extends SwitcherResultProps {
  disabled?: boolean,
  appCategoryId: string,
  label?: string
}

const InstallationSwitcher: React.FC<Props> = ({
  disabled, appCategoryId, label, ...switcherProps
}) => {
  const {
    switcher: {
      data: switcherData
    },
    setSwitcher,
    installationsListForApp = []
  } = switcherProps
  return (
    <Box style={{ minWidth: '300px' }}>
      <SelectInput
        // TODO: Discuss this
        // isClearable
        isSearchable
        isDisabled={disabled || !appCategoryId}
        name="installation"
        label={label}
        prependIcon="search"
        placeholder="Select an installation..."
        options={installationsListForApp as Installation[]}
        labelKey="name"
        valueKey="id"
        setValueAsObject
        noOptionsMessage={() => 'No installation found. Please install this app.'}
        size="small"
        variant="light"
        input={{
          value: switcherData?.installation,
          onChange: (installation: Installation) => {
            if (!installation || disabled) return
            setSwitcher({
              id: appCategoryId,
              environment: switcherData?.environment ?? null,
              installation
            })
          }
        }}
      />
    </Box>
  )
}

InstallationSwitcher.defaultProps = {
  label: 'Installation'
}

export default InstallationSwitcher
