import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

enum Kind {
  BELONGS_TO = 'BELONGS_TO',
  HAS_ONE = 'HAS_ONE',
  HAS_MANY = 'HAS_MANY'
}

const KIND_OPTIONS = [
  {
    name: 'Belongs to (1:1)',
    identifier: Kind.BELONGS_TO,
    icon: 'one-to-one',
    description: 'Eg: Person belongs to a company'
  },
  {
    name: 'Has One (1:1)',
    identifier: Kind.HAS_ONE,
    icon: 'one-to-one',
    description: 'Eg: User has one Profile'
  },
  {
    name: 'Has Many (1:N)',
    identifier: Kind.HAS_MANY,
    icon: 'flow',
    description: 'Eg: Post has many Comments'
  }
]

class Relationship extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required(),
    sourceAttributeId: string().required(),
    targetAttributeId: string().required(),
    kind: string().required()
  })
}

export {
  Kind,
  KIND_OPTIONS
}

export default Relationship
