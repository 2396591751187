import React, { useRef } from 'react'

import { FilterBody, FilterBodyProps } from 'components/dataWidgets/CustomizeDisplay'
import Flex from 'components/layout/Flex'
import FieldLabel from './FieldLabel'
import TextLink from 'components/links/TextLink'
import type { FieldArrayChildrenProps } from './FieldArray'

type FilterFieldType = {
  name: string,
  filterOptions: FilterBodyProps['filterOptions'],
  columns: FilterBodyProps['columns'],
  noFilterMessage?: FilterBodyProps['noFilterMessage']
}

function FilterField({ name, filterOptions, columns, noFilterMessage }: FilterFieldType) {
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()

  return (
    <>
      <Flex justifyContent="space-between">
        <FieldLabel>Filter</FieldLabel>
        <TextLink
          as="button"
          type="button"
          variant="underlined"
          fontSize={12}
          onClick={() => fieldsRef.current?.fields.push({
            column: '',
            operation: 'eq',
            value: ''
          })}
        >
          Add Filter
        </TextLink>
      </Flex>
      <FilterBody
        fieldName={name}
        fieldsRef={fieldsRef}
        filterOptions={filterOptions}
        columns={columns}
        noFilterMessage={noFilterMessage}
      />
    </>
  )
}

export default FilterField
