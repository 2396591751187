import React, { HTMLAttributes, memo, useCallback, useContext, useMemo, useRef } from 'react'
import { useSortable } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import SidebarPrimary from 'components/sidebar/SidebarPrimary'
import SidebarSecondary from 'components/sidebar/SidebarSecondary'
import useHover from 'hooks/useHover'
import { isElectron } from 'lib/electron'
import { SIDEBAR_PRIMARY_BACKGROUND, SIDEBAR_PRIMARY_WIDTH, SIDEBAR_PRIMARY_WIDTH_COLLAPSED, SIDEBAR_SECONDARY_WIDTH, SIDEBAR_SECONDARY_WIDTH_COLLAPSED, WORKSPACE_SIDEBAR_WIDTH } from 'components/sidebar/constants'
import { styled } from 'styles/stitches'
import { TourGuideContext, TourType } from 'components/providers/TourProvider'
import { TRIAL_BANNER_HEIGHT } from 'components/trial/TrialBanner'
import type { ComputedMenuElement } from 'lib/generateDashboard'

const SIDEBAR_BORDER_RADIUS = 8

const StyledSidebar = styled('nav', {
  ...mixins.transition('fastIn'),

  background: SIDEBAR_PRIMARY_BACKGROUND,
  display: 'flex',
  left: 0,
  position: 'fixed',
  top: 0,
  transform: 'translateZ(0)', // Force hardware acceleration for fixed elements
  zIndex: 'sidebar',

  variants: {
    isElectron: {
      true: {
        left: WORKSPACE_SIDEBAR_WIDTH
      }
    },
    hasTrialBanner: {
      true: {
        top: TRIAL_BANNER_HEIGHT
      },
      false: {
        top: 0
      }
    }
  }
})

type SidebarProps = Omit<HTMLAttributes<HTMLElement> & {
  menuElements: readonly ComputedMenuElement[],
  selectedMenuElement?: ComputedMenuElement,
  isLocked?: boolean,
  hasTrialBanner?: boolean
}, 'css'>

function Sidebar({
  menuElements,
  selectedMenuElement,
  isLocked = false,
  hasTrialBanner = false,
  ...others
}: SidebarProps) {
  const isAnimatingRef = useRef<boolean>(false)
  const { activeTour, showNextStep } = useContext(TourGuideContext)!
  const [ onPrimaryHoveredProps, isPrimaryHovered, setIsPrimaryHovered ] = useHover({
    pausedRef: isAnimatingRef
  })

  const [ onSecondaryHoveredProps, isSecondaryHovered, setIsSecondaryHovered ] = useHover({
    pausedRef: isAnimatingRef
  })

  const { over, active } = useSortable({ id: 'SIDEBAR' })

  const isDraggingOverSidebarPrimary = (active?.data.current?.kind && !over?.data.current?.parentId && over?.data.current?.placement === 'SIDE') || over?.id === 'SIDEBAR_PRIMARY'
  const isDraggingOverSidebarSecondary = (active?.data.current?.kind && !!over?.data.current?.parentId && over.data.current.placement === 'SIDE') || over?.id === 'SIDEBAR_SECONDARY'

  const toggleIsAnimating = useCallback((transitionDuration: number, transitionDelay: number) => {
    isAnimatingRef.current = true

    setTimeout(() => {
      isAnimatingRef.current = false
    }, (transitionDuration + transitionDelay) * 1000)
  }, [])

  const parentMenuElement = useMemo(() => (
    selectedMenuElement?.target === 'SUBMENU'
      ? selectedMenuElement
      : menuElements.find((menuElement) => menuElement.id === selectedMenuElement?.parentId)
  ), [ menuElements, selectedMenuElement ])

  const [ primaryMenuElements, secondaryMenuElements ] = useMemo(() => {
    const primaryElements: ComputedMenuElement[] = []
    const secondaryElements : ComputedMenuElement[] = []
    menuElements.forEach((menuElement) => {
      if (!menuElement.parentId) {
        primaryElements.push(menuElement)
      } else if (menuElement.parentId === parentMenuElement?.id) {
        secondaryElements.push(menuElement)
      }
    })
    return [ primaryElements, secondaryElements ]
  }, [ menuElements, parentMenuElement ])

  return (
    <StyledSidebar
      {...(activeTour === TourType.WELCOME_MEMBER_TOUR && { onClick: showNextStep })}
      className="tg--sidebar"
      {...others}
      isElectron={isElectron}
      hasTrialBanner={hasTrialBanner}
    >
      <SidebarPrimary
        hasSidebarSecondary={!!parentMenuElement}
        toggleIsAnimating={toggleIsAnimating}
        isPrimaryHovered={isPrimaryHovered || isDraggingOverSidebarPrimary}
        isSidebarLocked={isLocked}
        menuElements={primaryMenuElements}
        setIsPrimaryHovered={setIsPrimaryHovered}
        {...onPrimaryHoveredProps}
      />

      <SidebarSecondary
        toggleIsAnimating={toggleIsAnimating}
        isPrimaryHovered={isPrimaryHovered || isDraggingOverSidebarPrimary}
        isSecondaryHovered={
          (isSecondaryHovered || isDraggingOverSidebarSecondary) && !isDraggingOverSidebarPrimary
        }
        isSidebarLocked={isLocked}
        menuElements={secondaryMenuElements}
        parentMenuElement={parentMenuElement}
        setIsSecondaryHovered={setIsSecondaryHovered}
        {...onSecondaryHoveredProps}
      />
    </StyledSidebar>
  )
}

const SIDEBAR_WIDTH_OPTIONS_MAP = {
  SIDEBAR_MINIMIZED_WITH_SECONDARY:
    SIDEBAR_PRIMARY_WIDTH_COLLAPSED + SIDEBAR_SECONDARY_WIDTH_COLLAPSED,
  SIDEBAR_MINIMIZED_WITHOUT_SECONDARY: SIDEBAR_PRIMARY_WIDTH_COLLAPSED,
  SIDEBAR_EXPANDED_WITH_SECONDARY: SIDEBAR_PRIMARY_WIDTH_COLLAPSED + SIDEBAR_SECONDARY_WIDTH,
  SIDEBAR_EXPANDED_WITHOUT_SECONDARY: SIDEBAR_PRIMARY_WIDTH
} as const

export const getCurrentSidebarWidth = (
  isSidebarMinimized: boolean,
  selectedMenuElement?: ComputedMenuElement
): number => {
  const hasSidebarSecondary = !!selectedMenuElement?.parentId || selectedMenuElement?.target === 'SUBMENU'

  if (isSidebarMinimized) {
    return hasSidebarSecondary
      ? SIDEBAR_WIDTH_OPTIONS_MAP.SIDEBAR_MINIMIZED_WITH_SECONDARY
      : SIDEBAR_WIDTH_OPTIONS_MAP.SIDEBAR_MINIMIZED_WITHOUT_SECONDARY
  }
  return hasSidebarSecondary
    ? SIDEBAR_WIDTH_OPTIONS_MAP.SIDEBAR_EXPANDED_WITH_SECONDARY
    : SIDEBAR_WIDTH_OPTIONS_MAP.SIDEBAR_EXPANDED_WITHOUT_SECONDARY
}

export const sidebarWidthOptions = Object.values(SIDEBAR_WIDTH_OPTIONS_MAP)

export default memo(Sidebar)

export { SIDEBAR_BORDER_RADIUS }
