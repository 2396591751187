import React from 'react'

import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

type ToolbarProps = {
  primaryElements?: React.ReactNode,
  secondaryElements?: React.ReactNode,
  title?: string
}

const TOOLBAR_ELEMENTS_MARGIN = 14

const StyledToolbar = styled(Flex, {
  marginY: 10
})

const SecondaryElementContainer = styled(Flex, {
  '& > *': {
    flexShrink: 0
  }
})

function Toolbar({ primaryElements, secondaryElements, title }: ToolbarProps) {
  if (!primaryElements && !secondaryElements && !title) {
    return null
  }

  return (
    <StyledToolbar gap={TOOLBAR_ELEMENTS_MARGIN} justifyContent="space-between">
      <Flex alignItems="center" gap={TOOLBAR_ELEMENTS_MARGIN} grow={1}>
        {title && <Text fontWeight="bold">{title}</Text>}
        {title && primaryElements && <Divider orientation="vertical" spacing={0} />}
        {primaryElements}
      </Flex>
      {secondaryElements && (
        <SecondaryElementContainer alignItems="center" gap={TOOLBAR_ELEMENTS_MARGIN}>
          {secondaryElements}
        </SecondaryElementContainer>
      )}
    </StyledToolbar>
  )
}

export type { ToolbarProps }

export default Toolbar
