import isObject from 'lodash/isObject'
import React, { useEffect } from 'react'
import get from 'lodash/get'

import CopyToClipboard from 'components/buttons/CopyToClipboard'
import Flex from 'components/layout/Flex'
import useCodeJar from 'hooks/useCodeJar'
import { styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'
import type { CodeEditorInputProps } from 'components/inputs/CodeEditorInput'

interface CodeRendererProps<T> extends RendererOptions<T>, Pick<CodeEditorInputProps, 'language' | 'theme'> {
  enableCopyToClipboard?: boolean
}

const Container = styled(Flex, {
  width: '100%',
  borderRadius: 4,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: 4,

  variants: {
    theme: {
      light: {
        backgroundColor: 'light300',
        color: 'dark300'
      },
      dark: {
        backgroundColor: 'dark1000',
        color: 'light300'
      }
    }
  }
})

const StyledText = styled('pre', {
  '&': {
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
    marginBottom: '0 !important',
    marginTop: '0 !important',
    backgroundColor: 'transparent !important',
    resize: 'none  !important',
    color: 'dark100 !important',
    fontFamily: "'Fira Code', monospace !important"
  }
})

function CodeRenderer<T extends object>({
  dataKey,
  rowData,
  language = 'javascript',
  theme = 'light',
  enableCopyToClipboard = false,
  prefix = '',
  suffix = ''
}: CodeRendererProps<T>) {
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const data = get(rowData, `${prefixContent}${dataKey}${suffixContent}`)

  const { editorRef } = useCodeJar({
    input: { value: data, name: '', onBlur: () => {}, onChange: () => {}, onFocus: () => {} },
    language,
    theme
  })

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.contentEditable = 'false'
    }
  }, [ editorRef ])

  if (isObject(data)) {
    return (
      <Container theme={theme} title={JSON.stringify(data)}>
        <StyledText as="pre">{JSON.stringify(data)}</StyledText>
        {enableCopyToClipboard && (
          <CopyToClipboard
            iconSize={12}
            textToCopy={JSON.stringify(data)}
          />
        )}
      </Container>
    )
  }

  return (
    <Container theme={theme} title={data}>
      <StyledText as="pre" className={`language-${language}`} ref={editorRef}>{data}</StyledText>
      {enableCopyToClipboard && (
      <CopyToClipboard
        iconSize={12}
        textToCopy={data}
      />
      )}
    </Container>
  )
}

export default CodeRenderer
