import React from 'react'
import type { RouteProps } from 'react-router-dom'

import ExternalLayout from 'components/layouts/ExternalLayout'
import ProtectedRoute, { SessionState } from 'components/routes/ProtectedRoute'

function ExternalRoute(props: RouteProps) {
  return (
    <ProtectedRoute
      layout={ExternalLayout}
      requiredSessionState={SessionState.LOGGED_OUT}
      {...props}
    />
  )
}

export default ExternalRoute
