import { useMutation, useQuery } from '@apollo/client'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import type { LocationDescriptor, Path } from 'history'

import InternalContext from 'components/contexts/InternalContext'
import useQueryParams from 'hooks/useQueryParams'
import { SET_ENVIRONMENT_SWITCHER_MUTATION, ENVIRONMENT_SWITCHER_QUERY, EnvironmentSwitcherQuery } from 'client/state/environmentSwitcher'
import { InstallationSwitcherQuery, INSTALLATION_SWITCHER_QUERY, SET_INSTALLATION_SWITCHER_MUTATION } from 'client/state/installationSwitcher'
import type { Environment, EnvironmentsListQuery, Installation, InstallationsListQuery } from 'generated/schema'

type SwitcherInput = {
  id: string | null,
  environment: Environment | null,
  installation: Installation | null
}

interface SwitcherResultProps {
  switcher: { data: SwitcherInput },
  setSwitcher: ({ environment, installation }: SwitcherInput) => Promise<void>,
  isIntegration: boolean,
  installationsListForApp: InstallationsListQuery['installationsList'],
  installationsList?: InstallationsListQuery['installationsList'],
  environmentsList?: EnvironmentsListQuery['environmentsList']
}

const STABLE_EMPTY_ARRAY_FALLBACK: readonly any[] = Object.freeze([])

function useSwitcherState(
  appId: string | null,
  appCategoryId?: string,
  isIntegration: boolean = false
): SwitcherResultProps {
  const history = useHistory()

  const query = useQueryParams()
  const installationParam = query.get('installation')
  const environmentParam = query.get('environment')
  const {
    environmentsList: allEnvironmentsList = STABLE_EMPTY_ARRAY_FALLBACK,
    installationsList: allInstallationsList = STABLE_EMPTY_ARRAY_FALLBACK
  } = useContext(InternalContext)!

  const { data: { environmentSwitcher } = {} } = useQuery<EnvironmentSwitcherQuery>(
    ENVIRONMENT_SWITCHER_QUERY
  )

  const environment = environmentSwitcher?.environment || null

  const { data: { installationSwitcher } = {} } = useQuery<InstallationSwitcherQuery>(
    INSTALLATION_SWITCHER_QUERY,
    { variables: { id: appId }, skip: !appId }
  )

  const installation = installationSwitcher?.installation || null

  const [ environmentSwitcherMutation ] = useMutation(SET_ENVIRONMENT_SWITCHER_MUTATION)
  const [ installationSwitcherMutation ] = useMutation(SET_INSTALLATION_SWITCHER_MUTATION)

  const pushWithSearchParams = useCallback(
    (pathOrLocation: Path | LocationDescriptor<{}>, state?: {}) => {
      if (typeof pathOrLocation === 'string') {
        const currentSearch = new URLSearchParams(history.location.search)
        const newSearch = new URLSearchParams(pathOrLocation)

        newSearch.forEach((value, key) => {
          currentSearch.set(key, value)
        })

        history.replace({
          pathname: history.location.pathname,
          search: currentSearch.toString(),
          state
        })
      } else {
        const currentSearch = new URLSearchParams(history.location.search)
        const newSearch = new URLSearchParams(pathOrLocation.search)

        newSearch.forEach((value, key) => {
          currentSearch.set(key, value)
        })

        history.replace({
          ...pathOrLocation,
          search: currentSearch.toString()
        })
      }
    },
    [ history ]
  )

  const setSwitcher = useCallback(
    async ({ id, environment, installation }: SwitcherInput) => {
      try {
        const searchParams = new URLSearchParams()

        if (environment) {
          searchParams.append('environment', environment.identifier)
        }

        if (installation && isIntegration) {
          searchParams.append('installation', installation.identifier)
        }

        pushWithSearchParams({
          search: searchParams.toString(),
          state: { installation, environment }
        })

        await Promise.all([
          environmentSwitcherMutation({
            variables: { environment }
          }),
          installationSwitcherMutation({
            variables: { id, installation }
          })
        ])
      } catch (err) {
        console.error('Error occurred during switcher mutation:', err)
        throw err
      }
    },
    [
      pushWithSearchParams,
      environmentSwitcherMutation,
      installationSwitcherMutation,
      isIntegration
    ]
  )

  const filterInstallationsByAppId = useCallback(
    (appId: string) => appId && allInstallationsList.filter(
      (installation) => (isIntegration ? installation.id === appId : installation.appId === appId)
    ), [ allInstallationsList, isIntegration ]
  )

  const filterInstallationsByAppCategoryId = useCallback(
    (appCategoryId: string) => allInstallationsList.filter(
      (inst) => inst.app.appCategoryId === appCategoryId
    ), [ allInstallationsList ]
  )

  const setSwitcherForSearchParams = useCallback(async () => {
    const queryInstallation = allInstallationsList.find(
      (inst) => inst.identifier === installationParam
    ) || installation
    const queryEnvironment = allEnvironmentsList.find(
      (env) => env.identifier === environmentParam
    ) || environment

    if (queryInstallation || queryEnvironment) {
      return setSwitcher({
        id: appId,
        environment: queryEnvironment as Environment,
        installation: queryInstallation as Installation
      })
    }

    return null
  }, [
    allInstallationsList,
    allEnvironmentsList,
    installationParam,
    environmentParam,
    appId,
    setSwitcher,
    installation,
    environment
  ])

  const setSwitcherDefaultValues = async () => {
    const queryEnvironment = allEnvironmentsList.find(
      (env) => env.identifier === environmentParam
    )
    const queryInstallation = allInstallationsList.find(
      (inst) => inst.identifier === installationParam
    )

    const filterInstallations = appCategoryId
      ? filterInstallationsByAppCategoryId(appCategoryId)
      : ((appId && filterInstallationsByAppId(appId)) || [])
    const defaultEnvironment = environment || queryEnvironment || allEnvironmentsList[0]
    const defaultInstallation = installation || queryInstallation || filterInstallations[0]

    if (defaultInstallation) {
      setSwitcher({
        environment,
        installation: defaultInstallation,
        id: appId!
      })
    } else if (defaultEnvironment) {
      setSwitcher({
        environment: defaultEnvironment,
        installation,
        id: appId!
      })
    }
  }

  const setSwitcherDefaultValuesRef = useRef(setSwitcherDefaultValues)

  useEffect(() => {
    setSwitcherDefaultValuesRef.current = setSwitcherDefaultValues
  })

  useEffect(() => {
    if ((installationParam && installationParam !== installation?.identifier)
      || (environmentParam && environmentParam !== environment?.identifier)) {
      setSwitcherForSearchParams()
      return
    }

    if ((appCategoryId && !installation) || !environment) {
      setSwitcherDefaultValuesRef.current()
    }
  }, [
    installationParam,
    environmentParam,
    setSwitcherForSearchParams,
    installation,
    environment,
    appCategoryId
  ])

  const switcherState = useMemo(() => ({
    switcher: { data: { id: appId, environment, installation } },
    setSwitcher,
    isIntegration,
    installationsListForApp: appCategoryId
      ? filterInstallationsByAppCategoryId(appCategoryId)
      : ((appId && filterInstallationsByAppId(appId)) || []),

    installationsList: allInstallationsList,
    environmentsList: allEnvironmentsList
  }), [
    allEnvironmentsList,
    allInstallationsList,
    appCategoryId,
    appId,
    environment,
    filterInstallationsByAppCategoryId,
    filterInstallationsByAppId,
    installation,
    isIntegration,
    setSwitcher
  ])

  return switcherState
}

export type { SwitcherResultProps }

export default useSwitcherState
