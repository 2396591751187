import React, { useState } from 'react'

import * as mixins from 'styles/mixins'
import Card from 'components/card/Card'
import ConditionalField from 'components/form/ConditionalField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import Icon from 'components/icons/Icon'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import { styled } from 'styles/stitches'
import type { Attribute } from 'generated/schema'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

enum DisplayTypeValues {
  SYMBOL_ONLY = 'symbol_only',
  LABEL_ONLY = 'label_only',
  SYMBOL_WITH_LABEL = 'symbol_with_label'
}

enum SymbolTypeValues {
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  RADIO = 'radio'
}

const DisplayTypeOptions = [
  {
    label: 'Symbol only',
    value: DisplayTypeValues.SYMBOL_ONLY
  },
  {
    label: 'Label only',
    value: DisplayTypeValues.LABEL_ONLY
  },
  {
    label: 'Symbol + Label',
    value: DisplayTypeValues.SYMBOL_WITH_LABEL
  }
]

const SymbolTypes = [
  {
    value: SymbolTypeValues.SWITCH,
    unCheckedIcon: <Icon name="switch-field" size={16} />,
    checkedIcon: <Icon name="switch-field" size={16} style={{ transform: 'rotateY(180deg)' }} />
  },
  {
    value: SymbolTypeValues.RADIO,
    checkedIcon: <Icon name="radio-field" size={12} />,
    unCheckedIcon: <Icon name="unchecked-circle" size={16} />
  },
  {
    value: SymbolTypeValues.CHECKBOX,
    checkedIcon: <Icon name="checked-circle" size={16} />,
    unCheckedIcon: <Icon name="cross-circle" size={12} />
  }
]

const StyledCard = styled(Card, {
  ...mixins.transition('fluid'),

  borderColor: 'transparent',
  borderStyle: 'solid',
  borderWidth: 2,

  variants: {
    active: {
      true: {
        borderColor: 'primary200'
      }
    }
  }
})

const SymbolTypeInput = ({ fieldPrefix }: { fieldPrefix: string }) => {
  const [ symbolType, setSymbolType ] = useState<string>()

  return (
    <Flex direction="column" gap={10}>
      <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">Symbol Type</Text>
      <Flex gap={6}>
        {SymbolTypes.map(({ checkedIcon, unCheckedIcon, value }) => (
          <StyledCard
            active={value === symbolType}
            onClick={() => setSymbolType(value)}
            key={value}
          >
            <Flex alignItems="center" gap={6}>
              {unCheckedIcon}
              {checkedIcon}
            </Flex>
          </StyledCard>
        ))}
      </Flex>
      <FormField name={`${fieldPrefix}symbol_type`} component="input" defaultValue={symbolType} type="hidden" />
    </Flex>
  )
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const {
    symbol_type: symbolType = '',
    on_label: onLabel,
    off_label: offLabel,
    fontWeight = 'regular'
  } = displaySettings

  const symbol = SymbolTypes.find((s) => s.value === symbolType)
  const symbolView = data ? symbol?.checkedIcon : symbol?.unCheckedIcon
  const label = data ? onLabel : offLabel

  return (
    <Flex gap={6} alignItems="center">
      {symbolView}
      {label && <Text fontSize={14} fontWeight={fontWeight}>{label}</Text>}
    </Flex>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}format`}
      component={SelectInput}
      label="Display Type"
      size="small"
      options={DisplayTypeOptions}
    />
    <ConditionalField when={`${fieldPrefix}format`} is={DisplayTypeValues.LABEL_ONLY}>
      <FormField name={`${fieldPrefix}on_label`} label="'On' Label" component={TextInput} size="small" />
      <FormField name={`${fieldPrefix}off_label`} label="'Off' Label" component={TextInput} size="small" />
    </ConditionalField>
    <ConditionalField when={`${fieldPrefix}format`} is={DisplayTypeValues.SYMBOL_WITH_LABEL}>
      <SymbolTypeInput fieldPrefix={fieldPrefix} />
      <FormField name={`${fieldPrefix}on_label`} label="'On' Label" component={TextInput} size="small" />
      <FormField name={`${fieldPrefix}off_label`} label="'Off' Label" component={TextInput} size="small" />
    </ConditionalField>
    <ConditionalField when={`${fieldPrefix}format`} is={DisplayTypeValues.SYMBOL_ONLY}>
      <SymbolTypeInput fieldPrefix={fieldPrefix} />
    </ConditionalField>
  </Grid>
)

export default {
  Configurations
}

export { Renderer as SwitchRenderer }
