import React from 'react'

import Text from 'components/typography/Text'
import type { FontSize } from 'styles/theme'
import { styled } from 'styles/stitches'

type HeadingProps = {
  children: React.ReactNode,
  level: string
}

type HeadingElement = 'h1' | 'h2'

const SECTION_HEADING_MARGIN_BOTTOM = 16
const SIZES = {
  h1: 24,
  h2: 18
}

const StyledHeading = styled(Text, {
  marginBottom: SECTION_HEADING_MARGIN_BOTTOM
})

function Heading({ children, level }: HeadingProps) {
  const headingTag = `h${level}` as HeadingElement

  return (
    <StyledHeading
      color="dark900"
      fontWeight="bold"
      as={headingTag}

      fontSize={SIZES[headingTag] as FontSize}
    >
      {children}
    </StyledHeading>
  )
}

export default Heading
