import camelCase from 'lodash/camelCase'
import React from 'react'
import { useQuery } from '@apollo/client'
import type { DocumentNode } from 'graphql'

import * as queries from 'generated/schema' // Todo lazy import()
import pascalCase from 'lib/pascalCase'
import TimeSeriesBlock from '../TimeSeriesBlock'
import type { BlockProps } from 'components/blocks/Block'

const TimeSeriesBlockWrapper: React.FC<BlockProps & Record<any, any>> = ({
  block, ...blockProps
}) => {
  const { properties, layout } = block
  const { width } = layout || { width: 400 }
  const { datasets = [], title, description, ...rest } = properties
  const { resource } = datasets[0]

  const listOperation = resource.operations.find((operation: any) => operation.behavior === 'LIST')

  const listOperationName = pascalCase(listOperation?.name.split(' ').reverse().join(' '))
  const listNodeName = camelCase(listOperation?.name.split(' ').reverse().join(' '))

  const listDocument = queries?.[`${listOperationName}Document` as keyof typeof queries] as DocumentNode || queries.AccountsListDocument

  const listQueryOptions = {
    variables: {
      filter: {
        ...listOperation?.parameters
      },
      limit: 1000,
      order: [ {
        createdAt: 'asc'
      } ]
    },
    context: {
      installationId: listOperation?.installationId
    }
  }

  const {
    data,
    loading,
    error
  } = useQuery(listDocument, listQueryOptions)

  return (
    <TimeSeriesBlock
      title={title}
      description={description}
      dataset={datasets[0]}
      data={data?.[listNodeName]}
      loading={loading}
      error={error}
      width={width}
      {...blockProps}
      {...rest}
    />
  )
}

export default TimeSeriesBlockWrapper
