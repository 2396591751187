import React from 'react'
import type { ReactNode } from 'react'

import FormField from 'components/form/FormField'

type Props = {
  children: (values: Record<string, any>) => ReactNode,
  fieldNames: Array<string>
}

const FormValuesField = ({ children, fieldNames }: Props) => (
  <>
    {fieldNames.reduce(
      (acc, fieldName) => (values) => (
        <FormField name={fieldName} subscription={{ value: true }}>
          {({ input: { value } }) => acc({ ...values, [fieldName]: value })}
        </FormField>
      ),
      children
    )({})}
  </>
)

export default FormValuesField
