import AvatarEditor from 'react-avatar-editor'
import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Rect from '@reach/rect'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'

import * as mixins from 'styles/mixins'
import Button from 'components/buttons/Button'
import componentLoader from 'lib/componentLoader'
import Flex from 'components/layout/Flex'
import GlobalContext from 'components/contexts/GlobalContext'
import Icon from 'components/icons/Icon'
import Loader from 'components/loaders/Loader'
import Modal, { ModalProps } from 'components/modal/Modal'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

type ImageUploadModalProps = {
  defaultImage: string,
  imageHeight: number,
  imageWidth: number,
  isOpen: ModalProps['isOpen'],
  onClose: () => void,
  onSubmit: (image: Blob, imageName: string) => void,
  title: string
}

const BUTTON_STEP = 0.1
const IMAGE_EDITOR_HEIGHT = 450
const DEFAULT_SCALE = 1
const MAX_SCALE = 5
const MIN_SCALE = 0.5
const PREVIEW_SIZE = 250
const SLIDER_STEP = 0.01

const Slider = React.lazy(() => componentLoader('slider/Slider'))

const StyledUploadWrapper = styled('div', {
  borderRadius: 6,
  cursor: 'move',
  height: IMAGE_EDITOR_HEIGHT,
  overflow: 'hidden',
  variants: {
    empty: {
      true: {
        ...mixins.transition('simple'),

        alignItems: 'center',
        borderColor: 'dark100',
        borderStyle: 'solid',
        borderWidth: 1,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        ':hover': {
          borderColor: 'dark200'
        }
      }
    }
  }
})

const StyledZoomIcon = styled(Icon, {
  cursor: 'pointer'
})

const StyledUploadSection = styled(Flex, {
  flex: 1,
  minWidth: PREVIEW_SIZE,
  overflow: 'hidden'
})

const StyledPreviewWrapper = styled(Flex, {
  background: 'dark100',
  borderRadius: 6,

  overflow: 'hidden',
  position: 'relative',
  width: PREVIEW_SIZE,

  '& > img': {
    height: '100%',
    objectFit: 'contain',
    overflow: 'hidden'
  },

  '& > [data-placeholder]': {
    color: 'dark200'
  }
})

function ImageUploadModal({
  defaultImage,
  imageHeight,
  imageWidth,
  isOpen,
  onClose,
  onSubmit,
  title,
  ...others
}: ImageUploadModalProps) {
  const [ scale, setScale ] = useState<number>(DEFAULT_SCALE)
  const [ image, setImage ] = useState<File | string | null>(defaultImage)
  const [ croppedImage, setCroppedImage ] = useState<Blob | null>(null)
  const AvatarEditorEl = useRef<AvatarEditor>(null)
  const { openFailureAlert } = useContext(GlobalContext)!

  useEffect(() => {
    setImage(defaultImage)
    setScale(DEFAULT_SCALE)
    setCroppedImage(null)
  }, [ defaultImage, isOpen ])

  const croppedImageUrl = useMemo(() => {
    if (!croppedImage) return undefined

    return URL.createObjectURL(croppedImage)
  }, [ croppedImage ])

  useEffect(() => {
    if (!croppedImageUrl) return () => null

    return () => URL.revokeObjectURL(croppedImageUrl)
  }, [ croppedImageUrl ])

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0])
    }
  }, [])

  const onFileUploadError = () => {
    openFailureAlert({
      title: 'Yikes! Format not supported',
      message: 'Please check the file that you are trying to upload.'
    })
  }

  const onImageChange = () => {
    AvatarEditorEl.current?.getImage().toBlob((blob) => {
      setCroppedImage(blob)
    })
  }

  const adjustScale = () => {
    const rect = AvatarEditorEl.current?.getCroppingRect()
    if (!rect) return

    setScale(
      rect.height > rect.width
        ? rect.width / rect.height
        : rect.height / rect.width
    )
  }

  const onRequestClose = () => {
    onClose()
    setCroppedImage(null)
    setImage(null)
  }

  const handleSubmit = () => {
    onSubmit(croppedImage!, image && typeof image === 'object' ? image.name : '')
    onRequestClose()
  }

  const onZoomIn = () => {
    setScale(
      Math.max(scale - BUTTON_STEP, 1)
    )
    onImageChange()
  }

  const onZoomOut = () => {
    setScale(
      Math.min(scale + BUTTON_STEP, 5)
    )
    onImageChange()
  }

  const renderSlider = () => (
    <Flex alignItems="center" gap={20} grow={1}>
      <StyledZoomIcon
        name="upload-placeholder"
        onClick={onZoomIn}
        size={20}
      />
      <Suspense fallback={<Loader loading />}>
        <Slider
          defaultValue={scale}
          max={MAX_SCALE}
          min={MIN_SCALE}
          onAfterChange={onImageChange}
          onChange={setScale}
          step={SLIDER_STEP}
          value={scale}
        />
      </Suspense>
      <StyledZoomIcon
        name="upload-placeholder"
        onClick={onZoomOut}
        size={24}
      />
    </Flex>
  )

  const renderEditor = () => {
    if (!image) {
      return null
    }

    return (
      <Rect>
        {({ rect, ref }) => {
          const width = rect?.width || 0
          const borderHorizontalWidth = (width - imageWidth) / 2
          const borderVerticalWidth = (IMAGE_EDITOR_HEIGHT - imageHeight) / 2
          const editorWidth = width - (2 * borderHorizontalWidth)
          const editorHeight = IMAGE_EDITOR_HEIGHT - (2 * borderVerticalWidth)

          return (
            <div ref={ref}>
              <AvatarEditor
                border={[ borderHorizontalWidth, borderVerticalWidth ]}
                crossOrigin="anonymous"
                height={editorHeight}
                image={image}
                onLoadFailure={onFileUploadError}
                onLoadSuccess={() => {
                  adjustScale()
                  onImageChange()
                }}
                onMouseUp={onImageChange}
                ref={AvatarEditorEl}
                scale={scale}
                width={editorWidth}
              />
            </div>
          )
        }}
      </Rect>
    )
  }

  const { getRootProps, getInputProps, open } = useDropzone(
    { onDrop, accept: 'image/*', multiple: false, noClick: !!image }
  )

  return (
    <Modal
      {...others}
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {({ Body, Header }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Flex gap={24} grow={1}>
              <StyledUploadSection direction="column" gap={8}>
                <Text fontSize={10} color="dark500" textTransform="uppercase">
                  Upload, Crop and Resize.
                </Text>
                <StyledUploadWrapper
                  {...getRootProps() as Omit<DropzoneRootProps, 'css'>}
                  empty={!image}
                >
                  <input {...getInputProps()} />
                  {!image && (
                    <Flex direction="column" gap={18}>
                      <Text fontSize={14} fontWeight="semibold">
                        Drag and drop picture here
                      </Text>
                      <Text fontSize={12}>or <b>upload</b> from disk.</Text>
                    </Flex>
                  )}
                  {renderEditor()}
                </StyledUploadWrapper>
                {image && (
                  <Flex alignItems="center" gap={20}>
                    {renderSlider()}

                    <Button
                      label="Choose another image"
                      onClick={open}
                      variant="outline"
                    />
                  </Flex>
                )}
              </StyledUploadSection>
              <Flex direction="column" gap={8}>
                <Text fontSize={10} color="dark500" textTransform="uppercase">
                  Preview
                </Text>
                <StyledPreviewWrapper
                  alignItems="center"
                  gap={0}
                  justifyContent="center"
                  role="presentation"
                  style={{
                    height: (imageHeight / imageWidth) * PREVIEW_SIZE
                  }}
                >
                  {!croppedImage && (
                    <Icon data-placeholder name="upload-placeholder" size={32} />
                  )}

                  {croppedImage && (
                    <img src={croppedImageUrl} alt="preview" />
                  )}
                </StyledPreviewWrapper>
                {croppedImage && <Button label="Submit" onClick={handleSubmit} />}
              </Flex>
            </Flex>
          </Body>
        </>
      )}
    </Modal>
  )
}

export type { ImageUploadModalProps }

export default ImageUploadModal
