import React, { forwardRef, useLayoutEffect, useState } from 'react'
import type { HTMLAttributes, Ref } from 'react'
import type { Direction, DroppableStateSnapshot } from 'react-beautiful-dnd'

type PlaceholderProps = {
  className: string,
  direction?: Direction,
  snapshot: DroppableStateSnapshot
}

const withPlaceholder = (props: PlaceholderProps) => (
  forwardRef((innerProps: any, ref: Ref<any>) => (
    <CustomPlaceholder ref={ref} {...props} {...innerProps} />
  ))
)

const CustomPlaceholder = forwardRef((
  { className, direction = 'vertical', snapshot, ...props }: HTMLAttributes<HTMLElement> & PlaceholderProps, ref: Ref<HTMLDivElement>
) => {
  const getInitialPlaceholderDimension = () => {
    const dragElement = document.querySelector(`[data-rbd-draggable-id="${snapshot?.draggingFromThisWith}"]`) as HTMLElement
    return direction === 'vertical' ? dragElement?.clientHeight : dragElement?.clientWidth
  }

  const [ y, setY ] = useState(0)
  const [ x, setX ] = useState(0)
  const [ placeholderDimension, setPlaceholderDimension ] = useState(getInitialPlaceholderDimension)
  const [ referenceEl, setReferenceEl ] = useState<HTMLDivElement | null>(null)

  const setRef = (el: HTMLDivElement) => {
    if (typeof ref === 'function') {
      ref(el)
    }
    setReferenceEl(el)
  }

  useLayoutEffect(() => {
    const dropElement = document.querySelector(`[data-rbd-draggable-id="${snapshot.draggingOverWith}"]`) as HTMLElement
    let animationFrame: number

    if (!snapshot.isDraggingOver) {
      return undefined
    }

    animationFrame = requestAnimationFrame(function calculateY() {
      const droppableChildNodes = referenceEl?.parentElement?.children || []
      const firstTransformedEl = (Array.from(droppableChildNodes) as HTMLElement[]).find((el) => el && el.style.transform && el.style.transform.includes('translate'))
      const sourceRect = firstTransformedEl?.getBoundingClientRect()
      const targetRect = referenceEl?.getBoundingClientRect()

      if (direction === 'vertical') {
        // react-beautiful-dnd adds translate(Xpx, Ypx) to elements
        const transformY = parseFloat(firstTransformedEl?.style.transform.split(', ')[1].replace('px)', '') || '0')

        if (dropElement !== null) { firstTransformedEl?.style.setProperty('transform', `translate(0, ${dropElement.clientHeight}px)`) }

        setPlaceholderDimension(dropElement && dropElement.clientHeight !== 0
          ? dropElement.clientHeight
          : 56)

        if (transformY > 0) {
          targetRect && sourceRect && setY(
            Math.floor(targetRect.y - sourceRect.y + dropElement?.clientHeight)
          )
        } else {
          setY(0)
        }
      } else {
        // react-beautiful-dnd adds translate(Xpx, Ypx) to elements
        const transformX = parseFloat(firstTransformedEl?.style.transform.split('px')[0].replace('translate(', '') || '0')

        if (dropElement !== null) { firstTransformedEl?.style.setProperty('transform', `translate(${dropElement.clientWidth}px, 0)`) }

        setPlaceholderDimension(dropElement && dropElement.clientWidth !== 0
          ? dropElement.clientWidth
          : 40)

        if (transformX > 0) {
          targetRect && sourceRect && setX(
            Math.floor(targetRect.x - sourceRect.x + dropElement?.clientWidth)
          )
        } else {
          setX(0)
        }
      }

      animationFrame = requestAnimationFrame(calculateY)
    })

    return () => cancelAnimationFrame(animationFrame)
  }, [ direction, referenceEl, snapshot ])

  return (
    <div
      ref={setRef}
      {...props}
      style={{
        ...props.style,

        [direction === 'vertical' ? 'width' : 'height']: '100%',
        [direction !== 'vertical' ? 'width' : 'height']: placeholderDimension
      }}
    >
      {!!props.style?.[direction === 'vertical' ? 'width' : 'height'] && (
        <div
          className={className}
          style={{
            borderColor: 'black',
            transform: `translate(-${x}px, ${-y}px)`
          }}
        />
      )}
    </div>
  )
})

CustomPlaceholder.displayName = 'CustomPlaceholder'

export default withPlaceholder
