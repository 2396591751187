import React from 'react'

import DetailsBlock from 'components/blocks/DetailsBlock'
import type { ViewProps } from 'components/views'

type Params = {
  title: string,
  resourceId: string,
  recordId: string
}

function GenericResourceDetailsView({
  closeView,
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const {
    title,
    resourceId,
    recordId
  } = params || {}

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <DetailsBlock
              asFragment
              resourceId={resourceId}
              recordId={recordId}
            />
          </Body>
        </>
      )}
    </View>
  )
}

GenericResourceDetailsView.defaultStyle = 'PANEL' as const

export default GenericResourceDetailsView
