import { cloneElement, Children, Fragment, isValidElement } from 'react'

type InjectPropsCallback = (child: React.ReactNode, index: number) => Record<string, any>

function injectProps(children: React.ReactNode, callback: InjectPropsCallback): React.ReactNode {
  return (
    Children.map(children, (child: React.ReactNode, index) => {
      if (isValidElement(child)) {
        if (child.type === Fragment) {
          return injectProps(child.props.children, callback)
        }
        return cloneElement(child, callback(child, index))
      }
      return child
    })
  )
}

export default injectProps
