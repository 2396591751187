import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import type { Color } from 'styles/theme'
import type { ConfigurationStatus } from 'generated/schema'

enum Status {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

const STATUS_ICON = {
  [Status.PENDING]: 'info',
  [Status.COMPLETED]: 'tick-circle',
  [Status.FAILED]: 'error'
}

const STATUS_MAP = {
  [Status.PENDING]: 'INSTALLING',
  [Status.COMPLETED]: 'INSTALLED',
  [Status.FAILED]: 'FAILED'
}

const STATUS_MESSAGE = {
  [Status.PENDING]: 'Installation in progress...',
  [Status.COMPLETED]: 'App is installed.',
  [Status.FAILED]: 'Failed to install.'
}

const STATUS_COLOR: Record<ConfigurationStatus, Color> = {
  [Status.PENDING]: 'warning500',
  [Status.COMPLETED]: 'positive500',
  [Status.FAILED]: 'negative500'
}

class Configuration extends BaseModel {
  static schema = object({
    installationId: string().required(),
    environmentId: string().required()
  })
}

export { Status, STATUS_ICON, STATUS_COLOR, STATUS_MAP, STATUS_MESSAGE }
export default Configuration
