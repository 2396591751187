import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import IconTextButton from 'components/buttons/IconTextButton'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import RadioInput from 'components/inputs/RadioInput'
import Text from 'components/typography/Text'
import updateMenuElements from 'lib/updateMenuElements'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { MENU_ELEMENT_OPTIMISTIC_PROPERTIES } from 'lib/generateDashboard'
import {
  useCreateMenuElementMutation,
  useDestroyMenuElementMutation,
  useUpdateMenuElementMutation
} from 'generated/schema'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { CreateMenuElementMutationVariables, CreateMenuElementInput, UpdateMenuElementInput, MenuElementSeparatorStyle } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { MenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import type { ViewProps } from 'components/views'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type Params = Partial<ComputedMenuElement & MenuElementPositionContext>

const DEFAULT_SEPARATOR_STYLE = 'RULER' as MenuElementSeparatorStyle

const SEPARATOR_STYLES: { label: string, value: MenuElementSeparatorStyle }[] = [
  {
    label: 'Ruler',
    value: 'RULER'
  },
  {
    label: 'Whitespace',
    value: 'WHITESPACE'
  }
]

function AddMenuSeparatorView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { parentIdToMenuElementsMap } = useContext(InternalContext)!
  const {
    dashboardId,
    id,
    newNonStickyPosition,
    parentId,
    placement,
    position,
    separatorStyle = DEFAULT_SEPARATOR_STYLE
  } = params

  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted: onRequestClose
  })
  const [ updateMenuElement ] = useUpdateMenuElementMutation({ onCompleted: onRequestClose })
  const [ destroyMenuElement ] = useDestroyMenuElementMutation({ onCompleted: onRequestClose })

  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    optimisticResponse: {
      response: 'CREATE',
      mutation: 'createMenuElement',
      typename: 'MenuElement',
      defaultValue: MENU_ELEMENT_OPTIMISTIC_PROPERTIES,
      override: (input: CreateMenuElementMutationVariables['input']) => ({
        parentId: input.parentId || null
      })
    },
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleDestroyMenuElementSubmit = useSubmitHandler(destroyMenuElement, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('DESTROY', parentIdToMenuElementsMap, dashboardId)
  })

  const confirm = useConfirmation()

  const onConfirmDestroy = () => handleDestroyMenuElementSubmit({ id })

  const handleMenuSeparatorFormSubmit = (values: FormValues, form: FormPropsWithId<UpdateMenuElementInput>['form']) => {
    if (id) {
      return handleUpdateMenuElementSubmit({
        ...values,
        id
      }, form)
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId,
      kind: 'SEPARATOR'
    })
  }

  const renderRadioInputs = () => SEPARATOR_STYLES.map((style) => (
    <Field
      autoFocus={style.value === separatorStyle}
      key={style.value}
      label={style.label}
      component={RadioInput}
      name="separatorStyle"
      type="radio"
      value={style.value}
    />
  ))

  const title = id ? 'Update Separator' : 'Add Separator'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            onSubmit={handleMenuSeparatorFormSubmit}
            initialValues={{
              id,
              parentId,
              placement,
              position: id ? position : newNonStickyPosition,
              separatorStyle
            }}
            validate={(values) => MenuElement.validate(values, [ 'separatorStyle' ])}
            render={({ handleSubmit, submitting }) => (
              <>
                <Body>
                  <Flex alignItems="flex-start" as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                    <Text
                      fontSize={18}
                      fontWeight="bold"
                      lineHeight="cozy"
                    >
                      Style
                    </Text>
                    {renderRadioInputs()}
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Footer.Left>
                    {id && (
                      <IconTextButton
                        label="Delete"
                        name="trash"
                        onClick={() => confirm(
                          { action: 'delete', onConfirmClick: onConfirmDestroy, recordType: 'Menu Separator' }
                        )}
                      />
                    )}
                  </Footer.Left>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button type="submit" disabled={submitting} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

AddMenuSeparatorView.defaultStyle = 'DIALOG' as const

export default AddMenuSeparatorView
