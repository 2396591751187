import React from 'react'

import ToggleInput from 'components/inputs/ToggleInput'
import type { ComputeDiffProps } from 'components/contentVersion/VersionDiff'

type SwitchFieldProps = {
  value?: boolean
}

const noop = () => {}

const mockFieldProps = {
  name: 'toggle',
  onBlur: noop,
  onFocus: noop,
  value: ''
}

function SwitchField({ value }: SwitchFieldProps) {
  if (!value) return null

  return (
    <ToggleInput
      meta={{}}
      label=""
      input={{
        checked: value,
        onChange: () => {},
        ...mockFieldProps
      }}
    />
  )
}

SwitchField.computeDiff = <T extends boolean>({
  previousValue, currentValue
}: ComputeDiffProps<T>) => ({
    previousNode: <SwitchField value={previousValue} />,
    currentNode: <SwitchField value={currentValue} />,
    isDiff: previousValue !== currentValue
  })

export default SwitchField
