import { alertVar } from 'client/state/alert'

type Options = {
  retryAttempts?: number,
  waitInterval?: number,
  suppressAlert?: boolean
}

const DEFAULT_OPTIONS: Options = {
  retryAttempts: 3,
  waitInterval: 1000, // in ms
  suppressAlert: false
}

/**
 * 1. Retries fetching chunks if for some reason the initial attempt(s) failed.
 * 2. Throws an alert message if it ran out of max retry attempts.
 *
 * Note: use only for loading components/** paths.
 * lib/** or hooks/** etc. paths won't work as it is hard coded to component/** due to
 * the rules defined by webpack.
 * For more info - https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
 */

function componentLoader<T = any>(
  path: string, opts?: Options
) {
  const options = { ...DEFAULT_OPTIONS, ...opts }
  const { retryAttempts, waitInterval } = options as Required<Options>

  return new Promise<T>((resolve, reject) => {
    // https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
    import(`components/${path}`)
      .then(resolve)
      .catch((error) => {
        if (retryAttempts <= 1) {
          if (!options.suppressAlert) {
            alertVar({
              preventAutoClose: true,
              icon: 'alert',
              message: 'A new update is available. Refresh the page to apply the update.',
              title: 'New Update!',
              variant: 'warning',
              isOpen: true
            })
          }

          reject(error)
          return
        }

        setTimeout(() => {
          componentLoader(path, {
            ...options,
            retryAttempts: retryAttempts! - 1
          }).then(resolve, reject)
        }, waitInterval)
      })
  })
}

export default componentLoader
