import React from 'react'
import SVG from 'react-inlinesvg'

import loaderImage from 'assets/images/loader-animated.svg'
import { styled } from 'styles/stitches'

const LOADER_SIZES = {
  large: 175,
  normal: 80,
  small: 40
}

type SimpleLoaderProps = {
  className?: string,
  size?: 'large' | 'normal' | 'small'
}

const StyledSimpleLoader = styled(SVG, {
  color: 'light700',

  // normalize.css makes all non-root SVGs hidden
  // https://github.com/necolas/normalize.css/issues/718
  '& > svg': {
    overflow: 'visible !important'
  }
})

function SimpleLoader({ className, size = 'normal', ...others }: SimpleLoaderProps) {
  return (
    <StyledSimpleLoader
      className={className}
      style={{
        height: LOADER_SIZES[size!],
        width: LOADER_SIZES[size!]
      }}
      src={loaderImage}
      title="Loading..."
      {...others}
    />
  )
}

export default SimpleLoader
