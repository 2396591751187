import React from 'react'

import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'

type ExternalFormFooterProps = {
  children: React.ReactNode
}

function ExternalFormFooter({ children }: ExternalFormFooterProps) {
  return (
    <>
      <Divider />

      <Flex justifyContent="space-between" alignItems="center">
        {children}
      </Flex>
    </>
  )
}

export default ExternalFormFooter
