import React from 'react'

import { Popover, PopoverContainer, PopoverItem } from 'components/popover'

interface Props {
  onClick: (payload: any) => void
}

const HEADING_OPTIONS = [
  { label: 'Heading 1', value: 'heading1', level: 1 },
  { label: 'Heading 2', value: 'heading2', level: 2 },
  { label: 'Heading 3', value: 'heading3', level: 3 },
  { label: 'Heading 4', value: 'heading4', level: 4 },
  { label: 'Heading 5', value: 'heading5', level: 5 },
  { label: 'Heading 6', value: 'heading6', level: 6 }
  // { label: 'Paragraph', value: 'paragraph', level: 0 }
]

const HeadingCommand: React.FC<Props> = ({ children, onClick }) => (
  <PopoverContainer
    modifiers={[
      {
        name: 'offset',
        options: {
          offset: [ -30, 8 ]
        }
      }
    ]}
  >
    {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
      <div {...otherToggleProps}>
        {children}
      </div>
    )}
    {(popoverProps) => (
      <Popover withArrow {...popoverProps}>
        {HEADING_OPTIONS.map((heading) => (
          <PopoverItem
            key={heading.value}
            text={heading.label}
            onClick={() => onClick({ level: heading.level })}
          />
        ))}
      </Popover>
    )}
  </PopoverContainer>
)

export default HeadingCommand
