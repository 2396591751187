import rafSchd from 'raf-schd'
import { useCallback, useState } from 'react'

type ScrollPosition = {
  scrollX: number,
  scrollY: number
}

function useContainerScroll(
  initialValue?: ScrollPosition
): [ ScrollPosition, (values: ScrollPosition) => void ] {
  const [ position, setPosition ] = useState<ScrollPosition>({
    scrollX: initialValue?.scrollX || 0,
    scrollY: initialValue?.scrollY || 0
  })

  const updatePosition = useCallback(
    (newValues) => rafSchd((values: ScrollPosition) => setPosition({
      scrollX: values.scrollX,
      scrollY: values.scrollY
    }))(newValues),
    []
  )

  return [ position, updatePosition ]
}

export default useContainerScroll
