import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { Attribute, Relationship, useAttributesListQuery, useFieldTypesListQuery, useRelationshipsListQuery } from 'generated/schema'
import { Kind } from 'models/Relationship'
import { RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from './constants'
import type { ActiveViewProps } from './DashboardEditor'

type Params = ViewParams[Views.LINK_ATTRIBUTE]

const AttributesList = ({ attributes, activeAttribute, onClick }: {
  attributes: Attribute[],
  activeAttribute?: Attribute['id'],
  onClick: (id: Attribute['id']) => void
}) => {
  const { data: { fieldTypes } = {} } = useFieldTypesListQuery()

  return (
    <Flex direction="column" gap={6}>
      {attributes.map((attr: Attribute) => {
        const fieldType = fieldTypes?.find((type) => type.identifier === attr.fieldType)

        return (
          <MediaCard
            active={activeAttribute === attr.id}
            compact
            media={`${fieldType?.icon || 'text-field'}`}
            title={attr.name}
            height={64}
            width="full"
            onClick={() => onClick(attr.id)}
          />
        )
      })}
    </Flex>
  )
}

const RelatedResourcesList = ({ relationships, onClick }: {
  relationships: Relationship[],
  onClick: (resourceId: string, relationId: string) => void
}) => (
  <Flex direction="column" gap={16}>
    <Text
      fontWeight="bold"
      color="dark500"
      fontSize={10}
      textTransform="uppercase"
    >
      Related
    </Text>
    <Flex direction="column" gap={6}>
      {relationships.map((relation) => (
        <MediaCard
          compact
          media={relation.target.icon || 'graph'}
          title={relation.target.name}
          height={64}
          width="full"
          onClick={() => onClick(relation.target.id, relation.id)}
          actions={[ {
            description: '',
            icon: 'arrow-right',
            onClick: () => onClick(relation.target.id, relation.id),
            isIconAlwaysVisible: true
          } ]}
        />
      ))}
    </Flex>
  </Flex>
)

const getRelatedLabel = (relations: Relationship[], attribute: Attribute) => {
  const relatedName = relations?.map((r) => r.name).join(' ') || ''

  return `${relatedName} ${attribute?.name}`
}

const LinkAttributeView = ({ onClose }: ActiveViewProps) => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    stepBackDashboardEditor
  } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const {
    parentResourceId,
    resourceId,
    relations,
    isUpdating,
    initialValues,
    currentIndex,
    block,
    isColumnUpdating,
    type
  } = params! as Params

  const {
    data: { relationshipsList = [] } = {},
    loading: relationshipsLoading
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId },
        kind: { in: [ Kind.BELONGS_TO, Kind.HAS_ONE ] }
      },
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const [ activeAttribute, setActiveAttribute ] = useState(null)

  const {
    data: { attributesList = [] } = {},
    loading: attributesListLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      }
    },
    skip: !resourceId
  })

  const idToAttributesMap = getPropertyToElementMap(attributesList, 'id')
  const idToRelationsMap = getPropertyToElementMap(relationshipsList, 'id')

  const stepBack = (values?: any) => {
    let formValues
    let stepBackCount

    if (values && values.attribute) {
      const attributeId = isUpdating ? activeAttribute : values.attribute
      const attribute = idToAttributesMap[attributeId]
      const isCurrentResourceAttribute = attribute && attribute.resourceId === parentResourceId

      formValues = {
        ...initialValues,
        display_type: undefined,
        attribute: attribute.id,
        label: isCurrentResourceAttribute
          ? attribute.name
          : getRelatedLabel(relations, attribute as Attribute),
        ...(!isCurrentResourceAttribute ? {
          relations: relations.map((r: Relationship) => r.id)
        } : {}),
        kind: isCurrentResourceAttribute ? 'RESOURCE' : 'RELATION'
      }

      stepBackCount = relations ? relations.length + 2 : 1
    } else {
      formValues = { ...initialValues, display_type: undefined }
      stepBackCount = 1
    }

    stepBackDashboardEditor(stepBackCount, {
      initialValues: formValues,
      currentIndex,
      block,
      type,
      isUpdating: isColumnUpdating
    })
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle="Link Attribute"
        heading={relations?.length ? 'Back' : `${isUpdating ? 'Edit' : 'Add'} Column`}
        onClose={onClose}
        onStepBack={stepBack}
      />
      <Form
        initialValues={{
          attribute: initialValues.attribute
        }}
        onSubmit={stepBack}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting }) => (
          <>
            <DashboardEditorBody>
              <DashboardEditorLoader
                empty={{
                  variant: 'simple',
                  element: (
                    <Flex alignItems="center" direction="column">
                      <Text fontSize={14} color="dark500">No attributes found.</Text>
                    </Flex>
                  )
                }}
                data={attributesList || relationshipsList}
                loading={attributesListLoading || relationshipsLoading}
              >
                <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                  {!!attributesList?.length && (
                    <>
                      <AttributesList
                        attributes={attributesList as Attribute[]}
                        onClick={(id) => {
                          setActiveAttribute(id)
                        }}
                        activeAttribute={activeAttribute}
                      />
                      <FormField name="attribute" type="hidden" defaultValue={activeAttribute} />
                    </>
                  )}
                  {!!relationshipsList?.length && (
                    <RelatedResourcesList
                      relationships={relationshipsList as Relationship[]}
                      onClick={(resourceId, relationId) => openDashboardEditorView({
                        target: Views.LINK_ATTRIBUTE,
                        params: {
                          parentResourceId,
                          resourceId,
                          isUpdating,
                          relations: [
                            ...(relations || []),
                            idToRelationsMap[relationId]
                          ],
                          initialValues,
                          block,
                          type,
                          currentIndex
                        }
                      })}
                    />
                  )}
                  <input type="submit" style={{ display: 'none' }} />
                </Flex>
              </DashboardEditorLoader>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || !activeAttribute} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default LinkAttributeView
