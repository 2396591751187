import camelCase from 'lodash/camelCase'
import group from 'lodash/groupBy'
import React from 'react'
import sum from 'lodash/sum'
import { useRecoilValue } from 'recoil'

import BarChartBlock from 'components/blocks/BarChartBlock'
import useDashboard from 'hooks/useDashboard'
import type { BlockProps } from 'components/blocks/Block'

const BarChartBlockWrapper: React.FC<BlockProps & Record<any, any>> = ({
  block, ...blockProps
}) => {
  const { getOperationsSelector } = useDashboard()
  const { properties, layout } = block
  const { width } = layout || { width: 400 }
  const { datasets = [], title, description, type, ...rest } = properties

  const operationIds = datasets.map((dataset: any) => dataset.operation?.identifier).filter(Boolean)
  const operationsState = useRecoilValue(getOperationsSelector(operationIds))

  const seriesData = datasets.map((dataset: any) => {
    const operationIdentifier = dataset.operation?.identifier
    const operationData = operationsState.find((state) => state.identifier === operationIdentifier)
    const data = (operationData as any)?.[operationIdentifier] || []
    const { name, show, groupBy } = dataset
    const groupedData = group(data, camelCase(groupBy.xAxis))

    return {
      name,
      data: Object.entries(groupedData).map(
        ([ key, value ]) => ([ key, show.aggregate === 'SUM'
          ? sum(value.map((val: any) => val[camelCase(show.yAxis)]))
          : value.length ])
      )
    }
  })

  return (
    <BarChartBlock
      title={title}
      description={description}
      type={type}
      series={seriesData}
      loading={operationsState.some((state) => state.loading)}
      error={operationsState.filter((state) => state.error)[0]}
      width={width}
      {...blockProps}
      {...rest}
    />
  )
}

export default BarChartBlockWrapper
