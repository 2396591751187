import dayjs from 'dayjs'
import React, { useCallback } from 'react'

import DateInput from 'components/inputs/DateInput'
import FormField from 'components/form/FormField'
import isNullOrUndefined from 'lib/isNullOrUndefined'
import isValidDate from 'lib/formValidators/isValidDate'
import { DATE_FORMATS, fieldProps, NUMBER_RANGE } from 'components/contentEditors/generic/fields/fieldProps'
import type { DateInputProps } from 'components/inputs/DateInput'

type DateTimeFieldProps = Omit<DateInputProps, 'input' | 'meta'> & fieldProps<'date'>

const getMaxDateMessage = (format: string = DATE_FORMATS.MM_DD_YYYY) => ({ max }:any) => `can be at most ${dayjs(max).format(format)}`

const getMinDateMessage = (format: string = DATE_FORMATS.MM_DD_YYYY) => ({ min }:any) => `must be at least ${dayjs(min).format(format)}`

const getDateTimeFieldSchema = (settings: DateTimeFieldProps['settings'] = {}, isNullable: boolean, value: any) => {
  let schema = isValidDate(isNullable).typeError('must be a valid date')

  if (settings.checkRequired && !settings.hasFallbackLocale) schema = schema.required()

  if (settings.checkValue) {
    if (!settings.checkRequired && isNullOrUndefined(value)) {
      return schema
    }

    switch (settings.valueComparator) {
      case NUMBER_RANGE.BETWEEN:
        if (settings.valueMaximum) {
          schema = schema.max(
            settings.valueMaximum, getMaxDateMessage(settings.format)
          )
        }
        if (settings.valueMinimum) {
          schema = schema.min(
            settings.valueMinimum, getMinDateMessage(settings.format)
          )
        }
        break

      case NUMBER_RANGE.GREATER_THAN_OR_EQUAL:
        if (settings.valueMinimum) {
          schema = schema.min(
            settings.valueMinimum, getMinDateMessage(settings.format)
          )
        }
        break

      case NUMBER_RANGE.LESS_THAN_OR_EQUAL:
        if (settings.valueMaximum) {
          schema = schema.max(
            settings.valueMaximum, getMaxDateMessage(settings.format)
          )
        }
        break

      default:
        break
    }
  }

  return schema
}

const DateTimeField = ({
  name,
  settings,
  shouldValidate,
  isNullable = false,
  ...others
}: DateTimeFieldProps) => {
  const withTime = settings?.format?.includes(':')

  const validate = useCallback(
    (value: string) => getDateTimeFieldSchema(settings, isNullable, value)
      .validate(value)
      .then(() => { })
      .catch((e) => e.message), [ settings, isNullable ]
  )

  return (
    <FormField
      component={DateInput}
      name={name}
      inputFormat={settings?.format}
      isNullable={isNullable}
      saveFormat={settings?.saveFormat}
      withTime={withTime}
      {...({
        checkRequired: settings?.checkRequired,
        placeholder: settings?.placeholder,
        helpText: settings?.helpText
      })}
      {...shouldValidate && { validate }}
      {...others}
    />
  )
}

export type { DateTimeFieldProps }

export { getDateTimeFieldSchema, getMaxDateMessage, getMinDateMessage }

export default DateTimeField
