import { useCallback, useMemo, useState } from 'react'

import useHotKey from 'hooks/useHotKey'

type OptionsProps = {
  toggle?: boolean,
  escape?: boolean
}

function useHotMenu(
  hotkey: string,
  { escape, toggle }: OptionsProps = {}
) {
  const [ isOpen, setIsOpen ] = useState(false)

  const closeMenu = useCallback(() => setIsOpen(false), [])
  const openMenu = useCallback(() => setIsOpen(true), [])

  const params = useMemo(() => ({
    [hotkey]: (event: KeyboardEvent) => {
      event.preventDefault()
      setIsOpen((value) => (toggle ? !value : true))
    },
    Escape: () => (escape ? setIsOpen(false) : undefined)
  }), [ escape, hotkey, toggle ])

  useHotKey(params)

  return [ isOpen, { closeMenu, openMenu } ] as const
}

export default useHotMenu
