import compact from 'lodash/compact'
import { object, ref, string } from 'yup'

import BaseModel from 'models/BaseModel'
import type { Account as AccountType, AccountGender } from 'generated/schema'

enum Status {
  NOT_INVITED = 'NOT_INVITED',
  INVITED = 'INVITED',
  ONBOARDING = 'ONBOARDING',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

enum Kind {
  MEMBER = 'MEMBER',
  SERVICE = 'SERVICE',
  USER = 'USER',
  VISITOR = 'VISITOR'
}

enum MfaVerificationMethod {
  BACKUP = 'BACKUP',
  SOFTWARE = 'SOFTWARE'
}

type GenderOption = {
  label: string,
  value: AccountGender
}

const MIN_PASSWORD_LENGTH = 8
const MAX_PASSWORD_LENGTH = 72
const MIN_PASSWORD_STRENGTH = 2
const MFA_BACKUP_CODE_LENGTH = 16
const MFA_CODE_LENGTH = 6

const KIND_OPTIONS = [
  { label: 'Member', value: Kind.MEMBER },
  { label: 'Service', value: Kind.SERVICE },
  { label: 'User', value: Kind.USER },
  { label: 'Visitor', value: Kind.VISITOR }
]

const GENDER_OPTIONS: GenderOption[] = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
  { label: 'Other', value: 'OTHER' }
]

const STATUS_LABELS_MAP = {
  [Status.NOT_INVITED]: 'NOT INVITED',
  [Status.INVITED]: 'INVITED',
  [Status.ONBOARDING]: 'ONBOARDING',
  [Status.ENABLED]: 'ENABLED',
  [Status.DISABLED]: 'DISABLED'
} as const

const SERVICE_ACCOUNTS_STATUS_LABELS_MAP = {
  [Status.NOT_INVITED]: 'ENABLED',
  [Status.INVITED]: 'ENABLED',
  [Status.ONBOARDING]: 'ENABLED',
  [Status.ENABLED]: 'ENABLED',
  [Status.DISABLED]: 'DISABLED'
} as const

const STATUS_VARIANTS_MAP = {
  [Status.NOT_INVITED]: 'light',
  [Status.INVITED]: 'primary',
  [Status.ONBOARDING]: 'warning',
  [Status.DISABLED]: 'negative',
  [Status.ENABLED]: 'positive'
} as const

const SERVICE_ACCOUNTS_STATUS_VARIANTS_MAP = {
  [Status.NOT_INVITED]: 'positive',
  [Status.INVITED]: 'positive',
  [Status.ONBOARDING]: 'positive',
  [Status.DISABLED]: 'negative',
  [Status.ENABLED]: 'positive'
} as const

const ANONYMOUS_NAME_PLACEHOLDER = 'Anonymous'
const VISITOR_NAME_PLACEHOLDER = 'Visitor'
const REGULAR_ACCOUNT_NAME_PLACEHOLDER = 'Regular'
const WORKSPACE_ACCOUNT_NAME_PLACEHOLDER = 'Workspace'
const ACCOUNT_NAME_PLACEHOLDER = 'Account'

class Account extends BaseModel {
  static isAnonymous({ anonymousUid, uid }: Pick<AccountType, 'anonymousUid' | 'uid'>) {
    return Boolean(!uid && anonymousUid)
  }

  static getAccountType({ anonymousUid, uid }: Pick<AccountType, 'anonymousUid' | 'uid'>) {
    if (!anonymousUid && !uid) return WORKSPACE_ACCOUNT_NAME_PLACEHOLDER
    if (uid) return REGULAR_ACCOUNT_NAME_PLACEHOLDER
    if (anonymousUid) return ANONYMOUS_NAME_PLACEHOLDER
    return ACCOUNT_NAME_PLACEHOLDER
  }

  static getFullName(account: Pick<AccountType, 'firstName' | 'lastName' | 'name' | 'fullName' | 'anonymousUid' | 'uid' | 'custom'>) {
    if (!account) return ANONYMOUS_NAME_PLACEHOLDER

    const { firstName, lastName, name, fullName, custom } = account
    const renderedName = (
      name
      || fullName
      || [ firstName, lastName ].filter((str) => !!str?.trim()).join(' ')
      || [ custom?.first_name, custom?.last_name ].filter((str) => !!str?.trim()).join(' ')
    )

    if (!renderedName && Account.isAnonymous(account)) {
      return ANONYMOUS_NAME_PLACEHOLDER
    }

    return renderedName
  }

  static getSecondaryDetails(account: Pick<AccountType, 'anonymousUid' | 'uid' | 'custom' | 'email' | 'phone'>) {
    const { email, phone, custom, anonymousUid } = account
    const renderedSecondary = (
      [ email, phone ].filter((str) => !!str?.trim()).join(' | ')
      || [ custom?.email, custom?.phone ].filter((str) => !!str?.trim()).join(' | ')
      || email
      || phone
    )

    if (!renderedSecondary && Account.isAnonymous(account)) {
      return anonymousUid
    }

    return renderedSecondary
  }

  static getFullNameWithContact(account: Pick<AccountType, 'id' | 'firstName' | 'lastName' | 'name' | 'fullName' | 'anonymousUid' | 'uid' | 'email' | 'phone' | 'kind'>) {
    const fullName = Account.getFullName(account)
    const renderedName = fullName !== ANONYMOUS_NAME_PLACEHOLDER ? fullName : undefined

    if (!renderedName) {
      if (account?.kind === Kind.VISITOR) return `${VISITOR_NAME_PLACEHOLDER} (${account.id})`
      if (account?.email || account?.phone) return `${ACCOUNT_NAME_PLACEHOLDER} (${compact([ account.email, account.phone ]).join(', ')})`
      return `${ACCOUNT_NAME_PLACEHOLDER} (${account?.uid})`
    }

    if (!renderedName && Account.isAnonymous(account)) {
      if (account?.email || account?.phone) return `${ANONYMOUS_NAME_PLACEHOLDER} (${compact([ account.email, account.phone ]).join(', ')})`
      return `${ANONYMOUS_NAME_PLACEHOLDER} (${account?.anonymousUid})`
    }

    return renderedName
  }

  static getInitials({ firstName, lastName, email, name, fullName }: Pick<AccountType, 'firstName' | 'lastName' | 'email' | 'fullName' | 'name'>) {
    const result = (() => {
      if (name) return name.trim().charAt(0)
      if (fullName) {
        const fName = fullName.split(' ')
        return fName
          .map((str) => str?.trim().charAt(0).toUpperCase())
          .join('')
      }
      if (firstName && lastName) {
        return [ firstName, lastName ]
          .map((str) => str?.trim().charAt(0).toUpperCase())
          .join('')
      }
      if (email) return email.charAt(0)
      return ''
    })()

    return result.toUpperCase()
  }

  static getLoginAccessButtonProps = (account: Partial<AccountType>) => {
    if (!account.email) return { label: 'Invite Account', variant: 'filled', mode: 'distinct', disabled: true }
    if (account.status === 'NOT_INVITED') return { label: 'Invite Account', variant: 'filled', mode: 'distinct' }
    if (account.status === 'INVITED' || account.status === 'ONBOARDING') return { label: 'Revoke Invitation', variant: 'outline', mode: 'subtle' }
    if (account.status === 'ENABLED') return { label: 'Disable Account', variant: 'outline', mode: 'subtle' }
    if (account.status === 'DISABLED') return { label: 'Enable Account', variant: 'filled', mode: 'subtle' }
    return { label: '' }
  }

  static getLoginAccessText = (account: Partial<AccountType>) => {
    if (!account.email) return 'Email address is required'
    if (account.status === 'NOT_INVITED') return 'Account is not invited'
    if (account.status === 'INVITED') return 'Account is invited'
    if (account.status === 'ONBOARDING') return 'Account is onboarding'
    if (account.status === 'ENABLED') return 'Account is enabled'
    if (account.status === 'DISABLED') return 'Account is disabled'
    return ''
  }

  static getLoginAccessIcon = (account: Partial<AccountType>) => {
    if (account.status === 'NOT_INVITED') return 'decline'
    if (account.status === 'INVITED') return 'tick-circle'
    if (account.status === 'ONBOARDING') return 'tick-circle'
    if (account.status === 'ENABLED') return 'tick-circle'
    if (account.status === 'DISABLED') return 'decline'
    return 'tick-circle'
  }

  static getLoginAccessIconColor = (account: Partial<AccountType>) => {
    if (account.status === 'ONBOARDING') return 'warning'
    if (account.status === 'ENABLED') return 'positive'
    if (account.status === 'DISABLED') return 'negative'
    return 'dark'
  }

  static schema = object({
    email: string().email().required(),
    firstName: string().required(),
    lastName: string().required(),
    gender: string().required(),
    dateOfBirth: string().required(),
    timeZone: string().required(),
    currentPassword: string().required(),
    password: string()
      .required()
      .when('passwordStrength', {
        is: (value) => value < MIN_PASSWORD_STRENGTH,
        then: string().length(MIN_PASSWORD_STRENGTH, 'too weak')
      })
      .min(MIN_PASSWORD_LENGTH)
      .max(MAX_PASSWORD_LENGTH),
    passwordConfirmation: string().oneOf([ ref('password') ], "doesn't match").required(),
    token: string().required(),
    environmentId: string().required()
  })
}

export {
  MFA_BACKUP_CODE_LENGTH,
  MFA_CODE_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  GENDER_OPTIONS,
  KIND_OPTIONS,
  Kind,
  MfaVerificationMethod,
  SERVICE_ACCOUNTS_STATUS_LABELS_MAP,
  SERVICE_ACCOUNTS_STATUS_VARIANTS_MAP,
  STATUS_LABELS_MAP,
  STATUS_VARIANTS_MAP,
  Status
}

export default Account
