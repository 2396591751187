import React from 'react'
import { number, object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import ConditionalField from 'components/form/ConditionalField'
import DefaultValueField from 'components/fieldViews/DefaultValueField'
import Divider from 'components/divider/Divider'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import isIdentifier from 'lib/formValidators/isIdentifier'
import isNullOrUndefined from 'lib/isNullOrUndefined'
import Text from 'components/typography/Text'
import ToggleInput from 'components/inputs/ToggleInput'
import { DEFAULT_MIN_ROWS } from 'components/inputs/TextAreaInput'
import { FieldIdentifier } from 'models/Field'
import { NumberField } from 'components/contentEditors/generic/fields'
import { UniquenessField, RepeatedConfigField, getRepeatedConfigSchema } from 'components/fieldViews/common'
import type { CreateAttributeInput, UpdateAttributeInput } from 'generated/schema'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type FormValues = CreateAttributeInput | UpdateAttributeInput

const Base = ({ disableAutoFocus = false }) => (
  <>
    <FormField autoFocus={!disableAutoFocus} name="name" label="Name" size="small" />
    <FormField name="identifier" label="Identifier" size="small" />
  </>
)

type ConfigurationsProps = {
  fieldPrefix?: string,
  translatableFieldName?: string,
  disableLocalization?: boolean
}

const Configurations = ({ fieldPrefix = 'settings.', disableLocalization = false, translatableFieldName = 'isTranslatable' }: ConfigurationsProps) => (
  <>
    <FormField name={`${fieldPrefix}placeholder`} label="Placeholder" size="small" />
    <FormField name={`${fieldPrefix}helpText`} label="Help Text" size="small" />
    {!disableLocalization && (
      <FormField
        name={translatableFieldName}
        component={ToggleInput}
        label="Enable localization?"
        helpText="Allow the attribute to be have localized values"
        labelPosition="left"
        type="checkbox"
      />
    )}
    <RepeatedConfigField fieldPrefix={fieldPrefix} />
    <FormField
      name={`${fieldPrefix}disableEmojis`}
      component={ToggleInput}
      label="Use emojis?"
      helpText="Allow the attribute to use emojis"
      labelPosition="left"
      type="checkbox"
    />
    <FormField
      name={`${fieldPrefix}multiline`}
      component={ToggleInput}
      label="Allow multiple lines?"
      helpText="Allow the attribute to be a multi-line input"
      labelPosition="left"
      type="checkbox"
    />
    <ConditionalField when={`${fieldPrefix}multiline`} is>
      <FormField
        name={`${fieldPrefix}multilineAutogrow`}
        component={ToggleInput}
        label="Allow auto-grow?"
        helpText="Auto-grow to fit input text?"
        labelPosition="left"
        type="checkbox"
      />
      <NumberField
        name={`${fieldPrefix}multilineVisibleRows`}
        label="Number of visible lines"
        size="small"
        defaultValue={DEFAULT_MIN_ROWS}
      />
      <Divider variant="ruler" />
    </ConditionalField>
  </>
)

type SettingsProps = {
  fieldSettingsPrefix?: string
}

const Settings = ({ fieldSettingsPrefix = 'fieldTypeSettings.' }: SettingsProps) => (
  <>
    <FormField
      name={`${fieldSettingsPrefix}is_multiline`}
      component={ToggleInput}
      label="Allow multiple lines?"
      type="checkbox"
    />
    <ConditionalField when={`${fieldSettingsPrefix}is_multiline`} is>
      <Grid gap={16}>
        <NumberField name={`${fieldSettingsPrefix}min_height`} label="Minimum Height" size="small" appendText="rows" type="number" />
        <FormField
          name={`${fieldSettingsPrefix}is_autogrow`}
          component={ToggleInput}
          label="Auto-grow to fit text?"
          type="checkbox"
        />
        <ConditionalField when={`${fieldSettingsPrefix}is_autogrow`} is>
          <NumberField name={`${fieldSettingsPrefix}max_height`} label="Maximum Height" size="small" appendText="rows" type="number" />
        </ConditionalField>
      </Grid>
    </ConditionalField>
    <FormField name={`${fieldSettingsPrefix}label`} label="Label" size="small" />
    <FormField name={`${fieldSettingsPrefix}placeholder`} label="Placeholder" size="small" />
    <FormField name={`${fieldSettingsPrefix}help_text`} label="Help Text" size="small" />
  </>
)

const Validations = ({ fieldPrefix = 'settings.' }) => (
  <>
    <Divider />
    <Grid alignItems="center" gap={20} columns={1}>
      <Text fontSize={16} fontWeight="bold">Validations</Text>
      <FormField
        name={`${fieldPrefix}checkRequired`}
        component={ToggleInput}
        label="Required value?"
        labelPosition="left"
        type="checkbox"
      />
      <UniquenessField fieldPrefix={fieldPrefix} />
      <FormField
        name={`${fieldPrefix}checkLength`}
        component={ToggleInput}
        label="Limit character count?"
        labelPosition="left"
        type="checkbox"
      />
      <ConditionalField when="checkLength" is>
        <Grid alignItems="center" gap={20} columns={2}>
          <NumberField
            name={`${fieldPrefix}lengthMinimum`}
            label="Minimum"
            size="small"
          />
          <NumberField
            name={`${fieldPrefix}lengthMaximum`}
            label="Maximum"
            size="small"
          />
          <NumberField
            name={`${fieldPrefix}lengthEqualTo`}
            label="Equal to"
            size="small"
          />
        </Grid>
      </ConditionalField>
    </Grid>
  </>
)

type PreviewProps = {
  fieldPrefix?: string,
  repeatableFieldName?: string
}

const Preview = ({ fieldPrefix = '' }: PreviewProps) => (
  <>
    {/* <Divider /> */}
    <Grid alignItems="center" gap={20} columns={1}>
      <Text fontSize={16} fontWeight="bold">Preview</Text>
      <DefaultValueField
        identifier={FieldIdentifier.TEXT}
        fieldPrefix={fieldPrefix}
      />
    </Grid>
  </>
)

const TextFieldView = () => (
  <Grid columns={1} gap={24}>
    <Grid alignItems="center" gap={20} columns={2}>
      <Base />
      <Configurations />
    </Grid>
    <Preview />
    <Validations />
  </Grid>
)

TextFieldView.validate = (values: fieldProps<'text'>) => {
  const settings = values.settings || {}

  let lengthMinimumSchema = number()
  let lengthMaximumSchema = number()
  let lengthEqualToSchema = number()
  let multilineVisibleRowsSchema = number()

  if (settings.checkLength) {
    if (isNullOrUndefined(settings.lengthMinimum) && isNullOrUndefined(settings.lengthMaximum)) {
      lengthEqualToSchema = lengthEqualToSchema.nonNegative().integer().required()
    }
    if (isNullOrUndefined(settings.lengthMaximum) && isNullOrUndefined(settings.lengthEqualTo)) {
      lengthMinimumSchema = lengthMinimumSchema.nonNegative().integer().required()
    }
    if (isNullOrUndefined(settings.lengthEqualTo) && isNullOrUndefined(settings.lengthMinimum)) {
      lengthMaximumSchema = lengthMaximumSchema.nonNegative().integer().required()
    }
  }

  if (!isNullOrUndefined(settings.lengthMinimum) && !isNullOrUndefined(settings.lengthMaximum)) {
    lengthMinimumSchema = lengthMinimumSchema.max(settings.lengthMaximum!)
    lengthMaximumSchema = lengthMaximumSchema.min(settings.lengthMinimum!)
  }

  if (settings.multiline) {
    multilineVisibleRowsSchema = multilineVisibleRowsSchema.nonNegative().integer().required()
  }

  const schema = {
    name: string().required(),
    identifier: isIdentifier(),
    settings: object({
      lengthMinimum: lengthMinimumSchema,
      lengthMaximum: lengthMaximumSchema,
      lengthEqualTo: lengthEqualToSchema,
      multilineVisibleRows: multilineVisibleRowsSchema,
      ...getRepeatedConfigSchema(values)
    })
  }

  return BaseModel.validateSchema(values, schema)
}

export default Object.assign(TextFieldView, {
  Base,
  Configurations,
  Preview,
  Settings,
  Validations
})
