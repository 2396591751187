import React, { useContext } from 'react'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Form } from 'react-final-form'
import type { StripeError } from '@stripe/stripe-js'

import * as mixins from 'styles/mixins'
import Box from 'components/layout/Box'
import Button from 'components/buttons/Button'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import GlobalContext from 'components/contexts/GlobalContext'
import Loader from 'components/loaders/Loader'
import pascalCase from 'lib/pascalCase'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useMounted from 'hooks/useMounted'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { css } from 'styles/stitches'
import { INPUT_BORDER_WIDTH, INPUT_FONT_SIZE } from 'components/inputs/TextInput'
import { useCreateWorkspacePaymentMethodMutation, usePrepareWorkspacePaymentMethodMutation, WorkspaceContextDocument, WorkspacePaymentMethodsListDocument } from 'generated/schema'
import type { ViewProps } from 'components/views'

const classes = {
  input: css({
    ...mixins.transition('fluid'),

    backgroundColor: 'light100',
    borderColor: 'dark100',
    borderWidth: INPUT_BORDER_WIDTH,
    borderStyle: 'solid',
    fontFamily: 'normal',
    fontSize: INPUT_FONT_SIZE,
    fontWeight: 'regular',
    height: 60,
    lineHeight: 'normal',
    padding: 20,
    width: '100%',

    '&:hover': {
      borderColor: 'dark300'
    },

    '&:focus': {
      borderColor: 'dark300'
    }
  })
}

function AddPaymentMethodView({
  closeView,
  onRequestClose,
  isOpen,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<{}>) {
  const mounted = useMounted(isOpen)
  const stripe = useStripe()
  const elements = useElements()
  const { openFailureAlert } = useContext(GlobalContext)!
  const title = 'Add Payment Method'

  const [
    createPaymentMethod
  ] = useCreateWorkspacePaymentMethodMutation({
    onCompleted: () => {
      setLoading(false)
      onRequestClose()
    },
    onError: () => {
      setLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [ WorkspacePaymentMethodsListDocument, WorkspaceContextDocument ]
  })

  const [
    preparePaymentMethod,
    {
      data,
      loading: preparing,
      error
    }
  ] = usePrepareWorkspacePaymentMethodMutation({
    onCompleted: () => {
      setLoading(false)
    },
    onError: () => {
      setLoading(false)
    }
  })

  const [ loading, setLoading ] = React.useState<boolean>(preparing || true)

  const handleCreatePaymentMethod = useSubmitHandler(createPaymentMethod, {
    update: {
      strategy: 'APPEND',
      query: WorkspacePaymentMethodsListDocument,
      queryVariables: {},
      dataKey: 'workspacePaymentMethodsList',
      mutation: 'createWorkspacePaymentMethod'
    },
    successAlert: {
      title: 'Payment Method Added',
      message: 'Your payment method has been added.'
    }
  })

  const showFailureAlert = (error: StripeError) => {
    openFailureAlert({ title: pascalCase(error.code || 'Failed to add Payment Method'), message: error.message })
    setLoading(false)
  }

  const handleSubmit = () => {
    setLoading(true)

    if (!stripe || !elements) {
      return
    }

    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!
    }).then((res) => {
      if (!res.error) {
        stripe.confirmCardSetup(data!.prepareWorkspacePaymentMethod.token, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!
          }
        }).then((res) => {
          if (!res.error) {
            handleCreatePaymentMethod({
              token: res.setupIntent?.id
            })
          } else showFailureAlert(res.error)
        }).catch((error) => {
          showFailureAlert(error)
        })
      } else showFailureAlert(res.error)
    }).catch((error) => {
      showFailureAlert(error)
    })
  }

  useComponentDidMount(() => {
    if (mounted) preparePaymentMethod()
  })

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} isOpen={isOpen} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Loader data={data} loading={loading} error={error}>
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Flex gap={16} direction="column">
                      <Flex as="label" direction="column" gap={10} grow={1}>
                        <FieldLabel>Card Details</FieldLabel>
                        <CardNumberElement className={classes.input} />
                      </Flex>
                      <Flex gap={16}>
                        <Flex as="label" direction="column" gap={10} grow={1}>
                          <FieldLabel>Expiry</FieldLabel>
                          <CardExpiryElement className={classes.input} />
                        </Flex>
                        <Flex as="label" direction="column" gap={10} grow={1}>
                          <FieldLabel>CVC</FieldLabel>
                          <CardCvcElement className={classes.input} />
                        </Flex>
                      </Flex>
                      <Box css={{ height: 8 }} />
                      <Flex>
                        <Button type="submit" label="Save Changes" disabled={loading} />
                      </Flex>
                    </Flex>
                  </form>
                )}
              />
            </Loader>
          </Body>
        </>
      )}
    </View>
  )
}

export default AddPaymentMethodView
