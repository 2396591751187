import React, { createContext, useEffect, useState } from 'react'
import { Form, FormProps } from 'react-final-form'
import type { AnyObject, FormApi, ValidationErrors } from 'final-form'

import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { SidePaneBody, SidePaneFooter, SidePaneHeader, SidePaneSubHeader } from 'components/sidePane'

type SidePaneWizardStep = {
  heading: string,
  subheading? : string,
  disabled?: boolean
}

type SidePaneWizardProps = FormProps & React.PropsWithChildren<{
  steps: Array<SidePaneWizardStep>,
  onRequestClose: () => void
}>

type SidePaneWizardStepProps = {
  children: JSX.Element,
  validate?: (values: AnyObject) => ValidationErrors | Promise<ValidationErrors> | undefined
}

const SidePaneWizardContext = createContext({} as {
  setCurrentStep: Function
})

function SidePaneWizard({
  children, initialValues = {}, onSubmit, onRequestClose, steps, ...rest
}: SidePaneWizardProps) {
  const [ currentStep, setCurrentStep ] = useState(0)
  const [ formValues, setFormValues ] = useState(initialValues)

  useEffect(() => {
    setFormValues(initialValues)
  }, [ initialValues ])

  const nextStep = (values: AnyObject) => {
    setCurrentStep(currentStep + 1)
    setFormValues(formValues.concat(values))
  }
  const prevStep = () => setCurrentStep(currentStep - 1)

  const isFirstStep = currentStep === 0
  const isLastStep = steps.length - 1 === currentStep
  const currentView = React.Children.toArray(children)[currentStep] as JSX.Element

  const validate = (values: AnyObject) => (
    currentView.props.validate ? currentView.props.validate(values) : {}
  )

  const onFormSubmit = (values: AnyObject, formApi: FormApi<AnyObject>) => (
    isLastStep ? onSubmit(values, formApi) : nextStep(values)
  )

  const { heading, subheading } = steps[currentStep]

  return (
    <Form
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validate={validate}
      render={({ handleSubmit, submitting }) => {
        const submitButtonProps = (() => {
          const baseProps = {
            disabled: steps[currentStep].disabled,
            onClick: handleSubmit,
            size: 'small',
            type: 'submit'
          }

          if (isLastStep) {
            return { ...baseProps, disabled: submitting, label: 'Done', size: 'small' }
          }

          return { ...baseProps, icon: 'arrow-right' }
        })()

        return (
          <SidePaneWizardContext.Provider value={{ setCurrentStep }}>
            <SidePaneHeader
              onCloseClick={onRequestClose}
              title={heading}
            />
            <SidePaneSubHeader>
              <Text fontSize={14} fontWeight="bold">
                Step {currentStep + 1}: {subheading || heading}
              </Text>
            </SidePaneSubHeader>
            <SidePaneBody>
              <form onSubmit={handleSubmit}>
                {currentView}
              </form>
            </SidePaneBody>
            <SidePaneFooter>
              <Flex alignItems="center" grow={1} justifyContent="space-between">
                <Chip icon="arrow-right" iconPlacement="right" label={`Step ${currentStep + 1}`} variant="light" />
                <Flex gap={10}>
                  <Button disabled={isFirstStep} icon="arrow-left" onClick={prevStep} size="small" />
                  <Button {...submitButtonProps} />
                </Flex>
              </Flex>
            </SidePaneFooter>
          </SidePaneWizardContext.Provider>
        )
      }}
      {...rest}
    />
  )
}

SidePaneWizard.Step = ({ children }: SidePaneWizardStepProps) => (children)

export default SidePaneWizard

export { SidePaneWizardContext }
