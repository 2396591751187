import React from 'react'
import { Field, Form } from 'react-final-form'

import Account, { GENDER_OPTIONS } from 'models/Account'
import Button from 'components/buttons/Button'
import DateInput from 'components/inputs/DateInput'
import Flex from 'components/layout/Flex'
import SelectInput from 'components/inputs/SelectInput'
import TextInput from 'components/inputs/TextInput'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { UpdateAccountInput } from 'generated/schema'

type AccountProfileFormProps = FormPropsWithId<UpdateAccountInput>

export default function AccountProfileForm(props: AccountProfileFormProps) {
  return (
    <Form
      validate={(values) => Account.validate(values, [ 'firstName', 'lastName' ])}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap={36} wrap="wrap">
            <Flex gap={32}>
              <Field
                autoComplete="on"
                name="firstName"
                component={TextInput}
                placeholder="First Name"
                label="Name"
              />
              <Field
                autoComplete="on"
                name="lastName"
                component={TextInput}
                placeholder="Last Name"
                label="&nbsp;"
              />
            </Flex>
            <Flex gap={32}>
              <Field
                name="gender"
                component={SelectInput}
                options={GENDER_OPTIONS}
                placeholder="Select Gender"
                label="Gender"
              />
              <Field
                name="dateOfBirth"
                component={DateInput}
                label="Date of Birth"
              />
            </Flex>
            <Flex justifyContent="flex-end">
              <Button type="submit" disabled={pristine || submitting} label="Save changes" />
            </Flex>
          </Flex>
        </form>
      )}
      {...props}
    />
  )
}
