import copy from 'copy-text-to-clipboard'
import React, { useState } from 'react'

import Button from 'components/buttons/Button'
import IconButton from 'components/buttons/IconButton'
import type { IconProps } from 'components/icons/Icon'

type CopyToClipboardProps = {
  buttonSize?: string,
  description?: string,
  icon?: IconProps['name'],
  iconSize?: IconProps['size'],
  label?: string,
  onComplete?: () => void,
  textToCopy: string,
  style?: React.CSSProperties
}

function CopyToClipboard({
  buttonSize = 'normal',
  description = 'Copy to clipboard',
  icon = 'copy',
  iconSize = 12,
  label = '',
  onComplete,
  textToCopy,
  ...others
}: CopyToClipboardProps) {
  const [ isCopied, setIsCopied ] = useState(false)

  const handleClick = () => {
    copy(textToCopy)
    setIsCopied(true)

    onComplete && onComplete()
  }

  const handleMouseLeave = () => setIsCopied(false)

  const tooltip = isCopied ? 'Copied!' : description

  return (
    <>
      {label.length ? (
        <Button
          icon={icon}
          label={isCopied ? 'Copied!' : label}
          mode="subtle"
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          variant="outline"
          size={buttonSize}
          {...others}
        />
      ) : (
        <IconButton
          description={tooltip}
          name={icon}
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          size={iconSize}
          variant="dark"
          {...others}
        />
      )}
    </>
  )
}

export default CopyToClipboard
