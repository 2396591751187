import dayjs from 'dayjs'
import get from 'lodash/get'
import React from 'react'
import utc from 'dayjs/plugin/utc'

import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

dayjs.extend(utc)

const StyledText = styled(Text, {
  color: 'dark800',
  fontWeight: 600
})

// Please fix the logic once backed supports dueData or billingDate
function InvoiceNameRenderer({ rowData }: RendererOptions) {
  const createdAt = dayjs(get(rowData, 'createdAt')).utc()
  // invoice name is createdAt month - 1 and should be in format of January 2021
  const invoiceName = createdAt.subtract(1, 'month').format('MMMM YYYY')

  return <StyledText title={invoiceName}>{invoiceName}</StyledText>
}

export default InvoiceNameRenderer
