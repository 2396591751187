import React from 'react'
import { Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import SelectInput from 'components/inputs/SelectInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import Contact, { KIND_OPTIONS, STATUS_OPTIONS } from 'models/Contact'
import { ContactsListDocument, ContactsAggregateDocument, useCreateContactMutation, useUpdateContactMutation } from 'generated/schema'
import type { ContactsListQueryVariables, CreateContactInput, UpdateContactInput } from 'generated/schema'
import type { ViewProps, ViewStyleComponentRenderProps } from 'components/views'

type FormValues = CreateContactInput | UpdateContactInput

type Params = {
  initialValues?: any,
  queryVariables: ContactsListQueryVariables
}

function AddContactView({
  onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<Params>) {
  const { initialValues, queryVariables } = params
  const isUpdating = initialValues?.id

  const [ createContact ] = useCreateContactMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: ContactsListDocument, variables: queryVariables },
      {
        query: ContactsAggregateDocument, variables: { filter: queryVariables.filter }
      }
    ]
  })

  const handleCreateContact = useSubmitHandler(createContact, {
    successAlert: { message: 'Contact added.' }
  })

  const [ updateContact ] = useUpdateContactMutation({ onCompleted: onRequestClose })
  const handleUpdateContact = useSubmitHandler(updateContact, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateContact',
      typename: 'Contact',
      override: (values: UpdateContactInput) => ({
        ...initialValues,
        ...values
      })
    },
    successAlert: { message: 'Contact updated.' }
  })

  const handleFormSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if ('id' in values) {
      return handleUpdateContact(values, form as FormApi<UpdateContactInput>)
    }
    return handleCreateContact(values)
  }

  const title = isUpdating ? 'Edit Contact' : 'New Contact'

  const ContactForm = ({
    Header, Body, Footer
  }: ViewStyleComponentRenderProps) => (
    <>
      <Header
        title={title}
        onCloseClick={onRequestClose}
      />
      <Form
        validate={(values) => Contact.validate(values, [ 'name', 'value', 'kind', 'status' ])}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <Body>
              <Flex as="form" onSubmit={handleSubmit} direction="column" gap={24}>
                <FormField name="accountId" component="input" type="hidden" />

                <Flex direction="column" gap={20}>
                  <FormField
                    label="Name"
                    name="name"
                    type="text"
                    size="small"
                  />
                  <FormField
                    component={SelectInput}
                    options={KIND_OPTIONS}
                    label="Contact Type"
                    name="kind"
                    size="small"
                  />
                  <FormField
                    component={SelectInput}
                    options={STATUS_OPTIONS}
                    label="Status"
                    name="status"
                    size="small"
                  />
                  <FormField
                    label="Value"
                    name="value"
                    type="text"
                    size="small"
                  />
                </Flex>
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </Body>
            <Footer>
              <Flex alignItems="center" direction="row-reverse" grow={1} justifyContent="space-between">
                <Flex gap={14}>
                  <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} size="small" />
                </Flex>
              </Flex>
            </Footer>
          </>
        )}
      />
    </>
  )

  return (
    <View contentLabel="Add Contact" onRequestClose={onRequestClose} {...other}>
      {ContactForm}
    </View>
  )
}

AddContactView.defaultStyle = 'PANEL' as const

export default AddContactView
