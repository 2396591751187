import React from 'react'
import { plural } from 'pluralize'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import type { ViewProps } from 'components/views'

type ConfirmationViewProps = {
  action: string,
  isPlural?: boolean,
  recordType?: string,
  recordDescription?: string,
  onConfirmClick: () => Promise<any>
}

function ConfirmationView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<ConfirmationViewProps>) {
  const { action, isPlural = false, onConfirmClick, recordDescription, recordType = 'record' } = params
  const makePlural = (word: string) => (isPlural ? plural(word) : word)

  const title = 'Are you sure?'

  const handleConfirmClick = () => onConfirmClick()
    .then(onRequestClose)

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Flex direction="column" gap={36}>
              <Text>
                <>
                  By clicking on Confirm, you will {action} {makePlural('this')} {makePlural(recordType)}
                </>
                {recordDescription && <>:&nbsp;<b>{recordDescription}</b></>}
                <>.</>
              </Text>
            </Flex>
          </Body>
          <Footer>
            <Button label="Cancel" mode="subtle" onClick={onRequestClose} variant="outline" />
            <Button
              label="Confirm"
              mode="subtle"
              onClick={handleConfirmClick}
            />
          </Footer>
        </>
      )}
    </View>
  )
}

export default ConfirmationView

export type { ConfirmationViewProps }
