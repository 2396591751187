import React from 'react'

import Flex from 'components/layout/Flex'
import { SIDEBAR_BORDER_RADIUS } from 'components/sidebar/constants'
import { styled } from 'styles/stitches'

const SIDEBAR_FOOTER_MIN_HEIGHT = 60

const StyledSidebarFooter = styled(Flex, {
  borderBottomRightRadius: SIDEBAR_BORDER_RADIUS,
  overflowX: 'hidden'
})

function SidebarFooter({ children }: React.PropsWithChildren<{}>) {
  return (
    <StyledSidebarFooter
      direction="column"
      justifyContent="center"
      shrink={0}
    >
      {children}
    </StyledSidebarFooter>
  )
}

export default SidebarFooter

export { SIDEBAR_FOOTER_MIN_HEIGHT }
