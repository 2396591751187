import React from 'react'
import type { FieldRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import { styled } from 'styles/stitches'

type RatingInputOwnProps = {
  disabled?: boolean,
  helpText?: string,
  isTranslatable?: boolean,
  length?: number
}

type RatingInputProps = FieldRenderProps<number, HTMLInputElement> & RatingInputOwnProps

const DEFAULT_LENGTH = 5
const STAR_PADDING_X = 4

const StyledStar = styled('div', {
  ...mixins.transition('fastIn', 'color'),

  color: 'dark100',
  paddingY: 0,
  paddingX: STAR_PADDING_X,

  '&:first-of-type': {
    paddingLeft: 0
  },
  variants: {
    filled: {
      true: {
        color: 'accent400'
      }
    },
    disabled: {
      true: {
        opacity: 0.4
      }
    }
  }
})

const StyledRatingContainer = styled(Flex, {
  position: 'relative',
  variants: {
    enabled: {
      true: {
        cursor: 'pointer',

        [`&:hover > ${StyledStar}`]: {
          color: 'accent400'
        },

        [`& > ${StyledStar}:hover ~ ${StyledStar}`]: {
          color: 'dark100'
        }
      }
    }
  }
})

const StyledInput = styled('input', {
  hidden: true
})

function RatingInput({
  disabled,
  grow,
  helpText,
  input,
  isTranslatable,
  checkRequired = false,
  label,
  length = DEFAULT_LENGTH,
  meta,
  ...other
}: RatingInputProps) {
  const inputValue = input.value

  return (
    <Flex direction="column" gap={10}>
      {label && (
        <FieldLabel isTranslatable={isTranslatable}>
          {label}{checkRequired && <span> *</span>}
        </FieldLabel>
      )}
      <StyledRatingContainer
        alignItems="center"
        as="label"
        enabled={!disabled}
      >
        <StyledInput
          disabled={disabled}
          max={length}
          min={1}
          type="number"
          {...input}
          {...other}
        />
        {Array.from(Array(length).keys(), (i) => i + 1).map((index) => (
          <StyledStar
            filled={Boolean(inputValue && inputValue >= index)}
            disabled={disabled}
            key={index}
            role="button"
            tabIndex={0}
            onKeyUp={() => !disabled && input.onChange(index)}
            onClick={() => !disabled && input.onChange(index)}
          >
            <Icon name="star-filled" />
          </StyledStar>
        ))}
        <FieldError error={FieldError.getError(meta)} />
      </StyledRatingContainer>
      {helpText && <InputHelpText helpText={helpText} />}
    </Flex>
  )
}

export type {
  RatingInputProps,
  RatingInputOwnProps
}

export default RatingInput
