import React from 'react'
import type { PropsWithChildren } from 'react'

import * as constants from 'components/modal/constants'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

type ModalFooterProps = PropsWithChildren<{
  wide?: boolean
}>

const FOOTER_MARGIN_Y = 24

const StyledFooter = styled(Flex, {
  marginTop: FOOTER_MARGIN_Y,
  paddingBottom: 20,
  paddingX: constants.LARGE_HORIZONTAL_PADDING,

  variants: {
    wide: {
      true: {
        paddingX: constants.SMALL_HORIZONTAL_PADDING
      },
      false: {
        paddingX: constants.LARGE_HORIZONTAL_PADDING
      }
    }
  }
})

function ModalFooter({ children, wide = false }: ModalFooterProps) {
  return (
    <StyledFooter
      alignItems="center"
      grow={1}
      justifyContent="space-between"
      wide={wide}
    >
      {children}
    </StyledFooter>
  )
}

ModalFooter.Left = (props: ModalFooterProps) => (
  <StyledFooter {...props} />
)

ModalFooter.Right = (props: ModalFooterProps) => (
  <StyledFooter {...props} />
)

export default ModalFooter
