import React, { useState } from 'react'
import { Form, FormProps } from 'react-final-form'

import AuthModel from 'models/AuthModel'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import { MFA_CODE_LENGTH, MFA_BACKUP_CODE_LENGTH, MfaVerificationMethod } from 'models/Account'

type VerifyMfaFormProps = FormProps<any>

function VerifyMfaForm(props: VerifyMfaFormProps) {
  const [ method, setMethod ] = useState(MfaVerificationMethod.SOFTWARE)

  const toggleMethod = () => {
    if (method === MfaVerificationMethod.SOFTWARE) setMethod(MfaVerificationMethod.BACKUP)
    else setMethod(MfaVerificationMethod.SOFTWARE)
  }

  return (
    <Form
      subscription={{
        submitting: true,
        pristine: true,
        values: true
      }}
      validate={(values) => AuthModel.validate(values, [ 'code' ])}
      initialValues={{ method }}
      render={({ handleSubmit, form, submitting }) => {
        const isSoftwareMethod = method === MfaVerificationMethod.SOFTWARE

        return (
          <Flex as="form" direction="column" gap={48} onSubmit={handleSubmit} grow={1}>
            <Flex direction="column" gap={24}>
              <Text fontSize={18} fontWeight="bold">
                {isSoftwareMethod ? 'Enter Verification Code' : 'Enter Backup Code'}
              </Text>
              <Text>
                {isSoftwareMethod
                  ? `Check your Authenticator App for a ${MFA_CODE_LENGTH}-digit code to confirm that it's really you.`
                  : 'You can use one of your backup codes if you don\'t have access to your Authenticator App.'}
              </Text>
              <FormField
                alwaysDirty
                component="input"
                name="method"
                type="hidden"
              />
              <FormField
                autoFocus
                component={TextInput}
                maxLength={isSoftwareMethod ? MFA_CODE_LENGTH : MFA_BACKUP_CODE_LENGTH}
                name="code"
                placeholder={isSoftwareMethod ? 'XXXXXX' : 'Backup Code'}
                type="text"
              />
              <Flex direction="column" gap={16} alignItems="flex-end">
                <TextLink
                  as="button"
                  type="button"
                  fontSize={14}
                  onClick={() => {
                    toggleMethod()
                    form.reset()
                  }}
                >
                  {isSoftwareMethod ? 'Use a backup code' : 'Use code from Authenticator App'}
                </TextLink>
                <Button
                  label="Verify"
                  onClick={handleSubmit}
                  disabled={submitting}
                />
              </Flex>
            </Flex>
          </Flex>
        )
      }}
      {...props}
    />
  )
}

export default VerifyMfaForm
