import React from 'react'
import type { ReactNode } from 'react'

import FormField from 'components/form/FormField'

type ConditionalFieldProps = {
  children: ReactNode,
  when: string,
  invert?: boolean
} & ({ is: any, in?: undefined } | { is?: undefined, in: any[] })

const ConditionalField = (
  { children, is, in: inList, when, invert = false }: ConditionalFieldProps
) => (
  <FormField name={when} subscription={{ value: true }}>
    {({ input }) => {
      const isBoolean = typeof is === 'boolean'

      if (isBoolean) {
        return is === Boolean(input.value) && !invert ? children : null
      }

      if (inList?.length) {
        return !invert && inList.includes(input.value) ? children : null
      }

      return (!invert && input.value === is) ? children : null
    }}
  </FormField>
)

export default ConditionalField
