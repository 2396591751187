import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

class Parameter extends BaseModel {
  static identifierRegex = /^(\d|\w|-|\.)+$/

  static schema = object({
    identifier: isIdentifier(Parameter.identifierRegex),
    name: string().required()
  })
}

export default Parameter
