import React, { useContext, useState } from 'react'

import AddIntegrationView from 'components/views/AddIntegrationView'
import Button from 'components/buttons/Button'
import DataTableBlock from 'components/blocks/DataTableBlock'
import InstallAppView from 'components/views/InstallAppView'
import IntegrationNameRenderer from 'components/renderers/IntegrationNameRenderer'
import IntegrationView from 'components/views/IntegrationView'
import InternalContext from 'components/contexts/InternalContext'
import SearchBar from 'components/searchbar/SearchBar'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { useArchiveInstallationMutation, InstallationsListDocument, useUnarchiveInstallationMutation, InstallationsAggregateDocument, InstallationsListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { Installation, InstallationsListQueryVariables } from 'generated/schema'

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [ { name: 'asc' } ]

function ThirdPartyAppsList() {
  const { currentDashboard } = useContext(InternalContext) || {}
  const { openView } = useViewDispatch()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const [ order, setOrder ] = useState(DEFAULT_ORDER)
  const [ filters, setFilters ] = useState<FilterType>({})
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      archivedAt: 'null',
      appKind: { eq: 'INTEGRATION' },
      ...filters
    },
    limit: pageSize,
    page,
    order
  }

  const [ {
    data: { installationsList = [], installationsAggregate = {} } = {},
    loading,
    error,
    variables: installationListQueryVariables
  }, handleChange ] = useSearch<InstallationsListQuery, InstallationsListQueryVariables>({
    query: InstallationsListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ 'name', 'identifier' ]
  })

  const [ archiveInstallation ] = useArchiveInstallationMutation({
    refetchQueries: [
      { query: InstallationsListDocument, variables: installationListQueryVariables },
      {
        query: InstallationsAggregateDocument,
        variables: { filter: installationListQueryVariables?.filter }
      }
    ]
  })

  const [ unarchiveInstallation ] = useUnarchiveInstallationMutation({
    refetchQueries: [
      { query: InstallationsListDocument, variables: installationListQueryVariables }
    ]
  })

  const handleArchive = useSubmitHandler(archiveInstallation)
  const handleUnarchive = useSubmitHandler(unarchiveInstallation)

  const openInstallationForm = (values: Installation) => {
    openView({
      title: `${values.app.name}`,
      component: InstallAppView,
      params: {
        selectedApp: values.app,
        initialValues: values,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const openAddIntegrationView = () => openView({
    title: 'Add Integration',
    component: AddIntegrationView,
    style: 'PANEL',
    params: {
      dashboardId: currentDashboard?.id,
      queryVariables
    }
  })

  const openConfirmationDialog = (installation: Installation) => {
    confirm({
      action: installation.archivedAt !== null ? 'archive' : 'unarchive',
      onConfirmClick: async () => {
        if (installation.archivedAt !== null) {
          handleArchive({ id: installation.id })
        } else {
          handleUnarchive({ id: installation.id })
        }
      },
      recordType: 'Installation',
      recordDescription: installation.name
    })
  }

  const columns: Column[] = [
    {
      dataKey: 'name',
      fieldType: 'text-field',
      sortable: true,
      title: 'Name',
      style: { width: 200 },
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          as: IntegrationNameRenderer,
          onClick: () => {
            openView({
              title: props.rowData.name || 'App',
              component: IntegrationView,
              style: IntegrationView.defaultStyle,
              params: {
                installationId: props.rowData.id
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      }
    },
    { dataKey: 'identifier', fieldType: 'text-field', sortable: true, title: 'Identifier', style: { width: 200 } },
    { dataKey: 'app.appCategory.name', fieldType: 'text-field', sortable: false, title: 'Category', style: { width: 200 } }
  ]

  const actions = [
    { icon: 'edit', title: 'Edit', onClick: openInstallationForm },
    {
      icon: 'archive',
      title: (installation: Installation) => (installation.archivedAt !== null ? 'Unarchive' : 'Archive'),
      onClick: openConfirmationDialog
    }
  ]

  const batchActions = [
    { icon: 'archive', title: 'Archive', onClick: () => {} }
  ]

  const dataTableSecondaryElement = (
    <Button icon="add-thin" onClick={openAddIntegrationView} size="small" />
  )

  return (
    <DataTableBlock
      actions={actions}
      batchActions={batchActions}
      columns={columns}
      data={installationsList as Installation[]}
      defaultOrder={order}
      error={error}
      loading={loading}
      onChangePage={handlePageChange}
      onChangePageSize={handlePageSizeChange}
      page={page}
      pageSize={pageSize}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      paginationMode="finite"
      primaryElements={<SearchBar placeholder="Search..." onChange={handleChange} />}
      secondaryElements={dataTableSecondaryElement}
      setOrder={setOrder}
      totalRows={installationsAggregate?.count || 0}
      filters={filters}
      setFilters={setFilters}
    />
  )
}

export default ThirdPartyAppsList
