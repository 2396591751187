import { useCallback, useRef, useLayoutEffect } from 'react'
import type { MutableRefObject } from 'react'
import type { OnDragEndResponder } from 'react-beautiful-dnd'

import type { FieldArrayChildrenProps } from 'components/form/FieldArray'

const useReorderFieldArray = (
  fieldsRef: MutableRefObject<FieldArrayChildrenProps<any> | undefined>,
  callback?: OnDragEndResponder
) => {
  const callbackRef = useRef<OnDragEndResponder>()

  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  const onDragEnd: OnDragEndResponder = useCallback((result, provided) => {
    if (
      !fieldsRef.current?.fields
      || !result.destination
      || result.destination.index === result.source.index
    ) { return }

    fieldsRef.current.fields.move(result.source.index, result.destination.index)
    callbackRef.current?.(result, provided)
  }, [ fieldsRef /* fieldsRef is a stable object */ ])

  return onDragEnd
}

export default useReorderFieldArray
