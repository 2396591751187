import { ApolloCache, defaultDataIdFromObject, FetchResult } from '@apollo/client'

import {
  CreateMenuElementMutation,
  DashboardFragmentFragmentDoc as DashboardFragmentDoc,
  DashboardsListQuery,
  DestroyMenuElementMutation,
  MenuElement,
  UpdateMenuElementMutation
} from 'generated/schema'
import type { GroupedMenuElementsMap } from 'lib/generateDashboard'

const DEPENDENT_PROPERTIES: Array<keyof Pick<MenuElement, 'placement'>> = [ 'placement' ]

export default (mode: 'CREATE' | 'DESTROY' | 'UPDATE', parentToMenuElementMap: GroupedMenuElementsMap, dashboardId: string) => {
  function recursiveUpdate<T extends DashboardsListQuery['dashboardsList'][0]['menuElements'][0]>(elements: readonly T[], element: UpdateMenuElementMutation['updateMenuElement']): readonly T[] {
    const children = parentToMenuElementMap[element.id]
    const index = elements.findIndex(({ id }) => element.id === id)

    const updatedElements: typeof elements = [
      ...elements.slice(0, index),
      { ...elements[index], ...element },
      ...elements.slice(index + 1)
    ]

    if (!children || !children.length) {
      return updatedElements
    }

    return children.reduce((acc, el) => {
      const updatedEl = DEPENDENT_PROPERTIES.reduce((obj, prop) => ({
        ...obj,
        [prop]: element[prop]
      }), el)

      const updated = recursiveUpdate(acc, updatedEl as T)

      return updated
    }, updatedElements)
  }

  function recursiveDelete<T extends DashboardsListQuery['dashboardsList'][0]['menuElements'][0]>(elements: readonly T[], element: DestroyMenuElementMutation['destroyMenuElement']): readonly T[] {
    const children = parentToMenuElementMap[element.id]
    const updatedElements: typeof elements = elements.filter(({ id }) => id !== element.id)

    if (!children || !children.length) {
      return updatedElements
    }

    return children.reduce((acc, el) => {
      const filtered = recursiveDelete(acc, el as T)
      return filtered
    }, updatedElements)
  }

  function recursiveCreate<T extends DashboardsListQuery['dashboardsList'][0]['menuElements'][0]>(elements: readonly T[], element: CreateMenuElementMutation['createMenuElement']): readonly T[] {
    // No recursive condition here
    return elements.concat([ element as T ])
  }

  return (
    cache: ApolloCache<
      CreateMenuElementMutation | DestroyMenuElementMutation | UpdateMenuElementMutation
    >,
    { data }: FetchResult<
      CreateMenuElementMutation | DestroyMenuElementMutation | UpdateMenuElementMutation,
      Record<string, any>,
      Record<string, any>
    >
  ) => {
    const dashboardTypeName = 'Dashboard'

    type DashboardFragment = Pick<DashboardsListQuery['dashboardsList'][0], 'menuElements' | '__typename'>

    const dashboard = cache.readFragment<DashboardFragment, {}>({
      id: defaultDataIdFromObject({ __typename: dashboardTypeName, _id: dashboardId }),
      fragment: DashboardFragmentDoc,
      fragmentName: 'DashboardFragment'
    })

    if (!dashboard || !data) {
      return
    }

    let menuElement = {} as CreateMenuElementMutation['createMenuElement'] | DestroyMenuElementMutation['destroyMenuElement'] | UpdateMenuElementMutation['updateMenuElement']

    if ('createMenuElement' in data) {
      menuElement = data.createMenuElement
    } else if ('destroyMenuElement' in data) {
      menuElement = data.destroyMenuElement
    } else if ('updateMenuElement' in data) {
      menuElement = data.updateMenuElement
    }

    let { menuElements } = dashboard

    if (mode === 'CREATE') {
      menuElements = recursiveCreate(dashboard.menuElements, menuElement as CreateMenuElementMutation['createMenuElement'])
    } else if (mode === 'UPDATE') {
      menuElements = recursiveUpdate(dashboard.menuElements, menuElement as UpdateMenuElementMutation['updateMenuElement'])
    } else if (mode === 'DESTROY') {
      menuElements = recursiveDelete(dashboard.menuElements, menuElement as DestroyMenuElementMutation['destroyMenuElement'])
    }

    cache.writeFragment<DashboardFragment, {}>({
      id: defaultDataIdFromObject({ __typename: dashboardTypeName, _id: dashboardId }),
      fragment: DashboardFragmentDoc,
      fragmentName: 'DashboardFragment',
      data: {
        ...dashboard,
        menuElements
      }
    })
  }
}
