import Box from 'components/layout/Box'
import { generateThemeVariants, generateVariants, styled, theme } from 'styles/stitches'

const contentOptions = [ 'flex-start', 'flex-end', 'center', 'stretch', 'space-between', 'space-around', 'space-evenly' ] as const
const itemsOptions = [ 'flex-start', 'flex-end', 'center', 'baseline', 'stretch' ] as const
const directionOptions = [ 'row', 'row-reverse', 'column', 'column-reverse' ] as const
const directionToPropertyMap = {
  row: 'marginRight',
  'row-reverse': 'marginLeft',
  column: 'marginBottom',
  'column-reverse': 'marginTop'
} as const

const alignContentVariants = generateVariants(contentOptions, 'alignContent')

const justifyContentVariants = generateVariants(contentOptions, 'justifyContent')

const alignItemsVariants = generateVariants(itemsOptions, 'alignItems')

const justifyItemsVariants = generateVariants(itemsOptions, 'justifyItems')

const directionVariants = generateVariants(directionOptions, 'flexDirection')

const gapVariants = generateThemeVariants('space')

const Flex = styled(Box, {
  variants: {
    alignContent: alignContentVariants,
    alignItems: alignItemsVariants,
    direction: directionVariants,
    gap: gapVariants,
    justifyContent: justifyContentVariants,
    justifyItems: justifyItemsVariants,
    display: {
      flex: {
        display: 'flex'
      },
      'inline-flex': {
        display: 'inline-flex'
      }
    },
    wrap: {
      wrap: {
        flexWrap: 'wrap'
      },
      nowrap: {
        flexWrap: 'nowrap'
      },
      'wrap-reverse': {
        flexWrap: 'wrap-reverse'
      }
    }
  }
})

Flex.defaultProps = {
  direction: 'row',
  display: 'flex',
  wrap: 'nowrap'
}

directionOptions.forEach((direction) => {
  (Object.keys(theme.space) as unknown as Array<keyof typeof theme['space']>).forEach((gap) => {
    Flex.compoundVariant(
      {
        direction,
        gap
      },
      {
        '& > *:not(:last-child)': {
          [directionToPropertyMap[direction]]: gap
        }
      }
    )
  })
})

Flex.displayName = 'Flex'

export default Flex

export type FlexProps = StyledProps<typeof Flex>
