import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Use it to prevent calling state setter after component has unmounted.

 * If possible use `useState` directly.
*/

const useSafeSetState = <T>(initialState: T) => {
  const isMounted = useRef(false)
  const [ state, setState ] = useState(initialState)

  const stateSetter: typeof setState = useCallback(
    (arg) => {
      isMounted.current && setState(arg)
    },
    []
  )

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return [ state, stateSetter ] as const
}

export default useSafeSetState
