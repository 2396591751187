type UrnObject = {
  type: 'app' | 'resource' | 'custom',
  id?: string,
  componentPath?: string,
  viewId?: string
}

const parseViewUrn = (urn?: string): UrnObject | null => {
  if (!urn) return null

  const [ type, viewId, componentPath ] = urn.split('::')

  switch (type) {
    case 'app':
    case 'resource':
      return {
        type,
        id: viewId,
        componentPath
      }
    case 'custom':
      return {
        type,
        viewId
      }
    default:
      throw new Error(`Unknown URN type: ${type}`)
  }
}

export type { UrnObject }

export default parseViewUrn
