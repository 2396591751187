import React from 'react'
import get from 'lodash/get'

import TextRenderer from './TextRenderer'
import type { RendererOptions } from 'components/dataTable/types'

function EmbeddedRenderer<T extends object>({
  dataKey,
  rowData,
  prefix = '',
  suffix = '',
  fieldProps
}: RendererOptions<T>) {
  const key = [ prefix, dataKey, suffix ].filter(Boolean).join('.')
  const data = get(rowData, key)

  if (fieldProps.title_field_id) {
    const titleFieldIdentifier = fieldProps.fields
      .find((field: any) => field.id === fieldProps.title_field_id).identifier as string

    if (Array.isArray(data)) {
      return (
        <TextRenderer
          dataKey={titleFieldIdentifier}
          rowData={{
            [titleFieldIdentifier]: data.map((datum) => datum.data[titleFieldIdentifier].en_US)
          }}
        />
      )
    }
    return (
      <TextRenderer
        dataKey={`data.${titleFieldIdentifier}.en_US`}
        rowData={data}
      />
    )
  }

  return (
    <TextRenderer
      {...{
        dataKey,
        rowData,
        prefix,
        suffix
      }}
    />
  )
}

export default EmbeddedRenderer
