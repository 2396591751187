import omit from 'lodash/omit'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import uuid from 'uuid-random'
import { rectIntersection, DndContext, DragEndEvent, DragOverEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import type { PropsWithChildren } from 'react'

import generatePosition from 'lib/generatePosition'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import updateMenuElements from 'lib/updateMenuElements'
import useDashboard from 'hooks/useDashboard'
// import useRedirectToMenuElement from 'hooks/useRedirectToMenuElement'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, useCreateMenuElementMutation, useGenerateMenuElementsMutation, useUpdateMenuElementMutation } from 'generated/schema'
import { Views } from './constants'
import type { ComputedMenuElement } from 'lib/generateDashboard'

const insertColumn = (columns: any[], innerBlock: any, index: number) => {
  const resizeableColumnsLength = columns.filter((column: any) => column.width !== '10%').length
  const updatedColumns = columns.map((column: any) => ({
    ...column,
    width: column.width ? `${Math.max(10, +column.width.split('%')[0] * (resizeableColumnsLength / (resizeableColumnsLength + 1)))}%` : `${100 / resizeableColumnsLength + 1}%`
  }))

  updatedColumns.splice(
    index,
    0,
    {
      width: `${100 - updatedColumns.reduce((acc: number, column: any) => acc + +column.width.split('%')[0], 0)}%`,
      children: [
        innerBlock
      ]
    }
  )

  return updatedColumns
}

function DashboardEditorProvider({ children }: PropsWithChildren<{}>) {
  const [ urn, setUrn ] = useState('')
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const {
    draggedMenuState,
    draggedAppState,
    draggedResourceState,
    draggedBlockState,
    draggingOverBlockIdState,
    draggingOverMenuIdState,
    moveBlockToIndex,
    replaceBlockAtIndex,
    getBlocks,
    blockIds: blockIdsState,
    openDashboardEditorView,
    selectMenu,
    selectBlock,
    updateBlock,
    removeBlock,
    updateBlockProperties
  } = useDashboard()
  const [ draggedBlock, setDraggedBlock ] = useRecoilState(draggedBlockState)
  const [ draggedMenu, setDraggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp, setDraggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource, setDraggedResource ] = useRecoilState(draggedResourceState)

  const blockIds = useRecoilValue(blockIdsState(urn!))
  const setBlockIds = useSetRecoilState(blockIdsState(urn!))
  const setDraggingOverBlockId = useSetRecoilState(draggingOverBlockIdState)
  const [ draggingOverMenuId, setDraggingOverMenuId ] = useRecoilState(draggingOverMenuIdState)

  const openEditMenuItemView = (menuElement: ComputedMenuElement) => {
    openDashboardEditorView({
      target: Views.ADD_MENU_ELEMENT,
      params: {
        initialValues: menuElement
      }
    })
    selectMenu(menuElement)
  }

  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted: (data) => {
      openEditMenuItemView(data.createMenuElement)
    }
  })

  const {
    currentDashboard,
    parentIdToMenuElementsMap,
    idToMenuElementMap
  } = useContext(InternalContext)!

  // const redirect = useRedirectToMenuElement()

  const dashboardId = currentDashboard?.id

  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const [ updateMenuElement ] = useUpdateMenuElementMutation()
  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement',
      defaultValue: draggedMenu as any,
      override: (values: any) => ({
        ...values
      })
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const [ addToDashboard ] = useGenerateMenuElementsMutation({
    /* onCompleted: (data) => {
      const menuElement = data.generateMenuElements[0]
      if (menuElement) redirect(menuElement)
    }, */
    refetchQueries: [ DashboardsListDocument ]
  })

  const handleAddToDashboard = useSubmitHandler(addToDashboard)

  const handleDragOver = (event: DragOverEvent) => {
    const cleanup = () => {
      setDraggingOverBlockId(null)
      setDraggingOverMenuId(null)
    }

    if (event.over?.id === event.active.id) return cleanup()
    if (!event.over?.id) return cleanup()
    // check if block is dragged and if the block is being dragged over another block
    if (draggedBlock) {
      if (event.over.data.current?.type || event.over.id === 'ADD_BLOCK' || event.over.id === 'DASHBOARD') {
        return setDraggingOverBlockId(event.over.id.toString())
      }
    } else if (
      !event.over.data.current?.type
      || event.over.id.toString().startsWith('ADD_MENU')
      || event.over.id.toString() === 'SIDEBAR_PRIMARY'
      || event.over.id.toString() === 'SIDEBAR_SECONDARY'
      || event.over.id.toString() === 'TOPBAR'
    ) {
      // check if menu is dragged and if the menu is being dragged over another menu
      return setDraggingOverMenuId(event.over.id.toString())
    }

    return cleanup()
  }

  const cleanup = () => {
    setDraggingOverBlockId(null)
    setDraggingOverMenuId(null)
    setDraggedBlock(null)
    setDraggedMenu(null)
    setDraggedApp(null)
    setDraggedResource(null)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    if (!event.over?.id) return cleanup()

    if (draggedBlock) {
      handleDraggedBlock(event)
    }

    if (draggedMenu || draggedApp || draggedResource) {
      handleDraggedMenu(event)
    }

    return cleanup()
  }

  const handleColumnInsertion = async (overId: string, block: any) => {
    const targetBlockId = overId.split('blockId:')[1].split('::')[0]
    const targetDirectionOrIndex = overId.split('blockId:')[1].split('::')[1]
    const targetIndex = blockIds.findIndex((id) => id === targetBlockId)
    const sourceIndex = blockIds.findIndex((id) => id === block.id)
    const [ targetBlock ] = await getBlocks(urn, [ targetBlockId ])

    // Source is either a new block or in columns block
    if (sourceIndex === -1) {
      // Block is within a columns or tabs block
      const [ sourceBlockId, sourceColumnOrTabIndex ] = block?.sortable?.containerId?.split('::') || []
      const [ sourceBlock ] = sourceBlockId ? await getBlocks(urn, [ sourceBlockId ]) : []

      // remove from source
      if (sourceBlock?.id) {
        if ([ 'ColumnsBlock', 'TabsBlock' ].includes(sourceBlock.type)) {
          const specifier = sourceBlock.type === 'ColumnsBlock' ? 'columns' : 'tabs'
          const sourceColumnOrTab = sourceBlock?.properties[specifier][sourceColumnOrTabIndex]

          await updateBlock(urn, {
            ...sourceBlock,
            properties: {
              ...sourceBlock.properties,
              [specifier]: [
                ...sourceBlock.properties[specifier]?.slice(0, sourceColumnOrTabIndex),
                {
                  ...sourceColumnOrTab,
                  children: sourceColumnOrTab.children.filter((child: any) => child.id !== block.id)
                },
                ...sourceBlock.properties[specifier]?.slice(+sourceColumnOrTabIndex + 1)
              ].filter((column) => !!column.children?.length || specifier === 'tabs')
            }
          })
        }
      }
    } else {
      setBlockIds(blockIds.filter((id) => id !== block.id))
    }

    // target block is a columns block
    if (targetBlock.type === 'ColumnsBlock') {
      // insert target block into an existing columns block
      return updateBlock(urn, {
        ...targetBlock,
        properties: {
          ...targetBlock.properties,
          columns: insertColumn(
            targetBlock.properties.columns?.map((column: any) => ({
              ...column,
              children: column.children.filter((child: any) => child.id !== block.id)
            })).filter((column: any) => !!column.children?.length) || [],
            block,
            // eslint-disable-next-line no-nested-ternary
            targetDirectionOrIndex === 'left'
              ? 0
              : targetDirectionOrIndex === 'right'
                ? targetBlock.properties.columns?.length
                : Number(targetDirectionOrIndex) + 1
          )
        }
      } as any) as Promise<any>
    }

    // target block is a tabs block
    if (targetBlock?.type === 'TabsBlock') {
      const targetTab = targetBlock.properties.tabs?.[targetDirectionOrIndex] || {}

      // insert target block into an existing tabs block
      return updateBlock(urn, {
        ...targetBlock,
        properties: {
          ...targetBlock.properties,
          tabs: [
            // eslint-disable-next-line no-nested-ternary
            ...((targetBlock.properties.tabs?.slice(0, targetDirectionOrIndex === 'left' ? 0 : targetDirectionOrIndex === 'right' ? undefined : +targetDirectionOrIndex) || [])
              .map((tab: any) => ({
                ...tab, children: tab.children?.filter((child: any) => child.id !== block.id)
              }))),
            {
              title: Number.isNaN(+targetDirectionOrIndex) ? 'New Tab' : `Tab ${+targetDirectionOrIndex + 1}`,
              ...targetTab,
              children: [
                ...(targetTab.children || []),
                block
              ]
            },
            // eslint-disable-next-line no-nested-ternary
            ...(targetDirectionOrIndex === 'right' ? [] : (targetBlock.properties.tabs?.slice(targetDirectionOrIndex === 'left' ? 0 : +targetDirectionOrIndex + 1) || [])
              .map((tab: any) => ({
                ...tab, children: tab.children?.filter((child: any) => child.id !== block.id)
              })))
          ]
        }
      } as any) as Promise<any>
    }

    // target is normal block
    // insert target block into a columns block
    const id = uuid()
    const identifier = `columns-block-${id.slice(0, 8)}`

    const promise = await replaceBlockAtIndex(urn, {
      id,
      identifier,
      type: 'ColumnsBlock',
      properties: {
        columns: [
          {
            width: '50%',
            children: [ targetDirectionOrIndex === 'left' ? block : targetBlock ]
          },
          {
            width: '50%',
            children: [ targetDirectionOrIndex === 'right' ? block : targetBlock ]
          }
        ]
      }
    } as any, targetIndex)

    // block is being moved from a normal block to another normal block
    if (sourceIndex !== -1) {
      replaceBlockAtIndex(urn, null as any, sourceIndex)
    }

    return promise
  }

  const handleDropBlock = async (overId: string, block: any, containerId?: string) => {
    const computedDraggingOverBlockId = overId.split('__dropzone')[0]
    const index = blockIds.findIndex((id) => id === computedDraggingOverBlockId)

    // target block is inside a columns or tabs block
    if (index === -1) {
      const [ targetBlockId, targetIndex ] = containerId?.split('::') || []
      const [ targetBlock ] = targetBlockId ? await getBlocks(urn, [ targetBlockId ]) : []

      if (![ 'ColumnsBlock', 'TabsBlock' ].includes(targetBlock?.type)) return undefined

      const targetColumnOrTabIndex = Number(targetIndex)
      const targetSpecifier = targetBlock.type === 'ColumnsBlock' ? 'columns' : 'tabs'
      const targetColumnOrTab = targetBlock.properties[targetSpecifier][targetColumnOrTabIndex]
      const targetRowIndex = targetColumnOrTab
        .children.findIndex((child: any) => child.id === computedDraggingOverBlockId) + 1

      if (draggedBlock?.id) {
        // @ts-ignore
        const [ sourceBlockId, sourceIndex ] = draggedBlock.sortable?.containerId?.split('::') || []
        const [ sourceBlock ] = sourceBlockId ? await getBlocks(urn, [ sourceBlockId ]) : []
        if ([ 'ColumnsBlock', 'TabsBlock' ].includes(sourceBlock?.type)) {
          const sourceSpecifier = sourceBlock.type === 'ColumnsBlock' ? 'columns' : 'tabs'
          const sourceColumnOrTabIndex = Number(sourceIndex)
          const sourceColumnOrTab = sourceBlock.properties[sourceSpecifier][sourceColumnOrTabIndex]
          const sourceRowIndex = sourceColumnOrTab
            ?.children.findIndex((child: any) => child.id === draggedBlock.id) + 1

          // move within columns/tabs block
          if (sourceBlockId === targetBlockId) {
            // move within same column
            if (sourceColumnOrTabIndex === targetColumnOrTabIndex) {
              if (sourceRowIndex === targetRowIndex) return undefined
              const updatedColumnsOrTabs = [
                ...sourceBlock.properties[sourceSpecifier]?.slice(0, sourceColumnOrTabIndex),
                {
                  ...sourceColumnOrTab,
                  children: sourceColumnOrTab.children
                    .filter((child: any) => child.id !== draggedBlock.id)
                },
                ...sourceBlock.properties[sourceSpecifier]?.slice(sourceColumnOrTabIndex + 1)
              ]

              if (sourceRowIndex > targetRowIndex) {
                updatedColumnsOrTabs.splice(
                  targetColumnOrTabIndex,
                  1,
                  {
                    ...updatedColumnsOrTabs[targetColumnOrTabIndex],
                    children: [
                      ...(updatedColumnsOrTabs[targetColumnOrTabIndex]
                        .children.slice(0, targetRowIndex) || []),
                      block,
                      ...(updatedColumnsOrTabs[targetColumnOrTabIndex]
                        .children.slice(targetRowIndex) || [])
                    ]
                  }
                )
              } else {
                updatedColumnsOrTabs.splice(
                  targetColumnOrTabIndex,
                  1,
                  {
                    ...updatedColumnsOrTabs[targetColumnOrTabIndex],
                    children: [
                      ...(updatedColumnsOrTabs[targetColumnOrTabIndex]
                        .children.slice(0, targetRowIndex - 1) || []),
                      block,
                      ...(updatedColumnsOrTabs[targetColumnOrTabIndex]
                        .children.slice(targetRowIndex - 1) || [])
                    ]
                  }
                )
              }

              return updateBlock(urn, {
                ...targetBlock,
                properties: {
                  ...targetBlock.properties,
                  [targetSpecifier]: updatedColumnsOrTabs.filter((column) => !!column.children?.length || targetSpecifier === 'tabs')
                }
              } as any) as Promise<any>
            }

            const updatedColumnsOrTabs = [
              ...sourceBlock.properties[sourceSpecifier]?.slice(0, sourceColumnOrTabIndex),
              {
                ...sourceColumnOrTab,
                children: sourceColumnOrTab.children
                  .filter((child: any) => child.id !== draggedBlock.id)
              },
              ...sourceBlock.properties[sourceSpecifier]?.slice(sourceColumnOrTabIndex + 1)
            ]

            updatedColumnsOrTabs.splice(
              targetColumnOrTabIndex,
              1,
              {
                ...targetColumnOrTab,
                children: [
                  ...(targetColumnOrTab.children.slice(0, targetRowIndex) || []),
                  block,
                  ...(targetColumnOrTab.children.slice(targetRowIndex) || [])
                ]
              }
            )

            return updateBlock(urn, {
              ...targetBlock,
              properties: {
                ...targetBlock.properties,
                [targetSpecifier]: updatedColumnsOrTabs.filter((column) => !!column.children?.length || targetSpecifier === 'tabs')
              }
            } as any) as Promise<any>
          }
          // source columns/tabs block and target columns/tabs block are different
          // remove from source
          await updateBlock(urn, {
            ...sourceBlock,
            properties: {
              ...sourceBlock.properties,
              [sourceSpecifier]: [
                ...sourceBlock.properties[sourceSpecifier]?.slice(0, sourceColumnOrTabIndex),
                {
                  ...sourceColumnOrTab,
                  children: sourceColumnOrTab.children
                    .filter((child: any) => child.id !== draggedBlock.id)
                },
                ...sourceBlock.properties[sourceSpecifier]?.slice(sourceColumnOrTabIndex + 1)
              ].filter((column) => !!column.children?.length || sourceSpecifier === 'tabs')
            }
          })

          // add to target
          return updateBlock(urn, {
            ...targetBlock,
            properties: {
              ...targetBlock.properties,
              [targetSpecifier]: [
                ...targetBlock.properties[targetSpecifier]?.slice(0, targetColumnOrTabIndex),
                {
                  ...targetColumnOrTab,
                  children: [
                    ...(targetColumnOrTab?.children.slice(0, targetRowIndex) || []),
                    block,
                    ...(targetColumnOrTab?.children.slice(targetRowIndex) || [])
                  ]
                },
                ...targetBlock.properties[targetSpecifier]?.slice(targetColumnOrTabIndex + 1)
              ].filter((column) => !!column.children?.length || targetSpecifier === 'tabs')
            }
          } as any) as Promise<any>
        }
        // remove from original place
        await removeBlock(urn, draggedBlock.id)
        // add to target
        return updateBlock(urn, {
          ...targetBlock,
          properties: {
            ...targetBlock.properties,
            [targetSpecifier]: [
              ...targetBlock.properties[targetSpecifier]?.slice(0, targetColumnOrTabIndex),
              {
                ...targetColumnOrTab,
                children: [
                  ...(targetColumnOrTab?.children.slice(0, targetRowIndex) || []),
                  block,
                  ...(targetColumnOrTab?.children.slice(targetRowIndex) || [])
                ]
              },
              ...targetBlock.properties[targetSpecifier]?.slice(targetColumnOrTabIndex + 1)
            ].filter((column) => !!column.children?.length || targetSpecifier === 'tabs')
          }
        } as any) as Promise<any>
      }
      // add to target
      return updateBlock(urn, {
        ...targetBlock,
        properties: {
          ...targetBlock.properties,
          [targetSpecifier]: [
            ...targetBlock.properties[targetSpecifier]?.slice(0, targetColumnOrTabIndex),
            {
              ...targetColumnOrTab,
              children: [
                ...(targetColumnOrTab?.children.slice(0, targetRowIndex) || []),
                block,
                ...(targetColumnOrTab?.children.slice(targetRowIndex) || [])
              ]
            },
            ...targetBlock.properties[targetSpecifier]?.slice(targetColumnOrTabIndex + 1)
          ].filter((column) => !!column.children?.length || targetSpecifier === 'tabs')
        }
      } as any) as Promise<any>
    }

    if (draggedBlock?.id) {
      // @ts-ignore
      const [ sourceBlockId, sourceIndex ] = draggedBlock.sortable?.containerId?.split('::') || []
      const [ sourceBlock ] = sourceBlockId ? await getBlocks(urn, [ sourceBlockId ]) : []

      if ([ 'ColumnsBlock', 'TabsBlock' ].includes(sourceBlock?.type)) {
        const sourceColumnOrTabIndex = Number(sourceIndex)
        const specifier = sourceBlock.type === 'ColumnsBlock' ? 'columns' : 'tabs'
        const sourceColumnOrTab = sourceBlock.properties[specifier]?.[sourceColumnOrTabIndex]

        await updateBlock(urn, {
          ...sourceBlock,
          properties: {
            ...sourceBlock.properties,
            [specifier]: [
              ...sourceBlock.properties[specifier]?.slice(0, sourceColumnOrTabIndex),
              {
                ...sourceColumnOrTab,
                children: sourceColumnOrTab.children
                  .filter((child: any) => child.id !== draggedBlock.id)
              },
              ...sourceBlock.properties[specifier]?.slice(sourceColumnOrTabIndex + 1)
            ].filter((column) => !!column.children?.length || specifier === 'tabs')
          }
        })
      }
    }

    const draggedBlockIndex = draggedBlock?.id
      ? blockIds.findIndex((id) => id === draggedBlock.id)
      : -1

    if (draggedBlockIndex > index) {
      // insert after
      return moveBlockToIndex(urn, block, index + 1)
    }

    return moveBlockToIndex(urn, block, index)
  }

  const handleDraggedBlock = async (event: DragEndEvent) => {
    const block = {
      ...draggedBlock,
      id: draggedBlock?.id || uuid()
    } as any

    let promise: Promise<void> | undefined
    const overId = event.over?.id.toString()!
    // Insert within columns block
    if (overId.startsWith('blockId:')) {
      promise = handleColumnInsertion(overId, block)
    } else if ([ 'ADD_BLOCK', 'DASHBOARD' ].includes(overId!)) {
      // Append to Dashboard
      promise = moveBlockToIndex(urn, block, blockIds.length)
    } else if (event.over?.data.current?.type || event.over?.id.toString().endsWith('__dropzone')) {
      // Dropping in the mid of dashboard
      promise = handleDropBlock(overId, block, event.over.data.current?.sortable?.containerId)
    }
    if (!draggedBlock?.id) {
      promise?.then(() => {
        selectBlock(block.id)
        openDashboardEditorView({
          target: Views.EDIT_BLOCK
        })
      })
    }
  }

  const handleDraggedMenu = (event: DragEndEvent) => {
    const draggedElementId = draggedMenu?.id
      || draggedApp?.id
      || draggedResource?.id

    if (MenuElement.isDescendantOf(
      draggedElementId,
      idToMenuElementMap[event.over?.data.current?.parentId]
    )) {
      return cleanup()
    }
    if (event.over?.id === draggedElementId) {
      return cleanup()
    }
    if (
      event.over?.id.toString().startsWith('ADD_MENU')
      || event.over?.id.toString() === 'SIDEBAR_PRIMARY'
      || event.over?.id.toString() === 'SIDEBAR_SECONDARY'
      || event.over?.id.toString() === 'TOPBAR'
    ) {
      return handleMenuSubmission(event, 'APPEND')
    }

    if (event.over?.id.toString().startsWith('PREPEND')) {
      return handleMenuSubmission(event, 'PREPEND')
    }

    if (event.over?.data.current?.kind) {
      return handleMenuSubmission(event, 'INSERT')
    }

    return cleanup()
  }

  const handleMenuSubmission = (event: DragEndEvent, mode: 'PREPEND' | 'APPEND' | 'INSERT') => {
    const siblings = getParentMenuSiblings(event)

    const generatedPosition = {
      PREPEND: () => generatePosition(
        undefined,
        siblings[0]?.position
      ),
      APPEND: () => generatePosition(
        // @ts-ignore
        siblings.at(-1)?.position
      ),
      INSERT: () => {
        const draggingOverMenuElement = idToMenuElementMap[draggingOverMenuId]

        const isDraggingAccrossDroppables = (
          draggedMenu?.parentId !== draggingOverMenuElement?.parentId
            || draggedMenu?.placement !== draggingOverMenuElement?.placement
        )

        const nextMenuElement = getNextMenuElement(siblings)

        if (
          isDraggingAccrossDroppables
          || !nextMenuElement
          || nextMenuElement.position > draggedMenu.position
        ) {
          return generatePosition(
            draggingOverMenuElement?.position,
            nextMenuElement?.position
          )
        }

        const previousMenuElement = getPreviousMenuElement(siblings)

        return generatePosition(
          previousMenuElement?.position,
          draggingOverMenuElement?.position
        )
      }
    }[mode]()

    if (draggedMenu) {
      if (!draggedMenu.id) {
        handleCreateMenuElementSubmit({
          ...draggedMenu,
          dashboardId,
          parentId: event.over?.data.current?.parentId,
          placement: event.over?.data.current?.placement,
          isSticky: event.over?.data.current?.isSticky,
          position: generatedPosition
        } as any)
      } else {
        handleUpdateMenuElementSubmit({
          id: draggedMenu.id,
          parentId: event.over?.data.current?.parentId,
          placement: event.over?.data.current?.placement,
          isSticky: event.over?.data.current?.isSticky,
          position: generatedPosition
        })
      }
    } else if (draggedApp || draggedResource) {
      const parentId = event.over?.data.current?.parentId

      handleAddToDashboard({
        appId: draggedApp?.id,
        parentId,
        ...(parentId ? {} : { placement: event.over?.data.current?.placement }),
        position: generatedPosition,
        resourceMenuStubs: draggedResource?.id
          ? [ { position: generatedPosition, id: draggedResource.id } ]
          : undefined,
        dashboardId
      })
    }
  }

  const getParentMenuSiblings = (event: DragEndEvent) => (parentIdToMenuElementsMap[
    event.over?.data.current!.parentId! || null
  ] || []).filter((menu) => (
    menu.placement === event.over?.data.current!.placement
    && menu.dashboardId === dashboardId
    && !!menu.isSticky === !!event.over?.data.current!.isSticky
  ))

  const getPreviousMenuElement = (siblings: ComputedMenuElement[]) => {
    const index = siblings.findIndex((menu) => menu.id === draggingOverMenuId)
    return siblings[index - 1]
  }

  const getNextMenuElement = (siblings: ComputedMenuElement[]) => {
    const index = siblings.findIndex((menu) => menu.id === draggingOverMenuId)
    return siblings[index + 1]
  }

  const contextValue = useMemo(() => ({ urn, onUrnChange: setUrn }), [ urn ])

  const location = useLocation()

  useEffect(() => {
    const search = Object.fromEntries(new URLSearchParams(location.search))

    updateBlockProperties({ location: { ...location, search } })
  }, [ location, updateBlockProperties ])

  return (
    <DashboardEditorContext.Provider value={contextValue}>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={({ active }) => {
          if (active.data.current?.type) {
            setDraggedBlock(active.data.current as any)
          } else if (active.data.current?.__typename === 'App') {
            setDraggedApp(omit(active.data.current, 'sortable') as any)
          } else if (active.data.current?.__typename === 'Resource') {
            setDraggedResource(omit(active.data.current, 'sortable') as any)
          } else {
            setDraggedMenu(omit(active.data.current, 'sortable') as any)
          }
        }}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={cleanup}
      >
        {children}
      </DndContext>
    </DashboardEditorContext.Provider>
  )
}

type DashboardEditorContextType = {
  urn: string,
  onUrnChange: React.Dispatch<React.SetStateAction<string>>
}
const DashboardEditorContext = createContext<DashboardEditorContextType>({
  urn: '',
  onUrnChange: () => {}
})
const useDashboardEditorContextProvider = () => useContext(DashboardEditorContext)

export { useDashboardEditorContextProvider }

export default DashboardEditorProvider
