import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React from 'react'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import SelectInput from 'components/inputs/SelectInput'
import type { Attribute } from 'generated/schema'
import Text from 'components/typography/Text'

dayjs.extend(duration)

type ConfigurationsProps = {
  fieldPrefix?: string
}

const FormatOptions = [
  {
    label: 'Shorthand',
    meta: '1d 2h',
    value: 'shorthand'
  },
  {
    label: 'Descriptive',
    meta: '1 day 2 hours',
    value: 'descriptive'
  },
  {
    label: 'Standard (ISO8601)',
    meta: 'P1D2H',
    value: 'iso8601'
  }
]

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { fontWeight = 'regular' } = displaySettings

  const duration = dayjs.duration(data, 'seconds')
  // any .asXXX method works to check if we got a valid date
  const formatted = Number.isNaN(duration.asDays()) ? null : duration.format('m[m] s[s]')

  return (
    <Text fontSize={14} fontWeight={fontWeight}>{formatted || '-'}</Text>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}format`}
      component={SelectInput}
      label="Format"
      size="small"
      options={FormatOptions}
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as DurationRenderer }
