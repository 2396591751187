import groupBy from 'lodash/groupBy'
import React, { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { APP_LIST_LIMIT, getAppIcon } from 'models/App'
import { App, useAppCategoriesListQuery, useAppListQuery } from 'generated/schema'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'

type IntegrationsGridProps = {
  integrations: App[],
  title: string
}

type Params = ViewParams[Views.ADD_INTEGRATION]

const AddIntegrationView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { operation, appCategoryIds } = params! as Params

  const { data: { appCategoriesList = [] } = {} } = useAppCategoriesListQuery({
    variables: {
      filter: {
        kind: { eq: 'INTEGRATION' }
      },
      limit: APP_LIST_LIMIT,
      order: [ { position: 'asc' } ]
    }
  })

  const { data: { appsList = [] } = {} } = useAppListQuery({
    variables: {
      filter: {
        kind: { eq: 'INTEGRATION' },
        ...(appCategoryIds
          && appCategoryIds?.length > 0
          && { appCategoryId: { in: appCategoryIds } }
        )
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const appCategories = useMemo(() => appCategoriesList || [], [ appCategoriesList ])
  const integrationsList = useMemo(() => appsList || [], [ appsList ])

  const filteredIntegrations = integrationsList?.filter(
    (app) => app.name.toLowerCase().includes(search.toLowerCase())
  )

  const groupedIntegrations = useMemo(() => (
    groupBy(filteredIntegrations, (app) => app.appCategoryId)
  ), [ filteredIntegrations ])

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add Integration"
        // eslint-disable-next-line no-nested-ternary
        heading={operation ? `${operation.id ? 'Edit' : 'New'} Operation`
          : appCategoryIds ? 'Import from Database' : 'Integrations'}
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        {appCategories.map((category) => {
          const integrations = groupedIntegrations[category.id]

          if (!integrations?.length) return null

          return (
            <IntegrationsGrid
              key={category.id}
              integrations={integrations}
              title={category.name}
            />
          )
        })}
      </DashboardEditorBody>
    </>
  )
}

const IntegrationsGrid = ({ integrations, title }: IntegrationsGridProps) => {
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues } = params! as Params

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        {title}
      </Text>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={integrations}
        loading={!integrations}
      >
        <Flex direction="column" gap={2}>
          {integrations?.map((integration) => {
            const onClick = () => openDashboardEditorView({
              target: Views.ADD_INTEGRATION_FORM,
              params: {
                ...params,
                initialValues: {
                  selectedApp: integration
                },
                parentInitialValues: initialValues
              }
            })

            return (
              <MediaCard
                compact
                onClick={onClick}
                key={integration.id}
                media={(
                  <img
                    style={{ width: '32px' }}
                    src={getAppIcon(integration.identifier)}
                    alt={integration.identifier || ''}
                  />
                )}
                title={integration.name}
                height={64}
                width="full"
                actions={[ {
                  description: '',
                  icon: 'arrow-right',
                  onClick,
                  isIconAlwaysVisible: true
                } ]}
              />
            )
          })}
        </Flex>
      </DashboardEditorLoader>
    </Flex>
  )
}

export default AddIntegrationView
