import React, { useMemo } from 'react'
import { Field, Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import RadioInput from 'components/inputs/RadioInput'
import SelectInput from 'components/inputs/SelectInput'
import SwitchField from 'components/contentEditors/generic/fields/SwitchField'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { DropdownField } from 'components/contentEditors/generic/fields'
import { useLocaleListQuery, useSaveWorkspaceLocaleMutation } from 'generated/schema'
import type { Locale } from 'hooks/useActiveLocales'
import type { SaveWorkspaceLocaleInput } from 'generated/schema'
import type { ViewProps } from 'components/views'

type Params = {
  activeLocales: Locale[],
  initialValues: Partial<SaveWorkspaceLocaleInput>
}

enum fallbackValues {
  'TRUE' = 'true',
  'FALSE' = 'false',
  'EMPTY' ='empty'
}

const FALLBACK_OPTIONS = [
  {
    label: 'Fallback to the default Locale',
    value: fallbackValues.TRUE
  },
  {
    label: 'Fallback to the selected locales (in order)',
    value: fallbackValues.FALSE
  },
  {
    label: 'Don\'t fallback to any locale (sends back empty data)',
    value: fallbackValues.EMPTY
  }
]

const getFallBack = (initialValues: any) => {
  if (!initialValues?.allowFallback && !initialValues.fallbacks?.length) {
    return fallbackValues.EMPTY
  }

  if (initialValues?.allowFallback && !initialValues.fallbacks?.length) {
    return fallbackValues.TRUE
  }

  return fallbackValues.FALSE
}

function AddWorkspaceLocaleView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { initialValues, activeLocales } = params || {}
  const { identifier } = initialValues || {}

  const fallbackOptions = activeLocales.map((activeLocale) => ({
    label: activeLocale.name, value: activeLocale.identifier
  }))

  const initialFormValues = identifier
    ? {
      ...initialValues,
      allowFallback: getFallBack(initialValues)
    }
    : { allowFallback: fallbackValues.TRUE }

  const title = identifier ? 'Update Locale' : 'Add Locale'

  const { data, loading } = useLocaleListQuery()
  const { locales = [] } = data || {}

  const [ saveenvironmentLocale ] = useSaveWorkspaceLocaleMutation({
    onCompleted: () => {
      onRequestClose()
    }
  })

  const submitHandler = useSubmitHandler(saveenvironmentLocale)

  const localeOptions = useMemo(() => locales.map(
    (locale: any) => ({ value: locale.identifier, label: `${locale.name} (${locale.identifier})` })
  ), [ locales ])

  const renderAddon = (option: any) => {
    if (option === fallbackValues.FALSE) {
      return (
        <Field
          component={SelectInput}
          isLoading={loading}
          name="fallbacks"
          options={fallbackOptions}
          size="small"
          virtualize
          closeMenuOnSelect={false}
          isMulti
        />
      )
    }

    return null
  }

  const renderRadioInputs = () => FALLBACK_OPTIONS.map((option) => (
    <Flex alignItems="stretch" key={option.label} grow={1}>
      <Field
        addon={renderAddon(option.value)}
        component={RadioInput}
        grow={1}
        label={option.label}
        name="allowFallback"
        type="radio"
        value={option.value as any}
      />
    </Flex>
  ))

  const handleAddLocale = (values:any) => {
    const sanitizedValues = {
      ...values,
      allowFallback: values.allowFallback !== fallbackValues.EMPTY
    }

    submitHandler(sanitizedValues)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer, Divider: ViewDivider }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            initialValues={{
              isDefault: false,
              fallbacks: [],
              ...initialFormValues
            }}
            onSubmit={handleAddLocale}
            subscription={{ submitting: true, pristine: true }}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex as="form" direction="column" gap={32} onSubmit={handleSubmit}>
                    <Field name="environmentId" type="hidden" component="input" />

                    <DropdownField
                      isLoading={loading}
                      name="identifier"
                      options={localeOptions}
                      label="Select A Locale"
                      size="small"
                      virtualize
                    />
                    <SwitchField
                      name="isDefault"
                      label="Is this the default Locale?"
                      helpText="If there is no locale specified by the user, this will be used."
                      labelPosition="left"
                      showGuide={false}
                    />
                    <WhenFieldChanges
                      field="allowFallback"
                      set="fallbacks"
                      to={[]}
                      includes={[ 'empty', 'true' ]}
                    />

                    <ViewDivider />
                    <ConditionalField when="isDefault" is={false}>
                      <Flex as="label" direction="column" gap={24}>
                        <FieldLabel>Fallback</FieldLabel>
                        {renderRadioInputs()}
                      </Flex>
                    </ConditionalField>
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

export default AddWorkspaceLocaleView
