import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import ActionsView from 'components/dashboardEditor/ActionsView'
import AddActionView from './AddActionView'
import AddBlockView from 'components/dashboardEditor/AddBlockView'
import AddColumnView from 'components/dashboardEditor/AddColumnView'
import AddDashboardView from 'components/dashboardEditor/AddDashboardView'
import AddFormBlockView from './AddFormBlockView'
import AddIntegrationFormView from 'components/dashboardEditor/integration/AddIntegrationFormView'
import AddIntegrationView from 'components/dashboardEditor/integration/AddIntegrationView'
import AddMemberView from 'components/dashboardEditor/roleMembership/AddMemberView'
import AddMenuAppView from './menu/AddMenuAppView'
import AddMenuElementView from 'components/dashboardEditor/menu/AddMenuElementView'
import AddMenuProjectView from './menu/AddMenuProjectView'
import AddMenuResourceView from './menu/AddMenuResourceView'
import AddMenuView from 'components/dashboardEditor/AddMenuView'
import AddRowView from './AddRowView'
import AppDetailsView from 'components/dashboardEditor/app/AppDetailsView'
import AppsView from 'components/dashboardEditor/app/AppsView'
import CreateAttributeView from 'components/dashboardEditor/graph/CreateAttributeView'
import CreateCustomAppView from 'components/dashboardEditor/app/CreateCustomAppView'
import CreateDataTypeView from './graph/CreateDataTypeView'
import CreateDataTypeFieldView from './graph/CreateDataTypeFieldView'
import CreateOperationView from 'components/dashboardEditor/graph/CreateOperationView'
import CreateParameterView from 'components/dashboardEditor/graph/CreateParameterView'
import CreateProjectView from 'components/dashboardEditor/project/CreateProjectView'
import CreateRelationshipView from 'components/dashboardEditor/graph/CreateRelationshipView'
import CreateResourceView from 'components/dashboardEditor/graph/CreateResourceView'
import CreateRoleView from 'components/dashboardEditor/roleMembership/CreateRoleView'
import CreateView from './CreateView'
import EditAppView from 'components/dashboardEditor/app/EditAppView'
import EditBlockView from 'components/dashboardEditor/EditBlockView'
import EditComponentView from 'components/dashboardEditor/EditComponentView'
import EditProjectView from 'components/dashboardEditor/project/EditProjectView'
import EditViewView from 'components/dashboardEditor/EditViewView'
import Flex from 'components/layout/Flex'
import ImportFromDatabaseView from 'components/dashboardEditor/graph/ImportFromDatabaseView'
import IntegrationDetailsView from './integration/IntegrationDetailsView'
import IntegrationsView from 'components/dashboardEditor/integration/IntegrationsView'
import LinkAttributeView from './LinkAttributeView'
import Portal from 'components/portal/Portal'
import ProjectDetailsView from 'components/dashboardEditor/project/ProjectDetailsView'
import ProjectsView from 'components/dashboardEditor/project/ProjectsView'
import ResourceDetailsView from 'components/dashboardEditor/graph/ResourceDetailsView'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import ViewDetailsView from 'components/dashboardEditor/ViewDetailsView'
import WorkspaceSchemaView from 'components/dashboardEditor/graph/WorkspaceSchemaView'
import { colorVars } from 'styles/theme'
import { DASHBOARD_EDITOR_WIDTH, Views } from 'components/dashboardEditor/constants'
import { styled } from 'styles/stitches'

type DashboardEditorProps = {
  isOpen: boolean,
  onClose: () => void
}

type ActiveViewProps = {
  onClose: () => void
}

const StyledDashboardEditor = styled(Flex, {
  ...mixins.transition('fastIn'),

  background: 'light400',
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  bottom: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  transform: 'translateX(100%)',
  width: DASHBOARD_EDITOR_WIDTH,
  zIndex: 'popover',
  variants: {
    open: {
      true: {
        ...mixins.shadow('xxxLarge', colorVars.dark600rgb, 0.6),
        transform: 'translateX(0)'
      }
    }
  }
})

const ViewsMap = {
  [Views.ACTIONS]: ActionsView,
  [Views.ADD_ACTION]: AddActionView,
  [Views.ADD_BLOCK]: AddBlockView,
  [Views.ADD_COLUMN]: AddColumnView,
  [Views.ADD_ROW]: AddRowView,
  [Views.ADD_FORM_BLOCK]: AddFormBlockView,
  [Views.ADD_DASHBOARD]: AddDashboardView,
  [Views.ADD_INTEGRATION_FORM]: AddIntegrationFormView,
  [Views.ADD_INTEGRATION]: AddIntegrationView,
  [Views.ADD_MEMBER]: AddMemberView,
  [Views.ADD_MENU_APP]: AddMenuAppView,
  [Views.ADD_MENU_ELEMENT]: AddMenuElementView,
  [Views.ADD_MENU_PROJECT]: AddMenuProjectView,
  [Views.ADD_MENU_RESOURCE]: AddMenuResourceView,
  [Views.ADD_MENU]: AddMenuView,
  [Views.ADD_VIEW_OPERATION]: 'div',
  [Views.APP_DETAILS]: AppDetailsView,
  [Views.APP]: AppsView,
  [Views.CREATE_ATTRIBUTE]: CreateAttributeView,
  [Views.CREATE_CUSTOM_APP]: CreateCustomAppView,
  [Views.CREATE_DATA_SOURCE]: 'div',
  [Views.CREATE_DATA_TYPE]: CreateDataTypeView,
  [Views.CREATE_DATA_TYPE_FIELD]: CreateDataTypeFieldView,
  [Views.CREATE_OPERATION]: CreateOperationView,
  [Views.CREATE_PARAMETER]: CreateParameterView,
  [Views.CREATE_PROJECT]: CreateProjectView,
  [Views.CREATE_RELATIONSHIP]: CreateRelationshipView,
  [Views.CREATE_RESOURCE]: CreateResourceView,
  [Views.CREATE_ROLE]: CreateRoleView,
  [Views.CREATE_SEGMENT]: 'div',
  [Views.CREATE_VIEW]: CreateView,
  [Views.DASHBOARD]: 'div',
  [Views.EDIT_APP]: EditAppView,
  [Views.EDIT_BLOCK]: EditBlockView,
  [Views.EDIT_COMPONENT]: EditComponentView,
  [Views.EDIT_PROJECT]: EditProjectView,
  [Views.EDIT_RESOURCE]: 'div',
  [Views.EDIT_VIEW]: EditViewView,
  [Views.IMPORT_FROM_API]: 'div',
  [Views.IMPORT_FROM_DATABASE]: ImportFromDatabaseView,
  [Views.INTEGRATION_DETAILS]: IntegrationDetailsView,
  [Views.INTEGRATIONS]: IntegrationsView,
  [Views.LINK_ATTRIBUTE]: LinkAttributeView,
  [Views.MENU]: 'div',
  [Views.PROJECT_DETAILS]: ProjectDetailsView,
  [Views.PROJECT]: ProjectsView,
  [Views.RESOURCE_DETAILS]: ResourceDetailsView,
  [Views.SCHEMA]: WorkspaceSchemaView,
  [Views.VIEW_DETAILS]: ViewDetailsView
}

function DashboardEditor({
  isOpen,
  onClose
}: DashboardEditorProps) {
  const {
    dashboardEditorState,
    resetDashboardEditorStack
  } = useDashboard()

  const dbStack = useRecoilValue<DashboardEditorView<Views>>(dashboardEditorState)

  useEffect(() => {
    if (!isOpen) resetDashboardEditorStack()
  }, [ isOpen, resetDashboardEditorStack ])

  // Dashboard Editor Stack is empty
  if (!dbStack) return null

  const ActiveView = ViewsMap[dbStack.target]

  return (
    <Portal>
      <StyledDashboardEditor direction="column" open={isOpen}>
        <ActiveView onClose={onClose} />
      </StyledDashboardEditor>
    </Portal>
  )
}

export default DashboardEditor

export type { ActiveViewProps }
