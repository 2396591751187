import React from 'react'

import Text from 'components/typography/Text'
import { Link, MarkdownRenderer, Paragraph } from 'components/markdown'
import { styled } from 'styles/stitches'

const StyledInputHelpText = styled(Text, {
  [`${Paragraph}`]: {
    marginBottom: 0,
    lineHeight: 'compact'
  },
  [`${Paragraph}, ${Link}`]: {
    fontSize: 'inherit'
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.3
      }
    }
  }
})

const InputHelpText = ({ helpText, disabled }: { helpText: string, disabled?: boolean }) => (
  <StyledInputHelpText
    as="div"
    color="dark300"
    fontSize={10}
    disabled={disabled}
  >
    <MarkdownRenderer source={helpText} />
  </StyledInputHelpText>
)

export default InputHelpText
