import rafSchd from 'raf-schd'
import React, { useContext } from 'react'
import { colord } from 'colord'
import { Form, FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import TextInput from 'components/inputs/TextInput'
import Theme from 'models/Theme'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { ColorField } from 'components/contentEditors/generic/fields'
import type { CreateThemeInput, ThemePalette, UpdateThemeInput } from 'generated/schema'

type ThemeFormValues = CreateThemeInput | UpdateThemeInput

type ThemeFormProps = FormProps<ThemeFormValues> & {
  onHideColorPicker?: () => void,
  onShowColorPicker?: () => void
}

function ThemeForm({ onShowColorPicker, onHideColorPicker, ...others }: ThemeFormProps) {
  const { changeThemePalette } = useContext(WorkspaceContext)!

  const handleChangeColor = rafSchd(
    (color: string, palette: ThemePalette) => {
      if (colord(color).isValid()) {
        changeThemePalette(palette)
      }
    }
  )

  return (
    <Form
      validate={(values) => Theme.validate(values, [ 'name', 'palette' ])}
      subscription={{
        submitting: true,
        pristine: true,
        values: true
      }}
      render={({ handleSubmit, submitting, pristine, form: { reset }, values }) => (
        <form onSubmit={handleSubmit} onReset={reset}>
          <Flex gap={16} direction="column">
            <FormField
              autoFocus
              component={TextInput}
              label="Name"
              name="name"
              placeholder="Enter a name for your theme"
              type="text"
            />

            <Flex gap={24} grow={1}>
              <ColorField
                alwaysDirty // to avoid partial palette updates
                helpText="Sidebar, overall branding"
                label="Primary Color"
                name="palette.primary"
                settings={{ showAlpha: false }}
                onChangeComplete={(color: string) => (
                  handleChangeColor(color, { ...values.palette!, primary: color })
                )}
                onHideColorPicker={onHideColorPicker}
                onShowColorPicker={onShowColorPicker}
              />

              <ColorField
                alwaysDirty // to avoid partial palette updates
                helpText="Sidebar, overall branding"
                label="Secondary Color"
                name="palette.secondary"
                settings={{ showAlpha: false }}
                onChangeComplete={(color: string) => (
                  handleChangeColor(color, { ...values.palette!, secondary: color })
                )}
                onHideColorPicker={onHideColorPicker}
                onShowColorPicker={onShowColorPicker}
              />
              <ColorField
                alwaysDirty // to avoid partial palette updates
                helpText="Buttons, actions, links"
                label="Accent Color"
                name="palette.accent"
                settings={{ showAlpha: false }}
                onChangeComplete={(color: string) => (
                  handleChangeColor(color, { ...values.palette!, accent: color })
                )}
                onHideColorPicker={onHideColorPicker}
                onShowColorPicker={onShowColorPicker}
              />
            </Flex>

            <Flex gap={24}>
              <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
            </Flex>
          </Flex>
        </form>
      )}
      {...others}
    />
  )
}

export default ThemeForm
