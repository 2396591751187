import React, { Ref } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text, { TextProps } from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { BaseLinkProps } from 'components/links/BaseLink'
import type { FlexProps } from 'components/layout/Flex'

const DEFAULT_ELEMENT = 'div'

type ChipProps = FlexProps & BaseLinkProps & TextProps & {
  icon?: string,
  iconPlacement?: 'left' | 'right',
  isCollapsed?: boolean,
  label: string,
  onDelete?: () => void,
  variant?: 'accent' | 'light' | 'neutral' | 'negative' | 'positive' | 'primary' | 'secondary' | 'warning' | 'accent_inverse' | 'light_inverse' | 'negative_inverse' | 'positive_inverse' | 'primary_inverse' | 'secondary_inverse' | 'warning_inverse',
  inverse?: boolean,
  size?: 'small' | 'normal' | 'large'
}

const CHIP_COLLAPSED_SIZE = 8

const StyledChip = styled(Flex, {
  ...mixins.transition('simple'),

  backgroundColor: 'dark700',
  borderRadius: '$full',
  paddingY: 0,
  width: 'fit-content',
  truncate: true,

  [`& ${Icon}`]: {
    color: 'dark300'
  },

  '&& [data-delete-icon]': {
    ...mixins.transition('fastIn'),

    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '0 -10px',
    padding: '0 10px',
    width: 'auto',
    cursor: 'pointer',

    '&:hover': {
      'mix-blend-mode': 'color-dodge'
    }
  },

  variants: {
    size: {
      small: {
        height: 16,
        minWidth: 16,
        paddingX: 4
      },
      normal: {
        height: 26,
        minWidth: 26,
        paddingX: 10
      },
      large: {
        height: 36,
        minWidth: 36,
        paddingX: 16
      }
    },
    variant: {
      neutral: {},
      accent: {
        backgroundColor: 'accent400'
      },
      light: {
        backgroundColor: 'dark100',

        [`& ${Text}`]: {
          color: 'dark500'
        },

        [`& ${Icon}`]: {
          color: 'dark500'
        }
      },
      negative: {
        backgroundColor: 'negative500',

        [`& ${Icon}`]: {
          color: 'light100'
        }
      },
      positive: {
        backgroundColor: 'positive300',

        [`& ${Icon}`]: {
          color: 'light100'
        }
      },
      primary: {
        backgroundColor: 'primary300',

        [`& ${Icon}`]: {
          color: 'light100'
        }
      },
      secondary: {
        backgroundColor: 'secondary400',

        [`& ${Icon}`]: {
          color: 'light100'
        }
      },
      warning: {
        backgroundColor: 'warning300',

        [`& ${Icon}`]: {
          color: 'light100'
        }
      },
      accent_inverse: {
        backgroundColor: 'accent200',
        [`& ${Text}`]: {
          color: 'accent600'
        },

        [`& ${Icon}`]: {
          color: 'accent600'
        }
      },
      light_inverse: {
        backgroundColor: 'dark100',
        [`& ${Text}`]: {
          color: 'dark700'
        },

        [`& ${Icon}`]: {
          color: 'dark700'
        }
      },
      negative_inverse: {
        backgroundColor: 'negative200',
        [`& ${Text}`]: {
          color: 'negative600'
        },

        [`& ${Icon}`]: {
          color: 'negative600'
        }
      },
      positive_inverse: {
        backgroundColor: 'positive50',
        [`& ${Text}`]: {
          color: 'positive600'
        },

        [`& ${Icon}`]: {
          color: 'positive600'
        }
      },
      primary_inverse: {
        backgroundColor: 'primary50',
        [`& ${Text}`]: {
          color: 'primary300'
        },

        [`& ${Icon}`]: {
          color: 'primary300'
        }
      },
      secondary_inverse: {
        backgroundColor: 'secondary50',
        [`& ${Text}`]: {
          color: 'secondary400'
        },

        [`& ${Icon}`]: {
          color: 'secondary400'
        }
      },
      warning_inverse: {
        backgroundColor: 'warning100',
        [`& ${Text}`]: {
          color: 'warning800'
        },

        [`& ${Icon}`]: {
          color: 'warning800'
        }
      }
    },
    collapsed: {
      true: {
        '&&': {
          borderRadius: '$full',
          height: CHIP_COLLAPSED_SIZE,
          minWidth: CHIP_COLLAPSED_SIZE,
          maxWidth: 8,
          paddingX: 0,
          paddingY: 0,

          [`& ${Text}`]: {
            opacity: 0
          }
        }
      }
    },
    clickable: {
      true: { cursor: 'pointer' }
    }
  }
})

const SIZE_TO_GAP_MAP = {
  small: 4,
  normal: 10,
  large: 16
} as const

const Chip = React.forwardRef((
  {
    className,
    icon,
    iconPlacement = 'left',
    isCollapsed = false,
    label,
    onDelete,
    variant = 'neutral',
    color = 'light100',
    fontFamily,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
    textTransform = 'uppercase',
    textAlign,
    truncate,
    size = 'normal',

    ...other
  }: ChipProps,
  innerRef: Ref<any>
) => {
  const Element = (() => {
    if ('href' in other || 'to' in other) { return BaseLink }

    if ('onClick' in other) { return 'button' }

    return DEFAULT_ELEMENT
  })()

  const renderIcon = () => icon && <Icon name={icon} size={10} />

  return (
    <StyledChip
      as={Element}
      alignItems="center"
      shrink={0}
      variant={variant}
      className={className}
      collapsed={isCollapsed}
      clickable={Element !== 'div'}
      size={size}
      gap={SIZE_TO_GAP_MAP[size]}
      {...other}
      ref={innerRef}
    >
      {iconPlacement === 'left' && renderIcon()}
      {label !== null && label !== undefined && (
      <Text
        as="span"
        fontSize={fontSize || 10}
        fontWeight={fontWeight || 'bold'}
        textTransform={textTransform}
        textAlign={textAlign || 'center'}
        lineHeight={lineHeight || 'compact'}
        grow={1}
        truncate={truncate}
        {...{
          color,
          fontFamily,
          letterSpacing
        }}
      >
        {label}
      </Text>
      )}
      {iconPlacement === 'right' && renderIcon()}

      {onDelete && (
        <Icon
          data-delete-icon
          name="cross"
          size={6}
          onClick={onDelete}
        />
      )}
    </StyledChip>
  )
})

Chip.displayName = 'Chip'

export type { ChipProps }

export default Chip
