import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'
import { getMenuElements } from 'dashx-fixtures'
import type { MenuElement } from 'dashx-fixtures'

// use this to get the menu elements for a given app
const useAppMenuElements = (app: string): MenuElement[] => {
  const allMenuElements = getMenuElements()
  const menuElements = useMemo(() => allMenuElements[app] || [], [ app, allMenuElements ])

  return useMemo(() => sortBy(menuElements, 'position'), [ menuElements ])
}

export default useAppMenuElements
