import React, { useCallback } from 'react'
import { boolean } from 'yup'

import FormField from 'components/form/FormField'
import ToggleInput from 'components/inputs/ToggleInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { ToggleInputProps } from 'components/inputs/ToggleInput'

type SwitchFieldProps = Omit<ToggleInputProps, 'input' | 'meta'> & fieldProps<'toggle'>

const getSwitchFieldSchema = (settings: SwitchFieldProps['settings'] = {}, isNullable: boolean) => {
  let schema = boolean().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema
      .oneOf([ true ], 'must be checked')
      .required('must be checked')
  }

  return schema
}

const SwitchField = ({
  title,
  name,
  isTranslatable,
  showGuide,
  settings,
  shouldValidate,
  isNullable = false,
  ...others
}: SwitchFieldProps) => {
  const validate = useCallback((value: boolean) => getSwitchFieldSchema(settings, isNullable)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings, isNullable ])

  return (
    <FormField
      component={ToggleInput}
      name={name}
      defaultValue={false}
      type="checkbox"
      labelPosition="left"
      isTranslatable={isTranslatable}
      {...({
        checkRequired: settings?.checkRequired,
        placeholder: settings?.placeholder,
        helpText: settings?.helpText
      })}
      {...(shouldValidate && { validate })}
      {...others}
    />
  )
}

export type { SwitchFieldProps }
export default SwitchField
