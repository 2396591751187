import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

class Workspace extends BaseModel {
  static getComputedHostname = (identifier: string, hostname?: string) => hostname || `${identifier}.${process.env.REACT_APP_DOMAIN}`

  static schema = object({
    identifier: isIdentifier(),
    name: string().required(),
    hostname: string().required(),
    themeId: string().required()
  })
}

export default Workspace
