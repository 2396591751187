import Creatable from 'react-select/creatable'
import fuzzysort from 'fuzzysort'
import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'
import ReactAsyncSelect from 'react-select/async'
import ReactSelect, {
  components,
  MenuPlacement,
  MenuPosition,
  MenuListComponentProps,
  OptionsType,
  Props as ReactSelectProps,
  Styles as ReactSelectStyles,
  ValueContainerProps
} from 'react-select'
import Scrollbars from 'react-custom-scrollbars'
import { useRect } from '@reach/rect'
import { useVirtual } from 'react-virtual'
import type SelectType from 'react-select/src/Select'
import type StateManager from 'react-select'
import type { Option as OptionType } from 'react-select/src/filters'
import type { Props as ReactAsyncSelectProps } from 'react-select/async'
import type { ReactElement } from 'react'

import * as mixins from 'styles/mixins'
import Box from 'components/layout/Box'
import CheckboxInput from 'components/inputs/CheckboxInput'
import colors from 'styles/primitives/colors'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import rgba from 'lib/rgba'
import SimpleLoader from 'components/loaders/SimpleLoader'
import Text from 'components/typography/Text'
import typography from 'styles/primitives/typography'
import useHover from 'hooks/useHover'
import useResizeObserver from 'hooks/useResizeObserver'
import zIndices from 'styles/primitives/zIndices'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { useNestedPopover } from 'components/popover/Popover'
import type { Color } from 'styles/theme'
import type { Rounded } from 'components/form/InputGroup'

type SelectProps<T = SelectOptionType> = (
  ReactSelectProps<T> | ReactAsyncSelectProps<T>
) & {
  getOptionDescription?: (data: T) => string,
  getOptionMeta?: (data: T) => string,
  getOptionIcon?: (data: T) => string,
  inputIconColor?: (data: T) => string,
  hasError?: boolean,
  inputIconKey?: string,
  metaKey?: string,
  rounded?: Rounded,
  size?: 'small' | 'normal' | 'large',
  variant?: variant,
  virtualize?: boolean
}

type state = 'normal' | 'active' | 'error'
type variant = 'light' | 'dark'

type SelectOptionType = Record<string, any>

type SelectOptionsType = OptionsType<SelectOptionType>

type GroupedOption = {
  label: string,
  options: OptionType[]
}

const DEFAULT_NO_OPTIONS_MESSAGE = 'No matching options found'
const DEAFULT_CREATABLE_MESSAGE = 'Type to create an option'
const DEFAULT_MENU_HEIGHT = 300
const DEFAULT_OPTION_HEIGHT = 56

const FUZZY_SEARCH_THRESHOLD = -100

const SELECT_BACKGROUND_COLOR: { [key in state]: { [key in variant]: Color } } = {
  normal: {
    light: 'light100',
    dark: 'light600'
  },
  active: {
    light: 'light400',
    dark: 'light400'
  },
  error: {
    light: 'negative100',
    dark: 'negative100'
  }
}

const SELECT_BORDER_COLOR: { [key in state]: { [key in variant]: Color } } = {
  normal: {
    light: 'dark100',
    dark: 'light600'
  },
  active: {
    light: 'dark300',
    dark: 'dark300'
  },
  error: {
    light: 'negative300',
    dark: 'negative300'
  }
}

const SELECT_BORDER_RADIUS = 4

const SELECT_INPUT_HEIGHT: { [key: string]: number } = {
  large: 80,
  normal: 60,
  small: 40
}

const SELECT_INPUT_FONT_SIZE = 14
const SELECT_INPUT_META_FONT_SIZE = 12
const SELECT_INPUT_ICON_FONT_SIZE = 16

const SELECT_INPUT_PADDING_X = {
  large: 30,
  normal: 20,
  small: 12
}

const SELECT_INPUT_PADDING_Y = {
  large: 20,
  normal: 12,
  small: 4
}

const SELECT_INPUT_VALUE_MARGIN = 2
const SELECT_MENU_MAX_HEIGHT = 300

// Custom styles

const getMenuBorderRadius = (menuPlacement: 'bottom' | 'top') => ({
  borderBottomLeftRadius: menuPlacement === 'top' ? 0 : SELECT_BORDER_RADIUS,
  borderBottomRightRadius: menuPlacement === 'top' ? 0 : SELECT_BORDER_RADIUS,
  borderTopLeftRadius: menuPlacement === 'bottom' ? 0 : SELECT_BORDER_RADIUS,
  borderTopRightRadius: menuPlacement === 'bottom' ? 0 : SELECT_BORDER_RADIUS
})

const customStyles = (
  { controlHeight, currentMenuPlacement, hasError, rounded, size, variant }: SelectProps
): ReactSelectStyles => ({
  control: (
    provided, { isDisabled, isFocused, menuIsOpen }
  ) => {
    const getBackgroundColor = () => {
      if (hasError) {
        return colors[SELECT_BACKGROUND_COLOR.error[variant!]]
      }

      return colors[SELECT_BACKGROUND_COLOR.normal[variant!]]
    }

    const getBorderColor = () => {
      if (menuIsOpen) return 'transparent'

      if (hasError) {
        return colors[SELECT_BORDER_COLOR.error[variant!]]
      }

      if (isFocused) {
        return colors[SELECT_BORDER_COLOR.active[variant!]]
      }

      return colors[SELECT_BORDER_COLOR.normal[variant!]]
    }

    const getBorderRadius = () => {
      let base = {}
      switch (rounded) {
        case 'all':
          base = {
            borderBottomLeftRadius: SELECT_BORDER_RADIUS,
            borderBottomRightRadius: SELECT_BORDER_RADIUS,
            borderTopLeftRadius: SELECT_BORDER_RADIUS,
            borderTopRightRadius: SELECT_BORDER_RADIUS
          }
          break
        case 'left':
          base = {
            borderBottomLeftRadius: SELECT_BORDER_RADIUS,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: SELECT_BORDER_RADIUS,
            borderTopRightRadius: 0
          }
          break
        case 'right':
          base = {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: SELECT_BORDER_RADIUS,
            borderTopLeftRadius: 0,
            borderTopRightRadius: SELECT_BORDER_RADIUS
          }
          break
        case 'none':
          base = {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }
          break
      }

      if (menuIsOpen) {
        if (currentMenuPlacement === 'bottom') {
          return {
            ...base,

            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }
        }

        if (currentMenuPlacement === 'top') {
          return {
            ...base,

            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }
        }
      }

      return base
    }

    return {
      ...provided,
      ...getBorderRadius(),
      ...mixins.transition('fluid'),

      backgroundColor: getBackgroundColor(),
      borderColor: getBorderColor(),
      boxShadow: 'none',
      minHeight: SELECT_INPUT_HEIGHT[size!],
      opacity: isDisabled ? 0.3 : 1,

      'fieldset[disabled] &': {
        backgroundColor: colors.light400,
        borderColor: colors.dark100,
        color: colors.dark500
      },

      '&:hover': {
        borderColor: (!menuIsOpen && !hasError) && colors[SELECT_BORDER_COLOR.active[variant!]]
      }
    }
  },
  valueContainer: (provided) => ({
    ...provided,

    '@media (pointer:coarse)': {
      '&>div>div': { marginBottom: '0 !important' }
    },
    padding: 0
  }),
  placeholder: (provided, { menuIsOpen }) => ({
    ...provided,

    color: hasError ? colors.negative300 : colors.dark500,
    fontFamily: typography.fontFamilies.normal,
    fontSize: typography.fontSizes[SELECT_INPUT_FONT_SIZE],
    opacity: menuIsOpen ? 0.3 : 1
  }),
  input: (provided) => ({
    ...provided,

    color: colors.dark900,
    fontFamily: typography.fontFamilies.normal,
    fontSize: typography.fontSizes[SELECT_INPUT_FONT_SIZE]
  }),
  singleValue: (provided, { isDisabled }) => {
    const getValueColor = () => {
      if (isDisabled) return colors.dark500
      if (hasError) return colors.negative500
      return colors.dark900
    }

    return {
      ...provided,
      // special case for env switcher

      color: getValueColor(),
      fontFamily: typography.fontFamilies.normal,
      fontSize: typography.fontSizes[SELECT_INPUT_FONT_SIZE],
      fontWeight: parseInt(typography.fontWeights.regular, 10),

      'fieldset[disabled] &': {
        color: colors.dark500
      }
    }
  },
  indicatorsContainer: (provided) => ({
    ...provided,

    paddingLeft: SELECT_INPUT_PADDING_X[size!] - 8,
    paddingRight: SELECT_INPUT_PADDING_X[size!] - 8
  }),
  indicatorSeparator: (
    provided, { hasValue, selectProps: { isClearable, isLoading, isMulti } }
  ) => ({
    ...provided,

    backgroundColor: hasError ? colors.negative200 : colors.light700,
    height: 30,
    marginBottom: 'auto',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 'auto',
    display: isLoading || (hasValue && (isClearable || isMulti)) ? 'block' : 'none'
  }),
  loadingIndicator: (provided) => ({
    ...provided,

    color: colors.dark300
  }),
  menu: (provided) => ({
    ...provided,
    ...getMenuBorderRadius(currentMenuPlacement),

    borderBottom: currentMenuPlacement === 'top' ? `1px solid ${colors.dark100}` : 'none',
    borderTop: currentMenuPlacement === 'bottom' ? `1px solid ${colors.dark100}` : 'none',
    boxShadow: 'none',
    margin: 0,
    zIndex: parseInt(zIndices.popover, 10),

    '&::before': {
      ...getMenuBorderRadius(currentMenuPlacement),
      ...mixins.shadow('medium', colors.dark600rgb, 0.3),

      bottom: currentMenuPlacement === 'top' ? -controlHeight : 0,
      content: '""',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: currentMenuPlacement === 'bottom' ? -controlHeight : 0
    }
  }),
  menuPortal: (provided) => ({
    ...provided,

    zIndex: Number.parseInt(zIndices.modal, 10)
  }),
  menuList: (provided) => ({
    ...provided,
    ...getMenuBorderRadius(currentMenuPlacement),

    background: colors[SELECT_BACKGROUND_COLOR.normal[variant!]],
    maxHeight: SELECT_MENU_MAX_HEIGHT,
    paddingBottom: 0,
    paddingTop: 0
  }),
  option: () => ({}), // Remove default option styles
  multiValue: () => ({}),
  multiValueRemove: () => ({ background: 'transparent' })
})

// Custom components

const StyledClearIndicator = styled('div', {
  cursor: 'pointer',
  '& [data-icon]': {
    ...mixins.transition('fluid'),

    color: 'dark300'
  },

  '&:hover [data-icon]': {
    color: 'dark700'
  },
  variants: {
    hasError: {
      true: {
        '& [data-icon]': {
          color: 'negative300'
        },

        '&:hover [data-icon]': {
          color: 'negative500'
        }
      }
    }
  }

})

const ClearIndicator: typeof components.ClearIndicator = (props) => {
  const { selectProps } = props

  return (
    <StyledClearIndicator
      as={components.ClearIndicator}
      hasError={selectProps.hasError}
      {...props}
    >
      <Icon data-icon name="cross" size={8} />
    </StyledClearIndicator>
  )
}

const Control: typeof components.Control = (props) => {
  const { selectProps: { controlRef } } = props

  return (
    <div ref={controlRef}>
      <components.Control {...props} />
    </div>
  )
}

const StyledDropdownIndicator = styled('div', {
  cursor: 'pointer',

  'fieldset[disabled] &': {
    cursor: 'not-allowed',
    pointerEvents: 'none',

    '& [data-icon]': {
      color: 'dark100'
    }
  }
})

const getArrowClassName = ({
  isDisabled,
  isFocused,
  selectProps: { hasError, isHovered, menuIsOpen }
}: any) => {
  const getArrowColor = () => {
    if (isDisabled) return 'dark100'

    if (isHovered || isFocused) {
      return (hasError ? 'negative500' : 'dark700')
    }

    return (hasError ? 'negative300' : 'dark100')
  }

  return css({
    ...mixins.transition('fluid'),

    color: getArrowColor(),
    transform: menuIsOpen && 'rotate(180deg)'
  })
}

const DropdownIndicator: typeof components.DropdownIndicator = (props) => (
  <StyledDropdownIndicator as={components.DropdownIndicator} {...props}>
    <Icon
      data-icon
      name="arrow-down"
      size={10}
      className={getArrowClassName(props)}
    />
  </StyledDropdownIndicator>
)

const StyledLoaderContainer = styled(Flex, {
  height: 80
})

const StyledLoader = styled(SimpleLoader, {
  color: 'light700',
  variants: {
    dark: {
      true: {
        color: 'dark30'
      }
    }
  }
})

const LoadingMessage: typeof components.LoadingMessage = ({ selectProps: { variant } }) => (
  <StyledLoaderContainer alignItems="center" justifyContent="center">
    <StyledLoader dark={variant === 'dark'} size="small" />
  </StyledLoaderContainer>
)

const Menu: typeof components.Menu = (props) => {
  const { placement, selectProps: { setCurrentMenuPlacement } } = props as any

  useEffect(() => {
    setCurrentMenuPlacement(placement)
  }, [ placement, setCurrentMenuPlacement ])

  return <components.Menu {...props} />
}

const getOptionClassName = ({
  isDisabled,
  isFocused,
  isSelected,
  size,
  variant
}: SelectProps) => {
  const getBackgroundColor = () => {
    if (!isDisabled && isFocused) {
      return SELECT_BACKGROUND_COLOR.active[variant!]
    }

    return SELECT_BACKGROUND_COLOR.normal[variant!]
  }

  const getLabelColor = () => {
    if (isDisabled) return rgba(colorVars.dark500rgb, 0.3)
    if (isFocused) return 'primary300'
    return 'dark900'
  }

  const getDescriptionColor = () => {
    if (isDisabled) return rgba(colorVars.dark500rgb, 0.3)
    if (isFocused) return 'primary200'
    return 'dark500'
  }

  const getMetaColor = () => {
    if (isDisabled) return rgba(colorVars.dark300rgb, 0.3)
    if (isFocused) return 'primary200'
    return 'dark500'
  }

  const getIconColor = () => {
    if (isDisabled) return rgba(colorVars.dark300rgb, 0.3)
    if (isFocused) return 'primary200'
    return 'dark500'
  }

  return css({
    ...mixins.transition('fluid', 'background'),
    cursor: !(isDisabled || isSelected) ? 'pointer' : 'initial',
    paddingY: 20,
    paddingX: SELECT_INPUT_PADDING_X[size!] + SELECT_INPUT_VALUE_MARGIN,
    background: getBackgroundColor(),

    '&:hover': {
      background: !isDisabled ? SELECT_BACKGROUND_COLOR.active[variant!] : ''
    },

    '& [data-label]': {
      ...mixins.transition('fluid', 'color'),

      color: getLabelColor(),
      fontSize: SELECT_INPUT_FONT_SIZE,
      fontWeight: 'regular'
    },

    '&:hover [data-label]': {
      color: !isDisabled ? 'primary300' : ''
    },

    '& [data-description]': {
      ...mixins.transition('fluid', 'color'),

      color: getDescriptionColor(),
      fontSize: SELECT_INPUT_META_FONT_SIZE,
      fontWeight: 'regular',
      whiteSpace: 'pre-wrap'
    },

    '& [data-meta]': {
      ...mixins.transition('fluid', 'color'),

      maxWidth: '50%',
      color: getMetaColor(),
      fontSize: SELECT_INPUT_META_FONT_SIZE,
      fontWeight: 'regular',
      truncate: true
    },

    '& [data-icon]': {
      ...mixins.transition('fluid', 'color'),

      color: getIconColor()
    },

    '&:hover [data-meta]': {
      color: !isDisabled ? 'primary300' : ''
    },

    '&:hover [data-icon]': {
      color: !isDisabled ? 'primary300' : ''
    }
  })
}

const isIconString = (icon: SelectProps['icon']): icon is string => typeof icon === 'string'

const renderIcon = (icon: SelectProps['icon']) => {
  if (isIconString(icon)) {
    return (
      <Icon
        data-icon
        name={icon}
        size={SELECT_INPUT_ICON_FONT_SIZE}
      />
    )
  }

  return icon
}

const Option: typeof components.Option = (props) => {
  const {
    innerProps,
    isDisabled,
    isFocused,
    isMulti,
    isSelected,
    label,
    data,
    selectProps: {
      size,
      variant,
      descriptionKey,
      metaKey,
      iconKey,
      getOptionDescription,
      getOptionMeta,
      getOptionIcon
    }
  } = props

  const { onClick } = innerProps

  innerProps.onClick = (e) => {
    e.preventDefault()
    onClick?.(e)
  }

  const icon = resolveIconContent(data, iconKey, getOptionIcon)
  const description = resolveDescriptionContent(data, descriptionKey, getOptionDescription)

  // TODO: Fix this
  // const isMultiSelected = Array.isArray(value)
  //   && value.find((option) => option.value === get(data, valueKey))

  return (
    <components.Option {...props}>
      <Flex
        alignItems="center"
        className={getOptionClassName({ isDisabled, isFocused, isSelected, size, variant })}
        gap={10}
      >
        {isMulti && (
          <CheckboxInput
            input={{
              disabled: isDisabled,
              checked: isSelected,
              onChange: () => { }
            }}
          />
        )}
        {icon && renderIcon(icon)}
        <Flex direction="column" grow={1}>
          <Text data-label>{label}</Text>
          {description && <Text data-description>{description}</Text>}
        </Flex>
        <Text data-meta>{resolveMetaContent(data, metaKey, getOptionMeta)}</Text>
      </Flex>
    </components.Option>
  )
}

const StyledNoOptionMessage = styled(Flex, {
  padding: 20,
  [`& ${Icon}`]: {
    color: 'dark300'
  }
})

const NoOptionsMessage: typeof components.NoOptionsMessage = ({ children, innerProps }) => (
  <StyledNoOptionMessage alignItems="center" gap={20} {...innerProps}>
    <Icon name="info" size={22} />
    <Text fontSize={14} lineHeight="cozy" color="dark500">{children}</Text>
  </StyledNoOptionMessage>
)

const StyledMultiValueContainer = styled(Flex, {
  ...mixins.transition('fluid'),
  backgroundColor: 'dark700',
  borderRadius: 13,
  margin: SELECT_INPUT_VALUE_MARGIN,
  paddingY: 4,
  paddingX: 12,
  variants: {
    hasError: {
      true: {
        backgroundColor: 'negative500'
      }
    }
  }
})
const MultiValueContainer: typeof components.MultiValue = ({
  children,
  innerProps,
  selectProps
}) => {
  const [ multiValueLabel, multiValueRemove ] = children as ReactElement[]

  return (
    <StyledMultiValueContainer
      {...innerProps}
      alignItems="center"
      gap={10}
      hasError={selectProps.hasError}
    >
      {multiValueLabel}
      {multiValueRemove}
    </StyledMultiValueContainer>
  )
}

const MultiValueLabel: typeof components.MultiValueLabel = ({ children }) => (
  <Text color="light100" fontSize={14} lineHeight="compact">
    {children}
  </Text>
)

const StyledRemoveContainer = styled('div', {
  cursor: 'pointer',

  [`${Icon}`]: {
    ...mixins.transition('fluid'),
    color: 'dark300'
  },

  [`:hover ${Icon}`]: {
    color: 'light100'
  },

  variants: {
    hasError: {
      true: {
        [`${Icon}`]: {
          color: 'negative300'
        }
      }
    }
  }
})

const MultiValueRemove: typeof components.MultiValueRemove = (
  { innerProps, selectProps }
) => (
  <StyledRemoveContainer {...innerProps} hasError={selectProps.hasError}>
    <Icon name="cross" size={8} />
  </StyledRemoveContainer>
)

const MenuList = (props: MenuListComponentProps<any>) => {
  // Adds support for Select inside Popover
  useNestedPopover()

  return <components.MenuList {...props} />
}

const SingleValue: typeof components.SingleValue = ({
  children,
  data,
  selectProps,
  ...props
}) => {
  const hasColorDot = Boolean(selectProps.inputIconColor)
  const icon = !hasColorDot && selectProps.inputIconKey
    ? get(data, selectProps.inputIconKey)
    : null

  const InputIcon = () => renderIcon(icon)

  const getValueColor = () => {
    if (selectProps.isDisabled) return colors.dark500
    if (selectProps.hasError) return colors.negative500
    return colors.dark900
  }

  const dot = () => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: selectProps.inputIconColor(selectProps.value),
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10
    }
  })

  const className = {
    singleValue: css({
      display: 'flex',
      gap: 8,
      alignItems: 'center',

      ...(hasColorDot ? dot() : ''),

      color: getValueColor(),
      fontFamily: typography.fontFamilies.normal,
      fontSize: typography.fontSizes[SELECT_INPUT_FONT_SIZE],
      fontWeight: parseInt(typography.fontWeights.regular, 10),

      [`& ${Icon}`]: {
        color: 'dark300'
      },

      'fieldset[disabled] &': {
        color: colors.dark500
      }
    })
  }

  return (
    <components.SingleValue
      {...props}
      data={data}
      className={className.singleValue}
      selectProps={selectProps}
    >
      {icon && <InputIcon data-single-value-icon />}
      {data.label || children}
    </components.SingleValue>
  )
}

const StyledSelect = styled('div', { width: '100%' })

const resolveDescriptionContent = <T extends SelectOptionType, >(
  data: T,
  descriptionKey: string,
  getOptionDescription?: (data: T) => string
) => (getOptionDescription ? getOptionDescription(data) : data[descriptionKey])

const resolveMetaContent = <T extends SelectOptionType, >(
  data: T,
  metaKey: string,
  getOptionMeta?: (data: T) => string
) => (getOptionMeta ? getOptionMeta(data) : data[metaKey])

const resolveIconContent = <T extends SelectOptionType, >(
  data: T,
  iconKey: string,
  getOptionIcon?: (data: T) => string
) => (getOptionIcon ? getOptionIcon(data) : data[iconKey])

const estimateSize = () => DEFAULT_OPTION_HEIGHT

const VirtualMenuList = (props: MenuListComponentProps<{}>) => {
  const { children, selectProps } = props
  const containerRef = useRef(null)

  // Adds support for Select inside Popover
  useNestedPopover()

  const { totalSize, virtualItems, scrollToIndex } = useVirtual({
    parentRef: containerRef,
    estimateSize,
    overscan: 5,
    size: Array.isArray(children) ? children.length : 0
  })

  React.useImperativeHandle(selectProps.imperativeRef, () => ({
    scrollToIndex,
    getCurrentOption: () => { },
    getOptions: () => (Array.isArray(children) ? children : [])
      .map((child) => (React.isValidElement(child) ? child.props?.data : null))
  }), [ scrollToIndex, children ])

  return (
    <div
      ref={containerRef}
      style={{
        height: Math.min(totalSize, DEFAULT_MENU_HEIGHT),
        overflow: 'auto',
        position: 'relative'
      }}
    >
      <div style={{ height: totalSize }}>
        {virtualItems.map((item) => (
          <div
            key={item.index}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${item.size}px`,
              transform: `translateY(${item.start}px)`
            }}
          >
            {Array.isArray(children) ? children[item.index] : null}
          </div>
        ))}
      </div>
    </div>
  )
}

const ValueContainer = <T, >({ children, selectProps, ...props }: ValueContainerProps<T>) => (
  <components.ValueContainer selectProps={selectProps} {...props} css={{ padding: 0 }}>
    <Scrollbars autoHeight autoHide>
      <Flex style={{
        paddingBottom: SELECT_INPUT_PADDING_Y[(selectProps as SelectProps).size!],
        paddingLeft: SELECT_INPUT_PADDING_X[(selectProps as SelectProps).size!],
        paddingRight: SELECT_INPUT_PADDING_X[(selectProps as SelectProps).size!],
        paddingTop: SELECT_INPUT_PADDING_Y[(selectProps as SelectProps).size!]
      }}
      >
        {children}
      </Flex>
    </Scrollbars>
  </components.ValueContainer>
)

const classes = {
  group: css({ padding: 0, margin: 0 })
}

const Group: typeof components.Group = (props) => (
  <components.Group
    {...props}
    className={classes.group}
  />
)

const StyledGroupHeading = styled(Box, {
  backgroundColor: 'light700',
  padding: '8px 14px 8px 14px',
  margin: 0
})

const GroupHeading: typeof components.GroupHeading = ({ children }) => (
  <StyledGroupHeading>{children}</StyledGroupHeading>
)

const GroupLabel = styled(Flex, {
  fontSize: 12,
  alignItems: 'center',
  justifyContent: 'space-between'
})

const formatGroupLabel = (group: GroupedOption) => (
  <GroupLabel>
    <Text textTransform="uppercase" fontWeight="semibold" color="dark600">{group.label}</Text>
    <Text color="dark400">{group.options.length}</Text>
  </GroupLabel>
)

const customComponents = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  Group,
  GroupHeading,
  LoadingMessage,
  Menu,
  MenuList,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer
}

type SelectWrapperProps = {
  children: (p: { isHovered: boolean }) => React.ReactNode,
  placement: MenuPlacement,
  setPlacement: React.Dispatch<React.SetStateAction<MenuPlacement>>
}

function SelectWrapper({ children, setPlacement }: SelectWrapperProps) {
  const rootRef = useRef<HTMLDivElement>(null)
  const [ onHoverProps, isHovered ] = useHover()
  const rect = useRect(rootRef)

  const y = rect?.y
  useEffect(() => {
    if (!y) return undefined
    let placement: MenuPlacement
    if (window.innerHeight - y < 360) {
      setPlacement((pre) => {
        placement = pre
        return 'top'
      })
    }

    return () => {
      setPlacement(placement)
    }
  }, [ y, setPlacement ])

  return (
    <StyledSelect ref={rootRef} {...onHoverProps}>
      {children({ isHovered })}
    </StyledSelect>
  )
}

function Select<T = SelectOptionType>({
  hasError = false,
  loadOptions,
  rounded = 'all',
  size = 'normal',
  variant = 'light',
  descriptionKey = 'description',
  metaKey = 'meta',
  inputIconKey = 'icon',
  virtualize = false,
  onMenuOpen,
  noOptionsMessage,
  getOptionMeta,
  getOptionDescription,
  ...others
}: SelectProps<T>) {
  const [ currentMenuPlacement, setCurrentMenuPlacement ] = useState<MenuPlacement>('bottom')
  const [ controlRef, { height: controlHeight } ] = useResizeObserver()

  const selectRef = useRef<StateManager<SelectOptionType, SelectType<SelectOptionType>> | null>()
  const asyncRef = useRef<ReactAsyncSelect<T> | null>()
  const creatableRef = useRef<Creatable<SelectOptionType> | null>()
  const imperativeRef = useRef<any>()

  const isAsync = !!loadOptions
  const isCreatable = !!others.isCreatable

  const filterOption = (option: OptionType, input: string) => {
    if (loadOptions) return true
    if (!input.length) {
      return true
    }

    const result = fuzzysort.single(input, `${option.data.label || option.label || ''}${resolveMetaContent(option.data, metaKey, getOptionMeta) || ''}`)

    return Number(result?.score) > FUZZY_SEARCH_THRESHOLD
  }

  const handleMenuOpen = () => {
    onMenuOpen?.()
    requestAnimationFrame(() => {
      const option = isAsync
        ? get(asyncRef.current, 'select.select.state.selectValue[0]')
        : get(selectRef.current, 'select.state.selectValue[0]')

      const selectOptions = get((isAsync ? asyncRef : selectRef).current, 'select.props.options') as SelectOptionsType

      if (option && selectOptions) {
        const optionIndex = selectOptions.findIndex(({ value }) => option?.value === value)

        if (optionIndex !== -1) {
          imperativeRef.current?.scrollToIndex(optionIndex, {
            align: 'center'
          })
          if (isAsync) {
            (asyncRef.current?.select as any)?.select.setState({
              focusedValue: null,
              focusedOption: selectOptions[optionIndex]
            })
          } else {
            selectRef.current?.select.setState({
              focusedValue: null,
              focusedOption: selectOptions[optionIndex]
            })
          }
        }
      }
    })
  }

  const handleKeydown = () => {
    requestAnimationFrame(() => {
      const currentOptions = imperativeRef?.current?.getOptions() as SelectOptionsType
      const focusedOption = (isAsync
        ? get(asyncRef.current, 'select.select.state.focusedOption')
        : get(selectRef.current, 'select.state.focusedOption')) as SelectOptionsType[number]

      const optionIndex = currentOptions?.findIndex(({ value }) => focusedOption?.value === value)

      if (optionIndex !== -1) {
        imperativeRef.current?.scrollToIndex(optionIndex)
      }
    })
  }

  if (virtualize) customComponents.MenuList = VirtualMenuList

  const commonProps = {
    components: customComponents,
    controlRef,
    hasError,
    metaKey,
    inputIconKey,
    descriptionKey,
    menuPlacement: currentMenuPlacement,
    menuPortalTarget: (window as any).portal,
    menuPosition: 'fixed' as MenuPosition,
    noOptionsMessage: noOptionsMessage || (() => (
      isCreatable ? DEAFULT_CREATABLE_MESSAGE : DEFAULT_NO_OPTIONS_MESSAGE
    )),
    setCurrentMenuPlacement,
    size,
    styles: customStyles({
      controlHeight,
      currentMenuPlacement,
      hasError,
      rounded,
      size,
      variant
    }),
    variant,
    filterOption,
    getOptionMeta,
    getOptionDescription,

    ...(virtualize ? {
      onKeyDown: handleKeydown,
      onMenuOpen: handleMenuOpen,
      imperativeRef
    } : {
      onMenuOpen
    })
  }

  const SelectComponent = (isCreatable
    ? Creatable : ReactSelect) as React.ElementType

  return (
    <SelectWrapper placement={currentMenuPlacement} setPlacement={setCurrentMenuPlacement}>
      {({ isHovered }) => (loadOptions ? (
        <ReactAsyncSelect
          ref={(ref) => { asyncRef.current = ref }}
          loadOptions={loadOptions}
          formatGroupLabel={formatGroupLabel as any}
          isHovered={isHovered}
          {...commonProps}
          {...others}
        />
      ) : (
        <SelectComponent
          ref={(ref: Creatable<SelectOptionType> | ReactSelect<SelectOptionType> | null) => {
            if (isCreatable) {
              creatableRef.current = ref as Creatable<SelectOptionType>
            } else {
              selectRef.current = ref as ReactSelect<SelectOptionType>
            }
          }}
          formatGroupLabel={formatGroupLabel}
          isHovered={isHovered}
          {...commonProps}
          {...others}
        />
      ))}
    </SelectWrapper>
  )
}

export default Select

export type {
  SelectOptionsType,
  SelectOptionType,
  SelectProps
}
