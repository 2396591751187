import React from 'react'

import * as mixins from 'styles/mixins'
import Card from 'components/card/Card'
import IconButton from 'components/buttons/IconButton'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import { colorVars } from 'styles/theme'
import type { CardProps } from 'components/card/Card'
import type { MediaCardProps } from 'components/mediaCard/MediaCard'

type IconCardProps = Omit<CardProps, 'height' | 'width'> & {
  active?: boolean,
  disabled?: boolean,
  height?: CardProps['height'],
  icon: string,
  label: string,
  width?: CardProps['width'],
  actions?: MediaCardProps['actions']
}

const ICON_CARD_SIZE = 130

const StyledIconCard = styled(Card, {
  ...mixins.transition('fluid'),

  alignItems: 'center',
  borderColor: 'transparent',
  borderStyle: 'solid',
  borderWidth: 1,
  position: 'relative',

  'fieldset[disabled] &': {
    pointerEvents: 'none'
  },

  '& [data-card-icon]': {
    ...mixins.transition('fluid'),

    color: 'dark100'
  },

  '& [data-label]': {
    ...mixins.transition('fluid'),

    color: 'dark900'
  },

  variants: {
    active: {
      true: {
        borderColor: 'dark700',
        '& [data-card-icon]': {
          color: 'primary400'
        }
      }
    },
    disabled: {
      true: {
        '&&': {
          cursor: 'not-allowed',
          '&:hover': {
            ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1)
          }
        },
        '& [data-card-icon]': {
          color: 'light700'
        },
        '& [data-label]': {
          color: 'dark300'
        }
      },
      false: {
        '&:focus': {
          '& [data-card-icon]': {
            color: 'primary400'
          },
          '& [data-icon]': {
            color: 'dark600'
          }
        },
        '&:hover': {
          ...mixins.shadow('medium', colorVars.dark600rgb, 0.3),

          '& [data-card-icon]': {
            color: 'primary400'
          },
          '& [data-icon]': {
            color: 'dark600'
          }
        }
      }
    }
  }
})

StyledIconCard.compoundVariant({
  active: true,
  disabled: true
}, {
  borderColor: 'dark400',
  '& [data-card-icon]': {
    color: 'primary100'
  }
})

StyledIconCard.defaultProps = {
  as: 'button',
  type: 'button'
}

const IconCard = React.forwardRef(({
  active = false, disabled = false, icon, label, actions, ...other
}: IconCardProps, ref: any) => (
  <StyledIconCard
    disabled={disabled}
    active={active}
    height={ICON_CARD_SIZE}
    justifyContent="center"
    width={ICON_CARD_SIZE}
    ref={ref}
    {...other}
  >
    <Flex direction="column" alignItems="center" justifyContent="center" gap={10}>
      <Icon data-card-icon name={icon} size={32} />
      <Text data-label fontSize={14} fontWeight="bold">{label}</Text>
    </Flex>
    {actions && (
      <Flex
        css={{
          position: 'absolute'as const,
          top: 0,
          right: 0,
          bottom: 0
        }}
        direction="column"
        justifyContent="space-between"
      >
        {actions.map(({ description, icon, onClick, ...action }) => (
          <IconButton
            // @ts-ignore
            as="div"
            description={description}
            key={icon}
            name={icon}
            hideTooltip={!description}
            onClick={(e) => {
              e.stopPropagation()
              onClick && onClick()
            }}
            variant="dark"
            {...action}
          />
        ))}
      </Flex>
    )}
  </StyledIconCard>
))

export default IconCard
