import React, { Suspense, useCallback } from 'react'

import componentLoader from 'lib/componentLoader'
import FormField from 'components/form/FormField'
import isValidColor from 'lib/formValidators/isValidColor'
import Loader from 'components/loaders/Loader'
import type { ColorInputProps } from 'components/inputs/ColorInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type ColorFieldProps = Omit<ColorInputProps, 'input' | 'meta'> & fieldProps<'color'>

const ColorInput = React.lazy(() => componentLoader('inputs/ColorInput'))

const getColorFieldSchema = (settings: ColorFieldProps['settings'] = {}) => {
  let schema = isValidColor()

  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  return schema
}

const ColorField = ({
  name,
  settings,
  shouldValidate,
  ...others
}: ColorFieldProps) => {
  const validate = useCallback((value: boolean) => getColorFieldSchema(settings)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings ])

  return (
    <Suspense fallback={<Loader loading />}>
      <FormField
        component={ColorInput}
        name={name}
        presetColors={settings?.presetColors}
        showAlpha={settings?.showAlpha}
        {...(shouldValidate && { validate })}
        {...others}
      />
    </Suspense>
  )
}

export type { ColorFieldProps }
export default ColorField
