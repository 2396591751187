import React from 'react'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import SelectInput from 'components/inputs/SelectInput'
import TextInput from 'components/inputs/TextInput'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import type { Attribute } from 'generated/schema'
import { parseAndRenderSync } from 'lib/templater'

type ConfigurationsProps = {
  fieldPrefix?: string
}

const TargetOptions = [
  {
    label: 'Opens in new window',
    value: '_blank'
  },
  {
    label: 'Opens in same window',
    value: '_self'
  }
]

type RendererProps = {
  rowData: any,
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str, ...params)
  } catch (e) {
    console.error(e)

    return str
  }
}

const Renderer = ({ rowData, displaySettings, data }: RendererProps) => {
  const { fontWeight = 'regular' } = displaySettings

  const linkText = displaySettings.text ? safeParseLiquid(displaySettings.text, {
    record: rowData
  }) : 'Link'

  if (data) {
    return <TextLink href={data} target={displaySettings.target}>{linkText}</TextLink>
  }

  return (
    <Text fontSize={14} fontWeight={fontWeight}>{data || '-'}</Text>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField name={`${fieldPrefix}text`} label="Text" component={TextInput} size="small" />
    <FormField name={`${fieldPrefix}url`} label="URL" component={TextInput} size="small" />
    <FormField
      name={`${fieldPrefix}target`}
      component={SelectInput}
      label="Target"
      size="small"
      options={TargetOptions}
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as LinkRenderer }
