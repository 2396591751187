import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'
import type { AttributesListQuery, ResourceFragmentFragment as ResourceFragment, SortDirection } from 'generated/schema'
import Attribute from './Attribute'
import convertToArray from 'lib/convertToArray'

enum OrderStyle {
  CREATION_TIME = 'CREATION_TIME',
  SPECIFIC_FIELDS = 'SPECIFIC_FIELDS',
  LINEAR_DRAG_AND_DROP = 'LINEAR_DRAG_AND_DROP',
  TREE_DRAG_AND_DROP = 'TREE_DRAG_AND_DROP',
}

enum EnvironmentBehavior {
  NONE = 'NONE',
  ISOLATED = 'ISOLATED'
}

enum GenerateResourceType {
  DATABASE = 'DATABASE',
  API = 'API',
  FILE = 'FILE',
}

const ORDER_STYLE_OPTIONS = [
  { label: 'Creation Time', value: OrderStyle.CREATION_TIME },
  { label: 'Specific Fields', value: OrderStyle.SPECIFIC_FIELDS },
  { label: 'Linear Drag and Drop', value: OrderStyle.LINEAR_DRAG_AND_DROP },
  { label: 'Tree Drag and Drop', value: OrderStyle.TREE_DRAG_AND_DROP }
]

const DIRECTION_OPTIONS: { label: string, value: SortDirection }[] = [
  { label: 'ASC', value: 'ASC' },
  { label: 'DESC', value: 'DESC' }
]

type AttributesList = AttributesListQuery['attributesList']

type ResolveTitleOptions = {
  activeLocale?: string,
  defaultLocale?: string,
  fallbackTitle?: string
}
class Resource extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required()
  })

  static resolveTitle = (
    attributesList?: AttributesList,
    resource?: ResourceFragment,
    options?: ResolveTitleOptions
  ) => {
    if (!attributesList || !resource) {
      return ''
    }

    const {
      activeLocale = Resource.fallbackLocale,
      defaultLocale = Resource.fallbackLocale,
      fallbackTitle
    } = options || {}

    const {
      actualTitleField, potentialTitleField
    } = Attribute.getTitleField(attributesList, resource)

    const titleField = actualTitleField || potentialTitleField

    if (!titleField && fallbackTitle) {
      return fallbackTitle
    }

    const locale = titleField?.isTranslatable ? activeLocale : defaultLocale

    const value = titleField ? get(resource, `data.${camelCase(titleField.identifier)}.${locale}`) : ''

    const title = convertToArray(value)

    return title.filter(Boolean).join(', ')
  }

  static fallbackLocale = 'en_US'
}

export const ATTRIBUTES_LIST_LIMIT = 100
export const OPERATIONS_LIST_LIMIT = 100
export const PARAMETERS_LIST_LIMIT = 100
export const RELATIONSHIPS_LIST_LIMIT = 100
export const RESOURCES_LIST_LIMIT = 100
export const SEGMENTS_LIST_LIMIT = 100

export {
  DIRECTION_OPTIONS,
  EnvironmentBehavior,
  GenerateResourceType,
  ORDER_STYLE_OPTIONS,
  OrderStyle
}

export default Resource
