import get from 'lodash/get'
import has from 'lodash/has'
import React, { useContext, useState } from 'react'
import { Field, Form, FormProps, useForm } from 'react-final-form'
import type { AnyObject, FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import Grid from 'components/layout/Grid'
import IconButton from 'components/buttons/IconButton'
import MediaCard from 'components/mediaCard/MediaCard'
import pascalCase from 'lib/pascalCase'
import RoleModel, { Kind, KIND_ICON_MAP } from 'models/Role'
import RoleView from 'components/views/RoleView'
import SearchBar from 'components/searchbar/SearchBar'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import SearchSelectInput from 'components/inputs/SearchSelectInput'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useFuzzySearch from 'hooks/useFuzzySearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { App, AppListDocument, AppListQuery, AppListQueryVariables, Attribute, AttributesListDocument, AttributesListQuery, AttributesListQueryVariables, CustomRole, DashboardsListDocument, Operation, OperationsListDocument, OperationsListQuery, OperationsListQueryVariables, Resource, ResourcesListDocument, ResourcesListQuery, ResourcesListQueryVariables, RoleQueryVariables, RolesListDocument, RolesListQueryVariables, useCreateRoleMutation, useRolesListQuery, useUpdateRoleMutation } from 'generated/schema'
import { styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'
import type { CreateRoleInput, UpdateRoleInput } from 'generated/schema'
import type { ViewProps } from 'components/views'

type RoleFormValues = CreateRoleInput | UpdateRoleInput

type Params = {
  initialValues?: CustomRole,
  kind?: Kind,
  appId?: App['id'],
  app?: App,
  queryVariables: RolesListQueryVariables | RoleQueryVariables
}

type Permission = {
  id: string,
  identifier: string,
  name: string
}

const NestedBlock = styled(Flex, {
  paddingLeft: 24,
  borderLeft: '2px solid light700'
})

const StyledLabel = styled(Text, {
  fontSize: 12,
  fontWeight: 'bold'
})

const getPermissionsList = (values: AnyObject, key: string) => {
  const permissions = get(values, key)
  if (!permissions) return []
  const hasNestedPermissions = !Object.keys(permissions).includes('*')
  if (!hasNestedPermissions) return []
  return Object.entries(permissions).map(([ key, value ]) => ({
    id: key,
    // eslint-disable-next-line no-underscore-dangle
    name: (value as any)._name,
    // eslint-disable-next-line no-underscore-dangle
    identifier: (value as any)._identifier
  }))
}

const RoleTemplateForm = (
  {
    kind,
    appId,
    setKind,
    setStep
  }: {
    kind?: Kind,
    appId?: App['id'],
    setKind: React.Dispatch<React.SetStateAction<Kind>>,
    setStep: React.Dispatch<React.SetStateAction<number>>
  }
) => {
  const { change } = useForm()
  const {
    data: { rolesList = [] } = {},
    error,
    loading
  } = useRolesListQuery({
    variables: {
      filter: {
        ...(appId && { appId: { eq: appId } }),
        ...(kind && { kind: { eq: kind } })
      },
      limit: 100
    }
  })

  const { matches, handleChange } = useFuzzySearch<CustomRole>(rolesList, { key: 'name' })

  return (
    <Flex direction="column" alignItems="stretch" gap={12}>
      <MediaCard
        width="full"
        media="add-outline-thin"
        title="Create From Scratch"
        onClick={() => setStep((step) => step + 1)}
      />
      <Divider orientation="vertical" variant="ruler" />
      <SearchBar placeholder="Search for a role..." onChange={handleChange} />
      <SectionLoader
        data={rolesList}
        loading={loading}
        error={error}
        empty={{
          title: 'No roles found',
          subtitle: 'No roles found for this app'
        }}
      >
        <Grid columns={1} rowGap={16}>
          {matches.map((role) => (
            <MediaCard
              key={role.id}
              width="full"
              media={KIND_ICON_MAP[role.kind]}
              title={role.name}
              onClick={() => {
                change('policy', role.policy)
                setKind(role.kind as Kind)
                setStep(1)
              }}
            />
          ))}
        </Grid>
      </SectionLoader>
    </Flex>
  )
}

// const RoleTypeForm = (
//   {
//     kind,
//     setKind,
//     setStep
//   }: {
//     kind: Kind,
//     setKind: React.Dispatch<React.SetStateAction<Kind>>,
//     setStep: React.Dispatch<React.SetStateAction<number>>
//   }
// ) => (
//   <Grid columns={2} rowGap={16} columnGap={16}>
//     {WORKSPACE_KIND_OPTIONS.map((k) => (
//       <MediaCard
//         key={k.value}
//         width="full"
//         media={k.icon}
//         title={k.label}
//         active={kind === k.value}
//         onClick={() => {
//           setKind(k.value as Kind)
//           setStep((step) => step + 1)
//         }}
//       />
//     ))}
//   </Grid>
// )

const PERMISSION_OPTIONS = [
  { label: 'Allow', value: 'allow' },
  { label: 'Deny', value: 'deny' }
]

const AttributesPermissionBlock = (
  { resourceId }:
  {
    resourceId: string
   }
) => {
  const { change, getState } = useForm()
  const { values } = getState()
  const permissionsList = getPermissionsList(values, `policy.resources.${resourceId}.attributes`)
  const [ selectedAttributes, setSelectedAttributes ] = useState<Permission[]>(permissionsList)
  const [ isCustomizing, setIsCustomizing ] = useState(!has(values, `policy.resources.${resourceId}.attributes.*`))

  const onDeleteAttributesPolicy = (attribute: Permission) => {
    change(`policy.resources.${resourceId}.attributes.${attribute.id}`, undefined)
    setSelectedAttributes((prev) => prev.filter((attr) => attr.id !== attribute.id))
  }

  return (
    <Flex direction="column" gap={16}>
      <Flex alignItems="center" justifyContent="space-between">
        <StyledLabel>All Attributes</StyledLabel>
        <Flex gap={16} alignItems="center" alignSelf="stretch">
          <IconButton
            name={isCustomizing ? 'close' : 'edit'}
            description={isCustomizing ? 'Close' : 'Customize'}
            size={16}
            variant="dark"
            onClick={() => {
              setIsCustomizing((prev) => {
                if (!prev) {
                  change(`policy.resources.${resourceId}.attributes.*`, undefined)
                } else {
                  change(`policy.resources.${resourceId}.attributes.*.grant`, 'allow')
                }
                return !prev
              })
            }}
          />
          {!isCustomizing && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <Field
                name={`policy.resources.${resourceId}.attributes.*.grant`}
                component={ButtonGroupInput}
                options={PERMISSION_OPTIONS}
                size="small"
              />
            </>
          )}
        </Flex>
      </Flex>
      {isCustomizing && (
        <NestedBlock direction="column" gap={16}>
          <Flex gap={12} direction="column">
            <SearchSelectInput<AttributesListQuery, AttributesListQueryVariables>
              isSearchable
              preload
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              query={AttributesListDocument}
              dataKey="attributesList"
              keys={[ 'name' ]}
              isOptionDisabled={(attribute: Attribute) => (
                selectedAttributes.filter((attr) => attr.id === attribute.id).length > 0
              )}
              setValueAsObject
              input={{
                onChange: (attribute: Attribute) => {
                  if (!attribute) return
                  setSelectedAttributes((prev) => ([
                    ...prev,
                    { id: attribute.id, name: attribute.name, identifier: attribute.identifier }
                  ]))
                  change(`policy.resources.${resourceId}.attributes.${attribute.id}.grant`, 'allow')
                }
              }}
              queryOptions={{
                variables: {
                  filter: {
                    resourceId: { eq: resourceId }
                  }
                }
              }}
            />
            {selectedAttributes.map(
              (attribute: Permission) => attribute && (
                <CustomizeAttribute
                  resourceId={resourceId}
                  attribute={attribute}
                  onRemove={onDeleteAttributesPolicy}
                />
              )
            )}
          </Flex>
        </NestedBlock>
      )}
    </Flex>
  )
}

const OperationsPermissionBlock = (
  { resourceId }:
  {
    resourceId: string
   }
) => {
  const { change, getState } = useForm()
  const { values } = getState()
  const permissionsList = getPermissionsList(values, `policy.resources.${resourceId}.operations`)
  const [ selectedOperations, setSelectedOperations ] = useState<Permission[]>(permissionsList)
  const [ isCustomizing, setIsCustomizing ] = useState(!has(values, `policy.resources.${resourceId}.operations.*`))

  const onDeleteOperationPolicy = (operation: Permission) => {
    change(`policy.resources.${resourceId}.operations.${operation.id}`, undefined)
    setSelectedOperations((prev) => prev.filter((op) => op.id !== operation.id))
  }

  return (
    <Flex direction="column" gap={16}>
      <Flex alignItems="center" justifyContent="space-between">
        <StyledLabel>All Operations</StyledLabel>
        <Flex gap={16} alignItems="center" alignSelf="stretch">
          <IconButton
            name={isCustomizing ? 'close' : 'edit'}
            description={isCustomizing ? 'Close' : 'Customize'}
            size={16}
            variant="dark"
            onClick={() => {
              setIsCustomizing((prev) => {
                if (!prev) {
                  change(`policy.resources.${resourceId}.operations.*`, undefined)
                } else {
                  change(`policy.resources.${resourceId}.operations.*.grant`, 'allow')
                }
                return !prev
              })
            }}
          />
          {!isCustomizing && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <Field
                name={`policy.resources.${resourceId}.operations.*.grant`}
                component={ButtonGroupInput}
                options={PERMISSION_OPTIONS}
                size="small"
              />
            </>
          )}
        </Flex>
      </Flex>
      {isCustomizing && (
        <NestedBlock direction="column" gap={16}>
          <Flex gap={12} direction="column">
            <SearchSelectInput<OperationsListQuery, OperationsListQueryVariables>
              isSearchable
              preload
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              query={OperationsListDocument}
              dataKey="operationsList"
              keys={[ 'name' ]}
              isOptionDisabled={(operation: Operation) => (
                selectedOperations.filter((op) => op.id === operation.id).length > 0
              )}
              setValueAsObject
              input={{
                onChange: (operation: Operation) => {
                  if (!operation) return
                  setSelectedOperations((prev) => ([
                    ...prev,
                    { id: operation.id, name: operation.name, identifier: operation.identifier }
                  ]))
                  change(`policy.resources.${resourceId}.operations.${operation.id}.grant`, 'allow')
                }
              }}
              queryOptions={{
                variables: {
                  filter: {
                    resourceId: { eq: resourceId }
                  }
                }
              }}
            />
            {selectedOperations.map(
              (operation: Permission) => (
                <CustomizeOperations
                  name={`policy.resources.${resourceId}.operations.${operation.id}.grant`}
                  operation={operation}
                  onRemove={onDeleteOperationPolicy}
                />
              )
            )}
          </Flex>
        </NestedBlock>
      )}
    </Flex>
  )
}

const CustomizeAttribute = (
  { resourceId, attribute, onRemove }:
  { resourceId: string, attribute: Permission, onRemove: (attribute: Permission) => void }
) => (
  <Flex alignItems="center" justifyContent="space-between">
    <StyledLabel>{attribute.name}</StyledLabel>
    <Flex gap={16} alignItems="center" alignSelf="stretch">
      <IconButton
        name="trash"
        size={16}
        description="Delete"
        variant="dark"
        onClick={() => onRemove(attribute)}
      />
      <Divider orientation="vertical" variant="ruler" />
      <Field
        name={`policy.resources.${resourceId}.attributes.${attribute.id}.grant`}
        component={ButtonGroupInput}
        options={PERMISSION_OPTIONS}
        size="small"
      />
    </Flex>
  </Flex>
)

const CustomizeOperations = (
  { name, operation, onRemove }:
  { name: string, operation: Permission, onRemove: (operation: Permission) => void }
) => (
  <Flex alignItems="center" justifyContent="space-between">
    <StyledLabel>{operation.name}</StyledLabel>
    <Flex gap={16} alignItems="center" alignSelf="stretch">
      <IconButton
        name="trash"
        size={16}
        description="Delete"
        variant="dark"
        onClick={() => onRemove(operation)}
      />
      <Divider orientation="vertical" variant="ruler" />
      <Field
        name={name}
        component={ButtonGroupInput}
        options={PERMISSION_OPTIONS}
        size="small"
      />
    </Flex>
  </Flex>
)

const CustomizeResource = (
  { resource, onRemove }: { resource: Permission, onRemove: (resource: Permission) => void }
) => (
  <Flex direction="column" gap={12}>
    <Flex gap={8} alignItems="center" justifyContent="space-between">
      <StyledLabel>{resource.name}</StyledLabel>
      <IconButton
        name="trash"
        size={16}
        description="Delete"
        variant="dark"
        onClick={() => onRemove(resource)}
      />
    </Flex>
    <AttributesPermissionBlock resourceId={resource.id} />
    <OperationsPermissionBlock resourceId={resource.id} />
  </Flex>
)

const RoleDetailsForm = ({ app, kind }: { app?: App, kind: Kind }) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const { change, getState } = useForm()
  const { values } = getState()
  const resourcePermissionsList = getPermissionsList(values, 'policy.resources')
  const operationPermissionsList = getPermissionsList(values, 'policy.operations')
  const [
    selectedResources, setSelectedResources
  ] = useState<Permission[]>(resourcePermissionsList)
  const [
    selectedOperations, setSelectedOperations
  ] = useState<Permission[]>(operationPermissionsList)
  const [ isCustomizingResources, setIsCustomizingResources ] = useState(!has(values, 'policy.resources.*'))
  const [ isCustomizingOperations, setIsCustomizingOperations ] = useState(!has(values, 'policy.operations.*'))

  const onDeleteResourcePolicy = (resource: Permission) => {
    change(`policy.resources.${resource.id}`, undefined)
    setSelectedResources((prev) => prev.filter((res) => res.id !== resource.id))
  }

  const onDeleteOperationPolicy = (operation: Permission) => {
    change(`policy.operations.${operation.id}`, undefined)
    setSelectedOperations((prev) => prev.filter((op) => op.id !== operation.id))
  }

  return (
    <Flex direction="column" gap={16}>
      <Text fontWeight="bold">Details</Text>
      <Field autoFocus name="name" label="Name" component={TextInput} size="small" type="text" />
      {kind === Kind.APP && (
        <SearchSelectField<AppListQuery, AppListQueryVariables>
          isSearchable
          preload
          isDisabled={Boolean(app)}
          name="appId"
          label="App"
          prependIcon="search"
          placeholder="Start typing to search"
          size="small"
          variant="light"
          labelKey="name"
          metaKey="identifier"
          valueKey="id"
          options={app ? [ app ] : []}
          query={AppListDocument}
          dataKey="appsList"
          keys={[ 'name' ]}
          queryOptions={{
            variables: {
              filter: { kind: { eq: 'EXTENSION' } },
              order: [ { name: 'asc' } ]
            }
          }}
        />
      )}
      <Text fontWeight="bold">Permissions</Text>
      <Flex direction="column" gap={16}>
        <Flex alignItems="center" justifyContent="space-between">
          <StyledLabel>All Resources</StyledLabel>
          <Flex gap={16} alignItems="center" alignSelf="stretch">
            <IconButton
              name={isCustomizingResources ? 'close' : 'edit'}
              description={isCustomizingResources ? 'Close' : 'Customize'}
              size={16}
              variant="dark"
              onClick={() => {
                setIsCustomizingResources((prev) => {
                  if (!prev) {
                    change('policy.resources.*', undefined)
                  } else {
                    change('policy.resources.*.', {
                      attributes: { grant: 'allow' },
                      operations: { grant: 'allow' }
                    })
                  }
                  return !prev
                })
              }}
            />
            {!isCustomizingResources && (
              <>
                <Divider orientation="vertical" variant="ruler" />
                <FormValuesField fieldNames={[ 'policy' ]}>
                  {(values) => {
                    const resourcePermission = get(values, 'policy.resources.*.attributes.*.grant')
                    return (
                      <ButtonGroupInput
                        meta={{}}
                        options={PERMISSION_OPTIONS}
                        size="small"
                        input={{
                          name: '',
                          value: resourcePermission,
                          onFocus: () => {},
                          onBlur: () => {},
                          onChange: (value) => {
                            change('policy.resources.*', {
                              attributes: { '*': { grant: value } },
                              operations: { '*': { grant: value } }
                            })
                          }
                        }}
                      />
                    )
                  }}
                </FormValuesField>
              </>
            )}
          </Flex>
        </Flex>
        {isCustomizingResources && (
          <NestedBlock direction="column" gap={16}>
            <Flex gap={12} direction="column">
              <SearchSelectInput<ResourcesListQuery, ResourcesListQueryVariables>
                isSearchable
                preload
                prependIcon="search"
                placeholder="Start typing to search"
                size="small"
                variant="light"
                labelKey="name"
                metaKey="identifier"
                valueKey="id"
                query={ResourcesListDocument}
                dataKey="resourcesList"
                keys={[ 'name' ]}
                isOptionDisabled={(resource: Resource) => (
                  selectedResources.filter((res) => res.id === resource.id).length > 0
                )}
                setValueAsObject
                input={{
                  onChange: (resource: Resource) => {
                    if (!resource) return
                    setSelectedResources((prev) => ([
                      ...prev,
                      { id: resource.id, name: resource.name, identifier: resource.identifier }
                    ]))
                    change('policy.resources', {
                      [resource.id]: {
                        attributes: { '*': { grant: 'allow' } },
                        operations: { '*': { grant: 'allow' } }
                      }
                    })
                  }
                }}
                queryOptions={{
                  variables: {
                    filter: {
                      ...(kind === 'WORKSPACE' && { workspaceId: { eq: currentWorkspace.id } }),
                      ...(kind === 'APP' && { appId: { eq: values.appId } })
                    },
                    order: [ { name: 'asc' } ]
                  }
                }}
              />
              {selectedResources.map(
                (resource: any) => (
                  <CustomizeResource resource={resource} onRemove={onDeleteResourcePolicy} />
                )
              )}
            </Flex>
          </NestedBlock>
        )}
      </Flex>
      <Flex direction="column" gap={16}>
        <Flex alignItems="center" justifyContent="space-between">
          <StyledLabel>All Operations</StyledLabel>
          <Flex gap={16} alignItems="center" alignSelf="stretch">
            <IconButton
              name={isCustomizingOperations ? 'close' : 'edit'}
              description={isCustomizingOperations ? 'Close' : 'Customize'}
              size={16}
              variant="dark"
              onClick={() => {
                setIsCustomizingOperations((prev) => {
                  if (!prev) {
                    change('policy.operations.*', undefined)
                  } else {
                    change('policy.operations.*.grant', 'allow')
                  }
                  return !prev
                })
              }}
            />
            {!isCustomizingOperations && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <FormValuesField fieldNames={[ 'policy' ]}>
                {(values) => {
                  const opertaionPermission = get(values.policy, 'operations.*.grant')
                  return (
                    <ButtonGroupInput
                      meta={{}}
                      options={PERMISSION_OPTIONS}
                      size="small"
                      input={{
                        name: '',
                        value: opertaionPermission,
                        onFocus: () => {},
                        onBlur: () => {},
                        onChange: (value) => {
                          change('policy.operations.*.grant', value)
                        }
                      }}
                    />
                  )
                }}
              </FormValuesField>
            </>
            )}
          </Flex>
        </Flex>
        {isCustomizingOperations && (
          <NestedBlock direction="column" gap={16}>
            <Flex gap={12} direction="column">
              <SearchSelectInput<OperationsListQuery, OperationsListQueryVariables>
                isSearchable
                preload
                prependIcon="search"
                placeholder="Start typing to search"
                size="small"
                variant="light"
                labelKey="name"
                metaKey="identifier"
                valueKey="id"
                query={OperationsListDocument}
                dataKey="operationsList"
                keys={[ 'name' ]}
                isOptionDisabled={(operation: Operation) => (
                  selectedOperations.filter((op) => op.id === operation.id).length > 0
                )}
                setValueAsObject
                input={{
                  onChange: (operation: Operation) => {
                    if (!operation) return
                    setSelectedOperations((prev) => ([
                      ...prev,
                      { id: operation.id, name: operation.name, identifier: operation.identifier }
                    ]))
                    change(`policy.operations.${operation.id}.grant`, 'allow')
                  }
                }}
                queryOptions={{
                  variables: {
                    filter: {
                      ...(kind === 'WORKSPACE' && { workspaceId: { eq: currentWorkspace.id } }),
                      ...(kind === 'APP' && { appId: { eq: values.appId } })
                    },
                    order: [ { name: 'asc' } ]
                  }
                }}
              />
              {selectedOperations.map(
                (operation: any) => (
                  <CustomizeOperations name={`policy.operations.${operation.id}.grant`} operation={operation} onRemove={onDeleteOperationPolicy} />
                )
              )}
            </Flex>
          </NestedBlock>
        )}
      </Flex>
    </Flex>
  )
}

function AddRoleView({
  closeView,
  onRequestClose,
  params: { initialValues, queryVariables, kind: initialKind, app, appId },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = initialValues?.id
  const { openView } = useViewDispatch()
  const [ kind, setKind ] = useState<Kind>(initialKind || Kind.WORKSPACE)
  const [ step, setStep ] = useState(isUpdating ? 1 : 0)

  const [ createRole ] = useCreateRoleMutation({
    onCompleted: (data) => {
      onRequestClose()
      closeView()
      openView({
        title: `Role: ${data.createRole.name}`,
        component: RoleView,
        style: RoleView.defaultStyle,
        params: {
          role: data.createRole
        }
      })
    },
    refetchQueries: [
      { query: RolesListDocument, variables: queryVariables }
    ]
  })
  const [ updateRole ] = useUpdateRoleMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: RolesListDocument, variables: queryVariables }
    ]
  })

  const handleCreateRoleSubmit = useSubmitHandler(createRole, {
    update: {
      strategy: 'APPEND',
      query: RolesListDocument,
      queryVariables,
      dataKey: 'rolesList',
      mutation: 'createRole'
    }
  })

  const handleUpdateRoleSubmit = useSubmitHandler(updateRole, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateRole',
      typename: 'Role',
      override: (values: UpdateRoleInput) => ({
        ...initialValues,
        ...values
      })
    }
  })

  const handleRoleFormSubmit = (values: RoleFormValues, form: FormProps<RoleFormValues>['form']) => {
    if ('id' in values) {
      return handleUpdateRoleSubmit({
        ...values,
        policy: {
          ...values.policy,
          version: values?.policy?.version + 1
        }
      }, form as FormApi<UpdateRoleInput>)
    }

    return handleCreateRoleSubmit(values as CreateRoleInput)
  }

  const title = isUpdating ? `Edit ${pascalCase(kind)} Role` : `New ${pascalCase(kind)} Role`
  const subtitle = step === 0 ? 'Step 1: Select a template' : 'Step 2: Enter Details'

  const formInitialValues = {
    appId,
    kind,
    policy: {
      version: 1,
      resources: { '*': {
        attributes: { '*': { grant: 'deny' } },
        operations: { '*': { grant: 'deny' } }
      } },
      operations: { '*': { grant: 'deny' } },
      ...initialValues?.policy
    },
    ...initialValues
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, SubHeader, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <SubHeader>
            <Text fontWeight="bold">{subtitle}</Text>
          </SubHeader>
          <Form
            initialValues={formInitialValues as RoleFormValues}
            validate={(values) => RoleModel.validate(values, [ 'name' ])}
            subscription={{ submitting: true, pristine: true }}
            onSubmit={handleRoleFormSubmit}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <form onSubmit={handleSubmit}>
                    <Field name="name" component="input" type="hidden" />
                    <Field name="kind" component="input" type="hidden" />
                    <Field name="policy" component="input" type="hidden" />

                    {step === 0 && (
                      <RoleTemplateForm
                        kind={kind}
                        appId={appId}
                        setKind={setKind}
                        setStep={setStep}
                      />
                    )}
                    {step === 1 && (
                      <RoleDetailsForm kind={kind} app={app} />
                    )}
                  </form>
                </Body>
                <Footer>
                  <Flex gap={24} grow={1} direction={step === 0 ? 'row-reverse' : 'row'} justifyContent="space-between">
                    {step !== 0 && <Button label="Back" size="small" icon="arrow-left" iconSize={12} mode="subtle" onClick={() => setStep((step) => step - 1)} />}
                    {step === 1 && (
                      <Button type="submit" label="Save" size="small" disabled={submitting || pristine} onClick={handleSubmit} />
                    )}
                  </Flex>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

export default AddRoleView
