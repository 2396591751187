import React from 'react'

import Divider from 'components/divider/Divider'
import { INNER_CONTAINER_PADDING } from 'components/dashboardEditor/constants'
import { SIDE_PANE_PADDING_X } from './constants'
import { styled } from 'styles/stitches'

const StyledSidePaneDivider = styled('div', {
  marginX: -SIDE_PANE_PADDING_X,

  variants: {
    size: {
      small: {
        marginX: -INNER_CONTAINER_PADDING
      },
      normal: {}
    }
  }
})

type SidePaneDividerProps = React.PropsWithChildren<{
  size?: 'normal' | 'small'
}>

function SidePaneDivider({ size }: SidePaneDividerProps) {
  return (
    <StyledSidePaneDivider size={size}>
      <Divider />
    </StyledSidePaneDivider>
  )
}

export default SidePaneDivider
