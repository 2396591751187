import React, { useEffect, useRef, useState } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

type CodeInputProps = FieldRenderProps<string, any> & {
  maxDigits: number,
  disabled?: boolean
}

const StyledCodeInput = styled(Flex, {
  ...mixins.transition('simple'),

  backgroundColor: 'light100',
  borderRadius: 4,
  paddingY: 20,
  variants: {
    disabled: {
      true: {
        backgroundColor: 'light400',
        cursor: 'not-allowed'
      }
    }
  }
})

const StyledBox = styled(Flex, {
  borderRight: '1px solid light700',
  textAlign: 'center',

  ':last-of-type': {
    borderRight: 0
  }
})

const StyledDigitInput = styled('input', {
  backgroundColor: 'transparent',
  border: 0,
  color: 'dark900',
  fontFamily: 'normal',
  fontSize: 16,
  fontWeight: 'regular',
  lineHeight: 'normal',
  paddingY: 10,
  textAlign: 'center',
  width: '100%',

  ':focus': {
    fontWeight: 'bold'
  },

  '::selection': {
    backgroundColor: 'transparent'
  }
})

const StyledSeparator = styled('div', {
  size: [ 15, 2 ],
  backgroundColor: 'light700',
  variants: {
    active: {
      true: {
        backgroundColor: 'positive300'
      }
    }
  }
})

function CodeInput({ maxDigits, disabled, input, meta }: CodeInputProps) {
  const digitInputs: React.MutableRefObject<Array<HTMLInputElement>> = useRef([])
  const [ active, setActive ] = useState(false)
  const boxesCount = maxDigits + 1

  useEffect(() => {
    digitInputs.current[0].focus()
  }, [ digitInputs ])

  const getCode = () => digitInputs.current.map((digitInput) => digitInput.value).join('')

  const checkCode = () => {
    const code = getCode()
    setActive(code.length > 0)

    input.onChange(code)
  }

  const digitInputDidFocus = (e: React.FocusEvent<HTMLInputElement>) => e.currentTarget.select()

  const digitInputDidClick = (e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.select()

  const digitInputDidChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { length } = e.currentTarget.value

    if (length >= 1) {
      focusNextDigitInput(e.currentTarget)
    }

    checkCode()
  }

  const digitInputDidKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowLeft') {
      focusPreviousDigitInput(e.currentTarget)
    } else if (e.key === 'ArrowRight') {
      focusNextDigitInput(e.currentTarget)
    }
  }

  const digitInputDidKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const disallowedKeys = [
      'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight',
      '+', '-', '.', 'e'
    ]

    if (disallowedKeys.includes(e.key)) {
      e.preventDefault()
      return
    }

    if (e.key === 'Backspace' && e.currentTarget.value.length === 0) {
      focusPreviousDigitInput(e.currentTarget)
      return
    }

    if (e.key === e.currentTarget.value) {
      e.preventDefault()
      digitInputDidChange(e)
      return
    }

    const numericKeys = [
      '1', '2', '3', '4', '5',
      '6', '7', '8', '9', '0'
    ]

    if (numericKeys.includes(e.key) && e.currentTarget.value.length !== 0) {
      e.currentTarget.select()
    }
  }

  const digitInputDidPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const possibleCode = (e.clipboardData.getData('Text') || '')
      .trim() // trim whitespaces
      .split('') // split into characters
      .filter((c: string) => !Number.isNaN(parseInt(c, 10))) // filter only numeric

    if (possibleCode.length > maxDigits) {
      return
    }

    let currentLetterIndex = 0
    let currentIndex = digitInputs.current.indexOf(e.currentTarget)
    while (
      currentIndex < maxDigits
      && currentLetterIndex < possibleCode.length
    ) {
      digitInputs.current[currentIndex].focus()
      digitInputs.current[currentIndex].value = possibleCode[currentLetterIndex]
      currentIndex += 1
      currentLetterIndex += 1
    }

    checkCode()
  }

  const focusPreviousDigitInput = (current: HTMLInputElement) => {
    const currentIndex = digitInputs.current.indexOf(current)
    if (currentIndex > 0) {
      digitInputs.current[currentIndex - 1].focus()
    } else {
      digitInputs.current[currentIndex].select()
    }
  }

  const focusNextDigitInput = (current: HTMLInputElement) => {
    const currentIndex = digitInputs.current.indexOf(current)
    if (currentIndex < maxDigits - 1) {
      digitInputs.current[currentIndex + 1].focus()
    } else {
      digitInputs.current[currentIndex].blur()
    }
  }

  const renderDigitInput = (index: number) => (
    <StyledDigitInput
      disabled={disabled}
      type="number"
      min="0"
      max="9"
      ref={(el: HTMLInputElement) => { digitInputs.current[index] = el }}
      onFocus={digitInputDidFocus}
      onClick={digitInputDidClick}
      onChange={digitInputDidChange}
      onKeyUp={digitInputDidKeyUp}
      onKeyDown={digitInputDidKeyDown}
      onPaste={digitInputDidPaste}
    />
  )

  const renderSeparator = () => (
    <StyledSeparator active={active} />
  )

  const renderBox = (index: number, children: React.ReactNode) => (
    <StyledBox
      key={index}
      alignItems="center"
      justifyContent="center"
      style={{ width: `${100 / boxesCount}%` }}
    >
      {children}
    </StyledBox>
  )

  const renderBoxes = () => {
    const boxes = []

    for (let index = 0; index < boxesCount; ++index) {
      const midIndex = Math.floor(boxesCount / 2)

      if (index === midIndex) {
        boxes.push(renderBox(index, renderSeparator()))
      } else {
        const digitInputIndex = index < midIndex ? index : index - 1
        boxes.push(renderBox(index, renderDigitInput(digitInputIndex)))
      }
    }

    return boxes
  }

  if (meta.submitFailed) {
    const lastIndex = digitInputs.current.length - 1
    digitInputs.current[lastIndex].focus()
  }

  return (
    <StyledCodeInput disabled={disabled} grow={1}>
      {renderBoxes()}
    </StyledCodeInput>
  )
}

CodeInput.defaultProps = {
  disabled: false
}

export type { CodeInputProps }

export default CodeInput
