import classNames from 'classnames'
import React from 'react'
import type { ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import { css, styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'

const TOPBAR_ITEM_SPACING_X = 10 as const

const highlightedStyle = {
  '&&': {
    color: 'dark900'
  }
}

const StyledTopbarItem = styled(Flex, {
  backgroundColor: 'inherit',
  color: 'dark100',
  height: '100%',
  paddingX: TOPBAR_ITEM_SPACING_X,

  '&:hover': {
    willChange: 'transform'
  }
})

const classes = {
  topbarItem_interactive: css({
    ...mixins.transition('fluid'),

    cursor: 'pointer',

    '& [data-icon]': {
      ...mixins.transition('fluid')
    },

    '&:hover': highlightedStyle
  }),
  topbarItem_active: css(highlightedStyle)
}

type ChildrenRenderProps = {
  topbarItemClassNames: typeof classes
}

type ChildrenFunction = (props: ChildrenRenderProps) => ReactNode

type TopbarItemProps = FlexProps & {
  children?: ReactNode | ChildrenFunction,
  className?: string,
  isActive?: boolean,
  isDragging?: boolean,
  isInteractive?: boolean
}

const TopbarItem = React.forwardRef<HTMLDivElement, TopbarItemProps>(({
  children, className, isActive, isDragging, isInteractive = true, ...props
}, ref) => {
  const linkProps = props.to ? { activeClassName: classes.topbarItem_active } : {}

  return (
    <StyledTopbarItem
      className={classNames(
        {
          [classes.topbarItem_interactive]: isInteractive,
          [classes.topbarItem_active]: isActive || isDragging
        },
        className
      )}
      alignItems="center"
      gap={16}
      justifyContent="center"
      ref={ref}
      {...linkProps}
      {...props}
    >
      {children}
    </StyledTopbarItem>
  )
})

TopbarItem.displayName = 'TopbarItem'

export default TopbarItem

export { TOPBAR_ITEM_SPACING_X }

export type { ChildrenRenderProps as TopbarItemRenderProps }
