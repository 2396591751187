import rafSchd from 'raf-schd'
import React, { useMemo } from 'react'
import { colord } from 'colord'
import { HexColorPicker, RgbaStringColorPicker } from 'react-colorful'
import type { ChangeEvent } from 'react'

import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import { Popover, PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'
import type { TextInputProps } from 'components/inputs/TextInput'

type ColorInputProps = TextInputProps & {
  onChangeComplete?: (color: string) => void,
  onHideColorPicker?: () => void,
  onShowColorPicker?: () => void,
  showAlpha?: boolean,
  presetColors?: string[]
}

const DEFAULT_COLOR = '#ffffff'

const StyledColorPicker = styled(Popover, {
  zIndex: 'popover'
})

const StyledIndicator = styled('div', {
  variants: {
    size: {
      small: {
        borderRadius: 10,
        size: [ 25 ]
      },
      normal: {
        borderRadius: 16,
        size: [ 35 ]
      },
      large: {
        borderRadius: 20,
        size: [ 45 ]
      }
    }
  }
})

const PresetsContainer = styled('div', {
  backgroundColor: '#fafafa',
  display: 'flex',
  flexWrap: 'wrap',
  padding: 12,
  width: 200
})

const Preset = styled('button', {
  borderRadius: 4,
  boxShadow: 'inset 0 0 0 1px #33333359',
  cursor: 'pointer',
  margin: 5,
  outline: 'none',
  padding: 0,
  size: [ 25 ]
})

const convertToHex8 = (rgba: string) => colord(rgba).toHex()

function ColorInput({
  input: { value, onChange, ...otherInputs },
  onChangeComplete = () => null,
  onHideColorPicker = () => null,
  onShowColorPicker = () => null,
  showAlpha = false,
  presetColors = [],
  size = 'normal',
  ...others
}: ColorInputProps) {
  const defaultValue = value || DEFAULT_COLOR

  const rgbaString = useMemo(() => (defaultValue.startsWith('rgba') ? defaultValue : colord(defaultValue).toRgbString()), [ defaultValue ])

  const handleRgbaColorChange = rafSchd((rgba: string) => {
    const hex = convertToHex8(rgba)

    onChange(hex)
    onChangeComplete(hex)
  })

  const normalizeHexCode = (colorVal: string) => {
    if (!colorVal) return colorVal
    if (colorVal.startsWith('#')) return showAlpha ? colorVal.slice(0, 9) : colorVal.slice(0, 7)
    if (!colord(colorVal).isValid()) return colorVal

    return `#${colorVal}`
  }

  const filteredPresets = presetColors.filter(Boolean)

  return (
    <Flex direction="column" gap={10} grow={1}>
      <PopoverContainer
        openOn="focus"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [ 0, 6 ]
            }
          }
        ]}
        onPopoverClose={onHideColorPicker}
        onPopoverOpen={onShowColorPicker}
        placement="bottom-end"
      >
        {({ isActive, openPopover, closePopover, ...otherToggleProps }) => (
          <TextInput
            appendNode={<StyledIndicator size={size} style={{ backgroundColor: value }} />}
            input={{
              value,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                onChange(normalizeHexCode(e.target.value))
                onChangeComplete(normalizeHexCode(e.target.value))
              },
              ...otherInputs
            }}
            size={size}
            {...others}
            {...otherToggleProps}
          />
        )}
        {(popoverProps) => (
          <StyledColorPicker {...popoverProps}>
            {showAlpha
              ? (
                <RgbaStringColorPicker
                  color={rgbaString}
                  onChange={handleRgbaColorChange}
                />
              )
              : (
                <HexColorPicker
                  color={defaultValue}
                  onChange={(hex) => {
                    onChange(hex)
                    onChangeComplete(hex)
                  }}
                />
              )}

            {!!filteredPresets.length && (
              <PresetsContainer>
                {filteredPresets.map((presetColor, index) => (
                  <Preset
                    aria-label="button"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    type="button"
                    style={{
                      backgroundColor: presetColor
                    }}
                    onClick={() => {
                      onChange(presetColor)
                      onChangeComplete(presetColor)
                    }}
                  />
                ))}
              </PresetsContainer>
            )}
          </StyledColorPicker>
        )}
      </PopoverContainer>
    </Flex>
  )
}

export default ColorInput

export type { ColorInputProps }
