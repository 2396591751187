import type { FunctionComponent, ReactNode } from 'react'

import type { BaseModalProps, ModalRenderProps } from 'components/modal'
import type { DialogRenderProps } from 'components/dialog/Dialog'
import type { SidePaneRenderProps } from 'components/sidePane/SidePane'
import type { View } from 'components/providers/ViewProvider'

export type ViewStyleComponentRenderProps =
  DialogRenderProps
  | SidePaneRenderProps
  | ModalRenderProps

export type ViewProps<P extends Record<any, any>> = Omit<BaseModalProps, 'contentLabel'> & {
  closeView: (index?: number) => void,
  openView: (view: View) => void,
  params: P,
  viewStyleComponent: FunctionComponent<BaseModalProps & {
    children: (props: ViewStyleComponentRenderProps) => ReactNode
  }>
}

export type ViewComponent<P> = FunctionComponent<ViewProps<P>>

export { default as AddAccountView } from 'components/views/AddAccountView'
export { default as AddAppView } from 'components/views/AddAppView'
export { default as AddMenuGroupView } from 'components/views/AddMenuGroupView'
export { default as AddMenuItemView } from 'components/views/AddMenuItemView'
export { default as AttachmentEditView } from 'components/views/AttachmentEditView'
export { default as ConfirmationView } from 'components/views/ConfirmationView'
export { default as GroupFormView } from 'components/views/GroupFormView'
export { default as InstallAppView } from 'components/views/InstallAppView'
export { default as ThemeFormView } from 'components/views/ThemeFormView'
