import React from 'react'

import CheckboxInput from 'components/inputs/CheckboxInput'
import { styled } from 'styles/stitches'

type ListItemProps = {
  children: React.ReactNode,
  checked?: boolean
}

const LIST_ITEM_BULLET_FONT_SIZE = 24
const LIST_ITEM_BULLET_MARGIN_RIGHT = 15
const LIST_ITEM_DESCRIPTION_MARGIN_LEFT = 24
const LIST_ITEM_MARGIN_BOTTOM = 15

const StyledListItem = styled('li', {
  color: 'dark900',
  fontFamily: 'normal',
  fontSize: 14,
  lineHeight: 'cozy',
  marginBottom: LIST_ITEM_MARGIN_BOTTOM,
  position: 'relative',

  '&:last-child': {
    marginBottom: 0
  },
  variants: {
    regular: {
      true: {
        paddingLeft: LIST_ITEM_DESCRIPTION_MARGIN_LEFT,

        '&::before': {
          color: 'dark100',
          content: '"•"',
          fontSize: LIST_ITEM_BULLET_FONT_SIZE,
          left: 0,
          lineHeight: 1,
          marginRight: LIST_ITEM_BULLET_MARGIN_RIGHT,
          position: 'absolute'
        }
      }
    }
  }
})

function ListItem({ checked, children }: ListItemProps) {
  const isCheckList = typeof checked === 'boolean'

  return (
    <StyledListItem regular={!isCheckList}>
      {isCheckList
        ? <CheckboxInput label={children} checked={checked} />
        : children}
    </StyledListItem>
  )
}

export default ListItem
