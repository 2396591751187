import React from 'react'
import get from 'lodash/get'

import Flex from 'components/layout/Flex'
import { css, styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

const ColorIndicator = styled(Flex, {
  size: [ '24px' ],
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px',
  fontSize: '10px',
  fontWeight: 500,
  borderRadius: '50%'
})

const style = (code: string, margin?: number, zIndex?: number) => css({
  marginLeft: margin,
  zIndex,
  backgroundColor: code,
  border: '2px solid light200'
})

function ColorRenderer<T extends object>({
  dataKey,
  rowData,
  isArray,
  prefix = '',
  suffix = ''
}: RendererOptions<T>) {
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const colorCode = get(rowData, `${prefixContent}${dataKey}${suffixContent}`)

  if (isArray) {
    const extraColors = colorCode?.length - 4
    return (
      <Flex alignItems="center">
        {colorCode.slice(0, 4).map((code: string, index: number) => (
          <ColorIndicator className={style(code, -8, colorCode.length - index)} title={code} />
        ))}
        {extraColors > 0 && (
          <ColorIndicator className={style('dark100', -8, colorCode.length - 5)}>
            +{extraColors}
          </ColorIndicator>
        )}
      </Flex>
    )
  }
  return (
    <ColorIndicator className={style(colorCode)} title={colorCode} />
  )
}

export default ColorRenderer
