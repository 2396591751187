import React from 'react'
import type { FieldRenderProps } from 'react-final-form'
import type { ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'

const FLEX_GAP = 10
const RADIO_INACTIVE_COLOR = 'dark100'
const RADIO_INACTIVE_TEXT_COLOR = 'dark500'
const RADIO_ACTIVE_COLOR = 'dark900'
const RADIO_HOVER_COLOR = 'dark300'
const RADIO_INPUT_SIZE = 16
const RADIO_INPUT_CIRCLE_SIZE = 8

const StyledRadio = styled(Flex, {
  ...mixins.transition('fastIn'),

  background: 'light100',
  borderColor: RADIO_INACTIVE_COLOR,
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: 1,
  padding: 2,
  size: [ RADIO_INPUT_SIZE ],

  '& [data-circle]': {
    ...mixins.transition('fastIn'),

    background: RADIO_ACTIVE_COLOR,
    borderRadius: '50%',
    size: [ RADIO_INPUT_CIRCLE_SIZE ],
    opacity: 0,
    transform: 'scale(0)'
  },

  [`& + ${Text}`]: {
    ...mixins.transition('fastIn'),

    color: RADIO_INACTIVE_TEXT_COLOR,
    userSelect: 'none'
  }

})

const StyledInput = styled('input', {
  hidden: true,

  [`&:focus + ${StyledRadio}`]: {
    ...mixins.transition('simple'),

    borderColor: RADIO_HOVER_COLOR
  }
})

const StyledRadioContainer = styled(Flex, {
  cursor: 'pointer',

  [`&:hover ${StyledRadio}`]: {
    ...mixins.transition('simple'),

    borderColor: RADIO_HOVER_COLOR
  },

  variants: {
    checked: {
      true: {
        [`& ${StyledRadio}`]: {
          borderColor: RADIO_ACTIVE_COLOR,

          '& [data-circle]': {
            opacity: 1,
            ...mixins.transition('fastIn'),

            transform: 'scale(1)'
          },

          [`& + ${Text}`]: {
            color: RADIO_ACTIVE_COLOR
          }
        },

        [`& ${StyledInput}`]: {
          [`&:focus + ${StyledRadio}`]: {
            borderColor: RADIO_ACTIVE_COLOR
          }
        },

        [`&:hover ${StyledRadio}`]: {
          borderColor: RADIO_ACTIVE_COLOR
        }
      }
    },
    disabled: {
      true: {
        cursor: 'auto',

        [`& ${StyledRadio}`]: {
          opacity: 0.3
        },

        [`&:hover ${StyledRadio}`]: {
          borderColor: RADIO_INACTIVE_COLOR
        }
      }
    }
  }
})

const StyledAddon = styled(Flex, {
  paddingLeft: RADIO_INPUT_SIZE + FLEX_GAP
})

type RadioInputProps = FieldRenderProps<string, HTMLInputElement> & {
  align?: FlexProps['alignItems'],
  addon?: ReactNode,
  disabled?: boolean,
  grow?: 0 | 1,
  isTranslatable?: boolean
}

function RadioInput({
  align = 'center', addon, disabled, grow, input, isTranslatable, label, meta, ...other
}: RadioInputProps) {
  const isDisabled = input.disabled || disabled

  return (
    <Flex direction="column" gap={10} grow={grow}>
      <StyledRadioContainer
        disabled={isDisabled}
        alignItems={align}
        as="label"
        display="inline-flex"
        gap={FLEX_GAP}
        checked={input.checked}
      >
        <StyledInput type="radio" disabled={disabled} {...input} {...other} />
        <StyledRadio
          alignItems="center"
          justifyContent="center"
        >
          <div data-circle />
        </StyledRadio>
        {label && (
          <>
            {isTranslatable && <Icon size={12} name="translate" />}
            <Text fontSize={12}>
              {label}
            </Text>
          </>
        )}
      </StyledRadioContainer>
      {addon && input.checked && (
        <StyledAddon grow={1}>
          {addon}
        </StyledAddon>
      )}
    </Flex>
  )
}

export default RadioInput

export type { RadioInputProps }
