import uuid from 'uuid-random'
import React from 'react'
import { diffWords } from 'diff'
import type { Change } from 'diff'

import convertToArray from 'lib/convertToArray'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import useDeepMemo from 'hooks/useDeepMemo'
import type { ComputeDiffProps } from 'components/contentVersion/VersionDiff'

type TextFieldProps = {
  isCurrent?: boolean,
  isPrevious?: boolean,
  diffs: Change[][]
}

function TextField({ isCurrent = false, isPrevious = false, diffs }: TextFieldProps) {
  /**
   * A simple diff text can look this
   * <p>
   *  <span color="red">This</span>
   *  <span>is an</span>
   *  <span color="red">example</span>
   * </p>
   *
   * In a repeated text, there would be an array of <p>. In order to achieve guaranteed
   * diff updates when versions/locales are changed, the <span> an <p> are to be keyed uniquely.
   * There is no proper identifier is a string which can uniquely identify a substring, as
   * the substring can be repeated multiple times in a string (as a single character can
   * be a substring).
   * In order to identify is substring (or chunk) and string, uuid can be used but right
   * now there is no logical way to sustain the uuid key over multiple renders as the text can vary
   * in any number of way.
   *
   * deepMemo here just helps to prevent regeneration uuid on re-render with same diff result.
   * If the diff result changes (change of version), new uuids will be generated and
   * all the DOM nodes will re-render.
   */
  const chunksList = useDeepMemo(() => (
    diffs.map((chunks) => ({
      key: uuid(),
      chunks: chunks.reduce((acc, chunk) => {
        const { added, removed, value } = chunk

        if (!added && !removed) {
          acc.push(<Text key={uuid()} as="span">{value}</Text>)

          return acc
        }

        if (isCurrent && added) {
          acc.push(<Text key={uuid()} as="span" color="positive400">{value}</Text>)
        }

        if (isPrevious && removed) {
          acc.push(<Text key={uuid()} as="span" color="negative400">{value}</Text>)
        }

        return acc
      }, [] as React.ReactNode[])
    }))
  ), diffs)

  return (
    <Flex direction="column" gap={8}>
      {chunksList.map(({ key, chunks }) => (
        <Text key={key}>{chunks}</Text>
      ))}
    </Flex>
  )
}

TextField.computeDiff = <T extends string | string[]>({
  currentValue, previousValue
}: ComputeDiffProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  const length = Math.max(previous.length, current.length)

  let isDiff = false
  const diffResults = Array(length).fill([]).map((_, index) => {
    const result = diffWords(previous[index] || '', current[index] || '')

    if (!isDiff) {
      isDiff = result.filter((r) => r.added || r.removed).length > 0
    }
    return result
  })

  return {
    previousNode: <TextField isPrevious diffs={diffResults} />,
    currentNode: <TextField isCurrent diffs={diffResults} />,
    isDiff
  }
}

export default TextField
