import arrayMutators from 'final-form-arrays'
import React, { useMemo } from 'react'
import { Form, FormProps, useForm } from 'react-final-form'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import DrawerBlock from 'components/blocks/DrawerBlock'
import FilterField from 'components/form/FilterField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import Masonry from 'components/layout/Masonry'
import MediaCard from 'components/mediaCard/MediaCard'
import RelationshipModel, { Kind } from 'models/Relationship'
import SelectInput from 'components/inputs/SelectInput'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { RelationshipsListDocument, RelationshipsListQueryVariables, CreateRelationshipInput, UpdateRelationshipInput, useCreateRelationshipMutation, useUpdateRelationshipMutation, Resource, Attribute, Relationship, RelationshipsListQuery } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { constructFilters, constructInitialValues, FILTERABLE_FIELD_TYPES } from 'components/dataWidgets/CustomizeDisplay'
import type { ViewProps } from 'components/views'

type FormValues = (CreateRelationshipInput | UpdateRelationshipInput) & {
  source?: Resource,
  target?: Resource
}
type FormRelationshipType = Pick<Partial<Relationship>, 'source' | 'target' | 'sourceAttribute' | 'targetAttribute'>

type Params = {
  resourceId: Resource['id'],
  initialValues: FormValues,
  relationship: FormRelationshipType,
  relationshipsList: RelationshipsListQuery['relationshipsList'],
  queryVariables: RelationshipsListQueryVariables
}

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const RelationshipKindOptions = [
  { title: 'One to One', value: Kind.BELONGS_TO, icon: 'one-to-one', description: 'One to one relationship with another resource' },
  { title: 'One to Many', value: Kind.HAS_MANY, icon: 'one-to-many', description: 'One to many relationship with another resource' }
]

const SourceResourceField = (
  { resourcesList }: {
    resourcesList: Resource[]
  }
) => {
  const { getState, change } = useForm<FormValues>()
  const { values } = getState()
  return (
    <FormField
      name="source"
      label="Source"
      prependIcon="search"
      placeholder="Start typing to search"
      size="small"
      variant="light"
      labelKey="name"
      metaKey="fieldType"
      valueKey="id"
      setValueAsObject
      isOptionDisabled={({ attributes = [] }: Resource) => (
        attributes.length === 0
    || attributes.find((attr) => [ 'uid-field', 'reference-field' ].includes(attr.fieldType)) === undefined
      )}
      getOptionLabel={({ app, name }: Resource) => [ app?.name, name ].filter(Boolean).join(' > ')}
      getOptionMeta={({ attributes = [] }: Resource) => attributes.find((attr) => [ 'uid-field', 'reference-field' ].includes(attr.fieldType))?.name}
      input={{
        value: (values as any)?.source,
        onChange: (value: Resource) => {
          const sourceAttribute = value?.attributes.find((attr) => [ 'uid-field', 'reference-field' ].includes(attr.fieldType))
          sourceAttribute && change('sourceAttributeId', sourceAttribute?.id)
        }
      }}
      options={resourcesList}
      component={SelectInput}
    />
  )
}

const TargetResourceField = (
  { resourcesList }: {
    resourcesList: Resource[]
  }
) => {
  const { getState, change } = useForm<FormValues>()
  const { values } = getState()
  return (
    <FormField
      name="target"
      label="Target"
      prependIcon="search"
      placeholder="Start typing to search"
      size="small"
      variant="light"
      labelKey="name"
      metaKey="fieldType"
      valueKey="id"
      setValueAsObject
      isOptionDisabled={({ attributes = [] }: Resource) => (
        attributes.length === 0
    || attributes.find((attr) => [ 'uid-field', 'reference-field' ].includes(attr.fieldType)) === undefined
      )}
      getOptionLabel={({ app, name }: Resource) => [ app?.name, name ].filter(Boolean).join(' > ')}
      getOptionMeta={({ attributes = [] }: Resource) => attributes.find((attr) => [ 'uid-field', 'reference-field' ].includes(attr.fieldType))?.name}
      options={resourcesList}
      input={{
        value: (values as any)?.target,
        onChange: (value: Resource) => {
          const sourceAttribute = value?.attributes.find((attr) => [ 'uid-field', 'reference-field' ].includes(attr.fieldType))
          sourceAttribute && change('sourceAttributeId', sourceAttribute?.id)
        }
      }}
      component={SelectInput}
    />
  )
}

const KindFormField = ({ isUpdating }: {isUpdating: boolean}) => {
  const { change, getState } = useForm()
  const { values } = getState()

  return (
    <Grid columns={2} columnGap={12}>
      {RelationshipKindOptions.map(({ description, icon, title, value }) => (
        <MediaCard
          key={value}
          media={icon}
          disabled={isUpdating}
          onClick={() => {
            change('kind', value)
          }}
          active={value === values.kind}
          text={description}
          title={title}
          titlePosition="top"
          width="full"
        />
      ))}
    </Grid>
  )
}

function AddRelationshipView({
  closeView,
  onRequestClose,
  params: {
    initialValues,
    relationship,
    relationshipsList,
    queryVariables
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const title = `${isUpdating ? 'Update' : 'Add'} Relationship`

  const sourceAttributesList = useMemo(
    () => relationship.source?.attributes || [], [ relationship ]
  )

  const filterOptions: { label: string, value?: string }[] = useMemo(() => sourceAttributesList
    .filter((attribute) => (
      attribute.fieldType && FILTERABLE_FIELD_TYPES.includes(attribute.fieldType)
    ))
    .map((attribute) => ({
      label: attribute.name,
      value: attribute.identifier
    })), [ sourceAttributesList ])

  const [ createRelationship ] = useCreateRelationshipMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: RelationshipsListDocument, variables: queryVariables } ]
  })

  const [ updateRelationship ] = useUpdateRelationshipMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: RelationshipsListDocument, variables: queryVariables } ]
  })

  const handleCreateRelationship = useSubmitHandler(createRelationship, {
    successAlert: { message: 'Relationship Created.' }
  })

  const handleUpdateRelationship = useSubmitHandler(updateRelationship, {
    successAlert: { message: 'Relationship Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    const {
      filter = [],
      source,
      target,
      ...otherValues
    } = values
    const valuesWithConstructedFilters = {
      ...(filter.length > 0 ? { filter: constructFilters(filter) } : {}),
      ...otherValues
    }
    if (isUpdating) {
      return handleUpdateRelationship(
        valuesWithConstructedFilters as UpdateRelationshipInput,
        form as FormApi<UpdateRelationshipInput>
      )
    }
    return handleCreateRelationship(valuesWithConstructedFilters as CreateRelationshipInput)
  }

  const formInitialValues = {
    ...initialValues,
    kind: Kind.BELONGS_TO,
    source: relationship.source,
    target: relationship.target,
    sourceAttributeId: relationship.sourceAttribute?.id || (relationship.source?.attributes || []).find((attribute) => [ 'uid-field', 'reference-field' ].includes(attribute.fieldType))?.id,
    targetAttributeId: relationship.targetAttribute?.id || (relationship.target?.attributes || []).find((attribute) => [ 'uid-field', 'reference-field' ].includes(attribute.fieldType))?.id,
    filter: initialValues?.filter
      ? constructInitialValues(initialValues.filter as any).filters
      : undefined
  }

  const sourceRelationshipResources = useMemo(
    () => relationshipsList?.map(
      (relationship) => relationship.source
    ) as Resource[] || [], [ relationshipsList ]
  )

  const targetRelationshipResources = useMemo(
    () => relationshipsList?.map(
      (relationship) => relationship.target
    ) as Resource[] || [], [ relationshipsList ]
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Form
              decorators={[
                setIdentifier
              ]}
              mutators={{
                ...arrayMutators
              }}
              initialValues={formInitialValues}
              keepDirtyOnReinitialize
              onSubmit={handleSubmit}
              subscription={{
                submitting: true,
                pristine: true,
                values: true
              }}
              validate={(values) => RelationshipModel.validate(values, [ 'identifier', 'name', 'kind', 'sourceAttributeId', 'targetAttributeId' ])}
              render={({ handleSubmit, submitting, pristine }) => (
                <>
                  <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
                    <input type="hidden" name="kind" />
                    <KindFormField isUpdating={isUpdating} />
                    <Grid columns={1} columnGap={8} rowGap={8}>
                      <FormField
                        component={TextInput}
                        name="name"
                        label="Name"
                        size="small"
                        type="text"
                      />
                      <FormField
                        component={TextInput}
                        name="identifier"
                        label="Identifier"
                        size="small"
                        type="text"
                      />

                      <SourceResourceField
                        resourcesList={sourceRelationshipResources}
                      />
                      <TargetResourceField
                        resourcesList={targetRelationshipResources}
                      />
                    </Grid>
                    <Masonry>
                      <DrawerBlock title="Advanced Settings">
                        {() => (
                          <Grid columns={1} columnGap={8} rowGap={8}>
                            <FormField
                              isSearchable
                              name="sourceAttributeId"
                              label="Source Attribute"
                              prependIcon="search"
                              placeholder="Start typing to search"
                              size="small"
                              variant="light"
                              labelKey="name"
                              metaKey="fieldType"
                              valueKey="id"
                              isOptionDisabled={(option: Attribute) => [ 'uid-field', 'reference-field' ].includes(option.fieldType)}
                              getOptionLabel={(option: Attribute) => `${relationship?.source?.name} > ${option.name}`}
                              options={relationship.sourceAttribute
                                ? [ relationship.sourceAttribute ]
                                : (relationship?.source?.attributes || [])}
                              component={SelectInput}
                            />

                            <FormField
                              isSearchable
                              name="targetAttributeId"
                              label="Target Attribute"
                              prependIcon="search"
                              placeholder="Start typing to search"
                              size="small"
                              variant="light"
                              labelKey="name"
                              metaKey="fieldType"
                              valueKey="id"
                              isOptionDisabled={(option: Attribute) => [ 'uid-field', 'reference-field' ].includes(option.fieldType)}
                              getOptionLabel={(option: Attribute) => `${relationship?.target?.name} > ${option.name}`}
                              options={relationship.targetAttribute
                                ? [ relationship.targetAttribute ]
                                : (relationship?.target?.attributes || [])}
                              component={SelectInput}
                            />
                            <FilterField
                              name="filter"
                              filterOptions={filterOptions}
                              columns={sourceAttributesList.map(
                                (attr) => ({ ...attr, dataKey: attr.identifier })
                              ) as any[]}
                              noFilterMessage="No Filters"
                            />
                          </Grid>
                        )}
                      </DrawerBlock>
                    </Masonry>
                    <Flex gap={24} direction="row-reverse">
                      <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                    </Flex>
                  </Flex>
                </>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default AddRelationshipView
