import React, { useContext } from 'react'
import type { ErrorResponse } from '@apollo/client/link/error'

import AppLogo from 'components/logos/AppLogo'
import DashboardContext from 'components/contexts/DashboardContext'
import ElectronWorkspaceSidebar from 'components/sidebar/ElectronWorkspaceSidebar'
import Flex from 'components/layout/Flex'
import parseError from 'lib/parseError'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import { isElectron } from 'lib/electron'
import { styled } from 'styles/stitches'
import { WORKSPACE_SIDEBAR_WIDTH } from 'components/sidebar/constants'

import backgroundImage from 'assets/images/logo-bg.svg'

type AppErrorProps = {
  defaultCode?: number,
  defaultMessage?: string,
  error?: Error | ErrorResponse,
  fullscreen?: boolean
}

const StyledErrorPage = styled(Flex, {
  fullscreen: true,
  backgroundColor: 'light400',
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'bottom -15vh right -25vh',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '125vh',
  zIndex: 'appScreen'
})

function getCodeAndMessageFromError(error: AppErrorProps['error']) {
  let code: number | undefined
  let message: string | undefined

  if (error && ('graphQLErrors' in error || 'networkError' in error)) {
    const { alert, statusCode } = parseError(error)

    if (statusCode) {
      code = statusCode
    }

    if (alert && alert.message) {
      message = alert.message
    }
  }

  return { code, message }
}

function AppError({
  defaultCode = 500,
  defaultMessage = 'Something went wrong.',
  error,
  fullscreen = true
}: AppErrorProps) {
  const { code, message } = getCodeAndMessageFromError(error)
  const { currentSidebarWidth } = useContext(DashboardContext)! || {}

  return (
    <>
      <ElectronWorkspaceSidebar />
      <StyledErrorPage
        alignItems="center"
        direction="column"
        gap={60}
        justifyContent="center"
        css={{
          marginLeft: fullscreen
            ? (currentSidebarWidth || 0) + (isElectron ? WORKSPACE_SIDEBAR_WIDTH : 0)
            : undefined,
          marginTop: fullscreen ? undefined : 95
        }}
      >
        <AppLogo variant="symbol" />
        <Flex alignItems="center" direction="column" gap={14}>
          <Text fontSize={80} fontWeight="light" letterSpacing="compact">
            {code || defaultCode}
          </Text>
          <Text letterSpacing="condensed">
            {message || defaultMessage}
          </Text>
        </Flex>
        <TextLink href="mailto:support@dashx.com" fontSize={14} variant="underlined">
          Need help?
        </TextLink>
      </StyledErrorPage>
    </>
  )
}

export type { AppErrorProps }
export default AppError
