import React from 'react'
import Highcharts, { Series, SeriesBarOptions, SeriesColumnOptions, TooltipFormatterContextObject, XAxisOptions, YAxisOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import pluralize from 'pluralize'

import { colors } from 'lib/chartColors'

function tooltipFormatter(
  this: TooltipFormatterContextObject
): string {
  const { series, point }: {series: Series, point: any} = this
  const xAsisText = series.xAxis.categories.length > 0
    ? series.xAxis.categories[point.x]
    : series.xAxis.options.title

  const seriesName = series.name.includes('Series') ? point.name : series.name
  const suffix = pluralize((series.yAxis.options.title as any)?.suffix || '', point.y)
  const currencyCode = (series.yAxis.options.title as any)?.currencyCode

  const formatter = new Intl.NumberFormat('en-US', currencyCode ? {
    style: 'currency',
    currency: currencyCode
  } : undefined)

  return `<div style="min-width: 250px; border-radius: 4px; box-shadow: 0px 13px 20px #aaadbc1a">
            <div style="display: flex; align-items: center; padding: 14px 25px;">
              <div style="width: 12px; height: 12px; margin-right: 10px; background-color: ${point.color}; border-radius: 2px;"></div>
              <p style="font-size: 14px; color: var(--colors-dark900); font-weight: bold">${seriesName}</p>
            </div>
            <div style="width: 100%; height: 0px; border-top: 1px solid var(--colors-light700)"></div>
            <div style="padding: 14px 25px;">
              <div style="display: flex; justify-content: space-between; margin: 6px 0px;">
                <p>${series.xAxis.options.title?.text}</p>
                <p style="font-weight: bold;">${xAsisText}</p>
              </div>
              <div style="display: flex; justify-content: space-between; margin: 6px 0px;">
                <p>${series.yAxis.options.title?.text}</p>
                <p style="font-weight: bold;">${formatter.format(point.y)} ${suffix || ''}</p>
              </div>
            </div>
          </div>`
}

const options: Highcharts.Options = {
  title: {
    align: 'left'
  },
  credits: {
    enabled: false
  },
  colors,
  tooltip: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 8,
    padding: 0,
    formatter: tooltipFormatter,
    shadow: false,
    useHTML: true
  },
  chart: {
    style: {
      fontFamily: 'var(--fonts-normal)'
    },
    spacing: [ 16, 16, 16, 16 ],
    type: 'column'
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom'
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false
      }
    }
  },
  responsive: {
    rules: [ {
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    } ]
  }

}

type Props = {
  title?: string,
  description?: string,
  type: 'bar' | 'column',
  xAxis: XAxisOptions,
  yAxis: YAxisOptions,
  series: (SeriesBarOptions | SeriesColumnOptions)[],
  plotOptions?: Highcharts.PlotOptions['bar'] | Highcharts.PlotOptions['column']
}

const BarChart: React.FC<Props> = ({
  title, description, type, xAxis, yAxis, series, plotOptions
}) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      ...options,
      chart: { ...options.chart, type },
      plotOptions: {
        ...options.plotOptions,
        series: {
          ...options.plotOptions?.series,
          ...plotOptions
        }
      },
      title: { ...options.title, text: title },
      subtitle: { text: description },
      xAxis,
      yAxis,
      series
    }}
  />
)

export default BarChart
