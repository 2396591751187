import React from 'react'

import QuickLinksBlock from 'components/blocks/QuickLinksBlock'
import type { BlockProps } from 'components/blocks/Block'

const QuickLinksBlockWrapper: React.FC<BlockProps & Record<any, any>> = ({
  block, ...blockProps
}) => {
  const { properties, layout } = block
  const { width } = layout || { width: { sm: 400 } }
  const { title, links } = properties
  return (
    <QuickLinksBlock width={width} links={links} title={title} {...blockProps} />
  )
}

export default QuickLinksBlockWrapper
