import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import ExternalParagraph from 'components/typography/ExternalParagraph'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import StartForm from 'components/forms/StartForm'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useIdentifyProspectMutation } from 'generated/schema'
import type { IdentifyProspectInput } from 'generated/schema'

function StartPage() {
  const [ email, setEmail ] = useState('')
  const { push } = useHistory()

  const [ identifyProspect ] = useIdentifyProspectMutation({
    onCompleted: () => {
      push({
        pathname: '/verify-email',
        state: { email }
      })
    }
  })

  const handleIdentifyProspect = useSubmitHandler(identifyProspect)

  const handleSubmit = (values: IdentifyProspectInput) => {
    setEmail(values.email)
    return handleIdentifyProspect(values)
  }

  return (
    <Flex direction="column" gap={20}>
      <ExternalTitle>Get Started</ExternalTitle>
      <Flex direction="column" gap={48}>
        <ExternalParagraph>Sign in or create account</ExternalParagraph>
        <StartForm onSubmit={handleSubmit} />
      </Flex>
    </Flex>
  )
}

export default StartPage
