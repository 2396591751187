import React from 'react'
import { Form, Field } from 'react-final-form'
import type { FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import Account from 'models/Account'

export default function UpdateAccountEmailForm({ initialValues, ...others }: FormProps) {
  return (
    <Form
      initialValues={initialValues}
      validate={(values) => Account.validate(values, [ 'password', 'email' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Flex gap={36}>
            <Field name="password" component={TextInput} type="password" placeholder="Current password" />
            <Field name="email" component={TextInput} placeholder="New email address" />
            <Button type="submit" label="Update email" disabled={submitting || pristine} />
          </Flex>
        </form>
      )}
      {...others}
    />
  )
}
