import React from 'react'
import { useMutation } from '@apollo/client'

import * as mixins from 'styles/mixins'
import Icon from 'components/icons/Icon'
import rgba from 'lib/rgba'
import { colorVars } from 'styles/theme'
import { SAVE_PREFERENCES_MUTATION } from 'client/state/preferences'
import { SIDEBAR_PRIMARY_BACKGROUND_COLOR, SIDEBAR_SECONDARY_BACKGROUND_COLOR } from 'components/sidebar/constants'
import { styled } from 'styles/stitches'

const StyledToggleButton = styled('button', {
  ...mixins.transition('simple'),

  borderRadius: '50%',
  borderWidth: 0,
  cursor: 'pointer',
  display: 'inline-block',
  height: 30,
  left: '100%',
  opacity: 1,
  paddingY: 9,
  paddingLeft: 8,
  paddingRight: 10,
  position: 'absolute',
  transform: 'translateX(-50%) translateY(-50%) scale(1)',
  top: 54,
  verticalAlign: 'middle',
  width: 30,
  zIndex: 'sidebar',

  '&:hover, &:active': {
    transitionDelay: '0s' // Disappear quickly when clicked
  },
  variants: {
    variant: {
      primary: {
        ...mixins.shadow('xxSmall', colorVars.dark900rgb, 0.1),

        backgroundColor: SIDEBAR_PRIMARY_BACKGROUND_COLOR,
        color: rgba(colorVars.light100rgb, 0.4),

        '&:hover': {
          color: 'light100'
        }
      },
      secondary: {
        ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),

        backgroundColor: SIDEBAR_SECONDARY_BACKGROUND_COLOR,
        color: 'dark100',

        '&:hover': {
          color: 'dark700'
        }
      }
    },
    invisible: {
      true: {
        opacity: 0,
        transform: 'translateX(-50%) translateY(-50%) scale(0)',
        transitionDelay: '0.3s' // Don't disappear too quickly as user might try to reach it
      }
    },
    minimized: {
      true: {
        transform: 'translateX(-50%) translateY(-50%) scale(1) rotate(180deg)'
      }
    }

  }
})

StyledToggleButton.compoundVariant({
  invisible: true,
  minimized: true
}, {
  transform: 'translateX(-50%) scale(0) rotate(180deg)'
})

type SidebarToggleButtonProps = {
  isMinimized: boolean,
  isVisible: boolean,
  onToggleMinimize: () => void,
  variant: 'primary' | 'secondary'
}

function SidebarToggleButton({
  isMinimized,
  isVisible,
  onToggleMinimize,
  variant
}: SidebarToggleButtonProps) {
  const [ savePreferences ] = useMutation(SAVE_PREFERENCES_MUTATION)

  const handleClick = () => {
    onToggleMinimize()
    savePreferences({ variables: { preferences: { isSidebarMinimized: !isMinimized } } })
  }

  return (
    <StyledToggleButton
      aria-label="Minimize Toggle"
      invisible={!isVisible}
      minimized={isMinimized}
      onClick={handleClick}
      type="button"
      variant={variant}
    >
      <Icon data-icon name="close-submenu" size={12} />
    </StyledToggleButton>
  )
}

export default SidebarToggleButton
