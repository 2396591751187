import React from 'react'
import type { RouteProps } from 'react-router-dom'

import InternalLayout from 'components/layouts/InternalLayout'
import ProtectedRoute, { SessionState } from 'components/routes/ProtectedRoute'

function InternalRoute(props: RouteProps) {
  return (
    <ProtectedRoute
      layout={InternalLayout}
      requiredSessionState={SessionState.LOGGED_IN}
      requiredAccountState="ENABLED"
      {...props}
    />
  )
}

export default InternalRoute
