import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import boost from 'highcharts/modules/boost'
import Highcharts from 'highcharts'
import type { SeriesLineOptions, TooltipFormatterContextObject, XAxisOptions, YAxisOptions } from 'highcharts'

import dayjs from 'dayjs'

import { colors } from 'lib/chartColors'

boost(Highcharts)

function tooltipFormatter(
  this: TooltipFormatterContextObject
): string {
  const { series, point } = this
  return `<div style="min-width: 250px; border-radius: 4px; box-shadow: 0px 13px 20px #aaadbc1a">
            <div style="display: flex; align-items: center; padding: 14px 25px;">
              <div style="width: 12px; height: 12px; margin-right: 10px; background-color: ${point.color}; border-radius: 2px;"></div>
              <p style="font-size: 14px; color: var(--colors-dark900); font-weight: bold">${series.name}</p>
            </div>
            <div style="width: 100%; height: 0px; border-top: 1px solid var(--colors-light700)"></div>
            <div style="padding: 14px 25px;">
              <div style="display: flex; justify-content: space-between; margin: 6px 0px;">
                <p>${series.xAxis?.options.title?.text || ''}</p>
                <p style="font-weight: bold;">${series.xAxis?.userOptions.type === 'datetime' ? dayjs(point.x).format('ddd MMM D, YYYY H:mmA') : point.x}</p>
              </div>
              <div style="display: flex; justify-content: space-between; margin: 6px 0px;">
                <p>${series.yAxis?.options.title?.text || ''}</p>
                <p style="font-weight: bold;">${point.y}</p>
              </div>
            </div>
          </div>`
}

const options: Highcharts.Options = {
  title: {
    align: 'left'
  },
  credits: {
    enabled: false
  },
  boost: {
    useGPUTranslations: true
  },
  colors,
  tooltip: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 8,
    padding: 0,
    formatter: tooltipFormatter,
    shadow: false,
    useHTML: true
  },
  chart: {
    style: {
      fontFamily: 'var(--fonts-normal)'
    },
    spacing: [ 16, 16, 16, 16 ]
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom'
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false
      },
      label: {
        connectorAllowed: false
      },
      pointStart: 2010
    }
  },
  responsive: {
    rules: [ {
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    } ]
  }

}

type Props = {
  title?: string,
  description?: string,
  type?: 'line' | 'spline',
  xAxis?: XAxisOptions,
  yAxis?: YAxisOptions,
  series: SeriesLineOptions[]
}

const LineChart: React.FC<Props> = ({ title, description, type = 'line', xAxis, yAxis, series }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      ...options,
      chart: { ...options.chart, type },
      title: { text: title },
      subtitle: { text: description },
      xAxis,
      yAxis,
      series
    }}
  />
)

export default LineChart
