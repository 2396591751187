import React from 'react'
import Select, { OptionTypeBase, Props as SelectProps, Styles as SelectStyles } from 'react-select'

import * as mixins from 'styles/mixins'
import colors from 'styles/primitives/colors'
import typography from 'styles/primitives/typography'
import { styled } from 'styles/stitches'

type SimpleSelectProps = SelectProps & {
  wrapperClassName?: string
}

type SimpleSelectOption = OptionTypeBase & {
  label?: string,
  value?: string
}

const MAX_VISIBLE_LIST_SIZE = 12
const OPTION_FONT_SIZE = 12
const OPTION_X_PADDING = 20
const OPTION_Y_PADDING = 10

const StyledWrapper = styled('div', {
  width: '100%'
})

const optionHeight = (lineHeight: number) => (
  Math.ceil(OPTION_FONT_SIZE * lineHeight) + (OPTION_Y_PADDING * 2)
)

const optionsHeight = (lineHeight: number) => (
  (optionHeight(lineHeight) * MAX_VISIBLE_LIST_SIZE) + (OPTION_Y_PADDING * 2)
)

const styles:SelectStyles = {
  control: (provided) => ({
    ...provided,

    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    fontFamily: typography.fontFamilies.normal,
    fontSize: typography.fontSizes[14],
    fontWeight: parseInt(typography.fontWeights.bold, 10),
    lineHeight: typography.lineHeights.normal
  }),
  placeholder: (provided) => ({
    ...provided,

    color: colors.dark900,
    maxWidth: 'none',
    position: 'static',
    top: 'none',
    transform: 'none'
  }),
  input: (provided) => ({
    ...provided,

    input: {
      fontFamily: typography.fontFamilies.normal,
      fontSize: typography.fontSizes[14],
      fontWeight: parseInt(typography.fontWeights.regular, 10),
      lineHeight: typography.lineHeights.normal
    }
  }),
  indicatorSeparator: () => ({}),
  menu: (provided) => ({
    ...provided,
    ...mixins.shadow('xxSmall', colors.dark600rgb, 0.1),

    backgroundColor: colors.light100,
    borderColor: colors.dark100,
    borderRadius: 'none'
  }),
  option: (provided, state) => ({
    backgroundColor: state.isFocused && colors.light400,
    color: (() => {
      if (state.isFocused) {
        return colors.primary300
      }

      return state.isSelected ? colors.dark100 : colors.dark900
    })(),
    cursor: 'pointer',
    fontFamily: typography.fontFamilies.normal,
    fontSize: typography.fontSizes[OPTION_FONT_SIZE],
    fontWeight: parseInt(typography.fontWeights.regular, 10),
    lineHeight: typography.lineHeights.normal,
    paddingBottom: OPTION_Y_PADDING,
    paddingLeft: OPTION_X_PADDING,
    paddingRight: OPTION_X_PADDING,
    paddingTop: OPTION_Y_PADDING,

    '&:first-of-type': {
      marginTop: OPTION_Y_PADDING
    },

    '&:last-of-type': {
      marginBottom: OPTION_Y_PADDING
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,

    color: colors.dark500,
    fontFamily: typography.fontFamilies.normal,
    fontSize: typography.fontSizes[OPTION_FONT_SIZE],
    fontWeight: parseInt(typography.fontWeights.regular, 10),
    lineHeight: typography.lineHeights.normal
  }),
  singleValue: (provided) => ({
    ...provided,

    top: 'auto',
    transform: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,

    paddingLeft: 4,
    paddingRight: 4
  })
}

function SimpleSelect({ wrapperClassName = '', ...rest }: SimpleSelectProps) {
  return (
    <StyledWrapper className={wrapperClassName}>
      <Select
        styles={styles}
        maxMenuHeight={optionsHeight(parseInt(typography.lineHeights.normal, 10))}
        {...rest}
      />
    </StyledWrapper>
  )
}

export type { SimpleSelectOption }

export default SimpleSelect
