import isObject from 'lodash/isObject'
import React from 'react'

import ConditionalField from 'components/form/ConditionalField'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import NumberField from 'components/contentEditors/generic/fields/NumberField'
import Text from 'components/typography/Text'
import type { Attribute } from 'generated/schema'
import { NUMBER_MODE } from 'components/contentEditors/generic/fields/fieldProps'
import SelectInput from 'components/inputs/SelectInput'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ data, displaySettings }: RendererProps) => {
  const { fontWeight = 'regular' } = displaySettings
  return (
    <Text
      fontSize={14}
      fontWeight={fontWeight}
      css={{
        color: 'currentColor',
        fontVariantNumeric: 'tabular-nums'
      } as any}
    >
      {isObject(data) ? JSON.stringify(data) : data ?? '-'}
    </Text>
  )
}

const NUMBER_TYPE_OPTIONS = [
  { value: NUMBER_MODE.INTEGER, label: 'Integers' },
  { value: NUMBER_MODE.DECIMAL, label: 'Decimals' }
]

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}mode`}
      component={SelectInput}
      options={NUMBER_TYPE_OPTIONS}
      label="Accepted Values"
      size="small"
    />
    <ConditionalField when="mode" is={NUMBER_MODE.DECIMAL}>
      <NumberField
        name={`${fieldPrefix}decimalPlaces`}
        label="Max decimal places"
        size="small"
      />
    </ConditionalField>
  </Grid>
)

export default {
  Configurations
}

export { Renderer as NumberRenderer }
