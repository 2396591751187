import { ConfirmationView } from 'components/views'
import type { ConfirmationViewProps } from 'components/views/ConfirmationView'
import type { View } from 'components/providers/ViewProvider'
import { useViewDispatch } from 'hooks/useViewContext'

type OnConfirmClick<P> = () => Promise<P>

type ConfirmParameters<P> = Pick<ConfirmationViewProps, 'action' | 'isPlural' | 'recordDescription' | 'recordType'> & {
  onConfirmClick: OnConfirmClick<P>
}

const DEFAULT_STYLE = 'DIALOG'

function useConfirmation<P>(options?: Pick<View, 'style'>): (args: ConfirmParameters<P>) => void {
  const style = options?.style || DEFAULT_STYLE
  const { openView } = useViewDispatch()

  return (params) => openView({ title: 'Confirmation', component: ConfirmationView, style, params })
}

export default useConfirmation
