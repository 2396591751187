import React from 'react'

import AddAttributeView from 'components/views/AddAttributeView'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataListBlock from 'components/blocks/DataListBlock'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import parseError from 'lib/parseError'
import SearchBar from 'components/searchbar/SearchBar'
import Text from 'components/typography/Text'
import ToggleInput from 'components/inputs/ToggleInput'
import useConfirmation from 'hooks/useConfirmation'
import useFuzzySearch from 'hooks/useFuzzySearch'
import useReorder from 'hooks/useReorder'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Attribute, AttributesListDocument, Resource, useAttributesListQuery, useDestroyAttributeMutation, useUpdateAttributeMutation } from 'generated/schema'
import { ATTRIBUTES_LIST_LIMIT } from 'models/Resource'
import { DisplayType, RESERVED_ATTRIBUTES, ResolutionKind } from 'models/Attribute'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'

const DEFAULT_TITLE = 'There are no attributes.'
const DEFAULT_SUBTITLE = 'Attributes are the properties of a resource.'
const DEFAULT_ERROR_SUBTITLE = 'Please contact support if the problem persists.'

const noop = () => {}

const AttributesList = ({ resource }: { resource: Resource }) => {
  const confirm = useConfirmation({ style: 'DIALOG' })
  const resourceId = resource.id
  const { isReadOnly } = resource
  const { openView } = useViewDispatch()
  const [ showReserved, setShowReserved ] = React.useState(false)
  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId }
    },
    order: [ {
      position: 'asc'
    } ],
    limit: ATTRIBUTES_LIST_LIMIT
  }

  const {
    data: { attributesList = [] } = {},
    loading,
    error
  } = useAttributesListQuery({
    variables: queryVariables,
    skip: !resource
  })

  const { matches, handleChange } = useFuzzySearch<Attribute>(attributesList as Attribute[], { key: 'name' })
  const filteredAttributes = showReserved
    ? matches
    : matches.filter((attribute) => !RESERVED_ATTRIBUTES.includes(attribute.identifier))

  const [ updateAttribute ] = useUpdateAttributeMutation()
  const [ destroyAttribute ] = useDestroyAttributeMutation({
    refetchQueries: [
      AttributesListDocument
    ]
  })

  const handleDestroyAttribute = useSubmitHandler(destroyAttribute, {
    successAlert: { message: 'Attribute Deleted.' }
  })

  const handleUpdateAttribute = useSubmitHandler(updateAttribute)

  const reorder = useReorder({
    query: AttributesListDocument,
    variables: queryVariables,
    dataKey: 'attributesList',
    callback: handleUpdateAttribute
  })

  const onEdit = (attribute: Attribute) => openView({
    title: 'Edit Attribute',
    component: AddAttributeView,
    params: {
      initialValues: attribute,
      queryVariables
    },
    style: 'PANEL'
  })

  const onAdd = () => openView({
    title: 'Add Attribute',
    component: AddAttributeView,
    params: {
      initialValues: {
        name: '',
        identifier: '',
        dataTypeId: '',
        fieldType: 'text-field',
        validations: [],
        settings: {},
        isFilterable: false,
        isOrderable: false,
        isPrimary: false,
        isTranslatable: false,
        position: generatePosition(getMaxPosition(attributesList as any[])),
        resourceId,
        dataTypeSettings: {},
        resolutionKind: ResolutionKind.LOCAL,
        resolutionSettings: {},
        fieldTypeSettings: {},
        displayType: DisplayType.PLAIN_TEXT,
        displayTypeSettings: {}
      },
      queryVariables
    },
    style: 'PANEL'
  })

  const openConfirmationDialog = (attribute: Attribute) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        handleDestroyAttribute({ id: attribute.id })
      },
      recordType: 'Attribute',
      recordDescription: attribute.name
    })
  }

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' },
    { dataKey: 'fieldType', slot: 'meta', renderer: ChipRenderer }
  ]

  const isSystem = !resource.workspaceId

  const actions = isReadOnly || isSystem
    ? [ { icon: 'pad-lock', title: 'Read only' } ]
    : [
      {
        icon: 'edit',
        title: 'Edit',
        onClick: onEdit
      },
      {
        icon: 'trash',
        title: 'Delete',
        onClick: openConfirmationDialog
      }
    ]

  const EmptyState = () => {
    const loaderTitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return alert?.title || alert?.message
      }

      return DEFAULT_TITLE
    })()

    const loaderSubtitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return (alert?.title && alert?.message) || DEFAULT_ERROR_SUBTITLE
      }

      return DEFAULT_SUBTITLE
    })()

    return (
      <Flex alignItems="center" direction="column" gap={16}>
        <Flex alignItems="center" direction="column" gap={8}>
          <Text fontWeight="bold">{loaderTitle}</Text>
          <Text fontSize={14}>{loaderSubtitle}</Text>
        </Flex>
        {!isReadOnly && <Button label="Create Attribute" size="small" mode="subtle" onClick={onAdd} />}
      </Flex>
    )
  }

  return (
    <DataListBlock
      asFragment
      title="Attributes"
      actions={actions}
      contents={contents}
      data={filteredAttributes}
      loading={loading}
      error={error}
      {...(!isReadOnly && !isSystem && { onRowDragEnd: reorder })}
      empty={{
        element: <EmptyState />
      }}
      selectionMode="none"
      primaryElements={<SearchBar placeholder="Search attributes" onChange={handleChange} css={{ maxWidth: 240 }} />}
      secondaryElements={(
        <>
          <Flex gap={8}>
            <FieldLabel>Show Reserved?</FieldLabel>
            <ToggleInput
              meta={{}}
              input={{
                checked: showReserved,
                onChange: () => setShowReserved((prev) => !prev),
                name: 'toggle',
                onBlur: noop,
                onFocus: noop,
                value: ''
              }}
            />
          </Flex>
          {!isReadOnly && !isSystem && <Button icon="add-thin" size="small" onClick={onAdd} />}
        </>
      )}
      width={{ md: '75%' }}
    />
  )
}
export default AttributesList
