import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Extender from 'components/extender/Extender'
import InputGroup from 'components/form/InputGroup'
import Prospect from 'models/Prospect'
import TextInput from 'components/inputs/TextInput'
import type { IdentifyProspectInput } from 'generated/schema'

function StartForm(props: FormProps<IdentifyProspectInput>) {
  return (
    <Form
      validate={(values) => Prospect.validate(values, [ 'email' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Extender>
            <InputGroup grow={1}>
              <Field
                autoFocus
                border="shadow"
                component={TextInput}
                inputMode="email"
                name="email"
                placeholder="Enter your work email"
                size="large"
                type="text"
              />
              <Button type="submit" label="Continue" disabled={submitting || pristine} size="large" />
            </InputGroup>
          </Extender>
        </form>
      )}
      {...props}
    />
  )
}

export default StartForm
