import classNames from 'classnames'
import React from 'react'

import * as mixins from 'styles/mixins'
import Icon from 'components/icons/Icon'
import { css } from 'styles/stitches'
import type { IconProps } from 'components/icons/Icon'

const iconClass = css({
  ...mixins.transition('fluid'),

  color: 'dark700',
  cursor: 'pointer',
  opacity: 0.3,

  '&:hover, &:focus': {
    opacity: 1
  }
})

function ActionIcon({ className, name, size, ...others }: IconProps) {
  return (
    <button type="button" className={classNames(iconClass, className)} {...others}>
      <Icon name={name} size={size} />
    </button>
  )
}

export default ActionIcon
