/* eslint-disable camelcase */
import type { Account, App, Attribute, CreateAppInput, CreateAttributeInput, CreateDashboardInput, CreateOperationInput, CreateParameterInput, CreateRelationshipInput, CustomRole, DataType, DataTypeFragmentFragment, Environment, GenerateGraphInput, GenerateMenuElementsInput, Group, Installation, Operation, Resource, RoleMembershipFragmentFragment, UpdateAppInput, UpdateAttributeInput, UpdateDashboardInput, UpdateOperationInput, UpdateParameterInput, UpdateRelationshipInput, View, Workspace } from 'generated/schema'
import type { BehaviorMethod } from 'models/Operation'
import type { Block } from 'hooks/useDashboard'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { DataSource, ActionType } from './EditBlockView'
import type { DisplayType } from 'models/Attribute'
import type { Kind } from 'models/Role'
import type { MenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import type { View as StackedView } from 'components/providers/ViewProvider'

const DASHBOARD_EDITOR_WIDTH = 400
const LAYOUT_OVERLAP = 10
const INNER_CONTAINER_PADDING = 30

enum Views {
  ACTIONS = 'actions',
  ADD_ACTION = 'add-action',
  ADD_BLOCK = 'add-block',
  ADD_COLUMN = 'add-column',
  ADD_ROW = 'add-row',
  ADD_FORM_BLOCK = 'add-form-block',
  ADD_DASHBOARD = 'add-dashboard',
  ADD_INTEGRATION = 'add-integration',
  ADD_INTEGRATION_FORM = 'add-integration-form',
  ADD_MEMBER = 'add-member',
  ADD_MENU = 'add-menu',
  ADD_MENU_APP = 'add-menu-app',
  ADD_MENU_ELEMENT = 'add-menu-element',
  ADD_MENU_PROJECT = 'add-menu-project',
  ADD_MENU_RESOURCE = 'add-menu-resource',
  ADD_VIEW_OPERATION = 'add-view-operation',
  APP = 'app',
  APP_DETAILS = 'app-details',
  CREATE_ATTRIBUTE = 'create-attribute',
  CREATE_DATA_TYPE = 'create-data-type',
  CREATE_CUSTOM_APP = 'create-custom-app',
  CREATE_DATA_SOURCE = 'create-data-source',
  CREATE_OPERATION = 'create-operation',
  CREATE_PARAMETER = 'create-parameter',
  CREATE_DATA_TYPE_FIELD = 'create-data-type-field',
  CREATE_PROJECT = 'create-project',
  CREATE_RELATIONSHIP = 'create-relationship',
  CREATE_RESOURCE = 'create-resource',
  CREATE_ROLE = 'create-role',
  CREATE_SEGMENT = 'create-segment',
  CREATE_VIEW = 'create-view',
  DASHBOARD = 'dashboard',
  EDIT_APP = 'edit-app',
  EDIT_BLOCK = 'edit-block',
  EDIT_COMPONENT = 'edit-component',
  EDIT_PROJECT = 'edit-project',
  EDIT_RESOURCE = 'edit-resource',
  EDIT_VIEW = 'edit-view',
  IMPORT_FROM_API = 'import-from-api',
  IMPORT_FROM_DATABASE = 'import-from-database',
  INTEGRATION_DETAILS = 'integration-details',
  INTEGRATIONS = 'integrations',
  LINK_ATTRIBUTE = 'link-attribute',
  MENU = 'menu',
  PROJECT = 'project',
  PROJECT_DETAILS = 'project-details',
  RESOURCE_DETAILS = 'resource-details',
  SCHEMA = 'schema',
  VIEW_DETAILS = 'view-details'
}

type ViewParams = {
  [Views.ACTIONS]: {},
  [Views.ADD_ACTION]: {
    actsOn: Operation['actsOn'],
    kind: ActionType,
    index: number,
    identifier?: string,
    name?: string,
    id?: string,
    operation?: string,
    input?: any,
    behavior?: string,
    href?: string,
    target?: string,
    block: Block,
    defaultActions: BehaviorMethod[],
    resourceId: Resource['id'],
    url?: string
  },
  [Views.ADD_BLOCK]: {},
  [Views.ADD_DASHBOARD]: {
    initialValues: CreateDashboardInput | UpdateDashboardInput
  },
  [Views.ADD_INTEGRATION]: {
    appCategoryIds?: string[],
    initialValues: GenerateGraphInput,
    operation?: Operation
  },
  [Views.ADD_INTEGRATION_FORM]: {
    initialValues: {
      selectedApp: App,
      integration: Installation
    },
    operation?: Operation,
    integration: Installation,
    appCategoryIds?: string[]
  },
  [Views.ADD_MEMBER]: {
    app?: App,
    initialValues?: {
      kind: Kind,
      roleId?: CustomRole['id'],
      accountId?: Account['id'],
      groupId?: Group['id'],
      environmentId?: Environment['id'],
      roleMemberships?: Readonly<RoleMembershipFragmentFragment[]>,
      role?: CustomRole,
      account?: Account,
      group?: Group,
      mode?: 'account' | 'group' | 'role'
    }
  },
  [Views.ADD_MENU]: {},
  [Views.ADD_MENU_APP]: {
    initialValues: GenerateMenuElementsInput
  },
  [Views.ADD_MENU_ELEMENT]: {
    initialValues: Partial<ComputedMenuElement & MenuElementPositionContext>,
    parentView?: Views
  },
  [Views.ADD_MENU_PROJECT]: {
    initialValues: GenerateMenuElementsInput
  },
  [Views.ADD_MENU_RESOURCE]: {
    initialValues: GenerateMenuElementsInput
  },
  [Views.APP]: {},
  [Views.APP_DETAILS]: {
    activeTab?: string,
    app: App,
    parentView?: Views
  },
  [Views.CREATE_ATTRIBUTE]: {
    app?: App,
    initialValues?: CreateAttributeInput | UpdateAttributeInput,
    resource: Resource,
    workspace?: Workspace
  },
  [Views.CREATE_DATA_TYPE]: {
    initialValues?: DataTypeFragmentFragment,
    heading: string,
    sourceKind: 'ATTRIBUTE' | 'DATA_TYPE' | 'ROW'
  },
  [Views.CREATE_CUSTOM_APP]: {
    initialValues: CreateAppInput
  },
  [Views.CREATE_DATA_SOURCE]: {},
  [Views.CREATE_OPERATION]: {
    app?: App,
    operation?: Operation,
    resource?: Resource,
    workspace?: Workspace
  },
  [Views.CREATE_PARAMETER]: {
    app?: App,
    currentIndex?: number,
    initialValues?: CreateParameterInput | (UpdateParameterInput & { relationshipId?: string }),
    operation?: Operation,
    resource?: Resource,
    workspace?: Workspace,
    block?: Block
  },
  [Views.CREATE_DATA_TYPE_FIELD]: {
    currentIndex?: number,
    initialValues?: DataType,
    parentDataType: DataType
  },
  [Views.CREATE_PROJECT]: {
    initialValues: CreateAppInput
  },
  [Views.CREATE_RELATIONSHIP]: {
    initialValues?: CreateRelationshipInput | UpdateRelationshipInput,
    resource?: Resource
  },
  [Views.CREATE_RESOURCE]: {
    app?: App,
    initialValues?: CreateOperationInput | UpdateOperationInput,
    resource?: Resource,
    workspace?: Workspace
  },
  [Views.CREATE_ROLE]: {
    app?: App,
    initialValues: Partial<CustomRole>
  },
  [Views.CREATE_SEGMENT]: {},
  [Views.CREATE_VIEW]: {},
  [Views.DASHBOARD]: {},
  [Views.EDIT_APP]: {
    initialValues: UpdateAppInput
  },
  [Views.EDIT_BLOCK]: {
    block?: Block
  },
  [Views.ADD_FORM_BLOCK]: {
    block?: Block
  },
  [Views.ADD_COLUMN]: {
    initialValues: {
      attribute?: Attribute['id'],
      label?: string,
      value?: string,
      data_type?: DataType['id'],
      display_type?: DisplayType,
      display_type_settings?: any,
      relations?: any,
      width: string,
      is_visible: boolean,
      is_orderable?: boolean,
      kind: 'DEFAULT' | 'RESOURCE'
    },
    currentIndex?: number,
    type: DataSource,
    block: Block,
    isUpdating?: boolean
  },
  [Views.ADD_ROW]: {
    initialValues: {
      attribute?: Attribute['id'],
      label?: string,
      value?: string,
      data_type?: DataType['id'],
      display_type?: DisplayType,
      display_type_settings?: any,
      is_hidden?: boolean
    },
    currentIndex?: number,
    type: DataSource,
    block: Block,
    isUpdating?: boolean
  },
  [Views.EDIT_COMPONENT]: {},
  [Views.EDIT_PROJECT]: {
    initialValues: UpdateAppInput
  },
  [Views.EDIT_RESOURCE]: {},
  [Views.IMPORT_FROM_API]: {},
  [Views.IMPORT_FROM_DATABASE]: {
    app?: App,
    initialValues: GenerateGraphInput
  },
  [Views.INTEGRATIONS]: {},
  [Views.INTEGRATION_DETAILS]: {
    integration: Installation
  },
  [Views.LINK_ATTRIBUTE]: {
    initialValues: any,
    relations?: any,
    parentResourceId: Resource['id'],
    resourceId: Resource['id'],
    isUpdating?: boolean,
    currentIndex?: number,
    isColumnUpdating?: boolean,
    type: DataSource,
    block: Block
  },
  [Views.MENU]: {},
  [Views.PROJECT]: {},
  [Views.PROJECT_DETAILS]: {
    app: App,
    parentView?: Views
  },
  [Views.SCHEMA]: {},
  [Views.RESOURCE_DETAILS]: {
    app?: App,
    resource?: Resource,
    parentView?: Views,
    workspace?: Workspace
  },
  [Views.ADD_VIEW_OPERATION]: {},
  [Views.EDIT_VIEW]: {
    initialValues: View
  },
  [Views.VIEW_DETAILS]: {
    initialValues: View | StackedView
  }
}

const BLOCK_TYPE_TO_NAME_MAP = {
  TitleBlock: 'Title',
  HeaderBlock: 'Header',
  NotesBlock: 'Notes',
  StatBlock: 'Stat',
  QuickLinksBlock: 'Links',
  DataTableBlock: 'Table',
  DataListBlock: 'List',
  GridBlock: 'Grid',
  LineChartBlock: 'Line Chart',
  PieChartBlock: 'Pie Chart',
  BarChartBlock: 'Bar Chart',
  GaugeBlock: 'Gauge',
  BehaviorBlock: 'Behavior',
  FlowBlock: 'Flow',
  FunnelBlock: 'Funnel',
  ImpactBlock: 'Impact',
  RetentionBlock: 'Retention',
  TimeSeriesBlock: 'Time Series',
  DrawerBlock: 'Drawer',
  DetailsBlock: 'Details',
  FormBlock: 'Form',
  ColumnsBlock: 'Columns',
  TabsBlock: 'Tabs',
  EmailPreviewBlock: 'Email',
  DropdownBlock: 'Dropdown',
  TextInputBlock: 'Text Input',
  ButtonBlock: 'Button',
  CSVInputBlock: 'CSV Input'
} as const

const BLOCK_TYPE_TO_ICON_MAP = {
  TitleBlock: 'heading',
  HeaderBlock: 'heading',
  NotesBlock: 'notes',
  StatBlock: 'number-field',
  QuickLinksBlock: 'unlink',
  DataTableBlock: 'table',
  DataListBlock: 'data-list',
  GridBlock: 'grid',
  FormBlock: 'form',
  DetailsBlock: 'details',
  LineChartBlock: 'line-chart',
  PieChartBlock: 'pie-chart',
  BarChartBlock: 'bar-chart',
  GaugeBlock: 'gauge',
  BehaviorBlock: 'behaviour',
  FlowBlock: 'flow',
  FunnelBlock: 'block',
  ImpactBlock: 'impact',
  RetentionBlock: 'retention',
  TimeSeriesBlock: 'timesheets',
  DrawerBlock: 'block',
  ColumnsBlock: 'columns',
  TabsBlock: 'tabs',
  EmailPreviewBlock: 'email',
  DropdownBlock: 'dropdown-field',
  TextInputBlock: 'text-field',
  ButtonBlock: 'button',
  CSVInputBlock: 'grid'
} as const

const ELEMENT_TYPE_TO_ICON_MAP = {
  EnvironmentSwitcher: 'environment',
  InstallationSwitcher: 'integration',
  TextElement: 'text',
  ButtonElement: 'button',
  InputElement: 'input',
  ImageElement: 'image',
  TabElement: 'tab'
} as const

export {
  BLOCK_TYPE_TO_ICON_MAP,
  BLOCK_TYPE_TO_NAME_MAP,
  DASHBOARD_EDITOR_WIDTH,
  ELEMENT_TYPE_TO_ICON_MAP,
  INNER_CONTAINER_PADDING,
  LAYOUT_OVERLAP,
  Views
}

export type { ViewParams }
