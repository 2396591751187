import React from 'react'
import type { PropsWithChildren, ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import Box from 'components/layout/Box'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import useMounted from 'hooks/useMounted'
import { styled } from 'styles/stitches'
import { useTabs } from 'components/tabs/Tabs'

const StyledLabel = styled(Text, {
  ...mixins.transition('fluid'),

  cursor: 'pointer',
  minWidth: 96,
  padding: 16,
  position: 'relative',
  userSelect: 'none',
  textAlign: 'center',

  '&:hover, &:focus': {
    color: 'dark900'
  },

  '&&&[data-disabled=true]': {
    cursor: 'not-allowed'
  },

  variants: {
    isActive: {
      true: {
        color: 'dark900',
        position: 'relative',

        '&::after': {
          ...mixins.transition('fluid'),

          backgroundColor: 'dark900',
          bottom: 0,
          content: "''",
          height: 2,
          left: 0,
          position: 'absolute',
          width: '100%'
        }
      }
    }
  }
})

type TabProps = PropsWithChildren<{
  appendHeaderNode?: ReactNode,
  alwaysMounted?: boolean,
  keepMounted?: boolean,
  className?: string,
  disabled?:boolean,
  index: number,
  label: ReactNode,
  tabLabelClassName?: string,
  tabButtonRef?: React.Ref<any>,
  href?: string
}>

function Tab({
  children,
  className,
  disabled = false,
  index,
  label,
  appendHeaderNode,
  alwaysMounted = false,
  keepMounted = true,
  tabLabelClassName,
  tabButtonRef,
  href
}: TabProps) {
  const { containerEl, activeIndex, setActiveIndex } = useTabs()
  const isActive = activeIndex === index

  const mounted = useMounted(isActive)

  const shouldRender = (keepMounted ? mounted : isActive) || alwaysMounted

  return (
    <>
      <StyledLabel
        className={tabLabelClassName}
        aria-selected={isActive}
        as={href ? 'a' : 'button'}
        // @ts-ignore
        href={href}
        color="dark500"
        data-index={index}
        data-disabled={disabled}
        data-tab
        fontSize={14}
        isActive={isActive}
        onClick={() => setActiveIndex(index)}
        role="tab"
        type="button"
        ref={tabButtonRef}
      >
        {label}
        {appendHeaderNode}
      </StyledLabel>
      {shouldRender && (
        <Portal target={containerEl}>
          <Box className={className} role="tabpanel" style={{ display: isActive ? 'flex' : 'none', flexDirection: 'column' }}>
            {children}
          </Box>
        </Portal>
      )}
    </>
  )
}

export default Tab
