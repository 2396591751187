import React, { useEffect } from 'react'

import AppLoader from 'components/loaders/AppLoader'
import client from 'client'
import Flex from 'components/layout/Flex'
import useClientQuery from 'hooks/useClientQuery'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { persistor } from 'components/providers/ClientProvider'
import { SESSION_QUERY } from 'client/state/session'
import { styled } from 'styles/stitches'
import { useRevokeSessionMutation } from 'generated/schema'

const StyledFlex = styled(Flex, {
  height: 'calc(100vh - 80px)'
})

export default function LogoutPage() {
  const [ revokeSession ] = useRevokeSessionMutation()
  const { data: { session: { id } } } = useClientQuery(SESSION_QUERY)

  const handleSubmit = useSubmitHandler(revokeSession)

  useEffect(() => {
    handleSubmit({ id }).finally(async () => {
      await Promise.all([
        client.clearStore(),
        // https://github.com/apollographql/apollo-cache-persist/issues/126#issuecomment-602725578
        persistor.purge()
      ])

      // Manually redirect
      window.location.href = window.location.origin
    })
  // We want the revokeSession mutation to be called only once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledFlex grow={1}>
      <AppLoader />
    </StyledFlex>
  )
}
