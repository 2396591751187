import React from 'react'

import AppIconRenderer from 'components/renderers/AppIconRenderer'
import Button from 'components/buttons/Button'
import DataListBlock from 'components/blocks/DataListBlock'
import EnvironmentFormView from 'components/views/EnvironmentFormView'
import Flex from 'components/layout/Flex'
import Masonry from 'components/layout/Masonry'
import PageLoader from 'components/loaders/PageLoader'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import usePager from 'hooks/usePager'
import { css } from 'styles/stitches'
import { Configuration, Environment, useConfigurationsAggregateQuery, useConfigurationsListQuery, useEnvironmentQuery } from 'generated/schema'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'
import type { ViewProps } from 'components/views'

type ViewParamsType = {
  environmentId: string
}

const PAGE_SIZE_OPTIONS = [ 5, 10, 15 ]

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

function EnvironmentView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { openView } = useViewDispatch()
  const { environmentId } = params
  const [
    page, pageSize, handlePageChange, handlePageSizeChange
  ] = usePager({ initialPageSize: 5 })
  const { data, loading, error } = useEnvironmentQuery({
    variables: { id: environmentId }, skip: !environmentId
  })

  const {
    data: { configurationsList = [] } = {},
    loading: installationsLoading,
    error: installationsError
  } = useConfigurationsListQuery({
    variables: {
      filter: {
        environmentId: { eq: environmentId }
      },
      limit: pageSize,
      page
    },
    skip: !environmentId
  })

  const {
    data: { configurationsAggregate } = {}
  } = useConfigurationsAggregateQuery({
    variables: {
      filter: {
        environmentId: { eq: environmentId }
      }
    },
    skip: !environmentId
  })

  const openEnvironmentForm = (environment?: Environment) => {
    openView({
      title: `${environment?.id ? 'Edit' : 'Create'} Environment}`,
      component: EnvironmentFormView,
      params: {
        initialValues: { ...environment },
        isWorkspace: environment?.isLive,
        queryVariables: { id: environmentId }
      },
      style: 'DIALOG'
    })
  }

  const title = data?.environment.name || 'Environment'

  const onModifySettings = () => openEnvironmentForm(data?.environment)

  const contents: Content[] = [
    { dataKey: 'installation.app', slot: 'icon', renderer: AppIconRenderer as any },
    { dataKey: 'installation.name', slot: 'primary' },
    { dataKey: 'installation.identifier', slot: 'secondary' }
  ]

  const renderOverview = () => (
    <Flex direction="column" gap={36}>
      <Flex direction="column" gap={12}>
        <Masonry>
          <DataListBlock
            actions={[]}
            contents={contents}
            data={configurationsList as Configuration[]}
            error={installationsError}
            loading={installationsLoading}
            selectionMode="none"
            paginationMode="finite"
            page={page}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            title="App Configurations"
            totalRows={configurationsAggregate?.count || 0}
            onChangePage={handlePageChange}
            onChangePageSize={handlePageSizeChange}
            dataListItemVariant="detailed"
          />
        </Masonry>
      </Flex>
      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">Actions</Text>
        <Button label="Modify Settings" variant="outline" mode="distinct" size="small" onClick={onModifySettings} />
        <Button label="View Documentation" variant="outline" mode="distinct" size="small" href="https://docs.dashx.com/platform/advanced/environments" />
      </Flex>
    </Flex>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          {loading && !data ? (
            <Body>
              <PageLoader data={undefined} loading error={error} />
            </Body>
          ) : (
            <Tabs
              tabListBackground="light"
              panelWrapper={Body}
              tabListClassName={classes.tabsList}
            >
              <Tab label="Overview" index={0}>
                {renderOverview()}
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </View>
  )
}

EnvironmentView.defaultStyle = 'PANEL' as const

export default EnvironmentView
