import React from 'react'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import SelectInput from 'components/inputs/SelectInput'
import { PlainTextRenderer } from './PlainTextView'
import type { Attribute } from 'generated/schema'

type ConfigurationsProps = {
  fieldPrefix?: string
}

const ViewOptions = [
  {
    label: 'Dialog',
    value: 'dialog'
  },
  {
    label: 'Side Pane',
    value: 'side_pane'
  },
  {
    label: 'Inline',
    value: 'inline'
  },
  {
    label: 'Title Field',
    value: 'title_field'
  }
]

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data }: RendererProps) => (
  <PlainTextRenderer displaySettings={displaySettings} data={data} />
)

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}view_style`}
      component={SelectInput}
      label="View Style"
      size="small"
      options={ViewOptions}
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as ReferenceRenderer }
