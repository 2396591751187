import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

enum Kind {
  QUERY = 'QUERY',
  MUTATION = 'MUTATION'
}

enum BehaviorKind {
  DATABASE_QUERY = 'DATABASE_QUERY',
  API_REQUEST = 'API_REQUEST',
  CODE = 'CODE',
  FLOW = 'FLOW'
}

enum BehaviorMethod {
  GET = 'GET',
  LIST = 'LIST',
  AGGREGATE = 'AGGREGATE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DESTROY = 'DESTROY',
  GENERIC = 'GENERIC',
  IMPORT = 'IMPORT',
}

enum ActsOn {
  MEMBER = 'MEMBER',
  COLLECTION = 'COLLECTION'
}

const ACTS_ON_OPTIONS = [
  { label: 'Member', value: ActsOn.MEMBER },
  { label: 'Collection', value: ActsOn.COLLECTION }
]

const KIND_OPTIONS = [
  { label: 'Query', value: Kind.QUERY },
  { label: 'Mutation', value: Kind.MUTATION }
]

const BEHAVIOR_METHOD_OPTIONS = [
  { label: 'GENERIC', value: BehaviorMethod.GENERIC },
  { label: 'GET', value: BehaviorMethod.GET },
  { label: 'LIST', value: BehaviorMethod.LIST },
  { label: 'AGGREGATE', value: BehaviorMethod.AGGREGATE },
  { label: 'CREATE', value: BehaviorMethod.CREATE },
  { label: 'UPDATE', value: BehaviorMethod.UPDATE },
  { label: 'DESTROY', value: BehaviorMethod.DESTROY },
  { label: 'IMPORT', value: BehaviorMethod.IMPORT }
]

enum ResponseMapperKind {
  KEY = 'KEY',
  FUNCTION = 'FUNCTION'
}

const RESPONSE_MAPPER_KIND_OPTIONS = [
  { label: 'Key', value: ResponseMapperKind.KEY, description: 'Return the value at this key from the response.' },
  { label: 'Function', value: ResponseMapperKind.FUNCTION, description: 'A transformation function. This function will receive the response data as it\'s input.' }
]

class Operation extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required(),
    behaviorKind: string().required()
  })
}

export {
  ActsOn,
  BehaviorMethod,
  BehaviorKind,
  Kind,
  ResponseMapperKind,
  ACTS_ON_OPTIONS,
  BEHAVIOR_METHOD_OPTIONS,
  KIND_OPTIONS,
  RESPONSE_MAPPER_KIND_OPTIONS
}

export default Operation
