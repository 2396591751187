import isEqual from 'lodash/isEqual'
import React from 'react'
import uuid from 'uuid-random'

import convertToArray from 'lib/convertToArray'
import Flex from 'components/layout/Flex'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import Text from 'components/typography/Text'
import useDeepMemo from 'hooks/useDeepMemo'
import { styled } from 'styles/stitches'
import type { ComputeDiffWithOptionsProps } from 'components/contentVersion/VersionDiff'
import type { Attribute } from 'generated/schema'

type Options = {
  field: Attribute
}

type DropdownOption = {
  label: string,
  value: string
}

type DropdownFieldProps = {
  options: Options,
  values: string[]
}

const BORDER_RADIUS = 13
const PADDING_Y = 4
const PADDING_X = 12

const StyledText = styled(Text, {
  border: '1px solid dark500',
  borderRadius: BORDER_RADIUS,
  paddingX: PADDING_X,
  paddingY: PADDING_Y
})

function DropdownField({ values, options }: DropdownFieldProps) {
  const { field } = options

  const memoizedKeyedValues = useDeepMemo(() => (
    values.map((value) => ({ value, key: uuid() }))
  ), values)

  if (!values.length) return null

  const fieldOptions = field.settings?.options || []
  const fieldOptionsMapping = getPropertyToElementMap<DropdownOption>(fieldOptions, 'value')

  return (
    <Flex gap={10}>
      {memoizedKeyedValues.map(({ value, key }) => {
        const option = fieldOptionsMapping[value]

        return (
          <StyledText fontSize={12} key={key}>{option?.label || value}</StyledText>
        )
      })}
    </Flex>
  )
}

DropdownField.computeDiff = <T extends string | string[]>({
  previousValue, currentValue, options
}: ComputeDiffWithOptionsProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  return {
    previousNode: <DropdownField options={options} values={previous} />,
    currentNode: <DropdownField options={options} values={current} />,
    isDiff: !isEqual(previous, current)
  }
}

export default DropdownField
