import React from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import ListItem, { ListItemProps } from 'components/list/ListItem'
import Text from 'components/typography/Text'
import useClientQuery from 'hooks/useClientQuery'
import Workspace from 'models/Workspace'
import WorkspaceLogo from 'components/logos/WorkspaceLogo'
import { colorVars } from 'styles/theme'
import { isElectron, openWorkspace } from 'lib/electron'
import { PROSPECT_QUERY, ProspectQuery } from 'client/state/prospect'
import { styled } from 'styles/stitches'
import type { ProspectWorkspace as ProspectWorkspaceType } from 'generated/schema'

type WorkspaceListItemProps = ListItemProps & {
  workspace: ProspectWorkspaceType,
  target: '_blank'|'_self'
}

const StyledWorkspaceListItem = styled(ListItem, {
  '& [data-icon]': {
    ...mixins.transition('simple'),

    color: 'dark100'
  },

  '& [data-logo]': {
    ...mixins.transition('simple'),

    filter: 'grayscale(100%)'
  },

  '&:hover': {
    ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.2),

    '& [data-icon]': {
      color: 'dark900'
    },

    '& [data-logo]': {
      filter: 'grayscale(0%)'
    }
  }
})

/**
 * Remap the given url to the current `location.protocol` and `location.port`
 * with the given `hostname`. The pathname and query parameters are preserved.
 *
 * ```js
 * const url = 'https://keepworks.dashx.com/join?token=xxx
 * // Given location.port = 3000
 * const newUrl = remapUrl(url, 'docbuddy.dashx-staging.com')
 * // => https://docbuddy.dashx-staging.com:3000/join?token=xxx
 * ```
 * @param url The url to be remapped
 * @param hostname The hostname to use for the new url
 * @returns A new url
 */
function remapUrl(url: string, hostname: string): string {
  const { location } = window

  const mappedUrl = new URL(url)
  const port = location.port ? `:${location.port}` : ''
  const queryString = mappedUrl.searchParams ? `?${mappedUrl.searchParams.toString()}` : ''

  return `${location.protocol}//${hostname}${port}${mappedUrl.pathname}${queryString}`
}

function WorkspaceListItem({ workspace, target }: WorkspaceListItemProps) {
  const { data: { prospect } } = useClientQuery<ProspectQuery>(PROSPECT_QUERY)

  const { identifier, logoSymbol, name } = workspace
  const hostname = Workspace.getComputedHostname(workspace.identifier, workspace.hostname)

  const url = remapUrl(workspace.accessUrl, hostname)

  const handleOpenWorkspace = () => {
    openWorkspace({ workspaceIdentifier: identifier, workspaceEmail: prospect?.email || '' })
  }

  return (
    <StyledWorkspaceListItem
      alignItems="center"
      {...!isElectron && { href: url }}
      {...isElectron && { onClick: handleOpenWorkspace }}
      justifyContent="space-between"
      key={identifier}
      target={isElectron ? '_self' : target}
    >
      <Flex gap={20}>
        <WorkspaceLogo data-logo variant="symbol" size="small" logoSymbol={logoSymbol} />
        <Flex direction="column" justifyContent="center" gap={4}>
          <Text color="dark900" fontWeight="semibold" fontSize={14}>{name}</Text>
          <Text color="dark500" fontSize={12}>{hostname}</Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Icon data-icon name="arrow-right" size={10} />
      </Flex>
    </StyledWorkspaceListItem>
  )
}

export default WorkspaceListItem
