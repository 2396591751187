import rafSchd from 'raf-schd'
import React, { useCallback, useContext, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { gql } from '@apollo/client'

import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Loader from 'components/loaders/Loader'
import Masonry from 'components/layout/Masonry'
import MediaCard from 'components/mediaCard/MediaCard'
import PaletteIndicator from 'components/artwork/PaletteIndicator'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { DEFAULT_THEME } from 'lib/generateTheme'
import { ThemeFormView } from 'components/views'
import { useDestroyThemeMutation, useWorkspaceThemesListQuery, WorkspaceThemesListDocument } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { Theme, UpdateWorkspaceThemeInput } from 'generated/schema'

type WorkspaceThemeFormProps = FormPropsWithId<UpdateWorkspaceThemeInput> & {
  onCancel: () => void
}

// Had to add id here because getting id as string from introspection
type WorkspaceTheme = Pick<Theme, 'name' | 'palette' | 'workspaceId'> & { id: string | null }

function WorkspaceThemeForm({ onCancel, ...others }: WorkspaceThemeFormProps) {
  const {
    changeThemePalette,
    currentWorkspace,
    resetThemePaletteRef
  } = useContext(WorkspaceContext)!
  const { openView } = useViewDispatch()

  // Resets theme back when we navigate away without saving or canceling changes to theme
  useEffect(() => resetThemePaletteRef.current, [ resetThemePaletteRef ])

  const debouncedChangeThemePalette = useCallback(
    (palette) => rafSchd(changeThemePalette)(palette), [ changeThemePalette ]
  )

  const openThemeForm = (theme: WorkspaceTheme, currentTheme?: WorkspaceTheme) => {
    debouncedChangeThemePalette(theme.palette)

    const newTheme = { palette: theme.palette }
    const oldTheme = { id: theme.id, palette: theme.palette, name: theme.name }
    const activeTheme = theme.workspaceId ? oldTheme : newTheme

    openView({
      title: `${activeTheme && 'id' in activeTheme ? 'Update' : 'Create'} Theme`,
      component: ThemeFormView,
      params: {
        theme: activeTheme,
        currentTheme
      },
      style: 'DIALOG'
    })
  }

  const { data, loading, error } = useWorkspaceThemesListQuery()

  const [ deleteTheme ] = useDestroyThemeMutation({
    onCompleted: (response) => {
      if (response.destroyTheme.id === currentWorkspace.themeId) {
        debouncedChangeThemePalette(DEFAULT_THEME.palette)
      }
    }
  })

  const handleDeleteTheme = useSubmitHandler(deleteTheme, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyTheme',
      typename: 'Theme'
    },
    update: (cache, destroyThemeMutation) => {
      cache.writeQuery({
        query: WorkspaceThemesListDocument,
        data: {
          themesList: data?.themesList.filter(
            (theme) => theme.id !== destroyThemeMutation.data?.destroyTheme.id
          )
        }
      })

      if (destroyThemeMutation.data?.destroyTheme.id === currentWorkspace.themeId) {
        const workspaceFragment = gql`
          fragment WorkspaceFragment on Workspace {
            themeId
            theme
          }
        `

        cache.writeFragment({
          id: cache.identify(currentWorkspace),
          fragment: workspaceFragment,
          data: { theme: null, themeId: null }
        })
      }
    }
  })

  const confirm = useConfirmation()

  const themes = data?.themesList ? [ DEFAULT_THEME, ...data.themesList ] : []
  const systemThemes = themes.filter((t) => !t.workspaceId)
  const customThemes = themes.filter((t) => t.workspaceId)

  const renderThemeCard = ({ theme, onClick, currentTheme } : {
    theme: WorkspaceTheme,
    onClick: () => void,
    currentTheme: WorkspaceTheme | undefined
  }) => {
    const getActions = () => {
      if (!theme.workspaceId) {
        return [
          { icon: 'edit', description: 'Customize', onClick: () => openThemeForm(theme, currentTheme) }
        ]
      }

      return [
        { icon: 'edit', description: 'Edit', onClick: () => openThemeForm(theme, currentTheme) },
        {
          icon: 'trash',
          description: 'Delete',
          onClick: () => confirm({
            action: 'delete',
            recordType: 'theme',
            recordDescription: theme.name,
            onConfirmClick: () => handleDeleteTheme({ id: theme.id! })
          })
        }
      ]
    }

    return (
      <Block key={theme.id || 'default'}>
        <MediaCard
          actions={getActions()}
          active={theme.id === currentTheme?.id}
          onClick={onClick}
          text={theme.workspaceId ? 'CUSTOM' : 'SYSTEM'}
          title={theme.name}
          media={(
            <PaletteIndicator
              primary={theme.palette.primary}
              secondary={theme.palette.secondary}
              accent={theme.palette.accent}
            />
        )}
        />
      </Block>
    )
  }

  return (
    <Form
      mutators={{
        setTheme: ([ theme ]: [ WorkspaceTheme ], state, utils) => {
          debouncedChangeThemePalette(theme.palette)
          utils.changeValue(state, 'themeId', () => theme.id)
        }
      }}
      subscription={{
        submitting: true,
        pristine: true,
        values: true
      }}
      render={({ handleSubmit, submitting, pristine, form: { reset, mutators }, values }) => {
        const currentTheme = themes.find((theme) => theme.id === values.themeId)
        const handleClick = (theme: WorkspaceTheme) => mutators.setTheme(theme)

        return (
          <form onSubmit={handleSubmit} onReset={reset}>
            <Field name="themeId" component="input" type="hidden" />

            <Loader
              data={themes}
              empty={{ title: 'There are no themes.' }}
              error={error}
              loading={loading}
            >
              <Flex direction="column" gap={32}>
                <Text fontSize={14}>Select a theme for your workspace from the ones listed below, or{' '}
                  <TextLink
                    alignSelf="flex-start"
                    as="button"
                    fontSize={14}
                    fontWeight="semibold"
                    mode="subtle"
                    onClick={() => openThemeForm(currentTheme!, currentTheme)}
                    type="button"
                  >
                    create your own custom theme
                  </TextLink>.
                </Text>
                <Flex gap={16} direction="column">
                  {customThemes.length > 0 && (
                    <>
                      <div>
                        <Masonry gap={16}>
                          {customThemes.map((theme) => renderThemeCard({
                            theme,
                            onClick: () => handleClick(theme),
                            currentTheme
                          }))}
                        </Masonry>
                      </div>
                      <Divider />
                    </>
                  )}
                  <div>
                    <Masonry gap={16}>
                      {systemThemes.map((theme) => renderThemeCard({
                        theme,
                        onClick: () => handleClick(theme),
                        currentTheme
                      }))}
                    </Masonry>
                  </div>
                  <Divider variant="whitespace" />
                </Flex>
              </Flex>

              <Flex gap={24}>
                <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                <Button type="reset" variant="outline" label="Cancel" mode="subtle" disabled={submitting || pristine} onClick={onCancel} />
              </Flex>
            </Loader>
          </form>
        )
      }}
      {...others}
    />
  )
}

export default WorkspaceThemeForm

export type { WorkspaceTheme }
