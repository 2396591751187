import React from 'react'
import { Field, Form } from 'react-final-form'

import Account from 'models/Account'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import type { ForgotPasswordInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'

function ForgotPasswordForm(props: FormPropsWithId<ForgotPasswordInput>) {
  return (
    <Form
      validate={(values) => Account.validate(values, [ 'email' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap={16}>
            <Field name="email" autoComplete="on" component={TextInput} type="text" inputMode="email" placeholder="Enter your work email address" autoFocus />
            <Flex justifyContent="flex-end">
              <Button type="submit" label="Submit" disabled={submitting} />
            </Flex>
          </Flex>
        </form>
      )}
      {...props}
    />
  )
}

export default ForgotPasswordForm
