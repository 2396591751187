import get from 'lodash/get'
import React from 'react'

import AccountModel from 'models/Account'
import AccountAvatar from 'components/avatars/AccountAvatar'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Account } from 'generated/schema'
import type { RendererOptions } from 'components/dataTable/types'

const StyledText = styled(Text, {
  color: 'currentColor',
  flexGrow: 1
})

function AccountRenderer<T>({
  rowData,
  dataKey
}: RendererOptions<Account> | RendererOptions<T>) {
  const data = get(rowData, dataKey)
  const account = data?.__typename === 'Account' ? data : rowData

  const name = AccountModel.getFullName(account)

  return (
    <Flex gap={16} alignItems="center" grow={1}>
      <AccountAvatar account={account} />
      <StyledText
        as="span"
        title={name}
        truncate
      >
        {name}
      </StyledText>
    </Flex>
  )
}

export default AccountRenderer
