import { generateThemeVariants, generateVariants, styled } from 'styles/stitches'

import type { Space } from 'styles/theme'

const DIVIDER_SIZES = {
  ruler: 1,
  whitespace: 0
}

const variants = [ 'ruler', 'whitespace' ] as const
const orientations = [ 'horizontal', 'vertical' ] as const

const containerSize = ({ variant, spacing = variant === 'ruler' ? 0 : 4 }: { variant: typeof variants[number], spacing?: Space }) => (
  DIVIDER_SIZES[variant!] + 2 * spacing!
)

const colorVariants = generateThemeVariants('colors', 'backgroundColor', '&::before')
const spacingVariants = generateThemeVariants('space')
const variantVariants = generateVariants(variants)

const Divider = styled('div', {
  display: 'flex',

  '&::before': {
    content: "''",
    display: 'block'
  },

  variants: {
    color: colorVariants,
    spacing: spacingVariants,
    orientation: {
      vertical: {
        justifyContent: 'center',
        height: '100%',

        '&::before': {
          height: '100%'
        }
      },
      horizontal: {
        alignItems: 'center',
        width: '100%',

        '&::before': {
          width: '100%'
        }
      }
    },
    variant: variantVariants
  }
})

orientations.forEach((orientation) => {
  variants.forEach((variant) => {
    Divider.compoundVariant(
      {
        orientation,
        variant
      },
      {
        // When spacing is NOT specified
        [orientation === 'vertical' ? 'width' : 'height']: containerSize({ variant }),

        '&::before': {
          [orientation === 'vertical' ? 'width' : 'height']: DIVIDER_SIZES[variant]
        }
      }
    )
  })
})

orientations.forEach((orientation) => {
  variants.forEach((variant) => {
    (Object.keys(spacingVariants) as unknown as Array<Space>).forEach((spacing) => {
      Divider.compoundVariant(
        {
          orientation,
          spacing,
          variant
        },
        {
          // When spacing is specified
          [orientation === 'vertical' ? 'width' : 'height']: containerSize({ spacing, variant })
        }
      )
    })
  })
})

Divider.defaultProps = {
  color: 'light700',
  orientation: 'horizontal',
  variant: 'ruler',
  role: 'separator'
}

export default Divider

export type DividerProps = StyledProps<typeof Divider>
