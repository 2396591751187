import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { DragOverlay, useDraggable } from '@dnd-kit/core'

import { createPortal } from 'react-dom'

import { restrictToWindowEdges } from '@dnd-kit/modifiers'

import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import IconCard from 'components/iconCard/IconCard'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useHotKey from 'hooks/useHotKey'
import { SidePaneDivider } from 'components/sidePane'
import { ViewParams, Views } from './constants'
import type { ActiveViewProps } from './DashboardEditor'

type Params = ViewParams[Views.ADD_MENU_ELEMENT]

function AddMenuView({ onClose }: ActiveViewProps) {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add Menu"
        heading="Dashboard Editor"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>Click or drag-and-drop a component onto the sidebar or topbar.</Text>
        <Basics search={search} />
        <Organizers search={search} />
        <SidePaneDivider size="small" />
        <Advanced />
      </DashboardEditorBody>
    </>
  )
}

const menuItems = {
  basics: [
    {
      title: 'View',
      icon: 'display-normal',
      identifier: Views.ADD_MENU_ELEMENT,
      initialValues: {
        name: 'View',
        kind: 'ITEM',
        target: 'VIEW',
        icon: 'display-normal'
      }
    },
    {
      title: 'Submenu',
      icon: 'menu-item',
      identifier: Views.ADD_MENU_ELEMENT,
      initialValues: {
        name: 'Submenu',
        kind: 'ITEM',
        target: 'SUBMENU',
        icon: 'menu-item'
      }
    },
    {
      title: 'Link',
      icon: 'link',
      identifier: Views.ADD_MENU_ELEMENT,
      initialValues: {
        name: 'Link',
        kind: 'ITEM',
        target: 'URL',
        icon: 'link',
        url: 'https://docs.dashx.com'
      }
    }
  ],
  organizers: [
    {
      title: 'Header',
      icon: 'heading',
      identifier: Views.ADD_MENU_ELEMENT,
      initialValues: {
        name: 'Header',
        kind: 'GROUP',
        icon: 'heading'
      }
    },
    {
      title: 'Separator',
      icon: 'separator',
      identifier: Views.ADD_MENU_ELEMENT,
      initialValues: {
        kind: 'SEPARATOR',
        separatorStyle: 'RULER',
        icon: 'separator'
      }
    }
  ],
  advanced: [
    {
      title: 'Project Menu',
      icon: 'projects',
      identifier: Views.ADD_MENU_PROJECT
    },
    {
      title: 'App Menu',
      icon: 'app',
      identifier: Views.ADD_MENU_APP
    },
    // {
    //   title: 'Integration Menu',
    //   icon: 'integration',
    //   identifier: Views.INTEGRATION
    // },
    {
      title: 'Resource Menu',
      icon: 'graph',
      identifier: Views.ADD_MENU_RESOURCE
    }
  ]
}
const DraggableCard = ({ menu, isActive, isOverlay }: any) => {
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues } = params as Params

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: menu.identifier + menu.icon,
    data: menu.initialValues
  })

  const onAdd = () => {
    openDashboardEditorView({
      target: menu.identifier,
      params: {
        initialValues: {
          ...menu.initialValues,
          ...initialValues
        }
      }
    })
  }

  return (
    <IconCard
      width={108}
      height={108}
      icon={menu.icon}
      label={menu.title}
      css={{
        opacity: isActive ? 0.5 : 1,
        border: isActive && !isOverlay ? '1px dashed gray' : '1px solid transparent'
      }}
      active={isActive}
      onClick={onAdd}
      actions={[
        {
          icon: 'drag-block',
          onClick: onAdd
        }
      ]}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    />
  )
}

const withMenuCategory = (category: keyof typeof menuItems, title: string) => ({ search = '' }: { search: string }) => {
  const { draggedMenuState, draggingOverMenuIdState } = useDashboard()

  const [ draggedMenu, setDraggedMenu ] = useRecoilState(draggedMenuState)
  const setDraggingOverMenuId = useSetRecoilState(draggingOverMenuIdState)

  useEffect(() => () => {
    setDraggedMenu(null)
  }, [ setDraggedMenu ])

  useHotKey({
    Escape: () => {
      setDraggedMenu(null)
      setDraggingOverMenuId(null)
    }
  })

  const filteredList = menuItems[category].filter(
    (menu) => menu.title.toLowerCase().includes(search.toLowerCase())
  )

  if (!filteredList.length) return null

  return (
    <Flex gap={16} direction="column">
      <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">{title}</Text>
      <Grid columns={3} gap={2}>
        {filteredList.map((menu) => {
          const isActive = draggedMenu?.icon === menu.icon && !draggedMenu?.id
          return <DraggableCard key={menu.identifier + menu.icon} menu={menu} isActive={isActive} />
        })}
      </Grid>
      {createPortal(
        <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
          {draggedMenu?.icon && !draggedMenu?.id ? (
            <DraggableCard menu={draggedMenu} isActive isOverlay />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </Flex>
  )
}

const Basics = withMenuCategory('basics', 'Basics')

const Organizers = withMenuCategory('organizers', 'Organizers')

const Advanced = () => {
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)

  return (
    <Flex gap={16} direction="column">
      <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">Advanced</Text>
      <Flex direction="column" gap={2}>
        {menuItems.advanced.map((menu) => (
          <MediaCard
            compact
            onClick={() => openDashboardEditorView({ target: menu.identifier, params })}
            key={menu.title}
            media={menu.icon}
            title={menu.title}
            height={64}
            width="full"
            actions={[ {
              description: '',
              icon: 'arrow-right',
              isIconAlwaysVisible: true
            } ]}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default AddMenuView
