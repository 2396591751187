import useSubmitHandler from 'hooks/useSubmitHandler'
import { usePublishRecordVersionMutation, useUnpublishRecordVersionMutation } from 'generated/schema'

enum PublishingAction {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish'
}

type HandlePublishingProps = {
  action: PublishingAction,
  id?: string
}

type HandlePublishingFn = (options: HandlePublishingProps) => Promise<Record<string, any>>

function useRecordPublishing() {
  const [ publishRecord ] = usePublishRecordVersionMutation()
  const publishRecordSubmitHandler = useSubmitHandler(publishRecord)

  const [ unpublishRecord ] = useUnpublishRecordVersionMutation()
  const unpublishRecordSubmitHandler = useSubmitHandler(unpublishRecord)

  const handlePublishing = ({ action, id }: HandlePublishingProps) => {
    if (action === PublishingAction.UNPUBLISH) {
      return unpublishRecordSubmitHandler({ id })
    }

    return publishRecordSubmitHandler({
      id
    })
  }

  return [ handlePublishing ]
}

export type {
  HandlePublishingFn
}

export {
  PublishingAction
}

export default useRecordPublishing
