import React, { Fragment } from 'react'
import type { ReactElement, ReactNode } from 'react'

import Divider from 'components/divider/Divider'
import { BaseModal, ModalBody, ModalFooter, ModalHeader, ModalSubHeader } from 'components/modal'
import { css } from 'styles/stitches'
import type { BaseModalProps } from 'components/modal/BaseModal'

type ModalRenderProps = {
  Header: typeof ModalHeader,
  Body: typeof ModalBody,
  Footer: typeof ModalFooter,
  Divider: typeof Divider,
  SearchBar: typeof Fragment,
  SubHeader: typeof ModalSubHeader
}

const classes = {
  contentBase: css({
    backgroundColor: 'light400',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%'
  })
}

type ModalProps = BaseModalProps & {
  children: ((props: ModalRenderProps) => ReactNode) | ReactElement | ReactElement[]
}

function Modal({ children, ...other }: ModalProps) {
  const renderChild = () => {
    if (typeof children !== 'function') {
      return children
    }

    return children({
      Body: ModalBody,
      Divider,
      Footer: ModalFooter,
      Header: ModalHeader,
      SearchBar: Fragment,
      SubHeader: ModalSubHeader
    })
  }
  return (
    <BaseModal
      contentBaseClassName={classes.contentBase}
      {...other}
    >
      {renderChild()}
    </BaseModal>
  )
}

export type { ModalProps, ModalRenderProps }

export default Modal
