import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useMutation } from '@apollo/client'

import Account, { MFA_CODE_LENGTH } from 'models/Account'
import AuthModel from 'models/AuthModel'
import Box from 'components/layout/Box'
import Button from 'components/buttons/Button'
import CopyToClipboard from 'components/buttons/CopyToClipboard'
import Chip from 'components/chip/Chip'
import Flex, { FlexProps } from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import GlobalContext from 'components/contexts/GlobalContext'
import HintBox from 'components/hints/HintBox'
import Loader from 'components/loaders/Loader'
import parseError from 'lib/parseError'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useDownloadTextFile from 'hooks/useDownloadTextFile'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceLogo from 'components/logos/WorkspaceLogo'
import { CurrentAccountDocument, useConfigureMfaMutation, useDisableMfaMutation, useEnableMfaMutation, useRetrieveMfaBackupCodesLazyQuery } from 'generated/schema'
import { SET_SESSION_MUTATION } from 'client/state/session'
import { useViewDispatch } from 'hooks/useViewContext'
import type { ViewProps, ViewStyleComponentRenderProps } from 'components/views'

const EXTERNAL_LINKS = {
  AUTHY: 'https://authy.com/features/setup',
  DUO: 'https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app',
  GOOGLE_AUTHENTICATOR: 'https://support.google.com/accounts/answer/1066447',
  KEEPER: 'https://www.keepersecurity.com',
  ONE_PASSWORD: 'https://1password.com'
}

const ComponentToPrint = React.forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
  <Flex direction="column" gap={80} ref={ref} css={{ padding: 60 }}>
    <WorkspaceLogo logoFull={props.logoFull} />
    <Flex direction="column">
      <Text fontSize={24} lineHeight="cozy" style={{ whiteSpace: 'pre' }}>{props.content}</Text>
    </Flex>
  </Flex>
))

const BackupCodesBlock = ({ codes, onActionComplete }: any) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const componentRef = useRef(null)
  const [ isPrintDisabled, setIsPrintDisabled ] = useState(false)

  const fileName = `dashx-${currentWorkspace.name.toLowerCase()}-mfa-backup-codes`
  const content = codes.join('\n')
  const downloadTextFile = useDownloadTextFile(content, `${fileName}.txt`)

  const printTrigger = useCallback(() => <Button disabled={isPrintDisabled} label="Print Codes" icon="print" variant="outline" mode="subtle" size="small" />, [ isPrintDisabled ])

  const handleDownloadTextFile = () => {
    downloadTextFile()
    onActionComplete && onActionComplete(true)
  }

  return (
    <>
      <Flex style={{ rowGap: 10 }} alignItems="flex-start" justifyContent="space-around" wrap="wrap">
        {codes.map((c: string) => (
          <Chip key={c} textTransform="none" css={{ flexBasis: '40%' }} justifyContent="center" size="large" label={c} variant="light" />
        ))}
      </Flex>
      <Text fontSize={14} color="dark500">
        Each backup code can be used once in place of a regular verification code.
      </Text>
      <Flex gap={14} direction="column">
        <Button label="Download Codes" icon="download" variant="filled" mode="subtle" onClick={handleDownloadTextFile} size="small" />
        <div style={{ display: 'none' }}>
          <ComponentToPrint
            ref={componentRef}
            logoFull={currentWorkspace?.logoFull}
            content={content}
          />
        </div>
        <ReactToPrint
          content={() => componentRef.current}
          documentTitle={`${fileName}.pdf`}
          trigger={printTrigger}
          removeAfterPrint
          onBeforePrint={() => setIsPrintDisabled(true)}
          onAfterPrint={() => {
            onActionComplete && onActionComplete(true)
            setIsPrintDisabled(false)
          }}
        />
        <CopyToClipboard textToCopy={content} label="Copy Codes" onComplete={() => onActionComplete && onActionComplete(true)} buttonSize="small" />
      </Flex>
    </>
  )
}

const SetupMfaView = ({
  onRequestClose,
  openView,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<{}>) => {
  const [ configureMfa, {
    data: configureMfaData,
    loading: isConfigureMfaLoading
  } ] = useConfigureMfaMutation()

  const [ setSession ] = useMutation(SET_SESSION_MUTATION)

  const [ currentStep, setCurrentStep ] = useState(0)
  const [ isActionComplete, setIsActionComplete ] = useState(false)
  const [ enableMfa, { data: enableMfaData } ] = useEnableMfaMutation({
    onCompleted: () => {
      setCurrentStep(currentStep + 1)

      setSession({ variables: { session: {
        isMfaRequired: true,
        mfaVerifiedAt: Date.now()
      } } })
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: CurrentAccountDocument }
    ]
  })

  const isVerificationStep = currentStep === 0

  const handleEnableMfa = useSubmitHandler(enableMfa, {
    successAlert: { message: 'Two-Factor Authentication enabled.' }
  })

  useEffect(() => {
    configureMfa()
  }, [ configureMfa ])

  const VerificationView = () => (
    <Flex direction="column" gap={32}>
      <Flex direction="column" gap={14}>
        <Text fontSize={14} color="dark500">STEP 1</Text>
        <Text fontSize={16} fontWeight="bold">Get Authenticator App</Text>
        <Text fontSize={14}>Download and install{' '}
          <TextLink href={EXTERNAL_LINKS.GOOGLE_AUTHENTICATOR}>Google Authenticator</TextLink>,{' '}
          <TextLink href={EXTERNAL_LINKS.AUTHY}>Authy</TextLink>, or{' '}
          <TextLink href={EXTERNAL_LINKS.DUO}>Duo</TextLink> for your phone or tablet.
        </Text>
      </Flex>
      <Flex alignItems="flex-start" direction="column" gap={14}>
        <Text fontSize={14} color="dark500">STEP 2</Text>
        <Text fontSize={16} fontWeight="bold">Scan QR Code</Text>
        <Text fontSize={14}>
          Using the Authenticator App, scan the following QR code:
        </Text>
        {isConfigureMfaLoading && (
          <Flex css={{ width: 150, height: 150 }}>
            <Loader loading={isConfigureMfaLoading} />
          </Flex>
        )}
        {configureMfaData?.configureMfa?.qrCode && (
          <img
            style={{ width: 150, height: 150 }}
            src={`data:image/png;base64, ${configureMfaData.configureMfa.qrCode}`}
            alt="QR code"
          />
        )}
      </Flex>
      <Flex direction="column" gap={14}>
        <Text fontSize={14} color="dark500">STEP 3</Text>
        <Text fontSize={16} fontWeight="bold">Enter Verification Code</Text>
        <Text fontSize={14}>
          Enter the {MFA_CODE_LENGTH}-digit verification code generated by the Authenticator App:
        </Text>
        <FormField
          autoFocus
          component={TextInput}
          maxLength={MFA_CODE_LENGTH}
          name="code"
          placeholder="XXXXXX"
          size="large"
          type="text"
        />
      </Flex>
    </Flex>
  )

  const BackupCodesView = ({ onRequestClose, onActionComplete }: any) => (
    <Flex direction="column" gap={32}>
      <Flex direction="column" gap={12}>
        <Text fontSize={14} color="dark500">FINAL STEP</Text>
        <Text fontSize={16} fontWeight="bold">Save your Backup Codes</Text>
        <Text fontSize={14}>
          These one-time codes allow you to sign-in when you do
          not have access to your device, like when traveling.
        </Text>
      </Flex>
      <BackupCodesBlock
        codes={enableMfaData?.enableMfa.mfaBackupCodes || []}
        onRequestClose={onRequestClose}
        onActionComplete={onActionComplete}
      />
    </Flex>
  )

  return (
    <View contentLabel="MFA Settings" onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }: ViewStyleComponentRenderProps) => (
        <>
          <Header
            title="Configure Two-Factor Authentication"
            onCloseClick={onRequestClose}
          />
          <Form
            validate={(values) => AuthModel.validate(values, [ 'code' ])}
            initialValues={{ code: '' }}
            onSubmit={handleEnableMfa}
            render={({ handleSubmit, submitting }) => (
              <>
                <Body>
                  <HintBox>
                    <b>Need help?</b> Learn more about{' '}
                    <TextLink href="/">two-factor authentication</TextLink>.
                  </HintBox>
                  <Box css={{ height: 24 }} />
                  <form onSubmit={handleSubmit}>
                    {isVerificationStep ? <VerificationView />
                      : <BackupCodesView onActionComplete={setIsActionComplete} /> }
                  </form>
                </Body>
                <Footer>
                  <Flex direction="row-reverse" gap={16}>
                    {isVerificationStep && (
                      <Button
                        disabled={submitting}
                        label="Submit"
                        onClick={handleSubmit}
                        type="submit"
                      />
                    )}
                    {!isVerificationStep && <Button label="Done" disabled={!isActionComplete} onClick={onRequestClose} />}
                  </Flex>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

const DisableMfaConfirmationView = ({
  onRequestClose,
  openView,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<{}>) => {
  const [ disableMfa ] = useDisableMfaMutation({
    onCompleted: onRequestClose
  })

  const handleDisableMfa = useSubmitHandler(disableMfa, {
    successAlert: { message: 'Two-Factor Authentication disabled.' }
  })

  return (
    <View contentLabel="Confirm Disable MFA" onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }: ViewStyleComponentRenderProps) => (
        <>
          <Header
            title="Disable Two-Factor Authentication"
            onCloseClick={onRequestClose}
          />
          <Form
            validate={(values) => AuthModel.validate(values, [ 'code' ])}
            initialValues={{ code: '' }}
            onSubmit={handleDisableMfa}
            render={({ handleSubmit, submitting }) => (
              <>
                <Body>
                  <Flex as="form" direction="column" gap={32} onSubmit={handleSubmit}>
                    <Text>
                      This will deactivate two-factor authentication,
                      which will make your account less secure.
                    </Text>
                    <Flex direction="column" gap={16}>
                      <Text>
                        Enter the {MFA_CODE_LENGTH}-digit verification code
                        generated by the Authenticator App to continue:
                      </Text>
                      <FormField
                        autofocus
                        component={TextInput}
                        maxLength={MFA_CODE_LENGTH}
                        name="code"
                        placeholder="XXXXXX"
                        size="large"
                        type="text"
                      />
                    </Flex>
                  </Flex>
                </Body>
                <Footer wide>
                  <Flex gap={16}>
                    <Button label="Cancel" variant="outline" mode="subtle" onClick={onRequestClose} />
                    <Button
                      disabled={submitting}
                      label="Confirm"
                      mode="subtle"
                      onClick={handleSubmit}
                      type="submit"
                      variant="filled"
                    />
                  </Flex>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

const ViewBackupCodesView = ({
  onRequestClose,
  openView,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<{}>) => {
  const { openFailureAlert } = useContext(GlobalContext)!
  const [ currentStep, setCurrentStep ] = useState(0)

  const [
    retrieveMfaBackupCodes,
    { loading: retrieving, data }
  ] = useRetrieveMfaBackupCodesLazyQuery({
    variables: {
      input: {
        password: ''
      }
    },

    onCompleted: () => setCurrentStep(currentStep + 1),

    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error)

      const { alert, formErrors } = parseError(error)
      if (formErrors) return Promise.resolve(formErrors)

      const errorAlert = alert
      if (errorAlert) openFailureAlert(errorAlert)

      return Promise.resolve({ [FORM_ERROR]: error })
    }
  })

  return (
    <View contentLabel="View Backup Codes" onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }: ViewStyleComponentRenderProps) => (
        <>
          <Header
            title="Backup Codes"
            onCloseClick={onRequestClose}
          />
          {currentStep === 0 ? (
            <Form
              onSubmit={({ password }: { password: string }) => retrieveMfaBackupCodes({
                variables: { input: { password } }
              })}
              validate={(values) => Account.validate(values, [ 'password' ])}
              render={({ handleSubmit }) => (
                <>
                  <Body>
                    <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                      <HintBox>
                        <b>Need help?</b> Learn more about{' '}
                        <TextLink href="/">backup codes</TextLink>.
                      </HintBox>
                      <Text fontWeight="bold">Verify It&apos;s You</Text>
                      <Text>Please re-enter your password to reveal your backup codes:</Text>
                      <FormField
                        autofocus
                        autoComplete="on"
                        component={TextInput}
                        name="password"
                        placeholder="Enter password"
                        type="password"
                      />
                    </Flex>
                  </Body>
                  <Footer>
                    <Button
                      label="Submit"
                      onClick={handleSubmit}
                      disabled={retrieving}
                      type="submit"
                    />
                  </Footer>
                </>
              )}
            />
          ) : (
            <>
              <Body>
                <Flex direction="column" gap={16}>
                  <HintBox>
                    <b>Need help?</b> Learn more about{' '}
                    <TextLink href="/">backup codes</TextLink>.
                  </HintBox>
                  <Text fontWeight="bold">Unused Backup Codes</Text>
                  <Text>
                    You have {data?.retrieveMfaBackupCodes.mfaBackupCodes.length || 0}
                    {' '}backup codes remaining.
                  </Text>
                  <BackupCodesBlock
                    codes={data?.retrieveMfaBackupCodes.mfaBackupCodes || []}
                  />
                  <Text>
                    Keep your backup codes as safe as your password.
                    We recommend saving them with a password manager such as
                    {' '}<TextLink href={EXTERNAL_LINKS.ONE_PASSWORD} mode="subtle" variant="underlined">1Password</TextLink>,
                    {' '}<TextLink href={EXTERNAL_LINKS.AUTHY} mode="subtle" variant="underlined">Authy</TextLink>, or
                    {' '}<TextLink href={EXTERNAL_LINKS.KEEPER} mode="subtle" variant="underlined">Keeper</TextLink>.
                  </Text>
                </Flex>
              </Body>
              <Footer>
                <Button label="Done" onClick={onRequestClose} />
              </Footer>
            </>
          )}
        </>
      )}
    </View>
  )
}

const MfaEnabledView = () => {
  const { openView } = useViewDispatch()

  return (
    <Flex direction="column" gap={36}>
      <Flex alignItems="flex-start" direction="column" gap={16}>
        <Text fontSize={14} fontWeight="bold">
          Authenticator app
        </Text>
        <Text fontSize={14}>
          Get verification codes from an authentication app,
          such as{' '}
          <TextLink href={EXTERNAL_LINKS.GOOGLE_AUTHENTICATOR}>Google Authenticator</TextLink>,{' '}
          <TextLink href={EXTERNAL_LINKS.AUTHY}>Authy</TextLink>, or{' '}
          <TextLink href={EXTERNAL_LINKS.DUO}>Duo</TextLink>.
        </Text>
        <Button
          label="Disable Two-Factor Authentication"
          mode="negative"
          onClick={() => openView({ title: 'Disable Two-Factor Authentication', component: DisableMfaConfirmationView, style: 'DIALOG' })}
        />
      </Flex>
      <Flex alignItems="flex-start" direction="column" gap={16}>
        <Text fontSize={14} fontWeight="bold">
          Backup codes
        </Text>
        <Text fontSize={14}>
          These one-time codes allow you to sign-in when you do not have access to your device,
          like when traveling.
        </Text>
        <Button
          label="View Backup Codes"
          mode="distinct"
          variant="simple"
          onClick={() => openView({ title: 'Backup Codes', component: ViewBackupCodesView, style: 'PANEL' })}
        />
        <Text fontSize={14}>Note: Each backup code can be used only once.</Text>
      </Flex>
    </Flex>
  )
}

const MfaDisabledView = () => {
  const { openView } = useViewDispatch()

  return (
    <Flex alignItems="flex-start" direction="column" gap={16}>
      <Button
        label="Setup Two-Factor Authentication"
        mode="distinct"
        onClick={() => { openView({ title: 'Configure Two-Factor Authentication', component: SetupMfaView, style: 'PANEL' }) }}
      />
      <Text fontSize={14}>Note: You will be signed out of all your other sessions.</Text>
    </Flex>
  )
}

const MfaSettingsView = ({ isMfaEnabled = false }: {
  isMfaEnabled?: boolean
}) => (
  <Flex direction="column">
    <Text fontSize={14}>
      Two-factor authentication is an extra layer of security for your account. It is currently <b>{isMfaEnabled ? 'enabled' : 'disabled'}</b>.
    </Text>
    <Box css={{ height: 32 }} />
    {isMfaEnabled ? <MfaEnabledView /> : <MfaDisabledView />}
  </Flex>
)

export default MfaSettingsView
