import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import ExternalParagraph from 'components/typography/ExternalParagraph'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import useSubmitHandler from 'hooks/useSubmitHandler'
import VerifyEmailForm from 'components/forms/VerifyEmailForm'
import { CODE_LENGTH } from 'models/Prospect'
import { SET_PROSPECT_MUTATION } from 'client/state/prospect'
import { useVerifyProspectMutation, VerifyProspectInput } from 'generated/schema'

type VerifyEmailPageLocationState = {
  email: string
}

function VerifyEmailPage() {
  const { state: { email } = {} } = useLocation<VerifyEmailPageLocationState>()
  const { push } = useHistory()

  const [ setProspect ] = useMutation(SET_PROSPECT_MUTATION)
  const [ verifyProspect, { loading } ] = useVerifyProspectMutation({
    onCompleted: async ({ verifyProspect: prospect }) => {
      await setProspect({ variables: { prospect } })
      push('/workspaces')
    }
  })

  const handleSubmit = useSubmitHandler(verifyProspect)

  const handleFormValuesChange = (values: VerifyProspectInput) => {
    if (values.code?.length === CODE_LENGTH) {
      handleSubmit(values)
    }
  }

  if (!email) {
    push('/start')
  }

  return (
    <Flex direction="column" gap={20}>
      <ExternalTitle>Verify Your Email Address</ExternalTitle>
      <Flex direction="column" gap={48}>
        <ExternalParagraph>
          Check your inbox for a {CODE_LENGTH}-digit code.
          If you don’t receive an email within 5 minutes,
          please check your Spam folder.
        </ExternalParagraph>
        <VerifyEmailForm
          initialValues={{ email } as VerifyProspectInput}
          onSubmit={handleSubmit}
          onChange={handleFormValuesChange}
          disabled={loading}
        />
      </Flex>
    </Flex>
  )
}

export default VerifyEmailPage
