import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import Button from 'components/buttons/Button'
import ExternalFormFooter from 'components/formFooters/ExternalFormFooter'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import TextLink from 'components/links/TextLink'
import useSubmitHandler from 'hooks/useSubmitHandler'
import VerifyMfaForm from 'components/forms/VerifyMfaForm'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { SET_SESSION_MUTATION } from 'client/state/session'
import { VerifySessionInput, useVerifySessionMutation } from 'generated/schema'

export default function VerifyMfaPage() {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const [ setSession ] = useMutation(SET_SESSION_MUTATION)

  const [ verifySession ] = useVerifySessionMutation({
    onCompleted: ({ verifySession: { id, mfaVerifiedAt } }) => {
      setSession({ variables: { session: { id, mfaVerifiedAt } } })
    }
  })

  const handleVerifySession = useSubmitHandler(verifySession)

  const handleSubmit = (values: VerifySessionInput & { id?: any }) => handleVerifySession(values)

  return (
    <Flex direction="column" gap={48} css={{ height: '100%' }}>
      <ExternalTitle>{currentWorkspace?.name}</ExternalTitle>
      <VerifyMfaForm onSubmit={handleSubmit} />
      <ExternalFormFooter>
        <TextLink href="/">
          Having trouble?
        </TextLink>
        <Button to="/logout" label="Logout" variant="simple" size="small" />
      </ExternalFormFooter>
    </Flex>
  )
}
