import React, { useState } from 'react'

import Grid from 'components/layout/Grid'
import { Popover, PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'

interface Props {
  onClick: (payload: any) => void
}

const POPOVER_PADDING = 15
const CELL_SIZE = [ 24 ]
const CELL_MARGIN = -1

const StyledContainer = styled(Popover, {
  padding: POPOVER_PADDING
})

const StyledCell = styled('div', {
  size: CELL_SIZE,
  border: '1px solid dark100',
  marginRight: CELL_MARGIN,
  marginBottom: CELL_MARGIN,

  '&:hover': {
    borderColor: 'primary300',
    zIndex: 'above'
  },

  variants: {
    selected: {
      true: {
        zIndex: 'above',
        borderColor: 'primary300',
        backgroundColor: 'primary100'
      }
    }
  }
})

const TableCommand: React.FC<Props> = ({ children, onClick }) => {
  const [ rowCount, setRowCount ] = useState(0)
  const [ columnCount, setColumnCount ] = useState(0)

  const Cell = ({ row, col }: {row: number, col: number}) => (
    <StyledCell
      id={`${col}-${row}`}
      selected={row <= rowCount && col <= columnCount}
      onClick={() => onClick({ rowCount: rowCount + 1, columnCount })}
      onMouseEnter={() => {
        setRowCount(row)
        setColumnCount(col)
      }}
    />
  )

  return (
    <PopoverContainer
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ -38, 8 ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <div {...otherToggleProps}>
          {children}
        </div>
      )}
      {(popoverProps) => (
        <StyledContainer withArrow {...popoverProps}>
          <Grid gap={0} columns={5}>
            {Array.from(Array(5).keys()).map((row) => (Array.from(Array(5).keys()).map((col) => (
              <Cell key={`${col}x${row}`} row={row + 1} col={col + 1} />
            ))))}
          </Grid>
        </StyledContainer>
      )}
    </PopoverContainer>
  )
}

export default TableCommand
