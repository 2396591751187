import omit from 'lodash/omit'
import pluralize from 'pluralize'
import React from 'react'

import AccountSignOutAllOtherForm from 'components/forms/AccountSignOutAllOtherForm'
import ChangePasswordForm from 'components/forms/ChangePasswordForm'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import log from 'lib/log'
import MfaSettingsView from 'components/views/MfaSettingsView'
import Text from 'components/typography/Text'
import TitleBlock from 'components/blocks/TitleBlock'
import UpdateAccountEmailForm from 'components/forms/UpdateAccountEmailForm'
import UpdateAccountTimeZone from 'components/forms/UpdateAccountTimeZoneForm'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import {
  SessionsListDocument,
  useChangePasswordMutation,
  useRevokeOtherSessionsMutation,
  useSessionsListQuery,
  useUpdateAccountTimeZoneMutation
} from 'generated/schema'
import type { ChangePasswordInput } from 'generated/schema'
import type { DrawerBlockRenderProps } from 'components/blocks/DrawerBlock'

const handleUpdateEmail = () => {
  log('Dummy update email handler. Replace me.')
}

const MfaStatus = ({ isMfaEnabled }: { isMfaEnabled?: boolean }) => (
  <Flex alignItems="center" gap={6}>
    <Icon
      name={isMfaEnabled ? 'accept' : 'error'}
      size={12}
      color={isMfaEnabled ? 'positive400' : 'dark300'}
    />
    <Text color="dark500" fontSize={12}>{isMfaEnabled ? 'Enabled' : 'Disabled'}</Text>
  </Flex>
)

export default function AccountSettingsPage() {
  const currentAccount = useCurrentAccountContext()!
  const { data: { sessionsList = [] } = {} } = useSessionsListQuery()
  const [ changePassword ] = useChangePasswordMutation()
  const [ revokeOtherSessions ] = useRevokeOtherSessionsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ { query: SessionsListDocument } ]
  })
  const [ updateTimeZone ] = useUpdateAccountTimeZoneMutation()

  const handleChangePassword = useSubmitHandler(changePassword, {
    successAlert: {
      message: 'You have updated your password.'
    }
  })

  const handleRevokeOtherSessions = useSubmitHandler(revokeOtherSessions, {
    successAlert: {
      message: 'You have signed out of other sessions.'
    }
  })

  const handleUpdateTimeZone = useSubmitHandler(updateTimeZone, {
    successAlert: {
      message: 'You have updated your time zone'
    }
  })

  const onChangePasswordSubmit = (values: ChangePasswordInput & { passwordStrength?: number }) => {
    const formValues = omit(values, [ 'passwordStrength' ])
    return handleChangePassword(formValues)
  }

  const sessionsSummary = (otherSessionsCount: number) => {
    if (otherSessionsCount > 0) {
      return `Currently signed into ${pluralize('other device', otherSessionsCount, true)}`
    }

    return 'No other active sessions'
  }

  return (
    <>
      <TitleBlock heading="Settings" />

      <DrawerBlock icon="mail" title="Change Email Address" summary={currentAccount.email}>
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <UpdateAccountEmailForm
            onSubmit={useSubmitHandler.withCallback(handleUpdateEmail as any, closeDrawer)}
          />
        )}
      </DrawerBlock>

      <DrawerBlock icon="change-password" title="Change Password" summary="Last changed never">
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <ChangePasswordForm
            initialValues={{ id: currentAccount.id } as (ChangePasswordInput & { id: any })}
            onSubmit={useSubmitHandler.withCallback(onChangePasswordSubmit, closeDrawer)}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="pad-lock"
        title="Two-Factor Authentication"
        summary={<MfaStatus isMfaEnabled={currentAccount.isMfaEnabled} />}
      >
        {() => (
          <MfaSettingsView isMfaEnabled={currentAccount.isMfaEnabled} />
        )}
      </DrawerBlock>

      <DrawerBlock icon="clock" title="Time Zone" summary={currentAccount.timeZone || 'No time zone selected'}>
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <UpdateAccountTimeZone
            initialValues={
              {
                id: currentAccount.id,
                timeZone: currentAccount.timeZone
              }
            }
            onSubmit={useSubmitHandler.withCallback(handleUpdateTimeZone, closeDrawer)}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="sign-out-all"
        title="Sign Out of Other Sessions"
        summary={sessionsSummary(sessionsList.length - 1)}
      >
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <AccountSignOutAllOtherForm
            onSubmit={useSubmitHandler.withCallback(handleRevokeOtherSessions, closeDrawer)}
            sessions={sessionsList}
          />
        )}
      </DrawerBlock>
    </>
  )
}
