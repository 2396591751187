import * as Sentry from '@sentry/browser'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect } from 'react-router-dom'

import Button from 'components/buttons/Button'
import ExternalParagraph from 'components/typography/ExternalParagraph'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import List from 'components/list/List'
import TextLink from 'components/links/TextLink'
import useClientQuery from 'hooks/useClientQuery'
import WorkspaceListItem from 'components/list/WorkspaceListItem'
import { ProspectQuery, PROSPECT_QUERY } from 'client/state/prospect'
import { styled } from 'styles/stitches'
import { useProspectWorkspacesListQuery } from 'generated/schema'

const Footer = styled(Flex, {
  width: '100%',
  paddingX: 80,
  paddingY: 24,
  borderTop: '1px solid dark100',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'space-between',
  bottom: 0,
  left: 0
})

const StyledList = styled(List, {
  '&&&': {
    marginBottom: 80
  }
})

function WorkspacesPage() {
  const { data: { prospect } } = useClientQuery<ProspectQuery>(PROSPECT_QUERY)
  const { data, loading, error } = useProspectWorkspacesListQuery({
    skip: !prospect.token
  })

  useEffect(() => {
    if (prospect?.email && prospect?.id) {
      Sentry.setUser({
        email: prospect.email,
        id: prospect.id
      })
    }
  }, [ prospect ])

  if (error || !prospect.id) {
    return <Redirect to="/start" />
  }

  const { prospectWorkspaces: workspacesList = [] } = data || {}

  if (!loading && workspacesList.length === 0) {
    return <Redirect to="/workspaces/new" />
  }

  return (
    <Flex direction="column" gap={16}>
      <ExternalTitle>Your Workspaces</ExternalTitle>
      <ExternalParagraph>
        List of workspaces you&apos;ve already joined, or allowed to join.
      </ExternalParagraph>
      <StyledList loading={loading}>
        {workspacesList.map((workspace) => (
          <ErrorBoundary
            key={workspace.id}
            fallbackRender={() => null}
            onError={(error) => {
              Sentry.setContext('workspace', workspace)
              Sentry.captureException(error)
            }}
          >
            <WorkspaceListItem
              key={workspace.id}
              workspace={workspace}
              target={workspacesList?.length === 1 ? '_self' : '_blank'}
            />
          </ErrorBoundary>
        ))}
      </StyledList>
      <Footer>
        <TextLink fontSize={14} to="/start">Not {prospect.email}?</TextLink>
        <Button label="Create Workspace" to="/workspaces/new" />
      </Footer>
    </Flex>
  )
}

export default WorkspacesPage
