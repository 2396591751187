import classNames from 'classnames'
import React, { Fragment } from 'react'
import type { ReactElement, ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import DialogBody from 'components/dialog/DialogBody'
import DialogFooter from 'components/dialog/DialogFooter'
import DialogHeader from 'components/dialog/DialogHeader'
import Divider from 'components/divider/Divider'
import { BaseModal } from 'components/modal'
import { colorVars } from 'styles/theme'
import { css } from 'styles/stitches'
import type { BaseModalProps } from 'components/modal'

type DialogRenderProps = {
  Body: typeof DialogBody,
  Divider: typeof Divider,
  Footer: typeof DialogFooter,
  Header: typeof DialogHeader,
  SearchBar: typeof Fragment,
  SubHeader: typeof Fragment
}

type DialogProps = BaseModalProps & {
  children: ((props: DialogRenderProps) => ReactNode) | ReactElement | ReactElement[]
}

const classes = {
  overlayBase: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }),
  contentBase: css({
    ...mixins.shadow('xxxLarge', colorVars.dark600rgb, 0.6),

    backgroundColor: 'light400',
    transform: 'translateY(48px)',
    overflow: 'hidden',
    width: 600,
    zIndex: 0
  }),
  contentAfterOpen: css({
    '&&': {
      transform: 'none'
    }
  }),
  contentBeforeClose: css({
    '&&&': {
      transform: 'translateY(48px)'
    }
  })
}

function Dialog({ children, ...others }: DialogProps) {
  const renderChild = () => {
    if (typeof children !== 'function') {
      return children
    }

    return children({
      Body: DialogBody,
      Divider,
      Footer: DialogFooter,
      Header: DialogHeader,
      SearchBar: Fragment,
      SubHeader: Fragment
    })
  }

  return (
    <BaseModal
      {...others}
      contentAfterOpenClassName={classNames(classes.contentAfterOpen)}
      contentBaseClassName={classNames(classes.contentBase)}
      contentBeforeCloseClassName={classNames(classes.contentBeforeClose)}
      overlayBaseClassName={classes.overlayBase}
    >
      {renderChild()}
    </BaseModal>
  )
}

export default Dialog

export type { DialogProps, DialogRenderProps }
