import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import React, { useRef } from 'react'
import { useForm, useFormState } from 'react-final-form'

import AddRecordView from 'components/views/graph/AddRecordView'
import AttributeModel from 'models/Attribute'
import DataList from 'components/dataList/DataList'
import DataType from 'models/DataType'
import Divider from 'components/divider/Divider'
import DropdownField from 'components/contentEditors/generic/fields/DropdownField'
import FieldArray from 'components/form/FieldArray'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import useSearchGenericRecords from 'components/views/graph/useSearchGenericRecords'
import { Kind } from 'models/Relationship'
import { useInternalSearchRecordsQuery, useOperationsListQuery, useRelationshipsListQuery, useResourceQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Attribute, Resource } from 'generated/schema'
import type { FieldArrayChildrenProps } from 'components/form/FieldArray'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type ReferenceAttributeProps = Omit<fieldProps<''>, 'input' | 'meta'>& {
  disabled?: boolean,
  attribute?: Attribute,
  currentLocale?: string,
  environmentId?: string
}

function ReferenceAttribute(
  { attribute, currentLocale, environmentId, label, ...inputProps }: ReferenceAttributeProps
) {
  const { resourceId, id, isArray } = attribute || {}
  const { values } = useFormState()
  const { openView } = useViewDispatch()
  const inputValue = get(values, inputProps.name)

  const { disabled } = inputProps

  const { data: { relationshipsList } = {} } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId },
        sourceAttributeId: { eq: id }
      }
    },
    skip: !resourceId || attribute?.fieldTypeSettings.references?.[0]
  })

  const relationship = relationshipsList?.[0] || undefined

  const targetId = relationship?.target.id || attribute?.fieldTypeSettings.references?.[0]

  const {
    data: { operationsList = [] } = {}
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: targetId }
      }
    },
    skip: !targetId
  })

  const createOperation = operationsList.find((op) => op.graphqlKind === 'MUTATION' && op.identifier.includes('create'))

  const sourceAttribute = id !== relationship?.sourceAttributeId
    ? relationship?.sourceAttribute
    : relationship?.targetAttribute

  const { data: { resource = relationship?.target } = {} } = useResourceQuery({
    variables: {
      id: attribute?.fieldTypeSettings.references?.[0]
    },
    skip: !attribute?.fieldTypeSettings.references?.[0]
  })

  const target = resource
  const fieldsRef = useRef<FieldArrayChildrenProps<string>>()

  const {
    attributesResult,
    searchResult
  } = useSearchGenericRecords({
    resourceId: targetId!,
    environmentId,
    ...(isArray && !!fieldsRef.current?.fields.length && {
      filters: {
        id: {
          notIn: fieldsRef.current?.fields.value
        }
      }
    }),
    skip: !targetId
  })

  const {
    data: {
      // @ts-ignore
      attributesList = (target?.attributes || []) as typeof attributesResult.data.attributesList
    } = {},
    loading: attributesLoading,
    error: attributesError
  } = attributesResult

  const [ {
    data: { internalSearchRecords: searchRecords = [] } = {},
    loading: searchLoading
  }, onSearch ] = searchResult

  const titleAttribute = attributesList.find((attr: any) => attr.id === target?.titleAttributeId)
  const subTitleAttribute = attributesList.find(
    (attr: any) => attr.id === target?.subtitleAttributeId
  )

  const labelKey = DataType.resolveTitleKey(titleAttribute || attributesList[0], currentLocale)

  const metaKey = subTitleAttribute ? `data.${camelCase(subTitleAttribute.identifier)}.${currentLocale}` : undefined
  const valueKey = `data.id.${currentLocale}`

  const onAdd = () => openView({
    title: target?.name,
    component: AddRecordView,
    params: {
      resource: target as Resource,
      switcher: {
        data: {
          environment: {
            id: environmentId
          }
        } as any
      },
      operationId: createOperation?.id
    },
    style: 'PANEL'
  })

  const { change } = useForm()
  const onDragEnd = useReorderFieldArray(fieldsRef)

  const { previousData, data = previousData, loading, error } = useInternalSearchRecordsQuery({
    variables: {
      input: {
        preview: true,
        resourceId: targetId,
        targetEnvironment: environmentId,
        filter: {
          id: {
            in: [ inputValue ].flat()
          }
        },
        limit: [ inputValue ].flat().length
      }
    },
    skip: ![ inputValue ].flat().length
  })

  return (
    <>
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          {label}{inputProps.settings?.checkRequired && <span> *</span>}
        </Text>
        <Flex gap={8} alignItems="center">
          {attribute?.isTranslatable && (
            <Flex alignItems="center" alignSelf="stretch" gap={12}>
              <Divider variant="ruler" orientation="vertical" color="light700" />
              <Icon size={12} name="translate" color="dark200" />
            </Flex>
          )}
          {!disabled && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={onAdd}
              mode="distinct"
              disabled={!target}
            >
              Add new
            </TextLink>
          )}
        </Flex>
      </Flex>
      <DropdownField
        isMulti={(relationship?.kind === Kind.HAS_MANY && sourceAttribute?.id !== id)}
        size="small"
        variant="light"
        isLoading={searchLoading}
        labelKey={labelKey}
        metaKey={metaKey}
        valueKey={valueKey}
        defaultOptions={isArray
          ? searchRecords
          : searchRecords.concat(data?.internalSearchRecords || [])}
        options={searchRecords}
        loadOptions={((inputValue: string, callback: any) => {
          onSearch(inputValue, (data) => callback(data.internalSearchRecords))
        })}
        onBlur={() => {
          if (!inputValue) onSearch('')
        }}
        isClearable
        {...inputProps}
        {...inputProps.settings}
        {...(isArray && {
          name: '',
          input: {
            value: null,
            onChange: (value: any) => {
              fieldsRef.current?.fields.push(value)
            }
          }
        })}
      />

      {isArray && (
        <FieldArray
          name={inputProps.name}
          fieldsRef={fieldsRef}
        >
          {({ fields }) => (
            <DataList
              loading={loading || attributesLoading}
              error={error || attributesError}
              actions={[
                {
                  icon: 'trash',
                  title: 'Delete',
                  onClick: (record) => {
                    if (fields.length === 1) {
                      change(inputProps.name, undefined)
                    } else {
                      fields.remove(record.itemIndex)
                    }
                  }
                }
              ]}
              contents={[
                { dataKey: 'name', slot: 'primary' },
                { dataKey: 'title', slot: 'secondary' }
              ]}
              data={
                (fields.value || [])
                  .map((id) => data?.internalSearchRecords.find((r) => r.id === id))
                  .map((d, index) => ({
                    ...d,
                    itemIndex: index,
                    name: target?.name,
                    title: AttributeModel.resolveTitle(
                      attributesList || [],
                      d?.data,
                      target as Resource,
                      { activeLocale: currentLocale }
                    )
                  }))
              }
              onRowDragEnd={onDragEnd}
              selectionMode="none"
              paginationMode="infinite"
            />
          )}
        </FieldArray>
      )}
    </>
  )
}

export default ReferenceAttribute
