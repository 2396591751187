import React, { createContext, PropsWithChildren, useContext } from 'react'

import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import type { ComputedMenuElement } from 'lib/generateDashboard'

type MenuElementPositionContext = {
  newNonStickyPosition: number,
  newStickyPosition: number
} | undefined

const menuElementPositionContext = createContext<MenuElementPositionContext>(undefined)

const useMenuElementPositionContext = () => useContext(menuElementPositionContext)

const MenuElementPositionProvider = ({
  children,
  stickyElements,
  nonStickyElements
}: PropsWithChildren<{
  nonStickyElements: ComputedMenuElement[],
  stickyElements: ComputedMenuElement[]
}>) => (
  <menuElementPositionContext.Provider value={{
    newNonStickyPosition: generatePosition(getMaxPosition(nonStickyElements)),
    newStickyPosition: generatePosition(getMaxPosition(stickyElements))
  }}
  >
    {children}
  </menuElementPositionContext.Provider>
)

export type { MenuElementPositionContext }

export { useMenuElementPositionContext }
export default MenuElementPositionProvider
