import { gql } from '@apollo/client'

import type { ClientState } from 'client/state/types'
import type { Installation } from 'generated/schema'

/* Default Data */

const DEFAULT_SWITCHER: InstallationSwitcher = {
  __typename: 'InstallationSwitcher',
  id: null,
  installation: null
}

/* Types */

export type InstallationSwitcher = {
  __typename: 'InstallationSwitcher',
  id: string | null,
  installation: Installation | null
}

export type InstallationSwitcherQuery = {
  installationSwitcher: InstallationSwitcher
}

/* Queries */

export const INSTALLATION_SWITCHER_QUERY = gql`
  query InstalationSwitcherQuery($id: String) {
    installationSwitcher(id: $id) @client {
      id
      installation {
        ...InstallationFragment
      }
    }
  }

  fragment InstallationFragment on Installation {
    id
    workspaceId
    appKind
    name
    identifier
    createdAt
    updatedAt
    archivedAt

    app {
      ...AppFragment
    }
  }

  fragment AppFragment on App {
    id
    name
    identifier
    appCategoryId
    workspaceId
    appCategory {
      ...AppCategoryFragment
    }
    kind
    summary
    fields
    createdAt
    updatedAt
  }

  fragment AppCategoryFragment on AppCategory {
    id
    name
    description
    position
    createdAt
    updatedAt
    kind
  }
`

/* Default Query */

export const writeDefaults = {
  query: INSTALLATION_SWITCHER_QUERY,
  data: { installationSwitcher: DEFAULT_SWITCHER }
}

/* Mutations */

export const SET_INSTALLATION_SWITCHER_MUTATION = gql`
  mutation SetInstallationSwitcherMutation($id: String, $installation: Installation) {
    setInstallationSwitcher(id: $id, installation: $installation) @client
  }
`

/* ClientState */

export default {
  defaults: writeDefaults,
  resolvers: {
    Query: {
      installationSwitcher: (_, { id }, { cache }) => {
        const data = cache.readQuery({
          query: INSTALLATION_SWITCHER_QUERY,
          variables: { id }
        })

        if (!data) {
          return {
            ...DEFAULT_SWITCHER,
            id
          }
        }

        return data?.installationSwitcher
      }
    },
    Mutation: {
      setInstallationSwitcher: (_, { id, installation }, { cache }) => {
        cache.writeQuery({
          query: INSTALLATION_SWITCHER_QUERY,
          data: {
            installationSwitcher: {
              __typename: 'InstallationSwitcher',
              id,
              installation
            }
          }
        })

        return {
          __typename: 'InstallationSwitcher',
          id,
          installation
        }
      }
    }
  }
} as ClientState
