import dayjs from 'dayjs'
import get from 'lodash/get'
import React from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

import { useDateTimeRenderer } from 'components/displayTypes/DateTimeView'
import type { RendererOptions } from 'components/dataTable/types'

dayjs.extend(relativeTime)
dayjs.extend(utc)

type DateRendererProps = RendererOptions & {
  format?: string,
  full?: boolean
}

const FULL_DATE_FORMAT = 'MMM D YYYY hh:mm A'

function DateRenderer({
  dataKey,
  rowData = {},
  format = '',
  full = false,
  style,
  prefix = '',
  suffix = '',
  displayTypeSettings
}: DateRendererProps) {
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const data = get(rowData, `${prefixContent}${dataKey}${suffixContent}`)

  const formattedDate = useDateTimeRenderer({ displaySettings: displayTypeSettings, data })

  if (displayTypeSettings) {
    return (
      <span
        style={style}
        title={formattedDate}
      >
        {formattedDate}
      </span>
    )
  }

  const parsedDate = data ? data.split('+')[0] : data
  if (!parsedDate || !dayjs(parsedDate, undefined, true).isValid()) {
    return <span style={style}>-</span>
  }

  let day = dayjs(parsedDate)
  if (parsedDate.length > 10 /* with time*/) {
    day = day.utc(true).local()
  }

  let date = dayjs().to(day)
  const fullFormatDate = day.format(FULL_DATE_FORMAT)

  if (full) {
    date = fullFormatDate
  }

  if (format) {
    date = day.format(format)
  }

  return (
    <span
      style={style}
      title={fullFormatDate}
    >
      {date}
    </span>
  )
}

export default DateRenderer
