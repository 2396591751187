export default {
  typePolicies: {
    CustomRecord: {
      fields: {
        data: {
          merge(existing = {}, incoming: any) {
            return { ...existing, ...incoming }
          }
        }
      }
    }
  }
}
