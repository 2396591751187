import React, { useContext, useEffect, useState } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Flex from 'components/layout/Flex'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import WorkspaceAvatar from 'components/avatars/WorkspaceAvatar'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { colorVars } from 'styles/theme'
import { getWorkspaces, isElectron, isFullScreen, showProspectFlow } from 'lib/electron'
import { SIDEBAR_PRIMARY_BACKGROUND, SIDEBAR_PRIMARY_BORDER_COLOR_RGB, WORKSPACE_SIDEBAR_WIDTH } from 'components/sidebar/constants'
import { styled } from 'styles/stitches'
import type { Workspace } from 'generated/schema'

type ElectronWorkspaceSidebarProps = {
  initialWorkspaces?: Partial<Workspace>[]
}

const StyledWorkspacesSidebar = styled('nav', {
  ...mixins.transition('fastIn'),

  background: SIDEBAR_PRIMARY_BACKGROUND,
  borderRightColor: rgba(colorVars[SIDEBAR_PRIMARY_BORDER_COLOR_RGB], 0.2),
  borderRightStyle: 'solid',
  borderRightWidth: 1,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  padding: 10,
  position: 'fixed',
  top: 0,
  transform: 'translateZ(0)', // Force hardware acceleration for fixed elements
  width: WORKSPACE_SIDEBAR_WIDTH,
  zIndex: 'workspaceSidebar',

  variants: {
    fullScreen: {
      false: {
        paddingTop: 35
      }
    }
  }
})

const StyledWorkspacesDragbar = styled(Flex, {

  // To facilitate dragging desktop window
  appRegion: 'drag',

  background: 'transparent',
  left: WORKSPACE_SIDEBAR_WIDTH,
  padding: 10,
  pointerEvents: 'none',
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 'appScreen'
})

const AddWorkSpaceButton = styled(Flex, {
  padding: '10'
})

function ElectronWorkspaceSidebar({ initialWorkspaces = [] }: ElectronWorkspaceSidebarProps) {
  const [ workSpaces, setWorkSpaces ] = useState<any>(initialWorkspaces)
  const { currentWorkspace } = useContext(WorkspaceContext) || {}

  const fullScreen = isElectron && isFullScreen()

  useEffect(() => {
    const getStoreValue = async () => {
      setWorkSpaces(await getWorkspaces())
    }

    isElectron && getStoreValue()
  }, [])

  const openProspectFlow = () => {
    showProspectFlow({ workspaceIdentifier: currentWorkspace?.identifier })
  }

  return isElectron ? (
    <>
      <StyledWorkspacesSidebar fullScreen={fullScreen}>
        <Flex direction="column" alignItems="center" gap={10}>
          {workSpaces?.map((workSpace: any) => (
            <WorkspaceAvatar key={workSpace.id} workspace={workSpace} />
          ))}
          <AddWorkSpaceButton onClick={openProspectFlow} as={BaseLink}>
            <Text fontSize={24} color="light100">
              +
            </Text>
          </AddWorkSpaceButton>
        </Flex>
      </StyledWorkspacesSidebar>
      <StyledWorkspacesDragbar />
    </>
  ) : null
}

export default ElectronWorkspaceSidebar
