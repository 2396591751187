import React from 'react'

import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { TextProps } from 'components/typography/Text'

interface Props extends TextProps, StyledProps<typeof CurrencyText> {
  currencyCode?: string,
  value?: string
}

const CurrencyText = styled(Text, {
  variants: {
    size: {
      small: {
        fontSize: 14,
        lineHeight: 0.75,
        fontWeight: 400
      },
      normal: {
        fontSize: 16,
        lineHeight: 1,
        fontWeight: 500
      },
      large: {
        fontSize: 24,
        lineHeight: 1.5,
        fontWeight: 700
      }
    }
  }
})

const Currency: React.FC<Props> = ({ currencyCode = 'USD', value = '0', size = 'small', ...props }) => (
  <CurrencyText size={size} {...props}>
    {currencyCode} {Number.parseFloat(value).toFixed(2)}
  </CurrencyText>
)

export default Currency
