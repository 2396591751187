import React from 'react'
import Highcharts, { SeriesPieOptions, TooltipFormatterContextObject } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { colors } from 'lib/chartColors'

function tooltipFormatter(
  this: TooltipFormatterContextObject
): string {
  const { point } = this
  return `<div style="min-width: 250px; border-radius: 4px; box-shadow: 0px 13px 20px #aaadbc1a">
            <div style="display: flex; align-items: center; justify-content: space-between; padding: 14px 25px;">
              <div style="display: flex; align-items: center">
                <div style="width: 12px; height: 12px; margin-right: 10px; background-color: ${point.color}; border-radius: 2px;"></div>
                <p style="font-size: 14px; color: var(--colors-dark900); font-weight: bold; margin-right: 16px;">${point.name}</p>
              </div>
              <div style="font-size: 16px; line-height: 34px; color: var(--colors-dark900); font-weight: bold;">${(point?.percentage || 0).toFixed(2)}<span style="font-size: 14px;">%</span></div>
            </div>
          </div>`
}

const options: Highcharts.Options = {
  title: {
    align: 'left',
    text: 'Solar Employment Growth by Sector, 2010-2016'
  },
  credits: {
    enabled: false
  },
  colors,
  tooltip: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 8,
    padding: 0,
    formatter: tooltipFormatter,
    shadow: false,
    useHTML: true
  },
  chart: {
    style: {
      fontFamily: 'var(--fonts-normal)'
    },
    spacing: [ 16, 16, 16, 16 ],
    type: 'pie'
  },
  plotOptions: {
    pie: {
      slicedOffset: 5
    }
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle'
  },
  series: [ {
    borderRadius: 6,
    states: {
      inactive: {
        opacity: 1,
        linkOpacity: 1
      },
      hover: {
        opacity: 1,
        shadow: true
      }
    },
    type: 'pie'
  } ],
  responsive: {
    rules: [ {
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    } ]
  }

}

type Props = {
  title?: string,
  type: 'pie' | 'donut',
  series: SeriesPieOptions[]
}

const PieChart: React.FC<Props> = ({ title, type, series }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      ...options,
      chart: { ...options.chart },
      title: { text: title },
      series: series.map((s) => ({ ...s, size: '100%', innerSize: type === 'donut' ? '60%' : '0%' }))
    }}
  />
)

export default PieChart
