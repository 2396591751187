import React from 'react'
import get from 'lodash/get'

import Account from 'models/Account'
import AccountNameRenderer from 'components/renderers/AccountNameRenderer'
import AssignGroupView from 'components/views/AssignGroupView'
import AssignRoleView from './AssignRoleView'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import IconRenderer from 'components/renderers/IconRenderer'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Toolbar from 'components/toolbar/Toolbar'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import {
  Group,
  GroupMembership,
  GroupMembershipsListDocument,
  useDestroyGroupMembershipMutation,
  useGroupQuery,
  RoleMembershipsListQuery,
  RoleMembershipsListQueryVariables,
  RoleMembershipsListDocument,
  RoleMembership,
  Environment,
  GroupMembershipsListQuery,
  GroupMembershipsListQueryVariables,
  useDestroyRoleMembershipMutation
} from 'generated/schema'
import { css } from 'styles/stitches'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { Kind, KIND_ICON_MAP } from 'models/Role'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'
import type { ViewProps } from 'components/views'

const TITLE = 'Group View'

type Params = {
  group: Group,
  accountKind: Group['accountKind'],
  environmentId: Environment['id']
}

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

const Permissions = ({ group }: { group: Group }) => {
  const { openView } = useViewDispatch()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      groupId: { eq: group.id }
    },
    order: [ { createdAt: 'desc' } ],
    limit: 100
  }

  const [ {
    data: { roleMembershipsList = [], roleMembershipsAggregate = {} } = {},
    error,
    loading
  } ] = useSearch<RoleMembershipsListQuery, RoleMembershipsListQueryVariables>({
    query: RoleMembershipsListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ ]
  })

  const openAssignRole = () => {
    openView({
      title: 'Assign Role',
      component: AssignRoleView,
      params: {
        groupId: group.id,
        group,
        roleMemberships: roleMembershipsList,
        mode: 'role',
        kind: Kind.WORKSPACE,
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const contents: Content[] = [
    { dataKey: 'role.name', slot: 'primary' },
    { dataKey: 'role.kind', slot: 'icon', renderer: (props) => <IconRenderer iconMap={KIND_ICON_MAP} {...props} /> },
    { dataKey: 'environment',
      slot: 'toggle',
      renderer: ({ rowData, dataKey }) => {
        const isWorkspaceRole = rowData.role.kind === 'WORKSPACE'
        if (isWorkspaceRole) return <></>
        const environment = get(rowData, dataKey)
        const hasEnvironment = Boolean(environment)
        return <Chip label={hasEnvironment ? environment.name : 'All Environments'} variant="accent_inverse" />
      } }
  ]

  const [ deleteRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      { query: RoleMembershipsListDocument, variables: queryVariables }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRoleMembership, {
    update: {
      strategy: 'REMOVE',
      query: RoleMembershipsListDocument,
      queryVariables,
      dataKey: 'roleMembershipsList',
      mutation: 'destroyRoleMembership'
    }
  })

  const openDeleteRoleMembershipDialog = (roleMembership: RoleMembership) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: roleMembership.id }),
      recordType: 'Role',
      recordDescription: roleMembership.role.name
    })
  }

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (roleMembership: RoleMembership) => openDeleteRoleMembershipDialog(roleMembership)
    }
  ]

  const secondaryElement = <Button icon="add-thin" size="small" onClick={openAssignRole} />

  return (
    <Flex direction="column" gap={16}>
      <Toolbar
        title="Roles"
        // primaryElements={<SearchBar placeholder="Search..." onChange={handleChange} />}
        secondaryElements={secondaryElement}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={roleMembershipsList as RoleMembership[]}
        loading={loading}
        error={error}
        selectionMode="none"
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        totalRows={roleMembershipsAggregate.count || 0}
      />
    </Flex>
  )
}

const Members = ({ group, targetEnvironment }: { group: Group, targetEnvironment: string }) => {
  const { openView } = useViewDispatch()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      groupId: { eq: group.id }
    },
    order: [ { createdAt: 'desc' } ],
    limit: 100
  }

  const [ {
    data: { groupMembershipsList = [], groupMembershipsAggregate = {} } = {},
    error,
    loading
  } ] = useSearch<GroupMembershipsListQuery, GroupMembershipsListQueryVariables>({
    query: GroupMembershipsListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ ]
  })

  const openAssignGroup = () => {
    openView({
      title: 'Assign Group',
      component: AssignGroupView,
      params: {
        groupId: group.id,
        group,
        accountKind: group.accountKind,
        environmentId: targetEnvironment,
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const contents: Content[] = [
    { dataKey: 'account', slot: 'primary', renderer: (props) => <AccountNameRenderer full {...props} /> },
    { dataKey: 'group.secondary', slot: 'primary' },

    { dataKey: 'group.accountKind', slot: 'meta', renderer: ChipRenderer }
  ]

  const [ deleteGroupMembership ] = useDestroyGroupMembershipMutation({
    refetchQueries: [
      { query: GroupMembershipsListDocument, variables: queryVariables }
    ]
  })

  const handleDeleteGroup = useSubmitHandler(deleteGroupMembership, {
    update: {
      strategy: 'REMOVE',
      query: GroupMembershipsListDocument,
      queryVariables,
      dataKey: 'groupMembershipsList',
      mutation: 'destroyGroupMembership'
    }
  })

  const openDeleteGroupMembershipDialog = (groupMembership: GroupMembership) => {
    confirm({
      action: 'remove',
      onConfirmClick: async () => handleDeleteGroup({ id: groupMembership.id }),
      recordType: 'Member',
      recordDescription: Account.getFullName(groupMembership.account)
    })
  }

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (groupMembership: GroupMembership) => (
        openDeleteGroupMembershipDialog(groupMembership)
      )
    }
  ]

  const secondaryElement = <Button icon="add-thin" size="small" onClick={openAssignGroup} />

  return (
    <Flex direction="column" gap={16}>
      <Toolbar
        // primaryElements={<SearchBar placeholder="Search..." onChange={handleChange} />}
        secondaryElements={secondaryElement}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={groupMembershipsList as GroupMembership[]}
        loading={loading}
        error={error}
        selectionMode="none"
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        dataListItemVariant="detailed"
        totalRows={groupMembershipsAggregate.count || 0}
      />
    </Flex>
  )
}

function GroupView({
  onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<Params>) {
  const { data } = useGroupQuery({
    variables: {
      id: params.group.id
    },
    skip: !params.group.id
  })

  const group = data?.group || params.group
  const title = `${group.name} Group`

  return (
    <View contentLabel={TITLE} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          >
            <Chip label={group.accountKind} variant="accent_inverse" />
          </Header>
          <Tabs
            tabListBackground="light"
            panelWrapper={Body}
            tabListClassName={classes.tabsList}
          >
            <Tab label="Members" index={0}>
              <Members group={group} targetEnvironment={params.environmentId} />
            </Tab>
            <Tab label="Permissions" index={1}>
              <Permissions group={group} />
            </Tab>
          </Tabs>
        </>
      )}
    </View>
  )
}

GroupView.defaultStyle = 'PANEL' as const

export default GroupView
