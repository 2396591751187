import React, { useContext, useMemo } from 'react'
import type { FormApi } from 'final-form'

import { useHistory } from 'react-router-dom'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import InstallAppForm from 'components/forms/InstallAppForm'
import InternalContext from 'components/contexts/InternalContext'
import Text from 'components/typography/Text'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { InstallationsListDocument, DashboardsListDocument, useCreateInstallationMutation, useUpdateInstallationMutation, InstallationQueryVariables, InstallationsListQueryVariables } from 'generated/schema'
import { getAppIcon } from 'models/App'
import type { App, CreateInstallationInput, UpdateInstallationInput } from 'generated/schema'
import type { ViewProps } from 'components/views'

type ViewParamsType = {
  selectedApp: App,
  initialValues?: UpdateInstallationInput,
  queryVariables: InstallationsListQueryVariables | InstallationQueryVariables
}

function InstallAppView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { push } = useHistory()
  const { currentDashboard } = useContext(InternalContext)!

  const { selectedApp, initialValues, queryVariables } = params

  const [ installApp ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      onRequestClose()
      closeView()
      if (data.createInstallation.appKind === 'EXTENSION') {
        push({
          pathname: 'apps',
          search: `id=${data.createInstallation.appId}`
        })
      }
    },
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: InstallationsListDocument, variables: queryVariables }
    ]
  })

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'App was installed successfully.' }
  })

  const [ updateInstallation ] = useUpdateInstallationMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: InstallationsListDocument, variables: queryVariables }
    ]
  })
  const handleUpdateInstallation = useSubmitHandler(updateInstallation, {
    successAlert: { message: 'Installation updated successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput | UpdateInstallationInput,
    form: FormApi<CreateInstallationInput | UpdateInstallationInput>
  ) => {
    if ('id' in values && values.id) {
      return handleUpdateInstallation(values, form as FormApi<UpdateInstallationInput>)
    }

    return handleInstallApp(values as CreateInstallationInput)
  }

  const title = `${selectedApp?.name}`

  const formInitialValues = useMemo(() => initialValues || {
    appId: selectedApp.id,
    name: selectedApp.name
  } as CreateInstallationInput | UpdateInstallationInput,
  [
    selectedApp,
    initialValues
  ])

  const renderHeader = () => (
    <Flex alignItems="stretch" direction="row" gap={18} grow={1} justifyContent="space-between">
      <Flex alignItems="center" gap={20}>
        {selectedApp?.identifier && (
          <>
            {selectedApp.kind === 'EXTENSION'
              ? (
                <AppIcon
                  icon={selectedApp.icon}
                  identifier={selectedApp?.identifier as AppIconIdentifier}
                  size={48}
                />
              )
              : <img style={{ width: '32px' }} src={getAppIcon(selectedApp.identifier)} alt={selectedApp.identifier} />}
            <Divider orientation="vertical" />
          </>
        )}
        <Flex direction="column" gap={8}>
          <Text
            color="dark900"
            fontSize={24}
            fontWeight="bold"
            letterSpacing="compact"
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {(viewComponents) => {
        const { Header } = viewComponents
        return (
          <>
            <Header title="" onCloseClick={onRequestClose}>
              {renderHeader()}
            </Header>
            <InstallAppForm
              currentDashboard={currentDashboard}
              viewComponents={viewComponents}
              initialValues={formInitialValues}
              onSubmit={handleSubmit}
              selectedApp={selectedApp}
            />
          </>
        )
      }}
    </View>
  )
}

export default InstallAppView
