import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import useHotKey from 'hooks/useHotKey'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { DraggableResourceCard } from '../menu/AddMenuResourceView'
import { useResourcesAggregateQuery, useResourcesListQuery, Resource, Workspace } from 'generated/schema'
import { ViewParams, Views } from 'components/dashboardEditor/constants'

const INITIAL_PAGE_LIMIT = 3

type Params = ViewParams[Views.RESOURCE_DETAILS]

const ResourcesView = ({ workspaceId = '' }: { workspaceId?: string }) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { id: appId } = app! || {}

  const [ viewAll, setViewAll ] = useState(false)

  const {
    data: { resourcesList = [] } = {},
    loading,
    error
  } = useResourcesListQuery({
    variables: {
      filter: {
        ...(appId ? { appId: { eq: appId } } : { appId: 'null' }),
        ...(workspaceId ? { workspaceId: { eq: workspaceId } } : {}),
        isReadOnly: { eq: false }
      },
      order: [ {
        position: 'asc'
      } ],
      ...(viewAll ? {} : { limit: INITIAL_PAGE_LIMIT })
    }
  })

  const {
    data: { resourcesAggregate = {} } = {}
  } = useResourcesAggregateQuery({
    variables: {
      filter: {
        ...(appId ? { appId: { eq: appId } } : { appId: 'null' }),
        ...(workspaceId ? { workspaceId: { eq: workspaceId } } : {}),
        isReadOnly: { eq: false }
      }
    }
  })

  const handleAddNewResource = () => openDashboardEditorView({
    target: Views.CREATE_RESOURCE,
    params: { app, ...(workspaceId ? { workspace: currentWorkspace as Workspace } : {}) }
  })

  const { draggedResourceState, draggingOverMenuIdState } = useDashboard()

  const [ draggedResource, setDraggedResource ] = useRecoilState(draggedResourceState)
  const setDraggingOverMenuId = useSetRecoilState(draggingOverMenuIdState)

  useEffect(() => () => {
    setDraggedResource(null)
  }, [ setDraggedResource ])

  useHotKey({
    Escape: () => {
      setDraggedResource(null)
      setDraggingOverMenuId(null)
    }
  })

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Resources
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={handleAddNewResource}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={resourcesList}
          loading={loading}
          error={error}
        >
          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={2}>
              {resourcesList?.map((resource) => {
                const isActive = draggedResource?.id === resource.id

                return (
                  <DraggableResourceCard
                    key={resource.id}
                    resource={resource}
                    onClick={() => openDashboardEditorView({
                      target: Views.RESOURCE_DETAILS,
                      params: {
                        app,
                        resource: resource as Resource,
                        ...(workspaceId ? { workspace: currentWorkspace as Workspace } : {})
                      }
                    })}
                    isActive={isActive}
                  />
                )
              })}
            </Flex>
            {(resourcesAggregate.count && resourcesAggregate.count > INITIAL_PAGE_LIMIT) && (
              <TextLink
                as="button"
                type="button"
                mode="distinct"
                alignSelf="flex-start"
                onClick={() => {
                  if (viewAll) setViewAll(false)
                  else setViewAll(true)
                }}
              >
                {viewAll ? 'View less' : `View all (${resourcesAggregate.count})`}
              </TextLink>
            )}
          </Flex>
        </DashboardEditorLoader>
        {createPortal(
          <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
            {draggedResource?.id ? (
              <DraggableResourceCard
                resource={resourcesList.find((r) => r.id === draggedResource.id)}
                isActive
                isOverlay
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </Flex>
    </Flex>
  )
}

export default ResourcesView
