/* eslint-disable camelcase */
import isEqual from 'lodash/isEqual'
import React from 'react'
import uuid from 'uuid-random'

import convertToArray from 'lib/convertToArray'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import useDeepMemo from 'hooks/useDeepMemo'
import { Content, StyledEmpty, renderFieldContent } from 'components/contentVersion/VersionDiff'
import { Attribute, useDataTypeQuery } from 'generated/schema'
import type { ComputeDiffWithOptionsProps } from 'components/contentVersion/VersionDiff'

type Options = {
  field: Attribute,
  activeLocale: string,
  defaultLocale: string
}

type EmbeddedOld = {
  contentTypeId: string,
  data: Record<string, any>
}

type EmbeddedNew = {
  data_type_id: string,
  data: Record<string, any>
}

type EmbeddedFieldProps<T> = {
  previous: T[],
  current: T[],
  isCurrent?: Boolean,
  options: Options
}

type EmbeddedFieldBlockProps<T> = {
  previous?: T,
  current?: T,
  isCurrent?: Boolean,
  options: Options
}

function EmbeddedFieldBlockNew({
  previous, current, isCurrent, options
}: EmbeddedFieldBlockProps<EmbeddedNew>) {
  const { data_type_id: currentDataTypeId, data: currentData } = current || {}
  const { data_type_id: previousDataTypeId, data: previousData } = previous || {}

  // Only decides the title of the DrawerBlock
  const dataTypeId = isCurrent ? currentDataTypeId : previousDataTypeId
  // Only decides the subtitle of the DrawerBlock
  const data = isCurrent ? currentData : previousData

  const {
    data: { dataType } = {}
  } = useDataTypeQuery({
    variables: {
      id: dataTypeId
    },
    skip: !dataTypeId
  })

  const fields = dataType?.settings?.fields
  if (!dataType || fields?.length === 0) {
    return <StyledEmpty />
  }

  const { title_field_id } = dataType.settings
  const titleField = fields.find((f: any) => f.id === title_field_id)

  return (
    <DrawerBlock
      as={Flex}
      contentPadding="small"
      headerHeight="small"
      headerPadding="small"
      subtitle={titleField?.isTranslatable
        ? data?.[titleField?.identifier]?.[options.activeLocale]
        : data?.[titleField?.identifier]?.[options.defaultLocale]}
      title={dataType.name}
    >
      {() => (
        <Flex direction="column" gap={20}>
          {(fields as Attribute[]).map((field) => {
            const {
              currentNode,
              previousNode,
              isDiff
            } = renderFieldContent(
              currentData,
              previousData,
              field,
              {
                ...options
              }
            )

            const node = isCurrent ? currentNode : previousNode
            const variant = (() => {
              if (!isDiff) return 'neutral'

              return isCurrent ? 'positive' : 'negative'
            })()

            return (
              <Content
                key={field.id}
                field={field}
                variant={variant}
                isNested
              >
                {node || <StyledEmpty />}
              </Content>
            )
          })}
        </Flex>
      )}
    </DrawerBlock>
  )
}

function EmbeddedField({ previous, current, isCurrent = false, ...rest }: EmbeddedFieldProps<any>) {
  /**
   * - Reason for toggling between current and previous as the primary list to render.
   *
   * If the previous version as more data items than the current version AND if we always
   * choose to loop through the current version data items, then we would lose the extra
   * previous selected version items that has to be shown as deleted/removed.
   */
  const primary = isCurrent ? current : previous
  const currentAndPrevious = [ ...current, ...previous ]

  const memoizedBlocks = useDeepMemo(() => (
    primary.map((_, index) => {
      const currentValue = current[index]
      const previousValue = previous[index]

      return (
        <EmbeddedFieldBlockNew
          key={uuid()}
          current={currentValue}
          previous={previousValue}
          isCurrent={isCurrent}
          {...rest}
        />
      )
    })
  // We need to pass both current and previous to make sure that when either side
  // version changes we update the whole block else there could be a possibility
  // of stale data.
  ), currentAndPrevious)

  return (
    <>{memoizedBlocks}</>
  )
}

EmbeddedField.computeDiff = <T extends any>({
  previousValue, currentValue, options
}: ComputeDiffWithOptionsProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  const props = { options, previous, current }

  return {
    previousNode: (<EmbeddedField {...props} />),
    currentNode: <EmbeddedField {...props} isCurrent />,
    isDiff: !isEqual(previousValue, currentValue)
  }
}

export default EmbeddedField
