import React from 'react'
import get from 'lodash/get'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { css, styled } from 'styles/stitches'
import { colorVars } from 'styles/theme'
import type { RendererOptions } from 'components/dataTable/types'

const MEDIA_SIZE = 32

const Media = styled(Flex, {
  alignItems: 'center',
  borderRadius: '50%',
  fontSize: '10px',
  fontWeight: 500,
  justifyContent: 'center',
  padding: '2px',
  width: MEDIA_SIZE
})

const style = (url?: string, margin?: number, zIndex?: number) => css({
  ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),

  marginLeft: margin,
  zIndex,

  background: `url("${url}")`,
  backgroundColor: 'dark100',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  border: '2px solid light200'
})

const getMediaURL = (data: any) => data.url

const inlineStyle = {
  // doesn't work with stiches styled function
  aspectRatio: '1'
} as const

function MediaRenderer<T extends object>({
  dataKey,
  rowData,
  isArray,
  prefix,
  suffix
}: RendererOptions<T>) {
  const key = [ prefix, dataKey, suffix ].filter(Boolean).join('.')
  const mediaData = get(rowData, key)

  if (isArray) {
    let mediaUrl = [ '' ]

    if (mediaData?.[0].id) {
      mediaUrl = mediaData?.map((data: any) => getMediaURL(data))
    } else {
      mediaUrl = mediaData || mediaUrl
    }
    const extraMedias = mediaUrl.length - 4

    return (
      <Flex>
        {mediaUrl.slice(0, 4).map((media: string, index: number) => (
          <Media className={style(media, -8, mediaUrl.length - index)} style={inlineStyle} />
        ))}
        {extraMedias > 0 && (
          <Media className={style('', -8, mediaUrl.length - 5)} style={inlineStyle}>
            +{extraMedias}
          </Media>
        )}
      </Flex>
    )
  }

  let mediaUrl = ''

  if (mediaData?.id) {
    mediaUrl = getMediaURL(mediaData)
  } else {
    mediaUrl = mediaData
  }

  if (!mediaUrl) {
    return <Text />
  }

  return (
    <Media className={style(mediaUrl)} style={inlineStyle} />
  )
}

export default MediaRenderer
