import React from 'react'
import type { PropsWithChildren } from 'react'

import Flex from 'components/layout/Flex'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'

type DialogFooterProps = PropsWithChildren<{}>

const DIALOG_FOOTER_GAP = 10

const StyledFooter = styled(Flex, {
  paddingX: DIALOG_PADDING,
  paddingBottom: DIALOG_PADDING
})

function DialogFooter({ children, ...others }: DialogFooterProps) {
  return (
    <StyledFooter alignItems="center" gap={DIALOG_FOOTER_GAP} justifyContent="flex-end" {...others}>
      {children}
    </StyledFooter>
  )
}

DialogFooter.Left = (props: FlexProps) => (
  <Flex gap={DIALOG_FOOTER_GAP} grow={1} {...props} />
)

DialogFooter.Right = (props: FlexProps) => (
  <Flex gap={DIALOG_FOOTER_GAP} grow={0} {...props} />
)

export default DialogFooter
