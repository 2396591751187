import React, { useContext } from 'react'
import { Field, Form, FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import IconTextButton from 'components/buttons/IconTextButton'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import TextInput from 'components/inputs/TextInput'
import updateMenuElements from 'lib/updateMenuElements'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import {
  useCreateMenuElementMutation,
  useDestroyMenuElementMutation,
  useUpdateMenuElementMutation
} from 'generated/schema'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { CreateMenuElementInput, UpdateMenuElementInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { MenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import type { ViewProps } from 'components/views'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type Params = Partial<ComputedMenuElement & MenuElementPositionContext>

function AddMenuHeaderItemView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { parentIdToMenuElementsMap } = useContext(InternalContext)!
  const {
    dashboardId,
    id,
    name,
    newNonStickyPosition,
    parentId,
    placement,
    position,
    kind = 'GROUP'
  } = params

  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted: onRequestClose
  })
  const [ updateMenuElement ] = useUpdateMenuElementMutation({ onCompleted: onRequestClose })
  const [ destroyMenuElement ] = useDestroyMenuElementMutation({ onCompleted: onRequestClose })

  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleDestroyMenuElementSubmit = useSubmitHandler(destroyMenuElement, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('DESTROY', parentIdToMenuElementsMap, dashboardId)
  })

  const confirm = useConfirmation()

  const onConfirmDestroy = () => handleDestroyMenuElementSubmit({ id })

  const handleMenuSeparatorFormSubmit = (values: FormValues, form: FormPropsWithId<FormValues>['form']) => {
    if (id) {
      return handleUpdateMenuElementSubmit({
        ...values,
        id
      }, form as FormProps<UpdateMenuElementInput>['form'])
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId
    })
  }

  const title = id ? 'Update Header' : 'Add Header'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            onSubmit={handleMenuSeparatorFormSubmit}
            initialValues={{
              id,
              name,
              parentId,
              placement: placement!,
              position: (id ? position : newNonStickyPosition)!,
              kind
            }}
            validate={(values) => MenuElement.validate(values, [ 'separatorStyle' ])}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex alignItems="flex-start" as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                    <Field
                      autoFocus
                      component={TextInput}
                      name="name"
                      label="Label"
                      size="small"
                      type="text"
                    />
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Footer.Left>
                    {id && (
                      <IconTextButton
                        label="Delete"
                        name="trash"
                        onClick={() => confirm(
                          { action: 'delete', onConfirmClick: onConfirmDestroy, recordType: 'Menu Separator' }
                        )}
                      />
                    )}
                  </Footer.Left>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

AddMenuHeaderItemView.defaultStyle = 'DIALOG' as const

export default AddMenuHeaderItemView
