import React from 'react'
import classNames from 'classnames'

import Account from 'models/Account'
import Flex from 'components/layout/Flex'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { styled, css } from 'styles/stitches'
import type { Account as AccountType } from 'generated/schema'

import defaultAccountAvatarImage from 'assets/images/default-account-avatar.svg'

const AVATAR_SIZES = {
  small: 40,
  full: '100%'
}

type AccountAvatarProps = {
  account: Pick<AccountType, 'firstName' | 'lastName' | 'email' | 'avatar' | 'name' | 'fullName'>,
  className?: string,
  variant?: 'small' | 'full'
}

const Avatar = styled(Flex, {
  variants: {
    variant: {
      small: {
        size: [ AVATAR_SIZES.small ],
        backgroundColor: rgba(colorVars.secondary400rgb, 0.1),
        borderRadius: '$16',
        userSelect: 'none'
      },
      full: {
        size: [ AVATAR_SIZES.full ],
        backgroundImage: `url(${defaultAccountAvatarImage})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat'
      }
    }
  }
})

const AvatarDetails = styled(Flex, {
  backdropFilter: 'blur(4px)',
  padding: 14,
  background: 'linear-gradient(to top, #00000038, #ffffff00)'
})

const AccountEmail = styled(Text, {
  opacity: 0.5,
  textAlign: 'center',
  truncate: true,
  width: '100%'
})

const avatarStyle = (avatar: string) => css({
  backgroundImage: `url(${avatar})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
})

function AccountAvatar({ account, className, variant = 'small' }: AccountAvatarProps) {
  const { firstName, lastName, email, avatar } = account
  if (variant === 'small') {
    return (
      <Avatar
        variant={variant}
        className={classNames(avatar ? avatarStyle(avatar) : undefined, className)}
        alignItems="center"
        justifyContent="center"
        shrink={0}
      >
        {!avatar && (
        <Text color="primary300" fontSize={13} fontWeight="bold">
          {Account.getInitials(account) || '-'}
        </Text>
        )}
      </Avatar>
    )
  }

  return (
    <Avatar
      variant="full"
      className={classNames(avatar ? avatarStyle(avatar) : undefined, className)}
    >
      <AvatarDetails
        className={className}
        alignItems="center"
        direction="column"
        justifyContent="flex-end"
      >
        <Text
          color="light400"
          fontSize={18}
          fontWeight="bold"
          lineHeight="cozy"
          letterSpacing="condensed"
        >
          {Account.getFullName({ firstName, lastName })}
        </Text>
        <AccountEmail
          color="light100"
          fontSize={12}
          letterSpacing="condensed"
        >
          {email}
        </AccountEmail>
      </AvatarDetails>
    </Avatar>
  )
}

export default AccountAvatar
