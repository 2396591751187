/**
   * Parses JSON string and returns parsed Object or the supplied fallback
   *
   * Usage:
   *
   * `JSONParseOr('{}', null): Record<any, any> | null`
   *
   * `JSONParseOr('{}', {}): Record<any, any> | {}`
   *
   * `JSONParseOr('{}', '{}'): string | Record<any, any>`
   *
   * @param value
   * @param fallback
   * @returns Parsed Object or the supplied fallback
   */
const JSONParseOr = <T extends unknown = string>(
  value: string,
  // @ts-ignore
  fallback: T = value
) => {
  try {
    return JSON.parse(value) as Record<any, any>
  } catch {
    return fallback
  }
}

export default JSONParseOr
