import { useContext, useEffect } from 'react'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'
import uniq from 'lodash/uniq'

import InternalContext from 'components/contexts/InternalContext'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import type { DashboardFragmentFragment as DashboardFragment, WorkspaceContextQuery } from 'generated/schema'

const useDocumentTitle = (
  ...parts: string[]
) => {
  const { currentWorkspace = {} as WorkspaceContextQuery['workspace'] } = useContext(WorkspaceContext) || {}
  const { currentDashboard = {} as DashboardFragment } = useContext(InternalContext) || {}

  useEffect(() => {
    const oldTitle = document.title

    const envName = process.env.REACT_APP_ENV?.replace('production', '')
    const prefix = uniq(compact([ currentWorkspace.name, currentDashboard.name, ...parts ])).join(' - ')

    const suffix = [ 'DashX', startCase(envName) ].join(' ').trim()

    document.title = compact([ prefix, suffix ]).join(' | ')

    return () => { document.title = oldTitle }
  }, [
    currentDashboard.name,
    currentWorkspace.name,
    parts
  ])
}

export default useDocumentTitle
