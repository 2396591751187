import arrayMutators from 'final-form-arrays'
import React, { useContext, useEffect, useState } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import { string } from 'yup'
import { useRecoilValue } from 'recoil'

import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataList from 'components/dataList/DataList'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import HintBox from 'components/hints/HintBox'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import SearchBar from 'components/searchbar/SearchBar'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { APP_CATEGORIES_ID } from 'models/App'
import { GenerateResourceType } from 'models/Resource'
import { ResourcesListDocument, GenerateGraphInput, Installation, useGenerateGraphMutation, Environment, useDatabaseTablesListQuery, useUpdateConfigurationMutation, useConfigurationsListQuery, UpdateConfigurationInput, DatabaseTable, ConfigurationStatus, ConfigurationsListDocument, useDatabaseTablesAggregateQuery } from 'generated/schema'
// import { SET_SWITCHER_MUTATION } from 'client/state/environmentSwitcher'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'
import type { Content } from 'components/dataList/DataList'
import { useSearchText } from 'hooks/useSearch'

type FormValues = GenerateGraphInput

type Params = ViewParams[Views.IMPORT_FROM_DATABASE]

const order = [ {
  name: 'asc'
} ]

const contents: Content[] = [
  { dataKey: 'name', slot: 'primary' }
]

const TablesList = ({
  installationId, targetEnvironment
}: {
  installationId: string,
  targetEnvironment: string,
  setOriginIds: React.Dispatch<React.SetStateAction<string[]>>,
  originIds: string[]
}) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: 10
  })

  const { searchText, handleSearch, fetchOptions } = useSearchText('')

  const queryVariables = {
    filter: {
      integrationId: { eq: installationId },
      environmentId: { eq: targetEnvironment },
      name: {
        contains: searchText.trim()
      }
    },
    limit: pageSize,
    page,
    order
  }

  const {
    data,
    loading,
    error,
    refetch
  } = useDatabaseTablesListQuery({
    variables: queryVariables,
    context: {
      fetchOptions
    }
  })

  const { data: aggregateData } = useDatabaseTablesAggregateQuery({
    variables: { filter: queryVariables.filter }
  })

  const {
    data: configurationData,
    loading: configurationLoading,
    error: configurationError,
    startPolling,
    stopPolling
  } = useConfigurationsListQuery({
    variables: {
      filter: {
        installationId: { eq: installationId },
        environmentId: { eq: targetEnvironment }
      },
      limit: 1
    }
  })

  const [ updateConfiguration, {
    loading: updateConfigurationLoading
  } ] = useUpdateConfigurationMutation({
    update: (cache, { data, errors }) => {
      if (!data || errors) return

      cache.writeQuery({
        query: ConfigurationsListDocument,
        data: {
          configurationsList: [ data.updateConfiguration ]
        },
        variables: {
          filter: {
            installationId: { eq: installationId },
            environmentId: { eq: targetEnvironment }
          },
          limit: 1
        }
      })
    }
  })

  const configuration = configurationData?.configurationsList[0]

  const handleReSync = useSubmitHandler(updateConfiguration, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateConfiguration',
      typename: 'Configuration'
    },
    successAlert: { message: 'Sync initiated.' }
  })

  let syncStatus: ConfigurationStatus | 'IN_PROGRESS' | 'NOT_STARTED' = 'NOT_STARTED'

  if (updateConfigurationLoading) {
    syncStatus = 'IN_PROGRESS'
  } else if (configuration) {
    syncStatus = configuration.status
  }

  useEffect(() => {
    if (syncStatus === 'PENDING') startPolling(1000)
  }, [ syncStatus, startPolling, targetEnvironment, installationId ])

  useEffect(() => {
    if (syncStatus === 'COMPLETED') {
      stopPolling()
      refetch()
    }
  }, [ refetch, stopPolling, syncStatus ])

  const { change, getState } = useForm()

  return (
    <Flex direction="column" gap={10}>
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Tables
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          disabled={![ 'NOT_STARTED', 'FAILED', 'COMPLETED' ].includes(syncStatus)}
          mode="distinct"
          onClick={() => handleReSync({
            id: configuration?.id, settings: configuration?.settings
          } as UpdateConfigurationInput)}
        >
          <Flex gap={4} alignItems="center">
            <Icon name="refresh" size={10} />
            <span>Refresh</span>
          </Flex>
        </TextLink>
      </Flex>
      <SearchBar placeholder="Quick Search..." onChange={handleSearch} />
      <DataList
        actions={[]}
        contents={contents}
        data={data?.databaseTablesList || [] as DatabaseTable[]}
        loading={loading || configurationLoading || ![ 'NOT_STARTED', 'FAILED', 'COMPLETED', 'FINISHED' ].includes(syncStatus)}
        error={error || configurationError}
        onRowSelect={(databaseTable, isSelected) => {
          if (isSelected) {
            change('originIds', (getState().values?.originIds || []).concat(databaseTable.id))
          } else {
            change('originIds', (getState().values?.originIds || []).filter((id: string) => id !== databaseTable.id))
          }
        }}
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        page={page}
        pageSize={pageSize}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        pageSizeOptions={[ 5, 10, 25, 50 ]}
        paginationMode="finite"
        totalRows={aggregateData?.databaseTablesAggregate.count || 0}
        selectionMode="multiple"
        withPageControls={false}
      />
    </Flex>
  )
}

const EnvironmentPicker = ({ installationId }: { installationId: string }) => {
  const { environmentsList = [] } = useContext(InternalContext)!
  const { data: { configurationsList = [] } = {}, loading } = useConfigurationsListQuery({
    variables: {
      filter: {
        installationId: {
          eq: installationId
        }
      }
    }
  })
  const form = useForm()
  const isDisabled = !configurationsList.find(
    (config) => config.installationId === installationId
  )

  useEffect(() => {
    form.change('targetEnvironment', '')
  }, [ installationId, form ])

  return (
    <Field
      loading={loading}
      isDisabled={isDisabled}
      component={SelectInput}
      name="targetEnvironment"
      label="Environment"
      size="small"
      variant="light"
      labelKey="name"
      valueKey="id"
      options={environmentsList}
      isOptionDisabled={(option: Environment) => {
        const installationConfigurations = configurationsList.filter(
          (config) => config.installationId === installationId
        )

        const isConfigured = installationConfigurations.find(
          (config) => config.environmentId === option.id
        )

        return !isConfigured
      }}
      helpText={isDisabled && 'No configurations found for installation'}
    />
  )
}

const ImportFromDatabaseView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, initialValues = {} } = params! as Params
  const { id: appId, kind, name: appName } = app || {}

  const { installationsList = [] } = useContext(InternalContext)!

  const isProject = kind === 'PROJECT'

  const [ originIds, setOriginIds ] = useState<string[]>([])

  const queryVariables = {
    filter: {
      ...(appId ? { appId: { eq: appId } } : { appId: 'null' }),
      isReadOnly: { eq: false }
    },
    order: [ {
      position: 'asc'
    } ]
  }

  // const [ createSwitcherState ] = useMutation(SET_SWITCHER_MUTATION)

  /* const createResourceEnvironment = useCallback(
    async (resourceId: string, environment: Environment | null) => {
      try {
        await createSwitcherState({
          variables: { id: resourceId, environment }
        })
      } catch (err) {
        // Handle any errors
        console.error('Error occurred during createResourceEnvironment mutation:', err)
        throw err
      }
    }, [ createSwitcherState ]
  ) */

  const [ generateResource ] = useGenerateGraphMutation({
    onCompleted: () => openDashboardEditorView({
      target: isProject ? Views.PROJECT_DETAILS : Views.APP_DETAILS,
      params: { app: app! }
    }),
    refetchQueries: [
      { query: ResourcesListDocument, variables: queryVariables }
    ]
  })

  const handleGenerateResource = useSubmitHandler(generateResource, {
    successAlert: { message: 'Resource Generated.' }
  })

  const handleSubmit = (values: FormValues) => handleGenerateResource(values)

  const handleAddNewDatabase = () => openDashboardEditorView({
    target: Views.ADD_INTEGRATION,
    params: {
      ...params,
      appCategoryIds: [ APP_CATEGORIES_ID.Databases ]
    }
  })

  const installationOptions = installationsList.filter(
    (installation) => installation.app.appCategoryId === APP_CATEGORIES_ID.Databases
  )
  return (
    <>
      <DashboardEditorHeader
        subtitle="Import from Database"
        heading={appId ? `${isProject ? 'Project' : 'App'}: ${appName}` : 'Schema'}
        onClose={onClose}
      />
      <Form
        initialValues={{
          ...initialValues as FormValues,
          ...(appId ? { appId } : {}),
          installationId: installationOptions[0]?.id,
          originKind: GenerateResourceType.DATABASE
        }}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators
        }}
        subscription={{
          submitting: true,
          pristine: true,
          values: true
        }}
        validate={(values) => BaseModel.validateSchema(values, {
          installationId: string().required(),
          targetEnvironment: string().required()
        })}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <HintBox size="compact">
                <Text fontSize={12}>You can use the{' '}
                  <TextLink href="">
                    Database Hub
                  </TextLink>
                  {' '}app to explore your databases.
                </Text>
              </HintBox>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Flex direction="column" gap={10}>
                  <Flex justifyContent="space-between" gap={16}>
                    <Text
                      color="dark500"
                      fontSize={10}
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Select Database
                    </Text>
                    <TextLink
                      as="button"
                      type="button"
                      fontSize={10}
                      onClick={handleAddNewDatabase}
                      mode="distinct"
                    >
                      Add new
                    </TextLink>
                  </Flex>
                  <Field
                    isSearchable
                    autoFocus
                    component={SelectInput}
                    name="installationId"
                    prependIcon="search"
                    placeholder="Choose a database"
                    size="small"
                    variant="light"
                    labelKey="name"
                    metaKey="kind"
                    valueKey="id"
                    options={installationOptions}
                    getOptionLabel={(option: Installation) => {
                      const { app } = option
                      const { name } = option
                      const hasSameAppName = app.name === name
                      return `${name} ${!hasSameAppName ? `(${app.name})` : ''}`
                    }}
                  />
                </Flex>
                <FormValuesField fieldNames={[ 'installationId' ]}>
                  {({ installationId }) => installationId && (
                    <EnvironmentPicker
                      installationId={installationId}
                    />
                  )}
                </FormValuesField>
                <FormValuesField fieldNames={[ 'targetEnvironment', 'installationId' ]}>
                  {({ targetEnvironment, installationId }) => targetEnvironment
                    && installationId && (
                      <>
                        <TablesList
                          installationId={installationId}
                          targetEnvironment={targetEnvironment}
                          setOriginIds={setOriginIds}
                          originIds={originIds}
                        />
                        <Flex direction="column" gap={10}>
                          <Text
                            color="dark500"
                            fontSize={10}
                            fontWeight="bold"
                            textTransform="uppercase"
                          >
                            Views
                          </Text>
                          <Text color="dark700">
                            There are no views to be listed.
                          </Text>
                        </Flex>
                        <DrawerBlock as={Flex} title="Advanced Settings">
                          {() => (
                            <Flex direction="column" gap={8}>
                              <Field
                                name="settings.primaryKey"
                                label="Primary Key"
                                component={TextInput}
                                size="small"
                              />
                              <Field
                                isClearable
                                name="settings.transformCase"
                                label="Name Casing"
                                component={SelectInput}
                                size="small"
                                options={[
                                  { label: 'Sentence Case', meta: 'Sentence Case', value: 'SENTENCE' },
                                  { label: 'Pascal Case', meta: 'PascalCase', value: 'PASCAL' },
                                  { label: 'Camel Case', meta: 'camelCase', value: 'CAMEL' },
                                  { label: 'Snake Case', meta: 'snake_case', value: 'SNAKE' },
                                  { label: 'Kebab Case', meta: 'kebab-case', value: 'KEBAB' }
                                ]}
                              />
                            </Flex>
                          )}
                        </DrawerBlock>
                      </>
                  )}
                </FormValuesField>
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button type="submit" size="small" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default ImportFromDatabaseView

export { EnvironmentPicker }
