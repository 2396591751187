import React from 'react'

import DropdownBlock from 'components/blocks/DropdownBlock'
import type { DropdownBlockProps } from 'components/blocks/DropdownBlock'

const DropdownBlockWrapper: React.FC<DropdownBlockProps> = ({ block, ...props }) => (
  <DropdownBlock
    {...props}
    {...block.properties}
    identifier={block.identifier}
    dataSource={block?.properties?.data_source}
    dataSourceSettings={block?.properties?.data_source_settings}
    labelKey={block?.properties?.label_key}
    valueKey={block?.properties?.value_key}
    metaKey={block?.properties?.meta_key}
  />
)

export default DropdownBlockWrapper
