// https://erikras.com/blog/declarative-form-rules
import React from 'react'
import { OnChange } from 'react-final-form-listeners'

import FormField from 'components/form/FormField'

type WhenFieldChangesProps = {
  becomes?: any,
  includes?: any[],
  field: string,
  overrideOnTouch?: boolean,
  replicateValue?: boolean,
  set: string,
  to?: ((value: any) => any) | any
}

const isValueEqual = (
  value: any,
  becomes: WhenFieldChangesProps['becomes'],
  includes: WhenFieldChangesProps['includes']
) => {
  if (includes?.length) {
    return includes.includes(value)
  }

  return value === becomes
}

const WhenFieldChanges = ({
  becomes,
  includes,
  field,
  overrideOnTouch,
  replicateValue,
  set,
  to
}: WhenFieldChangesProps) => (
  <FormField name={set} subscription={{ touched: overrideOnTouch }}>
    {({ input: { onChange }, meta: { touched } }) => (
      <OnChange name={field}>
        {(value: any) => {
          const shouldChange = !((overrideOnTouch && touched))
          if (shouldChange) {
            if (replicateValue) {
              onChange(value)
            } else if (becomes === undefined && !includes && to) {
              onChange(typeof to === 'function' ? to(value) : to)
            } else if (isValueEqual(value, becomes, includes)) {
              onChange(typeof to === 'function' ? to(value) : to)
            }
          }
        }}
      </OnChange>
    )}
  </FormField>
)

export default WhenFieldChanges
