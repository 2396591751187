import React, { useState } from 'react'

import Button from 'components/buttons/Button'
import DashboardFormView from 'components/views/DashboardFormView'
import DashboardNameRenderer from 'components/renderers/DashboardNameRenderer'
import DataTableBlock from 'components/blocks/DataTableBlock'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import SearchBar from 'components/searchbar/SearchBar'
import TitleBlock from 'components/blocks/TitleBlock'
import useConfirmation from 'hooks/useConfirmation'
import useReorder from 'hooks/useReorder'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, DashboardsListQuery, useDestroyDashboardMutation, useUpdateDashboardMutation } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { Dashboard, DashboardsListQueryVariables } from 'generated/schema'

const columns: Column[] = [
  { dataKey: 'name', fieldType: 'text-field', title: 'Name', sortable: true, style: { width: 200 }, renderer: DashboardNameRenderer }
  // { dataKey: 'identifier', fieldType: 'text-field', title: 'Identifier', style: { flexGrow: 1 } }
]

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [ { position: 'asc' } ]

function DashboardsPage() {
  const { openView } = useViewDispatch()
  const [ order, setOrder ] = useState(DEFAULT_ORDER)

  const queryVariables = {
    limit: 100,
    order
  }

  const [ {
    data: { dashboardsList = [], dashboardsAggregate = {} } = {},
    error,
    loading
  }, handleChange ] = useSearch<DashboardsListQuery, DashboardsListQueryVariables>({
    query: DashboardsListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ 'name' ]
  })

  const [ deleteDashboard ] = useDestroyDashboardMutation({
    refetchQueries: [
      DashboardsListDocument
    ]
  })

  const handleDeleteDashboard = useSubmitHandler(deleteDashboard, {
    update: {
      strategy: 'REMOVE',
      query: DashboardsListDocument,
      queryVariables,
      dataKey: 'dashboardsList',
      mutation: 'destroyDashboard'
    }
  })

  const confirm = useConfirmation()

  const openDashboardForm = (values?: Dashboard) => {
    openView({
      title: `${values?.id ? 'Edit' : 'Create'} Dashboard`,
      component: DashboardFormView,
      params: {
        initialValues: values || {
          position: generatePosition(getMaxPosition(dashboardsList as any[]))
        },
        queryVariables
      },
      style: values?.id ? 'PANEL' : 'DIALOG'
    })
  }

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: (dashboard: Dashboard) => openDashboardForm(dashboard)
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (dashboard: Dashboard) => {
        confirm({
          action: 'delete',
          recordType: 'dashboard',
          recordDescription: dashboard.name,
          onConfirmClick: () => handleDeleteDashboard({ id: dashboard.id })
        })
      }
    }
  ]

  const batchActions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: () => {}
    }
  ]

  const dataTableSecondaryElement = (
    <Button icon="add-thin" onClick={() => openDashboardForm()} size="small" />
  )

  const [ updateDashboard ] = useUpdateDashboardMutation()
  const handleUpdateDashboard = useSubmitHandler(updateDashboard)

  const reorder = useReorder({
    query: DashboardsListDocument,
    variables: queryVariables,
    dataKey: 'dashboardsList',
    callback: handleUpdateDashboard
  })

  return (
    <>
      <TitleBlock heading="Dashboards" />
      <DataTableBlock
        actions={actions}
        batchActions={batchActions}
        columns={columns}
        data={dashboardsList as Dashboard[]}
        defaultOrder={order}
        error={error}
        loading={loading}
        primaryElements={<SearchBar placeholder="Search..." onChange={handleChange} />}
        secondaryElements={dataTableSecondaryElement}
        setOrder={setOrder}
        totalRows={dashboardsAggregate?.count || 0}
        onRowDragEnd={reorder}
      />
    </>
  )
}

export default DashboardsPage
