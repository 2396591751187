import React from 'react'
import type { PropsWithChildren } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { styled } from 'styles/stitches'

type DialogHeaderProps = PropsWithChildren<{
  onCloseClick: () => void,
  title: string
}>

const StyledHeader = styled(Flex, {
  backgroundColor: 'light100',
  borderBottomColor: 'light700',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  height: 96,
  paddingX: DIALOG_PADDING,
  paddingY: 24
})

const StyledCloseIcon = styled(Icon, {
  ...mixins.transition('simple'),

  color: 'dark100',
  cursor: 'pointer',

  '&:hover': {
    color: 'dark500'
  }
})

function DialogHeader({ children, onCloseClick, title }: DialogHeaderProps) {
  return (
    <Flex direction="column">
      <StyledHeader alignItems="center" justifyContent="space-between">
        <Text
          as="h1"
          fontSize={32}
          fontWeight="bold"
          letterSpacing="compact"
        >
          {title}
        </Text>
        <StyledCloseIcon size={22} name="cross-thin" onClick={onCloseClick} />
      </StyledHeader>
      {children}
    </Flex>
  )
}

export default DialogHeader
