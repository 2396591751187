import React, { ReactNode } from 'react'

import Text from 'components/typography/Text'

type ExternalTitleProps = {
  children: ReactNode
}

function ExternalTitle({ children }: ExternalTitleProps) {
  return <Text as="h1" color="dark900" fontSize={36} fontWeight="light" letterSpacing="compact">{children}</Text>
}

export default ExternalTitle
