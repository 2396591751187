import React, { ReactNode } from 'react'

import Text from 'components/typography/Text'

type ExternalParagraphProps = {
  children: ReactNode
}

function ExternalParagraph({ children }: ExternalParagraphProps) {
  return <Text color="dark700" fontSize={14} fontWeight="light" letterSpacing="condensed">{children}</Text>
}

export default ExternalParagraph
