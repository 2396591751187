/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable, DropResult } from 'react-beautiful-dnd'
import { Form, FormProps, useField, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import ConditionalField from 'components/form/ConditionalField'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import FormValuesField from 'components/form/FormValuesField'
import Icon from 'components/icons/Icon'
import IconInput from 'components/inputs/IconInput'
import Loader from 'components/loaders/Loader'
import MediaCard from 'components/mediaCard/MediaCard'
import Operation, { BehaviorKind, BehaviorMethod, KIND_OPTIONS, ResponseMapperKind, RESPONSE_MAPPER_KIND_OPTIONS } from 'models/Operation'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useReorder from 'hooks/useReorder'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { APP_CATEGORIES_ID, APP_IDS } from 'models/App'
import { App, CreateOperationInput, Installation, OperationBehaviorKind, OperationBehaviorMethod, OperationsListDocument, Parameter, ParametersListDocument, Resource, UpdateOperationInput, useConfigurationsListQuery, useCreateOperationMutation, useFieldTypesListQuery, useInstallationsListQuery, useOperationsListQuery, useParametersListQuery, useUpdateOperationMutation, useUpdateParameterMutation, ApiRequestsListQuery, ApiRequestsListQueryVariables, ApiRequestsListDocument, useDestroyParameterMutation, useFlowsListQuery, useApiRequestQuery } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { INTEGRATIONS_LIST_LIMIT } from 'models/Installation'
import { OPERATIONS_LIST_LIMIT, PARAMETERS_LIST_LIMIT } from 'models/Resource'
import { SidePaneFooter, SidePaneSubHeader } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type FormValues = CreateOperationInput | UpdateOperationInput

type Params = ViewParams[Views.CREATE_OPERATION]

const CONFIGURATIONS_LIST_LIMIT = 1000

const defaultStepSubtitles = [
  'Step 1: Select a behavior',
  'Step 2: Enter details'
]

const resourceStepSubtitles = [
  'Step 1: Select type of operation',
  'Step 2: Select a behavior',
  'Step 3: Enter details'
]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const disabledKinds = [ BehaviorKind.CODE ]

const BehaviorKinds = [
  {
    name: 'Database Query',
    icon: 'table',
    identifier: BehaviorKind.DATABASE_QUERY
  }, {
    name: 'API Request',
    icon: 'graph',
    identifier: BehaviorKind.API_REQUEST
  }, {
    name: 'Trigger Flow',
    icon: 'flow',
    identifier: BehaviorKind.FLOW
  }, {
    name: 'Execute Code',
    icon: 'code',
    identifier: BehaviorKind.CODE,
    description: 'Coming soon',
    disabled: true
  }
]

const BehaviorMethods = [
  {
    name: 'Get a record',
    icon: 'table',
    identifier: BehaviorMethod.GET
  }, {
    name: 'List records',
    icon: 'data-table',
    identifier: BehaviorMethod.LIST
  }, {
    name: 'Create record',
    icon: 'add',
    identifier: BehaviorMethod.CREATE
  }, {
    name: 'Update a record',
    icon: 'edit',
    identifier: BehaviorMethod.UPDATE
  }, {
    name: 'Delete a record',
    icon: 'trash',
    identifier: BehaviorMethod.DESTROY
  }, {
    name: 'Import records',
    icon: 'import',
    identifier: BehaviorMethod.IMPORT
  }, {
    name: 'Other',
    icon: 'settings',
    identifier: BehaviorMethod.GENERIC
  }
]

const noop = () => {}

const calculateCurrentStep = (isTouched: boolean, isUpdating: boolean, resourceId: string) => {
  if (isUpdating) {
    if (resourceId) return 2
    if (isTouched) return 1
    return 1
  }

  if (isTouched) {
    if (resourceId) return 2
    return 1
  }

  return 0
}

const BehaviorMethodStep = ({
  behaviorMethod,
  setBehaviorMethod,
  handleStepForward,
  resourceId,
  appId
}: {
  behaviorMethod: OperationBehaviorMethod | null,
  setBehaviorMethod: React.Dispatch<React.SetStateAction<OperationBehaviorMethod | null>>,
  handleStepForward: () => void,
  resourceId: string,
  appId?: string
}) => {
  const {
    data: { operationsList = [] } = {},
    loading,
    error
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId },
        appId: appId ? { eq: appId } : undefined
      },
      order: [ {
        position: 'asc'
      } ],
      limit: OPERATIONS_LIST_LIMIT
    },
    skip: !resourceId
  })

  return (
    <Loader
      data={operationsList}
      loading={loading}
      error={error}
    >
      <Flex direction="column" gap={16}>
        {BehaviorMethods.map((kind) => {
          const exists = operationsList.some((operation) => {
            // Always allow creation of GENERIC operations
            if (kind.identifier === BehaviorMethod.GENERIC) {
              return false
            }

            return operation.behaviorMethod === kind.identifier
          })

          const onClick = () => {
            setBehaviorMethod(kind.identifier)
            handleStepForward()
          }

          return (
            <MediaCard
              active={behaviorMethod === kind.identifier}
              disabled={exists}
              key={kind.identifier}
              media={kind.icon}
              meta={exists && <Chip label="Exists" variant="light" />}
              onClick={onClick}
              title={kind.name}
              width="full"
              height={64}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                isIconAlwaysVisible: true,
                onClick
              } ]}
            />
          )
        })}

      </Flex>
    </Loader>
  )
}

const BehaviorKindStep = ({
  behaviorKind,
  setBehaviorKind,
  handleStepForward
}: {
  behaviorKind: OperationBehaviorKind | null,
  setBehaviorKind: React.Dispatch<React.SetStateAction<OperationBehaviorKind | null>>,
  handleStepForward: () => void
}) => (
  <Flex direction="column" gap={16}>
    {BehaviorKinds.map((kind) => {
      const onClick = () => {
        setBehaviorKind(kind.identifier)
        handleStepForward()
      }

      return (
        <MediaCard
          compact
          active={behaviorKind === kind.identifier}
          key={kind.identifier}
          media={kind.icon}
          onClick={onClick}
          title={kind.name}
          width="full"
          height={64}
          text={kind.description}
          titlePosition="top"
          disabled={disabledKinds.includes(kind.identifier)}
          actions={[ {
            description: '',
            icon: 'arrow-right',
            isIconAlwaysVisible: true,
            onClick
          } ]}
        />
      )
    })}
  </Flex>
)

const FinalStep = ({
  behaviorKind,
  behaviorMethod
}: {
  behaviorKind: OperationBehaviorKind | null,
  behaviorMethod: OperationBehaviorMethod | null
}) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const {
    params = {}
  } = useRecoilValue<DashboardEditorView<Views.CREATE_OPERATION>>(dashboardEditorState)
  const { operation = {}, app, resource, workspace } = params! as Params
  const { description, icon } = operation as FormValues
  const [ showIcon, setShowIcon ] = useState(!!icon)
  const [ showDescription, setShowDescription ] = useState(!!description)

  const apiRequestIdField = useField('behaviorSettings.apiRequestId')

  const { data: { apiRequest } = {} } = useApiRequestQuery({
    variables: {
      id: apiRequestIdField.input.value
    },
    skip: !apiRequestIdField.input.value
  })

  const {
    data: { installationsList = [] } = {},
    loading: installationsLoading
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        appKind: { eq: 'INTEGRATION' },
        appId: {
          in: {
            [BehaviorKind.DATABASE_QUERY]: [ APP_IDS.postgresql, APP_IDS.mysql, APP_IDS.mongodb ],
            [BehaviorKind.API_REQUEST]: [ APP_IDS.rest, APP_IDS.graphql ]
          }[behaviorKind as string]
        }
      },
      limit: INTEGRATIONS_LIST_LIMIT
    },
    skip: !(
      behaviorKind === BehaviorKind.DATABASE_QUERY || behaviorKind === BehaviorKind.API_REQUEST
    )
  })

  const { data, loading } = useFlowsListQuery({
    variables: {
      filter: {
        status: {
          eq: 'PUBLISHED'
        }
      }
    },
    skip: behaviorKind !== BehaviorKind.FLOW
  })

  const {
    data: { configurationsList = [] } = {},
    loading: configurationsLoading
  } = useConfigurationsListQuery({
    variables: {
      filter: {
        installationId: {
          in: installationsList.map((installation) => installation.id)
        }
      },
      limit: CONFIGURATIONS_LIST_LIMIT
    },
    skip: !installationsList.length
  })

  const handleAddNewIntegration = () => openDashboardEditorView({
    target: Views.ADD_INTEGRATION,
    params: {
      ...params,
      operation,
      appCategoryIds: {
        [BehaviorKind.API_REQUEST]: [ APP_CATEGORIES_ID.APIs ],
        [BehaviorKind.DATABASE_QUERY]: [ APP_CATEGORIES_ID.Databases ]
      }[behaviorKind as string]
    } as any
  })

  const { values: formValues } = useFormState()

  const getOptionLabel = (option: Installation) => {
    const { app } = option
    const { name } = option
    const hasSameAppName = app.name === name
    return `${name} ${!hasSameAppName ? `(${app.name})` : ''}`
  }

  const getOptionMeta = (option: Installation) => {
    const isConfigured = configurationsList.find(
      (config) => config.installationId === option.id
    )

    return isConfigured ? 'Configured' : 'Not Configured'
  }

  const getOptionDisabled = (option: Installation) => {
    const isConfigured = configurationsList.find(
      (config) => config.installationId === option.id
    )

    return !isConfigured
  }

  return (
    <Flex direction="column" gap={32}>
      <Flex direction="column" gap={16}>
        <FormField
          autoFocus
          component={TextInput}
          name="name"
          label="Name"
          size="small"
          type="text"
          helpText={'Use the format: VERB + OBJECT. Example: "Check Health" "List Posts"'}
        />
        <FormField
          component={TextInput}
          name="identifier"
          label="Identifier"
          size="small"
          type="text"
          helpText="Used in code. Avoid modifying this."
        />
        {showDescription && (
          <FormField
            name="description"
            component={TextAreaInput}
            label="Description"
            type="text"
          />
        )}
        {showIcon && (
          <FormField
            component={IconInput}
            name="icon"
            label="Choose Icon"
            size="small"
            type="text"
          />
        )}
        {(!showDescription || !showIcon) && (
          <Flex gap={16}>
            {!showDescription && (
              <TextLink
                as="button"
                type="button"
                variant="underlined"
                mode="subtle"
                alignSelf="flex-start"
                onClick={() => setShowDescription(true)}
              >
                Add description
              </TextLink>
            )}
            {!showIcon && (
              <TextLink
                as="button"
                type="button"
                variant="underlined"
                mode="subtle"
                alignSelf="flex-start"
                onClick={() => setShowIcon(true)}
              >
                Add icon
              </TextLink>
            )}
          </Flex>
        )}
      </Flex>

      <Parameters
        {...{
          app,
          resource,
          workspace,
          operation: formValues
        }}
      />

      <Flex gap={16} direction="column">
        <Flex direction="column" gap={4}>
          <Text
            color="dark700"
            fontSize={14}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Behavior
          </Text>
          <Text fontSize={12} color="dark500">Specify the logic for your operation.</Text>
        </Flex>
        <Flex direction="column" gap={10}>
          <FormField
            component={SelectInput}
            name="behaviorKind"
            label="Source"
            size="small"
            type="text"
            options={BehaviorKinds}
            valueKey="identifier"
            labelKey="name"
            isOptionDisabled={(option: typeof BehaviorKinds[number]) => option.disabled}
            defaultValue={behaviorKind}
          />
          {((behaviorKind) => {
            if (behaviorKind === BehaviorKind.API_REQUEST) {
              return (
                <>
                  <Flex justifyContent="space-between" gap={16}>
                    <Text
                      color="dark500"
                      fontSize={10}
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      API
                    </Text>
                    <TextLink
                      as="button"
                      type="button"
                      fontSize={10}
                      onClick={handleAddNewIntegration}
                      mode="distinct"
                    >
                      Add new
                    </TextLink>
                  </Flex>
                  <FormField
                    loading={installationsLoading || configurationsLoading}
                    isSearchable
                    component={SelectInput}
                    name="behaviorSettings.integrationId"
                    size="small"
                    valueKey="id"
                    placeholder="Choose an API"
                    key={behaviorKind}
                    options={installationsList}
                    getOptionLabel={getOptionLabel}
                    getOptionMeta={getOptionMeta}
                    isOptionDisabled={getOptionDisabled}
                  />
                  <FormValuesField fieldNames={[ 'behaviorSettings.integrationId' ]}>
                    {(values) => (
                      <>
                        <FormField component="input" type="hidden" name="behaviorSettings" />
                        <SearchSelectField<ApiRequestsListQuery, ApiRequestsListQueryVariables>
                          key={values['behaviorSettings.integrationId']}
                          isClearable
                          name="behaviorSettings.apiRequestId"
                          label="Endpoint"
                          placeholder="Search endpoint"
                          size="small"
                          labelKey="name"
                          valueKey="id"
                          metaKey="method"
                          query={ApiRequestsListDocument}
                          dataKey="apiRequestsList"
                          keys={[ 'name', 'path' ]}
                          options={apiRequest ? [ apiRequest ] : []}
                          queryOptions={{
                            variables: {
                              filter: {
                                integrationId: { eq: values['behaviorSettings.integrationId'] },
                                kind: { eq: 'HTTP' }
                              }
                            },
                            skip: !values['behaviorSettings.integrationId']
                          }}
                        />
                      </>
                    )}
                  </FormValuesField>
                </>
              )
            }
            if (behaviorKind === BehaviorKind.DATABASE_QUERY) {
              return (
                <>
                  <Flex justifyContent="space-between" gap={16}>
                    <Text
                      color="dark500"
                      fontSize={10}
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Database
                    </Text>
                    <TextLink
                      as="button"
                      type="button"
                      fontSize={10}
                      onClick={handleAddNewIntegration}
                      mode="distinct"
                    >
                      Add new
                    </TextLink>
                  </Flex>
                  <FormField component="input" type="hidden" name="behaviorSettings" />
                  <FormField
                    loading={installationsLoading || configurationsLoading}
                    isSearchable
                    component={SelectInput}
                    name="behaviorSettings.integrationId"
                    size="small"
                    valueKey="id"
                    placeholder="Choose a database"
                    options={installationsList}
                    getOptionLabel={getOptionLabel}
                    getOptionMeta={getOptionMeta}
                    isOptionDisabled={getOptionDisabled}
                  />
                  <FormField
                    component={CodeEditorInput}
                    name="behaviorSettings.query"
                    label="Query"
                    size="small"
                  />
                </>
              )
            }
            if (behaviorKind === BehaviorKind.FLOW) {
              return (
                <FormField
                  isClearable
                  loading={loading}
                  isSearchable
                  component={SelectInput}
                  name="behaviorSettings.flowId"
                  size="small"
                  valueKey="id"
                  placeholder="Choose a flow"
                  options={data?.flowsList || []}
                  labelKey="name"
                  label="Flow"
                />
              )
            }

            return null
          })(behaviorKind)}
        </Flex>
        {behaviorMethod === BehaviorMethod.GENERIC && behaviorKind !== 'FLOW' && (
          <>
            <FormField
              alwaysDirty
              isClearable
              component={SelectInput}
              name="responseMapper.kind"
              label="Response Mapper"
              size="small"
              options={RESPONSE_MAPPER_KIND_OPTIONS}
              helpText="Use this to modify this operation's response."
            />
            <ConditionalField when="responseMapper.kind" is={ResponseMapperKind.KEY}>
              <FormField
                alwaysDirty
                checkRequired
                component={TextInput}
                name="responseMapper.mapper"
                label="Response path"
                size="small"
                helpText="Dot-notation JSON path. For eg: 'a.b.c'"
              />
            </ConditionalField>
            <ConditionalField when="responseMapper.kind" is={ResponseMapperKind.FUNCTION}>
              <FormField
                alwaysDirty
                checkRequired
                name="responseMapper.mapper"
                label="Response Transform Function"
                component={CodeEditorInput}
              />
            </ConditionalField>
            <WhenFieldChanges field="responseMapper.kind" becomes={null} set="responseMapper" to={null} />
          </>
        )}
        <FormField
          component={SelectInput}
          name="graphqlKind"
          label="GraphQL Kind"
          size="small"
          options={KIND_OPTIONS}
          helpText="Leave blank to automatically decide."
        />
      </Flex>
    </Flex>
  )
}

const Parameters = ({ operation, app, resource, workspace }: any) => {
  const { openDashboardEditorView, selectedBlockState } = useDashboard()
  const selectedBlock = useRecoilValue(selectedBlockState)!
  const operationId = operation.id
  const confirm = useConfirmation({ style: 'DIALOG' })
  const droppableId = useRef(uuid())
  const { data: { fieldTypes } = {} } = useFieldTypesListQuery()
  const form = useForm()

  const handleAddNewParameter = () => openDashboardEditorView({
    target: Views.CREATE_PARAMETER,
    params: {
      operation,
      app,
      resource,
      workspace,
      block: selectedBlock
    }
  })
  const queryVariables = {
    filter: {
      operationId: { eq: operationId }
    },
    order: [ {
      position: 'asc'
    } ],
    limit: PARAMETERS_LIST_LIMIT
  }

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading,
    error: parametersListError
  } = useParametersListQuery({
    variables: queryVariables,
    skip: !operationId
  })

  const [ updateParameter ] = useUpdateParameterMutation({
    onCompleted: () => {},
    refetchQueries: [ ParametersListDocument ]
  })

  const handleUpdateParameter = useSubmitHandler(updateParameter, {
    successAlert: { message: 'Parameter Updated.' }
  })

  const [ destroyParameter ] = useDestroyParameterMutation({
    refetchQueries: [ ParametersListDocument ]
  })

  const handleDestroyParameter = useSubmitHandler(destroyParameter, {
    successAlert: { message: 'Parameter Deleted.' }
  })

  const openConfirmationDialog = (param: Parameter) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        handleDestroyParameter({ id: param.id })
      },
      recordType: 'Parameter',
      recordDescription: param.name
    })
  }

  const reorder = useReorder({
    query: ParametersListDocument,
    variables: queryVariables,
    dataKey: 'parametersList',
    callback: handleUpdateParameter
  })

  const reorderFields = (result: DropResult) => {
    if (!result.destination) return

    const paramsList = (form.getFieldState('parameters')?.value || []) as any[]

    const newParamsList = paramsList.slice()
    const [ draggedItem ] = newParamsList.splice(result.source.index, 1)
    newParamsList.splice(result.destination.index, 0, draggedItem)
    form.change('parameters', newParamsList)
  }

  const renderDraggableChildren = (
    parameters: readonly any[],
    onDelete: () => any
  ): DraggableChildrenFn => ({
    draggableProps,
    dragHandleProps,
    innerRef
  }, _, rubric) => {
    const parameter = parameters[rubric.source.index]
    const fieldType = fieldTypes?.find((type) => type.identifier === parameter.fieldType)

    const onClick = () => openDashboardEditorView({
      target: Views.CREATE_PARAMETER,
      params: {
        initialValues: parameter,
        operation,
        app,
        currentIndex: rubric.source.index,
        resource,
        block: selectedBlock
      }
    })

    return (
      <div
        {...draggableProps}
        {...dragHandleProps}
        ref={innerRef}
      >
        <MediaCard
          compact
          key={parameter.id}
          media={(
            <Icon name="drag" size={16} color="dark100" />
          )}
          title={parameter.name}
          height={64}
          width="full"
          onClick={onClick}
          description={(
            <Chip label={parameter.identifier} variant="light" textTransform="lowercase" />
          )}
          actions={[
            {
              icon: 'trash',
              description: 'Delete',
              onClick: onDelete,
              variant: 'negative'
            },
            {
              description: parameter.fieldType?.replace('-field', '') || parameter.dataType?.kind,
              icon: `${fieldType?.icon || 'text-field'}`,
              onClick,
              isIconAlwaysVisible: true
            },
            {
              description: '',
              icon: 'arrow-right',
              onClick,
              isIconAlwaysVisible: true
            }
          ]}
        />
      </div>
    )
  }

  return (
    <Flex gap={16} direction="column">
      <Flex direction="column" gap={4}>
        <Flex justifyContent="space-between" gap={16}>
          <Text
            color="dark700"
            fontSize={14}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Parameters
          </Text>
          <TextLink
            as="button"
            type="button"
            fontSize={10}
            onClick={handleAddNewParameter}
            mode="distinct"
          >
            Add new
          </TextLink>
        </Flex>
        <Text fontSize={12} color="dark500">Specify the inputs for your operation.</Text>
      </Flex>
      {operationId ? (
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={parametersList}
          loading={parametersListLoading}
          error={parametersListError}
        >
          <DragDropContext onDragEnd={reorder}>
            <Droppable
              droppableId={droppableId.current}
              renderClone={renderDraggableChildren(parametersList, noop)}
            >
              {(droppableProvided) => (
                <Flex
                  direction="column"
                  gap={2}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {parametersList.map((parameter, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={parameter.identifier}
                      index={index}
                      key={parameter.identifier}
                    >
                      {renderDraggableChildren(
                        parametersList,
                        () => openConfirmationDialog(parameter as Parameter)
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </DashboardEditorLoader>
      ) : (
        <FormValuesField fieldNames={[ 'parameters' ]}>
          {({ parameters }) => (parameters ? (
            <DragDropContext onDragEnd={reorderFields}>
              <Droppable
                droppableId={droppableId.current}
                renderClone={renderDraggableChildren(parameters, noop)}
              >
                {(droppableProvided) => (
                  <Flex
                    direction="column"
                    gap={2}
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {parameters.map((param: any, index: number) => (
                      <Draggable
                        disableInteractiveElementBlocking
                        draggableId={param.identifier}
                        index={index}
                        key={param.identifier}
                      >
                        {renderDraggableChildren(
                          parameters,
                          () => form.change('parameters', [ ...parameters.slice(0, index), ...parameters.slice(index + 1) ])
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </Flex>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          ))}
        </FormValuesField>
      )}
    </Flex>
  )
}

const CreateOperationInputs = ({
  appId,
  handleStepForward,
  isFinalStep,
  isFirstStep,
  isSecondStep,
  resourceId
}: any) => {
  const behaviorKindField = useField('behaviorKind')
  const behaviorMethodField = useField('behaviorMethod')

  const behaviorKind = behaviorKindField.input.value
  const behaviorMethod = behaviorMethodField.input.value

  const setBehaviorKind = behaviorKindField.input.onChange
  const setBehaviorMethod = behaviorMethodField.input.onChange

  return (
    <>
      <FormField name="behaviorMethod" type="hidden" component="input" value={behaviorMethod} />
      {isFirstStep && (resourceId ? (
        <BehaviorMethodStep
          behaviorMethod={behaviorMethod}
          setBehaviorMethod={setBehaviorMethod}
          handleStepForward={handleStepForward}
          resourceId={resourceId}
          appId={appId}
        />
      ) : (
        <BehaviorKindStep
          behaviorKind={behaviorKind}
          setBehaviorKind={setBehaviorKind}
          handleStepForward={handleStepForward}
        />
      ))}
      {isSecondStep && (
        <BehaviorKindStep
          behaviorKind={behaviorKind}
          setBehaviorKind={setBehaviorKind}
          handleStepForward={handleStepForward}
        />
      )}
      {isFinalStep && (
        <FinalStep
          behaviorKind={behaviorKind}
          behaviorMethod={behaviorMethod}
        />
      )}
      <input type="submit" style={{ display: 'none' }} />
    </>
  )
}

const CreateOperationView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, resource, operation } = params! as Params
  const { id: appId, name: appName, kind: appKind } = app || {} as App
  const { id: resourceId } = resource || {} as Resource

  const isUpdating = 'id' in (operation || {})
  const isProject = appKind === 'PROJECT'

  const isTouched = !!operation?.behaviorKind
    || !!operation?.behaviorMethod
    || !resourceId

  const [ currentStep, setCurrentStep ] = useState(
    calculateCurrentStep(isTouched, isUpdating, resourceId)
  )

  const isFirstStep = currentStep === 0
  const isSecondStep = resourceId ? currentStep === 1 : false
  const isFinalStep = resourceId ? currentStep === 2 : currentStep === 1

  const handleStepForward = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleStepBehind = () => {
    setCurrentStep(currentStep - 1)
  }

  const [ createOperation ] = useCreateOperationMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ OperationsListDocument ]
  })

  const [ updateOperation ] = useUpdateOperationMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ OperationsListDocument ]
  })

  const handleCreateOperation = useSubmitHandler(createOperation, {
    successAlert: { message: 'Operation Created.' }
  })

  const handleUpdateOperation = useSubmitHandler(updateOperation, {
    successAlert: { message: 'Operation Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    const parsedValues = {
      behaviorMethod: resourceId ? values.behaviorMethod : BehaviorMethod.GENERIC,
      ...values
    }

    if (isUpdating) {
      return handleUpdateOperation(
        parsedValues as UpdateOperationInput,
        form as FormApi<UpdateOperationInput>
      )
    }

    return handleCreateOperation(parsedValues as CreateOperationInput)
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'New'} Operation`}
        heading={resourceId
          ? `Resource: ${resource?.name}`
          : isProject ? `Project: ${appName}`
            : appName ? `App: ${appName}` : 'Schema'}
        onClose={onClose}
      />
      {!isUpdating && (
        <SidePaneSubHeader size="small">
          <Text fontWeight="bold">{resourceId ? resourceStepSubtitles[currentStep] : defaultStepSubtitles[currentStep]}</Text>
        </SidePaneSubHeader>
      )}
      <Form
        decorators={[
          setIdentifier
        ]}
        initialValues={{
          parameters: [],
          behaviorSettings: {},
          position: 0,
          behaviorMethod: operation?.behaviorMethod || BehaviorMethod.GENERIC,
          behaviorKind: operation?.behaviorKind || BehaviorKind.DATABASE_QUERY,
          ...(!isUpdating && resourceId ? { resourceId } : {}),
          ...(!isUpdating && appId ? { appId } : {}),
          ...operation as FormValues
        }}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        subscription={{
          submitting: true
        }}
        validate={(values) => Operation.validate(values, [ 'identifier', 'name' ])}
        render={({ handleSubmit, submitting }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                <CreateOperationInputs
                  appId={appId}
                  handleStepForward={handleStepForward}
                  isFinalStep={isFinalStep}
                  isFirstStep={isFirstStep}
                  isSecondStep={isSecondStep}
                  resourceId={resourceId}
                />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                {isFinalStep && <Button size="small" type="submit" disabled={submitting} label="Submit" onClick={handleSubmit} />}
                {!isFinalStep && (
                  <Button
                    icon="arrow-right"
                    onClick={handleStepForward}
                    size="small"
                  />
                )}
                {!isFirstStep && !isUpdating && <Button size="small" icon="arrow-left" onClick={handleStepBehind} />}
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export { Parameters }

export default CreateOperationView
