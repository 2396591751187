import get from 'lodash/get'
import { setIn } from 'final-form'
import { useApolloClient } from '@apollo/client'
import type { DocumentNode } from '@apollo/client'
import type { DropResult } from 'react-beautiful-dnd'

import generatePosition from 'lib/generatePosition'

type Input = {
  id: string,
  position: number
} | {
  id: string,
  [key: string]: any
}

type Reorder = {
  broadcast?: boolean,
  query: DocumentNode,
  dataKey: string,
  callback: (input: Input) => void,
  variables?: Record<string, any>,
  positionKey?: string,
  returnKey?: string
}

function reorderData<T extends OrderableData>(
  sourceIndex: number,
  targetIndex: number,
  data: T[],
  key: string
) {
  const dataCopy = data.slice()
  const [ removed ] = dataCopy.splice(sourceIndex, 1)
  dataCopy.splice(targetIndex, 0, removed)

  const position = generatePosition(
    get(dataCopy[targetIndex - 1], key),
    get(dataCopy[targetIndex + 1], key)
  )

  dataCopy[targetIndex] = setIn(dataCopy[targetIndex], key, position) as any

  return dataCopy
}

type OrderableData = { id: string, position: number } & Record<any, any>

const rebalance = <T extends OrderableData, >(
  data: T[],
  targetIndex: number,
  callback: (input: Input) => void,
  key: string,
  returnKey: string
) => {
  if (
    data[targetIndex - 1]
    && get(data[targetIndex], key) === get(data[targetIndex - 1], key)
  ) {
    const position = generatePosition(
      get(data[targetIndex - 2], key),
      get(data[targetIndex - 1], key)
    )
    callback({
      id: data[targetIndex - 1].id,
      [returnKey]: get(setIn(data[targetIndex - 1], key, position), returnKey)
    })
  }

  if (
    data[targetIndex + 1]
    && get(data[targetIndex], key) === get(data[targetIndex + 1], key)
  ) {
    const position = generatePosition(
      get(data[targetIndex + 1], key),
      get(data[targetIndex + 2], key)
    )
    callback({
      id: data[targetIndex + 1].id,
      [returnKey]: get(setIn(data[targetIndex + 1], key, position), returnKey)
    })
  }
}

const useReorder = (
  { query, variables, dataKey, callback, broadcast, positionKey: key = 'position', returnKey = 'position' }: Reorder
) => {
  const client = useApolloClient()

  return (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) { return null }

    const sourceIndex = result.source.index
    const targetIndex = result.destination.index

    const cachedData = client.readQuery({
      query, variables
    }) || {}

    const sortedData = reorderData(
      sourceIndex,
      targetIndex,
      (cachedData[dataKey] || []).slice(),
      key
    )

    client.writeQuery({
      // TODO:- Investigate why this causes refetch
      broadcast,
      query,
      variables,
      data: {
        ...cachedData,
        [dataKey]: sortedData
      }
    })

    rebalance(sortedData, targetIndex, callback, key, returnKey)

    const { id } = sortedData[targetIndex]
    return callback({
      id,
      [returnKey]: get(sortedData[targetIndex], returnKey)
    })
  }
}

export {
  rebalance,
  reorderData
}

export type { OrderableData }

export default useReorder
