// Inspired by: https://github.com/sindresorhus/normalize-url

import isCallableUrl from 'lib/isCallableUrl'

type Protocol = 'http' | 'https'

type NormalizeUrlOptions = {
  defaultProtocol: Protocol
}

const isRelativeUrl = (url: string = '') => (!url.startsWith('//') && /^\.*\/|^#/.test(url))

function normalizeUrl(
  url: string = '',
  options: NormalizeUrlOptions = { defaultProtocol: 'http' }
) {
  let normalizedUrl = url.trim()

  if (!normalizedUrl.length) return ''

  // Added support for ‘#’ targets
  const isRelative = isRelativeUrl(normalizedUrl)
  const isCallable = isCallableUrl(normalizedUrl)

  if (!isRelative && !isCallable) {
    normalizedUrl = normalizedUrl.replace(/^(?!(?:\w+:)?\/\/)|^\/\//, `${options.defaultProtocol}:`)
    normalizedUrl = new URL(normalizedUrl).href
  }

  return normalizedUrl
}

export type { Protocol }

export { isRelativeUrl }

export default normalizeUrl
