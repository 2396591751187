import React from 'react'

import CheckboxInput from 'components/inputs/CheckboxInput'
import FormField from 'components/form/FormField'
import type { CheckboxInputProps } from 'components/inputs/CheckboxInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type CheckboxFieldProps = Omit<CheckboxInputProps, 'input' | 'meta'> & fieldProps<'checkbox'>

const CheckboxField = ({ name, ...others }: CheckboxFieldProps) => (
  <FormField
    component={CheckboxInput}
    name={name}
    type="checkbox"
    {...others}
  />
)

export default CheckboxField
