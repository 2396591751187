import Hotjar from '@hotjar/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import * as serviceWorker from 'serviceWorker'
import Root from 'Root'

import 'assets/stylesheets/main.css'

const HOTJAR_VERSION = 6

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_ENV
  })
}

if (process.env.REACT_APP_HOTJAR_SITE_ID) {
  Hotjar.init(Number(process.env.REACT_APP_HOTJAR_SITE_ID), HOTJAR_VERSION)
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  // require('./mocks/browser').worker.start()
}

ReactDOM.render(
  <Root />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// const configuration = {
//   onUpdate: (registration: ServiceWorkerRegistration) => {
//     if (registration && registration.waiting) {
//       // eslint-disable-next-line no-alert
//       if (window.confirm('New version available!  refresh to update your app?')) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//         window.location.reload()
//       }
//     }
//   }
// }
serviceWorker.unregister()
