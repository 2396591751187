import React from 'react'
import get from 'lodash/get'
import { number } from 'yup'

import ConditionalField from 'components/form/ConditionalField'
import Divider from 'components/divider/Divider'
import Grid from 'components/layout/Grid'
import { NumberField, SwitchField } from 'components/contentEditors/generic/fields'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type RepeatedConfigFieldProps = {
  fieldPrefix?: string,
  repeatedLabel?: string,
  hideUniquenessField?: boolean
}

export const RepeatedConfigField = ({
  fieldPrefix = '',
  repeatedLabel = 'Collect multiple values?',
  hideUniquenessField = false
}: RepeatedConfigFieldProps) => (
  <>
    <SwitchField
      name="isArray"
      helpText="Allow the attribute to collect multiple values"
      label={repeatedLabel}
    />
    <ConditionalField when="isArray" is>
      <Grid columns={2} columnGap={8} rowGap={8}>
        <NumberField
          name={`${fieldPrefix}repeatedMinimum`}
          size="small"
          label="Minimum Count"
        />
        <NumberField
          name={`${fieldPrefix}repeatedMaximum`}
          size="small"
          label="Maximum Count"
        />
      </Grid>
      {!hideUniquenessField && (
        <SwitchField
          name={`${fieldPrefix}repeatedAllowDuplicates`}
          label="Allow duplicates?"
          helpText="Allow the same value to appear more than once?"
        />
      )}
      <Divider variant="ruler" />
    </ConditionalField>
  </>
)

export const getRepeatedConfigSchema = <T, >(values: fieldProps<T>) => {
  let repeatedMaximumSchema = number()
  let repeatedMinimumSchema = number()
  const settings = get(values, 'settings')

  // add check for attribute repeatable prop
  if (values.isArray) {
    if (settings?.repeatedMinimum != null) {
      repeatedMinimumSchema = repeatedMinimumSchema.nonNegative().integer()
    }

    if (settings?.repeatedMaximum != null) {
      repeatedMaximumSchema = repeatedMaximumSchema.nonNegative().integer()
    }

    if (settings?.repeatedMaximum != null && settings?.repeatedMinimum != null) {
      repeatedMinimumSchema = repeatedMinimumSchema.max(settings.repeatedMaximum)
      repeatedMaximumSchema = repeatedMaximumSchema.min(settings.repeatedMinimum)
    }
  }
  return {
    repeatedMaximum: repeatedMaximumSchema,
    repeatedMinimum: repeatedMinimumSchema
  }
}

export const UniquenessField = ({ fieldPrefix = '' }) => (
  <ConditionalField when={`${fieldPrefix}isArray`} is={false}>
    <SwitchField
      name={`${fieldPrefix}checkUniqueness`}
      label="No duplicates allowed?"
    />
  </ConditionalField>
)

export type { RepeatedConfigFieldProps }
