import React from 'react'

import AddSegmentView from 'components/views/AddSegmentView'
import Button from 'components/buttons/Button'
import DataListBlock from 'components/blocks/DataListBlock'
import Flex from 'components/layout/Flex'
import parseError from 'lib/parseError'
import SearchBar from 'components/searchbar/SearchBar'
import Text from 'components/typography/Text'
import useConfirmation from 'hooks/useConfirmation'
import useFuzzySearch from 'hooks/useFuzzySearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Segment, SegmentsListDocument, Resource, useSegmentsListQuery, useDestroySegmentMutation, CreateSegmentInput } from 'generated/schema'
import { SEGMENTS_LIST_LIMIT } from 'models/Resource'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'

const DEFAULT_TITLE = 'There are no segments.'
const DEFAULT_SUBTITLE = 'Segments are subsets of a resource.'
const DEFAULT_ERROR_SUBTITLE = 'Please contact support if the problem persists.'

const SegmentsList = ({ resource }: { resource: Resource }) => {
  const confirm = useConfirmation({ style: 'DIALOG' })
  const resourceId = resource.id
  const { isReadOnly } = resource
  const { openView } = useViewDispatch()

  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId }
    },
    limit: SEGMENTS_LIST_LIMIT
  }

  const {
    data: { segmentsList = [] } = {},
    loading,
    error
  } = useSegmentsListQuery({
    variables: queryVariables,
    skip: !resourceId
  })

  const { matches, handleChange } = useFuzzySearch(segmentsList as Segment[], { key: 'name' })

  const [ destroySegment ] = useDestroySegmentMutation({
    refetchQueries: [
      { query: SegmentsListDocument, variables: queryVariables }
    ]
  })

  const handleDestroySegment = useSubmitHandler(destroySegment, {
    successAlert: { message: 'Segment Deleted.' }
  })

  const onEdit = (segment: Segment) => openView({
    title: 'Update Segment',
    component: AddSegmentView,
    params: {
      initialValues: segment,
      resourceId,
      queryVariables
    },
    style: 'PANEL'
  })

  const onAdd = () => openView({
    title: 'Add Segment',
    component: AddSegmentView,
    params: {
      initialValues: {
        name: '',
        identifier: '',
        resourceId
      } as CreateSegmentInput,
      resourceId,
      queryVariables
    },
    style: 'PANEL'
  })

  const openConfirmationDialog = (segment: Segment) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        handleDestroySegment({ id: segment.id })
      },
      recordType: 'Segment',
      recordDescription: segment.name
    })
  }

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' }
  ]

  const isSystem = !resource.workspaceId

  const actions = isReadOnly || isSystem
    ? [ { icon: 'pad-lock', title: 'Read only' } ]
    : [
      {
        icon: 'edit',
        title: 'Edit',
        onClick: onEdit
      },
      {
        icon: 'trash',
        title: 'Delete',
        onClick: openConfirmationDialog
      }
    ]

  const EmptyState = () => {
    const loaderTitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return alert?.title || alert?.message
      }

      return DEFAULT_TITLE
    })()

    const loaderSubtitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return (alert?.title && alert?.message) || DEFAULT_ERROR_SUBTITLE
      }

      return DEFAULT_SUBTITLE
    })()

    return (
      <Flex alignItems="center" direction="column" gap={16}>
        <Flex alignItems="center" direction="column" gap={8}>
          <Text fontWeight="bold">{loaderTitle}</Text>
          <Text fontSize={14}>{loaderSubtitle}</Text>
        </Flex>
        {!isReadOnly && <Button label="Create Segment" size="small" mode="subtle" onClick={onAdd} />}
      </Flex>
    )
  }

  return (
    <DataListBlock
      asFragment
      title="Segments"
      actions={actions}
      contents={contents}
      data={matches}
      loading={loading}
      error={error}
      selectionMode="none"
      empty={{
        element: <EmptyState />
      }}
      primaryElements={<SearchBar placeholder="Search segments" onChange={handleChange} css={{ maxWidth: 240 }} />}
      secondaryElements={!isReadOnly && <Button icon="add-thin" size="small" onClick={onAdd} />}
      width={{ md: '75%' }}
    />
  )
}
export default SegmentsList
