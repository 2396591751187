import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import Button from 'components/buttons/Button'
import CreateWorkspaceForm from 'components/forms/CreateWorkspaceForm'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import TextLink from 'components/links/TextLink'
import useClientQuery from 'hooks/useClientQuery'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { ProspectQuery, PROSPECT_QUERY } from 'client/state/prospect'
import { styled } from 'styles/stitches'
import { useRegisterWorkspaceMutation, useWorkspacesListQuery } from 'generated/schema'

const Footer = styled(Flex, {
  width: '100%',
  paddingX: 80,
  paddingY: 24,
  borderTop: '1px solid dark100',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'space-between',
  bottom: 0,
  left: 0
})

function NewWorkspacePage() {
  const [ disallowFormSubmission, setDisallowFormSubmission ] = useState(false)
  const { data: { prospect } } = useClientQuery<ProspectQuery>(PROSPECT_QUERY)
  const { data: { workspacesList = [] } = {} } = useWorkspacesListQuery({
    skip: !prospect.token
  })
  const [ registerWorkspace ] = useRegisterWorkspaceMutation({
    onCompleted: (data) => {
      const { invitationUrl } = data.registerWorkspace

      // Disallow form submission while we are redirecting
      setDisallowFormSubmission(true)

      window.location.replace(invitationUrl)
    }
  })
  const handleOnSubmit = useSubmitHandler(registerWorkspace)

  if (!prospect.id) {
    return <Redirect to="/start" />
  }

  return (
    <Flex direction="column" gap={16}>
      <ExternalTitle>Create Workspace</ExternalTitle>
      <Flex direction="column" gap={48}>
        <CreateWorkspaceForm
          formProps={{ onSubmit: handleOnSubmit }}
          disabled={disallowFormSubmission}
        />
      </Flex>
      <Footer>
        <TextLink fontSize={14} to="/start">Not {prospect.email}?</TextLink>
        {workspacesList.length > 0 && <Button size="small" label="My Workspaces" to="/workspaces" mode="distinct" variant="outline" />}
      </Footer>
    </Flex>
  )
}

export default NewWorkspacePage
