import React from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import PaymentMethod, { Brands } from 'models/PaymentMethod'
import Text from 'components/typography/Text'
import UpdatePaymentMethodView from 'components/views/UpdatePaymentMethodView'
import { css, styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'

const CREDIT_CARD_WIDTH = 300
const CREDIT_CARD_HEIGHT = 170
const CREDIT_CARD_BORDER_RADIUS = 6

const CREDIT_CARD_SHADOW = '0px 4px 6px 0px rgba(170, 173, 188, 0.25)'
const CREDIT_CARD_SHADOW_HOVER = '0px 13px 24px 0px rgba(170, 173, 188, 0.41)'

interface Props {
  id: string,
  index: number,
  brand: Brands,
  name?: string,
  expiryMonth: number,
  expiryYear: number,
  last4: string,
  children?: React.ReactNode
}

const CardWrapper = styled(Flex, {
  display: 'inline-block',
  perspective: '1000px',

  '>> [data-text]': {
    margin: 0
  }
})

const CardContainer = styled(Flex, {
  ...mixins.transition('slow'),

  width: CREDIT_CARD_WIDTH,
  height: CREDIT_CARD_HEIGHT,
  borderRadius: CREDIT_CARD_BORDER_RADIUS,

  position: 'relative',
  transformStyle: 'preserve-3d',

  '&': {
    '&:hover': {
      '& [data-edit-expiry]': {
        opacity: 1
      }
    },
    '& [data-edit-expiry]': {
      ...mixins.transition('fluid'),
      margin: '0 -6px -6px 0',
      opacity: 0
    }
  },

  variants: {
    flipped: {
      true: {
        transform: 'rotateY(180deg)'
      }
    }
  }
})

const StyledCreditCard = styled(Flex, {
  ...mixins.transition('simple'),

  borderRadius: CREDIT_CARD_BORDER_RADIUS,
  boxShadow: CREDIT_CARD_SHADOW,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
  transform: 'translateZ(0)', // Force hardware acceleration to prevent a Firefox bug with SVGs

  ':hover': {
    boxShadow: CREDIT_CARD_SHADOW_HOVER
  },

  variants: {
    side: {
      front: {
        backgroundImage: 'linear-gradient(45deg, #0f509e, #1399cd)',
        padding: 20,
        transform: 'rotateY(0)', // This is needed to tell the browser that it is the front side.

        '.number': {
          ...mixins.transition('simple'),

          fontSize: 14,
          fontWeight: 'normal',
          lineHeight: 1.2,
          color: 'dark100',
          letterSpacing: 2,
          wordSpacing: 15
        },
        '.header': {
          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: 1.2,
          color: 'dark100',
          marginBottom: 15,
          opacity: 0.5
        },
        '.details': {
          ...mixins.transition('simple'),

          fontSize: 14,
          fontWeight: 'normal',
          letterSpacing: 0,
          lineHeight: 1.2,
          color: 'dark100'
        },

        '.detailsHeader': {
          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: 1.2,
          color: 'dark100',
          marginBottom: 15,
          opacity: 0.5
        },

        '.footer': {
          display: 'flex'
        },

        '.footerLeft': {
          flex: '0 0 70%',
          marginRight: 10,
          minWidth: 0, // Flex items default`min-width` and `max-width` to auto

          '> [data-text]': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        }
      },
      back: {
        backgroundImage: 'linear-gradient(45deg, #0f509e, #1399cd)',
        transform: 'rotateY(180deg)',

        '.cvc': {
          ...mixins.transition('simple'),

          fontSize: 12,
          fontWeight: 'normal',
          lineHeight: 1.2,
          background: '#fff',
          padding: 5,
          position: 'absolute',
          top: 80,
          right: 30
        },

        '.backStrip': {
          ...mixins.size('100%', 40),

          background: '#000',
          position: 'absolute',
          top: 30
        }
      }
    }
  }
})

const CreditCardBackground = styled('div', {
  ...mixins.size('150%', '250%'),

  pointerEvents: 'none',
  position: 'absolute',
  top: '-90%',
  left: '-20%',
  transform: 'rotate(25deg)',
  zIndex: -1,

  variants: {
    hasBrand: {
      true: {
        left: '-20%'
      }
    }
  }
})

const CreditCardLogo = styled('div', {
  ...mixins.size(50, 35),
  ...mixins.transition('simple'),

  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain'
})

const classes = {
  cardLogo: (brand: Brands) => css({
    background: `url(${PaymentMethod.getLogo(brand as Brands)})`
  })
}

const CreditCard: React.FC<Props> = ({
  id,
  index,
  brand = Brands.UNKNOWN,
  name,
  expiryMonth,
  expiryYear,
  last4,
  children
}) => {
  const { openView } = useViewDispatch()

  return (
    <CardWrapper>
      <CardContainer>
        <StyledCreditCard side="front">
          <CreditCardBackground className={css(PaymentMethod.getBrandColor(brand, index))} />
          <CreditCardLogo className={classes.cardLogo(brand)} />
          <Text className="number">
            {PaymentMethod.getFormattedNumber(last4)}
          </Text>
          <div className="footer">
            <div className="footerLeft">
              <Text className="detailsHeader">CARD HOLDER</Text>
              <Text className="details">
                {PaymentMethod.getFormattedName(name)}
              </Text>
            </div>
            <Flex alignItems="flex-end" gap={8}>
              <div>
                <Text className="detailsHeader">EXPIRES</Text>
                <Text className="details">
                  {PaymentMethod.getFormattedExpiry(expiryYear, expiryMonth)}
                </Text>
              </div>
              <div
                data-edit-expiry
              >
                <IconButton
                  name="edit"
                  variant="light"
                  description="Edit"
                  onClick={() => {
                    openView({
                      title: 'Edit Payment Method',
                      component: UpdatePaymentMethodView,
                      params: { id, last4, expiryMonth, expiryYear },
                      style: 'DIALOG'
                    })
                  }}
                  hideTooltip
                />
              </div>
            </Flex>
          </div>
          {children}
          {/* <div className="backStrip" />
          <Text className="cvc">
            {PaymentMethod.getFormattedCVC()}
          </Text> */}
        </StyledCreditCard>
      </CardContainer>
    </CardWrapper>
  )
}

export default CreditCard
