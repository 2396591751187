import React from 'react'
import group from 'lodash/groupBy'
import { useRecoilValue } from 'recoil'

import Account from 'models/Account'
import AccountAvatar from 'components/avatars/AccountAvatar'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Kind } from 'models/Role'
import { App, RoleMembership, RoleMembershipsListDocument, useDestroyRoleMembershipMutation, useEnvironmentsListQuery, useRoleMembershipsListQuery, useRolesListQuery } from 'generated/schema'
import { Views } from '../constants'

type Params = {
  app?: App
}

const MembersView = () => {
  const confirm = useConfirmation({ style: 'DIALOG' })
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { id } = app!

  const {
    data: { rolesList = [] } = {}
  } = useRolesListQuery({
    variables: {
      filter: {
        appId: { eq: id }
      }
    },
    skip: !id
  })

  const queryVariables = {
    filter: {
      roleId: { in: rolesList.map((role) => role.id) }
    }
  }

  const {
    data: { roleMembershipsList = [] } = {},
    loading,
    error
  } = useRoleMembershipsListQuery({
    variables: queryVariables,
    skip: rolesList.length === 0
  })

  const { data: { environmentsList = [] } = {} } = useEnvironmentsListQuery()

  const groupedList = group(roleMembershipsList, 'accountId')

  const [ deleteRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      { query: RoleMembershipsListDocument }
    ]
  })

  const handleDeleteRoleMembership = useSubmitHandler(deleteRoleMembership, {
    update: {
      strategy: 'REMOVE',
      query: RoleMembershipsListDocument,
      queryVariables,
      dataKey: 'roleMembershipsList',
      mutation: 'destroyRoleMembership'
    }
  })

  const onDelete = (roleMemberships: RoleMembership[]) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        roleMemberships.map((roleMembership) => handleDeleteRoleMembership({
          id: roleMembership.id
        }))
      },
      recordType: 'Role Membership',
      recordDescription: roleMemberships[0].account?.name
    })
  }

  const handleAddNewMember = () => openDashboardEditorView({
    target: Views.ADD_MEMBER,
    params: {
      app,
      initialValues: {
        kind: Kind.APP,
        mode: 'account'
      }
    }
  })

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Members
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={handleAddNewMember}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={roleMembershipsList}
          loading={loading}
          error={error}
        >
          <Flex direction="column" gap={2}>
            {Object.entries(groupedList)?.map(([ key, roles ]) => {
              const { account } = roles[0]
              if (!account) return null

              const { firstName, lastName, name = '' } = account

              const getEnvironmentText = () => {
                if (environmentsList.length === roles.length) return 'ALL ENVIRONMENTS'
                return roles.map((r) => r.environment?.name.toUpperCase()).join(', ')
              }

              const onClick = () => {}

              return (
                <MediaCard
                  compact
                  key={key}
                  media={(
                    <AccountAvatar variant="small" account={account} />
                  )}
                  title={Account.getFullName({ firstName, lastName }) || name}
                  text={getEnvironmentText()}
                  titlePosition="top"
                  height={64}
                  width="full"
                  onClick={onClick}
                  withIcon={false}
                  actions={[ {
                    description: 'Delete Role',
                    icon: 'trash',
                    onClick: () => onDelete(roles as RoleMembership[]),
                    variant: 'negative'
                  } ]}
                />
              )
            })}
          </Flex>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

export default MembersView
