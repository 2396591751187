import React, { useEffect } from 'react'
import { useDroppable } from '@dnd-kit/core'

import Block, { BlockProps } from './Block'
import Tabs, { useTabs } from 'components/tabs/Tabs'
import Tab from 'components/tabs/Tab'
import Flex from 'components/layout/Flex'
import useDashboard from 'hooks/useDashboard'
import { AddBlockButton } from 'components/pages/DashboardPage'
import { Column } from './ColumnsBlock'
import { css } from 'styles/stitches'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'

type TabType = {
  title: string,
  children: any[]
}

type TabsBlockProps = BlockProps & {
  variant: 'fixed' | 'fluid',
  tabs: TabType[]
}

const tabLabelHovered = css({
  border: '2px dashed dark700'
})

const tabLabel = css({
  border: '2px solid transparent'
})

const wrapperClass = (variant: 'fixed' | 'fluid') => css({
  position: variant === 'fixed' ? 'sticky' : undefined,
  top: 40,
  backgroundColor: 'light100',
  zIndex: 'above'
})

const TabComponent = ({ id, tab, index }: {id: string, tab: TabType, index: number}) => {
  const { setActiveIndex } = useTabs()
  const { setNodeRef, isOver } = useDroppable({
    id: `blockId:${id}::${index}`
  })

  useEffect(() => {
    if (isOver) {
      setActiveIndex(index)
    }
  }, [ index, isOver, setActiveIndex ])

  return (
    <Tab
      key={index}
      index={index}
      label={tab.title}
      tabLabelClassName={isOver ? tabLabelHovered : tabLabel}
      tabButtonRef={setNodeRef}
    >
      <Column
        id={`${id}::${index}`}
        items={tab.children}
        css={{
          width: '100%'
        }}
      />
    </Tab>
  )
}

function TabsBlock(
  { blockRef, tabs = [], variant, ...other }: TabsBlockProps
) {
  const { updateBlock } = useDashboard()
  const { activeUrn } = useDashboardViewContext()
  useEffect(() => {
    tabs?.map((tab) => (
      tab.children?.map((block: any) => (
        updateBlock(activeUrn, block)
      ))
    ))
  }, [ updateBlock, tabs, activeUrn ])

  return (
    <Block masonryItemRef={blockRef} direction="column" {...other}>
      <Flex
        direction="column"
        css={{
          border: '1px solid dark300'
        }}
      >
        {tabs.length
          ? (
            <Tabs wrapperClassName={wrapperClass(variant)}>
              {tabs.map((tab, index) => (
                <TabComponent
                // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  id={other.id}
                  tab={tab}
                  index={index}
                />
              ))}
            </Tabs>
          )
          : <AddBlockButton label="Add Tab" id={`blockId:${other.id}::0`} />}
      </Flex>
    </Block>
  )
}

export default TabsBlock
