import classNames from 'classnames'
import React from 'react'
import type { ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import { css, styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'
import type { Space } from 'styles/theme'

const TOP_MENU_ITEM_SPACING_X: Space = 24
const TOP_MENU_ITEM_SPACING_Y: Space = 14

const StyledTopbarMenuItem = styled(Flex, {
  backgroundColor: 'light100',
  color: 'dark900',
  fontFamily: 'normal',
  fontSize: 14,
  fontWeight: 'regular',
  width: '100%',

  '&:hover': {
    willChange: 'transform'
  },

  '& [data-icon]': {
    color: 'dark100'
  },

  variants: {
    spacing: {
      none: {},
      normal: {
        paddingY: TOP_MENU_ITEM_SPACING_Y,
        paddingX: TOP_MENU_ITEM_SPACING_X
      }
    }
  }
})

const highlightedStyle = {
  '&&': {
    color: 'primary400'
  }
}

const classes = {
  menuItem_interactive: css({
    ...mixins.transition('simple'),

    cursor: 'pointer',

    '&:hover': highlightedStyle
  }),
  menuItem_highlighted: css(highlightedStyle),
  menuItem_active: css(highlightedStyle, {
    fontWeight: 'bold'
  })
}

type ChildrenRenderProps = {
  menuItemClassNames: typeof classes
}

type ChildrenFunction = (props: ChildrenRenderProps) => ReactNode

type TopbarMenuItemProps = FlexProps & {
  children?: ReactNode | ChildrenFunction,
  className?: string,
  isActive?: boolean,
  isDragging?: boolean,
  isInteractive?: boolean,
  isHighlighted?: boolean,
  spacing?: 'normal' | 'none'
}

const TopbarMenuItem = React.forwardRef<HTMLDivElement, TopbarMenuItemProps>(({
  children, className, isActive, isDragging, isHighlighted, isInteractive = true, spacing = 'normal', ...props
}, ref) => {
  const linkProps = props.to ? { activeClassName: classes.menuItem_active } : {}

  return (
    <StyledTopbarMenuItem
      alignItems="center"
      className={classNames(
        {
          [classes.menuItem_highlighted]: isHighlighted,
          [classes.menuItem_interactive]: isInteractive,
          [classes.menuItem_active]: isActive
        },
        className
      )}
      gap={10}
      justifyContent="space-between"
      ref={ref}
      spacing={spacing}
      {...linkProps}
      {...props}
    >
      {children}
    </StyledTopbarMenuItem>
  )
})

TopbarMenuItem.displayName = 'TopbarMenuItem'

export default TopbarMenuItem

export type { ChildrenRenderProps as TopbarMenuItemRenderProps }
