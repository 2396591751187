import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

class Installation extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required()
  })
}

export const INTEGRATIONS_LIST_LIMIT = 1000

export default Installation
