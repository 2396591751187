import React from 'react'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import ToggleInput from 'components/inputs/ToggleInput'
import type { Attribute } from 'generated/schema'
import Text from 'components/typography/Text'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { fontWeight = 'regular' } = displaySettings

  return (
    <Text fontSize={14} fontWeight={fontWeight}>{data || '-'}</Text>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}show_country_flag`}
      component={ToggleInput}
      label="Show country flag?"
      type="checkbox"
    />
    <FormField
      name={`${fieldPrefix}use_e164_format`}
      component={ToggleInput}
      label="Use international format (E164)?"
      type="checkbox"
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as PhoneNumberRenderer }
