import React from 'react'
import { useRecoilValue } from 'recoil'

import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { App, CustomRole, RolesListDocument, useDestroyRoleMutation, useRolesListQuery } from 'generated/schema'
import { Kind } from 'models/Role'
import { Views } from 'components/dashboardEditor/constants'

type Params = {
  app?: App
}

const RolesView = () => {
  const confirm = useConfirmation({ style: 'DIALOG' })
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { id } = app!

  const queryVariables = {
    filter: {
      appId: { eq: id }
    }
  }

  const {
    data: { rolesList = [] } = {},
    loading,
    error
  } = useRolesListQuery({
    variables: queryVariables,
    skip: !id
  })

  const [ deleteRole ] = useDestroyRoleMutation({
    refetchQueries: [
      { query: RolesListDocument }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRole, {
    update: {
      strategy: 'REMOVE',
      query: RolesListDocument,
      queryVariables,
      dataKey: 'rolesList',
      mutation: 'destroyRole'
    }
  })

  const onDelete = (role: CustomRole) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: role.id }),
      recordType: 'Role',
      recordDescription: role.name
    })
  }

  const handleAddNewRole = () => openDashboardEditorView({
    target: Views.CREATE_ROLE,
    params: {
      app,
      initialValues: {
        kind: Kind.APP
      }
    }
  })

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Roles
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={handleAddNewRole}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={rolesList}
          loading={loading}
          error={error}
        >
          <Flex direction="column" gap={2}>
            {rolesList?.map((role) => (
              <MediaCard
                compact
                key={role.id}
                media="roles"
                title={role.name}
                height={64}
                width="full"
                onClick={() => openDashboardEditorView({
                  target: Views.CREATE_ROLE,
                  params: {
                    app,
                    initialValues: role
                  }
                })}
                withIcon={false}
                actions={[ {
                  description: 'Delete Role',
                  icon: 'trash',
                  onClick: () => onDelete(role),
                  variant: 'negative'
                } ]}
              />
            ))}
          </Flex>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

export default RolesView
