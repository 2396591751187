import React from 'react'

import Flex from 'components/layout/Flex'
import Loader, { LoaderProps } from 'components/loaders/Loader'

const HEIGHT = 250

function Wrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        height: HEIGHT,
        width: '100%',
        padding: '16px 16px 38px 16px',
        border: '1px solid dark100',
        borderRadius: 6
      }}
    >
      {children}
    </Flex>
  )
}

function SectionLoader(props: LoaderProps) {
  return (
    <Loader
      wrapper={Wrapper}
      {...props}
    />
  )
}

export default SectionLoader
