import React, { useState } from 'react'

import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import PageLoader from 'components/loaders/PageLoader'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import useActiveLocales from 'hooks/useActiveLocales'
import useRecordPublishing, { PublishingAction } from 'hooks/useRecordPublishing'
import useSubmitHandler from 'hooks/useSubmitHandler'
import VersionDiff from 'components/contentVersion/VersionDiff'
import VersionHeader from 'components/contentVersion/VersionHeader'
import { ATTRIBUTES_LIST_LIMIT } from 'models/Resource'
import { contentVersionStatusVariants, ContentVersionStatus, ContentVersion } from 'models/Content'
import { RecordVersionsListDocument, Resource, useAttributesListQuery, useRestoreRecordVersionMutation } from 'generated/schema'
import { styled } from 'styles/stitches'
import type { ViewProps } from 'components/views'

const PUBLISHING_ELEMENTS_WRAPPER_MARGIN = 30

const PublishingElementsWrapper = styled(Flex, {
  marginLeft: PUBLISHING_ELEMENTS_WRAPPER_MARGIN
})

type Params = {
  resource: Resource,
  currentVersion: ContentVersion,
  versions: ContentVersion[],
  targetEnvironment?: string,
  onRestoreVersion: () => void
}

function RecordVersionView({
  closeView,
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const {
    resource,
    currentVersion: initialVersion,
    versions,
    targetEnvironment,
    onRestoreVersion
  } = params
  const [ currentVersionId, setCurrentVersionId ] = useState<string | undefined>(initialVersion.id)
  const currentVersionIndex = versions.findIndex((v) => v.id === currentVersionId)
  const currentVersion = versions[currentVersionIndex]
  const [ previousVersionId, setPreviousVersionId ] = useState<string | undefined>(
    () => versions[Math.max(0, currentVersionIndex + 1)]?.id
  )

  const previousVersion = versions.find((v) => v.id === previousVersionId)

  const {
    activeLocales,
    defaultLocale,
    error,
    loading
  } = useActiveLocales()

  const isPublished = currentVersion.status === ContentVersionStatus.PUBLISHED

  const isCurrentVersion = (
    // publishing workflow enabled
    currentVersion.status === ContentVersionStatus.CURRENT_DRAFT
    // publishing workflow disabled
    || currentVersion.status === ContentVersionStatus.CURRENT
    // published && latest version
    || (currentVersion.status === ContentVersionStatus.PUBLISHED && currentVersion.index === 0)
  )

  const [ handlePublishing ] = useRecordPublishing()

  const [ restoreRecordVersion ] = useRestoreRecordVersionMutation({
    onCompleted: () => {
      onRequestClose()
      onRestoreVersion()
    },
    refetchQueries: [ RecordVersionsListDocument ]
  })

  const handleRestoreRecordVersion = useSubmitHandler(restoreRecordVersion, {
    successAlert: {
      message: `Successfully restored to ${currentVersion?.name}`
    }
  })

  const onRestoreRecord = (id: string) => handleRestoreRecordVersion({ id, targetEnvironment })

  const onPublishingClick = () => (
    handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      id: currentVersionId
    })
  )

  const publishingElements = (() => {
    if (resource.isPublishingEnabled) {
      return (
        <Flex alignItems="center" gap={20} grow={1}>
          <Chip
            label={currentVersion.status}
            variant={contentVersionStatusVariants[currentVersion.status]}
          />
          {isPublished && (
            <Button
              label={isPublished ? 'Unpublish' : 'Publish'}
              variant={isPublished ? 'simple' : 'filled'}
              size="small"
              onClick={() => onPublishingClick()}
            />
          )}
          <Flex grow={1} />
          <Button
            label="Restore as current draft"
            size="small"
            mode="subtle"
            disabled={isCurrentVersion}
            onClick={() => onRestoreRecord(currentVersion.id)}
          />
        </Flex>
      )
    }

    return (
      <Flex alignItems="center" justifyContent="space-between" grow={1}>
        <Chip
          label={currentVersion.status}
          variant={contentVersionStatusVariants[currentVersion.status]}
        />

        <Button
          label="Restore"
          size="small"
          disabled={isCurrentVersion}
          onClick={() => onRestoreRecord(currentVersion.id)}
        />
      </Flex>
    )
  })()

  const { data, loading: attributesLoading, error: attributesError } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: resource.id
        }
      },
      order: [
        {
          position: 'asc'
        }
      ],
      limit: ATTRIBUTES_LIST_LIMIT
    }
  })

  return (
    <View
      contentLabel="Version History"
      onRequestClose={onRequestClose}
      {...other}
    >
      {({ Body, Header, SubHeader }) => (
        <>
          <Header
            onCloseClick={onRequestClose}
            title={currentVersion.name}
            wide
            tall
          >
            <PublishingElementsWrapper grow={1} alignItems="stretch" justifyContent="space-between">
              {publishingElements}
            </PublishingElementsWrapper>
          </Header>
          <SubHeader wide>
            <VersionHeader
              {...{
                versions,
                currentVersion,
                previousVersion,
                setCurrentVersionId,
                setPreviousVersionId
              }}
            />
          </SubHeader>
          <Body wide>
            <PageLoader
              loading={loading || attributesLoading}
              error={error || attributesError}
              data={activeLocales && data}
            >
              {activeLocales.length > 1 ? (
                <Tabs>
                  {activeLocales.map((activeLocale, index) => (
                    <Tab key={activeLocale.identifier} label={activeLocale.name} index={index}>
                      <VersionDiff
                        activeLocale={activeLocale.identifier}
                        defaultLocale={defaultLocale?.identifier!}
                        currentVersion={currentVersion}
                        previousVersion={previousVersion}
                        fieldsList={data?.attributesList! as any[]}
                      />
                    </Tab>
                  ))}
                </Tabs>
              ) : (
                <VersionDiff
                  activeLocale={activeLocales[0]?.identifier || defaultLocale?.identifier!}
                  defaultLocale={defaultLocale?.identifier!}
                  currentVersion={currentVersion}
                  previousVersion={previousVersion}
                  fieldsList={data?.attributesList! as any[]}
                />
              )}
            </PageLoader>
          </Body>

        </>
      )}
    </View>
  )
}

export default RecordVersionView
