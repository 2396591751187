import React from 'react'
import get from 'lodash/get'

import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import { css } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

type BooleanRendererProps<T> = RendererOptions<T> & { inverted?: boolean }

const classes = {
  container: css({
    '[data-icon]': {
      color: 'dark800'
    }
  })
}

function BooleanRenderer<T>({
  dataKey,
  rowData,
  prefix = '',
  suffix = '',
  inverted = false
}: BooleanRendererProps<T>) {
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const bool = get(rowData, `${prefixContent}${dataKey}${suffixContent}`) === true
  const isTrue = inverted ? !bool : bool

  return (
    <Flex className={classes.container}>
      <Icon
        data-icon
        name={isTrue ? 'accept' : 'cross-circle'}
      />
    </Flex>
  )
}

export default BooleanRenderer
