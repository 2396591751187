import React from 'react'
import omit from 'lodash/omit'
import { Field, Form, FormProps } from 'react-final-form'
import type { Decorator } from 'final-form'
import type { ElementType } from 'react'

import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import CurrencyField from 'components/fields/CurrencyField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Installation from 'models/Installation'
import JsonField from 'components/contentEditors/generic/fields/JsonField'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import ToggleInput from 'components/inputs/ToggleInput'
import { ConfigurationField } from 'components/views/AddConfigurationView'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import {
  CheckboxField,
  ColorField,
  DateTimeField,
  DropdownField,
  DurationField,
  EmailField,
  MediaField,
  NumberField,
  PasswordField,
  RadioField,
  SwitchField,
  TextField
} from 'components/contentEditors/generic/fields'
import { FieldIdentifier } from 'models/Field'
import { useEnvironmentsListQuery } from 'generated/schema'
import type { DashboardsListQuery, App as AppType, CreateInstallationInput, UpdateInstallationInput } from 'generated/schema'
import type { ViewStyleComponentRenderProps } from 'components/views'

type InstallAppFormProps = FormProps<CreateInstallationInput | UpdateInstallationInput> & {
  selectedApp: AppType,
  viewComponents: ViewStyleComponentRenderProps,
  currentDashboard?: DashboardsListQuery['dashboardsList'][number]
}

const IDENTIFIER_TO_FIELD_MAP: Partial<Record<FieldIdentifier, ElementType>> = {
  [FieldIdentifier.CHECKBOX]: CheckboxField,
  [FieldIdentifier.COLOR]: ColorField,
  [FieldIdentifier.CURRENCY]: CurrencyField,
  [FieldIdentifier.DATE]: DateTimeField,
  [FieldIdentifier.DURATION]: DurationField,
  [FieldIdentifier.DROPDOWN]: DropdownField,
  [FieldIdentifier.EMAIL]: EmailField,
  [FieldIdentifier.JSON]: JsonField,
  [FieldIdentifier.FILE]: MediaField,
  [FieldIdentifier.NUMBER]: NumberField,
  [FieldIdentifier.PASSWORD]: PasswordField,
  [FieldIdentifier.RADIO]: RadioField,
  [FieldIdentifier.SWITCH]: SwitchField,
  [FieldIdentifier.TEXT]: TextField
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<UpdateInstallationInput | CreateInstallationInput>

function InstallAppForm({
  selectedApp, viewComponents, currentDashboard, ...others
}: InstallAppFormProps) {
  const { initialValues, onSubmit } = others
  const { Body, Footer } = viewComponents
  const isUpdating = Boolean(initialValues && 'id' in initialValues && initialValues.id)
  const { data } = useEnvironmentsListQuery()

  const validate = (formValues: CreateInstallationInput | UpdateInstallationInput) => {
    const staticUpdationFields: (keyof UpdateInstallationInput)[] = [ 'name', 'identifier' ]
    const staticCreationFields: (keyof CreateInstallationInput)[] = [
      'identifier',
      'name'
    ]

    if ('id' in formValues) {
      return Installation.validate(
        formValues,
        staticUpdationFields
      )
    }

    return Installation.validate(
      formValues,
      staticCreationFields
    )
  }

  return (
    <Form
      decorators={[
        setIdentifier
      ]}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, submitting, pristine }) => (
        <>
          <Body>
            <form onSubmit={handleSubmit}>
              <Flex direction="column" gap={28}>
                <Flex direction="column" gap={28}>
                  <Text fontSize={18} fontWeight="bold">Details</Text>
                  <Flex direction="column" gap={20}>
                    <Flex direction="column" gap={14}>
                      <FormField alwaysDirty={!isUpdating} type="hidden" component="input" name="appId" />
                      <Flex gap={36}>
                        <Field
                          autoFocus
                          component={TextInput}
                          label="Name"
                          name="name"
                          size="small"
                          type="text"
                        />
                        <Field
                          component={TextInput}
                          label="Identifier"
                          name="identifier"
                          size="small"
                          type="text"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                {!isUpdating && (
                  <Flex direction="column" gap={28}>
                    <Text fontSize={18} fontWeight="bold">Environments</Text>
                    <Flex direction="column" gap={20}>
                      {data?.environmentsList?.map((e) => (
                        <>
                          <FormField
                            name={`environments.${e.id}`}
                            component={ToggleInput}
                            label={e.name}
                            labelPosition="left"
                            type="checkbox"
                          />
                          {selectedApp.fields.length !== 0 && (
                            <ConditionalField when={`environments.${e.id}`} is>
                              {selectedApp.fields.map(
                                (field) => <ConfigurationField key={field.name} field={field} prefix={`settings.${e.id}`} />
                              )}
                            </ConditionalField>
                          )}
                        </>
                      ))}
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <input type="submit" style={{ display: 'none' }} />
            </form>
          </Body>
          <Footer>
            <Flex justifyContent="flex-end">
              <Button onClick={handleSubmit} disabled={pristine || submitting} label="Submit" />
            </Flex>
          </Footer>
        </>
      )}
      {...others}
      onSubmit={(values: any, form) => {
        if (isUpdating) {
          return onSubmit(values, form)
        }

        const parsedConfigurations = Object.keys(values?.environments || {})
          .filter((e: any) => values.environments[e] === true)
          .map((e: any) => ({
            environmentId: e,
            settings: values?.settings?.[e] || {}
          }))

        const parsedFormValues = omit(values, 'environments', 'settings')

        return onSubmit({
          ...parsedFormValues,
          configurations: parsedConfigurations
        } as any, form)
      }}
    />
  )
}

export { IDENTIFIER_TO_FIELD_MAP }

export default InstallAppForm
