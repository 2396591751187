import React from 'react'
import type { Ref } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink, { BaseLinkProps } from 'components/links/BaseLink'
import Box, { BoxProps } from 'components/layout/Box'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { FontSize } from 'styles/theme'

type BackLinkElement = typeof BaseLink | 'button'

const DEFAULT_ELEMENT: BackLinkElement = 'button'

type BackLinkProps = BoxProps & BaseLinkProps & {
    label?: string,
    labelSize?: FontSize
  }

const StyledBackLink = styled(Box, {
  padding: 0,

  '& [data-link-icon], & [data-link-label]': {
    ...mixins.transition('simple'),

    color: 'dark500'
  },

  '&:hover': {
    '& [data-link-icon], & [data-link-label]': {
      color: 'dark900'
    }
  }
})

const BackLink = React.forwardRef(({
  label = 'Back',
  labelSize = 12,
  ...other
}: BackLinkProps,
innerRef: Ref<any>) => {
  const Element = ('href' in other || 'to' in other) ? BaseLink : DEFAULT_ELEMENT

  return (
    <StyledBackLink
      as={Element}
      ref={innerRef}
      {...other}
    >
      <Flex alignItems="center" gap={10}>
        <Icon data-link-label name="arrow-left" size={10} />
        <Text
          as="span"
          data-link-label
          fontSize={labelSize}
          textTransform="uppercase"
        >
          {label}
        </Text>
      </Flex>
    </StyledBackLink>
  )
})

BackLink.displayName = 'BackLink'

export default BackLink
