import dayjs from 'dayjs'
import React, { Fragment } from 'react'
import { Form } from 'react-final-form'
import type { FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import HintBox from 'components/hints/HintBox'
import Text from 'components/typography/Text'
import useConfirmation from 'hooks/useConfirmation'
import type { ConfirmationViewProps } from 'components/views/ConfirmationView'
import type { SessionsListQuery } from 'generated/schema'

const DATE_TIME_FORMAT = 'MM-DD-YYYY HH:mm:ss'

type AccountSignOutAllOtherFormProps = FormProps<any> & {
  sessions: SessionsListQuery['sessionsList']
}

export default function AccountSignOutAllOtherForm(
  { sessions, onSubmit, ...others }: AccountSignOutAllOtherFormProps
) {
  const confirm = useConfirmation()

  const handleOnSubmit = () => confirm({
    action: 'sign out',
    isPlural: true,
    onConfirmClick: onSubmit as ConfirmationViewProps['onConfirmClick'],
    recordType: 'session'
  })

  const hasOtherActiveSessions = sessions.length > 1

  return (
    <Form
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {hasOtherActiveSessions ? (
            <Flex direction="column" gap={20}>
              <Flex gap={40}>
                <Flex css={{ flexBasis: 300 }}>
                  <Text fontSize={12} textTransform="uppercase">Last Active At</Text>
                </Flex>
                <Flex css={{ flexBasis: 300 }}>
                  <Text fontSize={12} textTransform="uppercase">Expires At</Text>
                </Flex>
              </Flex>
              {sessions.map((session, index) => (
                <Fragment key={session.id}>
                  {index === 0 && <Divider />}
                  <Flex gap={40}>
                    <Flex css={{ flexBasis: 300 }}>
                      <Text fontWeight="bold" fontSize={14}>{dayjs(session.lastActiveAt).format(DATE_TIME_FORMAT)}</Text>
                    </Flex>
                    <Flex css={{ flexBasis: 300 }}>
                      <Text fontWeight="bold" fontSize={14}>{dayjs(session.expiresAt).format(DATE_TIME_FORMAT)}</Text>
                    </Flex>
                  </Flex>
                  <Divider />
                </Fragment>
              ))}
              <Flex alignItems="flex-end" direction="column" gap={14}>
                <Button
                  type="submit"
                  label="Sign Out of All Other Sessions"
                  disabled={submitting}
                />
                <Text fontSize={12}>You will continue to be signed into this device</Text>
              </Flex>
            </Flex>
          ) : (
            <HintBox>
              You don&apos;t have any other active sessions.
            </HintBox>
          )}
        </form>
      )}
      {...others}
    />
  )
}
