const whiteList = [
  'settings',
  'sms'
]

function isCountable(word: string) {
  if (!word) return false

  return !whiteList.includes(word.toLowerCase())
}

export default isCountable

export { whiteList }
