import React, { useContext } from 'react'
import type { PropsWithChildren } from 'react'

import * as mixins from 'styles/mixins'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import { SidePaneStateContext } from 'components/sidePane/SidePane'
import { SIDE_PANE_PADDING_X } from './constants'
import { styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'

const SIDE_PANE_FOOTER_GAP = 10
const SIDE_PANE_FOOTER_PADDING_Y = 15
const SIDE_PANE_FOOTER_STICKY_THRESHOLD = 0

const StyledDividerWrapper = styled(Divider, {
  ...mixins.transition('fluid'),
  transitionDelay: '0.3s'
})

const StyledSidePaneFooterWrapper = styled(Flex, {
  ...mixins.transition('slow'),
  paddingX: SIDE_PANE_PADDING_X,
  variants: {
    sticky: {
      true: {
        backgroundColor: 'light100',
        [`& > ${StyledDividerWrapper}`]: {
          marginX: -SIDE_PANE_PADDING_X,
          transitionDelay: '0s'
        }
      }
    },
    variant: {
      small: {},
      normal: {}
    }
  }
})

StyledSidePaneFooterWrapper.compoundVariant({
  variant: 'small',
  sticky: true
}, {
  [`& > ${StyledDividerWrapper}`]: {
    width: 'inherit'
  }
})

const StyledSidePaneFooter = styled(Flex, {
  '&:has(*:not([data-footer]))': {
    paddingY: SIDE_PANE_FOOTER_PADDING_Y
  }
})

type SidePaneFooterProps = PropsWithChildren<{
  isSticky?: boolean,
  variant?: 'normal' | 'small'
}>

function SidePaneFooter({ children, isSticky, variant }: SidePaneFooterProps) {
  const { offsetHeight, scrollHeight, scrollTop } = useContext(SidePaneStateContext)
  const isSticking = (scrollHeight - offsetHeight - scrollTop) > SIDE_PANE_FOOTER_STICKY_THRESHOLD
    || isSticky

  if (!children) return null

  return (
    <StyledSidePaneFooterWrapper
      sticky={isSticking}
      direction="column"
      variant={variant}
    >
      <StyledDividerWrapper>
        <Divider />
      </StyledDividerWrapper>
      <StyledSidePaneFooter
        alignItems="center"
        gap={SIDE_PANE_FOOTER_GAP}
        justifyContent="flex-end"
      >
        {children}
      </StyledSidePaneFooter>
    </StyledSidePaneFooterWrapper>
  )
}

SidePaneFooter.Left = React.forwardRef((props: FlexProps, ref: React.Ref<HTMLDivElement>) => (
  <Flex data-footer="left" gap={SIDE_PANE_FOOTER_GAP} grow={1} ref={ref} {...props} />
))

SidePaneFooter.Right = React.forwardRef((props: FlexProps, ref: React.Ref<HTMLDivElement>) => (
  <Flex data-footer="right" gap={SIDE_PANE_FOOTER_GAP} grow={0} ref={ref} {...props} />
))

export default SidePaneFooter
