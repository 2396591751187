import React from 'react'
import { Form } from 'react-final-form'

import Box from 'components/layout/Box'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import PaymentMethod from 'models/PaymentMethod'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Card, useUpdateWorkspacePaymentMethodMutation } from 'generated/schema'
import { DATE_FORMATS, SAVE_FORMATS } from 'components/contentEditors/generic/fields/fieldProps'
import { DateTimeField } from 'components/contentEditors/generic/fields'
import type { ViewProps } from 'components/views'

type Params = {
  id: string
} & Pick<Card, 'expiryMonth'| 'expiryYear' | 'last4'>

function UpdatePaymentMethodView({
  closeView,
  onRequestClose,
  isOpen,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const title = 'Update Payment Method'
  const { id, expiryMonth, expiryYear, last4 } = params
  const [ updatePaymentMethod, { loading } ] = useUpdateWorkspacePaymentMethodMutation({
    onCompleted: onRequestClose
  })

  const handleUpdatePaymentMethod = useSubmitHandler(updatePaymentMethod, {
    successAlert: {
      title: 'Payment method updated',
      message: 'Your payment method has been updated'
    }
  })

  const handleSubmit = (values: any) => {
    const [ year, month ] = values.expiry.split('-')
    return handleUpdatePaymentMethod({
      id,
      expiryMonth: parseInt(month, 10),
      expiryYear: parseInt(year, 10)
    })
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} isOpen={isOpen} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                id,
                expiry: `${expiryYear}-${expiryMonth.toString().padStart(2, '0')}-01`
              }}
              render={({ handleSubmit }) => (
                <Flex as="form" onSubmit={handleSubmit} gap={16} direction="column">
                  <TextInput disabled label="Card Details" value={PaymentMethod.getFormattedNumber(last4)} />
                  <Flex gap={16}>
                    <DateTimeField autoFocus hidePicker label="Expiry" name="expiry" inputFormat={DATE_FORMATS.MM_YYYY} saveFormat={SAVE_FORMATS.DATE} />
                    <TextInput disabled label="CVC" value={PaymentMethod.getFormattedCVC()} />
                  </Flex>
                  <Box css={{ height: 8 }} />
                  <Flex>
                    <Button type="submit" label="Save Changes" disabled={loading} />
                  </Flex>
                </Flex>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default UpdatePaymentMethodView
