import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator } from 'final-form'

import App, { APP_LIST_LIMIT } from 'models/App'
import Box from 'components/layout/Box'
import Button from 'components/buttons/Button'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import HintBox from 'components/hints/HintBox'
import IconInput from 'components/inputs/IconInput'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { AppListDocument, CreateAppInput, CreateAppMutation, useCreateAppMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type FormValues = CreateAppInput

type Params = ViewParams[Views.CREATE_CUSTOM_APP]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const CreateCustomAppView = ({ onClose }: ActiveViewProps) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const [ createApp ] = useCreateAppMutation({
    onCompleted: async ({ createApp }: CreateAppMutation) => {
      openDashboardEditorView({ target: Views.APP_DETAILS, params: { app: createApp } })
    }
  })

  const handleCreateApp = useSubmitHandler(createApp, {
    update: {
      strategy: 'APPEND',
      query: AppListDocument,
      dataKey: 'appsList',
      mutation: 'createApp',
      queryVariables: {
        limit: APP_LIST_LIMIT,
        order: [ { position: 'asc' } ]
      }
    }
  })

  const handleSubmit = (
    values: FormValues
  ) => handleCreateApp(values as CreateAppInput)

  return (
    <>
      <DashboardEditorHeader
        subtitle="New Custom App"
        heading="Apps"
        onClose={onClose}
      />
      <Form
        decorators={[
          setIdentifier
        ]}
        initialValues={{
          summary: `This is a Custom App for ${currentWorkspace.name} workspace.`,
          ...initialValues as FormValues,
          kind: 'EXTENSION'
        }}
        onSubmit={handleSubmit}
        validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} grow={1}>
                <HintBox size="compact">
                  <Text color="dark900" fontSize={12} fontWeight="bold">Need help?</Text>
                  <Text fontSize={12}>
                    <TextLink href="https://docs.dashx.com">
                      Learn more
                    </TextLink>
                    {' '}about Custom Apps, including best practices and guidelines.
                  </Text>
                </HintBox>
                <Box css={{ height: 24 }} />
                <Flex direction="column" gap={16}>
                  <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
                  <Field
                    checkRequired
                    name="identifier"
                    label="Identifier"
                    component={TextInput}
                    size="small"
                    helpText="Used in code. Avoid modifying this."
                  />
                  <Field
                    component={IconInput}
                    name="icon"
                    label="Icon"
                    placeholder="Choose Icon"
                    size="small"
                    type="text"
                  />
                  <Field
                    name="summary"
                    label="Summary"
                    helpText="Add notes regarding the app's intended use cases. Supports markdown."
                    component={TextAreaInput}
                    size="small"
                  />
                  <input type="submit" style={{ display: 'none' }} />
                </Flex>
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateCustomAppView
