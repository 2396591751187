import React, { useState } from 'react'

import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'
import type { IconProps } from 'components/icons/Icon'
import type { TextProps } from 'components/typography/Text'
import type { IconButtonProps } from 'components/buttons/IconButton'

type SensitiveTextProps = TextProps & {
  children: string,
  iconSize?: IconProps['size'],
  variant?: 'normal' | 'spaced'
}

type TextWidthOptions = {
  fontFamily?: TextProps['fontFamily'],
  fontSize?: TextProps['fontSize'],
  fontWeight?: TextProps['fontWeight']
}

const StyledText = styled(Text, {
  variants: {
    hidden: {
      true: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    }
  }
})

function getTextWidth(text: string, options?: TextWidthOptions) {
  const {
    fontFamily = 'Open Sans',
    fontSize = 14,
    fontWeight = 'regular'
  } = options || {}

  const font = `${fontWeight} ${fontSize}px ${fontFamily}`

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')!

  context.font = font

  return context.measureText(text).width
}

function SensitiveText({
  children, fontSize, fontWeight, iconSize = 12, variant = 'normal', ...rest
}: SensitiveTextProps) {
  const textWidth = getTextWidth(children, { fontWeight, fontSize })
  const textLength = (children || '').length

  const [ isVisible, setIsVisible ] = useState(false)

  const mutedText = Array(textLength).fill(1).map((_, idx) => (
    <span
      // eslint-disable-next-line react/no-array-index-key
      key={idx}
    >
      •
    </span>
  ))

  const flexProps = (() => {
    if (variant === 'spaced') {
      return { grow: 1, justifyContent: 'space-between' }
    }

    return {}
  })() as Partial<FlexProps>

  const toggleVisibleProps = {
    description: isVisible ? 'Hide' : 'Show',
    name: isVisible ? 'show' : 'hide',
    onClick: () => setIsVisible(!isVisible),
    size: iconSize,
    variant: 'dark'
  } as IconButtonProps

  return (
    <Flex alignItems="center" gap={12} style={{ width: 0 }} {...flexProps}>
      <StyledText
        hidden={!isVisible}
        fontSize={fontSize}
        fontWeight={fontWeight}
        style={{ width: `${textWidth}px` }}
        {...rest}
      >
        {isVisible ? children : mutedText}
      </StyledText>
      <IconButton {...toggleVisibleProps} />
    </Flex>
  )
}

export default SensitiveText
