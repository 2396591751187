const useDownloadTextFile = (content: string, filename: string) => {
  const element = document.createElement('a')
  const file = new Blob([ content ], { type: 'text/plain' })

  element.href = URL.createObjectURL(file)
  element.download = filename
  document.body.appendChild(element)

  const downloadTextFile = () => {
    element.click()
  }

  return downloadTextFile
}

export default useDownloadTextFile
