import React, { useState } from 'react'
import type { PropsWithChildren } from 'react'

import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import Card from 'components/card/Card'
import CodeViewer from 'components/dataWidgets/CodeViewer'
import ContentEditor, { Content, Mode } from 'models/ContentEditor'
import Flex from 'components/layout/Flex'
import IFrame from 'components/iframe/IFrame'
import Label from 'components/typography/Label'
import mjmlToHtml from 'lib/mjmlToHtml'
import Text from 'components/typography/Text'
import { PushSubChannel } from 'models/Channel'
import { TemplateSubkind } from 'models/ContentType'
import type { TemplateSubkind as TemplateSubkindType } from 'generated/schema'

interface Props {
  content: Content,
  templateSubkind: TemplateSubkindType,
  isContentRendered?: boolean,
  variant?: 'compact'
}

const noop = () => {}
const input = { onBlur: noop, onFocus: noop }

const Field = ({
  children, description, title
}: PropsWithChildren<{ title: string, description?: string }>) => (
  <Flex direction="column" gap={10} grow={1}>
    <Label>{title}</Label>
    {description && <Text fontSize={14} fontWeight="bold">{description}</Text>}
    {children}
  </Flex>
)

const PigeonContentView: React.FC<Props> = (
  { isContentRendered = false, content, templateSubkind, variant }
) => {
  const [ mode, setMode ] = useState<Mode>(Mode.DESIGN)
  const [ channel, setChannel ] = useState<PushSubChannel>(PushSubChannel.IOS)

  const renderEmailContent = () => {
    const { subject, preview, htmlBody, plainBody, from, replyTo } = content as Content
    return (
      <Flex direction="column" gap={36}>
        <Flex justifyContent="space-between">
          <Field title="From" description={from || '--'} />
          <Field title="Reply To" description={replyTo || '--'} />
        </Flex>
        <Field title="Subject" description={subject || '--'} />
        {preview && <Field title="Preview" description={preview || '--'} />}
        <Flex direction="column" gap={24} grow={1}>
          <Flex direction="column" gap={10} grow={1}>
            <Flex alignItems="center" justifyContent="space-between">
              <Label>Body</Label>
              <ButtonGroupInput
                input={{ ...input, name: 'mode', value: mode, onChange: setMode }}
                inputLabel="Mode"
                meta={{}}
                options={ContentEditor.email.modes.slice(0, variant === 'compact' ? 3 : 2)}
                size="small"
              />
            </Flex>
            <Card height={300} overflow>
              {mode === Mode.DESIGN && (
                <IFrame>
                  {isContentRendered ? htmlBody : mjmlToHtml(htmlBody || '')}
                </IFrame>
              )}
              {mode === Mode.CODE && (
                <CodeViewer language="handlebars" data={htmlBody} />
              )}
              {mode === Mode.PLAIN && variant === 'compact' && (
                <Text fontSize={14} fontWeight="bold">{plainBody}</Text>
              )}
            </Card>
          </Flex>
          {variant !== 'compact' && (
            <Field title="Plain Body">
              <Card height={300} overflow>
                <Text fontSize={14} fontWeight="bold">{plainBody}</Text>
              </Card>
            </Field>
          )}
        </Flex>
      </Flex>
    )
  }

  const renderSmsContent = () => {
    const { title, from, body } = content as Content
    return (
      <Flex direction="column" gap={36}>
        <Field title="Title" description={title || '--'} />
        <Field title="From" description={from || '--'} />
        <Field title="Body">
          <Card height={300} overflow>
            <Text fontSize={14} fontWeight="bold">{body}</Text>
          </Card>
        </Field>
      </Flex>
    )
  }

  const renderPushContent = () => {
    const { title, body, androidSettings, iosSettings, webSettings, data } = content as Content
    const platformContent: Record<PushSubChannel, any> = {
      android: androidSettings,
      ios: iosSettings,
      web: webSettings
    }
    return (
      <Flex direction="column" gap={36}>
        <Flex alignItems="center" justifyContent="flex-end">
          <ButtonGroupInput
            input={{ ...input, name: 'channel', value: channel, onChange: setChannel }}
            inputLabel="Channel"
            meta={{}}
            options={[
              { value: PushSubChannel.IOS, label: 'iOS' },
              { value: PushSubChannel.ANDROID, label: 'Android' },
              { value: PushSubChannel.WEB, label: 'Web' }
            ]}
            size="small"
          />
        </Flex>
        <Field title="Title" description={platformContent[channel]?.title || title || '--'} />
        {channel === PushSubChannel.IOS && <Field title="Subtitle" description={platformContent[channel]?.subtitle || '--'} />}
        <Field title="Body">
          <Card height={300} overflow>
            <Text fontSize={14} fontWeight="bold">{platformContent[channel]?.body || body}</Text>
          </Card>
        </Field>
        <Field title="Data">
          <CodeViewer language="json" data={JSON.stringify(platformContent[channel]?.data || data, null, 2)} />
        </Field>
      </Flex>
    )
  }

  if (templateSubkind === TemplateSubkind.EMAIL) {
    return renderEmailContent()
  }
  if (templateSubkind === TemplateSubkind.SMS) {
    return renderSmsContent()
  }

  if (templateSubkind === TemplateSubkind.PUSH) {
    return renderPushContent()
  }

  const { title, body } = content as Content

  return (
    <Flex direction="column" gap={36}>
      <Field title="Title" description={title || '--'} />
      <Field title="Body" description={body} />
    </Flex>
  )
}

export default PigeonContentView
