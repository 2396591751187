import React from 'react'

import SelectInput, { SelectInputProps } from 'components/inputs/SelectInput'
import { useSearch } from 'hooks/useSearch'
import type { UseSearchProps } from 'hooks/useSearch'

type SearchSelectInputProps<TData, TVariables> = Omit<SelectInputProps<any>, 'input' | 'meta'> & UseSearchProps<TData, TVariables> & {
  dataKey: keyof TData
}

function SearchSelectInput<TData, TVariables>({
  preload,
  query,
  queryOptions,
  dataKey,
  keys,
  handlePageChange,
  handlerRef,
  ...otherSelectProps
}: SearchSelectInputProps<TData, TVariables>) {
  const [ {
    data: preloadData,
    loading,
    error
  }, handleSearch ] = useSearch<TData, TVariables>({ query, queryOptions, keys, handlerRef })

  return (
    <SelectInput
      isLoading={loading}
      hasError={!!error}
      noOptionsMessage={() => 'Type to search records'}
      defaultOptions={preloadData?.[dataKey]}
      loadOptions={((inputValue: string, callback: any) => {
        handleSearch(inputValue, (data) => callback(data[dataKey]))
      })}
      {...otherSelectProps}
    />
  )
}

export default SearchSelectInput
