import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React, { useCallback, useContext, useState } from 'react'
import type { FormProps } from 'react-final-form'

import ThemeForm from 'components/forms/ThemeForm'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { css } from 'styles/stitches'
import { DEFAULT_THEME } from 'lib/generateTheme'
import {
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useUpdateWorkspaceThemeMutation,
  WorkspaceThemesListDocument
} from 'generated/schema'
import type { CreateThemeInput, UpdateThemeInput } from 'generated/schema'
import type { ViewProps } from 'components/views'
import type { WorkspaceTheme } from 'components/forms/WorkspaceThemeForm'

type ThemeFormValues = CreateThemeInput | UpdateThemeInput

type Params = {
  theme: Pick<WorkspaceTheme, 'palette'>,
  currentTheme?: WorkspaceTheme
}

const classes = {
  overlay_sharpened: css({
    '&&': {
      backdropFilter: 'none',
      backgroundColor: 'transparent'
    }
  })
}

function ThemeFormView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { changeThemePalette, currentWorkspace } = useContext(WorkspaceContext)!
  const [ updateWorkspaceTheme ] = useUpdateWorkspaceThemeMutation()

  const handleUpdateWorkspaceTheme = useSubmitHandler(updateWorkspaceTheme, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateWorkspaceTheme',
      typename: 'Workspace'
    }
  })

  const debouncedChangeThemePalette = useCallback(
    (palette) => debounce(changeThemePalette)(palette), [ changeThemePalette ]
  )

  const setTheme = (theme: ThemeFormValues) => {
    debouncedChangeThemePalette(theme.palette!)
  }

  const [ createTheme ] = useCreateThemeMutation({
    onCompleted: (response) => {
      setTheme(response.createTheme)
      onRequestClose()
    }
  })

  const [ updateTheme ] = useUpdateThemeMutation({
    onCompleted: (response) => {
      setTheme(response.updateTheme)
      onRequestClose()
    }
  })

  const handleCreateThemeSubmit = useSubmitHandler(createTheme, {
    optimisticResponse: {
      response: 'CREATE',
      mutation: 'createTheme',
      typename: 'Theme',
      override: () => ({ workspaceId: currentWorkspace.id })
    },
    update: {
      strategy: 'APPEND',
      query: WorkspaceThemesListDocument,
      dataKey: 'themesList',
      mutation: 'createTheme'
    }
  })

  const handleUpdateThemeSubmit = useSubmitHandler(updateTheme, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateTheme',
      typename: 'Theme'
    }
  })

  const handleThemeFormSubmit = (values: ThemeFormValues, form: FormProps<ThemeFormValues>['form']) => {
    if ('id' in values) {
      return handleUpdateThemeSubmit(values, form as FormProps<UpdateThemeInput>['form'])
    }

    return handleCreateThemeSubmit(values as CreateThemeInput, form as FormProps<CreateThemeInput>['form']).then(
      (fetchResult) => {
        if (fetchResult.data) {
          return handleUpdateWorkspaceTheme(
            { id: currentWorkspace.id, themeId: fetchResult.data.createTheme.id }
          )
        }

        return fetchResult
      }
    )
  }

  const title = params.theme && 'id' in params.theme ? 'Update Theme' : 'Create Theme'
  const [ isSharpened, setIsSharpened ] = useState(false)

  const sharpenBackground = () => setIsSharpened(true)
  const blurBackground = () => setIsSharpened(false)

  const resetToSelectedTheme = () => {
    const selectedTheme = params.currentTheme
    debouncedChangeThemePalette(selectedTheme?.palette || DEFAULT_THEME.palette)
  }

  const closeView = () => {
    resetToSelectedTheme()
    onRequestClose()
  }

  return (
    <View
      contentLabel={title}
      onRequestClose={closeView}
      overlayAfterOpenClassName={classNames({ [classes.overlay_sharpened]: isSharpened })}
      {...other}
    >
      {({ Header, Body }) => (
        <>
          <Header
            title={title}
            onCloseClick={closeView}
          />
          <Body>
            <ThemeForm
              initialValues={params.theme as ThemeFormValues}
              onHideColorPicker={blurBackground}
              onShowColorPicker={sharpenBackground}
              onSubmit={handleThemeFormSubmit}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default ThemeFormView
