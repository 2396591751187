import React from 'react'

import type { DashboardsListQuery, EnvironmentsListQuery, InstallationsListQuery } from 'generated/schema'
import type { MenuElementMap, GroupedMenuElementsMap, ComputedMenuElement } from 'lib/generateDashboard'

export type InternalContext = {
  currentDashboard?: DashboardsListQuery['dashboardsList'][number],
  defaultDashboard?: DashboardsListQuery['dashboardsList'][number],

  installationsList?: InstallationsListQuery['installationsList'],
  environmentsList?: EnvironmentsListQuery['environmentsList'],
  refetchContextQueries: () => void,

  menuElements: ComputedMenuElement[],
  idToMenuElementMap: MenuElementMap,
  parentIdToMenuElementsMap: GroupedMenuElementsMap
  // getEnvironmentId: (installationId?: Installation['id']) => string
} | null

export default React.createContext<InternalContext>(null)
