import React, { useState, useEffect } from 'react'
import type { ChangeEvent } from 'react'

import clamp from 'lib/clamp'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import InputGroup from 'components/form/InputGroup'
import PagerIconButton from 'components/dataWidgets/PagerIconButton'
import Select from 'components/select/Select'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import { styled } from 'styles/stitches'
import type { SelectOptionType, SelectOptionsType, SelectProps } from 'components/select/Select'
import type { LoaderProps } from 'components/loaders/Loader'

type PagerProps = LoaderProps & {
  withPageControls?: boolean,
  onChangePage?: (page: number) => void,
  onChangePageSize?: (pageSize: number) => void,
  page?: number,
  pageSize?: number,
  pageSizeOptions?: number[],
  paginationMode?: PaginationMode,
  totalRows?: number
}

// TODO: Implement 'infiniteLoadMore'
type PaginationMode = 'none' | 'finite' | 'infinite'

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE_OPTIONS = [ 10, 20, 30, 50 ]
const PAGE_CONTROLS_WIDTH = 260

const StyledDivider = styled(Divider, {
  background: 'light600',
  height: 40,
  paddingY: 10
})

const StyledPageControls = styled(InputGroup, { width: PAGE_CONTROLS_WIDTH })

function Pager({
  data,
  loading = false,
  error,
  withPageControls = true,
  onChangePage,
  onChangePageSize,
  page = DEFAULT_PAGE,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  paginationMode = 'finite',
  pageSize = pageSizeOptions[0],
  totalRows = pageSizeOptions[0]
}: PagerProps) {
  const [ pageInput, setPageInput ] = useState('')
  const totalPages = Math.ceil(totalRows / pageSize) || 1
  const isFirstPage = page === 1
  const isLastPage = page >= totalPages

  useEffect(() => {
    if (!loading && page > totalPages) {
      onChangePage?.(1)
    }
  }, [ data, loading, onChangePage, page, error, totalPages ])

  if (paginationMode === 'none') {
    return null
  }

  const pageSizeSelectOptions: SelectOptionsType = pageSizeOptions
    .map((value) => ({ label: `Show ${value}`, value }))

  const pageSizeSelectValue = pageSizeSelectOptions
    .find((option) => option.value === pageSize)

  const handleFirstPageButtonClick = () => {
    onChangePage?.(1)
  }

  const handleBackButtonClick = () => {
    onChangePage?.(page - 1)
  }

  const handleNextButtonClick = () => {
    onChangePage?.(page + 1)
  }

  const handleLastPageButtonClick = () => {
    onChangePage?.(totalPages)
  }

  const handleGoToPageInput = (event: ChangeEvent<HTMLInputElement>) => {
    setPageInput(event.target.value)
  }

  const handleGoToPage = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const enteredPage = parseInt(pageInput, 10)
      onChangePage?.(clamp(enteredPage, 1, totalPages))
      setPageInput('')
    }
  }

  const handlePageSizeChange: SelectProps['onChange'] = (option, { action }) => {
    if (action === 'select-option') {
      onChangePageSize?.((option as SelectOptionType).value as number)
    }
  }

  return (
    <Flex alignItems="center" gap={10}>
      <Flex alignItems="center" gap={32}>
        <InputGroup>
          <PagerIconButton
            disabled={isFirstPage}
            icon="first-arrow"
            onClick={handleFirstPageButtonClick}
          />
          <StyledDivider color="dark100" orientation="vertical" />
          <PagerIconButton
            disabled={isFirstPage}
            icon="back-arrow"
            onClick={handleBackButtonClick}
          />
        </InputGroup>

        <Text color="dark900" fontSize={14} lineHeight="cozy">
          <Text as="span" fontWeight="bold">
            {page}
          </Text>
          {' / '}
          {totalPages}
        </Text>

        <InputGroup>
          <PagerIconButton
            disabled={isLastPage}
            icon="next-arrow"
            onClick={handleNextButtonClick}
          />
          <StyledDivider color="dark100" orientation="vertical" />
          <PagerIconButton
            disabled={isLastPage}
            icon="last-arrow"
            onClick={handleLastPageButtonClick}
          />
        </InputGroup>
      </Flex>

      {withPageControls && (
        <Flex alignItems="center">
          <StyledPageControls>
            <TextInput
              max={totalPages}
              min={1}
              onChange={handleGoToPageInput}
              onKeyDown={handleGoToPage}
              placeholder="Go to page..."
              size="small"
              step={1}
              type="number"
              value={pageInput}
              variant="dark"
            />
            <StyledDivider color="dark100" orientation="vertical" />
            <Select
              isSearchable={false}
              onChange={handlePageSizeChange}
              options={pageSizeSelectOptions}
              size="small"
              value={pageSizeSelectValue}
              variant="dark"
            />
          </StyledPageControls>
        </Flex>
      )}
    </Flex>
  )
}

export default Pager

export {
  DEFAULT_PAGE_SIZE_OPTIONS,
  DEFAULT_PAGE
}

export type { PagerProps }
