import React, { useCallback, useState } from 'react'
import startCase from 'lodash/startCase'

import AccountModel, { Kind, SERVICE_ACCOUNTS_STATUS_LABELS_MAP, SERVICE_ACCOUNTS_STATUS_VARIANTS_MAP, Status, STATUS_LABELS_MAP, STATUS_VARIANTS_MAP } from 'models/Account'
import AccountRenderer from 'components/renderers/AccountRenderer'
import AccountView from 'components/views/AccountView'
import AddAccountView from 'components/views/AddAccountView'
import Button from 'components/buttons/Button'
import DataTableBlock from 'components/blocks/DataTableBlock'
import EnvironmentSwitcher from 'components/switcher/EnvironmentSwitcher'
import SearchBar from 'components/searchbar/SearchBar'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TitleBlock from 'components/blocks/TitleBlock'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSwitcherState from 'hooks/useSwitcherState'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { generateStatusRenderer } from 'components/renderers/StatusRenderer'
import { AccountsListDocument, CreateAccountInput } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Account, AccountsListQuery, AccountsListQueryVariables } from 'generated/schema'
import type { Column } from 'components/dataTable/types'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { DashboardViewComponentProps } from 'components/pages/DashboardPage'

type AccountsViewProps = {
  kinds: Kind[],
  appId?: DashboardViewComponentProps['appId']
}

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [ {
  createdAt: 'desc'
} ]

function AccountsView({ kinds, appId }: AccountsViewProps) {
  const [ activeKind, setActiveKind ] = useState(0)
  const [ filters, setFilters ] = useState<FilterType>({})
  const [ order, setOrder ] = useState(DEFAULT_ORDER)
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const { openView } = useViewDispatch()
  const switcherState = useSwitcherState(appId ?? null)
  const environmentId = switcherState.switcher.data?.environment?.id

  const kind = kinds[activeKind]

  const StatusRenderer = generateStatusRenderer<AccountsListQuery['accountsList'][number]>({
    statusMap: kind === Kind.MEMBER
      ? STATUS_LABELS_MAP
      : SERVICE_ACCOUNTS_STATUS_LABELS_MAP,
    statusVariants: kind === Kind.MEMBER
      ? STATUS_VARIANTS_MAP
      : SERVICE_ACCOUNTS_STATUS_VARIANTS_MAP
  })

  const queryVariables = {
    filter: {
      kind: { eq: kind },
      ...filters
    },
    limit: pageSize,
    page,
    order,
    targetEnvironment: environmentId
  }

  const [ {
    data,
    error: accountsListError,
    loading: accountsListLoading
  }, handleChange ] = useSearch<AccountsListQuery, AccountsListQueryVariables>({
    query: AccountsListDocument,
    queryOptions: {
      variables: queryVariables,
      skip: !environmentId
    },
    keys: [ 'name', 'fullName', 'firstName', 'lastName', 'email' ]
  })

  const accountsAggregate = data?.accountsAggregate
  const accountsList = data?.accountsList || []

  const getTitle = useCallback(() => {
    switch (kind) {
      case Kind.MEMBER:
      case Kind.SERVICE:
        return 'Accounts'
      case Kind.USER:
        return 'Users'
      case Kind.VISITOR:
        return 'Visitors'
      default:
        throw new Error(`Unknown Account Kind: ${kind}`)
    }
  }, [ kind ])

  const openAddAccountView = () => openView({
    title: 'New Account',
    component: AddAccountView,
    params: {
      initialValues: {
        environmentId,
        kind
      } as CreateAccountInput,
      kind,
      environmentId,
      queryVariables
    },
    style: 'PANEL'
  })

  const openEditAccountView = (account: Account) => openView({
    title: 'Edit Account',
    component: AddAccountView,
    params: {
      initialValues: account,
      kind,
      queryVariables,
      environmentId: account.environmentId
    },
    style: 'PANEL'
  })

  const getActions = useCallback(() => {
    switch (kind) {
      case Kind.MEMBER:
      case Kind.SERVICE:
        return [
          {
            icon: 'edit',
            title: 'Edit',
            onClick: openEditAccountView
          }
        ]

      default:
        return []
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ kind ])

  const getBatchActions = useCallback(() => {
    switch (kind) {
      case Kind.MEMBER:
        return [
          {
            icon: 'trash',
            title: 'Delete',
            onClick: () => { }
          }
        ]
      default:
        return []
    }
  }, [ kind ])

  const getColumns = useCallback(() => {
    const columns: Column<AccountsListQuery['accountsList'][number]>[] = [
      { dataKey: 'id', title: 'ID', fieldType: 'uid-field', hidden: true, style: { flexGrow: 1 } },
      {
        dataKey: 'name',
        title: 'Name',
        fieldType: 'account-field',
        sortable: true,
        style: { width: 200 },
        renderer: (props) => {
          const LinkRenderer = generateLinkRenderer({
            as: AccountRenderer,
            onClick: () => {
              openView({
                title: AccountModel.getFullName(props.rowData as Account) || props.rowData?.email || 'Account',
                component: AccountView,
                style: AccountView.defaultStyle,
                params: {
                  account: props.rowData as Account,
                  targetEnvironment: environmentId
                }
              })
            }
          })

          return <LinkRenderer {...props} />
        },
        renderCellTitle: ({ rowData }) => AccountModel.getFullName(rowData)
      },
      { dataKey: 'firstName', title: 'First Name', fieldType: 'text-field', hidden: true, sortable: true, style: { flexGrow: 1 } },
      { dataKey: 'lastName', title: 'Last Name', fieldType: 'text-field', hidden: true, sortable: true, style: { flexGrow: 1 } },
      { dataKey: 'email', title: 'Email', fieldType: 'text-field', sortable: true, style: { flexGrow: 1 } },
      { dataKey: 'phone', title: 'Phone', fieldType: 'text-field', hidden: true, sortable: true, style: { flexGrow: 1 } }
    ]

    switch (kind) {
      case Kind.MEMBER:
      case Kind.SERVICE:
        columns.push({
          dataKey: 'status',
          title: 'Status',
          fieldType: 'dropdown-field',
          fieldProps: {
            options: Object.entries(Status).map(([ value, label ]) => ({ value, label }))
          },
          sortable: true,
          style: { width: 150, justifyContent: 'flex-end' },
          renderer: StatusRenderer
        })
        return columns

      case 'USER':
        columns.push({ dataKey: 'uid', title: 'UID', fieldType: 'uid-field', style: { flexGrow: 1 } })
        return columns

      case 'VISITOR':
        columns.push({ dataKey: 'anonymousUid', title: 'Anonymous UID', fieldType: 'uid-field', style: { flexGrow: 1 } })
        return columns

      default:
        return columns
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ kind ])

  const getDataTableSecondaryElements = () => {
    switch (kind) {
      case Kind.MEMBER:
      case Kind.SERVICE:
        return (
          <Button icon="add-thin" onClick={openAddAccountView} size="small" />
        )

      default:
        return null
    }
  }

  return (
    <>
      <TitleBlock
        heading={getTitle()}
        secondaryElements={[ <EnvironmentSwitcher key="env-switcher" appId={appId ?? null} {...switcherState} /> ]}
      />
      <DataTableBlock
        actions={getActions()}
        batchActions={getBatchActions()}
        columns={getColumns()}
        data={accountsList as Account[]}
        defaultOrder={order}
        error={accountsListError}
        loading={accountsListLoading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        primaryElements={[
          kinds.length > 1 && (
            <Tabs onChange={(index) => {
              setActiveKind(index)
              handlePageChange(1)
            }}
            >
              {kinds.map((kind, index) => <Tab key={kind} index={index} label={`${startCase(kind.toLocaleLowerCase())} Accounts`} />)}
            </Tabs>
          ),
          <SearchBar placeholder="Search..." onChange={handleChange} css={{ maxWidth: 240 }} />
        ]}
        secondaryElements={getDataTableSecondaryElements()}
        setOrder={setOrder}
        totalRows={accountsAggregate?.count || 0}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  )
}

export default AccountsView
