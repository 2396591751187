import { useEffect, useState } from 'react'

type FilterColumnsProps = {
  columns: any[]
}

type FilterColumnsResult = [
  any[],
  (index: number, value: boolean) => void
]

function useFilterColumns({
  columns
}: FilterColumnsProps): FilterColumnsResult {
  const [ filters, setFilters ] = useState(() => columns.map((col) => Boolean(col.hidden)))

  useEffect(() => {
    const updatedFilters = columns.map((col) => Boolean(col.hidden))
    setFilters(updatedFilters)
  }, [ columns ])

  const filteredColumns = columns.map(
    (col, i) => ({
      ...col,
      hidden: filters[i]
    })
  )

  const toggleVisibility = (index: number, value: boolean) => setFilters((prev) => (
    prev.slice(0, index)
      .concat([ !value ])
      .concat(prev.slice(index + 1))
  ))

  return [ filteredColumns, toggleVisibility ]
}

export default useFilterColumns
