import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'

const color = () => string().matches(/^#[0-9a-f]{3,6}$/i, 'should be a color')

class Theme extends BaseModel {
  static schema = object({
    name: string().required(),
    palette: object({
      primary: color().required(),
      secondary: color().required(),
      accent: color().required()
    }).required()
  })
}

export default Theme
