import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import type { View } from 'generated/schema'

class ViewModel extends BaseModel {
  static schema = object({
    name: string().required()
  })

  static urn = (view: View): string => {
    let urn = ''
    switch (view.kind) {
      case 'CUSTOM':
        urn = `custom::${view.id}`
        break

      default:
        break
    }

    return urn
  }
}

export default ViewModel
