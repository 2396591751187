import React, { memo, useContext, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import Dialog from 'components/dialog/Dialog'
import GlobalContext from 'components/contexts/GlobalContext'
import Modal from 'components/modal/Modal'
import rgba from 'lib/rgba'
import { colorVars } from 'styles/theme'
import { css } from 'styles/stitches'
import { SidePane } from 'components/sidePane'
import { useViewDispatch, useViewState } from 'hooks/useViewContext'
import type { View } from 'components/providers/ViewProvider'

type ViewRendererProps = {
  index: number,
  item: View
}

const viewComponentMap = {
  DIALOG: Dialog,
  PANEL: SidePane,
  MODAL: Modal
}

const classes = (index: number) => ({
  overlay_sharpened: css({
    '&&&': {
      backdropFilter: index ? 'none' : undefined,
      backgroundColor: rgba(colorVars.light100rgb, 0.5 / (index + 1))
    }
  })
})

const ViewRenderer = memo(({ index, item }: ViewRendererProps) => {
  const [ isOpen, setIsOpen ] = useState(true)
  const { openView, closeView } = useViewDispatch()

  const ViewStyleComponent = viewComponentMap[item.style]
  const Component = item.component

  const { openFailureAlert } = useContext(GlobalContext)!

  return (
    <ErrorBoundary fallbackRender={() => null} onError={() => openFailureAlert({ title: 'Uh oh!', message: 'Something went wrong.' })}>
      <Component
        closeView={closeView}
        isOpen={isOpen}
        onAfterClose={() => closeView(index)}
        onRequestClose={() => setIsOpen(false)}
        openView={openView}
        overlayAfterOpenClassName={classes(index).overlay_sharpened}
        params={item.params || {}}
        viewStyleComponent={ViewStyleComponent}
      />
    </ErrorBoundary>
  )
})

ViewRenderer.displayName = 'ViewRenderer'

function ViewStack() {
  const { viewStack } = useViewState()

  return (
    <>
      {viewStack.map((item, index) => (
        <ViewRenderer index={index} item={item} key={`${item.component.name}-${index + 1}`} />
      ))}
    </>
  )
}

export default ViewStack
