import fuzzysort from 'fuzzysort'
import React, { useContext, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import type { ReactChild } from 'react'

import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import PopoverItem, { POPOVER_ITEM_TEXT_COLORS } from 'components/popover/PopoverItem'
import { styled } from 'styles/stitches'
import type { ComputedMenuElement, MenuElementMap } from 'lib/generateDashboard'
import type { PopoverItemProps } from 'components/popover/PopoverItem'

const JUMP_MENU_ITEM_HEIGHT = 60
const JUMP_MENU_ITEM_HORIZONTAL_PADDING = 36
const JUMP_MENU_ITEM_VERTICAL_PADDING = 18

const JUMP_MENU_ITEM_RENDERERS = {
  emphasis: () => <span data-divider />,
  paragraph: ({ children }: RendererProps) => <>{children}</>,
  root: ({ children }: RendererProps) => <Flex as="span" alignItems="center" data-text>{children}</Flex>,
  strong: ({ children }: RendererProps) => <span data-highlighted>{children}</span>
}

const StyledMenuItem = styled(PopoverItem, {
  height: JUMP_MENU_ITEM_HEIGHT,
  paddingY: JUMP_MENU_ITEM_VERTICAL_PADDING,
  paddingX: JUMP_MENU_ITEM_HORIZONTAL_PADDING,

  '&:hover, &:focus': {
    'i > svg': {
      color: 'dark200'
    },
    '[data-text]': {
      color: POPOVER_ITEM_TEXT_COLORS.hover
    }
  },
  '& [data-text]': {
    color: POPOVER_ITEM_TEXT_COLORS.normal,
    fontWeight: 400,
    lineHeight: 1.4,
    whiteSpace: 'pre'
  },
  '& [data-highlighted]': {
    backgroundColor: 'primary50'
  },
  '& [data-divider]': {
    backgroundColor: 'dark100',
    height: 13,
    marginY: 0,
    marginX: 10,
    width: 1
  },
  variants: {
    active: {
      true: {
        backgroundColor: 'light400',
        borderLeftWidth: 0,
        'i > svg': {
          color: 'dark200'
        },
        '[data-text]': {
          color: POPOVER_ITEM_TEXT_COLORS.hover
        }
      }
    }
  }
})

type RendererProps = {
  children: ReactChild
}

type JumpMenuItemProps = PopoverItemProps & {
  handleClose: () => void,
  menuElement: ComputedMenuElement,
  menuElementMap: MenuElementMap,
  searchText: string
}

function JumpMenuItem({
  handleClose,
  forceActive,
  menuElement,
  menuElementMap,
  searchText
}: JumpMenuItemProps) {
  const { dashboardsList } = useContext(DashboardContext)!

  const dashboardName = dashboardsList.find((dashboard) => dashboard.id === menuElement.dashboardId)?.name || ''

  const icon = useMemo(() => {
    let i
    menuElement.parentIds?.find((parentId) => {
      i = menuElementMap[parentId].renderedIcon
      return !!i
    })
    return i
  }, [ menuElement, menuElementMap ])

  const concatenatedNames = useMemo(() => {
    const isRootMenuElement = menuElement.parentId === null
    const menuElementName = menuElement.parentIds?.reduce((acc, parentId, index, { length }) => (
      `${acc + menuElementMap[parentId].renderedName}${index < length - 1 ? '*/*' : ''}`
    ), '')

    return isRootMenuElement ? dashboardName : `${dashboardName}*/*${menuElementName}`
  },
  [ dashboardName, menuElement, menuElementMap ])

  const highlighted = useMemo(() => {
    const searchResult = fuzzysort.single(searchText.trim(), concatenatedNames)

    // if no search result return the concatenatedNames,
    // otherwise fuzzysort.highlight will return undefined
    // and parsed content will be blank
    if (!searchResult) return concatenatedNames
    return fuzzysort.highlight(searchResult, '__', '__')
  }, [ concatenatedNames, searchText ])

  const parsed = useMemo(() => (
    <ReactMarkdown
      source={highlighted!}
      renderers={JUMP_MENU_ITEM_RENDERERS}
    />
  ), [ highlighted ])

  return (
    <StyledMenuItem
      active={forceActive}
      forceActive={forceActive}
      icon={icon}
      onClick={handleClose}
      scrollActiveIntoView
      to={menuElement.fullPath}
    >
      {parsed}
    </StyledMenuItem>
  )
}

export default JumpMenuItem

export { JUMP_MENU_ITEM_HORIZONTAL_PADDING, JUMP_MENU_ITEM_VERTICAL_PADDING }
