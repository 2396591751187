import React, { useEffect, useRef } from 'react'

import { styled } from 'styles/stitches'

type IFrameProps = React.PropsWithChildren<{}>

const StyledIFrame = styled('iframe', {
  border: 'none',
  height: '100%',
  width: '100%'
})

function IFrame({ children }: IFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const content = children as string
    const doc = iframeRef.current?.contentDocument

    if (!doc) return

    doc.open()
    doc.write(content)
    doc.close()
  }, [ children ])

  return (
    <StyledIFrame
      src="about:blank"
      title="Preview"
      ref={iframeRef}
    />
  )
}

export default IFrame
