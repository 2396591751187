import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AppLoader from 'components/loaders/AppLoader'
import Block from 'components/blocks/Block'
import InternalContext from 'components/contexts/InternalContext'
import useClientQuery from 'hooks/useClientQuery'
import { PREFERENCES_QUERY } from 'client/state/preferences'
import type { PreferencesQuery } from 'client/state/preferences'

function RootPage() {
  const {
    data: { preferences: { activeDashboardIdentifier } }
  } = useClientQuery<PreferencesQuery>(PREFERENCES_QUERY)
  const { defaultDashboard } = useContext(InternalContext)!
  const history = useHistory()

  useEffect(() => {
    if (activeDashboardIdentifier) {
      history.replace(`/~${activeDashboardIdentifier}`)
    } else if (defaultDashboard) {
      history.replace(`/~${defaultDashboard.identifier}`)
    }
  }, [ activeDashboardIdentifier, defaultDashboard, history ])

  return (
    <Block width={{ md: '100%' }}>
      <AppLoader />
    </Block>
  )
}

export default RootPage
