import React from 'react'
import type { ApolloError } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import type { SeriesBarOptions } from 'highcharts'

import BarChart from 'components/charts/BarChart'
import Block from 'components/blocks/Block'
import Card from 'components/card/Card'
import Loader from 'components/loaders/Loader'
import type { BlockProps } from 'components/blocks/Block'

type BarChartType = 'bar' | 'column'

type BarChartBlockProps = {
  title: string,
  description?: string,
  type: BarChartType,
  xAxisTitle: string,
  yAxisTitle: string,
  series: SeriesBarOptions[],
  loading: boolean,
  error: ErrorResponse | ApolloError
}

function BarChartBlock({
  title, description, type, xAxisTitle, yAxisTitle, series, loading, error, ...others
}: BlockProps & BarChartBlockProps) {
  return (
    <Block
      name="Bar Chart"
      resizeDirection={{ right: true }}
      direction="column"
      {...others}
    >
      <Card css={{ padding: 35 }}>
        <Loader
          data={series}
          error={error}
          loading={loading}
        >
          <BarChart
            title={title}
            description={description}
            type={type}
            xAxis={{ title: { text: xAxisTitle } }}
            yAxis={{ title: { text: yAxisTitle } }}
            series={series}
          />
        </Loader>
      </Card>
    </Block>
  )
}

export default BarChartBlock
