import { number, object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'
import type { ContentTypeFragmentFragment as ContentTypeFragment, FieldsListQuery } from 'generated/schema'

type FieldsList = FieldsListQuery['fieldsList']
type Field = FieldsList[number]

enum FieldIdentifier {
  EMBEDDED = 'embedded-field',
  CHECKBOX = 'checkbox-field',
  COLOR = 'color-field',
  CURRENCY = 'currency-field',
  DATE = 'date-time-field',
  DURATION = 'duration-field',
  DROPDOWN = 'dropdown-field',
  EMAIL = 'email-field',
  JSON = 'json-field',
  LOCATION = 'location-field',
  MEDIA = 'media-field', // deprecated
  FILE = 'file-field',
  NUMBER = 'number-field',
  PASSWORD = 'password-field',
  PHONE = 'phone-field',
  RADIO = 'radio-field',
  REFERENCE = 'reference-field',
  RELATIONSHIP = 'relationship-field',
  SLUG = 'slug-field',
  SWITCH = 'switch-field',
  TEXT = 'text-field',
  MARKDOWN = 'markdown-field',
  UID = 'uid-field',
  BOOLEAN = 'boolean-field',
  CODE = 'code-editor-field'
}

const contentListRenderableFields = [
  FieldIdentifier.TEXT,
  FieldIdentifier.PASSWORD,
  FieldIdentifier.EMAIL,
  FieldIdentifier.NUMBER,
  FieldIdentifier.DROPDOWN,
  FieldIdentifier.CHECKBOX,
  FieldIdentifier.CURRENCY,
  FieldIdentifier.DURATION,
  FieldIdentifier.EMAIL,
  FieldIdentifier.PHONE,
  FieldIdentifier.SLUG,
  FieldIdentifier.SWITCH,
  FieldIdentifier.UID
]

const allowedTitleFieldIdentifiers = [
  FieldIdentifier.NUMBER,
  FieldIdentifier.TEXT,
  FieldIdentifier.DROPDOWN
]

const fieldsWithTheirOwnRepeatedBehavior = [
  FieldIdentifier.DROPDOWN,
  FieldIdentifier.EMBEDDED,
  FieldIdentifier.FILE,
  FieldIdentifier.REFERENCE
]

const orderableFieldIdentifiers = [
  FieldIdentifier.NUMBER, FieldIdentifier.TEXT, FieldIdentifier.DATE, FieldIdentifier.SWITCH
]

class FieldModel extends BaseModel {
  static schema = object({
    name: string().required(),
    identifier: isIdentifier(),
    placeholder: string(),
    hint: string(),
    defaultValue: string(),
    validations: object({
      minLength: number().integer(),
      maxLength: number().integer()
    })
  })

  static getTitleField = (fieldsList: FieldsList, contentType?: ContentTypeFragment | null) => {
    const actualTitleField = contentType?.titleFieldId
      ? fieldsList.find((field) => field.id === contentType.titleFieldId)
      : undefined

    let potentialTitleField: Field | undefined

    if (!actualTitleField) {
      for (let i = 0; i < fieldsList.length; i++) {
        const field = fieldsList[i]
        if (FieldModel.isTitleableField(field.fieldType as FieldIdentifier)) {
          potentialTitleField = field
          break
        }
      }
    }

    return { actualTitleField, potentialTitleField }
  }

  static isTitleableField = (fieldType: FieldIdentifier) => (
    allowedTitleFieldIdentifiers.includes(fieldType)
  )

  static isOrderableField = (fieldType: FieldIdentifier) => (
    orderableFieldIdentifiers.includes(fieldType)
  )

  static hasCustomRepeatedBehavior = (fieldType: FieldIdentifier) => (
    fieldsWithTheirOwnRepeatedBehavior.includes(fieldType)
  )
}

export { FieldIdentifier, contentListRenderableFields }

export default FieldModel
