import type { Workspace, WorkspaceContextQuery } from 'generated/schema'

enum InteropMethods {
  ADD_WORKSPACE = 'addWorkspace',
  DOWNLOAD_UPDATE = 'downloadUpdate',
  GET_STORE_VALUE = 'getStoreValue',
  INSTALL_UPDATE = 'installUpdate',
  OPEN_WORKSPACE = 'openWorkspace',
  SHOW_PROSPECT_FLOW = 'showProspectFlow',
  SWITCH_WORKSPACE = 'switchWorkspace'
}

type InteropArgs = { workspaceIdentifier?: Workspace['identifier']} & { currentWorkspace?: WorkspaceContextQuery['workspace'] }
type Platform = 'darwin' | 'win32' | 'linux'
type UpdateCallback = (args: Record<string, any>) => void

const WORKSPACES_KEY = 'authMetadata.workSpaces'

declare global {
  interface Window {
    interop: {
      invoke: (operation: InteropMethods, args?: InteropArgs | typeof WORKSPACES_KEY) => {},
      isElectron: boolean,
      on(event: string | symbol, listener: (...args: any[]) => void): void,
      removeAllListeners(event?: string | symbol): void,
      platform: Platform
    }
  }
}

const isElectron = window.interop && window.interop.isElectron
const isFullScreen = () => window.innerHeight === window.screen.height

const addWorkspace = (args: InteropArgs) => {
  window.interop.invoke(InteropMethods.ADD_WORKSPACE, args)
}

const getWorkspaces = () => window.interop.invoke(InteropMethods.GET_STORE_VALUE, WORKSPACES_KEY)

const openWorkspace = (args: InteropArgs & { workspaceEmail?: string }) => {
  window.interop.invoke(InteropMethods.OPEN_WORKSPACE, args)
}

const showProspectFlow = (args: InteropArgs) => {
  window.interop.invoke(InteropMethods.SHOW_PROSPECT_FLOW, args)
}

const switchWorkspace = (args: InteropArgs) => {
  window.interop.invoke(InteropMethods.SWITCH_WORKSPACE, args)
}

const downloadUpdate = (args?: InteropArgs) => {
  window.interop.invoke(InteropMethods.DOWNLOAD_UPDATE, args)
}

const installUpdate = () => {
  window.interop.invoke(InteropMethods.INSTALL_UPDATE)
}

const onUpdateAvailable = (callback: UpdateCallback) => window.interop.on('update-available', callback)
const onUpdateDownloaded = (callback: UpdateCallback) => window.interop.on('update-available', callback)

const checkPlatform = () => window.interop.platform

export {
  addWorkspace,
  checkPlatform,
  downloadUpdate,
  getWorkspaces,
  installUpdate,
  isElectron,
  isFullScreen,
  onUpdateAvailable,
  onUpdateDownloaded,
  openWorkspace,
  showProspectFlow,
  switchWorkspace
}
