import fuzzysort from 'fuzzysort'
import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import type { ChangeEvent, KeyboardEvent } from 'react'

import { useHistory } from 'react-router-dom'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import JumpMenuItem, { JUMP_MENU_ITEM_HORIZONTAL_PADDING } from 'components/menuItems/JumpMenuItem'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { BaseModal, BaseModalProps } from 'components/modal'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { Popover, PopoverDivider } from 'components/popover'
import type { MenuElementMap } from 'lib/generateDashboard'

type JumpMenuProps = BaseModalProps & {
  menuElementMap: MenuElementMap
}

const JUMP_MENU_HEIGHT = 575
const JUMP_MENU_INPUT_HEIGHT = 80
const JUMP_MENU_WIDTH = 700

const StyledSearchIcon = styled(Icon, {
  ...mixins.transition('fluid'),

  color: rgba(colorVars.dark900rgb, 0.7)
})

const StyledSearchLabel = styled('label', {
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  flex: 1,
  left: 0,
  paddingLeft: 75,
  position: 'absolute',
  top: 0,
  width: '100%',

  '& > :first-child': {
    paddingRight: 15,
    whiteSpace: 'nowrap'
  }
})

const StyledInputDivider = styled('span', {
  backgroundColor: 'dark100',
  height: 40,
  width: 1
})

const StyledSearchInput = styled('input', {
  ...mixins.transition('fluid'),

  '&::placeholder': {
    ...mixins.transition('fluid'),

    color: 'transparent',
    fontFamily: 'normal',
    fontSize: 12,
    fontWeight: 'light',
    lineHeight: `${JUMP_MENU_INPUT_HEIGHT}px` // trick to center the placeholder vertically in firefox
  },

  backgroundColor: 'transparent',
  border: 0,
  fontFamily: 'normal',
  fontSize: 15,
  fontWeight: 'bold',
  lineHeight: 'cozy',
  paddingLeft: 20,
  paddingRight: JUMP_MENU_ITEM_HORIZONTAL_PADDING,
  width: '100%'
})

const StyledInputContainer = styled(Flex, {
  ...mixins.transition('fluid'),

  flexShrink: 0,
  flexGrow: 0,
  height: JUMP_MENU_INPUT_HEIGHT,
  paddingLeft: JUMP_MENU_ITEM_HORIZONTAL_PADDING,
  paddingRight: JUMP_MENU_ITEM_HORIZONTAL_PADDING,
  position: 'relative',

  '&:hover, &:focus-within': {
    backgroundColor: 'light200'
  },

  [`&:hover ${StyledSearchIcon}, &:focus-within ${StyledSearchIcon}`]: {
    color: 'dark900'
  },

  [`&:focus-within ${StyledSearchInput}`]: {
    '&::placeholder': {
      color: rgba(colorVars.dark500rgb, 0.5)
    }
  },

  [`&:hover ${StyledSearchInput}`]: {
    '&::placeholder': {
      color: 'dark500'
    }
  }
})

const StyledJumpMenuContainerDivider = styled(PopoverDivider, {
  flexGrow: 0,
  flexShrink: 0
})

const StyledJumpMenuContainer = styled(Scrollbars, {
  flexGrow: 1,
  overflow: 'auto'
})

const StyledJumpMenuDivider = styled(PopoverDivider, {
  paddingY: 0,
  paddingX: JUMP_MENU_ITEM_HORIZONTAL_PADDING,
  width: '100%'
})

const classes = {
  overlayBase: css({
    '&&': {
      backdropFilter: 'blur(0)',
      backgroundColor: 'transparent'
    }
  }),
  contentBase: css({
    ...mixins.shadow('xxxLarge', colorVars.dark600rgb, 0.6),

    backgroundColor: 'light100',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    maxHeight: `min(${JUMP_MENU_HEIGHT}px, 90vh)`,
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    transform: `translate(max(-${JUMP_MENU_WIDTH / 2}px, -45vw), max(-${JUMP_MENU_HEIGHT / 2}px, -45vh)) translateY(18px)`,
    width: `min(${JUMP_MENU_WIDTH}px, 90vw)`,
    zIndex: 'modal'
  }),
  contentAfterOpen: css({
    '&&': {
      transform: `translate(max(-${JUMP_MENU_WIDTH / 2}px, -45vw), max(-${JUMP_MENU_HEIGHT / 2}px, -45vh))`
    }
  }),
  contentBeforeClose: css({
    '&&&': {
      transform: `translate(max(-${JUMP_MENU_WIDTH / 2}px, -45vw), max(-${JUMP_MENU_HEIGHT / 2}px, -45vh)) translateY(18px)`
    }
  })

}

function JumpMenu({
  menuElementMap,
  onRequestClose,
  ...others
}: JumpMenuProps) {
  const [ searchText, setSearchText ] = useState('')
  const [ activeMenuItem, setActiveMenuItem ] = useState(0)
  const { push } = useHistory()

  const jumpableMenuElements = useMemo(
    () => Object.values(menuElementMap).filter((menuElement) => menuElement.target),
    [ menuElementMap ]
  )

  const matches = useMemo(() => (
    fuzzysort.go(searchText.trim(), jumpableMenuElements, {
      allowTypo: false,
      keys: [ 'concatenatedNames', 'name' ], // `name` for priority
      limit: 20
    })
  ), [ jumpableMenuElements, searchText ])

  useEffect(() => {
    setActiveMenuItem(0)
  }, [ searchText ])

  const handleClose = useCallback(() => {
    setSearchText('')
    onRequestClose()
  }, [ onRequestClose ])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setActiveMenuItem(Math.min(matches.length - 1, activeMenuItem + 1))
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      setActiveMenuItem(Math.max(0, activeMenuItem - 1))
    } else if (event.key === 'Enter') {
      handleClose()
      push(matches[activeMenuItem].obj.fullPath!)
    }
  }

  return (
    <BaseModal
      contentAfterOpenClassName={classes.contentAfterOpen}
      contentBaseClassName={classes.contentBase}
      onRequestClose={handleClose}
      overlayBaseClassName={classes.overlayBase}
      contentBeforeCloseClassName={classes.contentBeforeClose}
      {...others}
    >
      <StyledInputContainer
        alignItems="center"

        grow={1}
        shrink={0}
      >
        <StyledSearchIcon name="jump-menu" size={20} />
        <StyledSearchLabel htmlFor="jump-input">
          <Text fontWeight="bold" color="dark900">Jump to</Text>
          <StyledInputDivider />
          <StyledSearchInput
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            autoComplete="off"
            id="jump-input"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Where you wanna go?"
            type="text"
            value={searchText}
          />
        </StyledSearchLabel>
      </StyledInputContainer>
      {!!matches.length && (
        <StyledJumpMenuContainerDivider />
      )}
      <StyledJumpMenuContainer
        autoHeight
        autoHeightMax={JUMP_MENU_HEIGHT - JUMP_MENU_INPUT_HEIGHT}
        autoHide
      >
        <Popover>
          {matches.map(({ obj: menuElement }, index, { length }) => (
            <Fragment key={menuElement.id}>
              <JumpMenuItem
                handleClose={handleClose}
                forceActive={activeMenuItem === index}
                menuElement={menuElement}
                menuElementMap={menuElementMap}
                searchText={searchText}
              />
              {index < length - 1 && <StyledJumpMenuDivider />}
            </Fragment>
          ))}
        </Popover>
      </StyledJumpMenuContainer>
    </BaseModal>
  )
}

export default memo(JumpMenu)
