import { createContext, useContext } from 'react'

import type { View } from 'generated/schema'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'

interface IDashboardViewContext {
  activeUrn: string,
  activeView: View,
  switcher: SwitcherResultProps['switcher']
}

const DashboardViewContext = createContext<IDashboardViewContext>({ activeUrn: '', activeView: null as any, switcher: null as any })

const useDashboardViewContext = () => useContext(DashboardViewContext)

export { useDashboardViewContext }

export default DashboardViewContext
