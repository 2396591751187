import React, { Fragment } from 'react'
import type { SetStateAction } from 'react'

import Block from 'components/blocks/Block'
import CustomizeDisplay from 'components/dataWidgets/CustomizeDisplay'
import DataList from 'components/dataList/DataList'
import Toolbar from 'components/toolbar/Toolbar'
import useFilterColumns from 'hooks/useFilterColumns'
import type { BlockProps } from 'components/blocks/Block'
import type { DataListProps } from 'components/dataList/DataList'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { LoaderProps } from 'components/loaders/Loader'
import type { SummaryBarProps } from 'components/dataWidgets/SummaryBar'
import type { ToolbarProps } from 'components/toolbar/Toolbar'
import type { DataListItemProps } from 'components/dataList/DataListItem'

type DataListBlockProps
  = BlockProps
  & DataListProps<any>
  & LoaderProps
  & ToolbarProps
  & Pick<SummaryBarProps<any>, 'batchActions' | 'hideFormulae' | 'hideSelectAll'>
  & {
    asFragment?: boolean,
    batchActions?: SummaryBarProps<any>['batchActions'],
    dataListItemVariant?: DataListItemProps['variant'],
    filters?: FilterType,
    setFilters?: React.Dispatch<SetStateAction<FilterType>>,
    summaryBarVariant?: SummaryBarProps<any>['variant']
  }

function DataListBlock({
  actions,
  asFragment = false,
  batchActions,
  contents,
  data = [],
  defaultSelection,
  dataListItemVariant,
  empty,
  error,
  loading,
  nestedAction,
  onChangePage,
  onChangePageSize,
  onRowDragEnd,
  onRowDragUpdate,
  onRowSelect,
  page,
  pageSize,
  pageSizeOptions,
  paginationMode,
  primaryElements,
  filters,
  setFilters,
  secondaryElements,
  selectionHandlerRef,
  selectionMode,
  summaryBarVariant,
  title,
  totalRows,
  withPageControls = true,

  ...others
}: DataListBlockProps) {
  const isFilteringEnabled = filters && setFilters

  const [ filteredColumns, toggleVisibility ] = useFilterColumns({ columns: contents || [] })

  const secondaryElementsWithCustomizeDisplay = (
    <>
      { isFilteringEnabled && (
        <CustomizeDisplay
          columns={filteredColumns}
          disabled={loading && !data.length}
          filters={filters}
          onFilterChange={setFilters}
          onVisibilityToggle={toggleVisibility}
        />
      )}
      {secondaryElements}
    </>
  )

  const Wrapper = asFragment ? Fragment : Block
  const wrapperProps = (asFragment ? {} : { name: 'Data List', direction: 'column', gap: 20, width: { md: '100%' }, ...others }) as any
  return (
    <Wrapper {...wrapperProps}>
      <Toolbar
        primaryElements={primaryElements}
        secondaryElements={secondaryElementsWithCustomizeDisplay}
        title={title}
      />
      <DataList
        actions={actions}
        batchActions={batchActions}
        contents={contents}
        data={data}
        loading={loading}
        error={error}
        empty={empty}
        defaultSelection={defaultSelection}
        dataListItemVariant={dataListItemVariant}
        nestedAction={nestedAction}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRowDragEnd={onRowDragEnd}
        onRowDragUpdate={onRowDragUpdate}
        onRowSelect={onRowSelect}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        paginationMode={paginationMode}
        withPageControls={withPageControls}
        selectionHandlerRef={selectionHandlerRef}
        selectionMode={selectionMode}
        summaryBarVariant={summaryBarVariant}
        totalRows={totalRows}
      />
    </Wrapper>
  )
}

export default DataListBlock
