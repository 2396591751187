/* eslint-disable */
// @ts-nocheck
import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T;


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: any;
  /** A scalar that can represent any JSON value. */
  JSON: Record<any, any>;
  /**
   * ISO 8601 calendar date without timezone.
   * Format: %Y-%m-%d
   * 
   * # Examples
   * 
   * * `1994-11-13`
   * * `2000-02-24`
   */
  NaiveDate: any;
  Timestamp: any;
  UUID: any;
  Upload: any;
};

export type Account = {
  readonly __typename?: 'Account';
  readonly id: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<AccountGender>;
  readonly dateOfBirth?: Maybe<Scalars['NaiveDate']>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly uid?: Maybe<Scalars['String']>;
  readonly anonymousUid?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly hasApiAccess: Scalars['Boolean'];
  readonly hasGuiAccess: Scalars['Boolean'];
  readonly custom?: Maybe<Scalars['JSON']>;
  readonly status: AccountStatus;
  readonly unconfirmedEmail?: Maybe<Scalars['String']>;
  readonly confirmationDigest?: Maybe<Scalars['String']>;
  readonly confirmationExpiresAt?: Maybe<Scalars['Timestamp']>;
  readonly regularAccountId?: Maybe<Scalars['UUID']>;
  readonly kind: AccountKind;
  readonly tours: Scalars['JSON'];
  readonly billingExternalUid?: Maybe<Scalars['String']>;
  readonly isMfaEnabled: Scalars['Boolean'];
  readonly roleMemberships: ReadonlyArray<RoleMembership>;
  readonly addresses: ReadonlyArray<Address>;
  readonly taxRegistrations: ReadonlyArray<TaxRegistration>;
};


export type AccountRoleMembershipsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type AccountAddressesArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type AccountTaxRegistrationsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type AccountGender = 
  | 'MALE'
  | 'FEMALE'
  | 'OTHER';

export type AccountKind = 
  | 'MEMBER'
  | 'SERVICE'
  | 'USER'
  | 'VISITOR';

export type AccountStatus = 
  | 'NOT_INVITED'
  | 'INVITED'
  | 'ONBOARDING'
  | 'ENABLED'
  | 'DISABLED';

export type AccountTourStatus = 
  | 'PENDING'
  | 'COMPLETED'
  | 'SKIPPED';

export type AccountTourType = 
  | 'WELCOME_OWNER_TOUR'
  | 'WELCOME_MEMBER_TOUR';

export type AddDatabaseRecordInput = {
  readonly databaseTableId: Scalars['UUID'];
  readonly data: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type AddItemToCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly itemId: Scalars['UUID'];
  readonly pricingId: Scalars['UUID'];
  readonly quantity: Scalars['Decimal'];
  readonly reset: Scalars['Boolean'];
  readonly custom?: Maybe<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type Address = {
  readonly __typename?: 'Address';
  readonly id: Scalars['UUID'];
  readonly addressableId: Scalars['UUID'];
  readonly addressableType: Scalars['String'];
  readonly kind: AddressKind;
  readonly line1: Scalars['String'];
  readonly line2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zip: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly deletedAt?: Maybe<Scalars['Timestamp']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type AddressKind = 
  | 'BILLING'
  | 'SHIPPING';

export type AddressStub = {
  readonly __typename?: 'AddressStub';
  readonly kind: AddressKind;
  readonly line1: Scalars['String'];
  readonly line2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zip: Scalars['String'];
  readonly countryCode: Scalars['String'];
};

export type AddressStubInput = {
  readonly kind: AddressKind;
  readonly line1: Scalars['String'];
  readonly line2?: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zip: Scalars['String'];
  readonly countryCode: Scalars['String'];
};

export type AggregateResponse = {
  readonly __typename?: 'AggregateResponse';
  readonly count?: Maybe<Scalars['Int']>;
};

export type ApiImport = {
  readonly __typename?: 'ApiImport';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly integrationId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly kind?: Maybe<ApiImportKind>;
  readonly status: ApiImportStatus;
  readonly statusReason?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly assetId?: Maybe<Scalars['UUID']>;
  readonly data?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type ApiImportKind = 
  | 'OPEN_API'
  | 'POSTMAN'
  | 'INSOMNIA'
  | 'CURL';

export type ApiImportStatus = 
  | 'PENDING'
  | 'COMPLETED'
  | 'FAILED';

export type ApiRequest = {
  readonly __typename?: 'ApiRequest';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly integrationId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly position: Scalars['Int'];
  readonly method?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly urlFragment?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly queryParams?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly headers?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly kind: ApiRequestKind;
  readonly authStrategy: ApiRequestAuthStrategy;
};

export type ApiRequestAuthStrategy = 
  | 'INHERIT'
  | 'NONE';

export type ApiRequestKind = 
  | 'FOLDER'
  | 'HTTP'
  | 'GRPC'
  | 'WS';

export type App = {
  readonly __typename?: 'App';
  readonly id: Scalars['UUID'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly summary?: Maybe<Scalars['String']>;
  readonly fields: ReadonlyArray<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly appCategoryId?: Maybe<Scalars['UUID']>;
  readonly kind: AppKind;
  readonly icon?: Maybe<Scalars['String']>;
  readonly technology?: Maybe<AppTechnology>;
  readonly dimensions?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly platforms?: Maybe<ReadonlyArray<AppPlatform>>;
  readonly meta: AppMeta;
  readonly appCategory?: Maybe<AppCategory>;
};

export type AppCategory = {
  readonly __typename?: 'AppCategory';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly kind: AppKind;
};

export type AppKind = 
  | 'PROJECT'
  | 'EXTENSION'
  | 'INTEGRATION';

export type AppMeta = {
  readonly __typename?: 'AppMeta';
  readonly isTestable: Scalars['Boolean'];
  readonly isSyncable: Scalars['Boolean'];
  readonly hasWebhooks: Scalars['Boolean'];
  readonly hasInstallationFields: Scalars['Boolean'];
  readonly integrationCategoryIds: ReadonlyArray<Scalars['UUID']>;
};

export type AppPlatform = 
  | 'BROWSER'
  | 'IOS'
  | 'ANDROID'
  | 'SERVER';

export type AppTechnology = 
  | 'JAVASCRIPT'
  | 'REACT'
  | 'ANGULAR'
  | 'VUE'
  | 'REMIX'
  | 'NATIVE_IOS'
  | 'NATIVE_ANDROID'
  | 'REACT_NATIVE'
  | 'FLUTTER'
  | 'NODE'
  | 'RUBY'
  | 'PHP'
  | 'PYTHON'
  | 'GO'
  | 'RUST';

export type ApplyCouponToCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly couponCode: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type ArchiveInstallationInput = {
  readonly id: Scalars['UUID'];
};

export type Asset = {
  readonly __typename?: 'Asset';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly resourceId?: Maybe<Scalars['UUID']>;
  readonly attributeId?: Maybe<Scalars['UUID']>;
  readonly storageProviderId?: Maybe<Scalars['UUID']>;
  readonly uploaderId?: Maybe<Scalars['UUID']>;
  readonly data: Scalars['JSON'];
  readonly uploadStatus: AssetUploadStatus;
  readonly processingStatus: AssetProcessingStatus;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly name?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly mimeType?: Maybe<Scalars['String']>;
  readonly uploadStatusReason?: Maybe<Scalars['String']>;
  readonly processingStatusReason?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly staticVideoUrls?: Maybe<Scalars['JSON']>;
  readonly staticAudioUrl?: Maybe<Scalars['String']>;
};

export type AssetProcessingStatus = 
  | 'PENDING'
  | 'COMPLETED'
  | 'FAILED';

export type AssetUploadStatus = 
  | 'PENDING'
  | 'UPLOADED'
  | 'FAILED'
  | 'CANCELED'
  | 'TIMED_OUT';

export type AssignRolesInput = {
  readonly accountIds: ReadonlyArray<Scalars['UUID']>;
  readonly groupIds: ReadonlyArray<Scalars['UUID']>;
  readonly roles: ReadonlyArray<RoleStubInput>;
};

export type AssignRolesResponse = {
  readonly __typename?: 'AssignRolesResponse';
  readonly roleMemberships: ReadonlyArray<RoleMembership>;
};

export type Attribute = {
  readonly __typename?: 'Attribute';
  readonly id: Scalars['UUID'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly resourceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly isNullable: Scalars['Boolean'];
  readonly isArray: Scalars['Boolean'];
  readonly isTranslatable: Scalars['Boolean'];
  readonly isFilterable: Scalars['Boolean'];
  readonly isOrderable: Scalars['Boolean'];
  readonly validations: ReadonlyArray<Scalars['JSON']>;
  readonly settings: Scalars['JSON'];
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly resolution?: Maybe<Scalars['JSON']>;
  readonly isPrimary: Scalars['Boolean'];
  readonly dataTypeId: Scalars['UUID'];
  readonly fieldTypeSettings: Scalars['JSON'];
  readonly dataTypeSettings: Scalars['JSON'];
  readonly resolutionKind: AttributeResolutionKind;
  readonly resolutionSettings: Scalars['JSON'];
  readonly displayType: DisplayType;
  readonly displayTypeSettings: Scalars['JSON'];
  readonly fieldType?: Maybe<Scalars['String']>;
  readonly resource: Resource;
  readonly dataType: DataType;
};

export type AttributeResolutionKind = 
  | 'LOCAL'
  | 'REMOTE'
  | 'COMPUTED';

export type BulkEditRecordStubInput = {
  readonly versionId?: Maybe<Scalars['UUID']>;
  readonly arguments: Scalars['JSON'];
};

export type CancelFlowExecutionInput = {
  readonly id: Scalars['UUID'];
};

export type CapturePaymentInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly gatewayResponse?: Maybe<Scalars['JSON']>;
  readonly orderId?: Maybe<Scalars['UUID']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type Card = {
  readonly __typename?: 'Card';
  readonly id: Scalars['UUID'];
  readonly paymentMethodId: Scalars['UUID'];
  readonly last4: Scalars['String'];
  readonly brand: Scalars['String'];
  readonly expiryMonth: Scalars['Int'];
  readonly expiryYear: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly paymentMethod: PaymentMethod;
};

export type ChangeEmailInput = {
  readonly password: Scalars['String'];
  readonly email: Scalars['String'];
};

export type ChangeEmailResponse = {
  readonly __typename?: 'ChangeEmailResponse';
  readonly success: Scalars['Boolean'];
};

export type ChangePasswordInput = {
  readonly id?: Maybe<Scalars['UUID']>;
  readonly currentPassword: Scalars['String'];
  readonly password: Scalars['String'];
  readonly passwordConfirmation: Scalars['String'];
};

export type CheckoutCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly gatewayOptions?: Maybe<Scalars['JSON']>;
  readonly gatewayIdentifier?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['UUID']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type CompleteOnboardingInput = {
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<AccountGender>;
  readonly dateOfBirth?: Maybe<Scalars['NaiveDate']>;
  readonly custom?: Maybe<Scalars['JSON']>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
};

export type Configuration = {
  readonly __typename?: 'Configuration';
  readonly id: Scalars['UUID'];
  readonly installationId: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly digest: Scalars['String'];
  readonly settings: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly status: ConfigurationStatus;
  readonly statusMessage?: Maybe<Scalars['String']>;
  readonly installation: Installation;
  readonly environment: Environment;
};

export type ConfigurationStatus = 
  | 'PENDING'
  | 'COMPLETED'
  | 'FAILED';

export type ConfigurationStubInput = {
  readonly environmentId: Scalars['UUID'];
  readonly settings: Scalars['JSON'];
};

export type ConfigureInstallationInput = {
  readonly id: Scalars['UUID'];
};

export type ConfigureMfaResponse = {
  readonly __typename?: 'ConfigureMfaResponse';
  readonly qrCode: Scalars['String'];
};

export type Contact = {
  readonly __typename?: 'Contact';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly kind: ContactKind;
  readonly value: Scalars['String'];
  readonly unverifiedValue?: Maybe<Scalars['String']>;
  readonly verifiedAt?: Maybe<Scalars['Timestamp']>;
  readonly verificationDigest?: Maybe<Scalars['String']>;
  readonly verificationExpiresAt?: Maybe<Scalars['Timestamp']>;
  readonly status: ContactStatus;
  readonly userAgent?: Maybe<Scalars['String']>;
  readonly osName?: Maybe<Scalars['String']>;
  readonly osVersion?: Maybe<Scalars['String']>;
  readonly deviceModel?: Maybe<Scalars['String']>;
  readonly deviceManufacturer?: Maybe<Scalars['String']>;
  readonly deviceUid?: Maybe<Scalars['String']>;
  readonly deviceAdvertisingUid?: Maybe<Scalars['String']>;
  readonly isDeviceAdTrackingEnabled: Scalars['Boolean'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly tag?: Maybe<Scalars['String']>;
  readonly accountKind: AccountKind;
};

export type ContactKind = 
  | 'EMAIL'
  | 'PHONE'
  | 'IOS'
  | 'ANDROID'
  | 'WEB'
  | 'WHATSAPP';

export type ContactStatus = 
  | 'SUBSCRIBED'
  | 'NOT_SUBSCRIBED'
  | 'UNSUBSCRIBED'
  | 'BLACKLISTED'
  | 'REVOKED';

export type ContactStubInput = {
  readonly kind: ContactKind;
  readonly value: Scalars['String'];
  readonly tag?: Maybe<Scalars['String']>;
  readonly status?: Maybe<ContactStatus>;
  readonly shouldDestroy?: Maybe<Scalars['Boolean']>;
};

export type Coupon = {
  readonly __typename?: 'Coupon';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly discountType: CouponDiscountType;
  readonly discountAmount: Scalars['Decimal'];
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly expiresAt?: Maybe<Scalars['Timestamp']>;
  readonly redemptionsLimit?: Maybe<Scalars['Int']>;
  readonly redemptionsCount: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly environmentId: Scalars['UUID'];
};

export type CouponCode = {
  readonly __typename?: 'CouponCode';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly couponId: Scalars['UUID'];
  readonly redemptionsLimit?: Maybe<Scalars['Int']>;
  readonly redemptionsCount: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type CouponDiscountType = 
  | 'FIXED'
  | 'PERCENTAGE';

export type CouponRedemption = {
  readonly __typename?: 'CouponRedemption';
  readonly id: Scalars['UUID'];
  readonly couponId: Scalars['UUID'];
  readonly orderId: Scalars['UUID'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly coupon: Coupon;
  readonly order: Order;
};

export type CouponRestriction = {
  readonly __typename?: 'CouponRestriction';
  readonly id: Scalars['UUID'];
  readonly couponId: Scalars['UUID'];
  readonly itemId?: Maybe<Scalars['UUID']>;
  readonly itemCategoryId?: Maybe<Scalars['UUID']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly item?: Maybe<Item>;
  readonly itemCategory?: Maybe<ItemCategory>;
};

export type CreateAccountInput = {
  readonly environmentId: Scalars['UUID'];
  readonly kind: AccountKind;
  readonly email?: Maybe<Scalars['String']>;
  readonly custom: Scalars['JSON'];
  readonly hasGuiAccess: Scalars['Boolean'];
  readonly hasApiAccess: Scalars['Boolean'];
  readonly fullName?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['NaiveDate']>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<AccountGender>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly groupIds: ReadonlyArray<Scalars['UUID']>;
  readonly roleMemberships: ReadonlyArray<RoleStubInput>;
};

export type CreateApiImportInput = {
  readonly integrationId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly kind?: Maybe<ApiImportKind>;
  readonly url?: Maybe<Scalars['String']>;
  readonly assetId?: Maybe<Scalars['UUID']>;
  readonly data?: Maybe<Scalars['String']>;
};

export type CreateApiRequestInput = {
  readonly integrationId: Scalars['UUID'];
  readonly kind: ApiRequestKind;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly authStrategy?: Maybe<ApiRequestAuthStrategy>;
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly position: Scalars['Int'];
  readonly method?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly urlFragment?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
  readonly queryParams?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly headers?: Maybe<ReadonlyArray<Scalars['JSON']>>;
};

export type CreateAppInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly icon?: Maybe<Scalars['String']>;
  readonly summary?: Maybe<Scalars['String']>;
  readonly kind: AppKind;
  readonly technology?: Maybe<AppTechnology>;
};

export type CreateAttributeInput = {
  readonly resourceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly dataTypeId: Scalars['UUID'];
  readonly fieldType?: Maybe<Scalars['String']>;
  readonly fieldTypeSettings: Scalars['JSON'];
  readonly displayType: DisplayType;
  readonly displayTypeSettings: Scalars['JSON'];
  readonly isPrimary?: Maybe<Scalars['Boolean']>;
  readonly isNullable?: Maybe<Scalars['Boolean']>;
  readonly isArray?: Maybe<Scalars['Boolean']>;
  readonly isTranslatable?: Maybe<Scalars['Boolean']>;
  readonly isFilterable?: Maybe<Scalars['Boolean']>;
  readonly isOrderable?: Maybe<Scalars['Boolean']>;
  readonly validations: ReadonlyArray<ValidationInput>;
  readonly settings: Scalars['JSON'];
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position: Scalars['Int'];
  readonly originId?: Maybe<Scalars['UUID']>;
  readonly dataTypeSettings: Scalars['JSON'];
  readonly resolutionKind: AttributeResolutionKind;
  readonly resolutionSettings: Scalars['JSON'];
};

export type CreateConfigurationInput = {
  readonly installationId: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly settings: Scalars['JSON'];
};

export type CreateContactInput = {
  readonly accountId: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly kind: ContactKind;
  readonly value: Scalars['String'];
  readonly status: ContactStatus;
  readonly userAgent?: Maybe<Scalars['String']>;
  readonly osName?: Maybe<Scalars['String']>;
  readonly osVersion?: Maybe<Scalars['String']>;
  readonly deviceModel?: Maybe<Scalars['String']>;
  readonly deviceManufacturer?: Maybe<Scalars['String']>;
  readonly deviceUid?: Maybe<Scalars['String']>;
  readonly deviceAdvertisingUid?: Maybe<Scalars['String']>;
  readonly isDeviceAdTrackingEnabled?: Maybe<Scalars['Boolean']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type CreateCouponCodeInput = {
  readonly name: Scalars['String'];
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly redemptionsLimit?: Maybe<Scalars['Int']>;
  readonly couponId: Scalars['UUID'];
};

export type CreateCouponInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly discountType: CouponDiscountType;
  readonly discountAmount: Scalars['Decimal'];
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly expiresAt?: Maybe<Scalars['Timestamp']>;
  readonly redemptionsLimit?: Maybe<Scalars['Int']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type CreateCouponRedemptionInput = {
  readonly couponId: Scalars['UUID'];
  readonly orderId: Scalars['UUID'];
};

export type CreateCouponRestrictionInput = {
  readonly couponId: Scalars['UUID'];
  readonly itemId?: Maybe<Scalars['UUID']>;
  readonly itemCategoryId?: Maybe<Scalars['UUID']>;
};

export type CreateDashboardInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type CreateDataTypeInput = {
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly kind: DataTypeKind;
  readonly position: Scalars['Int'];
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly validations?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly settings?: Maybe<Scalars['JSON']>;
};

export type CreateDatabaseScratchpadInput = {
  readonly integrationId: Scalars['UUID'];
  readonly title: Scalars['String'];
  readonly body: Scalars['String'];
};

export type CreateDeliveryInput = {
  readonly templateSubkind: TemplateSubkind;
  readonly templateIdentifier?: Maybe<Scalars['String']>;
  readonly templateId?: Maybe<Scalars['UUID']>;
  readonly content?: Maybe<Scalars['JSON']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly isDraft?: Maybe<Scalars['Boolean']>;
  readonly isTest?: Maybe<Scalars['Boolean']>;
  readonly scheduledAt?: Maybe<Scalars['Timestamp']>;
  readonly recipients?: Maybe<ReadonlyArray<RecipientStubInput>>;
  readonly custom?: Maybe<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type CreateEnvironmentInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
};

export type CreateEventReportInput = {
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
};

export type CreateEventTypeInput = {
  readonly name: Scalars['String'];
};

export type CreateFlowInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
};

export type CreateFlowVersionInput = {
  readonly flowId: Scalars['UUID'];
};

export type CreateGroupInput = {
  readonly environmentId: Scalars['UUID'];
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly accountKind: AccountKind;
  readonly name: Scalars['String'];
  readonly position?: Maybe<Scalars['Int']>;
};

export type CreateGroupMembershipInput = {
  readonly groupId: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
};

export type CreateInstallationInput = {
  readonly appId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly configurations?: Maybe<ReadonlyArray<ConfigurationStubInput>>;
};

export type CreateItemCategoryInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
};

export type CreateItemCategoryMembershipInput = {
  readonly itemId: Scalars['UUID'];
  readonly itemCategoryId: Scalars['UUID'];
};

export type CreateItemInput = {
  readonly kind: ItemKind;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
};

export type CreateKeyPairInput = {
  readonly accountId: Scalars['UUID'];
};

export type CreateMenuElementInput = {
  readonly dashboardId: Scalars['UUID'];
  readonly kind: MenuElementKind;
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly placement: MenuElementPlacement;
  readonly target?: Maybe<MenuElementTarget>;
  readonly viewStyle?: Maybe<ViewStyle>;
  readonly url?: Maybe<Scalars['String']>;
  readonly query?: Maybe<Scalars['String']>;
  readonly actions?: Maybe<ReadonlyArray<MenuElementActionInput>>;
  readonly separatorStyle?: Maybe<MenuElementSeparatorStyle>;
  readonly isRepeated?: Maybe<Scalars['Boolean']>;
  readonly isSticky?: Maybe<Scalars['Boolean']>;
};

export type CreateOperationInput = {
  readonly resourceId?: Maybe<Scalars['UUID']>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly graphqlKind?: Maybe<OperationGraphqlKind>;
  readonly behaviorKind: OperationBehaviorKind;
  readonly behaviorMethod: OperationBehaviorMethod;
  readonly behaviorSettings: Scalars['JSON'];
  readonly actsOn?: Maybe<OperationActsOn>;
  readonly parameters: ReadonlyArray<ParameterStubInput>;
  readonly responseMapper?: Maybe<Scalars['JSON']>;
};

export type CreateOrderInput = {
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly status: OrderStatus;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type CreateOrderItemInput = {
  readonly orderId: Scalars['UUID'];
  readonly itemId: Scalars['UUID'];
  readonly pricingId: Scalars['UUID'];
  readonly quantity: Scalars['Decimal'];
  readonly discount: Scalars['Decimal'];
  readonly custom?: Maybe<Scalars['JSON']>;
};

export type CreateParameterInput = {
  readonly operationId: Scalars['UUID'];
  readonly attributeId?: Maybe<Scalars['UUID']>;
  readonly relationshipId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly dataTypeId?: Maybe<Scalars['UUID']>;
  readonly dataTypeSettings?: Maybe<Scalars['JSON']>;
  readonly fieldType?: Maybe<Scalars['String']>;
  readonly fieldTypeSettings?: Maybe<Scalars['JSON']>;
  readonly displayType?: Maybe<DisplayType>;
  readonly displayTypeSettings?: Maybe<Scalars['JSON']>;
  readonly isNullable?: Maybe<Scalars['Boolean']>;
  readonly isArray?: Maybe<Scalars['Boolean']>;
  readonly validations?: Maybe<ReadonlyArray<ValidationInput>>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position?: Maybe<Scalars['Int']>;
};

export type CreatePreferenceInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly restrictions?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly isEnabledByDefault: Scalars['Boolean'];
};

export type CreatePricingInput = {
  readonly itemId: Scalars['UUID'];
  readonly amount: Scalars['Decimal'];
  readonly currencyCode: Scalars['String'];
  readonly originalAmount?: Maybe<Scalars['Decimal']>;
  readonly isRecurring: Scalars['Boolean'];
  readonly recurringInterval: Scalars['Int'];
  readonly recurringIntervalUnit: PricingRecurringIntervalUnit;
  readonly kind: PricingKind;
  readonly appleProductIdentifier?: Maybe<Scalars['String']>;
  readonly googleProductIdentifier?: Maybe<Scalars['String']>;
};

export type CreateRecipientInput = {
  readonly deliveryId: Scalars['UUID'];
  readonly kind: RecipientKind;
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly groupId?: Maybe<Scalars['UUID']>;
  readonly target: RecipientTarget;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly custom?: Maybe<Scalars['JSON']>;
};

export type CreateRecordInput = {
  readonly resourceId: Scalars['UUID'];
  readonly data: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type CreateRelationshipInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly sourceAttributeId: Scalars['UUID'];
  readonly targetAttributeId: Scalars['UUID'];
  readonly kind: RelationshipKind;
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly position: Scalars['Int'];
  readonly deleteBehavior?: Maybe<RelationshipDeleteBehavior>;
};

export type CreateResourceInput = {
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly environmentBehavior: ResourceEnvironmentBehavior;
  readonly isPublishingEnabled?: Maybe<Scalars['Boolean']>;
};

export type CreateRoleInput = {
  readonly kind: RoleKind;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly resourceId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly policy: Scalars['JSON'];
};

export type CreateSegmentInput = {
  readonly resourceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly filter: Scalars['JSON'];
};

export type CreateSessionInput = {
  readonly workspaceId: Scalars['UUID'];
  readonly email: Scalars['String'];
  readonly password: Scalars['String'];
  readonly userAgent?: Maybe<Scalars['String']>;
};

export type CreateStoredPreferenceInput = {
  readonly accountId: Scalars['UUID'];
  readonly preferenceId?: Maybe<Scalars['UUID']>;
  readonly isEnabled: Scalars['Boolean'];
  readonly channels: ReadonlyArray<DeliveryChannel>;
};

export type CreateTemplateInput = {
  readonly projectId?: Maybe<Scalars['UUID']>;
  readonly kind: TemplateKind;
  readonly subkind: TemplateSubkind;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly settings?: Maybe<Scalars['JSON']>;
};

export type CreateThemeInput = {
  readonly name: Scalars['String'];
  readonly palette: ThemePaletteInput;
};

export type CreateTransactionInput = {
  readonly orderId: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly amount: Scalars['Decimal'];
  readonly status: TransactionStatus;
};

export type CreateViewInput = {
  readonly dashboardId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly defaultStyle?: Maybe<ViewStyle>;
};

export type CreateWorkspacePaymentMethodInput = {
  readonly token: Scalars['String'];
};

export type Currency = {
  readonly __typename?: 'Currency';
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
  readonly symbol: Scalars['String'];
};

export type CustomOrder = {
  readonly __typename?: 'CustomOrder';
  readonly id: Scalars['UUID'];
  readonly subtotal: Scalars['Decimal'];
  readonly discount: Scalars['Decimal'];
  readonly tax: Scalars['Decimal'];
  readonly total: Scalars['Decimal'];
  readonly currencyCode: Scalars['String'];
  readonly orderItems: ReadonlyArray<CustomOrderItem>;
};

export type CustomOrderItem = {
  readonly __typename?: 'CustomOrderItem';
  readonly id: Scalars['UUID'];
  readonly itemId: Scalars['UUID'];
  readonly pricingId: Scalars['UUID'];
  readonly quantity: Scalars['Decimal'];
  readonly unitPrice: Scalars['Decimal'];
  readonly subtotal: Scalars['Decimal'];
  readonly discount: Scalars['Decimal'];
  readonly tax: Scalars['Decimal'];
  readonly total: Scalars['Decimal'];
  readonly currencyCode: Scalars['String'];
  readonly item: Item;
};

export type CustomRecord = {
  readonly __typename?: 'CustomRecord';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly environmentId?: Maybe<Scalars['UUID']>;
  readonly resourceId: Scalars['UUID'];
  readonly data: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly publishedId?: Maybe<Scalars['UUID']>;
  readonly latestId?: Maybe<Scalars['UUID']>;
  readonly resource: Resource;
};

export type CustomRole = {
  readonly __typename?: 'CustomRole';
  readonly id: Scalars['UUID'];
  readonly kind: RoleKind;
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly policy: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type Dashboard = {
  readonly __typename?: 'Dashboard';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly position: Scalars['Int'];
  readonly menuElements: ReadonlyArray<MenuElement>;
};


export type DashboardMenuElementsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type DataType = {
  readonly __typename?: 'DataType';
  readonly id: Scalars['UUID'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly kind: DataTypeKind;
  readonly settings: Scalars['JSON'];
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly validations: ReadonlyArray<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly isPrimitive: Scalars['Boolean'];
  readonly allowableFieldTypes: ReadonlyArray<Scalars['String']>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly category: DataTypeCategory;
  readonly position: Scalars['Int'];
};

export type DataTypeCategory = 
  | 'BASIC'
  | 'NUMBER'
  | 'DATE_TIME'
  | 'FILE'
  | 'OTHER'
  | 'ADVANCED';

export type DataTypeKind = 
  | 'ID'
  | 'UUID'
  | 'STRING'
  | 'BOOLEAN'
  | 'INT'
  | 'BIGINT'
  | 'FLOAT'
  | 'BIGDECIMAL'
  | 'DATE'
  | 'TIME'
  | 'TIMESTAMP'
  | 'DURATION'
  | 'BINARY'
  | 'IMAGE'
  | 'VIDEO'
  | 'AUDIO'
  | 'DOCUMENT'
  | 'SPREADSHEET'
  | 'PRESENTATION'
  | 'FILE'
  | 'JSON'
  | 'ENUM'
  | 'OBJECT'
  | 'UNION';

export type DatabaseColumn = {
  readonly __typename?: 'DatabaseColumn';
  readonly id: Scalars['UUID'];
  readonly databaseTableId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly dbMeta: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly position: Scalars['Int'];
  readonly databaseTable: DatabaseTable;
};

export type DatabaseSchema = {
  readonly __typename?: 'DatabaseSchema';
  readonly id: Scalars['UUID'];
  readonly integrationId: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly integration: Installation;
  readonly environment: Environment;
};

export type DatabaseScratchpad = {
  readonly __typename?: 'DatabaseScratchpad';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly integrationId: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly body: Scalars['String'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly title: Scalars['String'];
  readonly integration: Installation;
  readonly account: Account;
};

export type DatabaseTable = {
  readonly __typename?: 'DatabaseTable';
  readonly id: Scalars['UUID'];
  readonly integrationId: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly databaseSchemaId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly integration: Installation;
  readonly environment: Environment;
  readonly databaseSchema?: Maybe<DatabaseSchema>;
  readonly databaseColumns: ReadonlyArray<DatabaseColumn>;
};


export type DatabaseTableDatabaseColumnsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type DatabaseTableExecuteQueryResult = {
  readonly __typename?: 'DatabaseTableExecuteQueryResult';
  readonly message?: Maybe<Scalars['String']>;
  readonly success: Scalars['Boolean'];
  readonly rowsAffected: Scalars['Int'];
  readonly rows: ReadonlyArray<Scalars['JSON']>;
  readonly columns: ReadonlyArray<Scalars['String']>;
};


export type DeleteDatabaseRecordInput = {
  readonly databaseTableId: Scalars['UUID'];
  readonly id: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type Delivery = {
  readonly __typename?: 'Delivery';
  readonly id: Scalars['UUID'];
  readonly content?: Maybe<Scalars['JSON']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly status: DeliveryStatus;
  readonly queuedNotificationsCount: Scalars['Int'];
  readonly deliveredNotificationsCount: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly name?: Maybe<Scalars['String']>;
  readonly failureReason?: Maybe<Scalars['String']>;
  readonly isTest: Scalars['Boolean'];
  readonly scheduledAt?: Maybe<Scalars['Timestamp']>;
  readonly environmentId: Scalars['UUID'];
  readonly failedNotificationsCount: Scalars['Int'];
  readonly sentNotificationsCount: Scalars['Int'];
  readonly templateId?: Maybe<Scalars['UUID']>;
  readonly templateSubkind: TemplateSubkind;
  readonly custom: Scalars['JSON'];
  readonly openedNotificationsCount: Scalars['Int'];
  readonly clickedNotificationsCount: Scalars['Int'];
  readonly environment: Environment;
  readonly recipients: ReadonlyArray<Recipient>;
};


export type DeliveryRecipientsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type DeliveryChannel = 
  | 'EMAIL'
  | 'SMS'
  | 'PUSH'
  | 'WHATSAPP'
  | 'IN_APP';

export type DeliveryStatus = 
  | 'DRAFT'
  | 'SCHEDULED'
  | 'PENDING'
  | 'QUEUED'
  | 'PROCESSED'
  | 'FAILED';

export type DestroyApiRequestInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyAssetInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyAttributeInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyConfigurationInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyContactInput = {
  readonly id: Scalars['UUID'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type DestroyCouponCodeInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyCouponInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyCouponRedemptionInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyCouponRestrictionInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyDashboardInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyDataTypeInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyDatabaseScratchpadInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyDeliveryInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyEventTypeInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyGroupInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyGroupMembershipInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyItemCategoryInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyItemCategoryMembershipInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyItemInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyMenuElementInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyOrderInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyOrderItemInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyParameterInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyPreferenceInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyPricingInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyRecipientInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyRecordInput = {
  readonly id: Scalars['UUID'];
  readonly resourceId: Scalars['UUID'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type DestroyRelationshipInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyResourceInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyRoleInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyRoleMembershipInput = {
  readonly id: Scalars['UUID'];
};

export type DestroySegmentInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyStoredPreferenceInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyTemplateInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyThemeInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyTransactionInput = {
  readonly id: Scalars['UUID'];
};

export type DestroyWorkspacePaymentMethodInput = {
  readonly id: Scalars['UUID'];
};

export type DisableMfaInput = {
  readonly code: Scalars['String'];
};

export type DisplayType = 
  | 'PLAIN_TEXT'
  | 'NUMERIC'
  | 'SENSITIVE_TEXT'
  | 'LINK'
  | 'RENDERED_HTML'
  | 'RENDERED_MARKDOWN'
  | 'CODE'
  | 'PHONE_NUMBER'
  | 'SWITCH'
  | 'CHIP'
  | 'DATE_TIME'
  | 'DURATION'
  | 'EMBEDDED'
  | 'REFERENCE'
  | 'IMAGE'
  | 'CURRENCY'
  | 'FILE'
  | 'DOCUMENT'
  | 'AUDIO'
  | 'VIDEO';

export type DuplicateFlowVersionInput = {
  readonly id: Scalars['UUID'];
};

export type EditAccountTourInput = {
  readonly id: Scalars['UUID'];
  readonly tourName: AccountTourType;
  readonly step: Scalars['Int'];
  readonly status: AccountTourStatus;
};

export type EditDatabaseRecordInput = {
  readonly databaseTableId: Scalars['UUID'];
  readonly data: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type EditItemInCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  readonly pricingId?: Maybe<Scalars['UUID']>;
  readonly quantity?: Maybe<Scalars['Decimal']>;
};

export type EnableMfaInput = {
  readonly code: Scalars['String'];
};

export type EnableMfaResponse = {
  readonly __typename?: 'EnableMfaResponse';
  readonly mfaBackupCodes: ReadonlyArray<Scalars['String']>;
};

export type Environment = {
  readonly __typename?: 'Environment';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly isLive: Scalars['Boolean'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type Event = {
  readonly __typename?: 'Event';
  readonly id: Scalars['UUID'];
  readonly eventTypeId: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly data: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly timestamp: Scalars['Timestamp'];
  readonly environmentId: Scalars['UUID'];
  readonly eventType: EventType;
  readonly account: Account;
  readonly operationLog?: Maybe<OperationLog>;
};

export type EventReport = {
  readonly __typename?: 'EventReport';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly criteria: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type EventReportBehavior = {
  readonly __typename?: 'EventReportBehavior';
  readonly series: ReadonlyArray<EventReportSeries>;
  readonly dateRange: EventReportDateRange;
};

export type EventReportCriteriaInput = {
  readonly kind: EventReportKind;
  readonly period: EventReportPeriodInput;
  readonly scale: EventReportScale;
  readonly sections?: Maybe<ReadonlyArray<EventReportSectionInput>>;
  readonly steps?: Maybe<ReadonlyArray<EventReportStepInput>>;
  readonly startEventTypeId?: Maybe<Scalars['UUID']>;
  readonly retentionEvents?: Maybe<ReadonlyArray<Scalars['UUID']>>;
};

export type EventReportDateRange = {
  readonly __typename?: 'EventReportDateRange';
  readonly from: Scalars['Timestamp'];
  readonly to: Scalars['Timestamp'];
};

export type EventReportFunnel = {
  readonly __typename?: 'EventReportFunnel';
  readonly steps: ReadonlyArray<EventReportFunnelStep>;
};

export type EventReportFunnelStep = {
  readonly __typename?: 'EventReportFunnelStep';
  readonly count: Scalars['Int'];
  readonly label: Scalars['String'];
  readonly overallConvRatio: Scalars['Float'];
  readonly stepConvRatio: Scalars['Float'];
};

export type EventReportKind = 
  | 'BEHAVIOR'
  | 'FUNNEL';

export type EventReportMeasure = 
  | 'TOTAL'
  | 'UNIQUE';

export type EventReportPeriodInput = {
  readonly kind: EventReportPeriodKind;
  readonly from?: Maybe<Scalars['String']>;
  readonly to?: Maybe<Scalars['String']>;
  readonly window?: Maybe<EventReportPeriodWindowInput>;
};

export type EventReportPeriodKind = 
  | 'FIXED'
  | 'LAST';

export type EventReportPeriodWindowInput = {
  readonly unit: EventReportPeriodWindowUnit;
  readonly value: Scalars['Int'];
};

export type EventReportPeriodWindowUnit = 
  | 'DAY'
  | 'HOUR'
  | 'WEEK'
  | 'MONTH';

export type EventReportScale = 
  | 'DAY'
  | 'HOUR'
  | 'WEEK'
  | 'MONTH';

export type EventReportSectionInput = {
  readonly eventTypeId: Scalars['UUID'];
  readonly measure: EventReportMeasure;
  readonly label: Scalars['String'];
};

export type EventReportSeries = {
  readonly __typename?: 'EventReportSeries';
  readonly label: Scalars['String'];
  readonly values: ReadonlyArray<Scalars['JSON']>;
};

export type EventReportStepInput = {
  readonly eventTypeId: Scalars['UUID'];
  readonly label?: Maybe<Scalars['String']>;
  readonly next?: Maybe<Scalars['Int']>;
  readonly previous?: Maybe<Scalars['Int']>;
};

export type EventType = {
  readonly __typename?: 'EventType';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly lastReceivedAt?: Maybe<Scalars['Timestamp']>;
  readonly archivedAt?: Maybe<Scalars['Timestamp']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly operationId?: Maybe<Scalars['UUID']>;
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly dataSchema?: Maybe<Scalars['JSON']>;
};

export type ExecuteApiRequestInput = {
  readonly integrationId: Scalars['UUID'];
  readonly path: Scalars['String'];
  readonly method: Scalars['String'];
  readonly headers?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly queryParams?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly body?: Maybe<Scalars['String']>;
};

export type ExecuteApiRequestResponse = {
  readonly __typename?: 'ExecuteApiRequestResponse';
  readonly time: Scalars['Float'];
  readonly statusCode: Scalars['Int'];
  readonly headers: ReadonlyArray<Scalars['JSON']>;
  readonly body: Scalars['String'];
};

export type ExecuteCodeInput = {
  readonly code: Scalars['String'];
  readonly arguments: Scalars['JSON'];
};

export type ExecuteCodeResponse = {
  readonly __typename?: 'ExecuteCodeResponse';
  readonly time: Scalars['Float'];
  readonly result: Scalars['JSON'];
};

export type ExecuteDatabaseQueryInput = {
  readonly integrationId: Scalars['UUID'];
  readonly query: Scalars['String'];
};

export type ExecuteDatabaseQueryResponse = {
  readonly __typename?: 'ExecuteDatabaseQueryResponse';
  readonly time: Scalars['Float'];
  readonly results: ReadonlyArray<DatabaseTableExecuteQueryResult>;
};

export type ExecuteEventReportInput = {
  readonly criteria: EventReportCriteriaInput;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type ExecuteEventReportResponse = {
  readonly __typename?: 'ExecuteEventReportResponse';
  readonly behaviorReport?: Maybe<EventReportBehavior>;
  readonly funnelReport?: Maybe<EventReportFunnel>;
  readonly flowReport?: Maybe<EventReportFunnel>;
  readonly retentionReport?: Maybe<Scalars['JSON']>;
};

export type ExecuteFlowInput = {
  readonly flowId: Scalars['UUID'];
  readonly arguments: Scalars['JSON'];
  readonly scheduledAt?: Maybe<Scalars['Timestamp']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type ExecuteMutationOperationInput = {
  readonly operationId: Scalars['UUID'];
  readonly arguments: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type ExecuteQueryOperationInput = {
  readonly operationId: Scalars['UUID'];
  readonly arguments: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type ExportDatabaseRecordsInput = {
  readonly databaseTableId: Scalars['UUID'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly page?: Maybe<Scalars['Int']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type ExportDatabaseRecordsResponse = {
  readonly __typename?: 'ExportDatabaseRecordsResponse';
  readonly success: Scalars['Boolean'];
};

export type ExternalLog = {
  readonly __typename?: 'ExternalLog';
  readonly id: Scalars['UUID'];
  readonly installationId: Scalars['UUID'];
  readonly kind: ExternalLogKind;
  readonly timeTaken?: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly requestUid?: Maybe<Scalars['String']>;
  readonly requestParts?: Maybe<Scalars['JSON']>;
  readonly requestBody?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly responseParts?: Maybe<Scalars['JSON']>;
  readonly responseBody?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly status: ExternalLogStatus;
  readonly statusReason?: Maybe<Scalars['String']>;
  readonly environmentId: Scalars['UUID'];
};

export type ExternalLogKind = 
  | 'INBOUND'
  | 'OUTBOUND';

export type ExternalLogStatus = 
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETED'
  | 'FAILED'
  | 'DROPPED';

export type FetchCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly orderId?: Maybe<Scalars['UUID']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type FetchContactsInput = {
  readonly uid: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type FetchContactsResponse = {
  readonly __typename?: 'FetchContactsResponse';
  readonly contacts: ReadonlyArray<Contact>;
};

export type FetchInAppNotificationsAggregateInput = {
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly accountUid: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type FetchInAppNotificationsAggregateResponse = {
  readonly __typename?: 'FetchInAppNotificationsAggregateResponse';
  readonly count?: Maybe<Scalars['Int']>;
};

export type FetchInAppNotificationsInput = {
  readonly accountUid: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type FetchItemInput = {
  readonly identifier: Scalars['String'];
};

export type FetchRecordInput = {
  readonly recordId: Scalars['UUID'];
  readonly resource?: Maybe<Scalars['String']>;
  readonly preview?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly include?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly exclude?: Maybe<ReadonlyArray<Scalars['JSON']>>;
};

export type FetchStoredPreferencesInput = {
  readonly accountUid: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type FetchStoredPreferencesResponse = {
  readonly __typename?: 'FetchStoredPreferencesResponse';
  readonly preferenceData: Scalars['JSON'];
};

export type FieldType = {
  readonly __typename?: 'FieldType';
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly icon: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly category: FieldTypeCategory;
  readonly dataType: FieldTypeDataType;
  readonly defaultValue?: Maybe<Scalars['String']>;
  readonly placeholder?: Maybe<Scalars['String']>;
  readonly helpText?: Maybe<Scalars['String']>;
  readonly configuration: Scalars['JSON'];
  readonly settings: Scalars['JSON'];
};

export type FieldTypeCategory = 
  | 'BASIC'
  | 'ADVANCED'
  | 'OTHER';

export type FieldTypeDataType = 
  | 'STRING'
  | 'NUMBER'
  | 'BOOLEAN'
  | 'JSON';

export type Flow = {
  readonly __typename?: 'Flow';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly status: FlowVersionStatus;
  readonly runCount: Scalars['Int'];
  readonly lastRunAt?: Maybe<Scalars['Timestamp']>;
  readonly workspaceId: Scalars['UUID'];
  readonly flowVersions: ReadonlyArray<FlowVersion>;
};


export type FlowFlowVersionsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type FlowExecution = {
  readonly __typename?: 'FlowExecution';
  readonly id: Scalars['UUID'];
  readonly flowVersionId: Scalars['UUID'];
  readonly failureReason?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly enteredAt?: Maybe<Scalars['Timestamp']>;
  readonly exitedAt?: Maybe<Scalars['Timestamp']>;
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly status: FlowExecutionStatus;
  readonly environmentId: Scalars['UUID'];
  readonly scheduledAt?: Maybe<Scalars['Timestamp']>;
  readonly flowVersion: FlowVersion;
  readonly environment: Environment;
};

export type FlowExecutionNode = {
  readonly __typename?: 'FlowExecutionNode';
  readonly id: Scalars['UUID'];
  readonly flowExecutionId: Scalars['UUID'];
  readonly flowNodeId: Scalars['UUID'];
  readonly data: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly status: FlowExecutionNodeStatus;
  readonly enteredAt?: Maybe<Scalars['Timestamp']>;
  readonly exitedAt?: Maybe<Scalars['Timestamp']>;
  readonly failureReason?: Maybe<Scalars['String']>;
  readonly scheduledAt?: Maybe<Scalars['Timestamp']>;
  readonly timeoutAt?: Maybe<Scalars['Timestamp']>;
  readonly flowExecution: FlowExecution;
  readonly flowNode: FlowNode;
};

export type FlowExecutionNodeStatus = 
  | 'PENDING'
  | 'COMPLETED'
  | 'FAILED';

export type FlowExecutionStatus = 
  | 'RUNNING'
  | 'PAUSED'
  | 'SCHEDULED'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELED';

export type FlowNode = {
  readonly __typename?: 'FlowNode';
  readonly id: Scalars['UUID'];
  readonly flowVersionId: Scalars['UUID'];
  readonly identifier: Scalars['String'];
  readonly kind: FlowNodeKind;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly eventTypeId?: Maybe<Scalars['UUID']>;
  readonly settings: Scalars['JSON'];
  readonly description?: Maybe<Scalars['String']>;
  readonly summary?: Maybe<Scalars['String']>;
  readonly occurrenceKind: FlowNodeOccurrenceKind;
  readonly occurrenceCount: Scalars['Int'];
  readonly occurrenceWindowPeriod: FlowNodeOccurrenceWindowPeriod;
  readonly occurrenceWindowAmount: Scalars['Int'];
  readonly waitBehavior: FlowNodeWaitBehavior;
  readonly waitForPeriod: FlowNodeWaitForPeriod;
  readonly waitForAmount: Scalars['String'];
  readonly waitUntilPeriod: FlowNodeWaitUntilPeriod;
  readonly waitUntilMinute?: Maybe<Scalars['String']>;
  readonly waitUntilHour?: Maybe<Scalars['String']>;
  readonly waitUntilDay?: Maybe<Scalars['String']>;
  readonly operationId?: Maybe<Scalars['UUID']>;
  readonly occurrenceScope: FlowNodeOccurrenceScope;
  readonly repeatability: FlowNodeRepeatability;
  readonly waitUntilWeekday?: Maybe<FlowNodeWaitUntilWeekday>;
  readonly occurrenceBehavior: FlowNodeOccurrenceBehavior;
  readonly arguments?: Maybe<Scalars['JSON']>;
  readonly dataFilter?: Maybe<Scalars['JSON']>;
  readonly isConcurrent: Scalars['Boolean'];
};

export type FlowNodeKind = 
  | 'MANUAL_TRIGGER'
  | 'TIME_TRIGGER'
  | 'EVENT_TRIGGER'
  | 'TIME_STEP'
  | 'EVENT_STEP'
  | 'ACTION_STEP'
  | 'BRANCH_STEP'
  | 'ITERATOR_START_STEP'
  | 'ITERATOR_END_STEP'
  | 'EXIT_STEP';

export type FlowNodeOccurrenceBehavior = 
  | 'MUST'
  | 'MUST_NOT';

export type FlowNodeOccurrenceKind = 
  | 'EXACTLY'
  | 'AT_LEAST';

export type FlowNodeOccurrenceScope = 
  | 'TRIGGER_ACCOUNT'
  | 'ANY_ACCOUNT'
  | 'CUSTOM';

export type FlowNodeOccurrenceWindowPeriod = 
  | 'LIFETIME'
  | 'YEAR'
  | 'MONTH'
  | 'WEEK'
  | 'DAY'
  | 'HOUR'
  | 'MINUTE'
  | 'SECOND';

export type FlowNodeRepeatability = 
  | 'ONE'
  | 'MANY';

export type FlowNodeWaitBehavior = 
  | 'WAIT_FOR'
  | 'WAIT_UNTIL';

export type FlowNodeWaitForPeriod = 
  | 'DAY'
  | 'YEAR'
  | 'MONTH'
  | 'WEEK'
  | 'HOUR'
  | 'MINUTE'
  | 'SECOND';

export type FlowNodeWaitUntilPeriod = 
  | 'HOUR'
  | 'DAY'
  | 'WEEK'
  | 'MONTH';

export type FlowNodeWaitUntilWeekday = 
  | 'MO'
  | 'TU'
  | 'WE'
  | 'TH'
  | 'FR'
  | 'SA'
  | 'SU';

export type FlowVersion = {
  readonly __typename?: 'FlowVersion';
  readonly id: Scalars['UUID'];
  readonly flowId: Scalars['UUID'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly status: FlowVersionStatus;
  readonly version: Scalars['Timestamp'];
  readonly snapshot: Scalars['JSON'];
  readonly runCount: Scalars['Int'];
  readonly lastRunAt?: Maybe<Scalars['Timestamp']>;
  readonly flow: Flow;
};

export type FlowVersionStatus = 
  | 'DRAFT'
  | 'PUBLISHED'
  | 'UNPUBLISHING'
  | 'UNPUBLISHED';

export type ForgotPasswordInput = {
  readonly workspaceId: Scalars['UUID'];
  readonly email: Scalars['String'];
};

export type ForgotPasswordResponse = {
  readonly __typename?: 'ForgotPasswordResponse';
  readonly success: Scalars['Boolean'];
};

export type GenerateGraphInput = {
  readonly originKind: Scalars['String'];
  readonly originIds: ReadonlyArray<Scalars['UUID']>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly settings?: Maybe<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type GenerateGraphResponse = {
  readonly __typename?: 'GenerateGraphResponse';
  readonly resources: ReadonlyArray<Resource>;
  readonly operations: ReadonlyArray<Operation>;
};

export type GenerateIdentityTokenInput = {
  readonly publicKey: Scalars['String'];
  readonly privateKey: Scalars['String'];
  readonly kind: IdentityKind;
  readonly uid: Scalars['String'];
};

export type GenerateIdentityTokenResponse = {
  readonly __typename?: 'GenerateIdentityTokenResponse';
  readonly token: Scalars['String'];
};

export type GenerateMenuElementsInput = {
  readonly dashboardId: Scalars['UUID'];
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly placement?: Maybe<MenuElementPlacement>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly resourceMenuStubs?: Maybe<ReadonlyArray<ResourceMenuStubInput>>;
  readonly position?: Maybe<Scalars['Int']>;
};

export type Group = {
  readonly __typename?: 'Group';
  readonly id: Scalars['UUID'];
  readonly environmentId: Scalars['UUID'];
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly accountKind: AccountKind;
};

export type GroupMembership = {
  readonly __typename?: 'GroupMembership';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly groupId: Scalars['UUID'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly account: Account;
  readonly group: Group;
};

export type IdentifyAccountInput = {
  readonly uid?: Maybe<Scalars['String']>;
  readonly anonymousUid?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly systemContext?: Maybe<SystemContextInput>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type IdentifyProspectInput = {
  readonly email: Scalars['String'];
};

export type IdentifyProspectResponse = {
  readonly __typename?: 'IdentifyProspectResponse';
  readonly success: Scalars['Boolean'];
};

export type IdentityKind = 
  | 'USER'
  | 'VISITOR';

export type Installation = {
  readonly __typename?: 'Installation';
  readonly id: Scalars['UUID'];
  readonly appId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly archivedAt?: Maybe<Scalars['Timestamp']>;
  readonly workspaceId: Scalars['UUID'];
  readonly appKind: AppKind;
  readonly fields: ReadonlyArray<Scalars['JSON']>;
  readonly settings: Scalars['JSON'];
  readonly app: App;
};

export type InternalAddRecordInput = {
  readonly resourceId: Scalars['UUID'];
  readonly arguments: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalBulkEditRecordsInput = {
  readonly resourceId: Scalars['UUID'];
  readonly records: ReadonlyArray<BulkEditRecordStubInput>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalDeleteRecordInput = {
  readonly resourceId: Scalars['UUID'];
  readonly arguments: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalEditRecordInput = {
  readonly resourceId: Scalars['UUID'];
  readonly versionId?: Maybe<Scalars['UUID']>;
  readonly arguments: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalFetchRecordInput = {
  readonly resourceId: Scalars['UUID'];
  readonly arguments: Scalars['JSON'];
  readonly preview?: Maybe<Scalars['Boolean']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalImportRecordsInput = {
  readonly resourceId: Scalars['UUID'];
  readonly headers: ReadonlyArray<Scalars['String']>;
  readonly rows: ReadonlyArray<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalSearchRecordsInput = {
  readonly resourceId: Scalars['UUID'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly page?: Maybe<Scalars['Int']>;
  readonly preview?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalSummarizeRecordsInput = {
  readonly resourceId: Scalars['UUID'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type InternalSummarizeRecordsResponse = {
  readonly __typename?: 'InternalSummarizeRecordsResponse';
  readonly count: Scalars['Int'];
};

export type Item = {
  readonly __typename?: 'Item';
  readonly id: Scalars['UUID'];
  readonly kind: ItemKind;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly workspaceId: Scalars['UUID'];
  readonly itemCategoryMemberships: ReadonlyArray<ItemCategoryMembership>;
  readonly pricings: ReadonlyArray<Pricing>;
};


export type ItemItemCategoryMembershipsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type ItemPricingsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ItemCategory = {
  readonly __typename?: 'ItemCategory';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly workspaceId: Scalars['UUID'];
};

export type ItemCategoryMembership = {
  readonly __typename?: 'ItemCategoryMembership';
  readonly id: Scalars['UUID'];
  readonly itemId: Scalars['UUID'];
  readonly itemCategoryId: Scalars['UUID'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly item: Item;
  readonly itemCategory: ItemCategory;
};

export type ItemKind = 
  | 'GOODS'
  | 'SERVICES';


export type KeyPair = {
  readonly __typename?: 'KeyPair';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly publicKey: Scalars['String'];
  readonly privateKey: Scalars['String'];
  readonly expiresAt?: Maybe<Scalars['Timestamp']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type Locale = {
  readonly __typename?: 'Locale';
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
};

export type MenuElement = {
  readonly __typename?: 'MenuElement';
  readonly id: Scalars['UUID'];
  readonly dashboardId: Scalars['UUID'];
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly viewStyle?: Maybe<ViewStyle>;
  readonly name?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly kind: MenuElementKind;
  readonly separatorStyle?: Maybe<MenuElementSeparatorStyle>;
  readonly placement: MenuElementPlacement;
  readonly target?: Maybe<MenuElementTarget>;
  readonly url?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly query?: Maybe<Scalars['String']>;
  readonly actions: ReadonlyArray<MenuElementAction>;
  readonly isRepeated: Scalars['Boolean'];
  readonly isSticky: Scalars['Boolean'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly viewUrn?: Maybe<Scalars['String']>;
};

export type MenuElementAction = {
  readonly __typename?: 'MenuElementAction';
  readonly icon: Scalars['String'];
  readonly name: Scalars['String'];
  readonly action: Scalars['String'];
};

export type MenuElementActionInput = {
  readonly icon: Scalars['String'];
  readonly name: Scalars['String'];
  readonly action: Scalars['String'];
};

export type MenuElementKind = 
  | 'ITEM'
  | 'GROUP'
  | 'SEPARATOR';

export type MenuElementPlacement = 
  | 'SIDE'
  | 'TOP';

export type MenuElementSeparatorStyle = 
  | 'RULER'
  | 'WHITESPACE';

export type MenuElementTarget = 
  | 'VIEW'
  | 'SUBMENU'
  | 'URL';

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly identifyProspect: IdentifyProspectResponse;
  readonly verifyProspect: Prospect;
  readonly registerWorkspace: RegisterWorkspaceResponse;
  readonly updateWorkspaceTheme: Workspace;
  readonly updateWorkspaceBranding: Workspace;
  readonly updateWorkspaceDomain: Workspace;
  readonly saveWorkspaceLocale: Workspace;
  readonly removeWorkspaceLocale: Workspace;
  readonly updateWorkspaceOnboarding: Workspace;
  readonly createApp: App;
  readonly updateApp: App;
  readonly createEnvironment: Environment;
  readonly updateEnvironment: Environment;
  readonly createInstallation: Installation;
  readonly updateInstallation: Installation;
  readonly archiveInstallation: Installation;
  readonly unarchiveInstallation: Installation;
  readonly createConfiguration: Configuration;
  readonly updateConfiguration: Configuration;
  readonly destroyConfiguration: Configuration;
  readonly configureInstallation: Configuration;
  readonly createDashboard: Dashboard;
  readonly updateDashboard: Dashboard;
  readonly destroyDashboard: Dashboard;
  readonly createView: View;
  readonly upsertView: View;
  readonly createMenuElement: MenuElement;
  readonly updateMenuElement: MenuElement;
  readonly destroyMenuElement: MenuElement;
  readonly generateMenuElements: ReadonlyArray<MenuElement>;
  readonly createTheme: Theme;
  readonly updateTheme: Theme;
  readonly destroyTheme: Theme;
  readonly createGroup: Group;
  readonly updateGroup: Group;
  readonly destroyGroup: Group;
  readonly forgotPassword: ForgotPasswordResponse;
  readonly updateAccount: Account;
  readonly identifyAccount: Account;
  readonly changePassword: Account;
  readonly changeEmail: ChangeEmailResponse;
  readonly verifyEmailChange: Account;
  readonly createAccount: Account;
  readonly completeOnboarding: Account;
  readonly updateAccountAvatar: Account;
  readonly submitContactForm: SubmitContactFormResponse;
  readonly editAccountTour: Account;
  readonly saveWorkspaceBillingAccount: SaveWorkspaceBillingAccountResponse;
  readonly configureMfa: ConfigureMfaResponse;
  readonly enableMfa: EnableMfaResponse;
  readonly disableMfa: Account;
  readonly createGroupMembership: GroupMembership;
  readonly destroyGroupMembership: GroupMembership;
  readonly createSession: Session;
  readonly verifySession: Session;
  readonly revokeOtherSessions: RevokeOtherSessionsResponse;
  readonly revokeSession: Session;
  readonly resetPassword: Session;
  readonly startOnboarding: Session;
  readonly updateSession: Session;
  readonly createKeyPair: KeyPair;
  readonly revokeKeyPair: KeyPair;
  readonly createContact: Contact;
  readonly updateContact: Contact;
  readonly destroyContact: Contact;
  readonly saveContacts: SaveContactsResponse;
  readonly subscribeContact: Contact;
  readonly unsubscribeContact: Contact;
  readonly destroyAsset: Asset;
  readonly prepareAsset: Asset;
  readonly updateEventType: EventType;
  readonly destroyEventType: EventType;
  readonly createEventType: EventType;
  readonly trackEvent: TrackEventResponse;
  readonly createEventReport: EventReport;
  readonly updateEventReport: EventReport;
  readonly createResource: Resource;
  readonly updateResource: Resource;
  readonly destroyResource: Resource;
  readonly generateGraph: GenerateGraphResponse;
  readonly createOperation: Operation;
  readonly updateOperation: Operation;
  readonly executeMutationOperation: Scalars['JSON'];
  readonly writeCsv: WriteCsvResponse;
  readonly executeCode: ExecuteCodeResponse;
  readonly createAttribute: Attribute;
  readonly updateAttribute: Attribute;
  readonly destroyAttribute: Attribute;
  readonly createParameter: Parameter;
  readonly updateParameter: Parameter;
  readonly destroyParameter: Parameter;
  readonly createRelationship: Relationship;
  readonly updateRelationship: Relationship;
  readonly destroyRelationship: Relationship;
  readonly createRecord: CustomRecord;
  readonly updateRecord: Record;
  readonly destroyRecord: Record;
  readonly internalAddRecord: CustomRecord;
  readonly internalEditRecord: CustomRecord;
  readonly internalBulkEditRecords: ReadonlyArray<CustomRecord>;
  readonly internalDeleteRecord: CustomRecord;
  readonly internalImportRecords: ReadonlyArray<CustomRecord>;
  readonly publishRecordVersion: CustomRecord;
  readonly unpublishRecordVersion: CustomRecord;
  readonly restoreRecordVersion: CustomRecord;
  readonly updateFlow: Flow;
  readonly createFlow: Flow;
  readonly executeFlow: FlowExecution;
  readonly createFlowVersion: FlowVersion;
  readonly updateFlowVersion: FlowVersion;
  readonly publishFlowVersion: FlowVersion;
  readonly unpublishFlowVersion: FlowVersion;
  readonly duplicateFlowVersion: FlowVersion;
  readonly pauseFlowExecution: FlowExecution;
  readonly resumeFlowExecution: FlowExecution;
  readonly cancelFlowExecution: FlowExecution;
  readonly createDelivery: Delivery;
  readonly updateDelivery: Delivery;
  readonly startDelivery: Delivery;
  readonly destroyDelivery: Delivery;
  readonly createRecipient: Recipient;
  readonly updateRecipient: Recipient;
  readonly destroyRecipient: Recipient;
  readonly trackNotification: TrackNotificationResponse;
  readonly updatePreference: Preference;
  readonly createPreference: Preference;
  readonly destroyPreference: Preference;
  readonly updateStoredPreference: StoredPreference;
  readonly createStoredPreference: StoredPreference;
  readonly destroyStoredPreference: StoredPreference;
  readonly saveStoredPreferences: SaveStoredPreferencesResponse;
  readonly createItem: Item;
  readonly updateItem: Item;
  readonly destroyItem: Item;
  readonly createPricing: Pricing;
  readonly updatePricing: Pricing;
  readonly destroyPricing: Pricing;
  readonly createItemCategory: ItemCategory;
  readonly updateItemCategory: ItemCategory;
  readonly destroyItemCategory: ItemCategory;
  readonly createItemCategoryMembership: ItemCategoryMembership;
  readonly updateItemCategoryMembership: ItemCategoryMembership;
  readonly destroyItemCategoryMembership: ItemCategoryMembership;
  readonly createCoupon: Coupon;
  readonly updateCoupon: Coupon;
  readonly destroyCoupon: Coupon;
  readonly createCouponCode: CouponCode;
  readonly updateCouponCode: CouponCode;
  readonly destroyCouponCode: CouponCode;
  readonly createCouponRestriction: CouponRestriction;
  readonly updateCouponRestriction: CouponRestriction;
  readonly destroyCouponRestriction: CouponRestriction;
  readonly createCouponRedemption: CouponRedemption;
  readonly updateCouponRedemption: CouponRedemption;
  readonly destroyCouponRedemption: CouponRedemption;
  readonly createOrder: Order;
  readonly updateOrder: Order;
  readonly destroyOrder: Order;
  readonly transferCart: Order;
  readonly addItemToCart: Order;
  readonly editItemInCart: Order;
  readonly removeItemFromCart: Order;
  readonly applyCouponToCart: Order;
  readonly removeCouponFromCart: Order;
  readonly checkoutCart: Order;
  readonly capturePayment: Order;
  readonly createOrderItem: OrderItem;
  readonly updateOrderItem: OrderItem;
  readonly destroyOrderItem: OrderItem;
  readonly createTransaction: Transaction;
  readonly updateTransaction: Transaction;
  readonly destroyTransaction: Transaction;
  readonly createWorkspacePaymentMethod: PaymentMethod;
  readonly updateWorkspacePaymentMethod: PaymentMethod;
  readonly destroyWorkspacePaymentMethod: PaymentMethod;
  readonly prepareWorkspacePaymentMethod: PrepareWorkspacePaymentMethodResponse;
  readonly setWorkspacePaymentMethodAsDefault: PaymentMethod;
  readonly createRole: CustomRole;
  readonly updateRole: CustomRole;
  readonly destroyRole: CustomRole;
  readonly destroyRoleMembership: RoleMembership;
  readonly assignRoles: AssignRolesResponse;
  readonly createSegment: Segment;
  readonly updateSegment: Segment;
  readonly destroySegment: Segment;
  readonly createApiImport: ApiImport;
  readonly createApiRequest: ApiRequest;
  readonly updateApiRequest: ApiRequest;
  readonly destroyApiRequest: ApiRequest;
  readonly executeApiRequest: ExecuteApiRequestResponse;
  readonly createDatabaseScratchpad: DatabaseScratchpad;
  readonly updateDatabaseScratchpad: DatabaseScratchpad;
  readonly destroyDatabaseScratchpad: DatabaseScratchpad;
  readonly executeDatabaseQuery: ExecuteDatabaseQueryResponse;
  readonly addDatabaseRecord: Scalars['JSON'];
  readonly editDatabaseRecord: Scalars['JSON'];
  readonly deleteDatabaseRecord: Scalars['JSON'];
  readonly createDataType: DataType;
  readonly updateDataType: DataType;
  readonly destroyDataType: DataType;
  readonly createTemplate: Template;
  readonly updateTemplate: Template;
  readonly destroyTemplate: Template;
  readonly publishTemplateVersion: Template;
  readonly unpublishTemplateVersion: Template;
  readonly restoreTemplateVersion: Template;
};


export type MutationIdentifyProspectArgs = {
  input: IdentifyProspectInput;
};


export type MutationVerifyProspectArgs = {
  input: VerifyProspectInput;
};


export type MutationRegisterWorkspaceArgs = {
  input: RegisterWorkspaceInput;
};


export type MutationUpdateWorkspaceThemeArgs = {
  input: UpdateWorkspaceThemeInput;
};


export type MutationUpdateWorkspaceBrandingArgs = {
  input: UpdateWorkspaceBrandingInput;
};


export type MutationUpdateWorkspaceDomainArgs = {
  input: UpdateWorkspaceDomainInput;
};


export type MutationSaveWorkspaceLocaleArgs = {
  input: SaveWorkspaceLocaleInput;
};


export type MutationRemoveWorkspaceLocaleArgs = {
  input: RemoveWorkspaceLocaleInput;
};


export type MutationUpdateWorkspaceOnboardingArgs = {
  input: UpdateWorkspaceOnboardingInput;
};


export type MutationCreateAppArgs = {
  input: CreateAppInput;
};


export type MutationUpdateAppArgs = {
  input: UpdateAppInput;
};


export type MutationCreateEnvironmentArgs = {
  input: CreateEnvironmentInput;
};


export type MutationUpdateEnvironmentArgs = {
  input: UpdateEnvironmentInput;
};


export type MutationCreateInstallationArgs = {
  input: CreateInstallationInput;
};


export type MutationUpdateInstallationArgs = {
  input: UpdateInstallationInput;
};


export type MutationArchiveInstallationArgs = {
  input: ArchiveInstallationInput;
};


export type MutationUnarchiveInstallationArgs = {
  input: UnarchiveInstallationInput;
};


export type MutationCreateConfigurationArgs = {
  input: CreateConfigurationInput;
};


export type MutationUpdateConfigurationArgs = {
  input: UpdateConfigurationInput;
};


export type MutationDestroyConfigurationArgs = {
  input: DestroyConfigurationInput;
};


export type MutationConfigureInstallationArgs = {
  input: ConfigureInstallationInput;
};


export type MutationCreateDashboardArgs = {
  input: CreateDashboardInput;
};


export type MutationUpdateDashboardArgs = {
  input: UpdateDashboardInput;
};


export type MutationDestroyDashboardArgs = {
  input: DestroyDashboardInput;
};


export type MutationCreateViewArgs = {
  input: CreateViewInput;
};


export type MutationUpsertViewArgs = {
  input: UpsertViewInput;
};


export type MutationCreateMenuElementArgs = {
  input: CreateMenuElementInput;
};


export type MutationUpdateMenuElementArgs = {
  input: UpdateMenuElementInput;
};


export type MutationDestroyMenuElementArgs = {
  input: DestroyMenuElementInput;
};


export type MutationGenerateMenuElementsArgs = {
  input: GenerateMenuElementsInput;
};


export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};


export type MutationUpdateThemeArgs = {
  input: UpdateThemeInput;
};


export type MutationDestroyThemeArgs = {
  input: DestroyThemeInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationDestroyGroupArgs = {
  input: DestroyGroupInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationIdentifyAccountArgs = {
  input: IdentifyAccountInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationVerifyEmailChangeArgs = {
  input: VerifyEmailChangeInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCompleteOnboardingArgs = {
  input: CompleteOnboardingInput;
};


export type MutationUpdateAccountAvatarArgs = {
  input: UpdateAccountAvatarInput;
};


export type MutationSubmitContactFormArgs = {
  input: SubmitContactFormInput;
};


export type MutationEditAccountTourArgs = {
  input: EditAccountTourInput;
};


export type MutationSaveWorkspaceBillingAccountArgs = {
  input: SaveWorkspaceBillingAccountInput;
};


export type MutationEnableMfaArgs = {
  input: EnableMfaInput;
};


export type MutationDisableMfaArgs = {
  input: DisableMfaInput;
};


export type MutationCreateGroupMembershipArgs = {
  input: CreateGroupMembershipInput;
};


export type MutationDestroyGroupMembershipArgs = {
  input: DestroyGroupMembershipInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationVerifySessionArgs = {
  input: VerifySessionInput;
};


export type MutationRevokeSessionArgs = {
  input: RevokeSessionInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationStartOnboardingArgs = {
  input: StartOnboardingInput;
};


export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};


export type MutationCreateKeyPairArgs = {
  input: CreateKeyPairInput;
};


export type MutationRevokeKeyPairArgs = {
  input: RevokeKeyPairInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationDestroyContactArgs = {
  input: DestroyContactInput;
};


export type MutationSaveContactsArgs = {
  input: SaveContactsInput;
};


export type MutationSubscribeContactArgs = {
  input: SubscribeContactInput;
};


export type MutationUnsubscribeContactArgs = {
  input: UnsubscribeContactInput;
};


export type MutationDestroyAssetArgs = {
  input: DestroyAssetInput;
};


export type MutationPrepareAssetArgs = {
  input: PrepareAssetInput;
};


export type MutationUpdateEventTypeArgs = {
  input: UpdateEventTypeInput;
};


export type MutationDestroyEventTypeArgs = {
  input: DestroyEventTypeInput;
};


export type MutationCreateEventTypeArgs = {
  input: CreateEventTypeInput;
};


export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


export type MutationCreateEventReportArgs = {
  input: CreateEventReportInput;
};


export type MutationUpdateEventReportArgs = {
  input: UpdateEventReportInput;
};


export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};


export type MutationDestroyResourceArgs = {
  input: DestroyResourceInput;
};


export type MutationGenerateGraphArgs = {
  input: GenerateGraphInput;
};


export type MutationCreateOperationArgs = {
  input: CreateOperationInput;
};


export type MutationUpdateOperationArgs = {
  input: UpdateOperationInput;
};


export type MutationExecuteMutationOperationArgs = {
  input: ExecuteMutationOperationInput;
};


export type MutationWriteCsvArgs = {
  input: WriteCsvInput;
};


export type MutationExecuteCodeArgs = {
  input: ExecuteCodeInput;
};


export type MutationCreateAttributeArgs = {
  input: CreateAttributeInput;
};


export type MutationUpdateAttributeArgs = {
  input: UpdateAttributeInput;
};


export type MutationDestroyAttributeArgs = {
  input: DestroyAttributeInput;
};


export type MutationCreateParameterArgs = {
  input: CreateParameterInput;
};


export type MutationUpdateParameterArgs = {
  input: UpdateParameterInput;
};


export type MutationDestroyParameterArgs = {
  input: DestroyParameterInput;
};


export type MutationCreateRelationshipArgs = {
  input: CreateRelationshipInput;
};


export type MutationUpdateRelationshipArgs = {
  input: UpdateRelationshipInput;
};


export type MutationDestroyRelationshipArgs = {
  input: DestroyRelationshipInput;
};


export type MutationCreateRecordArgs = {
  input: CreateRecordInput;
};


export type MutationUpdateRecordArgs = {
  input: UpdateRecordInput;
};


export type MutationDestroyRecordArgs = {
  input: DestroyRecordInput;
};


export type MutationInternalAddRecordArgs = {
  input: InternalAddRecordInput;
};


export type MutationInternalEditRecordArgs = {
  input: InternalEditRecordInput;
};


export type MutationInternalBulkEditRecordsArgs = {
  input: InternalBulkEditRecordsInput;
};


export type MutationInternalDeleteRecordArgs = {
  input: InternalDeleteRecordInput;
};


export type MutationInternalImportRecordsArgs = {
  input: InternalImportRecordsInput;
};


export type MutationPublishRecordVersionArgs = {
  input: PublishRecordVersionInput;
};


export type MutationUnpublishRecordVersionArgs = {
  input: UnpublishRecordVersionInput;
};


export type MutationRestoreRecordVersionArgs = {
  input: RestoreRecordVersionInput;
};


export type MutationUpdateFlowArgs = {
  input: UpdateFlowInput;
};


export type MutationCreateFlowArgs = {
  input: CreateFlowInput;
};


export type MutationExecuteFlowArgs = {
  input: ExecuteFlowInput;
};


export type MutationCreateFlowVersionArgs = {
  input: CreateFlowVersionInput;
};


export type MutationUpdateFlowVersionArgs = {
  input: UpdateFlowVersionInput;
};


export type MutationPublishFlowVersionArgs = {
  input: PublishFlowVersionInput;
};


export type MutationUnpublishFlowVersionArgs = {
  input: UnpublishFlowVersionInput;
};


export type MutationDuplicateFlowVersionArgs = {
  input: DuplicateFlowVersionInput;
};


export type MutationPauseFlowExecutionArgs = {
  input: PauseFlowExecutionInput;
};


export type MutationResumeFlowExecutionArgs = {
  input: ResumeFlowExecutionInput;
};


export type MutationCancelFlowExecutionArgs = {
  input: CancelFlowExecutionInput;
};


export type MutationCreateDeliveryArgs = {
  input: CreateDeliveryInput;
};


export type MutationUpdateDeliveryArgs = {
  input: UpdateDeliveryInput;
};


export type MutationStartDeliveryArgs = {
  input: StartDeliveryInput;
};


export type MutationDestroyDeliveryArgs = {
  input: DestroyDeliveryInput;
};


export type MutationCreateRecipientArgs = {
  input: CreateRecipientInput;
};


export type MutationUpdateRecipientArgs = {
  input: UpdateRecipientInput;
};


export type MutationDestroyRecipientArgs = {
  input: DestroyRecipientInput;
};


export type MutationTrackNotificationArgs = {
  input: TrackNotificationInput;
};


export type MutationUpdatePreferenceArgs = {
  input: UpdatePreferenceInput;
};


export type MutationCreatePreferenceArgs = {
  input: CreatePreferenceInput;
};


export type MutationDestroyPreferenceArgs = {
  input: DestroyPreferenceInput;
};


export type MutationUpdateStoredPreferenceArgs = {
  input: UpdateStoredPreferenceInput;
};


export type MutationCreateStoredPreferenceArgs = {
  input: CreateStoredPreferenceInput;
};


export type MutationDestroyStoredPreferenceArgs = {
  input: DestroyStoredPreferenceInput;
};


export type MutationSaveStoredPreferencesArgs = {
  input: SaveStoredPreferencesInput;
};


export type MutationCreateItemArgs = {
  input: CreateItemInput;
};


export type MutationUpdateItemArgs = {
  input: UpdateItemInput;
};


export type MutationDestroyItemArgs = {
  input: DestroyItemInput;
};


export type MutationCreatePricingArgs = {
  input: CreatePricingInput;
};


export type MutationUpdatePricingArgs = {
  input: UpdatePricingInput;
};


export type MutationDestroyPricingArgs = {
  input: DestroyPricingInput;
};


export type MutationCreateItemCategoryArgs = {
  input: CreateItemCategoryInput;
};


export type MutationUpdateItemCategoryArgs = {
  input: UpdateItemCategoryInput;
};


export type MutationDestroyItemCategoryArgs = {
  input: DestroyItemCategoryInput;
};


export type MutationCreateItemCategoryMembershipArgs = {
  input: CreateItemCategoryMembershipInput;
};


export type MutationUpdateItemCategoryMembershipArgs = {
  input: UpdateItemCategoryMembershipInput;
};


export type MutationDestroyItemCategoryMembershipArgs = {
  input: DestroyItemCategoryMembershipInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};


export type MutationDestroyCouponArgs = {
  input: DestroyCouponInput;
};


export type MutationCreateCouponCodeArgs = {
  input: CreateCouponCodeInput;
};


export type MutationUpdateCouponCodeArgs = {
  input: UpdateCouponCodeInput;
};


export type MutationDestroyCouponCodeArgs = {
  input: DestroyCouponCodeInput;
};


export type MutationCreateCouponRestrictionArgs = {
  input: CreateCouponRestrictionInput;
};


export type MutationUpdateCouponRestrictionArgs = {
  input: UpdateCouponRestrictionInput;
};


export type MutationDestroyCouponRestrictionArgs = {
  input: DestroyCouponRestrictionInput;
};


export type MutationCreateCouponRedemptionArgs = {
  input: CreateCouponRedemptionInput;
};


export type MutationUpdateCouponRedemptionArgs = {
  input: UpdateCouponRedemptionInput;
};


export type MutationDestroyCouponRedemptionArgs = {
  input: DestroyCouponRedemptionInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationDestroyOrderArgs = {
  input: DestroyOrderInput;
};


export type MutationTransferCartArgs = {
  input: TransferCartInput;
};


export type MutationAddItemToCartArgs = {
  input: AddItemToCartInput;
};


export type MutationEditItemInCartArgs = {
  input: EditItemInCartInput;
};


export type MutationRemoveItemFromCartArgs = {
  input: RemoveItemFromCartInput;
};


export type MutationApplyCouponToCartArgs = {
  input: ApplyCouponToCartInput;
};


export type MutationRemoveCouponFromCartArgs = {
  input: RemoveCouponFromCartInput;
};


export type MutationCheckoutCartArgs = {
  input: CheckoutCartInput;
};


export type MutationCapturePaymentArgs = {
  input: CapturePaymentInput;
};


export type MutationCreateOrderItemArgs = {
  input: CreateOrderItemInput;
};


export type MutationUpdateOrderItemArgs = {
  input: UpdateOrderItemInput;
};


export type MutationDestroyOrderItemArgs = {
  input: DestroyOrderItemInput;
};


export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


export type MutationUpdateTransactionArgs = {
  input: UpdateTransactionInput;
};


export type MutationDestroyTransactionArgs = {
  input: DestroyTransactionInput;
};


export type MutationCreateWorkspacePaymentMethodArgs = {
  input: CreateWorkspacePaymentMethodInput;
};


export type MutationUpdateWorkspacePaymentMethodArgs = {
  input: UpdateWorkspacePaymentMethodInput;
};


export type MutationDestroyWorkspacePaymentMethodArgs = {
  input: DestroyWorkspacePaymentMethodInput;
};


export type MutationSetWorkspacePaymentMethodAsDefaultArgs = {
  input: SetWorkspacePaymentMethodAsDefaultInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationDestroyRoleArgs = {
  input: DestroyRoleInput;
};


export type MutationDestroyRoleMembershipArgs = {
  input: DestroyRoleMembershipInput;
};


export type MutationAssignRolesArgs = {
  input: AssignRolesInput;
};


export type MutationCreateSegmentArgs = {
  input: CreateSegmentInput;
};


export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};


export type MutationDestroySegmentArgs = {
  input: DestroySegmentInput;
};


export type MutationCreateApiImportArgs = {
  input: CreateApiImportInput;
};


export type MutationCreateApiRequestArgs = {
  input: CreateApiRequestInput;
};


export type MutationUpdateApiRequestArgs = {
  input: UpdateApiRequestInput;
};


export type MutationDestroyApiRequestArgs = {
  input: DestroyApiRequestInput;
};


export type MutationExecuteApiRequestArgs = {
  input: ExecuteApiRequestInput;
};


export type MutationCreateDatabaseScratchpadArgs = {
  input: CreateDatabaseScratchpadInput;
};


export type MutationUpdateDatabaseScratchpadArgs = {
  input: UpdateDatabaseScratchpadInput;
};


export type MutationDestroyDatabaseScratchpadArgs = {
  input: DestroyDatabaseScratchpadInput;
};


export type MutationExecuteDatabaseQueryArgs = {
  input: ExecuteDatabaseQueryInput;
};


export type MutationAddDatabaseRecordArgs = {
  input: AddDatabaseRecordInput;
};


export type MutationEditDatabaseRecordArgs = {
  input: EditDatabaseRecordInput;
};


export type MutationDeleteDatabaseRecordArgs = {
  input: DeleteDatabaseRecordInput;
};


export type MutationCreateDataTypeArgs = {
  input: CreateDataTypeInput;
};


export type MutationUpdateDataTypeArgs = {
  input: UpdateDataTypeInput;
};


export type MutationDestroyDataTypeArgs = {
  input: DestroyDataTypeInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationDestroyTemplateArgs = {
  input: DestroyTemplateInput;
};


export type MutationPublishTemplateVersionArgs = {
  input: PublishTemplateVersionInput;
};


export type MutationUnpublishTemplateVersionArgs = {
  input: UnpublishTemplateVersionInput;
};


export type MutationRestoreTemplateVersionArgs = {
  input: RestoreTemplateVersionInput;
};


export type Notification = {
  readonly __typename?: 'Notification';
  readonly id: Scalars['UUID'];
  readonly deliveryId: Scalars['UUID'];
  readonly integrationId: Scalars['UUID'];
  readonly data?: Maybe<Scalars['JSON']>;
  readonly renderedContent: Scalars['JSON'];
  readonly report?: Maybe<Scalars['JSON']>;
  readonly failedSendAttempts: Scalars['Int'];
  readonly lastSendFailureAt?: Maybe<Scalars['Timestamp']>;
  readonly sentAt?: Maybe<Scalars['Timestamp']>;
  readonly deliveredAt?: Maybe<Scalars['Timestamp']>;
  readonly firstOpenedAt?: Maybe<Scalars['Timestamp']>;
  readonly externalUid?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly queuedAt?: Maybe<Scalars['Timestamp']>;
  readonly lastDeliveryFailureAt?: Maybe<Scalars['Timestamp']>;
  readonly failedDeliveryAttempts?: Maybe<Scalars['Int']>;
  readonly failedAt?: Maybe<Scalars['Timestamp']>;
  readonly failureReason?: Maybe<Scalars['String']>;
  readonly firstClickedAt?: Maybe<Scalars['Timestamp']>;
  readonly complainedAt?: Maybe<Scalars['Timestamp']>;
  readonly unsubscribedAt?: Maybe<Scalars['Timestamp']>;
  readonly dismissedAt?: Maybe<Scalars['Timestamp']>;
  readonly readAt?: Maybe<Scalars['Timestamp']>;
  readonly delivery: Delivery;
  readonly notificationRecipients: ReadonlyArray<NotificationRecipient>;
};


export type NotificationNotificationRecipientsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type NotificationLog = {
  readonly __typename?: 'NotificationLog';
  readonly id: Scalars['UUID'];
  readonly notificationId: Scalars['UUID'];
  readonly integrationId: Scalars['UUID'];
  readonly category: NotificationLogCategory;
  readonly subcategory?: Maybe<NotificationLogSubcategory>;
  readonly data: Scalars['JSON'];
  readonly timestamp: Scalars['Timestamp'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly contact?: Maybe<Scalars['String']>;
  readonly notificationRecipientId?: Maybe<Scalars['UUID']>;
  readonly notification: Notification;
  readonly notificationRecipient?: Maybe<NotificationRecipient>;
  readonly integration: Installation;
};

export type NotificationLogCategory = 
  | 'SEND_FAILED'
  | 'SENT'
  | 'REJECTED'
  | 'BOUNCED'
  | 'DELIVERY_FAILED'
  | 'DELIVERED'
  | 'OPENED'
  | 'CLICKED'
  | 'COMPLAINED'
  | 'SUBSCRIPTION_CHANGED'
  | 'UNKNOWN';

export type NotificationLogSubcategory = 
  | 'HARD_BOUNCED'
  | 'SOFT_BOUNCED'
  | 'OTHER_BOUNCED'
  | 'UNSUBSCRIBED'
  | 'SUBSCRIBED'
  | 'GROUP_UNSUBSCRIBED'
  | 'GROUP_SUBSCRIBED'
  | 'FAILED_DND';

export type NotificationRecipient = {
  readonly __typename?: 'NotificationRecipient';
  readonly id: Scalars['UUID'];
  readonly notificationId: Scalars['UUID'];
  readonly recipientId: Scalars['UUID'];
  readonly contactId?: Maybe<Scalars['UUID']>;
  readonly contact: Scalars['String'];
  readonly readAt?: Maybe<Scalars['Timestamp']>;
  readonly externalUid?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly lastDeliveryFailureAt?: Maybe<Scalars['Timestamp']>;
  readonly failedDeliveryAttempts?: Maybe<Scalars['Int']>;
  readonly deliveredAt?: Maybe<Scalars['Timestamp']>;
  readonly failedAt?: Maybe<Scalars['Timestamp']>;
  readonly failureReason?: Maybe<Scalars['String']>;
  readonly complainedAt?: Maybe<Scalars['Timestamp']>;
  readonly recipient: Recipient;
};

export type Operation = {
  readonly __typename?: 'Operation';
  readonly id: Scalars['UUID'];
  readonly resourceId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly graphqlKind: OperationGraphqlKind;
  readonly actsOn?: Maybe<OperationActsOn>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly behaviorKind?: Maybe<OperationBehaviorKind>;
  readonly behaviorMethod: OperationBehaviorMethod;
  readonly behaviorSettings: Scalars['JSON'];
  readonly responseMapper?: Maybe<Scalars['JSON']>;
  readonly resource?: Maybe<Resource>;
  readonly app?: Maybe<App>;
  readonly parameters: ReadonlyArray<Parameter>;
};


export type OperationParametersArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type OperationActsOn = 
  | 'MEMBER'
  | 'COLLECTION';

export type OperationBehaviorKind = 
  | 'DATABASE_QUERY'
  | 'API_REQUEST'
  | 'CODE'
  | 'FLOW';

export type OperationBehaviorMethod = 
  | 'GENERIC'
  | 'GET'
  | 'LIST'
  | 'AGGREGATE'
  | 'CREATE'
  | 'UPDATE'
  | 'DESTROY'
  | 'IMPORT';

export type OperationGraphqlKind = 
  | 'QUERY'
  | 'MUTATION';

export type OperationLog = {
  readonly __typename?: 'OperationLog';
  readonly id: Scalars['UUID'];
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly keyPairId?: Maybe<Scalars['UUID']>;
  readonly operationId: Scalars['UUID'];
  readonly operationInstanceId?: Maybe<Scalars['UUID']>;
  readonly systemContext?: Maybe<SystemContext>;
  readonly status: OperationLogStatus;
  readonly statusReason?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly keyPair?: Maybe<KeyPair>;
  readonly account?: Maybe<Account>;
  readonly operation: Operation;
};

export type OperationLogStatus = 
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETED'
  | 'FAILED';

export type Order = {
  readonly __typename?: 'Order';
  readonly id: Scalars['UUID'];
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly gatewayId?: Maybe<Scalars['UUID']>;
  readonly gatewayMeta?: Maybe<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly subtotal: Scalars['Decimal'];
  readonly discount: Scalars['Decimal'];
  readonly tax: Scalars['Decimal'];
  readonly total: Scalars['Decimal'];
  readonly status: OrderStatus;
  readonly paidAt?: Maybe<Scalars['Timestamp']>;
  readonly checkedOutAt?: Maybe<Scalars['Timestamp']>;
  readonly currencyCode: Scalars['String'];
  readonly createdById?: Maybe<Scalars['UUID']>;
  readonly environmentId: Scalars['UUID'];
  readonly paymentLink?: Maybe<Scalars['String']>;
  readonly orderItems: ReadonlyArray<OrderItem>;
  readonly couponRedemptions: ReadonlyArray<CouponRedemption>;
  readonly account?: Maybe<Account>;
  readonly createdBy?: Maybe<Account>;
};


export type OrderOrderItemsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type OrderCouponRedemptionsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type OrderItem = {
  readonly __typename?: 'OrderItem';
  readonly id: Scalars['UUID'];
  readonly orderId: Scalars['UUID'];
  readonly itemId: Scalars['UUID'];
  readonly pricingId: Scalars['UUID'];
  readonly quantity: Scalars['Decimal'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly unitPrice: Scalars['Decimal'];
  readonly subtotal: Scalars['Decimal'];
  readonly discount: Scalars['Decimal'];
  readonly tax: Scalars['Decimal'];
  readonly total: Scalars['Decimal'];
  readonly custom: Scalars['JSON'];
  readonly currencyCode: Scalars['String'];
  readonly order: Order;
  readonly item: Item;
  readonly pricing: Pricing;
};

export type OrderStatus = 
  | 'DRAFT'
  | 'INITIAL'
  | 'CHECKED_OUT'
  | 'PAID'
  | 'CANCELED';

export type OrderStyle = 
  | 'CREATION_TIME'
  | 'SPECIFIC_FIELDS'
  | 'LINEAR_DRAG_AND_DROP'
  | 'TREE_DRAG_AND_DROP';

export type OrdersAggregateByItemsInput = {
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly itemIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
};

export type OrdersAggregateByItemsResponse = {
  readonly __typename?: 'OrdersAggregateByItemsResponse';
  readonly count?: Maybe<Scalars['Int']>;
};

export type OrdersListByItemsInput = {
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly page?: Maybe<Scalars['Int']>;
  readonly itemIds?: Maybe<ReadonlyArray<Scalars['UUID']>>;
};

export type Parameter = {
  readonly __typename?: 'Parameter';
  readonly id: Scalars['UUID'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly operationId: Scalars['UUID'];
  readonly attributeId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly isNullable?: Maybe<Scalars['Boolean']>;
  readonly isArray?: Maybe<Scalars['Boolean']>;
  readonly validations: ReadonlyArray<Scalars['JSON']>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly dataTypeId?: Maybe<Scalars['UUID']>;
  readonly fieldTypeSettings: Scalars['JSON'];
  readonly displayType?: Maybe<DisplayType>;
  readonly displayTypeSettings?: Maybe<Scalars['JSON']>;
  readonly relationshipId?: Maybe<Scalars['UUID']>;
  readonly dataTypeSettings: Scalars['JSON'];
  readonly fieldType?: Maybe<Scalars['String']>;
  readonly operation: Operation;
  readonly attribute?: Maybe<Attribute>;
  readonly relationship?: Maybe<Relationship>;
  readonly dataType?: Maybe<DataType>;
};

export type ParameterStubInput = {
  readonly attributeId?: Maybe<Scalars['UUID']>;
  readonly relationshipId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly dataTypeId?: Maybe<Scalars['UUID']>;
  readonly dataTypeSettings?: Maybe<Scalars['JSON']>;
  readonly fieldType?: Maybe<Scalars['String']>;
  readonly fieldTypeSettings?: Maybe<Scalars['JSON']>;
  readonly displayType?: Maybe<DisplayType>;
  readonly displayTypeSettings?: Maybe<Scalars['JSON']>;
  readonly isNullable?: Maybe<Scalars['Boolean']>;
  readonly isArray?: Maybe<Scalars['Boolean']>;
  readonly validations?: Maybe<ReadonlyArray<ValidationInput>>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position?: Maybe<Scalars['Int']>;
};

export type ParseIdentityTokenInput = {
  readonly publicKey: Scalars['String'];
  readonly privateKey: Scalars['String'];
  readonly token: Scalars['String'];
};

export type ParseIdentityTokenResponse = {
  readonly __typename?: 'ParseIdentityTokenResponse';
  readonly kind: IdentityKind;
  readonly uid: Scalars['String'];
};

export type PauseFlowExecutionInput = {
  readonly id: Scalars['UUID'];
};

export type PaymentMethod = {
  readonly __typename?: 'PaymentMethod';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly isDefault: Scalars['Boolean'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly deletedAt?: Maybe<Scalars['Timestamp']>;
  readonly card?: Maybe<Card>;
};

export type Preference = {
  readonly __typename?: 'Preference';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly restrictions: ReadonlyArray<Scalars['UUID']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly isEnabledByDefault: Scalars['Boolean'];
  readonly workspaceId: Scalars['UUID'];
  readonly preferenceRestrictions: ReadonlyArray<PreferenceRestriction>;
};


export type PreferencePreferenceRestrictionsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type PreferenceRestriction = {
  readonly __typename?: 'PreferenceRestriction';
  readonly id: Scalars['UUID'];
  readonly preferenceId: Scalars['UUID'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly templateId: Scalars['UUID'];
  readonly template: Template;
};

export type PrepareAssetInput = {
  readonly resource?: Maybe<Scalars['String']>;
  readonly attribute?: Maybe<Scalars['String']>;
  readonly operation?: Maybe<Scalars['String']>;
  readonly parameter?: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly size: Scalars['Int'];
  readonly mimeType: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type PrepareWorkspacePaymentMethodResponse = {
  readonly __typename?: 'PrepareWorkspacePaymentMethodResponse';
  readonly token: Scalars['String'];
};

export type Pricing = {
  readonly __typename?: 'Pricing';
  readonly id: Scalars['UUID'];
  readonly itemId: Scalars['UUID'];
  readonly amount: Scalars['Decimal'];
  readonly currencyCode: Scalars['String'];
  readonly isRecurring: Scalars['Boolean'];
  readonly recurringInterval: Scalars['Int'];
  readonly recurringIntervalUnit: PricingRecurringIntervalUnit;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly originalAmount?: Maybe<Scalars['Decimal']>;
  readonly kind: PricingKind;
  readonly appleProductIdentifier?: Maybe<Scalars['String']>;
  readonly googleProductIdentifier?: Maybe<Scalars['String']>;
  readonly item: Item;
};

export type PricingKind = 
  | 'REGULAR'
  | 'IN_APP';

export type PricingRecurringIntervalUnit = 
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'YEAR';

export type Prospect = {
  readonly __typename?: 'Prospect';
  readonly id: Scalars['UUID'];
  readonly email: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly codeExpiresAt?: Maybe<Scalars['Timestamp']>;
  readonly token?: Maybe<Scalars['String']>;
  readonly expiresAt?: Maybe<Scalars['Timestamp']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type ProspectWorkspace = {
  readonly __typename?: 'ProspectWorkspace';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly accessUrl: Scalars['String'];
  readonly hostname?: Maybe<Scalars['String']>;
  readonly logoSymbol?: Maybe<Scalars['String']>;
};

export type PublishFlowVersionInput = {
  readonly id: Scalars['UUID'];
  readonly isGraceful: Scalars['Boolean'];
};

export type PublishRecordVersionInput = {
  readonly id: Scalars['UUID'];
};

export type PublishTemplateVersionInput = {
  readonly id: Scalars['UUID'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly workspace: Workspace;
  readonly workspacesList: ReadonlyArray<Workspace>;
  readonly workspacesAggregate: AggregateResponse;
  readonly generateIdentityToken: GenerateIdentityTokenResponse;
  readonly parseIdentityToken: ParseIdentityTokenResponse;
  readonly validateKeyPair: ValidateKeyPairResponse;
  readonly prospectWorkspaces: ReadonlyArray<ProspectWorkspace>;
  readonly app: App;
  readonly appsList: ReadonlyArray<App>;
  readonly appsAggregate: AggregateResponse;
  readonly appCategoriesList: ReadonlyArray<AppCategory>;
  readonly appCategoriesAggregate: AggregateResponse;
  readonly appCategory: AppCategory;
  readonly environment: Environment;
  readonly environmentsList: ReadonlyArray<Environment>;
  readonly environmentsAggregate: AggregateResponse;
  readonly installation: Installation;
  readonly resolveInstallation: Installation;
  readonly installationsList: ReadonlyArray<Installation>;
  readonly installationsAggregate: AggregateResponse;
  readonly configuration: Configuration;
  readonly configurationsList: ReadonlyArray<Configuration>;
  readonly configurationsAggregate: AggregateResponse;
  readonly testConfiguration: TestConfigurationResponse;
  readonly dashboard: Dashboard;
  readonly dashboardsList: ReadonlyArray<Dashboard>;
  readonly dashboardsAggregate: AggregateResponse;
  readonly view: View;
  readonly viewsList: ReadonlyArray<View>;
  readonly viewsAggregate: AggregateResponse;
  readonly resolveView?: Maybe<View>;
  readonly menuElement: MenuElement;
  readonly menuElementsList: ReadonlyArray<MenuElement>;
  readonly menuElementsAggregate: AggregateResponse;
  readonly theme: Theme;
  readonly themesList: ReadonlyArray<Theme>;
  readonly themesAggregate: AggregateResponse;
  readonly group: Group;
  readonly groupsList: ReadonlyArray<Group>;
  readonly groupsAggregate: AggregateResponse;
  readonly verifyResetPasswordToken: VerifyResetPasswordTokenResponse;
  readonly account: Account;
  readonly currentAccount: Account;
  readonly accountsList: ReadonlyArray<Account>;
  readonly accountsAggregate: AggregateResponse;
  readonly verifyInvitation: VerifyInvitationResponse;
  readonly fetchWorkspaceBillingAccount: WorkspaceBillingAccount;
  readonly retrieveMfaBackupCodes: RetrieveMfaBackupCodesResponse;
  readonly groupMembership: GroupMembership;
  readonly groupMembershipsList: ReadonlyArray<GroupMembership>;
  readonly groupMembershipsAggregate: AggregateResponse;
  readonly sessionsList: ReadonlyArray<Session>;
  readonly sessionsAggregate: AggregateResponse;
  readonly keyPairsList: ReadonlyArray<KeyPair>;
  readonly keyPairsAggregate: AggregateResponse;
  readonly keyPair: KeyPair;
  readonly contact: Contact;
  readonly contactsList: ReadonlyArray<Contact>;
  readonly contactsAggregate: AggregateResponse;
  readonly fetchContacts: FetchContactsResponse;
  readonly addressesList: ReadonlyArray<Address>;
  readonly addressesAggregate: AggregateResponse;
  readonly address: Address;
  readonly taxRegistrationsList: ReadonlyArray<TaxRegistration>;
  readonly taxRegistrationsAggregate: AggregateResponse;
  readonly taxRegistration: TaxRegistration;
  readonly timeZones: ReadonlyArray<TimeZone>;
  readonly fieldTypes: ReadonlyArray<FieldType>;
  readonly currencies: ReadonlyArray<Currency>;
  readonly externalLog: ExternalLog;
  readonly externalLogsList: ReadonlyArray<ExternalLog>;
  readonly externalLogsAggregate: AggregateResponse;
  readonly locales: ReadonlyArray<Locale>;
  readonly asset: Asset;
  readonly assetsList: ReadonlyArray<Asset>;
  readonly assetsAggregate: AggregateResponse;
  readonly eventType: EventType;
  readonly eventTypesList: ReadonlyArray<EventType>;
  readonly eventTypesAggregate: AggregateResponse;
  readonly event: Event;
  readonly eventsList: ReadonlyArray<Event>;
  readonly eventsAggregate: AggregateResponse;
  readonly eventReport: EventReport;
  readonly eventReportsList: ReadonlyArray<EventReport>;
  readonly eventReportsAggregate: AggregateResponse;
  readonly executeEventReport: ExecuteEventReportResponse;
  readonly resourcesList: ReadonlyArray<Resource>;
  readonly resourcesAggregate: AggregateResponse;
  readonly resource: Resource;
  readonly operationsList: ReadonlyArray<Operation>;
  readonly operationsAggregate: AggregateResponse;
  readonly operation: Operation;
  readonly executeQueryOperation: Scalars['JSON'];
  readonly readCsv: ReadCsvResponse;
  readonly attributesAggregate: AggregateResponse;
  readonly attributesList: ReadonlyArray<Attribute>;
  readonly attribute: Attribute;
  readonly parametersAggregate: AggregateResponse;
  readonly parametersList: ReadonlyArray<Parameter>;
  readonly parameter: Parameter;
  readonly relationshipsList: ReadonlyArray<Relationship>;
  readonly relationshipsAggregate: AggregateResponse;
  readonly relationship: Relationship;
  readonly recordsAggregate: AggregateResponse;
  readonly recordsList: ReadonlyArray<CustomRecord>;
  readonly record: Record;
  readonly internalSearchRecords: ReadonlyArray<CustomRecord>;
  readonly internalSummarizeRecords: InternalSummarizeRecordsResponse;
  readonly internalFetchRecord: CustomRecord;
  readonly searchRecords: ReadonlyArray<Scalars['JSON']>;
  readonly fetchRecord: Scalars['JSON'];
  readonly recordVersionsAggregate: AggregateResponse;
  readonly recordVersionsList: ReadonlyArray<RecordVersion>;
  readonly operationLog: OperationLog;
  readonly operationLogsList: ReadonlyArray<OperationLog>;
  readonly operationLogsAggregate: AggregateResponse;
  readonly searchOperationLogs: ReadonlyArray<OperationLog>;
  readonly summarizeOperationLogs: SummarizeOperationLogsResponse;
  readonly flow: Flow;
  readonly flowsList: ReadonlyArray<Flow>;
  readonly flowsAggregate: AggregateResponse;
  readonly flowVersion: FlowVersion;
  readonly flowVersionsList: ReadonlyArray<FlowVersion>;
  readonly flowVersionsAggregate: AggregateResponse;
  readonly flowNode: FlowNode;
  readonly flowNodesList: ReadonlyArray<FlowNode>;
  readonly flowNodesAggregate: AggregateResponse;
  readonly flowExecution: FlowExecution;
  readonly flowExecutionsList: ReadonlyArray<FlowExecution>;
  readonly flowExecutionsAggregate: AggregateResponse;
  readonly flowExecutionNode: FlowExecutionNode;
  readonly flowExecutionNodesList: ReadonlyArray<FlowExecutionNode>;
  readonly flowExecutionNodesAggregate: AggregateResponse;
  readonly delivery: Delivery;
  readonly deliveriesList: ReadonlyArray<Delivery>;
  readonly deliveriesAggregate: AggregateResponse;
  readonly recipient: Recipient;
  readonly recipientsList: ReadonlyArray<Recipient>;
  readonly recipientsAggregate: AggregateResponse;
  readonly notification: Notification;
  readonly notificationsList: ReadonlyArray<Notification>;
  readonly notificationsAggregate: AggregateResponse;
  readonly fetchInAppNotifications: ReadonlyArray<Notification>;
  readonly fetchInAppNotificationsAggregate: FetchInAppNotificationsAggregateResponse;
  readonly notificationRecipient: NotificationRecipient;
  readonly notificationRecipientsList: ReadonlyArray<NotificationRecipient>;
  readonly notificationRecipientsAggregate: AggregateResponse;
  readonly preference: Preference;
  readonly preferencesList: ReadonlyArray<Preference>;
  readonly preferencesAggregate: AggregateResponse;
  readonly storedPreference: StoredPreference;
  readonly storedPreferencesList: ReadonlyArray<StoredPreference>;
  readonly storedPreferencesAggregate: AggregateResponse;
  readonly fetchStoredPreferences: FetchStoredPreferencesResponse;
  readonly preferenceRestriction: PreferenceRestriction;
  readonly preferenceRestrictionsList: ReadonlyArray<PreferenceRestriction>;
  readonly preferenceRestrictionsAggregate: AggregateResponse;
  readonly notificationLog: NotificationLog;
  readonly notificationLogsList: ReadonlyArray<NotificationLog>;
  readonly notificationLogsAggregate: AggregateResponse;
  readonly item: Item;
  readonly fetchItem: Item;
  readonly itemsList: ReadonlyArray<Item>;
  readonly itemsAggregate: AggregateResponse;
  readonly pricing: Pricing;
  readonly pricingsList: ReadonlyArray<Pricing>;
  readonly pricingsAggregate: AggregateResponse;
  readonly itemCategory: ItemCategory;
  readonly itemCategoriesList: ReadonlyArray<ItemCategory>;
  readonly itemCategoriesAggregate: AggregateResponse;
  readonly itemCategoryMembership: ItemCategoryMembership;
  readonly itemCategoryMembershipsList: ReadonlyArray<ItemCategoryMembership>;
  readonly itemCategoryMembershipsAggregate: AggregateResponse;
  readonly coupon: Coupon;
  readonly couponsList: ReadonlyArray<Coupon>;
  readonly couponsAggregate: AggregateResponse;
  readonly couponCode: CouponCode;
  readonly couponCodesList: ReadonlyArray<CouponCode>;
  readonly couponCodesAggregate: AggregateResponse;
  readonly couponRestriction: CouponRestriction;
  readonly couponRestrictionsList: ReadonlyArray<CouponRestriction>;
  readonly couponRestrictionsAggregate: AggregateResponse;
  readonly couponRedemption: CouponRedemption;
  readonly couponRedemptionsList: ReadonlyArray<CouponRedemption>;
  readonly couponRedemptionsAggregate: AggregateResponse;
  readonly order: Order;
  readonly ordersList: ReadonlyArray<Order>;
  readonly ordersAggregate: AggregateResponse;
  readonly ordersListByItems: ReadonlyArray<Order>;
  readonly ordersAggregateByItems: OrdersAggregateByItemsResponse;
  readonly fetchCart: Order;
  readonly workspaceOrdersList: ReadonlyArray<Order>;
  readonly workspaceOrdersAggregate: AggregateResponse;
  readonly fetchCurrentWorkspaceOrder: CustomOrder;
  readonly orderItemsList: ReadonlyArray<OrderItem>;
  readonly transaction: Transaction;
  readonly transactionsList: ReadonlyArray<Transaction>;
  readonly transactionsAggregate: AggregateResponse;
  readonly workspacePaymentMethodsList: ReadonlyArray<PaymentMethod>;
  readonly workspacePaymentMethodsAggregate: AggregateResponse;
  readonly paymentMethod: PaymentMethod;
  readonly workspaceCardsList: ReadonlyArray<Card>;
  readonly workspaceCardsAggregate: AggregateResponse;
  readonly role: CustomRole;
  readonly rolesList: ReadonlyArray<CustomRole>;
  readonly rolesAggregate: AggregateResponse;
  readonly roleMembership: RoleMembership;
  readonly roleMembershipsList: ReadonlyArray<RoleMembership>;
  readonly roleMembershipsAggregate: AggregateResponse;
  readonly segment: Segment;
  readonly segmentsList: ReadonlyArray<Segment>;
  readonly segmentsAggregate: AggregateResponse;
  readonly apiImport: ApiImport;
  readonly apiRequest: ApiRequest;
  readonly apiRequestsList: ReadonlyArray<ApiRequest>;
  readonly apiRequestsAggregate: AggregateResponse;
  readonly databaseScratchpad: DatabaseScratchpad;
  readonly databaseScratchpadsList: ReadonlyArray<DatabaseScratchpad>;
  readonly databaseScratchpadsAggregate: AggregateResponse;
  readonly databaseSchema: DatabaseSchema;
  readonly databaseSchemasList: ReadonlyArray<DatabaseSchema>;
  readonly databaseSchemasAggregate: AggregateResponse;
  readonly databaseTable: DatabaseTable;
  readonly databaseTablesList: ReadonlyArray<DatabaseTable>;
  readonly databaseTablesAggregate: AggregateResponse;
  readonly searchDatabaseRecords: ReadonlyArray<Scalars['JSON']>;
  readonly exportDatabaseRecords: ExportDatabaseRecordsResponse;
  readonly summarizeDatabaseRecords: SummarizeDatabaseRecordsResponse;
  readonly databaseColumn: DatabaseColumn;
  readonly databaseColumnsList: ReadonlyArray<DatabaseColumn>;
  readonly databaseColumnsAggregate: AggregateResponse;
  readonly dataTypesList: ReadonlyArray<DataType>;
  readonly dataTypesAggregate: AggregateResponse;
  readonly dataType: DataType;
  readonly templatesList: ReadonlyArray<Template>;
  readonly templatesAggregate: AggregateResponse;
  readonly template: Template;
  readonly templateVersionsList: ReadonlyArray<TemplateVersion>;
  readonly templateVersionsAggregate: AggregateResponse;
  readonly templateVersion: TemplateVersion;
};


export type QueryWorkspaceArgs = {
  hostname: Scalars['String'];
};


export type QueryWorkspacesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryWorkspacesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryGenerateIdentityTokenArgs = {
  input: GenerateIdentityTokenInput;
};


export type QueryParseIdentityTokenArgs = {
  input: ParseIdentityTokenInput;
};


export type QueryValidateKeyPairArgs = {
  input: ValidateKeyPairInput;
};


export type QueryAppArgs = {
  id: Scalars['UUID'];
};


export type QueryAppsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAppsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryAppCategoriesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAppCategoriesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryAppCategoryArgs = {
  id: Scalars['UUID'];
};


export type QueryEnvironmentArgs = {
  id: Scalars['UUID'];
};


export type QueryEnvironmentsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryEnvironmentsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryInstallationArgs = {
  id: Scalars['UUID'];
};


export type QueryResolveInstallationArgs = {
  input: ResolveInstallationInput;
};


export type QueryInstallationsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryInstallationsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryConfigurationArgs = {
  id: Scalars['UUID'];
};


export type QueryConfigurationsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryConfigurationsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryTestConfigurationArgs = {
  input: TestConfigurationInput;
};


export type QueryDashboardArgs = {
  id: Scalars['UUID'];
};


export type QueryDashboardsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDashboardsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryViewArgs = {
  id: Scalars['UUID'];
};


export type QueryViewsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryViewsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryResolveViewArgs = {
  input: ResolveViewInput;
};


export type QueryMenuElementArgs = {
  id: Scalars['UUID'];
};


export type QueryMenuElementsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryMenuElementsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryThemeArgs = {
  id: Scalars['UUID'];
};


export type QueryThemesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryThemesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGroupsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryVerifyResetPasswordTokenArgs = {
  input: VerifyResetPasswordTokenInput;
};


export type QueryAccountArgs = {
  id: Scalars['UUID'];
};


export type QueryAccountsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryAccountsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryVerifyInvitationArgs = {
  input: VerifyInvitationInput;
};


export type QueryRetrieveMfaBackupCodesArgs = {
  input: RetrieveMfaBackupCodesInput;
};


export type QueryGroupMembershipArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupMembershipsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGroupMembershipsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QuerySessionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySessionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryKeyPairsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryKeyPairsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryKeyPairArgs = {
  id: Scalars['UUID'];
};


export type QueryContactArgs = {
  id: Scalars['UUID'];
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryContactsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryContactsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryFetchContactsArgs = {
  input: FetchContactsInput;
};


export type QueryAddressesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAddressesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryAddressArgs = {
  id: Scalars['UUID'];
};


export type QueryTaxRegistrationsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTaxRegistrationsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryTaxRegistrationArgs = {
  id: Scalars['UUID'];
};


export type QueryExternalLogArgs = {
  id: Scalars['UUID'];
};


export type QueryExternalLogsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryExternalLogsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryAssetArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAssetsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryEventTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryEventTypesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryEventTypesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryEventArgs = {
  id: Scalars['UUID'];
};


export type QueryEventsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryEventsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryEventReportArgs = {
  id: Scalars['UUID'];
};


export type QueryEventReportsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryEventReportsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryExecuteEventReportArgs = {
  input: ExecuteEventReportInput;
};


export type QueryResourcesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryResourcesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryResourceArgs = {
  id: Scalars['UUID'];
};


export type QueryOperationsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOperationsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryOperationArgs = {
  id: Scalars['UUID'];
};


export type QueryExecuteQueryOperationArgs = {
  input: ExecuteQueryOperationInput;
};


export type QueryReadCsvArgs = {
  input: ReadCsvInput;
};


export type QueryAttributesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryAttributesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAttributeArgs = {
  id: Scalars['UUID'];
};


export type QueryParametersAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryParametersListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryParameterArgs = {
  id: Scalars['UUID'];
};


export type QueryRelationshipsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryRelationshipsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryRelationshipArgs = {
  id: Scalars['UUID'];
};


export type QueryRecordsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryRecordsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryRecordArgs = {
  id: Scalars['UUID'];
  resourceId: Scalars['UUID'];
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryInternalSearchRecordsArgs = {
  input: InternalSearchRecordsInput;
};


export type QueryInternalSummarizeRecordsArgs = {
  input: InternalSummarizeRecordsInput;
};


export type QueryInternalFetchRecordArgs = {
  input: InternalFetchRecordInput;
};


export type QuerySearchRecordsArgs = {
  input: SearchRecordsInput;
};


export type QueryFetchRecordArgs = {
  input: FetchRecordInput;
};


export type QueryRecordVersionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryRecordVersionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOperationLogArgs = {
  id: Scalars['UUID'];
};


export type QueryOperationLogsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOperationLogsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QuerySearchOperationLogsArgs = {
  input: SearchOperationLogsInput;
};


export type QuerySummarizeOperationLogsArgs = {
  input: SummarizeOperationLogsInput;
};


export type QueryFlowArgs = {
  id: Scalars['UUID'];
};


export type QueryFlowsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryFlowsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryFlowVersionArgs = {
  id: Scalars['UUID'];
};


export type QueryFlowVersionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryFlowVersionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryFlowNodeArgs = {
  id: Scalars['UUID'];
};


export type QueryFlowNodesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryFlowNodesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryFlowExecutionArgs = {
  id: Scalars['UUID'];
};


export type QueryFlowExecutionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryFlowExecutionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryFlowExecutionNodeArgs = {
  id: Scalars['UUID'];
};


export type QueryFlowExecutionNodesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryFlowExecutionNodesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryDeliveryArgs = {
  id: Scalars['UUID'];
};


export type QueryDeliveriesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryDeliveriesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryRecipientArgs = {
  id: Scalars['UUID'];
};


export type QueryRecipientsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryRecipientsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryNotificationArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryNotificationsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryFetchInAppNotificationsArgs = {
  input: FetchInAppNotificationsInput;
};


export type QueryFetchInAppNotificationsAggregateArgs = {
  input: FetchInAppNotificationsAggregateInput;
};


export type QueryNotificationRecipientArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationRecipientsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryNotificationRecipientsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryPreferenceArgs = {
  id: Scalars['UUID'];
};


export type QueryPreferencesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPreferencesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryStoredPreferenceArgs = {
  id: Scalars['UUID'];
};


export type QueryStoredPreferencesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryStoredPreferencesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryFetchStoredPreferencesArgs = {
  input: FetchStoredPreferencesInput;
};


export type QueryPreferenceRestrictionArgs = {
  id: Scalars['UUID'];
};


export type QueryPreferenceRestrictionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPreferenceRestrictionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryNotificationLogArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationLogsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryNotificationLogsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryItemArgs = {
  id: Scalars['UUID'];
};


export type QueryFetchItemArgs = {
  input: FetchItemInput;
};


export type QueryItemsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryItemsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryPricingArgs = {
  id: Scalars['UUID'];
};


export type QueryPricingsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPricingsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryItemCategoryArgs = {
  id: Scalars['UUID'];
};


export type QueryItemCategoriesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryItemCategoriesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryItemCategoryMembershipArgs = {
  id: Scalars['UUID'];
};


export type QueryItemCategoryMembershipsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryItemCategoryMembershipsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryCouponArgs = {
  id: Scalars['UUID'];
};


export type QueryCouponsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryCouponsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryCouponCodeArgs = {
  id: Scalars['UUID'];
};


export type QueryCouponCodesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryCouponCodesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryCouponRestrictionArgs = {
  id: Scalars['UUID'];
};


export type QueryCouponRestrictionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryCouponRestrictionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryCouponRedemptionArgs = {
  id: Scalars['UUID'];
};


export type QueryCouponRedemptionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryCouponRedemptionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryOrderArgs = {
  id: Scalars['UUID'];
};


export type QueryOrdersListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryOrdersAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryOrdersListByItemsArgs = {
  input: OrdersListByItemsInput;
};


export type QueryOrdersAggregateByItemsArgs = {
  input: OrdersAggregateByItemsInput;
};


export type QueryFetchCartArgs = {
  input: FetchCartInput;
};


export type QueryWorkspaceOrdersListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryWorkspaceOrdersAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryOrderItemsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTransactionArgs = {
  id: Scalars['UUID'];
};


export type QueryTransactionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryTransactionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type QueryWorkspacePaymentMethodsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryWorkspacePaymentMethodsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryPaymentMethodArgs = {
  id: Scalars['UUID'];
};


export type QueryWorkspaceCardsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryWorkspaceCardsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


export type QueryRolesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryRolesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryRoleMembershipArgs = {
  id: Scalars['UUID'];
};


export type QueryRoleMembershipsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryRoleMembershipsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QuerySegmentArgs = {
  id: Scalars['UUID'];
};


export type QuerySegmentsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySegmentsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryApiImportArgs = {
  id: Scalars['UUID'];
};


export type QueryApiRequestArgs = {
  id: Scalars['UUID'];
};


export type QueryApiRequestsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryApiRequestsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryDatabaseScratchpadArgs = {
  id: Scalars['UUID'];
};


export type QueryDatabaseScratchpadsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDatabaseScratchpadsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryDatabaseSchemaArgs = {
  id: Scalars['UUID'];
};


export type QueryDatabaseSchemasListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['UUID']>;
};


export type QueryDatabaseSchemasAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['UUID']>;
};


export type QueryDatabaseTableArgs = {
  id: Scalars['UUID'];
};


export type QueryDatabaseTablesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDatabaseTablesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QuerySearchDatabaseRecordsArgs = {
  input: SearchDatabaseRecordsInput;
};


export type QueryExportDatabaseRecordsArgs = {
  input: ExportDatabaseRecordsInput;
};


export type QuerySummarizeDatabaseRecordsArgs = {
  input: SummarizeDatabaseRecordsInput;
};


export type QueryDatabaseColumnArgs = {
  id: Scalars['UUID'];
};


export type QueryDatabaseColumnsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDatabaseColumnsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryDataTypesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDataTypesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryDataTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryTemplatesListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTemplatesAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryTemplateArgs = {
  id: Scalars['UUID'];
};


export type QueryTemplateVersionsListArgs = {
  filter?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTemplateVersionsAggregateArgs = {
  filter?: Maybe<Scalars['JSON']>;
};


export type QueryTemplateVersionArgs = {
  id: Scalars['UUID'];
};

export type ReadCsvInput = {
  readonly url: Scalars['String'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly group?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly batchNumber?: Maybe<Scalars['Int']>;
};

export type ReadCsvResponse = {
  readonly __typename?: 'ReadCsvResponse';
  readonly rows: ReadonlyArray<Scalars['JSON']>;
  readonly totalRows: Scalars['Int'];
  readonly batchNumber: Scalars['Int'];
  readonly isLastBatch: Scalars['Boolean'];
};

export type Recipient = {
  readonly __typename?: 'Recipient';
  readonly id: Scalars['UUID'];
  readonly deliveryId: Scalars['UUID'];
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly groupId?: Maybe<Scalars['UUID']>;
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly target: RecipientTarget;
  readonly kind: RecipientKind;
  readonly status: RecipientStatus;
  readonly statusReason?: Maybe<Scalars['String']>;
  readonly custom: Scalars['JSON'];
  readonly account?: Maybe<Account>;
  readonly group?: Maybe<Group>;
};

export type RecipientKind = 
  | 'DIRECT_CONTACT'
  | 'ACCOUNT'
  | 'GROUP'
  | 'ACCOUNT_SEGMENT'
  | 'GROUP_SEGMENT';

export type RecipientStatus = 
  | 'PENDING'
  | 'VALIDATED'
  | 'PROCESSED'
  | 'FAILED'
  | 'SKIPPED';

export type RecipientStubInput = {
  readonly kind: RecipientKind;
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly groupId?: Maybe<Scalars['UUID']>;
  readonly target?: Maybe<RecipientTarget>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly custom?: Maybe<Scalars['JSON']>;
};

export type RecipientTarget = 
  | 'TO'
  | 'CC'
  | 'BCC';

export type Record = {
  readonly __typename?: 'Record';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly environmentId?: Maybe<Scalars['UUID']>;
  readonly resourceId: Scalars['UUID'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly publishedId?: Maybe<Scalars['UUID']>;
  readonly latestId?: Maybe<Scalars['UUID']>;
  readonly recordVersions: ReadonlyArray<RecordVersion>;
  readonly published: RecordVersion;
  readonly draft: RecordVersion;
};


export type RecordRecordVersionsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type RecordVersion = {
  readonly __typename?: 'RecordVersion';
  readonly id: Scalars['UUID'];
  readonly recordId: Scalars['UUID'];
  readonly version: Scalars['Timestamp'];
  readonly snapshot: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly createdById?: Maybe<Scalars['UUID']>;
  readonly updatedAt: Scalars['Timestamp'];
  readonly updatedById?: Maybe<Scalars['UUID']>;
  readonly deletedAt?: Maybe<Scalars['Timestamp']>;
  readonly deletedById?: Maybe<Scalars['UUID']>;
  readonly createdBy?: Maybe<Account>;
  readonly updatedBy?: Maybe<Account>;
  readonly deletedBy?: Maybe<Account>;
};

export type RegisterWorkspaceInput = {
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
};

export type RegisterWorkspaceResponse = {
  readonly __typename?: 'RegisterWorkspaceResponse';
  readonly invitationUrl: Scalars['String'];
};

export type Relationship = {
  readonly __typename?: 'Relationship';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly sourceId: Scalars['UUID'];
  readonly sourceAttributeId: Scalars['UUID'];
  readonly targetId: Scalars['UUID'];
  readonly targetAttributeId: Scalars['UUID'];
  readonly kind: RelationshipKind;
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly position: Scalars['Int'];
  readonly deleteBehavior: RelationshipDeleteBehavior;
  readonly source: Resource;
  readonly sourceAttribute: Attribute;
  readonly target: Resource;
  readonly targetAttribute: Attribute;
};

export type RelationshipDeleteBehavior = 
  | 'DISALLOW'
  | 'CASCADE'
  | 'NULLIFY'
  | 'DO_NOTHING';

export type RelationshipKind = 
  | 'BELONGS_TO'
  | 'HAS_ONE'
  | 'HAS_MANY';

export type RemoveCouponFromCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly couponCode: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type RemoveItemFromCartInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
};

export type RemoveWorkspaceLocaleInput = {
  readonly identifier: Scalars['String'];
};

export type ResetPasswordInput = {
  readonly token: Scalars['String'];
  readonly password: Scalars['String'];
};

export type ResolveInstallationInput = {
  readonly workspaceId: Scalars['UUID'];
  readonly appIdentifier: Scalars['String'];
  readonly environmentId?: Maybe<Scalars['UUID']>;
};

export type ResolveViewInput = {
  readonly urn: Scalars['String'];
};

export type Resource = {
  readonly __typename?: 'Resource';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly titleAttributeId?: Maybe<Scalars['UUID']>;
  readonly subtitleAttributeId?: Maybe<Scalars['UUID']>;
  readonly polymorphicAttributeId?: Maybe<Scalars['UUID']>;
  readonly orderStyle?: Maybe<OrderStyle>;
  readonly orderAttributes: ReadonlyArray<Scalars['JSON']>;
  readonly validations: ReadonlyArray<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly isReadOnly: Scalars['Boolean'];
  readonly origins?: Maybe<Scalars['JSON']>;
  readonly creationTimestampAttributeId?: Maybe<Scalars['UUID']>;
  readonly updationTimestampAttributeId?: Maybe<Scalars['UUID']>;
  readonly deletionTimestampAttributeId?: Maybe<Scalars['UUID']>;
  readonly isTranslatable: Scalars['Boolean'];
  readonly isPublishingEnabled: Scalars['Boolean'];
  readonly environmentBehavior: ResourceEnvironmentBehavior;
  readonly positionAttributeId?: Maybe<Scalars['UUID']>;
  readonly operations: ReadonlyArray<Operation>;
  readonly attributes: ReadonlyArray<Attribute>;
  readonly relationships: ReadonlyArray<Relationship>;
  readonly app?: Maybe<App>;
};


export type ResourceOperationsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type ResourceAttributesArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type ResourceRelationshipsArgs = {
  order?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ResourceEnvironmentBehavior = 
  | 'NONE'
  | 'ISOLATED';

export type ResourceMenuStubInput = {
  readonly id: Scalars['UUID'];
  readonly position?: Maybe<Scalars['Int']>;
};

export type RestoreRecordVersionInput = {
  readonly id: Scalars['UUID'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type RestoreTemplateVersionInput = {
  readonly id: Scalars['UUID'];
};

export type ResumeFlowExecutionInput = {
  readonly id: Scalars['UUID'];
};

export type RetrieveMfaBackupCodesInput = {
  readonly password: Scalars['String'];
};

export type RetrieveMfaBackupCodesResponse = {
  readonly __typename?: 'RetrieveMfaBackupCodesResponse';
  readonly mfaBackupCodes: ReadonlyArray<Scalars['String']>;
};

export type RevokeKeyPairInput = {
  readonly id: Scalars['UUID'];
};

export type RevokeOtherSessionsResponse = {
  readonly __typename?: 'RevokeOtherSessionsResponse';
  readonly success: Scalars['Boolean'];
};

export type RevokeSessionInput = {
  readonly id: Scalars['UUID'];
};

export type RoleKind = 
  | 'WORKSPACE'
  | 'APP'
  | 'RESOURCE';

export type RoleMembership = {
  readonly __typename?: 'RoleMembership';
  readonly id: Scalars['UUID'];
  readonly roleId: Scalars['UUID'];
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly environmentId?: Maybe<Scalars['UUID']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly groupId?: Maybe<Scalars['UUID']>;
  readonly workspaceId: Scalars['UUID'];
  readonly objectId?: Maybe<Scalars['UUID']>;
  readonly account?: Maybe<Account>;
  readonly role: CustomRole;
  readonly environment?: Maybe<Environment>;
  readonly group?: Maybe<Group>;
};

export type RoleStubInput = {
  readonly id: Scalars['UUID'];
  readonly environmentIds: ReadonlyArray<Scalars['UUID']>;
  readonly objectIds: ReadonlyArray<Scalars['UUID']>;
};

export type SaveContactsInput = {
  readonly uid: Scalars['String'];
  readonly contacts: ReadonlyArray<ContactStubInput>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type SaveContactsResponse = {
  readonly __typename?: 'SaveContactsResponse';
  readonly contacts: ReadonlyArray<Contact>;
};

export type SaveStoredPreferencesInput = {
  readonly accountUid: Scalars['String'];
  readonly preferenceData: Scalars['JSON'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type SaveStoredPreferencesResponse = {
  readonly __typename?: 'SaveStoredPreferencesResponse';
  readonly success: Scalars['Boolean'];
};

export type SaveWorkspaceBillingAccountInput = {
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly address?: Maybe<AddressStubInput>;
  readonly taxRegistration?: Maybe<TaxRegistrationStubInput>;
};

export type SaveWorkspaceBillingAccountResponse = {
  readonly __typename?: 'SaveWorkspaceBillingAccountResponse';
  readonly billingAccount: WorkspaceBillingAccount;
};

export type SaveWorkspaceLocaleInput = {
  readonly identifier: Scalars['String'];
  readonly isDefault: Scalars['Boolean'];
  readonly allowFallback: Scalars['Boolean'];
  readonly fallbacks: ReadonlyArray<Scalars['String']>;
};

export type SearchDatabaseRecordsInput = {
  readonly databaseTableId: Scalars['UUID'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly page?: Maybe<Scalars['Int']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type SearchOperationLogsInput = {
  readonly publicKey: Scalars['String'];
  readonly privateKey: Scalars['String'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly page?: Maybe<Scalars['Int']>;
};

export type SearchRecordsInput = {
  readonly resource: Scalars['String'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly order?: Maybe<Scalars['JSON']>;
  readonly limit?: Maybe<Scalars['Int']>;
  readonly page?: Maybe<Scalars['Int']>;
  readonly preview?: Maybe<Scalars['Boolean']>;
  readonly language?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly include?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly exclude?: Maybe<ReadonlyArray<Scalars['JSON']>>;
};

export type Segment = {
  readonly __typename?: 'Segment';
  readonly id: Scalars['UUID'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly resourceId: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly filter: Scalars['JSON'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly resource: Resource;
};

export type Session = {
  readonly __typename?: 'Session';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly token: Scalars['String'];
  readonly lastActiveAt: Scalars['Timestamp'];
  readonly expiresAt: Scalars['Timestamp'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly isMfaRequired: Scalars['Boolean'];
  readonly mfaVerifiedAt?: Maybe<Scalars['Timestamp']>;
};

export type SessionVerifyMethod = 
  | 'SOFTWARE'
  | 'BACKUP';

export type SetWorkspacePaymentMethodAsDefaultInput = {
  readonly id: Scalars['UUID'];
};

export type StartDeliveryInput = {
  readonly id: Scalars['UUID'];
};

export type StartOnboardingInput = {
  readonly invitationToken: Scalars['String'];
  readonly password: Scalars['String'];
  readonly passwordConfirmation: Scalars['String'];
};

export type StoredPreference = {
  readonly __typename?: 'StoredPreference';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly preferenceId?: Maybe<Scalars['UUID']>;
  readonly isEnabled: Scalars['Boolean'];
  readonly channels: ReadonlyArray<DeliveryChannel>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly preference?: Maybe<Preference>;
  readonly account: Account;
};

export type SubmitContactFormInput = {
  readonly message: Scalars['String'];
};

export type SubmitContactFormResponse = {
  readonly __typename?: 'SubmitContactFormResponse';
  readonly success: Scalars['Boolean'];
};

export type SubscribeContactInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly kind: ContactKind;
  readonly value: Scalars['String'];
  readonly userAgent?: Maybe<Scalars['String']>;
  readonly osName?: Maybe<Scalars['String']>;
  readonly osVersion?: Maybe<Scalars['String']>;
  readonly deviceModel?: Maybe<Scalars['String']>;
  readonly deviceManufacturer?: Maybe<Scalars['String']>;
  readonly deviceUid?: Maybe<Scalars['String']>;
  readonly deviceAdvertisingUid?: Maybe<Scalars['String']>;
  readonly isDeviceAdTrackingEnabled?: Maybe<Scalars['Boolean']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type SummarizeDatabaseRecordsInput = {
  readonly databaseTableId: Scalars['UUID'];
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
  readonly installationId: Scalars['UUID'];
};

export type SummarizeDatabaseRecordsResponse = {
  readonly __typename?: 'SummarizeDatabaseRecordsResponse';
  readonly count: Scalars['Int'];
};

export type SummarizeOperationLogsInput = {
  readonly publicKey: Scalars['String'];
  readonly privateKey: Scalars['String'];
  readonly filter?: Maybe<Scalars['JSON']>;
};

export type SummarizeOperationLogsResponse = {
  readonly __typename?: 'SummarizeOperationLogsResponse';
  readonly count?: Maybe<Scalars['Int']>;
};

export type SystemContext = {
  readonly __typename?: 'SystemContext';
  readonly ipV4: Scalars['String'];
  readonly ipV6?: Maybe<Scalars['String']>;
  readonly locale: Scalars['String'];
  readonly timeZone: Scalars['String'];
  readonly userAgent: Scalars['String'];
  readonly app?: Maybe<SystemContextApp>;
  readonly device?: Maybe<SystemContextDevice>;
  readonly os?: Maybe<SystemContextOs>;
  readonly library?: Maybe<SystemContextLibrary>;
  readonly network?: Maybe<SystemContextNetwork>;
  readonly screen?: Maybe<SystemContextScreen>;
  readonly campaign?: Maybe<SystemContextCampaign>;
  readonly location?: Maybe<SystemContextLocation>;
};

export type SystemContextApp = {
  readonly __typename?: 'SystemContextApp';
  readonly name: Scalars['String'];
  readonly version: Scalars['String'];
  readonly build: Scalars['String'];
  readonly namespace: Scalars['String'];
};

export type SystemContextAppInput = {
  readonly name: Scalars['String'];
  readonly version: Scalars['String'];
  readonly build: Scalars['String'];
  readonly namespace: Scalars['String'];
};

export type SystemContextCampaign = {
  readonly __typename?: 'SystemContextCampaign';
  readonly name: Scalars['String'];
  readonly source: Scalars['String'];
  readonly medium: Scalars['String'];
  readonly term: Scalars['String'];
  readonly content: Scalars['String'];
};

export type SystemContextCampaignInput = {
  readonly name: Scalars['String'];
  readonly source: Scalars['String'];
  readonly medium: Scalars['String'];
  readonly term: Scalars['String'];
  readonly content: Scalars['String'];
};

export type SystemContextDevice = {
  readonly __typename?: 'SystemContextDevice';
  readonly id: Scalars['String'];
  readonly advertisingId: Scalars['String'];
  readonly adTrackingEnabled: Scalars['Boolean'];
  readonly manufacturer: Scalars['String'];
  readonly model: Scalars['String'];
  readonly name: Scalars['String'];
  readonly kind: Scalars['String'];
};

export type SystemContextDeviceInput = {
  readonly id: Scalars['String'];
  readonly advertisingId: Scalars['String'];
  readonly adTrackingEnabled: Scalars['Boolean'];
  readonly manufacturer: Scalars['String'];
  readonly model: Scalars['String'];
  readonly name: Scalars['String'];
  readonly kind: Scalars['String'];
};

export type SystemContextInput = {
  readonly ipV4: Scalars['String'];
  readonly ipV6?: Maybe<Scalars['String']>;
  readonly locale: Scalars['String'];
  readonly timeZone: Scalars['String'];
  readonly userAgent: Scalars['String'];
  readonly app?: Maybe<SystemContextAppInput>;
  readonly device?: Maybe<SystemContextDeviceInput>;
  readonly os?: Maybe<SystemContextOsInput>;
  readonly library?: Maybe<SystemContextLibraryInput>;
  readonly network?: Maybe<SystemContextNetworkInput>;
  readonly screen?: Maybe<SystemContextScreenInput>;
  readonly campaign?: Maybe<SystemContextCampaignInput>;
  readonly location?: Maybe<SystemContextLocationInput>;
};

export type SystemContextLibrary = {
  readonly __typename?: 'SystemContextLibrary';
  readonly name: Scalars['String'];
  readonly version: Scalars['String'];
};

export type SystemContextLibraryInput = {
  readonly name: Scalars['String'];
  readonly version: Scalars['String'];
};

export type SystemContextLocation = {
  readonly __typename?: 'SystemContextLocation';
  readonly latitude?: Maybe<Scalars['Decimal']>;
  readonly longitude?: Maybe<Scalars['Decimal']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly speed?: Maybe<Scalars['Decimal']>;
};

export type SystemContextLocationInput = {
  readonly latitude?: Maybe<Scalars['Decimal']>;
  readonly longitude?: Maybe<Scalars['Decimal']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly speed?: Maybe<Scalars['Decimal']>;
};

export type SystemContextNetwork = {
  readonly __typename?: 'SystemContextNetwork';
  readonly bluetooth: Scalars['Boolean'];
  readonly carrier: Scalars['String'];
  readonly cellular: Scalars['Boolean'];
  readonly wifi: Scalars['Boolean'];
};

export type SystemContextNetworkInput = {
  readonly bluetooth: Scalars['Boolean'];
  readonly carrier: Scalars['String'];
  readonly cellular: Scalars['Boolean'];
  readonly wifi: Scalars['Boolean'];
};

export type SystemContextOs = {
  readonly __typename?: 'SystemContextOs';
  readonly name: Scalars['String'];
  readonly version: Scalars['String'];
};

export type SystemContextOsInput = {
  readonly name: Scalars['String'];
  readonly version: Scalars['String'];
};

export type SystemContextScreen = {
  readonly __typename?: 'SystemContextScreen';
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
  readonly density: Scalars['Int'];
};

export type SystemContextScreenInput = {
  readonly width: Scalars['Int'];
  readonly height: Scalars['Int'];
  readonly density: Scalars['Int'];
};

export type TaxRegistration = {
  readonly __typename?: 'TaxRegistration';
  readonly id: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly externalUid: Scalars['String'];
  readonly kind: TaxRegistrationKind;
  readonly value: Scalars['String'];
  readonly deletedAt?: Maybe<Scalars['Timestamp']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type TaxRegistrationKind = 
  | 'AE_TRN'
  | 'AU_ABN'
  | 'AU_ARN'
  | 'BG_UIC'
  | 'BR_CNPJ'
  | 'BR_CPF'
  | 'CA_BN'
  | 'CA_GST_HST'
  | 'CA_PST_BC'
  | 'CA_PST_MB'
  | 'CA_PST_SK'
  | 'CA_QST'
  | 'CH_VAT'
  | 'CL_TIN'
  | 'EG_TIN'
  | 'ES_CIF'
  | 'EU_OSS_VAT'
  | 'EU_VAT'
  | 'GB_VAT'
  | 'GE_VAT'
  | 'HK_BR'
  | 'HU_TIN'
  | 'ID_NPWP'
  | 'IL_VAT'
  | 'IN_GST'
  | 'IS_VAT'
  | 'JP_CN'
  | 'JP_RN'
  | 'JP_TRN'
  | 'KE_PIN'
  | 'KR_BRN'
  | 'LI_UID'
  | 'MX_RFC'
  | 'MY_FRP'
  | 'MY_ITN'
  | 'MY_SST'
  | 'NO_VAT'
  | 'NZ_GST'
  | 'PH_TIN'
  | 'RU_INN'
  | 'RU_KPP'
  | 'SA_VAT'
  | 'SG_GST'
  | 'SG_UEN'
  | 'SI_TIN'
  | 'TH_VAT'
  | 'TR_TIN'
  | 'TW_VAT'
  | 'UA_VAT'
  | 'US_EIN'
  | 'ZA_VAT';

export type TaxRegistrationStub = {
  readonly __typename?: 'TaxRegistrationStub';
  readonly kind: TaxRegistrationKind;
  readonly value: Scalars['String'];
};

export type TaxRegistrationStubInput = {
  readonly kind: TaxRegistrationKind;
  readonly value: Scalars['String'];
};

export type Template = {
  readonly __typename?: 'Template';
  readonly id: Scalars['UUID'];
  readonly workspaceId: Scalars['UUID'];
  readonly projectId?: Maybe<Scalars['UUID']>;
  readonly kind: TemplateKind;
  readonly subkind: TemplateSubkind;
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly currentVersionId?: Maybe<Scalars['UUID']>;
  readonly publishedVersionId?: Maybe<Scalars['UUID']>;
  readonly settings?: Maybe<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly currentVersion?: Maybe<TemplateVersion>;
  readonly publishedVersion?: Maybe<TemplateVersion>;
};

export type TemplateKind = 
  | 'VIEW'
  | 'MESSAGE';

export type TemplateSubkind = 
  | 'SCREEN'
  | 'OVERLAY'
  | 'EMAIL'
  | 'SMS'
  | 'PUSH'
  | 'WHATSAPP'
  | 'IN_APP';

export type TemplateVersion = {
  readonly __typename?: 'TemplateVersion';
  readonly id: Scalars['UUID'];
  readonly templateId: Scalars['UUID'];
  readonly settings?: Maybe<Scalars['JSON']>;
  readonly authorId: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly template: Template;
  readonly author: Account;
};

export type TestConfigurationInput = {
  readonly appId: Scalars['UUID'];
  readonly settings: Scalars['JSON'];
};

export type TestConfigurationResponse = {
  readonly __typename?: 'TestConfigurationResponse';
  readonly success: Scalars['Boolean'];
};

export type Theme = {
  readonly __typename?: 'Theme';
  readonly id: Scalars['UUID'];
  readonly workspaceId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly palette: ThemePalette;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
};

export type ThemePalette = {
  readonly __typename?: 'ThemePalette';
  readonly primary: Scalars['String'];
  readonly secondary: Scalars['String'];
  readonly accent: Scalars['String'];
};

export type ThemePaletteInput = {
  readonly primary: Scalars['String'];
  readonly secondary: Scalars['String'];
  readonly accent: Scalars['String'];
};

export type TimeZone = {
  readonly __typename?: 'TimeZone';
  readonly label: Scalars['String'];
  readonly value: Scalars['String'];
};


export type TrackEventInput = {
  readonly event: Scalars['String'];
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly timestamp?: Maybe<Scalars['Timestamp']>;
  readonly systemContext?: Maybe<SystemContextInput>;
};

export type TrackEventResponse = {
  readonly __typename?: 'TrackEventResponse';
  readonly success: Scalars['Boolean'];
};

export type TrackNotificationInput = {
  readonly id: Scalars['UUID'];
  readonly status: TrackNotificationStatus;
  readonly timestamp: Scalars['Timestamp'];
};

export type TrackNotificationResponse = {
  readonly __typename?: 'TrackNotificationResponse';
  readonly success: Scalars['Boolean'];
};

export type TrackNotificationStatus = 
  | 'DELIVERED'
  | 'DISMISSED'
  | 'OPENED'
  | 'CLICKED'
  | 'READ'
  | 'UNREAD';

export type Transaction = {
  readonly __typename?: 'Transaction';
  readonly id: Scalars['UUID'];
  readonly orderId: Scalars['UUID'];
  readonly accountId: Scalars['UUID'];
  readonly amount: Scalars['Decimal'];
  readonly status: TransactionStatus;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly gatewayMeta?: Maybe<Scalars['JSON']>;
  readonly currencyCode: Scalars['String'];
};

export type TransactionStatus = 
  | 'SUCCEEDED'
  | 'FAILED';

export type TransferCartInput = {
  readonly accountUid: Scalars['String'];
  readonly accountAnonymousUid: Scalars['String'];
  readonly orderId?: Maybe<Scalars['UUID']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};


export type UnarchiveInstallationInput = {
  readonly id: Scalars['UUID'];
};

export type UnpublishFlowVersionInput = {
  readonly id: Scalars['UUID'];
  readonly isGraceful: Scalars['Boolean'];
};

export type UnpublishRecordVersionInput = {
  readonly id: Scalars['UUID'];
};

export type UnpublishTemplateVersionInput = {
  readonly id: Scalars['UUID'];
};

export type UnsubscribeContactInput = {
  readonly accountUid?: Maybe<Scalars['String']>;
  readonly accountAnonymousUid?: Maybe<Scalars['String']>;
  readonly value: Scalars['String'];
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type UpdateAccountAvatarInput = {
  readonly id: Scalars['UUID'];
  readonly avatar?: Maybe<Scalars['Upload']>;
};

export type UpdateAccountInput = {
  readonly id: Scalars['UUID'];
  readonly email?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly avatar?: Maybe<Scalars['String']>;
  readonly dateOfBirth?: Maybe<Scalars['NaiveDate']>;
  readonly timeZone?: Maybe<Scalars['String']>;
  readonly hasApiAccess?: Maybe<Scalars['Boolean']>;
  readonly hasGuiAccess?: Maybe<Scalars['Boolean']>;
  readonly custom?: Maybe<Scalars['JSON']>;
  readonly gender?: Maybe<AccountGender>;
  readonly phone?: Maybe<Scalars['String']>;
};

export type UpdateApiRequestInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly authStrategy?: Maybe<ApiRequestAuthStrategy>;
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly method?: Maybe<Scalars['String']>;
  readonly path?: Maybe<Scalars['String']>;
  readonly urlFragment?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
  readonly queryParams?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly headers?: Maybe<ReadonlyArray<Scalars['JSON']>>;
};

export type UpdateAppInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly summary?: Maybe<Scalars['String']>;
};

export type UpdateAttributeInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly fieldType?: Maybe<Scalars['String']>;
  readonly fieldTypeSettings?: Maybe<Scalars['JSON']>;
  readonly displayType?: Maybe<DisplayType>;
  readonly displayTypeSettings?: Maybe<Scalars['JSON']>;
  readonly isPrimary?: Maybe<Scalars['Boolean']>;
  readonly isNullable?: Maybe<Scalars['Boolean']>;
  readonly isArray?: Maybe<Scalars['Boolean']>;
  readonly isTranslatable?: Maybe<Scalars['Boolean']>;
  readonly isFilterable?: Maybe<Scalars['Boolean']>;
  readonly isOrderable?: Maybe<Scalars['Boolean']>;
  readonly validations?: Maybe<ReadonlyArray<ValidationInput>>;
  readonly settings?: Maybe<Scalars['JSON']>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly dataTypeSettings?: Maybe<Scalars['JSON']>;
  readonly resolutionSettings?: Maybe<Scalars['JSON']>;
};

export type UpdateConfigurationInput = {
  readonly id: Scalars['UUID'];
  readonly settings?: Maybe<Scalars['JSON']>;
};

export type UpdateContactInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly kind?: Maybe<ContactKind>;
  readonly value?: Maybe<Scalars['String']>;
  readonly status?: Maybe<ContactStatus>;
  readonly userAgent?: Maybe<Scalars['String']>;
  readonly osName?: Maybe<Scalars['String']>;
  readonly osVersion?: Maybe<Scalars['String']>;
  readonly deviceModel?: Maybe<Scalars['String']>;
  readonly deviceManufacturer?: Maybe<Scalars['String']>;
  readonly deviceUid?: Maybe<Scalars['String']>;
  readonly deviceAdvertisingUid?: Maybe<Scalars['String']>;
  readonly isDeviceAdTrackingEnabled?: Maybe<Scalars['Boolean']>;
  readonly tag?: Maybe<Scalars['String']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type UpdateCouponCodeInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly accountId?: Maybe<Scalars['UUID']>;
  readonly redemptionsLimit?: Maybe<Scalars['Int']>;
};

export type UpdateCouponInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly discountType?: Maybe<CouponDiscountType>;
  readonly discountAmount?: Maybe<Scalars['Decimal']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly expiresAt?: Maybe<Scalars['Timestamp']>;
  readonly redemptionsLimit?: Maybe<Scalars['Int']>;
};

export type UpdateCouponRedemptionInput = {
  readonly id: Scalars['UUID'];
  readonly couponId?: Maybe<Scalars['UUID']>;
};

export type UpdateCouponRestrictionInput = {
  readonly id: Scalars['UUID'];
  readonly itemId?: Maybe<Scalars['UUID']>;
  readonly itemCategoryId?: Maybe<Scalars['UUID']>;
};

export type UpdateDashboardInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['Int']>;
};

export type UpdateDataTypeInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly validations?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly settings?: Maybe<Scalars['JSON']>;
};

export type UpdateDatabaseScratchpadInput = {
  readonly id: Scalars['UUID'];
  readonly title?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
};

export type UpdateDeliveryInput = {
  readonly id: Scalars['UUID'];
  readonly templateId?: Maybe<Scalars['UUID']>;
  readonly content?: Maybe<Scalars['JSON']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly isTest?: Maybe<Scalars['Boolean']>;
  readonly scheduledAt?: Maybe<Scalars['Timestamp']>;
  readonly status?: Maybe<DeliveryStatus>;
  readonly recipients?: Maybe<ReadonlyArray<RecipientStubInput>>;
  readonly custom?: Maybe<Scalars['JSON']>;
};

export type UpdateEnvironmentInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
};

export type UpdateEventReportInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly criteria?: Maybe<EventReportCriteriaInput>;
};

export type UpdateEventTypeInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type UpdateFlowInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
};

export type UpdateFlowVersionInput = {
  readonly id: Scalars['UUID'];
  readonly snapshot?: Maybe<Scalars['JSON']>;
};

export type UpdateGroupInput = {
  readonly id: Scalars['UUID'];
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['Int']>;
};

export type UpdateInstallationInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly settings?: Maybe<Scalars['JSON']>;
};

export type UpdateItemCategoryInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
};

export type UpdateItemCategoryMembershipInput = {
  readonly id: Scalars['UUID'];
  readonly itemId?: Maybe<Scalars['UUID']>;
  readonly itemCategoryId?: Maybe<Scalars['UUID']>;
};

export type UpdateItemInput = {
  readonly id: Scalars['UUID'];
  readonly kind?: Maybe<ItemKind>;
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
};

export type UpdateMenuElementInput = {
  readonly id: Scalars['UUID'];
  readonly parentId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly separatorStyle?: Maybe<MenuElementSeparatorStyle>;
  readonly placement?: Maybe<MenuElementPlacement>;
  readonly target?: Maybe<MenuElementTarget>;
  readonly url?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly query?: Maybe<Scalars['String']>;
  readonly actions?: Maybe<ReadonlyArray<MenuElementActionInput>>;
  readonly isRepeated?: Maybe<Scalars['Boolean']>;
  readonly isSticky?: Maybe<Scalars['Boolean']>;
};

export type UpdateOperationInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly graphqlKind?: Maybe<OperationGraphqlKind>;
  readonly behaviorKind?: Maybe<OperationBehaviorKind>;
  readonly behaviorMethod?: Maybe<OperationBehaviorMethod>;
  readonly behaviorSettings?: Maybe<Scalars['JSON']>;
  readonly actsOn?: Maybe<OperationActsOn>;
  readonly parameters?: Maybe<ReadonlyArray<ParameterStubInput>>;
  readonly responseMapper?: Maybe<Scalars['JSON']>;
};

export type UpdateOrderInput = {
  readonly id: Scalars['UUID'];
  readonly discount?: Maybe<Scalars['Decimal']>;
  readonly total?: Maybe<Scalars['Decimal']>;
  readonly checkedOutAt?: Maybe<Scalars['Timestamp']>;
  readonly paidAt?: Maybe<Scalars['Timestamp']>;
  readonly gatewayId?: Maybe<Scalars['UUID']>;
  readonly gatewayMeta?: Maybe<Scalars['JSON']>;
  readonly status?: Maybe<OrderStatus>;
};

export type UpdateOrderItemInput = {
  readonly id: Scalars['UUID'];
  readonly pricingId?: Maybe<Scalars['UUID']>;
  readonly quantity?: Maybe<Scalars['Decimal']>;
  readonly discount?: Maybe<Scalars['Decimal']>;
};

export type UpdateParameterInput = {
  readonly id: Scalars['UUID'];
  readonly attributeId?: Maybe<Scalars['UUID']>;
  readonly relationshipId?: Maybe<Scalars['UUID']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly dataTypeId?: Maybe<Scalars['UUID']>;
  readonly dataTypeSettings?: Maybe<Scalars['JSON']>;
  readonly fieldTypeSettings?: Maybe<Scalars['JSON']>;
  readonly displayType?: Maybe<DisplayType>;
  readonly displayTypeSettings?: Maybe<Scalars['JSON']>;
  readonly isNullable?: Maybe<Scalars['Boolean']>;
  readonly isArray?: Maybe<Scalars['Boolean']>;
  readonly validations?: Maybe<ReadonlyArray<ValidationInput>>;
  readonly defaultValue?: Maybe<Scalars['JSON']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly fieldType?: Maybe<Scalars['String']>;
};

export type UpdatePreferenceInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly restrictions?: Maybe<ReadonlyArray<Scalars['UUID']>>;
  readonly isEnabledByDefault?: Maybe<Scalars['Boolean']>;
};

export type UpdatePricingInput = {
  readonly id: Scalars['UUID'];
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly currencyCode?: Maybe<Scalars['String']>;
  readonly originalAmount?: Maybe<Scalars['Decimal']>;
  readonly isRecurring?: Maybe<Scalars['Boolean']>;
  readonly recurringInterval?: Maybe<Scalars['Int']>;
  readonly recurringIntervalUnit?: Maybe<PricingRecurringIntervalUnit>;
  readonly kind?: Maybe<PricingKind>;
  readonly appleProductIdentifier?: Maybe<Scalars['String']>;
  readonly googleProductIdentifier?: Maybe<Scalars['String']>;
};

export type UpdateRecipientInput = {
  readonly id: Scalars['UUID'];
  readonly target?: Maybe<RecipientTarget>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly custom?: Maybe<Scalars['JSON']>;
};

export type UpdateRecordInput = {
  readonly id: Scalars['UUID'];
  readonly resourceId?: Maybe<Scalars['UUID']>;
  readonly data?: Maybe<Scalars['JSON']>;
  readonly targetEnvironment?: Maybe<Scalars['String']>;
};

export type UpdateRelationshipInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly sourceAttributeId?: Maybe<Scalars['UUID']>;
  readonly targetAttributeId?: Maybe<Scalars['UUID']>;
  readonly kind?: Maybe<RelationshipKind>;
  readonly filter?: Maybe<Scalars['JSON']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly deleteBehavior?: Maybe<RelationshipDeleteBehavior>;
};

export type UpdateResourceInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly icon?: Maybe<Scalars['String']>;
  readonly position?: Maybe<Scalars['Int']>;
  readonly titleAttributeId?: Maybe<Scalars['UUID']>;
  readonly subtitleAttributeId?: Maybe<Scalars['UUID']>;
  readonly polymorphicAttributeId?: Maybe<Scalars['UUID']>;
  readonly creationTimestampAttributeId?: Maybe<Scalars['UUID']>;
  readonly updationTimestampAttributeId?: Maybe<Scalars['UUID']>;
  readonly deletionTimestampAttributeId?: Maybe<Scalars['UUID']>;
  readonly orderStyle?: Maybe<OrderStyle>;
  readonly orderAttributes?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly validations?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly environmentBehavior?: Maybe<ResourceEnvironmentBehavior>;
  readonly isPublishingEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateRoleInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly policy?: Maybe<Scalars['JSON']>;
};

export type UpdateSegmentInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly filter?: Maybe<Scalars['JSON']>;
};

export type UpdateSessionInput = {
  readonly id: Scalars['UUID'];
  readonly lastActiveAt?: Maybe<Scalars['Timestamp']>;
};

export type UpdateStoredPreferenceInput = {
  readonly id: Scalars['UUID'];
  readonly isEnabled?: Maybe<Scalars['Boolean']>;
  readonly channels?: Maybe<ReadonlyArray<DeliveryChannel>>;
};

export type UpdateTemplateInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly identifier?: Maybe<Scalars['String']>;
  readonly settings?: Maybe<Scalars['JSON']>;
};

export type UpdateThemeInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly palette?: Maybe<ThemePaletteInput>;
};

export type UpdateTransactionInput = {
  readonly id: Scalars['UUID'];
  readonly amount?: Maybe<Scalars['Decimal']>;
  readonly status?: Maybe<TransactionStatus>;
};

export type UpdateWorkspaceBrandingInput = {
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly logoFull?: Maybe<Scalars['Upload']>;
  readonly logoSymbol?: Maybe<Scalars['Upload']>;
  readonly wallpaper?: Maybe<Scalars['Upload']>;
};

export type UpdateWorkspaceDomainInput = {
  readonly id: Scalars['UUID'];
  readonly identifier?: Maybe<Scalars['String']>;
  readonly hostname?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceOnboardingInput = {
  readonly id: Scalars['UUID'];
  readonly onboarding?: Maybe<Scalars['JSON']>;
};

export type UpdateWorkspacePaymentMethodInput = {
  readonly id: Scalars['UUID'];
  readonly expiryMonth?: Maybe<Scalars['Int']>;
  readonly expiryYear?: Maybe<Scalars['Int']>;
};

export type UpdateWorkspaceThemeInput = {
  readonly id: Scalars['UUID'];
  readonly themeId?: Maybe<Scalars['UUID']>;
};


export type UpsertViewInput = {
  readonly urn: Scalars['String'];
  readonly blocks?: Maybe<ReadonlyArray<Scalars['JSON']>>;
  readonly operations?: Maybe<ReadonlyArray<Scalars['JSON']>>;
};

export type ValidateKeyPairInput = {
  readonly publicKey: Scalars['String'];
  readonly privateKey: Scalars['String'];
};

export type ValidateKeyPairResponse = {
  readonly __typename?: 'ValidateKeyPairResponse';
  readonly success: Scalars['Boolean'];
};

export type ValidationInput = {
  readonly kind: ValidationKind;
  readonly settings?: Maybe<Scalars['JSON']>;
  readonly criteria?: Maybe<Scalars['JSON']>;
};

export type ValidationKind = 
  | 'PRESENCE'
  | 'UNIQUENESS'
  | 'LENGTH'
  | 'MATCHES'
  | 'NOT_MATCHES'
  | 'INCLUSION'
  | 'EXCLUSION'
  | 'NUMERICALITY'
  | 'FILE'
  | 'CUSTOM';

export type VerifyEmailChangeInput = {
  readonly token: Scalars['String'];
};

export type VerifyInvitationInput = {
  readonly invitationToken: Scalars['String'];
};

export type VerifyInvitationResponse = {
  readonly __typename?: 'VerifyInvitationResponse';
  readonly success: Scalars['Boolean'];
};

export type VerifyProspectInput = {
  readonly email: Scalars['String'];
  readonly code: Scalars['String'];
};

export type VerifyResetPasswordTokenInput = {
  readonly token: Scalars['String'];
};

export type VerifyResetPasswordTokenResponse = {
  readonly __typename?: 'VerifyResetPasswordTokenResponse';
  readonly success: Scalars['Boolean'];
};

export type VerifySessionInput = {
  readonly code: Scalars['String'];
  readonly method?: Maybe<SessionVerifyMethod>;
};

export type View = {
  readonly __typename?: 'View';
  readonly id: Scalars['UUID'];
  readonly dashboardId?: Maybe<Scalars['UUID']>;
  readonly name: Scalars['String'];
  readonly defaultStyle: ViewStyle;
  readonly componentPath?: Maybe<Scalars['String']>;
  readonly blocks: ReadonlyArray<Scalars['JSON']>;
  readonly operations: ReadonlyArray<Scalars['JSON']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly workspaceId: Scalars['UUID'];
  readonly appId?: Maybe<Scalars['UUID']>;
  readonly resourceId?: Maybe<Scalars['UUID']>;
  readonly kind: ViewKind;
  readonly app?: Maybe<App>;
  readonly resource?: Maybe<Resource>;
};

export type ViewKind = 
  | 'CUSTOM'
  | 'APP'
  | 'RESOURCE';

export type ViewStyle = 
  | 'MAIN'
  | 'MODAL'
  | 'PANEL'
  | 'DIALOG'
  | 'POPOVER';

export type Workspace = {
  readonly __typename?: 'Workspace';
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly identifier: Scalars['String'];
  readonly hostname?: Maybe<Scalars['String']>;
  readonly logoSymbol?: Maybe<Scalars['String']>;
  readonly logoFull?: Maybe<Scalars['String']>;
  readonly wallpaper?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['Timestamp'];
  readonly updatedAt: Scalars['Timestamp'];
  readonly themeId?: Maybe<Scalars['UUID']>;
  readonly locales: ReadonlyArray<Scalars['JSON']>;
  readonly billingAccountId?: Maybe<Scalars['UUID']>;
  readonly status: WorkspaceStatus;
  readonly trialEndsAt: Scalars['Timestamp'];
  readonly dashxEmailsCount: Scalars['Int'];
  readonly onboarding?: Maybe<Scalars['JSON']>;
  readonly theme?: Maybe<Theme>;
};

export type WorkspaceBillingAccount = {
  readonly __typename?: 'WorkspaceBillingAccount';
  readonly id: Scalars['UUID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly address?: Maybe<AddressStub>;
  readonly taxRegistration?: Maybe<TaxRegistrationStub>;
};

export type WorkspaceStatus = 
  | 'IN_TRIAL'
  | 'ACTIVE'
  | 'SUSPENDED'
  | 'INACTIVE';

export type WriteCsvInput = {
  readonly rows: ReadonlyArray<Scalars['JSON']>;
};

export type WriteCsvResponse = {
  readonly __typename?: 'WriteCsvResponse';
  readonly csvBase64: Scalars['String'];
};

export type AccountQueryVariables = {
  id: Scalars['UUID'];
};


export type AccountQuery = (
  { readonly __typename?: 'Query' }
  & { readonly account: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type AccountFragmentFragment = (
  { readonly __typename?: 'Account' }
  & Pick<Account, 'id' | 'uid' | 'anonymousUid' | 'avatar' | 'confirmationDigest' | 'confirmationExpiresAt' | 'custom' | 'dateOfBirth' | 'email' | 'environmentId' | 'firstName' | 'fullName' | 'gender' | 'tours' | 'hasApiAccess' | 'hasGuiAccess' | 'isMfaEnabled' | 'kind' | 'lastName' | 'name' | 'phone' | 'regularAccountId' | 'status' | 'timeZone' | 'unconfirmedEmail' | 'createdAt' | 'updatedAt'>
);

export type AccountsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type AccountsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly accountsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type AccountsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type AccountsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly accountsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly accountsList: ReadonlyArray<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )> }
);

export type AddDatabaseRecordMutationVariables = {
  input: AddDatabaseRecordInput;
};


export type AddDatabaseRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & Pick<Mutation, 'addDatabaseRecord'>
);

export type ApiImportQueryVariables = {
  id: Scalars['UUID'];
};


export type ApiImportQuery = (
  { readonly __typename?: 'Query' }
  & { readonly apiImport: (
    { readonly __typename?: 'ApiImport' }
    & ApiImportFragmentFragment
  ) }
);

export type ApiImportFragmentFragment = (
  { readonly __typename?: 'ApiImport' }
  & Pick<ApiImport, 'id' | 'workspaceId' | 'integrationId' | 'name' | 'kind' | 'status' | 'statusReason' | 'url' | 'assetId' | 'data' | 'createdAt' | 'updatedAt'>
);

export type ApiRequestQueryVariables = {
  id: Scalars['UUID'];
};


export type ApiRequestQuery = (
  { readonly __typename?: 'Query' }
  & { readonly apiRequest: (
    { readonly __typename?: 'ApiRequest' }
    & ApiRequestFragmentFragment
  ) }
);

export type ApiRequestFragmentFragment = (
  { readonly __typename?: 'ApiRequest' }
  & Pick<ApiRequest, 'id' | 'workspaceId' | 'integrationId' | 'name' | 'description' | 'parentId' | 'position' | 'method' | 'path' | 'urlFragment' | 'body' | 'createdAt' | 'updatedAt' | 'queryParams' | 'headers' | 'kind'>
);

export type ApiRequestsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type ApiRequestsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly apiRequestsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type ApiRequestsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ApiRequestsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly apiRequestsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly apiRequestsList: ReadonlyArray<(
    { readonly __typename?: 'ApiRequest' }
    & ApiRequestFragmentFragment
  )> }
);

export type AppQueryVariables = {
  id: Scalars['UUID'];
};


export type AppQuery = (
  { readonly __typename?: 'Query' }
  & { readonly app: (
    { readonly __typename?: 'App' }
    & AppFragmentFragment
  ) }
);

export type AppCategoriesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type AppCategoriesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly appCategoriesList: ReadonlyArray<(
    { readonly __typename?: 'AppCategory' }
    & AppCategoryFragmentFragment
  )> }
);

export type AppCategoryFragmentFragment = (
  { readonly __typename?: 'AppCategory' }
  & Pick<AppCategory, 'id' | 'name' | 'description' | 'position' | 'createdAt' | 'updatedAt' | 'kind'>
);

export type AppFragmentFragment = (
  { readonly __typename?: 'App' }
  & Pick<App, 'id' | 'name' | 'icon' | 'identifier' | 'appCategoryId' | 'workspaceId' | 'kind' | 'summary' | 'fields' | 'createdAt' | 'updatedAt' | 'technology' | 'platforms'>
  & { readonly meta: (
    { readonly __typename?: 'AppMeta' }
    & AppMetaFragmentFragment
  ), readonly appCategory?: Maybe<(
    { readonly __typename?: 'AppCategory' }
    & AppCategoryFragmentFragment
  )> }
);

export type AppMetaFragmentFragment = (
  { readonly __typename?: 'AppMeta' }
  & Pick<AppMeta, 'isTestable' | 'isSyncable' | 'hasWebhooks' | 'hasInstallationFields' | 'integrationCategoryIds'>
);

export type AppListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type AppListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly appsList: ReadonlyArray<(
    { readonly __typename?: 'App' }
    & AppFragmentFragment
  )> }
);

export type ArchiveInstallationMutationVariables = {
  input: ArchiveInstallationInput;
};


export type ArchiveInstallationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly archiveInstallation: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ) }
);

export type AssetQueryVariables = {
  id: Scalars['UUID'];
};


export type AssetQuery = (
  { readonly __typename?: 'Query' }
  & { readonly asset: (
    { readonly __typename?: 'Asset' }
    & AssetFragmentFragment
  ) }
);

export type AssetFragmentFragment = (
  { readonly __typename?: 'Asset' }
  & Pick<Asset, 'id' | 'workspaceId' | 'resourceId' | 'attributeId' | 'storageProviderId' | 'uploaderId' | 'data' | 'mimeType' | 'name' | 'processingStatus' | 'size' | 'uploadStatus' | 'url' | 'createdAt' | 'updatedAt'>
);

export type AssignRolesMutationVariables = {
  input: AssignRolesInput;
};


export type AssignRolesMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly assignRoles: (
    { readonly __typename?: 'AssignRolesResponse' }
    & { readonly roleMemberships: ReadonlyArray<(
      { readonly __typename?: 'RoleMembership' }
      & RoleMembershipFragmentFragment
    )> }
  ) }
);

export type AttributeQueryVariables = {
  id: Scalars['UUID'];
};


export type AttributeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly attribute: (
    { readonly __typename?: 'Attribute' }
    & AttributeFragmentFragment
  ) }
);

export type AttributeFragmentFragment = (
  { readonly __typename?: 'Attribute' }
  & Pick<Attribute, 'id' | 'workspaceId' | 'resourceId' | 'name' | 'identifier' | 'fieldType' | 'fieldTypeSettings' | 'displayType' | 'displayTypeSettings' | 'dataTypeId' | 'dataTypeSettings' | 'isNullable' | 'isArray' | 'isTranslatable' | 'isFilterable' | 'isOrderable' | 'isPrimary' | 'validations' | 'resolution' | 'resolutionKind' | 'resolutionSettings' | 'settings' | 'defaultValue' | 'position' | 'createdAt' | 'updatedAt'>
  & { readonly resource: (
    { readonly __typename?: 'Resource' }
    & ResourceFragmentFragment
  ), readonly dataType: (
    { readonly __typename?: 'DataType' }
    & DataTypeFragmentFragment
  ) }
);

export type AttributeListFragmentFragment = (
  { readonly __typename?: 'Attribute' }
  & Pick<Attribute, 'id' | 'workspaceId' | 'resourceId' | 'name' | 'identifier' | 'fieldType' | 'fieldTypeSettings' | 'displayType' | 'displayTypeSettings' | 'dataTypeId' | 'dataTypeSettings' | 'isNullable' | 'isArray' | 'isTranslatable' | 'isFilterable' | 'isOrderable' | 'isPrimary' | 'validations' | 'resolution' | 'resolutionKind' | 'resolutionSettings' | 'settings' | 'defaultValue' | 'position' | 'createdAt' | 'updatedAt'>
  & { readonly dataType: (
    { readonly __typename?: 'DataType' }
    & Pick<DataType, 'id' | 'workspaceId' | 'name' | 'identifier' | 'kind' | 'settings' | 'defaultValue' | 'validations' | 'createdAt' | 'updatedAt' | 'description' | 'icon' | 'isPrimitive' | 'allowableFieldTypes' | 'category' | 'position'>
  ) }
);

export type AttributesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type AttributesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly attributesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type AttributesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type AttributesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly attributesList: ReadonlyArray<(
    { readonly __typename?: 'Attribute' }
    & AttributeListFragmentFragment
  )> }
);

export type CancelFlowExecutiontMutationVariables = {
  input: CancelFlowExecutionInput;
};


export type CancelFlowExecutiontMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly cancelFlowExecution: (
    { readonly __typename?: 'FlowExecution' }
    & FlowExecutionFragmentFragment
  ) }
);

export type ChangePasswordMutationVariables = {
  input: ChangePasswordInput;
};


export type ChangePasswordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly changePassword: (
    { readonly __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type CompleteOnboardingMutationVariables = {
  input: CompleteOnboardingInput;
};


export type CompleteOnboardingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly completeOnboarding: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type ConfigurationFragmentFragment = (
  { readonly __typename?: 'Configuration' }
  & Pick<Configuration, 'id' | 'installationId' | 'environmentId' | 'digest' | 'settings' | 'createdAt' | 'updatedAt' | 'status' | 'statusMessage'>
  & { readonly installation: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ), readonly environment: (
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  ) }
);

export type ConfigurationsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type ConfigurationsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly configurationsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type ConfigurationsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ConfigurationsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly configurationsList: ReadonlyArray<(
    { readonly __typename?: 'Configuration' }
    & ConfigurationFragmentFragment
  )> }
);

export type ConfigureInstallationMutationVariables = {
  input: ConfigureInstallationInput;
};


export type ConfigureInstallationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly configureInstallation: (
    { readonly __typename?: 'Configuration' }
    & ConfigurationFragmentFragment
  ) }
);

export type ConfigureMfaMutationVariables = {};


export type ConfigureMfaMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly configureMfa: (
    { readonly __typename?: 'ConfigureMfaResponse' }
    & Pick<ConfigureMfaResponse, 'qrCode'>
  ) }
);

export type ContactFragmentFragment = (
  { readonly __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'accountId' | 'environmentId' | 'name' | 'kind' | 'value' | 'unverifiedValue' | 'verifiedAt' | 'verificationDigest' | 'verificationExpiresAt' | 'status' | 'userAgent' | 'osName' | 'osVersion' | 'deviceModel' | 'deviceManufacturer' | 'deviceUid' | 'deviceAdvertisingUid' | 'isDeviceAdTrackingEnabled' | 'createdAt' | 'updatedAt'>
);

export type ContactsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type ContactsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly contactsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type ContactsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ContactsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly contactsList: ReadonlyArray<(
    { readonly __typename?: 'Contact' }
    & ContactFragmentFragment
  )> }
);

export type CouponCodeFragmentFragment = (
  { readonly __typename?: 'CouponCode' }
  & Pick<CouponCode, 'id' | 'name' | 'accountId' | 'couponId' | 'redemptionsLimit' | 'redemptionsCount' | 'createdAt' | 'updatedAt'>
);

export type CouponCodesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type CouponCodesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly couponCodesList: ReadonlyArray<(
    { readonly __typename?: 'CouponCode' }
    & CouponCodeFragmentFragment
  )> }
);

export type CouponFragmentFragment = (
  { readonly __typename?: 'Coupon' }
  & Pick<Coupon, 'id' | 'name' | 'identifier' | 'discountType' | 'discountAmount' | 'currencyCode' | 'expiresAt' | 'redemptionsLimit' | 'redemptionsCount' | 'createdAt' | 'updatedAt' | 'environmentId'>
);

export type CouponRedemptionFragmentFragment = (
  { readonly __typename?: 'CouponRedemption' }
  & Pick<CouponRedemption, 'id' | 'couponId' | 'orderId' | 'createdAt' | 'updatedAt'>
  & { readonly coupon: (
    { readonly __typename?: 'Coupon' }
    & CouponFragmentFragment
  ) }
);

export type CouponRedemptionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type CouponRedemptionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly couponRedemptionsList: ReadonlyArray<(
    { readonly __typename?: 'CouponRedemption' }
    & CouponRedemptionFragmentFragment
  )>, readonly couponRedemptionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type CouponRestrictionFragmentFragment = (
  { readonly __typename?: 'CouponRestriction' }
  & Pick<CouponRestriction, 'id' | 'couponId' | 'itemId' | 'itemCategoryId' | 'createdAt' | 'updatedAt'>
  & { readonly item?: Maybe<(
    { readonly __typename?: 'Item' }
    & Pick<Item, 'id' | 'name'>
  )>, readonly itemCategory?: Maybe<(
    { readonly __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'id' | 'name'>
  )> }
);

export type CouponRestrictionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type CouponRestrictionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly couponRestrictionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly couponRestrictionsList: ReadonlyArray<(
    { readonly __typename?: 'CouponRestriction' }
    & CouponRestrictionFragmentFragment
  )> }
);

export type CouponsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type CouponsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly couponsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type CouponsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type CouponsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly couponsList: ReadonlyArray<(
    { readonly __typename?: 'Coupon' }
    & CouponFragmentFragment
  )> }
);

export type CreateAccountMutationVariables = {
  input: CreateAccountInput;
};


export type CreateAccountMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createAccount: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type CreateApiImportMutationVariables = {
  input: CreateApiImportInput;
};


export type CreateApiImportMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createApiImport: (
    { readonly __typename?: 'ApiImport' }
    & ApiImportFragmentFragment
  ) }
);

export type CreateApiRequestMutationVariables = {
  input: CreateApiRequestInput;
};


export type CreateApiRequestMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createApiRequest: (
    { readonly __typename?: 'ApiRequest' }
    & ApiRequestFragmentFragment
  ) }
);

export type CreateAppMutationVariables = {
  input: CreateAppInput;
};


export type CreateAppMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createApp: (
    { readonly __typename?: 'App' }
    & AppFragmentFragment
  ) }
);

export type CreateAttributeMutationVariables = {
  input: CreateAttributeInput;
};


export type CreateAttributeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createAttribute: (
    { readonly __typename?: 'Attribute' }
    & AttributeFragmentFragment
  ) }
);

export type CreateConfigurationMutationVariables = {
  input: CreateConfigurationInput;
};


export type CreateConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createConfiguration: (
    { readonly __typename?: 'Configuration' }
    & ConfigurationFragmentFragment
  ) }
);

export type CreateContactMutationVariables = {
  input: CreateContactInput;
};


export type CreateContactMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createContact: (
    { readonly __typename?: 'Contact' }
    & ContactFragmentFragment
  ) }
);

export type CreateCouponMutationVariables = {
  input: CreateCouponInput;
};


export type CreateCouponMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createCoupon: (
    { readonly __typename?: 'Coupon' }
    & CouponFragmentFragment
  ) }
);

export type CreateCouponCodeMutationVariables = {
  input: CreateCouponCodeInput;
};


export type CreateCouponCodeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createCouponCode: (
    { readonly __typename?: 'CouponCode' }
    & CouponCodeFragmentFragment
  ) }
);

export type CreateCouponRedemptionMutationVariables = {
  input: CreateCouponRedemptionInput;
};


export type CreateCouponRedemptionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createCouponRedemption: (
    { readonly __typename?: 'CouponRedemption' }
    & CouponRedemptionFragmentFragment
  ) }
);

export type CreateCouponRestrictionMutationVariables = {
  input: CreateCouponRestrictionInput;
};


export type CreateCouponRestrictionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createCouponRestriction: (
    { readonly __typename?: 'CouponRestriction' }
    & CouponRestrictionFragmentFragment
  ) }
);

export type CreateDashboardMutationVariables = {
  input: CreateDashboardInput;
};


export type CreateDashboardMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createDashboard: (
    { readonly __typename?: 'Dashboard' }
    & DashboardFragmentFragment
  ) }
);

export type CreateDataTypeMutationVariables = {
  input: CreateDataTypeInput;
};


export type CreateDataTypeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createDataType: (
    { readonly __typename?: 'DataType' }
    & DataTypeFragmentFragment
  ) }
);

export type CreateDatabaseScratchpadMutationVariables = {
  input: CreateDatabaseScratchpadInput;
};


export type CreateDatabaseScratchpadMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createDatabaseScratchpad: (
    { readonly __typename?: 'DatabaseScratchpad' }
    & DatabaseScratchpadFragmentFragment
  ) }
);

export type CreateDeliveryMutationVariables = {
  input: CreateDeliveryInput;
};


export type CreateDeliveryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createDelivery: (
    { readonly __typename?: 'Delivery' }
    & DeliveryFragmentFragment
  ) }
);

export type CreateEnvironmentMutationVariables = {
  input: CreateEnvironmentInput;
};


export type CreateEnvironmentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createEnvironment: (
    { readonly __typename?: 'Environment' }
    & Pick<Environment, 'id' | 'name' | 'identifier'>
  ) }
);

export type CreateEventReportMutationVariables = {
  input: CreateEventReportInput;
};


export type CreateEventReportMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createEventReport: (
    { readonly __typename?: 'EventReport' }
    & Pick<EventReport, 'id' | 'name' | 'description' | 'criteria'>
  ) }
);

export type CreateEventTypeMutationVariables = {
  input: CreateEventTypeInput;
};


export type CreateEventTypeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createEventType: (
    { readonly __typename?: 'EventType' }
    & EventTypeFragmentFragment
  ) }
);

export type CreateFlowMutationVariables = {
  input: CreateFlowInput;
};


export type CreateFlowMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createFlow: (
    { readonly __typename?: 'Flow' }
    & FlowFragmentFragment
  ) }
);

export type CreateFlowVersionMutationVariables = {
  input: CreateFlowVersionInput;
};


export type CreateFlowVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createFlowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  ) }
);

export type CreateGroupMutationVariables = {
  input: CreateGroupInput;
};


export type CreateGroupMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createGroup: (
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  ) }
);

export type CreateGroupMembershipMutationVariables = {
  input: CreateGroupMembershipInput;
};


export type CreateGroupMembershipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createGroupMembership: (
    { readonly __typename?: 'GroupMembership' }
    & GroupMembershipFragmentFragment
  ) }
);

export type CreateInstallationMutationVariables = {
  input: CreateInstallationInput;
};


export type CreateInstallationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createInstallation: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ) }
);

export type CreateItemMutationVariables = {
  input: CreateItemInput;
};


export type CreateItemMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createItem: (
    { readonly __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type CreateItemCategoryMutationVariables = {
  input: CreateItemCategoryInput;
};


export type CreateItemCategoryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createItemCategory: (
    { readonly __typename?: 'ItemCategory' }
    & ItemCategoryFragmentFragment
  ) }
);

export type CreateItemCategoryMembershipMutationVariables = {
  input: CreateItemCategoryMembershipInput;
};


export type CreateItemCategoryMembershipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createItemCategoryMembership: (
    { readonly __typename?: 'ItemCategoryMembership' }
    & ItemCategoryMembershipFragmentFragment
  ) }
);

export type CreateKeyPairMutationVariables = {
  input: CreateKeyPairInput;
};


export type CreateKeyPairMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createKeyPair: (
    { readonly __typename?: 'KeyPair' }
    & Pick<KeyPair, 'id' | 'publicKey' | 'privateKey' | 'expiresAt' | 'createdAt'>
  ) }
);

export type CreateMenuElementMutationVariables = {
  input: CreateMenuElementInput;
};


export type CreateMenuElementMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createMenuElement: (
    { readonly __typename?: 'MenuElement' }
    & MenuElementFragmentFragment
  ) }
);

export type CreateOperationMutationVariables = {
  input: CreateOperationInput;
};


export type CreateOperationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createOperation: (
    { readonly __typename?: 'Operation' }
    & OperationFragmentFragment
  ) }
);

export type CreateOrderMutationVariables = {
  input: CreateOrderInput;
};


export type CreateOrderMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createOrder: (
    { readonly __typename?: 'Order' }
    & OrderFragmentFragment
  ) }
);

export type CreateOrderItemMutationVariables = {
  input: CreateOrderItemInput;
};


export type CreateOrderItemMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createOrderItem: (
    { readonly __typename?: 'OrderItem' }
    & OrderItemFragmentFragment
  ) }
);

export type CreateParameterMutationVariables = {
  input: CreateParameterInput;
};


export type CreateParameterMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createParameter: (
    { readonly __typename?: 'Parameter' }
    & ParameterFragmentFragment
  ) }
);

export type CreatePreferenceMutationVariables = {
  input: CreatePreferenceInput;
};


export type CreatePreferenceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createPreference: (
    { readonly __typename?: 'Preference' }
    & PreferenceFragmentFragment
  ) }
);

export type CreatePricingMutationVariables = {
  input: CreatePricingInput;
};


export type CreatePricingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createPricing: (
    { readonly __typename?: 'Pricing' }
    & PricingFragmentFragment
  ) }
);

export type CreateRecipientMutationVariables = {
  input: CreateRecipientInput;
};


export type CreateRecipientMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createRecipient: (
    { readonly __typename?: 'Recipient' }
    & RecipientFragmentFragment
  ) }
);

export type CreateRelationshipMutationVariables = {
  input: CreateRelationshipInput;
};


export type CreateRelationshipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createRelationship: (
    { readonly __typename?: 'Relationship' }
    & RelationshipFragmentFragment
  ) }
);

export type CreateResourceMutationVariables = {
  input: CreateResourceInput;
};


export type CreateResourceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createResource: (
    { readonly __typename?: 'Resource' }
    & ResourceFragmentFragment
  ) }
);

export type CreateRoleMutationVariables = {
  input: CreateRoleInput;
};


export type CreateRoleMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createRole: (
    { readonly __typename?: 'CustomRole' }
    & RoleFragmentFragment
  ) }
);

export type CreateSegmentMutationVariables = {
  input: CreateSegmentInput;
};


export type CreateSegmentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createSegment: (
    { readonly __typename?: 'Segment' }
    & SegmentFragmentFragment
  ) }
);

export type CreateSessionMutationVariables = {
  input: CreateSessionInput;
};


export type CreateSessionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createSession: (
    { readonly __typename?: 'Session' }
    & Pick<Session, 'id' | 'token' | 'isMfaRequired' | 'mfaVerifiedAt'>
  ) }
);

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput;
};


export type CreateTemplateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createTemplate: (
    { readonly __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type CreateThemeMutationVariables = {
  input: CreateThemeInput;
};


export type CreateThemeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createTheme: (
    { readonly __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'workspaceId' | 'name'>
    & { readonly palette: (
      { readonly __typename?: 'ThemePalette' }
      & Pick<ThemePalette, 'primary' | 'secondary' | 'accent'>
    ) }
  ) }
);

export type CreateViewMutationVariables = {
  input: CreateViewInput;
};


export type CreateViewMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createView: (
    { readonly __typename?: 'View' }
    & ViewFragmentFragment
  ) }
);

export type CreateWorkspacePaymentMethodMutationVariables = {
  input: CreateWorkspacePaymentMethodInput;
};


export type CreateWorkspacePaymentMethodMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly createWorkspacePaymentMethod: (
    { readonly __typename?: 'PaymentMethod' }
    & PaymentMethodFragmentFragment
  ) }
);

export type CurrenciesQueryVariables = {};


export type CurrenciesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly currencies: ReadonlyArray<(
    { readonly __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'name' | 'symbol'>
  )> }
);

export type CurrentAccountQueryVariables = {};


export type CurrentAccountQuery = (
  { readonly __typename?: 'Query' }
  & { readonly currentAccount: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type CustomOrderFragmentFragment = (
  { readonly __typename?: 'CustomOrder' }
  & Pick<CustomOrder, 'id' | 'subtotal' | 'discount' | 'tax' | 'total' | 'currencyCode'>
  & { readonly orderItems: ReadonlyArray<(
    { readonly __typename?: 'CustomOrderItem' }
    & CustomOrderItemFragmentFragment
  )> }
);

export type CustomOrderItemFragmentFragment = (
  { readonly __typename?: 'CustomOrderItem' }
  & Pick<CustomOrderItem, 'id' | 'itemId' | 'pricingId' | 'quantity' | 'unitPrice' | 'subtotal' | 'discount' | 'tax' | 'total' | 'currencyCode'>
  & { readonly item: (
    { readonly __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type CustomRecordFragmentFragment = (
  { readonly __typename?: 'CustomRecord' }
  & Pick<CustomRecord, 'id' | 'workspaceId' | 'environmentId' | 'resourceId' | 'data' | 'createdAt' | 'updatedAt' | 'publishedId' | 'latestId'>
);

export type DashboardFragmentFragment = (
  { readonly __typename?: 'Dashboard' }
  & Pick<Dashboard, 'id' | 'name' | 'identifier' | 'position'>
  & { readonly menuElements: ReadonlyArray<(
    { readonly __typename?: 'MenuElement' }
    & MenuElementFragmentFragment
  )> }
);

export type DashboardsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type DashboardsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly dashboardsList: ReadonlyArray<(
    { readonly __typename?: 'Dashboard' }
    & DashboardFragmentFragment
  )>, readonly dashboardsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type DataTypeQueryVariables = {
  id: Scalars['UUID'];
};


export type DataTypeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly dataType: (
    { readonly __typename?: 'DataType' }
    & DataTypeFragmentFragment
  ) }
);

export type DataTypeFragmentFragment = (
  { readonly __typename?: 'DataType' }
  & Pick<DataType, 'id' | 'appId' | 'workspaceId' | 'name' | 'identifier' | 'kind' | 'settings' | 'defaultValue' | 'validations' | 'createdAt' | 'updatedAt' | 'description' | 'icon' | 'isPrimitive' | 'allowableFieldTypes' | 'category' | 'position'>
);

export type DataTypesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type DataTypesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly dataTypesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly dataTypesList: ReadonlyArray<(
    { readonly __typename?: 'DataType' }
    & DataTypeFragmentFragment
  )> }
);

export type DatabaseColumnFragmentFragment = (
  { readonly __typename?: 'DatabaseColumn' }
  & Pick<DatabaseColumn, 'id' | 'position' | 'databaseTableId' | 'name' | 'dbMeta' | 'createdAt' | 'updatedAt'>
);

export type DatabaseSchemaQueryVariables = {
  id: Scalars['UUID'];
};


export type DatabaseSchemaQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseSchema: (
    { readonly __typename?: 'DatabaseSchema' }
    & DatabaseSchemaFragmentFragment
  ) }
);

export type DatabaseSchemaFragmentFragment = (
  { readonly __typename?: 'DatabaseSchema' }
  & Pick<DatabaseSchema, 'id' | 'integrationId' | 'environmentId' | 'name' | 'createdAt' | 'updatedAt'>
  & { readonly integration: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ), readonly environment: (
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  ) }
);

export type DatabaseSchemasAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  installationId?: Maybe<Scalars['UUID']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type DatabaseSchemasAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseSchemasAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type DatabaseSchemasListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  installationId?: Maybe<Scalars['UUID']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type DatabaseSchemasListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseSchemasList: ReadonlyArray<(
    { readonly __typename?: 'DatabaseSchema' }
    & DatabaseSchemaFragmentFragment
  )> }
);

export type DatabaseScratchpadFragmentFragment = (
  { readonly __typename?: 'DatabaseScratchpad' }
  & Pick<DatabaseScratchpad, 'id' | 'title' | 'workspaceId' | 'integrationId' | 'accountId' | 'body' | 'createdAt' | 'updatedAt'>
  & { readonly integration: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ), readonly account: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type DatabaseScratchpadsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type DatabaseScratchpadsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseScratchpadsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type DatabaseScratchpadsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type DatabaseScratchpadsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseScratchpadsList: ReadonlyArray<(
    { readonly __typename?: 'DatabaseScratchpad' }
    & DatabaseScratchpadFragmentFragment
  )> }
);

export type DatabaseTableQueryVariables = {
  id: Scalars['UUID'];
};


export type DatabaseTableQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseTable: (
    { readonly __typename?: 'DatabaseTable' }
    & DatabaseTableFragmentFragment
  ) }
);

export type DatabaseTableFragmentFragment = (
  { readonly __typename?: 'DatabaseTable' }
  & Pick<DatabaseTable, 'id' | 'integrationId' | 'environmentId' | 'databaseSchemaId' | 'name' | 'createdAt' | 'updatedAt'>
  & { readonly environment: (
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  ), readonly databaseSchema?: Maybe<(
    { readonly __typename?: 'DatabaseSchema' }
    & DatabaseSchemaFragmentFragment
  )>, readonly integration: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ), readonly databaseColumns: ReadonlyArray<(
    { readonly __typename?: 'DatabaseColumn' }
    & DatabaseColumnFragmentFragment
  )> }
);

export type DatabaseTablesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type DatabaseTablesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseTablesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type DatabaseTablesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type DatabaseTablesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly databaseTablesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly databaseTablesList: ReadonlyArray<(
    { readonly __typename?: 'DatabaseTable' }
    & DatabaseTableFragmentFragment
  )> }
);

export type DeleteDatabaseRecordMutationVariables = {
  input: DeleteDatabaseRecordInput;
};


export type DeleteDatabaseRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDatabaseRecord'>
);

export type DeliveriesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type DeliveriesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly deliveriesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type DeliveriesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type DeliveriesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly deliveriesList: ReadonlyArray<(
    { readonly __typename?: 'Delivery' }
    & DeliveryFragmentFragment
  )> }
);

export type DeliveryQueryVariables = {
  id: Scalars['UUID'];
};


export type DeliveryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly delivery: (
    { readonly __typename?: 'Delivery' }
    & DeliveryFragmentFragment
  ) }
);

export type DeliveryFragmentFragment = (
  { readonly __typename?: 'Delivery' }
  & Pick<Delivery, 'id' | 'name' | 'templateSubkind' | 'templateId' | 'data' | 'failureReason' | 'status' | 'isTest' | 'queuedNotificationsCount' | 'deliveredNotificationsCount' | 'failedNotificationsCount' | 'sentNotificationsCount' | 'openedNotificationsCount' | 'clickedNotificationsCount' | 'createdAt' | 'updatedAt' | 'scheduledAt' | 'content' | 'environmentId'>
  & { readonly environment: (
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  ) }
);

export type DestroyApiRequestMutationVariables = {
  input: DestroyApiRequestInput;
};


export type DestroyApiRequestMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyApiRequest: (
    { readonly __typename?: 'ApiRequest' }
    & Pick<ApiRequest, 'id'>
  ) }
);

export type DestroyAttributeMutationVariables = {
  input: DestroyAttributeInput;
};


export type DestroyAttributeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyAttribute: (
    { readonly __typename?: 'Attribute' }
    & AttributeFragmentFragment
  ) }
);

export type DestroyConfigurationMutationVariables = {
  input: DestroyConfigurationInput;
};


export type DestroyConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyConfiguration: (
    { readonly __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'environmentId' | 'installationId'>
  ) }
);

export type DestroyContactMutationVariables = {
  input: DestroyContactInput;
};


export type DestroyContactMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyContact: (
    { readonly __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  ) }
);

export type DestroyCouponMutationVariables = {
  input: DestroyCouponInput;
};


export type DestroyCouponMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyCoupon: (
    { readonly __typename?: 'Coupon' }
    & Pick<Coupon, 'id'>
  ) }
);

export type DestroyCouponCodeMutationVariables = {
  input: DestroyCouponCodeInput;
};


export type DestroyCouponCodeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyCouponCode: (
    { readonly __typename?: 'CouponCode' }
    & Pick<CouponCode, 'id'>
  ) }
);

export type DestroyCouponRedemptionMutationVariables = {
  input: DestroyCouponRedemptionInput;
};


export type DestroyCouponRedemptionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyCouponRedemption: (
    { readonly __typename?: 'CouponRedemption' }
    & Pick<CouponRedemption, 'id'>
  ) }
);

export type DestroyCouponRestrictionMutationVariables = {
  input: DestroyCouponRestrictionInput;
};


export type DestroyCouponRestrictionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyCouponRestriction: (
    { readonly __typename?: 'CouponRestriction' }
    & Pick<CouponRestriction, 'id'>
  ) }
);

export type DestroyDashboardMutationVariables = {
  input: DestroyDashboardInput;
};


export type DestroyDashboardMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyDashboard: (
    { readonly __typename?: 'Dashboard' }
    & Pick<Dashboard, 'id'>
  ) }
);

export type DestroyDatabaseScratchpadMutationVariables = {
  input: DestroyDatabaseScratchpadInput;
};


export type DestroyDatabaseScratchpadMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyDatabaseScratchpad: (
    { readonly __typename?: 'DatabaseScratchpad' }
    & Pick<DatabaseScratchpad, 'id'>
  ) }
);

export type DestroyDeliveryMutationVariables = {
  input: DestroyDeliveryInput;
};


export type DestroyDeliveryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyDelivery: (
    { readonly __typename?: 'Delivery' }
    & Pick<Delivery, 'id'>
  ) }
);

export type DestroyGroupMutationVariables = {
  input: DestroyGroupInput;
};


export type DestroyGroupMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyGroup: (
    { readonly __typename?: 'Group' }
    & Pick<Group, 'id'>
  ) }
);

export type DestroyGroupMembershipMutationVariables = {
  input: DestroyGroupMembershipInput;
};


export type DestroyGroupMembershipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyGroupMembership: (
    { readonly __typename?: 'GroupMembership' }
    & Pick<GroupMembership, 'id'>
  ) }
);

export type DestroyItemMutationVariables = {
  input: DestroyItemInput;
};


export type DestroyItemMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyItem: (
    { readonly __typename?: 'Item' }
    & Pick<Item, 'id'>
  ) }
);

export type DestroyItemCategoryMutationVariables = {
  input: DestroyItemCategoryInput;
};


export type DestroyItemCategoryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyItemCategory: (
    { readonly __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'id'>
  ) }
);

export type DestroyItemCategoryMembershipMutationVariables = {
  input: DestroyItemCategoryMembershipInput;
};


export type DestroyItemCategoryMembershipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyItemCategoryMembership: (
    { readonly __typename?: 'ItemCategoryMembership' }
    & Pick<ItemCategoryMembership, 'id'>
  ) }
);

export type DestroyMenuElementMutationVariables = {
  input: DestroyMenuElementInput;
};


export type DestroyMenuElementMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyMenuElement: (
    { readonly __typename?: 'MenuElement' }
    & Pick<MenuElement, 'id'>
  ) }
);

export type DestroyOrderMutationVariables = {
  input: DestroyOrderInput;
};


export type DestroyOrderMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyOrder: (
    { readonly __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type DestroyOrderItemMutationVariables = {
  input: DestroyOrderItemInput;
};


export type DestroyOrderItemMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyOrderItem: (
    { readonly __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id'>
  ) }
);

export type DestroyParameterMutationVariables = {
  input: DestroyParameterInput;
};


export type DestroyParameterMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyParameter: (
    { readonly __typename?: 'Parameter' }
    & Pick<Parameter, 'id'>
  ) }
);

export type DestroyPreferenceMutationVariables = {
  input: DestroyPreferenceInput;
};


export type DestroyPreferenceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyPreference: (
    { readonly __typename?: 'Preference' }
    & Pick<Preference, 'id'>
  ) }
);

export type DestroyPricingMutationVariables = {
  input: DestroyPricingInput;
};


export type DestroyPricingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyPricing: (
    { readonly __typename?: 'Pricing' }
    & Pick<Pricing, 'id'>
  ) }
);

export type DestroyRecipientMutationVariables = {
  input: DestroyRecipientInput;
};


export type DestroyRecipientMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyRecipient: (
    { readonly __typename?: 'Recipient' }
    & Pick<Recipient, 'id'>
  ) }
);

export type DestroyRecordMutationVariables = {
  input: DestroyRecordInput;
};


export type DestroyRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyRecord: (
    { readonly __typename?: 'Record' }
    & Pick<Record, 'id'>
  ) }
);

export type DestroyRelationshipMutationVariables = {
  input: DestroyRelationshipInput;
};


export type DestroyRelationshipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyRelationship: (
    { readonly __typename?: 'Relationship' }
    & Pick<Relationship, 'id'>
  ) }
);

export type DestroyResourceMutationVariables = {
  input: DestroyResourceInput;
};


export type DestroyResourceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyResource: (
    { readonly __typename?: 'Resource' }
    & Pick<Resource, 'id'>
  ) }
);

export type DestroyRoleMutationVariables = {
  input: DestroyRoleInput;
};


export type DestroyRoleMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyRole: (
    { readonly __typename?: 'CustomRole' }
    & Pick<CustomRole, 'id'>
  ) }
);

export type DestroyRoleMembershipMutationVariables = {
  input: DestroyRoleMembershipInput;
};


export type DestroyRoleMembershipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyRoleMembership: (
    { readonly __typename?: 'RoleMembership' }
    & Pick<RoleMembership, 'id'>
  ) }
);

export type DestroySegmentMutationVariables = {
  input: DestroySegmentInput;
};


export type DestroySegmentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroySegment: (
    { readonly __typename?: 'Segment' }
    & Pick<Segment, 'id'>
  ) }
);

export type DestroyTemplateMutationVariables = {
  input: DestroyTemplateInput;
};


export type DestroyTemplateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyTemplate: (
    { readonly __typename?: 'Template' }
    & Pick<Template, 'id'>
  ) }
);

export type DestroyThemeMutationVariables = {
  input: DestroyThemeInput;
};


export type DestroyThemeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyTheme: (
    { readonly __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  ) }
);

export type DestroyWorkspacePaymentMethodMutationVariables = {
  input: DestroyWorkspacePaymentMethodInput;
};


export type DestroyWorkspacePaymentMethodMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly destroyWorkspacePaymentMethod: (
    { readonly __typename?: 'PaymentMethod' }
    & Pick<PaymentMethod, 'id'>
  ) }
);

export type DisableMfaMutationVariables = {
  input: DisableMfaInput;
};


export type DisableMfaMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly disableMfa: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type DuplicateFlowVersionMutationVariables = {
  input: DuplicateFlowVersionInput;
};


export type DuplicateFlowVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly duplicateFlowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  ) }
);

export type EditAccountTourMutationVariables = {
  input: EditAccountTourInput;
};


export type EditAccountTourMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly editAccountTour: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type EditDatabaseRecordMutationVariables = {
  input: EditDatabaseRecordInput;
};


export type EditDatabaseRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & Pick<Mutation, 'editDatabaseRecord'>
);

export type EnableMfaMutationVariables = {
  input: EnableMfaInput;
};


export type EnableMfaMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly enableMfa: (
    { readonly __typename?: 'EnableMfaResponse' }
    & Pick<EnableMfaResponse, 'mfaBackupCodes'>
  ) }
);

export type EnvironmentQueryVariables = {
  id: Scalars['UUID'];
};


export type EnvironmentQuery = (
  { readonly __typename?: 'Query' }
  & { readonly environment: (
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  ) }
);

export type EnvironmentFragmentFragment = (
  { readonly __typename?: 'Environment' }
  & Pick<Environment, 'id' | 'workspaceId' | 'name' | 'identifier' | 'isLive' | 'createdAt' | 'updatedAt'>
);

export type EnvironmentsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type EnvironmentsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly environmentsList: ReadonlyArray<(
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  )>, readonly environmentsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type EventFragmentFragment = (
  { readonly __typename?: 'Event' }
  & Pick<Event, 'id' | 'accountId' | 'eventTypeId' | 'data' | 'timestamp' | 'createdAt' | 'updatedAt' | 'environmentId'>
  & { readonly operationLog?: Maybe<(
    { readonly __typename?: 'OperationLog' }
    & Pick<OperationLog, 'id'>
    & { readonly systemContext?: Maybe<(
      { readonly __typename?: 'SystemContext' }
      & Pick<SystemContext, 'ipV4' | 'ipV6' | 'locale' | 'timeZone' | 'userAgent'>
      & { readonly app?: Maybe<(
        { readonly __typename?: 'SystemContextApp' }
        & Pick<SystemContextApp, 'name' | 'version' | 'build' | 'namespace'>
      )>, readonly device?: Maybe<(
        { readonly __typename?: 'SystemContextDevice' }
        & Pick<SystemContextDevice, 'id' | 'advertisingId' | 'adTrackingEnabled' | 'manufacturer' | 'model' | 'name' | 'kind'>
      )>, readonly os?: Maybe<(
        { readonly __typename?: 'SystemContextOs' }
        & Pick<SystemContextOs, 'name' | 'version'>
      )>, readonly library?: Maybe<(
        { readonly __typename?: 'SystemContextLibrary' }
        & Pick<SystemContextLibrary, 'name' | 'version'>
      )>, readonly network?: Maybe<(
        { readonly __typename?: 'SystemContextNetwork' }
        & Pick<SystemContextNetwork, 'bluetooth' | 'carrier' | 'cellular' | 'wifi'>
      )>, readonly screen?: Maybe<(
        { readonly __typename?: 'SystemContextScreen' }
        & Pick<SystemContextScreen, 'width' | 'height' | 'density'>
      )>, readonly campaign?: Maybe<(
        { readonly __typename?: 'SystemContextCampaign' }
        & Pick<SystemContextCampaign, 'name' | 'source' | 'medium' | 'term' | 'content'>
      )>, readonly location?: Maybe<(
        { readonly __typename?: 'SystemContextLocation' }
        & Pick<SystemContextLocation, 'latitude' | 'longitude' | 'speed'>
      )> }
    )> }
  )>, readonly account: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ), readonly eventType: (
    { readonly __typename?: 'EventType' }
    & EventTypeFragmentFragment
  ) }
);

export type EventReportQueryVariables = {
  id: Scalars['UUID'];
};


export type EventReportQuery = (
  { readonly __typename?: 'Query' }
  & { readonly eventReport: (
    { readonly __typename?: 'EventReport' }
    & Pick<EventReport, 'id' | 'name' | 'description' | 'criteria'>
  ) }
);

export type EventReportFragmentFragment = (
  { readonly __typename?: 'EventReport' }
  & Pick<EventReport, 'id' | 'workspaceId' | 'name' | 'description' | 'criteria' | 'createdAt' | 'updatedAt'>
);

export type EventReportsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type EventReportsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly eventReportsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly eventReportsList: ReadonlyArray<(
    { readonly __typename?: 'EventReport' }
    & EventReportFragmentFragment
  )> }
);

export type EventTypeFragmentFragment = (
  { readonly __typename?: 'EventType' }
  & Pick<EventType, 'id' | 'name' | 'workspaceId' | 'operationId' | 'dataSchema' | 'lastReceivedAt' | 'archivedAt' | 'createdAt'>
);

export type EventTypesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type EventTypesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly eventTypesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly eventTypesList: ReadonlyArray<(
    { readonly __typename?: 'EventType' }
    & EventTypeFragmentFragment
  )> }
);

export type EventsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type EventsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly eventsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type EventsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type EventsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly eventsList: ReadonlyArray<(
    { readonly __typename?: 'Event' }
    & EventFragmentFragment
  )> }
);

export type ExecuteApiRequestMutationVariables = {
  input: ExecuteApiRequestInput;
};


export type ExecuteApiRequestMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly executeApiRequest: (
    { readonly __typename?: 'ExecuteApiRequestResponse' }
    & Pick<ExecuteApiRequestResponse, 'time' | 'statusCode' | 'headers' | 'body'>
  ) }
);

export type ExecuteDatabaseQueryMutationVariables = {
  input: ExecuteDatabaseQueryInput;
};


export type ExecuteDatabaseQueryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly executeDatabaseQuery: (
    { readonly __typename?: 'ExecuteDatabaseQueryResponse' }
    & Pick<ExecuteDatabaseQueryResponse, 'time'>
    & { readonly results: ReadonlyArray<(
      { readonly __typename?: 'DatabaseTableExecuteQueryResult' }
      & Pick<DatabaseTableExecuteQueryResult, 'message' | 'success' | 'rowsAffected' | 'rows' | 'columns'>
    )> }
  ) }
);

export type ExecuteEventReportQueryVariables = {
  input: ExecuteEventReportInput;
};


export type ExecuteEventReportQuery = (
  { readonly __typename?: 'Query' }
  & { readonly executeEventReport: (
    { readonly __typename?: 'ExecuteEventReportResponse' }
    & { readonly behaviorReport?: Maybe<(
      { readonly __typename?: 'EventReportBehavior' }
      & { readonly series: ReadonlyArray<(
        { readonly __typename?: 'EventReportSeries' }
        & Pick<EventReportSeries, 'label' | 'values'>
      )>, readonly dateRange: (
        { readonly __typename?: 'EventReportDateRange' }
        & Pick<EventReportDateRange, 'from' | 'to'>
      ) }
    )>, readonly funnelReport?: Maybe<(
      { readonly __typename?: 'EventReportFunnel' }
      & { readonly steps: ReadonlyArray<(
        { readonly __typename?: 'EventReportFunnelStep' }
        & Pick<EventReportFunnelStep, 'count' | 'label' | 'overallConvRatio' | 'stepConvRatio'>
      )> }
    )> }
  ) }
);

export type ExecuteFlowMutationVariables = {
  input: ExecuteFlowInput;
};


export type ExecuteFlowMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly executeFlow: (
    { readonly __typename?: 'FlowExecution' }
    & FlowExecutionFragmentFragment
  ) }
);

export type ExecuteMutationOperationMutationVariables = {
  input: ExecuteMutationOperationInput;
};


export type ExecuteMutationOperationMutation = (
  { readonly __typename?: 'Mutation' }
  & Pick<Mutation, 'executeMutationOperation'>
);

export type ExecuteQueryOperationQueryVariables = {
  input: ExecuteQueryOperationInput;
};


export type ExecuteQueryOperationQuery = (
  { readonly __typename?: 'Query' }
  & Pick<Query, 'executeQueryOperation'>
);

export type ExportDatabaseRecordsQueryVariables = {
  input: ExportDatabaseRecordsInput;
};


export type ExportDatabaseRecordsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly exportDatabaseRecords: (
    { readonly __typename?: 'ExportDatabaseRecordsResponse' }
    & Pick<ExportDatabaseRecordsResponse, 'success'>
  ) }
);

export type FetchCurrentWorkspaceOrderQueryVariables = {};


export type FetchCurrentWorkspaceOrderQuery = (
  { readonly __typename?: 'Query' }
  & { readonly fetchCurrentWorkspaceOrder: (
    { readonly __typename?: 'CustomOrder' }
    & CustomOrderFragmentFragment
  ) }
);

export type FetchStoredPreferencesQueryVariables = {
  input: FetchStoredPreferencesInput;
};


export type FetchStoredPreferencesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly fetchStoredPreferences: (
    { readonly __typename?: 'FetchStoredPreferencesResponse' }
    & Pick<FetchStoredPreferencesResponse, 'preferenceData'>
  ) }
);

export type FetchWorkspaceBillingAccountQueryVariables = {};


export type FetchWorkspaceBillingAccountQuery = (
  { readonly __typename?: 'Query' }
  & { readonly fetchWorkspaceBillingAccount: (
    { readonly __typename?: 'WorkspaceBillingAccount' }
    & WorkspaceBillingAccountFragmentFragment
  ) }
);

export type FieldTypesListQueryVariables = {};


export type FieldTypesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly fieldTypes: ReadonlyArray<(
    { readonly __typename?: 'FieldType' }
    & Pick<FieldType, 'name' | 'identifier' | 'icon' | 'position' | 'category' | 'dataType' | 'helpText' | 'defaultValue' | 'placeholder' | 'configuration' | 'settings'>
  )> }
);

export type FlowQueryVariables = {
  id: Scalars['UUID'];
};


export type FlowQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flow: (
    { readonly __typename?: 'Flow' }
    & { readonly flowVersions: ReadonlyArray<(
      { readonly __typename?: 'FlowVersion' }
      & FlowVersionFragmentFragment
    )> }
    & FlowFragmentFragment
  ) }
);

export type FlowExecutionQueryVariables = {
  id: Scalars['UUID'];
};


export type FlowExecutionQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowExecution: (
    { readonly __typename?: 'FlowExecution' }
    & FlowExecutionFragmentFragment
  ) }
);

export type FlowExecutionFragmentFragment = (
  { readonly __typename?: 'FlowExecution' }
  & Pick<FlowExecution, 'id' | 'flowVersionId' | 'status' | 'failureReason' | 'createdAt' | 'updatedAt' | 'enteredAt' | 'exitedAt' | 'scheduledAt'>
  & { readonly flowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & { readonly flow: (
      { readonly __typename?: 'Flow' }
      & FlowFragmentFragment
    ) }
    & FlowVersionFragmentFragment
  ) }
);

export type FlowExecutionNodeQueryVariables = {
  id: Scalars['UUID'];
};


export type FlowExecutionNodeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowExecutionNode: (
    { readonly __typename?: 'FlowExecutionNode' }
    & Pick<FlowExecutionNode, 'data'>
    & FlowExecutionNodeFragmentFragment
  ) }
);

export type FlowExecutionNodeFragmentFragment = (
  { readonly __typename?: 'FlowExecutionNode' }
  & Pick<FlowExecutionNode, 'id' | 'flowExecutionId' | 'flowNodeId' | 'status' | 'failureReason' | 'createdAt' | 'updatedAt' | 'enteredAt' | 'exitedAt'>
  & { readonly flowNode: (
    { readonly __typename?: 'FlowNode' }
    & Pick<FlowNode, 'id' | 'identifier'>
  ) }
);

export type FlowExecutionNodesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type FlowExecutionNodesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowExecutionNodesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type FlowExecutionNodesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type FlowExecutionNodesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowExecutionNodesList: ReadonlyArray<(
    { readonly __typename?: 'FlowExecutionNode' }
    & FlowExecutionNodeFragmentFragment
  )> }
);

export type FlowExecutionsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type FlowExecutionsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowExecutionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type FlowExecutionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type FlowExecutionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowExecutionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly flowExecutionsList: ReadonlyArray<(
    { readonly __typename?: 'FlowExecution' }
    & FlowExecutionFragmentFragment
  )> }
);

export type FlowFragmentFragment = (
  { readonly __typename?: 'Flow' }
  & Pick<Flow, 'id' | 'name' | 'status' | 'identifier' | 'runCount' | 'lastRunAt' | 'createdAt' | 'updatedAt'>
);

export type FlowVersionQueryVariables = {
  id: Scalars['UUID'];
};


export type FlowVersionQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  ) }
);

export type FlowVersionFragmentFragment = (
  { readonly __typename?: 'FlowVersion' }
  & Pick<FlowVersion, 'id' | 'flowId' | 'status' | 'version' | 'snapshot' | 'lastRunAt' | 'runCount' | 'createdAt' | 'updatedAt'>
);

export type FlowsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type FlowsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowsList: ReadonlyArray<(
    { readonly __typename?: 'Flow' }
    & FlowFragmentFragment
  )> }
);

export type ForgotPasswordMutationVariables = {
  input: ForgotPasswordInput;
};


export type ForgotPasswordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly forgotPassword: (
    { readonly __typename?: 'ForgotPasswordResponse' }
    & Pick<ForgotPasswordResponse, 'success'>
  ) }
);

export type GenerateGraphMutationVariables = {
  input: GenerateGraphInput;
};


export type GenerateGraphMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly generateGraph: (
    { readonly __typename?: 'GenerateGraphResponse' }
    & { readonly resources: ReadonlyArray<(
      { readonly __typename?: 'Resource' }
      & ResourceFragmentFragment
    )> }
  ) }
);

export type GenerateIdentityTokenQueryVariables = {
  input: GenerateIdentityTokenInput;
};


export type GenerateIdentityTokenQuery = (
  { readonly __typename?: 'Query' }
  & { readonly generateIdentityToken: (
    { readonly __typename?: 'GenerateIdentityTokenResponse' }
    & Pick<GenerateIdentityTokenResponse, 'token'>
  ) }
);

export type GenerateMenuElementsMutationVariables = {
  input: GenerateMenuElementsInput;
};


export type GenerateMenuElementsMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly generateMenuElements: ReadonlyArray<(
    { readonly __typename?: 'MenuElement' }
    & MenuElementFragmentFragment
  )> }
);

export type GroupQueryVariables = {
  id: Scalars['UUID'];
};


export type GroupQuery = (
  { readonly __typename?: 'Query' }
  & { readonly group: (
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  ) }
);

export type GroupFragmentFragment = (
  { readonly __typename?: 'Group' }
  & Pick<Group, 'id' | 'name' | 'parentId' | 'position' | 'accountKind' | 'environmentId' | 'createdAt' | 'updatedAt'>
);

export type GroupMembershipFragmentFragment = (
  { readonly __typename?: 'GroupMembership' }
  & Pick<GroupMembership, 'id' | 'accountId' | 'groupId' | 'createdAt' | 'updatedAt'>
  & { readonly account: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ), readonly group: (
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  ) }
);

export type GroupMembershipsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type GroupMembershipsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly groupMembershipsList: ReadonlyArray<(
    { readonly __typename?: 'GroupMembership' }
    & GroupMembershipFragmentFragment
  )>, readonly groupMembershipsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type GroupsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type GroupsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly groupsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly groupsList: ReadonlyArray<(
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  )> }
);

export type GroupsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type GroupsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly groupsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type IdentifyProspectMutationVariables = {
  input: IdentifyProspectInput;
};


export type IdentifyProspectMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly identifyProspect: (
    { readonly __typename?: 'IdentifyProspectResponse' }
    & Pick<IdentifyProspectResponse, 'success'>
  ) }
);

export type InstallationQueryVariables = {
  id: Scalars['UUID'];
};


export type InstallationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly installation: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ) }
);

export type InstallationFragmentFragment = (
  { readonly __typename?: 'Installation' }
  & Pick<Installation, 'id' | 'workspaceId' | 'appId' | 'appKind' | 'name' | 'identifier' | 'createdAt' | 'updatedAt' | 'archivedAt'>
  & { readonly app: (
    { readonly __typename?: 'App' }
    & AppFragmentFragment
  ) }
);

export type InstallationsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type InstallationsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly installationsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type InstallationsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type InstallationsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly installationsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly installationsList: ReadonlyArray<(
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  )> }
);

export type InternalAddRecordMutationVariables = {
  input: InternalAddRecordInput;
};


export type InternalAddRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly internalAddRecord: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type InternalDeleteRecordMutationVariables = {
  input: InternalDeleteRecordInput;
};


export type InternalDeleteRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly internalDeleteRecord: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type InternalEditRecordMutationVariables = {
  input: InternalEditRecordInput;
};


export type InternalEditRecordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly internalEditRecord: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type InternalFetchRecordQueryVariables = {
  input: InternalFetchRecordInput;
};


export type InternalFetchRecordQuery = (
  { readonly __typename?: 'Query' }
  & { readonly internalFetchRecord: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type InternalImportRecordsMutationVariables = {
  input: InternalImportRecordsInput;
};


export type InternalImportRecordsMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly internalImportRecords: ReadonlyArray<(
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  )> }
);

export type InternalSearchRecordsQueryVariables = {
  input: InternalSearchRecordsInput;
};


export type InternalSearchRecordsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly internalSearchRecords: ReadonlyArray<(
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  )> }
);

export type InternalSummarizeRecordsQueryVariables = {
  input: InternalSummarizeRecordsInput;
};


export type InternalSummarizeRecordsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly internalSummarizeRecords: (
    { readonly __typename?: 'InternalSummarizeRecordsResponse' }
    & Pick<InternalSummarizeRecordsResponse, 'count'>
  ) }
);

export type ItemCategoriesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ItemCategoriesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly itemCategoriesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly itemCategoriesList: ReadonlyArray<(
    { readonly __typename?: 'ItemCategory' }
    & ItemCategoryFragmentFragment
  )> }
);

export type ItemCategoryFragmentFragment = (
  { readonly __typename?: 'ItemCategory' }
  & Pick<ItemCategory, 'id' | 'name' | 'identifier' | 'description' | 'createdAt' | 'updatedAt' | 'workspaceId'>
);

export type ItemCategoryMembershipFragmentFragment = (
  { readonly __typename?: 'ItemCategoryMembership' }
  & Pick<ItemCategoryMembership, 'id' | 'itemId' | 'itemCategoryId'>
);

export type ItemCategoryMembershipsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ItemCategoryMembershipsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly itemCategoryMembershipsList: ReadonlyArray<(
    { readonly __typename?: 'ItemCategoryMembership' }
    & ItemCategoryMembershipFragmentFragment
  )> }
);

export type ItemFragmentFragment = (
  { readonly __typename?: 'Item' }
  & Pick<Item, 'id' | 'description' | 'identifier' | 'kind' | 'name' | 'createdAt' | 'updatedAt' | 'workspaceId'>
  & { readonly pricings: ReadonlyArray<(
    { readonly __typename?: 'Pricing' }
    & PricingFragmentFragment
  )>, readonly itemCategoryMemberships: ReadonlyArray<(
    { readonly __typename?: 'ItemCategoryMembership' }
    & Pick<ItemCategoryMembership, 'id'>
    & { readonly itemCategory: (
      { readonly __typename?: 'ItemCategory' }
      & Pick<ItemCategory, 'id' | 'name'>
    ) }
  )> }
);

export type ItemsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type ItemsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly itemsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type ItemsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ItemsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly itemsList: ReadonlyArray<(
    { readonly __typename?: 'Item' }
    & ItemFragmentFragment
  )> }
);

export type KeyPairsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type KeyPairsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly keyPairsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly keyPairsList: ReadonlyArray<(
    { readonly __typename?: 'KeyPair' }
    & Pick<KeyPair, 'id' | 'publicKey' | 'privateKey' | 'expiresAt' | 'createdAt'>
  )> }
);

export type LocaleListQueryVariables = {};


export type LocaleListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly locales: ReadonlyArray<(
    { readonly __typename?: 'Locale' }
    & Pick<Locale, 'name' | 'identifier'>
  )> }
);

export type MenuElementFragmentFragment = (
  { readonly __typename?: 'MenuElement' }
  & Pick<MenuElement, 'id' | 'dashboardId' | 'icon' | 'isRepeated' | 'isSticky' | 'kind' | 'name' | 'parentId' | 'path' | 'placement' | 'position' | 'query' | 'separatorStyle' | 'target' | 'url' | 'viewUrn'>
);

export type MenuElementsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type MenuElementsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly menuElementsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type MenuElementsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type MenuElementsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly menuElementsList: ReadonlyArray<(
    { readonly __typename?: 'MenuElement' }
    & MenuElementFragmentFragment
  )> }
);

export type NotificationFragmentFragment = (
  { readonly __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'deliveryId' | 'integrationId' | 'data' | 'renderedContent' | 'report' | 'failedSendAttempts' | 'failedDeliveryAttempts' | 'lastDeliveryFailureAt' | 'lastSendFailureAt' | 'failureReason' | 'unsubscribedAt' | 'firstClickedAt' | 'complainedAt' | 'failedAt' | 'sentAt' | 'deliveredAt' | 'firstOpenedAt' | 'externalUid' | 'queuedAt' | 'createdAt' | 'updatedAt'>
  & { readonly delivery: (
    { readonly __typename?: 'Delivery' }
    & DeliveryFragmentFragment
  ), readonly notificationRecipients: ReadonlyArray<(
    { readonly __typename?: 'NotificationRecipient' }
    & Pick<NotificationRecipient, 'id' | 'notificationId' | 'recipientId' | 'contactId' | 'contact' | 'readAt' | 'externalUid' | 'createdAt' | 'updatedAt'>
    & { readonly recipient: (
      { readonly __typename?: 'Recipient' }
      & Pick<Recipient, 'id' | 'accountId' | 'deliveryId' | 'groupId' | 'parentId' | 'target' | 'kind' | 'data' | 'status' | 'statusReason' | 'createdAt' | 'updatedAt'>
    ) }
  )> }
);

export type NotificationLogsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type NotificationLogsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly notificationLogsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type NotificationLogsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type NotificationLogsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly notificationLogsList: ReadonlyArray<(
    { readonly __typename?: 'NotificationLog' }
    & Pick<NotificationLog, 'id' | 'notificationId' | 'integrationId' | 'category' | 'subcategory' | 'data' | 'timestamp' | 'createdAt' | 'updatedAt'>
    & { readonly notification: (
      { readonly __typename?: 'Notification' }
      & NotificationFragmentFragment
    ), readonly integration: (
      { readonly __typename?: 'Installation' }
      & InstallationFragmentFragment
    ) }
  )> }
);

export type NotificationRecipientsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type NotificationRecipientsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly notificationRecipientsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type NotificationRecipientsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type NotificationRecipientsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly notificationRecipientsList: ReadonlyArray<(
    { readonly __typename?: 'NotificationRecipient' }
    & Pick<NotificationRecipient, 'id' | 'notificationId' | 'recipientId' | 'contactId' | 'contact' | 'readAt' | 'externalUid' | 'createdAt' | 'updatedAt'>
    & { readonly recipient: (
      { readonly __typename?: 'Recipient' }
      & RecipientFragmentFragment
    ) }
  )> }
);

export type NotificationsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type NotificationsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly notificationsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type NotificationsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type NotificationsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly notificationsList: ReadonlyArray<(
    { readonly __typename?: 'Notification' }
    & NotificationFragmentFragment
  )> }
);

export type OperationQueryVariables = {
  id: Scalars['UUID'];
};


export type OperationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly operation: (
    { readonly __typename?: 'Operation' }
    & OperationFragmentFragment
  ) }
);

export type OperationFragmentFragment = (
  { readonly __typename?: 'Operation' }
  & Pick<Operation, 'id' | 'name' | 'identifier' | 'description' | 'icon' | 'position' | 'graphqlKind' | 'actsOn' | 'behaviorMethod' | 'behaviorKind' | 'behaviorSettings' | 'createdAt' | 'updatedAt' | 'workspaceId' | 'appId' | 'resourceId' | 'responseMapper'>
  & { readonly app?: Maybe<(
    { readonly __typename?: 'App' }
    & Pick<App, 'id' | 'name' | 'icon' | 'identifier' | 'appCategoryId' | 'kind'>
  )>, readonly resource?: Maybe<(
    { readonly __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'workspaceId' | 'environmentBehavior' | 'isPublishingEnabled' | 'appId' | 'name' | 'identifier' | 'description' | 'origins' | 'icon' | 'position' | 'titleAttributeId' | 'subtitleAttributeId' | 'polymorphicAttributeId' | 'creationTimestampAttributeId' | 'updationTimestampAttributeId' | 'deletionTimestampAttributeId' | 'positionAttributeId' | 'orderStyle' | 'orderAttributes' | 'validations' | 'isReadOnly' | 'isTranslatable' | 'createdAt' | 'updatedAt'>
  )>, readonly parameters: ReadonlyArray<(
    { readonly __typename?: 'Parameter' }
    & Pick<Parameter, 'id' | 'workspaceId' | 'operationId' | 'attributeId' | 'relationshipId' | 'name' | 'identifier' | 'fieldType' | 'isNullable' | 'isArray' | 'validations' | 'defaultValue' | 'position' | 'createdAt' | 'updatedAt' | 'dataTypeId' | 'fieldTypeSettings' | 'displayType' | 'displayTypeSettings'>
  )> }
);

export type OperationListFragmentFragment = (
  { readonly __typename?: 'Operation' }
  & Pick<Operation, 'id' | 'name' | 'identifier' | 'description' | 'icon' | 'position' | 'graphqlKind' | 'actsOn' | 'behaviorMethod' | 'behaviorKind' | 'behaviorSettings' | 'createdAt' | 'updatedAt' | 'workspaceId' | 'appId' | 'resourceId' | 'responseMapper'>
  & { readonly app?: Maybe<(
    { readonly __typename?: 'App' }
    & Pick<App, 'id' | 'name' | 'icon' | 'identifier' | 'appCategoryId' | 'kind'>
  )>, readonly resource?: Maybe<(
    { readonly __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'workspaceId' | 'environmentBehavior' | 'isPublishingEnabled' | 'appId' | 'name' | 'identifier' | 'description' | 'origins' | 'icon' | 'position' | 'titleAttributeId' | 'subtitleAttributeId' | 'polymorphicAttributeId' | 'creationTimestampAttributeId' | 'updationTimestampAttributeId' | 'deletionTimestampAttributeId' | 'positionAttributeId' | 'orderStyle' | 'orderAttributes' | 'validations' | 'isReadOnly' | 'isTranslatable' | 'createdAt' | 'updatedAt'>
  )>, readonly parameters: ReadonlyArray<(
    { readonly __typename?: 'Parameter' }
    & Pick<Parameter, 'id' | 'workspaceId' | 'operationId' | 'attributeId' | 'relationshipId' | 'name' | 'identifier' | 'fieldType'>
  )> }
);

export type OperationsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type OperationsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly operationsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type OperationsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type OperationsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly operationsList: ReadonlyArray<(
    { readonly __typename?: 'Operation' }
    & OperationListFragmentFragment
  )> }
);

export type OrderQueryVariables = {
  id: Scalars['UUID'];
};


export type OrderQuery = (
  { readonly __typename?: 'Query' }
  & { readonly order: (
    { readonly __typename?: 'Order' }
    & OrderFragmentFragment
  ) }
);

export type OrderFragmentFragment = (
  { readonly __typename?: 'Order' }
  & Pick<Order, 'id' | 'accountId' | 'subtotal' | 'discount' | 'tax' | 'total' | 'currencyCode' | 'gatewayId' | 'gatewayMeta' | 'status' | 'createdAt' | 'updatedAt' | 'checkedOutAt' | 'paidAt' | 'environmentId' | 'paymentLink'>
  & { readonly orderItems: ReadonlyArray<(
    { readonly __typename?: 'OrderItem' }
    & OrderItemFragmentFragment
  )>, readonly account?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )> }
);

export type OrderItemFragmentFragment = (
  { readonly __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'discount' | 'itemId' | 'orderId' | 'pricingId' | 'quantity' | 'subtotal' | 'tax' | 'total' | 'unitPrice' | 'currencyCode' | 'createdAt' | 'updatedAt'>
  & { readonly item: (
    { readonly __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type OrderItemsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type OrderItemsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly orderItemsList: ReadonlyArray<(
    { readonly __typename?: 'OrderItem' }
    & OrderItemFragmentFragment
  )> }
);

export type OrdersAggregateByItemsQueryVariables = {
  input: OrdersAggregateByItemsInput;
};


export type OrdersAggregateByItemsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly ordersAggregateByItems: (
    { readonly __typename?: 'OrdersAggregateByItemsResponse' }
    & Pick<OrdersAggregateByItemsResponse, 'count'>
  ) }
);

export type OrdersListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type OrdersListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly ordersAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly ordersList: ReadonlyArray<(
    { readonly __typename?: 'Order' }
    & OrderFragmentFragment
  )> }
);

export type OrdersListByItemsQueryVariables = {
  input: OrdersListByItemsInput;
};


export type OrdersListByItemsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly ordersListByItems: ReadonlyArray<(
    { readonly __typename?: 'Order' }
    & OrderFragmentFragment
  )> }
);

export type ParameterQueryVariables = {
  id: Scalars['UUID'];
};


export type ParameterQuery = (
  { readonly __typename?: 'Query' }
  & { readonly parameter: (
    { readonly __typename?: 'Parameter' }
    & ParameterFragmentFragment
  ) }
);

export type ParameterFragmentFragment = (
  { readonly __typename?: 'Parameter' }
  & Pick<Parameter, 'id' | 'workspaceId' | 'operationId' | 'attributeId' | 'relationshipId' | 'name' | 'identifier' | 'fieldType' | 'isNullable' | 'isArray' | 'validations' | 'defaultValue' | 'position' | 'createdAt' | 'updatedAt' | 'dataTypeId' | 'fieldTypeSettings' | 'displayType' | 'displayTypeSettings'>
  & { readonly attribute?: Maybe<(
    { readonly __typename?: 'Attribute' }
    & AttributeFragmentFragment
  )>, readonly relationship?: Maybe<(
    { readonly __typename?: 'Relationship' }
    & Pick<Relationship, 'id' | 'name' | 'identifier' | 'sourceId' | 'sourceAttributeId' | 'targetId' | 'targetAttributeId' | 'kind' | 'filter' | 'position' | 'createdAt' | 'updatedAt'>
  )>, readonly dataType?: Maybe<(
    { readonly __typename?: 'DataType' }
    & DataTypeFragmentFragment
  )> }
);

export type ParameterListFragmentFragment = (
  { readonly __typename?: 'Parameter' }
  & Pick<Parameter, 'id' | 'workspaceId' | 'operationId' | 'attributeId' | 'relationshipId' | 'name' | 'identifier' | 'fieldType' | 'isNullable' | 'isArray' | 'validations' | 'defaultValue' | 'position' | 'createdAt' | 'updatedAt' | 'dataTypeId' | 'fieldTypeSettings' | 'displayType' | 'displayTypeSettings'>
  & { readonly attribute?: Maybe<(
    { readonly __typename?: 'Attribute' }
    & Pick<Attribute, 'id' | 'name' | 'identifier' | 'fieldType'>
  )>, readonly relationship?: Maybe<(
    { readonly __typename?: 'Relationship' }
    & Pick<Relationship, 'id' | 'name' | 'identifier' | 'sourceId' | 'sourceAttributeId' | 'targetId' | 'targetAttributeId'>
  )>, readonly dataType?: Maybe<(
    { readonly __typename?: 'DataType' }
    & Pick<DataType, 'id' | 'workspaceId' | 'name' | 'identifier' | 'kind' | 'settings' | 'defaultValue' | 'validations' | 'createdAt' | 'updatedAt' | 'description' | 'icon' | 'isPrimitive' | 'allowableFieldTypes' | 'category' | 'position'>
  )>, readonly operation: (
    { readonly __typename?: 'Operation' }
    & Pick<Operation, 'id' | 'name' | 'identifier' | 'description'>
  ) }
);

export type ParametersAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type ParametersAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly parametersAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type ParametersListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ParametersListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly parametersList: ReadonlyArray<(
    { readonly __typename?: 'Parameter' }
    & ParameterListFragmentFragment
  )> }
);

export type ParseIdentityTokenQueryVariables = {
  input: ParseIdentityTokenInput;
};


export type ParseIdentityTokenQuery = (
  { readonly __typename?: 'Query' }
  & { readonly parseIdentityToken: (
    { readonly __typename?: 'ParseIdentityTokenResponse' }
    & Pick<ParseIdentityTokenResponse, 'uid' | 'kind'>
  ) }
);

export type PauseFlowExecutionMutationVariables = {
  input: PauseFlowExecutionInput;
};


export type PauseFlowExecutionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly pauseFlowExecution: (
    { readonly __typename?: 'FlowExecution' }
    & FlowExecutionFragmentFragment
  ) }
);

export type PaymentMethodFragmentFragment = (
  { readonly __typename?: 'PaymentMethod' }
  & Pick<PaymentMethod, 'id' | 'accountId' | 'isDefault' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  & { readonly card?: Maybe<(
    { readonly __typename?: 'Card' }
    & Pick<Card, 'id' | 'paymentMethodId' | 'last4' | 'brand' | 'expiryMonth' | 'expiryYear' | 'createdAt' | 'updatedAt'>
  )> }
);

export type PreferenceFragmentFragment = (
  { readonly __typename?: 'Preference' }
  & Pick<Preference, 'id' | 'identifier' | 'isEnabledByDefault' | 'name' | 'restrictions' | 'createdAt' | 'updatedAt' | 'workspaceId'>
  & { readonly preferenceRestrictions: ReadonlyArray<(
    { readonly __typename?: 'PreferenceRestriction' }
    & Pick<PreferenceRestriction, 'id' | 'templateId' | 'preferenceId' | 'createdAt' | 'updatedAt'>
    & { readonly template: (
      { readonly __typename?: 'Template' }
      & TemplateFragmentFragment
    ) }
  )> }
);

export type PreferencesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type PreferencesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly preferencesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type PreferencesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type PreferencesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly preferencesList: ReadonlyArray<(
    { readonly __typename?: 'Preference' }
    & PreferenceFragmentFragment
  )> }
);

export type PrepareAssetMutationVariables = {
  input: PrepareAssetInput;
};


export type PrepareAssetMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly prepareAsset: (
    { readonly __typename?: 'Asset' }
    & AssetFragmentFragment
  ) }
);

export type PrepareWorkspacePaymentMethodMutationVariables = {};


export type PrepareWorkspacePaymentMethodMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly prepareWorkspacePaymentMethod: (
    { readonly __typename?: 'PrepareWorkspacePaymentMethodResponse' }
    & Pick<PrepareWorkspacePaymentMethodResponse, 'token'>
  ) }
);

export type PricingFragmentFragment = (
  { readonly __typename?: 'Pricing' }
  & Pick<Pricing, 'id' | 'itemId' | 'amount' | 'kind' | 'currencyCode' | 'originalAmount' | 'isRecurring' | 'recurringInterval' | 'recurringIntervalUnit' | 'appleProductIdentifier' | 'googleProductIdentifier' | 'createdAt' | 'updatedAt'>
);

export type PricingsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type PricingsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly pricingsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly pricingsList: ReadonlyArray<(
    { readonly __typename?: 'Pricing' }
    & PricingFragmentFragment
  )> }
);

export type ProspectWorkspacesListQueryVariables = {};


export type ProspectWorkspacesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly prospectWorkspaces: ReadonlyArray<(
    { readonly __typename?: 'ProspectWorkspace' }
    & Pick<ProspectWorkspace, 'id' | 'name' | 'identifier' | 'accessUrl' | 'hostname' | 'logoSymbol'>
  )> }
);

export type PublishFlowVersionMutationVariables = {
  input: PublishFlowVersionInput;
};


export type PublishFlowVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly publishFlowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  ) }
);

export type PublishRecordVersionMutationVariables = {
  input: PublishRecordVersionInput;
};


export type PublishRecordVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly publishRecordVersion: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type PublishTemplateVersionMutationVariables = {
  input: PublishTemplateVersionInput;
};


export type PublishTemplateVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly publishTemplateVersion: (
    { readonly __typename?: 'Template' }
    & Pick<Template, 'id' | 'identifier' | 'createdAt' | 'updatedAt' | 'publishedVersionId'>
  ) }
);

export type RecipientFragmentFragment = (
  { readonly __typename?: 'Recipient' }
  & Pick<Recipient, 'id' | 'accountId' | 'deliveryId' | 'groupId' | 'parentId' | 'target' | 'kind' | 'data' | 'status' | 'statusReason' | 'createdAt' | 'updatedAt'>
  & { readonly account?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )>, readonly group?: Maybe<(
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  )> }
);

export type RecipientsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type RecipientsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly recipientsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type RecipientsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type RecipientsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly recipientsList: ReadonlyArray<(
    { readonly __typename?: 'Recipient' }
    & RecipientFragmentFragment
  )> }
);

export type RecordFragmentFragment = (
  { readonly __typename?: 'Record' }
  & Pick<Record, 'id' | 'workspaceId' | 'environmentId' | 'resourceId' | 'createdAt' | 'updatedAt' | 'publishedId' | 'latestId'>
  & { readonly published: (
    { readonly __typename?: 'RecordVersion' }
    & RecordVersionFragmentFragment
  ), readonly draft: (
    { readonly __typename?: 'RecordVersion' }
    & RecordVersionFragmentFragment
  ) }
);

export type RecordVersionFragmentFragment = (
  { readonly __typename?: 'RecordVersion' }
  & Pick<RecordVersion, 'id' | 'recordId' | 'version' | 'snapshot' | 'createdAt' | 'createdById' | 'updatedAt' | 'updatedById' | 'deletedAt' | 'deletedById'>
  & { readonly createdBy?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )>, readonly updatedBy?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )>, readonly deletedBy?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )> }
);

export type RecordVersionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type RecordVersionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly recordVersionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly recordVersionsList: ReadonlyArray<(
    { readonly __typename?: 'RecordVersion' }
    & RecordVersionFragmentFragment
  )> }
);

export type RecordsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type RecordsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly recordsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type RecordsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type RecordsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly recordsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly recordsList: ReadonlyArray<(
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  )> }
);

export type RegisterWorkspaceMutationVariables = {
  input: RegisterWorkspaceInput;
};


export type RegisterWorkspaceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly registerWorkspace: (
    { readonly __typename?: 'RegisterWorkspaceResponse' }
    & Pick<RegisterWorkspaceResponse, 'invitationUrl'>
  ) }
);

export type RelationshipQueryVariables = {
  id: Scalars['UUID'];
};


export type RelationshipQuery = (
  { readonly __typename?: 'Query' }
  & { readonly relationship: (
    { readonly __typename?: 'Relationship' }
    & RelationshipFragmentFragment
  ) }
);

export type RelationshipFragmentFragment = (
  { readonly __typename?: 'Relationship' }
  & Pick<Relationship, 'id' | 'name' | 'identifier' | 'sourceId' | 'sourceAttributeId' | 'targetId' | 'targetAttributeId' | 'kind' | 'filter' | 'position' | 'createdAt' | 'updatedAt'>
  & { readonly source: (
    { readonly __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'name' | 'identifier' | 'titleAttributeId' | 'subtitleAttributeId'>
    & { readonly app?: Maybe<(
      { readonly __typename?: 'App' }
      & Pick<App, 'name'>
    )>, readonly attributes: ReadonlyArray<(
      { readonly __typename?: 'Attribute' }
      & AttributeListFragmentFragment
    )> }
  ), readonly sourceAttribute: (
    { readonly __typename?: 'Attribute' }
    & Pick<Attribute, 'id' | 'workspaceId' | 'resourceId' | 'name' | 'identifier' | 'fieldType' | 'fieldTypeSettings' | 'displayType' | 'displayTypeSettings' | 'dataTypeId' | 'dataTypeSettings' | 'isNullable' | 'isArray' | 'isTranslatable' | 'isFilterable' | 'isOrderable' | 'isPrimary' | 'validations' | 'resolution' | 'resolutionKind' | 'resolutionSettings' | 'settings' | 'defaultValue' | 'position' | 'createdAt' | 'updatedAt'>
  ), readonly target: (
    { readonly __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'name' | 'identifier' | 'titleAttributeId' | 'subtitleAttributeId'>
    & { readonly app?: Maybe<(
      { readonly __typename?: 'App' }
      & Pick<App, 'name'>
    )>, readonly attributes: ReadonlyArray<(
      { readonly __typename?: 'Attribute' }
      & AttributeListFragmentFragment
    )> }
  ), readonly targetAttribute: (
    { readonly __typename?: 'Attribute' }
    & Pick<Attribute, 'id' | 'name' | 'identifier' | 'fieldType'>
  ) }
);

export type RelationshipsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type RelationshipsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly relationshipsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type RelationshipsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type RelationshipsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly relationshipsList: ReadonlyArray<(
    { readonly __typename?: 'Relationship' }
    & RelationshipFragmentFragment
  )> }
);

export type RemoveWorkspaceLocaleMutationVariables = {
  input: RemoveWorkspaceLocaleInput;
};


export type RemoveWorkspaceLocaleMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly removeWorkspaceLocale: (
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  ) }
);

export type ResetPasswordMutationVariables = {
  input: ResetPasswordInput;
};


export type ResetPasswordMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly resetPassword: (
    { readonly __typename?: 'Session' }
    & Pick<Session, 'id' | 'token'>
  ) }
);

export type ResolveViewQueryVariables = {
  input: ResolveViewInput;
};


export type ResolveViewQuery = (
  { readonly __typename?: 'Query' }
  & { readonly resolveView?: Maybe<(
    { readonly __typename?: 'View' }
    & ViewFragmentFragment
  )> }
);

export type ResourceQueryVariables = {
  id: Scalars['UUID'];
};


export type ResourceQuery = (
  { readonly __typename?: 'Query' }
  & { readonly resource: (
    { readonly __typename?: 'Resource' }
    & ResourceFragmentFragment
  ) }
);

export type ResourceFragmentFragment = (
  { readonly __typename?: 'Resource' }
  & Pick<Resource, 'id' | 'workspaceId' | 'environmentBehavior' | 'isPublishingEnabled' | 'appId' | 'name' | 'identifier' | 'description' | 'origins' | 'icon' | 'position' | 'titleAttributeId' | 'subtitleAttributeId' | 'polymorphicAttributeId' | 'creationTimestampAttributeId' | 'updationTimestampAttributeId' | 'deletionTimestampAttributeId' | 'positionAttributeId' | 'orderStyle' | 'orderAttributes' | 'validations' | 'isReadOnly' | 'isTranslatable' | 'createdAt' | 'updatedAt'>
  & { readonly app?: Maybe<(
    { readonly __typename?: 'App' }
    & Pick<App, 'id' | 'name' | 'icon' | 'identifier' | 'appCategoryId' | 'kind'>
  )> }
);

export type ResourcesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type ResourcesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly resourcesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type ResourcesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ResourcesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly resourcesList: ReadonlyArray<(
    { readonly __typename?: 'Resource' }
    & ResourceFragmentFragment
  )> }
);

export type RestoreRecordVersionMutationVariables = {
  input: RestoreRecordVersionInput;
};


export type RestoreRecordVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly restoreRecordVersion: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type RestoreTemplateVersionMutationVariables = {
  input: RestoreTemplateVersionInput;
};


export type RestoreTemplateVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly restoreTemplateVersion: (
    { readonly __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type ResumeFlowExecutionMutationVariables = {
  input: ResumeFlowExecutionInput;
};


export type ResumeFlowExecutionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly resumeFlowExecution: (
    { readonly __typename?: 'FlowExecution' }
    & FlowExecutionFragmentFragment
  ) }
);

export type RetrieveMfaBackupCodesQueryVariables = {
  input: RetrieveMfaBackupCodesInput;
};


export type RetrieveMfaBackupCodesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly retrieveMfaBackupCodes: (
    { readonly __typename?: 'RetrieveMfaBackupCodesResponse' }
    & Pick<RetrieveMfaBackupCodesResponse, 'mfaBackupCodes'>
  ) }
);

export type RevokeKeyPairMutationVariables = {
  input: RevokeKeyPairInput;
};


export type RevokeKeyPairMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly revokeKeyPair: (
    { readonly __typename?: 'KeyPair' }
    & Pick<KeyPair, 'id' | 'expiresAt'>
  ) }
);

export type RevokeOtherSessionsMutationVariables = {};


export type RevokeOtherSessionsMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly revokeOtherSessions: (
    { readonly __typename?: 'RevokeOtherSessionsResponse' }
    & Pick<RevokeOtherSessionsResponse, 'success'>
  ) }
);

export type RevokeSessionMutationVariables = {
  input: RevokeSessionInput;
};


export type RevokeSessionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly revokeSession: (
    { readonly __typename?: 'Session' }
    & Pick<Session, 'id'>
  ) }
);

export type RoleQueryVariables = {
  id: Scalars['UUID'];
};


export type RoleQuery = (
  { readonly __typename?: 'Query' }
  & { readonly role: (
    { readonly __typename?: 'CustomRole' }
    & RoleFragmentFragment
  ) }
);

export type RoleFragmentFragment = (
  { readonly __typename?: 'CustomRole' }
  & Pick<CustomRole, 'id' | 'workspaceId' | 'appId' | 'name' | 'kind' | 'policy' | 'createdAt' | 'updatedAt'>
);

export type RoleMembershipFragmentFragment = (
  { readonly __typename?: 'RoleMembership' }
  & Pick<RoleMembership, 'id' | 'workspaceId' | 'roleId' | 'accountId' | 'environmentId' | 'groupId' | 'objectId' | 'createdAt' | 'updatedAt'>
  & { readonly account?: Maybe<(
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  )>, readonly role: (
    { readonly __typename?: 'CustomRole' }
    & RoleFragmentFragment
  ), readonly environment?: Maybe<(
    { readonly __typename?: 'Environment' }
    & EnvironmentFragmentFragment
  )>, readonly group?: Maybe<(
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  )> }
);

export type RoleMembershipsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type RoleMembershipsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly roleMembershipsList: ReadonlyArray<(
    { readonly __typename?: 'RoleMembership' }
    & RoleMembershipFragmentFragment
  )>, readonly roleMembershipsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type RolesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type RolesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly rolesList: ReadonlyArray<(
    { readonly __typename?: 'CustomRole' }
    & RoleFragmentFragment
  )>, readonly rolesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type SaveWorkspaceBillingAccountMutationVariables = {
  input: SaveWorkspaceBillingAccountInput;
};


export type SaveWorkspaceBillingAccountMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly saveWorkspaceBillingAccount: (
    { readonly __typename?: 'SaveWorkspaceBillingAccountResponse' }
    & { readonly billingAccount: (
      { readonly __typename?: 'WorkspaceBillingAccount' }
      & WorkspaceBillingAccountFragmentFragment
    ) }
  ) }
);

export type SaveWorkspaceLocaleMutationVariables = {
  input: SaveWorkspaceLocaleInput;
};


export type SaveWorkspaceLocaleMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly saveWorkspaceLocale: (
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  ) }
);

export type SearchDatabaseRecordsQueryVariables = {
  input: SearchDatabaseRecordsInput;
};


export type SearchDatabaseRecordsQuery = (
  { readonly __typename?: 'Query' }
  & Pick<Query, 'searchDatabaseRecords'>
);

export type SearchOperationLogsQueryVariables = {
  input: SearchOperationLogsInput;
};


export type SearchOperationLogsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly searchOperationLogs: ReadonlyArray<(
    { readonly __typename?: 'OperationLog' }
    & Pick<OperationLog, 'id' | 'accountId' | 'keyPairId' | 'operationId' | 'operationInstanceId' | 'status' | 'statusReason' | 'createdAt' | 'updatedAt'>
    & { readonly operation: (
      { readonly __typename?: 'Operation' }
      & OperationFragmentFragment
    ), readonly account?: Maybe<(
      { readonly __typename?: 'Account' }
      & AccountFragmentFragment
    )> }
  )> }
);

export type SegmentQueryVariables = {
  id: Scalars['UUID'];
};


export type SegmentQuery = (
  { readonly __typename?: 'Query' }
  & { readonly segment: (
    { readonly __typename?: 'Segment' }
    & SegmentFragmentFragment
  ) }
);

export type SegmentFragmentFragment = (
  { readonly __typename?: 'Segment' }
  & Pick<Segment, 'id' | 'workspaceId' | 'resourceId' | 'name' | 'identifier' | 'filter' | 'createdAt' | 'updatedAt'>
  & { readonly resource: (
    { readonly __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'name' | 'identifier'>
  ) }
);

export type SegmentsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type SegmentsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly segmentsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type SegmentsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type SegmentsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly segmentsList: ReadonlyArray<(
    { readonly __typename?: 'Segment' }
    & SegmentFragmentFragment
  )> }
);

export type SessionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type SessionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly sessionsList: ReadonlyArray<(
    { readonly __typename?: 'Session' }
    & Pick<Session, 'id' | 'lastActiveAt' | 'expiresAt'>
  )> }
);

export type SetWorkspacePaymentMethodAsDefaultMutationVariables = {
  input: SetWorkspacePaymentMethodAsDefaultInput;
};


export type SetWorkspacePaymentMethodAsDefaultMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly setWorkspacePaymentMethodAsDefault: (
    { readonly __typename?: 'PaymentMethod' }
    & PaymentMethodFragmentFragment
  ) }
);

export type StartDeliveryMutationVariables = {
  input: StartDeliveryInput;
};


export type StartDeliveryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly startDelivery: (
    { readonly __typename?: 'Delivery' }
    & DeliveryFragmentFragment
  ) }
);

export type StartOnboardingMutationVariables = {
  input: StartOnboardingInput;
};


export type StartOnboardingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly startOnboarding: (
    { readonly __typename?: 'Session' }
    & Pick<Session, 'id' | 'token'>
  ) }
);

export type StoredPreferenceQueryVariables = {
  id: Scalars['UUID'];
};


export type StoredPreferenceQuery = (
  { readonly __typename?: 'Query' }
  & { readonly storedPreference: (
    { readonly __typename?: 'StoredPreference' }
    & Pick<StoredPreference, 'id' | 'channels' | 'isEnabled' | 'createdAt' | 'updatedAt'>
    & { readonly account: (
      { readonly __typename?: 'Account' }
      & AccountFragmentFragment
    ), readonly preference?: Maybe<(
      { readonly __typename?: 'Preference' }
      & PreferenceFragmentFragment
    )> }
  ) }
);

export type StoredPreferencesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type StoredPreferencesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly storedPreferencesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly storedPreferencesList: ReadonlyArray<(
    { readonly __typename?: 'StoredPreference' }
    & Pick<StoredPreference, 'id' | 'preferenceId' | 'accountId' | 'isEnabled' | 'channels' | 'createdAt' | 'updatedAt'>
    & { readonly account: (
      { readonly __typename?: 'Account' }
      & AccountFragmentFragment
    ), readonly preference?: Maybe<(
      { readonly __typename?: 'Preference' }
      & PreferenceFragmentFragment
    )> }
  )> }
);

export type SubmitContactFormMutationVariables = {
  input: SubmitContactFormInput;
};


export type SubmitContactFormMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly submitContactForm: (
    { readonly __typename?: 'SubmitContactFormResponse' }
    & Pick<SubmitContactFormResponse, 'success'>
  ) }
);

export type SummarizeDatabaseRecordsQueryVariables = {
  input: SummarizeDatabaseRecordsInput;
};


export type SummarizeDatabaseRecordsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly summarizeDatabaseRecords: (
    { readonly __typename?: 'SummarizeDatabaseRecordsResponse' }
    & Pick<SummarizeDatabaseRecordsResponse, 'count'>
  ) }
);

export type SummarizeOperationLogsQueryVariables = {
  input: SummarizeOperationLogsInput;
};


export type SummarizeOperationLogsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly summarizeOperationLogs: (
    { readonly __typename?: 'SummarizeOperationLogsResponse' }
    & Pick<SummarizeOperationLogsResponse, 'count'>
  ) }
);

export type TemplateFragmentFragment = (
  { readonly __typename?: 'Template' }
  & Pick<Template, 'id' | 'workspaceId' | 'projectId' | 'kind' | 'subkind' | 'name' | 'identifier' | 'currentVersionId' | 'publishedVersionId' | 'createdAt' | 'updatedAt' | 'settings'>
  & { readonly currentVersion?: Maybe<(
    { readonly __typename?: 'TemplateVersion' }
    & Pick<TemplateVersion, 'id' | 'templateId' | 'settings' | 'isDeleted' | 'createdAt' | 'updatedAt' | 'authorId'>
    & { readonly author: (
      { readonly __typename?: 'Account' }
      & AccountFragmentFragment
    ) }
  )> }
);

export type TemplateVersionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type TemplateVersionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly templateVersionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly templateVersionsList: ReadonlyArray<(
    { readonly __typename?: 'TemplateVersion' }
    & Pick<TemplateVersion, 'id' | 'templateId' | 'settings' | 'isDeleted' | 'createdAt' | 'updatedAt' | 'authorId'>
    & { readonly author: (
      { readonly __typename?: 'Account' }
      & AccountFragmentFragment
    ) }
  )> }
);

export type TemplatesAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
};


export type TemplatesAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly templatesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type TemplatesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type TemplatesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly templatesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly templatesList: ReadonlyArray<(
    { readonly __typename?: 'Template' }
    & TemplateFragmentFragment
  )> }
);

export type TimeZonesQueryVariables = {};


export type TimeZonesQuery = (
  { readonly __typename?: 'Query' }
  & { readonly timeZones: ReadonlyArray<(
    { readonly __typename?: 'TimeZone' }
    & Pick<TimeZone, 'label' | 'value'>
  )> }
);

export type TransactionFragmentFragment = (
  { readonly __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'orderId' | 'accountId' | 'amount' | 'status' | 'createdAt' | 'updatedAt'>
);

export type TransactionsAggregateQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type TransactionsAggregateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly transactionsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ) }
);

export type TransactionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
  targetEnvironment?: Maybe<Scalars['String']>;
};


export type TransactionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly transactionsList: ReadonlyArray<(
    { readonly __typename?: 'Transaction' }
    & TransactionFragmentFragment
  )> }
);

export type UnarchiveInstallationMutationVariables = {
  input: UnarchiveInstallationInput;
};


export type UnarchiveInstallationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly unarchiveInstallation: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ) }
);

export type UnpublishFlowVersionMutationVariables = {
  input: UnpublishFlowVersionInput;
};


export type UnpublishFlowVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly unpublishFlowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  ) }
);

export type UnpublishRecordVersionMutationVariables = {
  input: UnpublishRecordVersionInput;
};


export type UnpublishRecordVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly unpublishRecordVersion: (
    { readonly __typename?: 'CustomRecord' }
    & CustomRecordFragmentFragment
  ) }
);

export type UnpublishTemplateVersionMutationVariables = {
  input: UnpublishTemplateVersionInput;
};


export type UnpublishTemplateVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly unpublishTemplateVersion: (
    { readonly __typename?: 'Template' }
    & Pick<Template, 'id' | 'identifier' | 'createdAt' | 'updatedAt' | 'publishedVersionId'>
  ) }
);

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput;
};


export type UpdateAccountMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateAccount: (
    { readonly __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type UpdateAccountAvatarMutationVariables = {
  input: UpdateAccountAvatarInput;
};


export type UpdateAccountAvatarMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateAccountAvatar: (
    { readonly __typename?: 'Account' }
    & Pick<Account, 'id' | 'avatar'>
  ) }
);

export type UpdateAccountTimeZoneMutationVariables = {
  input: UpdateAccountInput;
};


export type UpdateAccountTimeZoneMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateAccount: (
    { readonly __typename?: 'Account' }
    & Pick<Account, 'id' | 'timeZone'>
  ) }
);

export type UpdateApiRequestMutationVariables = {
  input: UpdateApiRequestInput;
};


export type UpdateApiRequestMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateApiRequest: (
    { readonly __typename?: 'ApiRequest' }
    & ApiRequestFragmentFragment
  ) }
);

export type UpdateAppMutationVariables = {
  input: UpdateAppInput;
};


export type UpdateAppMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateApp: (
    { readonly __typename?: 'App' }
    & AppFragmentFragment
  ) }
);

export type UpdateAttributeMutationVariables = {
  input: UpdateAttributeInput;
};


export type UpdateAttributeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateAttribute: (
    { readonly __typename?: 'Attribute' }
    & AttributeFragmentFragment
  ) }
);

export type UpdateConfigurationMutationVariables = {
  input: UpdateConfigurationInput;
};


export type UpdateConfigurationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateConfiguration: (
    { readonly __typename?: 'Configuration' }
    & ConfigurationFragmentFragment
  ) }
);

export type UpdateContactMutationVariables = {
  input: UpdateContactInput;
};


export type UpdateContactMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateContact: (
    { readonly __typename?: 'Contact' }
    & ContactFragmentFragment
  ) }
);

export type UpdateCouponMutationVariables = {
  input: UpdateCouponInput;
};


export type UpdateCouponMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCoupon: (
    { readonly __typename?: 'Coupon' }
    & CouponFragmentFragment
  ) }
);

export type UpdateCouponCodeMutationVariables = {
  input: UpdateCouponCodeInput;
};


export type UpdateCouponCodeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCouponCode: (
    { readonly __typename?: 'CouponCode' }
    & CouponCodeFragmentFragment
  ) }
);

export type UpdateCouponRedemptionMutationVariables = {
  input: UpdateCouponRedemptionInput;
};


export type UpdateCouponRedemptionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCouponRedemption: (
    { readonly __typename?: 'CouponRedemption' }
    & CouponRedemptionFragmentFragment
  ) }
);

export type UpdateCouponRestrictionMutationVariables = {
  input: UpdateCouponRestrictionInput;
};


export type UpdateCouponRestrictionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCouponRestriction: (
    { readonly __typename?: 'CouponRestriction' }
    & CouponRestrictionFragmentFragment
  ) }
);

export type UpdateDashboardMutationVariables = {
  input: UpdateDashboardInput;
};


export type UpdateDashboardMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateDashboard: (
    { readonly __typename?: 'Dashboard' }
    & DashboardFragmentFragment
  ) }
);

export type UpdateDataTypeMutationVariables = {
  input: UpdateDataTypeInput;
};


export type UpdateDataTypeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateDataType: (
    { readonly __typename?: 'DataType' }
    & DataTypeFragmentFragment
  ) }
);

export type UpdateDatabaseScratchpadMutationVariables = {
  input: UpdateDatabaseScratchpadInput;
};


export type UpdateDatabaseScratchpadMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateDatabaseScratchpad: (
    { readonly __typename?: 'DatabaseScratchpad' }
    & DatabaseScratchpadFragmentFragment
  ) }
);

export type UpdateDeliveryMutationVariables = {
  input: UpdateDeliveryInput;
};


export type UpdateDeliveryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateDelivery: (
    { readonly __typename?: 'Delivery' }
    & DeliveryFragmentFragment
  ) }
);

export type UpdateEnvironmentMutationVariables = {
  input: UpdateEnvironmentInput;
};


export type UpdateEnvironmentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateEnvironment: (
    { readonly __typename?: 'Environment' }
    & Pick<Environment, 'id' | 'name' | 'identifier'>
  ) }
);

export type UpdateEventReportMutationVariables = {
  input: UpdateEventReportInput;
};


export type UpdateEventReportMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateEventReport: (
    { readonly __typename?: 'EventReport' }
    & Pick<EventReport, 'id' | 'name' | 'description' | 'criteria'>
  ) }
);

export type UpdateEventTypeMutationVariables = {
  input: UpdateEventTypeInput;
};


export type UpdateEventTypeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateEventType: (
    { readonly __typename?: 'EventType' }
    & EventTypeFragmentFragment
  ) }
);

export type UpdateFlowMutationVariables = {
  input: UpdateFlowInput;
};


export type UpdateFlowMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateFlow: (
    { readonly __typename?: 'Flow' }
    & FlowFragmentFragment
  ) }
);

export type UpdateFlowVersionMutationVariables = {
  input: UpdateFlowVersionInput;
};


export type UpdateFlowVersionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateFlowVersion: (
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  ) }
);

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput;
};


export type UpdateGroupMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateGroup: (
    { readonly __typename?: 'Group' }
    & GroupFragmentFragment
  ) }
);

export type UpdateInstallationMutationVariables = {
  input: UpdateInstallationInput;
};


export type UpdateInstallationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateInstallation: (
    { readonly __typename?: 'Installation' }
    & InstallationFragmentFragment
  ) }
);

export type UpdateItemMutationVariables = {
  input: UpdateItemInput;
};


export type UpdateItemMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateItem: (
    { readonly __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type UpdateItemCategoryMutationVariables = {
  input: UpdateItemCategoryInput;
};


export type UpdateItemCategoryMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateItemCategory: (
    { readonly __typename?: 'ItemCategory' }
    & ItemCategoryFragmentFragment
  ) }
);

export type UpdateMenuElementMutationVariables = {
  input: UpdateMenuElementInput;
};


export type UpdateMenuElementMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateMenuElement: (
    { readonly __typename?: 'MenuElement' }
    & MenuElementFragmentFragment
  ) }
);

export type UpdateOperationMutationVariables = {
  input: UpdateOperationInput;
};


export type UpdateOperationMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateOperation: (
    { readonly __typename?: 'Operation' }
    & OperationFragmentFragment
  ) }
);

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput;
};


export type UpdateOrderMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateOrder: (
    { readonly __typename?: 'Order' }
    & OrderFragmentFragment
  ) }
);

export type UpdateOrderItemMutationVariables = {
  input: UpdateOrderItemInput;
};


export type UpdateOrderItemMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateOrderItem: (
    { readonly __typename?: 'OrderItem' }
    & OrderItemFragmentFragment
  ) }
);

export type UpdateParameterMutationVariables = {
  input: UpdateParameterInput;
};


export type UpdateParameterMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateParameter: (
    { readonly __typename?: 'Parameter' }
    & ParameterFragmentFragment
  ) }
);

export type UpdatePreferenceMutationVariables = {
  input: UpdatePreferenceInput;
};


export type UpdatePreferenceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updatePreference: (
    { readonly __typename?: 'Preference' }
    & PreferenceFragmentFragment
  ) }
);

export type UpdatePricingMutationVariables = {
  input: UpdatePricingInput;
};


export type UpdatePricingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updatePricing: (
    { readonly __typename?: 'Pricing' }
    & PricingFragmentFragment
  ) }
);

export type UpdateRecipientMutationVariables = {
  input: UpdateRecipientInput;
};


export type UpdateRecipientMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateRecipient: (
    { readonly __typename?: 'Recipient' }
    & RecipientFragmentFragment
  ) }
);

export type UpdateRelationshipMutationVariables = {
  input: UpdateRelationshipInput;
};


export type UpdateRelationshipMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateRelationship: (
    { readonly __typename?: 'Relationship' }
    & RelationshipFragmentFragment
  ) }
);

export type UpdateResourceMutationVariables = {
  input: UpdateResourceInput;
};


export type UpdateResourceMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateResource: (
    { readonly __typename?: 'Resource' }
    & ResourceFragmentFragment
  ) }
);

export type UpdateRoleMutationVariables = {
  input: UpdateRoleInput;
};


export type UpdateRoleMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateRole: (
    { readonly __typename?: 'CustomRole' }
    & RoleFragmentFragment
  ) }
);

export type UpdateSegmentMutationVariables = {
  input: UpdateSegmentInput;
};


export type UpdateSegmentMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateSegment: (
    { readonly __typename?: 'Segment' }
    & SegmentFragmentFragment
  ) }
);

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput;
};


export type UpdateTemplateMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateTemplate: (
    { readonly __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type UpdateThemeMutationVariables = {
  input: UpdateThemeInput;
};


export type UpdateThemeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateTheme: (
    { readonly __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'name'>
    & { readonly palette: (
      { readonly __typename?: 'ThemePalette' }
      & Pick<ThemePalette, 'primary' | 'secondary' | 'accent'>
    ) }
  ) }
);

export type UpsertViewMutationVariables = {
  input: UpsertViewInput;
};


export type UpsertViewMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly upsertView: (
    { readonly __typename?: 'View' }
    & Pick<View, 'id' | 'name' | 'componentPath' | 'blocks' | 'operations'>
  ) }
);

export type UpdateWorkspaceBrandingMutationVariables = {
  input: UpdateWorkspaceBrandingInput;
};


export type UpdateWorkspaceBrandingMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateWorkspaceBranding: (
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  ) }
);

export type UpdateWorkspaceDomainMutationVariables = {
  input: UpdateWorkspaceDomainInput;
};


export type UpdateWorkspaceDomainMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateWorkspaceDomain: (
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  ) }
);

export type UpdateWorkspacePaymentMethodMutationVariables = {
  input: UpdateWorkspacePaymentMethodInput;
};


export type UpdateWorkspacePaymentMethodMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateWorkspacePaymentMethod: (
    { readonly __typename?: 'PaymentMethod' }
    & PaymentMethodFragmentFragment
  ) }
);

export type UpdateWorkspaceThemeMutationVariables = {
  input: UpdateWorkspaceThemeInput;
};


export type UpdateWorkspaceThemeMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateWorkspaceTheme: (
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  ) }
);

export type ValidateKeyPairQueryVariables = {
  input: ValidateKeyPairInput;
};


export type ValidateKeyPairQuery = (
  { readonly __typename?: 'Query' }
  & { readonly validateKeyPair: (
    { readonly __typename?: 'ValidateKeyPairResponse' }
    & Pick<ValidateKeyPairResponse, 'success'>
  ) }
);

export type VerifyInvitationQueryVariables = {
  input: VerifyInvitationInput;
};


export type VerifyInvitationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly verifyInvitation: (
    { readonly __typename?: 'VerifyInvitationResponse' }
    & Pick<VerifyInvitationResponse, 'success'>
  ) }
);

export type VerifyProspectMutationVariables = {
  input: VerifyProspectInput;
};


export type VerifyProspectMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly verifyProspect: (
    { readonly __typename?: 'Prospect' }
    & Pick<Prospect, 'id' | 'email' | 'token' | 'expiresAt'>
  ) }
);

export type VerifyResetPasswordTokenQueryVariables = {
  input: VerifyResetPasswordTokenInput;
};


export type VerifyResetPasswordTokenQuery = (
  { readonly __typename?: 'Query' }
  & { readonly verifyResetPasswordToken: (
    { readonly __typename?: 'VerifyResetPasswordTokenResponse' }
    & Pick<VerifyResetPasswordTokenResponse, 'success'>
  ) }
);

export type VerifySessionMutationVariables = {
  input: VerifySessionInput;
};


export type VerifySessionMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly verifySession: (
    { readonly __typename?: 'Session' }
    & Pick<Session, 'id' | 'mfaVerifiedAt'>
  ) }
);

export type ViewQueryVariables = {
  id: Scalars['UUID'];
};


export type ViewQuery = (
  { readonly __typename?: 'Query' }
  & { readonly view: (
    { readonly __typename?: 'View' }
    & ViewFragmentFragment
  ) }
);

export type ViewFragmentFragment = (
  { readonly __typename?: 'View' }
  & Pick<View, 'id' | 'appId' | 'dashboardId' | 'resourceId' | 'workspaceId' | 'name' | 'kind' | 'componentPath' | 'blocks' | 'operations' | 'defaultStyle' | 'createdAt' | 'updatedAt'>
  & { readonly app?: Maybe<(
    { readonly __typename?: 'App' }
    & AppFragmentFragment
  )>, readonly resource?: Maybe<(
    { readonly __typename?: 'Resource' }
    & ResourceFragmentFragment
  )> }
);

export type ViewsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type ViewsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly viewsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly viewsList: ReadonlyArray<(
    { readonly __typename?: 'View' }
    & ViewFragmentFragment
  )> }
);

export type WorkspaceBillingAccountFragmentFragment = (
  { readonly __typename?: 'WorkspaceBillingAccount' }
  & Pick<WorkspaceBillingAccount, 'id' | 'name'>
  & { readonly address?: Maybe<(
    { readonly __typename?: 'AddressStub' }
    & Pick<AddressStub, 'kind' | 'line1' | 'line2' | 'city' | 'state' | 'zip' | 'countryCode'>
  )>, readonly taxRegistration?: Maybe<(
    { readonly __typename?: 'TaxRegistrationStub' }
    & Pick<TaxRegistrationStub, 'kind' | 'value'>
  )> }
);

export type WorkspaceContextQueryVariables = {
  hostname: Scalars['String'];
};


export type WorkspaceContextQuery = (
  { readonly __typename?: 'Query' }
  & { readonly workspace: (
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  ) }
);

export type WorkspaceFragmentFragment = (
  { readonly __typename?: 'Workspace' }
  & Pick<Workspace, 'id' | 'name' | 'identifier' | 'hostname' | 'logoFull' | 'logoSymbol' | 'wallpaper' | 'themeId' | 'locales' | 'status' | 'trialEndsAt'>
  & { readonly theme?: Maybe<(
    { readonly __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { readonly palette: (
      { readonly __typename?: 'ThemePalette' }
      & Pick<ThemePalette, 'primary' | 'secondary' | 'accent'>
    ) }
  )> }
);

export type WorkspaceOrdersListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type WorkspaceOrdersListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly workspaceOrdersAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly workspaceOrdersList: ReadonlyArray<(
    { readonly __typename?: 'Order' }
    & OrderFragmentFragment
  )> }
);

export type WorkspacePaymentMethodsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type WorkspacePaymentMethodsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly workspacePaymentMethodsAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly workspacePaymentMethodsList: ReadonlyArray<(
    { readonly __typename?: 'PaymentMethod' }
    & PaymentMethodFragmentFragment
  )> }
);

export type WorkspaceThemesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type WorkspaceThemesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly themesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly themesList: ReadonlyArray<(
    { readonly __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'workspaceId' | 'name'>
    & { readonly palette: (
      { readonly __typename?: 'ThemePalette' }
      & Pick<ThemePalette, 'primary' | 'secondary' | 'accent'>
    ) }
  )> }
);

export type WorkspacesListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type WorkspacesListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly workspacesAggregate: (
    { readonly __typename?: 'AggregateResponse' }
    & Pick<AggregateResponse, 'count'>
  ), readonly workspacesList: ReadonlyArray<(
    { readonly __typename?: 'Workspace' }
    & WorkspaceFragmentFragment
  )> }
);

export type FlowVersionsListQueryVariables = {
  filter?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
  page?: Maybe<Scalars['Int']>;
};


export type FlowVersionsListQuery = (
  { readonly __typename?: 'Query' }
  & { readonly flowVersionsList: ReadonlyArray<(
    { readonly __typename?: 'FlowVersion' }
    & FlowVersionFragmentFragment
  )> }
);

export type TemplateQueryVariables = {
  id: Scalars['UUID'];
};


export type TemplateQuery = (
  { readonly __typename?: 'Query' }
  & { readonly template: (
    { readonly __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type TemplateVersionQueryVariables = {
  id: Scalars['UUID'];
};


export type TemplateVersionQuery = (
  { readonly __typename?: 'Query' }
  & { readonly templateVersion: (
    { readonly __typename?: 'TemplateVersion' }
    & Pick<TemplateVersion, 'id' | 'templateId' | 'settings' | 'isDeleted' | 'createdAt' | 'updatedAt' | 'authorId'>
    & { readonly author: (
      { readonly __typename?: 'Account' }
      & AccountFragmentFragment
    ) }
  ) }
);

export const ApiImportFragmentFragmentDoc = gql`
    fragment ApiImportFragment on ApiImport {
  id
  workspaceId
  integrationId
  name
  kind
  status
  statusReason
  url
  assetId
  data
  createdAt
  updatedAt
}
    `;
export const ApiRequestFragmentFragmentDoc = gql`
    fragment ApiRequestFragment on ApiRequest {
  id
  workspaceId
  integrationId
  name
  description
  parentId
  position
  method
  path
  urlFragment
  body
  createdAt
  updatedAt
  queryParams
  headers
  kind
}
    `;
export const AssetFragmentFragmentDoc = gql`
    fragment AssetFragment on Asset {
  id
  workspaceId
  resourceId
  attributeId
  storageProviderId
  uploaderId
  data
  mimeType
  name
  processingStatus
  size
  uploadStatus
  url
  createdAt
  updatedAt
}
    `;
export const AppMetaFragmentFragmentDoc = gql`
    fragment AppMetaFragment on AppMeta {
  isTestable
  isSyncable
  hasWebhooks
  hasInstallationFields
  integrationCategoryIds
}
    `;
export const AppCategoryFragmentFragmentDoc = gql`
    fragment AppCategoryFragment on AppCategory {
  id
  name
  description
  position
  createdAt
  updatedAt
  kind
}
    `;
export const AppFragmentFragmentDoc = gql`
    fragment AppFragment on App {
  id
  name
  icon
  meta {
    ...AppMetaFragment
  }
  identifier
  appCategoryId
  workspaceId
  appCategory {
    ...AppCategoryFragment
  }
  kind
  summary
  fields
  createdAt
  updatedAt
  technology
  platforms
}
    ${AppMetaFragmentFragmentDoc}
${AppCategoryFragmentFragmentDoc}`;
export const InstallationFragmentFragmentDoc = gql`
    fragment InstallationFragment on Installation {
  id
  workspaceId
  appId
  appKind
  name
  identifier
  createdAt
  updatedAt
  archivedAt
  app {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;
export const EnvironmentFragmentFragmentDoc = gql`
    fragment EnvironmentFragment on Environment {
  id
  workspaceId
  name
  identifier
  isLive
  createdAt
  updatedAt
}
    `;
export const ConfigurationFragmentFragmentDoc = gql`
    fragment ConfigurationFragment on Configuration {
  id
  installationId
  environmentId
  digest
  settings
  createdAt
  updatedAt
  status
  statusMessage
  installation {
    ...InstallationFragment
  }
  environment {
    ...EnvironmentFragment
  }
}
    ${InstallationFragmentFragmentDoc}
${EnvironmentFragmentFragmentDoc}`;
export const ContactFragmentFragmentDoc = gql`
    fragment ContactFragment on Contact {
  id
  accountId
  environmentId
  name
  kind
  value
  unverifiedValue
  verifiedAt
  verificationDigest
  verificationExpiresAt
  status
  userAgent
  osName
  osVersion
  deviceModel
  deviceManufacturer
  deviceUid
  deviceAdvertisingUid
  isDeviceAdTrackingEnabled
  createdAt
  updatedAt
}
    `;
export const CouponCodeFragmentFragmentDoc = gql`
    fragment CouponCodeFragment on CouponCode {
  id
  name
  accountId
  couponId
  redemptionsLimit
  redemptionsCount
  createdAt
  updatedAt
}
    `;
export const CouponFragmentFragmentDoc = gql`
    fragment CouponFragment on Coupon {
  id
  name
  identifier
  discountType
  discountAmount
  currencyCode
  expiresAt
  redemptionsLimit
  redemptionsCount
  createdAt
  updatedAt
  environmentId
}
    `;
export const CouponRedemptionFragmentFragmentDoc = gql`
    fragment CouponRedemptionFragment on CouponRedemption {
  id
  couponId
  orderId
  createdAt
  updatedAt
  coupon {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;
export const CouponRestrictionFragmentFragmentDoc = gql`
    fragment CouponRestrictionFragment on CouponRestriction {
  id
  couponId
  itemId
  itemCategoryId
  createdAt
  updatedAt
  item {
    id
    name
  }
  itemCategory {
    id
    name
  }
}
    `;
export const PricingFragmentFragmentDoc = gql`
    fragment PricingFragment on Pricing {
  id
  itemId
  amount
  kind
  currencyCode
  originalAmount
  isRecurring
  recurringInterval
  recurringIntervalUnit
  appleProductIdentifier
  googleProductIdentifier
  createdAt
  updatedAt
}
    `;
export const ItemFragmentFragmentDoc = gql`
    fragment ItemFragment on Item {
  id
  description
  identifier
  kind
  name
  createdAt
  updatedAt
  workspaceId
  pricings {
    ...PricingFragment
  }
  itemCategoryMemberships {
    id
    itemCategory {
      id
      name
    }
  }
}
    ${PricingFragmentFragmentDoc}`;
export const CustomOrderItemFragmentFragmentDoc = gql`
    fragment CustomOrderItemFragment on CustomOrderItem {
  id
  itemId
  pricingId
  quantity
  unitPrice
  subtotal
  discount
  tax
  total
  currencyCode
  item {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;
export const CustomOrderFragmentFragmentDoc = gql`
    fragment CustomOrderFragment on CustomOrder {
  id
  subtotal
  discount
  tax
  total
  currencyCode
  orderItems {
    ...CustomOrderItemFragment
  }
}
    ${CustomOrderItemFragmentFragmentDoc}`;
export const CustomRecordFragmentFragmentDoc = gql`
    fragment CustomRecordFragment on CustomRecord {
  id
  workspaceId
  environmentId
  resourceId
  data
  createdAt
  updatedAt
  publishedId
  latestId
}
    `;
export const MenuElementFragmentFragmentDoc = gql`
    fragment MenuElementFragment on MenuElement {
  id
  dashboardId
  icon
  isRepeated
  isSticky
  kind
  name
  parentId
  path
  placement
  position
  query
  separatorStyle
  target
  url
  viewUrn
}
    `;
export const DashboardFragmentFragmentDoc = gql`
    fragment DashboardFragment on Dashboard {
  id
  name
  identifier
  position
  menuElements(limit: 999) {
    ...MenuElementFragment
  }
}
    ${MenuElementFragmentFragmentDoc}`;
export const AccountFragmentFragmentDoc = gql`
    fragment AccountFragment on Account {
  id
  uid
  anonymousUid
  avatar
  confirmationDigest
  confirmationExpiresAt
  custom
  dateOfBirth
  email
  environmentId
  firstName
  fullName
  gender
  tours
  hasApiAccess
  hasGuiAccess
  isMfaEnabled
  kind
  tours
  lastName
  name
  phone
  regularAccountId
  status
  timeZone
  unconfirmedEmail
  createdAt
  updatedAt
}
    `;
export const DatabaseScratchpadFragmentFragmentDoc = gql`
    fragment DatabaseScratchpadFragment on DatabaseScratchpad {
  id
  title
  workspaceId
  integrationId
  accountId
  body
  createdAt
  updatedAt
  integration {
    ...InstallationFragment
  }
  account {
    ...AccountFragment
  }
}
    ${InstallationFragmentFragmentDoc}
${AccountFragmentFragmentDoc}`;
export const DatabaseSchemaFragmentFragmentDoc = gql`
    fragment DatabaseSchemaFragment on DatabaseSchema {
  id
  integrationId
  environmentId
  name
  createdAt
  updatedAt
  integration {
    ...InstallationFragment
  }
  environment {
    ...EnvironmentFragment
  }
}
    ${InstallationFragmentFragmentDoc}
${EnvironmentFragmentFragmentDoc}`;
export const DatabaseColumnFragmentFragmentDoc = gql`
    fragment DatabaseColumnFragment on DatabaseColumn {
  id
  position
  databaseTableId
  name
  dbMeta
  createdAt
  updatedAt
}
    `;
export const DatabaseTableFragmentFragmentDoc = gql`
    fragment DatabaseTableFragment on DatabaseTable {
  id
  integrationId
  environmentId
  databaseSchemaId
  name
  createdAt
  updatedAt
  environment {
    ...EnvironmentFragment
  }
  databaseSchema {
    ...DatabaseSchemaFragment
  }
  integration {
    ...InstallationFragment
  }
  databaseColumns(limit: 100) {
    ...DatabaseColumnFragment
  }
}
    ${EnvironmentFragmentFragmentDoc}
${DatabaseSchemaFragmentFragmentDoc}
${InstallationFragmentFragmentDoc}
${DatabaseColumnFragmentFragmentDoc}`;
export const EventTypeFragmentFragmentDoc = gql`
    fragment EventTypeFragment on EventType {
  id
  name
  workspaceId
  operationId
  dataSchema
  lastReceivedAt
  archivedAt
  createdAt
}
    `;
export const EventFragmentFragmentDoc = gql`
    fragment EventFragment on Event {
  id
  accountId
  eventTypeId
  data
  timestamp
  createdAt
  updatedAt
  environmentId
  operationLog {
    id
    systemContext {
      ipV4
      ipV6
      locale
      timeZone
      userAgent
      app {
        name
        version
        build
        namespace
      }
      device {
        id
        advertisingId
        adTrackingEnabled
        manufacturer
        model
        name
        kind
      }
      os {
        name
        version
      }
      library {
        name
        version
      }
      network {
        bluetooth
        carrier
        cellular
        wifi
      }
      screen {
        width
        height
        density
      }
      campaign {
        name
        source
        medium
        term
        content
      }
      location {
        latitude
        longitude
        speed
      }
    }
  }
  account {
    ...AccountFragment
  }
  eventType {
    ...EventTypeFragment
  }
}
    ${AccountFragmentFragmentDoc}
${EventTypeFragmentFragmentDoc}`;
export const EventReportFragmentFragmentDoc = gql`
    fragment EventReportFragment on EventReport {
  id
  workspaceId
  name
  description
  criteria
  createdAt
  updatedAt
}
    `;
export const FlowVersionFragmentFragmentDoc = gql`
    fragment FlowVersionFragment on FlowVersion {
  id
  flowId
  status
  version
  snapshot
  lastRunAt
  runCount
  createdAt
  updatedAt
}
    `;
export const FlowFragmentFragmentDoc = gql`
    fragment FlowFragment on Flow {
  id
  name
  status
  identifier
  runCount
  lastRunAt
  createdAt
  updatedAt
}
    `;
export const FlowExecutionFragmentFragmentDoc = gql`
    fragment FlowExecutionFragment on FlowExecution {
  id
  flowVersionId
  status
  failureReason
  createdAt
  updatedAt
  enteredAt
  exitedAt
  scheduledAt
  flowVersion {
    ...FlowVersionFragment
    flow {
      ...FlowFragment
    }
  }
}
    ${FlowVersionFragmentFragmentDoc}
${FlowFragmentFragmentDoc}`;
export const FlowExecutionNodeFragmentFragmentDoc = gql`
    fragment FlowExecutionNodeFragment on FlowExecutionNode {
  id
  flowExecutionId
  flowNodeId
  status
  failureReason
  createdAt
  updatedAt
  enteredAt
  exitedAt
  flowNode {
    id
    identifier
  }
}
    `;
export const GroupFragmentFragmentDoc = gql`
    fragment GroupFragment on Group {
  id
  name
  parentId
  position
  accountKind
  environmentId
  createdAt
  updatedAt
}
    `;
export const GroupMembershipFragmentFragmentDoc = gql`
    fragment GroupMembershipFragment on GroupMembership {
  id
  accountId
  groupId
  createdAt
  updatedAt
  account {
    ...AccountFragment
  }
  group {
    ...GroupFragment
  }
}
    ${AccountFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
export const ItemCategoryFragmentFragmentDoc = gql`
    fragment ItemCategoryFragment on ItemCategory {
  id
  name
  identifier
  description
  createdAt
  updatedAt
  workspaceId
}
    `;
export const ItemCategoryMembershipFragmentFragmentDoc = gql`
    fragment ItemCategoryMembershipFragment on ItemCategoryMembership {
  id
  itemId
  itemCategoryId
}
    `;
export const DeliveryFragmentFragmentDoc = gql`
    fragment DeliveryFragment on Delivery {
  id
  name
  templateSubkind
  templateId
  data
  failureReason
  status
  isTest
  queuedNotificationsCount
  deliveredNotificationsCount
  failedNotificationsCount
  sentNotificationsCount
  openedNotificationsCount
  clickedNotificationsCount
  createdAt
  updatedAt
  scheduledAt
  content
  environmentId
  environment {
    ...EnvironmentFragment
  }
}
    ${EnvironmentFragmentFragmentDoc}`;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  deliveryId
  integrationId
  data
  renderedContent
  report
  failedSendAttempts
  failedDeliveryAttempts
  lastDeliveryFailureAt
  lastSendFailureAt
  failureReason
  unsubscribedAt
  firstClickedAt
  complainedAt
  failedAt
  sentAt
  deliveredAt
  firstOpenedAt
  externalUid
  queuedAt
  createdAt
  updatedAt
  delivery {
    ...DeliveryFragment
  }
  notificationRecipients {
    id
    notificationId
    recipientId
    contactId
    contact
    readAt
    externalUid
    createdAt
    updatedAt
    recipient {
      id
      accountId
      deliveryId
      groupId
      parentId
      target
      kind
      data
      status
      statusReason
      createdAt
      updatedAt
    }
  }
}
    ${DeliveryFragmentFragmentDoc}`;
export const OperationFragmentFragmentDoc = gql`
    fragment OperationFragment on Operation {
  id
  name
  identifier
  description
  icon
  position
  graphqlKind
  actsOn
  behaviorMethod
  behaviorKind
  behaviorSettings
  createdAt
  updatedAt
  workspaceId
  appId
  resourceId
  responseMapper
  app {
    id
    name
    icon
    identifier
    appCategoryId
    kind
  }
  resource {
    id
    workspaceId
    environmentBehavior
    isPublishingEnabled
    appId
    name
    identifier
    description
    origins
    icon
    position
    titleAttributeId
    subtitleAttributeId
    polymorphicAttributeId
    creationTimestampAttributeId
    updationTimestampAttributeId
    deletionTimestampAttributeId
    positionAttributeId
    orderStyle
    orderAttributes
    validations
    isReadOnly
    isTranslatable
    createdAt
    updatedAt
  }
  parameters {
    id
    workspaceId
    operationId
    attributeId
    relationshipId
    name
    identifier
    fieldType
    isNullable
    isArray
    validations
    defaultValue
    position
    createdAt
    updatedAt
    dataTypeId
    fieldTypeSettings
    displayType
    displayTypeSettings
  }
}
    `;
export const OperationListFragmentFragmentDoc = gql`
    fragment OperationListFragment on Operation {
  id
  name
  identifier
  description
  icon
  position
  graphqlKind
  actsOn
  behaviorMethod
  behaviorKind
  behaviorSettings
  createdAt
  updatedAt
  workspaceId
  appId
  resourceId
  responseMapper
  app {
    id
    name
    icon
    identifier
    appCategoryId
    kind
  }
  resource {
    id
    workspaceId
    environmentBehavior
    isPublishingEnabled
    appId
    name
    identifier
    description
    origins
    icon
    position
    titleAttributeId
    subtitleAttributeId
    polymorphicAttributeId
    creationTimestampAttributeId
    updationTimestampAttributeId
    deletionTimestampAttributeId
    positionAttributeId
    orderStyle
    orderAttributes
    validations
    isReadOnly
    isTranslatable
    createdAt
    updatedAt
  }
  parameters {
    id
    workspaceId
    operationId
    attributeId
    relationshipId
    name
    identifier
    fieldType
  }
}
    `;
export const OrderItemFragmentFragmentDoc = gql`
    fragment OrderItemFragment on OrderItem {
  id
  discount
  itemId
  item {
    ...ItemFragment
  }
  orderId
  pricingId
  quantity
  subtotal
  tax
  total
  unitPrice
  currencyCode
  createdAt
  updatedAt
}
    ${ItemFragmentFragmentDoc}`;
export const OrderFragmentFragmentDoc = gql`
    fragment OrderFragment on Order {
  id
  accountId
  subtotal
  discount
  tax
  total
  currencyCode
  gatewayId
  gatewayMeta
  status
  createdAt
  updatedAt
  checkedOutAt
  paidAt
  environmentId
  paymentLink
  orderItems {
    ...OrderItemFragment
  }
  account {
    ...AccountFragment
  }
}
    ${OrderItemFragmentFragmentDoc}
${AccountFragmentFragmentDoc}`;
export const ResourceFragmentFragmentDoc = gql`
    fragment ResourceFragment on Resource {
  id
  workspaceId
  environmentBehavior
  isPublishingEnabled
  appId
  name
  identifier
  description
  origins
  icon
  position
  titleAttributeId
  subtitleAttributeId
  polymorphicAttributeId
  creationTimestampAttributeId
  updationTimestampAttributeId
  deletionTimestampAttributeId
  positionAttributeId
  orderStyle
  orderAttributes
  validations
  isReadOnly
  isTranslatable
  createdAt
  updatedAt
  app {
    id
    name
    icon
    identifier
    appCategoryId
    kind
  }
}
    `;
export const DataTypeFragmentFragmentDoc = gql`
    fragment DataTypeFragment on DataType {
  id
  appId
  workspaceId
  name
  identifier
  kind
  settings
  defaultValue
  validations
  settings
  createdAt
  updatedAt
  description
  icon
  isPrimitive
  allowableFieldTypes
  category
  position
}
    `;
export const AttributeFragmentFragmentDoc = gql`
    fragment AttributeFragment on Attribute {
  id
  workspaceId
  resourceId
  name
  identifier
  fieldType
  fieldTypeSettings
  displayType
  displayTypeSettings
  dataTypeId
  dataTypeSettings
  isNullable
  isArray
  isTranslatable
  isFilterable
  isOrderable
  isPrimary
  validations
  resolution
  resolutionKind
  resolutionSettings
  settings
  defaultValue
  position
  createdAt
  updatedAt
  resource {
    ...ResourceFragment
  }
  dataType {
    ...DataTypeFragment
  }
}
    ${ResourceFragmentFragmentDoc}
${DataTypeFragmentFragmentDoc}`;
export const ParameterFragmentFragmentDoc = gql`
    fragment ParameterFragment on Parameter {
  id
  workspaceId
  operationId
  attributeId
  relationshipId
  name
  identifier
  fieldType
  isNullable
  isArray
  validations
  defaultValue
  position
  createdAt
  updatedAt
  dataTypeId
  fieldTypeSettings
  displayType
  displayTypeSettings
  attribute {
    ...AttributeFragment
  }
  relationship {
    id
    name
    identifier
    sourceId
    sourceAttributeId
    targetId
    targetAttributeId
    kind
    filter
    position
    createdAt
    updatedAt
    sourceAttributeId
    targetAttributeId
  }
  dataType {
    ...DataTypeFragment
  }
}
    ${AttributeFragmentFragmentDoc}
${DataTypeFragmentFragmentDoc}`;
export const ParameterListFragmentFragmentDoc = gql`
    fragment ParameterListFragment on Parameter {
  id
  workspaceId
  operationId
  attributeId
  relationshipId
  name
  identifier
  fieldType
  isNullable
  isArray
  validations
  defaultValue
  position
  createdAt
  updatedAt
  dataTypeId
  fieldTypeSettings
  displayType
  displayTypeSettings
  attribute {
    id
    name
    identifier
    fieldType
  }
  relationship {
    id
    name
    identifier
    sourceId
    sourceAttributeId
    targetId
    targetAttributeId
  }
  dataType {
    id
    workspaceId
    name
    identifier
    kind
    settings
    defaultValue
    validations
    settings
    createdAt
    updatedAt
    description
    icon
    isPrimitive
    allowableFieldTypes
    category
    position
  }
  operation {
    id
    name
    identifier
    description
  }
}
    `;
export const PaymentMethodFragmentFragmentDoc = gql`
    fragment PaymentMethodFragment on PaymentMethod {
  id
  accountId
  isDefault
  createdAt
  updatedAt
  deletedAt
  card {
    id
    paymentMethodId
    last4
    brand
    expiryMonth
    expiryYear
    createdAt
    updatedAt
  }
}
    `;
export const TemplateFragmentFragmentDoc = gql`
    fragment TemplateFragment on Template {
  id
  workspaceId
  projectId
  kind
  subkind
  name
  identifier
  currentVersionId
  publishedVersionId
  createdAt
  updatedAt
  settings
  currentVersion {
    id
    templateId
    settings
    isDeleted
    createdAt
    updatedAt
    authorId
    author {
      ...AccountFragment
    }
  }
}
    ${AccountFragmentFragmentDoc}`;
export const PreferenceFragmentFragmentDoc = gql`
    fragment PreferenceFragment on Preference {
  id
  identifier
  isEnabledByDefault
  name
  restrictions
  createdAt
  updatedAt
  workspaceId
  preferenceRestrictions {
    id
    templateId
    preferenceId
    createdAt
    updatedAt
    template {
      ...TemplateFragment
    }
  }
}
    ${TemplateFragmentFragmentDoc}`;
export const RecipientFragmentFragmentDoc = gql`
    fragment RecipientFragment on Recipient {
  id
  accountId
  deliveryId
  groupId
  parentId
  target
  kind
  data
  status
  statusReason
  createdAt
  updatedAt
  account {
    ...AccountFragment
  }
  group {
    ...GroupFragment
  }
}
    ${AccountFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
export const RecordVersionFragmentFragmentDoc = gql`
    fragment RecordVersionFragment on RecordVersion {
  id
  recordId
  version
  snapshot
  createdAt
  createdById
  updatedAt
  updatedById
  deletedAt
  deletedById
  createdBy {
    ...AccountFragment
  }
  updatedBy {
    ...AccountFragment
  }
  deletedBy {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;
export const RecordFragmentFragmentDoc = gql`
    fragment RecordFragment on Record {
  id
  workspaceId
  environmentId
  resourceId
  createdAt
  updatedAt
  publishedId
  latestId
  published {
    ...RecordVersionFragment
  }
  draft {
    ...RecordVersionFragment
  }
}
    ${RecordVersionFragmentFragmentDoc}`;
export const AttributeListFragmentFragmentDoc = gql`
    fragment AttributeListFragment on Attribute {
  id
  workspaceId
  resourceId
  name
  identifier
  fieldType
  fieldTypeSettings
  displayType
  displayTypeSettings
  dataTypeId
  dataTypeSettings
  isNullable
  isArray
  isTranslatable
  isFilterable
  isOrderable
  isPrimary
  validations
  resolution
  resolutionKind
  resolutionSettings
  settings
  defaultValue
  position
  createdAt
  updatedAt
  dataType {
    id
    workspaceId
    name
    identifier
    kind
    settings
    defaultValue
    validations
    settings
    createdAt
    updatedAt
    description
    icon
    isPrimitive
    allowableFieldTypes
    category
    position
  }
}
    `;
export const RelationshipFragmentFragmentDoc = gql`
    fragment RelationshipFragment on Relationship {
  id
  name
  identifier
  sourceId
  sourceAttributeId
  targetId
  targetAttributeId
  kind
  filter
  position
  createdAt
  updatedAt
  source {
    id
    name
    identifier
    titleAttributeId
    subtitleAttributeId
    app {
      name
    }
    attributes {
      ...AttributeListFragment
    }
  }
  sourceAttribute {
    id
    workspaceId
    resourceId
    name
    identifier
    fieldType
    fieldTypeSettings
    displayType
    displayTypeSettings
    dataTypeId
    dataTypeSettings
    isNullable
    isArray
    isTranslatable
    isFilterable
    isOrderable
    isPrimary
    validations
    resolution
    resolutionKind
    resolutionSettings
    settings
    defaultValue
    position
    createdAt
    updatedAt
  }
  sourceAttributeId
  targetAttributeId
  target {
    id
    name
    identifier
    titleAttributeId
    subtitleAttributeId
    app {
      name
    }
    attributes {
      ...AttributeListFragment
    }
  }
  targetAttribute {
    id
    name
    identifier
    fieldType
  }
}
    ${AttributeListFragmentFragmentDoc}`;
export const RoleFragmentFragmentDoc = gql`
    fragment RoleFragment on CustomRole {
  id
  workspaceId
  appId
  name
  kind
  policy
  createdAt
  updatedAt
}
    `;
export const RoleMembershipFragmentFragmentDoc = gql`
    fragment RoleMembershipFragment on RoleMembership {
  id
  workspaceId
  roleId
  accountId
  environmentId
  groupId
  objectId
  createdAt
  updatedAt
  account {
    ...AccountFragment
  }
  role {
    ...RoleFragment
  }
  environment {
    ...EnvironmentFragment
  }
  group {
    ...GroupFragment
  }
}
    ${AccountFragmentFragmentDoc}
${RoleFragmentFragmentDoc}
${EnvironmentFragmentFragmentDoc}
${GroupFragmentFragmentDoc}`;
export const SegmentFragmentFragmentDoc = gql`
    fragment SegmentFragment on Segment {
  id
  workspaceId
  resourceId
  name
  identifier
  filter
  createdAt
  updatedAt
  resource {
    id
    name
    identifier
  }
}
    `;
export const TransactionFragmentFragmentDoc = gql`
    fragment TransactionFragment on Transaction {
  id
  orderId
  accountId
  amount
  status
  createdAt
  updatedAt
}
    `;
export const ViewFragmentFragmentDoc = gql`
    fragment ViewFragment on View {
  id
  appId
  dashboardId
  resourceId
  workspaceId
  name
  kind
  componentPath
  blocks
  operations
  defaultStyle
  createdAt
  updatedAt
  app {
    ...AppFragment
  }
  resource {
    ...ResourceFragment
  }
}
    ${AppFragmentFragmentDoc}
${ResourceFragmentFragmentDoc}`;
export const WorkspaceBillingAccountFragmentFragmentDoc = gql`
    fragment WorkspaceBillingAccountFragment on WorkspaceBillingAccount {
  id
  name
  address {
    kind
    line1
    line2
    city
    state
    zip
    countryCode
  }
  taxRegistration {
    kind
    value
  }
}
    `;
export const WorkspaceFragmentFragmentDoc = gql`
    fragment WorkspaceFragment on Workspace {
  id
  name
  identifier
  hostname
  logoFull
  logoSymbol
  wallpaper
  themeId
  locales
  status
  trialEndsAt
  theme {
    id
    name
    palette {
      primary
      secondary
      accent
    }
    createdAt
    updatedAt
  }
}
    `;
export const AccountDocument = gql`
    query Account($id: UUID!) {
  account(id: $id) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
      }
export function useAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export const AccountsAggregateDocument = gql`
    query AccountsAggregate($filter: JSON, $targetEnvironment: String) {
  accountsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useAccountsAggregateQuery__
 *
 * To run a query within a React component, call `useAccountsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useAccountsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountsAggregateQuery, AccountsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountsAggregateQuery, AccountsAggregateQueryVariables>(AccountsAggregateDocument, baseOptions);
      }
export function useAccountsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountsAggregateQuery, AccountsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountsAggregateQuery, AccountsAggregateQueryVariables>(AccountsAggregateDocument, baseOptions);
        }
export type AccountsAggregateQueryHookResult = ReturnType<typeof useAccountsAggregateQuery>;
export type AccountsAggregateLazyQueryHookResult = ReturnType<typeof useAccountsAggregateLazyQuery>;
export const AccountsListDocument = gql`
    query AccountsList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  accountsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
  accountsList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useAccountsListQuery__
 *
 * To run a query within a React component, call `useAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useAccountsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, baseOptions);
      }
export function useAccountsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountsListQuery, AccountsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountsListQuery, AccountsListQueryVariables>(AccountsListDocument, baseOptions);
        }
export type AccountsListQueryHookResult = ReturnType<typeof useAccountsListQuery>;
export type AccountsListLazyQueryHookResult = ReturnType<typeof useAccountsListLazyQuery>;
export const AddDatabaseRecordDocument = gql`
    mutation AddDatabaseRecord($input: AddDatabaseRecordInput!) {
  addDatabaseRecord(input: $input)
}
    `;

/**
 * __useAddDatabaseRecordMutation__
 *
 * To run a mutation, you first call `useAddDatabaseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDatabaseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDatabaseRecordMutation, { data, loading, error }] = useAddDatabaseRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDatabaseRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDatabaseRecordMutation, AddDatabaseRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDatabaseRecordMutation, AddDatabaseRecordMutationVariables>(AddDatabaseRecordDocument, baseOptions);
      }
export type AddDatabaseRecordMutationHookResult = ReturnType<typeof useAddDatabaseRecordMutation>;
export const ApiImportDocument = gql`
    query ApiImport($id: UUID!) {
  apiImport(id: $id) {
    ...ApiImportFragment
  }
}
    ${ApiImportFragmentFragmentDoc}`;

/**
 * __useApiImportQuery__
 *
 * To run a query within a React component, call `useApiImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiImportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApiImportQuery, ApiImportQueryVariables>) {
        return ApolloReactHooks.useQuery<ApiImportQuery, ApiImportQueryVariables>(ApiImportDocument, baseOptions);
      }
export function useApiImportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApiImportQuery, ApiImportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApiImportQuery, ApiImportQueryVariables>(ApiImportDocument, baseOptions);
        }
export type ApiImportQueryHookResult = ReturnType<typeof useApiImportQuery>;
export type ApiImportLazyQueryHookResult = ReturnType<typeof useApiImportLazyQuery>;
export const ApiRequestDocument = gql`
    query ApiRequest($id: UUID!) {
  apiRequest(id: $id) {
    ...ApiRequestFragment
  }
}
    ${ApiRequestFragmentFragmentDoc}`;

/**
 * __useApiRequestQuery__
 *
 * To run a query within a React component, call `useApiRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApiRequestQuery, ApiRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<ApiRequestQuery, ApiRequestQueryVariables>(ApiRequestDocument, baseOptions);
      }
export function useApiRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApiRequestQuery, ApiRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApiRequestQuery, ApiRequestQueryVariables>(ApiRequestDocument, baseOptions);
        }
export type ApiRequestQueryHookResult = ReturnType<typeof useApiRequestQuery>;
export type ApiRequestLazyQueryHookResult = ReturnType<typeof useApiRequestLazyQuery>;
export const ApiRequestsAggregateDocument = gql`
    query ApiRequestsAggregate($filter: JSON) {
  apiRequestsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useApiRequestsAggregateQuery__
 *
 * To run a query within a React component, call `useApiRequestsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiRequestsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiRequestsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useApiRequestsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApiRequestsAggregateQuery, ApiRequestsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<ApiRequestsAggregateQuery, ApiRequestsAggregateQueryVariables>(ApiRequestsAggregateDocument, baseOptions);
      }
export function useApiRequestsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApiRequestsAggregateQuery, ApiRequestsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApiRequestsAggregateQuery, ApiRequestsAggregateQueryVariables>(ApiRequestsAggregateDocument, baseOptions);
        }
export type ApiRequestsAggregateQueryHookResult = ReturnType<typeof useApiRequestsAggregateQuery>;
export type ApiRequestsAggregateLazyQueryHookResult = ReturnType<typeof useApiRequestsAggregateLazyQuery>;
export const ApiRequestsListDocument = gql`
    query ApiRequestsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  apiRequestsAggregate(filter: $filter) {
    count
  }
  apiRequestsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ApiRequestFragment
  }
}
    ${ApiRequestFragmentFragmentDoc}`;

/**
 * __useApiRequestsListQuery__
 *
 * To run a query within a React component, call `useApiRequestsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiRequestsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiRequestsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useApiRequestsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApiRequestsListQuery, ApiRequestsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ApiRequestsListQuery, ApiRequestsListQueryVariables>(ApiRequestsListDocument, baseOptions);
      }
export function useApiRequestsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApiRequestsListQuery, ApiRequestsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApiRequestsListQuery, ApiRequestsListQueryVariables>(ApiRequestsListDocument, baseOptions);
        }
export type ApiRequestsListQueryHookResult = ReturnType<typeof useApiRequestsListQuery>;
export type ApiRequestsListLazyQueryHookResult = ReturnType<typeof useApiRequestsListLazyQuery>;
export const AppDocument = gql`
    query App($id: UUID!) {
  app(id: $id) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppQuery, AppQueryVariables>) {
        return ApolloReactHooks.useQuery<AppQuery, AppQueryVariables>(AppDocument, baseOptions);
      }
export function useAppLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, baseOptions);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export const AppCategoriesListDocument = gql`
    query AppCategoriesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  appCategoriesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...AppCategoryFragment
  }
}
    ${AppCategoryFragmentFragmentDoc}`;

/**
 * __useAppCategoriesListQuery__
 *
 * To run a query within a React component, call `useAppCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppCategoriesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAppCategoriesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppCategoriesListQuery, AppCategoriesListQueryVariables>) {
        return ApolloReactHooks.useQuery<AppCategoriesListQuery, AppCategoriesListQueryVariables>(AppCategoriesListDocument, baseOptions);
      }
export function useAppCategoriesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppCategoriesListQuery, AppCategoriesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppCategoriesListQuery, AppCategoriesListQueryVariables>(AppCategoriesListDocument, baseOptions);
        }
export type AppCategoriesListQueryHookResult = ReturnType<typeof useAppCategoriesListQuery>;
export type AppCategoriesListLazyQueryHookResult = ReturnType<typeof useAppCategoriesListLazyQuery>;
export const AppListDocument = gql`
    query AppList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  appsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useAppListQuery__
 *
 * To run a query within a React component, call `useAppListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAppListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppListQuery, AppListQueryVariables>) {
        return ApolloReactHooks.useQuery<AppListQuery, AppListQueryVariables>(AppListDocument, baseOptions);
      }
export function useAppListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppListQuery, AppListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppListQuery, AppListQueryVariables>(AppListDocument, baseOptions);
        }
export type AppListQueryHookResult = ReturnType<typeof useAppListQuery>;
export type AppListLazyQueryHookResult = ReturnType<typeof useAppListLazyQuery>;
export const ArchiveInstallationDocument = gql`
    mutation ArchiveInstallation($input: ArchiveInstallationInput!) {
  archiveInstallation(input: $input) {
    ...InstallationFragment
  }
}
    ${InstallationFragmentFragmentDoc}`;

/**
 * __useArchiveInstallationMutation__
 *
 * To run a mutation, you first call `useArchiveInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInstallationMutation, { data, loading, error }] = useArchiveInstallationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveInstallationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveInstallationMutation, ArchiveInstallationMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveInstallationMutation, ArchiveInstallationMutationVariables>(ArchiveInstallationDocument, baseOptions);
      }
export type ArchiveInstallationMutationHookResult = ReturnType<typeof useArchiveInstallationMutation>;
export const AssetDocument = gql`
    query Asset($id: UUID!) {
  asset(id: $id) {
    ...AssetFragment
  }
}
    ${AssetFragmentFragmentDoc}`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssetQuery, AssetQueryVariables>) {
        return ApolloReactHooks.useQuery<AssetQuery, AssetQueryVariables>(AssetDocument, baseOptions);
      }
export function useAssetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssetQuery, AssetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssetQuery, AssetQueryVariables>(AssetDocument, baseOptions);
        }
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetLazyQueryHookResult = ReturnType<typeof useAssetLazyQuery>;
export const AssignRolesDocument = gql`
    mutation AssignRoles($input: AssignRolesInput!) {
  assignRoles(input: $input) {
    roleMemberships {
      ...RoleMembershipFragment
    }
  }
}
    ${RoleMembershipFragmentFragmentDoc}`;

/**
 * __useAssignRolesMutation__
 *
 * To run a mutation, you first call `useAssignRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRolesMutation, { data, loading, error }] = useAssignRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRolesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignRolesMutation, AssignRolesMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignRolesMutation, AssignRolesMutationVariables>(AssignRolesDocument, baseOptions);
      }
export type AssignRolesMutationHookResult = ReturnType<typeof useAssignRolesMutation>;
export const AttributeDocument = gql`
    query Attribute($id: UUID!) {
  attribute(id: $id) {
    ...AttributeFragment
  }
}
    ${AttributeFragmentFragmentDoc}`;

/**
 * __useAttributeQuery__
 *
 * To run a query within a React component, call `useAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttributeQuery, AttributeQueryVariables>) {
        return ApolloReactHooks.useQuery<AttributeQuery, AttributeQueryVariables>(AttributeDocument, baseOptions);
      }
export function useAttributeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttributeQuery, AttributeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttributeQuery, AttributeQueryVariables>(AttributeDocument, baseOptions);
        }
export type AttributeQueryHookResult = ReturnType<typeof useAttributeQuery>;
export type AttributeLazyQueryHookResult = ReturnType<typeof useAttributeLazyQuery>;
export const AttributesAggregateDocument = gql`
    query AttributesAggregate($filter: JSON) {
  attributesAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useAttributesAggregateQuery__
 *
 * To run a query within a React component, call `useAttributesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttributesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttributesAggregateQuery, AttributesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<AttributesAggregateQuery, AttributesAggregateQueryVariables>(AttributesAggregateDocument, baseOptions);
      }
export function useAttributesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttributesAggregateQuery, AttributesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttributesAggregateQuery, AttributesAggregateQueryVariables>(AttributesAggregateDocument, baseOptions);
        }
export type AttributesAggregateQueryHookResult = ReturnType<typeof useAttributesAggregateQuery>;
export type AttributesAggregateLazyQueryHookResult = ReturnType<typeof useAttributesAggregateLazyQuery>;
export const AttributesListDocument = gql`
    query AttributesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  attributesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...AttributeListFragment
  }
}
    ${AttributeListFragmentFragmentDoc}`;

/**
 * __useAttributesListQuery__
 *
 * To run a query within a React component, call `useAttributesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAttributesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttributesListQuery, AttributesListQueryVariables>) {
        return ApolloReactHooks.useQuery<AttributesListQuery, AttributesListQueryVariables>(AttributesListDocument, baseOptions);
      }
export function useAttributesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttributesListQuery, AttributesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttributesListQuery, AttributesListQueryVariables>(AttributesListDocument, baseOptions);
        }
export type AttributesListQueryHookResult = ReturnType<typeof useAttributesListQuery>;
export type AttributesListLazyQueryHookResult = ReturnType<typeof useAttributesListLazyQuery>;
export const CancelFlowExecutiontDocument = gql`
    mutation CancelFlowExecutiont($input: CancelFlowExecutionInput!) {
  cancelFlowExecution(input: $input) {
    ...FlowExecutionFragment
  }
}
    ${FlowExecutionFragmentFragmentDoc}`;

/**
 * __useCancelFlowExecutiontMutation__
 *
 * To run a mutation, you first call `useCancelFlowExecutiontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFlowExecutiontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFlowExecutiontMutation, { data, loading, error }] = useCancelFlowExecutiontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFlowExecutiontMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelFlowExecutiontMutation, CancelFlowExecutiontMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelFlowExecutiontMutation, CancelFlowExecutiontMutationVariables>(CancelFlowExecutiontDocument, baseOptions);
      }
export type CancelFlowExecutiontMutationHookResult = ReturnType<typeof useCancelFlowExecutiontMutation>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    id
  }
}
    `;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export const CompleteOnboardingDocument = gql`
    mutation CompleteOnboarding($input: CompleteOnboardingInput!) {
  completeOnboarding(input: $input) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useCompleteOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingMutation, { data, loading, error }] = useCompleteOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteOnboardingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>(CompleteOnboardingDocument, baseOptions);
      }
export type CompleteOnboardingMutationHookResult = ReturnType<typeof useCompleteOnboardingMutation>;
export const ConfigurationsAggregateDocument = gql`
    query ConfigurationsAggregate($filter: JSON) {
  configurationsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useConfigurationsAggregateQuery__
 *
 * To run a query within a React component, call `useConfigurationsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useConfigurationsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigurationsAggregateQuery, ConfigurationsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigurationsAggregateQuery, ConfigurationsAggregateQueryVariables>(ConfigurationsAggregateDocument, baseOptions);
      }
export function useConfigurationsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigurationsAggregateQuery, ConfigurationsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigurationsAggregateQuery, ConfigurationsAggregateQueryVariables>(ConfigurationsAggregateDocument, baseOptions);
        }
export type ConfigurationsAggregateQueryHookResult = ReturnType<typeof useConfigurationsAggregateQuery>;
export type ConfigurationsAggregateLazyQueryHookResult = ReturnType<typeof useConfigurationsAggregateLazyQuery>;
export const ConfigurationsListDocument = gql`
    query ConfigurationsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  configurationsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ConfigurationFragment
  }
}
    ${ConfigurationFragmentFragmentDoc}`;

/**
 * __useConfigurationsListQuery__
 *
 * To run a query within a React component, call `useConfigurationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useConfigurationsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigurationsListQuery, ConfigurationsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigurationsListQuery, ConfigurationsListQueryVariables>(ConfigurationsListDocument, baseOptions);
      }
export function useConfigurationsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigurationsListQuery, ConfigurationsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigurationsListQuery, ConfigurationsListQueryVariables>(ConfigurationsListDocument, baseOptions);
        }
export type ConfigurationsListQueryHookResult = ReturnType<typeof useConfigurationsListQuery>;
export type ConfigurationsListLazyQueryHookResult = ReturnType<typeof useConfigurationsListLazyQuery>;
export const ConfigureInstallationDocument = gql`
    mutation ConfigureInstallation($input: ConfigureInstallationInput!) {
  configureInstallation(input: $input) {
    ...ConfigurationFragment
  }
}
    ${ConfigurationFragmentFragmentDoc}`;

/**
 * __useConfigureInstallationMutation__
 *
 * To run a mutation, you first call `useConfigureInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureInstallationMutation, { data, loading, error }] = useConfigureInstallationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigureInstallationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfigureInstallationMutation, ConfigureInstallationMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfigureInstallationMutation, ConfigureInstallationMutationVariables>(ConfigureInstallationDocument, baseOptions);
      }
export type ConfigureInstallationMutationHookResult = ReturnType<typeof useConfigureInstallationMutation>;
export const ConfigureMfaDocument = gql`
    mutation ConfigureMfa {
  configureMfa {
    qrCode
  }
}
    `;

/**
 * __useConfigureMfaMutation__
 *
 * To run a mutation, you first call `useConfigureMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureMfaMutation, { data, loading, error }] = useConfigureMfaMutation({
 *   variables: {
 *   },
 * });
 */
export function useConfigureMfaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfigureMfaMutation, ConfigureMfaMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfigureMfaMutation, ConfigureMfaMutationVariables>(ConfigureMfaDocument, baseOptions);
      }
export type ConfigureMfaMutationHookResult = ReturnType<typeof useConfigureMfaMutation>;
export const ContactsAggregateDocument = gql`
    query ContactsAggregate($filter: JSON) {
  contactsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useContactsAggregateQuery__
 *
 * To run a query within a React component, call `useContactsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContactsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactsAggregateQuery, ContactsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactsAggregateQuery, ContactsAggregateQueryVariables>(ContactsAggregateDocument, baseOptions);
      }
export function useContactsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactsAggregateQuery, ContactsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactsAggregateQuery, ContactsAggregateQueryVariables>(ContactsAggregateDocument, baseOptions);
        }
export type ContactsAggregateQueryHookResult = ReturnType<typeof useContactsAggregateQuery>;
export type ContactsAggregateLazyQueryHookResult = ReturnType<typeof useContactsAggregateLazyQuery>;
export const ContactsListDocument = gql`
    query ContactsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  contactsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

/**
 * __useContactsListQuery__
 *
 * To run a query within a React component, call `useContactsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useContactsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactsListQuery, ContactsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactsListQuery, ContactsListQueryVariables>(ContactsListDocument, baseOptions);
      }
export function useContactsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactsListQuery, ContactsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactsListQuery, ContactsListQueryVariables>(ContactsListDocument, baseOptions);
        }
export type ContactsListQueryHookResult = ReturnType<typeof useContactsListQuery>;
export type ContactsListLazyQueryHookResult = ReturnType<typeof useContactsListLazyQuery>;
export const CouponCodesListDocument = gql`
    query CouponCodesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  couponCodesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...CouponCodeFragment
  }
}
    ${CouponCodeFragmentFragmentDoc}`;

/**
 * __useCouponCodesListQuery__
 *
 * To run a query within a React component, call `useCouponCodesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponCodesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponCodesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCouponCodesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponCodesListQuery, CouponCodesListQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponCodesListQuery, CouponCodesListQueryVariables>(CouponCodesListDocument, baseOptions);
      }
export function useCouponCodesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponCodesListQuery, CouponCodesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponCodesListQuery, CouponCodesListQueryVariables>(CouponCodesListDocument, baseOptions);
        }
export type CouponCodesListQueryHookResult = ReturnType<typeof useCouponCodesListQuery>;
export type CouponCodesListLazyQueryHookResult = ReturnType<typeof useCouponCodesListLazyQuery>;
export const CouponRedemptionsListDocument = gql`
    query CouponRedemptionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  couponRedemptionsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...CouponRedemptionFragment
  }
  couponRedemptionsAggregate(filter: $filter) {
    count
  }
}
    ${CouponRedemptionFragmentFragmentDoc}`;

/**
 * __useCouponRedemptionsListQuery__
 *
 * To run a query within a React component, call `useCouponRedemptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponRedemptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponRedemptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCouponRedemptionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponRedemptionsListQuery, CouponRedemptionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponRedemptionsListQuery, CouponRedemptionsListQueryVariables>(CouponRedemptionsListDocument, baseOptions);
      }
export function useCouponRedemptionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponRedemptionsListQuery, CouponRedemptionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponRedemptionsListQuery, CouponRedemptionsListQueryVariables>(CouponRedemptionsListDocument, baseOptions);
        }
export type CouponRedemptionsListQueryHookResult = ReturnType<typeof useCouponRedemptionsListQuery>;
export type CouponRedemptionsListLazyQueryHookResult = ReturnType<typeof useCouponRedemptionsListLazyQuery>;
export const CouponRestrictionsListDocument = gql`
    query CouponRestrictionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  couponRestrictionsAggregate(filter: $filter) {
    count
  }
  couponRestrictionsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...CouponRestrictionFragment
  }
}
    ${CouponRestrictionFragmentFragmentDoc}`;

/**
 * __useCouponRestrictionsListQuery__
 *
 * To run a query within a React component, call `useCouponRestrictionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponRestrictionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponRestrictionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCouponRestrictionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponRestrictionsListQuery, CouponRestrictionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponRestrictionsListQuery, CouponRestrictionsListQueryVariables>(CouponRestrictionsListDocument, baseOptions);
      }
export function useCouponRestrictionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponRestrictionsListQuery, CouponRestrictionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponRestrictionsListQuery, CouponRestrictionsListQueryVariables>(CouponRestrictionsListDocument, baseOptions);
        }
export type CouponRestrictionsListQueryHookResult = ReturnType<typeof useCouponRestrictionsListQuery>;
export type CouponRestrictionsListLazyQueryHookResult = ReturnType<typeof useCouponRestrictionsListLazyQuery>;
export const CouponsAggregateDocument = gql`
    query CouponsAggregate($filter: JSON) {
  couponsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useCouponsAggregateQuery__
 *
 * To run a query within a React component, call `useCouponsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCouponsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponsAggregateQuery, CouponsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponsAggregateQuery, CouponsAggregateQueryVariables>(CouponsAggregateDocument, baseOptions);
      }
export function useCouponsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponsAggregateQuery, CouponsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponsAggregateQuery, CouponsAggregateQueryVariables>(CouponsAggregateDocument, baseOptions);
        }
export type CouponsAggregateQueryHookResult = ReturnType<typeof useCouponsAggregateQuery>;
export type CouponsAggregateLazyQueryHookResult = ReturnType<typeof useCouponsAggregateLazyQuery>;
export const CouponsListDocument = gql`
    query CouponsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  couponsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;

/**
 * __useCouponsListQuery__
 *
 * To run a query within a React component, call `useCouponsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCouponsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponsListQuery, CouponsListQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponsListQuery, CouponsListQueryVariables>(CouponsListDocument, baseOptions);
      }
export function useCouponsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponsListQuery, CouponsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponsListQuery, CouponsListQueryVariables>(CouponsListDocument, baseOptions);
        }
export type CouponsListQueryHookResult = ReturnType<typeof useCouponsListQuery>;
export type CouponsListLazyQueryHookResult = ReturnType<typeof useCouponsListLazyQuery>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export const CreateApiImportDocument = gql`
    mutation CreateApiImport($input: CreateApiImportInput!) {
  createApiImport(input: $input) {
    ...ApiImportFragment
  }
}
    ${ApiImportFragmentFragmentDoc}`;

/**
 * __useCreateApiImportMutation__
 *
 * To run a mutation, you first call `useCreateApiImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiImportMutation, { data, loading, error }] = useCreateApiImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApiImportMutation, CreateApiImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApiImportMutation, CreateApiImportMutationVariables>(CreateApiImportDocument, baseOptions);
      }
export type CreateApiImportMutationHookResult = ReturnType<typeof useCreateApiImportMutation>;
export const CreateApiRequestDocument = gql`
    mutation CreateApiRequest($input: CreateApiRequestInput!) {
  createApiRequest(input: $input) {
    ...ApiRequestFragment
  }
}
    ${ApiRequestFragmentFragmentDoc}`;

/**
 * __useCreateApiRequestMutation__
 *
 * To run a mutation, you first call `useCreateApiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiRequestMutation, { data, loading, error }] = useCreateApiRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApiRequestMutation, CreateApiRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApiRequestMutation, CreateApiRequestMutationVariables>(CreateApiRequestDocument, baseOptions);
      }
export type CreateApiRequestMutationHookResult = ReturnType<typeof useCreateApiRequestMutation>;
export const CreateAppDocument = gql`
    mutation CreateApp($input: CreateAppInput!) {
  createApp(input: $input) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useCreateAppMutation__
 *
 * To run a mutation, you first call `useCreateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppMutation, { data, loading, error }] = useCreateAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAppMutation, CreateAppMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAppMutation, CreateAppMutationVariables>(CreateAppDocument, baseOptions);
      }
export type CreateAppMutationHookResult = ReturnType<typeof useCreateAppMutation>;
export const CreateAttributeDocument = gql`
    mutation CreateAttribute($input: CreateAttributeInput!) {
  createAttribute(input: $input) {
    ...AttributeFragment
  }
}
    ${AttributeFragmentFragmentDoc}`;

/**
 * __useCreateAttributeMutation__
 *
 * To run a mutation, you first call `useCreateAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttributeMutation, { data, loading, error }] = useCreateAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttributeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttributeMutation, CreateAttributeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttributeMutation, CreateAttributeMutationVariables>(CreateAttributeDocument, baseOptions);
      }
export type CreateAttributeMutationHookResult = ReturnType<typeof useCreateAttributeMutation>;
export const CreateConfigurationDocument = gql`
    mutation CreateConfiguration($input: CreateConfigurationInput!) {
  createConfiguration(input: $input) {
    ...ConfigurationFragment
  }
}
    ${ConfigurationFragmentFragmentDoc}`;

/**
 * __useCreateConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationMutation, { data, loading, error }] = useCreateConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConfigurationMutation, CreateConfigurationMutationVariables>(CreateConfigurationDocument, baseOptions);
      }
export type CreateConfigurationMutationHookResult = ReturnType<typeof useCreateConfigurationMutation>;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export const CreateCouponDocument = gql`
    mutation CreateCoupon($input: CreateCouponInput!) {
  createCoupon(input: $input) {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, baseOptions);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export const CreateCouponCodeDocument = gql`
    mutation CreateCouponCode($input: CreateCouponCodeInput!) {
  createCouponCode(input: $input) {
    ...CouponCodeFragment
  }
}
    ${CouponCodeFragmentFragmentDoc}`;

/**
 * __useCreateCouponCodeMutation__
 *
 * To run a mutation, you first call `useCreateCouponCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponCodeMutation, { data, loading, error }] = useCreateCouponCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCouponCodeMutation, CreateCouponCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCouponCodeMutation, CreateCouponCodeMutationVariables>(CreateCouponCodeDocument, baseOptions);
      }
export type CreateCouponCodeMutationHookResult = ReturnType<typeof useCreateCouponCodeMutation>;
export const CreateCouponRedemptionDocument = gql`
    mutation CreateCouponRedemption($input: CreateCouponRedemptionInput!) {
  createCouponRedemption(input: $input) {
    ...CouponRedemptionFragment
  }
}
    ${CouponRedemptionFragmentFragmentDoc}`;

/**
 * __useCreateCouponRedemptionMutation__
 *
 * To run a mutation, you first call `useCreateCouponRedemptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponRedemptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponRedemptionMutation, { data, loading, error }] = useCreateCouponRedemptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponRedemptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCouponRedemptionMutation, CreateCouponRedemptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCouponRedemptionMutation, CreateCouponRedemptionMutationVariables>(CreateCouponRedemptionDocument, baseOptions);
      }
export type CreateCouponRedemptionMutationHookResult = ReturnType<typeof useCreateCouponRedemptionMutation>;
export const CreateCouponRestrictionDocument = gql`
    mutation CreateCouponRestriction($input: CreateCouponRestrictionInput!) {
  createCouponRestriction(input: $input) {
    ...CouponRestrictionFragment
  }
}
    ${CouponRestrictionFragmentFragmentDoc}`;

/**
 * __useCreateCouponRestrictionMutation__
 *
 * To run a mutation, you first call `useCreateCouponRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponRestrictionMutation, { data, loading, error }] = useCreateCouponRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCouponRestrictionMutation, CreateCouponRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCouponRestrictionMutation, CreateCouponRestrictionMutationVariables>(CreateCouponRestrictionDocument, baseOptions);
      }
export type CreateCouponRestrictionMutationHookResult = ReturnType<typeof useCreateCouponRestrictionMutation>;
export const CreateDashboardDocument = gql`
    mutation CreateDashboard($input: CreateDashboardInput!) {
  createDashboard(input: $input) {
    ...DashboardFragment
  }
}
    ${DashboardFragmentFragmentDoc}`;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDashboardMutation, CreateDashboardMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDashboardMutation, CreateDashboardMutationVariables>(CreateDashboardDocument, baseOptions);
      }
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export const CreateDataTypeDocument = gql`
    mutation CreateDataType($input: CreateDataTypeInput!) {
  createDataType(input: $input) {
    ...DataTypeFragment
  }
}
    ${DataTypeFragmentFragmentDoc}`;

/**
 * __useCreateDataTypeMutation__
 *
 * To run a mutation, you first call `useCreateDataTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataTypeMutation, { data, loading, error }] = useCreateDataTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDataTypeMutation, CreateDataTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDataTypeMutation, CreateDataTypeMutationVariables>(CreateDataTypeDocument, baseOptions);
      }
export type CreateDataTypeMutationHookResult = ReturnType<typeof useCreateDataTypeMutation>;
export const CreateDatabaseScratchpadDocument = gql`
    mutation CreateDatabaseScratchpad($input: CreateDatabaseScratchpadInput!) {
  createDatabaseScratchpad(input: $input) {
    ...DatabaseScratchpadFragment
  }
}
    ${DatabaseScratchpadFragmentFragmentDoc}`;

/**
 * __useCreateDatabaseScratchpadMutation__
 *
 * To run a mutation, you first call `useCreateDatabaseScratchpadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDatabaseScratchpadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDatabaseScratchpadMutation, { data, loading, error }] = useCreateDatabaseScratchpadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDatabaseScratchpadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDatabaseScratchpadMutation, CreateDatabaseScratchpadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDatabaseScratchpadMutation, CreateDatabaseScratchpadMutationVariables>(CreateDatabaseScratchpadDocument, baseOptions);
      }
export type CreateDatabaseScratchpadMutationHookResult = ReturnType<typeof useCreateDatabaseScratchpadMutation>;
export const CreateDeliveryDocument = gql`
    mutation CreateDelivery($input: CreateDeliveryInput!) {
  createDelivery(input: $input) {
    ...DeliveryFragment
  }
}
    ${DeliveryFragmentFragmentDoc}`;

/**
 * __useCreateDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryMutation, { data, loading, error }] = useCreateDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeliveryMutation, CreateDeliveryMutationVariables>(CreateDeliveryDocument, baseOptions);
      }
export type CreateDeliveryMutationHookResult = ReturnType<typeof useCreateDeliveryMutation>;
export const CreateEnvironmentDocument = gql`
    mutation CreateEnvironment($input: CreateEnvironmentInput!) {
  createEnvironment(input: $input) {
    id
    name
    identifier
  }
}
    `;

/**
 * __useCreateEnvironmentMutation__
 *
 * To run a mutation, you first call `useCreateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnvironmentMutation, { data, loading, error }] = useCreateEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnvironmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>(CreateEnvironmentDocument, baseOptions);
      }
export type CreateEnvironmentMutationHookResult = ReturnType<typeof useCreateEnvironmentMutation>;
export const CreateEventReportDocument = gql`
    mutation CreateEventReport($input: CreateEventReportInput!) {
  createEventReport(input: $input) {
    id
    name
    description
    criteria
  }
}
    `;

/**
 * __useCreateEventReportMutation__
 *
 * To run a mutation, you first call `useCreateEventReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventReportMutation, { data, loading, error }] = useCreateEventReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventReportMutation, CreateEventReportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventReportMutation, CreateEventReportMutationVariables>(CreateEventReportDocument, baseOptions);
      }
export type CreateEventReportMutationHookResult = ReturnType<typeof useCreateEventReportMutation>;
export const CreateEventTypeDocument = gql`
    mutation CreateEventType($input: CreateEventTypeInput!) {
  createEventType(input: $input) {
    ...EventTypeFragment
  }
}
    ${EventTypeFragmentFragmentDoc}`;

/**
 * __useCreateEventTypeMutation__
 *
 * To run a mutation, you first call `useCreateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTypeMutation, { data, loading, error }] = useCreateEventTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>(CreateEventTypeDocument, baseOptions);
      }
export type CreateEventTypeMutationHookResult = ReturnType<typeof useCreateEventTypeMutation>;
export const CreateFlowDocument = gql`
    mutation CreateFlow($input: CreateFlowInput!) {
  createFlow(input: $input) {
    ...FlowFragment
  }
}
    ${FlowFragmentFragmentDoc}`;

/**
 * __useCreateFlowMutation__
 *
 * To run a mutation, you first call `useCreateFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlowMutation, { data, loading, error }] = useCreateFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFlowMutation, CreateFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFlowMutation, CreateFlowMutationVariables>(CreateFlowDocument, baseOptions);
      }
export type CreateFlowMutationHookResult = ReturnType<typeof useCreateFlowMutation>;
export const CreateFlowVersionDocument = gql`
    mutation CreateFlowVersion($input: CreateFlowVersionInput!) {
  createFlowVersion(input: $input) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __useCreateFlowVersionMutation__
 *
 * To run a mutation, you first call `useCreateFlowVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlowVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlowVersionMutation, { data, loading, error }] = useCreateFlowVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFlowVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFlowVersionMutation, CreateFlowVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFlowVersionMutation, CreateFlowVersionMutationVariables>(CreateFlowVersionDocument, baseOptions);
      }
export type CreateFlowVersionMutationHookResult = ReturnType<typeof useCreateFlowVersionMutation>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput!) {
  createGroup(input: $input) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, baseOptions);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export const CreateGroupMembershipDocument = gql`
    mutation CreateGroupMembership($input: CreateGroupMembershipInput!) {
  createGroupMembership(input: $input) {
    ...GroupMembershipFragment
  }
}
    ${GroupMembershipFragmentFragmentDoc}`;

/**
 * __useCreateGroupMembershipMutation__
 *
 * To run a mutation, you first call `useCreateGroupMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMembershipMutation, { data, loading, error }] = useCreateGroupMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGroupMembershipMutation, CreateGroupMembershipMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGroupMembershipMutation, CreateGroupMembershipMutationVariables>(CreateGroupMembershipDocument, baseOptions);
      }
export type CreateGroupMembershipMutationHookResult = ReturnType<typeof useCreateGroupMembershipMutation>;
export const CreateInstallationDocument = gql`
    mutation CreateInstallation($input: CreateInstallationInput!) {
  createInstallation(input: $input) {
    ...InstallationFragment
  }
}
    ${InstallationFragmentFragmentDoc}`;

/**
 * __useCreateInstallationMutation__
 *
 * To run a mutation, you first call `useCreateInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstallationMutation, { data, loading, error }] = useCreateInstallationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstallationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInstallationMutation, CreateInstallationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInstallationMutation, CreateInstallationMutationVariables>(CreateInstallationDocument, baseOptions);
      }
export type CreateInstallationMutationHookResult = ReturnType<typeof useCreateInstallationMutation>;
export const CreateItemDocument = gql`
    mutation CreateItem($input: CreateItemInput!) {
  createItem(input: $input) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, baseOptions);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export const CreateItemCategoryDocument = gql`
    mutation CreateItemCategory($input: CreateItemCategoryInput!) {
  createItemCategory(input: $input) {
    ...ItemCategoryFragment
  }
}
    ${ItemCategoryFragmentFragmentDoc}`;

/**
 * __useCreateItemCategoryMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMutation, { data, loading, error }] = useCreateItemCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>(CreateItemCategoryDocument, baseOptions);
      }
export type CreateItemCategoryMutationHookResult = ReturnType<typeof useCreateItemCategoryMutation>;
export const CreateItemCategoryMembershipDocument = gql`
    mutation CreateItemCategoryMembership($input: CreateItemCategoryMembershipInput!) {
  createItemCategoryMembership(input: $input) {
    ...ItemCategoryMembershipFragment
  }
}
    ${ItemCategoryMembershipFragmentFragmentDoc}`;

/**
 * __useCreateItemCategoryMembershipMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMembershipMutation, { data, loading, error }] = useCreateItemCategoryMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemCategoryMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemCategoryMembershipMutation, CreateItemCategoryMembershipMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemCategoryMembershipMutation, CreateItemCategoryMembershipMutationVariables>(CreateItemCategoryMembershipDocument, baseOptions);
      }
export type CreateItemCategoryMembershipMutationHookResult = ReturnType<typeof useCreateItemCategoryMembershipMutation>;
export const CreateKeyPairDocument = gql`
    mutation CreateKeyPair($input: CreateKeyPairInput!) {
  createKeyPair(input: $input) {
    id
    publicKey
    privateKey
    expiresAt
    createdAt
  }
}
    `;

/**
 * __useCreateKeyPairMutation__
 *
 * To run a mutation, you first call `useCreateKeyPairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeyPairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKeyPairMutation, { data, loading, error }] = useCreateKeyPairMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKeyPairMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateKeyPairMutation, CreateKeyPairMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateKeyPairMutation, CreateKeyPairMutationVariables>(CreateKeyPairDocument, baseOptions);
      }
export type CreateKeyPairMutationHookResult = ReturnType<typeof useCreateKeyPairMutation>;
export const CreateMenuElementDocument = gql`
    mutation CreateMenuElement($input: CreateMenuElementInput!) {
  createMenuElement(input: $input) {
    ...MenuElementFragment
  }
}
    ${MenuElementFragmentFragmentDoc}`;

/**
 * __useCreateMenuElementMutation__
 *
 * To run a mutation, you first call `useCreateMenuElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuElementMutation, { data, loading, error }] = useCreateMenuElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuElementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMenuElementMutation, CreateMenuElementMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMenuElementMutation, CreateMenuElementMutationVariables>(CreateMenuElementDocument, baseOptions);
      }
export type CreateMenuElementMutationHookResult = ReturnType<typeof useCreateMenuElementMutation>;
export const CreateOperationDocument = gql`
    mutation CreateOperation($input: CreateOperationInput!) {
  createOperation(input: $input) {
    ...OperationFragment
  }
}
    ${OperationFragmentFragmentDoc}`;

/**
 * __useCreateOperationMutation__
 *
 * To run a mutation, you first call `useCreateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationMutation, { data, loading, error }] = useCreateOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOperationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOperationMutation, CreateOperationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOperationMutation, CreateOperationMutationVariables>(CreateOperationDocument, baseOptions);
      }
export type CreateOperationMutationHookResult = ReturnType<typeof useCreateOperationMutation>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export const CreateOrderItemDocument = gql`
    mutation CreateOrderItem($input: CreateOrderItemInput!) {
  createOrderItem(input: $input) {
    ...OrderItemFragment
  }
}
    ${OrderItemFragmentFragmentDoc}`;

/**
 * __useCreateOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemMutation, { data, loading, error }] = useCreateOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemMutation, CreateOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemMutation, CreateOrderItemMutationVariables>(CreateOrderItemDocument, baseOptions);
      }
export type CreateOrderItemMutationHookResult = ReturnType<typeof useCreateOrderItemMutation>;
export const CreateParameterDocument = gql`
    mutation CreateParameter($input: CreateParameterInput!) {
  createParameter(input: $input) {
    ...ParameterFragment
  }
}
    ${ParameterFragmentFragmentDoc}`;

/**
 * __useCreateParameterMutation__
 *
 * To run a mutation, you first call `useCreateParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParameterMutation, { data, loading, error }] = useCreateParameterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParameterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateParameterMutation, CreateParameterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateParameterMutation, CreateParameterMutationVariables>(CreateParameterDocument, baseOptions);
      }
export type CreateParameterMutationHookResult = ReturnType<typeof useCreateParameterMutation>;
export const CreatePreferenceDocument = gql`
    mutation CreatePreference($input: CreatePreferenceInput!) {
  createPreference(input: $input) {
    ...PreferenceFragment
  }
}
    ${PreferenceFragmentFragmentDoc}`;

/**
 * __useCreatePreferenceMutation__
 *
 * To run a mutation, you first call `useCreatePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreferenceMutation, { data, loading, error }] = useCreatePreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreferenceMutation, CreatePreferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePreferenceMutation, CreatePreferenceMutationVariables>(CreatePreferenceDocument, baseOptions);
      }
export type CreatePreferenceMutationHookResult = ReturnType<typeof useCreatePreferenceMutation>;
export const CreatePricingDocument = gql`
    mutation CreatePricing($input: CreatePricingInput!) {
  createPricing(input: $input) {
    ...PricingFragment
  }
}
    ${PricingFragmentFragmentDoc}`;

/**
 * __useCreatePricingMutation__
 *
 * To run a mutation, you first call `useCreatePricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPricingMutation, { data, loading, error }] = useCreatePricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePricingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePricingMutation, CreatePricingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePricingMutation, CreatePricingMutationVariables>(CreatePricingDocument, baseOptions);
      }
export type CreatePricingMutationHookResult = ReturnType<typeof useCreatePricingMutation>;
export const CreateRecipientDocument = gql`
    mutation CreateRecipient($input: CreateRecipientInput!) {
  createRecipient(input: $input) {
    ...RecipientFragment
  }
}
    ${RecipientFragmentFragmentDoc}`;

/**
 * __useCreateRecipientMutation__
 *
 * To run a mutation, you first call `useCreateRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipientMutation, { data, loading, error }] = useCreateRecipientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecipientMutation, CreateRecipientMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecipientMutation, CreateRecipientMutationVariables>(CreateRecipientDocument, baseOptions);
      }
export type CreateRecipientMutationHookResult = ReturnType<typeof useCreateRecipientMutation>;
export const CreateRelationshipDocument = gql`
    mutation CreateRelationship($input: CreateRelationshipInput!) {
  createRelationship(input: $input) {
    ...RelationshipFragment
  }
}
    ${RelationshipFragmentFragmentDoc}`;

/**
 * __useCreateRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRelationshipMutation, { data, loading, error }] = useCreateRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRelationshipMutation, CreateRelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRelationshipMutation, CreateRelationshipMutationVariables>(CreateRelationshipDocument, baseOptions);
      }
export type CreateRelationshipMutationHookResult = ReturnType<typeof useCreateRelationshipMutation>;
export const CreateResourceDocument = gql`
    mutation CreateResource($input: CreateResourceInput!) {
  createResource(input: $input) {
    ...ResourceFragment
  }
}
    ${ResourceFragmentFragmentDoc}`;

/**
 * __useCreateResourceMutation__
 *
 * To run a mutation, you first call `useCreateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceMutation, { data, loading, error }] = useCreateResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateResourceMutation, CreateResourceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateResourceMutation, CreateResourceMutationVariables>(CreateResourceDocument, baseOptions);
      }
export type CreateResourceMutationHookResult = ReturnType<typeof useCreateResourceMutation>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    ...RoleFragment
  }
}
    ${RoleFragmentFragmentDoc}`;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export const CreateSegmentDocument = gql`
    mutation CreateSegment($input: CreateSegmentInput!) {
  createSegment(input: $input) {
    ...SegmentFragment
  }
}
    ${SegmentFragmentFragmentDoc}`;

/**
 * __useCreateSegmentMutation__
 *
 * To run a mutation, you first call `useCreateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentMutation, { data, loading, error }] = useCreateSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSegmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSegmentMutation, CreateSegmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSegmentMutation, CreateSegmentMutationVariables>(CreateSegmentDocument, baseOptions);
      }
export type CreateSegmentMutationHookResult = ReturnType<typeof useCreateSegmentMutation>;
export const CreateSessionDocument = gql`
    mutation CreateSession($input: CreateSessionInput!) {
  createSession(input: $input) {
    id
    token
    isMfaRequired
    mfaVerifiedAt
  }
}
    `;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, baseOptions);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($input: CreateTemplateInput!) {
  createTemplate(input: $input) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, baseOptions);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export const CreateThemeDocument = gql`
    mutation CreateTheme($input: CreateThemeInput!) {
  createTheme(input: $input) {
    id
    workspaceId
    name
    palette {
      primary
      secondary
      accent
    }
  }
}
    `;

/**
 * __useCreateThemeMutation__
 *
 * To run a mutation, you first call `useCreateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThemeMutation, { data, loading, error }] = useCreateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateThemeMutation, CreateThemeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateThemeMutation, CreateThemeMutationVariables>(CreateThemeDocument, baseOptions);
      }
export type CreateThemeMutationHookResult = ReturnType<typeof useCreateThemeMutation>;
export const CreateViewDocument = gql`
    mutation CreateView($input: CreateViewInput!) {
  createView(input: $input) {
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;

/**
 * __useCreateViewMutation__
 *
 * To run a mutation, you first call `useCreateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewMutation, { data, loading, error }] = useCreateViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateViewMutation, CreateViewMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateViewMutation, CreateViewMutationVariables>(CreateViewDocument, baseOptions);
      }
export type CreateViewMutationHookResult = ReturnType<typeof useCreateViewMutation>;
export const CreateWorkspacePaymentMethodDocument = gql`
    mutation CreateWorkspacePaymentMethod($input: CreateWorkspacePaymentMethodInput!) {
  createWorkspacePaymentMethod(input: $input) {
    ...PaymentMethodFragment
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;

/**
 * __useCreateWorkspacePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreateWorkspacePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspacePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspacePaymentMethodMutation, { data, loading, error }] = useCreateWorkspacePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspacePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkspacePaymentMethodMutation, CreateWorkspacePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkspacePaymentMethodMutation, CreateWorkspacePaymentMethodMutationVariables>(CreateWorkspacePaymentMethodDocument, baseOptions);
      }
export type CreateWorkspacePaymentMethodMutationHookResult = ReturnType<typeof useCreateWorkspacePaymentMethodMutation>;
export const CurrenciesDocument = gql`
    query Currencies {
  currencies {
    code
    name
    symbol
  }
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, baseOptions);
      }
export function useCurrenciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, baseOptions);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export const CurrentAccountDocument = gql`
    query CurrentAccount {
  currentAccount {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useCurrentAccountQuery__
 *
 * To run a query within a React component, call `useCurrentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentAccountQuery, CurrentAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentAccountQuery, CurrentAccountQueryVariables>(CurrentAccountDocument, baseOptions);
      }
export function useCurrentAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentAccountQuery, CurrentAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentAccountQuery, CurrentAccountQueryVariables>(CurrentAccountDocument, baseOptions);
        }
export type CurrentAccountQueryHookResult = ReturnType<typeof useCurrentAccountQuery>;
export type CurrentAccountLazyQueryHookResult = ReturnType<typeof useCurrentAccountLazyQuery>;
export const DashboardsListDocument = gql`
    query DashboardsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  dashboardsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...DashboardFragment
  }
  dashboardsAggregate(filter: $filter) {
    count
  }
}
    ${DashboardFragmentFragmentDoc}`;

/**
 * __useDashboardsListQuery__
 *
 * To run a query within a React component, call `useDashboardsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDashboardsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardsListQuery, DashboardsListQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardsListQuery, DashboardsListQueryVariables>(DashboardsListDocument, baseOptions);
      }
export function useDashboardsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardsListQuery, DashboardsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardsListQuery, DashboardsListQueryVariables>(DashboardsListDocument, baseOptions);
        }
export type DashboardsListQueryHookResult = ReturnType<typeof useDashboardsListQuery>;
export type DashboardsListLazyQueryHookResult = ReturnType<typeof useDashboardsListLazyQuery>;
export const DataTypeDocument = gql`
    query DataType($id: UUID!) {
  dataType(id: $id) {
    ...DataTypeFragment
  }
}
    ${DataTypeFragmentFragmentDoc}`;

/**
 * __useDataTypeQuery__
 *
 * To run a query within a React component, call `useDataTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataTypeQuery, DataTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<DataTypeQuery, DataTypeQueryVariables>(DataTypeDocument, baseOptions);
      }
export function useDataTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataTypeQuery, DataTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataTypeQuery, DataTypeQueryVariables>(DataTypeDocument, baseOptions);
        }
export type DataTypeQueryHookResult = ReturnType<typeof useDataTypeQuery>;
export type DataTypeLazyQueryHookResult = ReturnType<typeof useDataTypeLazyQuery>;
export const DataTypesListDocument = gql`
    query DataTypesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  dataTypesAggregate(filter: $filter) {
    count
  }
  dataTypesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...DataTypeFragment
  }
}
    ${DataTypeFragmentFragmentDoc}`;

/**
 * __useDataTypesListQuery__
 *
 * To run a query within a React component, call `useDataTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTypesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDataTypesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataTypesListQuery, DataTypesListQueryVariables>) {
        return ApolloReactHooks.useQuery<DataTypesListQuery, DataTypesListQueryVariables>(DataTypesListDocument, baseOptions);
      }
export function useDataTypesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataTypesListQuery, DataTypesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataTypesListQuery, DataTypesListQueryVariables>(DataTypesListDocument, baseOptions);
        }
export type DataTypesListQueryHookResult = ReturnType<typeof useDataTypesListQuery>;
export type DataTypesListLazyQueryHookResult = ReturnType<typeof useDataTypesListLazyQuery>;
export const DatabaseSchemaDocument = gql`
    query DatabaseSchema($id: UUID!) {
  databaseSchema(id: $id) {
    ...DatabaseSchemaFragment
  }
}
    ${DatabaseSchemaFragmentFragmentDoc}`;

/**
 * __useDatabaseSchemaQuery__
 *
 * To run a query within a React component, call `useDatabaseSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDatabaseSchemaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseSchemaQuery, DatabaseSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseSchemaQuery, DatabaseSchemaQueryVariables>(DatabaseSchemaDocument, baseOptions);
      }
export function useDatabaseSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseSchemaQuery, DatabaseSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseSchemaQuery, DatabaseSchemaQueryVariables>(DatabaseSchemaDocument, baseOptions);
        }
export type DatabaseSchemaQueryHookResult = ReturnType<typeof useDatabaseSchemaQuery>;
export type DatabaseSchemaLazyQueryHookResult = ReturnType<typeof useDatabaseSchemaLazyQuery>;
export const DatabaseSchemasAggregateDocument = gql`
    query DatabaseSchemasAggregate($filter: JSON, $installationId: UUID, $targetEnvironment: String) {
  databaseSchemasAggregate(filter: $filter, installationId: $installationId, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useDatabaseSchemasAggregateQuery__
 *
 * To run a query within a React component, call `useDatabaseSchemasAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseSchemasAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseSchemasAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      installationId: // value for 'installationId'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useDatabaseSchemasAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseSchemasAggregateQuery, DatabaseSchemasAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseSchemasAggregateQuery, DatabaseSchemasAggregateQueryVariables>(DatabaseSchemasAggregateDocument, baseOptions);
      }
export function useDatabaseSchemasAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseSchemasAggregateQuery, DatabaseSchemasAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseSchemasAggregateQuery, DatabaseSchemasAggregateQueryVariables>(DatabaseSchemasAggregateDocument, baseOptions);
        }
export type DatabaseSchemasAggregateQueryHookResult = ReturnType<typeof useDatabaseSchemasAggregateQuery>;
export type DatabaseSchemasAggregateLazyQueryHookResult = ReturnType<typeof useDatabaseSchemasAggregateLazyQuery>;
export const DatabaseSchemasListDocument = gql`
    query DatabaseSchemasList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $installationId: UUID, $targetEnvironment: String) {
  databaseSchemasList(filter: $filter, limit: $limit, order: $order, page: $page, installationId: $installationId, targetEnvironment: $targetEnvironment) {
    ...DatabaseSchemaFragment
  }
}
    ${DatabaseSchemaFragmentFragmentDoc}`;

/**
 * __useDatabaseSchemasListQuery__
 *
 * To run a query within a React component, call `useDatabaseSchemasListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseSchemasListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseSchemasListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      installationId: // value for 'installationId'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useDatabaseSchemasListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseSchemasListQuery, DatabaseSchemasListQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseSchemasListQuery, DatabaseSchemasListQueryVariables>(DatabaseSchemasListDocument, baseOptions);
      }
export function useDatabaseSchemasListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseSchemasListQuery, DatabaseSchemasListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseSchemasListQuery, DatabaseSchemasListQueryVariables>(DatabaseSchemasListDocument, baseOptions);
        }
export type DatabaseSchemasListQueryHookResult = ReturnType<typeof useDatabaseSchemasListQuery>;
export type DatabaseSchemasListLazyQueryHookResult = ReturnType<typeof useDatabaseSchemasListLazyQuery>;
export const DatabaseScratchpadsAggregateDocument = gql`
    query DatabaseScratchpadsAggregate($filter: JSON) {
  databaseScratchpadsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useDatabaseScratchpadsAggregateQuery__
 *
 * To run a query within a React component, call `useDatabaseScratchpadsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseScratchpadsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseScratchpadsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDatabaseScratchpadsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseScratchpadsAggregateQuery, DatabaseScratchpadsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseScratchpadsAggregateQuery, DatabaseScratchpadsAggregateQueryVariables>(DatabaseScratchpadsAggregateDocument, baseOptions);
      }
export function useDatabaseScratchpadsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseScratchpadsAggregateQuery, DatabaseScratchpadsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseScratchpadsAggregateQuery, DatabaseScratchpadsAggregateQueryVariables>(DatabaseScratchpadsAggregateDocument, baseOptions);
        }
export type DatabaseScratchpadsAggregateQueryHookResult = ReturnType<typeof useDatabaseScratchpadsAggregateQuery>;
export type DatabaseScratchpadsAggregateLazyQueryHookResult = ReturnType<typeof useDatabaseScratchpadsAggregateLazyQuery>;
export const DatabaseScratchpadsListDocument = gql`
    query DatabaseScratchpadsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  databaseScratchpadsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...DatabaseScratchpadFragment
  }
}
    ${DatabaseScratchpadFragmentFragmentDoc}`;

/**
 * __useDatabaseScratchpadsListQuery__
 *
 * To run a query within a React component, call `useDatabaseScratchpadsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseScratchpadsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseScratchpadsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDatabaseScratchpadsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseScratchpadsListQuery, DatabaseScratchpadsListQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseScratchpadsListQuery, DatabaseScratchpadsListQueryVariables>(DatabaseScratchpadsListDocument, baseOptions);
      }
export function useDatabaseScratchpadsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseScratchpadsListQuery, DatabaseScratchpadsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseScratchpadsListQuery, DatabaseScratchpadsListQueryVariables>(DatabaseScratchpadsListDocument, baseOptions);
        }
export type DatabaseScratchpadsListQueryHookResult = ReturnType<typeof useDatabaseScratchpadsListQuery>;
export type DatabaseScratchpadsListLazyQueryHookResult = ReturnType<typeof useDatabaseScratchpadsListLazyQuery>;
export const DatabaseTableDocument = gql`
    query DatabaseTable($id: UUID!) {
  databaseTable(id: $id) {
    ...DatabaseTableFragment
  }
}
    ${DatabaseTableFragmentFragmentDoc}`;

/**
 * __useDatabaseTableQuery__
 *
 * To run a query within a React component, call `useDatabaseTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseTableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDatabaseTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseTableQuery, DatabaseTableQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseTableQuery, DatabaseTableQueryVariables>(DatabaseTableDocument, baseOptions);
      }
export function useDatabaseTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseTableQuery, DatabaseTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseTableQuery, DatabaseTableQueryVariables>(DatabaseTableDocument, baseOptions);
        }
export type DatabaseTableQueryHookResult = ReturnType<typeof useDatabaseTableQuery>;
export type DatabaseTableLazyQueryHookResult = ReturnType<typeof useDatabaseTableLazyQuery>;
export const DatabaseTablesAggregateDocument = gql`
    query DatabaseTablesAggregate($filter: JSON) {
  databaseTablesAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useDatabaseTablesAggregateQuery__
 *
 * To run a query within a React component, call `useDatabaseTablesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseTablesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseTablesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDatabaseTablesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseTablesAggregateQuery, DatabaseTablesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseTablesAggregateQuery, DatabaseTablesAggregateQueryVariables>(DatabaseTablesAggregateDocument, baseOptions);
      }
export function useDatabaseTablesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseTablesAggregateQuery, DatabaseTablesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseTablesAggregateQuery, DatabaseTablesAggregateQueryVariables>(DatabaseTablesAggregateDocument, baseOptions);
        }
export type DatabaseTablesAggregateQueryHookResult = ReturnType<typeof useDatabaseTablesAggregateQuery>;
export type DatabaseTablesAggregateLazyQueryHookResult = ReturnType<typeof useDatabaseTablesAggregateLazyQuery>;
export const DatabaseTablesListDocument = gql`
    query DatabaseTablesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  databaseTablesAggregate(filter: $filter) {
    count
  }
  databaseTablesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...DatabaseTableFragment
  }
}
    ${DatabaseTableFragmentFragmentDoc}`;

/**
 * __useDatabaseTablesListQuery__
 *
 * To run a query within a React component, call `useDatabaseTablesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatabaseTablesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatabaseTablesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDatabaseTablesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DatabaseTablesListQuery, DatabaseTablesListQueryVariables>) {
        return ApolloReactHooks.useQuery<DatabaseTablesListQuery, DatabaseTablesListQueryVariables>(DatabaseTablesListDocument, baseOptions);
      }
export function useDatabaseTablesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DatabaseTablesListQuery, DatabaseTablesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DatabaseTablesListQuery, DatabaseTablesListQueryVariables>(DatabaseTablesListDocument, baseOptions);
        }
export type DatabaseTablesListQueryHookResult = ReturnType<typeof useDatabaseTablesListQuery>;
export type DatabaseTablesListLazyQueryHookResult = ReturnType<typeof useDatabaseTablesListLazyQuery>;
export const DeleteDatabaseRecordDocument = gql`
    mutation DeleteDatabaseRecord($input: DeleteDatabaseRecordInput!) {
  deleteDatabaseRecord(input: $input)
}
    `;

/**
 * __useDeleteDatabaseRecordMutation__
 *
 * To run a mutation, you first call `useDeleteDatabaseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDatabaseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDatabaseRecordMutation, { data, loading, error }] = useDeleteDatabaseRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDatabaseRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDatabaseRecordMutation, DeleteDatabaseRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDatabaseRecordMutation, DeleteDatabaseRecordMutationVariables>(DeleteDatabaseRecordDocument, baseOptions);
      }
export type DeleteDatabaseRecordMutationHookResult = ReturnType<typeof useDeleteDatabaseRecordMutation>;
export const DeliveriesAggregateDocument = gql`
    query DeliveriesAggregate($filter: JSON, $targetEnvironment: String) {
  deliveriesAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useDeliveriesAggregateQuery__
 *
 * To run a query within a React component, call `useDeliveriesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveriesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveriesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useDeliveriesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveriesAggregateQuery, DeliveriesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveriesAggregateQuery, DeliveriesAggregateQueryVariables>(DeliveriesAggregateDocument, baseOptions);
      }
export function useDeliveriesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveriesAggregateQuery, DeliveriesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveriesAggregateQuery, DeliveriesAggregateQueryVariables>(DeliveriesAggregateDocument, baseOptions);
        }
export type DeliveriesAggregateQueryHookResult = ReturnType<typeof useDeliveriesAggregateQuery>;
export type DeliveriesAggregateLazyQueryHookResult = ReturnType<typeof useDeliveriesAggregateLazyQuery>;
export const DeliveriesListDocument = gql`
    query DeliveriesList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  deliveriesList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...DeliveryFragment
  }
}
    ${DeliveryFragmentFragmentDoc}`;

/**
 * __useDeliveriesListQuery__
 *
 * To run a query within a React component, call `useDeliveriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveriesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useDeliveriesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveriesListQuery, DeliveriesListQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveriesListQuery, DeliveriesListQueryVariables>(DeliveriesListDocument, baseOptions);
      }
export function useDeliveriesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveriesListQuery, DeliveriesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveriesListQuery, DeliveriesListQueryVariables>(DeliveriesListDocument, baseOptions);
        }
export type DeliveriesListQueryHookResult = ReturnType<typeof useDeliveriesListQuery>;
export type DeliveriesListLazyQueryHookResult = ReturnType<typeof useDeliveriesListLazyQuery>;
export const DeliveryDocument = gql`
    query Delivery($id: UUID!) {
  delivery(id: $id) {
    ...DeliveryFragment
  }
}
    ${DeliveryFragmentFragmentDoc}`;

/**
 * __useDeliveryQuery__
 *
 * To run a query within a React component, call `useDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliveryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveryQuery, DeliveryQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveryQuery, DeliveryQueryVariables>(DeliveryDocument, baseOptions);
      }
export function useDeliveryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveryQuery, DeliveryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveryQuery, DeliveryQueryVariables>(DeliveryDocument, baseOptions);
        }
export type DeliveryQueryHookResult = ReturnType<typeof useDeliveryQuery>;
export type DeliveryLazyQueryHookResult = ReturnType<typeof useDeliveryLazyQuery>;
export const DestroyApiRequestDocument = gql`
    mutation DestroyApiRequest($input: DestroyApiRequestInput!) {
  destroyApiRequest(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyApiRequestMutation__
 *
 * To run a mutation, you first call `useDestroyApiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyApiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyApiRequestMutation, { data, loading, error }] = useDestroyApiRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyApiRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyApiRequestMutation, DestroyApiRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyApiRequestMutation, DestroyApiRequestMutationVariables>(DestroyApiRequestDocument, baseOptions);
      }
export type DestroyApiRequestMutationHookResult = ReturnType<typeof useDestroyApiRequestMutation>;
export const DestroyAttributeDocument = gql`
    mutation DestroyAttribute($input: DestroyAttributeInput!) {
  destroyAttribute(input: $input) {
    ...AttributeFragment
  }
}
    ${AttributeFragmentFragmentDoc}`;

/**
 * __useDestroyAttributeMutation__
 *
 * To run a mutation, you first call `useDestroyAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAttributeMutation, { data, loading, error }] = useDestroyAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyAttributeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyAttributeMutation, DestroyAttributeMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyAttributeMutation, DestroyAttributeMutationVariables>(DestroyAttributeDocument, baseOptions);
      }
export type DestroyAttributeMutationHookResult = ReturnType<typeof useDestroyAttributeMutation>;
export const DestroyConfigurationDocument = gql`
    mutation DestroyConfiguration($input: DestroyConfigurationInput!) {
  destroyConfiguration(input: $input) {
    id
    environmentId
    installationId
  }
}
    `;

/**
 * __useDestroyConfigurationMutation__
 *
 * To run a mutation, you first call `useDestroyConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyConfigurationMutation, { data, loading, error }] = useDestroyConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyConfigurationMutation, DestroyConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyConfigurationMutation, DestroyConfigurationMutationVariables>(DestroyConfigurationDocument, baseOptions);
      }
export type DestroyConfigurationMutationHookResult = ReturnType<typeof useDestroyConfigurationMutation>;
export const DestroyContactDocument = gql`
    mutation DestroyContact($input: DestroyContactInput!) {
  destroyContact(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyContactMutation__
 *
 * To run a mutation, you first call `useDestroyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyContactMutation, { data, loading, error }] = useDestroyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyContactMutation, DestroyContactMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyContactMutation, DestroyContactMutationVariables>(DestroyContactDocument, baseOptions);
      }
export type DestroyContactMutationHookResult = ReturnType<typeof useDestroyContactMutation>;
export const DestroyCouponDocument = gql`
    mutation DestroyCoupon($input: DestroyCouponInput!) {
  destroyCoupon(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyCouponMutation__
 *
 * To run a mutation, you first call `useDestroyCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCouponMutation, { data, loading, error }] = useDestroyCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyCouponMutation, DestroyCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyCouponMutation, DestroyCouponMutationVariables>(DestroyCouponDocument, baseOptions);
      }
export type DestroyCouponMutationHookResult = ReturnType<typeof useDestroyCouponMutation>;
export const DestroyCouponCodeDocument = gql`
    mutation DestroyCouponCode($input: DestroyCouponCodeInput!) {
  destroyCouponCode(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyCouponCodeMutation__
 *
 * To run a mutation, you first call `useDestroyCouponCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCouponCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCouponCodeMutation, { data, loading, error }] = useDestroyCouponCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyCouponCodeMutation, DestroyCouponCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyCouponCodeMutation, DestroyCouponCodeMutationVariables>(DestroyCouponCodeDocument, baseOptions);
      }
export type DestroyCouponCodeMutationHookResult = ReturnType<typeof useDestroyCouponCodeMutation>;
export const DestroyCouponRedemptionDocument = gql`
    mutation DestroyCouponRedemption($input: DestroyCouponRedemptionInput!) {
  destroyCouponRedemption(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyCouponRedemptionMutation__
 *
 * To run a mutation, you first call `useDestroyCouponRedemptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCouponRedemptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCouponRedemptionMutation, { data, loading, error }] = useDestroyCouponRedemptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCouponRedemptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyCouponRedemptionMutation, DestroyCouponRedemptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyCouponRedemptionMutation, DestroyCouponRedemptionMutationVariables>(DestroyCouponRedemptionDocument, baseOptions);
      }
export type DestroyCouponRedemptionMutationHookResult = ReturnType<typeof useDestroyCouponRedemptionMutation>;
export const DestroyCouponRestrictionDocument = gql`
    mutation DestroyCouponRestriction($input: DestroyCouponRestrictionInput!) {
  destroyCouponRestriction(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyCouponRestrictionMutation__
 *
 * To run a mutation, you first call `useDestroyCouponRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCouponRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCouponRestrictionMutation, { data, loading, error }] = useDestroyCouponRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCouponRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyCouponRestrictionMutation, DestroyCouponRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyCouponRestrictionMutation, DestroyCouponRestrictionMutationVariables>(DestroyCouponRestrictionDocument, baseOptions);
      }
export type DestroyCouponRestrictionMutationHookResult = ReturnType<typeof useDestroyCouponRestrictionMutation>;
export const DestroyDashboardDocument = gql`
    mutation DestroyDashboard($input: DestroyDashboardInput!) {
  destroyDashboard(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyDashboardMutation__
 *
 * To run a mutation, you first call `useDestroyDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyDashboardMutation, { data, loading, error }] = useDestroyDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyDashboardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyDashboardMutation, DestroyDashboardMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyDashboardMutation, DestroyDashboardMutationVariables>(DestroyDashboardDocument, baseOptions);
      }
export type DestroyDashboardMutationHookResult = ReturnType<typeof useDestroyDashboardMutation>;
export const DestroyDatabaseScratchpadDocument = gql`
    mutation DestroyDatabaseScratchpad($input: DestroyDatabaseScratchpadInput!) {
  destroyDatabaseScratchpad(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyDatabaseScratchpadMutation__
 *
 * To run a mutation, you first call `useDestroyDatabaseScratchpadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyDatabaseScratchpadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyDatabaseScratchpadMutation, { data, loading, error }] = useDestroyDatabaseScratchpadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyDatabaseScratchpadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyDatabaseScratchpadMutation, DestroyDatabaseScratchpadMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyDatabaseScratchpadMutation, DestroyDatabaseScratchpadMutationVariables>(DestroyDatabaseScratchpadDocument, baseOptions);
      }
export type DestroyDatabaseScratchpadMutationHookResult = ReturnType<typeof useDestroyDatabaseScratchpadMutation>;
export const DestroyDeliveryDocument = gql`
    mutation DestroyDelivery($input: DestroyDeliveryInput!) {
  destroyDelivery(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyDeliveryMutation__
 *
 * To run a mutation, you first call `useDestroyDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyDeliveryMutation, { data, loading, error }] = useDestroyDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyDeliveryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyDeliveryMutation, DestroyDeliveryMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyDeliveryMutation, DestroyDeliveryMutationVariables>(DestroyDeliveryDocument, baseOptions);
      }
export type DestroyDeliveryMutationHookResult = ReturnType<typeof useDestroyDeliveryMutation>;
export const DestroyGroupDocument = gql`
    mutation DestroyGroup($input: DestroyGroupInput!) {
  destroyGroup(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyGroupMutation__
 *
 * To run a mutation, you first call `useDestroyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyGroupMutation, { data, loading, error }] = useDestroyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyGroupMutation, DestroyGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyGroupMutation, DestroyGroupMutationVariables>(DestroyGroupDocument, baseOptions);
      }
export type DestroyGroupMutationHookResult = ReturnType<typeof useDestroyGroupMutation>;
export const DestroyGroupMembershipDocument = gql`
    mutation DestroyGroupMembership($input: DestroyGroupMembershipInput!) {
  destroyGroupMembership(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyGroupMembershipMutation__
 *
 * To run a mutation, you first call `useDestroyGroupMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyGroupMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyGroupMembershipMutation, { data, loading, error }] = useDestroyGroupMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyGroupMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyGroupMembershipMutation, DestroyGroupMembershipMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyGroupMembershipMutation, DestroyGroupMembershipMutationVariables>(DestroyGroupMembershipDocument, baseOptions);
      }
export type DestroyGroupMembershipMutationHookResult = ReturnType<typeof useDestroyGroupMembershipMutation>;
export const DestroyItemDocument = gql`
    mutation DestroyItem($input: DestroyItemInput!) {
  destroyItem(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyItemMutation__
 *
 * To run a mutation, you first call `useDestroyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyItemMutation, { data, loading, error }] = useDestroyItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyItemMutation, DestroyItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyItemMutation, DestroyItemMutationVariables>(DestroyItemDocument, baseOptions);
      }
export type DestroyItemMutationHookResult = ReturnType<typeof useDestroyItemMutation>;
export const DestroyItemCategoryDocument = gql`
    mutation DestroyItemCategory($input: DestroyItemCategoryInput!) {
  destroyItemCategory(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyItemCategoryMutation__
 *
 * To run a mutation, you first call `useDestroyItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyItemCategoryMutation, { data, loading, error }] = useDestroyItemCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyItemCategoryMutation, DestroyItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyItemCategoryMutation, DestroyItemCategoryMutationVariables>(DestroyItemCategoryDocument, baseOptions);
      }
export type DestroyItemCategoryMutationHookResult = ReturnType<typeof useDestroyItemCategoryMutation>;
export const DestroyItemCategoryMembershipDocument = gql`
    mutation DestroyItemCategoryMembership($input: DestroyItemCategoryMembershipInput!) {
  destroyItemCategoryMembership(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyItemCategoryMembershipMutation__
 *
 * To run a mutation, you first call `useDestroyItemCategoryMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyItemCategoryMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyItemCategoryMembershipMutation, { data, loading, error }] = useDestroyItemCategoryMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyItemCategoryMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyItemCategoryMembershipMutation, DestroyItemCategoryMembershipMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyItemCategoryMembershipMutation, DestroyItemCategoryMembershipMutationVariables>(DestroyItemCategoryMembershipDocument, baseOptions);
      }
export type DestroyItemCategoryMembershipMutationHookResult = ReturnType<typeof useDestroyItemCategoryMembershipMutation>;
export const DestroyMenuElementDocument = gql`
    mutation DestroyMenuElement($input: DestroyMenuElementInput!) {
  destroyMenuElement(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyMenuElementMutation__
 *
 * To run a mutation, you first call `useDestroyMenuElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyMenuElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyMenuElementMutation, { data, loading, error }] = useDestroyMenuElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyMenuElementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyMenuElementMutation, DestroyMenuElementMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyMenuElementMutation, DestroyMenuElementMutationVariables>(DestroyMenuElementDocument, baseOptions);
      }
export type DestroyMenuElementMutationHookResult = ReturnType<typeof useDestroyMenuElementMutation>;
export const DestroyOrderDocument = gql`
    mutation DestroyOrder($input: DestroyOrderInput!) {
  destroyOrder(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyOrderMutation__
 *
 * To run a mutation, you first call `useDestroyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyOrderMutation, { data, loading, error }] = useDestroyOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyOrderMutation, DestroyOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyOrderMutation, DestroyOrderMutationVariables>(DestroyOrderDocument, baseOptions);
      }
export type DestroyOrderMutationHookResult = ReturnType<typeof useDestroyOrderMutation>;
export const DestroyOrderItemDocument = gql`
    mutation DestroyOrderItem($input: DestroyOrderItemInput!) {
  destroyOrderItem(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyOrderItemMutation__
 *
 * To run a mutation, you first call `useDestroyOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyOrderItemMutation, { data, loading, error }] = useDestroyOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyOrderItemMutation, DestroyOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyOrderItemMutation, DestroyOrderItemMutationVariables>(DestroyOrderItemDocument, baseOptions);
      }
export type DestroyOrderItemMutationHookResult = ReturnType<typeof useDestroyOrderItemMutation>;
export const DestroyParameterDocument = gql`
    mutation DestroyParameter($input: DestroyParameterInput!) {
  destroyParameter(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyParameterMutation__
 *
 * To run a mutation, you first call `useDestroyParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyParameterMutation, { data, loading, error }] = useDestroyParameterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyParameterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyParameterMutation, DestroyParameterMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyParameterMutation, DestroyParameterMutationVariables>(DestroyParameterDocument, baseOptions);
      }
export type DestroyParameterMutationHookResult = ReturnType<typeof useDestroyParameterMutation>;
export const DestroyPreferenceDocument = gql`
    mutation DestroyPreference($input: DestroyPreferenceInput!) {
  destroyPreference(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyPreferenceMutation__
 *
 * To run a mutation, you first call `useDestroyPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyPreferenceMutation, { data, loading, error }] = useDestroyPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyPreferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyPreferenceMutation, DestroyPreferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyPreferenceMutation, DestroyPreferenceMutationVariables>(DestroyPreferenceDocument, baseOptions);
      }
export type DestroyPreferenceMutationHookResult = ReturnType<typeof useDestroyPreferenceMutation>;
export const DestroyPricingDocument = gql`
    mutation DestroyPricing($input: DestroyPricingInput!) {
  destroyPricing(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyPricingMutation__
 *
 * To run a mutation, you first call `useDestroyPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyPricingMutation, { data, loading, error }] = useDestroyPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyPricingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyPricingMutation, DestroyPricingMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyPricingMutation, DestroyPricingMutationVariables>(DestroyPricingDocument, baseOptions);
      }
export type DestroyPricingMutationHookResult = ReturnType<typeof useDestroyPricingMutation>;
export const DestroyRecipientDocument = gql`
    mutation DestroyRecipient($input: DestroyRecipientInput!) {
  destroyRecipient(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyRecipientMutation__
 *
 * To run a mutation, you first call `useDestroyRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyRecipientMutation, { data, loading, error }] = useDestroyRecipientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyRecipientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyRecipientMutation, DestroyRecipientMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyRecipientMutation, DestroyRecipientMutationVariables>(DestroyRecipientDocument, baseOptions);
      }
export type DestroyRecipientMutationHookResult = ReturnType<typeof useDestroyRecipientMutation>;
export const DestroyRecordDocument = gql`
    mutation DestroyRecord($input: DestroyRecordInput!) {
  destroyRecord(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyRecordMutation__
 *
 * To run a mutation, you first call `useDestroyRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyRecordMutation, { data, loading, error }] = useDestroyRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyRecordMutation, DestroyRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyRecordMutation, DestroyRecordMutationVariables>(DestroyRecordDocument, baseOptions);
      }
export type DestroyRecordMutationHookResult = ReturnType<typeof useDestroyRecordMutation>;
export const DestroyRelationshipDocument = gql`
    mutation DestroyRelationship($input: DestroyRelationshipInput!) {
  destroyRelationship(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyRelationshipMutation__
 *
 * To run a mutation, you first call `useDestroyRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyRelationshipMutation, { data, loading, error }] = useDestroyRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyRelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyRelationshipMutation, DestroyRelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyRelationshipMutation, DestroyRelationshipMutationVariables>(DestroyRelationshipDocument, baseOptions);
      }
export type DestroyRelationshipMutationHookResult = ReturnType<typeof useDestroyRelationshipMutation>;
export const DestroyResourceDocument = gql`
    mutation DestroyResource($input: DestroyResourceInput!) {
  destroyResource(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyResourceMutation__
 *
 * To run a mutation, you first call `useDestroyResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyResourceMutation, { data, loading, error }] = useDestroyResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyResourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyResourceMutation, DestroyResourceMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyResourceMutation, DestroyResourceMutationVariables>(DestroyResourceDocument, baseOptions);
      }
export type DestroyResourceMutationHookResult = ReturnType<typeof useDestroyResourceMutation>;
export const DestroyRoleDocument = gql`
    mutation DestroyRole($input: DestroyRoleInput!) {
  destroyRole(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyRoleMutation__
 *
 * To run a mutation, you first call `useDestroyRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyRoleMutation, { data, loading, error }] = useDestroyRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyRoleMutation, DestroyRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyRoleMutation, DestroyRoleMutationVariables>(DestroyRoleDocument, baseOptions);
      }
export type DestroyRoleMutationHookResult = ReturnType<typeof useDestroyRoleMutation>;
export const DestroyRoleMembershipDocument = gql`
    mutation DestroyRoleMembership($input: DestroyRoleMembershipInput!) {
  destroyRoleMembership(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyRoleMembershipMutation__
 *
 * To run a mutation, you first call `useDestroyRoleMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyRoleMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyRoleMembershipMutation, { data, loading, error }] = useDestroyRoleMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyRoleMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyRoleMembershipMutation, DestroyRoleMembershipMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyRoleMembershipMutation, DestroyRoleMembershipMutationVariables>(DestroyRoleMembershipDocument, baseOptions);
      }
export type DestroyRoleMembershipMutationHookResult = ReturnType<typeof useDestroyRoleMembershipMutation>;
export const DestroySegmentDocument = gql`
    mutation DestroySegment($input: DestroySegmentInput!) {
  destroySegment(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroySegmentMutation__
 *
 * To run a mutation, you first call `useDestroySegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySegmentMutation, { data, loading, error }] = useDestroySegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySegmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroySegmentMutation, DestroySegmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroySegmentMutation, DestroySegmentMutationVariables>(DestroySegmentDocument, baseOptions);
      }
export type DestroySegmentMutationHookResult = ReturnType<typeof useDestroySegmentMutation>;
export const DestroyTemplateDocument = gql`
    mutation DestroyTemplate($input: DestroyTemplateInput!) {
  destroyTemplate(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyTemplateMutation__
 *
 * To run a mutation, you first call `useDestroyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyTemplateMutation, { data, loading, error }] = useDestroyTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyTemplateMutation, DestroyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyTemplateMutation, DestroyTemplateMutationVariables>(DestroyTemplateDocument, baseOptions);
      }
export type DestroyTemplateMutationHookResult = ReturnType<typeof useDestroyTemplateMutation>;
export const DestroyThemeDocument = gql`
    mutation DestroyTheme($input: DestroyThemeInput!) {
  destroyTheme(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyThemeMutation__
 *
 * To run a mutation, you first call `useDestroyThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyThemeMutation, { data, loading, error }] = useDestroyThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyThemeMutation, DestroyThemeMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyThemeMutation, DestroyThemeMutationVariables>(DestroyThemeDocument, baseOptions);
      }
export type DestroyThemeMutationHookResult = ReturnType<typeof useDestroyThemeMutation>;
export const DestroyWorkspacePaymentMethodDocument = gql`
    mutation DestroyWorkspacePaymentMethod($input: DestroyWorkspacePaymentMethodInput!) {
  destroyWorkspacePaymentMethod(input: $input) {
    id
  }
}
    `;

/**
 * __useDestroyWorkspacePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDestroyWorkspacePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyWorkspacePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyWorkspacePaymentMethodMutation, { data, loading, error }] = useDestroyWorkspacePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyWorkspacePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroyWorkspacePaymentMethodMutation, DestroyWorkspacePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<DestroyWorkspacePaymentMethodMutation, DestroyWorkspacePaymentMethodMutationVariables>(DestroyWorkspacePaymentMethodDocument, baseOptions);
      }
export type DestroyWorkspacePaymentMethodMutationHookResult = ReturnType<typeof useDestroyWorkspacePaymentMethodMutation>;
export const DisableMfaDocument = gql`
    mutation DisableMfa($input: DisableMfaInput!) {
  disableMfa(input: $input) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useDisableMfaMutation__
 *
 * To run a mutation, you first call `useDisableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableMfaMutation, { data, loading, error }] = useDisableMfaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableMfaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableMfaMutation, DisableMfaMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableMfaMutation, DisableMfaMutationVariables>(DisableMfaDocument, baseOptions);
      }
export type DisableMfaMutationHookResult = ReturnType<typeof useDisableMfaMutation>;
export const DuplicateFlowVersionDocument = gql`
    mutation DuplicateFlowVersion($input: DuplicateFlowVersionInput!) {
  duplicateFlowVersion(input: $input) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __useDuplicateFlowVersionMutation__
 *
 * To run a mutation, you first call `useDuplicateFlowVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFlowVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFlowVersionMutation, { data, loading, error }] = useDuplicateFlowVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateFlowVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateFlowVersionMutation, DuplicateFlowVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateFlowVersionMutation, DuplicateFlowVersionMutationVariables>(DuplicateFlowVersionDocument, baseOptions);
      }
export type DuplicateFlowVersionMutationHookResult = ReturnType<typeof useDuplicateFlowVersionMutation>;
export const EditAccountTourDocument = gql`
    mutation EditAccountTour($input: EditAccountTourInput!) {
  editAccountTour(input: $input) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useEditAccountTourMutation__
 *
 * To run a mutation, you first call `useEditAccountTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountTourMutation, { data, loading, error }] = useEditAccountTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccountTourMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAccountTourMutation, EditAccountTourMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAccountTourMutation, EditAccountTourMutationVariables>(EditAccountTourDocument, baseOptions);
      }
export type EditAccountTourMutationHookResult = ReturnType<typeof useEditAccountTourMutation>;
export const EditDatabaseRecordDocument = gql`
    mutation EditDatabaseRecord($input: EditDatabaseRecordInput!) {
  editDatabaseRecord(input: $input)
}
    `;

/**
 * __useEditDatabaseRecordMutation__
 *
 * To run a mutation, you first call `useEditDatabaseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDatabaseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDatabaseRecordMutation, { data, loading, error }] = useEditDatabaseRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDatabaseRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDatabaseRecordMutation, EditDatabaseRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDatabaseRecordMutation, EditDatabaseRecordMutationVariables>(EditDatabaseRecordDocument, baseOptions);
      }
export type EditDatabaseRecordMutationHookResult = ReturnType<typeof useEditDatabaseRecordMutation>;
export const EnableMfaDocument = gql`
    mutation EnableMfa($input: EnableMfaInput!) {
  enableMfa(input: $input) {
    mfaBackupCodes
  }
}
    `;

/**
 * __useEnableMfaMutation__
 *
 * To run a mutation, you first call `useEnableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableMfaMutation, { data, loading, error }] = useEnableMfaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableMfaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableMfaMutation, EnableMfaMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableMfaMutation, EnableMfaMutationVariables>(EnableMfaDocument, baseOptions);
      }
export type EnableMfaMutationHookResult = ReturnType<typeof useEnableMfaMutation>;
export const EnvironmentDocument = gql`
    query Environment($id: UUID!) {
  environment(id: $id) {
    ...EnvironmentFragment
  }
}
    ${EnvironmentFragmentFragmentDoc}`;

/**
 * __useEnvironmentQuery__
 *
 * To run a query within a React component, call `useEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnvironmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
        return ApolloReactHooks.useQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, baseOptions);
      }
export function useEnvironmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, baseOptions);
        }
export type EnvironmentQueryHookResult = ReturnType<typeof useEnvironmentQuery>;
export type EnvironmentLazyQueryHookResult = ReturnType<typeof useEnvironmentLazyQuery>;
export const EnvironmentsListDocument = gql`
    query EnvironmentsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  environmentsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...EnvironmentFragment
  }
  environmentsAggregate(filter: $filter) {
    count
  }
}
    ${EnvironmentFragmentFragmentDoc}`;

/**
 * __useEnvironmentsListQuery__
 *
 * To run a query within a React component, call `useEnvironmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEnvironmentsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnvironmentsListQuery, EnvironmentsListQueryVariables>) {
        return ApolloReactHooks.useQuery<EnvironmentsListQuery, EnvironmentsListQueryVariables>(EnvironmentsListDocument, baseOptions);
      }
export function useEnvironmentsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnvironmentsListQuery, EnvironmentsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EnvironmentsListQuery, EnvironmentsListQueryVariables>(EnvironmentsListDocument, baseOptions);
        }
export type EnvironmentsListQueryHookResult = ReturnType<typeof useEnvironmentsListQuery>;
export type EnvironmentsListLazyQueryHookResult = ReturnType<typeof useEnvironmentsListLazyQuery>;
export const EventReportDocument = gql`
    query EventReport($id: UUID!) {
  eventReport(id: $id) {
    id
    name
    description
    criteria
  }
}
    `;

/**
 * __useEventReportQuery__
 *
 * To run a query within a React component, call `useEventReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventReportQuery, EventReportQueryVariables>) {
        return ApolloReactHooks.useQuery<EventReportQuery, EventReportQueryVariables>(EventReportDocument, baseOptions);
      }
export function useEventReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventReportQuery, EventReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventReportQuery, EventReportQueryVariables>(EventReportDocument, baseOptions);
        }
export type EventReportQueryHookResult = ReturnType<typeof useEventReportQuery>;
export type EventReportLazyQueryHookResult = ReturnType<typeof useEventReportLazyQuery>;
export const EventReportsListDocument = gql`
    query EventReportsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  eventReportsAggregate(filter: $filter) {
    count
  }
  eventReportsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...EventReportFragment
  }
}
    ${EventReportFragmentFragmentDoc}`;

/**
 * __useEventReportsListQuery__
 *
 * To run a query within a React component, call `useEventReportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventReportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventReportsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEventReportsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventReportsListQuery, EventReportsListQueryVariables>) {
        return ApolloReactHooks.useQuery<EventReportsListQuery, EventReportsListQueryVariables>(EventReportsListDocument, baseOptions);
      }
export function useEventReportsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventReportsListQuery, EventReportsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventReportsListQuery, EventReportsListQueryVariables>(EventReportsListDocument, baseOptions);
        }
export type EventReportsListQueryHookResult = ReturnType<typeof useEventReportsListQuery>;
export type EventReportsListLazyQueryHookResult = ReturnType<typeof useEventReportsListLazyQuery>;
export const EventTypesListDocument = gql`
    query EventTypesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  eventTypesAggregate(filter: $filter) {
    count
  }
  eventTypesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...EventTypeFragment
  }
}
    ${EventTypeFragmentFragmentDoc}`;

/**
 * __useEventTypesListQuery__
 *
 * To run a query within a React component, call `useEventTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTypesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEventTypesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventTypesListQuery, EventTypesListQueryVariables>) {
        return ApolloReactHooks.useQuery<EventTypesListQuery, EventTypesListQueryVariables>(EventTypesListDocument, baseOptions);
      }
export function useEventTypesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventTypesListQuery, EventTypesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventTypesListQuery, EventTypesListQueryVariables>(EventTypesListDocument, baseOptions);
        }
export type EventTypesListQueryHookResult = ReturnType<typeof useEventTypesListQuery>;
export type EventTypesListLazyQueryHookResult = ReturnType<typeof useEventTypesListLazyQuery>;
export const EventsAggregateDocument = gql`
    query EventsAggregate($filter: JSON, $targetEnvironment: String) {
  eventsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useEventsAggregateQuery__
 *
 * To run a query within a React component, call `useEventsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useEventsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsAggregateQuery, EventsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsAggregateQuery, EventsAggregateQueryVariables>(EventsAggregateDocument, baseOptions);
      }
export function useEventsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsAggregateQuery, EventsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsAggregateQuery, EventsAggregateQueryVariables>(EventsAggregateDocument, baseOptions);
        }
export type EventsAggregateQueryHookResult = ReturnType<typeof useEventsAggregateQuery>;
export type EventsAggregateLazyQueryHookResult = ReturnType<typeof useEventsAggregateLazyQuery>;
export const EventsListDocument = gql`
    query EventsList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  eventsList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...EventFragment
  }
}
    ${EventFragmentFragmentDoc}`;

/**
 * __useEventsListQuery__
 *
 * To run a query within a React component, call `useEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useEventsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, baseOptions);
      }
export function useEventsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, baseOptions);
        }
export type EventsListQueryHookResult = ReturnType<typeof useEventsListQuery>;
export type EventsListLazyQueryHookResult = ReturnType<typeof useEventsListLazyQuery>;
export const ExecuteApiRequestDocument = gql`
    mutation ExecuteApiRequest($input: ExecuteApiRequestInput!) {
  executeApiRequest(input: $input) {
    time
    statusCode
    headers
    body
  }
}
    `;

/**
 * __useExecuteApiRequestMutation__
 *
 * To run a mutation, you first call `useExecuteApiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteApiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeApiRequestMutation, { data, loading, error }] = useExecuteApiRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteApiRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteApiRequestMutation, ExecuteApiRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteApiRequestMutation, ExecuteApiRequestMutationVariables>(ExecuteApiRequestDocument, baseOptions);
      }
export type ExecuteApiRequestMutationHookResult = ReturnType<typeof useExecuteApiRequestMutation>;
export const ExecuteDatabaseQueryDocument = gql`
    mutation ExecuteDatabaseQuery($input: ExecuteDatabaseQueryInput!) {
  executeDatabaseQuery(input: $input) {
    time
    results {
      message
      success
      rowsAffected
      rows
      columns
    }
  }
}
    `;

/**
 * __useExecuteDatabaseQueryMutation__
 *
 * To run a mutation, you first call `useExecuteDatabaseQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteDatabaseQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeDatabaseQueryMutation, { data, loading, error }] = useExecuteDatabaseQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteDatabaseQueryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteDatabaseQueryMutation, ExecuteDatabaseQueryMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteDatabaseQueryMutation, ExecuteDatabaseQueryMutationVariables>(ExecuteDatabaseQueryDocument, baseOptions);
      }
export type ExecuteDatabaseQueryMutationHookResult = ReturnType<typeof useExecuteDatabaseQueryMutation>;
export const ExecuteEventReportDocument = gql`
    query ExecuteEventReport($input: ExecuteEventReportInput!) {
  executeEventReport(input: $input) {
    behaviorReport {
      series {
        label
        values
      }
      dateRange {
        from
        to
      }
    }
    funnelReport {
      steps {
        count
        label
        overallConvRatio
        stepConvRatio
      }
    }
  }
}
    `;

/**
 * __useExecuteEventReportQuery__
 *
 * To run a query within a React component, call `useExecuteEventReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecuteEventReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecuteEventReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteEventReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExecuteEventReportQuery, ExecuteEventReportQueryVariables>) {
        return ApolloReactHooks.useQuery<ExecuteEventReportQuery, ExecuteEventReportQueryVariables>(ExecuteEventReportDocument, baseOptions);
      }
export function useExecuteEventReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExecuteEventReportQuery, ExecuteEventReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExecuteEventReportQuery, ExecuteEventReportQueryVariables>(ExecuteEventReportDocument, baseOptions);
        }
export type ExecuteEventReportQueryHookResult = ReturnType<typeof useExecuteEventReportQuery>;
export type ExecuteEventReportLazyQueryHookResult = ReturnType<typeof useExecuteEventReportLazyQuery>;
export const ExecuteFlowDocument = gql`
    mutation ExecuteFlow($input: ExecuteFlowInput!) {
  executeFlow(input: $input) {
    ...FlowExecutionFragment
  }
}
    ${FlowExecutionFragmentFragmentDoc}`;

/**
 * __useExecuteFlowMutation__
 *
 * To run a mutation, you first call `useExecuteFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeFlowMutation, { data, loading, error }] = useExecuteFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteFlowMutation, ExecuteFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteFlowMutation, ExecuteFlowMutationVariables>(ExecuteFlowDocument, baseOptions);
      }
export type ExecuteFlowMutationHookResult = ReturnType<typeof useExecuteFlowMutation>;
export const ExecuteMutationOperationDocument = gql`
    mutation ExecuteMutationOperation($input: ExecuteMutationOperationInput!) {
  executeMutationOperation(input: $input)
}
    `;

/**
 * __useExecuteMutationOperationMutation__
 *
 * To run a mutation, you first call `useExecuteMutationOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteMutationOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeMutationOperationMutation, { data, loading, error }] = useExecuteMutationOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteMutationOperationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteMutationOperationMutation, ExecuteMutationOperationMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteMutationOperationMutation, ExecuteMutationOperationMutationVariables>(ExecuteMutationOperationDocument, baseOptions);
      }
export type ExecuteMutationOperationMutationHookResult = ReturnType<typeof useExecuteMutationOperationMutation>;
export const ExecuteQueryOperationDocument = gql`
    query ExecuteQueryOperation($input: ExecuteQueryOperationInput!) {
  executeQueryOperation(input: $input)
}
    `;

/**
 * __useExecuteQueryOperationQuery__
 *
 * To run a query within a React component, call `useExecuteQueryOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecuteQueryOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecuteQueryOperationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteQueryOperationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExecuteQueryOperationQuery, ExecuteQueryOperationQueryVariables>) {
        return ApolloReactHooks.useQuery<ExecuteQueryOperationQuery, ExecuteQueryOperationQueryVariables>(ExecuteQueryOperationDocument, baseOptions);
      }
export function useExecuteQueryOperationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExecuteQueryOperationQuery, ExecuteQueryOperationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExecuteQueryOperationQuery, ExecuteQueryOperationQueryVariables>(ExecuteQueryOperationDocument, baseOptions);
        }
export type ExecuteQueryOperationQueryHookResult = ReturnType<typeof useExecuteQueryOperationQuery>;
export type ExecuteQueryOperationLazyQueryHookResult = ReturnType<typeof useExecuteQueryOperationLazyQuery>;
export const ExportDatabaseRecordsDocument = gql`
    query ExportDatabaseRecords($input: ExportDatabaseRecordsInput!) {
  exportDatabaseRecords(input: $input) {
    success
  }
}
    `;

/**
 * __useExportDatabaseRecordsQuery__
 *
 * To run a query within a React component, call `useExportDatabaseRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDatabaseRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDatabaseRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDatabaseRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportDatabaseRecordsQuery, ExportDatabaseRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportDatabaseRecordsQuery, ExportDatabaseRecordsQueryVariables>(ExportDatabaseRecordsDocument, baseOptions);
      }
export function useExportDatabaseRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportDatabaseRecordsQuery, ExportDatabaseRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportDatabaseRecordsQuery, ExportDatabaseRecordsQueryVariables>(ExportDatabaseRecordsDocument, baseOptions);
        }
export type ExportDatabaseRecordsQueryHookResult = ReturnType<typeof useExportDatabaseRecordsQuery>;
export type ExportDatabaseRecordsLazyQueryHookResult = ReturnType<typeof useExportDatabaseRecordsLazyQuery>;
export const FetchCurrentWorkspaceOrderDocument = gql`
    query FetchCurrentWorkspaceOrder {
  fetchCurrentWorkspaceOrder {
    ...CustomOrderFragment
  }
}
    ${CustomOrderFragmentFragmentDoc}`;

/**
 * __useFetchCurrentWorkspaceOrderQuery__
 *
 * To run a query within a React component, call `useFetchCurrentWorkspaceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCurrentWorkspaceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCurrentWorkspaceOrderQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCurrentWorkspaceOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCurrentWorkspaceOrderQuery, FetchCurrentWorkspaceOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchCurrentWorkspaceOrderQuery, FetchCurrentWorkspaceOrderQueryVariables>(FetchCurrentWorkspaceOrderDocument, baseOptions);
      }
export function useFetchCurrentWorkspaceOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCurrentWorkspaceOrderQuery, FetchCurrentWorkspaceOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchCurrentWorkspaceOrderQuery, FetchCurrentWorkspaceOrderQueryVariables>(FetchCurrentWorkspaceOrderDocument, baseOptions);
        }
export type FetchCurrentWorkspaceOrderQueryHookResult = ReturnType<typeof useFetchCurrentWorkspaceOrderQuery>;
export type FetchCurrentWorkspaceOrderLazyQueryHookResult = ReturnType<typeof useFetchCurrentWorkspaceOrderLazyQuery>;
export const FetchStoredPreferencesDocument = gql`
    query FetchStoredPreferences($input: FetchStoredPreferencesInput!) {
  fetchStoredPreferences(input: $input) {
    preferenceData
  }
}
    `;

/**
 * __useFetchStoredPreferencesQuery__
 *
 * To run a query within a React component, call `useFetchStoredPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStoredPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStoredPreferencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchStoredPreferencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchStoredPreferencesQuery, FetchStoredPreferencesQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchStoredPreferencesQuery, FetchStoredPreferencesQueryVariables>(FetchStoredPreferencesDocument, baseOptions);
      }
export function useFetchStoredPreferencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchStoredPreferencesQuery, FetchStoredPreferencesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchStoredPreferencesQuery, FetchStoredPreferencesQueryVariables>(FetchStoredPreferencesDocument, baseOptions);
        }
export type FetchStoredPreferencesQueryHookResult = ReturnType<typeof useFetchStoredPreferencesQuery>;
export type FetchStoredPreferencesLazyQueryHookResult = ReturnType<typeof useFetchStoredPreferencesLazyQuery>;
export const FetchWorkspaceBillingAccountDocument = gql`
    query FetchWorkspaceBillingAccount {
  fetchWorkspaceBillingAccount {
    ...WorkspaceBillingAccountFragment
  }
}
    ${WorkspaceBillingAccountFragmentFragmentDoc}`;

/**
 * __useFetchWorkspaceBillingAccountQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceBillingAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceBillingAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceBillingAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchWorkspaceBillingAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchWorkspaceBillingAccountQuery, FetchWorkspaceBillingAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchWorkspaceBillingAccountQuery, FetchWorkspaceBillingAccountQueryVariables>(FetchWorkspaceBillingAccountDocument, baseOptions);
      }
export function useFetchWorkspaceBillingAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchWorkspaceBillingAccountQuery, FetchWorkspaceBillingAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchWorkspaceBillingAccountQuery, FetchWorkspaceBillingAccountQueryVariables>(FetchWorkspaceBillingAccountDocument, baseOptions);
        }
export type FetchWorkspaceBillingAccountQueryHookResult = ReturnType<typeof useFetchWorkspaceBillingAccountQuery>;
export type FetchWorkspaceBillingAccountLazyQueryHookResult = ReturnType<typeof useFetchWorkspaceBillingAccountLazyQuery>;
export const FieldTypesListDocument = gql`
    query FieldTypesList {
  fieldTypes {
    name
    identifier
    icon
    position
    category
    dataType
    helpText
    defaultValue
    placeholder
    configuration
    settings
  }
}
    `;

/**
 * __useFieldTypesListQuery__
 *
 * To run a query within a React component, call `useFieldTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTypesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldTypesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FieldTypesListQuery, FieldTypesListQueryVariables>) {
        return ApolloReactHooks.useQuery<FieldTypesListQuery, FieldTypesListQueryVariables>(FieldTypesListDocument, baseOptions);
      }
export function useFieldTypesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FieldTypesListQuery, FieldTypesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FieldTypesListQuery, FieldTypesListQueryVariables>(FieldTypesListDocument, baseOptions);
        }
export type FieldTypesListQueryHookResult = ReturnType<typeof useFieldTypesListQuery>;
export type FieldTypesListLazyQueryHookResult = ReturnType<typeof useFieldTypesListLazyQuery>;
export const FlowDocument = gql`
    query Flow($id: UUID!) {
  flow(id: $id) {
    ...FlowFragment
    flowVersions(order: [{createdAt: "desc"}], limit: 100) {
      ...FlowVersionFragment
    }
  }
}
    ${FlowFragmentFragmentDoc}
${FlowVersionFragmentFragmentDoc}`;

/**
 * __useFlowQuery__
 *
 * To run a query within a React component, call `useFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlowQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowQuery, FlowQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowQuery, FlowQueryVariables>(FlowDocument, baseOptions);
      }
export function useFlowLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowQuery, FlowQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowQuery, FlowQueryVariables>(FlowDocument, baseOptions);
        }
export type FlowQueryHookResult = ReturnType<typeof useFlowQuery>;
export type FlowLazyQueryHookResult = ReturnType<typeof useFlowLazyQuery>;
export const FlowExecutionDocument = gql`
    query FlowExecution($id: UUID!) {
  flowExecution(id: $id) {
    ...FlowExecutionFragment
  }
}
    ${FlowExecutionFragmentFragmentDoc}`;

/**
 * __useFlowExecutionQuery__
 *
 * To run a query within a React component, call `useFlowExecutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlowExecutionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowExecutionQuery, FlowExecutionQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowExecutionQuery, FlowExecutionQueryVariables>(FlowExecutionDocument, baseOptions);
      }
export function useFlowExecutionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowExecutionQuery, FlowExecutionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowExecutionQuery, FlowExecutionQueryVariables>(FlowExecutionDocument, baseOptions);
        }
export type FlowExecutionQueryHookResult = ReturnType<typeof useFlowExecutionQuery>;
export type FlowExecutionLazyQueryHookResult = ReturnType<typeof useFlowExecutionLazyQuery>;
export const FlowExecutionNodeDocument = gql`
    query FlowExecutionNode($id: UUID!) {
  flowExecutionNode(id: $id) {
    ...FlowExecutionNodeFragment
    data
  }
}
    ${FlowExecutionNodeFragmentFragmentDoc}`;

/**
 * __useFlowExecutionNodeQuery__
 *
 * To run a query within a React component, call `useFlowExecutionNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionNodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlowExecutionNodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowExecutionNodeQuery, FlowExecutionNodeQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowExecutionNodeQuery, FlowExecutionNodeQueryVariables>(FlowExecutionNodeDocument, baseOptions);
      }
export function useFlowExecutionNodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowExecutionNodeQuery, FlowExecutionNodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowExecutionNodeQuery, FlowExecutionNodeQueryVariables>(FlowExecutionNodeDocument, baseOptions);
        }
export type FlowExecutionNodeQueryHookResult = ReturnType<typeof useFlowExecutionNodeQuery>;
export type FlowExecutionNodeLazyQueryHookResult = ReturnType<typeof useFlowExecutionNodeLazyQuery>;
export const FlowExecutionNodesAggregateDocument = gql`
    query FlowExecutionNodesAggregate($filter: JSON) {
  flowExecutionNodesAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useFlowExecutionNodesAggregateQuery__
 *
 * To run a query within a React component, call `useFlowExecutionNodesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionNodesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionNodesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFlowExecutionNodesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowExecutionNodesAggregateQuery, FlowExecutionNodesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowExecutionNodesAggregateQuery, FlowExecutionNodesAggregateQueryVariables>(FlowExecutionNodesAggregateDocument, baseOptions);
      }
export function useFlowExecutionNodesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowExecutionNodesAggregateQuery, FlowExecutionNodesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowExecutionNodesAggregateQuery, FlowExecutionNodesAggregateQueryVariables>(FlowExecutionNodesAggregateDocument, baseOptions);
        }
export type FlowExecutionNodesAggregateQueryHookResult = ReturnType<typeof useFlowExecutionNodesAggregateQuery>;
export type FlowExecutionNodesAggregateLazyQueryHookResult = ReturnType<typeof useFlowExecutionNodesAggregateLazyQuery>;
export const FlowExecutionNodesListDocument = gql`
    query FlowExecutionNodesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  flowExecutionNodesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...FlowExecutionNodeFragment
  }
}
    ${FlowExecutionNodeFragmentFragmentDoc}`;

/**
 * __useFlowExecutionNodesListQuery__
 *
 * To run a query within a React component, call `useFlowExecutionNodesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionNodesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionNodesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFlowExecutionNodesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowExecutionNodesListQuery, FlowExecutionNodesListQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowExecutionNodesListQuery, FlowExecutionNodesListQueryVariables>(FlowExecutionNodesListDocument, baseOptions);
      }
export function useFlowExecutionNodesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowExecutionNodesListQuery, FlowExecutionNodesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowExecutionNodesListQuery, FlowExecutionNodesListQueryVariables>(FlowExecutionNodesListDocument, baseOptions);
        }
export type FlowExecutionNodesListQueryHookResult = ReturnType<typeof useFlowExecutionNodesListQuery>;
export type FlowExecutionNodesListLazyQueryHookResult = ReturnType<typeof useFlowExecutionNodesListLazyQuery>;
export const FlowExecutionsAggregateDocument = gql`
    query FlowExecutionsAggregate($filter: JSON, $targetEnvironment: String) {
  flowExecutionsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useFlowExecutionsAggregateQuery__
 *
 * To run a query within a React component, call `useFlowExecutionsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useFlowExecutionsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowExecutionsAggregateQuery, FlowExecutionsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowExecutionsAggregateQuery, FlowExecutionsAggregateQueryVariables>(FlowExecutionsAggregateDocument, baseOptions);
      }
export function useFlowExecutionsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowExecutionsAggregateQuery, FlowExecutionsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowExecutionsAggregateQuery, FlowExecutionsAggregateQueryVariables>(FlowExecutionsAggregateDocument, baseOptions);
        }
export type FlowExecutionsAggregateQueryHookResult = ReturnType<typeof useFlowExecutionsAggregateQuery>;
export type FlowExecutionsAggregateLazyQueryHookResult = ReturnType<typeof useFlowExecutionsAggregateLazyQuery>;
export const FlowExecutionsListDocument = gql`
    query FlowExecutionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  flowExecutionsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
  flowExecutionsList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...FlowExecutionFragment
  }
}
    ${FlowExecutionFragmentFragmentDoc}`;

/**
 * __useFlowExecutionsListQuery__
 *
 * To run a query within a React component, call `useFlowExecutionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowExecutionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowExecutionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useFlowExecutionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowExecutionsListQuery, FlowExecutionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowExecutionsListQuery, FlowExecutionsListQueryVariables>(FlowExecutionsListDocument, baseOptions);
      }
export function useFlowExecutionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowExecutionsListQuery, FlowExecutionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowExecutionsListQuery, FlowExecutionsListQueryVariables>(FlowExecutionsListDocument, baseOptions);
        }
export type FlowExecutionsListQueryHookResult = ReturnType<typeof useFlowExecutionsListQuery>;
export type FlowExecutionsListLazyQueryHookResult = ReturnType<typeof useFlowExecutionsListLazyQuery>;
export const FlowVersionDocument = gql`
    query FlowVersion($id: UUID!) {
  flowVersion(id: $id) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __useFlowVersionQuery__
 *
 * To run a query within a React component, call `useFlowVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlowVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowVersionQuery, FlowVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowVersionQuery, FlowVersionQueryVariables>(FlowVersionDocument, baseOptions);
      }
export function useFlowVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowVersionQuery, FlowVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowVersionQuery, FlowVersionQueryVariables>(FlowVersionDocument, baseOptions);
        }
export type FlowVersionQueryHookResult = ReturnType<typeof useFlowVersionQuery>;
export type FlowVersionLazyQueryHookResult = ReturnType<typeof useFlowVersionLazyQuery>;
export const FlowsListDocument = gql`
    query FlowsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  flowsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...FlowFragment
  }
}
    ${FlowFragmentFragmentDoc}`;

/**
 * __useFlowsListQuery__
 *
 * To run a query within a React component, call `useFlowsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFlowsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowsListQuery, FlowsListQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowsListQuery, FlowsListQueryVariables>(FlowsListDocument, baseOptions);
      }
export function useFlowsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowsListQuery, FlowsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowsListQuery, FlowsListQueryVariables>(FlowsListDocument, baseOptions);
        }
export type FlowsListQueryHookResult = ReturnType<typeof useFlowsListQuery>;
export type FlowsListLazyQueryHookResult = ReturnType<typeof useFlowsListLazyQuery>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    success
  }
}
    `;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export const GenerateGraphDocument = gql`
    mutation GenerateGraph($input: GenerateGraphInput!) {
  generateGraph(input: $input) {
    resources {
      ...ResourceFragment
    }
  }
}
    ${ResourceFragmentFragmentDoc}`;

/**
 * __useGenerateGraphMutation__
 *
 * To run a mutation, you first call `useGenerateGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGraphMutation, { data, loading, error }] = useGenerateGraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateGraphMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateGraphMutation, GenerateGraphMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateGraphMutation, GenerateGraphMutationVariables>(GenerateGraphDocument, baseOptions);
      }
export type GenerateGraphMutationHookResult = ReturnType<typeof useGenerateGraphMutation>;
export const GenerateIdentityTokenDocument = gql`
    query GenerateIdentityToken($input: GenerateIdentityTokenInput!) {
  generateIdentityToken(input: $input) {
    token
  }
}
    `;

/**
 * __useGenerateIdentityTokenQuery__
 *
 * To run a query within a React component, call `useGenerateIdentityTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateIdentityTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateIdentityTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateIdentityTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenerateIdentityTokenQuery, GenerateIdentityTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<GenerateIdentityTokenQuery, GenerateIdentityTokenQueryVariables>(GenerateIdentityTokenDocument, baseOptions);
      }
export function useGenerateIdentityTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenerateIdentityTokenQuery, GenerateIdentityTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenerateIdentityTokenQuery, GenerateIdentityTokenQueryVariables>(GenerateIdentityTokenDocument, baseOptions);
        }
export type GenerateIdentityTokenQueryHookResult = ReturnType<typeof useGenerateIdentityTokenQuery>;
export type GenerateIdentityTokenLazyQueryHookResult = ReturnType<typeof useGenerateIdentityTokenLazyQuery>;
export const GenerateMenuElementsDocument = gql`
    mutation GenerateMenuElements($input: GenerateMenuElementsInput!) {
  generateMenuElements(input: $input) {
    ...MenuElementFragment
  }
}
    ${MenuElementFragmentFragmentDoc}`;

/**
 * __useGenerateMenuElementsMutation__
 *
 * To run a mutation, you first call `useGenerateMenuElementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMenuElementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMenuElementsMutation, { data, loading, error }] = useGenerateMenuElementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMenuElementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateMenuElementsMutation, GenerateMenuElementsMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateMenuElementsMutation, GenerateMenuElementsMutationVariables>(GenerateMenuElementsDocument, baseOptions);
      }
export type GenerateMenuElementsMutationHookResult = ReturnType<typeof useGenerateMenuElementsMutation>;
export const GroupDocument = gql`
    query Group($id: UUID!) {
  group(id: $id) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
      }
export function useGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export const GroupMembershipsListDocument = gql`
    query GroupMembershipsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  groupMembershipsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...GroupMembershipFragment
  }
  groupMembershipsAggregate(filter: $filter) {
    count
  }
}
    ${GroupMembershipFragmentFragmentDoc}`;

/**
 * __useGroupMembershipsListQuery__
 *
 * To run a query within a React component, call `useGroupMembershipsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembershipsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembershipsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGroupMembershipsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupMembershipsListQuery, GroupMembershipsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupMembershipsListQuery, GroupMembershipsListQueryVariables>(GroupMembershipsListDocument, baseOptions);
      }
export function useGroupMembershipsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupMembershipsListQuery, GroupMembershipsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupMembershipsListQuery, GroupMembershipsListQueryVariables>(GroupMembershipsListDocument, baseOptions);
        }
export type GroupMembershipsListQueryHookResult = ReturnType<typeof useGroupMembershipsListQuery>;
export type GroupMembershipsListLazyQueryHookResult = ReturnType<typeof useGroupMembershipsListLazyQuery>;
export const GroupsListDocument = gql`
    query GroupsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  groupsAggregate(filter: $filter) {
    count
  }
  groupsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useGroupsListQuery__
 *
 * To run a query within a React component, call `useGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGroupsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupsListQuery, GroupsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupsListQuery, GroupsListQueryVariables>(GroupsListDocument, baseOptions);
      }
export function useGroupsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsListQuery, GroupsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupsListQuery, GroupsListQueryVariables>(GroupsListDocument, baseOptions);
        }
export type GroupsListQueryHookResult = ReturnType<typeof useGroupsListQuery>;
export type GroupsListLazyQueryHookResult = ReturnType<typeof useGroupsListLazyQuery>;
export const GroupsAggregateDocument = gql`
    query GroupsAggregate($filter: JSON) {
  groupsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useGroupsAggregateQuery__
 *
 * To run a query within a React component, call `useGroupsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGroupsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupsAggregateQuery, GroupsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupsAggregateQuery, GroupsAggregateQueryVariables>(GroupsAggregateDocument, baseOptions);
      }
export function useGroupsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsAggregateQuery, GroupsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupsAggregateQuery, GroupsAggregateQueryVariables>(GroupsAggregateDocument, baseOptions);
        }
export type GroupsAggregateQueryHookResult = ReturnType<typeof useGroupsAggregateQuery>;
export type GroupsAggregateLazyQueryHookResult = ReturnType<typeof useGroupsAggregateLazyQuery>;
export const IdentifyProspectDocument = gql`
    mutation IdentifyProspect($input: IdentifyProspectInput!) {
  identifyProspect(input: $input) {
    success
  }
}
    `;

/**
 * __useIdentifyProspectMutation__
 *
 * To run a mutation, you first call `useIdentifyProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIdentifyProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [identifyProspectMutation, { data, loading, error }] = useIdentifyProspectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIdentifyProspectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IdentifyProspectMutation, IdentifyProspectMutationVariables>) {
        return ApolloReactHooks.useMutation<IdentifyProspectMutation, IdentifyProspectMutationVariables>(IdentifyProspectDocument, baseOptions);
      }
export type IdentifyProspectMutationHookResult = ReturnType<typeof useIdentifyProspectMutation>;
export const InstallationDocument = gql`
    query Installation($id: UUID!) {
  installation(id: $id) {
    ...InstallationFragment
  }
}
    ${InstallationFragmentFragmentDoc}`;

/**
 * __useInstallationQuery__
 *
 * To run a query within a React component, call `useInstallationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInstallationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InstallationQuery, InstallationQueryVariables>) {
        return ApolloReactHooks.useQuery<InstallationQuery, InstallationQueryVariables>(InstallationDocument, baseOptions);
      }
export function useInstallationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InstallationQuery, InstallationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InstallationQuery, InstallationQueryVariables>(InstallationDocument, baseOptions);
        }
export type InstallationQueryHookResult = ReturnType<typeof useInstallationQuery>;
export type InstallationLazyQueryHookResult = ReturnType<typeof useInstallationLazyQuery>;
export const InstallationsAggregateDocument = gql`
    query InstallationsAggregate($filter: JSON) {
  installationsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useInstallationsAggregateQuery__
 *
 * To run a query within a React component, call `useInstallationsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInstallationsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InstallationsAggregateQuery, InstallationsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<InstallationsAggregateQuery, InstallationsAggregateQueryVariables>(InstallationsAggregateDocument, baseOptions);
      }
export function useInstallationsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InstallationsAggregateQuery, InstallationsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InstallationsAggregateQuery, InstallationsAggregateQueryVariables>(InstallationsAggregateDocument, baseOptions);
        }
export type InstallationsAggregateQueryHookResult = ReturnType<typeof useInstallationsAggregateQuery>;
export type InstallationsAggregateLazyQueryHookResult = ReturnType<typeof useInstallationsAggregateLazyQuery>;
export const InstallationsListDocument = gql`
    query InstallationsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  installationsAggregate(filter: $filter) {
    count
  }
  installationsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...InstallationFragment
  }
}
    ${InstallationFragmentFragmentDoc}`;

/**
 * __useInstallationsListQuery__
 *
 * To run a query within a React component, call `useInstallationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useInstallationsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InstallationsListQuery, InstallationsListQueryVariables>) {
        return ApolloReactHooks.useQuery<InstallationsListQuery, InstallationsListQueryVariables>(InstallationsListDocument, baseOptions);
      }
export function useInstallationsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InstallationsListQuery, InstallationsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InstallationsListQuery, InstallationsListQueryVariables>(InstallationsListDocument, baseOptions);
        }
export type InstallationsListQueryHookResult = ReturnType<typeof useInstallationsListQuery>;
export type InstallationsListLazyQueryHookResult = ReturnType<typeof useInstallationsListLazyQuery>;
export const InternalAddRecordDocument = gql`
    mutation InternalAddRecord($input: InternalAddRecordInput!) {
  internalAddRecord(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useInternalAddRecordMutation__
 *
 * To run a mutation, you first call `useInternalAddRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalAddRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalAddRecordMutation, { data, loading, error }] = useInternalAddRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalAddRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InternalAddRecordMutation, InternalAddRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<InternalAddRecordMutation, InternalAddRecordMutationVariables>(InternalAddRecordDocument, baseOptions);
      }
export type InternalAddRecordMutationHookResult = ReturnType<typeof useInternalAddRecordMutation>;
export const InternalDeleteRecordDocument = gql`
    mutation InternalDeleteRecord($input: InternalDeleteRecordInput!) {
  internalDeleteRecord(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useInternalDeleteRecordMutation__
 *
 * To run a mutation, you first call `useInternalDeleteRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalDeleteRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalDeleteRecordMutation, { data, loading, error }] = useInternalDeleteRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalDeleteRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InternalDeleteRecordMutation, InternalDeleteRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<InternalDeleteRecordMutation, InternalDeleteRecordMutationVariables>(InternalDeleteRecordDocument, baseOptions);
      }
export type InternalDeleteRecordMutationHookResult = ReturnType<typeof useInternalDeleteRecordMutation>;
export const InternalEditRecordDocument = gql`
    mutation InternalEditRecord($input: InternalEditRecordInput!) {
  internalEditRecord(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useInternalEditRecordMutation__
 *
 * To run a mutation, you first call `useInternalEditRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalEditRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalEditRecordMutation, { data, loading, error }] = useInternalEditRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalEditRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InternalEditRecordMutation, InternalEditRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<InternalEditRecordMutation, InternalEditRecordMutationVariables>(InternalEditRecordDocument, baseOptions);
      }
export type InternalEditRecordMutationHookResult = ReturnType<typeof useInternalEditRecordMutation>;
export const InternalFetchRecordDocument = gql`
    query InternalFetchRecord($input: InternalFetchRecordInput!) {
  internalFetchRecord(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useInternalFetchRecordQuery__
 *
 * To run a query within a React component, call `useInternalFetchRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalFetchRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalFetchRecordQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalFetchRecordQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InternalFetchRecordQuery, InternalFetchRecordQueryVariables>) {
        return ApolloReactHooks.useQuery<InternalFetchRecordQuery, InternalFetchRecordQueryVariables>(InternalFetchRecordDocument, baseOptions);
      }
export function useInternalFetchRecordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InternalFetchRecordQuery, InternalFetchRecordQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InternalFetchRecordQuery, InternalFetchRecordQueryVariables>(InternalFetchRecordDocument, baseOptions);
        }
export type InternalFetchRecordQueryHookResult = ReturnType<typeof useInternalFetchRecordQuery>;
export type InternalFetchRecordLazyQueryHookResult = ReturnType<typeof useInternalFetchRecordLazyQuery>;
export const InternalImportRecordsDocument = gql`
    mutation InternalImportRecords($input: InternalImportRecordsInput!) {
  internalImportRecords(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useInternalImportRecordsMutation__
 *
 * To run a mutation, you first call `useInternalImportRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalImportRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalImportRecordsMutation, { data, loading, error }] = useInternalImportRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalImportRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InternalImportRecordsMutation, InternalImportRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<InternalImportRecordsMutation, InternalImportRecordsMutationVariables>(InternalImportRecordsDocument, baseOptions);
      }
export type InternalImportRecordsMutationHookResult = ReturnType<typeof useInternalImportRecordsMutation>;
export const InternalSearchRecordsDocument = gql`
    query InternalSearchRecords($input: InternalSearchRecordsInput!) {
  internalSearchRecords(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useInternalSearchRecordsQuery__
 *
 * To run a query within a React component, call `useInternalSearchRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalSearchRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalSearchRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalSearchRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InternalSearchRecordsQuery, InternalSearchRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<InternalSearchRecordsQuery, InternalSearchRecordsQueryVariables>(InternalSearchRecordsDocument, baseOptions);
      }
export function useInternalSearchRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InternalSearchRecordsQuery, InternalSearchRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InternalSearchRecordsQuery, InternalSearchRecordsQueryVariables>(InternalSearchRecordsDocument, baseOptions);
        }
export type InternalSearchRecordsQueryHookResult = ReturnType<typeof useInternalSearchRecordsQuery>;
export type InternalSearchRecordsLazyQueryHookResult = ReturnType<typeof useInternalSearchRecordsLazyQuery>;
export const InternalSummarizeRecordsDocument = gql`
    query InternalSummarizeRecords($input: InternalSummarizeRecordsInput!) {
  internalSummarizeRecords(input: $input) {
    count
  }
}
    `;

/**
 * __useInternalSummarizeRecordsQuery__
 *
 * To run a query within a React component, call `useInternalSummarizeRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalSummarizeRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalSummarizeRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalSummarizeRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InternalSummarizeRecordsQuery, InternalSummarizeRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<InternalSummarizeRecordsQuery, InternalSummarizeRecordsQueryVariables>(InternalSummarizeRecordsDocument, baseOptions);
      }
export function useInternalSummarizeRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InternalSummarizeRecordsQuery, InternalSummarizeRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InternalSummarizeRecordsQuery, InternalSummarizeRecordsQueryVariables>(InternalSummarizeRecordsDocument, baseOptions);
        }
export type InternalSummarizeRecordsQueryHookResult = ReturnType<typeof useInternalSummarizeRecordsQuery>;
export type InternalSummarizeRecordsLazyQueryHookResult = ReturnType<typeof useInternalSummarizeRecordsLazyQuery>;
export const ItemCategoriesListDocument = gql`
    query ItemCategoriesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  itemCategoriesAggregate(filter: $filter) {
    count
  }
  itemCategoriesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ItemCategoryFragment
  }
}
    ${ItemCategoryFragmentFragmentDoc}`;

/**
 * __useItemCategoriesListQuery__
 *
 * To run a query within a React component, call `useItemCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategoriesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useItemCategoriesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ItemCategoriesListQuery, ItemCategoriesListQueryVariables>) {
        return ApolloReactHooks.useQuery<ItemCategoriesListQuery, ItemCategoriesListQueryVariables>(ItemCategoriesListDocument, baseOptions);
      }
export function useItemCategoriesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ItemCategoriesListQuery, ItemCategoriesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ItemCategoriesListQuery, ItemCategoriesListQueryVariables>(ItemCategoriesListDocument, baseOptions);
        }
export type ItemCategoriesListQueryHookResult = ReturnType<typeof useItemCategoriesListQuery>;
export type ItemCategoriesListLazyQueryHookResult = ReturnType<typeof useItemCategoriesListLazyQuery>;
export const ItemCategoryMembershipsListDocument = gql`
    query ItemCategoryMembershipsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  itemCategoryMembershipsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ItemCategoryMembershipFragment
  }
}
    ${ItemCategoryMembershipFragmentFragmentDoc}`;

/**
 * __useItemCategoryMembershipsListQuery__
 *
 * To run a query within a React component, call `useItemCategoryMembershipsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategoryMembershipsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategoryMembershipsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useItemCategoryMembershipsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ItemCategoryMembershipsListQuery, ItemCategoryMembershipsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ItemCategoryMembershipsListQuery, ItemCategoryMembershipsListQueryVariables>(ItemCategoryMembershipsListDocument, baseOptions);
      }
export function useItemCategoryMembershipsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ItemCategoryMembershipsListQuery, ItemCategoryMembershipsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ItemCategoryMembershipsListQuery, ItemCategoryMembershipsListQueryVariables>(ItemCategoryMembershipsListDocument, baseOptions);
        }
export type ItemCategoryMembershipsListQueryHookResult = ReturnType<typeof useItemCategoryMembershipsListQuery>;
export type ItemCategoryMembershipsListLazyQueryHookResult = ReturnType<typeof useItemCategoryMembershipsListLazyQuery>;
export const ItemsAggregateDocument = gql`
    query ItemsAggregate($filter: JSON) {
  itemsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useItemsAggregateQuery__
 *
 * To run a query within a React component, call `useItemsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ItemsAggregateQuery, ItemsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<ItemsAggregateQuery, ItemsAggregateQueryVariables>(ItemsAggregateDocument, baseOptions);
      }
export function useItemsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ItemsAggregateQuery, ItemsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ItemsAggregateQuery, ItemsAggregateQueryVariables>(ItemsAggregateDocument, baseOptions);
        }
export type ItemsAggregateQueryHookResult = ReturnType<typeof useItemsAggregateQuery>;
export type ItemsAggregateLazyQueryHookResult = ReturnType<typeof useItemsAggregateLazyQuery>;
export const ItemsListDocument = gql`
    query ItemsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  itemsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;

/**
 * __useItemsListQuery__
 *
 * To run a query within a React component, call `useItemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useItemsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ItemsListQuery, ItemsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ItemsListQuery, ItemsListQueryVariables>(ItemsListDocument, baseOptions);
      }
export function useItemsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ItemsListQuery, ItemsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ItemsListQuery, ItemsListQueryVariables>(ItemsListDocument, baseOptions);
        }
export type ItemsListQueryHookResult = ReturnType<typeof useItemsListQuery>;
export type ItemsListLazyQueryHookResult = ReturnType<typeof useItemsListLazyQuery>;
export const KeyPairsListDocument = gql`
    query KeyPairsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  keyPairsAggregate(filter: $filter) {
    count
  }
  keyPairsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    publicKey
    privateKey
    expiresAt
    createdAt
  }
}
    `;

/**
 * __useKeyPairsListQuery__
 *
 * To run a query within a React component, call `useKeyPairsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyPairsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyPairsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useKeyPairsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KeyPairsListQuery, KeyPairsListQueryVariables>) {
        return ApolloReactHooks.useQuery<KeyPairsListQuery, KeyPairsListQueryVariables>(KeyPairsListDocument, baseOptions);
      }
export function useKeyPairsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeyPairsListQuery, KeyPairsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KeyPairsListQuery, KeyPairsListQueryVariables>(KeyPairsListDocument, baseOptions);
        }
export type KeyPairsListQueryHookResult = ReturnType<typeof useKeyPairsListQuery>;
export type KeyPairsListLazyQueryHookResult = ReturnType<typeof useKeyPairsListLazyQuery>;
export const LocaleListDocument = gql`
    query LocaleList {
  locales {
    name
    identifier
  }
}
    `;

/**
 * __useLocaleListQuery__
 *
 * To run a query within a React component, call `useLocaleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocaleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocaleListQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocaleListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocaleListQuery, LocaleListQueryVariables>) {
        return ApolloReactHooks.useQuery<LocaleListQuery, LocaleListQueryVariables>(LocaleListDocument, baseOptions);
      }
export function useLocaleListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocaleListQuery, LocaleListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocaleListQuery, LocaleListQueryVariables>(LocaleListDocument, baseOptions);
        }
export type LocaleListQueryHookResult = ReturnType<typeof useLocaleListQuery>;
export type LocaleListLazyQueryHookResult = ReturnType<typeof useLocaleListLazyQuery>;
export const MenuElementsAggregateDocument = gql`
    query MenuElementsAggregate($filter: JSON) {
  menuElementsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useMenuElementsAggregateQuery__
 *
 * To run a query within a React component, call `useMenuElementsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuElementsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuElementsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMenuElementsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuElementsAggregateQuery, MenuElementsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuElementsAggregateQuery, MenuElementsAggregateQueryVariables>(MenuElementsAggregateDocument, baseOptions);
      }
export function useMenuElementsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuElementsAggregateQuery, MenuElementsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuElementsAggregateQuery, MenuElementsAggregateQueryVariables>(MenuElementsAggregateDocument, baseOptions);
        }
export type MenuElementsAggregateQueryHookResult = ReturnType<typeof useMenuElementsAggregateQuery>;
export type MenuElementsAggregateLazyQueryHookResult = ReturnType<typeof useMenuElementsAggregateLazyQuery>;
export const MenuElementsListDocument = gql`
    query MenuElementsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  menuElementsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...MenuElementFragment
  }
}
    ${MenuElementFragmentFragmentDoc}`;

/**
 * __useMenuElementsListQuery__
 *
 * To run a query within a React component, call `useMenuElementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuElementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuElementsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMenuElementsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuElementsListQuery, MenuElementsListQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuElementsListQuery, MenuElementsListQueryVariables>(MenuElementsListDocument, baseOptions);
      }
export function useMenuElementsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuElementsListQuery, MenuElementsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuElementsListQuery, MenuElementsListQueryVariables>(MenuElementsListDocument, baseOptions);
        }
export type MenuElementsListQueryHookResult = ReturnType<typeof useMenuElementsListQuery>;
export type MenuElementsListLazyQueryHookResult = ReturnType<typeof useMenuElementsListLazyQuery>;
export const NotificationLogsAggregateDocument = gql`
    query NotificationLogsAggregate($filter: JSON) {
  notificationLogsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useNotificationLogsAggregateQuery__
 *
 * To run a query within a React component, call `useNotificationLogsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationLogsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationLogsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNotificationLogsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationLogsAggregateQuery, NotificationLogsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationLogsAggregateQuery, NotificationLogsAggregateQueryVariables>(NotificationLogsAggregateDocument, baseOptions);
      }
export function useNotificationLogsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationLogsAggregateQuery, NotificationLogsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationLogsAggregateQuery, NotificationLogsAggregateQueryVariables>(NotificationLogsAggregateDocument, baseOptions);
        }
export type NotificationLogsAggregateQueryHookResult = ReturnType<typeof useNotificationLogsAggregateQuery>;
export type NotificationLogsAggregateLazyQueryHookResult = ReturnType<typeof useNotificationLogsAggregateLazyQuery>;
export const NotificationLogsListDocument = gql`
    query NotificationLogsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  notificationLogsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    notificationId
    integrationId
    category
    subcategory
    data
    timestamp
    createdAt
    updatedAt
    notification {
      ...NotificationFragment
    }
    integration {
      ...InstallationFragment
    }
  }
}
    ${NotificationFragmentFragmentDoc}
${InstallationFragmentFragmentDoc}`;

/**
 * __useNotificationLogsListQuery__
 *
 * To run a query within a React component, call `useNotificationLogsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationLogsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationLogsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useNotificationLogsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationLogsListQuery, NotificationLogsListQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationLogsListQuery, NotificationLogsListQueryVariables>(NotificationLogsListDocument, baseOptions);
      }
export function useNotificationLogsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationLogsListQuery, NotificationLogsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationLogsListQuery, NotificationLogsListQueryVariables>(NotificationLogsListDocument, baseOptions);
        }
export type NotificationLogsListQueryHookResult = ReturnType<typeof useNotificationLogsListQuery>;
export type NotificationLogsListLazyQueryHookResult = ReturnType<typeof useNotificationLogsListLazyQuery>;
export const NotificationRecipientsAggregateDocument = gql`
    query NotificationRecipientsAggregate($filter: JSON) {
  notificationRecipientsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useNotificationRecipientsAggregateQuery__
 *
 * To run a query within a React component, call `useNotificationRecipientsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationRecipientsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationRecipientsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNotificationRecipientsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationRecipientsAggregateQuery, NotificationRecipientsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationRecipientsAggregateQuery, NotificationRecipientsAggregateQueryVariables>(NotificationRecipientsAggregateDocument, baseOptions);
      }
export function useNotificationRecipientsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationRecipientsAggregateQuery, NotificationRecipientsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationRecipientsAggregateQuery, NotificationRecipientsAggregateQueryVariables>(NotificationRecipientsAggregateDocument, baseOptions);
        }
export type NotificationRecipientsAggregateQueryHookResult = ReturnType<typeof useNotificationRecipientsAggregateQuery>;
export type NotificationRecipientsAggregateLazyQueryHookResult = ReturnType<typeof useNotificationRecipientsAggregateLazyQuery>;
export const NotificationRecipientsListDocument = gql`
    query NotificationRecipientsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  notificationRecipientsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    notificationId
    recipientId
    contactId
    contact
    readAt
    externalUid
    createdAt
    updatedAt
    recipient {
      ...RecipientFragment
    }
  }
}
    ${RecipientFragmentFragmentDoc}`;

/**
 * __useNotificationRecipientsListQuery__
 *
 * To run a query within a React component, call `useNotificationRecipientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationRecipientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationRecipientsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useNotificationRecipientsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationRecipientsListQuery, NotificationRecipientsListQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationRecipientsListQuery, NotificationRecipientsListQueryVariables>(NotificationRecipientsListDocument, baseOptions);
      }
export function useNotificationRecipientsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationRecipientsListQuery, NotificationRecipientsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationRecipientsListQuery, NotificationRecipientsListQueryVariables>(NotificationRecipientsListDocument, baseOptions);
        }
export type NotificationRecipientsListQueryHookResult = ReturnType<typeof useNotificationRecipientsListQuery>;
export type NotificationRecipientsListLazyQueryHookResult = ReturnType<typeof useNotificationRecipientsListLazyQuery>;
export const NotificationsAggregateDocument = gql`
    query NotificationsAggregate($filter: JSON, $targetEnvironment: String) {
  notificationsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useNotificationsAggregateQuery__
 *
 * To run a query within a React component, call `useNotificationsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useNotificationsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsAggregateQuery, NotificationsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationsAggregateQuery, NotificationsAggregateQueryVariables>(NotificationsAggregateDocument, baseOptions);
      }
export function useNotificationsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsAggregateQuery, NotificationsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationsAggregateQuery, NotificationsAggregateQueryVariables>(NotificationsAggregateDocument, baseOptions);
        }
export type NotificationsAggregateQueryHookResult = ReturnType<typeof useNotificationsAggregateQuery>;
export type NotificationsAggregateLazyQueryHookResult = ReturnType<typeof useNotificationsAggregateLazyQuery>;
export const NotificationsListDocument = gql`
    query NotificationsList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  notificationsList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useNotificationsListQuery__
 *
 * To run a query within a React component, call `useNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useNotificationsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, baseOptions);
      }
export function useNotificationsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, baseOptions);
        }
export type NotificationsListQueryHookResult = ReturnType<typeof useNotificationsListQuery>;
export type NotificationsListLazyQueryHookResult = ReturnType<typeof useNotificationsListLazyQuery>;
export const OperationDocument = gql`
    query Operation($id: UUID!) {
  operation(id: $id) {
    ...OperationFragment
  }
}
    ${OperationFragmentFragmentDoc}`;

/**
 * __useOperationQuery__
 *
 * To run a query within a React component, call `useOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOperationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OperationQuery, OperationQueryVariables>) {
        return ApolloReactHooks.useQuery<OperationQuery, OperationQueryVariables>(OperationDocument, baseOptions);
      }
export function useOperationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OperationQuery, OperationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OperationQuery, OperationQueryVariables>(OperationDocument, baseOptions);
        }
export type OperationQueryHookResult = ReturnType<typeof useOperationQuery>;
export type OperationLazyQueryHookResult = ReturnType<typeof useOperationLazyQuery>;
export const OperationsAggregateDocument = gql`
    query OperationsAggregate($filter: JSON) {
  operationsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useOperationsAggregateQuery__
 *
 * To run a query within a React component, call `useOperationsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOperationsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OperationsAggregateQuery, OperationsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<OperationsAggregateQuery, OperationsAggregateQueryVariables>(OperationsAggregateDocument, baseOptions);
      }
export function useOperationsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OperationsAggregateQuery, OperationsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OperationsAggregateQuery, OperationsAggregateQueryVariables>(OperationsAggregateDocument, baseOptions);
        }
export type OperationsAggregateQueryHookResult = ReturnType<typeof useOperationsAggregateQuery>;
export type OperationsAggregateLazyQueryHookResult = ReturnType<typeof useOperationsAggregateLazyQuery>;
export const OperationsListDocument = gql`
    query OperationsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  operationsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...OperationListFragment
  }
}
    ${OperationListFragmentFragmentDoc}`;

/**
 * __useOperationsListQuery__
 *
 * To run a query within a React component, call `useOperationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOperationsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OperationsListQuery, OperationsListQueryVariables>) {
        return ApolloReactHooks.useQuery<OperationsListQuery, OperationsListQueryVariables>(OperationsListDocument, baseOptions);
      }
export function useOperationsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OperationsListQuery, OperationsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OperationsListQuery, OperationsListQueryVariables>(OperationsListDocument, baseOptions);
        }
export type OperationsListQueryHookResult = ReturnType<typeof useOperationsListQuery>;
export type OperationsListLazyQueryHookResult = ReturnType<typeof useOperationsListLazyQuery>;
export const OrderDocument = gql`
    query Order($id: UUID!) {
  order(id: $id) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
      }
export function useOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export const OrderItemsListDocument = gql`
    query OrderItemsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  orderItemsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...OrderItemFragment
  }
}
    ${OrderItemFragmentFragmentDoc}`;

/**
 * __useOrderItemsListQuery__
 *
 * To run a query within a React component, call `useOrderItemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrderItemsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderItemsListQuery, OrderItemsListQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderItemsListQuery, OrderItemsListQueryVariables>(OrderItemsListDocument, baseOptions);
      }
export function useOrderItemsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderItemsListQuery, OrderItemsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderItemsListQuery, OrderItemsListQueryVariables>(OrderItemsListDocument, baseOptions);
        }
export type OrderItemsListQueryHookResult = ReturnType<typeof useOrderItemsListQuery>;
export type OrderItemsListLazyQueryHookResult = ReturnType<typeof useOrderItemsListLazyQuery>;
export const OrdersAggregateByItemsDocument = gql`
    query OrdersAggregateByItems($input: OrdersAggregateByItemsInput!) {
  ordersAggregateByItems(input: $input) {
    count
  }
}
    `;

/**
 * __useOrdersAggregateByItemsQuery__
 *
 * To run a query within a React component, call `useOrdersAggregateByItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersAggregateByItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersAggregateByItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrdersAggregateByItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersAggregateByItemsQuery, OrdersAggregateByItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersAggregateByItemsQuery, OrdersAggregateByItemsQueryVariables>(OrdersAggregateByItemsDocument, baseOptions);
      }
export function useOrdersAggregateByItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersAggregateByItemsQuery, OrdersAggregateByItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersAggregateByItemsQuery, OrdersAggregateByItemsQueryVariables>(OrdersAggregateByItemsDocument, baseOptions);
        }
export type OrdersAggregateByItemsQueryHookResult = ReturnType<typeof useOrdersAggregateByItemsQuery>;
export type OrdersAggregateByItemsLazyQueryHookResult = ReturnType<typeof useOrdersAggregateByItemsLazyQuery>;
export const OrdersListDocument = gql`
    query OrdersList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  ordersAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
  ordersList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useOrdersListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, baseOptions);
      }
export function useOrdersListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, baseOptions);
        }
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<typeof useOrdersListLazyQuery>;
export const OrdersListByItemsDocument = gql`
    query OrdersListByItems($input: OrdersListByItemsInput!) {
  ordersListByItems(input: $input) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useOrdersListByItemsQuery__
 *
 * To run a query within a React component, call `useOrdersListByItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListByItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListByItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrdersListByItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersListByItemsQuery, OrdersListByItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersListByItemsQuery, OrdersListByItemsQueryVariables>(OrdersListByItemsDocument, baseOptions);
      }
export function useOrdersListByItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersListByItemsQuery, OrdersListByItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersListByItemsQuery, OrdersListByItemsQueryVariables>(OrdersListByItemsDocument, baseOptions);
        }
export type OrdersListByItemsQueryHookResult = ReturnType<typeof useOrdersListByItemsQuery>;
export type OrdersListByItemsLazyQueryHookResult = ReturnType<typeof useOrdersListByItemsLazyQuery>;
export const ParameterDocument = gql`
    query Parameter($id: UUID!) {
  parameter(id: $id) {
    ...ParameterFragment
  }
}
    ${ParameterFragmentFragmentDoc}`;

/**
 * __useParameterQuery__
 *
 * To run a query within a React component, call `useParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParameterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParameterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParameterQuery, ParameterQueryVariables>) {
        return ApolloReactHooks.useQuery<ParameterQuery, ParameterQueryVariables>(ParameterDocument, baseOptions);
      }
export function useParameterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParameterQuery, ParameterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParameterQuery, ParameterQueryVariables>(ParameterDocument, baseOptions);
        }
export type ParameterQueryHookResult = ReturnType<typeof useParameterQuery>;
export type ParameterLazyQueryHookResult = ReturnType<typeof useParameterLazyQuery>;
export const ParametersAggregateDocument = gql`
    query ParametersAggregate($filter: JSON) {
  parametersAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useParametersAggregateQuery__
 *
 * To run a query within a React component, call `useParametersAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useParametersAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParametersAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useParametersAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParametersAggregateQuery, ParametersAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<ParametersAggregateQuery, ParametersAggregateQueryVariables>(ParametersAggregateDocument, baseOptions);
      }
export function useParametersAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParametersAggregateQuery, ParametersAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParametersAggregateQuery, ParametersAggregateQueryVariables>(ParametersAggregateDocument, baseOptions);
        }
export type ParametersAggregateQueryHookResult = ReturnType<typeof useParametersAggregateQuery>;
export type ParametersAggregateLazyQueryHookResult = ReturnType<typeof useParametersAggregateLazyQuery>;
export const ParametersListDocument = gql`
    query ParametersList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  parametersList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ParameterListFragment
  }
}
    ${ParameterListFragmentFragmentDoc}`;

/**
 * __useParametersListQuery__
 *
 * To run a query within a React component, call `useParametersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useParametersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParametersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useParametersListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParametersListQuery, ParametersListQueryVariables>) {
        return ApolloReactHooks.useQuery<ParametersListQuery, ParametersListQueryVariables>(ParametersListDocument, baseOptions);
      }
export function useParametersListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParametersListQuery, ParametersListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParametersListQuery, ParametersListQueryVariables>(ParametersListDocument, baseOptions);
        }
export type ParametersListQueryHookResult = ReturnType<typeof useParametersListQuery>;
export type ParametersListLazyQueryHookResult = ReturnType<typeof useParametersListLazyQuery>;
export const ParseIdentityTokenDocument = gql`
    query ParseIdentityToken($input: ParseIdentityTokenInput!) {
  parseIdentityToken(input: $input) {
    uid
    kind
  }
}
    `;

/**
 * __useParseIdentityTokenQuery__
 *
 * To run a query within a React component, call `useParseIdentityTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseIdentityTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseIdentityTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParseIdentityTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParseIdentityTokenQuery, ParseIdentityTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<ParseIdentityTokenQuery, ParseIdentityTokenQueryVariables>(ParseIdentityTokenDocument, baseOptions);
      }
export function useParseIdentityTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParseIdentityTokenQuery, ParseIdentityTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParseIdentityTokenQuery, ParseIdentityTokenQueryVariables>(ParseIdentityTokenDocument, baseOptions);
        }
export type ParseIdentityTokenQueryHookResult = ReturnType<typeof useParseIdentityTokenQuery>;
export type ParseIdentityTokenLazyQueryHookResult = ReturnType<typeof useParseIdentityTokenLazyQuery>;
export const PauseFlowExecutionDocument = gql`
    mutation PauseFlowExecution($input: PauseFlowExecutionInput!) {
  pauseFlowExecution(input: $input) {
    ...FlowExecutionFragment
  }
}
    ${FlowExecutionFragmentFragmentDoc}`;

/**
 * __usePauseFlowExecutionMutation__
 *
 * To run a mutation, you first call `usePauseFlowExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseFlowExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseFlowExecutionMutation, { data, loading, error }] = usePauseFlowExecutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseFlowExecutionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PauseFlowExecutionMutation, PauseFlowExecutionMutationVariables>) {
        return ApolloReactHooks.useMutation<PauseFlowExecutionMutation, PauseFlowExecutionMutationVariables>(PauseFlowExecutionDocument, baseOptions);
      }
export type PauseFlowExecutionMutationHookResult = ReturnType<typeof usePauseFlowExecutionMutation>;
export const PreferencesAggregateDocument = gql`
    query PreferencesAggregate($filter: JSON) {
  preferencesAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __usePreferencesAggregateQuery__
 *
 * To run a query within a React component, call `usePreferencesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePreferencesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PreferencesAggregateQuery, PreferencesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<PreferencesAggregateQuery, PreferencesAggregateQueryVariables>(PreferencesAggregateDocument, baseOptions);
      }
export function usePreferencesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreferencesAggregateQuery, PreferencesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PreferencesAggregateQuery, PreferencesAggregateQueryVariables>(PreferencesAggregateDocument, baseOptions);
        }
export type PreferencesAggregateQueryHookResult = ReturnType<typeof usePreferencesAggregateQuery>;
export type PreferencesAggregateLazyQueryHookResult = ReturnType<typeof usePreferencesAggregateLazyQuery>;
export const PreferencesListDocument = gql`
    query PreferencesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  preferencesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...PreferenceFragment
  }
}
    ${PreferenceFragmentFragmentDoc}`;

/**
 * __usePreferencesListQuery__
 *
 * To run a query within a React component, call `usePreferencesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePreferencesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PreferencesListQuery, PreferencesListQueryVariables>) {
        return ApolloReactHooks.useQuery<PreferencesListQuery, PreferencesListQueryVariables>(PreferencesListDocument, baseOptions);
      }
export function usePreferencesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreferencesListQuery, PreferencesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PreferencesListQuery, PreferencesListQueryVariables>(PreferencesListDocument, baseOptions);
        }
export type PreferencesListQueryHookResult = ReturnType<typeof usePreferencesListQuery>;
export type PreferencesListLazyQueryHookResult = ReturnType<typeof usePreferencesListLazyQuery>;
export const PrepareAssetDocument = gql`
    mutation PrepareAsset($input: PrepareAssetInput!) {
  prepareAsset(input: $input) {
    ...AssetFragment
  }
}
    ${AssetFragmentFragmentDoc}`;

/**
 * __usePrepareAssetMutation__
 *
 * To run a mutation, you first call `usePrepareAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareAssetMutation, { data, loading, error }] = usePrepareAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareAssetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareAssetMutation, PrepareAssetMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareAssetMutation, PrepareAssetMutationVariables>(PrepareAssetDocument, baseOptions);
      }
export type PrepareAssetMutationHookResult = ReturnType<typeof usePrepareAssetMutation>;
export const PrepareWorkspacePaymentMethodDocument = gql`
    mutation PrepareWorkspacePaymentMethod {
  prepareWorkspacePaymentMethod {
    token
  }
}
    `;

/**
 * __usePrepareWorkspacePaymentMethodMutation__
 *
 * To run a mutation, you first call `usePrepareWorkspacePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareWorkspacePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareWorkspacePaymentMethodMutation, { data, loading, error }] = usePrepareWorkspacePaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function usePrepareWorkspacePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareWorkspacePaymentMethodMutation, PrepareWorkspacePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareWorkspacePaymentMethodMutation, PrepareWorkspacePaymentMethodMutationVariables>(PrepareWorkspacePaymentMethodDocument, baseOptions);
      }
export type PrepareWorkspacePaymentMethodMutationHookResult = ReturnType<typeof usePrepareWorkspacePaymentMethodMutation>;
export const PricingsListDocument = gql`
    query PricingsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  pricingsAggregate(filter: $filter) {
    count
  }
  pricingsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...PricingFragment
  }
}
    ${PricingFragmentFragmentDoc}`;

/**
 * __usePricingsListQuery__
 *
 * To run a query within a React component, call `usePricingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePricingsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PricingsListQuery, PricingsListQueryVariables>) {
        return ApolloReactHooks.useQuery<PricingsListQuery, PricingsListQueryVariables>(PricingsListDocument, baseOptions);
      }
export function usePricingsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PricingsListQuery, PricingsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PricingsListQuery, PricingsListQueryVariables>(PricingsListDocument, baseOptions);
        }
export type PricingsListQueryHookResult = ReturnType<typeof usePricingsListQuery>;
export type PricingsListLazyQueryHookResult = ReturnType<typeof usePricingsListLazyQuery>;
export const ProspectWorkspacesListDocument = gql`
    query ProspectWorkspacesList {
  prospectWorkspaces {
    id
    name
    identifier
    accessUrl
    hostname
    logoSymbol
  }
}
    `;

/**
 * __useProspectWorkspacesListQuery__
 *
 * To run a query within a React component, call `useProspectWorkspacesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectWorkspacesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectWorkspacesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useProspectWorkspacesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectWorkspacesListQuery, ProspectWorkspacesListQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectWorkspacesListQuery, ProspectWorkspacesListQueryVariables>(ProspectWorkspacesListDocument, baseOptions);
      }
export function useProspectWorkspacesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectWorkspacesListQuery, ProspectWorkspacesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectWorkspacesListQuery, ProspectWorkspacesListQueryVariables>(ProspectWorkspacesListDocument, baseOptions);
        }
export type ProspectWorkspacesListQueryHookResult = ReturnType<typeof useProspectWorkspacesListQuery>;
export type ProspectWorkspacesListLazyQueryHookResult = ReturnType<typeof useProspectWorkspacesListLazyQuery>;
export const PublishFlowVersionDocument = gql`
    mutation PublishFlowVersion($input: PublishFlowVersionInput!) {
  publishFlowVersion(input: $input) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __usePublishFlowVersionMutation__
 *
 * To run a mutation, you first call `usePublishFlowVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishFlowVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishFlowVersionMutation, { data, loading, error }] = usePublishFlowVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishFlowVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishFlowVersionMutation, PublishFlowVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishFlowVersionMutation, PublishFlowVersionMutationVariables>(PublishFlowVersionDocument, baseOptions);
      }
export type PublishFlowVersionMutationHookResult = ReturnType<typeof usePublishFlowVersionMutation>;
export const PublishRecordVersionDocument = gql`
    mutation publishRecordVersion($input: PublishRecordVersionInput!) {
  publishRecordVersion(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __usePublishRecordVersionMutation__
 *
 * To run a mutation, you first call `usePublishRecordVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishRecordVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishRecordVersionMutation, { data, loading, error }] = usePublishRecordVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishRecordVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishRecordVersionMutation, PublishRecordVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishRecordVersionMutation, PublishRecordVersionMutationVariables>(PublishRecordVersionDocument, baseOptions);
      }
export type PublishRecordVersionMutationHookResult = ReturnType<typeof usePublishRecordVersionMutation>;
export const PublishTemplateVersionDocument = gql`
    mutation PublishTemplateVersion($input: PublishTemplateVersionInput!) {
  publishTemplateVersion(input: $input) {
    id
    identifier
    createdAt
    updatedAt
    publishedVersionId
  }
}
    `;

/**
 * __usePublishTemplateVersionMutation__
 *
 * To run a mutation, you first call `usePublishTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTemplateVersionMutation, { data, loading, error }] = usePublishTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishTemplateVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishTemplateVersionMutation, PublishTemplateVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishTemplateVersionMutation, PublishTemplateVersionMutationVariables>(PublishTemplateVersionDocument, baseOptions);
      }
export type PublishTemplateVersionMutationHookResult = ReturnType<typeof usePublishTemplateVersionMutation>;
export const RecipientsAggregateDocument = gql`
    query RecipientsAggregate($filter: JSON) {
  recipientsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useRecipientsAggregateQuery__
 *
 * To run a query within a React component, call `useRecipientsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRecipientsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipientsAggregateQuery, RecipientsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipientsAggregateQuery, RecipientsAggregateQueryVariables>(RecipientsAggregateDocument, baseOptions);
      }
export function useRecipientsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipientsAggregateQuery, RecipientsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipientsAggregateQuery, RecipientsAggregateQueryVariables>(RecipientsAggregateDocument, baseOptions);
        }
export type RecipientsAggregateQueryHookResult = ReturnType<typeof useRecipientsAggregateQuery>;
export type RecipientsAggregateLazyQueryHookResult = ReturnType<typeof useRecipientsAggregateLazyQuery>;
export const RecipientsListDocument = gql`
    query RecipientsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  recipientsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...RecipientFragment
  }
}
    ${RecipientFragmentFragmentDoc}`;

/**
 * __useRecipientsListQuery__
 *
 * To run a query within a React component, call `useRecipientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRecipientsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipientsListQuery, RecipientsListQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipientsListQuery, RecipientsListQueryVariables>(RecipientsListDocument, baseOptions);
      }
export function useRecipientsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipientsListQuery, RecipientsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipientsListQuery, RecipientsListQueryVariables>(RecipientsListDocument, baseOptions);
        }
export type RecipientsListQueryHookResult = ReturnType<typeof useRecipientsListQuery>;
export type RecipientsListLazyQueryHookResult = ReturnType<typeof useRecipientsListLazyQuery>;
export const RecordVersionsListDocument = gql`
    query RecordVersionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  recordVersionsAggregate(filter: $filter) {
    count
  }
  recordVersionsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...RecordVersionFragment
  }
}
    ${RecordVersionFragmentFragmentDoc}`;

/**
 * __useRecordVersionsListQuery__
 *
 * To run a query within a React component, call `useRecordVersionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordVersionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordVersionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRecordVersionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecordVersionsListQuery, RecordVersionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<RecordVersionsListQuery, RecordVersionsListQueryVariables>(RecordVersionsListDocument, baseOptions);
      }
export function useRecordVersionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecordVersionsListQuery, RecordVersionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecordVersionsListQuery, RecordVersionsListQueryVariables>(RecordVersionsListDocument, baseOptions);
        }
export type RecordVersionsListQueryHookResult = ReturnType<typeof useRecordVersionsListQuery>;
export type RecordVersionsListLazyQueryHookResult = ReturnType<typeof useRecordVersionsListLazyQuery>;
export const RecordsAggregateDocument = gql`
    query RecordsAggregate($filter: JSON) {
  recordsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useRecordsAggregateQuery__
 *
 * To run a query within a React component, call `useRecordsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRecordsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecordsAggregateQuery, RecordsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<RecordsAggregateQuery, RecordsAggregateQueryVariables>(RecordsAggregateDocument, baseOptions);
      }
export function useRecordsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecordsAggregateQuery, RecordsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecordsAggregateQuery, RecordsAggregateQueryVariables>(RecordsAggregateDocument, baseOptions);
        }
export type RecordsAggregateQueryHookResult = ReturnType<typeof useRecordsAggregateQuery>;
export type RecordsAggregateLazyQueryHookResult = ReturnType<typeof useRecordsAggregateLazyQuery>;
export const RecordsListDocument = gql`
    query RecordsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  recordsAggregate(filter: $filter) {
    count
  }
  recordsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useRecordsListQuery__
 *
 * To run a query within a React component, call `useRecordsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRecordsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecordsListQuery, RecordsListQueryVariables>) {
        return ApolloReactHooks.useQuery<RecordsListQuery, RecordsListQueryVariables>(RecordsListDocument, baseOptions);
      }
export function useRecordsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecordsListQuery, RecordsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecordsListQuery, RecordsListQueryVariables>(RecordsListDocument, baseOptions);
        }
export type RecordsListQueryHookResult = ReturnType<typeof useRecordsListQuery>;
export type RecordsListLazyQueryHookResult = ReturnType<typeof useRecordsListLazyQuery>;
export const RegisterWorkspaceDocument = gql`
    mutation RegisterWorkspace($input: RegisterWorkspaceInput!) {
  registerWorkspace(input: $input) {
    invitationUrl
  }
}
    `;

/**
 * __useRegisterWorkspaceMutation__
 *
 * To run a mutation, you first call `useRegisterWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWorkspaceMutation, { data, loading, error }] = useRegisterWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterWorkspaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterWorkspaceMutation, RegisterWorkspaceMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterWorkspaceMutation, RegisterWorkspaceMutationVariables>(RegisterWorkspaceDocument, baseOptions);
      }
export type RegisterWorkspaceMutationHookResult = ReturnType<typeof useRegisterWorkspaceMutation>;
export const RelationshipDocument = gql`
    query Relationship($id: UUID!) {
  relationship(id: $id) {
    ...RelationshipFragment
  }
}
    ${RelationshipFragmentFragmentDoc}`;

/**
 * __useRelationshipQuery__
 *
 * To run a query within a React component, call `useRelationshipQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelationshipQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RelationshipQuery, RelationshipQueryVariables>) {
        return ApolloReactHooks.useQuery<RelationshipQuery, RelationshipQueryVariables>(RelationshipDocument, baseOptions);
      }
export function useRelationshipLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RelationshipQuery, RelationshipQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RelationshipQuery, RelationshipQueryVariables>(RelationshipDocument, baseOptions);
        }
export type RelationshipQueryHookResult = ReturnType<typeof useRelationshipQuery>;
export type RelationshipLazyQueryHookResult = ReturnType<typeof useRelationshipLazyQuery>;
export const RelationshipsAggregateDocument = gql`
    query RelationshipsAggregate($filter: JSON) {
  relationshipsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useRelationshipsAggregateQuery__
 *
 * To run a query within a React component, call `useRelationshipsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRelationshipsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RelationshipsAggregateQuery, RelationshipsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<RelationshipsAggregateQuery, RelationshipsAggregateQueryVariables>(RelationshipsAggregateDocument, baseOptions);
      }
export function useRelationshipsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RelationshipsAggregateQuery, RelationshipsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RelationshipsAggregateQuery, RelationshipsAggregateQueryVariables>(RelationshipsAggregateDocument, baseOptions);
        }
export type RelationshipsAggregateQueryHookResult = ReturnType<typeof useRelationshipsAggregateQuery>;
export type RelationshipsAggregateLazyQueryHookResult = ReturnType<typeof useRelationshipsAggregateLazyQuery>;
export const RelationshipsListDocument = gql`
    query RelationshipsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  relationshipsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...RelationshipFragment
  }
}
    ${RelationshipFragmentFragmentDoc}`;

/**
 * __useRelationshipsListQuery__
 *
 * To run a query within a React component, call `useRelationshipsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRelationshipsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RelationshipsListQuery, RelationshipsListQueryVariables>) {
        return ApolloReactHooks.useQuery<RelationshipsListQuery, RelationshipsListQueryVariables>(RelationshipsListDocument, baseOptions);
      }
export function useRelationshipsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RelationshipsListQuery, RelationshipsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RelationshipsListQuery, RelationshipsListQueryVariables>(RelationshipsListDocument, baseOptions);
        }
export type RelationshipsListQueryHookResult = ReturnType<typeof useRelationshipsListQuery>;
export type RelationshipsListLazyQueryHookResult = ReturnType<typeof useRelationshipsListLazyQuery>;
export const RemoveWorkspaceLocaleDocument = gql`
    mutation RemoveWorkspaceLocale($input: RemoveWorkspaceLocaleInput!) {
  removeWorkspaceLocale(input: $input) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useRemoveWorkspaceLocaleMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceLocaleMutation, { data, loading, error }] = useRemoveWorkspaceLocaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWorkspaceLocaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWorkspaceLocaleMutation, RemoveWorkspaceLocaleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveWorkspaceLocaleMutation, RemoveWorkspaceLocaleMutationVariables>(RemoveWorkspaceLocaleDocument, baseOptions);
      }
export type RemoveWorkspaceLocaleMutationHookResult = ReturnType<typeof useRemoveWorkspaceLocaleMutation>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    id
    token
  }
}
    `;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export const ResolveViewDocument = gql`
    query ResolveView($input: ResolveViewInput!) {
  resolveView(input: $input) {
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;

/**
 * __useResolveViewQuery__
 *
 * To run a query within a React component, call `useResolveViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveViewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResolveViewQuery, ResolveViewQueryVariables>) {
        return ApolloReactHooks.useQuery<ResolveViewQuery, ResolveViewQueryVariables>(ResolveViewDocument, baseOptions);
      }
export function useResolveViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResolveViewQuery, ResolveViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResolveViewQuery, ResolveViewQueryVariables>(ResolveViewDocument, baseOptions);
        }
export type ResolveViewQueryHookResult = ReturnType<typeof useResolveViewQuery>;
export type ResolveViewLazyQueryHookResult = ReturnType<typeof useResolveViewLazyQuery>;
export const ResourceDocument = gql`
    query Resource($id: UUID!) {
  resource(id: $id) {
    ...ResourceFragment
  }
}
    ${ResourceFragmentFragmentDoc}`;

/**
 * __useResourceQuery__
 *
 * To run a query within a React component, call `useResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourceQuery, ResourceQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, baseOptions);
      }
export function useResourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourceQuery, ResourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, baseOptions);
        }
export type ResourceQueryHookResult = ReturnType<typeof useResourceQuery>;
export type ResourceLazyQueryHookResult = ReturnType<typeof useResourceLazyQuery>;
export const ResourcesAggregateDocument = gql`
    query ResourcesAggregate($filter: JSON) {
  resourcesAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useResourcesAggregateQuery__
 *
 * To run a query within a React component, call `useResourcesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useResourcesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourcesAggregateQuery, ResourcesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourcesAggregateQuery, ResourcesAggregateQueryVariables>(ResourcesAggregateDocument, baseOptions);
      }
export function useResourcesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourcesAggregateQuery, ResourcesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourcesAggregateQuery, ResourcesAggregateQueryVariables>(ResourcesAggregateDocument, baseOptions);
        }
export type ResourcesAggregateQueryHookResult = ReturnType<typeof useResourcesAggregateQuery>;
export type ResourcesAggregateLazyQueryHookResult = ReturnType<typeof useResourcesAggregateLazyQuery>;
export const ResourcesListDocument = gql`
    query ResourcesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  resourcesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ResourceFragment
  }
}
    ${ResourceFragmentFragmentDoc}`;

/**
 * __useResourcesListQuery__
 *
 * To run a query within a React component, call `useResourcesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useResourcesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResourcesListQuery, ResourcesListQueryVariables>) {
        return ApolloReactHooks.useQuery<ResourcesListQuery, ResourcesListQueryVariables>(ResourcesListDocument, baseOptions);
      }
export function useResourcesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResourcesListQuery, ResourcesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResourcesListQuery, ResourcesListQueryVariables>(ResourcesListDocument, baseOptions);
        }
export type ResourcesListQueryHookResult = ReturnType<typeof useResourcesListQuery>;
export type ResourcesListLazyQueryHookResult = ReturnType<typeof useResourcesListLazyQuery>;
export const RestoreRecordVersionDocument = gql`
    mutation RestoreRecordVersion($input: RestoreRecordVersionInput!) {
  restoreRecordVersion(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useRestoreRecordVersionMutation__
 *
 * To run a mutation, you first call `useRestoreRecordVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreRecordVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreRecordVersionMutation, { data, loading, error }] = useRestoreRecordVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreRecordVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreRecordVersionMutation, RestoreRecordVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreRecordVersionMutation, RestoreRecordVersionMutationVariables>(RestoreRecordVersionDocument, baseOptions);
      }
export type RestoreRecordVersionMutationHookResult = ReturnType<typeof useRestoreRecordVersionMutation>;
export const RestoreTemplateVersionDocument = gql`
    mutation RestoreTemplateVersion($input: RestoreTemplateVersionInput!) {
  restoreTemplateVersion(input: $input) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;

/**
 * __useRestoreTemplateVersionMutation__
 *
 * To run a mutation, you first call `useRestoreTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTemplateVersionMutation, { data, loading, error }] = useRestoreTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreTemplateVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestoreTemplateVersionMutation, RestoreTemplateVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<RestoreTemplateVersionMutation, RestoreTemplateVersionMutationVariables>(RestoreTemplateVersionDocument, baseOptions);
      }
export type RestoreTemplateVersionMutationHookResult = ReturnType<typeof useRestoreTemplateVersionMutation>;
export const ResumeFlowExecutionDocument = gql`
    mutation ResumeFlowExecution($input: ResumeFlowExecutionInput!) {
  resumeFlowExecution(input: $input) {
    ...FlowExecutionFragment
  }
}
    ${FlowExecutionFragmentFragmentDoc}`;

/**
 * __useResumeFlowExecutionMutation__
 *
 * To run a mutation, you first call `useResumeFlowExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeFlowExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeFlowExecutionMutation, { data, loading, error }] = useResumeFlowExecutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeFlowExecutionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeFlowExecutionMutation, ResumeFlowExecutionMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeFlowExecutionMutation, ResumeFlowExecutionMutationVariables>(ResumeFlowExecutionDocument, baseOptions);
      }
export type ResumeFlowExecutionMutationHookResult = ReturnType<typeof useResumeFlowExecutionMutation>;
export const RetrieveMfaBackupCodesDocument = gql`
    query RetrieveMfaBackupCodes($input: RetrieveMfaBackupCodesInput!) {
  retrieveMfaBackupCodes(input: $input) {
    mfaBackupCodes
  }
}
    `;

/**
 * __useRetrieveMfaBackupCodesQuery__
 *
 * To run a query within a React component, call `useRetrieveMfaBackupCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveMfaBackupCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveMfaBackupCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetrieveMfaBackupCodesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetrieveMfaBackupCodesQuery, RetrieveMfaBackupCodesQueryVariables>) {
        return ApolloReactHooks.useQuery<RetrieveMfaBackupCodesQuery, RetrieveMfaBackupCodesQueryVariables>(RetrieveMfaBackupCodesDocument, baseOptions);
      }
export function useRetrieveMfaBackupCodesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetrieveMfaBackupCodesQuery, RetrieveMfaBackupCodesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetrieveMfaBackupCodesQuery, RetrieveMfaBackupCodesQueryVariables>(RetrieveMfaBackupCodesDocument, baseOptions);
        }
export type RetrieveMfaBackupCodesQueryHookResult = ReturnType<typeof useRetrieveMfaBackupCodesQuery>;
export type RetrieveMfaBackupCodesLazyQueryHookResult = ReturnType<typeof useRetrieveMfaBackupCodesLazyQuery>;
export const RevokeKeyPairDocument = gql`
    mutation RevokeKeyPair($input: RevokeKeyPairInput!) {
  revokeKeyPair(input: $input) {
    id
    expiresAt
  }
}
    `;

/**
 * __useRevokeKeyPairMutation__
 *
 * To run a mutation, you first call `useRevokeKeyPairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeKeyPairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeKeyPairMutation, { data, loading, error }] = useRevokeKeyPairMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeKeyPairMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeKeyPairMutation, RevokeKeyPairMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeKeyPairMutation, RevokeKeyPairMutationVariables>(RevokeKeyPairDocument, baseOptions);
      }
export type RevokeKeyPairMutationHookResult = ReturnType<typeof useRevokeKeyPairMutation>;
export const RevokeOtherSessionsDocument = gql`
    mutation RevokeOtherSessions {
  revokeOtherSessions {
    success
  }
}
    `;

/**
 * __useRevokeOtherSessionsMutation__
 *
 * To run a mutation, you first call `useRevokeOtherSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeOtherSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeOtherSessionsMutation, { data, loading, error }] = useRevokeOtherSessionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeOtherSessionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeOtherSessionsMutation, RevokeOtherSessionsMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeOtherSessionsMutation, RevokeOtherSessionsMutationVariables>(RevokeOtherSessionsDocument, baseOptions);
      }
export type RevokeOtherSessionsMutationHookResult = ReturnType<typeof useRevokeOtherSessionsMutation>;
export const RevokeSessionDocument = gql`
    mutation RevokeSession($input: RevokeSessionInput!) {
  revokeSession(input: $input) {
    id
  }
}
    `;

/**
 * __useRevokeSessionMutation__
 *
 * To run a mutation, you first call `useRevokeSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSessionMutation, { data, loading, error }] = useRevokeSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeSessionMutation, RevokeSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeSessionMutation, RevokeSessionMutationVariables>(RevokeSessionDocument, baseOptions);
      }
export type RevokeSessionMutationHookResult = ReturnType<typeof useRevokeSessionMutation>;
export const RoleDocument = gql`
    query Role($id: UUID!) {
  role(id: $id) {
    ...RoleFragment
  }
}
    ${RoleFragmentFragmentDoc}`;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, baseOptions);
      }
export function useRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, baseOptions);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export const RoleMembershipsListDocument = gql`
    query RoleMembershipsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  roleMembershipsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...RoleMembershipFragment
  }
  roleMembershipsAggregate(filter: $filter) {
    count
  }
}
    ${RoleMembershipFragmentFragmentDoc}`;

/**
 * __useRoleMembershipsListQuery__
 *
 * To run a query within a React component, call `useRoleMembershipsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleMembershipsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleMembershipsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRoleMembershipsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleMembershipsListQuery, RoleMembershipsListQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleMembershipsListQuery, RoleMembershipsListQueryVariables>(RoleMembershipsListDocument, baseOptions);
      }
export function useRoleMembershipsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleMembershipsListQuery, RoleMembershipsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleMembershipsListQuery, RoleMembershipsListQueryVariables>(RoleMembershipsListDocument, baseOptions);
        }
export type RoleMembershipsListQueryHookResult = ReturnType<typeof useRoleMembershipsListQuery>;
export type RoleMembershipsListLazyQueryHookResult = ReturnType<typeof useRoleMembershipsListLazyQuery>;
export const RolesListDocument = gql`
    query RolesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  rolesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...RoleFragment
  }
  rolesAggregate(filter: $filter) {
    count
  }
}
    ${RoleFragmentFragmentDoc}`;

/**
 * __useRolesListQuery__
 *
 * To run a query within a React component, call `useRolesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useRolesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesListQuery, RolesListQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesListQuery, RolesListQueryVariables>(RolesListDocument, baseOptions);
      }
export function useRolesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesListQuery, RolesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesListQuery, RolesListQueryVariables>(RolesListDocument, baseOptions);
        }
export type RolesListQueryHookResult = ReturnType<typeof useRolesListQuery>;
export type RolesListLazyQueryHookResult = ReturnType<typeof useRolesListLazyQuery>;
export const SaveWorkspaceBillingAccountDocument = gql`
    mutation SaveWorkspaceBillingAccount($input: SaveWorkspaceBillingAccountInput!) {
  saveWorkspaceBillingAccount(input: $input) {
    billingAccount {
      ...WorkspaceBillingAccountFragment
    }
  }
}
    ${WorkspaceBillingAccountFragmentFragmentDoc}`;

/**
 * __useSaveWorkspaceBillingAccountMutation__
 *
 * To run a mutation, you first call `useSaveWorkspaceBillingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkspaceBillingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkspaceBillingAccountMutation, { data, loading, error }] = useSaveWorkspaceBillingAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWorkspaceBillingAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveWorkspaceBillingAccountMutation, SaveWorkspaceBillingAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveWorkspaceBillingAccountMutation, SaveWorkspaceBillingAccountMutationVariables>(SaveWorkspaceBillingAccountDocument, baseOptions);
      }
export type SaveWorkspaceBillingAccountMutationHookResult = ReturnType<typeof useSaveWorkspaceBillingAccountMutation>;
export const SaveWorkspaceLocaleDocument = gql`
    mutation SaveWorkspaceLocale($input: SaveWorkspaceLocaleInput!) {
  saveWorkspaceLocale(input: $input) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useSaveWorkspaceLocaleMutation__
 *
 * To run a mutation, you first call `useSaveWorkspaceLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkspaceLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkspaceLocaleMutation, { data, loading, error }] = useSaveWorkspaceLocaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWorkspaceLocaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveWorkspaceLocaleMutation, SaveWorkspaceLocaleMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveWorkspaceLocaleMutation, SaveWorkspaceLocaleMutationVariables>(SaveWorkspaceLocaleDocument, baseOptions);
      }
export type SaveWorkspaceLocaleMutationHookResult = ReturnType<typeof useSaveWorkspaceLocaleMutation>;
export const SearchDatabaseRecordsDocument = gql`
    query SearchDatabaseRecords($input: SearchDatabaseRecordsInput!) {
  searchDatabaseRecords(input: $input)
}
    `;

/**
 * __useSearchDatabaseRecordsQuery__
 *
 * To run a query within a React component, call `useSearchDatabaseRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDatabaseRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDatabaseRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchDatabaseRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchDatabaseRecordsQuery, SearchDatabaseRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchDatabaseRecordsQuery, SearchDatabaseRecordsQueryVariables>(SearchDatabaseRecordsDocument, baseOptions);
      }
export function useSearchDatabaseRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchDatabaseRecordsQuery, SearchDatabaseRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchDatabaseRecordsQuery, SearchDatabaseRecordsQueryVariables>(SearchDatabaseRecordsDocument, baseOptions);
        }
export type SearchDatabaseRecordsQueryHookResult = ReturnType<typeof useSearchDatabaseRecordsQuery>;
export type SearchDatabaseRecordsLazyQueryHookResult = ReturnType<typeof useSearchDatabaseRecordsLazyQuery>;
export const SearchOperationLogsDocument = gql`
    query SearchOperationLogs($input: SearchOperationLogsInput!) {
  searchOperationLogs(input: $input) {
    id
    accountId
    keyPairId
    operationId
    operation {
      ...OperationFragment
    }
    operationInstanceId
    status
    statusReason
    createdAt
    updatedAt
    account {
      ...AccountFragment
    }
  }
}
    ${OperationFragmentFragmentDoc}
${AccountFragmentFragmentDoc}`;

/**
 * __useSearchOperationLogsQuery__
 *
 * To run a query within a React component, call `useSearchOperationLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOperationLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOperationLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchOperationLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOperationLogsQuery, SearchOperationLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchOperationLogsQuery, SearchOperationLogsQueryVariables>(SearchOperationLogsDocument, baseOptions);
      }
export function useSearchOperationLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOperationLogsQuery, SearchOperationLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchOperationLogsQuery, SearchOperationLogsQueryVariables>(SearchOperationLogsDocument, baseOptions);
        }
export type SearchOperationLogsQueryHookResult = ReturnType<typeof useSearchOperationLogsQuery>;
export type SearchOperationLogsLazyQueryHookResult = ReturnType<typeof useSearchOperationLogsLazyQuery>;
export const SegmentDocument = gql`
    query Segment($id: UUID!) {
  segment(id: $id) {
    ...SegmentFragment
  }
}
    ${SegmentFragmentFragmentDoc}`;

/**
 * __useSegmentQuery__
 *
 * To run a query within a React component, call `useSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSegmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SegmentQuery, SegmentQueryVariables>) {
        return ApolloReactHooks.useQuery<SegmentQuery, SegmentQueryVariables>(SegmentDocument, baseOptions);
      }
export function useSegmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SegmentQuery, SegmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SegmentQuery, SegmentQueryVariables>(SegmentDocument, baseOptions);
        }
export type SegmentQueryHookResult = ReturnType<typeof useSegmentQuery>;
export type SegmentLazyQueryHookResult = ReturnType<typeof useSegmentLazyQuery>;
export const SegmentsAggregateDocument = gql`
    query SegmentsAggregate($filter: JSON) {
  segmentsAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useSegmentsAggregateQuery__
 *
 * To run a query within a React component, call `useSegmentsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSegmentsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SegmentsAggregateQuery, SegmentsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<SegmentsAggregateQuery, SegmentsAggregateQueryVariables>(SegmentsAggregateDocument, baseOptions);
      }
export function useSegmentsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SegmentsAggregateQuery, SegmentsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SegmentsAggregateQuery, SegmentsAggregateQueryVariables>(SegmentsAggregateDocument, baseOptions);
        }
export type SegmentsAggregateQueryHookResult = ReturnType<typeof useSegmentsAggregateQuery>;
export type SegmentsAggregateLazyQueryHookResult = ReturnType<typeof useSegmentsAggregateLazyQuery>;
export const SegmentsListDocument = gql`
    query SegmentsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  segmentsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...SegmentFragment
  }
}
    ${SegmentFragmentFragmentDoc}`;

/**
 * __useSegmentsListQuery__
 *
 * To run a query within a React component, call `useSegmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSegmentsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SegmentsListQuery, SegmentsListQueryVariables>) {
        return ApolloReactHooks.useQuery<SegmentsListQuery, SegmentsListQueryVariables>(SegmentsListDocument, baseOptions);
      }
export function useSegmentsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SegmentsListQuery, SegmentsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SegmentsListQuery, SegmentsListQueryVariables>(SegmentsListDocument, baseOptions);
        }
export type SegmentsListQueryHookResult = ReturnType<typeof useSegmentsListQuery>;
export type SegmentsListLazyQueryHookResult = ReturnType<typeof useSegmentsListLazyQuery>;
export const SessionsListDocument = gql`
    query SessionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  sessionsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    lastActiveAt
    expiresAt
  }
}
    `;

/**
 * __useSessionsListQuery__
 *
 * To run a query within a React component, call `useSessionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSessionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SessionsListQuery, SessionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<SessionsListQuery, SessionsListQueryVariables>(SessionsListDocument, baseOptions);
      }
export function useSessionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SessionsListQuery, SessionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SessionsListQuery, SessionsListQueryVariables>(SessionsListDocument, baseOptions);
        }
export type SessionsListQueryHookResult = ReturnType<typeof useSessionsListQuery>;
export type SessionsListLazyQueryHookResult = ReturnType<typeof useSessionsListLazyQuery>;
export const SetWorkspacePaymentMethodAsDefaultDocument = gql`
    mutation SetWorkspacePaymentMethodAsDefault($input: SetWorkspacePaymentMethodAsDefaultInput!) {
  setWorkspacePaymentMethodAsDefault(input: $input) {
    ...PaymentMethodFragment
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;

/**
 * __useSetWorkspacePaymentMethodAsDefaultMutation__
 *
 * To run a mutation, you first call `useSetWorkspacePaymentMethodAsDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspacePaymentMethodAsDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspacePaymentMethodAsDefaultMutation, { data, loading, error }] = useSetWorkspacePaymentMethodAsDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWorkspacePaymentMethodAsDefaultMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetWorkspacePaymentMethodAsDefaultMutation, SetWorkspacePaymentMethodAsDefaultMutationVariables>) {
        return ApolloReactHooks.useMutation<SetWorkspacePaymentMethodAsDefaultMutation, SetWorkspacePaymentMethodAsDefaultMutationVariables>(SetWorkspacePaymentMethodAsDefaultDocument, baseOptions);
      }
export type SetWorkspacePaymentMethodAsDefaultMutationHookResult = ReturnType<typeof useSetWorkspacePaymentMethodAsDefaultMutation>;
export const StartDeliveryDocument = gql`
    mutation StartDelivery($input: StartDeliveryInput!) {
  startDelivery(input: $input) {
    ...DeliveryFragment
  }
}
    ${DeliveryFragmentFragmentDoc}`;

/**
 * __useStartDeliveryMutation__
 *
 * To run a mutation, you first call `useStartDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDeliveryMutation, { data, loading, error }] = useStartDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartDeliveryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartDeliveryMutation, StartDeliveryMutationVariables>) {
        return ApolloReactHooks.useMutation<StartDeliveryMutation, StartDeliveryMutationVariables>(StartDeliveryDocument, baseOptions);
      }
export type StartDeliveryMutationHookResult = ReturnType<typeof useStartDeliveryMutation>;
export const StartOnboardingDocument = gql`
    mutation StartOnboarding($input: StartOnboardingInput!) {
  startOnboarding(input: $input) {
    id
    token
  }
}
    `;

/**
 * __useStartOnboardingMutation__
 *
 * To run a mutation, you first call `useStartOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startOnboardingMutation, { data, loading, error }] = useStartOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartOnboardingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartOnboardingMutation, StartOnboardingMutationVariables>) {
        return ApolloReactHooks.useMutation<StartOnboardingMutation, StartOnboardingMutationVariables>(StartOnboardingDocument, baseOptions);
      }
export type StartOnboardingMutationHookResult = ReturnType<typeof useStartOnboardingMutation>;
export const StoredPreferenceDocument = gql`
    query StoredPreference($id: UUID!) {
  storedPreference(id: $id) {
    id
    account {
      ...AccountFragment
    }
    channels
    isEnabled
    preference {
      ...PreferenceFragment
    }
    createdAt
    updatedAt
  }
}
    ${AccountFragmentFragmentDoc}
${PreferenceFragmentFragmentDoc}`;

/**
 * __useStoredPreferenceQuery__
 *
 * To run a query within a React component, call `useStoredPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoredPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoredPreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoredPreferenceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoredPreferenceQuery, StoredPreferenceQueryVariables>) {
        return ApolloReactHooks.useQuery<StoredPreferenceQuery, StoredPreferenceQueryVariables>(StoredPreferenceDocument, baseOptions);
      }
export function useStoredPreferenceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoredPreferenceQuery, StoredPreferenceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StoredPreferenceQuery, StoredPreferenceQueryVariables>(StoredPreferenceDocument, baseOptions);
        }
export type StoredPreferenceQueryHookResult = ReturnType<typeof useStoredPreferenceQuery>;
export type StoredPreferenceLazyQueryHookResult = ReturnType<typeof useStoredPreferenceLazyQuery>;
export const StoredPreferencesListDocument = gql`
    query StoredPreferencesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  storedPreferencesAggregate(filter: $filter) {
    count
  }
  storedPreferencesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    preferenceId
    accountId
    account {
      ...AccountFragment
    }
    preference {
      ...PreferenceFragment
    }
    isEnabled
    channels
    createdAt
    updatedAt
  }
}
    ${AccountFragmentFragmentDoc}
${PreferenceFragmentFragmentDoc}`;

/**
 * __useStoredPreferencesListQuery__
 *
 * To run a query within a React component, call `useStoredPreferencesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoredPreferencesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoredPreferencesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useStoredPreferencesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoredPreferencesListQuery, StoredPreferencesListQueryVariables>) {
        return ApolloReactHooks.useQuery<StoredPreferencesListQuery, StoredPreferencesListQueryVariables>(StoredPreferencesListDocument, baseOptions);
      }
export function useStoredPreferencesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoredPreferencesListQuery, StoredPreferencesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StoredPreferencesListQuery, StoredPreferencesListQueryVariables>(StoredPreferencesListDocument, baseOptions);
        }
export type StoredPreferencesListQueryHookResult = ReturnType<typeof useStoredPreferencesListQuery>;
export type StoredPreferencesListLazyQueryHookResult = ReturnType<typeof useStoredPreferencesListLazyQuery>;
export const SubmitContactFormDocument = gql`
    mutation SubmitContactForm($input: SubmitContactFormInput!) {
  submitContactForm(input: $input) {
    success
  }
}
    `;

/**
 * __useSubmitContactFormMutation__
 *
 * To run a mutation, you first call `useSubmitContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitContactFormMutation, { data, loading, error }] = useSubmitContactFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitContactFormMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitContactFormMutation, SubmitContactFormMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitContactFormMutation, SubmitContactFormMutationVariables>(SubmitContactFormDocument, baseOptions);
      }
export type SubmitContactFormMutationHookResult = ReturnType<typeof useSubmitContactFormMutation>;
export const SummarizeDatabaseRecordsDocument = gql`
    query SummarizeDatabaseRecords($input: SummarizeDatabaseRecordsInput!) {
  summarizeDatabaseRecords(input: $input) {
    count
  }
}
    `;

/**
 * __useSummarizeDatabaseRecordsQuery__
 *
 * To run a query within a React component, call `useSummarizeDatabaseRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeDatabaseRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeDatabaseRecordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSummarizeDatabaseRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SummarizeDatabaseRecordsQuery, SummarizeDatabaseRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<SummarizeDatabaseRecordsQuery, SummarizeDatabaseRecordsQueryVariables>(SummarizeDatabaseRecordsDocument, baseOptions);
      }
export function useSummarizeDatabaseRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SummarizeDatabaseRecordsQuery, SummarizeDatabaseRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SummarizeDatabaseRecordsQuery, SummarizeDatabaseRecordsQueryVariables>(SummarizeDatabaseRecordsDocument, baseOptions);
        }
export type SummarizeDatabaseRecordsQueryHookResult = ReturnType<typeof useSummarizeDatabaseRecordsQuery>;
export type SummarizeDatabaseRecordsLazyQueryHookResult = ReturnType<typeof useSummarizeDatabaseRecordsLazyQuery>;
export const SummarizeOperationLogsDocument = gql`
    query SummarizeOperationLogs($input: SummarizeOperationLogsInput!) {
  summarizeOperationLogs(input: $input) {
    count
  }
}
    `;

/**
 * __useSummarizeOperationLogsQuery__
 *
 * To run a query within a React component, call `useSummarizeOperationLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeOperationLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeOperationLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSummarizeOperationLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SummarizeOperationLogsQuery, SummarizeOperationLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<SummarizeOperationLogsQuery, SummarizeOperationLogsQueryVariables>(SummarizeOperationLogsDocument, baseOptions);
      }
export function useSummarizeOperationLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SummarizeOperationLogsQuery, SummarizeOperationLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SummarizeOperationLogsQuery, SummarizeOperationLogsQueryVariables>(SummarizeOperationLogsDocument, baseOptions);
        }
export type SummarizeOperationLogsQueryHookResult = ReturnType<typeof useSummarizeOperationLogsQuery>;
export type SummarizeOperationLogsLazyQueryHookResult = ReturnType<typeof useSummarizeOperationLogsLazyQuery>;
export const TemplateVersionsListDocument = gql`
    query TemplateVersionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  templateVersionsAggregate(filter: $filter) {
    count
  }
  templateVersionsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    templateId
    settings
    isDeleted
    createdAt
    updatedAt
    authorId
    author {
      ...AccountFragment
    }
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useTemplateVersionsListQuery__
 *
 * To run a query within a React component, call `useTemplateVersionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVersionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVersionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTemplateVersionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateVersionsListQuery, TemplateVersionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplateVersionsListQuery, TemplateVersionsListQueryVariables>(TemplateVersionsListDocument, baseOptions);
      }
export function useTemplateVersionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateVersionsListQuery, TemplateVersionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplateVersionsListQuery, TemplateVersionsListQueryVariables>(TemplateVersionsListDocument, baseOptions);
        }
export type TemplateVersionsListQueryHookResult = ReturnType<typeof useTemplateVersionsListQuery>;
export type TemplateVersionsListLazyQueryHookResult = ReturnType<typeof useTemplateVersionsListLazyQuery>;
export const TemplatesAggregateDocument = gql`
    query TemplatesAggregate($filter: JSON) {
  templatesAggregate(filter: $filter) {
    count
  }
}
    `;

/**
 * __useTemplatesAggregateQuery__
 *
 * To run a query within a React component, call `useTemplatesAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTemplatesAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplatesAggregateQuery, TemplatesAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplatesAggregateQuery, TemplatesAggregateQueryVariables>(TemplatesAggregateDocument, baseOptions);
      }
export function useTemplatesAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatesAggregateQuery, TemplatesAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplatesAggregateQuery, TemplatesAggregateQueryVariables>(TemplatesAggregateDocument, baseOptions);
        }
export type TemplatesAggregateQueryHookResult = ReturnType<typeof useTemplatesAggregateQuery>;
export type TemplatesAggregateLazyQueryHookResult = ReturnType<typeof useTemplatesAggregateLazyQuery>;
export const TemplatesListDocument = gql`
    query TemplatesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  templatesAggregate(filter: $filter) {
    count
  }
  templatesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;

/**
 * __useTemplatesListQuery__
 *
 * To run a query within a React component, call `useTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTemplatesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplatesListQuery, TemplatesListQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplatesListQuery, TemplatesListQueryVariables>(TemplatesListDocument, baseOptions);
      }
export function useTemplatesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatesListQuery, TemplatesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplatesListQuery, TemplatesListQueryVariables>(TemplatesListDocument, baseOptions);
        }
export type TemplatesListQueryHookResult = ReturnType<typeof useTemplatesListQuery>;
export type TemplatesListLazyQueryHookResult = ReturnType<typeof useTemplatesListLazyQuery>;
export const TimeZonesDocument = gql`
    query TimeZones {
  timeZones {
    label
    value
  }
}
    `;

/**
 * __useTimeZonesQuery__
 *
 * To run a query within a React component, call `useTimeZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeZonesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeZonesQuery, TimeZonesQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeZonesQuery, TimeZonesQueryVariables>(TimeZonesDocument, baseOptions);
      }
export function useTimeZonesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeZonesQuery, TimeZonesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeZonesQuery, TimeZonesQueryVariables>(TimeZonesDocument, baseOptions);
        }
export type TimeZonesQueryHookResult = ReturnType<typeof useTimeZonesQuery>;
export type TimeZonesLazyQueryHookResult = ReturnType<typeof useTimeZonesLazyQuery>;
export const TransactionsAggregateDocument = gql`
    query TransactionsAggregate($filter: JSON, $targetEnvironment: String) {
  transactionsAggregate(filter: $filter, targetEnvironment: $targetEnvironment) {
    count
  }
}
    `;

/**
 * __useTransactionsAggregateQuery__
 *
 * To run a query within a React component, call `useTransactionsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsAggregateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useTransactionsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionsAggregateQuery, TransactionsAggregateQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionsAggregateQuery, TransactionsAggregateQueryVariables>(TransactionsAggregateDocument, baseOptions);
      }
export function useTransactionsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionsAggregateQuery, TransactionsAggregateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionsAggregateQuery, TransactionsAggregateQueryVariables>(TransactionsAggregateDocument, baseOptions);
        }
export type TransactionsAggregateQueryHookResult = ReturnType<typeof useTransactionsAggregateQuery>;
export type TransactionsAggregateLazyQueryHookResult = ReturnType<typeof useTransactionsAggregateLazyQuery>;
export const TransactionsListDocument = gql`
    query TransactionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int, $targetEnvironment: String) {
  transactionsList(filter: $filter, limit: $limit, order: $order, page: $page, targetEnvironment: $targetEnvironment) {
    ...TransactionFragment
  }
}
    ${TransactionFragmentFragmentDoc}`;

/**
 * __useTransactionsListQuery__
 *
 * To run a query within a React component, call `useTransactionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      targetEnvironment: // value for 'targetEnvironment'
 *   },
 * });
 */
export function useTransactionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionsListQuery, TransactionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionsListQuery, TransactionsListQueryVariables>(TransactionsListDocument, baseOptions);
      }
export function useTransactionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionsListQuery, TransactionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionsListQuery, TransactionsListQueryVariables>(TransactionsListDocument, baseOptions);
        }
export type TransactionsListQueryHookResult = ReturnType<typeof useTransactionsListQuery>;
export type TransactionsListLazyQueryHookResult = ReturnType<typeof useTransactionsListLazyQuery>;
export const UnarchiveInstallationDocument = gql`
    mutation UnarchiveInstallation($input: UnarchiveInstallationInput!) {
  unarchiveInstallation(input: $input) {
    ...InstallationFragment
  }
}
    ${InstallationFragmentFragmentDoc}`;

/**
 * __useUnarchiveInstallationMutation__
 *
 * To run a mutation, you first call `useUnarchiveInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveInstallationMutation, { data, loading, error }] = useUnarchiveInstallationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveInstallationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnarchiveInstallationMutation, UnarchiveInstallationMutationVariables>) {
        return ApolloReactHooks.useMutation<UnarchiveInstallationMutation, UnarchiveInstallationMutationVariables>(UnarchiveInstallationDocument, baseOptions);
      }
export type UnarchiveInstallationMutationHookResult = ReturnType<typeof useUnarchiveInstallationMutation>;
export const UnpublishFlowVersionDocument = gql`
    mutation UnpublishFlowVersion($input: UnpublishFlowVersionInput!) {
  unpublishFlowVersion(input: $input) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __useUnpublishFlowVersionMutation__
 *
 * To run a mutation, you first call `useUnpublishFlowVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishFlowVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishFlowVersionMutation, { data, loading, error }] = useUnpublishFlowVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishFlowVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnpublishFlowVersionMutation, UnpublishFlowVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<UnpublishFlowVersionMutation, UnpublishFlowVersionMutationVariables>(UnpublishFlowVersionDocument, baseOptions);
      }
export type UnpublishFlowVersionMutationHookResult = ReturnType<typeof useUnpublishFlowVersionMutation>;
export const UnpublishRecordVersionDocument = gql`
    mutation unpublishRecordVersion($input: UnpublishRecordVersionInput!) {
  unpublishRecordVersion(input: $input) {
    ...CustomRecordFragment
  }
}
    ${CustomRecordFragmentFragmentDoc}`;

/**
 * __useUnpublishRecordVersionMutation__
 *
 * To run a mutation, you first call `useUnpublishRecordVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishRecordVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishRecordVersionMutation, { data, loading, error }] = useUnpublishRecordVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishRecordVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnpublishRecordVersionMutation, UnpublishRecordVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<UnpublishRecordVersionMutation, UnpublishRecordVersionMutationVariables>(UnpublishRecordVersionDocument, baseOptions);
      }
export type UnpublishRecordVersionMutationHookResult = ReturnType<typeof useUnpublishRecordVersionMutation>;
export const UnpublishTemplateVersionDocument = gql`
    mutation UnpublishTemplateVersion($input: UnpublishTemplateVersionInput!) {
  unpublishTemplateVersion(input: $input) {
    id
    identifier
    createdAt
    updatedAt
    publishedVersionId
  }
}
    `;

/**
 * __useUnpublishTemplateVersionMutation__
 *
 * To run a mutation, you first call `useUnpublishTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishTemplateVersionMutation, { data, loading, error }] = useUnpublishTemplateVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishTemplateVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnpublishTemplateVersionMutation, UnpublishTemplateVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<UnpublishTemplateVersionMutation, UnpublishTemplateVersionMutationVariables>(UnpublishTemplateVersionDocument, baseOptions);
      }
export type UnpublishTemplateVersionMutationHookResult = ReturnType<typeof useUnpublishTemplateVersionMutation>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export const UpdateAccountAvatarDocument = gql`
    mutation updateAccountAvatar($input: UpdateAccountAvatarInput!) {
  updateAccountAvatar(input: $input) {
    id
    avatar
  }
}
    `;

/**
 * __useUpdateAccountAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAvatarMutation, { data, loading, error }] = useUpdateAccountAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountAvatarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountAvatarMutation, UpdateAccountAvatarMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountAvatarMutation, UpdateAccountAvatarMutationVariables>(UpdateAccountAvatarDocument, baseOptions);
      }
export type UpdateAccountAvatarMutationHookResult = ReturnType<typeof useUpdateAccountAvatarMutation>;
export const UpdateAccountTimeZoneDocument = gql`
    mutation UpdateAccountTimeZone($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    id
    timeZone
  }
}
    `;

/**
 * __useUpdateAccountTimeZoneMutation__
 *
 * To run a mutation, you first call `useUpdateAccountTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountTimeZoneMutation, { data, loading, error }] = useUpdateAccountTimeZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountTimeZoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountTimeZoneMutation, UpdateAccountTimeZoneMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountTimeZoneMutation, UpdateAccountTimeZoneMutationVariables>(UpdateAccountTimeZoneDocument, baseOptions);
      }
export type UpdateAccountTimeZoneMutationHookResult = ReturnType<typeof useUpdateAccountTimeZoneMutation>;
export const UpdateApiRequestDocument = gql`
    mutation UpdateApiRequest($input: UpdateApiRequestInput!) {
  updateApiRequest(input: $input) {
    ...ApiRequestFragment
  }
}
    ${ApiRequestFragmentFragmentDoc}`;

/**
 * __useUpdateApiRequestMutation__
 *
 * To run a mutation, you first call `useUpdateApiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiRequestMutation, { data, loading, error }] = useUpdateApiRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApiRequestMutation, UpdateApiRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApiRequestMutation, UpdateApiRequestMutationVariables>(UpdateApiRequestDocument, baseOptions);
      }
export type UpdateApiRequestMutationHookResult = ReturnType<typeof useUpdateApiRequestMutation>;
export const UpdateAppDocument = gql`
    mutation UpdateApp($input: UpdateAppInput!) {
  updateApp(input: $input) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAppMutation, UpdateAppMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppDocument, baseOptions);
      }
export type UpdateAppMutationHookResult = ReturnType<typeof useUpdateAppMutation>;
export const UpdateAttributeDocument = gql`
    mutation UpdateAttribute($input: UpdateAttributeInput!) {
  updateAttribute(input: $input) {
    ...AttributeFragment
  }
}
    ${AttributeFragmentFragmentDoc}`;

/**
 * __useUpdateAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttributeMutation, { data, loading, error }] = useUpdateAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttributeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAttributeMutation, UpdateAttributeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAttributeMutation, UpdateAttributeMutationVariables>(UpdateAttributeDocument, baseOptions);
      }
export type UpdateAttributeMutationHookResult = ReturnType<typeof useUpdateAttributeMutation>;
export const UpdateConfigurationDocument = gql`
    mutation UpdateConfiguration($input: UpdateConfigurationInput!) {
  updateConfiguration(input: $input) {
    ...ConfigurationFragment
  }
}
    ${ConfigurationFragmentFragmentDoc}`;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, baseOptions);
      }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export const UpdateCouponDocument = gql`
    mutation UpdateCoupon($input: UpdateCouponInput!) {
  updateCoupon(input: $input) {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, baseOptions);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export const UpdateCouponCodeDocument = gql`
    mutation UpdateCouponCode($input: UpdateCouponCodeInput!) {
  updateCouponCode(input: $input) {
    ...CouponCodeFragment
  }
}
    ${CouponCodeFragmentFragmentDoc}`;

/**
 * __useUpdateCouponCodeMutation__
 *
 * To run a mutation, you first call `useUpdateCouponCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponCodeMutation, { data, loading, error }] = useUpdateCouponCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponCodeMutation, UpdateCouponCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCouponCodeMutation, UpdateCouponCodeMutationVariables>(UpdateCouponCodeDocument, baseOptions);
      }
export type UpdateCouponCodeMutationHookResult = ReturnType<typeof useUpdateCouponCodeMutation>;
export const UpdateCouponRedemptionDocument = gql`
    mutation UpdateCouponRedemption($input: UpdateCouponRedemptionInput!) {
  updateCouponRedemption(input: $input) {
    ...CouponRedemptionFragment
  }
}
    ${CouponRedemptionFragmentFragmentDoc}`;

/**
 * __useUpdateCouponRedemptionMutation__
 *
 * To run a mutation, you first call `useUpdateCouponRedemptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponRedemptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponRedemptionMutation, { data, loading, error }] = useUpdateCouponRedemptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponRedemptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponRedemptionMutation, UpdateCouponRedemptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCouponRedemptionMutation, UpdateCouponRedemptionMutationVariables>(UpdateCouponRedemptionDocument, baseOptions);
      }
export type UpdateCouponRedemptionMutationHookResult = ReturnType<typeof useUpdateCouponRedemptionMutation>;
export const UpdateCouponRestrictionDocument = gql`
    mutation UpdateCouponRestriction($input: UpdateCouponRestrictionInput!) {
  updateCouponRestriction(input: $input) {
    ...CouponRestrictionFragment
  }
}
    ${CouponRestrictionFragmentFragmentDoc}`;

/**
 * __useUpdateCouponRestrictionMutation__
 *
 * To run a mutation, you first call `useUpdateCouponRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponRestrictionMutation, { data, loading, error }] = useUpdateCouponRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCouponRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCouponRestrictionMutation, UpdateCouponRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCouponRestrictionMutation, UpdateCouponRestrictionMutationVariables>(UpdateCouponRestrictionDocument, baseOptions);
      }
export type UpdateCouponRestrictionMutationHookResult = ReturnType<typeof useUpdateCouponRestrictionMutation>;
export const UpdateDashboardDocument = gql`
    mutation UpdateDashboard($input: UpdateDashboardInput!) {
  updateDashboard(input: $input) {
    ...DashboardFragment
  }
}
    ${DashboardFragmentFragmentDoc}`;

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDashboardMutation, UpdateDashboardMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDashboardMutation, UpdateDashboardMutationVariables>(UpdateDashboardDocument, baseOptions);
      }
export type UpdateDashboardMutationHookResult = ReturnType<typeof useUpdateDashboardMutation>;
export const UpdateDataTypeDocument = gql`
    mutation UpdateDataType($input: UpdateDataTypeInput!) {
  updateDataType(input: $input) {
    ...DataTypeFragment
  }
}
    ${DataTypeFragmentFragmentDoc}`;

/**
 * __useUpdateDataTypeMutation__
 *
 * To run a mutation, you first call `useUpdateDataTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataTypeMutation, { data, loading, error }] = useUpdateDataTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDataTypeMutation, UpdateDataTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDataTypeMutation, UpdateDataTypeMutationVariables>(UpdateDataTypeDocument, baseOptions);
      }
export type UpdateDataTypeMutationHookResult = ReturnType<typeof useUpdateDataTypeMutation>;
export const UpdateDatabaseScratchpadDocument = gql`
    mutation UpdateDatabaseScratchpad($input: UpdateDatabaseScratchpadInput!) {
  updateDatabaseScratchpad(input: $input) {
    ...DatabaseScratchpadFragment
  }
}
    ${DatabaseScratchpadFragmentFragmentDoc}`;

/**
 * __useUpdateDatabaseScratchpadMutation__
 *
 * To run a mutation, you first call `useUpdateDatabaseScratchpadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatabaseScratchpadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatabaseScratchpadMutation, { data, loading, error }] = useUpdateDatabaseScratchpadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDatabaseScratchpadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDatabaseScratchpadMutation, UpdateDatabaseScratchpadMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDatabaseScratchpadMutation, UpdateDatabaseScratchpadMutationVariables>(UpdateDatabaseScratchpadDocument, baseOptions);
      }
export type UpdateDatabaseScratchpadMutationHookResult = ReturnType<typeof useUpdateDatabaseScratchpadMutation>;
export const UpdateDeliveryDocument = gql`
    mutation UpdateDelivery($input: UpdateDeliveryInput!) {
  updateDelivery(input: $input) {
    ...DeliveryFragment
  }
}
    ${DeliveryFragmentFragmentDoc}`;

/**
 * __useUpdateDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryMutation, { data, loading, error }] = useUpdateDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>(UpdateDeliveryDocument, baseOptions);
      }
export type UpdateDeliveryMutationHookResult = ReturnType<typeof useUpdateDeliveryMutation>;
export const UpdateEnvironmentDocument = gql`
    mutation UpdateEnvironment($input: UpdateEnvironmentInput!) {
  updateEnvironment(input: $input) {
    id
    name
    identifier
  }
}
    `;

/**
 * __useUpdateEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentMutation, { data, loading, error }] = useUpdateEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnvironmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>(UpdateEnvironmentDocument, baseOptions);
      }
export type UpdateEnvironmentMutationHookResult = ReturnType<typeof useUpdateEnvironmentMutation>;
export const UpdateEventReportDocument = gql`
    mutation UpdateEventReport($input: UpdateEventReportInput!) {
  updateEventReport(input: $input) {
    id
    name
    description
    criteria
  }
}
    `;

/**
 * __useUpdateEventReportMutation__
 *
 * To run a mutation, you first call `useUpdateEventReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventReportMutation, { data, loading, error }] = useUpdateEventReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventReportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventReportMutation, UpdateEventReportMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventReportMutation, UpdateEventReportMutationVariables>(UpdateEventReportDocument, baseOptions);
      }
export type UpdateEventReportMutationHookResult = ReturnType<typeof useUpdateEventReportMutation>;
export const UpdateEventTypeDocument = gql`
    mutation UpdateEventType($input: UpdateEventTypeInput!) {
  updateEventType(input: $input) {
    ...EventTypeFragment
  }
}
    ${EventTypeFragmentFragmentDoc}`;

/**
 * __useUpdateEventTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTypeMutation, { data, loading, error }] = useUpdateEventTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>(UpdateEventTypeDocument, baseOptions);
      }
export type UpdateEventTypeMutationHookResult = ReturnType<typeof useUpdateEventTypeMutation>;
export const UpdateFlowDocument = gql`
    mutation UpdateFlow($input: UpdateFlowInput!) {
  updateFlow(input: $input) {
    ...FlowFragment
  }
}
    ${FlowFragmentFragmentDoc}`;

/**
 * __useUpdateFlowMutation__
 *
 * To run a mutation, you first call `useUpdateFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowMutation, { data, loading, error }] = useUpdateFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFlowMutation, UpdateFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFlowMutation, UpdateFlowMutationVariables>(UpdateFlowDocument, baseOptions);
      }
export type UpdateFlowMutationHookResult = ReturnType<typeof useUpdateFlowMutation>;
export const UpdateFlowVersionDocument = gql`
    mutation UpdateFlowVersion($input: UpdateFlowVersionInput!) {
  updateFlowVersion(input: $input) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __useUpdateFlowVersionMutation__
 *
 * To run a mutation, you first call `useUpdateFlowVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowVersionMutation, { data, loading, error }] = useUpdateFlowVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFlowVersionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFlowVersionMutation, UpdateFlowVersionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFlowVersionMutation, UpdateFlowVersionMutationVariables>(UpdateFlowVersionDocument, baseOptions);
      }
export type UpdateFlowVersionMutationHookResult = ReturnType<typeof useUpdateFlowVersionMutation>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
  updateGroup(input: $input) {
    ...GroupFragment
  }
}
    ${GroupFragmentFragmentDoc}`;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, baseOptions);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export const UpdateInstallationDocument = gql`
    mutation UpdateInstallation($input: UpdateInstallationInput!) {
  updateInstallation(input: $input) {
    ...InstallationFragment
  }
}
    ${InstallationFragmentFragmentDoc}`;

/**
 * __useUpdateInstallationMutation__
 *
 * To run a mutation, you first call `useUpdateInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstallationMutation, { data, loading, error }] = useUpdateInstallationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstallationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInstallationMutation, UpdateInstallationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInstallationMutation, UpdateInstallationMutationVariables>(UpdateInstallationDocument, baseOptions);
      }
export type UpdateInstallationMutationHookResult = ReturnType<typeof useUpdateInstallationMutation>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($input: UpdateItemInput!) {
  updateItem(input: $input) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export const UpdateItemCategoryDocument = gql`
    mutation UpdateItemCategory($input: UpdateItemCategoryInput!) {
  updateItemCategory(input: $input) {
    ...ItemCategoryFragment
  }
}
    ${ItemCategoryFragmentFragmentDoc}`;

/**
 * __useUpdateItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryMutation, { data, loading, error }] = useUpdateItemCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>(UpdateItemCategoryDocument, baseOptions);
      }
export type UpdateItemCategoryMutationHookResult = ReturnType<typeof useUpdateItemCategoryMutation>;
export const UpdateMenuElementDocument = gql`
    mutation UpdateMenuElement($input: UpdateMenuElementInput!) {
  updateMenuElement(input: $input) {
    ...MenuElementFragment
  }
}
    ${MenuElementFragmentFragmentDoc}`;

/**
 * __useUpdateMenuElementMutation__
 *
 * To run a mutation, you first call `useUpdateMenuElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuElementMutation, { data, loading, error }] = useUpdateMenuElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenuElementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMenuElementMutation, UpdateMenuElementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMenuElementMutation, UpdateMenuElementMutationVariables>(UpdateMenuElementDocument, baseOptions);
      }
export type UpdateMenuElementMutationHookResult = ReturnType<typeof useUpdateMenuElementMutation>;
export const UpdateOperationDocument = gql`
    mutation UpdateOperation($input: UpdateOperationInput!) {
  updateOperation(input: $input) {
    ...OperationFragment
  }
}
    ${OperationFragmentFragmentDoc}`;

/**
 * __useUpdateOperationMutation__
 *
 * To run a mutation, you first call `useUpdateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationMutation, { data, loading, error }] = useUpdateOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOperationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOperationMutation, UpdateOperationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOperationMutation, UpdateOperationMutationVariables>(UpdateOperationDocument, baseOptions);
      }
export type UpdateOperationMutationHookResult = ReturnType<typeof useUpdateOperationMutation>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($input: UpdateOrderInput!) {
  updateOrder(input: $input) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, baseOptions);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export const UpdateOrderItemDocument = gql`
    mutation UpdateOrderItem($input: UpdateOrderItemInput!) {
  updateOrderItem(input: $input) {
    ...OrderItemFragment
  }
}
    ${OrderItemFragmentFragmentDoc}`;

/**
 * __useUpdateOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemMutation, { data, loading, error }] = useUpdateOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>(UpdateOrderItemDocument, baseOptions);
      }
export type UpdateOrderItemMutationHookResult = ReturnType<typeof useUpdateOrderItemMutation>;
export const UpdateParameterDocument = gql`
    mutation UpdateParameter($input: UpdateParameterInput!) {
  updateParameter(input: $input) {
    ...ParameterFragment
  }
}
    ${ParameterFragmentFragmentDoc}`;

/**
 * __useUpdateParameterMutation__
 *
 * To run a mutation, you first call `useUpdateParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParameterMutation, { data, loading, error }] = useUpdateParameterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParameterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateParameterMutation, UpdateParameterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateParameterMutation, UpdateParameterMutationVariables>(UpdateParameterDocument, baseOptions);
      }
export type UpdateParameterMutationHookResult = ReturnType<typeof useUpdateParameterMutation>;
export const UpdatePreferenceDocument = gql`
    mutation UpdatePreference($input: UpdatePreferenceInput!) {
  updatePreference(input: $input) {
    ...PreferenceFragment
  }
}
    ${PreferenceFragmentFragmentDoc}`;

/**
 * __useUpdatePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferenceMutation, { data, loading, error }] = useUpdatePreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePreferenceMutation, UpdatePreferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePreferenceMutation, UpdatePreferenceMutationVariables>(UpdatePreferenceDocument, baseOptions);
      }
export type UpdatePreferenceMutationHookResult = ReturnType<typeof useUpdatePreferenceMutation>;
export const UpdatePricingDocument = gql`
    mutation UpdatePricing($input: UpdatePricingInput!) {
  updatePricing(input: $input) {
    ...PricingFragment
  }
}
    ${PricingFragmentFragmentDoc}`;

/**
 * __useUpdatePricingMutation__
 *
 * To run a mutation, you first call `useUpdatePricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePricingMutation, { data, loading, error }] = useUpdatePricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePricingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePricingMutation, UpdatePricingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePricingMutation, UpdatePricingMutationVariables>(UpdatePricingDocument, baseOptions);
      }
export type UpdatePricingMutationHookResult = ReturnType<typeof useUpdatePricingMutation>;
export const UpdateRecipientDocument = gql`
    mutation UpdateRecipient($input: UpdateRecipientInput!) {
  updateRecipient(input: $input) {
    ...RecipientFragment
  }
}
    ${RecipientFragmentFragmentDoc}`;

/**
 * __useUpdateRecipientMutation__
 *
 * To run a mutation, you first call `useUpdateRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipientMutation, { data, loading, error }] = useUpdateRecipientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecipientMutation, UpdateRecipientMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecipientMutation, UpdateRecipientMutationVariables>(UpdateRecipientDocument, baseOptions);
      }
export type UpdateRecipientMutationHookResult = ReturnType<typeof useUpdateRecipientMutation>;
export const UpdateRelationshipDocument = gql`
    mutation UpdateRelationship($input: UpdateRelationshipInput!) {
  updateRelationship(input: $input) {
    ...RelationshipFragment
  }
}
    ${RelationshipFragmentFragmentDoc}`;

/**
 * __useUpdateRelationshipMutation__
 *
 * To run a mutation, you first call `useUpdateRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationshipMutation, { data, loading, error }] = useUpdateRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRelationshipMutation, UpdateRelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRelationshipMutation, UpdateRelationshipMutationVariables>(UpdateRelationshipDocument, baseOptions);
      }
export type UpdateRelationshipMutationHookResult = ReturnType<typeof useUpdateRelationshipMutation>;
export const UpdateResourceDocument = gql`
    mutation UpdateResource($input: UpdateResourceInput!) {
  updateResource(input: $input) {
    ...ResourceFragment
  }
}
    ${ResourceFragmentFragmentDoc}`;

/**
 * __useUpdateResourceMutation__
 *
 * To run a mutation, you first call `useUpdateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceMutation, { data, loading, error }] = useUpdateResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateResourceMutation, UpdateResourceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateResourceMutation, UpdateResourceMutationVariables>(UpdateResourceDocument, baseOptions);
      }
export type UpdateResourceMutationHookResult = ReturnType<typeof useUpdateResourceMutation>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    ...RoleFragment
  }
}
    ${RoleFragmentFragmentDoc}`;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export const UpdateSegmentDocument = gql`
    mutation UpdateSegment($input: UpdateSegmentInput!) {
  updateSegment(input: $input) {
    ...SegmentFragment
  }
}
    ${SegmentFragmentFragmentDoc}`;

/**
 * __useUpdateSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentMutation, { data, loading, error }] = useUpdateSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSegmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegmentDocument, baseOptions);
      }
export type UpdateSegmentMutationHookResult = ReturnType<typeof useUpdateSegmentMutation>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($input: UpdateTemplateInput!) {
  updateTemplate(input: $input) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, baseOptions);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export const UpdateThemeDocument = gql`
    mutation UpdateTheme($input: UpdateThemeInput!) {
  updateTheme(input: $input) {
    id
    name
    palette {
      primary
      secondary
      accent
    }
  }
}
    `;

/**
 * __useUpdateThemeMutation__
 *
 * To run a mutation, you first call `useUpdateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeMutation, { data, loading, error }] = useUpdateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateThemeMutation, UpdateThemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateThemeMutation, UpdateThemeMutationVariables>(UpdateThemeDocument, baseOptions);
      }
export type UpdateThemeMutationHookResult = ReturnType<typeof useUpdateThemeMutation>;
export const UpsertViewDocument = gql`
    mutation UpsertView($input: UpsertViewInput!) {
  upsertView(input: $input) {
    id
    name
    componentPath
    blocks
    operations
  }
}
    `;

/**
 * __useUpsertViewMutation__
 *
 * To run a mutation, you first call `useUpsertViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertViewMutation, { data, loading, error }] = useUpsertViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertViewMutation, UpsertViewMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertViewMutation, UpsertViewMutationVariables>(UpsertViewDocument, baseOptions);
      }
export type UpsertViewMutationHookResult = ReturnType<typeof useUpsertViewMutation>;
export const UpdateWorkspaceBrandingDocument = gql`
    mutation UpdateWorkspaceBranding($input: UpdateWorkspaceBrandingInput!) {
  updateWorkspaceBranding(input: $input) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useUpdateWorkspaceBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceBrandingMutation, { data, loading, error }] = useUpdateWorkspaceBrandingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceBrandingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkspaceBrandingMutation, UpdateWorkspaceBrandingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkspaceBrandingMutation, UpdateWorkspaceBrandingMutationVariables>(UpdateWorkspaceBrandingDocument, baseOptions);
      }
export type UpdateWorkspaceBrandingMutationHookResult = ReturnType<typeof useUpdateWorkspaceBrandingMutation>;
export const UpdateWorkspaceDomainDocument = gql`
    mutation UpdateWorkspaceDomain($input: UpdateWorkspaceDomainInput!) {
  updateWorkspaceDomain(input: $input) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useUpdateWorkspaceDomainMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceDomainMutation, { data, loading, error }] = useUpdateWorkspaceDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceDomainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkspaceDomainMutation, UpdateWorkspaceDomainMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkspaceDomainMutation, UpdateWorkspaceDomainMutationVariables>(UpdateWorkspaceDomainDocument, baseOptions);
      }
export type UpdateWorkspaceDomainMutationHookResult = ReturnType<typeof useUpdateWorkspaceDomainMutation>;
export const UpdateWorkspacePaymentMethodDocument = gql`
    mutation UpdateWorkspacePaymentMethod($input: UpdateWorkspacePaymentMethodInput!) {
  updateWorkspacePaymentMethod(input: $input) {
    ...PaymentMethodFragment
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;

/**
 * __useUpdateWorkspacePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspacePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspacePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspacePaymentMethodMutation, { data, loading, error }] = useUpdateWorkspacePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspacePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkspacePaymentMethodMutation, UpdateWorkspacePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkspacePaymentMethodMutation, UpdateWorkspacePaymentMethodMutationVariables>(UpdateWorkspacePaymentMethodDocument, baseOptions);
      }
export type UpdateWorkspacePaymentMethodMutationHookResult = ReturnType<typeof useUpdateWorkspacePaymentMethodMutation>;
export const UpdateWorkspaceThemeDocument = gql`
    mutation UpdateWorkspaceTheme($input: UpdateWorkspaceThemeInput!) {
  updateWorkspaceTheme(input: $input) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useUpdateWorkspaceThemeMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceThemeMutation, { data, loading, error }] = useUpdateWorkspaceThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkspaceThemeMutation, UpdateWorkspaceThemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkspaceThemeMutation, UpdateWorkspaceThemeMutationVariables>(UpdateWorkspaceThemeDocument, baseOptions);
      }
export type UpdateWorkspaceThemeMutationHookResult = ReturnType<typeof useUpdateWorkspaceThemeMutation>;
export const ValidateKeyPairDocument = gql`
    query ValidateKeyPair($input: ValidateKeyPairInput!) {
  validateKeyPair(input: $input) {
    success
  }
}
    `;

/**
 * __useValidateKeyPairQuery__
 *
 * To run a query within a React component, call `useValidateKeyPairQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateKeyPairQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateKeyPairQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateKeyPairQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateKeyPairQuery, ValidateKeyPairQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidateKeyPairQuery, ValidateKeyPairQueryVariables>(ValidateKeyPairDocument, baseOptions);
      }
export function useValidateKeyPairLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateKeyPairQuery, ValidateKeyPairQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidateKeyPairQuery, ValidateKeyPairQueryVariables>(ValidateKeyPairDocument, baseOptions);
        }
export type ValidateKeyPairQueryHookResult = ReturnType<typeof useValidateKeyPairQuery>;
export type ValidateKeyPairLazyQueryHookResult = ReturnType<typeof useValidateKeyPairLazyQuery>;
export const VerifyInvitationDocument = gql`
    query VerifyInvitation($input: VerifyInvitationInput!) {
  verifyInvitation(input: $input) {
    success
  }
}
    `;

/**
 * __useVerifyInvitationQuery__
 *
 * To run a query within a React component, call `useVerifyInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyInvitationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyInvitationQuery, VerifyInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyInvitationQuery, VerifyInvitationQueryVariables>(VerifyInvitationDocument, baseOptions);
      }
export function useVerifyInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyInvitationQuery, VerifyInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyInvitationQuery, VerifyInvitationQueryVariables>(VerifyInvitationDocument, baseOptions);
        }
export type VerifyInvitationQueryHookResult = ReturnType<typeof useVerifyInvitationQuery>;
export type VerifyInvitationLazyQueryHookResult = ReturnType<typeof useVerifyInvitationLazyQuery>;
export const VerifyProspectDocument = gql`
    mutation VerifyProspect($input: VerifyProspectInput!) {
  verifyProspect(input: $input) {
    id
    email
    token
    expiresAt
  }
}
    `;

/**
 * __useVerifyProspectMutation__
 *
 * To run a mutation, you first call `useVerifyProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyProspectMutation, { data, loading, error }] = useVerifyProspectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyProspectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyProspectMutation, VerifyProspectMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyProspectMutation, VerifyProspectMutationVariables>(VerifyProspectDocument, baseOptions);
      }
export type VerifyProspectMutationHookResult = ReturnType<typeof useVerifyProspectMutation>;
export const VerifyResetPasswordTokenDocument = gql`
    query VerifyResetPasswordToken($input: VerifyResetPasswordTokenInput!) {
  verifyResetPasswordToken(input: $input) {
    success
  }
}
    `;

/**
 * __useVerifyResetPasswordTokenQuery__
 *
 * To run a query within a React component, call `useVerifyResetPasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetPasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyResetPasswordTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyResetPasswordTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyResetPasswordTokenQuery, VerifyResetPasswordTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyResetPasswordTokenQuery, VerifyResetPasswordTokenQueryVariables>(VerifyResetPasswordTokenDocument, baseOptions);
      }
export function useVerifyResetPasswordTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyResetPasswordTokenQuery, VerifyResetPasswordTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyResetPasswordTokenQuery, VerifyResetPasswordTokenQueryVariables>(VerifyResetPasswordTokenDocument, baseOptions);
        }
export type VerifyResetPasswordTokenQueryHookResult = ReturnType<typeof useVerifyResetPasswordTokenQuery>;
export type VerifyResetPasswordTokenLazyQueryHookResult = ReturnType<typeof useVerifyResetPasswordTokenLazyQuery>;
export const VerifySessionDocument = gql`
    mutation VerifySession($input: VerifySessionInput!) {
  verifySession(input: $input) {
    id
    mfaVerifiedAt
  }
}
    `;

/**
 * __useVerifySessionMutation__
 *
 * To run a mutation, you first call `useVerifySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifySessionMutation, { data, loading, error }] = useVerifySessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifySessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifySessionMutation, VerifySessionMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifySessionMutation, VerifySessionMutationVariables>(VerifySessionDocument, baseOptions);
      }
export type VerifySessionMutationHookResult = ReturnType<typeof useVerifySessionMutation>;
export const ViewDocument = gql`
    query View($id: UUID!) {
  view(id: $id) {
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;

/**
 * __useViewQuery__
 *
 * To run a query within a React component, call `useViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewQuery, ViewQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewQuery, ViewQueryVariables>(ViewDocument, baseOptions);
      }
export function useViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewQuery, ViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewQuery, ViewQueryVariables>(ViewDocument, baseOptions);
        }
export type ViewQueryHookResult = ReturnType<typeof useViewQuery>;
export type ViewLazyQueryHookResult = ReturnType<typeof useViewLazyQuery>;
export const ViewsListDocument = gql`
    query ViewsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  viewsAggregate(filter: $filter) {
    count
  }
  viewsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;

/**
 * __useViewsListQuery__
 *
 * To run a query within a React component, call `useViewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useViewsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewsListQuery, ViewsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewsListQuery, ViewsListQueryVariables>(ViewsListDocument, baseOptions);
      }
export function useViewsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewsListQuery, ViewsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewsListQuery, ViewsListQueryVariables>(ViewsListDocument, baseOptions);
        }
export type ViewsListQueryHookResult = ReturnType<typeof useViewsListQuery>;
export type ViewsListLazyQueryHookResult = ReturnType<typeof useViewsListLazyQuery>;
export const WorkspaceContextDocument = gql`
    query WorkspaceContext($hostname: String!) {
  workspace(hostname: $hostname) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useWorkspaceContextQuery__
 *
 * To run a query within a React component, call `useWorkspaceContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceContextQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useWorkspaceContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspaceContextQuery, WorkspaceContextQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkspaceContextQuery, WorkspaceContextQueryVariables>(WorkspaceContextDocument, baseOptions);
      }
export function useWorkspaceContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspaceContextQuery, WorkspaceContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkspaceContextQuery, WorkspaceContextQueryVariables>(WorkspaceContextDocument, baseOptions);
        }
export type WorkspaceContextQueryHookResult = ReturnType<typeof useWorkspaceContextQuery>;
export type WorkspaceContextLazyQueryHookResult = ReturnType<typeof useWorkspaceContextLazyQuery>;
export const WorkspaceOrdersListDocument = gql`
    query WorkspaceOrdersList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  workspaceOrdersAggregate(filter: $filter) {
    count
  }
  workspaceOrdersList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useWorkspaceOrdersListQuery__
 *
 * To run a query within a React component, call `useWorkspaceOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWorkspaceOrdersListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspaceOrdersListQuery, WorkspaceOrdersListQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkspaceOrdersListQuery, WorkspaceOrdersListQueryVariables>(WorkspaceOrdersListDocument, baseOptions);
      }
export function useWorkspaceOrdersListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspaceOrdersListQuery, WorkspaceOrdersListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkspaceOrdersListQuery, WorkspaceOrdersListQueryVariables>(WorkspaceOrdersListDocument, baseOptions);
        }
export type WorkspaceOrdersListQueryHookResult = ReturnType<typeof useWorkspaceOrdersListQuery>;
export type WorkspaceOrdersListLazyQueryHookResult = ReturnType<typeof useWorkspaceOrdersListLazyQuery>;
export const WorkspacePaymentMethodsListDocument = gql`
    query WorkspacePaymentMethodsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  workspacePaymentMethodsAggregate(filter: $filter) {
    count
  }
  workspacePaymentMethodsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...PaymentMethodFragment
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;

/**
 * __useWorkspacePaymentMethodsListQuery__
 *
 * To run a query within a React component, call `useWorkspacePaymentMethodsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePaymentMethodsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacePaymentMethodsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWorkspacePaymentMethodsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspacePaymentMethodsListQuery, WorkspacePaymentMethodsListQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkspacePaymentMethodsListQuery, WorkspacePaymentMethodsListQueryVariables>(WorkspacePaymentMethodsListDocument, baseOptions);
      }
export function useWorkspacePaymentMethodsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspacePaymentMethodsListQuery, WorkspacePaymentMethodsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkspacePaymentMethodsListQuery, WorkspacePaymentMethodsListQueryVariables>(WorkspacePaymentMethodsListDocument, baseOptions);
        }
export type WorkspacePaymentMethodsListQueryHookResult = ReturnType<typeof useWorkspacePaymentMethodsListQuery>;
export type WorkspacePaymentMethodsListLazyQueryHookResult = ReturnType<typeof useWorkspacePaymentMethodsListLazyQuery>;
export const WorkspaceThemesListDocument = gql`
    query WorkspaceThemesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  themesAggregate(filter: $filter) {
    count
  }
  themesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    id
    workspaceId
    name
    palette {
      primary
      secondary
      accent
    }
  }
}
    `;

/**
 * __useWorkspaceThemesListQuery__
 *
 * To run a query within a React component, call `useWorkspaceThemesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceThemesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceThemesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWorkspaceThemesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspaceThemesListQuery, WorkspaceThemesListQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkspaceThemesListQuery, WorkspaceThemesListQueryVariables>(WorkspaceThemesListDocument, baseOptions);
      }
export function useWorkspaceThemesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspaceThemesListQuery, WorkspaceThemesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkspaceThemesListQuery, WorkspaceThemesListQueryVariables>(WorkspaceThemesListDocument, baseOptions);
        }
export type WorkspaceThemesListQueryHookResult = ReturnType<typeof useWorkspaceThemesListQuery>;
export type WorkspaceThemesListLazyQueryHookResult = ReturnType<typeof useWorkspaceThemesListLazyQuery>;
export const WorkspacesListDocument = gql`
    query WorkspacesList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  workspacesAggregate(filter: $filter) {
    count
  }
  workspacesList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...WorkspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

/**
 * __useWorkspacesListQuery__
 *
 * To run a query within a React component, call `useWorkspacesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWorkspacesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspacesListQuery, WorkspacesListQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkspacesListQuery, WorkspacesListQueryVariables>(WorkspacesListDocument, baseOptions);
      }
export function useWorkspacesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspacesListQuery, WorkspacesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkspacesListQuery, WorkspacesListQueryVariables>(WorkspacesListDocument, baseOptions);
        }
export type WorkspacesListQueryHookResult = ReturnType<typeof useWorkspacesListQuery>;
export type WorkspacesListLazyQueryHookResult = ReturnType<typeof useWorkspacesListLazyQuery>;
export const FlowVersionsListDocument = gql`
    query FlowVersionsList($filter: JSON, $limit: Int, $order: JSON, $page: Int) {
  flowVersionsList(filter: $filter, limit: $limit, order: $order, page: $page) {
    ...FlowVersionFragment
  }
}
    ${FlowVersionFragmentFragmentDoc}`;

/**
 * __useFlowVersionsListQuery__
 *
 * To run a query within a React component, call `useFlowVersionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowVersionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowVersionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFlowVersionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FlowVersionsListQuery, FlowVersionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<FlowVersionsListQuery, FlowVersionsListQueryVariables>(FlowVersionsListDocument, baseOptions);
      }
export function useFlowVersionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FlowVersionsListQuery, FlowVersionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FlowVersionsListQuery, FlowVersionsListQueryVariables>(FlowVersionsListDocument, baseOptions);
        }
export type FlowVersionsListQueryHookResult = ReturnType<typeof useFlowVersionsListQuery>;
export type FlowVersionsListLazyQueryHookResult = ReturnType<typeof useFlowVersionsListLazyQuery>;
export const TemplateDocument = gql`
    query Template($id: UUID!) {
  template(id: $id) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
      }
export function useTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
        }
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export const TemplateVersionDocument = gql`
    query TemplateVersion($id: UUID!) {
  templateVersion(id: $id) {
    id
    templateId
    settings
    isDeleted
    createdAt
    updatedAt
    authorId
    author {
      ...AccountFragment
    }
  }
}
    ${AccountFragmentFragmentDoc}`;

/**
 * __useTemplateVersionQuery__
 *
 * To run a query within a React component, call `useTemplateVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateVersionQuery, TemplateVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplateVersionQuery, TemplateVersionQueryVariables>(TemplateVersionDocument, baseOptions);
      }
export function useTemplateVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateVersionQuery, TemplateVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplateVersionQuery, TemplateVersionQueryVariables>(TemplateVersionDocument, baseOptions);
        }
export type TemplateVersionQueryHookResult = ReturnType<typeof useTemplateVersionQuery>;
export type TemplateVersionLazyQueryHookResult = ReturnType<typeof useTemplateVersionLazyQuery>;