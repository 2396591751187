import React from 'react'
import startCase from 'lodash/startCase'
import uniq from 'lodash/uniq'
import { useRecoilValue } from 'recoil'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataSourcesView from 'components/dashboardEditor/graph/DataSourcesView'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import MediaCard from 'components/mediaCard/MediaCard'
import OperationsView from 'components/dashboardEditor/graph/OperationsView'
import ResourcesView from 'components/dashboardEditor/graph/ResourcesView'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { AppPlatform } from 'models/App'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from '../DashboardEditor'
import type { App } from 'generated/schema'

type Params = ViewParams[Views.PROJECT_DETAILS]

const ProjectDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, parentView } = params! as Params
  const { name, platforms, technology, workspaceId, identifier, icon } = app as App

  return (
    <>
      <DashboardEditorHeader
        subtitle={name}
        onEdit={() => openDashboardEditorView({
          target: Views.EDIT_PROJECT,
          params: { initialValues: app }
        })}
        heading={parentView ? 'Dashboard Editor' : 'Projects'}
        onClose={onClose}
        icon={(
          <AppIcon
            isProject
            technology={technology}
            isCustomApp={!!workspaceId}
            identifier={identifier as AppIconIdentifier}
            icon={icon}
            size={24}
          />
        )}
      />
      <DashboardEditorBody>
        {(platforms || technology) && (
          <Flex>
            {technology && <Technology technology={technology} />}
            {platforms && <Platforms platforms={platforms} />}
          </Flex>
        )}
        <ResourcesView />
        <OperationsView />
        <DataSourcesView />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const Technology = ({ technology }: Partial<App>) => (
  <Flex gap={16} direction="column" css={{ flexBasis: '50%' }}>
    <Text
      color="dark500"
      fontSize={10}
      fontWeight="bold"
      textTransform="uppercase"
    >
      Built using
    </Text>
    <Text>{startCase(technology?.toLowerCase())}</Text>
  </Flex>
)

const appPlatforms = {
  [AppPlatform.BROWSER]: {
    icon: 'globe',
    name: 'Browser'
  },
  [AppPlatform.MOBILE]: {
    icon: 'phone',
    name: 'Mobile'
  },
  [AppPlatform.SERVER]: {
    icon: 'app-database-hub',
    name: 'Server'
  }
}

const Platforms = ({ platforms }: Partial<App>) => {
  const parsedPlatforms = uniq(platforms?.map(
    (p) => ([ 'IOS', 'ANDROID' ].includes(p) ? 'MOBILE' : p)
  ))

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Platforms
      </Text>
      <Flex direction="column" gap={16}>
        {parsedPlatforms?.map((platform) => {
          const { icon, name } = appPlatforms[platform as AppPlatform]

          return (
            <Flex key={name} gap={10} alignItems="center">
              <Icon name={icon} />
              <Text>{name}</Text>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

const actions = [
  {
    title: 'Create Resource',
    identifier: Views.CREATE_RESOURCE,
    media: 'graph'
  },
  {
    title: 'Create Operation',
    identifier: Views.CREATE_OPERATION,
    media: 'advance'
  },
  {
    title: 'Import from Database',
    identifier: Views.IMPORT_FROM_DATABASE,
    media: 'app-database-hub'
  },
  {
    title: 'Import from API',
    identifier: Views.IMPORT_FROM_API,
    media: 'app-api-hub'
  }
]

const Actions = () => {
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const onClick = () => openDashboardEditorView({
            target: action.identifier,
            params: { app }
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              onClick={onClick}
              media={action.media}
              title={action.title}
              height={64}
              width="full"
              actions={[ {
                description: '',
                icon: 'arrow-right',
                onClick,
                isIconAlwaysVisible: true
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default ProjectDetailsView

export { Actions }
