import React, { memo, useEffect, useState } from 'react'

import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import { checkPlatform, downloadUpdate, installUpdate, onUpdateAvailable, onUpdateDownloaded } from 'lib/electron'
import { styled } from 'styles/stitches'
import { StyledAlertContainer, StyledAlert, StyledIcon, StyledCloseIcon } from 'components/alert/Alert'

const ALERT_ICON_SIZE = 22
const CLOSE_ICON_SIZE = 10

const StyledUpdateAlert = styled(StyledAlert, {
  left: 'auto',
  right: '10%',
  transform: 'none'
})

const invokeDownloadUpdate = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
  downloadUpdate()
}

const closeAndInstallUpdate = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault()
  installUpdate()
}

function UpdateAlert() {
  const [ isOpen, setIsOpen ] = useState<boolean>(false)
  const [ isUpdatedDownloaded, setIsUpdatedDownloaded ] = useState<boolean>(false)
  const [ alertTitle, setAlertTitle ] = useState<string | null>(null)
  const [ alertSubtitle, setAlertSubtitle ] = useState<string | null>(null)

  const closeAlert = () => setIsOpen(false)

  // Skip auto-update for Mac for now as it requires code-signing
  const isEnvironmentMac = checkPlatform() === 'darwin'

  useEffect(() => {
    onUpdateAvailable(({ version }: any) => {
      window.interop.removeAllListeners('update-available')
      setIsOpen(true)
      setAlertTitle(`Version ${version} is avaiable`)
      setAlertSubtitle('Update now')
    })

    onUpdateDownloaded(() => {
      window.interop.removeAllListeners('update-downloaded')
      setIsUpdatedDownloaded(true)
      setAlertTitle('Update Downloaded. It will be installed on restart.')
      setAlertSubtitle('Restart now?')
    })
  }, [])

  return (
    <StyledAlertContainer>
      <StyledUpdateAlert visible={isOpen}>
        <Flex alignItems="flex-start" gap={24} justifyContent="space-between">
          <Flex gap={16}>
            <StyledIcon
              variant="success"
              name="wifi"
              size={ALERT_ICON_SIZE}
            />

            <Flex direction="column" gap={8}>
              <Text fontSize={16} fontWeight="bold">
                {alertTitle}
              </Text>

              {!isEnvironmentMac && (
                <TextLink
                  as="button"
                  type="button"
                  color="positive400"
                  fontSize={12}
                  onClick={isUpdatedDownloaded ? closeAndInstallUpdate : invokeDownloadUpdate}
                  mode="subtle"
                >
                  {alertSubtitle}
                </TextLink>
              )}
            </Flex>
          </Flex>

          <StyledCloseIcon
            aria-label="Close"
            onClick={closeAlert}
            onKeyPress={closeAlert}
            role="button"
            tabIndex={-1}
          >
            <Icon name="cross" size={CLOSE_ICON_SIZE} />
          </StyledCloseIcon>
        </Flex>
      </StyledUpdateAlert>
    </StyledAlertContainer>
  )
}

export default memo(UpdateAlert)
