import React from 'react'

import FormBlock from 'components/blocks/FormBlock'
import type { BlockProps } from '../Block'

const FormBlockWrapper: React.FC<BlockProps> = ({ block, ...props }) => (
  <FormBlock
    {...props}
    {...block.properties}
    identifier={block.identifier}
    operationId={block?.properties?.operation}
    initialValues={block?.properties?.initial_values}
  />
)

export default FormBlockWrapper
