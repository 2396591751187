import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'

import Account from 'models/Account'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import type { ResetPasswordInput } from 'generated/schema'

type ResetPasswordFormValues = ResetPasswordInput & { passwordConfirmation: string }

function ResetPasswordForm(props: FormProps<ResetPasswordFormValues>) {
  return (
    <Form
      validate={(values) => Account.validate(values, [ 'password', 'passwordConfirmation', 'token' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap={16}>
            <Field name="token" component="input" type="hidden" />
            <Field name="password" component={TextInput} type="password" placeholder="New Password" autoFocus />
            <Field name="passwordConfirmation" component={TextInput} type="password" placeholder="Confirm New Password" />
            <Flex justifyContent="flex-end">
              <Button type="submit" label="Submit" disabled={submitting || pristine} />
            </Flex>
          </Flex>
        </form>
      )}
      {...props}
    />
  )
}

export default ResetPasswordForm

export type { ResetPasswordFormValues }
