import React, { PropsWithChildren, useState } from 'react'

type UploadingType = string[]

type SetMediaFieldContextType = React.Dispatch<React.SetStateAction<UploadingType>>
type GetMediaFieldContextType = UploadingType

const SetMediaFieldStateContext = React.createContext<SetMediaFieldContextType>(() => {})
const GetMediaFieldStateContext = React.createContext<GetMediaFieldContextType>([])

const MediaFieldProvider = ({
  children
}: PropsWithChildren<{}>) => {
  const [ isUploading, setIsUploading ] = useState<UploadingType>([])

  return (
    <SetMediaFieldStateContext.Provider value={setIsUploading}>
      <GetMediaFieldStateContext.Provider value={isUploading}>
        {children}
      </GetMediaFieldStateContext.Provider>
    </SetMediaFieldStateContext.Provider>
  )
}

export { SetMediaFieldStateContext, GetMediaFieldStateContext }

export type { UploadingType }

export default MediaFieldProvider
