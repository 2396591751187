import { gql } from '@apollo/client'

import type { ClientState } from 'client/state/types'

/* Default Data */

const DEFAULT_DEVELOPER: Developer = {
  __typename: 'Developer',
  privateKey: '',
  publicKey: ''
}

/* Types */

export type Developer = {
  __typename: 'Developer',
  privateKey: string,
  publicKey: string
}

export type DeveloperInput = Pick<Developer, 'privateKey' | 'publicKey'>

export type DeveloperQuery = {
  developer: Developer
}

/* Queries */

export const DEVELOPER_QUERY = gql`
  query DeveloperQuery {
    developer @client {
      privateKey,
      publicKey
    }
  }
`

/* Mutations */

export const SAVE_DEVELOPER_MUTATION = gql`
  mutation SaveDeveloperMutation($developer: DeveloperInput!) {
    saveDeveloper(developer: $developer) @client
  }
`

/* ClientState */

export default {
  defaults: {
    query: DEVELOPER_QUERY,
    data: { developer: DEFAULT_DEVELOPER }
  },

  resolvers: {
    Mutation: {
      saveDeveloper: (_, args, { cache }) => {
        const { developer } = cache.readQuery({ query: DEVELOPER_QUERY })

        cache.writeQuery({
          query: DEVELOPER_QUERY,
          data: {
            developer: {
              ...developer,
              ...args.developer
            }
          }
        })

        return null
      }
    }
  }
} as ClientState
