import React from 'react'
import { Field, Form, FormProps, FormSpy } from 'react-final-form'

import CodeInput from 'components/inputs/CodeInput'
import Extender from 'components/extender/Extender'
import Prospect, { CODE_LENGTH } from 'models/Prospect'
import type { VerifyProspectInput } from 'generated/schema'

type VerifyEmailFormProps = FormProps<VerifyProspectInput> & {
  onChange: (values: VerifyProspectInput) => void,
  disabled: boolean
}

function VerifyEmailForm({ disabled, onChange, ...other }: VerifyEmailFormProps) {
  return (
    <Form
      validate={(values) => Prospect.validate(values, [ 'code' ])}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Extender>
            <Field name="code" maxDigits={CODE_LENGTH} component={CodeInput} placeholder={`Enter ${CODE_LENGTH}-digit code`} disabled={submitting || disabled} />
          </Extender>

          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => onChange(values as VerifyProspectInput)}
          />
        </form>
      )}
      {...other}
    />
  )
}

export default VerifyEmailForm
