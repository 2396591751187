import React from 'react'

import * as constants from 'components/modal/constants'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

const SubHeader = styled(Flex, {
  alignItems: 'center',
  borderTop: '1px solid light700',
  borderBottom: '1px solid light700',

  variants: {
    wide: {
      true: {
        marginX: constants.SMALL_HORIZONTAL_PADDING
      },
      false: {
        marginX: constants.LARGE_HORIZONTAL_PADDING
      }
    }
  }
})

type ModalSubHeaderProps = React.PropsWithChildren<{
  wide?: boolean
}>

function ModalSubHeader({ children, wide = false }: ModalSubHeaderProps) {
  return (
    <SubHeader wide={wide}>
      {children}
    </SubHeader>
  )
}

export default ModalSubHeader
