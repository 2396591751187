import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'

const CODE_LENGTH = 6

class Prospect extends BaseModel {
  static schema = object({
    email: string().email().required(),
    code: string().length(CODE_LENGTH).required()
  })
}

export { CODE_LENGTH }

export default Prospect
