import React, { useState } from 'react'

import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import { getAppIcon } from 'models/App'
import { Installation, useInstallationsListQuery } from 'generated/schema'
import { INTEGRATIONS_LIST_LIMIT } from 'models/Installation'
import { Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

const IntegrationsView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Integrations"
        heading="Dashboard Editor"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>Connect third-party Apps to your workspace for added functionality.
          {' '}<TextLink variant="underlined" href="https://docs.dashx.com/platform/projects">Learn more</TextLink>
        </Text>
        <IntegrationsGrid search={search} />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const IntegrationsGrid = ({ search = '' }: { search: string }) => {
  const { openDashboardEditorView } = useDashboard()

  const {
    data: { installationsList = [] } = {},
    loading,
    error
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        appKind: { eq: 'INTEGRATION' }
      },
      limit: INTEGRATIONS_LIST_LIMIT
    }
  })

  const filteredIntegrations = installationsList?.filter(
    (app) => app.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Installed Integrations
      </Text>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={filteredIntegrations}
        loading={loading}
        error={error}
      >
        <Flex direction="column" gap={2}>
          {filteredIntegrations?.map((integration) => {
            const onClick = () => openDashboardEditorView({
              target: Views.INTEGRATION_DETAILS,
              params: { integration: integration as Installation }
            })

            return (
              <MediaCard
                compact
                onClick={onClick}
                key={integration.id}
                media={(
                  <img
                    style={{ width: '32px' }}
                    src={getAppIcon(integration.app.identifier)}
                    alt={integration.app.identifier || ''}
                  />
                )}
                title={integration.name}
                text={integration.app.appCategory?.name}
                titlePosition="top"
                height={64}
                width="full"
                actions={[ {
                  description: '',
                  icon: 'arrow-right',
                  onClick,
                  isIconAlwaysVisible: true
                } ]}
              />
            )
          })}
        </Flex>
      </DashboardEditorLoader>
    </Flex>
  )
}

const Actions = () => {
  const { openDashboardEditorView } = useDashboard()
  const onClick = () => openDashboardEditorView({ target: Views.ADD_INTEGRATION })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <MediaCard
        compact
        onClick={onClick}
        media="integration"
        title="Add Integration"
        height={64}
        width="full"
        actions={[ {
          description: '',
          icon: 'arrow-right',
          onClick,
          isIconAlwaysVisible: true
        } ]}
      />
    </Flex>
  )
}

export default IntegrationsView
