import get from 'lodash/get'
import React from 'react'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import type { Installation } from 'generated/schema'
import type { RendererOptions } from 'components/dataTable/types'

const APP_ICON_SIZE = 16

function AppIconRenderer({
  rowData,
  dataKey
}: RendererOptions<Installation>) {
  const data = get(rowData, dataKey)
  const app = data?.__typename === 'App' ? data : rowData

  const { icon, identifier } = app || {}

  return (
    <AppIcon icon={icon} identifier={identifier as AppIconIdentifier} size={APP_ICON_SIZE} />
  )
}

export default AppIconRenderer
