const zIndices = {
  below: '-1',
  none: '0',
  above: '1',

  topbar: '100',
  sidebar: '200',
  workspaceSidebar: '300',
  popover: '400',
  modal: '400',
  appScreen: '1000',
  alert: '1500',
  dashboardEditor: '1100'
}

export default zIndices
