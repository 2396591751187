import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import type { DraggableChildrenFn, DroppableProvided } from 'react-beautiful-dnd'

import Button from 'components/buttons/Button'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import type { FieldsListQuery } from 'generated/schema'

type Field = FieldsListQuery['fieldsList'][number]

type RepeatedContainerProps = {
  name: string,
  shouldValidate?: boolean,
  field: Partial<Field>,
  fieldPrefix?: string,
  renderField: (
    field: Partial<Field>, options: { fieldName: string, index: number }
  ) => React.ReactNode,
  onAdd?: () => any,
  hideAdd?: boolean,
  hideDelete?: boolean,
  hideReorder?: boolean
}

function RepeatedField({
  field,
  fieldPrefix,
  name,
  shouldValidate,
  renderField,
  onAdd,
  hideAdd,
  hideDelete,
  hideReorder
}: RepeatedContainerProps) {
  const computedName = fieldPrefix ? `${fieldPrefix}.${name}` : name
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <FieldGroup
        {...provided}
        isDraggable={!hideReorder}
        onClick={hideDelete
          ? undefined
          : () => fieldsRef.current?.fields.remove(rubric.source.index)}
      >
        {/* name and helpText are stripped so child doesn't render them */}
        {renderField({ ...field, name: '', settings: { ...field.settings, helpText: '' } }, {
          fieldName: FieldArray.getFieldName(computedName, rubric.source.index),
          index: rubric.source.index
        })}
      </FieldGroup>
    </div>
  )

  const onDragEnd = useReorderFieldArray(fieldsRef)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={droppableId.current}
        renderClone={renderDraggableChildren}
      >
        {(droppableProvided: DroppableProvided) => (
          <Flex
            direction="column"
            gap={10}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {field.name && (
              <FieldLabel isTranslatable={field.isTranslatable}>
                {field.name}
              </FieldLabel>
            )}

            <FieldArray
              name={computedName}
              fieldsRef={fieldsRef}
              settings={field?.settings || {}}
              shouldValidate={shouldValidate}
            >
              {({ keys }) => keys.map((key, index) => (
                <Draggable
                  disableInteractiveElementBlocking
                  draggableId={key}
                  index={index}
                  key={key}
                >
                  {renderDraggableChildren}
                </Draggable>
              ))}
            </FieldArray>
            {droppableProvided.placeholder}

            {field.settings?.helpText && <InputHelpText helpText={field.settings.helpText} />}

            {!hideAdd && (
              <Button
                icon="add-thin"
                onClick={() => fieldsRef.current?.fields.push(onAdd?.())}
                size="small"
                name={computedName}
              />
            )}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default RepeatedField
