import React, { useState } from 'react'

import AddRoleView from 'components/views/AddRoleView'
import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import DataTableBlock from 'components/blocks/DataTableBlock'
import HintBox from 'components/hints/HintBox'
import RoleView from 'components/views/RoleView'
import SearchBar from 'components/searchbar/SearchBar'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TextLink from 'components/links/TextLink'
import TitleBlock from 'components/blocks/TitleBlock'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { Kind } from 'models/Role'
import { RolesListDocument, RolesListQuery, useDestroyRoleMutation } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { CustomRole, RolesListQueryVariables } from 'generated/schema'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [ {
  name: 'asc',
  kind: 'asc'
} ]

function RolesPage() {
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const [ order, setOrder ] = useState(DEFAULT_ORDER)
  const [ type, setType ] = useState<'custom'| 'system'>('custom')
  const [ filters, setFilters ] = useState<FilterType>({})

  const queryVariables = {
    limit: pageSize,
    filter: {
      ...(type === 'system' ? {
        workspaceId: 'null'
      } : {
        workspaceId: 'notNull'
      }),
      kind: { eq: Kind.WORKSPACE },
      ...filters
    },
    page,
    order
  }

  const [ {
    data: { rolesList = [], rolesAggregate = {} } = {},
    error,
    loading
  }, handleChange ] = useSearch<RolesListQuery, RolesListQueryVariables>({
    query: RolesListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ 'name' ]
  })

  const openEditRoleView = (role: CustomRole) => {
    openView({
      title: 'Edit Role',
      component: AddRoleView,
      params: {
        initialValues: role,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const openAddRoleView = () => {
    openView({
      title: 'Add Role',
      component: AddRoleView,
      params: {
        kind: Kind.WORKSPACE,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const [ deleteRole ] = useDestroyRoleMutation({
    refetchQueries: [
      { query: RolesListDocument }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRole, {
    update: {
      strategy: 'REMOVE',
      query: RolesListDocument,
      queryVariables,
      dataKey: 'rolesList',
      mutation: 'destroyRole'
    }
  })

  const openDeleteRoleDialog = (role: CustomRole) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: role.id }),
      recordType: 'Role',
      recordDescription: role.name
    })
  }

  const columns: Column<CustomRole>[] = [
    {
      dataKey: 'name',
      fieldType: 'text-field',
      title: 'Name',
      sortable: true,
      style: { width: 200 },
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          onClick: () => {
            openView({
              title: `Role: ${props.rowData.name}`,
              component: RoleView,
              style: RoleView.defaultStyle,
              params: {
                role: props.rowData
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      }
    }
  ]

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: (role: CustomRole) => openEditRoleView(role),
      visibilityFilter: (role: CustomRole) => role.workspaceId !== null
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (role: CustomRole) => openDeleteRoleDialog(role),
      visibilityFilter: (role: CustomRole) => role.workspaceId !== null
    }
  ]

  const dataTableSecondaryElement = type === 'custom' && (
    <Button icon="add-thin" onClick={() => openAddRoleView()} size="small" />
  )

  return (
    <>
      <TitleBlock heading="Roles" />
      <Block direction="column" width={{ md: '100%' }}>
        <HintBox headline="Advanced Feature">
          This feature must be used carefully. Please contact
          {' '}
          <TextLink href="mailto:support@dashx.com">support@dashx.com</TextLink>
          {' '}
          for help.
        </HintBox>
      </Block>
      <DataTableBlock
        primaryElements={[
          <Tabs onChange={(index) => {
            switch (index) {
              case 0:
                setType('custom')
                handlePageChange(1)
                break
              case 1:
                setType('system')
                handlePageChange(1)
                break
            }
          }}
          >
            <Tab index={0} label="Custom" />
            <Tab index={1} label="System" />
          </Tabs>,
          <SearchBar placeholder="Search..." onChange={handleChange} css={{ maxWidth: 240 }} />
        ]}
        actions={actions}
        batchActions={[]}
        columns={columns}
        data={rolesList as CustomRole[]}
        defaultOrder={order}
        error={error}
        loading={loading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        secondaryElements={dataTableSecondaryElement}
        setOrder={setOrder}
        totalRows={rolesAggregate?.count || 0}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  )
}

export default RolesPage
