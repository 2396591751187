import React from 'react'

import Card, { CardProps } from 'components/card/Card'

type ListItemProps = Omit<CardProps, 'height' | 'width'>

function ListItem({ children, ...other }: ListItemProps) {
  return (
    <Card
      height={80}
      width="100%"
      direction="row"
      padding="wide"
      {...other}
    >
      {children}
    </Card>
  )
}

export type { ListItemProps }

export default ListItem
