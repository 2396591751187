import * as mixins from 'styles/mixins'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'

const TABLE_CELL_HORIZONTAL_DIVIDER_VERTICAL_MARGIN = 10
const TABLE_CELL_PADDING = 20
const TABLE_ROW_BORDER_RADIUS = 4
const TABLE_ROW_VERTICAL_SPACING = 2
const TABLE_VERTICAL_MARGIN = 36

const Table = styled('table', {
  borderCollapse: 'separate',
  borderSpacing: `0 ${TABLE_ROW_VERTICAL_SPACING}px`,
  marginBottom: TABLE_VERTICAL_MARGIN,
  width: '100%',

  '& thead': {
    display: 'none'
  },

  '& tr': {
    ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),

    background: '#fff',

    '&:last-child': {
      marginBottom: 0
    },

    '& td:first-child': {
      borderTopLeftRadius: TABLE_ROW_BORDER_RADIUS,
      borderBottomLeftRadius: TABLE_ROW_BORDER_RADIUS
    },
    '& td:last-child': {
      borderTopRightRadius: TABLE_ROW_BORDER_RADIUS,
      borderBottomRightRadius: TABLE_ROW_BORDER_RADIUS
    }
  },

  '& td': {
    color: 'dark900',
    fontFamily: 'normal',
    fontSize: 14,
    fontWeight: 'regular',
    lineHeight: 'cozy',
    padding: TABLE_CELL_PADDING,
    position: 'relative',

    '&::after': {
      borderRight: '1px solid light700',
      bottom: TABLE_CELL_HORIZONTAL_DIVIDER_VERTICAL_MARGIN,
      content: '""',
      position: 'absolute',
      right: 0,
      top: TABLE_CELL_HORIZONTAL_DIVIDER_VERTICAL_MARGIN
    },

    '&:last-child': {
      '&::after': {
        display: 'none'
      }
    }
  }
})

export default Table
