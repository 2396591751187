import React from 'react'
import { Field, Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import MediaInput from 'components/inputs/MediaInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import { MEDIA_TYPE_OPTIONS } from 'components/contentEditors/generic/fields/fieldProps'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { UpdateWorkspaceBrandingInput } from 'generated/schema'

const LOGO_FULL_DIMENSIONS = {
  height: 150,
  width: 450
}

const LOGO_SYMBOL_DIMENSIONS = {
  height: 150,
  width: 150
}

const WALLPAPER_DIMENSIONS = {
  height: 1000,
  width: 1000
}

const WALLPAPER_PREVIEW_DIMENSIONS = {
  height: 300,
  width: 450
}

type WorkspaceBrandingFormProps = FormPropsWithId<UpdateWorkspaceBrandingInput> & {
  onCancel: () => void
}

function WorkspaceBrandingForm({ onCancel, ...others }: WorkspaceBrandingFormProps) {
  return (
    <Form
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine, form: { reset } }) => (
        <form onSubmit={handleSubmit} onReset={reset}>
          <Flex direction="column" gap={16}>
            <Flex grow={1}>
              <Flex direction="column" css={{ flexBasis: '50%' }}>
                <FormField name="name" label="Name" component={TextInput} type="text" helpText="Appears in the sidebar." />
              </Flex>
            </Flex>
            <Divider />

            <Flex direction="column" gap={48}>
              <Flex justifyContent="space-between" gap={20}>
                <Flex direction="column" gap={20} css={{ flexBasis: '100%' }}>
                  <Text fontWeight="bold" fontSize={14}>Logo (Symbol)</Text>
                  <Text fontSize={14}>Short version of your logo consisting of only the logomark.
                    Used in restricted places such as the sidebar. If not set,
                    full logo will be used.
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end" css={{ flexBasis: '100%' }}>
                  <Field
                    component={MediaInput}
                    name="logoSymbol"
                    title="Change Logo (Symbol)"
                    height={LOGO_SYMBOL_DIMENSIONS.height}
                    width={LOGO_SYMBOL_DIMENSIONS.width}
                    fileTypeCategory={MEDIA_TYPE_OPTIONS.IMAGE}
                    showLegacyModal
                  />
                </Flex>
              </Flex>
            </Flex>
            <Divider />

            <Flex justifyContent="space-between" gap={20}>
              <Flex direction="column" gap={20} css={{ flexBasis: '100%' }}>
                <Text fontSize={14} fontWeight="bold">Logo (Full)</Text>
                <Text fontSize={14}>Full version of your logo, used in most places,
                  such as onboarding, generated documents, etc.
                </Text>
              </Flex>
              <Flex justifyContent="flex-end" css={{ flexBasis: '100%' }}>
                <Field
                  component={MediaInput}
                  name="logoFull"
                  previewFit="contain"
                  height={LOGO_FULL_DIMENSIONS.height}
                  title="Change Logo (Full)"
                  width={LOGO_FULL_DIMENSIONS.width}
                  fileTypeCategory={MEDIA_TYPE_OPTIONS.IMAGE}
                  showLegacyModal
                />
              </Flex>
            </Flex>
            <Divider />

            <Flex justifyContent="space-between" gap={20}>
              <Flex direction="column" gap={20} css={{ flexBasis: '100%' }}>
                <Text fontSize={14} fontWeight="bold">Wallpaper</Text>
                <Text fontSize={14}>Large image shown during sign in or
                  onboading. Could be brand-related, fun or inspiring.
                </Text>
              </Flex>
              <Flex justifyContent="flex-end" css={{ flexBasis: '100%' }}>
                <Field
                  component={MediaInput}
                  name="wallpaper"
                  previewFit="cover"
                  previewHeight={WALLPAPER_PREVIEW_DIMENSIONS.height}
                  previewWidth={WALLPAPER_PREVIEW_DIMENSIONS.width}
                  height={WALLPAPER_DIMENSIONS.height}
                  title="Change Wallpaper"
                  width={WALLPAPER_DIMENSIONS.height}
                  fileTypeCategory={MEDIA_TYPE_OPTIONS.IMAGE}
                  showLegacyModal
                />
              </Flex>
            </Flex>
            <Divider />

            <Flex gap={24}>
              <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
              <Button type="reset" variant="outline" label="Cancel" mode="subtle" disabled={submitting || pristine} onClick={onCancel} />
            </Flex>
          </Flex>
        </form>
      )}
      {...others}
    />
  )
}

export default WorkspaceBrandingForm
