import React, { memo, useContext, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useSortable } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import AccountTopbarItem from 'components/topbar/AccountTopbarItem'
import DashboardContext from 'components/contexts/DashboardContext'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import HelpMenuTopbarItem from 'components/topbar/HelpMenuTopbarItem'
import Icon from 'components/icons/Icon'
import rgba from 'lib/rgba'
import TopbarElements from 'components/topbar/TopbarElements'
import useDashboard from 'hooks/useDashboard'
import useHover from 'hooks/useHover'
import useWindowScroll from 'hooks/useWindowScroll'
import { colorVars } from 'styles/theme'
import { DASHBOARD_EDITOR_WIDTH } from 'components/dashboardEditor/constants'
import { generateVariants, styled } from 'styles/stitches'
import { sidebarWidthOptions } from 'components/sidebar/Sidebar'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'
import { TRIAL_BANNER_HEIGHT } from 'components/trial/TrialBanner'
import type { ComputedMenuElement } from 'lib/generateDashboard'

type TopbarProps = {
  menuElements: readonly ComputedMenuElement[],
  isLocked?: boolean,
  hasTrialBanner?: boolean
}

const CLEAR_ICON_PADDING = 5
const CLEAR_ICON_SIZE = 8
const SEARCH_INPUT_PADDING = 30
const SEARCH_ICON_SIZE = 20
const TOPBAR_SCROLL_Y_THRESHOLD = 5

const CONTENT_PADDING_X = (SEARCH_INPUT_PADDING * 2) + SEARCH_ICON_SIZE
const SEARCH_INPUT_PADDING_RIGHT = (SEARCH_INPUT_PADDING * 2) + CLEAR_ICON_SIZE

const sidebarWidthVariants = generateVariants(sidebarWidthOptions, 'paddingLeft')

const StyledTopbar = styled(Flex, {
  ...mixins.transition('fluid', 'all', 0.5),

  backgroundColor: 'light400',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: 'light700',
  height: TOPBAR_HEIGHT,
  left: 0,

  position: 'fixed',
  right: 0,

  zIndex: 'topbar',

  variants: {
    editMode: {
      true: {
        paddingRight: DASHBOARD_EDITOR_WIDTH
      },
      false: {}
    },
    sidebarWidth: sidebarWidthVariants,
    isScrolling: {
      true: {
        backgroundColor: 'light100'
      }
    },
    hasTrialBanner: {
      true: {
        top: TRIAL_BANNER_HEIGHT
      },
      false: {
        top: 0
      }
    }
  }
})

const StyledSearchIcon = styled('div', {
  ...mixins.transition('fluid'),

  color: rgba(colorVars.dark900rgb, 0.7),
  padding: SEARCH_INPUT_PADDING
})

const StyledClearIcon = styled('div', {
  ...mixins.transition('fluid'),

  color: 'dark300',
  cursor: 'pointer',
  margin: SEARCH_INPUT_PADDING - CLEAR_ICON_PADDING,
  padding: CLEAR_ICON_PADDING,
  zIndex: 'above',

  '&:hover, &:focus': {
    color: 'dark900'
  }
})

const StyledSearchInput = styled('input', {
  ...mixins.transition('fluid'),

  '&::placeholder': {
    ...mixins.transition('fluid'),

    color: 'transparent',
    fontFamily: 'normal',
    fontSize: 15,
    fontStyle: 'italic',
    fontWeight: 'light',
    lineHeight: `${TOPBAR_HEIGHT}px` // trick to center the placeholder vertically in firefox
  },

  fontFamily: 'normal',
  fontSize: 15,
  fontWeight: 'bold',
  lineHeight: 'normal',
  backgroundColor: 'transparent',
  border: 0,
  bottom: 0,
  height: '100%',
  left: 0,
  paddingLeft: CONTENT_PADDING_X,
  paddingRight: SEARCH_INPUT_PADDING_RIGHT,
  position: 'absolute',
  top: 0,
  width: '100%',

  '&::-webkit-search-cancel-button': {
    display: 'none'
  }
})

const StyledTopbarLeft = styled(Flex, {
  ...mixins.transition('fluid'),

  cursor: 'text',
  pointerEvents: 'none', // temporarily hide searchbar
  position: 'relative',
  visibility: 'hidden', // temporarily hide searchbar

  '&:hover, &:focus-within': {
    backgroundColor: 'light500'
  },

  [`&:hover ${StyledSearchIcon}, &:focus-within ${StyledSearchIcon}`]: {
    color: 'dark900'
  },

  [`&:focus-within ${StyledSearchInput}`]: {
    '&::placeholder': {
      color: rgba(colorVars.dark500rgb, 0.3)
    }
  },

  [`&:hover ${StyledSearchInput}`]: {
    '&::placeholder': {
      color: 'dark500'
    }
  }
})

const Dropzone = ({ id }: any) => {
  const { draggingOverMenuIdState } = useDashboard()
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const { draggedMenuState, draggedAppState, draggedResourceState } = useDashboard()
  const draggedMenu = useRecoilValue(draggedMenuState)
  const draggedApp = useRecoilValue(draggedAppState)
  const draggedResource = useRecoilValue(draggedResourceState)

  const draggedElement = draggedMenu || draggedApp || draggedResource

  if (!draggedElement) {
    return null
  }

  return (
    <Flex
      justifyContent="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: '100%',
        width: 32,
        marginLeft: -16,
        marginRight: -16,
        opacity: draggingOverMenuId === id ? 1 : 0,

        '&:hover': {
          opacity: draggingOverMenuId ? undefined : 1
        }
      }}
    >
      <Flex
        css={{
          height: '100%',
          borderRight: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 6,
            backgroundColor: 'light100',
            overflow: 'hidden'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

function Topbar({ menuElements, isLocked = false, hasTrialBanner = false }: TopbarProps) {
  const searchEl = useRef<HTMLInputElement>(null)

  const { currentSidebarWidth, editMode } = useContext(DashboardContext)!
  const { scrollY } = useWindowScroll()
  const [ isActive, setIsActive ] = useState(false)
  const [ onHoverProps, isHovered ] = useHover()
  const [ search, setSearch ] = useState('')

  const isClearSearchActive = search && (isActive || isHovered)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }

  const resetSearch = () => setSearch('')

  const handleSearchClear = () => {
    resetSearch()
    searchEl.current?.focus()
  }

  const handleSearchClearKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleSearchClear()
    }
  }

  const { draggedMenuState } = useDashboard()
  const draggedMenu = useRecoilValue(draggedMenuState)

  const { setNodeRef: topbarRightRef } = useSortable({
    id: 'PREPEND_TOPBAR',
    data: {
      placement: 'TOP',
      parentId: null,
      ...(draggedMenu ? { isSticky: false } : {})
    }
  })

  const { setNodeRef: topbarLeftRef } = useSortable({
    id: 'TOPBAR',
    data: {
      placement: 'TOP',
      parentId: null,
      ...(draggedMenu ? { isSticky: false } : {})
    }
  })

  return (
    <StyledTopbar
      as="header"
      isScrolling={scrollY > TOPBAR_SCROLL_Y_THRESHOLD}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      sidebarWidth={currentSidebarWidth}
      hasTrialBanner={hasTrialBanner}
      editMode={editMode}
      ref={topbarLeftRef}
      {...onHoverProps}
    >
      <StyledTopbarLeft grow={1} justifyContent="space-between">
        <StyledSearchIcon>
          <Icon name="search" size={SEARCH_ICON_SIZE} />
        </StyledSearchIcon>
        <StyledSearchInput
          onChange={handleSearchChange}
          placeholder="Search away"
          ref={searchEl}
          type="search"
          value={search}
        />
        {isClearSearchActive && (
          <StyledClearIcon
            onClick={handleSearchClear}
            onKeyUp={handleSearchClearKeyUp}
            role="button"
            tabIndex={0}
          >
            <Icon name="cross" size={CLEAR_ICON_SIZE} />
          </StyledClearIcon>
        )}
      </StyledTopbarLeft>
      <Flex role="menubar" grow={0}>
        <TopbarElements
          isTopbarActive={menuElements.length === 0 ? true : isActive}
          isTopbarHovered={isHovered}
          isTopbarLocked={isLocked}
          menuElements={menuElements}
        />
        <Flex ref={topbarRightRef}>
          <Dropzone id="PREPEND_TOPBAR" />
          <Divider orientation="vertical" />
          <HelpMenuTopbarItem />
          <Divider orientation="vertical" />
          <AccountTopbarItem />
        </Flex>
      </Flex>
    </StyledTopbar>
  )
}

export default memo(Topbar)
