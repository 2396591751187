import React from 'react'

import TitleBlock from 'components/blocks/TitleBlock'

export default function AccountNotificationsPage() {
  return (
    <>
      <TitleBlock heading="Notifications" />
    </>
  )
}
