import { colord } from 'colord'
import type { MutableRefObject } from 'react'

import colors from 'styles/primitives/colors'
import { css } from 'styles/stitches'
import { rgb } from 'lib/rgba'
import type { Palette } from 'styles/theme'

const DEFAULT_THEME = {
  id: null,
  name: 'Default',
  palette: {
    primary: colors.primary400,
    secondary: colors.secondary400,
    accent: colors.accent400
  },
  workspaceId: ''
}

const isDark = (hexCode: string) => colord(hexCode).brightness() < 0.3

const darken = (hexCode: string, value: number) => colord(hexCode).darken(value).toHex()

const lighten = (hexCode: string, value: number) => colord(hexCode).lighten(value).toHex()

const generateTheme = (palette: Palette | undefined, themeRef: MutableRefObject<string | null>) => {
  if (!palette) {
    return
  }

  const { primary, secondary, accent } = palette

  const isDarkAccent = isDark(accent!)

  // Todo do not generate if default color palette
  const newColors = {
    primary50: lighten(primary!, 0.35),
    primary50rgb: rgb(lighten(primary!, 0.35)).join(', '),
    primary100: lighten(primary!, 0.30),
    primary100rgb: rgb(lighten(primary!, 0.30)).join(', '),
    primary200: lighten(primary!, 0.20),
    primary200rgb: rgb(lighten(primary!, 0.20)).join(', '),
    primary300: lighten(primary!, 0.10),
    primary300rgb: rgb(lighten(primary!, 0.10)).join(', '),
    primary350: lighten(primary!, 0.05),
    primary350rgb: rgb(lighten(primary!, 0.05)).join(', '),
    primary400: colord(primary!).toHex(),
    primary400rgb: rgb(colord(primary!).toHex()).join(', '),

    secondary50: lighten(secondary!, 0.35),
    secondary50rgb: rgb(lighten(secondary!, 0.35)).join(', '),
    secondary300: lighten(secondary!, 0.10),
    secondary300rgb: rgb(lighten(secondary!, 0.10)).join(', '),
    secondary350: lighten(secondary!, 0.05),
    secondary350rgb: rgb(lighten(secondary!, 0.05)).join(', '),
    secondary400: colord(secondary!).toHex(),
    secondary400rgb: rgb(colord(secondary!).toHex()).join(', '),

    accent200: isDarkAccent ? lighten(accent!, 0.50) : lighten(accent!, 0.20),
    accent200rgb: isDarkAccent ? rgb(lighten(accent!, 0.50)).join(', ') : rgb(lighten(accent!, 0.20)).join(', '),
    accent300: isDarkAccent ? lighten(accent!, 0.40) : lighten(accent!, 0.10),
    accent300rgb: isDarkAccent ? rgb(lighten(accent!, 0.40)).join(', ') : rgb(lighten(accent!, 0.10)).join(', '),
    accent400: colord(accent!).toHex(),
    accent400rgb: rgb(colord(accent!).toHex()).join(', '),
    accent500: darken(accent!, 0.10),
    accent500rgb: rgb(darken(accent!, 0.10)).join(', '),
    accent600: darken(accent!, 0.20),
    accent600rgb: rgb(darken(accent!, 0.20)).join(', ')
  }

  themeRef.current && document.body.classList.remove(themeRef.current)

  themeRef.current = css.theme({
    colors: {
      ...newColors
    }
  })

  document.body.classList.add(themeRef.current)
}

export { DEFAULT_THEME, isDark }

export default generateTheme
