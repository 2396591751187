import Prism from 'prismjs'
import React, { useEffect, useRef } from 'react'

import Flex from 'components/layout/Flex'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import usePrism from 'hooks/usePrism'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import type { Language } from 'hooks/usePrism'

type CodeViewerProps = {
  language: Language,
  data: string
}

const LINE_NUMBER_BORDER_RADIUS = 4
const LINE_NUMBER_PADDING_Y = 14
const LINE_NUMBER_PADDING_X = 12
const LINE_NUMBER_WIDTH = 46
const INPUT_PADDING_LEFT = 20

const ViewerContainer = styled(Flex, {
  backgroundColor: 'dark1000',
  overflow: 'hidden'
})

const StyledViewer = styled('pre', {
  '&': {
    backgroundColor: 'transparent !important',
    fontSize: '14px !important',
    height: '100%',
    marginBottom: '0 !important',
    marginTop: '0 !important',
    paddingBottom: `${LINE_NUMBER_PADDING_Y}px !important`,
    paddingTop: `${LINE_NUMBER_PADDING_Y}px !important`,
    paddingLeft: `${INPUT_PADDING_LEFT}px !important`,
    paddingRight: `${INPUT_PADDING_LEFT}px !important`,
    resize: 'none !important',
    lineHeight: 1.5,
    width: `calc(100% - ${LINE_NUMBER_WIDTH}px)`,
    overflow: 'auto'
  }
})

const StyledLineNumbers = styled(Flex, {
  backgroundColor: rgba(colorVars.dark800rgb, 0.1),
  borderBottomLeftRadius: LINE_NUMBER_BORDER_RADIUS,
  borderTopLeftRadius: LINE_NUMBER_BORDER_RADIUS,
  height: '100%',
  overflow: 'hidden',
  paddingBottom: LINE_NUMBER_PADDING_Y,
  paddingLeft: LINE_NUMBER_PADDING_X,
  paddingRight: LINE_NUMBER_PADDING_X,
  paddingTop: LINE_NUMBER_PADDING_Y,
  textAlign: 'right',
  width: LINE_NUMBER_WIDTH
})

const StyledLineNumber = styled(Text, {
  lineHeight: 1.5
})

const CodeViewer: React.FC<CodeViewerProps> = ({ language, data }) => {
  const viewerRef = useRef<HTMLPreElement>(null)
  const lineNumberRef = useRef<HTMLDivElement>(null)

  const { languageLoading, loadedLanguages } = usePrism(language, 'dark')

  const renderLineNumbers = () => {
    const linesCount = data?.replace(/\n+$/, '\n').split('\n').length

    return (
      <StyledLineNumbers direction="column" ref={lineNumberRef}>
        {Array(linesCount).fill(1).map((_, index) => (
          <StyledLineNumber
            as="span"
            color="light300"
            fontSize={14}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {index + 1}
          </StyledLineNumber>
        ))}
      </StyledLineNumbers>
    )
  }

  useEffect(() => {
    const highlight = (viewer: HTMLElement) => {
      if (languageLoading) return

      const code = viewer.textContent || ''
      viewer.innerHTML = Prism.highlight(code, Prism.languages[language], language)
    }

    const onScrollContainer = () => {
      if (lineNumberRef?.current && viewerRef?.current) {
        lineNumberRef.current.scrollTop = viewerRef.current.scrollTop
      }
    }

    const isLoaded = loadedLanguages.includes(language)

    if (viewerRef.current && isLoaded) {
      viewerRef.current?.addEventListener('scroll', onScrollContainer)
      highlight(viewerRef.current)
    }
  }, [ language, languageLoading, loadedLanguages, data ])

  return (
    <ViewerContainer>
      {renderLineNumbers()}

      <StyledViewer ref={viewerRef}>{data}</StyledViewer>
    </ViewerContainer>
  )
}

export type { CodeViewerProps }

export default CodeViewer
