import type { ComputedMenuElement, MenuElementMap } from 'lib/generateDashboard'

type Crumb = ComputedMenuElement & {
  isActive?: boolean
}

export default function generateBreadcrumbs(
  menuElementMap: MenuElementMap,
  activeMenuElement: ComputedMenuElement
): Crumb[] {
  const crumbs: Crumb[] = []

  activeMenuElement.parentIds?.forEach((parentId: any) => {
    const parentMenuElement = menuElementMap[parentId]

    if (!parentMenuElement) {
      return
    }

    if (parentMenuElement.kind === 'ITEM') {
      crumbs.push(parentMenuElement)
    }
  })

  return crumbs
}

export type { Crumb }
