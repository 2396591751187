import React, { useEffect, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { init, SearchIndex } from 'emoji-mart'
import { usePopper } from 'react-popper'

import Flex from 'components/layout/Flex'
import PopoverItem, { POPOVER_ITEM_TEXT_COLORS } from 'components/popover/PopoverItem'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import { Popover, PopoverBody } from 'components/popover'
import { styled } from 'styles/stitches'

type EmojiPickerProps = {
  referenceElement: HTMLElement | null,
  onChange: (value: string | null) => void,
  value: string
}

const StyledMenuItem = styled(PopoverItem, {
  '&:hover, &:focus': {
    '[data-text]': {
      color: POPOVER_ITEM_TEXT_COLORS.hover
    }
  },
  '& [data-text]': {
    color: POPOVER_ITEM_TEXT_COLORS.normal,
    fontWeight: 400,
    lineHeight: 1.4,
    whiteSpace: 'pre'
  },
  variants: {
    active: {
      true: {
        backgroundColor: 'light400',
        borderLeftWidth: 0,

        '[data-text]': {
          color: POPOVER_ITEM_TEXT_COLORS.hover
        }
      }
    }
  }
})

const emojiRegex = /(:\w\w+[^\s]*$)/g

let EMOJI_DATA: any = null

const fetchEmojiData = async () => EMOJI_DATA
|| fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
  .then((data) => {
    EMOJI_DATA = data
    return data
  })

function EmojiPicker({ referenceElement, onChange, value }: EmojiPickerProps) {
  const [ emojisList, setEmojisList ] = useState([])
  const [ activeEmojiItem, setActiveEmojiItem ] = useState(0)
  const [ popperElement, setPopperElement ] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'fixed'
  })

  useEffect(() => {
    fetchEmojiData().then(init)
  }, [])

  async function searchEmojis(value: string) {
    await SearchIndex.search(value).then((emojis) => {
      setEmojisList(emojis)
    })
  }

  const handleChange = (e: Event) => {
    // @ts-ignore
    const key = e?.target?.value

    if (emojiRegex.test(key)) {
      const searchText = key.match(emojiRegex) || ''
      searchEmojis(searchText[0].replace(':', ''))
    } else {
      handleCloseEmojiPicker()
    }
  }

  const handleChangeRef = useRef(handleChange)

  const handleCloseEmojiPicker = () => {
    setEmojisList([])
    setActiveEmojiItem(0)
  }

  const handleEmojiSelect = (arg: any) => {
    onChange(value.replace(/(:\w\w+[^\s]*$)/g, arg.skins[0].native))
    handleCloseEmojiPicker()
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (emojisList.length > 0) {
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        setActiveEmojiItem(Math.min(emojisList.length - 1, activeEmojiItem + 1))
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        setActiveEmojiItem(Math.max(0, activeEmojiItem - 1))
      } else if (event.key === 'Enter') {
        event.preventDefault()
        handleEmojiSelect(emojisList[activeEmojiItem])
      } else if (event.key === 'Escape') {
        event.preventDefault()
        event.stopPropagation()
        handleCloseEmojiPicker()
      }
    }
  }

  const handleKeyDownRef = useRef(handleKeyDown)

  useEffect(() => {
    handleChangeRef.current = handleChange
    handleKeyDownRef.current = handleKeyDown
  })

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => handleKeyDownRef.current(event)
    const changeHandler = (event: Event) => handleChangeRef.current(event)

    referenceElement?.addEventListener('keydown', keydownHandler, { passive: true, capture: false })
    referenceElement?.addEventListener('input', changeHandler, { passive: true, capture: false })

    return () => {
      referenceElement?.removeEventListener('keydown', keydownHandler, { capture: false })
      referenceElement?.removeEventListener('input', changeHandler, { capture: false })
    }
  }, [ referenceElement ])

  return (
    <Portal>
      {emojisList.length > 0 && (
        <Popover
          referenceElement={referenceElement}
          closePopover={handleCloseEmojiPicker}
          style={{
            ...styles.popper,
            width: referenceElement?.offsetWidth
          }}
          ref={setPopperElement}
          {...attributes.popper}
        >
          <Scrollbars autoHeight>
            <PopoverBody>
              {emojisList.map((emoji: any, index: number) => {
                const isActive = activeEmojiItem === index
                return (
                  <StyledMenuItem
                    key={emoji.id}
                    active={isActive}
                    forceActive={isActive}
                    onClick={() => handleEmojiSelect(emoji)}
                    scrollActiveIntoView
                    css={{ padding: 10 }}
                  >
                    <Flex gap={10}>
                      <Text>{emoji.skins[0].native}</Text>
                      <Text>{emoji.skins[0].shortcodes}</Text>
                    </Flex>
                  </StyledMenuItem>
                )
              })}
            </PopoverBody>
          </Scrollbars>
        </Popover>
      )}
    </Portal>
  )
}

export default EmojiPicker
