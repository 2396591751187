import { gql } from '@apollo/client'

import type { ClientState } from 'client/state/types'

/* Default Data */

const DEFAULT_PROSPECT: Prospect = {
  __typename: 'Prospect',
  id: null,
  email: null,
  expiresAt: null,
  token: null
}

/* Types */

export type Prospect = {
  __typename: 'Prospect',
  id: string | null,
  email: string | null,
  expiresAt: string | null,
  token: string | null
}

export type ProspectInput = Pick<Prospect, 'id' | 'email' | 'expiresAt' | 'token'>

export type ProspectQuery = {
  prospect: Prospect
}

/* Queries */

export const PROSPECT_QUERY = gql`
  query ProspectQuery {
    prospect @client {
      id
      email
      expiresAt
      token
    }
  }
`

/* Default Query */

export const writeDefaults = {
  query: PROSPECT_QUERY,
  data: { prospect: DEFAULT_PROSPECT }
}

/* Mutations */

export const SET_PROSPECT_MUTATION = gql`
  mutation SetProspectMutation($prospect: ProspectInput!) {
    setProspect(prospect: $prospect) @client
  }
`

/* ClientState */

export default {
  defaults: {
    query: PROSPECT_QUERY,
    data: { prospect: DEFAULT_PROSPECT }
  },

  resolvers: {
    Mutation: {
      setProspect: (_, { prospect }, { cache }) => {
        cache.writeQuery({
          query: PROSPECT_QUERY,
          data: {
            prospect: { ...DEFAULT_PROSPECT, ...prospect }
          }
        })

        return null
      }
    }
  }
} as ClientState
