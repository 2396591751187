import React, { ReactNode } from 'react'

import Flex from 'components/layout/Flex'
import { INNER_CONTAINER_PADDING } from 'components/dashboardEditor/constants'
import { styled } from 'styles/stitches'

type DashboardEditorBodyProps = {
  children: ReactNode
}

const StyledInnerContainer = styled(Flex, {
  padding: INNER_CONTAINER_PADDING,
  overflow: 'auto',
  height: '100%'
})

const DashboardEditorBody = ({
  children,
  ...others
}: DashboardEditorBodyProps) => (
  <>
    <StyledInnerContainer grow={1} direction="column" gap={30} {...others}>
      {children}
    </StyledInnerContainer>
  </>
)

export default DashboardEditorBody
