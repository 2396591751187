import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ExternalRoute from 'components/routes/ExternalRoute'
import NewWorkspacePage from 'components/pages/NewWorkspacePage'
import NotFoundPage from 'components/pages/NotFoundPage'
import StartPage from 'components/pages/StartPage'
import VerifyEmailPage from 'components/pages/VerifyEmailPage'
import WorkspacesPage from 'components/pages/WorkspacesPage'

function AppContainer() {
  return (
    <Switch>
      <Redirect exact from="/" to="/start" />

      <ExternalRoute path="/start" component={StartPage} />
      <ExternalRoute path="/verify-email" component={VerifyEmailPage} />
      <ExternalRoute path="/workspaces/new" component={NewWorkspacePage} />
      <ExternalRoute path="/workspaces" component={WorkspacesPage} />

      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default AppContainer
