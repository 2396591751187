import arrayMutators from 'final-form-arrays'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import merge from 'lodash/merge'
import React, { useContext, useMemo } from 'react'
import set from 'lodash/set'
import size from 'lodash/size'
import sum from 'lodash/sum'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { FormApi, FormState } from 'final-form'

import * as mixins from 'styles/mixins'
import Block from './Block'
import Button, { ButtonProps } from 'components/buttons/Button'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import JSONParseOr from 'lib/JSONParseOr'
import Loader from 'components/loaders/Loader'
import ParameterFields from 'components/resource/ParameterFields'
import Portal from 'components/portal/Portal'
import Tab from 'components/tabs/Tab'
import Tabs, { useTabs } from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import useActiveLocales, { Locale } from 'hooks/useActiveLocales'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { createFocusOnErrors } from 'lib/formDecorators/focusOnErrors'
import {
  InternalAddRecordInput,
  InternalEditRecordInput,
  InternalSearchRecordsDocument,
  InternalSummarizeRecordsDocument,
  Operation,
  Parameter,
  useExecuteMutationOperationMutation,
  useInternalAddRecordMutation,
  useInternalEditRecordMutation,
  useOperationQuery
} from 'generated/schema'
import { safeParseLiquid } from 'lib/templater'
import { styled } from 'styles/stitches'
import { useViewDispatch } from 'hooks/useViewContext'
import { Views } from 'components/dashboardEditor/constants'
import type { BlockProps } from './Block'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { GetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'

type FormValues = InternalAddRecordInput | InternalEditRecordInput | Record<any, any>;

type FormBlockProps = BlockProps & {
  asFragment?: boolean,
  initialValues?: Record<any, any>,
  operationId: string,
  resourceId?: string,
  targetEnvironment?: string,
  prefix?: string,
  footerEl?: HTMLDivElement | null,
  onChange?: (values: FormState<FormValues>) => void,
  isTranslatable?: boolean,
  publishedId?: string,
  latestId?: string,
  isPublishingEnabled?: boolean
}

const TAB_PADDING = 35
const TAB_NOTIFICATION_SIZE = 8

const StyledTabsWrapper = styled(Flex, {
  border: '1 solid light700',
  borderWidth: 0.5
})

const StyledTab = styled(Tab, {
  padding: TAB_PADDING,

  variants: {
    background: {
      light: {
        backgroundColor: 'light100'
      },
      none: {}
    }
  }
})

const StyledTabNotification = styled(Flex, {
  ...mixins.size(TAB_NOTIFICATION_SIZE),

  background: 'negative700',
  borderRadius: '50%',
  color: 'light700',
  position: 'absolute',
  right: 0,
  top: 8
})

const focusOnErrors = createFocusOnErrors<FormValues>()

type LocaleTabNotificationBadgeProps = {
  index: number,
  parameters: Parameter[],
  locale: Locale['identifier']
}

const LocaleTabNotificationBadge = ({
  index, parameters, locale
}: LocaleTabNotificationBadgeProps) => {
  const { activeIndex } = useTabs()

  if (index === activeIndex) {
    return null
  }

  return (
    <FormSpy subscription={{ errors: true, submitFailed: true }}>
      {({ errors, submitFailed }) => {
        const notificationCount = sum(parameters.map(
          (parameter) => size(errors?.arguments?.[camelCase(parameter.identifier)]?.[locale]) || 0
        ))

        return submitFailed && Boolean(notificationCount) && (
          <StyledTabNotification justifyContent="center" alignItems="center" />
        )
      }}
    </FormSpy>
  )
}

const SubmitButton = ({ submitting, isPublishingEnabled, ...rest }: Pick<FormRenderProps, 'submitting'> & ButtonProps) => {
  const uploadingMediaFields = useContext(GetMediaFieldStateContext)

  return (
    <Button
      key="submit-button"
      disabled={submitting || uploadingMediaFields.length}
      label={isPublishingEnabled ? 'Save Draft' : 'Save'}
      type="submit"
      {...rest}
    />
  )
}

function FormBlock({
  heading,
  initialValues,
  operationId,
  fields,
  resourceId,
  asFragment,
  footerEl,
  blockRef,
  prefix,
  identifier,
  onChange,
  isTranslatable,
  publishedId,
  latestId,
  isPublishingEnabled,
  switcher: _switcher,
  ...others
}: FormBlockProps) {
  const { switcher = _switcher } = useDashboardViewContext()
  const { blockPropertiesState, selectBlock, openDashboardEditorView } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)
  const { openDashboardEditor } = useContext(DashboardContext)!

  const targetEnvironment = switcher?.data.environment?.id

  const formInitialValues = useMemo(() => {
    let values = {}
    if (typeof initialValues === 'string') {
      values = JSONParseOr(safeParseLiquid(initialValues, blockProperties), {})
    } else if (initialValues) {
      values = initialValues
    }

    return {
      operationId,
      resourceId,
      targetEnvironment,
      arguments: values
    }
  }, [ blockProperties, initialValues, operationId, resourceId, targetEnvironment ])

  const isUpdating = typeof formInitialValues.arguments === 'object' && 'id' in formInitialValues.arguments

  const {
    data: { operation } = {},
    loading: operationLoading,
    error: operationError
  } = useOperationQuery({
    variables: { id: operationId },
    skip: !operationId
  })

  const { closeView } = useViewDispatch()

  const createMutationOptions = {
    onCompleted: () => closeView(),
    refetchQueries: [ InternalSearchRecordsDocument, InternalSummarizeRecordsDocument ]
  }

  const [ executeOperation ] = useExecuteMutationOperationMutation({
    onCompleted: () => closeView()
  })

  const [ createResource ] = useInternalAddRecordMutation(createMutationOptions)
  const [ updateResource ] = useInternalEditRecordMutation({ onCompleted: () => closeView() })

  const handleExecuteOperation = useSubmitHandler(executeOperation, {
    successAlert: { message: `${operation?.name || 'Operation'} successful.` }
  })

  const handleCreateResource = useSubmitHandler(createResource, {
    successAlert: operation
      ? { message: `${operation.resource?.name} added.` }
      : undefined
  })

  const handleUpdateResource = useSubmitHandler(updateResource, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'internalEditRecord',
      typename: 'Record',
      override: (values: InternalEditRecordInput) => ({
        ...formInitialValues,
        data: values
      })
    },
    successAlert: operation?.resource
      ? { message: `${operation.resource.name} updated.` }
      : undefined
  })

  const handleSubmit = (values: FormValues, form: FormApi<FormValues>) => {
    const args = (operation?.parameters || [])
      .map((param) => {
        const key = resourceId ? camelCase(param.identifier) : param.identifier

        const localisedValues = activeLocales.map((locale) => {
          const paramKey = `arguments.${key}.${locale.identifier}`
          const paramValue = get(values, paramKey)

          if (paramValue === undefined) {
            return undefined
          }

          return {
            [locale.identifier]: paramValue
          }
        }).filter(Boolean)

        if (!localisedValues.length) {
          return undefined
        }

        return set(
          {},
          key,
          localisedValues.reduce((acc: any, curr: any) => ({
            ...acc,
            ...curr
          }), {})
        )
      })

    if (resourceId) {
      const formValues: InternalEditRecordInput = {
        resourceId,
        arguments: args.reduce((acc, curr) => ({ ...acc, ...curr })),
        targetEnvironment,
        versionId: latestId
      }

      if (isUpdating) {
        return handleUpdateResource(
          formValues as InternalEditRecordInput, form as FormApi<InternalEditRecordInput>
        )
      }

      return handleCreateResource(formValues as InternalAddRecordInput)
    }

    const formValues = {
      operationId,
      arguments: args.reduce((acc, curr) => merge({}, acc, curr), { ...values.arguments }),
      targetEnvironment
    }

    return handleExecuteOperation(formValues)
  }

  const parameters = fields
    ? fields.map((field: any) => {
      if (field.is_hidden) return null
      return operation?.parameters.find((p) => p.id === field.parameter)
    }).filter(Boolean)
    : operation?.parameters

  const {
    activeLocales,
    defaultLocale,
    loading: localesLoading
  } = useActiveLocales()

  const [ _, setLocaleIndex ] = React.useState(0)

  const formBlock = (
    <Form
      decorators={[ focusOnErrors ]}
      keepDirtyOnReinitialize={!!resourceId}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators
      }}
      subscription={{
        submitting: true
      }}
      render={({ handleSubmit, submitting }) => {
        if (!resourceId && !operationId) {
          return (
            <Flex direction="column" gap={18} alignItems="center" css={{ padding: 24 }}>
              <Text>New Form</Text>
              <Button
                mode="subtle"
                label="Configure"
                size="small"
                variant="outline"
                onClick={() => {
                  selectBlock(others.id)
                  openDashboardEditor()
                  openDashboardEditorView({
                    target: Views.EDIT_BLOCK
                  })
                }}
              />
            </Flex>
          )
        }

        const renderContentForm = (currentLocale?: Locale) => (
          <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
            {heading && <Text fontWeight="bold">{heading}</Text>}
            <Loader
              loading={operationLoading || localesLoading}
              data={operation && activeLocales}
              error={operationError}
            >
              <ParameterFields
                currentLocale={(resourceId || operation?.resourceId) ? currentLocale : undefined}
                defaultLocale={(resourceId || operation?.resourceId) ? defaultLocale : undefined}
                isUpdating={isUpdating}
                operation={operation as Operation}
                parameters={parameters as Parameter[]}
                prefix="arguments"
                resourceId={operation?.resource?.id!}
                targetEnvironmentId={targetEnvironment}
              />
            </Loader>
            <input style={{ display: 'none' }} type="submit" />
            {onChange && <FormSpy onChange={onChange} />}
          </Flex>
        )

        return (
          <>
            {resourceId && isTranslatable && activeLocales?.length > 1 && !!parameters?.length
              ? (
                <StyledTabsWrapper direction="column">
                  <Tabs
                    onChange={setLocaleIndex}
                  >
                    {activeLocales.map((locale, index) => (
                      <StyledTab
                        index={index}
                        key={locale.identifier}
                        label={locale.name}
                        appendHeaderNode={(
                          <LocaleTabNotificationBadge
                            index={index}
                            parameters={parameters}
                            locale={locale.identifier}
                          />
                          )}
                        background="light"
                        alwaysMounted
                      >
                        {renderContentForm(locale)}
                      </StyledTab>
                    ))}
                  </Tabs>
                </StyledTabsWrapper>
              ) : renderContentForm(defaultLocale)}

            {footerEl ? (
              <Portal target={footerEl}>
                <SubmitButton
                  submitting={submitting}
                  onClick={handleSubmit}
                  isPublishingEnabled={isPublishingEnabled}
                />
              </Portal>
            ) : (
              <Flex gap={24} direction="row-reverse">
                <SubmitButton
                  submitting={submitting}
                  onClick={handleSubmit}
                  isPublishingEnabled={isPublishingEnabled}
                />
              </Flex>
            )}
          </>
        )
      }}
    />
  )

  if (asFragment) {
    return formBlock
  }

  return (
    <Block direction="column" masonryItemRef={blockRef} {...others}>
      {formBlock}
    </Block>
  )
}

export type { FormBlockProps }

export default React.memo(FormBlock)
