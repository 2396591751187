import React from 'react'
import startCase from 'lodash/startCase'
import { useRecoilValue } from 'recoil'

import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard, { Block } from 'hooks/useDashboard'
import { BLOCK_TYPE_TO_ICON_MAP } from './constants'
import type { ActiveViewProps } from './DashboardEditor'
import type { ComputedMenuElement } from 'lib/generateDashboard'

const getConfig = (hoveredComponent: any, hoveredComponentType: any) => {
  if (hoveredComponentType === 'Block') {
    const { type, properties = {} } = hoveredComponent as Block

    return {
      icon: BLOCK_TYPE_TO_ICON_MAP[type!] || 'app',
      title: properties.title,
      text: startCase(type)
    }
  }

  if (hoveredComponentType === 'MenuElement') {
    const {
      renderedName,
      target,
      icon,
      kind,
      separatorStyle
    } = hoveredComponent as ComputedMenuElement

    if (kind === 'SEPARATOR') {
      return {
        icon: 'separator',
        title: startCase(separatorStyle?.toLowerCase()),
        text: 'Separator'
      }
    }

    if (kind === 'GROUP') {
      return {
        icon: 'heading',
        title: 'Header',
        text: 'Menu'
      }
    }

    return {
      icon,
      title: renderedName,
      text: startCase(target?.toLowerCase())
    }
  }

  return {
    title: 'Default',
    icon: 'app',
    text: ''
  }
}

const EditComponentView = ({ onClose }: ActiveViewProps) => {
  const { selectedBlockState, selectedMenuState } = useDashboard()

  const selectedBlock = useRecoilValue(selectedBlockState)
  const selectedMenu = useRecoilValue(selectedMenuState)

  const element = selectedBlock || selectedMenu
  // eslint-disable-next-line no-nested-ternary
  const elementType = selectedBlock ? 'Block'
    : selectedMenu ? 'MenuElement' : 'null'

  const { title, icon, text } = getConfig(element, elementType)

  return (
    <>
      <DashboardEditorHeader
        subtitle="Edit Component"
        heading="Dashboard Editor"
        onClose={onClose}
      />
      <DashboardEditorBody>
        <Flex gap={16} direction="column">
          <Text color="dark900" fontSize={12}>
            Select any block, element, or menu from the dashboard to edit it.
          </Text>
          <DashboardEditorLoader
            empty={{
              variant: 'simple',
              element: (
                <Flex alignItems="center" direction="column">
                  <Text fontSize={14} color="dark500">Nothing selected.</Text>
                </Flex>
              )
            }}
            data={element}
          >
            <Flex alignItems="center" direction="column" gap={16}>
              <MediaCard
                compact
                media={icon}
                title={title}
                titlePosition="top"
                text={text}
                height={64}
                width="full"
              />
              <Text color="dark400">Click to select</Text>
            </Flex>
          </DashboardEditorLoader>
        </Flex>
      </DashboardEditorBody>
    </>
  )
}

export default EditComponentView
