import get from 'lodash/get'
import React from 'react'

import Text from 'components/typography/Text'
import { ContactKind } from 'models/Contact'
import { styled } from 'styles/stitches'
import type { Contact } from 'generated/schema'
import type { RendererOptions } from 'components/dataTable/types'

const StyledText = styled(Text, {
  color: 'currentColor',
  flexGrow: 1,
  width: 0
})

function ContactValueRenderer<T>({
  rowData,
  dataKey
}: RendererOptions<Contact> | RendererOptions<T>) {
  let data = get(rowData, dataKey)
  const kind = get(rowData, 'kind')

  if (kind === ContactKind.PHONE || kind === ContactKind.EMAIL) {
    data = get(rowData, 'value')
  }

  return (
    <StyledText
      as="span"
      title={data}
      truncate
    >
      {data}
    </StyledText>
  )
}

export default ContactValueRenderer
