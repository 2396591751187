import React from 'react'

import Flex from 'components/layout/Flex'
import Loader, { LoaderProps } from 'components/loaders/Loader'

const LOADER_WRAPPER_PADDING = 32

function Wrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        border: '1px dashed dark100',
        borderRadius: 6,
        padding: LOADER_WRAPPER_PADDING,
        width: '100%'
      }}
    >
      {children}
    </Flex>
  )
}

function DashboardEditorLoader(props: LoaderProps) {
  return (
    <Loader
      wrapper={Wrapper}
      size="compact"
      {...props}
    />
  )
}

export default DashboardEditorLoader

export { LOADER_WRAPPER_PADDING }
