import React from 'react'
import type { PropsWithChildren } from 'react'
import type { DraggableProvided } from 'react-beautiful-dnd'

import ActionIcon from 'components/icons/ActionIcon'
import Flex, { FlexProps } from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import type { IconProps } from 'components/icons/Icon'

type FieldGroupOwnProps = Partial<IconProps> & FlexProps & {
  deleteIcon?: string,
  isDraggable?: boolean,
  hasFieldLabel?: boolean,
  onClick?: () => void
}

function FieldGroup({
  deleteIcon = 'trash',
  children,
  onClick,
  isDraggable,
  hasFieldLabel,
  dragHandleProps,
  ...flexProps
}: Pick<DraggableProvided, 'dragHandleProps'> & PropsWithChildren<FieldGroupOwnProps>) {
  return (
    <Flex
      alignItems="center"
      gap={10}
      grow={1}
      {...flexProps}
    >
      {isDraggable && <IconButton size={10} hideTooltip description="Drag handle" name="drag-alt" variant="dark" {...dragHandleProps} />}
      {children}
      {onClick && (
        <ActionIcon
          style={{ paddingTop: hasFieldLabel ? 18 : 0 }}
          name={deleteIcon}
          size={16}
          onClick={onClick}
          tabIndex={0}
        />
      )}
    </Flex>
  )
}

export default FieldGroup
