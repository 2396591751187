import omit from 'lodash/omit'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import AppError from 'components/errors/AppError'
import AppLoader from 'components/loaders/AppLoader'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import ResetPasswordForm from 'components/forms/ResetPasswordForm'
import useQueryParams from 'hooks/useQueryParams'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { SET_SESSION_MUTATION } from 'client/state/session'
import { useResetPasswordMutation, useVerifyResetPasswordTokenQuery } from 'generated/schema'
import type { ResetPasswordFormValues } from 'components/forms/ResetPasswordForm'
import type { ResetPasswordInput } from 'generated/schema'

function ResetPasswordPage() {
  const queryParams = useQueryParams()
  const token = queryParams.get('token') || ''

  const { data, loading, error } = useVerifyResetPasswordTokenQuery({
    errorPolicy: 'all', // TODO: Remove later: https://github.com/apollographql/apollo-client/issues/5978
    variables: { input: { token } }
  })

  const [ setSession ] = useMutation(SET_SESSION_MUTATION)
  const [ resetPassword ] = useResetPasswordMutation({
    onCompleted: ({ resetPassword: session }) => {
      setSession({ variables: { session } })
    }
  })
  const handleSubmit = useSubmitHandler(resetPassword, {
    successAlert: { message: 'You have reset your password successfully.' },
    failureAlert: { message: 'We were unable to reset your password. Please try again.' }
  })

  const onFormSubmit = (values: ResetPasswordInput) => handleSubmit(omit(values, 'passwordConfirmation'))

  if (!token) {
    return <Redirect to="/forgot-password" />
  }

  if (loading && !data) {
    return <AppLoader />
  }

  if (error || !data || !data.verifyResetPasswordToken.success) {
    return <AppError defaultCode={404} defaultMessage="Your reset password token is either invalid or expired." error={error} />
  }

  return (
    <Flex direction="column" gap={48}>
      <ExternalTitle>Reset Password</ExternalTitle>
      <ResetPasswordForm
        initialValues={{ token } as ResetPasswordFormValues}
        onSubmit={onFormSubmit}
      />
    </Flex>
  )
}

export default ResetPasswordPage
