import React, { useMemo, Suspense } from 'react'
import get from 'lodash/get'
import { useFormState } from 'react-final-form'

import componentLoader from 'lib/componentLoader'
import FieldModel, { FieldIdentifier } from 'models/Field'
import pascalCase from 'lib/pascalCase'
import TextInput from 'components/inputs/TextInput'
import { RepeatedField } from 'components/contentEditors/generic/fields'
import type { FieldsListQuery } from 'generated/schema'

type DefaultValueFieldProps = {
  identifier: FieldIdentifier,
  fieldPrefix?: string
}

const getFieldName = (identifier?: string) => {
  if (!identifier) return ''

  // text-field -> TextField
  return `${pascalCase(identifier)}`
}

const fieldTypeFactoryWithFallback = (fieldTypeName: string) => () => componentLoader(`contentEditors/generic/fields/${fieldTypeName}`, { suppressAlert: true })
  .catch(() => componentLoader('contentEditors/generic/fields/TextField'))

const getProps = (
  values: any,
  identifier: FieldIdentifier,
  repeatableFieldName: string,
  fieldPrefix?: string
) => {
  const settings = get(values, `${fieldPrefix}settings`)

  switch (identifier) {
    case FieldIdentifier.COLOR:
      return {}

    case FieldIdentifier.DATE:
      return {
        inputFormat: settings?.format
      }

    case FieldIdentifier.DROPDOWN:
      return {
        closeMenuOnSelect: !values[repeatableFieldName],
        field: values,
        isCreatable: settings?.optionsCreatable,
        isMulti: values[repeatableFieldName],
        [repeatableFieldName]: values[repeatableFieldName]
      }

    case FieldIdentifier.MEDIA:
    case FieldIdentifier.FILE:
      return {
        [repeatableFieldName]: values[repeatableFieldName],
        isPreview: true
      }

    case FieldIdentifier.SWITCH:
      return {
        showGuide: false,
        labelPosition: 'left'
      }

    case FieldIdentifier.TEXT:
      return {
        autogrow: settings?.multilineAutogrow,
        rows: settings?.multilineVisibleRows
      }

    case FieldIdentifier.REFERENCE:
      return {
        restrictions: values.restrictions,
        [repeatableFieldName]: values[repeatableFieldName],
        field: values
      }

    default:
      return {}
  }
}

type Field = FieldsListQuery['fieldsList'][number]

const DefaultValueField = ({ identifier, fieldPrefix = '' }: DefaultValueFieldProps) => {
  const name = 'defaultValue'
  const { values: formValues } = useFormState({ subscription: { values: true } })
  const values = get(formValues, fieldPrefix, {})

  const FieldComponent = useMemo(() => (
    identifier
      ? React.lazy(fieldTypeFactoryWithFallback(getFieldName(identifier)))
      : () => null
  ), [ identifier ])

  if (!FieldComponent) return null

  const commonProps = {
    isTranslatable: formValues.isTranslatable,
    size: formValues.size || 'small',
    settings: formValues.settings || {}
  }

  if (
    !FieldModel.hasCustomRepeatedBehavior(formValues.fieldType as FieldIdentifier)
    && formValues.isArray
  ) {
    return (
      <Suspense fallback={<TextInput {...commonProps} disabled placeholder="Loading..." />}>
        <RepeatedField
          name={name}
          field={formValues as Field}
          renderField={({ settings }, { fieldName }) => (
            <FieldComponent
              name={fieldName}
              {...commonProps}
              {...getProps(values, identifier, fieldPrefix)}
              settings={settings}
            />
          )}
        />
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<TextInput {...commonProps} disabled placeholder="Loading..." />}>
      <FieldComponent
        name={name}
        label={formValues.label}
        {...commonProps}
        {...getProps(values, identifier, fieldPrefix)}
      />
    </Suspense>
  )
}

export default DefaultValueField

export type {
  DefaultValueFieldProps
}
