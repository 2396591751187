import React from 'react'
import type { FormApi, SubmissionErrors } from 'final-form'

import AttachmentEditForm from 'components/forms/AttachmentEditForm'
import type { ViewProps } from 'components/views'

type Params = {
  initialValues: object,
  handleSubmit: (
    values: any,
    form: FormApi<any>,
    callback?: ((errors?: SubmissionErrors | undefined) => void) | undefined
  ) => void | SubmissionErrors | Promise<any> | undefined
}

const title = 'Edit Attachment'

function AttachmentEditView({
  onRequestClose,
  params: { initialValues, handleSubmit },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <AttachmentEditForm
              initialValues={initialValues}
              onSubmit={(values, ...rest) => {
                handleSubmit(values, ...rest)
                onRequestClose()
              }}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default AttachmentEditView
