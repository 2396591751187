import uuid from 'uuid-random'
import React from 'react'
import isEqual from 'lodash/isEqual'

import convertToArray from 'lib/convertToArray'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import type { ComputeDiffProps } from 'components/contentVersion/VersionDiff'

type NumberFieldProps = {
  value: number[]
}
function NumberField({ value }: NumberFieldProps) {
  return (
    <Flex direction="column" gap={8}>
      {value.map((item) => (
        <Text key={uuid()}>{item}</Text>
      ))}
    </Flex>
  )
}

NumberField.computeDiff = <T extends number | number[]>({
  previousValue, currentValue
}: ComputeDiffProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  return {
    previousNode: <NumberField value={previous} />,
    currentNode: <NumberField value={current} />,
    isDiff: !isEqual(previousValue, currentValue)
  }
}

export default NumberField
