import React from 'react'
import { Form, FormProps } from 'react-final-form'

import AccountTypeModel from 'models/AccountType'
import Button from 'components/buttons/Button'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import type { CompleteOnboardingInput } from 'generated/schema'

// these fields should be omitted
const AUTHENTICATION_FIELDS_IDENTIFIERS = [ 'email', 'password' ]
const FAILING_FIELDS_IDENTIFIERS = [ 'avatar', 'phone' ]

const renderField = AccountTypeModel.renderField({ size: 'normal' })

const fields = [
  {
    fieldType: 'text-field',
    identifier: 'first_name',
    isPredefined: true,
    name: 'First Name'
  },
  {
    fieldType: 'text-field',
    identifier: 'last_name',
    isPredefined: true,
    name: 'Last Name'
  },
  {
    fieldType: 'file-field',
    identifier: 'avatar',
    isPredefined: true,
    name: 'Avatar'
  }
] as const

const isNotAuthField = ({ identifier }: typeof fields[number]) => !AUTHENTICATION_FIELDS_IDENTIFIERS
  .includes(identifier)
const isNotFailingField = ({ identifier }: typeof fields[number]) => !FAILING_FIELDS_IDENTIFIERS
  .includes(identifier)

const CompleteOnboardingForm = ({ ...rest }: FormProps<CompleteOnboardingInput>) => (
  <Form
    subscription={{ submitting: true, pristine: true }}
    initialValuesEqual={() => true}
    render={({ handleSubmit, submitting, pristine }) => (
      <Flex as="form" direction="column" gap={48} onSubmit={handleSubmit}>
        <ExternalTitle>Enter Your Profile Information</ExternalTitle>
        {fields.length > 0 && (
          <Grid columns={1} gap={16}>
            {fields.filter(isNotAuthField).filter(isNotFailingField).map(renderField)}
          </Grid>
        )}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            disabled={submitting || pristine}
            label="Continue"
          />
        </Flex>
      </Flex>
    )}
    {...rest}
  />
)

export default CompleteOnboardingForm
