import React from 'react'

import Block, { BlockProps } from './Block'
import TextAreaInput from 'components/inputs/TextAreaInput'

type NotesBlockProps = BlockProps & {
  text: string
}

function NotesBlock(
  { blockRef, text, ...other }: NotesBlockProps
) {
  return (
    <Block masonryItemRef={blockRef} {...other}>
      <TextAreaInput defaultValue={text} />
    </Block>
  )
}

export default NotesBlock
