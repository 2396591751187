import isEqual from 'lodash/isEqual'
import React from 'react'
import uuid from 'uuid-random'

import AttributeModel from 'models/Attribute'
import Card from 'components/card/Card'
import convertToArray from 'lib/convertToArray'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import Text from 'components/typography/Text'
import useDeepMemo from 'hooks/useDeepMemo'
import { useAttributesListQuery, useInternalSearchRecordsQuery, useResourceQuery } from 'generated/schema'
import type { ComputeDiffWithOptionsProps } from 'components/contentVersion/VersionDiff'
import type { Attribute } from 'generated/schema'

type Options = {
  field: Attribute,
  activeLocale: string,
  defaultLocale: string
}

type Reference = {
  id: string
}

type ReferenceFieldProps<T> = {
  options: Options,
  values: T[]
}

function ReferenceFieldNew({ values, options }: ReferenceFieldProps<string>) {
  const memoizedKeyedValues = useDeepMemo(() => (
    values.map((id) => ({ id, key: uuid() }))
  ), values)

  const { field, activeLocale, defaultLocale } = options || {}

  const recordIds = memoizedKeyedValues.map(({ id }) => id)
  // @ts-ignore
  const targetResourceId = field.fieldTypeSettings?.references?.[0]

  const { data: { internalSearchRecords = [] } = {} } = useInternalSearchRecordsQuery({
    variables: {
      input: {
        preview: true,
        // @ts-ignore
        resourceId: targetResourceId,
        filter: {
          id: {
            in: recordIds
          }
        }
      }
    }
  })

  const { data: { attributesList = [] } = {} } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: targetResourceId
        }
      }
    },
    skip: !targetResourceId
  })

  const { data: { resource } = {} } = useResourceQuery({
    variables: {
      id: targetResourceId
    },
    skip: !targetResourceId
  })

  if (!internalSearchRecords.length || !field || !attributesList.length) {
    return null
  }

  const recordsListMap = getPropertyToElementMap(internalSearchRecords, 'id')

  const renderReferenceContent = (recordId: string, key: string) => {
    const content = recordsListMap[recordId]

    const title = AttributeModel.resolveTitle(
      attributesList,
      content.data,
      resource,
      {
        activeLocale,
        defaultLocale
      }
    )

    return (
      <Card key={key} direction="row" gap={12}>
        <Text fontSize={12} fontWeight="bold">
          {resource?.name}
        </Text>
        {title && (
          <>
            <Flex alignSelf="stretch">
              <Divider orientation="vertical" />
            </Flex>
            <Text fontSize={12}>
              {title}
            </Text>
          </>
        )}
      </Card>
    )
  }

  return (
    <Flex direction="column" gap={12}>
      {memoizedKeyedValues.map(({ id, key }) => renderReferenceContent(id, key))}
    </Flex>
  )
}

function ReferenceField({ values, options }: ReferenceFieldProps<any>) {
  return <ReferenceFieldNew {...{ values, options }} />
}

ReferenceField.computeDiff = <T extends Reference | Reference[]>({
  previousValue, currentValue, options
}: ComputeDiffWithOptionsProps<T>) => {
  const previous = convertToArray(previousValue)
  const current = convertToArray(currentValue)

  return {
    previousNode: <ReferenceField options={options} values={previous} />,
    currentNode: <ReferenceField options={options} values={current} />,
    isDiff: !isEqual(previous, current)
  }
}

export default ReferenceField
