import React from 'react'

import Flex, { FlexProps } from 'components/layout/Flex'

function PopoverBody(props: FlexProps) {
  return (
    <Flex css={{ backgroundColor: 'light400' }} direction="column" {...props} />
  )
}

export default PopoverBody
