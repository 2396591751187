import isEqual from 'lodash/isEqual'
import React from 'react'

import convertToArray from 'lib/convertToArray'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import Loader from 'components/loaders/Loader'
import { styled } from 'styles/stitches'
import { useAssetQuery } from 'generated/schema'
import type { ComputeDiffProps } from 'components/contentVersion/VersionDiff'

type MediaFieldValue = {
  id: string
}

type MediaFieldProps = {
  value: MediaFieldValue[]
}

const size = 110

const StyledWrapper = styled(Flex, {
  size: [ size ],
  background: 'dark100',
  borderRadius: 6
})

const StyledImg = styled('img', {
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  objectFit: 'cover'
})

const StyledField = ({ asset }: {asset: any}) => {
  const assetId = asset?.id || asset
  const { data, loading } = useAssetQuery({
    variables: { id: assetId },
    skip: !!asset.url || !assetId
  })

  const assetUrl = asset?.url || data?.asset?.url
  const assetName = asset?.name || data?.asset?.name

  return (
    <StyledWrapper key={assetUrl}>
      {loading && !assetUrl ? (
        <Flex grow={1} justifyContent="center" alignItems="center">
          <Loader loading />
        </Flex>
      ) : (
        <StyledImg src={assetUrl} alt={assetName} />
      )}
    </StyledWrapper>
  )
}

function MediaField({ value }: MediaFieldProps) {
  return (
    <Grid gap={10} alignItems="center" css={{ gridTemplateColumns: `repeat(auto-fit, ${size}px)` }}>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {value.map((asset, index) => <StyledField asset={asset} key={index} />)}
    </Grid>
  )
}

MediaField.computeDiff = <T extends MediaFieldValue | MediaFieldValue[]>({
  previousValue, currentValue
}: ComputeDiffProps<T>) => ({
    previousNode: <MediaField value={convertToArray(previousValue)} />,
    currentNode: <MediaField value={convertToArray(currentValue)} />,
    isDiff: !isEqual(previousValue, currentValue)
  })

export default MediaField
