import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'

import Account from 'models/Account'
import Button from 'components/buttons/Button'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import { PasswordField } from 'components/contentEditors/generic/fields'
import type { StartOnboardingInput } from 'generated/schema'

const StartOnboardingForm = (props: FormProps<StartOnboardingInput>) => (
  <Form
    validate={(values) => Account.validate(values, [ 'password', 'passwordConfirmation' ])}
    render={({ handleSubmit, submitting, pristine }) => (
      <Flex as="form" direction="column" gap={48} onSubmit={handleSubmit}>
        <ExternalTitle>Set Your Password</ExternalTitle>
        <Flex direction="column" gap={16}>
          <Field name="invitationToken" component="input" type="hidden" />
          <PasswordField autoComplete="on" name="password" placeholder="Enter password" />
          <PasswordField autoComplete="on" name="passwordConfirmation" placeholder="Confirm password" />
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            disabled={submitting || pristine}
            label="Continue"
          />
        </Flex>
      </Flex>
    )}
    {...props}
  />
)

export default StartOnboardingForm
