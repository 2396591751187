import React, { useState } from 'react'
import startCase from 'lodash/startCase'

import Button from 'components/buttons/Button'
import DataTableBlock from 'components/blocks/DataTableBlock'
import EnvironmentSwitcher from 'components/switcher/EnvironmentSwitcher'
import GroupView from './GroupView'
import SearchBar from 'components/searchbar/SearchBar'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TitleBlock from 'components/blocks/TitleBlock'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useSwitcherState from 'hooks/useSwitcherState'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { GroupFormView } from 'components/views'
import { GroupsListQuery, GroupsAggregateDocument, GroupsListDocument, useDestroyGroupMutation } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { Group, GroupsListQueryVariables } from 'generated/schema'
import type { Kind } from 'models/Account'
import type { DashboardViewComponentProps } from 'components/pages/DashboardPage'

type GroupsViewProps = {
  accountKinds: Kind[],
  appId?: DashboardViewComponentProps['appId']
}

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [ {
  position: 'asc'
} ]

function GroupsPage({ accountKinds, appId }: GroupsViewProps) {
  const { openView } = useViewDispatch()
  const switcherState = useSwitcherState(appId ?? null)
  const [ activeKind, setActiveKind ] = useState(0)
  const [ filters, setFilters ] = useState<FilterType>({})
  const [ order, setOrder ] = useState(DEFAULT_ORDER)
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const environmentId = switcherState.switcher.data?.environment?.id

  const accountKind = accountKinds[activeKind]

  const queryVariables = {
    filter: {
      accountKind: { eq: accountKind },
      environmentId: { eq: environmentId },
      ...filters
    },
    limit: pageSize,
    order,
    page
  }

  const [ {
    data: { groupsList: groups = [], groupsAggregate = {} } = {},
    error: groupsError,
    loading: groupsLoading
  }, handleChange ] = useSearch<GroupsListQuery, GroupsListQueryVariables>({
    query: GroupsListDocument,
    queryOptions: {
      variables: queryVariables,
      skip: !environmentId
    },
    keys: [ 'name' ]
  })

  const [ destroyGroup ] = useDestroyGroupMutation({
    onCompleted: () => {
      // If destroyed the last item on the page ...
      if (groups.length === 0 && page !== 1) {
        handlePageChange(page - 1)
      }
    },
    refetchQueries: [ {
      query: GroupsAggregateDocument, variables: { filter: queryVariables.filter }
    } ]
  })

  const handleDestroyGroup = useSubmitHandler(destroyGroup, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyGroup',
      typename: 'Group'
    },
    update: {
      strategy: 'REMOVE',
      query: GroupsListDocument,
      queryVariables,
      dataKey: 'groupsList',
      mutation: 'destroyGroup'
    }
  })

  const confirm = useConfirmation()

  const openGroupForm = (values?: Group) => {
    openView({
      title: `${values?.id ? 'Edit' : 'New'} Group`,
      component: GroupFormView,
      params: {
        initialValues: values || {},
        accountKind,
        environmentId,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const getTitle = () => {
    switch (accountKind) {
      case 'USER':
        return 'User Groups'

      case 'VISITOR':
        return 'Visitor Groups'

      default:
        return 'Groups'
    }
  }

  const columns: Column<Group>[] = [
    { dataKey: 'name',
      title: 'Name',
      fieldType: 'text-field',
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          onClick: () => {
            openView({
              title: `${props.rowData.name} Group`,
              component: GroupView,
              style: GroupView.defaultStyle,
              params: {
                group: props.rowData,
                accountKind,
                environmentId
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      } }
  ]

  const records = [ ...groups ]

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: openGroupForm
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: ({ id, name }: Group) => confirm({
        action: 'delete',
        recordType: 'group',
        recordDescription: name,
        onConfirmClick: () => handleDestroyGroup({ id })
      })
    }
  ]

  const batchActions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: () => {}
    }
  ]

  const dataTableSecondaryElement = (
    <Button icon="add-thin" onClick={() => openGroupForm()} size="small" />
  )

  return (
    <>
      <TitleBlock
        heading={getTitle()}
        secondaryElements={[ <EnvironmentSwitcher key="env-switcher" appId={appId ?? null} {...switcherState} /> ]}
      />
      <DataTableBlock
        actions={actions}
        batchActions={batchActions}
        columns={columns}
        data={records}
        error={groupsError}
        loading={groupsLoading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        primaryElements={[
          accountKinds.length > 1 && (
            <Tabs onChange={(index) => {
              setActiveKind(index)
              handlePageChange(1)
            }}
            >
              {accountKinds.map((kind, index) => <Tab key={kind} index={index} label={`${startCase(kind.toLocaleLowerCase())} Group`} />)}
            </Tabs>
          ),
          <SearchBar placeholder="Search..." onChange={handleChange} loading={groupsLoading} css={{ maxWidth: 240 }} />
        ]}
        secondaryElements={dataTableSecondaryElement}
        totalRows={groupsAggregate?.count || 0}
        filters={filters}
        setFilters={setFilters}
        defaultOrder={order}
        setOrder={setOrder}
      />
    </>
  )
}

export default GroupsPage
