import * as Sentry from '@sentry/browser'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { matchPath, useHistory, useLocation, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useDroppable } from '@dnd-kit/core'
import type { PropsWithChildren } from 'react'

import * as mixins from 'styles/mixins'
import AppError from 'components/errors/AppError'
import AppLoader from 'components/loaders/AppLoader'
import DashboardContext from 'components/contexts/DashboardContext'
import DashboardEditorProvider from 'components/dashboardEditor/DashboardEditorProvider'
import DashboardEditor from 'components/dashboardEditor/DashboardEditor'
import DragDropContextProvider from 'components/providers/DragDropContextProvider'
import ElectronWorkspaceSidebar from 'components/sidebar/ElectronWorkspaceSidebar'
import Flex from 'components/layout/Flex'
import InternalContext from 'components/contexts/InternalContext'
import JumpMenu from 'components/menus/JumpMenu'
import Masonry from 'components/layout/Masonry'
import MediaFieldProvider from 'components/contexts/MediaFieldContext'
import MenuElementPositionProvider from 'components/contexts/MenuElementPositionContext'
import NotFoundPage from 'components/pages/NotFoundPage'
import Sidebar, { getCurrentSidebarWidth } from 'components/sidebar/Sidebar'
import Topbar from 'components/topbar/Topbar'
import TrialBanner, { TRIAL_BANNER_HEIGHT } from 'components/trial/TrialBanner'
import useClientQuery from 'hooks/useClientQuery'
import useHotMenu from 'hooks/useHotMenu'
import ViewStack from 'components/view/ViewStack'
import WelcomeTour from 'components/views/tour/WelcomeTour'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { css } from 'styles/stitches'
import { DASHBOARD_EDITOR_WIDTH, LAYOUT_OVERLAP } from 'components/dashboardEditor/constants'
import { generateDashboard, PERSONAL_DASHBOARD, splitMenuElements, WORKSPACE_DASHBOARD } from 'lib/generateDashboard'
import { isElectron } from 'lib/electron'
import { PREFERENCES_QUERY, SAVE_PREFERENCES_MUTATION } from 'client/state/preferences'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'
import { TourGuideContext, TourType } from 'components/providers/TourProvider'
import { useApps } from 'components/dashboardEditor/app/AppsView'
import { Account, useDashboardsListQuery, useEnvironmentsListQuery, useInstallationsListQuery } from 'generated/schema'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import { useViewDispatch } from 'hooks/useViewContext'
import { WORKSPACE_SIDEBAR_WIDTH } from 'components/sidebar/constants'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PreferencesQuery } from 'client/state/preferences'

type InternalLayoutParams = {
  dashboardIdentifier?: string
}

const JUMP_MENU_HOTKEY = '$mod+J'
const DASHBOARD_EDITOR_HOTKEY = '$mod+Shift+E'
const CONTAINER_PADDING_X = 80
const INSTALLATIONS_LIST_LIMIT = 100
const CONFIGURATIONS_LIST_LIMIT = 100

const content = css({
  marginBottom: 16
})

const scrollWrapperClass = css({
  ...mixins.transition('fastIn'),

  overflowX: 'hidden',
  overflowY: 'scroll', // to avoid jumps we keep it as scroll
  position: 'fixed',
  width: '100vw'
})

const findActiveMenuElement = (elements: ComputedMenuElement[], currentPathname: string) => (
  // If multiple elements match fullPath, prefer the inner-most element
  orderBy(elements.filter((element) => matchPath(currentPathname, { path: element.fullPath, exact: true })), (element) => element.parentIds?.length, 'desc')[0]
)

const findFirstMenuItem = (elements?: ComputedMenuElement[]) => (
  elements?.find((element) => element.kind === 'ITEM')
)

const Droppable = ({ children }: PropsWithChildren<{}>) => {
  const { setNodeRef } = useDroppable({
    id: 'DASHBOARD'
  })

  return <Flex direction="column" grow={1} ref={setNodeRef}>{children}</Flex>
}

const useInitialiseTour = (currentAccount: Account) => {
  const { startTour, skipTour } = useContext(TourGuideContext)!
  const { openView, closeView } = useViewDispatch()

  const unfinishedTour = Object.entries(currentAccount.tours)
    .find(([ _, value ]) => [ 'PENDING', 'SKIPPED' ].includes((value as any).status))
    ?.shift() as TourType
  const unfinishedTourStatus = currentAccount.tours[unfinishedTour]?.status
  const unfinishedTourStep = currentAccount.tours[unfinishedTour]?.step
  useEffect(() => {
    if (!unfinishedTour) {
      return undefined
    }

    const tourSkipped = unfinishedTourStatus === 'PENDING' && unfinishedTourStep === -1
    if (tourSkipped) {
      const handleSkip = () => {
        skipTour(unfinishedTour as TourType)
      }

      const handleStart = () => {
        startTour(unfinishedTour as TourType || TourType.WELCOME_MEMBER_TOUR)
      }

      openView({
        title: 'Welcome Tour',
        component: WelcomeTour,
        params: {
          onSkip: handleSkip,
          onStart: handleStart
        },
        style: 'DIALOG'
      })
    }

    return () => closeView()
  }, [
    closeView,
    openView,
    skipTour,
    startTour,
    unfinishedTour,
    unfinishedTourStatus,
    unfinishedTourStep
  ])
}

function InternalLayout({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation()
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const [ isJumpMenuOpen, { closeMenu: closeJumpMenu, openMenu: openJumpMenu } ] = useHotMenu(
    JUMP_MENU_HOTKEY, { escape: true }
  )

  const [
    isDashboardEditorOpen,
    { closeMenu: closeDashboardEditor, openMenu: openDashboardEditor }
  ] = useHotMenu(
    DASHBOARD_EDITOR_HOTKEY, { toggle: true }
  )

  const workspaceStatus = currentWorkspace?.status

  const {
    data: { preferences: { isSidebarMinimized } }
  } = useClientQuery<PreferencesQuery>(PREFERENCES_QUERY)

  const [ savePreferences ] = useMutation(SAVE_PREFERENCES_MUTATION)

  const { data, error, loading } = useDashboardsListQuery({
    variables: {
      order: [ { position: 'asc' } ],
      limit: 100
    }
  })

  const {
    data: { environmentsList = [] } = {},
    loading: environmentsLoading,
    refetch: refetchEnvironments
  } = useEnvironmentsListQuery()

  const {
    data: { installationsList = [] } = {},
    loading: installationsLoading,
    refetch: refetchInstallation
  } = useInstallationsListQuery({
    variables: {
      limit: INSTALLATIONS_LIST_LIMIT
    }
  })

  const refetchContextQueries = useCallback(() => {
    refetchInstallation()
    refetchEnvironments()
  }, [ refetchInstallation, refetchEnvironments ])

  const {
    appsList: projectAppsList
  } = useApps('PROJECT')

  const yourApps = projectAppsList
    .map((app, i) => ({
      name: app.name,
      icon: app.icon || 'app-custom',
      dashboardId: WORKSPACE_DASHBOARD.id,
      id: app.id,
      path: `app/${app.id}`,
      isRepeated: false,
      isVisible: true,
      isSticky: false,
      parentId: undefined,
      parentIds: [],
      placement: 'SIDE',
      position: 1000 + i * 1,
      target: 'VIEW',
      kind: 'ITEM'
    })) as ComputedMenuElement[]

  const {
    appCategoriesList,
    installedAppIds,
    appsList
  } = useApps('EXTENSION')

  const appMenuElementsByCategory = appCategoriesList.map((category, i) => {
    const apps = appsList
      .filter((app) => app.appCategoryId === category.id && installedAppIds.includes(app.id))
      .map((app, j) => ({
        name: app.name,
        icon: app.icon || `app-${app.identifier}`,
        dashboardId: WORKSPACE_DASHBOARD.id,
        id: app.id,
        path: `app/${app.id}`,
        isRepeated: false,
        isVisible: true,
        isSticky: false,
        parentId: undefined,
        parentIds: [],
        placement: 'SIDE',
        position: (i + 1) * 1000 + j * 10 + 1,
        target: 'VIEW',
        kind: 'ITEM'
      }))

    if (!apps.length) {
      return []
    }

    return [
      {
        name: `${category.name} Apps`,
        dashboardId: WORKSPACE_DASHBOARD.id,
        id: category.id,
        path: '',
        isRepeated: false,
        isVisible: true,
        isSticky: false,
        parentId: undefined,
        parentIds: [],
        placement: 'SIDE',
        position: (i + 1) * 1000,
        target: 'VIEW',
        kind: 'GROUP' as const
      },
      ...apps
    ]
  }).flat() as ComputedMenuElement[]

  const dashboardsList = (data?.dashboardsList || [])
    .concat({
      ...WORKSPACE_DASHBOARD,
      menuElements: [
        ...WORKSPACE_DASHBOARD.menuElements,
        ...(yourApps.length > 0 ? [ {
          name: 'Your Apps',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: 'your-apps',
          path: '',
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: undefined,
          parentIds: [],
          placement: 'SIDE',
          position: 1000,
          target: 'VIEW',
          kind: 'GROUP' as const
        } as ComputedMenuElement ] : []),
        ...yourApps,
        ...appMenuElementsByCategory
      ]
    })
    .concat(PERSONAL_DASHBOARD)

  const { dashboardIdentifier = '' } = useParams<InternalLayoutParams>()

  const currentAccount = useCurrentAccountContext()!

  const isWorkspaceDashboard = location.pathname.startsWith('/~workspace')
  const isPersonalDashboard = location.pathname.startsWith('/~personal')

  const isSystemDashboard = isWorkspaceDashboard || isPersonalDashboard

  useLayoutEffect(() => {
    savePreferences({
      variables: {
        preferences: {
          activeDashboardIdentifier: dashboardIdentifier
        }
      }
    })
  }, [ dashboardIdentifier, savePreferences ])

  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (currentAccount) {
        const { id, email } = currentAccount
        scope.setUser({ id, email })
      } else {
        scope.setUser(null)
      }
    })
  }, [ currentAccount ])

  const { menuElements, idToMenuElementMap, parentIdToMenuElementsMap } = useMemo(() => (
    generateDashboard(
      dashboardsList,
      currentWorkspace
    )
  ), [
    currentWorkspace,
    dashboardsList
  ])

  const defaultDashboard = dashboardsList[0]

  const { currentDashboard, sideMenuElements, topMenuElements } = useMemo(() => (
    splitMenuElements(
      dashboardsList,
      // eslint-disable-next-line no-nested-ternary
      isWorkspaceDashboard
        ? WORKSPACE_DASHBOARD.identifier : isPersonalDashboard
          ? PERSONAL_DASHBOARD.identifier : dashboardIdentifier,

      menuElements
    )
  ), [
    dashboardIdentifier,
    dashboardsList,
    menuElements,
    isWorkspaceDashboard,
    isPersonalDashboard
  ])

  const lastSelectedMenuElement = useRef<ComputedMenuElement | null>(null)

  const selectedSideMenuElement = useMemo(() => (
    findActiveMenuElement(sideMenuElements, location.pathname)
  ), [ location.pathname, sideMenuElements ])

  const selectedTopMenuElement = useMemo(() => (
    findActiveMenuElement(topMenuElements, location.pathname)
  ), [ location.pathname, topMenuElements ])

  const currentSidebarWidth = useMemo(() => (
    getCurrentSidebarWidth(isSidebarMinimized, selectedSideMenuElement)
  ), [ isSidebarMinimized, selectedSideMenuElement ])

  const { replace } = useHistory()

  useEffect(() => {
    const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement
    const updatedLastSelectedMenuElement = idToMenuElementMap[lastSelectedMenuElement.current?.id]
    const matchedPath = matchPath(location.pathname, {
      path: lastSelectedMenuElement.current?.fullPath,
      exact: true
    })

    // Handles deleting selected menu element
    if (
      !selectedMenuElement
      && lastSelectedMenuElement.current
      && matchedPath
      && !updatedLastSelectedMenuElement
    ) {
      const fallback = `/~${currentDashboard?.identifier || defaultDashboard?.identifier || ''}`
      replace(idToMenuElementMap[lastSelectedMenuElement.current.parentId]?.fullPath || fallback)
      lastSelectedMenuElement.current = selectedMenuElement
      return
    }

    // Handles updating path of selected menu element
    if (
      !selectedMenuElement
      && lastSelectedMenuElement.current
      && matchedPath
      && lastSelectedMenuElement.current.fullPath !== updatedLastSelectedMenuElement.fullPath
    ) {
      replace(updatedLastSelectedMenuElement.fullPath!)
      lastSelectedMenuElement.current = selectedMenuElement
      return
    }

    const getId = (path?: string) => last(last(path?.split('/').filter(Boolean))?.split('-'))
    const findById = (element: ComputedMenuElement) => matchPath(
      getId(location.pathname)!, { path: getId(element.fullPath)!, exact: true }
    )

    const possibleSelectedSideMenuElement = sideMenuElements.find(findById)

    if (!selectedMenuElement && possibleSelectedSideMenuElement) {
      replace(possibleSelectedSideMenuElement.fullPath!)
      lastSelectedMenuElement.current = selectedMenuElement
      return
    }

    const possibleSelectedTopMenuElement = topMenuElements.find(findById)

    if (!selectedMenuElement && possibleSelectedTopMenuElement) {
      replace(possibleSelectedTopMenuElement.fullPath!)
      lastSelectedMenuElement.current = selectedMenuElement
      return
    }

    if (!selectedMenuElement) {
      if (sideMenuElements[0]?.fullPath) {
        replace(sideMenuElements[0].fullPath!)
        return
      }

      if (topMenuElements[0]?.fullPath) {
        replace(topMenuElements[0].fullPath!)
        return
      }
    }

    // Need to abstract this into InternalRedirect component
    if (selectedMenuElement?.target === 'SUBMENU') {
      const firstMenuItem = findFirstMenuItem(parentIdToMenuElementsMap[selectedMenuElement.id])
      if (firstMenuItem?.target === 'VIEW') {
        replace(firstMenuItem.fullPath!)
        lastSelectedMenuElement.current = selectedMenuElement
      }
    }
  }, [
    currentDashboard,
    defaultDashboard,
    idToMenuElementMap,
    location.pathname,
    parentIdToMenuElementsMap,
    replace,
    selectedSideMenuElement,
    selectedTopMenuElement,
    sideMenuElements,
    topMenuElements
  ])

  useInitialiseTour(currentAccount)

  const internalContextValue = useMemo(() => ({
    // Todo: Move currentDashboard to preferences.activeDashboard?
    currentDashboard,
    defaultDashboard,

    installationsList,
    environmentsList,
    refetchContextQueries,

    menuElements,
    idToMenuElementMap,
    parentIdToMenuElementsMap

  }), [
    currentDashboard,
    defaultDashboard,
    installationsList,
    environmentsList,
    refetchContextQueries,
    menuElements,
    idToMenuElementMap,
    parentIdToMenuElementsMap
  ])

  const scrollWrapperRef = useRef<HTMLDivElement | null>(null)

  const trialEndsAt = new Date(currentWorkspace.trialEndsAt)
  const daysLeft = Math.max(Math.ceil(
    (trialEndsAt.getTime() - Date.now()) / (1000 * 60 * 60 * 24)
  ), 0)
  const suspended = currentWorkspace.status === 'SUSPENDED'
  const showTrialBanner = suspended || (currentWorkspace.status === 'IN_TRIAL' && daysLeft <= 7)

  const SIDE_BAR_HEIGHT = showTrialBanner
    ? `calc(100vh - ${TRIAL_BANNER_HEIGHT}px)`
    : '100vh'

  const MAIN_CONTENT_HEIGHT = showTrialBanner
    ? `calc(100vh - ${TRIAL_BANNER_HEIGHT}px)`
    : 'calc(100vh)'

  if (!data) {
    if (loading || environmentsLoading || installationsLoading) {
      return <AppLoader />
    }

    if (workspaceStatus !== 'SUSPENDED') {
      return <AppError error={error} />
    }
  }

  if (location.pathname !== '/' && !location.pathname.startsWith('/~') && location.pathname !== '/logout') {
    return <NotFoundPage />
  }

  return (
    <InternalContext.Provider value={internalContextValue}>
      <DashboardContext.Provider
        value={{
          currentSidebarWidth: sideMenuElements.length ? currentSidebarWidth : 0,
          editMode: isDashboardEditorOpen,
          openDashboardEditor,
          openJumpMenu,
          selectedSideMenuElement,
          selectedTopMenuElement,
          dashboardLoading: loading,
          showTrialBanner,
          dashboardsList
        }}
      >
        {showTrialBanner && <TrialBanner daysLeft={daysLeft} status={currentWorkspace.status} />}
        <ElectronWorkspaceSidebar />
        <DashboardEditorProvider>
          <MediaFieldProvider>
            <MenuElementPositionProvider
              stickyElements={sideMenuElements.filter((element) => element.isSticky)}
              nonStickyElements={sideMenuElements.filter((element) => !element.isSticky)}
            >
              <DragDropContextProvider>
                {sideMenuElements.length > 0 && (
                <Sidebar
                  menuElements={sideMenuElements.filter((element) => element.isVisible)}
                  selectedMenuElement={selectedSideMenuElement}
                  isLocked={isSystemDashboard}
                  hasTrialBanner={showTrialBanner}
                  style={{
                    height: SIDE_BAR_HEIGHT
                  }}
                />
                )}

                {location.pathname !== '/logout' && (
                <Topbar
                  menuElements={topMenuElements.filter((element) => element.isVisible)}
                  isLocked={isSystemDashboard}
                  hasTrialBanner={showTrialBanner}
                />
                )}
              </DragDropContextProvider>
              <Flex
                direction="column"
                className={scrollWrapperClass}
                ref={scrollWrapperRef}
                style={{
                  height: MAIN_CONTENT_HEIGHT,
                  paddingTop: TOPBAR_HEIGHT,
                  scrollPaddingTop: 160,
                  scrollBehavior: 'smooth',
                  paddingLeft: sideMenuElements.length
                    ? currentSidebarWidth + (isElectron ? WORKSPACE_SIDEBAR_WIDTH : 0)
                    : 0,
                  paddingRight: isDashboardEditorOpen ? DASHBOARD_EDITOR_WIDTH - LAYOUT_OVERLAP : 0,
                  top: showTrialBanner ? TRIAL_BANNER_HEIGHT : 0
                }}
              >
                <Droppable>
                  <Masonry
                    className={content}
                    containerPadding={CONTAINER_PADDING_X}
                    key={location.pathname}
                  >
                    {children}
                  </Masonry>
                </Droppable>
              </Flex>
              <DashboardEditor
                onClose={closeDashboardEditor}
                isOpen={isDashboardEditorOpen}
              />
            </MenuElementPositionProvider>

            <ViewStack />
          </MediaFieldProvider>
        </DashboardEditorProvider>

        <JumpMenu
          contentLabel="Jump Menu"
          isOpen={isJumpMenuOpen}
          menuElementMap={idToMenuElementMap}
          onRequestClose={closeJumpMenu}
        />
      </DashboardContext.Provider>
    </InternalContext.Provider>
  )
}

export {
  CONFIGURATIONS_LIST_LIMIT,
  CONTAINER_PADDING_X,
  INSTALLATIONS_LIST_LIMIT,
  findActiveMenuElement
}

export default InternalLayout
