import React from 'react'
import { Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import { DropdownField } from 'components/contentEditors/generic/fields'
import { UpdateAccountInput, useTimeZonesQuery } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'

export default function UpdateAccountTimeZone(
  { initialValues, ...others }: FormPropsWithId<UpdateAccountInput>
) {
  const { data: { timeZones = [] } = {}, loading, error } = useTimeZonesQuery({
    fetchPolicy: 'cache-first' // TimeZones is a static list of timezones that won't change
  })

  return (
    <Form
      initialValues={initialValues}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Flex gap={36}>
            <DropdownField
              defaultValue={initialValues?.timeZone}
              hasError={Boolean(error)}
              isLoading={loading}
              name="timeZone"
              options={timeZones}
              placeholder="Select timezone"
              virtualize
            />
            <Button type="submit" label="Update time zone" disabled={submitting || pristine} />
          </Flex>
        </form>
      )}
      {...others}
    />
  )
}
