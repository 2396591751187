import React, { useState } from 'react'

import CreateResourceForm from './CreateResourceForm'
import Divider from 'components/divider/Divider'
import GenerateResourceForm from './GenerateResourceForm'
import Grid from 'components/layout/Grid'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import { GenerateResourceType } from 'models/Resource'
import type { CreateResourceInput, GenerateGraphInput, Resource, ResourcesListQueryVariables, UpdateResourceInput } from 'generated/schema'
import type { SidePaneRenderProps } from 'components/sidePane/SidePane'
import type { ViewProps } from 'components/views'

type FormValues = CreateResourceInput | UpdateResourceInput | GenerateGraphInput
type OperationType = 'create' | 'generate' | undefined

type Params = {
  appId: string | null,
  initialValues: FormValues,
  queryVariables: ResourcesListQueryVariables,
  onComplete?: (resource: Resource) => void
}

const ResourceTemplateView = ({ setGenerateResourceType, setCurrentOperationType }: {
  setCurrentOperationType: React.Dispatch<React.SetStateAction<OperationType | undefined>>,
  setGenerateResourceType: React.Dispatch<React.SetStateAction<GenerateResourceType | undefined>>
}) => (
  <Grid gap={12} columns={1}>
    <MediaCard
      media="app-database-hub"
      title="From Database"
      text="Connect a table from an external database."
      onClick={() => {
        setCurrentOperationType('generate')
        setGenerateResourceType(GenerateResourceType.DATABASE)
      }}
      titlePosition="top"
      width="full"
    />
    {/* <MediaCard
      disabled
      media="app-api-hub"
      title="From API"
      text="Upload any Swagger or Postman collection."
      onClick={() => {
        setCurrentOperationType('generate')
        setGenerateResourceType(GenerateResourceType.API)
      }}
      titlePosition="top"
      width="full"
    />
    <MediaCard
      disabled
      media="documents"
      title="From File"
      text="Upload any XML, JSON, CSV, Excel"
      onClick={() => {
        setCurrentOperationType('generate')
        setGenerateResourceType(GenerateResourceType.FILE)
      }}
      titlePosition="top"
      width="full"
    /> */}
  </Grid>
)

function AddResourceView({
  params,
  viewStyleComponent: View,
  onRequestClose,
  ...others
}: ViewProps<Params>) {
  const isUpdating = 'id' in params.initialValues

  const [ currentOperationType, setCurrentOperationType ] = useState<OperationType>(isUpdating ? 'create' : undefined)
  const [
    generateResourceType, setGenerateResourceType
  ] = useState<GenerateResourceType | undefined>()

  const onBackClick = () => setCurrentOperationType(undefined)

  const title = 'New Resource'
  const subtitle = 'Step 1: How would you like to create this resource?'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...others}>
      {(viewProps) => (
        <>
          {currentOperationType === 'create' && (
            <CreateResourceForm
              params={{
                ...params,
                initialValues: params.initialValues as CreateResourceInput | UpdateResourceInput,
                onBackClick
              }}
              onRequestClose={onRequestClose}
              viewStyleComponent={View}
              viewProps={viewProps as SidePaneRenderProps}
              {...others}
            />
          )}
          {currentOperationType === 'generate' && (
            <GenerateResourceForm
              params={{
                ...params,
                initialValues: {
                  appId: (params.initialValues as GenerateGraphInput)?.appId,
                  originKind: generateResourceType
                } as GenerateGraphInput,
                onBackClick
              }}
              onRequestClose={onRequestClose}
              viewStyleComponent={View}
              viewProps={viewProps as SidePaneRenderProps}
              {...others}
            />
          )}
          {!currentOperationType && (
            <>
              <viewProps.Header
                title={title}
                onCloseClick={onRequestClose}
              />
              <viewProps.SubHeader>
                <Text fontWeight="regular" fontSize={14} color="dark700">{subtitle}</Text>
              </viewProps.SubHeader>
              <viewProps.Body>
                <MediaCard
                  media="embedded-field"
                  onClick={() => {
                    setCurrentOperationType('create')
                  }}
                  title="From Scratch"
                  text="Create a new table within DashX"
                  titlePosition="top"
                  width="full"
                />
                <Divider spacing={14} variant="whitespace" />
                <Text fontWeight="bold">Connect external sources</Text>
                <Divider spacing={4} variant="whitespace" />
                <Text color="dark400">
                  You can create resources that are connected to external data sources,
                  such as databases, APIs and files.
                </Text>
                <Divider spacing={10} variant="whitespace" />
                <ResourceTemplateView
                  setGenerateResourceType={setGenerateResourceType}
                  setCurrentOperationType={setCurrentOperationType}
                />
              </viewProps.Body>
            </>
          )}
        </>
      )}
    </View>
  )
}

AddResourceView.defaultStyle = 'PANEL' as const

export default AddResourceView
