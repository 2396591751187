/* eslint-disable no-nested-ternary */
import arrayMutators from 'final-form-arrays'
import kebabCase from 'lodash/kebabCase'
import React, { useEffect, useMemo, useState } from 'react'
import { Form, useField, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import LiveBlockEditorOrchestrator from './LiveBlockEditorOrchestrator'
import MediaCard from 'components/mediaCard/MediaCard'
import OperationField, { FieldsList } from './OperationField'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { css } from 'styles/stitches'
import { SidePaneFooter } from 'components/sidePane'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { useOperationQuery, useUpsertViewMutation } from 'generated/schema'
import type { ActiveViewProps } from './DashboardEditor'
import type { Views } from './constants'

const classes = {
  wrapper: css({
    marginBottom: 30
  })
}

const EMPTY_ARRAY: any[] = []

function Step1({ setStep }: any) {
  const form = useForm()
  const { viewState } = useDashboard()
  const { urn } = useDashboardEditorContextProvider()
  const view = useRecoilValue(viewState(urn))
  const viewName = [ view.app?.name, view.resource?.name, view.name ].filter(Boolean).join(' > ')
  const title = viewName ? `View: ${viewName}` : 'Dashboard Editor'
  const subtitle = <Text fontSize={16} fontWeight="bold">Step 1: Generate your form</Text>

  return (
    <>
      <DashboardEditorHeader
        heading={title}
        subtitle={subtitle}
        onClose={form.mutators.onClose}
      />
      <DashboardEditorBody>
        <Flex direction="column" gap={16}>
          <MediaCard
            title="From an Operation"
            text="Quickly generate from an operation"
            titlePosition="top"
            media="action"
            onClick={() => setStep(2)}
            width="full"
          />
          <MediaCard
            title="From Scratch"
            text="A blank form to customize from ground up"
            titlePosition="top"
            media="embedded-field"
            // onClick={}
            width="full"
            disabled
          />
        </Flex>
      </DashboardEditorBody>
    </>
  )
}

function Step2({ setStep, operation }: any) {
  const form = useForm()
  const subtitle = <Text fontSize={16} fontWeight="bold">Step 2: Select an operation</Text>

  return (
    <>
      <DashboardEditorHeader
        onStepBack={() => setStep(1)}
        heading="Back"
        subtitle={subtitle}
        onClose={form.mutators.onClose}
      />
      <DashboardEditorBody>
        <Flex direction="column" gap={24}>
          <OperationField operation={operation} helpText="This operation will be called when this form is submitted" />
          {operation && <FieldsList operation={operation} />}
        </Flex>
      </DashboardEditorBody>

      <SidePaneFooter variant="small" isSticky>
        <Step2Button disabled={!operation} onClick={() => setStep(3)} />
      </SidePaneFooter>

    </>
  )
}

const Step2Button = ({ onClick, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const form = useForm()
  const { urn } = useDashboardEditorContextProvider()
  const { selectedBlockState, updateBlock } = useDashboard()
  const selectedBlock = useRecoilValue(selectedBlockState)!

  return (
    <Button
      label="Save Changes"
      size="small"
      {...rest}
      onClick={(e: any) => {
        const { values: { identifier, actions, ...properties } } = form.getState()
        updateBlock(urn, {
          ...selectedBlock,
          actions,
          identifier,
          properties: {
            ...selectedBlock.properties,
            ...properties
          }
        })
        onClick?.(e)
      }}
    />
  )
}

function Step3({ operation }: any) {
  const form = useForm()
  const { viewState } = useDashboard()
  const { urn } = useDashboardEditorContextProvider()
  const view = useRecoilValue(viewState(urn))
  const viewName = [ view.app?.name, view.resource?.name, view.name ].filter(Boolean).join(' > ')
  const title = viewName ? `View: ${viewName}` : 'Dashboard Editor'
  const subtitle = 'Edit Form Block'

  return (
    <>
      <DashboardEditorHeader
        heading={title}
        subtitle={subtitle}
        onClose={form.mutators.onClose}
      />
      <DashboardEditorBody css={{ paddingTop: 0 }}>
        <Flex as="form" direction="column" onSubmit={form.submit}>
          <Tabs wrapperClassName={classes.wrapper}>
            <Tab index={0} label="General" alwaysMounted>
              <Flex justifyContent="space-between" direction="column" gap={24}>
                <FormField name="heading" label="Heading" size="small" />
                <OperationField operation={operation} />
                {operation && <FieldsList operation={operation} />}
                <FormField
                  component={CodeEditorInput}
                  name="initial_values"
                  label="Initial Values"
                  language="json"
                  size="small"
                  type="text"
                  defaultValue=""
                />
                <FormField
                  name="children"
                  defaultValue={EMPTY_ARRAY}
                  type="hidden"
                />
                <FormField
                  name="fields"
                  defaultValue={EMPTY_ARRAY}
                  type="hidden"
                />
              </Flex>
            </Tab>
            <Tab index={1} label="Advanced" alwaysMounted>
              <FormField
                name="identifier"
                label="Identifier"
                size="small"
              />
            </Tab>
          </Tabs>
          <LiveBlockEditorOrchestrator />
          <input type="submit" style={{ display: 'none' }} />
        </Flex>
      </DashboardEditorBody>

      <SidePaneFooter variant="small" isSticky>
        <Step3Button />
      </SidePaneFooter>

    </>
  )
}

const Step3Button = () => {
  const form = useForm()
  const { submitting } = useFormState()
  return <Button disabled={submitting} type="submit" label="Save Changes" size="small" onClick={form.submit} />
}

const EditBlockForm = () => {
  const operationId = useField('operation').input.value
  const { data: operationData } = useOperationQuery({
    variables: { id: operationId },
    skip: !operationId
  })

  const operation = operationData?.operation

  const [ step, setStep ] = useState(operationId ? 3 : 1)

  return (
    <>
      {step === 1 && <Step1 setStep={setStep} />}
      {step === 2 && <Step2 setStep={setStep} operation={operation} />}
      {step === 3 && <Step3 operation={operation} />}
    </>
  )
}

function AddFormBlockView({ onClose }: ActiveViewProps) {
  const {
    selectBlock,
    selectedBlockState,
    getBlocks,
    getOperations,
    dashboardEditorState
  } = useDashboard()

  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<Views.EDIT_BLOCK>>(
    dashboardEditorState
  )

  const selectedBlock = useRecoilValue(selectedBlockState)! || block

  const [ upsertView ] = useUpsertViewMutation()

  const handleUpsertBlock = useSubmitHandler(upsertView, { successAlert: { message: 'Your changes were saved.' } })
  const { urn } = useDashboardEditorContextProvider()

  const handleSubmit = async () => handleUpsertBlock({
    urn,
    blocks: await getBlocks(urn),
    operations: await getOperations()
  })

  const renderForm = useMemo(() => () => (
    <EditBlockForm />
  ), [])

  useEffect(() => () => selectBlock(null), [ selectBlock ])

  return (
    <Form
      key={selectedBlock.id}
      mutators={{ ...arrayMutators, onClose }}
      keepDirtyOnReinitialize
      initialValues={{
        identifier: `${kebabCase(selectedBlock.type).toLowerCase()}-${selectedBlock.id.slice(0, 8)}`,
        ...selectedBlock.properties,
        actions: selectedBlock.actions
      }}
      subscription={{ submitting: true }}
      onSubmit={handleSubmit}
      render={renderForm}
    />
  )
}

export default AddFormBlockView
