import React from 'react'
import { Form } from 'react-final-form'

import AccountModel from 'models/Account'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Account, AccountsListDocument, AccountsListQuery, AccountsListQueryVariables, Environment, Group, GroupMembershipsListDocument, GroupMembershipsListQueryVariables, GroupsListDocument, GroupsListQuery, GroupsListQueryVariables, useCreateGroupMembershipMutation } from 'generated/schema'
import type { ViewProps } from 'components/views'

type Params = {
  groupId?: Group['id'],
  accountId?: Account['id'],
  accountKind?: Group['accountKind'],
  environmentId?: Environment['id'],
  group?: Group,
  account?: Account,
  queryVariables: GroupMembershipsListQueryVariables
}

function AssignGroupView({
  closeView,
  onRequestClose,
  params: {
    groupId, accountId, accountKind, environmentId, group, account, queryVariables
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ assignGroup ] = useCreateGroupMembershipMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: GroupMembershipsListDocument, variables: queryVariables } ]
  })

  const handleAssignGroup = useSubmitHandler(assignGroup, {
    successAlert: { message: 'Successfully assigned group.' }
  })

  const title = 'Assign Group'

  const initialValues = {
    accountId,
    groupId
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Form
              initialValues={initialValues}
              onSubmit={handleAssignGroup}
              subscription={{
                submitting: true,
                pristine: true
              }}
              render={({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <Flex gap={16} direction="column">
                    <FormField
                      component="input"
                      type="hidden"
                      name="envrionmentId"
                    />
                    <SearchSelectField<GroupsListQuery, GroupsListQueryVariables>
                      isDisabled={Boolean(groupId)}
                      isSearchable
                      preload
                      name="groupId"
                      label="Group"
                      prependIcon="search"
                      placeholder="Start typing to search"
                      size="small"
                      variant="light"
                      labelKey="name"
                      valueKey="id"
                      options={group ? [ group ] : []}
                      query={GroupsListDocument}
                      dataKey="groupsList"
                      queryOptions={{
                        variables: {
                          filter: {
                            accountKind: { eq: accountKind },
                            environmentId: { eq: environmentId }
                          }
                        }
                      }}
                      keys={[ 'name' ]}
                    />
                    <SearchSelectField<AccountsListQuery, AccountsListQueryVariables>
                      isDisabled={Boolean(accountId)}
                      isSearchable
                      preload
                      name="accountId"
                      label="Account"
                      prependIcon="search"
                      placeholder="Start typing to search"
                      size="small"
                      variant="light"
                      labelKey="name"
                      metaKey="identifier"
                      valueKey="id"
                      options={account ? [ account ] : []}
                      getOptionLabel={
                        (option: Account) => AccountModel.getFullNameWithContact(option)
                      }
                      getOptionMeta={(option: Account) => option.email || option.phone}
                      query={AccountsListDocument}
                      dataKey="accountsList"
                      queryOptions={{
                        variables: {
                          filter: {
                            kind: { eq: accountKind }
                          }
                        }
                      }}
                      keys={[ 'name', 'firstName', 'lastName', 'email' ]}
                    />
                    <Flex gap={24} direction="row-reverse">
                      <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                    </Flex>
                  </Flex>
                </form>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default AssignGroupView
