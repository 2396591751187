import React from 'react'

import FormField from 'components/form/FormField'
import TextInput from 'components/inputs/TextInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { TextInputProps } from 'components/inputs/TextInput'

type EmailFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'email'>

const EmailField = ({ name, ...others }: EmailFieldProps) => (
  <FormField
    component={TextInput}
    name={name}
    type="email"
    checkRequired={others.settings?.checkRequired}
    {...others}
  />
)

export default EmailField
