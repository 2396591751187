import mjml2html from 'mjml-browser'

type MJMLErrorFormat = {
  formattedMessage: string,
  line: number,
  message: string,
  tagName: string
}

const regex = new RegExp(/id="[a-zA-Z0-9]{5}"/g)

function mjmlToHtml(mjml: string) {
  if (!mjml.trim()) {
    return ''
  }

  try {
    const { errors, html } = mjml2html(mjml.replaceAll(regex, ''))

    if (errors.length) {
      return errors
        .map(({ formattedMessage }: MJMLErrorFormat) => formattedMessage)
        .join('\n')
    }

    return html
  } catch {
    return mjml
  }
}

export default mjmlToHtml
