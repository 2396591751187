import React, { forwardRef } from 'react'
import TextAreaAutoSize from 'react-textarea-autosize'
import type { FieldRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import rgba from 'lib/rgba'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'

type StyledTextAreaInputProps = StyledProps<typeof StyledTextAreaInput>

type TextAreaInputProps = {
  autogrow?: boolean,
  helpText?: string,
  inline?: boolean,
  isTranslatable?: boolean
} & FieldRenderProps<string, HTMLTextAreaElement> & StyledTextAreaInputProps

const TEXTAREA_INPUT_BORDER_RADIUS = 4
const TEXTAREA_INPUT_BORDER_WIDTH = 1
const TEXTAREA_INPUT_FONT_SIZE = 14
const DEFAULT_MIN_ROWS = 3

const sizes = [ 'small', 'normal', 'large' ] as const

const TEXTAREA_INPUT_PADDING: { [key: string]: number } = {
  large: 30,
  normal: 20,
  small: 12
}

const textAreaInputSizeVariants = sizes.reduce((acc, size) => ({
  ...acc,
  [size]: { padding: TEXTAREA_INPUT_PADDING[size] }
}), {} as Record<typeof sizes[number], Record<string, string | number>>)

const wrapper = css({ width: '100%' })

const autogrowStyles = (maxRows?: number) => css({
  resize: 'none',
  overflow: maxRows ? 'auto' : 'hidden'
})

const StyledContainer = styled('div', {
  position: 'relative',

  variants: {
    display: {
      inline: {
        display: 'inline-block'
      },
      normal: {}
    }
  }
})

const StyledTextAreaInput = styled('textarea', {
  ...mixins.transition('fluid', 'border'),

  fontFamily: 'normal',
  fontSize: TEXTAREA_INPUT_FONT_SIZE,
  fontWeight: 'regular',
  lineHeight: 'normal',
  width: '100%',

  '&::placeholder': {
    fontWeight: 'regular'
  },

  '&:read-only': {
    userSelect: 'none'
  },

  '&[disabled]': {
    backgroundColor: 'light400',
    borderColor: 'dark100',
    color: 'dark500',
    fontWeight: 'regular'
  },

  variants: {
    size: textAreaInputSizeVariants,
    hasError: {
      none: {
        color: 'dark900',

        '&::placeholder': {
          color: 'dark500',
          fontWeight: 'regular'
        },

        '&:focus:not([disabled])': {
          '&::placeholder': {
            color: rgba(colorVars.dark500rgb, 0.3)
          }
        }
      },
      error: {
        '&&': {
          backgroundColor: 'negative100',
          borderColor: 'negative300',
          color: 'negative500',

          '&::placeholder': {
            color: 'negative400',
            fontWeight: 'regular'
          },

          '&:hover': {
            borderColor: 'negative300'
          },

          '&:focus': {
            borderColor: 'negative300',
            '&::placeholder': {
              color: rgba(colorVars.negative500rgb, 0.3)
            }
          }
        }
      }
    },
    variant: {
      light: {
        backgroundColor: 'light100',
        borderColor: 'dark100',

        '&:hover:not([disabled])': {
          borderColor: 'dark300'
        },
        '&:focus:not([disabled])': {
          borderColor: 'dark300'
        }
      },
      dark: {
        backgroundColor: 'light600',
        borderColor: 'light600',

        '&:hover:not([disabled])': {
          borderColor: 'dark300'
        },
        '&:focus:not([disabled])': {
          borderColor: 'dark300'
        }
      }
    },
    rounded: {
      all: {
        borderRadius: TEXTAREA_INPUT_BORDER_RADIUS
      },
      left: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      right: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      },
      none: {
        borderRadius: 0
      }
    },
    border: {
      solid: {
        borderWidth: TEXTAREA_INPUT_BORDER_WIDTH,
        borderStyle: 'solid'
      },
      shadow: {
        ...mixins.shadow('xLarge', colorVars.dark1000rgb, 0.1),

        borderStyle: 'none'
      }
    }
  }
})

StyledTextAreaInput.defaultProps = {
  border: 'solid',
  rounded: 'all',
  variant: 'light'
}

const TextAreaInput = forwardRef<HTMLInputElement, TextAreaInputProps>(({
  autogrow,
  helpText,
  inline = false,
  input,
  isTranslatable,
  checkRequired = false,
  label,
  meta,
  size = 'normal',
  rows = DEFAULT_MIN_ROWS,
  maxRows,
  ...other
}, innerRef) => {
  const hasError = !!FieldError.getError(meta)

  const autoGrowProps = autogrow ? {
    as: TextAreaAutoSize,
    minRows: rows,
    maxRows,
    css: autogrowStyles(maxRows)
  } : { rows }

  return (
    <Flex as="label" className={wrapper} direction="column" gap={10} grow={1}>
      {label && (
        <FieldLabel isTranslatable={isTranslatable} checkRequired={checkRequired}>
          {label}
        </FieldLabel>
      )}
      <StyledContainer
        display={inline ? 'inline' : 'normal'}
      >
        <StyledTextAreaInput
          autoComplete="off"
          hasError={hasError ? 'error' : 'none'}
          ref={innerRef}
          size={size}

          {...autoGrowProps}
          {...input}
          {...other}
        />
        <FieldError error={FieldError.getError(meta)} />
      </StyledContainer>

      {helpText && <InputHelpText helpText={helpText} />}
    </Flex>
  )
})

TextAreaInput.displayName = 'TextAreaInput'

export { DEFAULT_MIN_ROWS }
export default TextAreaInput
