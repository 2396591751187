import classNames from 'classnames'
import Modal from 'react-modal'
import React, { useContext } from 'react'
import type ModalType from 'react-modal'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import rgba from 'lib/rgba'
import zIndices from 'styles/primitives/zIndices'
import { colorVars } from 'styles/theme'
import { css } from 'styles/stitches'
import { DASHBOARD_EDITOR_WIDTH } from 'components/dashboardEditor/constants'

const CLOSE_TRANSITION_DURATION = 300

const classes = {
  overlay: css({
    ...mixins.transition('fluid'),

    backgroundColor: rgba(colorVars.light100rgb, 0.5),
    bottom: 0,
    left: 0,
    opacity: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    transform: 'translateZ(0)', // Force hardware acceleration for fixed elements
    zIndex: Number(zIndices.modal) - 1
  }),
  overlay_editMode: css({
    '&&': {
      right: DASHBOARD_EDITOR_WIDTH
    }
  }),
  overlayAfterOpen: css({
    '&&': {
      backdropFilter: 'blur(5px)',
      opacity: 1
    }
  }),
  overlayBeforeClose: css({
    '&&&': {
      backdropFilter: 'blur(0)',
      opacity: 0,
      zIndex: Number(zIndices.modal) - 2
    }
  }),
  content: css({
    ...mixins.transition('fluid')
  }),
  body: css({
    overflow: 'hidden'
  })
}

type BaseModalProps = ModalType.Props & React.PropsWithChildren<{
  contentAfterOpenClassName?: string,
  contentBaseClassName?: string,
  contentBeforeCloseClassName?: string,
  contentLabel: string,
  onRequestClose: () => void,
  overlayAfterOpenClassName?: string,
  overlayBaseClassName?: string,
  overlayBeforeCloseClassName?: string
}>

function BaseModal({
  contentAfterOpenClassName,
  contentBaseClassName,
  contentBeforeCloseClassName,
  overlayAfterOpenClassName,
  overlayBaseClassName,
  overlayBeforeCloseClassName,
  children,
  ...other
}: BaseModalProps) {
  const { editMode } = useContext(DashboardContext) || {}

  return (
    <Modal
      parentSelector={() => document.querySelector('#portal') as HTMLDivElement}
      appElement={document.getElementById('root') as Element}
      bodyOpenClassName={classes.body}
      overlayClassName={{
        base: classNames(
          classes.overlay,
          overlayBaseClassName,
          { [classes.overlay_editMode]: editMode }
        ),
        afterOpen: classNames(classes.overlayAfterOpen, overlayAfterOpenClassName),
        beforeClose: classNames(classes.overlayBeforeClose, overlayBeforeCloseClassName)
      }}
      className={{
        base: classNames(classes.content, contentBaseClassName),
        afterOpen: classNames(contentAfterOpenClassName),
        beforeClose: classNames(contentBeforeCloseClassName)
      }}
      closeTimeoutMS={CLOSE_TRANSITION_DURATION}
      {...other}
    >
      {children}
    </Modal>
  )
}

export type { BaseModalProps }

export default BaseModal
