import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'

enum SystemContentTypeIdentifier {
  EMAIL = 'email',
  PUSH = 'push',
  SMS = 'sms',
  WHATSAPP = 'whatsapp'
}

enum TemplateSubkind {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP'
}

const systemContentTypeOptions = [
  { label: 'Email', value: SystemContentTypeIdentifier.EMAIL, icon: SystemContentTypeIdentifier.EMAIL },
  { label: 'Push', value: SystemContentTypeIdentifier.PUSH, icon: SystemContentTypeIdentifier.PUSH },
  { label: 'SMS', value: SystemContentTypeIdentifier.SMS, icon: SystemContentTypeIdentifier.SMS },
  { label: 'WhatsApp', value: SystemContentTypeIdentifier.WHATSAPP, icon: SystemContentTypeIdentifier.WHATSAPP }
]

class ContentTypeModel extends BaseModel {
  static schema = object({
    name: string().singular().required(),
    identifier: isIdentifier().required()
  })
}

export { SystemContentTypeIdentifier, systemContentTypeOptions, TemplateSubkind }

export default ContentTypeModel
