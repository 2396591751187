import { useLayoutEffect } from 'react'

import useContainerScroll from 'hooks/useContainerScroll'

type ScrollPosition = {
  scrollX: number,
  scrollY: number
}

function useWindowScroll(): ScrollPosition {
  const [ position, updatePosition ] = useContainerScroll(window)

  useLayoutEffect(() => {
    const handleScroll = () => updatePosition(window)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return position
}

export default useWindowScroll
