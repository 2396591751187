import React, { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import type { ApolloError } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import loaderImage from 'assets/images/loader-animated.svg'
import parseError from 'lib/parseError'
import Text from 'components/typography/Text'
import { LOADER_WRAPPER_PADDING } from './DashboardEditorLoader'
import { styled } from 'styles/stitches'

type LoaderEmptyType = {
  element?: React.ReactElement,
  subtitle?: string,
  title?: string,
  variant?: 'neutral' | 'positive' | 'bare' | 'negative' | 'simple'
}

type LoaderProps = React.PropsWithChildren<{
  data?: any,
  empty?: LoaderEmptyType | false,
  error?: ErrorResponse | ApolloError,
  loading?: boolean,
  size?: 'normal' | 'large' | 'compact',
  wrapper?: React.JSXElementConstructor<any>
}>

const LOADER_SIZE = 80
const LOADING_ICON_INACTIVE_SCALE = 0.7
const LOADING_ICON_LARGE_ACTIVE_SCALE = 2
const LOADING_ICON_COMPACT_ACTIVE_SCALE = 0.7
const STATUS_ICON_SIZE = 32

const DEFAULT_ERROR_SUBTITLE = 'Please contact support if the problem persists.'
const DEFAULT_POSITIVE_SUBTITLE = 'Great job.'
const DEFAULT_SUBTITLE = 'Nothing to show here.'
const DEFAULT_TITLE = 'There are no records.'

const STATUS_ICON = {
  negative: 'cross-circle',
  neutral: '',
  bare: '',
  simple: '',
  positive: 'check-circle'
}

const DEFAULT_WRAPPER = ({ children }: React.PropsWithChildren<{}>) => <>{children}</>

const StyledEmptyView = styled(Flex, {
  ...mixins.transition('simple'),

  display: 'none',
  textAlign: 'center',
  variants: {
    active: {
      true: {
        display: 'flex'
      }
    }
  }
})

const StyledLoader = styled(Flex, {
  ...mixins.transition('simple'),

  perspective: '600px',
  position: 'relative',
  transformStyle: 'preserve-3d',
  size: [ LOADER_SIZE ],

  '& > i': {
    transform: 'rotateY(180deg)',

    backfaceVisibility: 'hidden',
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0
  },
  variants: {
    flipped: {
      true: {
        transform: 'rotateY(180deg)'
      }
    },
    hidden: {
      true: {
        display: 'none'
      }
    },
    compact: {
      true: {
        margin: -LOADER_WRAPPER_PADDING
      }
    }
  }
})

const StyledLoadingIcon = styled(SVG, {
  ...mixins.transition('simple'),

  backfaceVisibility: 'hidden',
  bottom: 0,
  left: 0,
  margin: 'auto',
  position: 'absolute',
  right: 0,
  top: 0,

  color: 'light700',
  size: [ LOADER_SIZE ],

  transform: 'scale(1)',

  // normalize.css makes all non-root SVGs hidden
  // https://github.com/necolas/normalize.css/issues/718
  '& > svg': {
    overflow: 'visible !important'
  },

  variants: {
    size: {
      normal: {},
      large: {
        transform: `scale(${LOADING_ICON_LARGE_ACTIVE_SCALE})`
      },
      compact: {
        transform: `scale(${LOADING_ICON_COMPACT_ACTIVE_SCALE})`
      }
    },
    inactive: {
      true: {
        '&&': {
          transform: `scale(${LOADING_ICON_INACTIVE_SCALE})`
        }
      }
    }
  }
})

const StyledStatusIcon = styled(Icon, {
  '&[data-variant="negative"]': {
    color: 'negative500'
  },
  '&[data-variant="positive"]': {
    color: 'positive500'
  }
})

function Loader({
  children,
  data,
  empty,
  error,
  loading,
  size = 'normal',
  wrapper,
  ...rest
}: LoaderProps) {
  const [ loaderEl, setLoaderEl ] = useState<HTMLElement | null>(null)
  const [ isAnimationComplete, setIsAnimationComplete ] = useState(!loading)

  const { subtitle, title, variant = 'neutral', element } = empty || {}
  const showLoader = loading || variant !== 'simple'
  const isEmpty = ((Array.isArray(data) && !data?.length) || (!Array.isArray(data) && !data))
  const loaderVariant = error ? 'negative' : variant
  const Wrapper = wrapper || DEFAULT_WRAPPER

  useEffect(() => {
    const stopAnimation = (e: any) => {
      const el = e.currentTarget
      el.setAttribute('repeatCount', '0')

      setIsAnimationComplete(true)
    }

    const startAnimation = (el: any) => {
      el.removeEventListener('repeatEvent', stopAnimation)
      el.setAttribute('repeatCount', 'indefinite')
      el.setAttribute('dur', '1.5s') // resets duration to 1.5s if got removed by `initialAnimationState`
      el.beginElement()
      setIsAnimationComplete(false)
    }

    if (loaderEl) {
      loaderEl.querySelectorAll('animate').forEach((el: any) => {
        if (!loading) {
          el.addEventListener('repeatEvent', stopAnimation)
        } else {
          startAnimation(el)
        }
      })
    }

    return () => {
      if (!loaderEl) setIsAnimationComplete(true)
    }
  }, [ loading, loaderEl ])

  if (empty === false) {
    return null
  }

  if (!isEmpty) {
    return <>{children}</>
  }

  if (isEmpty && empty?.variant === 'bare' && !loading) {
    return empty.element || null
  }

  const statusIcon = STATUS_ICON[loaderVariant]

  const loaderTitle = (() => {
    if (error) {
      const { alert } = parseError(error)

      return alert?.title || alert?.message
    }

    return title || DEFAULT_TITLE
  })()

  const loaderSubtitle = (() => {
    if (error) {
      const { alert } = parseError(error)

      return (alert?.title && alert?.message) || DEFAULT_ERROR_SUBTITLE
    }

    if (subtitle === '') {
      return subtitle
    }

    if (variant === 'positive' && !subtitle) {
      return DEFAULT_POSITIVE_SUBTITLE
    }

    return subtitle || DEFAULT_SUBTITLE
  })()

  const initialAnimationState = (code: string) => {
    if (!loading) {
      return code.replace(/dur=".*?"/g, 'dur="0"')
    }

    return code
  }

  return (
    <Wrapper {...rest}>
      <Flex grow={1} alignItems="center" direction="column" justifyContent="center">
        <StyledLoader
          flipped={Boolean(isAnimationComplete && statusIcon)}
          hidden={isAnimationComplete && !showLoader}
          compact={size === 'compact'}
        >
          <StyledLoadingIcon
            inactive={!loading}
            size={size}
            innerRef={setLoaderEl}
            preProcessor={initialAnimationState}
            src={loaderImage}
            title="Loading..."
          />
          {statusIcon && (
            <StyledStatusIcon
              data-variant={loaderVariant}
              name={statusIcon}
              size={STATUS_ICON_SIZE}
            />
          )}
        </StyledLoader>
        <StyledEmptyView
          active={!loading && isAnimationComplete}
          alignItems="center"
          direction="column"
          gap={16}
        >
          {element || (
            <Flex alignItems="center" direction="column" gap={10}>
              <Text fontWeight="bold">{loaderTitle}</Text>
              <Text fontSize={14}>{loaderSubtitle}</Text>
            </Flex>
          )}
        </StyledEmptyView>
      </Flex>
    </Wrapper>
  )
}

export type { LoaderProps }

export default Loader
