import React, { Children } from 'react'

import Flex, { FlexProps } from 'components/layout/Flex'
import injectProps from 'lib/injectProps'

type Rounded = 'all' | 'left' | 'right' | 'none'

function InputGroup({ children, ...other }: FlexProps) {
  const childrenCount = Children.count(children)
  const firstChildIndex = 0
  const lastChildIndex = childrenCount - 1

  const props = (_: React.ReactNode, index: number) => {
    if (childrenCount === 1) {
      return { rounded: 'all' }
    }

    if (index === firstChildIndex) {
      return { rounded: 'left' }
    }

    if (index === lastChildIndex) {
      return { rounded: 'right' }
    }

    return { rounded: 'none' }
  }

  return (
    <Flex direction="row" gap={0} {...other}>
      {injectProps(children, props)}
    </Flex>
  )
}

export default InputGroup

export type { Rounded }
