import React from 'react'
import { useRecoilValue } from 'recoil'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import BaseLink from 'components/links/BaseLink'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataSourcesView from 'components/dashboardEditor/graph/DataSourcesView'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import MembersView from 'components/dashboardEditor/roleMembership/MembersView'
import NotFoundPage from 'components/pages/NotFoundPage'
import OperationsView from 'components/dashboardEditor/graph/OperationsView'
import ResourcesView from 'components/dashboardEditor/graph/ResourcesView'
import RolesView from 'components/dashboardEditor/roleMembership/RolesView'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Actions as CustomAppOverviewActions } from '../project/ProjectDetailsView'
import { CreateInstallationInput, DashboardsListDocument, InstallationsListDocument, useCreateInstallationMutation, useInstallationsListQuery } from 'generated/schema'
import { css } from 'styles/stitches'
import { INNER_CONTAINER_PADDING, ViewParams, Views } from '../constants'
import { Kind } from 'models/Role'
import { MarkdownRenderer } from 'components/markdown'
import type { ActiveViewProps } from '../DashboardEditor'

type Params = ViewParams[Views.APP_DETAILS]

const AppDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()

  const {
    data: { installationsList = [] } = {}
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        or: [
          { appKind: { eq: 'EXTENSION' } }
        ]
      }
    }
  })

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, parentView } = params! as Params

  if (!app) {
    return (
      <>
        <DashboardEditorHeader
          heading={parentView ? 'Dashboard Editor' : 'Apps'}
          onClose={onClose}
        />
        <DashboardEditorBody>
          <NotFoundPage defaultMessage="App not found." fullscreen={false} />
        </DashboardEditorBody>
      </>
    )
  }

  const { id: appId, workspaceId, icon, identifier, name } = app
  const installation = installationsList.find((i) => i.appId === appId)
  const isInstalled = Boolean(installation)
  const isCustomApp = !!workspaceId

  return (
    <>
      <DashboardEditorHeader
        subtitle={name}
        heading={parentView ? 'Dashboard Editor' : 'Apps'}
        onEdit={isCustomApp ? () => openDashboardEditorView({
          target: Views.EDIT_APP,
          params: { initialValues: app }
        }) : undefined}
        onClose={onClose}
        icon={(
          <AppIcon
            isCustomApp={!!workspaceId}
            identifier={identifier as AppIconIdentifier}
            icon={icon}
            size={24}
          />
        )}
      />
      <DashboardEditorBody>
        {isInstalled && <InstalledAppDetailsView isCustomApp={isCustomApp} />}
        {!isInstalled && <InstallAppView />}
      </DashboardEditorBody>
    </>
  )
}

// TODO: Add other app details
const appDetails = {
  messaging: {
    pricing: [
      {
        title: 'Email',
        subTitle: '$0.10 per 1000 emails',
        description: 'First 10,000 emails FREE'
      },
      {
        title: 'SMS',
        subTitle: 'Country-based pricing',
        description: 'No free tier',
        highlightSubTitle: true
      }
    ]
  }
}

const classes = {
  wrapper: css({
    marginTop: -INNER_CONTAINER_PADDING
  })
}

const InstalledAppDetailsView = ({ isCustomApp }: { isCustomApp: boolean }) => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { activeTab = '' } = params! as Params

  return (
    <Tabs wrapperClassName={classes.wrapper} initialIndex={activeTab === 'Access' ? 1 : 0}>
      <Tab index={0} label="Overview">
        {isCustomApp ? <CustomAppOverview /> : <InstalledAppOverview />}
      </Tab>
      <Tab index={1} label="Access">
        <Access />
      </Tab>
    </Tabs>
  )
}

const Access = () => (
  <Flex direction="column" gap={16}>
    <MembersView />
    <RolesView />
    <AccessActions />
  </Flex>
)

const accessActions = [
  {
    title: 'Add Member',
    identifier: Views.ADD_MEMBER,
    media: 'account'
  },
  {
    title: 'Create Role',
    identifier: Views.CREATE_ROLE,
    media: 'roles'
  }
]

const AccessActions = () => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {accessActions.map((action) => {
          const { media, title, identifier } = action
          const updatedParams = {
            initialValues: {
              kind: Kind.APP
            },
            app
          }

          const onClick = () => openDashboardEditorView({
            target: identifier,
            params: identifier === Views.CREATE_ROLE ? updatedParams : {
              ...updatedParams,
              initialValues: {
                ...updatedParams.initialValues,
                mode: 'account'
              }
            }
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              media={media}
              title={title}
              height={64}
              width="full"
              onClick={onClick}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                isIconAlwaysVisible: true,
                onClick
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

const InstalledAppOverviewActions = () => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { name } = app!

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <BaseLink href={`https://docs.dashx.com/${name.toLowerCase()}`}>
        <MediaCard
          compact
          media="page-stroked"
          title="View Documentation"
          height={64}
          width="full"
          actions={[ {
            description: '',
            icon: 'external-link',
            isIconAlwaysVisible: true
          } ]}
        />
      </BaseLink>
    </Flex>
  )
}

const InstalledAppOverview = () => (
  <Flex direction="column" gap={32}>
    <InstalledAppOverviewActions />
  </Flex>
)

const CustomAppOverview = () => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { summary = '' } = app!

  return (
    <Flex direction="column" gap={32}>
      <MarkdownRenderer source={summary!} />
      <ResourcesView />
      <OperationsView />
      <DataSourcesView />
      <CustomAppOverviewActions />
    </Flex>
  )
}

const InstallAppView = () => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { identifier, summary } = app!

  const { pricing } = appDetails[identifier as keyof typeof appDetails]
    // TODO: Remove after adding other app details
    || appDetails.messaging

  return (
    <Flex gap={32} direction="column">
      <MarkdownRenderer source={summary!} />
      {/* <Pricing pricing={pricing} /> */}
      <InstallActions />
    </Flex>
  )
}

const Pricing = ({ pricing }: { pricing: typeof appDetails.messaging.pricing }) => (
  <Flex direction="column" gap={16}>
    <Text
      color="dark500"
      fontSize={10}
      fontWeight="bold"
      textTransform="uppercase"
    >
      Pricing
    </Text>
    <Flex direction="column" gap={10}>
      {pricing.map((p) => (
        <Flex alignItems="center" justifyContent="space-between" gap={16} css={{ padding: 16, border: '1px solid dark300' }}>
          <Text fontWeight="bold">{p.title}</Text>
          <Flex direction="column" gap={10} alignItems="flex-end">
            <Text color={p.highlightSubTitle ? 'primary400' : 'dark900'}>{p.subTitle}</Text>
            <Text color="dark700">{p.description}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  </Flex>
)

const InstallActions = () => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params
  const { id: appId, name = '', identifier = '' } = app!

  const queryVariables = {
    archivedAt: 'null',
    or: [
      { appKind: { eq: 'EXTENSION' } }
    ]
  }

  const [ installApp, { loading: submitting } ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ DashboardsListDocument, InstallationsListDocument ]
  })

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'App was installed successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput
  ) => handleInstallApp(values as CreateInstallationInput)

  const onInstallApp = () => {
    handleSubmit({ appId, name, identifier })
  }

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        <MediaCard
          compact
          media="app"
          title="Install App"
          height={64}
          width="full"
          onClick={onInstallApp}
          disabled={submitting}
        />
        <BaseLink href={`https://docs.dashx.com/${name.toLowerCase()}`}>
          <MediaCard
            compact
            media="page-stroked"
            title="View Documentation"
            height={64}
            width="full"
            actions={[ {
              description: '',
              icon: 'external-link',
              isIconAlwaysVisible: true
            } ]}
          />
        </BaseLink>
      </Flex>
    </Flex>
  )
}

export default AppDetailsView
