import React, { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import type HotTableClass from '@handsontable/react/hotTableClass'
import type { CellChange, ChangeSource } from 'handsontable/common'

import Block from './Block'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import mapArraysToObjects from 'lib/mapArraysToObjects'
import useDashboard from 'hooks/useDashboard'
import type { BlockProps } from './Block'

type CSVInputBlockProps = BlockProps & {
  headers: string[]
}

const ImportRecordsDialog = React.lazy(() => import('components/views/graph/ImportRecordsDialog'))
const CSVEditor = React.lazy(() => import('components/views/graph/ImportRecordsDialog').then((module) => ({ default: module.Preview })))

function CSVInputBlock(
  {
    blockRef, block, headers = [], ...other
  }: CSVInputBlockProps
) {
  const [ isOpen, setOpen ] = useState(false)
  const { blockPropertiesState, updateBlockProperties } = useDashboard()

  const blockProperties = useRecoilValue(blockPropertiesState)
  const currentBlockProperties = blockProperties[block.identifier] || {}
  const hotRef = useRef<HotTableClass>(null)

  const handleSubmit = (
    { headers, rows, mappings }: {headers: string[], rows: any[], mappings: (string | null)[]}
  ) => updateBlockProperties({
    [block.identifier]: {
      ...currentBlockProperties,
      headers,
      rows,
      mappedRows: mapArraysToObjects(headers, rows),
      mappings
    }
  })

  const handleChange = (changes: CellChange[] | null, source: ChangeSource) => {
    if (source === 'edit') {
      const data = hotRef.current?.hotInstance?.getData()
      const { headers: updatedHeaders } = currentBlockProperties

      const csvHeaders = updatedHeaders || headers
      const filledRows: any[] = []

      data?.forEach((row) => {
        if (row.filter(Boolean).length) {
          filledRows.push(row)
        }
      })

      if (filledRows.length) {
        updateBlockProperties({
          [block.identifier]: {
            ...currentBlockProperties,
            rows: filledRows,
            mappedRows: mapArraysToObjects(csvHeaders, filledRows)
          }
        })
      }
    }
  }

  const parameters = headers.map((h) => ({ name: h, identifier: h }))

  return (
    <Block direction="column" gap={24} masonryItemRef={blockRef} {...other}>
      <Button
        onClick={() => setOpen(true)}
        label="Upload CSV"
        mode="subtle"
        size="small"
      />
      <React.Suspense fallback={null}>
        <ImportRecordsDialog
          title="Import"
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSubmit={handleSubmit}
          parameters={parameters}
        />

        <Flex direction="column" css={{ height: 400 }}>
          <CSVEditor
            data={currentBlockProperties.rows
              ? JSON.parse(JSON.stringify(currentBlockProperties.rows)) : [ [] ]} // https://github.com/handsontable/handsontable/issues/10518
            headers={currentBlockProperties.headers || headers}
            mappings={currentBlockProperties.headers || headers}
            parameters={parameters}
            hotRef={hotRef}
            disableValidationUI
            afterChange={handleChange}
          />
        </Flex>
      </React.Suspense>
    </Block>
  )
}

export type { CSVInputBlockProps }

export default CSVInputBlock
