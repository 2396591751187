import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import type { Ref } from 'react'
import type { NavLinkProps } from 'react-router-dom'

import isCallableUrl from 'lib/isCallableUrl'
import normalizeUrl, { isRelativeUrl } from 'lib/normalizeUrl'

type BaseLinkProps = Partial<NavLinkProps>

const BaseLink = React.forwardRef((props: BaseLinkProps, innerRef: Ref<HTMLAnchorElement>) => {
  if (props.to || isRelativeUrl(props.href)) {
    const { href, to = href!, ...rest } = props
    if (props.activeClassName) {
      return <NavLink ref={innerRef} to={to} {...rest} />
    }

    return <Link ref={innerRef} to={to} {...rest} />
  }

  const { children, href = '#', ...rest } = props

  const defaultTarget = isCallableUrl(href) ? undefined : '_blank'

  return <a href={normalizeUrl(href)} ref={innerRef} rel="noopener noreferrer" target={defaultTarget} {...rest}>{children}</a>
})

BaseLink.displayName = 'BaseLink'

export default BaseLink

export type { BaseLinkProps }
