import React from 'react'

import Flex from 'components/layout/Flex'
import Loader, { LoaderProps } from 'components/loaders/Loader'
import { styled } from 'styles/stitches'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'

const MIN_HEIGHT = `calc(100vh - ${TOPBAR_HEIGHT}px)`

const Wrapper = styled(Flex, {
  flexGrow: 1,
  height: '10%',

  variants: {
    full: {
      true: {
        minHeight: MIN_HEIGHT
      },
      false: {

      }
    }
  }
})

Wrapper.defaultProps = {
  alignItems: 'center',
  direction: 'column',
  justifyContent: 'center',
  full: true
}

type PageLoaderProps = LoaderProps & StyledProps<typeof Wrapper>

function PageLoader(props: PageLoaderProps) {
  return (
    <Loader
      size="large"
      wrapper={Wrapper}
      {...props}
    />
  )
}

export default PageLoader
