import arrayMutators from 'final-form-arrays'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import sortBy from 'lodash/sortBy'
import { Form, useForm, useFormState } from 'react-final-form'

import Button from 'components/buttons/Button'
import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import SelectInput from 'components/inputs/SelectInput'
import TextLink from 'components/links/TextLink'
import Toolbar from 'components/toolbar/Toolbar'
import useAppMenuElements from 'hooks/useAppMenuElements'
import useRedirectToMenuElement from 'hooks/useRedirectToMenuElement'
import useSubmitHandler from 'hooks/useSubmitHandler'
import {
  GenerateMenuElementsInput,
  DashboardsListDocument,
  Installation,
  Resource,
  useGenerateMenuElementsMutation,
  useInstallationsListQuery,
  useResourcesListQuery,
  useAppListQuery,
  App
} from 'generated/schema'
import { APP_LIST_LIMIT, CUSTOM_CATEGORY_ID } from '../../models/App'
import type { ViewProps } from 'components/views'
import type { Content } from 'components/dataList/DataList'
import type { SelectionHandlerProps } from 'components/providers/DataManagerProvider'

type Params = {
  initialValues: GenerateMenuElementsInput
}

const SelectAllButton = ({ resourcesList = [], selectionHandlerRef }: {
  resourcesList: Resource[],
  selectionHandlerRef: React.MutableRefObject<SelectionHandlerProps | undefined>
}) => {
  const { values } = useFormState()
  const { change } = useForm()

  const toggleSelected = () => {
    if (values.resourceMenuStubs?.length === resourcesList.length) {
      selectionHandlerRef.current?.deselectAllRows()

      change('resourceMenuStubs', [])
    } else {
      selectionHandlerRef.current?.selectAllRows()
      change('resourceMenuStubs', resourcesList.map((resource) => ({ id: resource.id })))
    }
  }

  return (
    <TextLink
      as="button"
      type="button"
      variant="underlined"
      onClick={toggleSelected}
    >
      {values.resourceMenuStubs?.length === resourcesList.length ? 'Deselect All' : 'Select All'}
    </TextLink>
  )
}

const ResourceMenus = ({
  app,
  values
}: {
  app?: App,
  values: GenerateMenuElementsInput
 }) => {
  const { change } = useForm()
  const initialResourceIds = useMemo(
    () => (values.resourceMenuStubs || []).map((r) => r.id),
    [ values ]
  )

  const {
    data: { resourcesList = [] } = {}, loading, error
  } = useResourcesListQuery({
    variables: {
      filter: {
        appId: { eq: app?.id }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: 250
    },
    skip: !app?.id
  })
  const selectionHandlerRef = useRef<SelectionHandlerProps>()

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' }
  ]

  useEffect(() => {
    if (initialResourceIds.length) {
      initialResourceIds.forEach((id: string) => {
        selectionHandlerRef.current?.selectRow(id)
      })
    }
  }, [ initialResourceIds ])

  return (
    <>
      <Toolbar
        title="Resource Menus"
        secondaryElements={(
          <SelectAllButton
            resourcesList={resourcesList as Resource[]}
            selectionHandlerRef={selectionHandlerRef}
          />
        )}
      />
      <DataList
        contents={contents}
        data={resourcesList as Resource[]}
        loading={loading}
        error={error}
        selectionMode="multiple"
        selectionHandlerRef={selectionHandlerRef}
        onRowSelect={(resource: Resource, isSelected) => {
          const resourceId = resource.id
          if (!isSelected) {
            change('resourceMenuStubs', (values.resourceMenuStubs || []).filter(({ id }) => id !== resourceId))
          } else {
            change('resourceMenuStubs', [ ...(values.resourceMenuStubs || []), { id: resourceId } ])
          }
        }}
        empty={{
          title: !app ? 'Select a project first.' : 'This project has no resources.',
          subtitle: 'You will be creating an empty sub menu.'
        }}
        maxHeight={400}
        overflow="auto"
      />
    </>
  )
}

const AppMenus = ({ installation }: {installation?: Installation}) => {
  const menuElements = useAppMenuElements(installation?.app.identifier || '')

  const contents: Content[] = [
    { dataKey: 'icon', slot: 'icon' },
    { dataKey: 'name', slot: 'primary' }
  ]

  return (
    <>
      <Toolbar title="App Menus" />
      <DataList
        contents={contents}
        data={menuElements as any[]}
        empty={{
          title: !installation ? 'Select an app first.' : 'This app has no menus.',
          subtitle: 'You will be creating an empty sub menu.'
        }}
        selectionMode="none"
      />
    </>
  )
}

function AddAppItemView({
  onRequestClose,
  params: { initialValues },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const redirect = useRedirectToMenuElement()

  const [ addToDashboard ] = useGenerateMenuElementsMutation({
    onCompleted: (data) => {
      onRequestClose()
      const menuElement = data.generateMenuElements[0]
      redirect(menuElement)
    },
    refetchQueries: [ DashboardsListDocument ],
    awaitRefetchQueries: true
  })

  const handleAddToDashboard = useSubmitHandler(addToDashboard, {
    successAlert: { message: 'Successfully added to dashboard.' }
  })

  const {
    data: { appsList } = {},
    error: appsListError,
    loading: appsListLoading
  } = useAppListQuery({
    variables: {
      filter: {
        or: [
          { kind: { eq: 'PROJECT' } }
        ]
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    },
    skip: !initialValues.resourceMenuStubs
  })

  const {
    data: { installationsList = [] } = {},
    loading: installationsLoading,
    error: installationsError
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        appKind: { eq: 'EXTENSION' }
      },
      order: [ {
        name: 'asc'
      } ]
    },
    skip: !!initialValues.resourceMenuStubs
  })

  const handleSubmit = (values: GenerateMenuElementsInput) => handleAddToDashboard({
    ...values,
    appId: selectedInstallation?.appId || selectedApp?.id
  })

  const installedAppsList = sortBy(installationsList, (installation) => (
    installation.app.appCategoryId === CUSTOM_CATEGORY_ID ? 1 : 0
  ))

  const defaultApp = (installedAppsList.find(
    (installation) => installation.appId === initialValues.appId
  ) || installedAppsList[0]
  )

  const [
    selectedInstallation, setSelectedInstallation
  ] = useState<Installation | undefined>(defaultApp as Installation)

  const [
    selectedApp, setSelectedApp
  ] = useState<App | undefined>()

  const title = isUpdating ? 'Update App' : 'Add App'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            mutators={{
              ...arrayMutators
            }}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subscription={{ submitting: true, pristine: true, values: true }}
            render={({ handleSubmit, submitting, pristine, values }) => (
              <>
                <Body>
                  <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                    <FormField alwaysDirty name="position" component="input" type="hidden" />
                    <FormField alwaysDirty name="resourceMenuStubs" component="input" type="hidden" />
                    {initialValues.resourceMenuStubs
                      ? (
                        <SelectInput
                          isLoading={appsListLoading}
                          hasError={!!appsListError}
                          isSearchable
                          prependIcon="search"
                          placeholder="Select a project…"
                          options={appsList}
                          label="Project"
                          labelKey="name"
                          valueKey="id"
                          metaKey=""
                          size="small"
                          getOptionIcon={(option) => (
                            option.icon || 'app-custom'
                          )}
                          setValueAsObject
                          input={{
                            value: selectedApp,
                            onChange: (value: App) => setSelectedApp(value)
                          }}
                        />
                      ) : (
                        <SelectInput
                          isLoading={installationsLoading}
                          hasError={!!installationsError}
                          isSearchable
                          prependIcon="search"
                          placeholder="Select an app…"
                          options={installedAppsList as Installation[]}
                          label="App"
                          labelKey="app.name"
                          valueKey="id"
                          size="small"
                          getOptionIcon={(option: Installation) => (
                            option.app?.appCategoryId === CUSTOM_CATEGORY_ID
                              ? 'app-custom'
                              : `app-${option.app?.identifier || 'bridge'}`
                          )}
                          setValueAsObject
                          input={{
                            value: selectedInstallation,
                            onChange: (value: Installation) => setSelectedInstallation(value)
                          }}
                        />
                      )}
                    {initialValues.resourceMenuStubs ? (
                      <ResourceMenus app={selectedApp} values={values} />
                    ) : (
                      <AppMenus installation={selectedInstallation} />
                    )}
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

AddAppItemView.defaultStyle = 'PANEL' as const

export default AddAppItemView
