import React from 'react'
import type { FormEvent, ReactNode } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import * as mixins from 'styles/mixins'
import Card from 'components/card/Card'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton, { IconButtonVariant } from 'components/buttons/IconButton'
import Text from 'components/typography/Text'
import useCombinedRefs from 'hooks/useCombinedRefs'
import useHover from 'hooks/useHover'
import { styled } from 'styles/stitches'
import type { CardProps } from 'components/card/Card'

type MediaCardAction = {
  description: string,
  icon: string,
  isIconAlwaysVisible?: boolean,
  variant?: IconButtonVariant,
  onClick?: () => void
}

type MediaCardProps = Omit<CardProps, 'actions' | 'width'> & {
  actions?: MediaCardAction[],
  active?: boolean,
  compact?: boolean,
  disabled?: boolean,
  media: ReactNode | string,
  isDraggable?: boolean,
  meta?: ReactNode,
  text?: string,
  title: string,
  titlePosition?: 'top' | 'bottom',
  withIcon?: boolean,
  width?: 'normal' | 'full',
  dragHandleProps?: DraggableProvidedDragHandleProps
}

const MEDIA_CARD_HEIGHT = 90

const StyledCard = styled(Card, {
  ...mixins.transition('fluid'),

  borderColor: 'transparent',
  borderStyle: 'solid',
  borderWidth: 2,
  textAlign: 'left',

  '& [data-icon]': {
    ...mixins.transition('fluid'),
    color: 'dark100'
  },

  '& [data-title]': {
    color: 'dark900'
  },

  '& [data-text]': {
    color: 'dark500'
  },

  '& [data-secondary-icon]': {
    color: 'dark100'
  },

  variants: {
    active: {
      true: {
        borderColor: 'primary200'
      }
    },
    isDisabled: {
      true: {
        pointerEvents: 'none',
        boxShadow: 'none',

        cursor: 'not-allowed !important',
        userSelect: 'none',

        '& [data-title]': {
          color: 'dark300'
        },

        '& [data-text]': {
          color: 'dark100'
        }
      }
    },
    withIcon: {
      true: {
        '&:hover': {
          '& [data-icon]': {
            color: 'primary400'
          }
        },

        '&:focus': {
          '& [data-icon]': {
            color: 'primary400'
          }
        }
      }
    },
    isDraggable: {
      true: {}
    }
  }
})

StyledCard.compoundVariant({
  active: true,
  withIcon: true
},
{
  '& [data-icon]': {
    color: 'primary200'
  }
})

StyledCard.compoundVariant({
  withIcon: true,
  isDraggable: true
},
{
  '&:hover': {
    '& [data-icon]': {
      color: 'dark600'
    }
  },
  '&:focus': {
    '& [data-icon]': {
      color: 'dark600'
    }
  }
})

StyledCard.compoundVariant({
  isDisabled: true,
  withIcon: true
},
{
  boxShadow: 'none',

  cursor: 'not-allowed',
  userSelect: 'none',

  '&:hover': {
    '& [data-icon]': {
      color: 'dark100'
    }
  },

  '&:focus': {
    '& [data-icon]': {
      color: 'dark100'
    }
  }
})

StyledCard.defaultProps = {
  active: false,
  isDisabled: false,
  isDraggable: false,
  width: 'normal'
}

const StyledMedia = styled(Flex, {
  borderRightColor: 'light700',
  borderRightStyle: 'solid',
  borderRightWidth: 1,
  paddingRight: 16
})

function isMediaString(media: MediaCardProps['media']): media is string {
  return typeof media === 'string'
}

const MediaCard = React.forwardRef(({
  actions = [],
  disabled = false,
  active,
  dragHandleProps,
  media,
  meta,
  text,
  title,
  titlePosition = 'bottom',
  width = 'normal',
  withIcon = true,
  compact = false,
  ...others
}: MediaCardProps, ref: any) => {
  const [ onHoverProps, isHovered, setIsHovered ] = useHover()
  const combinedRef = useCombinedRefs<HTMLButtonElement | null | any>(ref)

  const renderMedia = () => {
    if (isMediaString(media)) return <Icon data-icon name={media} size={compact ? 24 : 32} />
    return media
  }

  const titleView = (
    <Flex alignItems="center" gap={6}>
      <Text data-title fontWeight="bold" fontSize={14}>{title}</Text>
      {meta}
    </Flex>
  )

  return (
    <StyledCard
      ref={combinedRef}
      active={active}
      isDisabled={disabled}
      disabled={disabled}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      withIcon={withIcon}
      width={width === 'normal' ? 270 : '100%'}
      height={MEDIA_CARD_HEIGHT}
      justifyContent="center"
      {...onHoverProps}
      {...others}
    >
      <Flex gap={20} style={{ width: '100%' }}>
        {dragHandleProps && (
          <Flex alignItems="center">
            <IconButton
              description="Drag handle"
              hideTooltip
              name="drag"
              size={16}
              variant="dark"
              {...dragHandleProps}
            />
          </Flex>
        )}
        {media && (
          <StyledMedia alignItems="center">
            {renderMedia()}
          </StyledMedia>
        )}
        <Flex justifyContent="space-between" grow={1}>
          <Flex gap={16} alignItems="center" justifyContent="center">
            <Flex direction="column" gap={compact ? 6 : 10} justifyContent="center">
              {titlePosition === 'top' && titleView}
              {text && <Text data-text color="dark500" fontSize={12}>{text}</Text>}
              {titlePosition === 'bottom' && titleView}
            </Flex>
          </Flex>
          <Flex direction={compact ? 'row' : 'column'} justifyContent="center" alignItems="center">
            {actions.map((action) => (
              <IconButton
                description={action.description}
                isVisible={action.isIconAlwaysVisible || isHovered}
                key={action.icon}
                name={action.icon}
                hideTooltip={!action.description}
                onClick={(e: FormEvent) => {
                  e.stopPropagation()
                  action.onClick && action.onClick()
                }}
                variant={action.variant || 'dark'}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </StyledCard>
  )
})

export type { MediaCardProps }

export default MediaCard
