import React, { useContext, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import * as mixins from 'styles/mixins'
import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InstallAppForm from 'components/forms/InstallAppForm'
import InternalContext from 'components/contexts/InternalContext'
import SimpleLoader from 'components/loaders/SimpleLoader'
import Text from 'components/typography/Text'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, CreateInstallationInput, InstallationsListDocument, UpdateInstallationInput, useCreateInstallationMutation, useInstallationsAggregateQuery, InstallationsListQueryVariables } from 'generated/schema'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { getAppIcon } from 'models/App'
import { MarkdownRenderer } from 'components/markdown'
import { styled } from 'styles/stitches'
import type { App } from 'generated/schema'
import type { ViewStyleComponentRenderProps, ViewProps } from 'components/views'

const TITLE = 'App Directory'

type FormValues = CreateInstallationInput | UpdateInstallationInput

type Params = {
  dashboardId: string,
  selectedApp?: App,
  isInstalling?: boolean,
  queryVariables?: InstallationsListQueryVariables
}

const StyledHeader = styled(Flex, {
  backgroundColor: 'light100',
  borderBottomColor: 'light700',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  height: 96,
  alignItems: 'center',
  paddingX: DIALOG_PADDING,
  paddingY: 24
})

const StyledCloseIcon = styled(Icon, {
  ...mixins.transition('simple'),

  color: 'dark100',
  cursor: 'pointer',

  '&:hover': {
    color: 'dark500'
  }
})

function AddAppView({
  closeView,
  onAfterClose,
  onRequestClose,
  params: { queryVariables, isInstalling: installing, selectedApp: initialSelectedApp },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { push } = useHistory()
  const [ selectedApp, setSelectedApp ] = useState<App | null | undefined>(initialSelectedApp)
  const [ isInstalling, setIsInstalling ] = useState(installing)
  const { currentDashboard } = useContext(InternalContext)!

  const {
    data: { installationsAggregate } = {},
    loading: installationsLoading
  } = useInstallationsAggregateQuery({
    variables: {
      filter: {
        appId: { eq: selectedApp?.id }
      }
    },
    skip: !selectedApp?.id
  })

  const isInstalled = useMemo(() => (installationsAggregate?.count || 0) >= 1,
    [ installationsAggregate ])

  const resetInstalling = () => {
    if (selectedApp?.kind === 'EXTENSION') {
      setIsInstalling(false)
    } else {
      setSelectedApp(null)
      setIsInstalling(false)
    }
  }

  const [ installApp ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      onRequestClose()
      closeView()
      if (data.createInstallation.appKind === 'EXTENSION') {
        push({
          pathname: 'apps',
          search: `id=${data.createInstallation.appId}`
        })
      }
    },
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: InstallationsListDocument, variables: queryVariables }
    ]
  })

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'App was installed successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput | UpdateInstallationInput
  ) => handleInstallApp(values as CreateInstallationInput)

  const formInitialValues = useMemo(() => ({
    appId: selectedApp?.id,
    name: selectedApp?.name
  } as FormValues),
  [
    selectedApp
  ])

  const renderInstallButton = () => (
    isInstalled
      ? (
        <Flex gap={20} alignItems="center">
          <Chip label="Installed" variant="positive" icon="tick-circle" />
          {(![ 'EXTENSION' ].includes(selectedApp?.kind || '')) && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <Button variant="outline" size="small" label="Install Another" onClick={() => setIsInstalling(true)} />
            </>
          )}
        </Flex>
      )
      : <Button label="Install App" onClick={() => setIsInstalling(true)} />
  )

  const selectedAppView = ({ Body }: ViewStyleComponentRenderProps) => {
    const { icon, identifier, name, summary } = selectedApp || {}

    return (
      <>
        <StyledHeader>
          <Flex alignItems="stretch" grow={1} justifyContent="space-between">
            <Flex alignItems="center" gap={20}>
              {identifier && (
                <>
                  <AppIcon
                    icon={icon}
                    identifier={identifier as AppIconIdentifier}
                    size={32}
                  />
                  <Divider orientation="vertical" />
                </>
              )}
              <Flex direction="column" gap={8}>
                <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
                  {name}
                </Text>
              </Flex>
            </Flex>
            <Flex gap={16} alignItems="center">
              {installationsLoading ? <Flex alignItems="center"><SimpleLoader size="small" /></Flex> : renderInstallButton()}
              <StyledCloseIcon size={22} name="cross-thin" onClick={onRequestClose} />
            </Flex>
          </Flex>
        </StyledHeader>
        <Body>
          <MarkdownRenderer source={summary!} />
        </Body>
      </>
    )
  }

  const installAppView = (viewComponents: ViewStyleComponentRenderProps) => {
    const { icon, identifier, name } = selectedApp || {}
    const { Header, SubHeader } = viewComponents
    const isIntegration = selectedApp?.kind === 'INTEGRATION'
    return (
      <>
        <Header
          title={selectedApp?.kind !== 'EXTENSION' ? TITLE : 'App Details'}
          onCloseClick={onRequestClose}
          onBackArrowClick={resetInstalling}
        />
        <SubHeader>
          <Flex alignItems="stretch" grow={1} justifyContent="space-between">
            <Flex alignItems="center" gap={20}>
              {isIntegration && identifier ? (
                <>
                  <img
                    style={{ width: '32px' }}
                    src={getAppIcon(identifier)}
                    alt={identifier || ''}
                  />
                  <Divider orientation="vertical" />
                </>
              ) : (
                <>
                  <AppIcon
                    icon={icon}
                    identifier={identifier as AppIconIdentifier}
                    size={32}
                  />
                  <Divider orientation="vertical" />
                </>
              )}
              <Flex direction="column" gap={8}>
                <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
                  {name}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </SubHeader>
        <InstallAppForm
          currentDashboard={currentDashboard}
          viewComponents={viewComponents}
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
          selectedApp={selectedApp as App}
        />
      </>
    )
  }

  return (
    <View
      contentLabel={TITLE}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      {...other}
    >
      {(renderProps) => {
        if (selectedApp && isInstalling) return installAppView(renderProps)
        return selectedAppView(renderProps)
      }}
    </View>
  )
}

AddAppView.defaultStyle = 'PANEL' as const

export default AddAppView
