import React from 'react'

import AppPage from 'components/pages/AppPage'
import Block from 'components/blocks/Block'
import DashxAppsView from 'components/views/apps/DashxAppsView'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import ThirdPartyAppsList from 'components/views/apps/ThirdPartyAppsList'
import TitleBlock from 'components/blocks/TitleBlock'
import useQueryParams from 'hooks/useQueryParams'
import YourAppsView from 'components/views/apps/YourAppsView'

function AppsPage() {
  const queryParams = useQueryParams()
  const id = queryParams.get('id')

  if (id) return <AppPage />

  return (
    <>
      <TitleBlock heading="Apps" />
      <Block
        direction="column"
        gap={36}
        width={{ md: '100%' }}
      >
        <Tabs>
          <Tab index={0} label="Your Apps">
            <YourAppsView />
          </Tab>
          <Tab index={1} label="DashX Apps">
            <DashxAppsView />
          </Tab>
          <Tab index={2} label="Third-Party Apps">
            <ThirdPartyAppsList />
          </Tab>
        </Tabs>
      </Block>
    </>
  )
}

export default AppsPage
