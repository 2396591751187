import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay, useDraggable } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import InternalContext from 'components/contexts/InternalContext'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useHotKey from 'hooks/useHotKey'
import useRedirectToMenuElement from 'hooks/useRedirectToMenuElement'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { APP_LIST_LIMIT } from 'models/App'
import { AppFragmentFragment, DashboardsListDocument, GenerateMenuElementsInput, Installation, useAppCategoriesListQuery, useGenerateMenuElementsMutation, useInstallationsListQuery } from 'generated/schema'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'
import Icon from 'components/icons/Icon'

type FormValues = GenerateMenuElementsInput

type Params = ViewParams[Views.ADD_MENU_APP]

type AppGridProps = {
  installations: Installation[],
  title: string,
  draggedApp: AppFragmentFragment | null
}

const AddMenuAppView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')

  const {
    data: { appCategoriesList = [] } = {}
  } = useAppCategoriesListQuery({
    variables: {
      filter: {
        kind: { eq: 'EXTENSION' }
      },
      limit: APP_LIST_LIMIT,
      order: [ { position: 'asc' } ]
    }
  })

  const {
    data: { installationsList = [] } = {},
    loading: installationsListLoading,
    error: installationsListError
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        or: [
          { appKind: { eq: 'EXTENSION' } }
        ]
      }
    }
  })

  const { draggedAppState, draggingOverMenuIdState } = useDashboard()

  const [ draggedApp, setDraggedApp ] = useRecoilState(draggedAppState)
  const setDraggingOverMenuId = useSetRecoilState(draggingOverMenuIdState)

  useEffect(() => () => {
    setDraggedApp(null)
  }, [ setDraggedApp ])

  useHotKey({
    Escape: () => {
      setDraggedApp(null)
      setDraggingOverMenuId(null)
    }
  })

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add App Menu"
        heading="Add Menu"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>
          Provide easy access to Apps by
          {' '}<b>dragging</b> an App Menu onto the <b>sidebar</b> or <b>topbar</b>.
        </Text>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={installationsList}
          loading={installationsListLoading}
          error={installationsListError}
        >
          {appCategoriesList.map((category) => {
            const categorizedInstallations = installationsList.filter(
              (installation) => installation.app.appCategoryId === category.id
            )

            const filteredInstallations = categorizedInstallations?.filter(
              (app) => app.name.toLowerCase().includes(search.toLowerCase())
            )

            if (!filteredInstallations.length) return null

            return (
              <AppsGrid
                key={category.id}
                installations={filteredInstallations as Installation[]}
                title={`${category.name} Apps`}
                draggedApp={draggedApp}
              />
            )
          })}
        </DashboardEditorLoader>
        {createPortal(
          <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
            {draggedApp?.id ? (
              <DraggableAppCard
                app={installationsList.find((i) => i.appId === draggedApp.id)}
                isActive
                isOverlay
                mode="normal"
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DashboardEditorBody>
    </>
  )
}

const DraggableAppCard = ({ app, onClick, isActive, isOverlay, isInstalled, mode = 'withArrow' }: any) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: app.id,
    data: app
  })

  return (
    <MediaCard
      compact
      onClick={onClick}
      key={app.id}
      css={{
        opacity: isActive ? 0.5 : 1,
        border: isActive && !isOverlay ? '1px dashed gray' : '1px solid transparent'
      }}
      media={(
        <AppIcon
          technology={app.technology}
          isCustomApp={!!app.workspaceId}
          identifier={app.identifier as AppIconIdentifier}
          icon={app.icon}
          size={32}
        />
      )}
      meta={isInstalled ? (
        <Icon name="accept" color="positive400" />
      ) : undefined}
      title={app.name}
      height={64}
      width="full"
      isDraggable
      actions={[
        {
          description: '',
          icon: 'drag-block',
          isIconAlwaysVisible: true
        },
        ...(mode === 'withArrow' ? [ {
          description: '',
          icon: 'arrow-right',
          isIconAlwaysVisible: true
        } ] : [])
      ]}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    />
  )
}

const AppsGrid = ({ installations, title = '', draggedApp }: AppGridProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { currentDashboard } = useContext(InternalContext)!

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params
  const { placement = 'SIDE', parentId } = initialValues as FormValues

  const redirect = useRedirectToMenuElement()

  const dashboardId = currentDashboard?.id

  const [ addToDashboard ] = useGenerateMenuElementsMutation({
    onCompleted: (data) => {
      const menuElement = data.generateMenuElements[0]
      if (menuElement) redirect(menuElement)
      else stepBackDashboardEditor()
    },
    refetchQueries: [ DashboardsListDocument ],
    awaitRefetchQueries: true
  })

  const handleAddToDashboard = useSubmitHandler(addToDashboard, {
    successAlert: { message: 'Successfully added to dashboard.' }
  })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Flex direction="column" gap={2}>
        {installations.map((installation) => {
          const isActive = draggedApp?.id === installation.appId

          return (
            <DraggableAppCard
              key={installation.appId}
              app={installation.app}
              onClick={() => handleAddToDashboard({
                appId: installation.appId,
                dashboardId,
                parentId,
                ...(parentId ? {} : { placement })
              })}
              isActive={isActive}
              mode="normal"
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export { DraggableAppCard }

export default AddMenuAppView
