import React from 'react'
import get from 'lodash/get'
import isObject from 'lodash/isObject'

import Chip from 'components/chip/Chip'
import type { ChipProps } from 'components/chip/Chip'
import type { RendererOptions } from 'components/dataTable/types'

type StatusRendererOwnProps = {
  statusMap?: Record<string, string>,
  statusVariants?: Record<string, ChipProps['variant']>,
  isCollapsible?: boolean
}

type StatusRendererProps<T> = RendererOptions<T> & StatusRendererOwnProps

type GenerateStatusRendererProps = StatusRendererOwnProps

const defaultStatusVariants: Record<string, ChipProps['variant']> = {
  DRAFT: 'light',
  ACTIVE: 'positive',
  PAUSED: 'warning',
  STOPPED: 'negative'
}

function StatusRenderer<T>({
  dataKey,
  rowData,
  isHovered,
  statusMap,
  statusVariants = defaultStatusVariants,
  isCollapsible = true,
  prefix = '',
  suffix = ''
}: StatusRendererProps<T>) {
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const label = get(rowData, `${prefixContent}${dataKey}${suffixContent}`)

  return (
    <Chip
      label={statusMap?.[label] ?? (isObject(label) ? JSON.stringify(label) : label)}
      title={statusMap?.[label] ?? label}
      variant={statusVariants[label]}
      isCollapsed={isCollapsible && !isHovered}
    />
  )
}

function generateStatusRenderer<T>(props: GenerateStatusRendererProps) {
  return (
    (rendererProps: RendererOptions<T>) => <StatusRenderer {...rendererProps} {...props} />
  )
}

export {
  generateStatusRenderer
}

export default StatusRenderer
