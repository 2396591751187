import React, { useContext } from 'react'

import PaletteIndicator from 'components/artwork/PaletteIndicator'
import DrawerBlock from 'components/blocks/DrawerBlock'
import TitleBlock from 'components/blocks/TitleBlock'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceBrandingForm from 'components/forms/WorkspaceBrandingForm'
import WorkspaceThemeForm from 'components/forms/WorkspaceThemeForm'
import {
  useUpdateWorkspaceBrandingMutation, useUpdateWorkspaceThemeMutation
} from 'generated/schema'
import useSubmitHandler from 'hooks/useSubmitHandler'
import type { DrawerBlockRenderProps } from 'components/blocks/DrawerBlock'

function BrandingPage() {
  const { currentWorkspace, resetThemePaletteRef } = useContext(WorkspaceContext)!

  const [ updateWorkspaceBranding ] = useUpdateWorkspaceBrandingMutation()
  const [ updateWorkspaceTheme ] = useUpdateWorkspaceThemeMutation()

  const handleSubmitBrandingForm = useSubmitHandler(updateWorkspaceBranding, {
    successAlert: { message: 'Branding settings saved.' }
  })

  const handleSubmitThemeForm = useSubmitHandler(updateWorkspaceTheme, {
    successAlert: { message: 'Theme settings saved.' }
  })

  return (
    <>
      <TitleBlock heading="Branding" />
      <DrawerBlock icon="branding" title="General" summary="Name, Logo, Wallpaper">
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <WorkspaceBrandingForm
            initialValues={{
              id: currentWorkspace.id,
              logoFull: currentWorkspace.logoFull,
              logoSymbol: currentWorkspace.logoSymbol,
              name: currentWorkspace.name,
              wallpaper: currentWorkspace.wallpaper
            }}
            onSubmit={useSubmitHandler.withCallback(handleSubmitBrandingForm, closeDrawer)}
            onCancel={closeDrawer}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="theme"
        summary={<PaletteIndicator />}
        title="Theme"
      >
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <WorkspaceThemeForm
            initialValues={{ id: currentWorkspace.id, themeId: currentWorkspace.themeId }}
            onSubmit={useSubmitHandler.withCallback(handleSubmitThemeForm, closeDrawer)}
            onCancel={() => {
              resetThemePaletteRef.current()
              closeDrawer()
            }}
          />
        )}
      </DrawerBlock>
    </>
  )
}

export default BrandingPage
