import React from 'react'

import FormField from 'components/form/FormField'
import TextInput from 'components/inputs/TextInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { TextInputProps } from 'components/inputs/TextInput'

type PasswordFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'password'>

const PasswordField = ({ name, ...others }: PasswordFieldProps) => (
  <FormField
    component={TextInput}
    name={name}
    type="password"
    {...others}
  />
)

export default PasswordField
