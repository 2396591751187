import { gql } from '@apollo/client'

import type { ClientState } from 'client/state/types'
import type { Scalars } from 'generated/schema'

/* Default Data */

const DEFAULT_SESSION: Session = {
  __typename: 'Session',
  id: null,
  token: null,
  isMfaRequired: null,
  mfaVerifiedAt: null
}

/* Types */

export type Session = {
  __typename: 'Session',
  id: string | null,
  token: string | null,
  isMfaRequired: boolean | null,
  mfaVerifiedAt: Scalars['Timestamp'] | null
}

export type SessionQuery = {
  session: Session
}

/* Queries */

export const SESSION_QUERY = gql`
  query SessionQuery {
    session @client {
      id
      token
      isMfaRequired
      mfaVerifiedAt
    }
  }
`

/* Default Query */

export const writeDefaults = {
  query: SESSION_QUERY,
  data: { session: DEFAULT_SESSION }
}

/* Mutations */

export const SET_SESSION_MUTATION = gql`
  mutation SetSessionMutation($session: SessionInput!) {
    setSession(session: $session) @client
  }
`

/* ClientState */

export default {
  defaults: {
    query: SESSION_QUERY,
    data: { session: DEFAULT_SESSION }
  },

  resolvers: {
    Mutation: {
      setSession: (_, args, { cache }) => {
        const { session } = cache.readQuery({ query: SESSION_QUERY })

        cache.writeQuery({
          query: SESSION_QUERY,
          data: {
            session: {
              ...session,
              ...args.session
            }
          }
        })

        return null
      }
    }
  }
} as ClientState
