import { gql } from '@apollo/client'

import type { ClientState } from 'client/state/types'

/* Default Data */

const DEFAULT_PREFERENCES: Preferences = {
  __typename: 'Preferences',
  activeDashboardIdentifier: '',
  isSidebarMinimized: false
}

/* Types */

export type Preferences = {
  __typename: 'Preferences',
  activeDashboardIdentifier: string,
  isSidebarMinimized: boolean
}

export type PreferencesInput = Pick<Preferences, 'isSidebarMinimized'>

export type PreferencesQuery = {
  preferences: Preferences
}

/* Queries */

export const PREFERENCES_QUERY = gql`
  query PreferencesQuery {
    preferences @client {
      activeDashboardIdentifier,
      isSidebarMinimized
    }
  }
`

/* Mutations */

export const SAVE_PREFERENCES_MUTATION = gql`
  mutation SavePreferencesMutation($preferences: PreferencesInput!) {
    savePreferences(preferences: $preferences) @client
  }
`

/* ClientState */

export default {
  defaults: {
    query: PREFERENCES_QUERY,
    data: { preferences: DEFAULT_PREFERENCES }
  },

  resolvers: {
    Mutation: {
      savePreferences: (_, args, { cache }) => {
        const { preferences } = cache.readQuery({ query: PREFERENCES_QUERY })

        cache.writeQuery({
          query: PREFERENCES_QUERY,
          data: {
            preferences: {
              ...preferences,
              ...args.preferences
            }
          }
        })

        return null
      }
    }
  }
} as ClientState
