import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'

import Account from 'models/Account'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextLink from 'components/links/TextLink'
import TextInput from 'components/inputs/TextInput'
import type { CreateSessionInput } from 'generated/schema'

function LoginForm(props: FormProps<CreateSessionInput>) {
  return (
    <Form
      validate={(values) => Account.validate(values, [ 'email', 'password' ])}
      subscription={{
        submitting: true,
        pristine: true,
        values: true
      }}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap={16}>
            <Field name="email" component={TextInput} type="text" inputMode="email" placeholder="Enter your work email address" autoFocus autoComplete="on" />
            <Field name="password" component={TextInput} type="password" placeholder="Enter password" autoComplete="on" />
            <Flex direction="column" alignItems="flex-end" gap={32}>
              <TextLink
                fontSize={12}
                mode="subtle"
                to={{ pathname: '/forgot-password', state: { email: values.email } }}
              >
                Forgot Password?
              </TextLink>
              <Button type="submit" label="Sign In" disabled={submitting || pristine} />
            </Flex>
          </Flex>
        </form>
      )}
      {...props}
    />
  )
}

export default LoginForm
