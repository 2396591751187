import React, { useContext } from 'react'
import { Field, Form, FormProps } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import TextInput from 'components/inputs/TextInput'
import updateMenuElements from 'lib/updateMenuElements'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler, { FormPropsWithId } from 'hooks/useSubmitHandler'
import { CreateMenuElementInput, UpdateMenuElementInput, useCreateMenuElementMutation, useUpdateMenuElementMutation } from 'generated/schema'
import { LiveMenuEditorOrchestrator } from './AddMenuElementView'
import { MenuElementPositionContext, useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { SidePaneFooter } from 'components/sidePane'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type InitialValues = Partial<ComputedMenuElement & MenuElementPositionContext>

type Params = ViewParams[Views.ADD_MENU_ELEMENT]

function AddMenuHeaderView() {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params
  const {
    placement = 'SIDE',
    id,
    name,
    parentId,
    position,
    kind = 'GROUP'
  } = initialValues as InitialValues

  const { currentDashboard, parentIdToMenuElementsMap } = useContext(InternalContext)!

  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition } = useMenuElementPositionContext() || {}

  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted: () => stepBackDashboardEditor()
  })

  const [ updateMenuElement ] = useUpdateMenuElementMutation({
    onCompleted: () => stepBackDashboardEditor()
  })

  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleMenuItemFormSubmit = (
    values: FormValues,
    form: FormPropsWithId<FormValues>['form']
  ) => {
    if (id) {
      return handleUpdateMenuElementSubmit({
        ...values,
        id
      }, form as FormProps<UpdateMenuElementInput>['form'])
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId
    })
  }

  return (
    <Form
      initialValues={{
        id,
        name,
        parentId,
        placement,
        position: (id ? position : newNonStickyPosition)!,
        kind
      }}
      onSubmit={handleMenuItemFormSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={(values) => MenuElement.validate(values, [ 'name' ])}
      render={({ handleSubmit, submitting, pristine }) => (
        <>
          <DashboardEditorBody>
            <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
              <Grid alignItems="center" gap={16} columns={1}>
                <Field
                  autoFocus
                  component={TextInput}
                  name="name"
                  label="Label"
                  size="small"
                  type="text"
                />
              </Grid>
              <input type="submit" style={{ display: 'none' }} />
            </Flex>
            <LiveMenuEditorOrchestrator />
          </DashboardEditorBody>
          <SidePaneFooter variant="small" isSticky>
            <Flex gap={24} direction="row-reverse">
              <Button type="submit" size="small" label="Submit" disabled={submitting || pristine} onClick={handleSubmit} />
              <Button label="Cancel" size="small" variant="outline" mode="subtle" onClick={() => stepBackDashboardEditor()} />
            </Flex>
          </SidePaneFooter>
        </>
      )}
    />
  )
}

export default AddMenuHeaderView
