import last from 'lodash/last'

const minRankValue = -2147483648
const maxRankValue = 2147483647

function validate(start: number, end: number) {
  const validStart = Math.max(start, minRankValue)
  const validEnd = Math.min(end, maxRankValue)

  return [ validStart, validEnd ]
}

function generatePosition(start: number = minRankValue, end: number = maxRankValue) {
  const [ validStart, validEnd ] = validate(start, end)

  return Math.floor((validStart + validEnd) / 2)
}

const getMaxPosition = (reorderable: readonly any[]) => Math.max(
  reorderable[0]?.position || 0, last(reorderable)?.position || 0
)

export { getMaxPosition }
export default generatePosition
