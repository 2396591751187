import uuid from 'uuid-random'
import dayjs from 'dayjs'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { HotTable } from '@handsontable/react'
import { read, utils } from 'xlsx'
import { registerAllModules } from 'handsontable/registry'
import type HotTableClass from '@handsontable/react/hotTableClass'
import type { CellChange, ChangeSource } from 'handsontable/common'

import * as mixins from 'styles/mixins'
import Button from 'components/buttons/Button'
import Dialog from 'components/dialog/Dialog'
import DialogBody from 'components/dialog/DialogBody'
import DialogFooter from 'components/dialog/DialogFooter'
import DialogHeader from 'components/dialog/DialogHeader'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import GlobalContext from 'components/contexts/GlobalContext'
import Grid from 'components/layout/Grid'
import Icon from 'components/icons/Icon'
import Loader from 'components/loaders/Loader'
import SelectInput from 'components/inputs/SelectInput'
import SidePaneSubHeader from 'components/sidePane/SidePaneSubHeader'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import { colorVars } from 'styles/theme'
import { DataTypeKind } from 'models/DataType'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { styled } from 'styles/stitches'
import type { ModalProps } from 'components/modal/Modal'
import type { Parameter } from 'generated/schema'

import 'handsontable/dist/handsontable.min.css'

registerAllModules()

type ImportRecordsDialogProps = {
  isOpen: ModalProps['isOpen'],
  onClose: () => void,
  onSubmit: (
    params: { headers: string[], rows: any[], mappings: (string | null)[] }
  ) => Promise<any>,
  title: string,
  parameters: (Pick<Parameter, 'name' | 'identifier'> & Partial<Parameter>)[]
}

const DIALOG_WIDTH = 800
const DIALOG_BODY_HEIGHT = 514

const StyledFileInput = styled(Flex, {
  ...mixins.transition('simple'),

  borderColor: 'dark100',
  borderRadius: 6,
  overflow: 'hidden',
  position: 'relative',
  height: DIALOG_BODY_HEIGHT - DIALOG_PADDING * 2,
  width: '100%',

  '&:hover': {
    borderColor: 'dark200'
  },

  variants: {
    variant: {
      bordered: {
        borderStyle: 'dashed',
        borderWidth: 2
      },
      bare: {}
    }
  }
})

const MediaButton = styled('button', {
  ...mixins.transition('simple'),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 10,
  width: 100,
  height: 100,
  borderRadius: 4,
  border: '2px solid dark100',
  color: 'dark500',
  cursor: 'pointer',

  '&:hover': {
    borderColor: 'dark200',
    color: 'dark700'
  },

  '&:focus': {
    borderColor: 'dark200',
    color: 'dark700'
  }
})

const importOptions = [
  {
    label: 'Upload',
    icon: 'import'
  },
  {
    label: 'URL',
    icon: 'link'
  }
]

const UploadView = ({ fileRejectionError, setFile, setFileRejectionError }: any) => {
  const [ step, setStep ] = useState<'drop' | 'paste'>('drop')
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      const [ newFile ] = acceptedFiles
      setFile(newFile)
      setFileRejectionError(null)
    } else {
      setFile(null)
      setFileRejectionError(fileRejections[0].errors[0].message)
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .numbers',
    noClick: true
  })
  const { css, ...rootProps } = getRootProps()

  const handleMediaButtonClick = (identifier: string) => {
    switch (identifier) {
      case 'import':
        open()
        break
      case 'link':
        setStep('paste')
        break
    }
  }

  const [ link, setLink ] = useState('')

  const renderback = () => (
    <Flex
      as="button"
      type="button"
      alignItems="center"
      gap={4}
      css={{
        ...mixins.dropShadow('icon', colorVars.dark500rgb),
        alignSelf: 'flex-start',
        zIndex: 'above',
        color: 'dark500',
        position: 'absolute',
        top: 0,
        left: 0
      } as any}
      onClick={() => {
        setStep('drop')
      }}
    >
      <Icon size={16} name="arrow-left" />
      <Text
        color="dark500"
        fontSize="10"
        textTransform="uppercase"
        shrink={0}
      >
        Back
      </Text>
    </Flex>
  )

  const handleImportFromUrl = () => fetch(link).then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.blob()
  }).then((blob) => {
    setFile(new File([ blob ], link.split('/').pop() || ''))
  }).catch(() => {
    setFileRejectionError('Invalid URL')
  })

  if (step === 'paste') {
    return (
      <StyledFileInput
        alignItems="center"
        justifyContent="center"
        direction="column"
        gap={18}
        variant="bare"
        onPaste={(event: any) => {
          setLink(event.clipboardData?.getData('text'))
          setFileRejectionError(null)
        }}
      >
        {renderback()}
        <Text>Paste URL here</Text>
        <Flex gap={10} alignSelf="stretch">
          <TextInput
            autoFocus
            placeholder="https://example.com/file.csv"
            input={{
              onChange: (event: any) => {
                setLink(event.target.value)
                setFileRejectionError(null)
              },
              value: link
            }}
            meta={{
              touched: !!link,
              error: fileRejectionError
            }}
            onPaste={(event: any) => {
              event.stopPropagation()
            }}
          />
        </Flex>
        <Button
          label="Import"
          disabled={!link}
          mode="subtle"
          onClick={handleImportFromUrl}
        />
      </StyledFileInput>
    )
  }

  return (
    <StyledFileInput
      {...rootProps}
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={48}
      variant="bordered"
    >
      <Text>Drag-and-drop file(s), <TextLink onClick={open}>browse</TextLink> or choose from:</Text>
      <Flex gap={8}>
        {importOptions.map(({ label, icon }) => (
          <MediaButton as="button" key={label} onClick={() => handleMediaButtonClick(icon)}>
            <Icon
              size={32}
              name={icon}
            />
            <Text css={{ color: 'inherit' }}>{label}</Text>
          </MediaButton>
        ))}
      </Flex>
      <input {...getInputProps()} />
    </StyledFileInput>
  )
}

type PreviewProps = {
  data: any[][],
  headers: string[],
  hotRef?: React.MutableRefObject<HotTableClass | null>,
  mappings?: (string| null)[],
  parameters?: (Pick<Parameter, 'name' | 'identifier'> & Partial<Parameter>)[],
  disableValidationUI?: boolean,
  readOnly?: boolean,
  afterChange?: (changes: CellChange[] | null, source: ChangeSource) => void
}

const Preview = ({
  data,
  headers,
  hotRef,
  mappings,
  parameters,
  disableValidationUI,
  readOnly,
  afterChange
}: PreviewProps) => {
  const validators = mappings?.map((mapping) => {
    if (!mapping) return undefined

    const parameter = parameters?.find((parameter) => parameter.identifier === mapping)
    const attribute = parameter?.attribute || parameter

    if (!attribute) return undefined
    if (!attribute.dataType) return undefined

    return (value: any, callback: any) => {
      if (!value) return callback(attribute.isNullable)

      switch (attribute.dataType!.kind) {
        case DataTypeKind.BIGDECIMAL:
          return callback(typeof value === 'number' || !Number.isNaN(Number(value)))
        case DataTypeKind.BIGINT:
          return callback(typeof value === 'number' || !Number.isNaN(Number.parseInt(value, 10)))
        case DataTypeKind.BOOLEAN:
          return callback(typeof value === 'boolean')
        case DataTypeKind.DATE:
          return callback(dayjs(value).isValid())
        case DataTypeKind.DURATION:
          return callback(dayjs(value, 'HH:mm:ss').isValid())
        case DataTypeKind.ENUM:
          return callback(attribute.fieldTypeSettings?.options?.includes(value))
        case DataTypeKind.FLOAT:
          return callback(typeof value === 'number' || !Number.isNaN(Number.parseFloat(value)))
        case DataTypeKind.ID:
          return callback(typeof value === 'string' || typeof value === 'number')
        case DataTypeKind.INT:
          return callback(typeof value === 'number' || !Number.isNaN(Number.parseInt(value, 10)))
        case DataTypeKind.JSON:
          return callback((() => {
            try {
              JSON.parse(value)
              return true
            } catch {
              return false
            }
          })())
        case DataTypeKind.STRING:
          return callback(typeof value === 'string')
        case DataTypeKind.TIME:
          return callback(dayjs(value, 'HH:mm:ss').isValid())
        case DataTypeKind.TIMESTAMP:
          return callback(dayjs(value).isValid())
        case DataTypeKind.UUID:
          return callback(uuid.test(value))
        default:
          return callback(true)
      }
    }
  }) || []

  useEffect(() => {
    hotRef?.current?.hotInstance?.validateCells()
  }, [ hotRef ])

  return (
    data.length ? (
      <HotTable
        data={data}
        colHeaders={headers}
        columns={mappings?.length
          ? headers.map((_, index) => ({
            validator: validators[index],
            className: !disableValidationUI && mappings[index] ? 'htValid' : undefined
          }))
          : undefined}
        licenseKey="non-commercial-and-evaluation"
        height="100%"
        width="100%"
        stretchH="all"
        dropdownMenu
        contextMenu
        manualRowMove
        filters
        rowHeaders
        multiColumnSorting
        manualColumnResize
        autoColumnSize
        ref={hotRef}
        readOnly={readOnly}
        afterRender={() => {
          hotRef?.current?.hotInstance?.validateCells()
        }}
        afterChange={afterChange}
      />
    ) : <Text>No Data</Text>
  )
}

type MappingProps = {
  parameters: (Pick<Parameter, 'name' | 'identifier'> & Partial<Parameter>)[],
  columns: string[],
  mappings: any[],
  setMappings: React.Dispatch<React.SetStateAction<(string | null)[]>>
}

const Mapping = (
  { parameters, columns, mappings, setMappings }: MappingProps
) => (
  <Flex direction="column" gap={20}>
    <Grid columns={2} gap={20}>
      {columns.map((column: string, index) => (
        <Fragment key={column}>
          <Flex justifyContent="center" direction="column" gap={4}>
            <Text truncate>{column}</Text>
          </Flex>
          <SelectInput
            options={
              parameters.filter((parameter) => (
                !mappings.includes(parameter.identifier)
                  || parameter.identifier === mappings[index]
              ))
            }
            size="small"
            labelKey="name"
            valueKey="identifier"
            input={{
              value: mappings[index],
              onChange: (value: string) => {
                if (!value) {
                  setMappings((prev) => {
                    const newMappings = [ ...prev ]
                    newMappings[index] = null
                    return newMappings
                  })
                  return
                }

                setMappings((prev) => {
                  const newMappings = [ ...prev ]
                  newMappings[index] = value
                  return newMappings
                })
              }
            }}
            isClearable
          />
        </Fragment>
      ))}
    </Grid>
  </Flex>
)

const ImportRecordsDialogWrapped = (
  { parameters, onClose, onSubmit, title }: ImportRecordsDialogProps
) => {
  const { openFailureAlert } = useContext(GlobalContext)!
  const [ view, setView ] = useState<'upload' | 'edit' | 'mapping' | 'preview' | 'submitting'>('upload')
  const [ fileRejectionError, setFileRejectionError ] = useState<string | null>(null)
  const [ file, setFile ] = useState<File | null>(null)
  const hotRef = useRef<HotTableClass>(null)

  const [ data, setData ] = useState<any[]>([])
  const [ headers, setHeaders ] = useState<any[]>([])
  const [ mappings, setMappings ] = useState<(string | null)[]>(() => headers.map(() => null))

  useEffect(() => {
    if (!file) return

    (async () => {
      try {
        const ab = await file.arrayBuffer()

        /* parse */
        const wb = read(ab)

        /* generate array of data from the first worksheet */
        const ws = wb.Sheets[wb.SheetNames[0]] // get the first worksheet
        const data = utils.sheet_to_json(ws) // generate objects

        const heads = Object.keys((data[0] || {}) as any)
        setHeaders(heads)
        setData(data.map((d) => Object.values(d as any)))
        setMappings(heads.map(
          (h) => parameters
            .find((p) => (p.identifier === h || p.name === h))?.identifier || null
        ))
        setView('edit')
      } catch {
        setFileRejectionError('Invalid file')
      }
    })()
  }, [ file, parameters ])

  const renderView = () => {
    switch (view) {
      case 'upload':
        return (
          <UploadView
            fileRejectionError={fileRejectionError}
            setFile={setFile}
            setFileRejectionError={setFileRejectionError}
          />
        )
      case 'edit':
      case 'preview':
        return (
          <Preview
            data={data}
            headers={headers}
            hotRef={hotRef}
            mappings={mappings}
            parameters={parameters}
          />
        )
      case 'mapping':
        return (
          <Mapping
            parameters={parameters}
            columns={headers}
            mappings={mappings}
            setMappings={setMappings}
          />
        )
      case 'submitting':
        return (
          <Flex justifyContent="center" alignItems="center" css={{ height: DIALOG_BODY_HEIGHT }}>
            <Flex direction="column" alignItems="center" gap={10}>
              <Loader loading />
              <Text color="dark700" fontSize={12}>Processing...</Text>
            </Flex>
          </Flex>
        )
      default:
        return null
    }
  }

  const subtitle = (() => {
    switch (view) {
      case 'edit':
        return 'Step 1: Edit your records'
      case 'mapping':
        return 'Step 2: Map your columns to the appropriate fields'
      case 'preview':
        return 'Step 3: Preview your records'
      default:
        return null
    }
  })()

  const goBack = () => {
    switch (view) {
      case 'edit':
        setView('upload')
        break
      case 'mapping':
        setView('edit')
        break
      case 'preview':
        setView('mapping')
        break
      default:
        break
    }
  }

  const moveToNextStep = () => {
    const newData = hotRef.current?.hotInstance?.getData() || data
    switch (view) {
      case 'upload':
        return setView('edit')

      case 'edit':
        setData(newData)
        setHeaders(hotRef.current?.hotInstance?.getColHeader() || headers)
        return setView('mapping')

      case 'mapping':
        return setView('preview')
      case 'preview':
        return new Promise((resolve, reject) => {
          try {
            hotRef.current?.hotInstance?.validateCells((isValid) => {
              if (isValid) {
                setData(newData)
                setView('submitting')
                onSubmit({
                  headers: mappings.filter((header) => header) as string[],
                  rows: newData.map((row: any[]) => row.filter((_, index) => mappings[index])),
                  mappings
                }).then(onClose)
              } else {
                openFailureAlert({
                  title: 'Invalid data',
                  message: 'Please check your data and try again'
                })
                resolve(undefined)
              }
            })
          // eslint-disable-next-line no-empty
          } catch (e) {
            openFailureAlert({
              title: 'Invalid data',
              message: 'Please check your data and try again'
            })

            reject(e)
          }
        })
      default:
        return undefined
    }
  }

  return (
    <>
      <DialogHeader onCloseClick={onClose} title={title} />
      {subtitle && (
        <SidePaneSubHeader>
          <Text fontSize="14">{subtitle}</Text>
        </SidePaneSubHeader>
      )}
      <DialogBody style={{
        height: subtitle ? DIALOG_BODY_HEIGHT - 50 : DIALOG_BODY_HEIGHT
      }}
      >
        {renderView()}
      </DialogBody>
      <Divider />
      <DialogFooter css={{ backgroundColor: 'light100', paddingY: 18 }}>
        <Flex grow={1} />
        <Flex gap={20} alignItems="center">
          <Button disabled={[ 'upload', 'submitting' ].includes(view)} icon="arrow-left" onClick={goBack} size="small" />
          <Button
            disabled={(view === 'upload' && data.length === 0) || view === 'submitting'}
            label="Next"
            onClick={moveToNextStep}
            size="small"
          />
        </Flex>
      </DialogFooter>
    </>
  )
}

const ImportRecordsDialog = ({ isOpen, onClose, title, ...props }: ImportRecordsDialogProps) => {
  const [ key, setKey ] = useState(0)
  return (
    <Dialog
      isOpen={isOpen}
      contentLabel={title}
      onRequestClose={onClose}
      onAfterClose={() => setKey(key + 1)}
      onAfterOpen={() => setKey(key + 1)}
      style={{ content: { width: DIALOG_WIDTH } }}
    >
      <ImportRecordsDialogWrapped
        key={key}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        {...props}
      />
    </Dialog>
  )
}

export { Preview }

export default ImportRecordsDialog
