import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import LoginForm from 'components/forms/LoginForm'
import useQueryParams from 'hooks/useQueryParams'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { addWorkspace, isElectron } from 'lib/electron'
import { SET_SESSION_MUTATION } from 'client/state/session'
import { useCreateSessionMutation, CreateSessionInput } from 'generated/schema'

type LoginPageLocationState = {
  email: string
}

export default function LoginPage() {
  const { push } = useHistory()
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const location = useLocation<LoginPageLocationState>()
  const queryParams = useQueryParams()

  const [ setSession ] = useMutation(SET_SESSION_MUTATION)

  const [ createSession ] = useCreateSessionMutation({
    onCompleted: ({ createSession: { id, token, isMfaRequired, mfaVerifiedAt } }) => {
      setSession({ variables: { session: { id, token, isMfaRequired, mfaVerifiedAt } } })
      isElectron && addWorkspace({ currentWorkspace })

      const isMfaRequested = isMfaRequired && !mfaVerifiedAt
      if (isMfaRequested) push('/verify-mfa')
    }
  })

  const handleCreateSession = useSubmitHandler(createSession)

  const handleSubmit = (values: CreateSessionInput & { id?: any }) => handleCreateSession(values)

  return (
    <Flex direction="column" gap={48}>
      <ExternalTitle>Sign in to {currentWorkspace?.name}</ExternalTitle>
      <LoginForm
        initialValues={{
          workspaceId: currentWorkspace?.id,
          email: location.state?.email || queryParams.get('email') || ''
        } as CreateSessionInput}
        onSubmit={handleSubmit}
      />
    </Flex>
  )
}
