import pick from 'lodash/pick'
import React from 'react'

import AccountAvatarForm from 'components/forms/AccountAvatarForm'
import AccountProfileForm from 'components/forms/AccountProfileForm'
import Block from 'components/blocks/Block'
import Flex from 'components/layout/Flex'
import TitleBlock from 'components/blocks/TitleBlock'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useUpdateAccountAvatarMutation, useUpdateAccountMutation } from 'generated/schema'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'

export default function AccountProfilePage() {
  const currentAccount = useCurrentAccountContext()!

  const [ updateAccount ] = useUpdateAccountMutation()
  const [ updateAccountAvatar ] = useUpdateAccountAvatarMutation()

  const onSubmit = useSubmitHandler(updateAccount, {
    successAlert: { message: 'Your changes were applied successfully.' }
  })

  const onAvatarUpdate = useSubmitHandler(updateAccountAvatar, {
    successAlert: { message: 'Your avatar was saved successfully.' }
  })

  const profileFormInitialValues = pick(
    currentAccount,
    'id', 'firstName', 'lastName', 'gender', 'dateOfBirth'
  )
  const avatarFormInitialValues = {
    id: currentAccount.id,
    avatar: currentAccount.avatar
  }

  return (
    <>
      <TitleBlock heading={`Hello, ${currentAccount.firstName}!`} />
      <Block gap={80} width={{ md: '100%' }}>
        <AccountAvatarForm initialValues={avatarFormInitialValues} onSubmit={onAvatarUpdate} />
        <Flex direction="column" grow={1}>
          <AccountProfileForm initialValues={profileFormInitialValues} onSubmit={onSubmit} />
        </Flex>
      </Block>
    </>
  )
}
