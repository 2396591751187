import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

const Extender = styled(Flex, {
  position: 'relative',
  width: `calc(100% + ${120}px)`,
  zIndex: 'above'
})

export default Extender
