import React from 'react'
import type { ApolloError } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import type { SeriesPieOptions } from 'highcharts'

import Block from 'components/blocks/Block'
import Card from 'components/card/Card'
import Loader from 'components/loaders/Loader'
import PieChart from 'components/charts/PieChart'
import type { BlockProps } from 'components/blocks/Block'

type PieChartBlockProps = {
  title: string,
  type: 'pie' | 'donut',
  series: SeriesPieOptions[],
  loading: boolean,
  error: ErrorResponse | ApolloError
}

function PieChartBlock({
  title, description, type, series, loading, error, ...others
}: BlockProps & PieChartBlockProps) {
  return (
    <Block
      name="Pie Chart"
      resizeDirection={{ right: true }}
      direction="column"
      {...others}
    >
      <Card css={{ padding: 35 }}>
        <Loader
          data={series}
          error={error}
          loading={loading}
        >
          <PieChart
            title={title}
            type={type}
            series={series}
          />
        </Loader>
      </Card>
    </Block>
  )
}

export default PieChartBlock
