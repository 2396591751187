import arrayMutators from 'final-form-arrays'
import React, { useMemo } from 'react'
import { Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import FilterField from 'components/form/FilterField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import SegmentModel from 'models/Segment'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { SegmentsListDocument, SegmentsListQueryVariables, CreateSegmentInput, UpdateSegmentInput, useCreateSegmentMutation, useUpdateSegmentMutation, useAttributesListQuery, Resource } from 'generated/schema'
import { constructFilters, constructInitialValues, FILTERABLE_FIELD_TYPES } from 'components/dataWidgets/CustomizeDisplay'
import type { ViewProps } from 'components/views'

type FormValues = CreateSegmentInput | UpdateSegmentInput

type Params = {
  initialValues: FormValues,
  resourceId: Resource['id'],
  queryVariables: SegmentsListQueryVariables
}

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

function AddSegmentView({
  closeView,
  onRequestClose,
  params: { initialValues, resourceId, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const title = `${isUpdating ? 'Update' : 'Add'} Segment`

  const {
    data: { attributesList = [] } = {}
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      }
    },
    skip: !resourceId
  })

  const filterOptions = useMemo(() => attributesList
    .filter((attribute) => (
      attribute.fieldType && FILTERABLE_FIELD_TYPES.includes(attribute.fieldType)
    ))
    .map((attribute) => ({
      label: attribute.name,
      value: attribute.identifier
    })), [ attributesList ])

  const [ createSegment ] = useCreateSegmentMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: SegmentsListDocument, variables: queryVariables } ]
  })

  const [ updateSegment ] = useUpdateSegmentMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: SegmentsListDocument, variables: queryVariables } ]
  })

  const handleCreateSegment = useSubmitHandler(createSegment, {
    successAlert: { message: 'Segment Created.' }
  })

  const handleUpdateSegment = useSubmitHandler(updateSegment, {
    successAlert: { message: 'Segment Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    const { filter, ...otherValues } = values
    const valuesWithConstructedFilters = {
      filter: { and: (filter as any).map(constructFilters) },
      ...otherValues
    }
    if (isUpdating) {
      return handleUpdateSegment(
        valuesWithConstructedFilters as UpdateSegmentInput,
        form as FormApi<UpdateSegmentInput>
      )
    }
    return handleCreateSegment(valuesWithConstructedFilters as CreateSegmentInput)
  }

  const formInitialValues = {
    ...initialValues,
    filter: initialValues?.filter
      ? constructInitialValues(initialValues.filter as any).filters
      : undefined
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Form
              decorators={[
                setIdentifier
              ]}
              mutators={{
                ...arrayMutators
              }}
              initialValues={formInitialValues as FormValues}
              keepDirtyOnReinitialize
              subscription={{
                submitting: true,
                pristine: true
              }}
              onSubmit={handleSubmit}
              validate={(values) => SegmentModel.validate(values, [ 'identifier', 'name' ])}
              render={({ handleSubmit, submitting, pristine }) => (
                <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
                  <Grid columns={2} columnGap={8} rowGap={8}>
                    <FormField
                      component={TextInput}
                      name="name"
                      label="Name"
                      size="small"
                      type="text"
                    />
                    <FormField
                      component={TextInput}
                      name="identifier"
                      label="Identifier"
                      size="small"
                      type="text"
                    />
                  </Grid>
                  <FilterField
                    name="filter"
                    filterOptions={filterOptions}
                    columns={attributesList.map(
                      (attr) => ({ ...attr, dataKey: attr.identifier })
                    ) as any[]}
                    noFilterMessage="No Filters"
                  />
                  <Flex gap={24} direction="row-reverse">
                    <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                  </Flex>
                </Flex>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default AddSegmentView
