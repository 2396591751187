import React, { useCallback, useContext } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import type { DragStart, DropResult } from 'react-beautiful-dnd'
import type { PropsWithChildren } from 'react'

import InternalContext from 'components/contexts/InternalContext'
import updateMenuElements from 'lib/updateMenuElements'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { parseDroppableId } from 'lib/dragDropHelpers'
import { UpdateMenuElementInput, useUpdateMenuElementMutation } from 'generated/schema'

const onDragStart = ({ draggableId }: DragStart) => {
  const element = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement

  element.style.width = ''
  element.style.height = ''
}

function DragDropContextProvider({ children }: PropsWithChildren<{}>) {
  const {
    currentDashboard,
    idToMenuElementMap,
    parentIdToMenuElementsMap
  } = useContext(InternalContext)!
  const [ updateMenuElement ] = useUpdateMenuElementMutation()

  const getUpdatedValues = (id: string) => {
    const {
      fullPath,
      concatenatedNames,
      parentIds,
      renderedName,
      renderedIcon,
      ...updateValues
    } = idToMenuElementMap[id]

    return updateValues
  }

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement',
      override: (values: UpdateMenuElementInput) => ({
        ...getUpdatedValues(values.id),
        ...values
      })
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, currentDashboard?.id)
  })

  const onDragEnd = useCallback(({ destination, draggableId, source }: DropResult) => {
    if (!destination) {
      return undefined
    }

    const targetMenuElements = parseDroppableId(destination.droppableId)

    if (!targetMenuElements) {
      return undefined
    }

    if (!targetMenuElements.validate(draggableId, idToMenuElementMap)) return undefined

    const prevElement = targetMenuElements[destination.index - 1]
    const destinationElement = targetMenuElements[destination.index]
    const nextElement = targetMenuElements[destination.index + 1]

    // within same submenu
    if (source.droppableId === destination.droppableId) {
      // dropped at same position
      if (source.index === destination.index) {
        return undefined
      }

      if (source.index < destination.index) {
        return targetMenuElements.reposition(
          draggableId,
          destination.index,
          destinationElement,
          nextElement,
          handleUpdateMenuElementSubmit
        )
      }
    }

    return targetMenuElements.reposition(
      draggableId,
      destination.index,
      prevElement,
      destinationElement,
      handleUpdateMenuElementSubmit
    )
  }, [ handleUpdateMenuElementSubmit, idToMenuElementMap ])

  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      {children}
    </DragDropContext>

  )
}

export default DragDropContextProvider
