import React, { Suspense, useMemo, useState } from 'react'

import componentLoader from 'lib/componentLoader'
import TextInput from 'components/inputs/TextInput'
import zones from 'components/inputs/PhoneInput/zones'
import { DateInputProps, DEFAULT_DATE_DISPLAY_FORMAT, DEFAULT_TIME_FORMAT } from 'components/inputs/DateInput/DateInput'
import { DATE_FORMATS, SAVE_FORMATS } from 'components/contentEditors/generic/fields/fieldProps'

const DEFAULT_LOCALE = 'en-IN'
let language = navigator?.language?.replace(/-\w+$/, (match) => match.toUpperCase()) || DEFAULT_LOCALE // transform en-us -> en-US (Safari)
const timeZone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone as keyof typeof zones
const countryCode = zones[timeZone]

if (language.length === 2 && countryCode) language = `${language}-${countryCode}`

const importLocale = () => import(`date-fns/locale/${language}`)
const importDefaultLocale = () => import(`date-fns/locale/${DEFAULT_LOCALE}`)

const importDateInput = () => componentLoader('inputs/DateInput/DateInput')

/**
 * All locale formats & their count
 * yyyy/MM/dd: 2
 * MM/dd/yyyy: 8
 * dd.MM.yyyy: 5
 * dd.MM.y: 10
 * dd/MM/yyyy: 15
 * dd/MM/y: 9
 * d/M/yy: 4
 * yyyy-MM-dd: 2
 * yy/MM/dd: 1
 * d.M.y: 2
 * yy-MM-dd: 3
 * dd. MM. y.: 1
 * y. MM. dd.: 1
 * d/M/yyyy: 2
 * d.MM.y: 1
 * y/MM/dd: 1
 * y.MM.dd: 2
 * dd.MM.yy: 1
 * y-MM-dd: 2
 * dd.MM.y.: 1
 * dd-MM-y: 2
 * d. M. y: 1
 * d. MM. yy: 1
 * dd. MM. yy.: 2
 * dd-MM-yy: 1
 */

function DateInputHoC({ saveFormat, withTime, ...props }: DateInputProps) {
  const [ localizedFormat, setLocalizedFormat ] = useState(DEFAULT_DATE_DISPLAY_FORMAT)

  const mapLocale = (localeModule: any) => {
    const localeFormat: string | undefined = localeModule.default.formatLong?.date({ width: 'short' })?.toUpperCase()

    setLocalizedFormat(() => {
      if (localeFormat?.startsWith('Y')) {
        return DATE_FORMATS.YYYY_MM_DD
      }

      if (localeFormat?.startsWith('D')) {
        return DATE_FORMATS.DD_MM_YYYY
      }

      if (localeFormat?.startsWith('M')) {
        return DATE_FORMATS.MM_DD_YYYY
      }

      return DEFAULT_DATE_DISPLAY_FORMAT
    })

    return importDateInput()
  }

  const DateInput = useMemo(() => React.lazy(
    () => importLocale()
      .then(mapLocale)
      .catch(() => importDefaultLocale().then(mapLocale))
  ), [])

  const shouldShowTime = withTime || saveFormat === SAVE_FORMATS.TIMESTAMP

  return (
    <Suspense fallback={<TextInput disabled placeholder="Loading..." {...props} />}>
      <DateInput
        localizedFormat={shouldShowTime ? `${localizedFormat} ${DEFAULT_TIME_FORMAT}` as DATE_FORMATS : localizedFormat}
        withTime={withTime}
        {...props}
      />
    </Suspense>
  )
}

export default DateInputHoC

export type { DateInputProps }
