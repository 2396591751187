import get from 'lodash/get'
import React, { useContext, useMemo } from 'react'
import { ApolloError, defaultDataIdFromObject, MutationHookOptions, QueryResult } from '@apollo/client'

import * as mixins from 'styles/mixins'
import AccountAvatar from 'components/avatars/AccountAvatar'
import AccountModel from 'models/Account'
import AccountNameRenderer from 'components/renderers/AccountNameRenderer'
import AccountView from 'components/views/AccountView'
import AddIntegrationView from './AddIntegrationView'
import AddRoleView from 'components/views/AddRoleView'
import AssignRoleView from 'components/views/AssignRoleView'
import Button from 'components/buttons/Button'
import Card from 'components/card/Card'
import Chip from 'components/chip/Chip'
import ChipRenderer from 'components/renderers/ChipRenderer'
import client from 'client'
import DataList from 'components/dataList/DataList'
import DataTable from 'components/dataTable/DataTable'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IntegrationNameRenderer from 'components/renderers/IntegrationNameRenderer'
import IntegrationView from './IntegrationView'
import InternalContext from 'components/contexts/InternalContext'
import List from 'components/list/List'
import PageLoader from 'components/loaders/PageLoader'
import ResourcesList from './graph/ResourcesList'
import RoleView from 'components/views/RoleView'
import Tab from 'components/tabs/Tab'
import Tabs, { useTabs } from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import Toolbar from 'components/toolbar/Toolbar'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { APP_CATEGORIES_ID, getAppIcon } from 'models/App'
import { App, CreateInstallationInput, CustomRole, DashboardsListDocument, Installation, InstallationDocument, InstallationFragmentFragmentDoc, InstallationsAggregateDocument, InstallationsListDocument, InstallationsListQueryVariables, RoleMembership, RoleMembershipsListDocument, RolesListDocument, RolesListQuery, RolesListQueryVariables, useAppListQuery, useArchiveInstallationMutation, useCreateInstallationMutation, useDestroyRoleMembershipMutation, useDestroyRoleMutation, useInstallationQuery, useInstallationsAggregateQuery, useInstallationsListQuery, useResourcesAggregateQuery, useRoleMembershipsListQuery, useRolesListQuery, useUnarchiveInstallationMutation } from 'generated/schema'
import { css, styled } from 'styles/stitches'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { InstallAppView, ViewProps } from 'components/views'
import { Kind } from 'models/Role'
import { MarkdownRenderer } from 'components/markdown'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { Content } from 'components/dataList/DataList'
import type { InstallationQuery } from 'generated/schema'

const PAGE_SIZE_OPTIONS = [ 5, 10, 15 ]

type ViewParamsType = {
  installationId: string
}

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

const Graph = ResourcesList

const Integrations = ({ app }: { app: App }) => {
  const { currentDashboard } = useContext(InternalContext) || {}
  const { openView } = useViewDispatch()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const isCustomApp = !!app.workspaceId

  const {
    data: { appsList = [] } = {},
    loading: appsListLoading,
    error: appsListError
  } = useAppListQuery({
    variables: {
      filter: {
        appCategoryId: { in: isCustomApp
          ? [ APP_CATEGORIES_ID.Custom ]
          : app.meta.integrationCategoryIds || [] }
      }
    }
  })

  const queryVariables = {
    filter: {
      archivedAt: 'null',
      appKind: { eq: 'INTEGRATION' },
      appId: { in: appsList.map((app) => app.id) }
    },
    limit: pageSize,
    page
  }

  const {
    data: { installationsList = [] } = {},
    loading,
    error
  } = useInstallationsListQuery({
    variables: queryVariables,
    skip: !appsList
  })

  const {
    data: { installationsAggregate } = {}
  } = useInstallationsAggregateQuery({
    variables: { filter: queryVariables?.filter }
  })

  const [ archiveInstallation ] = useArchiveInstallationMutation({
    refetchQueries: [
      { query: InstallationsListDocument, variables: queryVariables },
      {
        query: InstallationsAggregateDocument,
        variables: { filter: queryVariables?.filter }
      }
    ]
  })

  const [ unarchiveInstallation ] = useUnarchiveInstallationMutation({
    refetchQueries: [
      { query: InstallationsListDocument, variables: queryVariables },
      {
        query: InstallationsAggregateDocument,
        variables: { filter: queryVariables?.filter }
      }
    ]
  })

  const handleArchive = useSubmitHandler(archiveInstallation)
  const handleUnarchive = useSubmitHandler(unarchiveInstallation)

  const openInstallationForm = (values: Installation) => {
    openView({
      title: `${values.app.name}`,
      component: InstallAppView,
      params: {
        selectedApp: values.app,
        initialValues: values,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const openAddIntegrationView = () => openView({
    title: 'Add Integration',
    component: AddIntegrationView,
    style: 'PANEL',
    params: {
      dashboardId: currentDashboard?.id,
      appCategoryIds: app.meta.integrationCategoryIds,
      queryVariables
    }
  })

  const openConfirmationDialog = (installation: Installation) => {
    confirm({
      action: installation.archivedAt !== null ? 'archive' : 'unarchive',
      onConfirmClick: async () => {
        if (installation.archivedAt !== null) {
          handleArchive({ id: installation.id })
        } else {
          handleUnarchive({ id: installation.id })
        }
      },
      recordType: 'Installation',
      recordDescription: installation.name
    })
  }

  const columns: Column[] = [
    {
      dataKey: 'name',
      fieldType: 'text-field',
      sortable: true,
      title: 'Name',
      style: { width: 200 },
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          as: IntegrationNameRenderer,
          onClick: () => {
            openView({
              title: props.rowData.name || 'App',
              component: IntegrationView,
              style: IntegrationView.defaultStyle,
              params: {
                installationId: props.rowData.id
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      }
    },
    { dataKey: 'identifier', fieldType: 'text-field', sortable: true, title: 'Identifier', style: { width: 200 } },
    { dataKey: 'app.appCategory.name', fieldType: 'text-field', sortable: true, title: 'Category', style: { width: 200 } }
  ]

  const actions = [
    { icon: 'edit', title: 'Edit', onClick: openInstallationForm },
    {
      icon: 'archive',
      title: (installation: Installation) => (installation.archivedAt !== null ? 'Unarchive' : 'Archive'),
      onClick: openConfirmationDialog
    }
  ]

  const batchActions = [
    { icon: 'archive', title: 'Archive', onClick: () => {} }
  ]

  const dataTableSecondaryElement = (
    <Button icon="add-thin" onClick={openAddIntegrationView} size="small" />
  )

  return (
    <Flex direction="column" gap={36}>
      <Toolbar title="Integrations" secondaryElements={dataTableSecondaryElement} />
      <DataTable
        actions={actions}
        batchActions={batchActions}
        columns={columns}
        data={installationsList as Installation[]}
        error={error || appsListError}
        loading={loading || appsListLoading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        totalRows={installationsAggregate?.count || 0}
      />
    </Flex>
  )
}

const Members = ({ rolesList = [], installation }: {installation: Installation, rolesList: RolesListQuery['rolesList']}) => {
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: 5
  })

  const queryVariables = {
    filter: {
      roleId: { in: rolesList.map((role) => role.id) }
    },
    limit: pageSize,
    page
  }

  const {
    data: { roleMembershipsList = [], roleMembershipsAggregate = {} } = {},
    loading,
    error
  } = useRoleMembershipsListQuery({
    variables: queryVariables,
    skip: rolesList.length === 0
  })

  const [ deleteRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      { query: RoleMembershipsListDocument }
    ]
  })

  const handleDeleteRoleMembership = useSubmitHandler(deleteRoleMembership, {
    update: {
      strategy: 'REMOVE',
      query: RoleMembershipsListDocument,
      queryVariables,
      dataKey: 'roleMembershipsList',
      mutation: 'destroyRoleMembership'
    }
  })

  const onAdd = () => openView({
    title: 'Assign Role',
    component: AssignRoleView,
    params: {
      appId: installation.appId,
      mode: 'account',
      kind: Kind.APP,
      queryVariables
    },
    style: 'PANEL'
  })

  const onDelete = (roleMembership: RoleMembership) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRoleMembership({ id: roleMembership.id }),
      recordType: 'Role Membership',
      recordDescription: roleMembership.account?.name
    })
  }

  const contents: Content[] = [
    {
      dataKey: 'account',
      slot: 'primary',
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          as: AccountNameRenderer,
          onClick: () => {
            openView({
              title: AccountModel.getFullName(props.rowData.account) || props.rowData.account.email || '',
              component: AccountView,
              style: AccountView.defaultStyle,
              params: {
                account: props.rowData.account
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      }
    },
    { dataKey: 'role.name', slot: 'meta', renderer: ChipRenderer },
    {
      dataKey: 'environment',
      slot: 'toggle',
      renderer: ({ rowData, dataKey }) => {
        const environment = get(rowData, dataKey)
        const hasEnvironment = Boolean(environment)
        return <Chip label={hasEnvironment ? environment.name : 'All Environments'} variant="accent_inverse" />
      }
    }
  ]

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (roleMembership: RoleMembership) => onDelete(roleMembership)
    }
  ]

  return (
    <>
      <Toolbar
        title="Members"
        secondaryElements={[
          <Flex gap={16} alignItems="center">
            <Button
              icon="add-thin"
              size="small"
              onClick={onAdd}
            />
          </Flex>
        ]}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={roleMembershipsList as RoleMembership[]}
        loading={loading}
        error={error}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        summaryBarVariant="normal"
        selectionMode="none"
        totalRows={roleMembershipsAggregate?.count || 0}
      />
    </>
  )
}

const Roles = ({
  installation,
  data,
  loading,
  error,
  queryVariables,
  page,
  pageSize,
  handlePageChange,
  handlePageSizeChange
}: {
  installation: Installation,
  data: QueryResult<RolesListQuery, RolesListQueryVariables>['data'],
  loading: boolean,
  error?: ApolloError,
  queryVariables: RolesListQueryVariables,
  page: number,
  pageSize: number,
  handlePageChange: (nextPage: number) => void,
  handlePageSizeChange: (nextPage: number) => void
}) => {
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const onEdit = (role: CustomRole) => {
    openView({
      title: 'Edit Role',
      component: AddRoleView,
      params: {
        initialValues: role,
        kind: Kind.APP,
        appId: installation.appId,
        app: installation.app,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const onAdd = () => {
    openView({
      title: 'Add Role',
      component: AddRoleView,
      params: {
        kind: Kind.APP,
        appId: installation.appId,
        app: installation.app,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const [ deleteRole ] = useDestroyRoleMutation({
    refetchQueries: [
      { query: RolesListDocument }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRole, {
    update: {
      strategy: 'REMOVE',
      query: RolesListDocument,
      queryVariables,
      dataKey: 'rolesList',
      mutation: 'destroyRole'
    }
  })

  const onDelete = (role: CustomRole) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: role.id }),
      recordType: 'Role',
      recordDescription: role.name
    })
  }

  const contents: Content[] = [
    {
      dataKey: 'name',
      slot: 'primary',
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          onClick: () => {
            openView({
              title: `Role: ${props.rowData.name}`,
              component: RoleView,
              style: RoleView.defaultStyle,
              params: {
                role: props.rowData
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      }
    },
    {
      dataKey: 'kind',
      slot: 'meta',
      renderer: ChipRenderer
    }
  ]

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: (role: CustomRole) => onEdit(role),
      visibilityFilter: (role: CustomRole) => role.workspaceId !== null
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (role: CustomRole) => onDelete(role),
      visibilityFilter: (role: CustomRole) => role.workspaceId !== null
    }
  ]

  return (
    <>
      <Toolbar
        title="Roles"
        secondaryElements={[
          <Flex gap={16} alignItems="center">
            <Button
              icon="add-thin"
              size="small"
              onClick={onAdd}
            />
          </Flex>
        ]}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={(data?.rolesList || []) as CustomRole[]}
        loading={loading}
        error={error}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        summaryBarVariant="normal"
        selectionMode="none"
        totalRows={data?.rolesAggregate?.count || 0}
      />
    </>
  )
}

const Access = ({ installation }: { installation: Installation }) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: 5
  })

  const queryVariables = {
    filter: {
      appId: { eq: installation.appId }
    },
    limit: pageSize,
    page
  }

  const {
    data,
    loading,
    error
  } = useRolesListQuery({
    variables: {
      filter: {
        appId: { eq: installation.appId }
      }
    },
    skip: !installation
  })

  return (
    <Flex direction="column" gap={36}>
      <Members rolesList={data?.rolesList || []} installation={installation} />
      <Roles
        queryVariables={queryVariables}
        data={data}
        loading={loading}
        error={error}
        installation={installation}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Flex>
  )
}

const Step = styled(Flex, {
  size: [ 24 ],
  backgroundColor: 'light500',
  color: 'dark700',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'normal',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 'normal',
  lineHeight: 'normal'
})

const OverviewListItem = styled(Card, {
  ...mixins.transition('simple'),
  '&&': {
    paddingY: 32
  },

  '& [data-icon]': {
    alignSelf: 'center',
    color: 'dark100'
  },

  '&:hover': {
    '& [data-icon]': {
      color: 'dark300'
    }
  },

  variants: {
    completed: {
      true: {
        '& [data-status-icon]': {
          color: 'positive500'
        },

        '&:hover': {
          '& [data-status-icon]': {
            color: 'positive500'
          }
        }
      },
      false: {
        '& [data-status-icon]': {
          color: 'dark100'
        },

        '&:hover': {
          '& [data-status-icon]': {
            color: 'dark300'
          }
        }
      }
    }
  }

})

const Overview = ({ installation }: { installation: Installation }) => {
  const { setActiveIndex } = useTabs()
  const { openView } = useViewDispatch()
  const { currentDashboard } = useContext(InternalContext)!

  const isCustomApp = !!installation.app.workspaceId

  const {
    data: { rolesList = [] } = {}
  } = useRolesListQuery({
    variables: {
      filter: {
        appId: { eq: installation.appId }
      }
    }
  })

  const {
    data: { roleMembershipsList = [] } = {}
  } = useRoleMembershipsListQuery({
    variables: {
      filter: {
        roleId: { in: rolesList.map((role) => role.id) }
      },
      // this is to ensure this query refetches when the user is added to a role
      limit: 5,
      page: 1
    },
    skip: rolesList.length === 0
  })

  const {
    data: { appsList = [] } = {}
  } = useAppListQuery({
    variables: {
      filter: {
        appCategoryId: { in: !isCustomApp && (
          installation.app.meta.integrationCategoryIds || []
        ) }
      }
    },
    skip: isCustomApp
  })

  const {
    data: { installationsList = [] } = {}
  } = useInstallationsListQuery({
    variables: {
      filter: {
        appId: { in: appsList.map((app) => app.id) }
      }
    },
    skip: appsList.length === 0
  })

  const integrationQueryVariables = {
    filter: {
      appCategoryId: { in: !isCustomApp && (
        installation.app.meta.integrationCategoryIds || []
      ) }
    }
  }

  const {
    data: { appsList: integrationsList = [] } = {}
  } = useAppListQuery({
    variables: integrationQueryVariables,
    skip: isCustomApp
  })

  const {
    data: { resourcesAggregate } = {}
  } = useResourcesAggregateQuery({
    variables: {
      filter: {
        appId: { eq: installation.appId }
      }
    },
    skip: !installation.appId
  })

  const hasAccess = roleMembershipsList.length > 0
  const hasRoles = rolesList.length > 0
  const isIntegrated = installationsList.length > 0
  const hasResources = !!resourcesAggregate?.count
  const hasIntegrations = !isCustomApp
    && installation.app.meta.integrationCategoryIds.length > 0

  const openAddIntegrationView = () => openView({
    title: 'Add Integration',
    component: AddIntegrationView,
    style: 'PANEL',
    params: {
      dashboardId: currentDashboard?.id,
      appCategoryIds: !isCustomApp ? installation.app.meta.integrationCategoryIds : [],
      queryVariables: integrationQueryVariables
    }
  })

  const OVERVIEW_STEPS = {
    INTEGRATION: {
      title: 'Connect one or more integrations',
      description: 'Place menus & views that allow using this app from a dashboard',
      label: 'Add Integrations',
      onClick: () => (isIntegrated ? setActiveIndex(1) : openAddIntegrationView()),
      completed: isIntegrated,
      links: (
        <>
          {integrationsList.length > 0 && (
          <Flex gap={16}>
            {integrationsList.map(({ identifier }) => (
              <img
                style={{ width: 40, objectFit: 'contain' }}
                src={getAppIcon(identifier)}
                alt={identifier || ''}
              />
            ))}
          </Flex>
          )}
        </>
      )
    },
    RESOURCES: {
      title: 'Create or generate resources',
      description: 'Create resources & operations to manage this app’s data',
      label: 'Manage Graph',
      onClick: () => setActiveIndex(hasIntegrations ? 2 : 1),
      completed: hasResources,
      links: <></>
    },
    ROLES: {
      title: 'Create one or more roles',
      description: 'Define the roles & permissions to access this app’s data',
      label: 'Manage Roles',
      onClick: () => setActiveIndex(hasIntegrations ? 3 : 2),
      completed: hasRoles,
      links: <></>
    },
    ACCESS: {
      title: 'Invite people to access this app',
      description: 'Assign roles to accounts & groups that can use this app',
      label: 'Manage Access',
      onClick: () => setActiveIndex(hasIntegrations ? 3 : 2),
      completed: hasAccess,
      links: (
        <>
          {roleMembershipsList.length > 0 && (
          <Flex gap={8}>
            {roleMembershipsList.map((roleMembership) => (
              <AccountAvatar account={roleMembership.account || {}} />
            ))}
          </Flex>
          )}
        </>
      )
    }
  }

  const customAppOverviewItems = [
    OVERVIEW_STEPS.RESOURCES, OVERVIEW_STEPS.ROLES, OVERVIEW_STEPS.ACCESS
  ]

  const systemAppsOverviewItems = [
    OVERVIEW_STEPS.ACCESS
  ]

  if (hasIntegrations) {
    systemAppsOverviewItems.splice(0, 0, OVERVIEW_STEPS.INTEGRATION)
  }

  const overviewItems = [
    ...(isCustomApp ? customAppOverviewItems : systemAppsOverviewItems)
  ]

  return (
    <List gap={8}>
      {overviewItems.map(({ title, description, completed, links, ...buttonProps }, index) => (
        <OverviewListItem
          key={title}
          direction="row"
          padding="wide"
          completed={completed}
          alignItems="flex-start"
          justifyContent="space-between"
          mode="actionable"
          {...buttonProps}
        >
          <Flex grow={1} gap={16} alignItems="flex-start">
            <Step>{index + 1}</Step>
            <Flex grow={1} direction="column" gap={16}>
              <Flex direction="column" gap={8}>
                <Flex gap={8} alignItems="center">
                  <Text fontSize={16} fontWeight="semibold">{title}</Text>
                  {completed && <Icon data-status-icon name="accept" size={16} />}
                </Flex>
                <Text fontSize={14} color="dark600">{description}</Text>
              </Flex>
              {links}
            </Flex>
            <Icon data-icon name="arrow-right" size={16} />
          </Flex>
        </OverviewListItem>
      ))}
    </List>
  )
}

const About = ({
  app,
  installation,
  refetchQueries = [
    { query: DashboardsListDocument }
  ],
  queryVariables = {
    filter: {
      archivedAt: 'null',
      appKind: { eq: 'EXTENSION' }
    }
  }
}: {
  app: App,
  installation?: Installation,
  refetchQueries?: MutationHookOptions['refetchQueries'],
  queryVariables?: InstallationsListQueryVariables
}) => {
  const isInstalled = Boolean(installation)
  const isProject = app.kind === 'PROJECT'

  const documentationLink = isProject ? 'https://docs.dashx.com/platform/projects' : 'https://docs.dashx.com/platform/apps/custom-apps'

  const [ installApp, { loading: submitting } ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    refetchQueries
  })

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'App was installed successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput
  ) => handleInstallApp(values as CreateInstallationInput)

  const onInstallApp = () => {
    handleSubmit({ appId: app.id, name: app.name, identifier: app.identifier })
  }

  return (
    <>
      {app.summary && (
      <Card padding="cozy">
        <MarkdownRenderer source={app.summary!} />
        <Flex gap={8}>
          <Button label="View Documentation" variant="outline" mode="distinct" size="small" rel="noopener noreferrer" target="_blank" href={documentationLink} />
          {!isInstalled && <Button disabled={submitting} label="Install App" size="small" onClick={onInstallApp} />}
        </Flex>
      </Card>
      )}
    </>
  )
}

function InstallationView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { installationId } = params
  const cachedInstallationData = useMemo(() => {
    const installation = client.readFragment<InstallationQuery['installation'], {}>({
      id: defaultDataIdFromObject({ __typename: 'Installation', _id: installationId }),
      fragment: InstallationFragmentFragmentDoc,
      fragmentName: 'InstallationFragment'
    })
    if (!installation) return null

    return { installation }
  }, [ installationId ])

  const queryVariables = {
    id: installationId
  }

  const refetchQueries = [
    { query: DashboardsListDocument },
    { query: InstallationDocument, variables: queryVariables }
  ]

  const {
    data = cachedInstallationData,
    loading,
    error
  } = useInstallationQuery({
    variables: queryVariables, skip: !installationId
  })

  const title = data?.installation.name || 'App'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          {!data ? (
            <Body>
              <PageLoader data={data} loading={loading} error={error} />
            </Body>
          ) : (
            <Tabs
              tabListBackground="light"
              panelWrapper={Body}
              tabListClassName={classes.tabsList}
            >
              <Tab keepMounted label="Overview" index={0}>
                <Overview
                  installation={data.installation as Installation}
                />
              </Tab>
              <Tab keepMounted label="Integrations" index={1}>
                <Integrations app={data.installation.app} />
              </Tab>
              <Tab keepMounted label="Graph" index={2}>
                <Graph app={data.installation.app} />
              </Tab>
              <Tab keepMounted label="Access" index={3} />
              <Tab keepMounted label="About" index={4}>
                <About
                  app={data.installation.app}
                  installation={data.installation as Installation}
                  refetchQueries={refetchQueries}
                />
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </View>
  )
}

InstallationView.defaultStyle = 'PANEL' as const

export {
  About,
  Overview,
  Graph,
  Integrations,
  Access
}

export default InstallationView
