import React, { useContext } from 'react'

import { useHistory } from 'react-router-dom'

import Account from 'models/Account'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import { switchWorkspace } from 'lib/electron'
import type { Workspace as WorkspaceType } from 'generated/schema'

const AVATAR_SIZES = {
  small: 40,
  full: '100%'
}

type WorkspaceAvatarProps = {
  workspace: Pick<WorkspaceType, 'name' | 'hostname' | 'identifier'>,
  className?: string,
  variant?: 'small' | 'full'
}

const Avatar = styled(Flex, {
  cursor: 'pointer',

  variants: {
    variant: {
      small: {
        size: [ AVATAR_SIZES.small ],
        backgroundColor: (colorVars.light100),
        borderRadius: '$8',
        userSelect: 'none'
      }
    },
    isActive: {
      true: {
        border: '2px solid accent300'
      }
    }
  }
})

function WorkspaceAvatar({ workspace, className }: WorkspaceAvatarProps) {
  const { identifier } = workspace || {}
  const { currentWorkspace } = useContext(WorkspaceContext) || {}
  const { push } = useHistory()

  const handleSwitchWorkspace = () => {
    if (currentWorkspace?.identifier === identifier) {
      return push('/')
    }

    return switchWorkspace({ workspaceIdentifier: identifier, currentWorkspace })
  }

  return (
    <Avatar
      alignItems="center"
      className={className}
      isActive={identifier === currentWorkspace?.identifier}
      justifyContent="center"
      onClick={handleSwitchWorkspace}
      variant="small"
    >
      <Text color="primary300" fontSize={13} fontWeight="bold">
        {Account.getInitials({ firstName: identifier })}
      </Text>
    </Avatar>
  )
}

export default WorkspaceAvatar
