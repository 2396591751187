import React, { useState } from 'react'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import { Popover, PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'
import ColorInput from '../ColorInput'

interface Props {
  onClick: (payload: any) => void,
  selectedText?: string
}

const StyledContainer = styled(Popover, {
  padding: 16
})

const ColorCommand: React.FC<Props> = ({ children, onClick }) => {
  const [ value, onChange ] = useState('')
  return (
    <PopoverContainer
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ -38, 8 ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <div {...otherToggleProps}>
          {children}
        </div>
      )}
      {(popoverProps) => (
        <StyledContainer withArrow {...popoverProps}>
          <Flex
            as="form"
            direction="column"
            gap={8}
            onSubmit={(e: any) => {
              e.preventDefault()
              e.stopPropagation()

              onClick({
                selectedColor: value
              })
              popoverProps.closePopover()
            }}
          >
            <ColorInput
              autoComplete="off"
              size="small"
              meta={{}}
              input={{
                name: 'color',
                value,
                onChange,
                onBlur: () => {},
                onFocus: () => {}
              }}
              name="color"
            />
            <Button label="Submit" mode="subtle" variant="simple" size="small" type="submit" />
          </Flex>
        </StyledContainer>
      )}
    </PopoverContainer>
  )
}

export default ColorCommand
