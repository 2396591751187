import { gql, makeVar } from '@apollo/client'

export const DEFAULT_INSTALLATION_ID_DATA = null

/* Reactive Vars */

export const installationIdVar = makeVar(DEFAULT_INSTALLATION_ID_DATA)

/* Queries */

export const INSTALLATION_CLIENT_QUERY = gql`
  query InstallationClientQuery {
    installationId @client
  }
`

/* ClientState */

export default {
  defaults: {
    data: { installationId: DEFAULT_INSTALLATION_ID_DATA }
  },

  typePolicies: {
    Query: {
      fields: {
        installationId: {
          read() {
            return installationIdVar()
          }
        }
      }
    }
  }
}
