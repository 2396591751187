import React, { ReactNode } from 'react'

import Text from 'components/typography/Text'

type ExternalHintProps = {
  children: ReactNode
}

function ExternalHint({ children }: ExternalHintProps) {
  return <Text color="dark800" fontSize={12} fontWeight="light" letterSpacing="condensed">{children}</Text>
}

export default ExternalHint
