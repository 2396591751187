import get from 'lodash/get'
import React from 'react'

import AccountModel from 'models/Account'
import AccountAvatar from 'components/avatars/AccountAvatar'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Account } from 'generated/schema'
import type { RendererOptions } from 'components/dataTable/types'

type AccountNameRendererProps<T> = (RendererOptions<Account> | RendererOptions<T>) & {
  full?: boolean
}

const StyledText = styled(Text, {
  color: 'currentColor',
  fontWeight: 600,
  flexGrow: 1
})

const SecondaryText = styled(Text, {
  color: 'dark500',
  fontSize: 12,
  fontWeight: 400,
  flexGrow: 1
})

function AccountNameRenderer<T>({
  rowData,
  dataKey,
  full = false
}: AccountNameRendererProps<T>) {
  const data = get(rowData, dataKey)
  const account = data?.__typename === 'Account' ? data : rowData

  const name = AccountModel.getFullName(account)
  const secondary = AccountModel.getSecondaryDetails(account)

  if (full) {
    return (
      <Flex gap={16} alignItems="center" style={{ width: '100%' }}>
        <AccountAvatar account={account} />
        <Flex direction="column" gap={4} grow={1} style={{ width: 0 }}>
          <StyledText truncate>
            {name || '--'}
          </StyledText>
          <SecondaryText truncate>
            {secondary || '--'}
          </SecondaryText>
        </Flex>
      </Flex>
    )
  }

  return (
    <StyledText
      as="span"
      title={name}
      truncate
    >
      {name || '--'}
    </StyledText>
  )
}

export default AccountNameRenderer
