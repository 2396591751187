import React from 'react'
import Highcharts, { Series, SeriesAreaOptions, TooltipFormatterContextObject, YAxisOptions, color } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Card from 'components/card/Card'
import { colors } from 'lib/chartColors'

function epochToDate(epoch: number) {
  let epochToMs = epoch
  if (epoch < 10000000000) {
    epochToMs = epoch * 1000
  }
  const epochOffset = epochToMs + (new Date().getTimezoneOffset() * -1)
  return `${new Date(epochOffset).toLocaleDateString()} ${new Date(epochOffset).toLocaleTimeString()}`
}

function tooltipFormatter(
  this: TooltipFormatterContextObject
): string {
  const { series, point }: {series: Series, point: any} = this
  return `<div style="min-width: 250px; border-radius: 4px; box-shadow: 0px 13px 20px #aaadbc1a">
            <div style="display: flex; align-items: center; padding: 14px 25px;">
              <div style="width: 12px; height: 12px; margin-right: 10px; background-color: ${point.color}; border-radius: 2px;"></div>
              <p style="font-size: 14px; color: var(--colors-dark900); font-weight: bold">${series.name}</p>
            </div>
            <div style="width: 100%; height: 0px; border-top: 1px solid var(--colors-light700)"></div>
            <div style="padding: 14px 25px;">
              <div style="display: flex; justify-content: space-between; margin: 6px 0px;">
                <p>Time</p>
                <p style="font-weight: bold;">${epochToDate(point.x)}</p>
              </div>
              <div style="display: flex; justify-content: space-between; margin: 6px 0px;">
                <p>${series.yAxis?.options.title?.text}</p>
                <p style="font-weight: bold;">${point.y}</p>
              </div>
            </div>
          </div>`
}

const options: Highcharts.Options = {
  title: {
    align: 'left',
    text: 'Solar Employment Growth by Sector, 2010-2016'
  },
  credits: {
    enabled: false
  },
  colors,
  tooltip: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 8,
    padding: 0,
    formatter: tooltipFormatter,
    shadow: false,
    useHTML: true
  },
  chart: {
    type: 'area',
    style: {
      fontFamily: 'var(--fonts-normal)'
    },
    spacing: [ 16, 16, 16, 16 ]
  },
  yAxis: {
    title: {
      text: 'Number of Employees'
    }
  },
  xAxis: {
    type: 'datetime'
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom'
  },
  responsive: {
    rules: [ {
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        }
      }
    } ]
  }
}

type Props = {
  title?: string,
  yAxis: YAxisOptions,
  series: SeriesAreaOptions[]
}

const TimeSeries: React.FC<Props> = ({ title, yAxis, series }) => (
  <Card css={{ padding: 35 }}>
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...options,
        chart: { ...options.chart },
        title: { text: title },
        yAxis,
        series: series.map((s, idx) => {
          const colorsLength = colors.length
          const colorIdx = idx % colorsLength
          return ({
            ...s,
            fillColor: {
              linearGradient: [ 0, 0, 0, 300 ],
              stops: [
                [ 0, colors[colorIdx] ],
                [ 1, color(colors[colorIdx]).setOpacity(0).get('rgba').toString() ]
              ]
            }
          })
        })
      }}
    />
  </Card>
)

export default TimeSeries
