import parsePhoneNumber from 'libphonenumber-js'
import pluralize from 'pluralize'
import { addMethod, lazy, number, object, reach, setLocale, string, StringSchema } from 'yup'
import { setIn } from 'final-form'
import type { Lazy, NumberSchema, ObjectSchema } from 'yup'

import { isCountable } from 'lib/inflector'

/* eslint-disable no-template-curly-in-string */
setLocale({
  mixed: {
    required: "can't be blank",
    oneOf: ({ values }) => `must be one of the following: ${values}`,
    notOneOf: 'must be unique'
  },
  array: {
    min: ({ min }) => `must have at least ${min} item${min > 1 ? 's' : ''}`,
    max: ({ max }) => `can have at most ${max} item${max > 1 ? 's' : ''}`
  },
  date: {
    min: 'must be at least ${min}',
    max: 'can be at most ${max}'
  },
  string: {
    email: 'is not a valid email',
    max: 'is too long (maximum is ${max} characters)',
    min: 'is too short (minimum is ${min} characters)',
    length: 'must be ${length} characters',
    trim: "can't be blank"
  },
  number: {
    lessThan: 'must be less than ${less}',
    moreThan: 'must be greater than ${more}',
    integer: 'must be an integer',
    min: 'must be at least ${min}',
    max: 'can be at most ${max}',
    positive: 'must be a positive number'
  }
})
/* eslint-enable no-template-curly-in-string */

type ValidatorType = 'required'

addMethod<NumberSchema>(number, 'nonNegative', function nonNegative() {
  return this.min(0)
})

addMethod<NumberSchema>(number, 'between', function between(min, max) {
  return this.test(
    'between', `must be between ${min} and ${max}`,
    (value) => value >= min && value <= max
  )
})

addMethod<NumberSchema>(number, 'notBetween', function notBetween(min, max) {
  return this.test(
    'notBetween', `can't be between ${min} and ${max}`,
    (value) => !(value >= min && value <= max)
  )
})

addMethod<NumberSchema>(number, 'equalTo', function equalTo(x) {
  return this.test(
    'equalTo', `must be ${x}`,
    (value) => (value === x)
  )
})

addMethod<NumberSchema>(number, 'notEqualTo', function notEqualTo(x) {
  return this.test(
    'notEqualTo', `can't be ${x}`,
    (value) => (value !== x)
  )
})

addMethod<StringSchema>(string, 'singular', function singular() {
  return this.test(
    'singular',
    'must be singular',
    (value) => (isCountable(value) ? value !== pluralize(value) : true)
  )
})

addMethod<StringSchema>(string, 'required', function required() {
  return this.trim().test(
    'required',
    'can\'t be blank',
    (value) => !!value
  )
})

addMethod<StringSchema>(string, 'onlyRequired', function required() {
  return this.test(
    'onlyRequired',
    'can\'t be blank',
    (value) => !!value
  )
})

addMethod<StringSchema>(string, 'phone', function isValidPhoneNumber() {
  return this.test(
    'phone', 'must be a valid phone number',
    (value) => (value ? (parsePhoneNumber(value)?.isValid() || false) : true)
  )
})

declare module 'yup' {
  interface NumberSchema {
    /** valid when `value >= 0` */
    nonNegative(): this,
    between(min: number, max: number): this,
    notBetween(min: number, max: number): this,
    equalTo(x: number): this,
    notEqualTo(x: number): this
  }
  interface StringSchema {
    singular(): this,
    /** does both `trim` and `required` validations */
    required(): this,
    onlyRequired(): this,
    phone(): this
  }
}

class BaseModel {
  static schema: ObjectSchema

  static permissions: Record<string, any>

  static validate<T, K extends keyof T>(
    values: T, staticFields: K[], dynamicFields?: Record<any, any>, fieldPrefix?: string
  ) {
    const validationSchema = lazy(() => {
      if (staticFields.length === 0) {
        return this.schema
      }

      const dynamicSchema = this.extendSchema(dynamicFields, fieldPrefix)

      return object(staticFields.reduce((fieldList: any, field) => {
        fieldList[field] = reach(this.schema, field as string)
        return fieldList
      }, {})).concat(dynamicSchema)
    })

    return this.validateWithSchema<T>(values, validationSchema)
  }

  static validateSchema<T, S extends Record<string, any>>(values: T, schema: S) {
    const validationSchema = object(schema)

    return this.validateWithSchema(values, validationSchema)
  }

  private static validateWithSchema<T>(values: T, validationSchema: Lazy) {
    return validationSchema.validate(values, { abortEarly: false })
      .then(() => { })
      .catch((e) => e.inner.reduce((
        errors: object, { path, message }: { path: string, message: any }
      ) => setIn(errors, path, message), {}))
  }

  private static extendSchema<T>(fields: T, fieldPrefix?: string) {
    if (!fields) return object()

    const fieldsSchema = Object.entries(fields).reduce((schema, [ field, { validations } ]) => {
      let validator = string()

      validations.forEach((type: ValidatorType) => {
        validator = validator[type]()
      })

      if (fieldPrefix) {
        schema[fieldPrefix] = { ...schema[fieldPrefix], [field]: validator }
      } else {
        schema[field] = validator
      }

      return schema
    }, {} as Record<string, any>)

    if (fieldPrefix) {
      fieldsSchema[fieldPrefix] = object().shape(
        fieldsSchema[fieldPrefix]
      )
    }

    return object(fieldsSchema)
  }

  static authorize(record: any = {}, action: string) {
    return this.permissions[action].indexOf(record.role) !== -1
  }
}

export default BaseModel
