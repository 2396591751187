import { cloneElement } from 'react'
import type { ReactElement } from 'react'

import generatePosition from './generatePosition'
import MenuElement from 'models/MenuElement'
import withPlaceholder from 'components/placeholder/withPlaceholder'
import type { ComputedMenuElement, MenuElementMap } from 'lib/generateDashboard'
import type { OrderableData } from 'hooks/useReorder'

type TargetMenuElementProperties = Pick<ComputedMenuElement, 'parentId'| 'placement' | 'isSticky'>

type TargetMenuElements = TargetMenuElementProperties & Array<ComputedMenuElement> & {
  validate: (id: string, idToMenuElementMap: MenuElementMap) => boolean,
  reposition: (
    id: string,
    destinationIndex: number,
    prevElement: ComputedMenuElement | undefined,
    nextElement: ComputedMenuElement | undefined,
    callback: (values: OrderableData) => void
  ) => void
}

function computeDroppableId(
  menuElements: ComputedMenuElement[],
  properties: TargetMenuElementProperties
) {
  return JSON.stringify({
    // convert to object to preserve index position as keys
    ...(Object.fromEntries(Object.entries(menuElements))),
    ...properties
  })
}

function parseDroppableId(droppableId: string) {
  let targetMenuElements: TargetMenuElements

  try {
    targetMenuElements = JSON.parse(droppableId)
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error parsing droppableId as JSON')
    return undefined
  }

  const { parentId, placement, isSticky } = targetMenuElements
  const isHeader = parentId === null && placement === 'TOP'

  const getNewPosition = (start?: number, end?: number) => {
    if (isHeader) {
    // handle descending sorted menu elements
      return generatePosition(end, start)
    }

    return generatePosition(start, end)
  }

  const rebalance = (
    position: number,
    destinationIndex: number,
    prevElement: ComputedMenuElement | undefined,
    nextElement: ComputedMenuElement | undefined,
    callback: (values: OrderableData) => void
  ) => {
    // If clash with next element update next element position
    if (nextElement?.position === position) {
      const nextPosition = getNewPosition(
        nextElement.position,
        targetMenuElements[destinationIndex + 2]?.position
      )

      callback({
        id: nextElement.id,
        parentId,
        position: nextPosition
      })
    }

    // If clash with prev element update prev element position
    if (prevElement?.position === position) {
      const prevPosition = getNewPosition(
        targetMenuElements[destinationIndex - 2]?.position,
        prevElement?.position
      )

      callback({
        id: prevElement.id,
        parentId,
        position: prevPosition
      })
    }
  }

  targetMenuElements.validate = (id, idToMenuElementMap) => {
    const parentMenuElement = idToMenuElementMap[parentId]
    const isHeader = parentId === null && placement === 'TOP'

    // don't allow dropping in it's own submenu tree
    if (MenuElement.isDescendantOf(id, parentMenuElement)) {
      return false
    }

    // don't allow dropping menu group on header
    if (isHeader) {
      if (id && idToMenuElementMap[id].kind === 'GROUP') {
        return false
      }
    }

    return true
  }

  targetMenuElements.reposition = (
    id,
    destinationIndex,
    prevElement,
    nextElement,
    callback
  ) => {
    const position = getNewPosition(prevElement?.position, nextElement?.position)

    rebalance(
      position,
      destinationIndex,
      prevElement,
      nextElement,
      callback
    )

    return callback({
      id,
      parentId,
      isSticky,
      placement,
      position
    })
  }

  return targetMenuElements
}

function getPlaceholder(
  providedPlaceholder: ReactElement | null | undefined,
  placeholderProps: Parameters<typeof withPlaceholder>[0]
) {
  return providedPlaceholder && cloneElement(providedPlaceholder, {}, (props: any) => {
    const p = providedPlaceholder?.props && providedPlaceholder.props.children?.(props)

    return cloneElement(p, {
      placeholder: {
        ...p.props.placeholder,
        tagName: withPlaceholder(placeholderProps)
      }
    })
  })
}

export { computeDroppableId, getPlaceholder, parseDroppableId }

export type { TargetMenuElements }
