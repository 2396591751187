import React from 'react'

import RatingInput from 'components/inputs/RatingInput'

type RatingFieldProps = {
  value?: number
}

const noop = () => {}

const mockFieldProps = {
  name: 'rating',
  onBlur: noop,
  onFocus: noop,
  onChange: noop
}

function RatingField({ value }: RatingFieldProps) {
  if (!value) return null

  return (
    <RatingInput
      meta={{}}
      label=""
      input={{
        value,
        ...mockFieldProps
      }}
    />
  )
}

RatingField.computeDiff = (previousValue?: number, currentValue?: number) => ({
  previousNode: <RatingField value={previousValue} />,
  currentNode: <RatingField value={currentValue} />,
  isDiff: previousValue !== currentValue
})

export default RatingField
