// https://github.com/tannerlinsley/react-virtual
import rafSchd from 'raf-schd'
import React from 'react'
import { useRect } from '@reach/rect'

const defaultEstimateSize = () => 50

type VirtualItem = {
  index: number,
  start: number,
  end: number,
  size: number
}

/* eslint-disable no-shadow */
export function useVirtual<T extends HTMLElement>({
  size = 0,
  estimateSize = defaultEstimateSize,
  overscan = 0,
  paddingStart = 0,
  paddingEnd = 0,
  containerParentRef,
  scrollParentRef, // we specify separate refs for container and scroll parent
  horizontal
}: {
  size: number,
  containerParentRef: React.RefObject<T>,
  scrollParentRef?: React.RefObject<T>,
  estimateSize?: (index: number) => number,
  overscan?: number,
  horizontal?: boolean,
  paddingStart?: number,
  paddingEnd?: number
}): {
  virtualItems: VirtualItem[],
  totalSize: number
} {
  function useScroll(
    onChange: ({ scrollLeft, scrollTop }:{scrollLeft:number, scrollTop:number}) => void,
    nodeRef?: React.RefObject<T>
  ) {
    const [ element, setElement ] = React.useState(nodeRef?.current)
    const onChangeRef = React.useRef(onChange)

    React.useLayoutEffect(() => {
      if (nodeRef?.current !== element) {
        setElement(nodeRef?.current)
      }
    }, [ nodeRef, element ])

    React.useLayoutEffect(() => {
      if (nodeRef && !element) {
        return () => null
      }

      const handler = rafSchd(() => {
        if (element) {
          onChangeRef.current({
            scrollLeft: element.scrollLeft,
            scrollTop: element.scrollTop
          })
        } else {
          onChangeRef.current({
            scrollLeft: window.scrollX,
            scrollTop: window.scrollY
          })
        }
      })

      // scrollTarget is either passed element via nodeRef or else default to window
      const scrollTarget = element || window
      scrollTarget.addEventListener('scroll', handler, {
        capture: false,
        passive: true
      })

      handler()

      return () => scrollTarget.removeEventListener('scroll', handler)
    }, [ element, nodeRef ])
  }

  const sizeKey = horizontal ? 'width' : 'height'
  const scrollKey = horizontal ? 'scrollLeft' : 'scrollTop'
  const offsetKey = horizontal ? 'x' : 'y'

  const { [sizeKey]: containerParentSize, [offsetKey]: containerOffsetDist } = useRect(
    containerParentRef
  ) || {
    [sizeKey]: 0,
    [offsetKey]: 0
  }

  const { [sizeKey]: scrollParentSize, [offsetKey]: scrollerOffsetDist } = useRect(
    scrollParentRef || { current: null },
    { observe: !!scrollParentRef }
  ) || {
    height: window.innerHeight,
    width: window.innerWidth,
    x: -window.pageXOffset,
    y: -window.pageYOffset
  }

  const outerSize = Math.min(containerParentSize, scrollParentSize)

  const [ scrollOffset, _setScrollOffset ] = React.useState(0)

  // Adjust the scroll offset with container parent distance `y`
  // w.r.t. scroll parent distance `y` from the top
  const adjustedScrollOfset = Math.max(0, scrollOffset - (containerOffsetDist - scrollerOffsetDist))

  const scrollOffsetPlusOuterSize = adjustedScrollOfset + outerSize

  useScroll(({ [scrollKey]: newScrollOffset }) => {
    _setScrollOffset(newScrollOffset)
  }, scrollParentRef)

  const [ measuredCache, setMeasuredCache ] = React.useState<{[key: string]: number}>({})

  const { measurements, reversedMeasurements } = React.useMemo(() => {
    const measurements = []
    const reversedMeasurements = []

    for (let i = 0, j = size - 1; i < size; i += 1, j -= 1) {
      const start: number = measurements[i - 1] ? measurements[i - 1].end : paddingStart
      const size: number = measuredCache[i] || estimateSize(i)
      const end = start + size
      const bounds = { index: i, start, size, end }
      measurements[i] = {
        ...bounds
      }
      reversedMeasurements[j] = {
        ...bounds
      }
    }
    return { measurements, reversedMeasurements }
  }, [ estimateSize, measuredCache, paddingStart, size ])

  const totalSize = (measurements[size - 1]?.end || 0) + paddingEnd

  const start = React.useMemo(
    () => reversedMeasurements.reduce(
      (last, rowStat) => (rowStat.end >= adjustedScrollOfset ? rowStat : last),
      reversedMeasurements[0]
    ),
    [ reversedMeasurements, adjustedScrollOfset ]
  )

  const end = React.useMemo(
    () => measurements.reduce(
      (last, rowStat) => (rowStat.start <= scrollOffsetPlusOuterSize ? rowStat : last),
      measurements[0]
    ),
    [ measurements, scrollOffsetPlusOuterSize ]
  )

  let startIndex = start ? start.index : 0
  let endIndex = end ? end.index : 0

  // Always add at least one overscan item, so focus will work
  startIndex = Math.max(startIndex - overscan, 0)
  endIndex = Math.min(endIndex + overscan, size - 1)

  const latestRef = React.useRef({
    measurements,
    outerSize,
    scrollOffset: adjustedScrollOfset,
    scrollOffsetPlusOuterSize,
    totalSize
  })

  const defaultScrollToFn = React.useCallback(
    (offset) => {
      // If scrollParentRef is not given fallback to window
      if (!scrollParentRef) {
        _setScrollOffset(offset)
        window.scroll({
          ...(scrollKey === 'scrollLeft' ? { left: offset } : { top: offset })
        })
      }
      if (scrollParentRef && scrollParentRef.current) {
        _setScrollOffset(offset)
        scrollParentRef.current[scrollKey] = offset
      }
    },
    [ scrollParentRef, scrollKey ]
  )

  const virtualItems = React.useMemo(() => {
    const items = [] as VirtualItem[]

    for (let i = startIndex; i <= endIndex; i++) {
      const measurement = measurements[i]

      const item = {
        ...measurement,
        measureRef: (el: HTMLElement) => {
          const { scrollOffset } = latestRef.current

          if (el) {
            const { [sizeKey]: measuredSize } = el.getBoundingClientRect()

            if (measuredSize !== item.size) {
              if (item.start < scrollOffset) {
                defaultScrollToFn(scrollOffset + (measuredSize - item.size))
              }

              setMeasuredCache((old) => ({
                ...old,
                [i]: measuredSize
              }))
            }
          }
        }
      }

      items.push(item)
    }

    return items
  }, [ startIndex, endIndex, measurements, sizeKey, defaultScrollToFn ])
  /* eslint-enable */

  const mountedRef = React.useRef(false)

  React.useLayoutEffect(() => {
    if (mountedRef.current) {
      if (estimateSize || size > 0) setMeasuredCache({})
    }
    mountedRef.current = true
  }, [ estimateSize, size ])

  // Reduced the api surface to only what we are using
  // Removed stuff like scrollToIndex, etc.
  return {
    virtualItems,
    totalSize
  }
}

export type {
  VirtualItem
}

export default useVirtual
