import React from 'react'
import type { ReactNode } from 'react'

import GlobalContext from 'components/contexts/GlobalContext'
import { alertVar } from 'client/state/alert'
import type { AlertOptions } from 'components/contexts/GlobalContext'

const openFailureAlert = ({ icon = null, title = null, message = null }: AlertOptions) => {
  alertVar({ variant: 'failure', isOpen: true, icon, title, message })
}

const openSuccessAlert = ({ icon = null, title = null, message = null }: AlertOptions) => {
  alertVar({ variant: 'success', isOpen: true, icon, title, message })
}

const openWarningAlert = ({ icon = null, title = null, message = null }: AlertOptions) => {
  alertVar({ variant: 'warning', isOpen: true, icon, title, message })
}

export default function GlobalProvider({ children }: { children: ReactNode }) {
  return (
    <GlobalContext.Provider value={{
      openFailureAlert,
      openSuccessAlert,
      openWarningAlert
    }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
