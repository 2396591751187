import React from 'react'
import get from 'lodash/get'

import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import type { RendererOptions } from 'components/dataTable/types'
import type { DashboardFragmentFragment as DashboardFragment } from 'generated/schema'

function DashboardNameRenderer<T extends DashboardFragment>({
  dataKey,
  rowData
}: RendererOptions<T>) {
  const data = get(rowData, dataKey, '') as string

  return (
    <Flex gap={8} alignItems="center">
      <Text as="span" truncate title={data}>{data}</Text>
    </Flex>
  )
}

export default DashboardNameRenderer
