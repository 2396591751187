import React, { useRef } from 'react'
import type { FormEvent, ReactNode } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import * as mixins from 'styles/mixins'
import Card from 'components/card/Card'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import Text from 'components/typography/Text'
import useHover from 'hooks/useHover'
import { styled } from 'styles/stitches'
import type { CardProps } from 'components/card/Card'

type AppCardAction = {
  description: string,
  icon: string,
  onClick?: () => void
}

type AppCardProps = Omit<CardProps, 'actions' | 'width'> & {
  actions?: AppCardAction[],
  active?: boolean,
  media: ReactNode | string,
  text?: string,
  title: string,
  titlePosition?: 'top' | 'bottom',
  width?: 'normal' | 'full',
  secondaryIcon?: string,
  dragHandleProps?: DraggableProvidedDragHandleProps
}

const MEDIA_CARD_HEIGHT = 90

const StyledCard = styled(Card, {
  ...mixins.transition('fluid'),

  borderColor: 'transparent',
  borderStyle: 'solid',
  borderWidth: 1,
  textAlign: 'left',

  '& [data-secondary-icon]': {
    display: 'inline-table !important',
    color: 'positive400',
    padding: 6
  },

  variants: {
    active: {
      true: {
        borderColor: 'dark700'
      }
    }
  }
})

const StyledApp = styled(Flex, {
  borderRightColor: 'light700',
  borderRightStyle: 'solid',
  borderRightWidth: 1,
  paddingRight: 16
})

const Actions = styled(Flex, {
  ...mixins.transition('fluid'),

  variants: {
    hovered: {
      true: {
        width: 45,
        flexShrink: 1,
        opacity: 1,
        transform: 'translateX(0)'
      },
      false: {
        flexShrink: 0,
        width: 0,
        opacity: 0,
        transform: 'translateX(45px)'
      }
    }
  }
})

function AppCard({
  actions = [],
  active,
  dragHandleProps,
  media,
  text,
  title,
  titlePosition = 'bottom',
  width = 'normal',
  secondaryIcon,
  ...others
}: AppCardProps) {
  const [ onHoverProps, isHovered, setIsHovered ] = useHover()
  const cardRef = useRef<HTMLButtonElement | null>(null)

  return (
    <StyledCard
      ref={cardRef}
      active={active}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      width={width === 'normal' ? 270 : '100%'}
      height={MEDIA_CARD_HEIGHT}
      justifyContent="center"
      {...onHoverProps}
      {...others}
    >
      <Flex gap={20} style={{ width: '100%' }}>
        {dragHandleProps && (
          <Flex alignItems="center">
            <IconButton
              description="Drag handle"
              hideTooltip
              name="drag"
              size={16}
              variant="dark"
              {...dragHandleProps}
            />
          </Flex>
        )}
        {media && (
          <StyledApp alignItems="center">
            {media}
          </StyledApp>
        )}
        <Flex justifyContent="space-between" alignItems="center" grow={1}>
          <Flex grow={1} justifyContent="space-between" alignItems="center">
            <Flex direction="column" gap={10} alignItems="center" justifyContent="center">
              {titlePosition === 'top' && <Text fontWeight="bold" fontSize={14}>{title}</Text>}
              {text && <Text color="dark500" fontSize={12}>{text}</Text>}
              {titlePosition === 'bottom' && <Text fontWeight="bold" fontSize={14}>{title}</Text>}
            </Flex>
            {secondaryIcon && (<Icon data-secondary-icon name={secondaryIcon} size={16} />)}
          </Flex>
          {!!actions.length && (
            <Actions
              alignSelf="stretch"
              hovered={isHovered}
            >
              <Divider orientation="vertical" spacing={8} />
              <Flex direction="column" justifyContent="center">
                {actions.map((action) => (
                  <IconButton
                    description={action.description}
                    isVisible={isHovered}
                    key={action.icon}
                    name={action.icon}
                    onClick={(e: FormEvent) => {
                      e.stopPropagation()
                      action.onClick && action.onClick()
                    }}
                    variant="dark"
                  />
                ))}
              </Flex>
            </Actions>
          )}
        </Flex>
      </Flex>
    </StyledCard>
  )
}

export type { AppCardProps }

export default AppCard
