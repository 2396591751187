import React, { ReactNode, useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { array, object, string } from 'yup'
import { useField } from 'react-final-form'

import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import DefaultValueField from 'components/fieldViews/DefaultValueField'
import Divider from 'components/divider/Divider'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import isIdentifier from 'lib/formValidators/isIdentifier'
import NumberField from 'components/contentEditors/generic/fields/NumberField'
import Text from 'components/typography/Text'
import TextField from 'components/contentEditors/generic/fields/TextField'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { DataTypeKind } from 'models/DataType'
import { FieldIdentifier } from 'models/Field'
import { getRepeatedConfigSchema, RepeatedConfigField, UniquenessField } from 'components/fieldViews/common'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

const useIsFirstRenderRef = () => {
  const isFirstRenderRef = useRef(true)

  useComponentDidMount(() => {
    isFirstRenderRef.current = false
  })

  return isFirstRenderRef
}

const Base = ({ disableAutoFocus = false }) => (
  <>
    <FormField name="name" autoFocus={!disableAutoFocus} label="Name" size="small" />
    <FormField name="identifier" label="Identifier" size="small" />
  </>
)

type ConfigurationsProps = {
  fieldPrefix?: string,
  translatableFieldName?: string,
  disableLocalization?: boolean
}

const Configurations = ({ fieldPrefix = 'settings.', disableLocalization = false, translatableFieldName = 'isTranslatable' }: ConfigurationsProps) => (
  <>
    <FormField name={`${fieldPrefix}placeholder`} label="Placeholder" size="small" />
    <FormField name={`${fieldPrefix}helpText`} label="Help Text" size="small" />
    {!disableLocalization && (
      <FormField
        name={translatableFieldName}
        component={ToggleInput}
        label="Enable localization?"
        helpText="Allow the attribute to be have localized values"
        labelPosition="left"
        type="checkbox"
      />
    )}
    <RepeatedConfigField fieldPrefix={fieldPrefix} />
    <Divider />
    <DropDownOptions fieldPrefix={fieldPrefix} />
  </>
)

type SettingsProps = {
  optionsConfig?: ReactNode,
  fieldSettingsPrefix?: string
}

const Settings = ({ fieldSettingsPrefix = 'fieldTypeSettings.', optionsConfig }: SettingsProps) => (
  <>
    <FormField
      name={`${fieldSettingsPrefix}is_multiselect`}
      component={ToggleInput}
      label="Allow multiple selections?"
      type="checkbox"
    />
    <Text fontWeight="bold" fontSize={12} textTransform="uppercase">Options</Text>
    {optionsConfig || (
      <>
        <FormField
          name={`${fieldSettingsPrefix}enable_option_keys`}
          component={ToggleInput}
          label="Add key for each option?"
          type="checkbox"
        />
        <FormField
          name={`${fieldSettingsPrefix}enable_option_colors`}
          component={ToggleInput}
          label="Add color for each option?"
          type="checkbox"
        />
        <OptionsConfiguration fieldSettingsPrefix={fieldSettingsPrefix} />
        <FormField
          name={`${fieldSettingsPrefix}is_creatable`}
          component={ToggleInput}
          label="Allow new options to be created?"
          type="checkbox"
        />
      </>
    )}
    <FormField
      name={`${fieldSettingsPrefix}label`}
      label="Label"
      size="small"
    />
    <FormField name={`${fieldSettingsPrefix}placeholder`} label="Placeholder" size="small" />
    <FormField name={`${fieldSettingsPrefix}help_text`} label="Help Text" size="small" />
  </>
)

const OptionsConfiguration = ({ fieldSettingsPrefix = 'fieldTypeSettings.' }) => {
  const name = `${fieldSettingsPrefix}options`
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  const isFirstRenderRef = useIsFirstRenderRef()

  const onDragEnd = useReorderFieldArray(fieldsRef)

  const dataType = useField('dataType').input.value

  const KeyField = dataType === DataTypeKind.INT ? NumberField : TextField

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const fieldName = FieldArray.getFieldName(name, rubric.source.index)

    return (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <FieldGroup
          dragHandleProps={provided.dragHandleProps}
          isDraggable
          onClick={() => fieldsRef.current?.fields.remove(rubric.source.index)}
        >
          <FormField
            name={`${fieldName}.label`}
            component={TextInput}
            placeholder="label"
            size="small"
            autoFocus={!isFirstRenderRef.current}
          />

          <WhenFieldChanges
            field={`${fieldName}.label`}
            set={`${fieldName}.key`}
            replicateValue
            overrideOnTouch
          />

          <ConditionalField when={`${fieldSettingsPrefix}enable_option_keys`} is>
            <KeyField
              name={`${fieldName}.key`}
              placeholder="key"
              size="small"
            />
          </ConditionalField>
        </FieldGroup>
      </div>
    )
  }

  return (
    <Grid gap={12}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={droppableId.current}
          renderClone={renderDraggableChildren}
        >
          {(droppableProvided: DroppableProvided) => (
            <Flex
              direction="column"
              gap={14}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              <FieldArray
                name={name}
                fieldsRef={fieldsRef}
              >
                {({ keys }) => keys.map((key, index) => (
                  <Draggable
                    disableInteractiveElementBlocking
                    draggableId={key}
                    index={index}
                    key={key}
                  >
                    {renderDraggableChildren}
                  </Draggable>
                ))}
              </FieldArray>

              {droppableProvided.placeholder}
              <div />
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        label="Add Option"
        variant="filled"
        mode="subtle"
        size="small"
        onClick={() => {
          fieldsRef.current?.fields.push({ label: '' })
        }}
      />
    </Grid>
  )
}

const DropDownOptions = ({ fieldPrefix = 'settings.' }) => {
  const name = `${fieldPrefix}options`
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  const isFirstRenderRef = useIsFirstRenderRef()

  const onDragEnd = useReorderFieldArray(fieldsRef)

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const fieldName = FieldArray.getFieldName(name, rubric.source.index)
    return (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <FieldGroup
          dragHandleProps={provided.dragHandleProps}
          isDraggable
          onClick={() => fieldsRef.current?.fields.remove(rubric.source.index)}
        >
          <FormField
            name={`${fieldName}.label`}
            component={TextInput}
            placeholder="label"
            size="small"
            autoFocus={!isFirstRenderRef.current}
          />

          <WhenFieldChanges
            field={`${fieldName}.label`}
            set={`${fieldName}.value`}
            replicateValue
            overrideOnTouch
          />

          <ConditionalField when={`${fieldPrefix}optionsKeyable`} is>
            <FormField
              name={`${fieldName}.value`}
              placeholder="key"
              size="small"
            />
          </ConditionalField>
        </FieldGroup>
      </div>
    )
  }
  return (
    <Grid alignItems="center" gap={20} columns={1}>
      <Grid columns={1} gap={14}>
        <Flex as="label" justifyContent="space-between">
          <FieldLabel>Options</FieldLabel>
          <TextLink
            as="button"
            type="button"
            fontSize={10}
            mode="subtle"
            onClick={() => {
              fieldsRef.current?.fields.push({ label: '' })
            }}
          >
            Add an option
          </TextLink>
        </Flex>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={droppableId.current}
            renderClone={renderDraggableChildren}
          >
            {(droppableProvided: DroppableProvided) => (
              <Flex
                direction="column"
                gap={14}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <FieldArray
                  name={name}
                  fieldsRef={fieldsRef}
                >
                  {({ keys }) => keys.map((key, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={key}
                      index={index}
                      key={key}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                </FieldArray>

                {droppableProvided.placeholder}
                <div />
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <FormField
        name={`${fieldPrefix}optionsCreatable`}
        component={ToggleInput}
        label="Allow user to create new option?"
        labelPosition="left"
        type="checkbox"
      />
      <FormField
        name={`${fieldPrefix}optionsKeyable`}
        component={ToggleInput}
        label="Use different values for key & label?"
        labelPosition="left"
        type="checkbox"
      />
    </Grid>
  )
}

const Validations = ({ fieldPrefix = 'settings.' }) => (
  <>
    <Divider />
    <Grid alignItems="center" gap={20} columns={1}>
      <Text fontSize={16} fontWeight="bold">Validations</Text>
      <FormField
        name={`${fieldPrefix}checkRequired`}
        component={ToggleInput}
        label="Required value?"
        labelPosition="left"
        type="checkbox"
      />
      <UniquenessField fieldPrefix={fieldPrefix} />
    </Grid>
  </>
)

type PreviewProps = {
  fieldPrefix?: string
}

const Preview = ({ fieldPrefix = '' }: PreviewProps) => (
  <>
    <Divider />
    <Grid alignItems="center" gap={20} columns={1}>
      <Text fontSize={16} fontWeight="bold">Preview</Text>
      <DefaultValueField
        identifier={FieldIdentifier.DROPDOWN}
        fieldPrefix={fieldPrefix}
      />
    </Grid>
  </>
)

const DropdownFieldView = () => (
  <Grid columns={1} gap={24}>
    <Grid alignItems="center" gap={20} columns={2}>
      <Base />
    </Grid>
    <Configurations />
    <Preview />
    <Validations />
  </Grid>
)

DropdownFieldView.validate = (values: fieldProps<'dropDown'>) => {
  const schema = {
    name: string().required(),
    identifier: isIdentifier(),
    settings: object({
      options: array().of(object({
        label: string().required(),
        value: string().notRequired()
      })),
      ...getRepeatedConfigSchema(values)
    })
  }

  return BaseModel.validateSchema(values, schema)
}

DropdownFieldView.Base = Base
DropdownFieldView.Configurations = Configurations
DropdownFieldView.Preview = Preview
DropdownFieldView.Settings = Settings
DropdownFieldView.Validations = Validations

export { OptionsConfiguration }
export default DropdownFieldView
