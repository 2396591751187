import React from 'react'

import * as constants from 'components/modal/constants'
import * as mixins from 'styles/mixins'
import BackLink from 'components/links/BackLink'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

const HEADER_MARGIN_Y = 24
const CLOSE_ICON_POSITION = 40

const StyledHeader = styled(Flex, {
  marginBottom: HEADER_MARGIN_Y,
  paddingTop: constants.VERTICAL_PADDING,

  variants: {
    tall: {
      true: {
        paddingTop: HEADER_MARGIN_Y
      },
      false: {}
    },
    wide: {
      true: {
        paddingX: constants.SMALL_HORIZONTAL_PADDING
      },
      false: {
        paddingX: constants.LARGE_HORIZONTAL_PADDING
      }
    }
  }
})

const StyledCloseIcon = styled(Icon, {
  ...mixins.transition('simple'),

  color: 'dark100',
  cursor: 'pointer',
  position: 'absolute',
  top: CLOSE_ICON_POSITION,
  right: CLOSE_ICON_POSITION,

  '&:hover': {
    color: 'dark500'
  }
})

type ModalHeaderProps = React.PropsWithChildren<{
  backLinkLabel?: string,
  onBackClick?: () => void,
  onCloseClick: () => void,
  title?: string,
  wide?: boolean,
  tall?: boolean
}>

function ModalHeader({
  children,
  backLinkLabel,
  onBackClick,
  onCloseClick,
  title = '',
  wide = false,
  tall = false
}: ModalHeaderProps) {
  return (
    <StyledHeader
      as="header"
      alignItems="center"
      grow={1}
      justifyContent="space-between"
      wide={wide}
      tall={tall}
    >
      <StyledCloseIcon name="cross" onClick={onCloseClick} />
      <Flex gap={10} grow={1} direction="column">
        {onBackClick && (
          <BackLink onClick={onBackClick} label={backLinkLabel} />
        )}
        <Flex grow={1}>
          <Text
            fontSize={36}
            fontWeight="bold"
            letterSpacing="compact"
          >
            {title}
          </Text>

          {children}
        </Flex>
      </Flex>
    </StyledHeader>
  )
}

export default ModalHeader
