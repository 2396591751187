import React from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import IconButton from 'components/buttons/IconButton'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

type DragHandleProps = {
  dragHandleProps?: DraggableProvidedDragHandleProps,
  hidden?: boolean
}

const StyledDragHandle = styled(Flex, {
  marginLeft: 20,
  zIndex: 'above',

  variants: {
    hidden: {
      true: {
        opacity: 0,
        pointerEvents: 'none'
      }
    }
  }
})

const DragHandle = ({ dragHandleProps, hidden }: DragHandleProps) => (
  <StyledDragHandle hidden={hidden}>
    <IconButton
      description="Drag handle"
      hideTooltip
      name="drag"
      size={12}
      variant="dark"
      {...dragHandleProps}
    />
  </StyledDragHandle>
)

export default DragHandle
