import React from 'react'
import type { FormProps } from 'react-final-form'

import GroupForm from 'components/forms/GroupForm'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Environment, GroupsAggregateDocument, GroupsListDocument, useCreateGroupMutation, useUpdateGroupMutation } from 'generated/schema'
import { Kind } from 'models/Account'
import type { CreateGroupInput, Group, GroupsListQueryVariables, UpdateGroupInput } from 'generated/schema'
import type { ViewProps } from 'components/views'

type GroupFormValues = CreateGroupInput | UpdateGroupInput

type Params = {
  initialValues: Partial<Group>,
  accountKind: Group['accountKind'],
  environmentId: Environment['id'],
  queryVariables: GroupsListQueryVariables
}

function GroupFormView({
  onRequestClose,
  params: { initialValues, accountKind, environmentId, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues

  const [ createGroup ] = useCreateGroupMutation({
    refetchQueries: [
      {
        query: GroupsListDocument,
        variables: queryVariables
      },
      {
        query: GroupsAggregateDocument,
        variables: { filter: queryVariables.filter }
      }
    ]
  })

  const [ updateGroup ] = useUpdateGroupMutation({ onCompleted: onRequestClose })

  const handleCreateGroupSubmit = useSubmitHandler(createGroup, {
    update: {
      strategy: 'APPEND',
      query: GroupsListDocument,
      queryVariables,
      dataKey: 'groupsList',
      mutation: 'createGroup'
    }
  })

  const handleUpdateGroupSubmit = useSubmitHandler(updateGroup, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateGroup',
      typename: 'Group',
      override: (values: UpdateGroupInput) => ({
        ...initialFormValues,
        ...values
      })
    }
  })

  const handleGroupFormSubmit = (values: GroupFormValues, form: FormProps<UpdateGroupInput>['form']) => {
    if ('id' in values) {
      return handleUpdateGroupSubmit(values, form)
    }

    return handleCreateGroupSubmit(values as CreateGroupInput)
  }

  const kindString = accountKind === Kind.MEMBER ? 'Member' : 'Service'

  const title = isUpdating ? `Edit ${kindString} Group` : `New ${kindString} Group`

  const initialFormValues = {
    ...initialValues,
    environmentId,
    accountKind
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {(viewComponents) => {
        const { Header } = viewComponents

        return (
          <>
            <Header
              title={title}
              onCloseClick={onRequestClose}
            />
            <GroupForm
              viewComponents={viewComponents}
              initialValues={initialFormValues}
              onRequestClose={onRequestClose}
              onSubmit={handleGroupFormSubmit}
            />
          </>
        )
      }}
    </View>
  )
}

export default GroupFormView
