import dayjs from 'dayjs'
import omit from 'lodash/omit'
import React, { useContext, useState } from 'react'

import Button, { ButtonProps } from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import FormBlock from 'components/blocks/FormBlock'
import IconButton from 'components/buttons/IconButton'
import RecordVersionsListView from './RecordVersionsListView'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import useRecordPublishing, { PublishingAction } from 'hooks/useRecordPublishing'
import { GetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'
import { useInternalFetchRecordQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { CustomRecord, InternalAddRecordInput, InternalEditRecordInput, Operation, Resource } from 'generated/schema'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'
import type { ViewProps } from 'components/views'

type FormValues = InternalAddRecordInput['arguments'] | InternalEditRecordInput['arguments']

type Params = {
  record?: Partial<CustomRecord>,
  operationId: Operation['id'],
  switcher?: SwitcherResultProps['switcher'],
  resource: Resource,
  titleAttributeIdentifier?: string,
  onDelete?: () => void
}

const prefix = 'arguments'

const PublishButton = ({ isPublished, ...rest }: { isPublished: boolean } & ButtonProps) => {
  const uploadingMediaFields = useContext(GetMediaFieldStateContext)

  return (
    <Button
      disabled={uploadingMediaFields.length}
      label={isPublished ? 'Unpublish' : 'Publish'}
      variant={isPublished ? 'outline' : 'filled'}
      size="small"
      {...rest}
    />
  )
}

function AddRecordView({
  onRequestClose,
  params: {
    record: cachedRecord,
    operationId,
    switcher,
    resource,
    onDelete
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { openView } = useViewDispatch()
  const resourceId = resource.id
  const title = resource.name
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)
  const isUpdating = cachedRecord?.id !== undefined
  const { isPublishingEnabled } = resource

  const {
    data: { internalFetchRecord: record = cachedRecord } = {},
    loading,
    error
  } = useInternalFetchRecordQuery({
    variables: {
      input: {
        preview: true,
        resourceId,
        targetEnvironment: switcher?.data.environment?.id,
        arguments: {
          id: cachedRecord?.data?.id
        }
      }
    },
    skip: !cachedRecord?.data?.id
  })

  const initialValues = record?.data || {} as FormValues

  const duplicateButton = (
    <IconButton
      key="duplicate"
      name="duplicate"
      description="Duplicate"
      variant="dark"
      size={24}
      onClick={() => {
        openView({
          title,
          component: AddRecordView,
          params: {
            resource,
            record: { data: omit(record?.data || {}, 'id', 'createdAt', 'updatedAt') } as CustomRecord,
            switcher,
            operationId
          },
          style: 'PANEL'
        })
      }}
    />

  )

  const historyButton = (
    <IconButton
      key="revision-history"
      name="clock-outline"
      description="Revision History"
      variant="dark"
      size={24}
      onClick={() => {
        if (record?.id) {
          openView({
            title: 'Version History',
            component: RecordVersionsListView,
            style: 'PANEL',
            params: {
              recordId: record.id,
              latestId: record.latestId,
              publishedId: record.publishedId,
              targetEnvironment: switcher?.data.environment?.id,
              resource
            }
          })
        }
      }}
    />
  )

  const deleteButton = onDelete && (
    <IconButton
      key="delete-content"
      name="trash"
      description="Delete Content"
      variant="dark"
      size={24}
      onClick={() => {
        onDelete()
      }}
    />
  )

  const isPublished = isPublishingEnabled
    && !!record?.publishedId
    && record.publishedId === record.latestId

  const versionStatus = isPublishingEnabled && (
    <Chip
      label={isPublished ? 'published' : 'current draft'}
      variant={isPublished ? 'positive' : 'dark100'}
    />
  )

  const shouldShowPublishing = isPublishingEnabled && isUpdating

  const [ handlePublishing ] = useRecordPublishing()

  const onPublishingClick = () => (
    handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      id: record?.latestId
    })
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, SubHeader, Footer, Body }) => (
        <>
          <Header title={isUpdating ? `Update ${title}` : `Add ${title}`} onCloseClick={onRequestClose}>
            {isUpdating && (
            <>
              {duplicateButton}
              {deleteButton}
            </>
            )}
          </Header>
          {isUpdating && (
            <SubHeader justifyContent="space-between" gap={16}>
              {record?.updatedAt && (
                <Text
                  color="dark500"
                  css={{ paddingRight: 16 }}
                  fontSize={14}
                  title={dayjs(record.updatedAt).format('MMM D, YYYY h:mm A')}
                >
                  Last modified {dayjs(record.updatedAt).fromNow()}
                </Text>
              )}
              <Flex gap={16} grow={1}shrink={0} alignItems="center" justifyContent="flex-end">
                {versionStatus}
                {shouldShowPublishing && (
                  <PublishButton
                    isPublished={Boolean(isPublished)}
                    onClick={onPublishingClick}
                  />
                )}
                {historyButton}
              </Flex>
            </SubHeader>
          )}
          <Body>
            <SectionLoader loading={loading} error={error} data={isUpdating ? record : {}}>
              <FormBlock
                key={record?.latestId}
                initialValues={initialValues}
                operationId={operationId}
                resourceId={resourceId}
                isTranslatable={resource.isTranslatable}
                asFragment
                footerEl={footerEl}
                switcher={switcher}
                prefix={prefix}
                isPublishingEnabled={isPublishingEnabled}
                publishedId={record?.publishedId}
                latestId={record?.latestId}
              />
            </SectionLoader>
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </>
      )}
    </View>
  )
}

export default AddRecordView
