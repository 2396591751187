import React, { Fragment, useContext } from 'react'
import type { ReactNode, SyntheticEvent } from 'react'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import { isElectron } from 'lib/electron'
import { styled } from 'styles/stitches'
import { useDataManagerContext } from 'hooks/useDataManagerContext'
import { WORKSPACE_SIDEBAR_WIDTH } from 'components/sidebar/constants'

type SummaryBarProps<T> = {
  batchActions?: BatchAction<T>[],
  variant?: 'normal' | 'fixed',
  hideSelectAll?: boolean,
  hideFormulae?: boolean
}

type BatchAction<T> = {
  icon: string,
  title: string,
  onClick: (records: T[]) => void
}

const SPACING = 24
const WRAPPER_BORDER_RADIUS = 9
const WRAPPER_HEIGHT = 60
const PADDING_X = SPACING
const PADDING_Y = 15
const ACTION_Z_INDEX = 1

const baseBoxStyles = {
  background: 'dark900',
  borderRadius: WRAPPER_BORDER_RADIUS,
  height: WRAPPER_HEIGHT,
  paddingY: PADDING_Y,
  paddingX: PADDING_X
}

const StyledInfo = styled(Flex, {
  ...baseBoxStyles,

  zIndex: ACTION_Z_INDEX + 1
})

const StyledActions = styled(Flex, {
  ...mixins.transition('fastIn', 'transform'),
  ...baseBoxStyles,

  zIndex: ACTION_Z_INDEX,
  variants: {
    visible: {
      false: {
        transform: 'translateX(-100%)'
      },
      true: {
        transform: `translateX(-${SPACING}px)`,
        width: 'fit-content'
      }
    }
  }
})

// TODO: prepend 2 '-' after upgrading stitches
const TRANSLATE_X_VAR = '-summary-bar-translate-x'
const TRANSLATE_Y_VAR = '-summary-bar-translate-y'

const StyledWrapper = styled(Flex, {
  ...mixins.transition('fastIn'),

  [TRANSLATE_X_VAR]: 0,
  [TRANSLATE_Y_VAR]: 0,
  position: 'relative',
  whiteSpace: 'nowrap',
  width: 'fit-content',
  transform: `translate(var(-${TRANSLATE_X_VAR}), var(-${TRANSLATE_Y_VAR}))`,

  variants: {
    visible: {
      true: {},
      false: {
        [TRANSLATE_Y_VAR]: '100%'
      }
    },
    variant: {
      normal: {},
      fixed: {
        bottom: 0,
        left: '50%',
        position: 'fixed',
        [TRANSLATE_X_VAR]: '-50%',

        [`& ${StyledActions}`]: {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        },
        [`& ${StyledInfo}`]: {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }
      }
    }
  }
})

const StyledSelectDeselectAllLink = styled(TextLink, {
  '&&': {
    color: 'dark500'
  },

  variants: {
    disabled: {
      false: {
        '&&:hover, &&:focus': {
          color: 'dark100'
        }
      },
      true: {
        '&&': {
          color: 'dark700',
          cursor: 'not-allowed'
        },
        '&&:hover, &&:focus': {
          color: 'dark700'
        }
      }
    }
  }
})

const defaultPreventedClickHandler = (cb: () => void) => (e: SyntheticEvent) => {
  e.preventDefault()
  cb()
}

function SummaryBar<T>({
  batchActions, variant, hideSelectAll = false, hideFormulae = true
}: SummaryBarProps<T>) {
  const {
    data,
    selection,
    selectAllRows,
    deselectAllRows,
    totalRows
  } = useDataManagerContext()
  const { currentSidebarWidth } = useContext(DashboardContext)!
  const allRecordsSelected = data.length === selection.length
  const noneSelected = selection.length === 0

  const divider = <Divider color="dark600" orientation="vertical" />

  const actions = (
    <StyledActions
      alignItems="center"
      gap={24}
      visible={selection.length !== 0}

    >
      {divider}
      {(batchActions || []).map(({ icon, onClick, title }) => (
        <IconButton
          key={title}
          name={icon}
          description={title}
          variant="dark"
          onClick={() => {
            const selectedRecords = data.filter((d) => selection.includes(d.id))

            onClick(selectedRecords)
          }}
        />
      ))}
    </StyledActions>
  )

  const selectionCount = (
    <Text
      key="selectionCount"
      fontWeight="bold"
      fontSize={12}
      color="light100"
    >
      {`${selection.length} selected`}
    </Text>
  )
  const totalCount = (
    <Text
      key="totalCount"
      fontWeight="bold"
      fontSize={12}
      color="light100"
    >
      {`Count: ${totalRows}`}
    </Text>
  )

  const appendDivider = (nodes: ReactNode[]) => {
    if (nodes.length < 2) {
      return nodes
    }

    const newNodes: ReactNode[] = []

    nodes.forEach((node: ReactNode, index) => {
      if (index !== 0) {
        newNodes.push(divider)
      }

      newNodes.push(node)
    })

    return newNodes
  }

  const infoItems = [
    selectionCount,
    <Fragment key="selectDeselectAll">
      {!hideSelectAll && (
        <StyledSelectDeselectAllLink
          disabled={allRecordsSelected}
          fontSize={12}
          mode="subtle"
          onClick={defaultPreventedClickHandler(selectAllRows)}
          variant="underlined"
        >
          Select All
        </StyledSelectDeselectAllLink>
      )}
      <StyledSelectDeselectAllLink
        disabled={noneSelected}
        fontSize={12}
        mode="subtle"
        onClick={defaultPreventedClickHandler(deselectAllRows)}
        variant="underlined"
      >
        Clear All
      </StyledSelectDeselectAllLink>
    </Fragment>
  ]

  if (totalRows) {
    infoItems.unshift(totalCount)
  }

  if (!hideFormulae) {
    infoItems.unshift(
      <IconButton
        description="Formulae"
        key="formulae"
        name="add-outline"
        onClick={() => { }}
        variant="dark"
      />
    )
  }

  const summaryBar = (
    <StyledWrapper
      variant={variant}
      visible={variant !== 'fixed' || !!selection.length}
      style={{
        paddingLeft: variant === 'fixed' ? currentSidebarWidth + (isElectron ? WORKSPACE_SIDEBAR_WIDTH : 0) : 0
      }}
    >
      <StyledInfo
        alignItems="center"
        gap={SPACING}
      >
        {appendDivider(infoItems)}
      </StyledInfo>
      {!!batchActions?.length && actions}
    </StyledWrapper>
  )

  if (variant === 'fixed') {
    return <Portal>{summaryBar}</Portal>
  }

  return summaryBar
}

export type {
  BatchAction,
  SummaryBarProps
}

export default SummaryBar
