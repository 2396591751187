import isObject from 'lodash/isObject'
import React from 'react'
import type { FieldMetaState } from 'react-final-form'

import * as mixins from 'styles/mixins'
import { styled } from 'styles/stitches'
import { colorVars } from 'styles/theme'

const StyledFieldError = styled('div', {
  ...mixins.shadow('medium', colorVars.negative600rgb, 0.3),

  backgroundColor: 'negative500',
  borderRadius: 2,
  color: 'light100',
  fontFamily: 'normal',
  fontSize: 10,
  fontWeight: 'regular',
  paddingX: 20,
  paddingY: 10,
  position: 'absolute',
  left: 20,
  top: '100%',
  marginTop: -11,
  zIndex: 'above'
})

function FieldError({ error, ...others }: FieldMetaState<any>) {
  if (!error) {
    return null
  }

  if (Array.isArray(error)) {
    return (
      <StyledFieldError {...others}>
        {error.join(', ')}
      </StyledFieldError>
    )
  }

  if (isObject(error)) {
    return <StyledFieldError {...others}>{JSON.stringify(error)}</StyledFieldError>
  }

  return (
    <StyledFieldError {...others}>
      {error}
    </StyledFieldError>
  )
}

FieldError.getError = (meta: FieldMetaState<any>) => {
  if (!meta) return undefined

  const {
    active,
    error,
    pristine,
    submitError,
    submitFailed,
    touched
  } = meta

  return (
    touched
    && (!pristine || submitFailed)
    && (error || (!active && submitError))
  )
}

export default FieldError
