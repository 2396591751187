import React from 'react'
import { Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import Grid from 'components/layout/Grid'
import useSubmitHandler from 'hooks/useSubmitHandler'
import {
  App,
  ConfigurationsListDocument,
  EnvironmentsListDocument,
  useCreateConfigurationMutation,
  useUpdateConfigurationMutation
} from 'generated/schema'
import type {
  CreateConfigurationInput,
  UpdateConfigurationInput
} from 'generated/schema'
import type { ViewProps } from 'components/views'
import { TextField } from 'components/contentEditors/generic/fields'
import { IDENTIFIER_TO_FIELD_MAP } from 'components/forms/InstallAppForm'
import type { FieldIdentifier } from 'models/Field'

type ConfigurationFormValues = CreateConfigurationInput | UpdateConfigurationInput

type Params = {
  app: App,
  initialValues: ConfigurationFormValues
}

const ConfigurationField = ({ field, prefix = 'settings' }: any) => {
  const { label, name, field_type: fieldType, validations, options, help_text: helpText } = field
  const SettingsField = IDENTIFIER_TO_FIELD_MAP[
    fieldType as FieldIdentifier
  ] || TextField

  const validationSettings = validations?.includes('required') ? { checkRequired: true } : {}
  const fieldName = `${prefix}.${name}`

  return (
    <>
      <SettingsField
        alwaysDirty
        label={label}
        key={name}
        name={fieldName}
        size="small"
        settings={validationSettings}
        options={options}
        isClearable={!validationSettings.checkRequired}
        shouldValidate
        helpText={helpText}
      />
      {field.field_sets && (
        <FormValuesField fieldNames={[ fieldName ]}>
          {(values) => (
            field.field_sets
              .filter((fieldset: any) => fieldset.name === values[fieldName])
              .map(
                (fieldSet: any) => fieldSet.fields
                  .map((field: any) => (
                    <ConfigurationField
                      key={field.name}
                      field={field}
                      prefix={prefix}
                    />
                  ))
              )
          )}
        </FormValuesField>
      )}
    </>
  )
}

function AddConfigurationView({
  onRequestClose,
  params: { app, initialValues },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues

  const [ createConfiguration ] = useCreateConfigurationMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      ConfigurationsListDocument,
      EnvironmentsListDocument
    ]
  })
  const [ updateConfiguration ] = useUpdateConfigurationMutation({
    onCompleted: onRequestClose
  })

  const title = isUpdating ? 'Edit Configuration' : 'New Configuration'

  const handleCreateConfigurationSubmit = useSubmitHandler(createConfiguration, {
    successAlert: { message: 'Configuration added.' }
  })

  const handleUpdateConfigurationSubmit = useSubmitHandler(updateConfiguration, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateConfiguration',
      typename: 'Configuration',
      override: (values: UpdateConfigurationInput) => ({
        ...initialValues,
        ...values
      })
    },
    successAlert: { message: 'Configuration updated.' }
  })

  const handleConfigurationFormSubmit = (
    values: ConfigurationFormValues, form: FormProps<ConfigurationFormValues>['form']
  ) => {
    if (isUpdating) {
      return handleUpdateConfigurationSubmit(
        values as UpdateConfigurationInput,
        form as FormApi<UpdateConfigurationInput>
      )
    }
    return handleCreateConfigurationSubmit(values as CreateConfigurationInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Form
            initialValues={initialValues as ConfigurationFormValues}
            keepDirtyOnReinitialize
            subscription={{
              submitting: true
            }}
            onSubmit={handleConfigurationFormSubmit}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <form onSubmit={handleSubmit}>
                    <Grid columns={1} gap={24}>
                      {app.fields.map(
                        (field) => <ConfigurationField key={field.name} field={field} />
                      )}
                    </Grid>
                  </form>
                </Body>
                <Footer>
                  <Flex gap={24} direction="row-reverse">
                    <Button type="submit" label="Save" disabled={submitting || pristine} onClick={handleSubmit} />
                  </Flex>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

export { ConfigurationField }

export default AddConfigurationView
