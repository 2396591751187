import { number, NumberSchema } from 'yup'
import React, { useCallback } from 'react'

import DurationInput from 'components/inputs/DurationInput'
import FormField from 'components/form/FormField'
import type { DurationInputProps } from 'components/inputs/DurationInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type DurationFieldProps = Omit<DurationInputProps, 'input' | 'meta'> & fieldProps<'date'>
const getDurationFieldSchema = (settings: DurationFieldProps['settings'] = {}, isNullable: boolean) => {
  let schema: NumberSchema<number | null | undefined> = number().typeError('Invalid Duration').nullable(isNullable)

  if (settings.checkRequired && !settings.hasFallbackLocale) schema = schema.required()

  return schema
}

const DurationField = ({
  name,
  settings,
  shouldValidate,
  isNullable = false,
  ...others
}: DurationFieldProps) => {
  const validate = useCallback((value: string) => getDurationFieldSchema(settings, isNullable)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings, isNullable ])

  return (
    <FormField
      component={DurationInput}
      name={name}
      inputFormat={settings?.format}
      {...({
        checkRequired: settings?.checkRequired,
        placeholder: settings?.placeholder,
        helpText: settings?.helpText
      })}
      {...shouldValidate && { validate }}
      {...others}
    />
  )
}

export { getDurationFieldSchema }

export default DurationField
