import React from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import AppError from 'components/errors/AppError'
import AppLoader from 'components/loaders/AppLoader'
import StartOnboardingForm from 'components/forms/StartOnboardingForm'
import useQueryParams from 'hooks/useQueryParams'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { SET_SESSION_MUTATION } from 'client/state/session'
import { StartOnboardingInput, useStartOnboardingMutation, useVerifyInvitationQuery } from 'generated/schema'

function StartOnboardingPage() {
  const queryParams = useQueryParams()
  const invitationToken = queryParams.get('token') || ''

  const { data, loading, error } = useVerifyInvitationQuery({
    variables: {
      input: {
        invitationToken
      }
    },
    skip: !invitationToken
  })

  const [ setSession ] = useMutation(SET_SESSION_MUTATION)
  const [ startOnboarding ] = useStartOnboardingMutation({
    onCompleted: ({ startOnboarding: session }) => {
      setSession({ variables: { session } })
    }
  })
  const handleSubmit = useSubmitHandler(startOnboarding)
  const handleFormSubmit = (values: StartOnboardingInput) => handleSubmit(values)

  if (!invitationToken) {
    return <Redirect to="/login" />
  }

  if (loading && !data) {
    return <AppLoader />
  }

  if (error || !data || !data.verifyInvitation.success) {
    return (
      <AppError
        defaultCode={404}
        defaultMessage="Your token is either invalid or expired."
        error={error}
      />
    )
  }

  return (
    <StartOnboardingForm
      initialValues={{ invitationToken, password: '', passwordConfirmation: '' }}
      onSubmit={handleFormSubmit}
    />
  )
}

export default StartOnboardingPage
