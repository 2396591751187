import isObject from 'lodash/isObject'
import React from 'react'

import ConditionalField from 'components/form/ConditionalField'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import TextInput from 'components/inputs/TextInput'
import ToggleInput from 'components/inputs/ToggleInput'
import Text from 'components/typography/Text'
import type { Attribute } from 'generated/schema'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { fontWeight = 'regular', truncate = true } = displaySettings

  if (Array.isArray(data)) {
    return (
      <Text fontSize={14} fontWeight={fontWeight} truncate={truncate} css={{ overflow: 'hidden', textOverflow: 'ellipsis', whitespace: 'nowrap' }}>
        {data.join(', ') || '-'}
      </Text>
    )
  }

  return (
    <Text fontSize={14} fontWeight={fontWeight} truncate={truncate} css={{ overflow: 'hidden', textOverflow: 'ellipsis', whitespace: 'nowrap' }}>
      {isObject(data) ? JSON.stringify(data) : data || '-'}
    </Text>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}truncate`}
      component={ToggleInput}
      label="Truncate text?"
      type="checkbox"
    />
    <FormField
      name={`${fieldPrefix}length`}
      label="Length"
      component={TextInput}
      size="small"
      type="number"
      parse={(value: string) => value && parseInt(value, 10)}
    />
    <FormField
      name={`${fieldPrefix}show_view_more`}
      component={ToggleInput}
      label="Allow user to view more?"
      type="checkbox"
    />
    <ConditionalField when={`${fieldPrefix}show_view_more`} is>
      <FormField name={`${fieldPrefix}view_more_label`} label="View More Label" component={TextInput} size="small" />
    </ConditionalField>
  </Grid>
)

export default {
  Configurations
}

export { Renderer as PlainTextRenderer }
