import React, { useEffect, useMemo, useState, HTMLAttributes, useRef, MouseEvent } from 'react'
import { usePopper, PopperProps } from 'react-popper'
import type { CSSProperties, FocusEvent, KeyboardEvent, ReactNode } from 'react'

import Portal from 'components/portal/Portal'
import useMousePositionRef from 'hooks/useMousePosition'
import { styled } from 'styles/stitches'

type OpenOnClickToggleProps = Pick<HTMLAttributes<any>, 'tabIndex' | 'role' | 'aria-haspopup'> & {
  onClick: <T>(e: MouseEvent<T>) => void,
  onKeyDown: (e: KeyboardEvent) => void
}

type OpenOnHoverToggleProps = Pick<HTMLAttributes<any>, 'tabIndex' | 'role' | 'aria-haspopup'> & {
  onMouseEnter: <T>(e: MouseEvent<T>) => void,
  onMouseLeave: <T>(e: MouseEvent<T>) => void,
  onKeyDown: (e: KeyboardEvent) => void
}

type OpenOnContextMenuToggleProps = Pick<HTMLAttributes<any>, 'tabIndex' | 'role' | 'aria-haspopup'> & {
  onContextMenu: <T>(e: MouseEvent<T>) => void,
  onKeyDown: (e: KeyboardEvent) => void
}

type OpenOnFocusToggleProps = {
  onBlur: (e: FocusEvent<HTMLElement>) => void,
  onFocus: (e: FocusEvent<HTMLElement>) => void,
  onKeyDown: (e: KeyboardEvent) => void
}

type PopoverToggleProps = {
  isActive: boolean,
  closePopover: () => void,
  openPopover: () => void,
  ref: (ref: HTMLElement | null) => void
} & (
  | OpenOnClickToggleProps
  | OpenOnFocusToggleProps
  | OpenOnContextMenuToggleProps
  | OpenOnHoverToggleProps
)

type PopoverProps = {
  closePopover: () => void,
  onKeyDown: (e: KeyboardEvent) => void,
  ref: (ref: HTMLElement | null) => void,
  referenceElement?: HTMLElement | null,
  arrow: ReactNode,
  style: CSSProperties,
  tabIndex: number
}

type PopoverContainerProps = Omit<PopperProps, 'children'> & {
  children: [
    (popoverToggleProps: PopoverToggleProps) => ReactNode,
    (popoverProps: PopoverProps) => ReactNode
  ],
  onPopoverClose?: () => void,
  onPopoverOpen?: () => void,
  openOn?: 'click' | 'focus' | 'always' | 'contextMenu' | 'hover'
}

const ARROW_HEIGHT = 5
const ARROW_WIDTH = 12

const StyledArrow = styled('div', {
  size: [ 12, 5 ],
  position: 'absolute',
  zIndex: 'below',

  '[data-popper-placement="top"] > &': {
    bottom: -2
  },

  '[data-popper-placement="bottom"] > &': {
    top: -2
  },

  '[data-popper-placement="top-end"] > &': {
    bottom: -2
  },

  '[data-popper-placement="bottom-end"] > &': {
    top: -2
  },

  '[data-popper-placement="top-start"] > &': {
    bottom: -2
  },

  '[data-popper-placement="bottom-start"] > &': {
    top: -2
  },

  '&::before': {
    backgroundColor: 'light100',
    borderRadius: 1,
    content: "''",
    display: 'block',
    height: ARROW_WIDTH,
    overflow: 'hidden',
    position: 'absolute',
    width: ARROW_WIDTH,
    zIndex: 'below',
    top: -ARROW_HEIGHT + 1,
    transform: 'rotate(45deg)'
  }
})

function generateGetBoundingClientRect(mousePosition: ReturnType<typeof useMousePositionRef>['current']) {
  return () => ({
    width: 0,
    height: 0,
    top: mousePosition.y || 0,
    right: mousePosition.x || 0,
    bottom: mousePosition.y || 0,
    left: mousePosition.x || 0
  }) as DOMRect
}

function PopoverContainer({
  children,
  modifiers = [],
  onPopoverClose = () => null,
  onPopoverOpen = () => null,
  openOn = 'click',
  placement = openOn === 'contextMenu' ? 'right-start' : 'bottom',
  strategy = 'fixed'
}: PopoverContainerProps) {
  const [ isActive, setIsActive ] = useState(false)
  const [ referenceElement, setReferenceElement ] = useState<HTMLElement | null>(null)
  const [ popperElement, setPopperElement ] = useState<HTMLElement | null>(null)
  const [ arrowElement, setArrowElement ] = useState<HTMLDivElement | null>(null)

  const mousePositionRef = useMousePositionRef()

  const virtualReferenceElement = useMemo(() => ({} as HTMLElement), [])
  useEffect(() => {
    virtualReferenceElement.getBoundingClientRect = generateGetBoundingClientRect(
      mousePositionRef.current
    )
  }, [ mousePositionRef, isActive, virtualReferenceElement ])

  const resolvedReferenceElement = openOn === 'contextMenu' ? virtualReferenceElement : referenceElement

  const { styles, attributes } = usePopper(resolvedReferenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      ...modifiers
    ]
  })

  const onPopoverOpenRef = useRef(onPopoverOpen)
  const onPopoverCloseRef = useRef(onPopoverClose)

  useEffect(() => {
    onPopoverOpenRef.current = onPopoverOpen
    onPopoverCloseRef.current = onPopoverClose
  })

  useEffect(() => {
    if (isActive) {
      onPopoverOpenRef.current()
    } else {
      onPopoverCloseRef.current()
    }
  }, [ isActive ])

  const openPopover = () => {
    setIsActive(true)
  }

  const closePopover = () => {
    if (!isActive) {
      return
    }

    setIsActive(false)
  }

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    /*
     * Close dropdown when shifting focus between fields.
    */
    const relatedTargetNode = e.relatedTarget as Node | undefined
    const popperNode = popperElement as Node | null

    if (
      relatedTargetNode && !relatedTargetNode.contains(referenceElement!) && popperNode
      && !popperNode.contains(relatedTargetNode)
    ) {
      closePopover()
    }
  }

  const handleEscapeKey = (e: KeyboardEvent) => {
    const currentElement = e.currentTarget as HTMLElement
    const popperNode = popperElement as Node | null
    if (e.key === 'Escape') {
      e.stopPropagation()

      // If user hits Escape in popper, bring back focus the referenceElement
      if (popperNode && popperNode.contains(currentElement)) {
        referenceElement?.focus()
      }

      closePopover()
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isActive) {
      if (e.key === 'Enter' || e.key === ' ') {
        e.stopPropagation()
        openPopover()
      }
      return
    }
    handleEscapeKey(e)
  }

  const handleKeyDownWhileFocused = (e: KeyboardEvent) => {
    if (!isActive) return

    handleEscapeKey(e)

    if (e.key === 'Tab') {
      e.stopPropagation()
      closePopover()
    }
  }

  const togglePopover = isActive ? closePopover : openPopover

  const [ toggle, popover ] = children

  let toggleProps:
    | OpenOnClickToggleProps
    | OpenOnFocusToggleProps
    | OpenOnContextMenuToggleProps
    | OpenOnHoverToggleProps = {
      onBlur: handleBlur,
      onFocus: openPopover,
      onKeyDown: handleKeyDownWhileFocused
    }

  if (openOn === 'hover') {
    toggleProps = {
      onMouseEnter: () => {
        openPopover()
      },
      onMouseLeave: () => {
        closePopover()
      },
      onKeyDown: handleKeyDown,
      role: 'button',
      'aria-haspopup': 'true'
    }
  }

  if (openOn === 'click') {
    toggleProps = {
      onClick: (e) => {
        e.preventDefault()
        togglePopover()
      },
      onKeyDown: handleKeyDown,
      role: 'button',
      'aria-haspopup': 'true'
    }
  }

  if (openOn === 'contextMenu') {
    toggleProps = {
      onContextMenu: (e) => {
        e.preventDefault()
        togglePopover()
      },
      onKeyDown: handleKeyDown,
      role: 'button',
      'aria-haspopup': 'true',
      tabIndex: -1
    }
  }

  return (
    <>
      {toggle({
        isActive,
        ref: setReferenceElement,
        closePopover,
        openPopover,
        ...toggleProps
      })}
      <Portal>
        {isActive && (
          popover({
            closePopover,
            onKeyDown: handleKeyDownWhileFocused,
            tabIndex: -1,
            ref: setPopperElement,
            referenceElement,
            style: styles.popper,
            arrow: <StyledArrow ref={setArrowElement} style={styles.arrow} />,
            ...attributes.popper
          })
        )}
      </Portal>
    </>
  )
}

export default PopoverContainer

export type { PopoverToggleProps, OpenOnClickToggleProps }
