import React from 'react'

import * as constants from 'components/modal/constants'
import { styled } from 'styles/stitches'

type ModalBodyProps = React.PropsWithChildren<{
  wide?: boolean
}>

const VERTICAL_PADDING = 30

const StyledBody = styled('div', {
  height: '100%',
  overflowY: 'auto',
  paddingY: VERTICAL_PADDING,

  variants: {
    wide: {
      true: {
        paddingX: constants.SMALL_HORIZONTAL_PADDING
      },
      false: {
        paddingX: constants.LARGE_HORIZONTAL_PADDING
      }
    }
  }
})

const ModalBody = ({ children, wide = false }: ModalBodyProps) => (
  <StyledBody wide={wide}>
    {children}
  </StyledBody>
)

export default ModalBody
