import React from 'react'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import TextInput from 'components/inputs/TextInput'
import Text from 'components/typography/Text'
import type { Attribute } from 'generated/schema'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { symbol = '', fontWeight = 'regular' } = displaySettings

  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' })
  const formattedCurrency = formatter.format(data)

  const currencyString = (symbol && formattedCurrency) ? `${symbol} ${formattedCurrency}` : '-'

  return (
    <Text fontSize={14} fontWeight={fontWeight}>{currencyString}</Text>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField name={`${fieldPrefix}symbol`} label="Symbol" component={TextInput} size="small" />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as CurrencyRenderer }
