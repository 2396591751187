import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { klona } from 'klona'
import { TourGuideClient } from '@sjmc11/tourguidejs/dist/tour'
import type { ReactNode } from 'react'
import type { TourGuideStep } from '@sjmc11/tourguidejs/src/types/TourGuideStep'

import * as mixins from 'styles/mixins'
import zIndices from 'styles/primitives/zIndices'
import { colorVars } from 'styles/theme'
import { css } from 'styles/stitches'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import { AccountTourType, AccountTourStatus, CurrentAccountDocument, useEditAccountTourMutation } from 'generated/schema'

enum TourType {
  WELCOME_MEMBER_TOUR = 'WELCOME_MEMBER_TOUR',
  WELCOME_OWNER_TOUR = 'WELCOME_OWNER_TOUR',
  HELP_TOUR = 'HELP_TOUR'
}

type _TourName = TourType.WELCOME_MEMBER_TOUR | TourType.WELCOME_OWNER_TOUR | TourType.HELP_TOUR
type TourName = _TourName | null

type TourGuideContextType = {
  activeTour: TourName,
  startTour: (name: _TourName) => void,
  skipTour: (name: _TourName) => void,
  endTour: () => void,
  showNextStep: () => void
}

const TourGuideContext = createContext<TourGuideContextType | null>(null)

const classes = {
  backdrop: css({
    pointerEvents: 'none',
    zIndex: zIndices.popover,
    boxShadow: 'dark500 0px 0px 1px 2px, rgba(0, 0, 0, 0.2) 0px 0px 0px 1000vh !important'
  }),
  dialog: css({
    fontFamily: 'normal',
    pointerEvents: 'auto'
  }),
  nextButton: css({
    ...mixins.transition('fluid'),
    ...mixins.innerBorder(colorVars.dark500, 2),

    borderWidth: 0,
    height: 40,
    paddingX: 20,
    borderRadius: 4,
    cursor: 'pointer',
    fontFamily: 'normal',
    fontSize: '12',
    fontWeight: 'bold',
    lineHeight: 'normal',

    background: 'transparent',
    color: colorVars.dark500,

    '&:hover, &:focus': {
      ...mixins.innerBorder(colorVars.dark700, 2),
      color: colorVars.dark700
    }
  })
}

const TOUR_STEPS: Record<_TourName, (TourGuideStep & {hasNextButton: boolean})[]> = Object.freeze({
  [TourType.HELP_TOUR]: [
    {
      content: 'If you get stuck at any point, here’s the help menu. We’re a quick message away.',
      title: 'We’re here to help',
      target: '.tg--help-menu',
      order: 1,
      group: TourType.HELP_TOUR,
      hasNextButton: false
    }
  ],
  [TourType.WELCOME_OWNER_TOUR]: [
    {
      content: 'Use the Dashboard switcher to easily switch between your Dashboards.',
      title: 'Jump between Dashboards',
      target: '.tg--dashboard-switcher',
      order: 1,
      group: TourType.WELCOME_OWNER_TOUR,
      hasNextButton: false
    },
    {
      content: 'This is where you can manage your entire Workspace.',
      title: 'Select the Workspace Dashboard',
      target: '.tg--workspace-menu',
      order: 2,
      group: TourType.WELCOME_OWNER_TOUR,
      hasNextButton: false
    },
    {
      content: 'Create a Project for each website, mobile app, or backend server you wish to connect to DashX.',
      title: 'Set up your Projects',
      target: '.tg--projects',
      order: 3,
      group: TourType.WELCOME_OWNER_TOUR,
      hasNextButton: true
    },
    {
      content: 'Create an App for each website, mobile app, or backend server you wish to connect to DashX.',
      title: 'Set up your Apps',
      target: '.tg--apps',
      order: 4,
      group: TourType.WELCOME_OWNER_TOUR,
      hasNextButton: false
    }
  ],
  [TourType.WELCOME_MEMBER_TOUR]: [
    {
      content: 'Use the sidebar to switch between different apps in your Workspace.',
      title: 'Navigate your Workspace',
      target: '.tg--sidebar',
      order: 1,
      group: TourType.WELCOME_MEMBER_TOUR,
      hasNextButton: true
    },
    {
      content: 'Use the Dashboard switcher to easily switch between your Dashboards.',
      title: 'Jump between Dashboards',
      target: '.tg--dashboard-switcher',
      order: 2,
      group: TourType.WELCOME_MEMBER_TOUR,
      hasNextButton: true
    },
    {
      content: 'If you get stuck at any point, here’s the help menu. We’re a quick message away.',
      title: 'We’re here to help',
      target: '.tg--help-menu',
      order: 3,
      group: TourType.WELCOME_MEMBER_TOUR,
      hasNextButton: false
    }
  ]
})

const TOUR_GUIDE_CONFIG = Object.freeze({
  completeOnFinish: false,
  hideNext: false,
  hidePrev: true,
  rememberStep: true,
  progressBar: colorVars.primary400,
  dialogAnimate: true,
  dialogZ: zIndices.popover,
  showButtons: false,
  showStepDots: false,
  showStepProgress: false,
  debug: process.env.NODE_ENV !== 'production',
  exitOnClickOutside: false,
  steps: []
})

export default function TourProvider({ children }: { children: ReactNode }) {
  const currentAccount = useCurrentAccountContext()
  const currentAccountId = currentAccount?.id
  const [ activeTour, setActiveTour ] = useState<TourName>(null)
  const [ systemTour, setSystemTour ] = useState<AccountTourType | null>(null)
  const [ step, setStep ] = useState(0)
  const tourGuideRef = useRef<typeof TourGuideClient>()

  const [ editAccountTour ] = useEditAccountTourMutation({
    refetchQueries: [ { query: CurrentAccountDocument } ]
  })

  const updateTour = (status: AccountTourStatus) => {
    if (!currentAccountId || !systemTour) return null

    return editAccountTour({ variables: {
      input: {
        id: currentAccountId,
        status,
        step,
        tourName: systemTour
      }
    } })
  }

  const updateTourRef = useRef(updateTour)

  const createNextButton = () => {
    const nextButton = document.createElement('button')
    nextButton.className = 'tg-next-button'
    nextButton.classList.add(...classes.nextButton.split(' '))
    nextButton.innerHTML = 'Next'

    const dialogFooter = tourGuideRef.current.dialog.querySelector('.tg-dialog-footer')
    nextButton.addEventListener('click', () => {
      showNextStep()
      dialogFooter?.removeChild(nextButton)
    })
    dialogFooter?.appendChild(nextButton)
  }

  const createNextButtonRef = useRef(createNextButton)

  useEffect(() => {
    updateTourRef.current = updateTour
    createNextButtonRef.current = createNextButton
  })

  const onAfterStepChange = useCallback(() => {
    if (tourGuideRef.current.group === TourType.HELP_TOUR) return updateTourRef.current('SKIPPED')?.finally(() => setActiveTour(null))

    const { activeStep } = tourGuideRef.current
    const stepsLength = TOUR_STEPS[tourGuideRef.current.group as _TourName].length

    if (activeStep < stepsLength) {
      return updateTourRef.current('PENDING')
    }

    if (activeStep === stepsLength + 1) {
      return updateTourRef.current('COMPLETED')
    }
    return null
  }, [])

  const startTour = useCallback((name: _TourName) => {
    setStep(0)
    setSystemTour(name as AccountTourType)
    setActiveTour(name)
  }, [])

  const skipTour = useCallback((name: _TourName) => {
    setStep(0)
    setSystemTour(name as AccountTourType)
    setActiveTour(TourType.HELP_TOUR)
  }, [])

  const endTour = useCallback(() => {
    if (!activeTour) return

    tourGuideRef.current.exit()
    setActiveTour(null)
  }, [ activeTour ])

  const showNextStep = useCallback(() => {
    if (!activeTour) return

    setStep((currStepNumber) => {
      const nextStep = klona(TOUR_STEPS[activeTour][currStepNumber + 1])
      const { activeStep } = tourGuideRef.current

      if (!nextStep) {
        tourGuideRef.current.exit()
        setActiveTour(null)
        return currStepNumber
      }

      const { hasNextButton } = nextStep

      tourGuideRef.current?.addSteps([ nextStep ])
      tourGuideRef.current?.nextStep().then(() => {
        if (hasNextButton) {
          createNextButtonRef.current()
        }
        // remvoe pointer events from previous step & add to current step
        // @ts-ignore
        tourGuideRef.current.tourSteps[activeStep].target.style.pointerEvents = null
        tourGuideRef.current.tourSteps[activeStep + 1].target.style.pointerEvents = 'auto'
        onAfterStepChange()
      })
      return currStepNumber + 1
    })
  }, [ activeTour, onAfterStepChange ])

  useEffect(() => {
    const onBeforeExit = () => {
      if (tourGuideRef.current.group === TourType.HELP_TOUR) return updateTourRef.current('SKIPPED')?.finally(() => setActiveTour(null))

      const { activeStep } = tourGuideRef.current
      const stepsLength = TOUR_STEPS[tourGuideRef.current.group as _TourName].length

      if (activeStep < stepsLength - 1) {
        return updateTourRef.current('SKIPPED')?.finally(() => setActiveTour(null))
      }
      if (activeStep === stepsLength - 1) {
        return updateTourRef.current('COMPLETED')?.finally(() => setActiveTour(null))
      }
      return null
    }

    const onOutsideClick = (e: PointerEvent) => {
      const isTarget = tourGuideRef.current.tourSteps.some(
        (step: TourGuideStep) => (step.target as HTMLElement)?.contains?.(e.target as HTMLElement)
      )

      const isDialog = tourGuideRef.current.dialog?.contains(e.target as HTMLElement)
      const isNextButton = (e.target as HTMLElement).classList.contains('tg-next-button')

      if (isTarget || isDialog || isNextButton) return

      tourGuideRef.current.exit()
      setActiveTour(null)
    }

    const setupTourGuide = (config: typeof TOUR_GUIDE_CONFIG, activeTour: _TourName) => {
      const tourGuideClient = new TourGuideClient(klona(config))
      tourGuideRef.current = tourGuideClient

      // Add default classes
      tourGuideRef.current.backdrop?.classList.add(...classes.backdrop.split(' '))
      tourGuideRef.current.dialog?.classList.add(...classes.dialog.split(' '))

      const { hasNextButton, ...firstStep } = klona(TOUR_STEPS[activeTour][0])

      tourGuideRef.current.addSteps([ firstStep ])
      tourGuideRef.current.start(activeTour).then(() => {
        const { activeStep } = tourGuideRef.current

        if (hasNextButton) {
          createNextButtonRef.current()
        }

        tourGuideRef.current.tourSteps[activeStep].target.style.pointerEvents = 'auto'
        tourGuideRef.current.dialog.style.pointerEvents = 'auto'

        document.body.style.pointerEvents = 'none'
        document.addEventListener('click', onOutsideClick as any)

        if (tourGuideRef.current.group === TourType.HELP_TOUR) return updateTourRef.current('SKIPPED')

        return updateTourRef.current('PENDING')
      })

      tourGuideRef.current.onBeforeExit(onBeforeExit)
      tourGuideRef.current.onFinish(() => setActiveTour(null))
    }

    if (activeTour) {
      setupTourGuide(TOUR_GUIDE_CONFIG, activeTour)
    } else {
      // @ts-ignore
      document.body.style.pointerEvents = null
      tourGuideRef.current?.dialog.remove()
      tourGuideRef.current?.backdrop.remove()
    }
    return () => {
      document.removeEventListener('click', onOutsideClick as any)
    }
  }, [ activeTour, showNextStep ])

  const value = useMemo(() => ({
    activeTour,
    endTour,
    showNextStep,
    startTour,
    skipTour
  }), [ activeTour, endTour, showNextStep, startTour, skipTour ])

  return (
    <TourGuideContext.Provider value={value}>
      {children}
    </TourGuideContext.Provider>
  )
}

export { TourGuideContext, TourType }
