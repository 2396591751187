import React from 'react'
import type { PropsWithChildren } from 'react'

import Text from 'components/typography/Text'

function FormTitle({ children }: PropsWithChildren<{}>) {
  return (
    <Text fontSize={18} fontWeight="bold">
      {children}
    </Text>
  )
}

export default FormTitle
