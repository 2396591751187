import React from 'react'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import type { FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import Group from 'models/Group'
import TextInput from 'components/inputs/TextInput'
import type { ViewStyleComponentRenderProps } from 'components/views'

type GroupFormProps = FormProps<any> & {
  viewComponents: ViewStyleComponentRenderProps,
  onRequestClose: () => void
}

function GroupForm({ initialValues, viewComponents, onRequestClose, ...rest }: GroupFormProps) {
  const { Body, Footer } = viewComponents
  const isUpdate = 'id' in initialValues

  return (
    <Form
      initialValues={initialValues}
      validate={(values) => Group.validate(values, [ 'name', 'accountKind' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine, form: { reset } }) => {
        const onSubmit = (values: any) => handleSubmit(values)?.then((response) => {
          if (response && !response[FORM_ERROR]) {
            onRequestClose()
          }
        })

        const onSubmitAndReset = (values: any) => handleSubmit(values)?.then((() => {
          reset(initialValues)
        }))

        return (
          <form onSubmit={onSubmit}>
            <Body>
              <Flex gap={16} direction="column">
                <Field component="input" name="environmentId" type="hidden" />
                <Field component="input" name="accountKind" type="hidden" />

                <Field
                  autoFocus
                  component={TextInput}
                  size="small"
                  name="name"
                  label="Name"
                  type="text"
                />
              </Flex>
            </Body>
            <Footer>
              {!isUpdate && (
                <Button
                  disabled={submitting || pristine}
                  label="Save & Add Another"
                  onClick={onSubmitAndReset}
                  type="button"
                  variant="outline"
                />
              )}
              <Button type="submit" label="Save" disabled={submitting || pristine} />
            </Footer>
          </form>
        )
      }}
      {...rest}
    />
  )
}

export default GroupForm
