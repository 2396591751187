import React from 'react'
import { Form, Field } from 'react-final-form'
import type { FormProps } from 'react-final-form'

import Account from 'models/Account'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import type { ChangePasswordInput } from 'generated/schema'

export default function ChangePasswordForm(
  { initialValues, ...other }: FormProps<ChangePasswordInput>
) {
  return (
    <Form
      initialValues={initialValues}
      validate={(values) => Account.validate(values, [ 'currentPassword', 'password', 'passwordConfirmation' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ form: { reset }, handleSubmit, submitting, pristine }) => {
        const onSubmit = (values: any) => handleSubmit(values)?.then((() => {
          reset(initialValues)
        }))

        return (
          <form onSubmit={onSubmit}>
            <Flex gap={36}>
              <Field name="currentPassword" component={TextInput} type="password" autoComplete="current-password" placeholder="Current password" />
              <Field name="password" component={TextInput} type="password" autoComplete="new-password" placeholder="New password" withStrengthIndicator />
              <Field name="passwordConfirmation" component={TextInput} type="password" autoComplete="new-password" placeholder="Confirm password" />
              <Button type="submit" label="Update password" disabled={submitting || pristine} />
            </Flex>
          </form>
        )
      }}
      {...other}
    />
  )
}
