import classNames from 'classnames'
import React, { useRef } from 'react'
import SVG from 'react-inlinesvg'
import type { HTMLAttributes } from 'react'
import type { IStyledComponent } from '@stitches/react'

import theme from 'styles/theme'
import { generateThemeVariants, styled } from 'styles/stitches'
import type { IconSize, Color } from 'styles/theme'

const reqIcons = require.context('assets/icons', true, /\.svg$/)

type IconProps = HTMLAttributes<HTMLElement> & {
  name: string,
  color?: Color,
  size?: IconSize,
  height?: IconSize,
  width?: IconSize,
  reqContext?: __WebpackModuleApi.RequireContext
}

const colorVariants = generateThemeVariants('colors', 'color')

const IconContainer = styled('i', {
  display: 'inline-block',
  variants: {
    color: colorVariants
  }
})

const Icon = React.forwardRef<HTMLElement, IconProps>(
  ({
    css = {},
    name,
    className,
    color,
    size = 16,
    height = size,
    width = size,
    reqContext = reqIcons,
    ...other
  }, ref) => {
    const outerRef = useRef<HTMLElement>(null)
    const renderSVG = () => {
      try {
        return (
          <SVG
            innerRef={ref}
            cacheRequests
            style={{
              display: 'block',
              height: theme.iconography.sizes[height],
              width: theme.iconography.sizes[width]
            }}
            src={reqContext(`./${name}.svg`).default}
          />
        )
      } catch (e) {
        return null
      }
    }

    return (
      <IconContainer
        ref={outerRef}
        className={classNames('icon', className)}
        color={color}
        css={{
          ...(css as any),
          '&&&': {
            color,
            height: theme.iconography.sizes[height],
            width: theme.iconography.sizes[width]
          }
        }}
        {...other}
      >
        {renderSVG()}
      </IconContainer>
    )
  }
)

Icon.displayName = 'Icon'
Icon.toString = () => '.icon'

export const withPreload = (
  Component: typeof Icon | IStyledComponent<typeof Icon, any, any>,
  name: string,
  reqContext = reqIcons
) => {
  try {
    // @ts-ignore
    new SVG({ src: reqContext(`./${name}.svg`).default, cacheRequests: true }).request()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  return React.forwardRef(({ ...props }: Omit<IconProps, 'name' | 'reqContext'>, ref: any) => (
    <Component
      ref={ref}
      name={name}
      reqContext={reqContext}
      {...props as any}
    />
  ))
}

export default Icon

export type { IconProps }
