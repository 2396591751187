import Prism from 'prismjs'
import { CodeJar } from 'codejar'
import { useEffect, useRef } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import usePrism from 'hooks/usePrism'
import type { Language } from 'hooks/usePrism'

declare type Options = {
  tabSize?: number,
  indentOn?: RegExp,
  spellcheck?: boolean,
  addClosing?: boolean
}

type CodeJarProps = {
  input?: FieldRenderProps<string, HTMLPreElement>['input'],
  language: Language,
  theme?: 'light'| 'dark',
  options?: Options
}

function useCodeJar({ language, theme = 'dark', input, options }: CodeJarProps) {
  const currentLanguageRef = useRef(language)
  const editorRef = useRef<HTMLPreElement>(null)
  const codeJarRef = useRef<CodeJar | null>(null)

  const { languageLoading, loadedLanguages } = usePrism(language, theme)

  const inputValue = input?.value

  useEffect(() => {
    const highlight = (editor: HTMLElement) => {
      if (languageLoading) return

      const code = editor.textContent || ''
      editor.innerHTML = Prism.highlight(code, Prism.languages[language], language)
    }

    const onUpdate = (code: string) => {
      input?.onChange(code)
    }

    if (!editorRef.current) return

    const isLoaded = loadedLanguages.includes(language)
    if (isLoaded && !codeJarRef.current) {
      codeJarRef.current = CodeJar(
        editorRef.current,
        highlight,
        {
          ...options,
          tab: ' '.repeat(options?.tabSize || 2)
        }
      )

      codeJarRef.current.onUpdate(onUpdate)
      codeJarRef.current.updateCode(inputValue || '')
      currentLanguageRef.current = language
    }
  }, [ input, inputValue, language, languageLoading, loadedLanguages, options ])

  useEffect(() => {
    if (currentLanguageRef.current !== language && codeJarRef.current) {
      codeJarRef.current?.destroy()
      codeJarRef.current = null
    }
  }, [ language ])

  useEffect(() => {
    // If externally input.value is modified (ex - during reset), codejar input
    // needs to be explicitly updated
    if (codeJarRef?.current?.toString() !== inputValue) {
      // for reset we need to explicitly update codejar input to ''
      codeJarRef?.current?.updateCode(inputValue || '')
    }
  }, [ inputValue ])

  return {
    editorRef,
    codeJarRef
  }
}

export default useCodeJar
