import React from 'react'

import * as mixins from 'styles/mixins'
import CheckboxInput from 'components/inputs/CheckboxInput'
import Chip from 'components/chip/Chip'
import DragHandle from 'components/dataTable/DragHandle'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { useDataManagerContext } from 'hooks/useDataManagerContext'
import { css, styled } from 'styles/stitches'
import { DATA_TABLE_CELL_PADDING } from 'components/dataTable/constants'
import type { Column } from 'components/dataTable/types'

type TableHeaderProps = {
  columns: Column[]
}

const TableHeaderCell = styled(Flex, {
  ...mixins.transition('fastIn'),
  flex: '1 1 0',
  width: 0,
  padding: DATA_TABLE_CELL_PADDING,
  userSelect: 'none',
  position: 'relative',

  '& > span': {
    display: 'inline-block',
    verticalAlign: 'middle'
  },

  '& [data-sort-icon]': {
    ...mixins.transition('fastIn'),

    color: 'dark100',
    cursor: 'pointer',
    marginLeft: 10
  },

  '& [data-sort-icon][data-sort-active="true"]': {
    color: 'dark500'
  },

  ':has([data-sort-icon])': {
    '&:hover': {
      '& > [data-sort-icon]': {
        color: 'dark700'
      },
      '& > span': {
        color: 'dark700'
      }
    }
  }
})

const selectableWrapper = css({
  cursor: 'pointer',
  padding: DATA_TABLE_CELL_PADDING
})

function HeaderCell({ dataKey, sortable, title, style }: Column<any>) {
  const {
    sortBy = [],
    sortDirections,
    toggleSortDirection
  } = useDataManagerContext()

  const index = sortBy.findIndex((column) => column === dataKey)
  const isSortActive = index !== -1

  function renderSortingIcon() {
    if (!sortable) {
      return null
    }

    const iconNameMap = {
      asc: 'sort-up',
      desc: 'sort-down',
      none: 'sort-default'
    }

    const sortDirection = sortDirections[index]
    const iconName = isSortActive ? iconNameMap[sortDirection] : 'sort-default'

    return (
      <Icon
        key="icon"
        data-sort-icon
        name={iconName}
        data-sort-active={isSortActive}
        size={10}
      />
    )
  }

  return (
    <TableHeaderCell
      alignItems="center"
      key={`header-${dataKey}`}
      style={style}
      onClick={() => {
        if (!sortable) {
          return
        }

        toggleSortDirection(dataKey)
      }}
    >
      {isSortActive && sortBy.length > 1 && (
        <Chip
          fontSize={10}
          label={index + 1}
          size="small"
          css={{
            position: 'absolute',
            left: 0
          }}
        />
      )}
      <Text
        as="span"
        color="dark500"
        fontSize={12}
        textTransform="uppercase"
        truncate
        title={title}
      >
        {title}
      </Text>
      {renderSortingIcon()}
    </TableHeaderCell>
  )
}

export default function TableHeader({ columns }: TableHeaderProps) {
  const {
    data,
    deselectAllRows,
    selectionMode,
    selectAllRows,
    selection,
    isDraggable
  } = useDataManagerContext()

  function renderCheckBox() {
    const areAllRowsSelected = (
      data.length > 0
      && data.every((datum) => selection.indexOf(datum?.id) !== -1)
    )

    const handleChange = (e: React.FormEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()

      if (!areAllRowsSelected) {
        selectAllRows()
      } else {
        deselectAllRows()
      }
    }

    return (
      <div key="checkbox" role="presentation" onClick={handleChange} className={selectableWrapper}>
        <CheckboxInput
          input={{
            checked: areAllRowsSelected,
            onChange: handleChange
          }}
        />
      </div>
    )
  }

  return (
    <Flex
      alignItems="center"
      basis={0}
    >
      {isDraggable && <DragHandle key="drag-handle" hidden />}
      {selectionMode === 'multiple' && renderCheckBox()}
      {columns
        .filter((column) => !column.hidden)
        .map((column) => (
          <HeaderCell {...column} key={column.dataKey} />
        ))}
    </Flex>
  )
}
