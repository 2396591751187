import BaseModel from 'models/BaseModel'
import { PushSubChannel } from 'models/Channel'

enum Format {
  MJML = 'mjml',
  TEXT = 'text'
}

enum DisplaySelector {
  NORMAL = 'normal',
  PREVIEW = 'preview'
}

enum Mode {
  CODE = 'code',
  DESIGN = 'design',
  PLAIN = 'plain'
}

enum Priority {
  NORMAL = 'NORMAL',
  HIGH = 'HIGH'
}

type SubChannelListItem = {
  name: PushSubChannel,
  label: string,
  description: string,
  icon: string
}

type Content = {
  from: string,
  subject: string,
  title?: string,
  preview: string,
  body?: string,
  htmlBody: string,
  plainBody: string,
  replyTo: string,
  imageData: Record<string, any>,
  data: any,
  androidSettings?: {
    title: string,
    body: string,
    data: any
  },
  iosSettings?: {
    title: string,
    subtitle: string,
    body: string,
    data: any
  },
  webSettings?: {
    title: string,
    body: string,
    data: any
  }
}

type Push = {
  subChannelsList: readonly SubChannelListItem[],
  priorityOptions: Array<{
    label: string,
    value: Priority
  }>
}

type Email = {
  formats: Array<{
    label: string,
    value: Format
  }>,
  modes: Array<{
    label: string,
    value: Mode
  }>,
  defaultMJMLMarkup: string
}

class ContentEditor extends BaseModel {
  static push: Push = {
    subChannelsList: Object.freeze([
      { name: PushSubChannel.IOS, label: 'iOS', description: 'Includes iPhone, iPad, Apple Watch, Apple TV', icon: 'apple' },
      { name: PushSubChannel.ANDROID, label: 'Android', description: 'Includes Android phones, tablets', icon: 'android' },
      { name: PushSubChannel.WEB, label: 'Web', description: 'Includes desktop notifications for all web browsers', icon: 'widget' }
    ]),
    priorityOptions: [
      { label: 'Normal', value: Priority.NORMAL },
      { label: 'High', value: Priority.HIGH }
    ]
  }

  static email: Email = {
    formats: [
      { label: 'MJML', value: Format.MJML },
      { label: 'Text', value: Format.TEXT }
    ],
    modes: [
      { label: 'Design', value: Mode.DESIGN },
      { label: 'Code', value: Mode.CODE },
      { label: 'Plain', value: Mode.PLAIN }
    ],
    defaultMJMLMarkup: '<mjml><mj-body><mj-section><mj-column><mj-text>Enter your content here...</mj-text></mj-column></mj-section></mj-body></mjml>'
  }
}

export {
  Format,
  DisplaySelector,
  Mode
}

export type {
  Content
}

export default ContentEditor
