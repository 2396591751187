import React, { Fragment, memo, useCallback, useContext, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { createPortal } from 'react-dom'
import { DragOverlay } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import GenericMenuElement from 'components/menuelements/GenericMenuElement'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import MenuElementPositionProvider, { useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import Text from 'components/typography/Text'
import TopbarMenuItem from 'components/topbar/TopbarMenuItem'
import useDashboard from 'hooks/useDashboard'
import { css } from 'styles/stitches'
import { Popover, PopoverBody, PopoverDivider, PopoverFooter, PopoverHeader } from 'components/popover'
import { Views } from 'components/dashboardEditor/constants'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PopoverProps } from 'components/popover'
import type { Space } from 'styles/theme'

const ADD_MENU_ITEM_SPACING_X: Space = 24
const ADD_MENU_ITEM_SPACING_Y: Space = 18

type TopbarMenuProps = PopoverProps & {
  isActive: boolean,
  rootMenuElement: ComputedMenuElement
}

type AddMenuItemProps = {
  parentId?: string,
  closePopover?: () => void
}

type DraggableMenuElementsProps = {
  menuElements: ComputedMenuElement[],
  onMenuElementClick: (menuElement: ComputedMenuElement) => void
}

const TOP_MENU_WIDTH = 210

const classes = {
  menu: css({
    minWidth: TOP_MENU_WIDTH
  }),
  addMenuItem: (draggingOver = false) => css({
    ...mixins.transition('simple'),

    paddingY: ADD_MENU_ITEM_SPACING_Y,
    paddingX: ADD_MENU_ITEM_SPACING_X,

    '& [data-icon], & [data-text]': {
      ...mixins.transition('fastIn'),

      color: 'dark500'
    },

    '&:hover [data-icon], &:hover [data-text]': {
      color: 'dark700'
    },

    ...(draggingOver && {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        inset: 8,
        border: '2px dashed dark700',
        borderRadius: 4
      }
    })
  }),
  placeholder: css({
    flexGrow: 1,
    height: 'inherit',
    paddingY: 10,
    paddingX: 15,
    position: 'relative',
    zIndex: 'below',

    '&::before': {
      borderColor: 'light700',
      borderRadius: 4,
      borderStyle: 'dashed',
      borderWidth: 2,
      content: "''",
      height: 'calc(100% - 20px)',
      position: 'absolute',
      width: 'calc(100% - 30px)'
    }
  })
}

const AddMenuItem = ({ parentId, closePopover }: AddMenuItemProps) => {
  const { currentDashboard } = useContext(InternalContext)!
  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}

  const { openDashboardEditor } = useContext(DashboardContext)!
  const { openDashboardEditorView } = useDashboard()

  const initialValues = { dashboardId, parentId, placement: 'TOP', newNonStickyPosition, newStickyPosition }
  const handleClick = () => {
    openDashboardEditorView({
      target: Views.ADD_MENU,
      params: { initialValues }
    })
    openDashboardEditor()
    closePopover?.()
  }

  const {
    draggedMenuState,
    draggedAppState,
    draggedResourceState,
    draggingOverMenuIdState
  } = useDashboard()

  const draggedMenu = useRecoilValue(draggedMenuState)
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const draggedApp = useRecoilValue(draggedAppState)
  const draggedResource = useRecoilValue(draggedResourceState)

  const isMenuDragStarted = !!draggedMenu && !draggedMenu.id && !draggingOverMenuId
  const isAppDragStarted = !!draggedApp && !draggedApp.id && !draggingOverMenuId
  const isResourceDragStarted = !!draggedResource
    && !draggedResource.id && !draggingOverMenuId

  const isDragStarted = isMenuDragStarted || isAppDragStarted || isResourceDragStarted

  const isDraggingOverAddMenu = draggingOverMenuId === 'ADD_MENU_TOP_SUBMENU'

  const { setNodeRef } = useSortable({
    id: 'ADD_MENU_TOP_SUBMENU',
    data: {
      placement: 'TOP',
      parentId,
      ...(draggedMenu ? { isSticky: false } : {})
    }
  })

  return (
    <TopbarMenuItem
      onClick={handleClick}
      justifyContent="flex-start"
      className={classes.addMenuItem(isDragStarted || isDraggingOverAddMenu)}
      ref={setNodeRef}
    >
      <Icon data-icon name="add-outline-round" size={16} />
      <Text data-text fontSize={12} fontWeight="bold">Add Menu Item</Text>
    </TopbarMenuItem>
  )
}

const Dropzone = ({ id, index, length }: any) => {
  const { idToMenuElementMap } = useContext(InternalContext)!
  const { draggingOverMenuIdState } = useDashboard()
  const draggingOverMenuId = useRecoilValue(draggingOverMenuIdState)
  const { draggedMenuState, draggedAppState, draggedResourceState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)
  const [ draggedApp ] = useRecoilState(draggedAppState)
  const [ draggedResource ] = useRecoilState(draggedResourceState)

  const draggedElement = draggedMenu || draggedApp || draggedResource
  const draggingOverMenuElement = draggingOverMenuId && idToMenuElementMap[draggingOverMenuId]

  const isDraggingAccrossDroppables = draggedMenu?.parentId !== draggingOverMenuElement?.parentId
  || draggedMenu?.placement !== draggingOverMenuElement?.placement

  if (id === 'PREPEND_TOPBAR_SUBMENU') {
    if (!draggedElement || ('id' in draggedElement && !isDraggingAccrossDroppables)) {
      return null
    }
  }

  const isAddingMenuElement = draggedMenu && !draggedMenu.id
  const isDraggingBeforeLastMenuElement = index < length - 1
  const isAddingApp = draggedApp?.id
  const isDraggingResource = draggedResource?.id

  if (!(
    (isAddingMenuElement && isDraggingBeforeLastMenuElement)
    || (draggedMenu?.id && isDraggingAccrossDroppables)
    || isAddingApp
    || isDraggingResource
  )) {
    return null
  }

  return (
    <Flex
      alignItems="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: 32,
        width: '100%',
        marginTop: -16,
        marginBottom: -16,
        opacity: draggingOverMenuId === id ? 1 : 0,
        zIndex: 1,

        '&:hover': {
          opacity: draggingOverMenuId ? undefined : 1
        }
      }}
    >
      <Flex
        css={{
          width: '100%',
          borderBottom: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 6,
            backgroundColor: 'light100',
            overflow: 'hidden'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

const DraggableMenuElements = memo(({
  menuElements,
  onMenuElementClick
}: DraggableMenuElementsProps) => (
  <SortableContext
    id="TOPBAR_MENU_SORTABLE"
    items={menuElements.map((menuElement) => menuElement.id)}
    strategy={verticalListSortingStrategy}
  >
    {menuElements.map((menuElement, index, { length }) => (
      <Fragment key={menuElement.id}>
        <GenericMenuElement
          key={menuElement.id}
          menuElement={menuElement}
          onMenuElementClick={onMenuElementClick}
        />
        <Dropzone id={menuElement.id} index={index} length={length} />
      </Fragment>
    ))}
    <Overlay />
  </SortableContext>
))

const Overlay = () => {
  const { draggedMenuState } = useDashboard()
  const [ draggedMenu ] = useRecoilState(draggedMenuState)

  return createPortal(
    <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
      {draggedMenu?.id ? (
        <GenericMenuElement menuElement={draggedMenu} isDragging />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

const TopbarMenu = React.forwardRef<HTMLDivElement, TopbarMenuProps>(({
  isActive,
  rootMenuElement,
  ...props
}, ref) => {
  const { idToMenuElementMap, parentIdToMenuElementsMap } = useContext(InternalContext)!
  const { selectedTopMenuElement } = useContext(DashboardContext)!

  const initialParentMenuElement = isActive
    ? idToMenuElementMap[selectedTopMenuElement?.parentId] || rootMenuElement
    : rootMenuElement

  const [
    parentMenuElement,
    setParentMenuElement
  ] = useState<ComputedMenuElement>(initialParentMenuElement)

  const menuElements = useMemo(
    () => parentIdToMenuElementsMap[parentMenuElement?.id] || [],
    [ parentIdToMenuElementsMap, parentMenuElement ]
  )

  const primaryMenuElements = useMemo(
    () => (
      menuElements.filter(
        (element) => element.parentId === parentMenuElement?.id
      )
    ),
    [ menuElements, parentMenuElement ]
  )

  const [ bodyMenuElements, footerMenuElements ] = useMemo(
    () => {
      const bodyElements: ComputedMenuElement[] = []
      const footerElements: ComputedMenuElement[] = []

      primaryMenuElements.forEach((element) => (
        element.isSticky ? footerElements.push(element) : bodyElements.push(element)
      ))

      return [ bodyElements, footerElements ]
    },
    [ primaryMenuElements ]
  )

  const switchToParentMenu = useCallback(
    () => {
      let parent = rootMenuElement

      if (parentMenuElement.parentId) {
        parent = idToMenuElementMap[parentMenuElement.parentId]
      }

      setParentMenuElement(parent)
    },
    [ idToMenuElementMap, parentMenuElement, rootMenuElement, setParentMenuElement ]
  )

  const canGoBack = parentMenuElement?.id !== rootMenuElement?.id

  const { draggedMenuState, draggedAppState, draggedResourceState } = useDashboard()
  const draggedMenu = useRecoilValue(draggedMenuState)
  const draggedApp = useRecoilValue(draggedAppState)
  const draggedResource = useRecoilValue(draggedResourceState)
  const draggedElementId = draggedMenu?.id || draggedApp?.id || draggedResource?.id

  const { setNodeRef } = useSortable({
    id: 'PREPEND_TOPBAR_SUBMENU',
    data: {
      placement: 'TOP',
      parentId: parentMenuElement?.id,
      ...(draggedMenu ? { isSticky: false } : {})
    }
  })

  return (
    <Popover className={classes.menu} ref={ref} {...props}>
      <PopoverHeader
        ref={setNodeRef}
        onBackClick={switchToParentMenu}
        showBack={canGoBack}
        title={parentMenuElement?.renderedName || ''}
      />
      <Dropzone id="PREPEND_TOPBAR_SUBMENU" />
      <MenuElementPositionProvider
        stickyElements={footerMenuElements}
        nonStickyElements={bodyMenuElements}
      >
        <PopoverBody>

          <Flex
            direction="column"
            grow={1}
            style={{
              cursor: MenuElement.isDescendantOf(draggedElementId, parentMenuElement) ? 'no-drop' : undefined
            }}
          >
            <DraggableMenuElements
              menuElements={bodyMenuElements}
              onMenuElementClick={setParentMenuElement}
            />

            <AddMenuItem closePopover={props.closePopover} parentId={parentMenuElement?.id} />
          </Flex>

          <div>
            <PopoverFooter withDivider={!!footerMenuElements.length} direction="column">
              <DraggableMenuElements
                menuElements={footerMenuElements}
                onMenuElementClick={setParentMenuElement}
              />
              <PopoverDivider variant="whitespace" />
            </PopoverFooter>
          </div>

        </PopoverBody>
      </MenuElementPositionProvider>
    </Popover>
  )
})

TopbarMenu.displayName = 'TopbarMenu'

DraggableMenuElements.displayName = 'DraggableMenuElements'

export default TopbarMenu

export { TOP_MENU_WIDTH }
