import React from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import type { IconProps } from 'components/icons/Icon'

type IconTextButtonProps = IconProps & {
  disabled?: boolean,
  label: string
}

const StyledIconTextButton = styled(Flex, {
  color: 'dark500',
  cursor: 'pointer',

  [`& ${Icon}`]: {
    ...mixins.transition('fluid'),
    color: 'dark100'
  },

  [`& ${Text}`]: {
    ...mixins.transition('fluid'),
    color: 'dark500'
  },

  '&[disabled]': {
    cursor: 'not-allowed',

    [`& ${Icon}`]: {
      color: rgba(colorVars.dark100rgb, 0.4)
    },

    [`& ${Text}`]: {
      color: rgba(colorVars.dark500rgb, 0.3)
    }
  },

  '&:hover': {
    [`& ${Icon}`]: {
      color: 'dark700'
    },
    [`& ${Text}`]: {
      color: 'dark900'
    }
  },

  '&:focus': {
    [`& ${Icon}`]: {
      color: 'dark700'
    },
    [`& ${Text}`]: {
      color: 'dark900'
    }
  }
})

const IconTextButton = React.forwardRef<HTMLElement, IconTextButtonProps>(
  ({ className, disabled = false, label, name, size, css, ...others }, ref) => (
    <StyledIconTextButton
      alignItems="center"
      as="button"
      className={className}
      disabled={disabled}
      gap={10}
      type="button"
      {...others}
    >
      <Icon
        ref={ref}
        name={name}
        size={size}
      />
      <Text fontSize={12} fontWeight="bold">{label}</Text>
    </StyledIconTextButton>
  )
)

IconTextButton.displayName = 'IconTextButton'

export default IconTextButton
