import React from 'react'

import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import type { Attribute } from 'generated/schema'

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ data }: RendererProps) => {
  const { name, url, mime_type: mimeType, size } = data || {}

  const formattedSize = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow'
  }).format(size)

  return data ? (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex direction="column">
        <Text fontWeight="bold" fontSize={14}>{name}</Text>
        <Text fontSize={12} color="dark400">{[ mimeType, formattedSize ].filter(Boolean).join(' • ')}</Text>
      </Flex>
      <TextLink fontSize={12} variant="underlined" href={url} download>
        Download
      </TextLink>
    </Flex>
  ) : <>-</>
}

export { Renderer as FileRenderer }
