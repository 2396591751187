import React from 'react'
import ReactMarkdown from 'react-markdown'

import Box, { BoxProps } from 'components/layout/Box'
import { Heading, Link, List, ListItem, Paragraph, Table } from 'components/markdown'

type MarkdownRendererProps = BoxProps & {
  source: string
}

function MarkdownRenderer({ source, ...other }: MarkdownRendererProps) {
  return (
    <Box {...other}>
      <ReactMarkdown
        source={source}
        renderers={{
          heading: Heading,
          link: Link,
          list: List,
          listItem: ListItem,
          paragraph: Paragraph,
          table: Table
        }}
      />
    </Box>
  )
}

export default MarkdownRenderer
