import React, { useContext, useState } from 'react'
import startCase from 'lodash/startCase'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator } from 'final-form'

import App, { AppPlatform, AppTechnology as AppTechnologyEnum, APP_LIST_LIMIT } from 'models/App'
import Button from 'components/buttons/Button'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import HintBox from 'components/hints/HintBox'
import Icon from 'components/icons/Icon'
import IconInput from 'components/inputs/IconInput'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { AppListDocument, AppTechnology, CreateAppInput, CreateAppMutation, useCreateAppMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { SidePaneFooter, SidePaneSubHeader } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type FormValues = CreateAppInput

type Params = ViewParams[Views.CREATE_PROJECT]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const stepSubTitles = [
  'Step 1: Select your platform',
  'Step 2: Select your technology',
  'Step 3: Enter details'
]

const platformTypes = [
  {
    name: AppPlatform.BROWSER,
    icon: 'globe',
    description: 'Website, Blog or Portal'
  }, {
    name: AppPlatform.MOBILE,
    icon: 'phone',
    description: 'Native iOS/Android, React Native, Flutter'
  }, {
    name: AppPlatform.SERVER,
    icon: 'app-database-hub',
    description: 'Node.js, Python, PHP, Ruby, Rust, Go'
  }
]

const technologyTypes = {
  [AppPlatform.BROWSER]: [
    {
      name: 'React.js',
      description: 'Single page application written in React.js',
      identifier: AppTechnologyEnum.REACT,
      icon: 'react-colored'
    }, {
      name: 'Angular.js',
      description: 'Single page application written in Angular.js',
      identifier: AppTechnologyEnum.ANGULAR,
      icon: 'angular-colored'
    }, {
      name: 'Vue.js',
      description: 'Single page application written in Vue.js',
      identifier: AppTechnologyEnum.VUE,
      icon: 'vue-colored'
    }, {
      name: 'Remix',
      description: 'Single page application written in Remix.js',
      identifier: AppTechnologyEnum.REMIX,
      icon: 'remix-colored'
    }, {
      name: 'Other Framework',
      description: 'Any JavaScript / TypeScript framework',
      identifier: AppTechnologyEnum.JAVASCRIPT,
      icon: 'javascript-colored'
    }, {
      name: 'Static HTML',
      description: 'Web app written using HTML, JS & CSS',
      identifier: AppTechnologyEnum.JAVASCRIPT,
      icon: 'html-colored'
    }
  ],

  [AppPlatform.MOBILE]: [
    {
      name: 'Native iOS',
      description: 'Mobile app written in Swift or Objective-C',
      identifier: AppTechnologyEnum.NATIVE_IOS,
      icon: 'native-ios-colored'
    }, {
      name: 'Native Android',
      description: 'Mobile app written in Kotlin or Java',
      identifier: AppTechnologyEnum.NATIVE_ANDROID,
      icon: 'native-android-colored'
    }, {
      name: 'React Native',
      description: 'Cross platform app written using React Native',
      identifier: AppTechnologyEnum.REACT_NATIVE,
      icon: 'react-colored'
    }, {
      name: 'Flutter',
      description: 'Cross platform app written using Flutter',
      identifier: AppTechnologyEnum.FLUTTER,
      icon: 'flutter-colored'
    }
  ],

  [AppPlatform.SERVER]: [
    {
      name: 'Node,js',
      description: 'Server-side app written in Node.js',
      identifier: AppTechnologyEnum.NODE,
      icon: 'node-colored'
    }, {
      name: 'Python',
      description: 'Server-side app written in Python',
      identifier: AppTechnologyEnum.PYTHON,
      icon: 'python-colored'
    }, {
      name: 'PHP',
      description: 'Server-side app written in PHP',
      identifier: AppTechnologyEnum.PHP,
      icon: 'php-colored'
    }, {
      name: 'Ruby',
      description: 'Server-side app written in Ruby',
      identifier: AppTechnologyEnum.RUBY,
      icon: 'ruby-colored'
    }, {
      name: 'Rust',
      description: 'Server-side app written in Rust',
      identifier: AppTechnologyEnum.RUST,
      icon: 'rust-colored'
    }, {
      name: 'Go',
      description: 'Server-side app written in Go',
      identifier: AppTechnologyEnum.GO,
      icon: 'go-colored'
    }
  ]
}

const CreateProjectView = ({ onClose }: ActiveViewProps) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const [ currentStep, setCurrentStep ] = useState(0)

  const isFirstStep = currentStep === 0
  const isSecondStep = currentStep === 1
  const isFinalStep = currentStep === 2

  const handleStepForward = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleStepBehind = () => {
    setCurrentStep(currentStep - 1)
  }

  const [ platform, setPlatform ] = useState<AppPlatform | null>(null)
  const [ technology, setTechnology ] = useState<AppTechnology | undefined>(undefined)

  const FirstStep = () => (
    <Flex direction="column" gap={16}>
      {platformTypes.map((p) => (
        <MediaCard
          active={platform === p.name}
          key={p.name}
          text={p.description}
          titlePosition="top"
          media={p.icon}
          onClick={() => {
            setPlatform(p.name)
            handleStepForward()
          }}
          title={startCase(p.name.toLowerCase())}
          width="full"
        />
      ))}
    </Flex>
  )

  const SecondStep = () => platform && (
    <Flex direction="column" gap={16}>
      <Text fontWeight="bold">{startCase(platform.toLowerCase())}</Text>
      {technologyTypes[platform].map((t) => (
        <MediaCard
          active={technology === t.identifier}
          key={t.name}
          text={t.description}
          titlePosition="top"
          media={(
            <Icon
              reqContext={require.context('assets/images/technologies', true, /\.svg$/)}
              name={t.icon}
              size={32}
            />
          )}
          onClick={() => {
            setTechnology(t.identifier)
            handleStepForward()
          }}
          title={t.name}
          width="full"
        />
      ))}
    </Flex>
  )

  const FinalStep = () => (
    <Flex direction="column" gap={16}>
      <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
      <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" />
      <Field
        component={IconInput}
        name="icon"
        label="Icon"
        placeholder="Choose Icon"
        size="small"
        type="text"
      />
      <Field
        name="summary"
        label="Summary"
        helpText="Add notes regarding the project's intended use cases. Supports markdown."
        component={TextAreaInput}
        size="small"
      />
    </Flex>
  )

  const [ createApp ] = useCreateAppMutation({
    onCompleted: async ({ createApp }: CreateAppMutation) => {
      openDashboardEditorView({ target: Views.PROJECT_DETAILS, params: { app: createApp } })
    }
  })

  const handleCreateApp = useSubmitHandler(createApp, {
    update: {
      strategy: 'APPEND',
      query: AppListDocument,
      dataKey: 'appsList',
      mutation: 'createApp',
      queryVariables: {
        limit: APP_LIST_LIMIT,
        order: [ { position: 'asc' } ]
      }
    }
  })

  const handleSubmit = (
    values: FormValues
  ) => handleCreateApp(values as CreateAppInput)

  return (
    <>
      <DashboardEditorHeader
        subtitle="New Project"
        heading="Projects"
        onClose={onClose}
      />
      <SidePaneSubHeader size="small">
        <Text fontWeight="bold">{stepSubTitles[currentStep]}</Text>
      </SidePaneSubHeader>
      <Form
        decorators={[
          setIdentifier
        ]}
        initialValues={{
          technology,
          summary: `This is a Project for ${currentWorkspace.name} workspace.`,
          ...initialValues as FormValues,
          kind: 'PROJECT'
        }}
        onSubmit={handleSubmit}
        validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                {isFirstStep && (
                  <HintBox size="compact">
                    <Text color="dark900" fontSize={12} fontWeight="bold">Need help?</Text>
                    <Text fontSize={12}>Learn best practices for{' '}
                      <TextLink href="https://docs.dashx.com/platform/projects">
                        creating new projects
                      </TextLink>
                      .
                    </Text>
                  </HintBox>
                )}
                {isFirstStep && <FirstStep />}
                {isSecondStep && <SecondStep />}
                {isFinalStep && <FinalStep />}
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                {isFinalStep && <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />}
                {!isFinalStep && (
                  <Button
                    size="small"
                    disabled={(isFirstStep && !platform)
                      || (isSecondStep && !technology)}
                    icon="arrow-right"
                    onClick={handleStepForward}
                  />
                )}
                {!isFirstStep && <Button size="small" icon="arrow-left" onClick={handleStepBehind} />}
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateProjectView
