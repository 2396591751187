import React from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import zIndices from 'styles/primitives/zIndices'
import { styled } from 'styles/stitches'
import type { Block } from 'hooks/useDashboard'
import type { ComputedMenuElement } from 'lib/generateDashboard'

type Params = Block | ComputedMenuElement

type InspectElementType = 'Block' | 'MenuElement'

type InspectWrapperProps = {
  isHovered: boolean,
  params: Params,
  type: InspectElementType
}

const StyledInspectCard = styled(Flex, {
  ...mixins.transition('simple'),

  position: 'absolute',
  opacity: '0 !important',
  backgroundColor: 'primary100',
  paddingX: 5,
  paddingY: 1,
  left: 0,

  variants: {
    isActive: {
      false: {
        top: -20
      },
      true: {
        opacity: 1,
        top: 0,
        zIndex: zIndices.dashboardEditor
      }
    }
  }
})

const getTitle = (type: InspectElementType, params: Params) => {
  if (type === 'Block') {
    const { type } = params as Block
    return type
  }

  if (type === 'MenuElement') {
    const { target, kind } = params as ComputedMenuElement

    if (kind === 'SEPARATOR') return 'SEPARATOR'
    if (kind === 'GROUP') return 'HEADER'
    return target
  }

  return ''
}

const InspectWrapper = ({ isHovered, params, type }: InspectWrapperProps) => (
  <StyledInspectCard isActive={isHovered}>
    <Text fontSize={10} fontWeight="bold" color="primary400">{getTitle(type, params)}</Text>
  </StyledInspectCard>
)

export default InspectWrapper
