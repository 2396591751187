import React, { useContext } from 'react'
import { Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import InternalContext from 'components/contexts/InternalContext'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useCreateViewMutation } from 'generated/schema'
import { SidePaneFooter } from 'components/sidePane'
import type { ActiveViewProps } from './DashboardEditor'
import DashboardEditorHeader from './base/DashboardEditorHeader'

const CreateView = ({ onClose }: ActiveViewProps) => {
  const { stepBackDashboardEditor } = useDashboard()

  const { currentDashboard } = useContext(InternalContext)!

  const [ createView ] = useCreateViewMutation({
    onCompleted: () => stepBackDashboardEditor()
  })

  const handleCreateView = useSubmitHandler(createView, {
    successAlert: { message: 'View Created.' }
  })

  return (
    <Form
      initialValues={{
        dashboardId: currentDashboard?.id,
        name: ''
      }}
      onSubmit={handleCreateView}
      subscription={{ submitting: true, pristine: true }}
      render={({ handleSubmit, submitting, pristine }) => (
        <>
          <DashboardEditorHeader
            subtitle="Add View"
            heading="Back"
            onClose={onClose}
          />
          <DashboardEditorBody>
            <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
              <FormField alwaysDirty name="dashboardId" component="input" type="hidden" />
              <FormField
                autoFocus
                component={TextInput}
                name="name"
                label="Name"
                size="small"
                type="text"
              />
              <input type="submit" style={{ display: 'none' }} />
            </Flex>
          </DashboardEditorBody>
          <SidePaneFooter variant="small" isSticky>
            <Flex gap={24} direction="row-reverse">
              <Button type="submit" size="small" label="Submit" disabled={submitting || pristine} onClick={handleSubmit} />
              <Button label="Cancel" size="small" variant="outline" mode="subtle" onClick={() => stepBackDashboardEditor()} />
            </Flex>
          </SidePaneFooter>
        </>
      )}
    />
  )
}

export default CreateView
