import React from 'react'
import { useRecoilValue } from 'recoil'

import Block from './Block'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import type { BlockProps } from './Block'
import type { TextInputProps } from 'components/inputs/TextInput'
import { safeParseLiquid } from 'lib/templater'

type TextInputBlockProps = BlockProps & {
  settings?: Partial<TextInputProps>
}

function TextInputBlock({
  blockRef,
  identifier,

  settings,
  ...others
}: TextInputBlockProps) {
  const { blockPropertiesState, updateBlockProperties } = useDashboard()

  const blockProperties = useRecoilValue(blockPropertiesState)
  const currentBlockProperties = blockProperties[identifier] || {}

  const Component = settings?.is_multiline ? TextAreaInput : TextInput

  return (
    <Block masonryItemRef={blockRef} {...others}>
      <Component
        input={{
          value: currentBlockProperties.value,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => updateBlockProperties(
            (currentBlockProperties) => ({
              [identifier]: {
                ...currentBlockProperties,
                value: e.target.value
              }
            })
          ),
          onBlur: () => {}
        }}
        meta={{}}
        size="small"
        {...settings}
        defaultValue={safeParseLiquid(settings?.defaultValue, blockProperties)}
        {...(settings?.is_multiline && {
          rows: settings.min_height,
          maxRows: settings.max_height,
          autogrow: settings.is_autogrow
        })}
        {...({
          checkRequired: settings?.checkRequired,
          placeholder: settings?.placeholder,
          helpText: settings?.helpText
        })}
        {...{ disableEmojis: settings?.disableEmojis }}
      />
    </Block>
  )
}

export type { TextInputBlockProps }

export default TextInputBlock
