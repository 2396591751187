import React from 'react'
import get from 'lodash/get'

import TextLink from 'components/links/TextLink'
import TextRenderer from 'components/renderers/TextRenderer'
import { parseAndRenderSync } from 'lib/templater'
import type { RendererOptions } from 'components/dataTable/types'
import type { TextLinkProps } from 'components/links/TextLink'

type GenerateLinkRendererProps<T> = Omit<TextLinkProps, 'onClick'> & {
  onClick?: (record: T) => void,
  as?: (props: RendererOptions) => JSX.Element
}

type LinkRendererProps<T> = RendererOptions & GenerateLinkRendererProps<T>

function LinkRenderer<T>({
  dataKey,
  rowData,
  isHovered,
  isArray,
  selection,
  style,

  as: As = TextRenderer,
  to,
  onClick,
  ...other
}: LinkRendererProps<T>) {
  const linkProps = (() => {
    let elementProps = {}

    if (to) {
      elementProps = {
        to: parseAndRenderSync(to, rowData),
        onClick: undefined
      }
    }

    if (onClick) {
      elementProps = {
        as: 'button',
        onClick: () => onClick?.(rowData),
        type: 'button'
      }
    }

    return {
      ...other,
      ...elementProps,
      style: {
        textAlign: 'left',
        flexGrow: 1,
        ...style
      }
    } as TextLinkProps
  })()

  const data = get(rowData, dataKey)

  if (isArray) {
    const repeatedContent = Array.isArray(data) ? data.join(', ') : data
    return (
      <TextLink {...linkProps} title={repeatedContent}>
        <As dataKey={dataKey} rowData={rowData} isArray {...other} />
      </TextLink>
    )
  }
  return (
    <TextLink {...linkProps} title={data}>
      <As dataKey={dataKey} rowData={rowData} {...other} />
    </TextLink>
  )
}

function generateLinkRenderer<T>(generatorProps: GenerateLinkRendererProps<T>) {
  return ((props: LinkRendererProps<T>) => (
    <LinkRenderer
      {...generatorProps}
      {...props}
    />
  ))
}

export { generateLinkRenderer }

export default LinkRenderer
