/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable } from 'react-beautiful-dnd'
import { useRecoilValue } from 'recoil'

import Chip from 'components/chip/Chip'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard from 'hooks/useDashboard'
import useReorder from 'hooks/useReorder'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { ATTRIBUTES_LIST_LIMIT, OPERATIONS_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT, SEGMENTS_LIST_LIMIT } from 'models/Resource'
import { Attribute, AttributesListDocument, Operation, RelationshipsListDocument, useAttributesListQuery, useDestroyAttributeMutation, useFieldTypesListQuery, useOperationsListQuery, useRelationshipsListQuery, useSegmentsListQuery, useUpdateAttributeMutation, useUpdateRelationshipMutation } from 'generated/schema'
import { Kind } from 'models/Relationship'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type Params = ViewParams[Views.RESOURCE_DETAILS]

const ResourceDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { resource, parentView, app, workspace } = params! as Params
  const { name } = resource! || {}
  const { id: workspaceId } = workspace! || {}
  const { kind: appKind, name: appName } = app! || {}
  const isProject = appKind && appKind === 'PROJECT'

  return (
    <>
      <DashboardEditorHeader
        subtitle={`Resource: ${name}`}
        heading={parentView ? 'Dashboard Editor'
          : isProject ? `Project: ${appName}`
            : workspaceId ? 'Schema' : appName ? `App: ${appName}` : 'Dashboard Editor'}
        onEdit={() => openDashboardEditorView({
          target: Views.CREATE_RESOURCE,
          params: { initialValues: resource!, app, resource, workspace }
        })}
        onClose={onClose}
      />
      <DashboardEditorBody>
        <Attributes />
        <Operations />
        <Segments />
        <Relationships />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const Attributes = () => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { resource, app, workspace } = params! as Params
  const { id: resourceId } = resource! || {}

  const droppableId = useRef(uuid())

  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId }
    },
    order: [ {
      position: 'asc'
    } ],
    limit: ATTRIBUTES_LIST_LIMIT
  }

  const {
    data: { attributesList = [] } = {},
    loading,
    error
  } = useAttributesListQuery({
    variables: queryVariables,
    skip: !resource
  })

  const { data: { fieldTypes } = {} } = useFieldTypesListQuery()

  const onAdd = () => openDashboardEditorView({
    target: Views.CREATE_ATTRIBUTE,
    params: { resource: resource!, app, workspace }
  })

  const [ updateAttribute ] = useUpdateAttributeMutation({
    refetchQueries: [ AttributesListDocument ]
  })
  const handleUpdateAttribute = useSubmitHandler(updateAttribute)

  const reorder = useReorder({
    query: AttributesListDocument,
    variables: queryVariables,
    dataKey: 'attributesList',
    callback: handleUpdateAttribute
  })

  const confirm = useConfirmation({ style: 'DIALOG' })
  const [ destroyAttribute ] = useDestroyAttributeMutation({
    refetchQueries: [
      AttributesListDocument
    ]
  })

  const handleDestroyAttribute = useSubmitHandler(destroyAttribute, {
    successAlert: { message: 'Attribute Deleted.' }
  })

  const onClick = (attribute: Attribute) => openDashboardEditorView({
    target: Views.CREATE_ATTRIBUTE,
    params: { resource: resource!, app, initialValues: attribute, workspace }
  })

  const onDelete = (attribute: Attribute) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        handleDestroyAttribute({ id: attribute.id })
      },
      recordType: 'Attribute',
      recordDescription: attribute.name
    })
  }

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const attribute = attributesList[rubric.source.index]
    const fieldType = fieldTypes?.find((type) => type.identifier === attribute.fieldType)

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <MediaCard
          compact
          key={attribute.id}
          media={(
            <Icon name="drag" size={16} color="dark100" />
          )}
          title={attribute.name}
          height={64}
          width="full"
          onClick={() => onClick(attribute as Attribute)}
          actions={[
            {
              icon: 'trash',
              description: 'Delete',
              onClick: () => onDelete(attribute as Attribute),
              variant: 'negative'
            },
            {
              hideTooltip: true,
              icon: `${fieldType?.icon || 'text-field'}`,
              onClick,
              isIconAlwaysVisible: true
            }, {
              description: '',
              icon: 'arrow-right',
              onClick,
              isIconAlwaysVisible: true
            }
          ]}
        />
      </div>
    )
  }

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Attributes
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={attributesList}
          loading={loading}
          error={error}
        >
          <DragDropContext onDragEnd={reorder}>
            <Droppable
              droppableId={droppableId.current}
              renderClone={renderDraggableChildren}
            >
              {(droppableProvided) => (
                <Flex
                  direction="column"
                  gap={2}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {attributesList.map((attribute, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={attribute.id}
                      index={index}
                      key={attribute.id}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

const Operations = () => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { resource, app, workspace } = params! as Params
  const { id: appId } = app! || {}
  const { id: resourceId } = resource! || {}

  const onAdd = () => openDashboardEditorView({
    target: Views.CREATE_OPERATION,
    params: { resource, app, workspace }
  })

  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId },
      appId: appId ? { eq: appId } : undefined
    },
    order: [ {
      position: 'asc'
    } ],
    limit: OPERATIONS_LIST_LIMIT
  }

  const {
    data: { operationsList = [] } = {},
    loading,
    error
  } = useOperationsListQuery({
    variables: queryVariables,
    skip: !resourceId
  })

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Operations
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={operationsList}
          loading={loading}
          error={error}
        >
          <Flex direction="column" gap={2}>
            {operationsList?.map((operation) => {
              const onEdit = () => openDashboardEditorView({
                target: Views.CREATE_OPERATION,
                params: { resource, app, operation: operation as Operation, workspace }
              })

              return (
                <MediaCard
                  compact
                  key={operation.id}
                  media=""
                  title={operation.name}
                  height={64}
                  width="full"
                  onClick={onEdit}
                  actions={[ {
                    description: '',
                    icon: 'arrow-right',
                    onClick: onEdit,
                    isIconAlwaysVisible: true
                  } ]}
                />
              )
            })}
          </Flex>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

const Segments = () => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { resource, app, workspace } = params! as Params
  const { id: resourceId } = resource!

  const onAdd = () => openDashboardEditorView({
    target: Views.CREATE_SEGMENT,
    params: { resource, app, workspace }
  })

  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId }
    },
    limit: SEGMENTS_LIST_LIMIT
  }

  const {
    data: { segmentsList = [] } = {},
    loading,
    error
  } = useSegmentsListQuery({
    variables: queryVariables,
    skip: !resourceId
  })

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Segments
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={segmentsList}
          loading={loading}
          error={error}
        >
          <Flex direction="column" gap={2}>
            {segmentsList?.map((segment) => (
              <MediaCard
                compact
                key={segment.id}
                media=""
                title={segment.name}
                height={64}
                width="full"
                onClick={() => {}}
                actions={[ {
                  description: '',
                  icon: 'arrow-right',
                  onClick: () => {},
                  isIconAlwaysVisible: true
                } ]}
              />
            ))}
          </Flex>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

const Relationships = () => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { resource } = params! as Params
  const { id: resourceId } = resource!

  const droppableId = useRef(uuid())

  const onAdd = () => openDashboardEditorView({
    target: Views.CREATE_RELATIONSHIP,
    params: { resource }
  })

  const queryVariables = {
    filter: {
      sourceId: { eq: resourceId }
    },
    order: [ {
      position: 'asc'
    } ],
    limit: RELATIONSHIPS_LIST_LIMIT
  }

  const {
    data: { relationshipsList = [] } = {},
    loading,
    error
  } = useRelationshipsListQuery({
    variables: queryVariables,
    skip: !resourceId
  })

  const [ updateRelationship ] = useUpdateRelationshipMutation({
    refetchQueries: [ RelationshipsListDocument ]
  })

  const handleUpdateRelationship = useSubmitHandler(updateRelationship)

  const reorder = useReorder({
    query: RelationshipsListDocument,
    variables: queryVariables,
    dataKey: 'relationshipsList',
    callback: handleUpdateRelationship
  })

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const relation = relationshipsList[rubric.source.index]

    const onClick = () => openDashboardEditorView({
      target: Views.CREATE_RELATIONSHIP,
      params: { resource, initialValues: relation }
    })

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <MediaCard
          compact
          key={relation.id}
          media={(
            <Icon name="drag" size={16} color="dark100" />
          )}
          title={relation.name}
          height={64}
          width="full"
          onClick={onClick}
          description={(
            <Chip
              label={relation.kind === Kind.BELONGS_TO ? '1 : 1' : '1 : N'}
              variant="light"
            />
          )}
          actions={[ {
            description: '',
            icon: 'arrow-right',
            onClick,
            isIconAlwaysVisible: true
          } ]}
        />
      </div>
    )
  }

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Relationships
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={relationshipsList}
          loading={loading}
          error={error}
        >
          <DragDropContext onDragEnd={reorder}>
            <Droppable
              droppableId={droppableId.current}
              renderClone={renderDraggableChildren}
            >
              {(droppableProvided) => (
                <Flex
                  direction="column"
                  gap={2}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {relationshipsList.map((relation, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={relation.id}
                      index={index}
                      key={relation.id}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

const actions = [
  {
    title: 'Create Attribute',
    identifier: Views.CREATE_ATTRIBUTE,
    media: 'tag'
  },
  {
    title: 'Create Operation',
    identifier: Views.CREATE_OPERATION,
    media: 'advance'
  },
  {
    title: 'Create Segment',
    identifier: Views.CREATE_SEGMENT,
    media: 'indent'
  },
  {
    title: 'Create Relationship',
    identifier: Views.CREATE_RELATIONSHIP,
    media: 'flow'
  }
]

const Actions = () => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, resource, workspace } = params! as Params

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const { media, title, identifier } = action

          const onClick = () => openDashboardEditorView({
            target: identifier,
            params: { app, resource, workspace }
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              media={media}
              title={title}
              height={64}
              width="full"
              onClick={onClick}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                isIconAlwaysVisible: true,
                onClick
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default ResourceDetailsView
