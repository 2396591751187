import capitalize from 'lodash/capitalize'
import camelCase from 'lodash/camelCase'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { object, string } from 'yup'

import { ColorField, DateTimeField, EmailField, MediaField, NumberField, PasswordField, DropdownField, TextField, PhoneField } from 'components/contentEditors/generic/fields'
import BaseModel from 'models/BaseModel'

const AUTOCOMPLETE_FIELDS_IDENTIFIERS = [ 'first_name', 'last_name', 'email', 'phone', 'password' ]

const accountTypeFields = [
  'color-field',
  'date-time-field',
  'dropdown-field',
  'email-field',
  'file-field',
  'number-field',
  'password-field',
  'phone-field',
  'text-field'
]
  .map((x) => ({
    value: x,
    label: capitalize(x.replace('-field', ''))
  }))

const renderField = (props = {}) => (field: any) => {
  const { name, identifier, configuration, fieldType, isPredefined } = field

  const commonProps: any = {
    ...field,
    ...props,
    key: identifier,
    name: isPredefined ? camelCase(identifier) : `custom.${identifier}`,
    size: 'small',
    label: name,
    alwaysDirty: !isPredefined,
    settings: configuration,
    autoComplete: AUTOCOMPLETE_FIELDS_IDENTIFIERS.includes(identifier) ? 'on' : 'off'
  }

  switch (fieldType) {
    case 'email-field': {
      if (identifier === 'email') {
        return (
          <FormSpy
            key={identifier}
            subscription={{ values: true }}
            render={({ values: { hasGuiAccess } }) => (
              <EmailField
                {...commonProps}
                alwaysDirty={false}
                name="email"
                required={hasGuiAccess}
              />
            )}
          />
        )
      }

      return <EmailField {...commonProps} />
    }

    case 'phone-field': {
      return <PhoneField {...commonProps} />
    }

    case 'password-field': {
      if (identifier === 'password') return null
      return <PasswordField {...commonProps} />
    }

    case 'text-field': {
      return <TextField {...commonProps} />
    }

    case 'file-field': {
      return <MediaField {...commonProps} />
    }

    case 'dropdown-field': {
      return <DropdownField {...commonProps} />
    }

    case 'date-time-field': {
      return <DateTimeField {...commonProps} />
    }

    case 'number-field': {
      return <NumberField {...commonProps} />
    }

    case 'color-field': {
      return <ColorField {...commonProps} />
    }

    default:
      return null
  }
}

class AccountTypeModel extends BaseModel {
  static schema = object({
    name: string().required(),
    identifier: string().required()
  })

  static fieldSchema = (identifiers: string[]) => ({
    name: string().required(),
    identifier: string().notOneOf(identifiers).required(),
    fieldType: string().required()
  })

  static renderField = renderField
}

export { accountTypeFields }

export default AccountTypeModel
