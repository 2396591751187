import React from 'react'

import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { DashboardsListQuery } from 'generated/schema'

export type DashboardContext = {
  currentSidebarWidth: number,
  editMode: boolean,
  openDashboardEditor: () => void,
  openJumpMenu: () => void,
  selectedSideMenuElement?: ComputedMenuElement,
  selectedTopMenuElement?: ComputedMenuElement,
  dashboardLoading: boolean,
  showTrialBanner: boolean,
  dashboardsList: DashboardsListQuery['dashboardsList']
} | null

export default React.createContext<DashboardContext>(null)
