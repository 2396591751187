import React, { useCallback } from 'react'
import { string } from 'yup'
import type { StringSchema } from 'yup'

import FormField from 'components/form/FormField'
import PhoneInput from 'components/inputs/PhoneInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { PhoneInputProps } from 'components/inputs/PhoneInput'

type PhoneFieldProps = Omit<PhoneInputProps, 'input' | 'meta'> & fieldProps<'phone'>

const getPhoneFieldSchema = (settings: PhoneFieldProps['settings'] = {}, isNullable: boolean) => {
  let schema: StringSchema<string | null | undefined> = string().phone().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  return schema
}

const PhoneField = ({
  name,
  settings,
  shouldValidate,
  isNullable = false,
  ...others
}: PhoneFieldProps) => {
  const validate = useCallback((value: string) => getPhoneFieldSchema(settings, isNullable)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings, isNullable ])

  return (
    <FormField
      name={name}
      component={PhoneInput}
      parse={(x) => {
        if (!x && isNullable) return null

        return x
      }}
      {...({
        checkRequired: settings?.checkRequired,
        placeholder: settings?.placeholder,
        helpText: settings?.helpText
      })}
      {...(shouldValidate && { validate })}
      {...others}
    />
  )
}

export type { PhoneFieldProps }
export { getPhoneFieldSchema }
export default PhoneField
