import get from 'lodash/get'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AccountNotificationsPage from 'components/pages/AccountNotificationsPage'
import AccountProfilePage from 'components/pages/AccountProfilePage'
import AccountSettingsPage from 'components/pages/AccountSettingsPage'
import AccountsPage from 'components/pages/AccountsPage'
import AppError from 'components/errors/AppError'
import AppLoader from 'components/loaders/AppLoader'
import AppPage from 'components/pages/AppPage'
import AppsPage from 'components/pages/AppsPage'
import BillingPage from 'components/pages/BillingPage'
import CompleteOnboardingPage from 'components/pages/CompleteOnboardingPage'
import CurrentAccountProvider from 'components/contexts/CurrentAccountContext'
import DashboardPage from 'components/pages/DashboardPage'
import DashboardsPage from 'components/pages/DashboardsPage'
import DeveloperPage from 'components/pages/DeveloperPage'
import DomainPage from 'components/pages/DomainPage'
import EnvironmentsPage from 'components/pages/EnvironmentsPage'
import ExternalRoute from 'components/routes/ExternalRoute'
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage'
import generateTheme, { DEFAULT_THEME } from 'lib/generateTheme'
import GraphPage from 'components/pages/GraphPage'
import GroupsPage from 'components/pages/GroupsPage'
import InternalRoute from 'components/routes/InternalRoute'
import LoginPage from 'components/pages/LoginPage'
import LogoutPage from 'components/pages/LogoutPage'
import NotFoundPage from 'components/pages/NotFoundPage'
import OnboardingRoute from 'components/routes/OnboardingRoute'
import ResetPasswordPage from 'components/pages/ResetPasswordPage'
import RolesPage from 'components/pages/RolesPage'
import RootPage from 'components/pages/RootPage'
import StartOnboardingPage from 'components/pages/StartOnboardingPage'
import TourProvider from 'components/providers/TourProvider'
import VerifyMfaPage from 'components/pages/VerifyMfaPage'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import BrandingPage from 'components/pages/BrandingPage'
import LocalesPage from 'components/pages/LocalesPage'
import { hostname } from 'lib/hostname'
import { useWorkspaceContextQuery } from 'generated/schema'
import type { ThemePalette } from 'generated/schema'
import SettingsPage from 'components/pages/SettingsPage'

function WorkspaceContainer() {
  const themeRef = useRef<string | null>(null)

  const changeThemePalette = useCallback(
    (palette: ThemePalette) => generateTheme(palette, themeRef), []
  )

  const { data, error, loading } = useWorkspaceContextQuery({
    variables: { hostname }
  })

  const resetThemePalette = () => generateTheme(
    data?.workspace?.theme?.palette || DEFAULT_THEME.palette,
    themeRef
  )

  const resetThemePaletteRef = useRef(resetThemePalette)

  useLayoutEffect(() => {
    resetThemePaletteRef.current = resetThemePalette
  })

  useLayoutEffect(() => {
    generateTheme(data?.workspace?.theme?.palette, themeRef)
  }, [ data ])

  if (loading && !data) {
    return <AppLoader />
  }

  const isUnauth = get(error, 'networkError.statusCode') === 401 || error?.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHORIZED')

  if ((error && !isUnauth) || !data) {
    return <AppError error={error} />
  }

  return (
    <WorkspaceContext.Provider
      value={{ currentWorkspace: data.workspace, changeThemePalette, resetThemePaletteRef }}
    >
      <CurrentAccountProvider>
        <TourProvider>
          <Switch>
            <InternalRoute exact path="/" component={RootPage} />

            <ExternalRoute path="/login" component={LoginPage} />
            <ExternalRoute path="/verify-mfa" component={VerifyMfaPage} />
            <ExternalRoute path="/forgot-password" component={ForgotPasswordPage} />
            <ExternalRoute path="/reset-password" component={ResetPasswordPage} />
            <ExternalRoute path="/join" component={StartOnboardingPage} />

            <OnboardingRoute path="/onboard" component={CompleteOnboardingPage} />

            <Redirect exact path="/~personal" to="/~personal/profile" />
            <InternalRoute path="/~personal/profile" component={AccountProfilePage} />
            <InternalRoute path="/~personal/notifications" component={AccountNotificationsPage} />
            <InternalRoute path="/~personal/settings" component={AccountSettingsPage} />
            <InternalRoute path="/logout" component={LogoutPage} />

            <Redirect exact path="/~workspace" to="/~workspace/apps" />
            <InternalRoute path="/~workspace/apps" component={AppsPage} />
            <InternalRoute path="/~workspace/app/:appId" component={AppPage} />
            <InternalRoute path="/~workspace/dashboards" component={DashboardsPage} />
            <InternalRoute path="/~workspace/environments" component={EnvironmentsPage} />
            <InternalRoute path="/~workspace/billing" component={BillingPage} />
            <InternalRoute path="/~workspace/accounts" component={AccountsPage} />
            <InternalRoute path="/~workspace/groups" component={GroupsPage} />
            <InternalRoute path="/~workspace/roles" component={RolesPage} />
            <InternalRoute path="/~workspace/schema" component={GraphPage} />
            <InternalRoute path="/~workspace/developer" component={DeveloperPage} />
            <InternalRoute path="/~workspace/domain" component={DomainPage} />
            <InternalRoute path="/~workspace/branding" component={BrandingPage} />
            <InternalRoute path="/~workspace/locales" component={LocalesPage} />
            <InternalRoute path="/~workspace/settings" component={SettingsPage} />

            <InternalRoute path="/~:dashboardIdentifier?" render={DashboardPage} />

            <Route component={NotFoundPage} />
          </Switch>
        </TourProvider>
      </CurrentAccountProvider>
    </WorkspaceContext.Provider>
  )
}

export default WorkspaceContainer
