import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import setIdentifierDecorator from 'lib/formDecorators/setIdentifier'
import TextInput from 'components/inputs/TextInput'
import Workspace from 'models/Workspace'

const {
  REACT_APP_DOMAIN
} = process.env

function CreateWorkspaceForm(props: { formProps: FormProps<any>, disabled: boolean }) {
  const { formProps, disabled } = props
  return (
    <Form
      decorators={[
        setIdentifierDecorator
      ]}
      validate={(values) => Workspace.validate(values, [ 'identifier' ])}
      subscription={{
        submitting: true,
        pristine: true
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap={24}>
            <Field
              autoFocus
              component={TextInput}
              label="Name"
              name="name"
              helpText="Use your business or brand name here."
              type="text"
            />
            <Field
              component={TextInput}
              label="Workspace URL"
              name="identifier"
              helpText="The URL you will use to access this workspace."
              type="text"
              appendText={`.${REACT_APP_DOMAIN}`}
            />
            <Button type="submit" label="Continue" disabled={disabled || submitting || pristine} icon="arrow-right" iconPlacement="right" iconSize={12} />
          </Flex>
        </form>
      )}
      {...formProps}
    />
  )
}

export default CreateWorkspaceForm
