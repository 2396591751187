import React, { memo } from 'react'
import { useRecoilValue } from 'recoil'
import type { ReactNode } from 'react'

import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import SearchBar from 'components/searchbar/SearchBar'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { SidePaneHeader, SidePaneSubHeader } from 'components/sidePane'
import { Views } from '../constants'

type DashboardEditorHeaderProps = {
  heading: string,
  icon?: ReactNode,
  onClose: VoidFunction,
  onEdit?: VoidFunction,
  onSearch?: React.ChangeEventHandler<HTMLInputElement>,
  onStepBack?: VoidFunction,
  subtitle?: ReactNode
}

const DashboardEditorHeader = ({
  heading,
  icon,
  onClose,
  onEdit,
  onSearch,
  onStepBack,
  subtitle
}: DashboardEditorHeaderProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { target } = useRecoilValue(dashboardEditorState)
  const handleBack = target === Views.ACTIONS ? undefined : () => stepBackDashboardEditor()

  return (
    <>
      <SidePaneHeader
        onBackArrowClick={onStepBack || handleBack}
        onCloseClick={onClose}
        size="small"
        title={heading}
      />
      {subtitle && (
        <SidePaneSubHeader direction="column" alignItems="stretch" gap={16} size="small">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex gap={16} alignItems="center">
              {icon}
              <Text letterSpacing="compact" fontSize={24} fontWeight="bold">{subtitle}</Text>
            </Flex>
            {onEdit && <IconButton description="Edit" name="edit" onClick={onEdit} size={24} variant="dark" />}
          </Flex>
          {onSearch && <SearchBar autofocus onChange={onSearch} placeholder="Quick Search" />}
        </SidePaneSubHeader>
      )}
    </>
  )
}

export default memo(DashboardEditorHeader)
