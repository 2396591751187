/* eslint-disable camelcase */
import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'

import Box from 'components/layout/Box'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'

type ControlledProps = {
  activeIndex: number,
  onChange: Dispatch<SetStateAction<number>>,
  initialIndex?: undefined
}

type UncontrolledProps = {
  activeIndex?: undefined,
  initialIndex?: number,
  onChange?: Dispatch<SetStateAction<number>>
}

type TabsProps = PropsWithChildren<{
  appendNode?: React.ReactNode,
  panelWrapper?: React.JSXElementConstructor<any>,
  tabListBackground?: 'light' | 'none',
  tabListClassName?: string,
  tabPanelClassName?: string,
  wrapperClassName?: string
} & (ControlledProps | UncontrolledProps)>

type TabsContextProps = {
  activeIndex: number,
  containerEl: HTMLElement | null,
  setActiveIndex: Dispatch<SetStateAction<number>>
} | null

const TabsContext = createContext<TabsContextProps>(null)

const StyledTabList = styled(Flex, {
  variants: {
    background: {
      light: {
        backgroundColor: 'light100',
        borderBottom: '1px solid light700'
      },
      none: {}
    }
  }
})

const useTabs = () => {
  const state = useContext(TabsContext)

  if (!state) {
    throw new Error('useTabs must be used within a Tabs component')
  }

  return state
}

function Tabs({
  appendNode,
  children,
  panelWrapper,
  tabListBackground = 'none',
  tabListClassName,
  tabPanelClassName,
  onChange,
  initialIndex = 0,
  wrapperClassName,
  activeIndex: activeIndex_controlled
}: TabsProps) {
  const tabListRef = useRef<HTMLDivElement | null>(null)
  const [ activeIndex_uncontrolled, setActiveIndex_uncontrolled ] = useState(initialIndex)

  const activeIndex = activeIndex_controlled ?? activeIndex_uncontrolled
  const setActiveIndex: typeof setActiveIndex_uncontrolled = (i) => {
    onChange?.(i)
    setActiveIndex_uncontrolled(i)
  }

  const [ containerEl, setContainerEl ] = useState<HTMLElement | null>(null)
  const onChangeRef = useRef<typeof onChange>()

  useLayoutEffect(() => {
    onChangeRef.current = onChange
  }, [ onChange ])

  useEffect(() => {
    onChangeRef.current?.(activeIndex)
  }, [ activeIndex ])

  const Wrapper = panelWrapper || Box

  const findNextTab = (currentTab: HTMLElement, offset: number) => {
    const tabList = tabListRef.current

    if (tabList) {
      const tabs = [ ...tabList.querySelectorAll<HTMLElement>('[data-tab]') ]
      const index = tabs.indexOf(currentTab)

      if (index === -1) return null

      let nextIndex = index + offset
      if (nextIndex >= tabs.length) nextIndex = 0
      if (nextIndex < 0) nextIndex = tabs.length - 1

      return tabs[nextIndex]
    }

    return null
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const currentTab = event.target as HTMLElement
    let nextTab
    switch (event.key) {
      case 'ArrowLeft':
        nextTab = findNextTab(currentTab, -1)
        break
      case 'ArrowRight':
        nextTab = findNextTab(currentTab, 1)
        break
      default:
        return
    }

    if (nextTab) {
      const index = parseInt(nextTab.dataset.index || '', 10)

      event.preventDefault()
      setActiveIndex(index)
      nextTab.focus()
    }
  }

  return (
    <TabsContext.Provider value={{ activeIndex, setActiveIndex, containerEl }}>
      <Flex className={wrapperClassName} alignItems="center" justifyContent="space-between">
        <StyledTabList
          alignItems="baseline"
          background={tabListBackground}
          className={tabListClassName}
          onKeyDown={onKeyDown}
          ref={tabListRef}
          role="tablist"
        >
          {children}
        </StyledTabList>
        {appendNode}
      </Flex>
      <Wrapper className={tabPanelClassName} ref={setContainerEl} />
    </TabsContext.Provider>
  )
}

export default Tabs

export type { TabsProps }

export { useTabs }
