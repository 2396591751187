import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import type { FormApi } from 'final-form'

import { useHistory } from 'react-router-dom'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import IconTextButton from 'components/buttons/IconTextButton'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import TextInput from 'components/inputs/TextInput'
import IconInput from 'components/inputs/IconInput'
import updateMenuElements from 'lib/updateMenuElements'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { getInjectRecursiveProperties } from 'lib/generateDashboard'
import {
  useCreateMenuElementMutation,
  useDestroyMenuElementMutation,
  useUpdateMenuElementMutation
} from 'generated/schema'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { CreateMenuElementInput, UpdateMenuElementInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { MenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import type { ViewProps } from 'components/views'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type Params = Partial<ComputedMenuElement & MenuElementPositionContext>

function AddBlankItemView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { push } = useHistory()
  const {
    currentDashboard,
    idToMenuElementMap,
    parentIdToMenuElementsMap
  } = useContext(InternalContext)!
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const { dashboardId, isSticky = false, id, icon, name, parentId, placement, position, target = 'VIEW', url, newNonStickyPosition, newStickyPosition } = params
  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted({ createMenuElement: menuElement }) {
      if (menuElement.target !== 'URL') {
        const idToDashboardMap = currentDashboard ? getPropertyToElementMap<typeof currentDashboard>([ currentDashboard ], 'id') : {}
        const injectRecursiveProperties = getInjectRecursiveProperties(
          idToMenuElementMap,
          idToDashboardMap,
          currentWorkspace
        )

        const computedMenuElement = injectRecursiveProperties(menuElement)
        push(computedMenuElement.fullPath!)
      }

      onRequestClose()
    }
  })

  const [ updateMenuElement ] = useUpdateMenuElementMutation({ onCompleted: onRequestClose })
  const [ destroyMenuElement ] = useDestroyMenuElementMutation({ onCompleted: onRequestClose })
  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleDestroyMenuElementSubmit = useSubmitHandler(destroyMenuElement, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('DESTROY', parentIdToMenuElementsMap, dashboardId)
  })

  const confirm = useConfirmation()

  const onConfirmDestroy = () => handleDestroyMenuElementSubmit({ id })

  const handleMenuItemFormSubmit = (values: FormValues, form: FormPropsWithId<FormValues>['form']) => {
    if (id) {
      let newPosition

      if (isSticky === values.isSticky) {
        // no change needed
        newPosition = position
      } else {
        newPosition = values.isSticky
          ? newStickyPosition
          : newNonStickyPosition
      }

      return handleUpdateMenuElementSubmit({
        ...values,
        id,

        position: newPosition
      }, form as FormApi<UpdateMenuElementInput>)
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId,
      kind: 'ITEM',
      position: values.isSticky
        ? newStickyPosition!
        : newNonStickyPosition!
    })
  }

  const title = id ? 'Update Blank View' : 'Add Blank View'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            initialValues={{
              id,
              icon,
              isSticky,
              name,
              parentId,
              placement,
              position,
              target,
              url
            }}
            onSubmit={handleMenuItemFormSubmit}
            subscription={{ submitting: true, pristine: true }}
            validate={(values) => MenuElement.validate(values, [ 'name', 'icon', 'target', 'isSticky', 'url' ])}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex as="form" direction="column" gap={32} onSubmit={handleSubmit}>
                    <FormField alwaysDirty name="position" component="input" type="hidden" />
                    <Grid alignItems="center" gap={16} columns={1}>
                      <Field
                        autoFocus
                        component={TextInput}
                        name="name"
                        label="Label"
                        size="small"
                        type="text"
                      />
                      <Field
                        component={IconInput}
                        name="icon"
                        label="Choose Icon"
                        size="small"
                        type="text"
                      />
                    </Grid>
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Footer.Left>
                    {id && (
                      <IconTextButton
                        label="Delete"
                        name="trash"
                        onClick={() => confirm(
                          { action: 'delete', onConfirmClick: onConfirmDestroy, recordType: 'Menu Item' }
                        )}
                      />
                    )}
                  </Footer.Left>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

AddBlankItemView.defaultStyle = 'DIALOG' as const

export default AddBlankItemView
