import React, { forwardRef, useEffect, useRef } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import useCodeJar from 'hooks/useCodeJar'
import useCombinedRefs from 'hooks/useCombinedRefs'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import type { Language } from 'hooks/usePrism'

type CodeEditorInputProps = FieldRenderProps<string, HTMLPreElement> & {
  className?: string,
  helpText?: string,
  isTranslatable?: boolean,
  language?: Language,
  size?: 'small' | 'normal' | 'large' | 'full' | 'auto',
  theme?: 'light' | 'dark',
  showLineNumbers?: boolean
}

const LINE_NUMBER_BORDER_RADIUS = 4
const LINE_NUMBER_PADDING_Y = 14
const LINE_NUMBER_PADDING_X = 12
const LINE_NUMBER_WIDTH = 46
const INPUT_PADDING_LEFT = 20
const LARGE_SIZE = 450
const NORMAL_SIZE = 250
const SMALL_SIZE = 150
const TAB_SIZE = 2

const StyledWrapper = styled(Flex, {
  position: 'relative',
  width: '100%'
})

const StyledInput = styled('pre', {
  '&': {
    color: 'dark100',
    backgroundColor: 'transparent !important',
    fontSize: '14px !important',
    height: '100%',
    marginBottom: '0 !important',
    marginTop: '0 !important',
    paddingLeft: `${INPUT_PADDING_LEFT}px !important`,
    position: 'absolute',
    resize: 'none !important',
    right: 0,
    top: 0,
    whiteSpace: 'pre !important',
    width: `calc(100% - ${LINE_NUMBER_WIDTH}px)`,

    '&[class*="language-"]': {
      overflow: 'visible'
    }
  }
})

const StyledLineNumbers = styled(Flex, {
  borderBottomLeftRadius: LINE_NUMBER_BORDER_RADIUS,
  borderTopLeftRadius: LINE_NUMBER_BORDER_RADIUS,
  height: '100%',
  left: 0,
  overflow: 'hidden',
  paddingBottom: LINE_NUMBER_PADDING_Y,
  paddingLeft: LINE_NUMBER_PADDING_X,
  paddingRight: LINE_NUMBER_PADDING_X,
  paddingTop: LINE_NUMBER_PADDING_Y,
  position: 'absolute',
  textAlign: 'right',
  top: 0,
  width: LINE_NUMBER_WIDTH,

  variants: {
    theme: {
      light: {
        '&&': {
          backgroundColor: rgba(colorVars.dark500rgb, 0.1)
        }
      },
      dark: {
        '&&': {
          backgroundColor: rgba(colorVars.dark800rgb, 0.1)
        }
      }
    }
  }
})

const StyledEditorContainer = styled(Flex, {
  position: 'relative',
  overflow: 'hidden',

  variants: {
    theme: {
      light: {
        '&&': {
          backgroundColor: 'light300',
          color: 'dark500',
          caretColor: 'dark500'
        }
      },
      dark: {
        '&&': {
          backgroundColor: 'dark900',
          color: 'light100',
          caretColor: 'light100'
        }
      }
    },
    size: {
      full: {
        height: '100%'
      },
      small: {
        height: SMALL_SIZE
      },
      normal: {
        height: NORMAL_SIZE
      },
      large: {
        height: LARGE_SIZE
      },
      auto: {
        height: 'auto',

        [`& ${StyledLineNumbers}`]: {
          position: 'static'
        }
      }
    }
  }
})

const StyledLineNumber = styled(Text, {
  lineHeight: 1.5
})

const CodeEditorInput = forwardRef(({
  className,
  disabled,
  helpText,
  input,
  isTranslatable,
  label,
  language = 'javascript',
  meta,
  size = 'normal',
  theme = 'dark',
  showLineNumbers = true
}: CodeEditorInputProps,
ref: React.Ref<HTMLPreElement>) => {
  const lineNumberRef = useRef<HTMLDivElement>(null)

  const { editorRef } = useCodeJar({
    input,
    language,
    theme,
    options: { tabSize: TAB_SIZE }
  })

  const inputRef = useCombinedRefs(editorRef, ref)

  useEffect(() => {
    const onScrollContainer = () => {
      if (lineNumberRef?.current && inputRef?.current) {
        lineNumberRef.current.scrollTop = inputRef.current.scrollTop
      }
    }

    const refObject = inputRef?.current

    refObject?.addEventListener('scroll', onScrollContainer)

    return () => refObject?.removeEventListener('scroll', onScrollContainer)
  }, [ inputRef ])

  useEffect(() => {
    if (inputRef.current && disabled) {
      inputRef.current.contentEditable = 'false'
    }
  }, [ disabled, inputRef ])

  const renderLineNumbers = () => {
    let lines = input.value || ''
    if (typeof lines !== 'string') {
      lines = JSON.stringify(input.value || {}, null, TAB_SIZE)
    }

    const linesCount = lines.replace(/\n+$/, '\n').split('\n').length

    return (
      <StyledLineNumbers direction="column" ref={lineNumberRef} theme={theme}>
        {Array(linesCount).fill(1).map((_, index) => (
          <StyledLineNumber
            as="span"
            color={theme === 'dark' ? 'light500' : 'dark500'}
            fontSize={14}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {index + 1}
          </StyledLineNumber>
        ))}
      </StyledLineNumbers>
    )
  }

  return (
    <StyledWrapper as="label" className={className} direction="column" gap={10}>
      {label && <FieldLabel isTranslatable={isTranslatable}>{label}</FieldLabel>}
      <StyledEditorContainer
        size={size}
        theme={theme}
      >
        {showLineNumbers && renderLineNumbers()}
        <StyledInput
          className={`language-${language}`}
          ref={inputRef}
          {...input}
        />
      </StyledEditorContainer>
      <FieldError error={FieldError.getError(meta)} />
      {helpText && <InputHelpText helpText={helpText} />}
    </StyledWrapper>
  )
})

export type {
  CodeEditorInputProps
}

export {
  TAB_SIZE,
  LARGE_SIZE
}

export default CodeEditorInput
