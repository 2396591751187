import React, { PropsWithChildren } from 'react'

import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'

type FieldWrapperProps = {
  label?: string,
  isTranslatable?:boolean
}
const FieldWrapper = ({ label, isTranslatable, children }:PropsWithChildren<FieldWrapperProps>) => (
  <Flex direction="column" grow={1} gap={10}>
    {label && <FieldLabel isTranslatable={isTranslatable}>{label}</FieldLabel>}
    {children}
  </Flex>
)

export default FieldWrapper
