import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import RadioInput from 'components/inputs/RadioInput'
import Text from 'components/typography/Text'
import updateMenuElements from 'lib/updateMenuElements'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler, { FormPropsWithId } from 'hooks/useSubmitHandler'
import { ComputedMenuElement, MENU_ELEMENT_OPTIMISTIC_PROPERTIES } from 'lib/generateDashboard'
import { CreateMenuElementInput, CreateMenuElementMutationVariables, MenuElementSeparatorStyle, UpdateMenuElementInput, useCreateMenuElementMutation, useUpdateMenuElementMutation } from 'generated/schema'
import { LiveMenuEditorOrchestrator } from './AddMenuElementView'
import { MenuElementPositionContext, useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { SidePaneFooter } from 'components/sidePane'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type InitialValues = Partial<ComputedMenuElement & MenuElementPositionContext>

type Params = ViewParams[Views.ADD_MENU_ELEMENT]

const DEFAULT_SEPARATOR_STYLE = 'RULER' as MenuElementSeparatorStyle

const SEPARATOR_STYLES: { label: string, value: MenuElementSeparatorStyle }[] = [
  {
    label: 'Ruler',
    value: 'RULER'
  },
  {
    label: 'Whitespace',
    value: 'WHITESPACE'
  }
]

function AddMenuSeparatorView() {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const {
    id,
    parentId,
    placement = 'SIDE',
    position,
    separatorStyle = DEFAULT_SEPARATOR_STYLE
  } = initialValues as InitialValues

  const {
    currentDashboard,
    parentIdToMenuElementsMap
  } = useContext(InternalContext)!

  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition } = useMenuElementPositionContext() || {}

  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted: () => stepBackDashboardEditor()
  })

  const [ updateMenuElement ] = useUpdateMenuElementMutation({
    onCompleted: () => stepBackDashboardEditor()
  })

  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    optimisticResponse: {
      response: 'CREATE',
      mutation: 'createMenuElement',
      typename: 'MenuElement',
      defaultValue: MENU_ELEMENT_OPTIMISTIC_PROPERTIES,
      override: (input: CreateMenuElementMutationVariables['input']) => ({
        parentId: input.parentId || null
      })
    },
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleMenuItemFormSubmit = (
    values: FormValues,
    form: FormPropsWithId<UpdateMenuElementInput>['form']
  ) => {
    if (id) {
      return handleUpdateMenuElementSubmit({
        ...values,
        id
      }, form)
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId,
      kind: 'SEPARATOR'
    })
  }

  const renderRadioInputs = () => SEPARATOR_STYLES.map((style) => (
    <Field
      autoFocus={style.value === separatorStyle}
      key={style.value}
      label={style.label}
      component={RadioInput}
      name="separatorStyle"
      type="radio"
      value={style.value}
    />
  ))

  return (
    <Form
      initialValues={{
        id,
        parentId,
        placement,
        position: id ? position : newNonStickyPosition,
        separatorStyle
      }}
      onSubmit={handleMenuItemFormSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={(values) => MenuElement.validate(values, [ 'separatorStyle' ])}
      render={({ handleSubmit, submitting }) => (
        <>
          <DashboardEditorBody>
            <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
              <Grid alignItems="center" gap={16} columns={1}>
                <Text
                  fontSize={18}
                  fontWeight="bold"
                  lineHeight="cozy"
                >
                  Style
                </Text>
                {renderRadioInputs()}
              </Grid>
              <input type="submit" style={{ display: 'none' }} />
            </Flex>
            <LiveMenuEditorOrchestrator />
          </DashboardEditorBody>
          <SidePaneFooter variant="small" isSticky>
            <Flex gap={24} direction="row-reverse">
              <Button type="submit" size="small" label="Submit" disabled={submitting} onClick={handleSubmit} />
              <Button label="Cancel" size="small" variant="outline" mode="subtle" onClick={() => stepBackDashboardEditor()} />
            </Flex>
          </SidePaneFooter>
        </>
      )}
    />
  )
}

export default AddMenuSeparatorView
