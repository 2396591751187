import React from 'react'

import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

type ColorFieldProps = {
  value?: string
}

const SIZE = 30

const StyledColor = styled('div', {
  size: [ SIZE ],
  borderRadius: '100%'
})

function ColorField({ value }: ColorFieldProps) {
  if (!value) return null

  return (
    <Flex justifyContent="space-between">
      <Text>{value}</Text>
      <StyledColor style={{ backgroundColor: value }} />
    </Flex>
  )
}

ColorField.computeDiff = (previousValue?: string, currentValue?: string) => ({
  previousNode: <ColorField value={previousValue} />,
  currentNode: <ColorField value={currentValue} />,
  isDiff: previousValue !== currentValue
})

export default ColorField
