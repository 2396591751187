import rafSchd from 'raf-schd'
import { useEffect, useLayoutEffect, useRef } from 'react'

type MousePosition = {
  x: number | null,
  y: number | null
}

type Options = {
  onUpdate?: (position: MousePosition) => void
}

function isDivElement(
  element: HTMLDivElement | Window
): element is HTMLDivElement {
  return (element as HTMLDivElement).getBoundingClientRect !== undefined
}

const useMousePositionRef = (
  referenceEl?: HTMLDivElement | null,
  options?: Options
) => {
  const { onUpdate } = options || {}
  const mousePositionRef = useRef<MousePosition>({ x: null, y: null })
  const callback = useRef(onUpdate)

  useLayoutEffect(() => {
    callback.current = onUpdate
  })

  useEffect(() => {
    const element = referenceEl || window
    const updateMousePosition = rafSchd((e: Event) => {
      let rect = { top: 0, left: 0 }

      if (isDivElement(element)) {
        const { top, left } = element.getBoundingClientRect()
        rect = { top, left }
      }
      const event = e as MouseEvent
      const position = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
      }

      mousePositionRef.current = position
      callback.current?.(position)
    })

    element.addEventListener('mousemove', updateMousePosition)
    return () => {
      element.removeEventListener('mousemove', updateMousePosition)
    }
  }, [ referenceEl ])

  return mousePositionRef
}

export type {
  MousePosition
}

export default useMousePositionRef
