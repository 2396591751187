import React, { memo } from 'react'

import * as mixins from 'styles/mixins'
import AccountAvatar from 'components/avatars/AccountAvatar'
import AccountMenu from 'components/menus/AccountMenu'
import Flex from 'components/layout/Flex'
import { PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'

const StyledFlex = styled(Flex, {
  ...mixins.transition('fluid'),

  cursor: 'pointer',
  width: TOPBAR_HEIGHT,

  '&:hover': {
    backgroundColor: 'light500'
  }
})

function AccountTopbarItem() {
  const currentAccount = useCurrentAccountContext()!

  return (
    <PopoverContainer
      placement="bottom-end"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ 0, -TOPBAR_HEIGHT ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <StyledFlex alignItems="center" justifyContent="center" {...otherToggleProps}>
          <AccountAvatar account={currentAccount} />
        </StyledFlex>
      )}
      {(popoverProps) => (
        <AccountMenu
          account={currentAccount}
          {...popoverProps}
        />
      )}
    </PopoverContainer>
  )
}

export default memo(AccountTopbarItem)
