import React, { useCallback, useContext, useRef, useState } from 'react'
import uuid from 'uuid-random'
import { Field, useField, useForm } from 'react-final-form'
import { mixed } from 'yup'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable, DroppableProvided } from 'react-beautiful-dnd'

import FieldArray from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import FieldLabel from 'components/form/FieldLabel'
import FileUploadDialog, { FileUploadDialogView } from 'components/modals/FileUploadDialog'
import Flex from 'components/layout/Flex'
import HintBox from 'components/hints/HintBox'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import MediaInput from 'components/inputs/MediaInput'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useAssetUpload from 'hooks/useAssetUpload'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { fieldProps, MEDIA_SIZE_OPTIONS, MEDIA_TYPE_OPTIONS } from 'components/contentEditors/generic/fields/fieldProps'
import { GetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'
import { AssetFragmentFragment, useAssetQuery } from 'generated/schema'
import type { FieldArrayChildrenProps } from 'components/form/FieldArray'
import type { AssetQuery } from 'generated/schema'
import type { MediaInputProps } from 'components/inputs/MediaInput'
import type { UseAssetUploadProps } from 'hooks/useAssetUpload'

type MediaFieldProps = fieldProps<'media'> & {
  isPreview?: boolean,
  setToUndefinedOnDelete?: boolean,
  accept?: string[],
  resourceId?: string
} & UseAssetUploadProps

type SingleMediaFieldProps = MediaFieldProps
  & {
    parentFieldName?: string,
    index?: number,
    accept?: string[],
    onDelete?: MediaInputProps['onDelete'],
    onSelect?: MediaInputProps['onSelect'],
    setFileUploadDialogState: (state: FileUploadDialogState | null) => void
  }

const IMAGE_SIZE = 110
const REQUIRED_MESSAGE = 'Please upload a media file'

const FILE_SIZE_UNIT_MAP = {
  [MEDIA_SIZE_OPTIONS.BYTES]: 1,
  [MEDIA_SIZE_OPTIONS.KILOBYTES]: 1024,
  [MEDIA_SIZE_OPTIONS.MEGABYTES]: 1024 * 1024
}

const getMediaFieldSchema = (settings: MediaFieldProps['settings'] = {}, asset: AssetQuery['asset'], isNullable: boolean) => {
  let schema = mixed().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required(REQUIRED_MESSAGE)
  }
  if (settings.checkSize) {
    if (settings.fileSizeMaximum && settings.fileSizeUnit) {
      schema = schema.test(
        'fileSize',
        'File is too large',
        () => {
          const maximumFileSize = settings.fileSizeMaximum!
          * FILE_SIZE_UNIT_MAP[settings.fileSizeUnit! as MEDIA_SIZE_OPTIONS]
          return Boolean(asset && asset?.size && asset.size < maximumFileSize)
        }
      )
    }
    if (settings.fileSizeMinimum && settings.fileSizeUnit) {
      schema = schema.test(
        'fileSize',
        'File is too small',
        () => {
          const minimumFileSize = settings.fileSizeMinimum!
          * FILE_SIZE_UNIT_MAP[settings.fileSizeUnit! as MEDIA_SIZE_OPTIONS]
          return Boolean(asset && asset?.size && asset.size > minimumFileSize)
        }
      )
    }
  }

  return schema
}

// Cases: MediaField updating (value with URL), MediaField saving (value with asset)
// & preview (value with Blob)
const format = (
  value: any,
  fileURL?: string
) => fileURL || value?.url || value || null

function SingleMediaField({
  helpText,
  name,
  parentFieldName = name,
  index,
  isPreview,
  isNullable = false,
  onDelete,
  settings,
  shouldValidate,
  onSelect,
  setFileUploadDialogState,
  ...others
}: SingleMediaFieldProps) {
  const isMediaFieldLoading = useContext(GetMediaFieldStateContext)
  const { input } = useField(name)
  const { value } = input

  // @ts-ignore
  const fileTypeCategory = others?.fileTypeCategory

  const id = input.value?.id || input.value

  const { data, loading } = useAssetQuery({
    variables: { id },
    skip: !id || input.value?.url
  })

  const asset = data?.asset || value

  const mediaNotAvailable = id && !asset?.url

  const validate = useCallback((value: any) => getMediaFieldSchema(
    settings, asset!, isNullable
  )
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings, asset, isNullable ])

  return (
    <Flex direction="column" gap={16}>
      <Flex alignItems="center" gap={36}>
        <Field
          component={MediaInput}
          format={((currentValue) => (
            format(currentValue, asset?.url)
          ))}
          mediaNotAvailable={Boolean(mediaNotAvailable)}
          height={IMAGE_SIZE}
          index={index}
          isLoading={(!asset && (loading)) || isMediaFieldLoading?.includes(name)}
          name={name}
          parentFieldName={parentFieldName}
          onDelete={onDelete}
          onSelect={onSelect}
          previewFit="contain"
          fileTypeCategory={fileTypeCategory}
          includeExtensions={settings?.includeExtensions}
          width={IMAGE_SIZE}
          onClick={() => {
            setFileUploadDialogState({
              view: input.value ? 'preview' : 'upload',
              index: index || 0
            })
          }}
          {...(shouldValidate && { validate })}
          {...others}
        />
        <Flex direction="column" gap={6} alignItems="flex-start">
          <Text color={value ? 'dark900' : 'dark500'} fontSize={12} fontWeight={value ? 'bold' : 'regular'}>
            {value ? (asset?.name || value?.name) : getEmptyText(fileTypeCategory)}
          </Text>
          {value
            ? (
              <Flex alignItems="center" gap={10}>
                <TextLink
                  as="button"
                  type="button"
                  fontSize={12}
                  mode="subtle"
                  variant="underlined"
                  onClick={() => setFileUploadDialogState({
                    view: 'upload',
                    index: index || 0
                  })}
                >
                  Replace
                </TextLink>
                <TextLink
                  as="button"
                  type="button"
                  fontSize={12}
                  mode="subtle"
                  variant="underlined"
                  onClick={() => onDelete?.(index)}
                >
                  Remove
                </TextLink>
              </Flex>
            ) : (
              <TextLink
                as="button"
                type="button"
                fontSize={12}
                variant="underlined"
                shrink={0}
                onClick={() => setFileUploadDialogState({
                  view: 'upload',
                  index: index || 0
                })}
              >
                {getAddText(fileTypeCategory)}
              </TextLink>
            )}
        </Flex>
      </Flex>
      {helpText && <InputHelpText helpText={helpText} />}
    </Flex>
  )
}

const getEmptyText = (fileTypeCategory?: MEDIA_TYPE_OPTIONS) => {
  let mediaSpecificText = ''

  switch (fileTypeCategory) {
    case MEDIA_TYPE_OPTIONS.IMAGE:
      mediaSpecificText = 'No image uploaded'
      break
    case MEDIA_TYPE_OPTIONS.VIDEO:
      mediaSpecificText = 'No video uploaded'
      break
    case MEDIA_TYPE_OPTIONS.AUDIO:
      mediaSpecificText = 'No audio uploaded'
      break
    default:
      mediaSpecificText = 'No file uploaded'
  }

  return mediaSpecificText
}

const getAddText = (fileTypeCategory?: MEDIA_TYPE_OPTIONS) => {
  let addText = ''

  switch (fileTypeCategory) {
    case MEDIA_TYPE_OPTIONS.IMAGE:
      addText = 'Add Image'
      break
    case MEDIA_TYPE_OPTIONS.VIDEO:
      addText = 'Add Video'
      break
    case MEDIA_TYPE_OPTIONS.AUDIO:
      addText = 'Add Audio'
      break
    default:
      addText = 'Add File'
  }

  return addText
}

type FileUploadDialogState = {
  view: FileUploadDialogView,
  index: number
}

function MediaField({
  name,
  label,
  isArray,
  isTranslatable,
  isPreview = false,
  isNullable,
  settings,
  shouldValidate,
  attribute,
  onUploadStart,
  onUploadEnd,
  onUploadSuccess,
  resource,
  targetEnvironment,
  ...others
}: MediaFieldProps) {
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  const onDragEnd = useReorderFieldArray(fieldsRef)
  const [ fileUploadDialogState, setFileUploadDialogState ] = useState<
    FileUploadDialogState | null
  >(null)

  const { change } = useForm()

  const { upload } = useAssetUpload({
    attribute,
    resource,
    targetEnvironment,
    onUploadStart,
    onUploadEnd,
    onUploadSuccess: onUploadSuccess || ((asset, meta) => {
      const { fieldName } = meta || {}
      change(fieldName, asset)
    })
  })

  const onDelete = (currentIndex: number) => {
    if (currentIndex !== undefined) {
      if (isArray) {
        fieldsRef.current?.fields?.remove(currentIndex)
      }
    } else {
      change(name, others.setToUndefinedOnDelete ? undefined : null)
    }
  }

  const commonProps = {
    settings,
    shouldValidate,
    isPreview,
    isNullable,
    onDelete
  }

  let dialogTitle = ''

  switch (settings?.fileTypeCategory) {
    case MEDIA_TYPE_OPTIONS.IMAGE:
      dialogTitle = 'Image Manager'
      break
    case MEDIA_TYPE_OPTIONS.VIDEO:
      dialogTitle = 'Video Manager'
      break
    case MEDIA_TYPE_OPTIONS.AUDIO:
      dialogTitle = 'Audio Manager'
      break
    default:
      dialogTitle = 'File Manager'
  }

  const onSelect = (media: Blob, mediaName: string, index: number) => {
    const fieldName = isArray ? FieldArray.getFieldName(name, index) : name

    if (isPreview) {
      return change(fieldName, media)
    }
    return upload(media, mediaName, { fieldName, index })
  }

  const onDialogSubmit = (media: (File | AssetFragmentFragment)[]) => (
    Promise.all(media.map((m, i) => m instanceof File && onSelect(m, m.name, i)))
  )

  const droppableId = useRef(uuid())

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <FieldGroup
        {...provided}
        isDraggable
      >
        <SingleMediaField
          onSelect={onSelect}
          setFileUploadDialogState={setFileUploadDialogState}
          parentFieldName={name}
          name={FieldArray.getFieldName(name, rubric.source.index)}
          index={rubric.source.index}
          {...({
            checkRequired: settings?.checkRequired,
            placeholder: settings?.placeholder,
            fileTypeCategory: settings?.fileTypeCategory
          })}
          {...commonProps}
          {...others}
        />
      </FieldGroup>
    </div>
  )

  return (
    <Flex key={isArray?.toString() || 'single'} direction="column" grow={1} gap={10}>
      {!isArray && (
        <SingleMediaField
          label={label}
          onSelect={onSelect}
          setFileUploadDialogState={setFileUploadDialogState}
          name={name}
          {...({
            checkRequired: settings?.checkRequired,
            placeholder: settings?.placeholder,
            helpText: settings?.helpText,
            fileTypeCategory: settings?.fileTypeCategory
          })}
          {...commonProps}
          {...others}
        />
      )}
      {isArray && (
        <>
          {label && (
          <FieldLabel
            isTranslatable={isTranslatable}
            checkRequired={settings?.checkRequired}
          >
            {label}
          </FieldLabel>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={droppableId.current}
              renderClone={renderDraggableChildren}
            >
              {(droppableProvided: DroppableProvided) => (
                <Flex
                  direction="column"
                  gap={10}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  <FieldArray
                    name={name}
                    fieldsRef={fieldsRef}
                    settings={settings}
                    shouldValidate={shouldValidate}
                    subscription={{ value: true }}
                  >
                    {({ keys }) => {
                      if (keys.length === 0) {
                        return (
                        // For empty repeated media, we want to display the field,
                        // but not have the value populated in the form
                          <SingleMediaField
                            onSelect={onSelect}
                            setFileUploadDialogState={setFileUploadDialogState}
                            parentFieldName={name}
                            name={`${name}[0]`}
                            key={`${name}[0]`}
                            index={0}
                            {...({
                              checkRequired: settings?.checkRequired,
                              placeholder: settings?.placeholder
                            })}
                            {...commonProps}
                            {...others}
                          />
                        )
                      }

                      return (
                        <>
                          {keys.map((key, index) => (
                            <Draggable
                              disableInteractiveElementBlocking
                              draggableId={key}
                              index={index}
                              key={key}
                            >
                              {renderDraggableChildren}
                            </Draggable>
                          ))}
                        </>
                      )
                    }}
                  </FieldArray>
                  {droppableProvided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
          <Flex gap={10}>
            {settings?.helpText && <InputHelpText helpText={settings.helpText} />}
            <FieldArray
              fieldsRef={fieldsRef}
              name={name}
              subscription={{ value: true }}
            >
              {({ fields }) => {
                const shouldShowPlusIcon = Boolean(fields.length)
                && !fields.value.includes(undefined)

                return shouldShowPlusIcon ? (
                  <TextLink
                    as="button"
                    type="button"
                    fontSize={12}
                    variant="underlined"
                    shrink={0}
                    onClick={() => setFileUploadDialogState({
                      view: 'upload',
                      index: fields.value.length
                    })}
                  >
                    {getAddText(settings?.fileTypeCategory)}
                  </TextLink>
                ) : <></>
              }}
            </FieldArray>
          </Flex>
          <Field
            name={name}
            render={({ meta }) => {
              const fieldArrayError = typeof meta.error === 'string' && meta.touched && meta.error
              if (!fieldArrayError) return null
              return <HintBox size="small" variant="error" icon="error">{fieldArrayError}</HintBox>
            }}
          />
        </>
      )}
      {/* @ts-ignore */}
      {!others.showLegacyModal && (
        <FileUploadDialog
          isOpen={!!fileUploadDialogState}
          title={dialogTitle}
          onClose={() => {
            setFileUploadDialogState(null)
          }}
          onSubmit={onDialogSubmit}
          fileTypeCategory={settings?.fileTypeCategory!}
          accept={others.accept}
            // @ts-ignore
          includeExtensions={others.includeExtensions}
          isArray={isArray}
          name={name}
          view={fileUploadDialogState?.view}
          index={fileUploadDialogState?.index}
        />
      )}
    </Flex>
  )
}

export { getMediaFieldSchema }
export type { MediaFieldProps }

export default MediaField
