import React from 'react'
import { Field } from 'react-final-form'

import RatingInput from 'components/inputs/RatingInput'
import type { RatingInputOwnProps } from 'components/inputs/RatingInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type RatingFieldProps = RatingInputOwnProps & fieldProps<'rating'>

function RatingField({ name, ...others }: RatingFieldProps) {
  return (
    <Field
      component={RatingInput}
      name={name}
      {...others}
    />
  )
}

export default RatingField
