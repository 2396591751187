import Box from 'components/layout/Box'
import { generateThemeVariants, generateVariants, styled } from 'styles/stitches'

const contentOptions = [ 'start', 'end', 'center', 'stretch', 'space-between', 'space-around', 'space-evenly' ] as const
const itemsOptions = [ 'start', 'end', 'center', 'stretch' ] as const
const columnOptions = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ] as const

const alignContentVariants = generateVariants(contentOptions, 'alignContent')
const justifyContentVariants = generateVariants(contentOptions, 'justifyContent')
const alignItemsVariants = generateVariants(itemsOptions, 'alignItems')
const justifyItemsVariants = generateVariants(itemsOptions, 'justifyItems')
const gapVariants = generateThemeVariants('space', 'gap')
const columnGapVariants = generateThemeVariants('space', 'columnGap')
const rowGapVariants = generateThemeVariants('space', 'rowGap')

const columnVariants = columnOptions
  .reduce((acc, curr) => {
    acc[curr] = { gridTemplateColumns: `repeat(${curr}, minmax(0, 1fr))` }
    return acc
  }, {} as Record<number, Record<string, string>>)

const Grid = styled(Box, {
  variants: {
    alignContent: alignContentVariants,
    alignItems: alignItemsVariants,
    columnGap: columnGapVariants,
    display: {
      grid: {
        display: 'grid'
      },
      'inline-grid': {
        display: 'inline-grid'
      }
    },
    gap: gapVariants,
    columns: columnVariants,
    justifyContent: justifyContentVariants,
    justifyItems: justifyItemsVariants,
    rowGap: rowGapVariants
  }
})

Grid.defaultProps = {
  display: 'grid'
}

export default Grid

export type FlexProps = StyledProps<typeof Grid>
