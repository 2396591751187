import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { DragOverlay } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useRecoilValue } from 'recoil'

import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import { APP_LIST_LIMIT } from 'models/App'
import { App, useAppCategoriesListQuery, useAppListQuery, useInstallationsListQuery } from 'generated/schema'
import { DraggableAppCard } from '../menu/AddMenuAppView'
import { Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'

type AppGridProps = {
  apps: App[],
  installedAppIds: App['id'][],
  draggedApp: App | null,
  title: string
}

const AppsView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Apps"
        heading="Dashboard Editor"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>Extend your workspace&apos;s functionality with powerful, out of the box Apps.
          {' '}<TextLink variant="underlined" href="https://docs.dashx.com">Learn more</TextLink>
        </Text>
        <AppsCategoryGrid search={search} />
      </DashboardEditorBody>
    </>
  )
}

const useApps = (kind: 'EXTENSION' | 'PROJECT' = 'EXTENSION') => {
  const {
    data: { appCategoriesList = [] } = {},
    error: appCategoriesListError,
    loading: appCategoriesListLoading
  } = useAppCategoriesListQuery({
    variables: {
      filter: {
        kind: { eq: 'EXTENSION' }
      },
      limit: APP_LIST_LIMIT,
      order: [ { position: 'asc' } ]
    },
    skip: kind === 'PROJECT'
  })

  const {
    data: { installationsList = [] } = {},
    error: installationsListError,
    loading: installationsListLoading
  } = useInstallationsListQuery({
    variables: {
      filter: {
        archivedAt: 'null',
        or: [
          { appKind: { eq: kind } }
        ]
      }
    },
    skip: kind === 'PROJECT'
  })

  const {
    data: { appsList = [] } = {},
    error: appsListError,
    loading: appsListLoading
  } = useAppListQuery({
    variables: {
      filter: {
        or: [
          { kind: { eq: kind } }
        ]
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const installedAppIds = installationsList.map(({ appId }) => appId)

  return {
    appCategoriesList,
    appsList,
    installationsList,
    installedAppIds,
    loading: installationsListLoading || appsListLoading || appCategoriesListLoading,
    error: installationsListError || appsListError || appCategoriesListError
  }
}

const AppsCategoryGrid = ({ search = '' }: { search: string }) => {
  const { draggedAppState } = useDashboard()
  const draggedApp = useRecoilValue(draggedAppState)

  const {
    appCategoriesList,
    appsList,
    installationsList,
    installedAppIds,
    loading,
    error
  } = useApps()

  return (
    <>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={installationsList && appsList && appCategoriesList}
        loading={loading}
        error={error}
      >
        {appCategoriesList.map((category) => {
          const categorizedApps = appsList.filter((app) => app.appCategoryId === category.id)

          const filteredApps = categorizedApps?.filter(
            (app) => app.name.toLowerCase().includes(search.toLowerCase())
          )

          if (!filteredApps.length) return null

          return (
            <AppsGrid
              draggedApp={draggedApp}
              apps={filteredApps}
              installedAppIds={installedAppIds}
              key={category.id}
              title={`${category.name} Apps`}
            />
          )
        })}
        {createPortal(
          <DragOverlay dropAnimation={null} modifiers={[ restrictToWindowEdges ]}>
            {draggedApp?.id ? (
              <DraggableAppCard
                app={appsList.find((app) => app.id === draggedApp.id)}
                isActive
                isOverlay
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DashboardEditorLoader>
    </>
  )
}

const AppsGrid = ({ apps, title = '', draggedApp, installedAppIds }: AppGridProps) => {
  const { openDashboardEditorView } = useDashboard()

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Flex direction="column" gap={2}>
        {apps?.map((app) => {
          const onClick = () => openDashboardEditorView({
            target: Views.APP_DETAILS,
            params: { app }
          })

          const isActive = draggedApp?.id === app.id

          return (
            <DraggableAppCard
              key={app.id}
              app={app}
              onClick={onClick}
              isActive={isActive}
              isInstalled={installedAppIds?.includes(app.id)}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export { useApps }

export default AppsView
