import Flex from 'components/layout/Flex'
import { INNER_CONTAINER_PADDING } from 'components/dashboardEditor/constants'
import { SIDE_PANE_PADDING_X } from 'components/sidePane/constants'
import { styled } from 'styles/stitches'

const SUB_HEADER_PADDING_Y = 15

const SidePaneSubHeader = styled(Flex, {
  backgroundColor: 'light100',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: 'light700',
  paddingY: SUB_HEADER_PADDING_Y,
  paddingX: SIDE_PANE_PADDING_X,
  width: '100%',
  variants: {
    size: {
      small: {
        paddingX: INNER_CONTAINER_PADDING
      },
      normal: {}
    }
  }
})

SidePaneSubHeader.defaultProps = {
  alignItems: 'center'
}

export default SidePaneSubHeader
