import React from 'react'
import type { ReactNode } from 'react'

import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import type { FlexProps } from 'components/layout/Flex'
import type { Space } from 'styles/theme'

type PopoverFooterProps = FlexProps & {
  children?: ReactNode,
  className?: string,
  withDivider?: boolean
}

const POPOVER_FOOTER_SPACING_Y: Space = 14

function PopoverFooter({ children, withDivider = true, ...props }: PopoverFooterProps) {
  return (
    <>
      {withDivider && <Divider />}
      <Flex {...props}>
        {children}
      </Flex>
    </>
  )
}

export default PopoverFooter

export { POPOVER_FOOTER_SPACING_Y }
