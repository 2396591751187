import React from 'react'

import AccordianItem from 'components/accordian/AccordianItem'

import * as mixins from 'styles/mixins'
import DataManagerProvider from 'components/providers/DataManagerProvider'
import Flex from 'components/layout/Flex'
import { styled } from 'styles/stitches'
import type { DataManagerProviderProps, DEFAULT_ROW_DATA } from 'components/providers/DataManagerProvider'

import listItemSkeletonImage from 'assets/images/list-item-skeleton-colored.svg'

type Content<T = any> = {
  icon?: string,
  title: (data: T) => string,
  content: (data: T) => string | React.ReactNode
}

type AccordianProps<T extends DEFAULT_ROW_DATA> =
  & Pick<DataManagerProviderProps<T>, 'data'>
  & {
    content: Content<T>
}

const AccordianBody = styled(Flex, {
  ...mixins.transition('fluid', 'all', 0.2),

  backgroundImage: `url(${listItemSkeletonImage})`,
  backgroundRepeat: 'no-repeat repeat',
  position: 'relative',
  width: '100%',
  flexDirection: 'column',
  gap: 8
})

function Accordian<T extends DEFAULT_ROW_DATA>({
  content,
  data
}: AccordianProps<T>) {
  return (
    <Flex direction="column" gap={24}>
      <DataManagerProvider data={data}>
        <AccordianBody>
          {data.map((rowData) => <AccordianItem content={content} data={rowData} />)}
        </AccordianBody>
      </DataManagerProvider>
    </Flex>
  )
}

export default Accordian

export type {
  Content,
  AccordianProps
}
