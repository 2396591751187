import React from 'react'

import GroupsView from 'components/views/GroupsView'
import { Kind } from 'models/Account'
import type { DashboardViewComponentProps } from './DashboardPage'

const GroupsPage = ({ appId }: DashboardViewComponentProps) => (
  <GroupsView accountKinds={[ Kind.MEMBER, Kind.SERVICE ]} appId={appId} />
)

export default GroupsPage
