import arrayMutators from 'final-form-arrays'
import omit from 'lodash/omit'
import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, DraggableChildrenFn, Droppable } from 'react-beautiful-dnd'
import { Form, FormProps, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator, FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataTypeModel, { DataTypeKind, DEFAULT_DATA_TYPES } from 'models/DataType'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import FormValuesField from 'components/form/FormValuesField'
import Icon from 'components/icons/Icon'
import MediaCard from 'components/mediaCard/MediaCard'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import SectionLoader from 'components/loaders/SectionLoader'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useGetAllowedFieldTypes from 'hooks/useGetAllowedFieldTypes'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { CreateDataTypeInput, DataTypeFragmentFragment, DataTypesListDocument, DataTypesListQuery, DataTypesListQueryVariables, UpdateDataTypeInput, useCreateDataTypeMutation, useDataTypesListQuery, useFieldTypesListQuery, useUpdateDataTypeMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'
import { getAllowedDisplayTypes } from './CreateAttributeView'
import { OptionsConfiguration } from 'components/fieldViews/DropdownFieldView'
import { parseValidations } from './AttributeValidations'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from '../DashboardEditor'
import type { FieldIdentifier } from 'models/Field'

type FormValues = CreateDataTypeInput | UpdateDataTypeInput

type Params = ViewParams[Views.CREATE_DATA_TYPE]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const decorators = [
  setIdentifier
]

const Fields = () => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    saveDashboardEditorViewState
  } = useDashboard()
  const { params = {} } = useRecoilValue<DashboardEditorView<Views.CREATE_DATA_TYPE>>(
    dashboardEditorState
  )

  const { values: dataType } = useFormState<FormValues>()

  const droppableId = useRef(uuid())

  const { data: { fieldTypes } = {} } = useFieldTypesListQuery()

  const onAdd = () => {
    saveDashboardEditorViewState({ ...params, initialValues: dataType })
    openDashboardEditorView({
      target: Views.CREATE_DATA_TYPE_FIELD,
      params: { parentDataType: dataType as any }
    })
  }

  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name: 'settings.fields', fieldsRef })
  const onDragEnd = useReorderFieldArray(fieldsRef)

  const confirm = useConfirmation({ style: 'DIALOG' })

  const fields = dataType?.settings?.fields || []

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const field = fields[rubric.source.index]
    const fieldType = fieldTypes?.find((type) => type.identifier === field.fieldType)
    const onDelete = () => {
      confirm({
        action: 'delete',
        onConfirmClick: async () => {
          fieldsRef.current?.fields.remove(rubric.source.index)
        },
        recordType: 'Field',
        recordDescription: field.name
      })
    }

    const onClick = () => {
      saveDashboardEditorViewState({ ...params, initialValues: dataType })
      openDashboardEditorView({
        target: Views.CREATE_DATA_TYPE_FIELD,
        params: { currentIndex: rubric.source.index, parentDataType: dataType as any }
      })
    }

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <MediaCard
          compact
          key={field.id}
          media={(
            <Icon name="drag" size={16} color="dark100" />
          )}
          title={field.name}
          height={64}
          width="full"
          onClick={() => onClick()}
          actions={[
            {
              icon: 'trash',
              description: 'Delete',
              onClick: () => onDelete(),
              variant: 'negative'
            },
            {
              description: `${(field.fieldType || 'Computed').replace('-field', '')}`,
              icon: `${fieldType?.icon || 'code'}`,
              onClick,
              isIconAlwaysVisible: true
            }, {
              description: '',
              icon: 'arrow-right',
              onClick,
              isIconAlwaysVisible: true
            }
          ]}
        />
      </div>
    )
  }

  return (
    <Flex gap={16} direction="column">
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Fields
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <Flex direction="column" gap={2}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={droppableId.current}
            renderClone={renderDraggableChildren}
          >
            {(droppableProvided) => (
              <Flex
                direction="column"
                gap={2}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {fields.map((field: any, index: number) => (
                  <Draggable
                    disableInteractiveElementBlocking
                    draggableId={field.id}
                    index={index}
                    key={field.id}
                  >
                    {renderDraggableChildren}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </Flex>
  )
}

const DataTypeIds = () => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    saveDashboardEditorViewState
  } = useDashboard()

  const { params = {} as Params } = useRecoilValue<DashboardEditorView<Views.CREATE_DATA_TYPE>>(
    dashboardEditorState
  )

  const droppableId = useRef(uuid())

  const { data: { fieldTypes } = {} } = useFieldTypesListQuery()

  const onAdd = () => {
    const dataType: any = form.getState().values
    saveDashboardEditorViewState({ ...params, initialValues: dataType })
    openDashboardEditorView({
      target: Views.CREATE_DATA_TYPE,
      params: {
        heading: dataType.name!,
        initialValues: {
          kind: DataTypeKind.OBJECT
        } as any,
        sourceKind: 'DATA_TYPE'
      }
    })
  }

  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  const { fields } = useFieldArray({ name: 'settings.data_types', fieldsRef })
  const onDragEnd = useReorderFieldArray(fieldsRef)

  const confirm = useConfirmation({ style: 'DIALOG' })
  const form = useForm()

  const initialDataTypeIds = params.initialValues?.settings?.data_type_ids
  const { data: { dataTypesList } = {}, loading, error } = useDataTypesListQuery({
    variables: {
      filter: {
        id: {
          in: initialDataTypeIds
        }
      }
    },
    skip: !initialDataTypeIds?.length,
    onCompleted: (data) => {
      fields
        .change(initialDataTypeIds.map((id: string) => data.dataTypesList.find((d) => d.id === id)))
    }
  })

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => {
    const field = fields.value[rubric.source.index]
    const fieldType = fieldTypes?.find((type) => type.identifier === field.fieldType)
    const onDelete = () => {
      confirm({
        action: 'delete',
        onConfirmClick: async () => {
          fieldsRef.current?.fields.remove(rubric.source.index)
        },
        recordType: 'DataType',
        recordDescription: field.name
      })
    }

    const onClick = () => {
      const dataType: any = form.getState().values
      saveDashboardEditorViewState({ ...params, dataType })
      openDashboardEditorView({
        target: Views.CREATE_DATA_TYPE,
        params: {
          heading: dataType.name,
          initialValues: field as any,
          sourceKind: 'DATA_TYPE'
        }
      })
    }

    const editAction = {
      description: `${field.fieldType?.replace('-field', '')}`,
      icon: `${fieldType?.icon || 'text-field'}`,
      onClick,
      isIconAlwaysVisible: true
    }

    const viewAction = {
      description: '',
      icon: 'arrow-right',
      onClick,
      isIconAlwaysVisible: true
    }

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <MediaCard
          compact
          key={field.id}
          media={(
            <Icon name="drag" size={16} color="dark100" />
          )}
          title={field.name}
          height={64}
          width="full"
          onClick={() => onClick()}
          actions={[
            {
              icon: 'trash',
              description: 'Delete',
              onClick: () => onDelete(),
              variant: 'negative'
            },
            ...([ DataTypeKind.UNION, DataTypeKind.OBJECT ].includes(field.kind)
              ? [ editAction, viewAction ]
              : [ { icon: 'pad-lock', description: '' } ]
            )
          ]}
        />
      </div>
    )
  }

  const getOptionDisabled = (option: DataTypeFragmentFragment) => (
    fields.value
      ? fields.value.findIndex((f) => f?.id === option.id) !== -1
      : false
  )

  return (
    <Flex gap={16} direction="column">
      <Flex direction="column" gap={10}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Data Types
        </Text>
        <Flex gap={20}>
          <SearchSelectField<DataTypesListQuery, DataTypesListQueryVariables>
            disabled={loading}
            isSearchable
            preload
            name="settings.data_types"
            prependIcon="search"
            placeholder="Search data type"
            options={[]}
            labelKey="name"
            valueKey="id"
            metaKey="identifier"
            size="small"
            query={DataTypesListDocument}
            dataKey="dataTypesList"
            keys={[ 'name', 'identifier' ]}
            isOptionDisabled={getOptionDisabled}
            setValueAsObject
            input={{
              value: null,
              onChange: (value: DataTypeFragmentFragment) => fields.push(value)
            }}
          />
          <Button icon="add-thin" size="small" onClick={() => onAdd()} />
        </Flex>
      </Flex>
      <WhenFieldChanges field="settings.data_types" set="settings.data_type_ids" to={(dataTypes: any[]) => dataTypes.map(({ id }) => id)} />
      <SectionLoader loading={loading} data={initialDataTypeIds ? dataTypesList : {}} error={error} empty={{ title: 'No data types selected.' }}>
        <Flex direction="column" gap={2}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={droppableId.current}
              renderClone={renderDraggableChildren}
            >
              {(droppableProvided) => (
                <Flex
                  direction="column"
                  gap={2}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {fields.map((field, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={field}
                      index={index}
                      key={field}
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </SectionLoader>
    </Flex>
  )
}

const Options = () => (
  <Flex gap={16} direction="column">
    <FormField
      name="settings.enable_option_keys"
      component={ToggleInput}
      label="Add key for each option?"
      type="checkbox"
    />
    <FormField
      name="settings.enable_option_colors"
      component={ToggleInput}
      label="Add color for each option?"
      type="checkbox"
    />
    <OptionsConfiguration fieldSettingsPrefix="settings." />
  </Flex>
)

const DATA_TYPES_LIST_LIMIT = 100

const CreateDataTypeView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const {
    params: { initialValues: dataType, heading, sourceKind } = {}
  } = useRecoilValue<DashboardEditorView<Views.CREATE_DATA_TYPE>>(
    dashboardEditorState
  )

  const getAllowedFieldTypes = useGetAllowedFieldTypes()

  const isUpdating = dataType && 'id' in dataType

  const [ createDataType ] = useCreateDataTypeMutation({
    onCompleted: ({ createDataType: dataType }) => {
      if (sourceKind === 'ROW') {
        const [ defaultDisplayType ] = getAllowedDisplayTypes(
          dataType.kind as DataTypeKind
        )
        stepBackDashboardEditor(1, (params) => ({
          ...params,
          initialValues: {
            ...params.initialValues || {},
            data_type: dataType.id,
            display_type: defaultDisplayType?.value
          }
        }))
      }

      if (sourceKind === 'ATTRIBUTE') {
        const [ defaultFieldType ] = getAllowedFieldTypes(dataType).fieldTypes
        const defaultFieldTypeIdentifier = defaultFieldType?.identifier as FieldIdentifier
        const [ defaultDisplayType ] = getAllowedDisplayTypes(
          dataType.kind as DataTypeKind,
          defaultFieldTypeIdentifier
        )
        stepBackDashboardEditor(1, (params) => ({
          ...params,
          initialValues: {
            ...params.initialValues || {},
            dataTypeId: dataType.id,
            dataType,
            validations: [],
            fieldType: defaultFieldTypeIdentifier,
            displayType: defaultDisplayType?.value
          }
        }))
      }

      if (sourceKind === 'DATA_TYPE') {
        stepBackDashboardEditor(1, (params) => ({
          ...params,
          initialValues: {
            ...params.initialValues || {},
            settings: {
              ...params.initialValues?.settings || {},
              data_types: (params.initialValues?.settings?.data_types || []).concat([ dataType ])
            }
          }
        }))
      }
    },
    refetchQueries: [ {
      query: DataTypesListDocument,
      variables: {
        limit: DATA_TYPES_LIST_LIMIT,
        filter: {
          kind: {
            eq: DataTypeKind.OBJECT
          }
        }
      }
    } ]
  })

  const [ updateDataType ] = useUpdateDataTypeMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ {
      query: DataTypesListDocument,
      variables: {
        limit: DATA_TYPES_LIST_LIMIT,
        filter: {
          kind: {
            eq: DataTypeKind.OBJECT
          }
        }
      }
    } ]
  })

  const handleCreateDataType = useSubmitHandler(createDataType, {
    successAlert: { message: 'Data Type Created.' }
  })

  const handleUpdateDataType = useSubmitHandler(updateDataType, {
    successAlert: { message: 'Data Type Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    let parsedValues: any = omit(values, 'settings.data_types')
    if (values.validations?.length) {
      parsedValues = parseValidations(values as any)
    }

    if (isUpdating) {
      return handleUpdateDataType(
        parsedValues as UpdateDataTypeInput,
        form as FormApi<UpdateDataTypeInput>
      )
    }

    return handleCreateDataType(parsedValues as CreateDataTypeInput)
  }

  const fields = dataType?.settings?.fields || []

  return (
    <>
      <DashboardEditorHeader
        heading={heading || 'Back'}
        subtitle={`${isUpdating ? 'Edit' : 'New'} Data Type`}
        onClose={onClose}
      />
      <Form
        key={dataType?.id || heading}
        decorators={decorators}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          position: 0,
          ...(dataType as FormValues)
        }}
        keepDirtyOnReinitialize
        validate={(values) => DataTypeModel.validate(values, [ 'identifier', 'name' ])}
        subscription={{ submitting: true }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit}>
                <Flex direction="column" gap={24}>
                  <FormField
                    autoFocus
                    checkRequired
                    component={TextInput}
                    name="name"
                    label="Name"
                    size="small"
                    type="text"
                  />
                  <FormField
                    checkRequired
                    component={TextInput}
                    name="identifier"
                    label="Identifier"
                    size="small"
                    type="text"
                  />
                  {!isUpdating && (
                    <FormField
                      name="kind"
                      label="Data Type"
                      component={SelectInput}
                      options={DEFAULT_DATA_TYPES}
                      labelKey="name"
                      valueKey="kind"
                      size="small"
                    />
                  )}
                  <ConditionalField when="kind" is={DataTypeKind.OBJECT}>
                    <FormField
                      isDisabled={!fields.length}
                      isClearable
                      name="settings.title_field_id"
                      label="Title Field"
                      component={SelectInput}
                      options={fields}
                      labelKey="name"
                      valueKey="id"
                      size="small"
                    />
                    <FormValuesField fieldNames={[ 'settings.title_field_id' ]}>
                      {({ settings }) => (
                        <FormField
                          isDisabled={!fields.length}
                          isClearable
                          name="settings.subtitle_field_id"
                          label="Subtitle Field"
                          component={SelectInput}
                          options={
                            fields.filter((field: any) => field.id !== settings?.title_field_id)
                          }
                          labelKey="name"
                          valueKey="id"
                          size="small"
                        />
                      )}
                    </FormValuesField>
                    <Fields />
                  </ConditionalField>
                  <ConditionalField when="kind" is={DataTypeKind.UNION}>
                    <DataTypeIds />
                  </ConditionalField>
                  <ConditionalField when="kind" is={DataTypeKind.ENUM}>
                    <Options />
                  </ConditionalField>
                </Flex>
                <FormField name="settings" type="hidden" alwaysDirty defaultValue={{}} />
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateDataTypeView
