import debounce from 'lodash/debounce'
import fuzzysort from 'fuzzysort'
import { useMemo, useState } from 'react'

function useFuzzySearch<TData>(data: readonly TData[], options: Pick<Fuzzysort.KeyOptions, 'key'>) {
  const [ query, setQuery ] = useState<string>('')

  const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }, 100, { leading: true, trailing: false })

  const matches = useMemo(() => (
    query ? fuzzysort.go(query, [ ...data ], { key: options.key }).map((i) => i.obj) : [ ...data ]
  ), [ data, options.key, query ])

  return {
    handleChange,
    matches,
    query
  }
}

export default useFuzzySearch
