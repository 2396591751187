import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { ErrorBoundary } from 'react-error-boundary'
import { loadStripe } from '@stripe/stripe-js'
import { RecoilRoot } from 'recoil'

import Alert from 'components/alert/Alert'
import AppContainer from 'components/containers/AppContainer'
import AppError from 'components/errors/AppError'
import AppLoader from 'components/loaders/AppLoader'
import ClientProvider from 'components/providers/ClientProvider'
import GlobalProvider from 'components/providers/GlobalProvider'
import reportError from 'lib/reportError'
import useDocumentTitle from 'hooks/useDocumentTitle'
import ViewProvider from 'components/providers/ViewProvider'
import WorkspaceContainer from 'components/containers/WorkspaceContainer'
import { isAppHostname } from 'lib/hostname'
import { isElectron } from 'lib/electron'

const {
  REACT_APP_STRIPE_PUBLISHABLE_KEY = ''
} = process.env

const RouterComponent = isElectron ? HashRouter : BrowserRouter as React.ElementType

function Root() {
  useDocumentTitle()

  return (
    <ErrorBoundary FallbackComponent={AppError} onError={reportError}>
      <ClientProvider>
        {({ apolloClient }) => {
          if (!apolloClient) {
            return <AppLoader />
          }

          return (
            <ApolloProvider client={apolloClient}>
              <GlobalProvider>
                <ViewProvider>
                  <RecoilRoot>
                    <Elements stripe={loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY)}>
                      <RouterComponent>
                        {isAppHostname ? <AppContainer /> : <WorkspaceContainer />}
                      </RouterComponent>
                    </Elements>
                  </RecoilRoot>
                </ViewProvider>
                <Alert />
              </GlobalProvider>
            </ApolloProvider>
          )
        }}
      </ClientProvider>
    </ErrorBoundary>
  )
}

export default Root
