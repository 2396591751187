import { useMemo, useRef } from 'react'

import JSONParseOr from 'lib/JSONParseOr'
import { parseAndRenderSync } from 'lib/templater'
import { useExecuteQueryOperationQuery, useOperationQuery } from 'generated/schema'
import { ValidationKind } from 'models/Attribute'

type UseExecuteOperationWithParametersProps = {
  operationId: string,
  arguments: Record<any, any>,
  context: Record<any, any>,
  targetEnvironment?: string
}

const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str || '', ...params)
  } catch (e) {
    console.error(e)

    return str
  }
}

function useExecuteOperationQuery({
  operationId,
  arguments: args,
  context,
  targetEnvironment
}: UseExecuteOperationWithParametersProps) {
  const { data: { operation } = {} } = useOperationQuery({
    variables: { id: operationId },
    skip: !operationId
  })

  const parameters = operation?.parameters

  const aborterRef = useRef(new AbortController())

  const { parsedArguments, isInvalid } = useMemo(() => {
    aborterRef.current.abort()
    aborterRef.current = new AbortController()
    const parsedParams = (parameters || [])
      .map((p) => {
        const defaultValue = JSONParseOr(safeParseLiquid(p.defaultValue))
        || (p.isNullable ? null : undefined)
        const isRequired = p.validations?.some((v) => v.kind === ValidationKind.PRESENCE)
        if (args) {
          const paramString = args[p.identifier]
          const value = paramString && JSONParseOr(safeParseLiquid(paramString, context))

          if (value === undefined || value?.trim?.() === '') {
            return {
              key: p.identifier,
              value: defaultValue,
              isRequired
            }
          }

          return {
            key: p.identifier,
            value,
            isRequired
          }
        }

        return {
          key: p.identifier,
          value: defaultValue,
          isRequired
        }
      })

    const isInvalid = parsedParams.some((p) => p.isRequired && (p.value === undefined || p.value?.trim?.() === ''))

    const parsedArguments = parsedParams.reduce((acc, { key, value }) => ({
      ...acc,
      [key]: value
    }), {})

    return { isInvalid, parsedArguments }
  }, [ parameters, args, context ])

  return useExecuteQueryOperationQuery({
    variables: {
      input: {
        operationId,
        arguments: parsedArguments,
        targetEnvironment
      }
    },
    context: {
      fetchOptions: {
        signal: aborterRef.current.signal
      }
    },
    skip: !operation || isInvalid
  })
}

export default useExecuteOperationQuery
