import BaseModel from 'models/BaseModel'

enum ChannelType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP'
}

enum PushSubChannel {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web'
}

enum WhatsappContentType {
  text = 'text',
  document = 'document',
  image = 'image',
  audio = 'audio',
  video = 'video',
  contacts = 'contacts',
  sticker = 'sticker',
  interactive = 'interactive',
  location = 'location',
  template = 'template',
}

class Channel extends BaseModel {
  static icons = {
    [ChannelType.EMAIL]: 'email',
    [ChannelType.PUSH]: 'push',
    [ChannelType.SMS]: 'sms'
  }
}

export {
  ChannelType,
  PushSubChannel,
  WhatsappContentType
}

export default Channel
