// TODO: Remove after UpdateAccountAvatarInput
// @ts-nocheck
/* eslint-disable */
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'

import MediaInput from 'components/inputs/MediaInput'
import { MEDIA_TYPE_OPTIONS } from 'components/contentEditors/generic/fields/fieldProps'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { UpdateAccountAvatarInput } from 'generated/schema'

const PROFILE_PICTURE_PREVIEW_SIZE = 200

type AccountAvatarFormProps = FormPropsWithId<UpdateAccountAvatarInput>

export default function AccountAvatarForm(props: AccountAvatarFormProps) {
  return (
    <Form
      render={({ form }) => (
        <div>
          <Field
            component={MediaInput}
            name="avatar"
            title="Update your avatar"
            height={PROFILE_PICTURE_PREVIEW_SIZE}
            width={PROFILE_PICTURE_PREVIEW_SIZE}
            fileTypeCategory={MEDIA_TYPE_OPTIONS.IMAGE}
            showLegacyModal
          />
          <FormSpy
            subscription={{ dirtyFields: true, values: true }}
            onChange={({ dirtyFields, values }) => {
              if (dirtyFields?.avatar) {
                props.onSubmit(values as (UpdateAccountAvatarInput & { id: any }), form)
              }
            }}
          />
        </div>
      )}
      {...props}
    />
  )
}
