import React, { useCallback, useContext, useMemo } from 'react'

import AddAppItemView from 'components/views/AddAppItemView'
import AddBlankItemView from 'components/views/AddBlankItemView'
import AddExternalLinkItemView from 'components/views/AddExternalLinkItemView'
import AddMenuHeaderItemView from 'components/views/AddMenuHeaderItemView'
import AddResourceItemView from 'components/views/AddResourceItemView'
import AddMenuSeparatorView from 'components/views/AddMenuSeparatorView'
import AddSubMenuItemView from 'components/views/AddSubMenuItemView'
import InternalContext from 'components/contexts/InternalContext'
import { Popover, PopoverDivider, PopoverItem } from 'components/popover'
import { useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { useViewDispatch } from 'hooks/useViewContext'
import type { MenuElementPlacement } from 'generated/schema'
import type { PopoverProps } from 'components/popover'

type AddElementMenuProps = PopoverProps & {
  onAddElement?: () => void,
  parentId?: string,
  placement: MenuElementPlacement
}

const AddElementMenu = React.forwardRef<HTMLDivElement, AddElementMenuProps>(({
  onAddElement,
  parentId,
  placement,
  ...menuProps
}, ref) => {
  const { openView } = useViewDispatch()
  const { currentDashboard } = useContext(InternalContext)!
  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}
  const params = useMemo(() => (
    { dashboardId, parentId, placement, newNonStickyPosition, newStickyPosition }
  ), [ dashboardId, parentId, placement, newNonStickyPosition, newStickyPosition ])

  const onAddAppItem = useCallback(() => {
    openView({
      component: AddAppItemView,
      params: { initialValues: { parentId, dashboardId } },
      style: 'DIALOG'
    })
    onAddElement?.()
  }, [ onAddElement, openView, parentId, dashboardId ])

  const onAddProjectItem = useCallback(() => {
    openView({
      component: AddAppItemView,
      params: { initialValues: { parentId, dashboardId, resourceMenuStubs: [] } },
      style: 'DIALOG'
    })
    onAddElement?.()
  }, [ onAddElement, openView, parentId, dashboardId ])

  const onAddResourceItem = useCallback(() => {
    openView({
      component: AddResourceItemView,
      params: { initialValues: { parentId, dashboardId } },
      style: AddResourceItemView.defaultStyle
    })
    onAddElement?.()
  }, [ onAddElement, openView, parentId, dashboardId ])

  const onAddBlankViewItem = useCallback(() => {
    openView({
      component: AddBlankItemView,
      params: { ...params },
      style: AddBlankItemView.defaultStyle
    })
    onAddElement?.()
  }, [ onAddElement, openView, params ])

  const onAddSubMenuItem = useCallback(() => {
    openView({
      component: AddSubMenuItemView,
      params: { ...params },
      style: AddSubMenuItemView.defaultStyle
    })
    onAddElement?.()
  }, [ onAddElement, openView, params ])

  const onAddExternalLinkItem = useCallback(() => {
    openView({
      component: AddExternalLinkItemView,
      params: { ...params },
      style: AddExternalLinkItemView.defaultStyle
    })
    onAddElement?.()
  }, [ onAddElement, openView, params ])

  const onAddHeaderItem = useCallback(() => {
    openView({
      component: AddMenuHeaderItemView,
      params: { ...params },
      style: AddMenuHeaderItemView.defaultStyle
    })
    onAddElement?.()
  }, [ onAddElement, openView, params ])

  const onAddMenuSeparator = useCallback(() => {
    openView({ component: AddMenuSeparatorView, params, style: AddMenuSeparatorView.defaultStyle })
    onAddElement?.()
  }, [ onAddElement, openView, params ])

  return (
    <Popover ref={ref} {...menuProps}>
      <PopoverItem size="small" icon="display-normal" onClick={onAddBlankViewItem} text="Blank View" />
      <PopoverItem size="small" icon="menu-item" onClick={onAddSubMenuItem} text="Sub Menu" />
      <PopoverItem size="small" icon="link" onClick={onAddExternalLinkItem} text="External Link" />
      {/* <PopoverDivider /> */}
      {/* <PopoverItem icon="menu-header" onClick={onAddMenuGroup} text="Menu Header" /> */}
      <PopoverDivider />
      <PopoverItem size="small" icon="graph" onClick={onAddHeaderItem} text="Header" />
      <PopoverItem size="small" icon="separator" onClick={onAddMenuSeparator} text="Separator" />
      <PopoverDivider />
      <PopoverItem size="small" icon="app" onClick={onAddProjectItem} text="Project" />
      <PopoverItem size="small" icon="app" onClick={onAddAppItem} text="App" />
      <PopoverItem size="small" icon="graph" onClick={onAddResourceItem} text="Resources" />
    </Popover>
  )
})

AddElementMenu.displayName = 'AddElementMenu'

export default AddElementMenu
