import { ResizeObserver } from '@juggle/resize-observer'
import { useLayoutEffect, useMemo, useState } from 'react'

type Dimensions = {
  height: number,
  width: number
}

type ResizeObserverOptions = {
  paused?: boolean
}

function useResizeObserver<E extends HTMLElement>(
  options?: ResizeObserverOptions
): [(ref: E) => void, Dimensions] {
  const [ element, setElement ] = useState<E | null>(null)
  const [ dimensions, setDimensions ] = useState<Dimensions>({ height: 0, width: 0 })
  const paused = options?.paused

  const observer = useMemo(
    () => new ResizeObserver((entries) => {
      if (entries[0]) {
        const { height, width } = entries[0].contentRect
        setDimensions({ height, width })
      }
    }),
    []
  )

  useLayoutEffect(() => {
    if (!element || paused) {
      return undefined
    }

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [ element, observer, paused ])

  return [ setElement, dimensions ]
}

export default useResizeObserver
