import React from 'react'

import Flex from 'components/layout/Flex'
import AppLogo from 'components/logos/AppLogo'
import Text from 'components/typography/Text'
import Button from 'components/buttons/Button'
import type { ViewProps } from 'components/views'

import pattern from 'assets/images/pattern-colored.svg'
import TextLink from 'components/links/TextLink'

type Params = {
  onSkip: () => void,
  onStart: () => void
}

function WelcomeTour({
  closeView,
  onRequestClose,
  onAfterOpen,
  params: { onSkip, onStart },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  return (
    <View contentLabel="Welcome Tour" onRequestClose={onRequestClose} {...other}>
      {({ Body }) => (
        <>
          <Body
            style={{
              background: `url(${pattern})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              padding: '45px 60px'
            }}
          >
            <Flex direction="column" alignItems="flex-start" gap={60}>
              <Flex direction="column" alignItems="flex-start" gap={10}>
                <AppLogo variant="symbol" size="regular" />
                <Flex direction="column" alignItems="flex-start" gap={16}>
                  <Text fontSize={40} fontWeight="bold" color="dark900">Welcome to your Workspace!</Text>
                  <Text fontSize={16} textAlign="center" color="dark900">Your product operating system awaits.<br />
                    Let&apos;s get started by adding some Apps.
                  </Text>
                </Flex>
              </Flex>
              <Flex gap={24} alignItems="center">
                <Button
                  size="normal"
                  label="Let's do this!"
                  css={{ fontSize: 14 }}
                  onClick={() => {
                    onStart()
                    onRequestClose()
                  }}
                />
                <TextLink
                  as="button"
                  type="button"
                  size="normal"
                  mode="distinct"
                  variant="underlined"
                  fontSize={12}
                  onClick={() => {
                    onSkip()
                    onRequestClose()
                  }}
                >
                  Skip the tour
                </TextLink>
              </Flex>
            </Flex>
          </Body>
        </>
      )}
    </View>
  )
}

export default WelcomeTour
