import { string } from 'yup'

// Identifier should not accept spaces & special characters (except '-' & '_')
const identifierRegex = /^(\d|\w|-|\.)+$/

const isIdentifier = (regex = identifierRegex) => string()
  .test(
    'is-identifier',
    'must not contain spaces or special characters',
    (value) => (Boolean(value && regex.test(value)))
  ).required()

export default isIdentifier
