import React, { useContext, useMemo } from 'react'
import omit from 'lodash/omit'

import AddWorkspaceLocaleView from 'components/views/AddWorkspaceLocaleView'
import Button from 'components/buttons/Button'
import DataTable from 'components/dataTable/DataTable'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import LocaleFallbackRenderer from 'components/renderers/LocaleFallbackRenderer'
import PageLoader from 'components/loaders/PageLoader'
import PaletteIndicator from 'components/artwork/PaletteIndicator'
import TitleBlock from 'components/blocks/TitleBlock'
import Toolbar from 'components/toolbar/Toolbar'
import useActiveLocales, { Locale } from 'hooks/useActiveLocales'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceBrandingForm from 'components/forms/WorkspaceBrandingForm'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceDomainForm from 'components/forms/WorkspaceDomainForm'
import WorkspaceThemeForm from 'components/forms/WorkspaceThemeForm'
import {
  useRemoveWorkspaceLocaleMutation,
  useUpdateWorkspaceBrandingMutation,
  useUpdateWorkspaceDomainMutation,
  useUpdateWorkspaceThemeMutation
} from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { DrawerBlockRenderProps } from 'components/blocks/DrawerBlock'

const columns: Column[] = [
  { dataKey: 'name', title: 'Name', style: { width: 400 } },
  { dataKey: 'identifier', title: 'Identifier', style: { width: 200 } },
  { dataKey: 'fallbacks', title: 'Fallback', style: { flexGrow: 1 }, renderer: LocaleFallbackRenderer }
]

function SettingsPage() {
  const confirm = useConfirmation()
  const { openView } = useViewDispatch()
  const { currentWorkspace, resetThemePaletteRef } = useContext(WorkspaceContext)!

  const { activeLocales, error, loading } = useActiveLocales()

  const [ updateWorkspaceDomain ] = useUpdateWorkspaceDomainMutation()
  const [ updateWorkspaceBranding ] = useUpdateWorkspaceBrandingMutation()
  const [ updateWorkspaceTheme ] = useUpdateWorkspaceThemeMutation()
  const [ removeEnvironmentLocale ] = useRemoveWorkspaceLocaleMutation()

  const handleSubmitDomainForm = useSubmitHandler(updateWorkspaceDomain, {
    successAlert: { message: 'Domain settings saved.' }
  })

  const handleSubmitBrandingForm = useSubmitHandler(updateWorkspaceBranding, {
    successAlert: { message: 'Branding settings saved.' }
  })

  const handleSubmitThemeForm = useSubmitHandler(updateWorkspaceTheme, {
    successAlert: { message: 'Theme settings saved.' }
  })

  const handleRemoveLocale = useSubmitHandler(removeEnvironmentLocale)

  const openEditLocalView = (locale: Locale) => {
    openView({
      title: 'Update Locale',
      component: AddWorkspaceLocaleView,
      style: 'PANEL',
      params: {
        activeLocales,
        initialValues: {
          ...omit(locale, 'name')
        }
      }
    })
  }

  const openAddLocaleView = () => {
    openView({
      title: 'Add Locale',
      component: AddWorkspaceLocaleView,
      style: 'PANEL',
      params: {
        activeLocales,
        initialValues: {}
      }
    })
  }

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: openEditLocalView
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: ({ identifier }: Locale) => confirm({
        action: 'delete',
        recordType: 'locale',
        recordDescription: identifier,
        onConfirmClick: () => handleRemoveLocale({
          identifier
        })
      })
    }
  ]

  const summary = useMemo(() => {
    if (!activeLocales.length) {
      return ''
    }

    const defaultLocale = activeLocales[0].name
    const restLocalesLength = activeLocales.length - 1

    return defaultLocale
      ? `${defaultLocale}${restLocalesLength ? ` + ${restLocalesLength}` : ''}`
      : ''
  }, [ activeLocales ])

  const addComponent = (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button label="Add Locale" onClick={openAddLocaleView} size="small" />
    </Flex>
  )

  return (
    <>
      <TitleBlock heading="Settings" />
      <DrawerBlock icon="workspace" title="Domain" summary="Workspace Domain, Subdomain">
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <WorkspaceDomainForm
            initialValues={{
              id: currentWorkspace.id,
              hostname: currentWorkspace.hostname,
              identifier: currentWorkspace.identifier
            }}
            onCancel={closeDrawer}
            onSubmit={useSubmitHandler.withCallback(handleSubmitDomainForm, closeDrawer)}
          />
        )}
      </DrawerBlock>

      <DrawerBlock icon="branding" title="Branding" summary="Name, Logo, Wallpaper">
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <WorkspaceBrandingForm
            initialValues={{
              id: currentWorkspace.id,
              logoFull: currentWorkspace.logoFull,
              logoSymbol: currentWorkspace.logoSymbol,
              name: currentWorkspace.name,
              wallpaper: currentWorkspace.wallpaper
            }}
            onSubmit={useSubmitHandler.withCallback(handleSubmitBrandingForm, closeDrawer)}
            onCancel={closeDrawer}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="theme"
        summary={<PaletteIndicator />}
        title="Theme"
      >
        {({ closeDrawer }: DrawerBlockRenderProps) => (
          <WorkspaceThemeForm
            initialValues={{ id: currentWorkspace.id, themeId: currentWorkspace.themeId }}
            onSubmit={useSubmitHandler.withCallback(handleSubmitThemeForm, closeDrawer)}
            onCancel={() => {
              resetThemePaletteRef.current()
              closeDrawer()
            }}
          />
        )}
      </DrawerBlock>

      <DrawerBlock
        icon="globe"
        title="Locales"
        summary={summary}
      >
        {() => (
          <>
            <PageLoader
              data={activeLocales}
              error={error}
              loading={loading}
            >
              <DataTable
                columns={columns}
                data={activeLocales}
                selectionMode="none"
                actions={actions as any}
              />
            </PageLoader>
            <Toolbar
              secondaryElements={[ addComponent ]}
            />
          </>
        )}
      </DrawerBlock>
    </>
  )
}

export default SettingsPage
