import React from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import { useHistory } from 'react-router-dom'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import Dashboard from 'models/Dashboard'
import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, useCreateDashboardMutation, useUpdateDashboardMutation } from 'generated/schema'
import { SidePaneFooter } from 'components/sidePane'
import type { ActiveViewProps } from './DashboardEditor'
import type { CreateDashboardInput, UpdateDashboardInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type FormValues = CreateDashboardInput | UpdateDashboardInput

type Params = ViewParams[Views.ADD_DASHBOARD]

function AddDashboardView({ onClose }: ActiveViewProps) {
  const { push } = useHistory()
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const isUpdating = 'id' in initialValues

  const handleCloseView = (dashboard?: any) => {
    stepBackDashboardEditor()
    if (dashboard) push(`/~${dashboard.identifier}`)
  }

  const [ createDashboard ] = useCreateDashboardMutation({
    onCompleted: ({ createDashboard: dashboard }) => handleCloseView(dashboard),
    refetchQueries: [
      { query: DashboardsListDocument }
    ]
  })
  const [ updateDashboard ] = useUpdateDashboardMutation({
    onCompleted: ({ updateDashboard: dashboard }) => handleCloseView(dashboard),
    refetchQueries: [
      { query: DashboardsListDocument }
    ]
  })

  const handleCreateDashboardSubmit = useSubmitHandler(createDashboard, {
    update: {
      strategy: 'APPEND',
      query: DashboardsListDocument,
      dataKey: 'dashboardsList',
      mutation: 'createDashboard'
    }
  })

  const handleUpdateDashboardSubmit = useSubmitHandler(updateDashboard, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateDashboard',
      typename: 'Dashboard',
      defaultValue: {
        menuElements: []
      },
      override: (values: UpdateDashboardInput) => ({
        ...params,
        ...values
      })
    }
  })

  const handleDashboardFormSubmit = (values: FormValues, form: FormPropsWithId<FormValues>['form']) => {
    if ('id' in values) {
      return handleUpdateDashboardSubmit(values, form as FormApi<UpdateDashboardInput>)
    }

    return handleCreateDashboardSubmit(values as CreateDashboardInput)
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'Add'} Dashboard`}
        heading="Dashboard Editor"
        onClose={onClose}
      />
      <Form
        initialValues={initialValues as FormValues}
        onSubmit={handleDashboardFormSubmit}
        subscription={{ submitting: true, pristine: true }}
        validate={(values) => Dashboard.validate(values, [ 'name' ])}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Grid alignItems="center" gap={16} columns={1}>
                  <Field autoFocus name="name" label="Name" component={TextInput} type="text" />
                </Grid>
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button type="submit" size="small" label="Submit" disabled={submitting || pristine} onClick={handleSubmit} />
                <Button label="Cancel" size="small" variant="outline" mode="subtle" onClick={handleCloseView} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default AddDashboardView
