/* eslint-disable camelcase */
import React from 'react'

import Block, { BlockDataSourceKind } from 'components/blocks/Block'
import Card from 'components/card/Card'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Loader, { LoaderProps } from 'components/loaders/Loader'
import Text from 'components/typography/Text'
import { css, styled } from 'styles/stitches'
import type { Color } from 'styles/theme'
import type { BlockProps } from 'components/blocks/Block'

const DEFAULT_PRECISION: number = 1

enum StatItemValueKind {
  FIXED = 'FIXED',
  LIQUID = 'LIQUID'
}

const statItemValueKindOptions = [
  { label: 'Fixed Value', value: StatItemValueKind.FIXED },
  { label: 'Liquid Expression', value: StatItemValueKind.LIQUID }
]

interface StatItem {
  kind: StatItemValueKind,
  value: number | string,
  valueRendered?: number,
  caption?: string
}

interface StatItemPrevious extends StatItem {
  isVisible: boolean
}

interface StatItemDelta {
  kind: StatItemValueKind,
  value: number | string,
  format: StatItemValueFormat,
  isVisible: boolean,
  valueRendered?: number
}

interface StatItemValueFormat {
  style: StatItemValueFormatStyle,
  precision?: number,
  currency_code?: string
}

enum StatItemValueFormatStyle {
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE'
}

interface StatBlockDef {
  current: StatItem,
  format: StatItemValueFormat,
  data_source?: BlockDataSourceKind,
  data_source_settings?: Record<string, any>,
  heading?: string,
  previous?: StatItemPrevious,
  delta?: StatItemDelta
}

type StatBlockProps = StatBlockDef & BlockProps & LoaderProps & {
  width?: number | string
}

const STAT_BLOCK_WIDTH = 260
const STAT_BLOCK_HEIGHT = '100%'

const defaultStatItem: StatItem = {
  kind: StatItemValueKind.FIXED,
  value: 0
}

const defaultStatItemPrevious: StatItemPrevious = {
  kind: StatItemValueKind.FIXED,
  value: 0,
  isVisible: false
}

const defaultStatItemDelta: StatItemDelta = {
  kind: StatItemValueKind.FIXED,
  value: 0,
  isVisible: false,
  format: {
    style: StatItemValueFormatStyle.NUMBER
  }
}

const Title = styled(Text, {
  color: 'dark900',
  fontSize: 14
})

const SubTitle = styled(Text, {
  color: 'dark500',
  fontSize: 12
})

const ValueContainer = styled(Flex, {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between'
})

const ValueText = styled(Flex, {
  color: 'dark900',
  fontFamily: 'normal',
  fontSize: 24,
  fontWeight: 'bold',
  lineHeight: '34px'
})

const ChangePercent = styled(Text, {
  fontSize: 14,
  fontWeight: 'bold',
  lineHeight: '34px'
})

const PreviousValueText = styled(Flex, {
  color: 'dark900',
  fontFamily: 'normal',
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: '24px'
})

const changeColorMap: { [key: string]: Color } = {
  positive: 'positive500',
  negative: 'negative500',
  neutral: 'warning500'
}

const changeIconMap: { [key: string]: string } = {
  positive: 'small-triangle-up',
  negative: 'small-triangle-down',
  neutral: 'small-circle'
}

const changeIconStyle = (change: string) => css({
  color: changeColorMap[change]
})

const styleToFormatMap = {
  [StatItemValueFormatStyle.NUMBER]: 'decimal',
  [StatItemValueFormatStyle.CURRENCY]: 'currency',
  [StatItemValueFormatStyle.PERCENTAGE]: 'percent'
}

const renderValue = (
  value: number,
  { precision = DEFAULT_PRECISION, currency_code, style } = {} as StatItemValueFormat
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: styleToFormatMap[style || StatItemValueFormatStyle.NUMBER],
    currency: currency_code,
    maximumFractionDigits: precision
  })

  return formatter.format(value)
}

function StatBlock({
  heading,
  current,
  previous,
  delta,
  format,
  loading,
  error,
  ...others
}: StatBlockProps) {
  const currentValue = current?.valueRendered || 0
  const deltaValue = delta?.valueRendered || 0
  const previousValue = previous?.valueRendered
    || (deltaValue && (currentValue - deltaValue))
    || 0

  const precision = delta?.format?.precision || DEFAULT_PRECISION
  const changeValue = currentValue - previousValue
  const changePercent = Math.abs(changeValue / (previousValue || 1) || 0).toFixed(precision)

  let change = 'neutral'
  if (changeValue > 0) change = 'positive'
  if (changeValue < 0) change = 'negative'
  let changeSign = ''
  if (changeValue > 0) changeSign = '+'
  if (changeValue < 0) changeSign = '-'

  return (
    <Block
      name="Stats"
      minWidth={STAT_BLOCK_WIDTH}
      minHeight={STAT_BLOCK_HEIGHT}
      direction="column"
      {...others}
    >
      <Card alignItems="flex-start" padding="cozy">
        {loading ? (
          <Loader size="compact" loading />
        ) : (
          <>
            <Title>{heading}</Title>
            <Divider variant="whitespace" spacing={10} />
            <ValueContainer>
              <ValueText>{renderValue(currentValue, format)}</ValueText>
              {delta?.isVisible && (
                <Flex gap={4} alignItems="center">
                  <Icon
                    name={changeIconMap[change]}
                    size={10}
                    className={changeIconStyle(change)}
                  />
                  <ChangePercent color={changeColorMap[change]}>
                    {changeSign}{changePercent}
                    <span style={{ fontSize: '10px', fontWeight: 'bold', lineHeight: '34px' }}>%</span>
                  </ChangePercent>
                </Flex>
              )}
            </ValueContainer>
            <SubTitle>{current?.caption}</SubTitle>
            {previous?.isVisible && (
              <>
                <Divider variant="whitespace" spacing={16} />
                <PreviousValueText>{renderValue(previousValue, format)}</PreviousValueText>
                <SubTitle>{previous?.caption}</SubTitle>
              </>
            )}
          </>
        )}
      </Card>
    </Block>
  )
}

export type {
  StatBlockDef,
  StatBlockProps,
  StatItem,
  StatItemDelta,
  StatItemPrevious
}
export {
  defaultStatItem,
  defaultStatItemPrevious,
  defaultStatItemDelta,
  statItemValueKindOptions,
  STAT_BLOCK_WIDTH,
  STAT_BLOCK_HEIGHT
}
export { StatItemValueKind }

export default StatBlock
