import { useCallback, useState } from 'react'
import type { MouseEvent, MutableRefObject } from 'react'

type UseHoverProps = {
  onHoverIn?: () => void,
  onHoverOut?: () => void,
  onMouseEnter?: (event: MouseEvent<any>) => void,
  onMouseLeave?: (event: MouseEvent<any>) => void,
  onMouseMove?: (event: MouseEvent<any>) => void,
  pausedRef?: MutableRefObject<boolean>
}

/**
  * Adds Mouse Event Listeners to mimic hover effect.
  *
  * @param props.onHoverIn  Callback for when element is hovered in
  * @param props.onHoverOut  Callback for when element is hovered out
  * @param props.onMouseEnter  Callback for custom onMouseEnter listener
  * @param props.onMouseLeave  Callback for custom onMouseLeave listener
  * @param props.onMouseMove  Callback for custom onMouseMove listener
  *
  * NOTE: Do not directly add onMouseEnter, onMouseLeave, onMouseMove
  *       listeners on the element when using this hook
  */

function useHover(props: UseHoverProps = {}) {
  const [ isHovered, setIsHovered ] = useState(false)
  const { onHoverIn, onHoverOut, onMouseEnter, onMouseLeave, onMouseMove } = props

  const bind = {
    onMouseEnter: useCallback((event: MouseEvent<any>) => {
      if (!props.pausedRef?.current) {
        setIsHovered(true)
        onHoverIn?.()
      }

      onMouseEnter?.(event)
    }, [ onHoverIn, onMouseEnter, props.pausedRef ]),

    onMouseLeave: useCallback((event: MouseEvent<any>) => {
      if (!props.pausedRef?.current) {
        setIsHovered(false)
        onHoverOut?.()
      }

      onMouseLeave?.(event)
    }, [ onHoverOut, onMouseLeave, props.pausedRef ]),

    onMouseMove: useCallback((event: MouseEvent<any>) => {
      if (!isHovered && !props.pausedRef?.current) {
        setIsHovered(true)
        onHoverIn?.()
      }
      onMouseMove?.(event)
    }, [ isHovered, onHoverIn, onMouseMove, props.pausedRef ])
  }

  return [ bind, isHovered, setIsHovered ] as const
}

export default useHover
