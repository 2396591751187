import classNames from 'classnames'
import React from 'react'
import SVG from 'react-inlinesvg'

import { css } from 'styles/stitches'

import logoFullImage from 'assets/images/logo-full.svg'
import logoSymbolImage from 'assets/images/logo-symbol.svg'

type AppLogoProps = {
  image?: string,
  mode?: 'normal' | 'light',
  size?: 'large' |'regular' | 'small' | 'tiny',
  variant?: 'full' | 'symbol'
}

const LOGO_PATHS = {
  full: logoFullImage,
  symbol: logoSymbolImage
}

const LOGO_SIZES = {
  full: {
    large: [ 180, 60 ],
    regular: [ 150, 50 ],
    small: [ 120, 35 ],
    tiny: [ 60, 20 ]
  },
  symbol: {
    large: [ 60, 60 ],
    regular: [ 50, 50 ],
    small: [ 35, 35 ],
    tiny: [ 16, 16 ]
  }
}

const LOGO_COLORS = {
  normal: {
    textColor: 'dark1000',
    bigDashColor: 'secondary350',
    smallDashColor: 'primary350'
  },
  light: {
    textColor: 'light100',
    bigDashColor: 'light100',
    smallDashColor: 'light100'
  }
}

const classes = {
  logo: ({ size, variant }: AppLogoProps) => css({
    size: LOGO_SIZES[variant!][size!]
  }),
  image: ({ image }: AppLogoProps) => css({
    backgroundImage: `url(${image})`,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'inline-block'
  }),
  svg: ({ mode }: AppLogoProps) => css({
    '& [data-name="logo-text"]': {
      color: LOGO_COLORS[mode!].textColor
    },

    '& [data-name="logo-big-dash"]': {
      color: LOGO_COLORS[mode!].bigDashColor
    },

    '& [data-name="logo-small-dash"]': {
      color: LOGO_COLORS[mode!].smallDashColor
    }
  })
}

function AppLogo({ image, mode = 'normal', size = 'regular', variant = 'full', ...other }: AppLogoProps) {
  const logoPath = LOGO_PATHS[variant]

  return image ? (
    <div
      className={classNames({
        [classes.logo({ size, variant })]: true,
        [classes.image({ image })]: true
      })}
      {...other}
    />
  ) : (
    <SVG
      className={classNames({
        [classes.logo({ size, variant })]: true,
        [classes.svg({ mode })]: true
      })}
      src={logoPath}
      {...other}
    />
  )
}

export default AppLogo

export type { AppLogoProps }
