import fuzzysort from 'fuzzysort'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useId } from 'react-id-generator'

import * as mixins from 'styles/mixins'
import Divider from 'components/divider/Divider'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import scrollIntoViewIfNeeded from 'lib/scrollIntoViewIfNeeded'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import { Popover, PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'
import type { TextInputProps } from 'components/inputs/TextInput'
import type { PopoverToggleProps } from 'components/popover'

type IconInputProps = TextInputProps & PopoverToggleProps

const ICONS = (() => (
  require.context('../../assets/icons', false, /\.svg$/)
    .keys()
    .map((path) => path.slice(2, -4)) // ./icon-name.svg -> icon-name
))()

const ICON_SPACING = 20
const ICON_WRAPPER_PADDING = 20
const ICON_WRAPPER_SIZE = 280
const ICONS_PER_ROW = 5
const TOTAL_GUTTER = (ICONS_PER_ROW - 1) * ICON_SPACING
const TOTAL_WRAPPER_PADDING = (ICON_WRAPPER_PADDING * 2)
const ICON_SIZE = (ICON_WRAPPER_SIZE - TOTAL_WRAPPER_PADDING - TOTAL_GUTTER) / ICONS_PER_ROW

const ZERO_STATE_MESSAGE = 'Nothing to show here, search for something else.'

const StyledMenuToggle = styled(Flex, {
  position: 'relative',

  '& [data-selected]': {
    color: 'dark900'
  },

  '& [data-media-icon]': {
    ...mixins.transition('simple'),

    color: 'dark100'
  },
  variants: {
    active: {
      true: {
        '& [data-media-icon]': {
          color: 'dark700'
        }
      }
    }
  }
})

const StyledIconsContainer = styled('div', {
  display: 'grid',
  overflowY: 'auto',
  size: [ ICON_WRAPPER_SIZE ]
})

const StyledIcons = styled('div', {
  alignItems: 'center',
  display: 'grid',
  gridGap: ICON_SPACING,
  gridTemplate: `repeat(${ICONS_PER_ROW}, ${ICON_SIZE}px) / repeat(${ICONS_PER_ROW}, ${ICON_SIZE}px)`,
  justifyItems: 'center',
  padding: ICON_WRAPPER_PADDING,
  overflowX: 'hidden'
})

const StyledIcon = styled(Icon, {
  color: 'dark100',
  cursor: 'pointer',

  '&:hover, &:focus': {
    color: 'dark700'
  },
  variants: {
    selected: {
      true: {
        color: 'dark700'

      }
    }
  }
})

const StyledInputRightNode = styled(Flex, {
  height: '100%',

  '& [data-clear]': {
    ...mixins.transition('simple'),

    cursor: 'pointer',
    pointerEvents: 'auto',

    '&:hover': {
      color: 'dark900'
    }
  }
})

const StyledZeroState = styled(Flex, {
  padding: ICON_WRAPPER_PADDING,
  width: ICON_WRAPPER_SIZE,

  '& [data-icon]': {
    color: 'dark300'
  }
})

const IconInputToggle = forwardRef<HTMLInputElement, IconInputProps>(({
  disabled,
  input,
  isActive,
  onClick,
  onKeyDown,
  role,
  selectedEl,
  selectedIcon,
  closePopover,
  openPopover,
  setSelectedIcon,
  tabIndex,

  ...other
}, innerRef) => {
  const toggleProps = disabled ? {} : {
    onClick,
    onKeyDown,
    role,
    tabIndex
  }

  useEffect(() => {
    if (isActive && selectedEl) {
      scrollIntoViewIfNeeded(selectedEl)
    }
  }, [ isActive, selectedEl ])

  useEffect(() => {
    const isInvalidSearch = input.value !== selectedIcon

    if (!isActive && isInvalidSearch) {
      input.onChange(selectedIcon)
    }
  }, [ input, isActive, selectedIcon ])

  const onClearClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    input.onChange(null)
    setSelectedIcon(null)
  }

  const inputLeftNode = selectedIcon && <Icon data-selected name={selectedIcon} />

  const inputRightNode = (
    <StyledInputRightNode alignItems="center" gap={20}>
      {selectedIcon && (
        <>
          <Icon data-clear name="cross" onClick={onClearClick} size={8} />
          <Divider orientation="vertical" variant="ruler" />
        </>
      )}
      <Icon data-media-icon name="image-filled" />
    </StyledInputRightNode>
  )

  return (
    <StyledMenuToggle
      {...toggleProps}
      active={isActive}
      grow={1}
      ref={innerRef}
    >
      <TextInput
        appendNode={inputRightNode}
        disabled={disabled}
        input={input}
        prependNode={inputLeftNode}
        {...other}
      />
    </StyledMenuToggle>
  )
})

const IconInput = (({
  input,
  label,
  isTranslatable,
  checkRequired,

  ...other
}: TextInputProps) => {
  const inputValue = input.value

  const [ selectedIcon, setSelectedIcon ] = useState(inputValue)
  const selectedEl = useRef<HTMLElement>(null)

  const name = input?.name || 'icon'
  const [ uniqueId ] = useId(1, `${name}-`)

  const matches = useMemo(() => (
    fuzzysort.go(inputValue, ICONS).map((i) => i.target)
  ), [ inputValue ])

  const isSearching = inputValue !== selectedIcon
  const icons = !inputValue || !isSearching ? ICONS : matches
  const isIconsListEmpty = icons.length === 0

  const onIconClick = (iconName: string) => {
    input.onChange(iconName)
    setSelectedIcon(iconName)
  }

  const renderIcon = (iconName: string) => {
    const isSelected = iconName === selectedIcon

    return (
      <StyledIcon
        key={iconName}
        name={iconName}
        onClick={() => onIconClick(iconName)}
        ref={isSelected ? selectedEl : undefined}
        size={24}
        selected={isSelected}
      />
    )
  }

  return (
    <Flex as="label" direction="column" gap={10}>
      <FieldLabel htmlFor={uniqueId} isTranslatable={isTranslatable}>
        {label}{checkRequired && <span> *</span>}
      </FieldLabel>

      <PopoverContainer
        modifiers={[
          {
            name: 'offset',
            options: { offset: [ 0, 20 ] }
          }
        ]}
        placement="bottom-end"
        strategy="absolute"
        openOn="focus"
      >
        {(toggleProps) => (
          <IconInputToggle
            id={uniqueId}
            input={input}
            selectedEl={selectedEl.current}
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
            {...toggleProps}
            {...other}
          />
        )}
        {(popoverProps) => (
          <Popover
            {...popoverProps}
            withArrow
          >
            {isIconsListEmpty ? (
              <StyledZeroState alignItems="center" gap={20}>
                <Icon data-icon name="info" size={22} />
                <Text fontSize={14} lineHeight="cozy" color="dark500">{ZERO_STATE_MESSAGE}</Text>
              </StyledZeroState>
            ) : (
              <StyledIconsContainer>
                <StyledIcons>
                  {icons.map(renderIcon)}
                </StyledIcons>
              </StyledIconsContainer>
            )}
          </Popover>
        )}
      </PopoverContainer>
    </Flex>
  )
})

IconInputToggle.displayName = 'IconInputToggle'

export type { IconInputProps }

export default IconInput
