import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import AppLogo from 'components/logos/AppLogo'
import Dashes from 'components/artwork/Dashes'
import ElectronWorkspaceSidebar from 'components/sidebar/ElectronWorkspaceSidebar'
import Flex from 'components/layout/Flex'
import UpdateAlert from 'components/alert/UpdateAlert'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceLogo from 'components/logos/WorkspaceLogo'
import { colorVars } from 'styles/theme'
import { isAppHostname } from 'lib/hostname'
import { isElectron } from 'lib/electron'
import { styled, css } from 'styles/stitches'
import { WORKSPACE_SIDEBAR_WIDTH } from 'components/sidebar/constants'

import defaultWallpaperImage from 'assets/images/wallpaper.jpg'

const StyledContentWrapper = styled('div', {
  minHeight: '100vh',
  padding: 80,
  position: 'relative',
  width: '40%'
})

const StyledContent = styled('div', {
  height: 'calc(100% - 15vh)',
  marginTop: '15vh'
})

const StyledWallpaperWrapper = styled('div', {
  bottom: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '60%'
})

const StyledWallpaper = styled('div', {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
  transform: 'translateZ(0)', // Force hardware acceleration to prevent a Firefox bug with SVGs
  width: '100%',
  position: 'relative',

  '&::after': {
    background: `linear-gradient(322deg, ${colorVars.secondary400} 0%, ${colorVars.primary400} 100%)`,
    content: '""',

    height: '100%',
    mixBlendMode: 'multiply',
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%'
  }
})

const getBg = ({ customWallpaperImage }: ExternalLayoutStyleProps) => css({
  backgroundImage: `url(${customWallpaperImage || defaultWallpaperImage})`,
  '&::after': {
    display: customWallpaperImage ? 'none' : 'initial'
  }
})

type ExternalLayoutStyleProps = {
  customWallpaperImage?: string
}

const StyledLink = styled(Link, {
  display: 'inline-block'
})

function ExternalLayout({ children }: React.PropsWithChildren<{}>) {
  const { currentWorkspace } = useContext(WorkspaceContext) || {}

  return (
    <>
      <ElectronWorkspaceSidebar />
      <Flex style={{ marginLeft: isElectron ? WORKSPACE_SIDEBAR_WIDTH : 0 }}>
        <StyledContentWrapper>
          <StyledLink to="/">
            {isAppHostname
              ? <AppLogo />
              : <WorkspaceLogo logoFull={currentWorkspace?.logoFull} />}
          </StyledLink>
          <StyledContent>
            {children}
          </StyledContent>
          {isElectron && <UpdateAlert />}
        </StyledContentWrapper>
        <StyledWallpaperWrapper>
          <StyledWallpaper
            className={getBg({ customWallpaperImage: currentWorkspace?.wallpaper })}
          />
          {!currentWorkspace?.wallpaper && <Dashes />}
        </StyledWallpaperWrapper>
      </Flex>
    </>
  )
}

export default ExternalLayout
