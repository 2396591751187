import React from 'react'
import { Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import ParameterModel from 'models/Parameter'
import SelectInput from 'components/inputs/SelectInput'
import TextInput from 'components/inputs/TextInput'
import ToggleInput from 'components/inputs/ToggleInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { ParametersListDocument, ParametersListQueryVariables, CreateParameterInput, UpdateParameterInput, useCreateParameterMutation, useUpdateParameterMutation, useFieldTypesListQuery, Parameter } from 'generated/schema'
import type { ViewProps } from 'components/views'

type FormValues = CreateParameterInput | UpdateParameterInput

type Params = {
  initialValues: FormValues,
  attribute?: Parameter['attribute'],
  operationId: string,
  queryVariables: ParametersListQueryVariables
}

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

function AddParameterView({
  closeView,
  onRequestClose,
  params: { initialValues, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const title = `${isUpdating ? 'Update' : 'Add'} Parameter`

  const { data } = useFieldTypesListQuery()

  const [ createParameter ] = useCreateParameterMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: ParametersListDocument, variables: queryVariables } ]
  })

  const [ updateParameter ] = useUpdateParameterMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: ParametersListDocument, variables: queryVariables } ]
  })

  const handleCreateParameter = useSubmitHandler(createParameter, {
    successAlert: { message: 'Parameter Created.' }
  })

  const handleUpdateParameter = useSubmitHandler(updateParameter, {
    successAlert: { message: 'Parameter Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating) {
      return handleUpdateParameter(
        values as UpdateParameterInput,
        form as FormApi<UpdateParameterInput>
      )
    }
    return handleCreateParameter(values as CreateParameterInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Form
              decorators={[
                setIdentifier
              ]}
              initialValues={initialValues}
              keepDirtyOnReinitialize
              onSubmit={handleSubmit}
              validate={(values) => ParameterModel.validate(values, [ 'identifier', 'name' ])}
              subscription={{
                submitting: true,
                pristine: true
              }}
              render={({ handleSubmit, submitting, pristine }) => (
                <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
                  <FormField
                    name="fieldType"
                    component={SelectInput}
                    label="Field Type"
                    labelKey="name"
                    valueKey="identifier"
                    options={data?.fieldTypes || []}
                    size="small"
                  />

                  <Grid columns={2} columnGap={8} rowGap={8}>
                    <FormField
                      component={TextInput}
                      name="name"
                      label="Name"
                      size="small"
                      type="text"
                    />
                    <FormField
                      component={TextInput}
                      name="identifier"
                      label="Identifier"
                      size="small"
                      type="text"
                    />
                    <FormField
                      component={TextInput}
                      name="helpText"
                      label="Help Text"
                      size="small"
                      type="text"
                    />
                    <FormField
                      component={TextInput}
                      name="placeholder"
                      label="Placeholder"
                      size="small"
                      type="text"
                    />
                  </Grid>
                  <FormField
                    name="isNullable"
                    component={ToggleInput}
                    label="Allow Nullable"
                    helpText="Allow the attribute to assume a NULL value if no value is provided"
                    labelPosition="left"
                    type="checkbox"
                  />
                  <FormField
                    name="isArray"
                    component={ToggleInput}
                    helpText="Allow the attribute to collect multiple values"
                    label="Collect multiple values?"
                    labelPosition="left"
                    type="checkbox"
                  />
                  <Flex gap={24} direction="row-reverse">
                    <Button type="submit" label="Save Changes" disabled={submitting || pristine} onClick={handleSubmit} />
                  </Flex>
                </Flex>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default AddParameterView
