import arrayMutators from 'final-form-arrays'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import React, { Suspense, useContext, useMemo, useState } from 'react'
import { Form, FormProps, FormRenderProps, useField, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator, FormApi } from 'final-form'

import AttributeModel, { DisplayType, ResolutionKind } from 'models/Attribute'
import AttributeValidations, { formatValidations, parseValidations } from './AttributeValidations'
import Button from 'components/buttons/Button'
import componentLoader from 'lib/componentLoader'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataTypeModel, { DataTypeKind, DEFAULT_DATA_TYPES, nonPrimitiveDataTypes } from 'models/DataType'
import Divider from 'components/divider/Divider'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import Grid from 'components/layout/Grid'
import IconCard from 'components/iconCard/IconCard'
import InternalContext from 'components/contexts/InternalContext'
import Label from 'components/typography/Label'
import Loader from 'components/loaders/Loader'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import pascalCase from 'lib/pascalCase'
import RadioInput from 'components/inputs/RadioInput'
import SelectInput from 'components/inputs/SelectInput'
import Tab from 'components/tabs/Tab'
import Tabs, { useTabs } from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useGetAllowedFieldTypes from 'hooks/useGetAllowedFieldTypes'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { APP_CATEGORIES_ID } from 'models/App'
import { AttributeFragmentFragment, AttributesListDocument, CreateAttributeInput, DatabaseTable, DataType, DataTypeCategory, DataTypeFragmentFragment, FieldType, Resource, ResourcesListDocument, ResourcesListQuery, ResourcesListQueryVariables, UpdateAttributeInput, useAttributesListQuery, useCreateAttributeMutation, useDatabaseTablesListQuery, useDataTypeQuery, useDataTypesListQuery, useResourceQuery, useResourcesListQuery, useUpdateAttributeMutation, ValidationInput } from 'generated/schema'
import { ATTRIBUTES_LIST_LIMIT } from 'models/Resource'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { css } from 'styles/stitches'
import { FieldIdentifier } from 'models/Field'
import { formatDateTimeValues } from 'components/displayTypes/DateTimeView'
import { SidePaneFooter, SidePaneSubHeader } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import SearchBar from 'components/searchbar/SearchBar'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'

type FormValues = CreateAttributeInput | UpdateAttributeInput

type Params = ViewParams[Views.CREATE_ATTRIBUTE]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const decorators = [
  setIdentifier
]

const stepSubTitles: Record<Step, String> = {
  SELECT_RESOLUTION_KIND: 'Select attribute type',
  SELECT_DATA_TYPE: 'Select data type',
  CONFIGURE_SETTINGS: 'Enter details'
}

const AttributeTypes = [
  {
    name: 'DashX Attribute',
    icon: 'table',
    description: 'Fetched & stored within DashX',
    value: ResolutionKind.LOCAL
  },
  {
    name: 'Remote Attribute',
    icon: 'app-database-hub',
    description: 'Fetched from remote sources (Database, API)',
    value: ResolutionKind.REMOTE
  },
  {
    name: 'Computed Attribute',
    icon: 'app-database-hub',
    description: 'Computed on-the-fly from other fields',
    value: ResolutionKind.COMPUTED
  }
]

const DISPLAY_TYPES = [
  {
    label: 'Plain Text',
    icon: 'text-field',
    value: DisplayType.PLAIN_TEXT
  },
  {
    label: 'Sensitive Text',
    icon: 'password-field',
    value: DisplayType.SENSITIVE_TEXT
  },
  {
    label: 'Link',
    icon: 'link',
    value: DisplayType.LINK
  },
  {
    label: 'Rendered HTML',
    icon: 'code_block',
    value: DisplayType.RENDERED_HTML
  },
  {
    label: 'Rendered Markdown',
    icon: 'markdown-field',
    value: DisplayType.RENDERED_MARKDOWN
  },
  {
    label: 'Code',
    icon: 'code',
    value: DisplayType.CODE
  },
  {
    label: 'Phone Number',
    icon: 'phone-field',
    value: DisplayType.PHONE_NUMBER
  },
  {
    label: 'Boolean',
    icon: 'switch-field',
    value: DisplayType.SWITCH
  },
  {
    label: 'Chip',
    icon: 'tag',
    value: DisplayType.CHIP
  },
  {
    label: 'Date/Time',
    icon: 'datetime-field',
    value: DisplayType.DATE_TIME
  },
  {
    label: 'Duration',
    icon: 'time-zone',
    value: DisplayType.DURATION
  },
  {
    label: 'Embedded',
    icon: 'embedded-field',
    value: DisplayType.EMBEDDED
  },
  {
    label: 'Reference',
    icon: 'reference-field',
    value: DisplayType.REFERENCE
  },
  {
    label: 'Image',
    icon: 'image',
    value: DisplayType.IMAGE
  },
  {
    label: 'Audio',
    icon: 'sound',
    value: DisplayType.AUDIO
  },
  {
    label: 'Video',
    icon: 'video',
    value: DisplayType.VIDEO
  },
  {
    label: 'File',
    icon: 'notes',
    value: DisplayType.FILE
  },
  {
    label: 'Currency',
    icon: 'currency-field',
    value: DisplayType.CURRENCY
  },
  {
    label: 'Number',
    icon: 'number-field',
    value: DisplayType.NUMERIC
  }
]

const classes = {
  wrapper: css({
    marginBottom: 30
  })
}

const EnumOptions = ({ name, settings, onClick }: { name: string, settings: DataType['settings'], onClick?: VoidFunction }) => (
  <>
    <Text fontSize={10} color="dark500" css={{ marginTop: -10 }}>
      Linked to <TextLink fontSize={10} as="button" type="button" onClick={onClick} variant="underlined">{name}</TextLink>
    </Text>
    {settings?.options?.map(({ key, label }: { key: string, label: string }) => (
      <Flex gap={6} alignItems="center" key={key}>
        <Text fontSize={10} fontWeight="bold">{label}</Text>
        <Text fontSize={10} css={{ backgroundColor: 'light700', paddingX: 2, paddingY: 1 }}>{key}</Text>
      </Flex>
    ))}
  </>
)

const FirstStep = ({
  typeOptions,
  handleStepForward
}: {
  typeOptions: Record<string, any>[],
  handleStepForward: (step?: Step) => void
}) => {
  const resolutionKindField = useField<ResolutionKind | null>('resolutionKind')
  const resolutionKind = resolutionKindField.input.value
  const setResolutionKind = resolutionKindField.input.onChange

  return (
    <Flex direction="column" gap={24}>
      {typeOptions.map(({ name, description, icon, value }) => (
        <MediaCard
          active={resolutionKind === value}
          key={name}
          text={description}
          titlePosition="top"
          media={icon}
          onClick={() => {
            setResolutionKind(value)
            if (value === ResolutionKind.COMPUTED) {
              handleStepForward('CONFIGURE_SETTINGS')
            } else {
              handleStepForward('SELECT_DATA_TYPE')
            }
          }}
          title={name}
          width="full"
          actions={[ {
            description: '',
            icon: 'arrow-right',
            isIconAlwaysVisible: true
          } ]}
        />
      ))}
    </Flex>
  )
}

const getFieldViewName = (fieldType: string) => {
  if (!fieldType) return ''

  const identifier = fieldType.replace('input', 'field')

  // text-field -> TextFieldView
  return `${pascalCase(identifier)}View`
}

const getDisplayViewFileName = (displayType: string) => {
  if (!displayType) return ''

  return `${pascalCase(displayType)}View`
}

const getAllowedDisplayTypes = (kind: DataTypeKind, fieldType?: FieldIdentifier) => {
  let allowedDisplayTypes: DisplayType[] = [ DisplayType.PLAIN_TEXT ]

  switch (kind) {
    case DataTypeKind.BINARY:
      break
    case DataTypeKind.OBJECT:
      allowedDisplayTypes = [ DisplayType.EMBEDDED, DisplayType.PLAIN_TEXT ]
      break

    case DataTypeKind.JSON:
      allowedDisplayTypes = [ DisplayType.EMBEDDED, DisplayType.CODE, DisplayType.PLAIN_TEXT ]
      break

    case DataTypeKind.AUDIO:
      allowedDisplayTypes = [ DisplayType.AUDIO ]
      break

    case DataTypeKind.BOOLEAN:
      allowedDisplayTypes = [ DisplayType.CHIP, DisplayType.SWITCH ]
      break

    case DataTypeKind.DATE:
      allowedDisplayTypes = [ DisplayType.DATE_TIME ]
      break

    case DataTypeKind.DOCUMENT:
    case DataTypeKind.SPREADSHEET:
    case DataTypeKind.PRESENTATION:
      allowedDisplayTypes = [ DisplayType.FILE, DisplayType.CODE, DisplayType.PLAIN_TEXT ]
      break

    case DataTypeKind.DURATION:
      allowedDisplayTypes = [ DisplayType.DURATION ]
      break

    case DataTypeKind.ENUM:
      allowedDisplayTypes = [ DisplayType.CHIP ]
      break

    case DataTypeKind.FILE:
      allowedDisplayTypes = [ DisplayType.FILE ]
      break

    case DataTypeKind.FLOAT:
    case DataTypeKind.BIGDECIMAL:
    case DataTypeKind.BIGINT:
    case DataTypeKind.INT:
      if (!fieldType) {
        allowedDisplayTypes = [ DisplayType.CURRENCY, DisplayType.DURATION, DisplayType.NUMERIC ]
        break
      }
      if (fieldType === FieldIdentifier.CURRENCY) {
        allowedDisplayTypes = [ DisplayType.CURRENCY, DisplayType.NUMERIC ]
        break
      }
      if (fieldType === FieldIdentifier.DURATION) {
        allowedDisplayTypes = [ DisplayType.DURATION, DisplayType.NUMERIC ]
        break
      }

      allowedDisplayTypes = [ DisplayType.NUMERIC ]
      break

    case DataTypeKind.ID:
    case DataTypeKind.UUID:
      if (fieldType === FieldIdentifier.REFERENCE) {
        allowedDisplayTypes = [ DisplayType.REFERENCE ]
        break
      }
      allowedDisplayTypes = [ DisplayType.PLAIN_TEXT ]
      break

    case DataTypeKind.IMAGE:
      allowedDisplayTypes = [ DisplayType.IMAGE ]
      break

    case DataTypeKind.STRING:
      if (fieldType === FieldIdentifier.SWITCH) {
        allowedDisplayTypes = [ DisplayType.CHIP, DisplayType.SWITCH ]
        break
      }
      if (fieldType === FieldIdentifier.PASSWORD) {
        allowedDisplayTypes = [ DisplayType.SENSITIVE_TEXT ]
        break
      }
      if (fieldType === FieldIdentifier.EMAIL) {
        allowedDisplayTypes = [ DisplayType.PLAIN_TEXT, DisplayType.LINK ]
        break
      }
      if (fieldType === FieldIdentifier.MARKDOWN) {
        allowedDisplayTypes = [ DisplayType.RENDERED_MARKDOWN ]
        break
      }
      if (fieldType === FieldIdentifier.DROPDOWN) {
        allowedDisplayTypes = [ DisplayType.CHIP, DisplayType.PLAIN_TEXT ]
        break
      }
      allowedDisplayTypes = [
        DisplayType.PLAIN_TEXT,
        DisplayType.RENDERED_MARKDOWN,
        DisplayType.CHIP,
        DisplayType.SWITCH,
        DisplayType.LINK
      ]
      break

    case DataTypeKind.TIME:
      allowedDisplayTypes = [ DisplayType.DATE_TIME ]
      break

    case DataTypeKind.TIMESTAMP:
      allowedDisplayTypes = [ DisplayType.DATE_TIME, DisplayType.PLAIN_TEXT ]
      break

    case DataTypeKind.VIDEO:
      allowedDisplayTypes = [ DisplayType.VIDEO ]
      break

    default:
      return DISPLAY_TYPES
  }

  return allowedDisplayTypes.map((aD) => DISPLAY_TYPES.find((d) => d.value === aD))
}

const AppearanceTab = ({ appId }: FinalStepProps) => {
  const { values } = useFormState()
  const resolutionKindField = useField<ResolutionKind | null>('resolutionKind')
  const resolutionKind = resolutionKindField.input.value
  const {
    selectedDataType
  } = useDataTypes(values as any)
  const { change } = useForm()
  const { setActiveIndex } = useTabs()
  const fieldType = get(values, 'fieldType')
  const displayType = get(values, 'displayType')
  const dataTypeKind = get(values, 'dataTypeKind') as DataTypeKind

  const isEnum = dataTypeKind === DataTypeKind.ENUM

  const getAllowedFieldTypes = useGetAllowedFieldTypes()

  const {
    fieldTypes: allowedFieldTypes, loading: loadingFieldTypes
  } = getAllowedFieldTypes(selectedDataType)

  const defaultFieldType = allowedFieldTypes
    .find(
      (t) => t.identifier === values.fieldType
    )?.identifier
   || allowedFieldTypes?.[0]?.identifier
   || FieldIdentifier.TEXT

  const allowedDisplayTypes = useMemo(() => (
    dataTypeKind
      ? getAllowedDisplayTypes(dataTypeKind, fieldType)
      : DISPLAY_TYPES
  ), [ fieldType, dataTypeKind ])

  const defaultDisplayType = allowedDisplayTypes[0]?.value || DisplayType.PLAIN_TEXT

  const fieldViewName = getFieldViewName(fieldType)
  const fieldViewFileName = pascalCase(fieldViewName)
  const displayViewFileName = getDisplayViewFileName(displayType)

  const FieldTypeSettings = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Settings }))
  ), [ fieldViewFileName ])

  const DisplayTypeSettings = useMemo(() => React.lazy(
    () => componentLoader(`displayTypes/${displayViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('displayTypes/PlainTextView'))
      .then((module) => ({ default: module.default?.Configurations }))
  ), [ displayViewFileName ])

  const { data, loading: referencesOptionsLoading, previousData } = useResourcesListQuery({
    variables: { filter: { id: { in: values.fieldTypeSettings?.references } } },
    skip: !values.fieldTypeSettings?.references?.length
  })

  return (
    <Flex direction="column" gap={32}>
      {resolutionKind !== ResolutionKind.COMPUTED && (
        <Flex direction="column" gap={16}>
          <Flex direction="column" gap={6}>
            <Text fontSize={14} fontWeight="bold" textTransform="uppercase">Field Type</Text>
            <Text color="dark600" fontSize={12}>Choose how this attribute gets rendered in <b>Forms</b>.</Text>
          </Flex>
          <FormField
            alwaysDirty
            component={SelectInput}
            name="fieldType"
            size="small"
            options={allowedFieldTypes}
            loading={loadingFieldTypes}
            labelKey="name"
            valueKey="identifier"
            defaultValue={defaultFieldType}
            isDisabled={isEnum}
            onChange={(option: FieldType) => {
              change('validations', [])
              change('fieldType', option.identifier)
              change('fieldTypeSettings', {})
            }}
          />
          {values.fieldType === FieldIdentifier.REFERENCE && (
            <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
              loading={referencesOptionsLoading}
              isSearchable
              preload
              name="fieldTypeSettings.references"
              label="Referenced Resource"
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              iconKey="app.identifier"
              valueKey="id"
              options={data?.resourcesList || previousData?.resourcesList || []}
              getOptionLabel={(option: Resource) => option.name}
              getOptionIcon={(option: Resource) => option.icon || 'app-custom'}
              query={ResourcesListDocument}
              dataKey="resourcesList"
              keys={[ 'name', 'identifier' ]}
              isMulti
              queryOptions={{
                variables: {
                  filter: {
                    ...(appId && { appId: { eq: appId } })
                  },
                  order: [ { name: 'asc' } ]
                }
              }}
            />
          )}
          {AttributeModel.hasFieldTypeSettings(fieldType) && (
            <Suspense fallback={<Loader loading />}>
              <FormField component="input" type="hidden" name="fieldTypeSettings" alwaysDirty />
              <FieldTypeSettings
                fieldSettingsPrefix="fieldTypeSettings."
                dataType={dataTypeKind}
                optionsConfig={isEnum && (
                  <EnumOptions
                    name={selectedDataType?.name!}
                    settings={selectedDataType?.settings}
                    onClick={() => setActiveIndex(0)}
                  />
                )}
              />
            </Suspense>
          )}
        </Flex>
      )}
      <Flex direction="column" gap={16}>
        <Flex direction="column" gap={6}>
          <Text fontSize={14} fontWeight="bold" textTransform="uppercase">Default Display Settings</Text>
          <Text color="dark600" fontSize={12}>Choose how this attribute gets rendered in <b>Tables</b> and <b>Details</b>.</Text>
        </Flex>
        <FormField
          alwaysDirty
          component={SelectInput}
          name="displayType"
          size="small"
          options={allowedDisplayTypes}
          defaultValue={defaultDisplayType}
        />
        <WhenFieldChanges
          field="displayType"
          set="displayTypeSettings"
          to={{}}
        />
        {AttributeModel.hasDisplayTypeSettings(displayType) && (
          <Suspense fallback={<Loader loading />}>
            <FormField component="input" type="hidden" name="displayTypeSettings" alwaysDirty />
            <DisplayTypeSettings
              fieldPrefix="displayTypeSettings."
              dataType={dataTypeKind}
            />
          </Suspense>
        )}
      </Flex>
    </Flex>
  )
}

const AdvancedTab = () => (
  <Flex direction="column" gap={16}>
    <Text fontWeight="bold" fontSize={14} textTransform="uppercase">Validation Rules</Text>
    <AttributeValidations />
  </Flex>
)

const DataTypeSettings = ({ kind }: { kind: DataTypeKind }) => {
  if (kind === DataTypeKind.BIGDECIMAL) {
    return (
      <>
        <FormField
          name="dataTypeSettings.precision"
          label="Number of Significant Digits"
          component={TextInput}
          size="small"
          type="number"
          parse={(value: string) => value && parseInt(value, 10)}
        />
        <FormField
          name="dataTypeSettings.scale"
          label="Number of Decimal Places"
          component={TextInput}
          size="small"
          type="number"
          parse={(value: string) => value && parseInt(value, 10)}
        />
      </>
    )
  }

  return null
}

const DATA_TYPES_LIST_LIMIT = 100

const useDataTypes = (values: {
  dataTypeId: string,
  dataTypeKind: DataTypeKind
}) => {
  const {
    data: { dataTypesList: primitiveDataTypes } = {},
    loading: primitiveDataTypesLoading
  } = useDataTypesListQuery({
    variables: {
      limit: DATA_TYPES_LIST_LIMIT,
      order: [
        {
          position: 'asc'
        }
      ],
      filter: {
        isPrimitive: {
          eq: true
        }
      }
    }
  })

  const dataTypeOptions = useMemo(() => [
    ...primitiveDataTypes || [],
    ...DEFAULT_DATA_TYPES as DataType[]
  ], [ primitiveDataTypes ])

  const { data: { dataType } = {}, loading } = useDataTypeQuery({
    variables: {
      id: values.dataTypeId
    },
    skip: !values.dataTypeId
  })

  const selectedDataType = dataType || dataTypeOptions.find((d) => (
    values.dataTypeKind
      ? (d.kind === values.dataTypeKind)
      : (values.dataTypeId && d.id === values.dataTypeId)
  ))

  const {
    data: { dataTypesList: complexDataTypes } = {},
    loading: complexDataTypesLoading
  } = useDataTypesListQuery({
    variables: {
      limit: DATA_TYPES_LIST_LIMIT,
      filter: {
        kind: {
          eq: selectedDataType?.kind
        }
      }
    },
    skip: !selectedDataType?.kind || selectedDataType.isPrimitive
  })

  return {
    complexDataTypes,
    loading: primitiveDataTypesLoading || complexDataTypesLoading || loading,
    primitiveDataTypes,
    dataTypeOptions,
    selectedDataType: selectedDataType
      || dataTypeOptions.find((d) => d.kind === DataTypeKind.STRING),
    selectedDataTypeId: selectedDataType?.id
  }
}

const CATEGORIES: DataTypeCategory[] = [ 'BASIC', 'NUMBER', 'DATE_TIME', 'FILE', 'OTHER', 'ADVANCED' ]

const SecondStep = ({ handleStepForward }: { handleStepForward: () => void }) => {
  const { values } = useFormState()
  const form = useForm()

  const {
    dataTypeOptions,
    loading,
    selectedDataType
  } = useDataTypes(values as any)

  const getAllowedFieldTypes = useGetAllowedFieldTypes()
  const [ searchText, setSearchText ] = useState('')

  const groupedDataTypes = groupBy(dataTypeOptions.filter((dt) => dt.name.toLocaleLowerCase().includes(searchText)), 'category')

  const renderIconCard = (dataType: DataType) => (
    <IconCard
      active={selectedDataType?.kind === dataType.kind}
      autoFocus={
        selectedDataType?.kind === dataType.kind
      }
      key={dataType.identifier}
      icon={dataType.icon || 'unchecked-box'}
      onClick={() => {
        const [ defaultFieldType ] = getAllowedFieldTypes(dataType).fieldTypes
        const defaultFieldTypeIdentifier = defaultFieldType?.identifier as FieldIdentifier
        const [ defaultDisplayType ] = getAllowedDisplayTypes(
          dataType.kind as DataTypeKind,
          defaultFieldTypeIdentifier
        )
        form.change('fieldType', defaultFieldTypeIdentifier)
        form.change('displayType', defaultDisplayType?.value)
        form.change('validations', [])
        form.change('dataTypeKind', dataType.kind)
        form.change('dataTypeId', dataType.id)
        handleStepForward()
      }}
      label={dataType.name}
      width="full"
      height="auto"
    />
  )

  return (
    <PageLoader
      data={dataTypeOptions}
      loading={loading}
    >
      <Flex direction="column" gap={14}>
        <SearchBar placeholder="Search..." defaultValue={searchText} onChange={(e) => setSearchText(e.target.value)} />
        {CATEGORIES.map((category) => {
          if (!groupedDataTypes[category]?.length) return null

          return (
            <div>
              <Label>{category} types</Label>
              <Divider spacing={10} variant="whitespace" />
              <Grid columnGap={20} rowGap={20} columns={2}>
                {groupedDataTypes[category].map(renderIconCard)}
              </Grid>
              <Divider spacing={10} variant="whitespace" />
            </div>
          )
        })}
      </Flex>
    </PageLoader>
  )
}

const GeneralTab = ({
  appId,
  isUpdating,
  isTranslatable = false
}: FinalStepProps) => {
  const resolutionKindField = useField<ResolutionKind | null>('resolutionKind')
  const resolutionKind = resolutionKindField.input.value

  const {
    dashboardEditorState,
    openDashboardEditorView,
    saveDashboardEditorViewState
  } = useDashboard()

  const { params } = useRecoilValue<DashboardEditorView<Views.CREATE_ATTRIBUTE>>(
    dashboardEditorState
  )

  const { change } = useForm()
  const { values } = useFormState()
  const { installationsList = [] } = useContext(InternalContext)!
  const [ selectedRemoteSource, setSelectedRemoteSource ] = useState<DatabaseTable | null>(null)

  const installationIds = installationsList.filter(
    (installation) => installation.app.appCategoryId === APP_CATEGORIES_ID.Databases
  ).map((i) => i.id)

  const {
    complexDataTypes,
    loading,
    dataTypeOptions,
    selectedDataType,
    selectedDataTypeId
  } = useDataTypes(values as any)

  const {
    data: { databaseTablesList = [] } = {},
    loading: databaseTablesLoading
  } = useDatabaseTablesListQuery({
    variables: {
      filter: {
        integrationId: { in: installationIds }
      }
    },
    skip: !installationIds.length
  })

  const getAllowedFieldTypes = useGetAllowedFieldTypes()

  return (
    <Flex direction="column" gap={16}>
      {(resolutionKind === ResolutionKind.REMOTE) && (
      <Loader
        data={databaseTablesList}
        loading={databaseTablesLoading}
      >
        <Flex direction="column" gap={10}>
          <Flex justifyContent="space-between" gap={16}>
            <Text
              color="dark500"
              fontSize={10}
              fontWeight="bold"
              textTransform="uppercase"
            >
              Remote Source
            </Text>
          </Flex>
          <FormField
            isSearchable
            component={SelectInput}
            name="source"
            size="small"
            valueKey="id"
            options={databaseTablesList}
            onChange={(option: DatabaseTable) => {
              setSelectedRemoteSource(option)
              change('source', option.id)
            }}
            getOptionLabel={(option: DatabaseTable) => `${camelCase(option.integration.name)}.${option.name}`}
            getOptionMeta={(option: DatabaseTable) => option.environment.name}
          />
        </Flex>
      </Loader>
      )}
      {selectedRemoteSource && selectedRemoteSource.databaseColumns.map((col) => (
        <FormField
          component={RadioInput}
          label={col.name}
          name="originId"
          type="radio"
          value={col.id as any}
        />
      ))}
      <FormField autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
      <FormField checkRequired name="identifier" label="Identifier" component={TextInput} size="small" />
      <FormField
        isDisabled={isUpdating}
        alwaysDirty
        checkRequired
        component={SelectInput}
        name="dataTypeKind"
        label="Data Type"
        size="small"
        options={dataTypeOptions}
        loading={loading}
        labelKey="name"
        valueKey="kind"
        onChange={(option: typeof dataTypeOptions[number]) => {
          const [ defaultFieldType ] = getAllowedFieldTypes(option).fieldTypes
          const defaultFieldTypeIdentifier = defaultFieldType?.identifier as FieldIdentifier
          const [ defaultDisplayType ] = getAllowedDisplayTypes(
                option.kind as DataTypeKind,
                defaultFieldTypeIdentifier
          )
          change('fieldType', defaultFieldTypeIdentifier)
          change('displayType', defaultDisplayType?.value)
          change('validations', [])
          change('dataTypeKind', option.kind)
          change('dataTypeId', option.id)
        }}
        defaultValue={selectedDataType?.kind}
      />
      <FormField alwaysDirty name="dataTypeId" component="input" type="hidden" defaultValue={selectedDataTypeId} value={selectedDataTypeId} />
      {values.dataTypeKind === DataTypeKind.ENUM && (
        <Flex direction="column" gap={10}>
          <Flex justifyContent="space-between">
            <FieldLabel>
              Select Enum
            </FieldLabel>
            <Flex gap={8}>
              {values.dataTypeId && (
              <TextLink
                as="button"
                type="button"
                fontSize={10}
                onClick={() => {
                  saveDashboardEditorViewState({
                    ...params,
                    initialValues: {
                      ...(params?.initialValues || {}),
                      ...values
                    } as AttributeFragmentFragment
                  })

                  openDashboardEditorView({
                    target: Views.CREATE_DATA_TYPE,
                    params: {
                      heading: values.name,
                      initialValues: selectedDataType,
                      sourceKind: 'ATTRIBUTE'
                    }
                  })
                }}
              >
                Edit
              </TextLink>
              )}
              <TextLink
                as="button"
                type="button"
                fontSize={10}
                onClick={() => {
                  saveDashboardEditorViewState({
                    ...params,
                    initialValues: {
                      ...(params?.initialValues || {}),
                      ...values
                    } as AttributeFragmentFragment
                  })
                  openDashboardEditorView({
                    target: Views.CREATE_DATA_TYPE,
                    params: {
                      heading: values.name,
                      initialValues: {
                        appId,
                        kind: DataTypeKind.ENUM
                      } as DataTypeFragmentFragment,
                      sourceKind: 'ATTRIBUTE'
                    }
                  })
                }}
              >
                Add new
              </TextLink>
            </Flex>
          </Flex>
          <FormField
            alwaysDirty
            checkRequired
            isDisabled={isUpdating}
            component={SelectInput}
            name="dataTypeId"
            size="small"
            options={complexDataTypes}
            loading={loading}
            labelKey="name"
            valueKey="id"
            onChange={(option: typeof dataTypeOptions[number]) => {
              const [ defaultFieldType ] = getAllowedFieldTypes(option).fieldTypes
              const defaultFieldTypeIdentifier = defaultFieldType?.identifier as FieldIdentifier
              const [ defaultDisplayType ] = getAllowedDisplayTypes(
                  option.kind as DataTypeKind,
                  defaultFieldTypeIdentifier
              )
              change('fieldType', defaultFieldTypeIdentifier)
              change('displayType', defaultDisplayType?.value)
              change('validations', [])
              change('dataTypeId', option.id)
            }}
            defaultValue={values.dataTypeId}
          />
        </Flex>
      )}
      {values.dataTypeKind === DataTypeKind.OBJECT && (
      <Flex direction="column" gap={10}>
        <Flex justifyContent="space-between">
          <FieldLabel>
            Select an Object
          </FieldLabel>
          <Flex gap={8}>
            {values.dataTypeId && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  } as AttributeFragmentFragment
                })

                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.name,
                    initialValues: selectedDataType,
                    sourceKind: 'ATTRIBUTE'
                  }
                })
              }}
            >
              Edit
            </TextLink>
            )}
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  } as AttributeFragmentFragment
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.name,
                    initialValues: {
                      appId,
                      kind: DataTypeKind.OBJECT
                    } as DataTypeFragmentFragment,
                    sourceKind: 'ATTRIBUTE'
                  }
                })
              }}
            >
              Add new
            </TextLink>
          </Flex>
        </Flex>
        <FormField
          alwaysDirty
          checkRequired
          component={SelectInput}
          name="dataTypeId"
          size="small"
          options={complexDataTypes}
          loading={loading}
          labelKey="name"
          valueKey="id"
          onChange={(option: typeof dataTypeOptions[number]) => {
            const [ defaultFieldType ] = getAllowedFieldTypes(option).fieldTypes
            const defaultFieldTypeIdentifier = defaultFieldType?.identifier as FieldIdentifier
            const [ defaultDisplayType ] = getAllowedDisplayTypes(
                    option.kind as DataTypeKind,
                    defaultFieldTypeIdentifier
            )
            change('fieldType', defaultFieldTypeIdentifier)
            change('displayType', defaultDisplayType?.value)
            change('validations', [])
            change('dataTypeId', option.id)
          }}
          defaultValue={values.dataTypeId}
        />
      </Flex>
      )}
      {values.dataTypeKind === DataTypeKind.UNION && (
      <Flex direction="column" gap={10}>
        <Flex justifyContent="space-between">
          <FieldLabel>
            Select a Union type
          </FieldLabel>
          <Flex gap={8}>
            {values.dataTypeId && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  } as AttributeFragmentFragment
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.name,
                    initialValues: selectedDataType,
                    sourceKind: 'ATTRIBUTE'
                  }
                })
              }}
            >
              Edit
            </TextLink>
            )}
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  } as AttributeFragmentFragment
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.name,
                    initialValues: {
                      appId,
                      kind: DataTypeKind.UNION
                    } as DataTypeFragmentFragment,
                    sourceKind: 'ATTRIBUTE'
                  }
                })
              }}
            >
              Add new
            </TextLink>
          </Flex>
        </Flex>
        <FormField
          alwaysDirty
          checkRequired
          component={SelectInput}
          name="dataTypeId"
          size="small"
          options={complexDataTypes}
          loading={loading}
          labelKey="name"
          valueKey="id"
          onChange={(option: typeof dataTypeOptions[number]) => {
            const [ defaultFieldType ] = getAllowedFieldTypes(option).fieldTypes
            const defaultFieldTypeIdentifier = defaultFieldType?.identifier as FieldIdentifier
            const [ defaultDisplayType ] = getAllowedDisplayTypes(
                    option.kind as DataTypeKind,
                    defaultFieldTypeIdentifier
            )
            change('fieldType', defaultFieldTypeIdentifier)
            change('displayType', defaultDisplayType?.value)
            change('validations', [])
            change('dataTypeId', option.id)
          }}
          defaultValue={values.dataTypeId}
        />
      </Flex>
      )}
      {DataTypeModel.hasDataTypeSettings(selectedDataType?.kind as DataTypeKind) && (
        <DataTypeSettings kind={selectedDataType?.kind as DataTypeKind} />
      )}
      <FormField
        component={ToggleInput}
        name="isArray"
        label="Array"
        helpText="Allow multiple values?"
        size="small"
        type="checkbox"
      />
      <FormField
        component={ToggleInput}
        name="isNullable"
        label="Nullable"
        helpText="Allow blank values?"
        size="small"
        type="checkbox"
      />
      {isTranslatable && (
        <FormField
          component={ToggleInput}
          name="isTranslatable"
          label="Internationalization"
          helpText="Allow translations?"
          size="small"
          type="checkbox"
          disabled={nonPrimitiveDataTypes.includes(values.dataTypeKind)}
        />
      )}
      {(resolutionKind === ResolutionKind.COMPUTED) && (
        <>
          <FormField
            name="resolutionSettings.computed"
            component="input"
            type="hidden"
            alwaysDirty
          />
          <FieldLabel>Computed Expression</FieldLabel>
          <FormField
            component={ButtonGroupInput}
            fullWidth
            options={[
              { label: 'Use Expression', value: 'EXPRESSION' },
              { label: 'Use JavaScript', value: 'JAVASCRIPT' }
            ]}
            name="resolutionSettings.computed.kind"
            defaultValue="EXPRESSION"
          />
          {values.resolutionSettings.computed?.kind === 'EXPRESSION' ? (
            <FormField
              component={TextInput}
              key="resolutionSettings.computed.expression"
              name="resolutionSettings.computed.expression"
              size="small"
              placeholder="Enter a handlebars expression"
              helpText="Final value is computed using this expression"
            />
          ) : (
            <FormField
              component={CodeEditorInput}
              key="resolutionSettings.computed.code"
              name="resolutionSettings.computed.code"
              language="javascript"
              helpText="Final value is computed using this code"
              defaultValue="// Your code here"
            />
          )}

        </>
      )}
      <FormField
        component={TextInput}
        name="defaultValue.value"
        label="Default Value"
        helpText="Liquid parseable"
        size="small"
      />
      <FormField
        name="defaultValue"
        component="input"
        type="hidden"
        alwaysDirty
      />
    </Flex>
  )
}

type FinalStepProps = {
    isUpdating?: boolean,
    isTranslatable?: boolean,
    appId?: string
}

const FinalStep = ({ appId, ...props }: FinalStepProps) => {
  const resolutionKind = useField<ResolutionKind | null>('resolutionKind').input.value

  const resourceId = useField('resourceId').input.value

  const { data: { resource } = {} } = useResourceQuery({
    variables: {
      id: resourceId
    },
    skip: !resourceId || !!appId
  })

  return (
    <Tabs wrapperClassName={classes.wrapper}>
      <Tab index={0} label="General" alwaysMounted>
        <GeneralTab appId={resource?.appId || appId} {...props} />
      </Tab>
      <Tab index={1} label="Appearance" alwaysMounted>
        <AppearanceTab appId={resource?.appId || appId} {...props} />
      </Tab>
      {resolutionKind !== ResolutionKind.COMPUTED && (
      <Tab index={2} label="Advanced" alwaysMounted>
        <AdvancedTab />
      </Tab>
      )}
    </Tabs>
  )
}

type Step = 'SELECT_RESOLUTION_KIND' | 'SELECT_DATA_TYPE' | 'CONFIGURE_SETTINGS'

const CreateAttributeForm = (
  { handleSubmit, pristine, submitting, initialValues }: FormRenderProps<FormValues>
) => {
  const resolutionKindField = useField<ResolutionKind | null>('resolutionKind')

  const [ currentStep, setCurrentStep ] = useState<Step>(resolutionKindField.input.value ? 'CONFIGURE_SETTINGS' : 'SELECT_RESOLUTION_KIND')
  const isUpdating = 'id' in (initialValues || {})

  const handleStepForward = (step?: Step) => {
    if (step) {
      setCurrentStep(step)
      return
    }

    if (currentStep === 'SELECT_RESOLUTION_KIND') {
      setCurrentStep('SELECT_DATA_TYPE')
      return
    }

    setCurrentStep('CONFIGURE_SETTINGS')
  }

  const handleStepBehind = () => {
    if (currentStep === 'CONFIGURE_SETTINGS') {
      if (resolutionKindField.input.value === ResolutionKind.COMPUTED) {
        setCurrentStep('SELECT_RESOLUTION_KIND')
        return
      }

      setCurrentStep('SELECT_DATA_TYPE')
      return
    }

    if (currentStep === 'SELECT_DATA_TYPE') {
      setCurrentStep('SELECT_RESOLUTION_KIND')
      return
    }

    setCurrentStep('SELECT_DATA_TYPE')
  }

  const getStepNumber = () => {
    switch (currentStep) {
      case 'SELECT_RESOLUTION_KIND':
        return 1
      case 'SELECT_DATA_TYPE':
        return 2
      case 'CONFIGURE_SETTINGS':
        if (resolutionKindField.input.value === ResolutionKind.COMPUTED) {
          return 2
        }
    }
    return 3
  }

  return (
    <>
      {!isUpdating && (
      <SidePaneSubHeader size="small">
        <Text fontWeight="bold">
          Step {getStepNumber()}:&nbsp;{stepSubTitles[currentStep]}
        </Text>
      </SidePaneSubHeader>
      )}
      <DashboardEditorBody css={currentStep === 'CONFIGURE_SETTINGS' ? { paddingTop: 0 } : {}}>
        <Flex as="form" direction="column" onSubmit={handleSubmit}>
          {currentStep === 'SELECT_RESOLUTION_KIND' && (
            <FirstStep
              typeOptions={AttributeTypes}
              handleStepForward={handleStepForward}
              key={currentStep}
            />
          )}
          {currentStep === 'SELECT_DATA_TYPE' && (
            <SecondStep
              handleStepForward={handleStepForward}
              key={currentStep}
            />
          )}
          {currentStep === 'CONFIGURE_SETTINGS' && (
            <FinalStep
              isUpdating={isUpdating}
              isTranslatable
              key={currentStep}
            />
          )}
          <input type="submit" style={{ display: 'none' }} />
        </Flex>
      </DashboardEditorBody>
      <SidePaneFooter variant="small" isSticky>
        <Flex gap={16} direction="row-reverse">
          {currentStep === 'CONFIGURE_SETTINGS' && <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />}
          {currentStep !== 'CONFIGURE_SETTINGS' && (
            <Button
              disabled={(currentStep === 'SELECT_RESOLUTION_KIND' && !resolutionKindField.input.value)}
              icon="arrow-right"
              onClick={() => handleStepForward()}
              size="small"
            />
          )}
          {currentStep !== 'SELECT_RESOLUTION_KIND' && !isUpdating && <Button size="small" icon="arrow-left" onClick={handleStepBehind} />}
        </Flex>
      </SidePaneFooter>
    </>
  )
}

const CreateAttributeView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const {
    params: { initialValues = {} as FormValues, resource = {} as Resource } = {}
  } = useRecoilValue<DashboardEditorView<Views.CREATE_ATTRIBUTE>>(
    dashboardEditorState
  )

  const { id: resourceId, name: resourceName } = resource
  const { fieldType, displayType, validations } = initialValues

  const isUpdating = initialValues && 'id' in initialValues

  let formattedInitialValues = initialValues as any
  let formattedValidations = validations || []

  if (displayType && displayType === DisplayType.DATE_TIME) {
    formattedInitialValues = formatDateTimeValues(initialValues as FormValues)
  }

  if (validations?.length) {
    formattedValidations = formatValidations(validations as ValidationInput[])
  }

  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId }
    },
    order: [ {
      position: 'asc'
    } ],
    limit: ATTRIBUTES_LIST_LIMIT
  }

  const {
    data: { attributesList = [] } = {}
  } = useAttributesListQuery({
    variables: queryVariables,
    skip: !resource
  })

  const [ createAttribute ] = useCreateAttributeMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ AttributesListDocument ]
  })

  const [ updateAttribute ] = useUpdateAttributeMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ AttributesListDocument ]
  })

  const handleCreateAttribute = useSubmitHandler(createAttribute, {
    successAlert: { message: 'Attribute Created.' }
  })

  const handleUpdateAttribute = useSubmitHandler(updateAttribute, {
    successAlert: { message: 'Attribute Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    let parsedValues = omit(values, 'dataType', 'dataTypeKind')

    if (values.validations?.length) {
      parsedValues = parseValidations(parsedValues as FormValues)
    }

    if (isUpdating) {
      const cleanValues = omit(parsedValues, 'dataTypeId')

      return handleUpdateAttribute(
        cleanValues as UpdateAttributeInput,
        form as FormApi<UpdateAttributeInput>
      )
    }

    return handleCreateAttribute(parsedValues as CreateAttributeInput)
  }

  return (
    <>
      <DashboardEditorHeader
        heading={`Resource: ${resourceName}`}
        subtitle={`${isUpdating ? 'Edit' : 'New'} Attribute`}
        onClose={onClose}
      />
      <Form
        decorators={decorators}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          defaultValue: {
            value: null,
            type: 'fixed'
          },
          isFilterable: AttributeModel
            .isFilterableField(fieldType as FieldIdentifier),
          isNullable: AttributeModel
            .isNullableField(fieldType as FieldIdentifier),
          isOrderable: AttributeModel
            .isOrderableField(fieldType as FieldIdentifier),
          isTranslatable: false,
          position: generatePosition(getMaxPosition(attributesList as any[])),
          ...(isUpdating ? {} : { resourceId }),
          ...(isUpdating ? {} : { resolutionSettings: {} }),
          ...formattedInitialValues as FormValues,
          settings: formattedInitialValues?.settings || {},
          dataTypeSettings: formattedInitialValues?.dataTypeSettings || {},
          fieldTypeSettings: formattedInitialValues?.fieldTypeSettings || {},
          displayTypeSettings: formattedInitialValues?.displayTypeSettings || {},
          validations: formattedValidations,
          // @ts-ignore - required for UI but gets omitted out while submission
          // eslint-disable-next-line max-len
          dataTypeKind: formattedInitialValues?.dataTypeKind || formattedInitialValues?.dataType?.kind
        }}
        keepDirtyOnReinitialize
        validate={(values) => AttributeModel.validate(values, [ 'identifier', 'name' ])}
        subscription={{ submitting: true, pristine: true, initialValues: true }}
        onSubmit={handleSubmit}
        render={(props) => (
          <CreateAttributeForm {...props} />
        )}
      />
    </>
  )
}

CreateAttributeView.FinalStep = FinalStep
CreateAttributeView.FirstStep = FirstStep

export { DISPLAY_TYPES, getAllowedDisplayTypes, getDisplayViewFileName, useDataTypes }

export default CreateAttributeView
