import React from 'react'
import type { FieldRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'

type ButtonGroupOption = {
  label: string | React.ReactNode,
  value: string
}

type ButtonGroupInputProps = FieldRenderProps<string, HTMLInputElement> & {
  fullWidth?: boolean,
  handleMouseOut?: () => void,
  handleMouseOver?: (value: string) => void,
  inputLabel?: string,
  options?: Readonly<ButtonGroupOption[]>,
  size?: 'small' | 'normal' | 'large'
}

const BORDER_RADIUS = 4
const BORDER_WIDTH = 1
const OPTION_MIN_WIDTH_LARGE = 90
const OPTION_MIN_WIDTH_NORMAL = 65
const OPTION_MIN_WIDTH_SMALL = 40
const OPTION_PADDING_LARGE = 20
const OPTION_PADDING_NORMAL = 15
const OPTION_PADDING_SMALL = 12

const StyledOptionsWrapper = styled(Flex, {
  backgroundColor: 'light200',
  border: `${BORDER_WIDTH}px solid light700`,
  borderRadius: BORDER_RADIUS,

  variants: {
    fullWidth: {
      true: {
        flex: 1,

        '& [data-option]': {
          flex: 1
        }
      },
      false: {}
    }
  }
})

const StyledOption = styled(Text, {
  ...mixins.transition('simple', 'box-shadow'),
  ...mixins.transition('simple', 'color'),

  alignItems: 'center',
  borderRight: `${BORDER_WIDTH}px solid light700`,
  color: 'dark500',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',

  '&:last-of-type': {
    borderRight: 'none'
  },

  '&:hover': {
    color: 'dark700'
  },
  variants: {
    active: {
      true: {
        ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),

        backgroundColor: 'light100',
        border: `${BORDER_WIDTH}px solid light100`,
        borderRadius: BORDER_RADIUS,
        color: 'primary300',
        margin: -`${BORDER_WIDTH}px`,

        '&:hover': {
          color: 'primary300',
          cursor: 'default'
        }
      }
    },
    size: {
      small: {
        minWidth: OPTION_MIN_WIDTH_SMALL,
        padding: OPTION_PADDING_SMALL
      },
      normal: {
        minWidth: OPTION_MIN_WIDTH_NORMAL,
        padding: OPTION_PADDING_NORMAL
      },
      large: {
        minWidth: OPTION_MIN_WIDTH_LARGE,
        padding: OPTION_PADDING_LARGE
      }
    }
  }
})

function ButtonGroupInput({
  fullWidth = false,
  handleMouseOut,
  handleMouseOver,
  input,
  inputLabel,
  options,
  size = 'normal'
}: ButtonGroupInputProps) {
  if (!options || options.length === 0) {
    return null
  }

  return (
    <Flex as="label" alignItems="center" gap={10}>
      {inputLabel && <FieldLabel>{inputLabel}</FieldLabel>}
      <StyledOptionsWrapper
        alignItems="center"
        justifyContent="center"
        fullWidth={fullWidth}
      >
        {options.map(({ label, value }) => (
          <StyledOption
            active={value === input.value}
            data-option
            size={size}
            as="div"
            key={`option-${value}`}
            fontSize={12}
            fontWeight="bold"
            role="button"
            tabIndex={0}
            onClick={() => input.onChange(value)}
            onKeyPress={() => input.onChange(value)}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            onMouseOver={() => handleMouseOver?.(value)}
            onFocus={() => handleMouseOver?.(value)}
          >
            {label}
          </StyledOption>
        ))}
        <input type="hidden" {...input} />
      </StyledOptionsWrapper>
    </Flex>
  )
}

export type { ButtonGroupInputProps }

export default ButtonGroupInput
