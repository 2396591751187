import React from 'react'

import AddRelationshipView from 'components/views/AddRelationshipView'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataListBlock from 'components/blocks/DataListBlock'
import Flex from 'components/layout/Flex'
import parseError from 'lib/parseError'
import SearchBar from 'components/searchbar/SearchBar'
import Text from 'components/typography/Text'
import useConfirmation from 'hooks/useConfirmation'
import useFuzzySearch from 'hooks/useFuzzySearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Kind as RelationshipKind } from 'models/Relationship'
import { Relationship, RelationshipsListDocument, Resource, useRelationshipsListQuery, useDestroyRelationshipMutation, CreateRelationshipInput } from 'generated/schema'
import { RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'

const DEFAULT_TITLE = 'There are no relationships.'
const DEFAULT_SUBTITLE = 'Relationships link resources to one another.'
const DEFAULT_ERROR_SUBTITLE = 'Please contact support if the problem persists.'

const RelationshipsList = ({ resource }: { resource: Resource }) => {
  const resourceId = resource.id
  const confirm = useConfirmation({ style: 'DIALOG' })
  const { isReadOnly } = resource
  const { openView } = useViewDispatch()

  const queryVariables = {
    filter: {
      sourceId: { eq: resourceId }
    },
    limit: RELATIONSHIPS_LIST_LIMIT
  }

  const {
    data: { relationshipsList = [] } = {},
    loading,
    error
  } = useRelationshipsListQuery({
    variables: queryVariables,
    skip: !resourceId
  })

  const { matches, handleChange } = useFuzzySearch(relationshipsList as Relationship[], { key: 'name' })

  const [ destroyRelationship ] = useDestroyRelationshipMutation({
    refetchQueries: [
      { query: RelationshipsListDocument, variables: queryVariables }
    ]
  })

  const handleDestroyRelationship = useSubmitHandler(destroyRelationship, {
    successAlert: { message: 'Relationship Deleted.' }
  })

  const onEdit = (relationship: Relationship) => openView({
    title: 'Edit Relationship',
    component: AddRelationshipView,
    params: {
      resourceId,
      initialValues: relationship,
      relationship,
      relationshipsList,
      queryVariables
    },
    style: 'PANEL'
  })

  const onAdd = () => openView({
    title: 'Add Relationship',
    component: AddRelationshipView,
    params: {
      resourceId,
      initialValues: {
        name: '',
        identifier: '',
        sourceAttributeId: '',
        targetAttributeId: '',
        kind: RelationshipKind.BELONGS_TO
      } as CreateRelationshipInput,
      relationship: {
        source: resource
      },
      relationshipsList,
      queryVariables
    },
    style: 'PANEL'
  })

  const openConfirmationDialog = (relationship: Relationship) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        handleDestroyRelationship({ id: relationship.id })
      },
      recordType: 'Relationship',
      recordDescription: relationship.name
    })
  }

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' },
    { dataKey: 'kind', slot: 'meta', renderer: ChipRenderer }
  ]
  const isSystem = !resource.workspaceId

  const actions = isReadOnly || isSystem
    ? [ { icon: 'pad-lock', title: 'Read only' } ]
    : [
      {
        icon: 'edit',
        title: 'Edit',
        onClick: onEdit
      },
      {
        icon: 'trash',
        title: 'Delete',
        onClick: openConfirmationDialog
      }
    ]

  const EmptyState = () => {
    const loaderTitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return alert?.title || alert?.message
      }

      return DEFAULT_TITLE
    })()

    const loaderSubtitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return (alert?.title && alert?.message) || DEFAULT_ERROR_SUBTITLE
      }

      return DEFAULT_SUBTITLE
    })()

    return (
      <Flex alignItems="center" direction="column" gap={16}>
        <Flex alignItems="center" direction="column" gap={8}>
          <Text fontWeight="bold">{loaderTitle}</Text>
          <Text fontSize={14}>{loaderSubtitle}</Text>
        </Flex>
        {!isReadOnly && <Button label="Create Relationship" size="small" mode="subtle" onClick={onAdd} />}
      </Flex>
    )
  }

  return (
    <DataListBlock
      asFragment
      title="Relationships"
      actions={actions}
      contents={contents}
      data={matches}
      loading={loading}
      error={error}
      selectionMode="none"
      empty={{
        element: <EmptyState />
      }}
      primaryElements={<SearchBar placeholder="Search Relationships" onChange={handleChange} css={{ maxWidth: 240 }} />}
      secondaryElements={!isReadOnly && <Button icon="add-thin" size="small" onClick={onAdd} />}
      width={{ md: '75%' }}
    />
  )
}

export default RelationshipsList
