import React from 'react'
import type { FormProps } from 'react-final-form'

import EnvironmentForm from 'components/forms/EnvironmentForm'
import EnvironmentView from 'components/views/EnvironmentView'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, EnvironmentDocument, EnvironmentQueryVariables, EnvironmentsListDocument, EnvironmentsListQueryVariables, useCreateEnvironmentMutation, useUpdateEnvironmentMutation } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { CreateEnvironmentInput, UpdateEnvironmentInput } from 'generated/schema'
import type { ViewProps } from 'components/views'

type EnvironmentFormValues = CreateEnvironmentInput | UpdateEnvironmentInput

type Params = {
  initialValues: object,
  queryVariables: EnvironmentsListQueryVariables | EnvironmentQueryVariables
}

function EnvironmentFormView({
  closeView,
  onRequestClose,
  params: { initialValues, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { openView } = useViewDispatch()

  const [ createEnvironment ] = useCreateEnvironmentMutation({
    onCompleted: (data) => {
      onRequestClose()
      closeView()
      openView({
        title: data.createEnvironment.name || 'Environment',
        component: EnvironmentView,
        style: EnvironmentView.defaultStyle,
        params: {
          environmentId: data.createEnvironment.id
        }
      })
    },
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: EnvironmentsListDocument, variables: queryVariables }
    ]
  })
  const [ updateEnvironment ] = useUpdateEnvironmentMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: EnvironmentDocument, variables: queryVariables }
    ]
  })

  const handleCreateEnvironmentSubmit = useSubmitHandler(createEnvironment, {
    update: {
      strategy: 'APPEND',
      query: EnvironmentsListDocument,
      queryVariables,
      dataKey: 'environmentsList',
      mutation: 'createEnvironment'
    }
  })

  const handleUpdateEnvironmentSubmit = useSubmitHandler(updateEnvironment, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateEnvironment',
      typename: 'Environment',
      override: (values: UpdateEnvironmentInput) => ({
        ...initialValues,
        ...values
      })
    }
  })

  const handleEnvironmentFormSubmit = (values: EnvironmentFormValues, form: FormProps<UpdateEnvironmentInput>['form']) => {
    if ('id' in values) {
      return handleUpdateEnvironmentSubmit(values, form)
    }

    return handleCreateEnvironmentSubmit(values as CreateEnvironmentInput)
  }

  const title = 'id' in initialValues ? 'Edit Environment' : 'Create Environment'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <EnvironmentForm
              initialValues={initialValues}
              onSubmit={handleEnvironmentFormSubmit}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default EnvironmentFormView
