import React from 'react'

import AppLogo from 'components/logos/AppLogo'
import type { AppLogoProps } from 'components/logos/AppLogo'

type WorkspaceLogoProps = AppLogoProps & {
  logoFull?: string,
  logoSymbol?: string
}

function WorkspaceLogo({ logoFull, logoSymbol, variant = 'full', ...other }: WorkspaceLogoProps) {
  const image = variant === 'full' ? (logoFull || logoSymbol) : (logoSymbol || logoFull)

  return (
    <AppLogo image={image} variant={variant} {...other} />
  )
}

export default WorkspaceLogo
