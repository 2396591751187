import React from 'react'

import TextInputBlock from 'components/blocks/TextInputBlock'
import type { TextInputBlockProps } from 'components/blocks/TextInputBlock'

const TextInputBlockWrapper: React.FC<TextInputBlockProps> = ({ block, ...props }) => (
  <TextInputBlock
    {...props}
    {...block.properties}
    identifier={block.identifier}
  />
)

export default TextInputBlockWrapper
