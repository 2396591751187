import isObject from 'lodash/isObject'
import React from 'react'

import Text from 'components/typography/Text'
import type { Attribute } from 'generated/schema'

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { fontWeight = 'regular' } = displaySettings

  return (
    <Text fontSize={14} fontWeight={fontWeight}>{isObject(data) ? JSON.stringify(data) : data || '-'}</Text>
  )
}

export { Renderer as GenericRenderer }
