import createDecorator from 'final-form-focus'
import get from 'lodash/get'
import type { Decorator } from 'final-form'

const createFocusOnErrors = <T>() => createDecorator<T>(
  undefined,
  (inputs, errors) => {
    const isFocusable = (input: HTMLInputElement) => {
      if (input.type === 'hidden') {
        return false
      }
      // ID would be present in case of react-select
      return input.name || input.id || input.dataset?.inputType
    }

    const result = inputs.find((input) => isFocusable(input as HTMLInputElement)
    && get(errors, input.name)) as HTMLInputElement

    return result
  }
) as unknown as Decorator<T>

export { createFocusOnErrors }
