/* eslint-disable no-case-declarations */
import React from 'react'
import { useRecoilValue } from 'recoil'

import * as schema from 'generated/schema'
import Block from './Block'
import Button from 'components/buttons/Button'
import GenericView from 'components/views/GenericView'
import JSONParseOr from 'lib/JSONParseOr'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Behavior } from 'components/dashboardEditor/AddActionView'
import { parseAndRenderSync } from 'lib/templater'
import { useViewDispatch } from 'hooks/useViewContext'
import type { BlockProps } from './Block'
import type { ButtonProps } from 'components/buttons/Button'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'

type ButtonBlockProps = BlockProps & ButtonProps

function ButtonBlock(
  {
    blockRef, style, label, href, block, ...other
  }: ButtonBlockProps
) {
  const { switcher } = useDashboardViewContext()

  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)

  const [ executeAction ] = schema.useExecuteMutationOperationMutation()

  const handleExecuteOperation = useSubmitHandler(executeAction, {
    successAlert: {
      message: 'Operation executed successfully',
      title: 'Success'
    }
  })

  const { openView } = useViewDispatch()

  const [ action = {} ] = block.actions?.map((action: any) => {
    switch (action.behavior) {
      case Behavior.RUN_OPERATION:
        const input = Object.fromEntries(
          Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
            key,
            JSONParseOr(parseAndRenderSync(value || '', blockProperties)) || null
          ])
        )

        const handleRunOperationClick = () => handleExecuteOperation({
          arguments: input,
          operationId: action.operation,
          targetEnvironment: switcher?.data.environment?.id
        })

        return ({
          // icon: action.identifier,
          onClick: handleRunOperationClick,
          // disabled: Object.values(input).every((v) => !v),
          name: action.identifier,
          label: action.name
        })

      case Behavior.REDIRECT:
        const url = parseAndRenderSync(action.url, {
          ...blockProperties,
          environment: switcher?.data.environment?.id || ''
        })

        return ({
          // icon: action.identifier,
          href: url,
          target: action.target,
          name: action.identifier,
          label: action.name,
          onClick: () => window.open(url, action.target, 'noopener noreferrer')
        })

      case Behavior.OPEN_VIEW:

        const handleOpenViewClick = () => {
          openView({
            component: GenericView,
            style: action.view_style,
            params: {
              viewUrn: action.view_urn
            }
          })
        }

        return ({
          // icon: action.identifier,
          onClick: handleOpenViewClick,
          name: action.identifier,
          label: action.name
        })

      default:
        return null
    }
  }) || []

  return (
    <Block masonryItemRef={blockRef} {...other}>
      <Button
        size={style.size}
        variant={style.variant}
        mode={style.mode}
        label={label}
        icon={style.icon}
        iconSize={style.iconSize}
        iconPlacement={style.iconPlacement}
        href={href}
        {...action}
      />
    </Block>
  )
}

export type { ButtonBlockProps }

export default ButtonBlock
