import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'
import type { DashboardFragmentFragment as DashboardFragment } from 'generated/schema'

enum SourceType {
  ENVIRONMENT = 'ENVIRONMENT',
  INSTALLATION = 'INSTALLATION',
  RESOURCE = 'RESOURCE',
  MENU_ELEMENT = 'MENU_ELEMENT',
}

class Dashboard extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required()
  })

  static dashboardIcon({ identifier }: DashboardFragment) {
    if (identifier === 'workspace' || identifier === 'personal') return 'manage'
    return 'dashboard'
  }
}

export { SourceType }

export default Dashboard
