import classNames from 'classnames'
import React, { useEffect } from 'react'
import type { MouseEvent } from 'react'
import { useLocation } from 'react-router-dom'

import BaseLink from 'components/links/BaseLink'
import Divider from 'components/divider/Divider'
import Icon from 'components/icons/Icon'
import TopbarMenuItem from 'components/topbar/TopbarMenuItem'
import { css } from 'styles/stitches'
import type useLongPress from 'hooks/useLongPress'
import type { BaseLinkProps } from 'components/links/BaseLink'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PopoverToggleProps } from 'components/popover'

type CommonProps = {
  isDragging?: boolean,
  isFooter?: boolean,
  menuElement: ComputedMenuElement,
  popoverToggleProps?: PopoverToggleProps,
  longPressProps?: ReturnType<typeof useLongPress>
}

type TopbarSubMenuElementProps = CommonProps & {
  isOver?: boolean,
  onMenuElementClick?: (menuElement: ComputedMenuElement) => void
}

type MenuLinkItemProps = BaseLinkProps & CommonProps & {
  className?: string
}

type MenuElementProps = CommonProps & {
  className?: string,
  onClick?: (event: MouseEvent<any>) => void
}

const footerItemStyle = css({
  color: 'dark500',
  fontSize: 12,
  fontWeight: 'bold',
  textDecoration: 'underline'
})

const MenuLinkItem = ({
  isDragging, menuElement, popoverToggleProps, longPressProps, ...others
}: MenuLinkItemProps) => {
  const { fullPath, name, target, url } = menuElement

  const linkProps: BaseLinkProps = {}

  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  if (target === 'URL') {
    linkProps.href = url
  } else {
    linkProps.to = fullPath
    linkProps.exact = true
  }

  return (
    <TopbarMenuItem
      as={BaseLink}
      isHighlighted={isActive}
      {...contextMenuProps}
      {...longPressProps}
      {...linkProps}
      {...others}
    >
      {name}
      {target === 'URL' && <Icon name="external-link" size={10} data-icon />}
    </TopbarMenuItem>
  )
}

const MenuSeparator = ({ menuElement, popoverToggleProps, longPressProps }: MenuElementProps) => {
  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  if (menuElement.separatorStyle === 'RULER') {
    return (
      <TopbarMenuItem
        spacing="none"
        {...contextMenuProps}
      >
        <Divider orientation="horizontal" spacing={20} {...longPressProps} />
      </TopbarMenuItem>
    )
  }

  return (
    <TopbarMenuItem
      spacing="none"
      {...contextMenuProps}
    >
      <Divider orientation="horizontal" variant="whitespace" spacing={20} {...longPressProps} />
    </TopbarMenuItem>
  )
}

const SubMenuItem = ({
  menuElement, popoverToggleProps, longPressProps, ...others
}: MenuElementProps) => {
  const { pathname } = useLocation()

  const isSubMenuItemActive = pathname.startsWith(menuElement.fullPath!)

  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  return (
    <TopbarMenuItem
      isActive={isSubMenuItemActive}
      isHighlighted={isActive}
      {...contextMenuProps}
      {...longPressProps}
      {...others}
    >
      <span>{menuElement.name}</span>
      <Icon name="arrow-right" size={10} data-icon />
    </TopbarMenuItem>
  )
}

const MenuGroup = ({
  menuElement, popoverToggleProps, longPressProps, ...others
}: MenuElementProps) => {
  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  return (
    <TopbarMenuItem
      key={menuElement.id}
      isInteractive={false}
      {...contextMenuProps}
      {...longPressProps}
      {...others}
    >
      <span>{menuElement.name}</span>
      <Icon name="arrow-down" size={10} data-icon />
    </TopbarMenuItem>
  )
}

const menuGroupHeader = css({
  color: 'dark500',
  fontSize: 12,
  textTransform: 'uppercase'
})

function TopbarSubMenuElement({
  isDragging = false,
  isOver = false,
  isFooter = false,
  menuElement,
  onMenuElementClick,
  popoverToggleProps,
  longPressProps
}: TopbarSubMenuElementProps) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (menuElement.kind !== 'ITEM' || menuElement.target !== 'SUBMENU') return
      onMenuElementClick?.(menuElement)
    }, 1000)

    if (!isOver) {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
  }, [ isOver, onMenuElementClick, menuElement ])

  if (menuElement.kind === 'ITEM') {
    if (menuElement.target === 'SUBMENU') {
      return (
        <SubMenuItem
          className={classNames({
            [footerItemStyle]: isFooter
          })}
          key={menuElement.id}
          menuElement={menuElement}
          onClick={() => onMenuElementClick?.(menuElement)}
          popoverToggleProps={popoverToggleProps}
          longPressProps={longPressProps}
        />
      )
    }

    if (menuElement.fullPath) {
      return (
        <MenuLinkItem
          className={classNames({
            [footerItemStyle]: isFooter
          })}
          isDragging={isDragging}
          key={menuElement.id}
          menuElement={menuElement}
          popoverToggleProps={popoverToggleProps}
          longPressProps={longPressProps}
        />
      )
    }
  }

  if (menuElement.kind === 'SEPARATOR') {
    return (
      <MenuSeparator
        key={menuElement.id}
        menuElement={menuElement}
        popoverToggleProps={popoverToggleProps}
        longPressProps={longPressProps}
      />
    )
  }

  if (menuElement.kind === 'GROUP') {
    return (
      <MenuGroup
        className={menuGroupHeader}
        key={menuElement.id}
        menuElement={menuElement}
        popoverToggleProps={popoverToggleProps}
        longPressProps={longPressProps}
      />
    )
  }

  return null
}

export default TopbarSubMenuElement
