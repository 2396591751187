import React from 'react'

import BaseLink from 'components/links/BaseLink'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { WorkspaceStatus } from 'generated/schema'

interface Props {
  daysLeft: number,
  status: WorkspaceStatus
}

const TRIAL_BANNER_HEIGHT = 60

const Banner = styled(Flex, {
  cursor: 'pointer',
  position: 'fixed',
  width: '100%',
  height: 60,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'light100',
  border: '1px solid primary100',
  gap: 16,

  '& [data-highlight]': {
    color: 'primary400',
    fontWeight: 'bold'
  },

  '& [data-icon]': {
    color: 'primary400'
  }
})

const TrialBanner: React.FC<Props> = ({ daysLeft, status }) => {
  const suspended = status === 'SUSPENDED'
  const trialOver = daysLeft <= 0
  return (
    <Banner as={BaseLink} to="/~workspace/billing">
      <Text fontSize={14}>
        {!(suspended || trialOver) && (
          <>
            You have <span data-highlight>{daysLeft} {daysLeft === 1 ? 'day' : 'days'} left</span> in your trial. {' '}
          </>
        )}
        <span data-highlight>Enter your billing details</span> to keep using your workspace.
      </Text>
      <Icon data-icon name="circle-arrow-right" size={16} />
    </Banner>
  )
}

export { TRIAL_BANNER_HEIGHT }

export default TrialBanner
