import React, { useCallback } from 'react'
import { string, StringSchema } from 'yup'

import FormField from 'components/form/FormField'
import MarkdownInput from 'components/inputs/MarkdownInput/MarkdownInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { MarkdownInputProps } from 'components/inputs/MarkdownInput/MarkdownInput'

type MarkdownFieldProps = Omit<MarkdownInputProps, 'input' | 'meta'> & fieldProps<'text'>

const getMarkdownFieldSchema = (settings: MarkdownFieldProps['settings'] = {}, isNullable: boolean) => {
  let schema: StringSchema<string | null | undefined> = string().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) schema = schema.required()

  return schema
}

const MarkdownField = ({
  name,
  settings,
  shouldValidate,
  isNullable = false,
  ...others
}: MarkdownFieldProps) => {
  const validate = useCallback((value: string) => {
    getMarkdownFieldSchema(settings, isNullable)
      .validate(value)
      .then(() => { })
      .catch((e) => e.message)
  }, [ settings, isNullable ])

  return (
    <FormField
      name={name}
      component={MarkdownInput}
      parse={(x) => {
        if (!x && isNullable) return null

        return x
      }}
      {...(shouldValidate && { validate })}
      {...others}
    />
  )
}

export type { MarkdownFieldProps }
export { getMarkdownFieldSchema }
export default MarkdownField
