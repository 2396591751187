import React, { forwardRef, Ref } from 'react'

import * as mixins from 'styles/mixins'
import BackLink from 'components/links/BackLink'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Space } from 'styles/theme'

const TOP_MENU_HEADER_SPACING_X: Space = 24
const TOP_MENU_HEADER_SPACING_Y: Space = 20

const StyledPopoverHeader = styled(Flex, {
  ...mixins.transition('simple'),

  paddingY: TOP_MENU_HEADER_SPACING_Y,
  paddingX: TOP_MENU_HEADER_SPACING_X
})

type PopoverHeaderProps = {
  onBackClick? : () => void,
  showBack? : boolean,
  title : string,
  withDivider?: boolean
}

const PopoverHeader = forwardRef((
  { title, showBack, onBackClick, withDivider }: PopoverHeaderProps,
  ref: Ref<any>
) => (
  <>
    <StyledPopoverHeader ref={ref} justifyContent="center" direction="column" gap={20}>
      {showBack && (
        <BackLink label="Back" onClick={onBackClick} />
      )}
      <Text
        color="dark900"
        fontSize={16}
        fontWeight="bold"
      >
        {title}
      </Text>
    </StyledPopoverHeader>
    {withDivider && <Divider />}
  </>
))

export default PopoverHeader
