// https://gist.github.com/gre/296291b8ce0d8fe6e1c3ea4f1d1c5c3b
const regex = /(auto|scroll)/

const getStyle = (element: HTMLElement, prop: string) => (
  getComputedStyle(element, null).getPropertyValue(prop)
)

const isScrollable = (element: HTMLElement) => regex.test(
  getStyle(element, 'overflow')
    + getStyle(element, 'overflow-y')
    + getStyle(element, 'overflow-x')
)

const getScrollParent = (element: HTMLElement | null): HTMLElement => (
  // eslint-disable-next-line no-nested-ternary
  !element || element === document.body
    ? document.body
    : isScrollable(element)
      ? element
      : getScrollParent(element.parentElement)
)

export default getScrollParent
