import React, { SyntheticEvent, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Button from 'components/buttons/Button'
import ExternalFormFooter from 'components/formFooters/ExternalFormFooter'
import ExternalHint from 'components/typography/ExternalHint'
import ExternalParagraph from 'components/typography/ExternalParagraph'
import ExternalTitle from 'components/typography/ExternalTitle'
import Flex from 'components/layout/Flex'
import ForgotPasswordForm from 'components/forms/ForgotPasswordForm'
import TextLink from 'components/links/TextLink'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { useForgotPasswordMutation, ForgotPasswordInput } from 'generated/schema'

type ForgotPasswordPageLocationState = {
  email: string
}

function ForgotPasswordPage() {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const location = useLocation<ForgotPasswordPageLocationState>()

  const [ email, setEmail ] = useState(location.state?.email || '')
  const [ forgotPasswordRequested, setForgotPasswordRequested ] = useState(false)

  const [ forgotPassword ] = useForgotPasswordMutation({
    onCompleted: () => setForgotPasswordRequested(true)
  })

  const handleForgotPassword = useSubmitHandler(forgotPassword)

  const onFormSubmit = (values: ForgotPasswordInput & { id?: any }) => {
    setEmail(values.email)
    return handleForgotPassword(values)
  }

  const onTryAgainClick = (e: SyntheticEvent) => {
    e.preventDefault()

    setForgotPasswordRequested(false)
  }

  const renderFooter = () => (
    <ExternalFormFooter>
      <Button
        label="Back to Login"
        size="small"
        to={{ pathname: '/login', state: { email } }}
        variant="simple"
      />
    </ExternalFormFooter>
  )

  return (
    <Flex direction="column" gap={48}>
      {!forgotPasswordRequested && (
        <>
          <Flex direction="column" gap={16}>
            <ExternalTitle>Forgot Your Password?</ExternalTitle>
            <ExternalParagraph>
              It happens! We will send you a link to reset your password.
            </ExternalParagraph>
          </Flex>
          <ForgotPasswordForm
            initialValues={
              { workspaceId: currentWorkspace?.id, email } as ForgotPasswordInput & { id: any}
            }
            onSubmit={onFormSubmit}
          />

          {renderFooter()}
        </>
      )}

      {forgotPasswordRequested && (
        <>
          <ExternalTitle>Check your inbox!</ExternalTitle>
          <Flex direction="column" gap={20}>
            <ExternalParagraph>
              We’ve sent a password reset link to
              {' '}
              <strong>{email}</strong>
              . If you don’t receive an email within 5 minutes,
              please check you Spam folder.
            </ExternalParagraph>
            <ExternalHint>
              Taking too long? You can
              {' '}
              <TextLink as="button" type="button" mode="subtle" onClick={onTryAgainClick}>try again</TextLink>
              .
            </ExternalHint>
          </Flex>

          {renderFooter()}
        </>
      )}
    </Flex>
  )
}

export default ForgotPasswordPage
