import React, { ReactNode } from 'react'

import Text from 'components/typography/Text'
import type { TextProps } from 'components/typography/Text'

type LabelProps = TextProps & {
  children: ReactNode
}

function Label({ children, ...rest }: LabelProps) {
  return (
    <Text
      as="label"
      color="dark500"
      fontSize={12}
      textTransform="uppercase"
      {...rest}
    >
      {children}
    </Text>
  )
}

export default Label
