import React, { useEffect, useState } from 'react'
import uuid from 'uuid-random'
import { useDraggable } from '@dnd-kit/core'
import { useRecoilState } from 'recoil'

import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import IconCard from 'components/iconCard/IconCard'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import useHotKey from 'hooks/useHotKey'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { BLOCK_TYPE_TO_ICON_MAP, BLOCK_TYPE_TO_NAME_MAP, Views } from './constants'
import type { ActiveViewProps } from './DashboardEditor'
import type { BlockType } from 'components/blocks'

function AddBlockView({ onClose }: ActiveViewProps) {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add Block"
        heading="Dashboard Editor"
        onClose={onClose}
        onSearch={(e) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Basics search={search} />
        <Datas search={search} />
        <Charts search={search} />
        <Layouts search={search} />
        <Elements search={search} />
      </DashboardEditorBody>
    </>
  )
}

const blocksByCategory = {
  basics: [
    {
      title: 'Header',
      icon: 'heading',
      type: 'HeaderBlock',
      properties: {
        heading: 'Untitled',
        style: 'H1'
      }
    },
    {
      title: 'Notes',
      icon: 'notes',
      type: 'NotesBlock',
      properties: {
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vestibulum sollicitudin hendrerit. Nullam a nibh sed massa egestas auctor. Sed sed mauris mi. Curabitur elementum tellus euismod lacus imperdiet, eu mattis magna cursus. Fusce ut vestibulum nibh. Nulla finibus a sapien et dapibus. Phasellus fringilla pharetra magna quis consectetur. Nam id venenatis ligula, at luctus mauris. Aenean condimentum iaculis augue id blandit. Nulla eget dictum metus, et congue dolor.'
      }
    },
    {
      title: 'Stat',
      icon: 'number-field',
      type: 'StatBlock',
      properties: {
        heading: 'Untitled',
        format: {
          style: 'NUMBER',
          precision: 1,
          currency_code: 'USD'
        }
      }
    },
    {
      title: 'Links',
      icon: 'unlink',
      type: 'QuickLinksBlock',
      properties: {
        title: 'Links',
        links: [
          {
            label: 'Website',
            url: 'https://www.dashx.com'
          },
          {
            label: 'Docs',
            url: 'https://docs.dashx.com'
          }
        ]
      }
    }
  ],
  datas: [
    {
      title: 'Table',
      icon: 'table',
      type: 'DataTableBlock',
      properties: {
        heading: 'Untitled',
        selection_mode: 'MULTIPLE',
        pagination: {
          is_enabled: true,
          per_page_count: 10
        }
      }
    },
    {
      title: 'List',
      icon: 'data-list',
      type: 'DataListBlock',
      properties: {
        heading: 'Untitled',
        selection_mode: 'SINGLE'
      }
    },
    /* {
      title: 'Grid',
      icon: 'grid',
      type: 'GridBlock',
      properties: {}
    }, */
    {
      title: 'Form',
      icon: 'form',
      type: 'FormBlock',
      properties: {}
    },
    {
      title: 'Details',
      icon: 'details',
      type: 'DetailsBlock',
      properties: {}
    },
    {
      title: 'Email',
      icon: 'email',
      type: 'EmailPreviewBlock',
      properties: {}
    }
  ],
  charts: [
    {
      title: 'Bar',
      icon: 'bar-chart',
      type: 'BarChartBlock',
      properties: {}
    },
    {
      title: 'Line',
      icon: 'line-chart',
      type: 'LineChartBlock',
      properties: {}
    },
    {
      title: 'Pie',
      icon: 'pie-chart',
      type: 'PieChartBlock',
      properties: {}
    }
  ],
  layouts: [
    {
      title: 'Tabs',
      icon: 'tabs',
      type: 'TabsBlock',
      properties: {
        variant: 'fixed'
      }
    },
    {
      title: 'Columns',
      icon: 'columns',
      type: 'ColumnsBlock',
      properties: {}
    }
  ],
  elements: [
    {
      title: 'Dropdown',
      icon: 'dropdown-field',
      type: 'DropdownBlock',
      properties: {}
    },
    {
      title: 'Text Input',
      icon: 'text-field',
      type: 'TextInputBlock',
      properties: {}
    },
    {
      title: 'Button',
      icon: 'button',
      type: 'ButtonBlock',
      properties: {
        label: 'Button',
        style: {
          variant: 'filled',
          mode: 'distinct',
          size: 'normal',
          rounded: 'all',
          iconPlacement: 'left'
        },
        type: 'BUTTON'
      }
    },
    {
      title: 'CSV Input',
      icon: 'grid',
      type: 'CSVInputBlock',
      properties: {}
    }
  ]
}

const DraggableBlockCard = ({ block, isActive, isOverlay }: any) => {
  const { updateBlock, selectBlock, openDashboardEditorView } = useDashboard()

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: block.type,
    data: block
  })

  const { urn } = useDashboardEditorContextProvider()

  const onAdd = () => {
    const id = uuid()
    updateBlock(urn, {
      id,
      type: block.type,
      properties: block.properties
    } as any, true)
    selectBlock(id)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  return (
    <IconCard
      width={108}
      height={108}
      icon={BLOCK_TYPE_TO_ICON_MAP[block.type as BlockType]}
      label={BLOCK_TYPE_TO_NAME_MAP[block.type as BlockType]}
      css={{
        opacity: isActive ? 0.9 : 1,
        border: isActive && !isOverlay ? '1px dashed gray' : '1px solid transparent'
      }}
      active={isActive}
      onClick={onAdd}
      actions={[
        {
          icon: 'drag-block',
          onClick: onAdd
        }
      ]}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    />
  )
}

const withBlockCategory = (category: keyof typeof blocksByCategory, title: string) => ({ search = '' }: { search: string }) => {
  const { draggedBlockState } = useDashboard()

  const [ draggedBlock, setDraggedBlock ] = useRecoilState(draggedBlockState)

  useEffect(() => () => {
    setDraggedBlock(null)
  }, [ setDraggedBlock ])

  useHotKey({ Escape: () => setDraggedBlock(null) })

  const filteredList = blocksByCategory[category].filter(
    (block) => block.title.toLowerCase().includes(search.toLowerCase())
  )

  if (!filteredList.length) return null

  return (
    <Flex gap={16} direction="column">
      <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">{title}</Text>
      <Grid columns={3} gap={2}>
        {filteredList.map((block) => {
          const isActive = draggedBlock?.type === block.type && !draggedBlock?.id
          return <DraggableBlockCard key={block.type} block={block} isActive={isActive} />
        })}
      </Grid>
    </Flex>
  )
}

const Basics = withBlockCategory('basics', 'Basic Blocks')

const Datas = withBlockCategory('datas', 'Data Blocks')

const Charts = withBlockCategory('charts', 'Chart Blocks')

const Layouts = withBlockCategory('layouts', 'Layout Blocks')

const Elements = withBlockCategory('elements', 'Elements')

export default AddBlockView

export { blocksByCategory, DraggableBlockCard }
