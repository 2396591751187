import React, { useContext } from 'react'

import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import OperationsView from 'components/dashboardEditor/graph/OperationsView'
import ResourcesView from 'components/dashboardEditor/graph/ResourcesView'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from '../DashboardEditor'
import type { Workspace } from 'generated/schema'

const WorkspaceSchemaView = ({ onClose }: ActiveViewProps) => {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  return (
    <>
      <DashboardEditorHeader
        subtitle="Schema"
        heading="Dashboard Editor"
        onClose={onClose}
      />
      <DashboardEditorBody>
        <ResourcesView workspaceId={currentWorkspace.id} />
        <OperationsView workspaceId={currentWorkspace.id} />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const actions = [
  {
    title: 'Create Resource',
    identifier: Views.CREATE_RESOURCE,
    media: 'graph'
  },
  {
    title: 'Create Operation',
    identifier: Views.CREATE_OPERATION,
    media: 'advance'
  },
  {
    title: 'Import from Database',
    identifier: Views.IMPORT_FROM_DATABASE,
    media: 'app-database-hub'
  },
  {
    title: 'Import from API',
    identifier: Views.IMPORT_FROM_API,
    media: 'app-api-hub'
  }
]

const Actions = () => {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const { openDashboardEditorView } = useDashboard()

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const onClick = () => openDashboardEditorView({
            target: action.identifier,
            params: { workspace: currentWorkspace as Workspace }
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              onClick={onClick}
              media={action.media}
              title={action.title}
              height={64}
              width="full"
              actions={[ {
                description: '',
                icon: 'arrow-right',
                onClick,
                isIconAlwaysVisible: true
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default WorkspaceSchemaView
