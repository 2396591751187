import React from 'react'

import Block from './Block'
import Flex from 'components/layout/Flex'
import spaces from 'styles/primitives/spaces'
import Text from 'components/typography/Text'
import { css } from 'styles/stitches'
import { useMasonryContext } from 'components/layout/Masonry'
import type { BlockProps } from './Block'
import type { Space } from 'styles/theme'

type HeaderBlockProps = BlockProps & {
  blockRef?: React.RefCallback<HTMLElement>,
  heading: string,
  style: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6',
  primaryElements?: React.ReactNode,
  secondaryElements?: React.ReactNode
}

const TITLE_BLOCK_SECTION_ELEMENTS_GAP = 16
const TITLE_BLOCK_VERTICAL_PADDING = 20
const TITLE_BLOCK_PRIMARY_SECTION_ELEMENTS_GAP = 8
const TITLE_BLOCK_SECONDARY_SECTION_ELEMENTS_GAP = 32

const titleClass = css({
  marginLeft: `-${spaces[2]}`,
  width: '-webkit-fill-available'
})

const wrapperClass = (containerPadding: Space) => css({
  borderBottomColor: 'light700',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  paddingX: containerPadding,
  paddingY: TITLE_BLOCK_VERTICAL_PADDING
})

const sizeMap = {
  H1: 30,
  H2: 24,
  H3: 20,
  H4: 18,
  H5: 16,
  H6: 14
} as const

function HeaderBlock(
  { blockRef, heading, primaryElements, secondaryElements, style, ...other }: HeaderBlockProps
) {
  const { containerPadding } = useMasonryContext()

  return (
    <Block fullWidth masonryItemRef={blockRef} {...other}>
      <Flex direction="column" className={wrapperClass(containerPadding)} gap={4} grow={1}>
        <Flex alignItems="center" gap={TITLE_BLOCK_SECTION_ELEMENTS_GAP} justifyContent="space-between">
          <Flex alignItems="center" gap={TITLE_BLOCK_PRIMARY_SECTION_ELEMENTS_GAP} grow={1} style={{ width: 0 }}>
            <Text fontWeight="bold" fontSize={sizeMap[style]} letterSpacing="compact" className={titleClass} truncate>
              {heading}
            </Text>
            {primaryElements}
          </Flex>
          <Flex alignItems="stretch" gap={TITLE_BLOCK_SECONDARY_SECTION_ELEMENTS_GAP}>
            {secondaryElements}
          </Flex>
        </Flex>
      </Flex>
    </Block>
  )
}

export { sizeMap }

export type { HeaderBlockProps }

export default HeaderBlock
