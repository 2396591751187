import { gql } from '@apollo/client'

import { cache } from 'client'

import type { ClientState } from 'client/state/types'
import type { Environment } from 'generated/schema'

/* Default Data */

const DEFAULT_SWITCHER: EnvironmentSwitcher = {
  __typename: 'EnvironmentSwitcher',
  id: 'CORE',
  environment: null
}

/* Types */

export type EnvironmentSwitcher = {
  __typename: 'EnvironmentSwitcher',
  id: string | null,
  environment: Environment | null
}

export type EnvironmentSwitcherQuery = {
  environmentSwitcher: EnvironmentSwitcher
}

/* Queries */

export const ENVIRONMENT_SWITCHER_QUERY = gql`
  query EnvironmentSwitcherQuery($id: String) {
    environmentSwitcher(id: $id) @client {
      id
      environment {
        ...EnvironmentFragment
      }
    }
  }

  fragment EnvironmentFragment on Environment {
    id
    workspaceId
    name
    identifier
    isLive
    createdAt
    updatedAt
  }
`

/* Default Query */

export const writeDefaults = {
  query: ENVIRONMENT_SWITCHER_QUERY,
  data: { environmentSwitcher: DEFAULT_SWITCHER }
}

/* Mutations */

export const SET_ENVIRONMENT_SWITCHER_MUTATION = gql`
  mutation SetEnvironmentSwitcherMutation($environment: Environment) {
    setEnvironmentSwitcher(environment: $environment) @client
  }
`

/* ClientState */

export default {
  defaults: writeDefaults,
  resolvers: {
    Query: {
      environmentSwitcher: (_, __, { cache }) => {
        const data = cache.readQuery({
          query: ENVIRONMENT_SWITCHER_QUERY
        })

        return data?.environmentSwitcher || DEFAULT_SWITCHER
      }
    },
    Mutation: {
      setEnvironmentSwitcher: (_, { environment }) => {
        cache.writeQuery({
          query: ENVIRONMENT_SWITCHER_QUERY,
          data: {
            environmentSwitcher: {
              __typename: 'EnvironmentSwitcher',
              id: 'CORE',
              environment
            }
          }
        })

        return {
          __typename: 'EnvironmentSwitcher',
          id: 'CORE',
          environment
        }
      }
    }
  }
} as ClientState
