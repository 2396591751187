import React, { useContext, useState } from 'react'
import startCase from 'lodash/startCase'
import { Field, Form, FormProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import type { Decorator, FormApi } from 'final-form'

import App, { AppPlatform, AppTechnology as AppTechnologyEnum } from 'models/App'
import Box from 'components/layout/Box'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import HintBox from 'components/hints/HintBox'
import Icon from 'components/icons/Icon'
import IconInput from 'components/inputs/IconInput'
import MediaCard from 'components/mediaCard/MediaCard'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { App as AppType, AppListDocument, AppListQueryVariables, AppTechnology, CreateAppInput, CreateAppMutation, UpdateAppInput, useCreateAppMutation, useUpdateAppMutation } from 'generated/schema'
import type { ViewProps } from 'components/views'

type FormValues = CreateAppInput & {id?: string}

type ViewParamsType = {
  initialValues: AppType | FormValues,
  queryVariables: AppListQueryVariables
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const platformTypes = [
  {
    name: AppPlatform.BROWSER,
    icon: 'globe',
    description: 'Website, Blog or Portal'
  }, {
    name: AppPlatform.MOBILE,
    icon: 'phone',
    description: 'Native iOS/Android, React Native, Flutter'
  }, {
    name: AppPlatform.SERVER,
    icon: 'app-database-hub',
    description: 'Node.js, Python, PHP, Ruby, Rust, Go'
  }
]

const technologyTypes = {
  [AppPlatform.BROWSER]: [
    {
      name: 'React.js',
      description: 'Single page application written in React.js',
      identifier: AppTechnologyEnum.REACT,
      icon: 'react-colored'
    }, {
      name: 'Angular.js',
      description: 'Single page application written in Angular.js',
      identifier: AppTechnologyEnum.ANGULAR,
      icon: 'angular-colored'
    }, {
      name: 'Vue.js',
      description: 'Single page application written in Vue.js',
      identifier: AppTechnologyEnum.VUE,
      icon: 'vue-colored'
    }, {
      name: 'Remix',
      description: 'Single page application written in Remix.js',
      identifier: AppTechnologyEnum.REMIX,
      icon: 'remix-colored'
    }, {
      name: 'Other Framework',
      description: 'Any JavaScript / TypeScript framework',
      identifier: AppTechnologyEnum.JAVASCRIPT,
      icon: 'javascript-colored'
    }, {
      name: 'Static HTML',
      description: 'Web app written using HTML, JS & CSS',
      identifier: AppTechnologyEnum.JAVASCRIPT,
      icon: 'html-colored'
    }
  ],

  [AppPlatform.MOBILE]: [
    {
      name: 'Native iOS',
      description: 'Mobile app written in Swift or Objective-C',
      identifier: AppTechnologyEnum.NATIVE_IOS,
      icon: 'native-ios-colored'
    }, {
      name: 'Native Android',
      description: 'Mobile app written in Kotlin or Java',
      identifier: AppTechnologyEnum.NATIVE_ANDROID,
      icon: 'native-android-colored'
    }, {
      name: 'React Native',
      description: 'Cross platform app written using React Native',
      identifier: AppTechnologyEnum.REACT_NATIVE,
      icon: 'react-colored'
    }, {
      name: 'Flutter',
      description: 'Cross platform app written using Flutter',
      identifier: AppTechnologyEnum.FLUTTER,
      icon: 'flutter-colored'
    }
  ],

  [AppPlatform.SERVER]: [
    {
      name: 'Node,js',
      description: 'Server-side app written in Node.js',
      identifier: AppTechnologyEnum.NODE,
      icon: 'node-colored'
    }, {
      name: 'Python',
      description: 'Server-side app written in Python',
      identifier: AppTechnologyEnum.PYTHON,
      icon: 'python-colored'
    }, {
      name: 'PHP',
      description: 'Server-side app written in PHP',
      identifier: AppTechnologyEnum.PHP,
      icon: 'php-colored'
    }, {
      name: 'Ruby',
      description: 'Server-side app written in Ruby',
      identifier: AppTechnologyEnum.RUBY,
      icon: 'ruby-colored'
    }, {
      name: 'Rust',
      description: 'Server-side app written in Rust',
      identifier: AppTechnologyEnum.RUST,
      icon: 'rust-colored'
    }, {
      name: 'Go',
      description: 'Server-side app written in Go',
      identifier: AppTechnologyEnum.GO,
      icon: 'go-colored'
    }
  ]
}

const stepSubTitles = [
  'Step 1: Select your platform',
  'Step 2: Select your technology',
  'Step 3: Enter details'
]

const PLATFORM_OPTIONS: {label: string, value: string}[] = [
  { label: 'Android', value: 'ANDROID' },
  { label: 'Browser', value: 'BROWSER' },
  { label: 'iOS', value: 'IOS' },
  { label: 'Server', value: 'SERVER' }
]

function CreateAppView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { initialValues, queryVariables, ...rest } = params
  const { push } = useHistory()
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const isUpdating = 'id' in initialValues
  const isProject = initialValues.kind === 'PROJECT'

  const title = `${isUpdating ? 'Edit' : 'New'} ${isProject ? 'Project' : 'App'}`

  const hideSteps = isUpdating || !isProject

  const [ currentStep, setCurrentStep ] = useState(hideSteps ? 2 : 0)

  const isFirstStep = currentStep === 0
  const isSecondStep = currentStep === 1
  const isFinalStep = currentStep === 2

  const handleStepForward = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleStepBehind = () => {
    setCurrentStep(currentStep - 1)
  }

  const [ platform, setPlatform ] = useState<AppPlatform | null>(null)
  const [ technology, setTechnology ] = useState<AppTechnology | undefined>(undefined)

  const [ createApp ] = useCreateAppMutation({
    onCompleted: async (data: CreateAppMutation) => {
      const { id } = data.createApp
      onRequestClose()
      push({
        pathname: 'apps',
        search: `id=${id}`
      })
    }
  })

  const [ updateApp ] = useUpdateAppMutation({
    onCompleted: onRequestClose
  })

  const handleCreateApp = useSubmitHandler(createApp, {
    update: {
      strategy: 'APPEND',
      query: AppListDocument,
      dataKey: 'appsList',
      mutation: 'createApp',
      queryVariables
    }
  })

  const handleUpdateApp = useSubmitHandler(updateApp, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateApp',
      typename: 'App'
    }
  })

  const handleSubmit = (
    values: FormValues, form: FormProps<FormValues>['form']
  ) => {
    if ('id' in initialValues) {
      return handleUpdateApp(values as UpdateAppInput, form as FormApi<any>)
    }
    return handleCreateApp(values as CreateAppInput)
  }

  const FirstStep = () => (
    <Flex direction="column" gap={24}>
      {platformTypes.map((p) => (
        <MediaCard
          active={platform === p.name}
          key={p.name}
          text={p.description}
          titlePosition="top"
          media={p.icon}
          onClick={() => {
            setPlatform(p.name)
            handleStepForward()
          }}
          title={startCase(p.name.toLowerCase())}
          width="full"
        />
      ))}
    </Flex>
  )

  const SecondStep = () => platform && (
    <Flex direction="column" gap={24}>
      <Text fontWeight="bold">{startCase(platform.toLowerCase())}</Text>
      {technologyTypes[platform].map((t) => (
        <MediaCard
          active={technology === t.name}
          key={t.name}
          text={t.description}
          titlePosition="top"
          media={(
            <Icon
              reqContext={require.context('assets/images/technologies', true, /\.svg$/)}
              name={t.icon}
              size={32}
            />
          )}
          onClick={() => {
            setTechnology(t.identifier)
            handleStepForward()
          }}
          title={t.name}
          width="full"
        />
      ))}
    </Flex>
  )

  const FinalStep = () => (
    <Flex direction="column" gap={16}>
      <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
      <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" />
      <Field
        component={IconInput}
        name="icon"
        label="Icon"
        placeholder="Choose Icon"
        size="small"
        type="text"
      />
      <Field
        name="summary"
        label="Summary"
        helpText={`Add notes regarding the ${isProject ? 'project' : 'app'}'s intended use cases. Supports markdown.`}
        component={TextAreaInput}
        size="small"
      />
      <>
        {isProject && (
          <Field
            component={TextInput}
            disabled
            format={(value) => startCase(value?.toLowerCase())}
            helpText={isUpdating ? 'If you wish to change this, create a new project.' : ''}
            label={isUpdating ? 'Built Using' : ''}
            name="technology"
            size="small"
            type={isUpdating ? 'input' : 'hidden'}
          />
        )}
      </>
      {isUpdating && isProject && (
        <Field
          component={SelectInput}
          isDisabled
          isMulti
          label="Platforms"
          name="platforms"
          options={PLATFORM_OPTIONS}
          size="small"
        />
      )}
    </Flex>
  )

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, SubHeader, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          {!hideSteps && (
            <SubHeader>
              <Text fontWeight="bold">{stepSubTitles[currentStep]}</Text>
            </SubHeader>
          )}
          <Form
            decorators={[
              setIdentifier
            ]}
            initialValues={{
              technology,
              summary: `This is a ${isProject ? 'Project' : 'Custom App'} for ${currentWorkspace.name} workspace.`,
              ...initialValues
            }}
            onSubmit={handleSubmit}
            validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  {(!isUpdating && (isFirstStep || !isProject)) && (
                    <>
                      <HintBox>
                        <TextLink href={isProject ? 'https://docs.dashx.com/platform/projects' : 'https://docs.dashx.com/platform/apps/custom-apps'}>
                          Learn more
                        </TextLink>
                        {' '}about {`${isProject ? 'Projects' : 'Custom Apps'}`}, including best practices and guidelines.
                      </HintBox>
                      <Box css={{ height: 24 }} />
                    </>
                  )}
                  {isFirstStep && <FirstStep />}
                  {isSecondStep && <SecondStep />}
                  {isFinalStep && <FinalStep />}
                </Body>
                <Footer>
                  <Flex alignItems="center" grow={1} justifyContent={hideSteps ? 'flex-end' : 'space-between'}>
                    {!hideSteps && <Chip label={`Step ${currentStep + 1}`} icon="arrow-right" iconPlacement="right" variant="light" />}
                    <Flex gap={16}>
                      {!isFirstStep && !hideSteps && <Button icon="arrow-left" onClick={handleStepBehind} />}
                      {!isFinalStep && (
                        <Button
                          disabled={(isFirstStep && !platform)
                            || (isSecondStep && !technology)}
                          icon="arrow-right"
                          onClick={handleStepForward}
                        />
                      )}
                      {isFinalStep && <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />}
                    </Flex>
                  </Flex>
                </Footer>
              </>
            )}
            {...rest}
          />
        </>
      )}
    </View>
  )
}

CreateAppView.defaultStyle = 'PANEL' as const

export default CreateAppView
