import React from 'react'

import * as mixins from 'styles/mixins'
import Block, { Size } from 'components/blocks/Block'
import Card from 'components/card/Card'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import { styled } from 'styles/stitches'
import type { BlockProps } from 'components/blocks/Block'

type QuickLinksBlockProps = {
  title?: string,
  width?: Size,
  icon?: string,
  links?: Array<{
    label: string,
    url: string
  }>
}

const QUICK_LINKS_BLOCK_WIDTH = 260
const QUICK_LINKS_BLOCK_HEIGHT = '100%'
const QUICK_LINKS_HEADER_HEIGHT = 50
const QUICK_LINK_HEIGHT = 48

const Header = styled(Flex, {
  width: '100%',
  height: QUICK_LINKS_HEADER_HEIGHT,
  padding: '16px 20px',
  backgroundColor: 'light200',
  alignItems: 'center',
  gap: 8,
  borderBottom: '1px solid light700',
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,

  '& [data-icon]': {
    color: 'dark500'
  }
})

const Content = styled(Flex, {
  width: '100%'
})

const Title = styled(Text, {
  color: 'dark900',
  fontSize: 12,
  fontWeight: 700,
  lineHeight: '16px'
})

const LinkItem = styled(Flex, {
  ...mixins.transition('fluid'),

  position: 'relative',
  width: '100%',
  height: QUICK_LINK_HEIGHT,

  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid light700',

  '& [data-icon]': {
    ...mixins.transition('fluid'),

    position: 'absolute',
    right: 20,

    color: 'transparent'
  },

  '&:hover': {
    backgroundColor: 'light200',

    [`& ${TextLink}`]: {
      color: 'primary300'
    },

    '& [data-icon]': {
      color: 'primary300'
    }
  }
})

const Link = styled(TextLink, {
  width: '100%',
  height: '100%',
  color: 'dark900',
  fontSize: 12,
  padding: '16px 20px'
})

const QuickLinks = ({ title, links = [], icon = 'support' }: QuickLinksBlockProps) => (
  <Card alignItems="flex-start" padding="none" mode="actionable">
    <Header>
      <Icon data-icon name={icon} size={12} />
      <Title>{title}</Title>
    </Header>
    <Content direction="column">
      {links.map((link) => (
        <LinkItem key={link.url}>
          <Link href={link.url} mode="distinct">{link.label}</Link>
          <Icon data-icon name="arrow-right" size={10} />
        </LinkItem>
      ))}
    </Content>
  </Card>
)

function QuickLinksBlock({
  title = 'Quick Links',
  description,
  links = [],
  icon,
  ...others
}: BlockProps & QuickLinksBlockProps) {
  return (
    <Block
      name="QuickLinks"
      minWidth={QUICK_LINKS_BLOCK_WIDTH}
      minHeight={QUICK_LINKS_BLOCK_HEIGHT}
      direction="column"
      {...others}
    >
      <QuickLinks title={title} links={links} icon={icon} />
    </Block>
  )
}

export { QuickLinks }

export type { QuickLinksBlockProps }

export default QuickLinksBlock
