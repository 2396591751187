import React, { useContext } from 'react'

import Block from 'components/blocks/Block'
import TitleBlock from 'components/blocks/TitleBlock'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceDomainForm from 'components/forms/WorkspaceDomainForm'
import {
  useUpdateWorkspaceDomainMutation
} from 'generated/schema'

function DomainPage() {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const [ updateWorkspaceDomain ] = useUpdateWorkspaceDomainMutation()

  const handleSubmitDomainForm = useSubmitHandler(updateWorkspaceDomain, {
    successAlert: { message: 'Domain settings saved.' }
  })

  return (
    <>
      <TitleBlock heading="Domain" />
      <Block width={{ md: '100%' }} direction="column" gap={16}>
        <WorkspaceDomainForm
          initialValues={{
            id: currentWorkspace.id,
            hostname: currentWorkspace.hostname,
            identifier: currentWorkspace.identifier
          }}
          onSubmit={handleSubmitDomainForm}
        />
      </Block>
    </>
  )
}

export default DomainPage
