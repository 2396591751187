import React, { Suspense, useMemo, useState } from 'react'

import componentLoader from 'lib/componentLoader'
import TextInput from 'components/inputs/TextInput'
import zones from 'components/inputs/PhoneInput/zones'
import type { PhoneInputOwnProps } from 'components/inputs/PhoneInput/PhoneInput'

const language = navigator?.language?.replace(/-\w+$/, '') || 'en' // transform en-us -> en
const importLocale = () => import(`react-phone-number-input/locale/${language}.json`)

const importPhoneInput = () => componentLoader('inputs/PhoneInput/PhoneInput')

function PhoneInputHoC({ ...props }: PhoneInputOwnProps) {
  const [ localizedLabels, setLocalizedLabels ] = useState<any>([])
  const PhoneInput = useMemo(() => React.lazy(
    () => importLocale()
      .then((localeModule) => {
        setLocalizedLabels(() => localeModule.default)

        return importPhoneInput()
      })
      .catch(() => {
        import('react-phone-number-input/locale/en.json')
          .then((localeModule) => {
            setLocalizedLabels(() => localeModule.default)

            return importPhoneInput()
          })
      })
  ), [])

  const timeZone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone as keyof typeof zones

  return (
    <Suspense fallback={<TextInput disabled placeholder="Loading..." {...props} />}>
      <PhoneInput labels={localizedLabels} defaultCountry={zones[timeZone]} {...props} />
    </Suspense>
  )
}

export default PhoneInputHoC

export type { PhoneInputOwnProps as PhoneInputProps }
