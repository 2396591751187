import React, { Suspense } from 'react'
import { Field } from 'react-final-form'
import type { FieldProps } from 'react-final-form'

import componentLoader from 'lib/componentLoader'
import jsonlint from 'lib/jsonlint'
import Loader from 'components/loaders/Loader'
import { TAB_SIZE } from 'components/inputs/CodeEditorInput'
import type { CodeEditorInputProps } from 'components/inputs/CodeEditorInput'

const CodeEditorInput = React.lazy(() => componentLoader('inputs/CodeEditorInput'))

type JsonFieldProps = FieldProps<
  Object,
  CodeEditorInputProps,
  HTMLPreElement
>

function JsonField(props: JsonFieldProps) {
  const validate = (json: string) => {
    // If json comes in as string the the parse method was
    // unable to convert it JSON and requires linting.
    if (typeof json === 'string') {
      try {
        jsonlint.parse(json)
      } catch (e: any) {
        return e.message
      }
    }

    return undefined
  }

  const parse = (value: string) => {
    // If the value is valid, then we convert to a JSON object else
    // we return the value as is which gets picked up by the validate function.
    try {
      jsonlint.parse(value)
      return JSON.parse(value)
    } catch {
      return value
    }
  }

  const format = (value: any) => (
    typeof value !== 'string'
      ? JSON.stringify(value || {}, null, TAB_SIZE)
      : value
  )

  return (
    <Suspense fallback={<Loader loading />}>
      <Field
        {...props as FieldProps<string, CodeEditorInputProps, HTMLInputElement>}
        component={CodeEditorInput}
        format={format}
        language="json"
        parse={parse}
        validate={validate}
      />
    </Suspense>
  )
}

export default JsonField
