import React, { Children, cloneElement, useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { useRect } from '@reach/rect'
import type { ReactElement } from 'react'

import * as mixins from 'styles/mixins'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import useCombinedRefs from 'hooks/useCombinedRefs'
import useHover from 'hooks/useHover'
import { styled } from 'styles/stitches'
import { colorVars } from 'styles/theme'

type TooltipProps = {
  children: ReactElement, // must ensure it supports 'ref'
  description: string,
  disabled?: boolean,
  placement?: 'top' | 'bottom'
}

const StyledArrow = styled('div', {
  size: [ 8 ],
  position: 'absolute',

  '[data-popper-placement="top"] > &': {
    bottom: -2
  },

  '[data-popper-placement="bottom"] > &': {
    top: -2
  },

  '&::before': {
    size: [ '100%' ],
    background: 'dark700',
    borderRadius: 1,
    content: '" "',
    display: 'block',
    transform: 'rotate(45deg)'
  }
})

const StyledTooltip = styled('div', {
  ...mixins.shadow('medium', colorVars.dark600rgb, 0.3),

  background: 'dark700',
  borderRadius: 2,
  paddingY: 8,
  paddingX: 16,
  position: 'relative',
  zIndex: 'popover'
})

function Tooltip({ children, description, disabled, placement = 'top' }: TooltipProps) {
  const child = Children.only(children)

  const [ onHoverProps, active ] = useHover({
    onMouseEnter: child.props.onMouseEnter,
    onMouseLeave: child.props.onMouseLeave
  })

  const referenceElement = useRef<Element>(null)
  const [ arrowElement, setArrowElement ] = useState<HTMLDivElement | null>(null)
  const [ popperElement, setPopperElement ] = useState<HTMLDivElement | null>(null)
  const referenceElementRect = useRect(referenceElement, { observe: active })
  const { attributes, styles, update } = usePopper(referenceElement.current, popperElement, {
    strategy: 'fixed',
    placement,
    modifiers: [
      { name: 'offset', options: { offset: [ 0, 6 ] } },
      { name: 'arrow', options: { element: arrowElement } }
    ]
  })

  useEffect(() => {
    if (active && update) {
      update()
    }
  }, [
    active,
    description,
    /* eslint-disable react-hooks/exhaustive-deps */
    referenceElementRect?.bottom,
    referenceElementRect?.height,
    referenceElementRect?.left,
    referenceElementRect?.right,
    referenceElementRect?.top,
    referenceElementRect?.width,
    referenceElementRect?.x,
    referenceElementRect?.y,
    /* eslint-enable */
    update
  ])

  // @ts-ignore
  const combinedRef = useCombinedRefs(referenceElement, child.ref)
  const referenceComponent = cloneElement(child, {
    ref: combinedRef,
    ...onHoverProps
  })

  return (
    <>
      {referenceComponent}
      <Portal>
        {active && !disabled && (
          <StyledTooltip
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <Text color="light100" fontSize={10} textTransform="uppercase">{description}</Text>
            <StyledArrow ref={setArrowElement} style={styles.arrow} />
          </StyledTooltip>
        )}
      </Portal>
    </>
  )
}

export default Tooltip
