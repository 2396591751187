import React from 'react'

import * as mixins from 'styles/mixins'
import BaseLink, { BaseLinkProps } from 'components/links/BaseLink'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Color } from 'styles/theme'

const TEXT_COLOR_NORMAL: { [key: string]: Color } = {
  distinct: 'primary300',
  subtle: 'dark700'
}

const TEXT_COLOR_HOVER: { [key: string]: Color } = {
  distinct: 'primary200',
  subtle: 'dark900'
}

const StyledTextLink = styled(Text, {
  ...mixins.transition('fluid'),

  textDecoration: 'none',

  variants: {
    mode: {
      distinct: {
        color: TEXT_COLOR_NORMAL.distinct,

        '&:hover, &:focus': {
          color: TEXT_COLOR_HOVER.distinct
        }
      },
      subtle: {
        color: TEXT_COLOR_NORMAL.subtle,

        '&:hover, &:focus': {
          color: TEXT_COLOR_HOVER.subtle
        }
      }
    },
    variant: {
      normal: {},
      underlined: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 2
      }
    }
  }
})

TextLink.defaultProps = {
  mode: 'distinct',
  variant: 'normal'
}

type TextLinkProps = StyledProps<typeof StyledTextLink> & BaseLinkProps

function TextLink({ as, ...props }: TextLinkProps) {
  return (
    <StyledTextLink as={as || BaseLink} {...props} />
  )
}

export type { TextLinkProps }

export default TextLink
