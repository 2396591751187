import React, { ComponentType } from 'react'

type WithOnMountProps<P> = P & { onMount?: Function }

function withOnMount<P>(Component: ComponentType<P>) {
  class Komponent extends React.Component<WithOnMountProps<P> & { forwardedRef: React.Ref<any> }> {
    componentDidMount() {
      const { onMount } = this.props
      onMount?.()
    }

    render() {
      const { onMount, forwardedRef, ...props } = this.props
      return <Component ref={forwardedRef} {...props as P} />
    }
  }

  return React.forwardRef((
    props: WithOnMountProps<P>,
    ref: React.Ref<any>
  ) => <Komponent {...props} forwardedRef={ref!} />)
}

export default withOnMount
