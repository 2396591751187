import TextLink from 'components/links/TextLink'
import { styled } from 'styles/stitches'

const Link = styled(TextLink, {})

Link.defaultProps = {
  fontSize: 14,
  variant: 'underlined'
}

export default Link
