import React from 'react'
import { Field } from 'react-final-form'
import type { FieldProps, FieldRenderProps } from 'react-final-form'

import TextInput from 'components/inputs/TextInput'

type FormFieldProps = FieldProps<
  any,
  FieldRenderProps<any, HTMLInputElement>,
  HTMLInputElement
> & {
  required?: HTMLInputElement['required'],
  alwaysDirty?: boolean
}

function FormField({ alwaysDirty, required = false, ...rest }: FormFieldProps) {
  return (
    <Field<string, FieldRenderProps<string, HTMLInputElement>, HTMLInputElement>
      component={TextInput}

      /* https://final-form.org/docs/react-final-form/types/FieldProps#parse
        In order to override the default behavior of converting '' to undefined,
        we need pass an identity function
      */
      parse={(x) => x}

      /* https://final-form.org/docs/final-form/types/FieldConfig#isequal
        Use this prop to always submit this field value even if it has not been edited
        e.g - to send entire set of nested fields
      */
      isEqual={(a, b) => {
        if (alwaysDirty) {
          return false
        }
        return a === b
      }}
      checkRequired={required}
      {...rest}
    />
  )
}

export default FormField
