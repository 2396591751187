import camelCase from 'lodash/camelCase'
import group from 'lodash/groupBy'
import React from 'react'
import startCase from 'lodash/startCase'
import sum from 'lodash/sum'
import type { ApolloError } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import type { SeriesAreaOptions } from 'highcharts'

import Block from 'components/blocks/Block'
import Card from 'components/card/Card'
import Loader from 'components/loaders/Loader'
import TimeSeries from 'components/charts/TimeSeries'
import type { BlockProps } from 'components/blocks/Block'

type TimeSeriesBlockProps = {
  title: string,
  description?: string,
  dataset: any,
  data: any,
  loading: boolean,
  error: ErrorResponse | ApolloError
}

function TimeSeriesBlock({
  title, description, dataset, data, loading, error, ...others
}: BlockProps & TimeSeriesBlockProps) {
  const { name, show } = dataset
  const groupedData = group(data, 'createdAt')

  return (
    <Block
      name="TimeSeries"
      resizeDirection={{ right: true }}
      direction="column"
      {...others}
    >
      <Card css={{ padding: 35 }}>
        <Loader
          data={data}
          error={error}
          loading={loading}
        >
          <TimeSeries
            title={title}
            yAxis={{ title: { text: startCase(show.yAxis) } }}
            series={[
              {
                name,
                data: Object.entries(groupedData).map(
                  ([ key, value ]) => ({ x: Number.parseInt(key, 10),
                    y: show.aggregate === 'SUM'
                      ? sum(value.map((val: any) => val[camelCase(show.yAxis)]))
                      : value.length })
                )
              }
            ] as SeriesAreaOptions[]}
          />
        </Loader>
      </Card>

    </Block>
  )
}

export default TimeSeriesBlock
