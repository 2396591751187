import get from 'lodash/get'
import has from 'lodash/has'
import React, { useContext, useState } from 'react'
import { Field, Form, FormProps, useForm } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { AnyObject, FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import Grid from 'components/layout/Grid'
import IconButton from 'components/buttons/IconButton'
import MediaCard from 'components/mediaCard/MediaCard'
import Role, { KIND_ICON_MAP, Kind } from 'models/Role'
import SearchBar from 'components/searchbar/SearchBar'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import SearchSelectInput from 'components/inputs/SearchSelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useFuzzySearch from 'hooks/useFuzzySearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { AppListDocument, AppListQuery, AppListQueryVariables, Attribute, AttributesListDocument, AttributesListQuery, AttributesListQueryVariables, CreateRoleInput, CustomRole, DashboardsListDocument, Operation, OperationsListDocument, OperationsListQuery, OperationsListQueryVariables, Resource, ResourcesListDocument, ResourcesListQuery, ResourcesListQueryVariables, RoleKind, RolesListDocument, UpdateRoleInput, useCreateRoleMutation, useRolesListQuery, useUpdateRoleMutation } from 'generated/schema'
import { SidePaneFooter, SidePaneSubHeader } from 'components/sidePane'
import { styled } from 'styles/stitches'
import type { ActiveViewProps } from '../DashboardEditor'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type FormValues = CreateRoleInput | UpdateRoleInput

type Params = ViewParams[Views.CREATE_ROLE]

type Permission = {
  id: string,
  identifier: string,
  name: string
}

const NestedBlock = styled(Flex, {
  paddingLeft: 24,
  borderLeft: '2px solid light700'
})

const StyledLabel = styled(Text, {
  fontSize: 12,
  fontWeight: 'bold'
})

const stepSubTitles = [
  'Step 1: Select a template',
  'Step 2: Enter details'
]

const PERMISSION_OPTIONS = [
  { label: 'Allow', value: 'allow' },
  { label: 'Deny', value: 'deny' }
]

const AttributesPermissionBlock = ({ resourceId }: { resourceId: string }) => {
  const { change, getState } = useForm()
  const { values } = getState()
  const permissionsList = getPermissionsList(values, `policy.resources.${resourceId}.attributes`)
  const [ selectedAttributes, setSelectedAttributes ] = useState<Permission[]>(permissionsList)
  const [ isCustomizing, setIsCustomizing ] = useState(!has(values, `policy.resources.${resourceId}.attributes.*`))

  const onDeleteAttributesPolicy = (attribute: Permission) => {
    change(`policy.resources.${resourceId}.attributes.${attribute.id}`, undefined)
    setSelectedAttributes((prev) => prev.filter((attr) => attr.id !== attribute.id))
  }

  return (
    <Flex direction="column" gap={16}>
      <Flex alignItems="center" justifyContent="space-between">
        <StyledLabel>All Attributes</StyledLabel>
        <Flex gap={16} alignItems="center" alignSelf="stretch">
          <IconButton
            name={isCustomizing ? 'close' : 'edit'}
            description={isCustomizing ? 'Close' : 'Customize'}
            size={16}
            variant="dark"
            onClick={() => {
              setIsCustomizing((prev) => {
                if (!prev) {
                  change(`policy.resources.${resourceId}.attributes.*`, undefined)
                } else {
                  change(`policy.resources.${resourceId}.attributes.*.grant`, 'allow')
                }
                return !prev
              })
            }}
          />
          {!isCustomizing && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <Field
                name={`policy.resources.${resourceId}.attributes.*.grant`}
                component={ButtonGroupInput}
                options={PERMISSION_OPTIONS}
                size="small"
              />
            </>
          )}
        </Flex>
      </Flex>
      {isCustomizing && (
        <NestedBlock direction="column" gap={16}>
          <Flex gap={12} direction="column">
            <SearchSelectInput<AttributesListQuery, AttributesListQueryVariables>
              isSearchable
              preload
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              query={AttributesListDocument}
              dataKey="attributesList"
              keys={[ 'name' ]}
              isOptionDisabled={(attribute: Attribute) => (
                selectedAttributes.filter((attr) => attr.id === attribute.id).length > 0
              )}
              setValueAsObject
              input={{
                onChange: (attribute: Attribute) => {
                  if (!attribute) return
                  setSelectedAttributes((prev) => ([
                    ...prev,
                    { id: attribute.id, name: attribute.name, identifier: attribute.identifier }
                  ]))
                  change(`policy.resources.${resourceId}.attributes.${attribute.id}.grant`, 'allow')
                }
              }}
              queryOptions={{
                variables: {
                  filter: {
                    resourceId: { eq: resourceId }
                  }
                }
              }}
            />
            {selectedAttributes.map(
              (attribute: Permission) => attribute && (
                <CustomizeAttribute
                  resourceId={resourceId}
                  attribute={attribute}
                  onRemove={onDeleteAttributesPolicy}
                />
              )
            )}
          </Flex>
        </NestedBlock>
      )}
    </Flex>
  )
}

const OperationsPermissionBlock = ({ resourceId }: { resourceId: string }) => {
  const { change, getState } = useForm()
  const { values } = getState()
  const permissionsList = getPermissionsList(values, `policy.resources.${resourceId}.operations`)
  const [ selectedOperations, setSelectedOperations ] = useState<Permission[]>(permissionsList)
  const [ isCustomizing, setIsCustomizing ] = useState(!has(values, `policy.resources.${resourceId}.operations.*`))

  const onDeleteOperationPolicy = (operation: Permission) => {
    change(`policy.resources.${resourceId}.operations.${operation.id}`, undefined)
    setSelectedOperations((prev) => prev.filter((op) => op.id !== operation.id))
  }

  return (
    <Flex direction="column" gap={16}>
      <Flex alignItems="center" justifyContent="space-between">
        <StyledLabel>All Operations</StyledLabel>
        <Flex gap={16} alignItems="center" alignSelf="stretch">
          <IconButton
            name={isCustomizing ? 'close' : 'edit'}
            description={isCustomizing ? 'Close' : 'Customize'}
            size={16}
            variant="dark"
            onClick={() => {
              setIsCustomizing((prev) => {
                if (!prev) {
                  change(`policy.resources.${resourceId}.operations.*`, undefined)
                } else {
                  change(`policy.resources.${resourceId}.operations.*.grant`, 'allow')
                }
                return !prev
              })
            }}
          />
          {!isCustomizing && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <Field
                name={`policy.resources.${resourceId}.operations.*.grant`}
                component={ButtonGroupInput}
                options={PERMISSION_OPTIONS}
                size="small"
              />
            </>
          )}
        </Flex>
      </Flex>
      {isCustomizing && (
        <NestedBlock direction="column" gap={16}>
          <Flex gap={12} direction="column">
            <SearchSelectInput<OperationsListQuery, OperationsListQueryVariables>
              isSearchable
              preload
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              query={OperationsListDocument}
              dataKey="operationsList"
              keys={[ 'name' ]}
              isOptionDisabled={(operation: Operation) => (
                selectedOperations.filter((op) => op.id === operation.id).length > 0
              )}
              setValueAsObject
              input={{
                onChange: (operation: Operation) => {
                  if (!operation) return
                  setSelectedOperations((prev) => ([
                    ...prev,
                    { id: operation.id, name: operation.name, identifier: operation.identifier }
                  ]))
                  change(`policy.resources.${resourceId}.operations.${operation.id}.grant`, 'allow')
                }
              }}
              queryOptions={{
                variables: {
                  filter: {
                    resourceId: { eq: resourceId }
                  }
                }
              }}
            />
            {selectedOperations.map(
              (operation: Permission) => (
                <CustomizeOperations
                  name={`policy.resources.${resourceId}.operations.${operation.id}.grant`}
                  operation={operation}
                  onRemove={onDeleteOperationPolicy}
                />
              )
            )}
          </Flex>
        </NestedBlock>
      )}
    </Flex>
  )
}

const CustomizeAttribute = (
  { resourceId, attribute, onRemove }:
  { resourceId: string, attribute: Permission, onRemove: (attribute: Permission) => void }
) => (
  <Flex alignItems="center" justifyContent="space-between">
    <StyledLabel>{attribute.name}</StyledLabel>
    <Flex gap={16} alignItems="center" alignSelf="stretch">
      <IconButton
        name="trash"
        size={16}
        description="Delete"
        variant="dark"
        onClick={() => onRemove(attribute)}
      />
      <Divider orientation="vertical" variant="ruler" />
      <Field
        name={`policy.resources.${resourceId}.attributes.${attribute.id}.grant`}
        component={ButtonGroupInput}
        options={PERMISSION_OPTIONS}
        size="small"
      />
    </Flex>
  </Flex>
)

const CustomizeOperations = (
  { name, operation, onRemove }:
  { name: string, operation: Permission, onRemove: (operation: Permission) => void }
) => (
  <Flex alignItems="center" justifyContent="space-between">
    <StyledLabel>{operation.name}</StyledLabel>
    <Flex gap={16} alignItems="center" alignSelf="stretch">
      <IconButton
        name="trash"
        size={16}
        description="Delete"
        variant="dark"
        onClick={() => onRemove(operation)}
      />
      <Divider orientation="vertical" variant="ruler" />
      <Field
        name={name}
        component={ButtonGroupInput}
        options={PERMISSION_OPTIONS}
        size="small"
      />
    </Flex>
  </Flex>
)

const CustomizeResource = (
  { resource, onRemove }: { resource: Permission, onRemove: (resource: Permission) => void }
) => (
  <Flex direction="column" gap={12}>
    <Flex gap={8} alignItems="center" justifyContent="space-between">
      <StyledLabel>{resource.name}</StyledLabel>
      <IconButton
        name="trash"
        size={16}
        description="Delete"
        variant="dark"
        onClick={() => onRemove(resource)}
      />
    </Flex>
    <AttributesPermissionBlock resourceId={resource.id} />
    <OperationsPermissionBlock resourceId={resource.id} />
  </Flex>
)

const getPermissionsList = (values: AnyObject, key: string) => {
  const permissions = get(values, key)
  if (!permissions) return []
  const hasNestedPermissions = !Object.keys(permissions).includes('*')
  if (!hasNestedPermissions) return []
  return Object.entries(permissions).map(([ key, value ]) => ({
    id: key,
    // eslint-disable-next-line no-underscore-dangle
    name: (value as any)._name,
    // eslint-disable-next-line no-underscore-dangle
    identifier: (value as any)._identifier
  }))
}

const CreateRoleView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {}, app } = params! as Params

  const { id: appId, name: appName, kind: appKind } = app!
  const { kind: initialKind, policy: initialPolicy } = initialValues as CustomRole

  const isUpdating = 'id' in initialValues!
  const isProject = appKind === 'PROJECT'

  const [ kind, setKind ] = useState<RoleKind>(initialKind || Kind.WORKSPACE)
  const [ currentStep, setCurrentStep ] = useState(isUpdating ? 1 : 0)

  const isFirstStep = currentStep === 0
  const isFinalStep = currentStep === 1

  const handleStepBehind = () => {
    setCurrentStep(currentStep - 1)
  }

  const FirstStep = () => {
    const { change } = useForm()
    const {
      data: { rolesList = [] } = {},
      error,
      loading
    } = useRolesListQuery({
      variables: {
        filter: {
          ...(appId && { appId: { eq: appId } }),
          ...(kind && { kind: { eq: kind } })
        },
        limit: 100
      }
    })

    const { matches, handleChange } = useFuzzySearch<CustomRole>(rolesList, { key: 'name' })

    return (
      <Flex direction="column" alignItems="stretch" gap={32}>
        <MediaCard
          width="full"
          media="add-outline-thin"
          title="Create From Scratch"
          onClick={() => setCurrentStep((step) => step + 1)}
        />
        <Flex direction="column" gap={12}>
          <SearchBar placeholder="Search for a role..." onChange={handleChange} />
          <DashboardEditorLoader
            data={rolesList}
            loading={loading}
            error={error}
            empty={{
              variant: 'simple',
              element: (
                <Flex alignItems="center" direction="column">
                  <Text fontSize={14} color="dark500">Nothing to show here.</Text>
                </Flex>
              )
            }}
          >
            <Grid columns={1} rowGap={16}>
              {matches.map((role) => (
                <MediaCard
                  key={role.id}
                  width="full"
                  media={KIND_ICON_MAP[role.kind]}
                  title={role.name}
                  onClick={() => {
                    change('policy', role.policy)
                    setKind(role.kind)
                    setCurrentStep(1)
                  }}
                />
              ))}
            </Grid>
          </DashboardEditorLoader>
        </Flex>
      </Flex>
    )
  }

  const FinalStep = () => {
    const { currentWorkspace } = useContext(WorkspaceContext)!
    const { change, getState } = useForm()
    const { values } = getState()
    const resourcePermissionsList = getPermissionsList(values, 'policy.resources')
    const operationPermissionsList = getPermissionsList(values, 'policy.operations')
    const [
      selectedResources, setSelectedResources
    ] = useState<Permission[]>(resourcePermissionsList)
    const [
      selectedOperations, setSelectedOperations
    ] = useState<Permission[]>(operationPermissionsList)
    const [ isCustomizingResources, setIsCustomizingResources ] = useState(!has(values, 'policy.resources.*'))
    const [ isCustomizingOperations, setIsCustomizingOperations ] = useState(!has(values, 'policy.operations.*'))

    const onDeleteResourcePolicy = (resource: Permission) => {
      change(`policy.resources.${resource.id}`, undefined)
      setSelectedResources((prev) => prev.filter((res) => res.id !== resource.id))
    }

    const onDeleteOperationPolicy = (operation: Permission) => {
      change(`policy.operations.${operation.id}`, undefined)
      setSelectedOperations((prev) => prev.filter((op) => op.id !== operation.id))
    }

    return (
      <Flex direction="column" gap={16}>
        <Text fontWeight="bold">Details</Text>
        <Field autoFocus name="name" label="Name" component={TextInput} size="small" type="text" />
        {kind === Kind.APP && (
          <SearchSelectField<AppListQuery, AppListQueryVariables>
            isSearchable
            preload
            isDisabled={Boolean(app)}
            name="appId"
            label="App"
            prependIcon="search"
            placeholder="Start typing to search"
            size="small"
            variant="light"
            labelKey="name"
            metaKey="identifier"
            valueKey="id"
            options={app ? [ app ] : []}
            query={AppListDocument}
            dataKey="appsList"
            keys={[ 'name' ]}
            queryOptions={{
              variables: {
                filter: { kind: { eq: 'EXTENSION' } },
                order: [ { name: 'asc' } ]
              }
            }}
          />
        )}
        <Text fontWeight="bold">Permissions</Text>
        <Flex direction="column" gap={16}>
          <Flex alignItems="center" justifyContent="space-between">
            <StyledLabel>All Resources</StyledLabel>
            <Flex gap={16} alignItems="center" alignSelf="stretch">
              <IconButton
                name={isCustomizingResources ? 'close' : 'edit'}
                description={isCustomizingResources ? 'Close' : 'Customize'}
                size={16}
                variant="dark"
                onClick={() => {
                  setIsCustomizingResources((prev) => {
                    if (!prev) {
                      change('policy.resources.*', undefined)
                    } else {
                      change('policy.resources.*.', {
                        attributes: { grant: 'allow' },
                        operations: { grant: 'allow' }
                      })
                    }
                    return !prev
                  })
                }}
              />
              {!isCustomizingResources && (
                <>
                  <Divider orientation="vertical" variant="ruler" />
                  <FormValuesField fieldNames={[ 'policy' ]}>
                    {(values) => {
                      const resourcePermission = get(values, 'policy.resources.*.attributes.*.grant')
                      return (
                        <ButtonGroupInput
                          meta={{}}
                          options={PERMISSION_OPTIONS}
                          size="small"
                          input={{
                            name: '',
                            value: resourcePermission,
                            onFocus: () => {},
                            onBlur: () => {},
                            onChange: (value) => {
                              change('policy.resources.*', {
                                attributes: { '*': { grant: value } },
                                operations: { '*': { grant: value } }
                              })
                            }
                          }}
                        />
                      )
                    }}
                  </FormValuesField>
                </>
              )}
            </Flex>
          </Flex>
          {isCustomizingResources && (
            <NestedBlock direction="column" gap={16}>
              <Flex gap={12} direction="column">
                <SearchSelectInput<ResourcesListQuery, ResourcesListQueryVariables>
                  isSearchable
                  preload
                  prependIcon="search"
                  placeholder="Start typing to search"
                  size="small"
                  variant="light"
                  labelKey="name"
                  metaKey="identifier"
                  valueKey="id"
                  query={ResourcesListDocument}
                  dataKey="resourcesList"
                  keys={[ 'name' ]}
                  isOptionDisabled={(resource: Resource) => (
                    selectedResources.filter((res) => res.id === resource.id).length > 0
                  )}
                  setValueAsObject
                  input={{
                    onChange: (resource: Resource) => {
                      if (!resource) return
                      setSelectedResources((prev) => ([
                        ...prev,
                        { id: resource.id, name: resource.name, identifier: resource.identifier }
                      ]))
                      change('policy.resources', {
                        [resource.id]: {
                          attributes: { '*': { grant: 'allow' } },
                          operations: { '*': { grant: 'allow' } }
                        }
                      })
                    }
                  }}
                  queryOptions={{
                    variables: {
                      filter: {
                        ...(kind === 'WORKSPACE' && { workspaceId: { eq: currentWorkspace.id } }),
                        ...(kind === 'APP' && { appId: { eq: values.appId } })
                      },
                      order: [ { name: 'asc' } ]
                    }
                  }}
                />
                {selectedResources.map(
                  (resource: any) => (
                    <CustomizeResource resource={resource} onRemove={onDeleteResourcePolicy} />
                  )
                )}
              </Flex>
            </NestedBlock>
          )}
        </Flex>
        <Flex direction="column" gap={16}>
          <Flex alignItems="center" justifyContent="space-between">
            <StyledLabel>All Operations</StyledLabel>
            <Flex gap={16} alignItems="center" alignSelf="stretch">
              <IconButton
                name={isCustomizingOperations ? 'close' : 'edit'}
                description={isCustomizingOperations ? 'Close' : 'Customize'}
                size={16}
                variant="dark"
                onClick={() => {
                  setIsCustomizingOperations((prev) => {
                    if (!prev) {
                      change('policy.operations.*', undefined)
                    } else {
                      change('policy.operations.*.grant', 'allow')
                    }
                    return !prev
                  })
                }}
              />
              {!isCustomizingOperations && (
              <>
                <Divider orientation="vertical" variant="ruler" />
                <FormValuesField fieldNames={[ 'policy' ]}>
                  {(values) => {
                    const operationPermission = get(values.policy, 'operations.*.grant')
                    return (
                      <ButtonGroupInput
                        meta={{}}
                        options={PERMISSION_OPTIONS}
                        size="small"
                        input={{
                          name: '',
                          value: operationPermission,
                          onFocus: () => {},
                          onBlur: () => {},
                          onChange: (value) => {
                            change('policy.operations.*.grant', value)
                          }
                        }}
                      />
                    )
                  }}
                </FormValuesField>
              </>
              )}
            </Flex>
          </Flex>
          {isCustomizingOperations && (
            <NestedBlock direction="column" gap={16}>
              <Flex gap={12} direction="column">
                <SearchSelectInput<OperationsListQuery, OperationsListQueryVariables>
                  isSearchable
                  preload
                  prependIcon="search"
                  placeholder="Start typing to search"
                  size="small"
                  variant="light"
                  labelKey="name"
                  metaKey="identifier"
                  valueKey="id"
                  query={OperationsListDocument}
                  dataKey="operationsList"
                  keys={[ 'name' ]}
                  isOptionDisabled={(operation: Operation) => (
                    selectedOperations.filter((op) => op.id === operation.id).length > 0
                  )}
                  setValueAsObject
                  input={{
                    onChange: (operation: Operation) => {
                      if (!operation) return
                      setSelectedOperations((prev) => ([
                        ...prev,
                        { id: operation.id, name: operation.name, identifier: operation.identifier }
                      ]))
                      change(`policy.operations.${operation.id}.grant`, 'allow')
                    }
                  }}
                  queryOptions={{
                    variables: {
                      filter: {
                        ...(kind === 'WORKSPACE' && { workspaceId: { eq: currentWorkspace.id } }),
                        ...(kind === 'APP' && { appId: { eq: values.appId } })
                      },
                      order: [ { name: 'asc' } ]
                    }
                  }}
                />
                {selectedOperations.map(
                  (operation: any) => (
                    <CustomizeOperations name={`policy.operations.${operation.id}.grant`} operation={operation} onRemove={onDeleteOperationPolicy} />
                  )
                )}
              </Flex>
            </NestedBlock>
          )}
        </Flex>
      </Flex>
    )
  }

  const queryVariables = {
    filter: {
      appId: { eq: appId }
    }
  }

  const [ createRole ] = useCreateRoleMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [
      { query: RolesListDocument, variables: queryVariables }
    ]
  })

  const [ updateRole ] = useUpdateRoleMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: RolesListDocument, variables: queryVariables }
    ]
  })

  const handleCreateRoleSubmit = useSubmitHandler(createRole, {
    update: {
      strategy: 'APPEND',
      query: RolesListDocument,
      queryVariables,
      dataKey: 'rolesList',
      mutation: 'createRole'
    }
  })

  const handleUpdateRoleSubmit = useSubmitHandler(updateRole, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateRole',
      typename: 'Role',
      override: (values: UpdateRoleInput) => ({
        ...initialValues,
        ...values
      })
    }
  })

  const handleRoleFormSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating && 'id' in values) {
      return handleUpdateRoleSubmit({
        ...values,
        policy: {
          ...values.policy,
          version: values?.policy?.version + 1
        }
      }, form as FormApi<UpdateRoleInput>)
    }

    return handleCreateRoleSubmit(values as CreateRoleInput)
  }

  const formInitialValues = {
    appId,
    kind,
    policy: {
      version: 1,
      resources: { '*': {
        attributes: { '*': { grant: 'deny' } },
        operations: { '*': { grant: 'deny' } }
      } },
      operations: { '*': { grant: 'deny' } },
      ...initialPolicy
    },
    ...initialValues
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'Create'} Role`}
        heading={`${isProject ? 'Project' : 'App'}: ${appName}`}
        onClose={onClose}
      />
      {!isUpdating && (
        <SidePaneSubHeader size="small">
          <Text fontWeight="bold">{stepSubTitles[currentStep]}</Text>
        </SidePaneSubHeader>
      )}
      <Form
        initialValues={formInitialValues as FormValues}
        validate={(values) => Role.validate(values, [ 'name' ])}
        subscription={{ submitting: true, pristine: true }}
        onSubmit={handleRoleFormSubmit}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Field name="name" component="input" type="hidden" />
                <Field name="kind" component="input" type="hidden" />
                <Field name="policy" component="input" type="hidden" />
                {isFirstStep && <FirstStep />}
                {isFinalStep && <FinalStep />}
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            {isFinalStep && (
              <SidePaneFooter variant="small" isSticky>
                <Flex gap={16} direction="row-reverse">
                  <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  {!isUpdating && <Button size="small" icon="arrow-left" onClick={handleStepBehind} />}
                </Flex>
              </SidePaneFooter>
            )}
          </>
        )}
      />
    </>
  )
}

export default CreateRoleView
