import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import Editor from '@toast-ui/editor'
import escape from 'lodash/escape'
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import Divider from 'components/divider/Divider'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import HeadingCommand from 'components/inputs/MarkdownInput/HeadingCommand'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import ColorCommand from 'components/inputs/MarkdownInput/ColorCommand'
import ImageCommand from 'components/inputs/MarkdownInput/ImageCommand'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import LinkCommand from 'components/inputs/MarkdownInput/LinkCommand'
import TableCommand from 'components/inputs/MarkdownInput/TableCommand'
import Text from 'components/typography/Text'
import useAssetUpload from 'hooks/useAssetUpload'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { isMacintosh } from 'lib/platform'

import '@toast-ui/editor/dist/toastui-editor.css'

type MarkdownInputProps = FieldRenderProps<string, any> & {
  placeholder?: string,
  helpText?: string,
  isTranslatable?: boolean,
  height?: string,
  disabled?: boolean
}

type ToolbarEvent = 'strong' | 'emph' | 'heading' | 'paragraph' | 'text' | 'strike' | 'blockQuote' | 'bulletList' | 'orderedList' | 'taskList' | 'code'

const VIEW_HEIGHT_LARGE = 650
const VIEW_HEIGHT_NORMAL = 450
const BORDER_RADIUS = 4
const COMMAND_ICON_SIZE = 40
const HEADER_X_PADDING = 25
const HEADER_Y_PADDING = 8
const FOOTER_HEIGHT = 30
const FOOTER_X_PADDING = 25

const modKey = isMacintosh() ? 'Cmd' : 'Ctrl'
const altKey = isMacintosh() ? 'Option' : 'Alt'

const STYLE_COMMANDS = [
  { description: 'Heading', icon: 'heading', command: 'heading', events: [ 'heading' ], shortcut: null },
  { description: 'Bold', icon: 'bold', command: 'bold', events: [ 'strong' ], shortcut: `${modKey} + b` },
  { description: 'Italic', icon: 'italic', command: 'italic', events: [ 'emph' ], shortcut: `${modKey} + i` },
  { description: 'Strike', icon: 'strike', command: 'strike', events: [ 'strike' ], shortcut: `${modKey} + s` },
  { description: 'Indent', icon: 'indent', command: 'indent', events: [ 'indent' ], shortcut: 'Tab' },
  { description: 'Outdent', icon: 'outdent', command: 'outdent', events: [ 'outdent' ], shortcut: 'Shift + Tab' },
  { description: 'Color', icon: 'color', command: 'color', events: [ 'color' ], shortcut: null }
]
const BLOCK_COMMANDS = [
  { description: 'Divider', icon: 'hr', command: 'hr', events: [ 'hr', 'thematicBreak' ], shortcut: `${modKey} + l` },
  { description: 'Quote', icon: 'quote', command: 'blockQuote', events: [ 'blockQuote' ], shortcut: `${altKey} + q` },
  { description: 'Code Block', icon: 'codeblock', command: 'codeBlock', events: [ 'codeBlock' ], shortcut: `Shift + ${modKey} + p` }
]
const TRANSFORM_COMMANDS = [
  { description: 'Bullet List', icon: 'ul', command: 'bulletList', events: [ 'bulletList' ], shortcut: `${modKey} + u` },
  { description: 'Ordered List', icon: 'ol', command: 'orderedList', events: [ 'orderedList' ], shortcut: `${modKey} + o` },
  { description: 'Task', icon: 'task', command: 'taskList', events: [ 'taskList' ], shortcut: `${altKey} + t` },
  { description: 'Code', icon: 'code', command: 'code', events: [ 'code' ], shortcut: `Shift + ${modKey} + c` }
]
const ELEMENT_COMMANDS = [
  { description: 'Table', icon: 'table', command: 'addTable', events: [ 'table' ], shortcut: null },
  { description: 'Link', icon: 'link', command: 'addLink', events: [ 'link' ], shortcut: null },
  { description: 'Media', icon: 'image', command: 'addImageOrVideo', events: [ 'image' ], shortcut: null }
]

const iconButtonCommonProps = {
  alignItems: 'center',
  justifyContent: 'center',
  variant: 'dark'
}

const COMMAND_ICON_MAP: Record<string, (props: any) => JSX.Element> = {
  heading: ({ onClick, selectedText, ...props }) => <HeadingCommand onClick={onClick}><CommandButton name="heading" {...iconButtonCommonProps} {...props} /></HeadingCommand>,
  bold: ({ selectedText, ...props }) => <CommandButton name="bold" {...iconButtonCommonProps} {...props} />,
  color: ({ selectedText, onClick, ...props }) => <ColorCommand onClick={onClick}><CommandButton name="color-field" {...iconButtonCommonProps} {...props} /></ColorCommand>,
  italic: ({ selectedText, ...props }) => <CommandButton name="italic" {...iconButtonCommonProps} {...props} />,
  strike: ({ selectedText, ...props }) => <CommandButton name="strike" {...iconButtonCommonProps} {...props} />,
  hr: ({ selectedText, ...props }) => <CommandButton name="divider" {...iconButtonCommonProps} {...props} />,
  blockQuote: ({ selectedText, ...props }) => <CommandButton name="quote" {...iconButtonCommonProps} {...props} />,
  bulletList: ({ selectedText, ...props }) => <CommandButton name="bullet_list" {...iconButtonCommonProps} {...props} />,
  orderedList: ({ selectedText, ...props }) => <CommandButton name="numbered_list" {...iconButtonCommonProps} {...props} />,
  taskList: ({ selectedText, ...props }) => <CommandButton name="task" {...iconButtonCommonProps} {...props} />,
  indent: ({ selectedText, ...props }) => <CommandButton name="indent" {...iconButtonCommonProps} {...props} />,
  outdent: ({ selectedText, ...props }) => <CommandButton name="outdent" {...iconButtonCommonProps} {...props} />,
  addImageOrVideo: ({ onClick, selectedText, ...props }) => <ImageCommand onClick={onClick}><CommandButton name="md_image" {...iconButtonCommonProps} {...props} /></ImageCommand>,
  addLink: ({ onClick, selectedText, ...props }) => <LinkCommand selectedText={selectedText} onClick={onClick}><CommandButton name="link" {...iconButtonCommonProps} {...props} /></LinkCommand>,
  addTable: ({ onClick, selectedText, ...props }) => <TableCommand onClick={onClick}><CommandButton name="table" {...iconButtonCommonProps} {...props} /></TableCommand>,
  code: ({ selectedText, ...props }) => <CommandButton name="code" {...iconButtonCommonProps} {...props} />,
  codeBlock: ({ selectedText, ...props }) => <CommandButton name="code_block" {...iconButtonCommonProps} {...props} />
}

const wrapper = css({ width: '100%' })

const StyledHeader = styled(Flex, {
  ...mixins.transition('fluid'),

  backgroundColor: 'light200',
  borderBottom: '1px solid dark100',
  borderTopRightRadius: 4,
  borderTopLeftRadius: 4,
  paddingX: HEADER_X_PADDING,
  paddingY: HEADER_Y_PADDING,
  color: 'dark600',

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        color: 'dark500'
      }
    }
  }
})

const StyledFooter = styled(Flex, {
  ...mixins.transition('fluid'),

  cursor: 'pointer',
  position: 'relative',
  backgroundColor: 'light200',
  borderTop: '1px solid dark100',
  borderBottomRightRadius: BORDER_RADIUS,
  borderBottomLeftRadius: BORDER_RADIUS,
  height: FOOTER_HEIGHT,
  paddingX: FOOTER_X_PADDING
})

const StyledViewWrapper = styled('div', {
  ...mixins.transition('fluid'),

  border: '1px solid dark100',
  borderRadius: BORDER_RADIUS,

  '&:focus-within': {
    borderColor: 'dark300',
    [`& > ${StyledHeader}`]: { borderColor: 'dark300' },
    [`& > ${StyledFooter}`]: { borderColor: 'dark300' }
  },

  '&:hover': {
    borderColor: 'dark300',
    [`& > ${StyledHeader}`]: { borderColor: 'dark300' },
    [`& > ${StyledFooter}`]: { borderColor: 'dark300' }
  },

  variants: {
    size: {
      normal: {
        height: VIEW_HEIGHT_NORMAL
      },
      large: {
        height: VIEW_HEIGHT_LARGE
      }
    },
    uploading: {
      true: {
        borderColor: 'primary100',
        [`& > ${StyledHeader}`]: { borderColor: 'primary100' },
        [`& > ${StyledFooter}`]: { borderColor: 'primary100' },

        '&:focus-within': {
          borderColor: 'primary100',
          [`& > ${StyledHeader}`]: { borderColor: 'primary100' },
          [`& > ${StyledFooter}`]: { borderColor: 'primary100' }
        },

        '&:hover': {
          borderColor: 'primary100',
          [`& > ${StyledHeader}`]: { borderColor: 'primary100' },
          [`& > ${StyledFooter}`]: { borderColor: 'primary100' }
        }
      },
      false: {}
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        backgroundColor: 'light400',
        borderColor: 'dark100',
        color: 'dark500'
      }
    }
  }
})

const CommandButton = styled(IconButton, {
  cursor: 'pointer',
  padding: 6,
  alignItems: 'center',
  borderRadius: BORDER_RADIUS,
  justifyContent: 'center',
  size: [ COMMAND_ICON_SIZE ],

  [`& ${Icon}`]: { color: `${colorVars.dark400} !important` },

  '&:hover': {
    backgroundColor: 'light300'
  },

  variants: {
    active: {
      true: {
        backgroundColor: 'light500',
        [`& ${Icon}`]: { color: `${colorVars.primary300} !important` }
      }
    }
  }
})

const StyledEditor = styled('div', {
  backgroundColor: 'light100',
  borderRadius: BORDER_RADIUS,

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        backgroundColor: 'light400',
        borderColor: 'dark100',
        color: 'dark500'
      }
    }
  }
})

const StyledFileInput = styled('input', {
  cursor: 'pointer',
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  opacity: 0.01,

  '::-webkit-file-upload-button': {
    cursor: 'pointer'
  }
})

const toolbarItems = [
  STYLE_COMMANDS.map((comm) => comm.icon).filter((item) => item !== 'color'),
  BLOCK_COMMANDS.map((comm) => comm.icon),
  TRANSFORM_COMMANDS.map((comm) => comm.icon),
  ELEMENT_COMMANDS.map((comm) => comm.icon)
]

const allCommands = [
  STYLE_COMMANDS,
  BLOCK_COMMANDS,
  TRANSFORM_COMMANDS,
  ELEMENT_COMMANDS
]

const { REACT_APP_ASSET_BASE_URL } = process.env

const alignmentStyles = Object.freeze({
  left: 'float: left;',
  center: 'text-align: center;',
  right: 'float: right;'
})

const MarkdownInput: React.FC<MarkdownInputProps> = ({
  autoFocus = false,
  label,
  isTranslatable,
  checkRequired,
  placeholder,
  helpText,
  input,
  height = '300px',
  disabled = false
}) => {
  const editorContainerRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<Editor>()
  const [ toolbarState, setToolbarState ] = useState<Partial<Record<ToolbarEvent, boolean>>>({})
  const [ mode, setMode ] = useState<'WRITE' | 'PREVIEW'>('WRITE')
  const [ isUploading, setIsUploading ] = useState(false)

  const { upload } = useAssetUpload({
    onUploadStart: () => {
      setIsUploading(true)
    },
    onUploadSuccess: (asset, meta) => {
      const fileUrl = asset.url || `${REACT_APP_ASSET_BASE_URL}/${asset.data.asset.name}`
      meta?.callback({ imageUrl: fileUrl, altText: asset.name, mimeType: asset.mimeType })
    },
    onUploadEnd: () => {
      setIsUploading(false)
    }
  })

  const onAttatchFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (files?.length) {
      const file = files[0]
      upload(file, file.name, {
        callback: (args: any) => {
          if (args.mimeType.includes('image/')) {
            editorRef.current?.exec('addImage', args)
          } else {
            editorRef.current?.exec('addImageOrVideo', args)
          }
        }
      })
    }
  }

  useEffect(() => {
    if (editorContainerRef.current) {
      editorRef.current = new Editor({
        el: editorContainerRef.current,
        autofocus: autoFocus,
        height,
        initialValue: input.value,
        initialEditType: 'markdown',
        previewStyle: 'vertical',
        toolbarItems,
        previewHighlight: false,
        hideModeSwitch: true,
        useCommandShortcut: true,
        placeholder,
        plugins: [ colorSyntax ],
        customHTMLRenderer: {
          htmlBlock: {
            // @ts-ignore
            iframe(node) {
              return [
                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                { type: 'html', content: node.childrenHTML },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true }
              ]
            }
          }
        }
      })

      editorRef.current.eventEmitter.listen('changeToolbarState', (args: any) => {
        setToolbarState(args.toolbarState)
      })

      editorRef.current.eventEmitter.listen('change', () => {
        input.onChange(editorRef.current?.getMarkdown())
      })

      editorRef.current.addHook('addImageBlobHook', (blob, callback) => {
        upload(blob, blob?.name || '', { callback: (args: any) => callback(args.imageUrl, args.altText) })
      })

      editorRef.current.addCommand('markdown', 'addImageOrVideo', (payload, { schema, tr }, dispatch) => {
        const { url, width, height, altText, alignment, showAltText, mimeType } = payload!

        if (!url) {
          return false
        }

        const openTag = `<figure style="${alignmentStyles[alignment as keyof typeof alignmentStyles] || ''}">`
        const closeTag = '</figure>'
        const caption = `<figcaption align="center">${escape(altText)}</figcaption>`
        const tag = `<img src="${escape(url)}" alt="${escape(altText)}" width="${escape(width)}" height="${escape(height)}" />`
        const videoTag = `<video src="${escape(url)}" width="${escape(width)}" height="${escape(height)}" controls></video>`
        const final = `${openTag}${mimeType.includes('video/') ? videoTag : tag}${showAltText ? caption : ''}${closeTag}`

        const node = schema.text(final)

        dispatch!(tr.replaceSelectionWith(node!).scrollIntoView())

        return true
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (editorRef.current) {
      const editorContainer = (editorRef.current as any).options.el as HTMLElement
      const mainContianer = editorContainer.querySelector('.toastui-editor-defaultUI') as HTMLElement
      const previewerEl = editorRef.current.getEditorElements().mdPreview as HTMLElement
      const editorEl = editorRef.current.getEditorElements().mdEditor as HTMLElement
      const editorView = (editorRef.current as any).mdEditor.view.dom
      mainContianer.style.border = 'none'
      mainContianer.style.outline = 'none'
      editorContainer.style.border = 'none'
      editorContainer.style.outline = 'none'
      editorView.style.border = 'none'
      editorView.style.outline = 'none'
      editorContainer.querySelector('.toastui-editor-toolbar')?.remove()
      editorContainer.querySelector('.toastui-editor-md-splitter')?.remove()
      if (mode === 'WRITE') {
        previewerEl.style.width = '0'
        previewerEl.style.padding = '0'
        editorEl.style.width = '100%'
        editorView.style.height = '100%'
        editorView.style.padding = '18px 25px'
      }
      if (mode === 'PREVIEW') {
        editorEl.style.width = '0'
        editorView.style.padding = '0'
        previewerEl.style.width = '100%'
        previewerEl.style.height = '100%'
        previewerEl.style.padding = '0 25px'
      }
    }
  }, [ mode ])

  return (
    <Flex className={wrapper} direction="column" gap={10} grow={1}>
      <Flex as="label">
        {label && (
          <FieldLabel htmlFor={input.name} isTranslatable={isTranslatable}>
            {label}{checkRequired && <span> *</span>}
          </FieldLabel>
        )}
        <input
          type="hidden"
          {...input}
        />
      </Flex>
      <StyledViewWrapper disabled={disabled} uploading={isUploading}>
        <StyledHeader wrap="wrap" justifyContent="space-between" disabled={disabled}>
          <Flex alignItems="center" gap={32}>
            <ButtonGroupInput
              input={{ name: 'mode', value: mode, onChange: setMode, onBlur: () => {}, onFocus: () => {} }}
              meta={{}}
              options={[ { label: 'Write', value: 'WRITE' }, { label: 'Preview', value: 'PREVIEW' } ]}
              size="small"
            />
          </Flex>
          {mode === 'WRITE' && (
            <Flex wrap="wrap" alignItems="center" gap={8}>
              {allCommands.map((commands, index) => (
                <Flex gap={4} key={`${index + 1}`}>
                  {commands.map((comm) => {
                    const Command = COMMAND_ICON_MAP[comm.command]
                    const active = Object.keys(toolbarState)
                      .map((e) => comm.events.includes(e))
                      .some((e) => e)
                    const hasShortcut = comm.shortcut !== null
                    const description = hasShortcut
                      ? `${comm.description} <${comm.shortcut}>`
                      : comm.description
                    const selectedText = editorRef.current?.getSelectedText()

                    return (
                      <Command
                        key={comm.command}
                        disabled={disabled}
                        active={active}
                        description={description}
                        selectedText={selectedText}
                        onClick={(payload: any) => {
                          if (editorRef.current) {
                            editorRef.current.focus()
                            editorRef.current.exec(comm.command, payload)
                          }
                        }}
                      />
                    )
                  })}
                  <Divider orientation="vertical" variant="ruler" />
                </Flex>
              ))}
            </Flex>
          )}
        </StyledHeader>
        <StyledEditor css={{ height }} disabled={disabled} ref={editorContainerRef} />
        <StyledFooter alignItems="center" justifyContent="space-between">
          <StyledFileInput
            accept="image/*, video/*"
            multiple={false}
            type="file"
            onChange={onAttatchFile}
          />
          <Text fontSize={12} color="dark500">{isUploading ? 'Uploading...' : 'Add image or video by dragging & dropping, copy-pasting, or by just clicking here.'}</Text>
        </StyledFooter>
      </StyledViewWrapper>
      {helpText && (<InputHelpText helpText={helpText} />)}
    </Flex>
  )
}

export type { MarkdownInputProps }

export default MarkdownInput
