import React from 'react'

import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { css } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'
import type { Color } from 'styles/theme'

type FallbackOption = { text: string, icon: string, iconColor: Color }

const classes = {
  fallBackContainer: (iconColor: Color) => css({
    '[data-icon]': {
      color: iconColor
    }
  })
}

const FALLBACK_DATA: Record<string, FallbackOption> = {
  DEFAULT: {
    text: 'Default Locale',
    icon: 'accept',
    iconColor: 'positive600'
  },
  FALLBACK_TO_DEFAULT: {
    text: 'Falls back to default',
    icon: 'accept',
    iconColor: 'primary300'
  },
  NONE: {
    text: 'No fallback',
    icon: 'cross-circle',
    iconColor: 'negative600'
  }
}

const getFallbackDefaultString = (fallbacks: string[]) => {
  if (fallbacks.length === 1) {
    return fallbacks[0]
  }

  return `
    ${fallbacks.slice(0, 2).join(', ')}${fallbacks.length - 2 ? `, and +${fallbacks.length - 2}` : ''}
  `
}

const getFallbackData = (
  isDefault: boolean,
  fallbacks?: string[],
  allowFallback?: boolean
) => {
  if (isDefault) {
    return FALLBACK_DATA.DEFAULT
  }

  if (allowFallback && !fallbacks?.length) {
    return FALLBACK_DATA.FALLBACK_TO_DEFAULT
  }

  if (fallbacks?.length) {
    return {
      ...FALLBACK_DATA.FALLBACK_TO_DEFAULT,
      text: `Fall backs to ${getFallbackDefaultString(fallbacks)}`
    }
  }

  return FALLBACK_DATA.NONE
}

function LocaleFallbackRenderer({ rowData }: RendererOptions<any>) {
  const { isDefault, fallbacks, allowFallback } = rowData || {}

  const { text, icon, iconColor } = getFallbackData(isDefault, fallbacks, allowFallback)

  return (
    <Flex alignItems="center" gap={10} className={classes.fallBackContainer(iconColor)}>
      <Icon data-icon name={icon} />
      <Text fontSize={14} title={text}>{text}</Text>
    </Flex>
  )
}

export default LocaleFallbackRenderer
