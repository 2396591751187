import { useEffect, useState } from 'react'

const SUPPORTED_LANGUAGES = {
  handlebars: [ 'markup-templating', 'handlebars' ],
  json: [ 'json' ],
  markdown: [ 'markdown' ],
  database: [ 'sql' ]
}

const DEFAULT_LANGUAGES = [ 'javascript' ] as const

const LANGUAGES = [ ...DEFAULT_LANGUAGES, ...Object.keys(SUPPORTED_LANGUAGES) ] as const

type SupportedLanguage = keyof (typeof SUPPORTED_LANGUAGES)
type LanguageTuple = typeof LANGUAGES
type Language = LanguageTuple[number]

const loadLanguage = (language: SupportedLanguage) => Promise.all(
  SUPPORTED_LANGUAGES[language].map((lang: string) => import(`prismjs/components/prism-${lang}`))
)

const loadCss = (theme = 'dark') => Promise.resolve(import(`prismjs/themes/prism${theme === 'dark' ? '-okaidia' : ''}.css`))

function usePrism(language: Language, theme?: 'light' | 'dark') {
  loadCss(theme)
  const defaultLoaded = DEFAULT_LANGUAGES as unknown as Language[]

  const [ loading, setLoading ] = useState(false)
  const [ loaded, setLoaded ] = useState<Language[]>(defaultLoaded)

  useEffect(() => {
    if (
      language in SUPPORTED_LANGUAGES
      && !loaded.includes(language)
    ) {
      setLoading(true)
      loadLanguage(language as SupportedLanguage).finally(() => {
        setLoading(false)
        setLoaded((prev) => ([
          ...prev,
          language
        ]))
      })
    } else {
      setLoading(false)
    }
  }, [ language, loaded, setLoading ])

  return {
    languageLoading: loading,
    loadedLanguages: loaded
  }
}

export type {
  Language
}

export {
  LANGUAGES
}

export default usePrism
