import { generateVariants, styled } from 'styles/stitches'

const alignOptions = [ 'auto', 'flex-start', 'flex-end', 'center', 'baseline', 'stretch' ] as const

const DEFAULT_ELEMENT = 'div'

const alignSelfVariants = generateVariants(alignOptions, 'alignSelf')

const justifySelfVariants = generateVariants(alignOptions, 'justifySelf')

const Box = styled(DEFAULT_ELEMENT, {
  variants: {
    alignSelf: alignSelfVariants,
    justifySelf: justifySelfVariants,
    basis: {
      0: {
        flexBasis: 0
      },
      '100%': {
        flexBasis: '100%'
      },
      auto: {
        flexBasis: 'auto'
      }
    },
    grow: {
      0: {
        flexGrow: 0
      },
      1: {
        flexGrow: 1
      }
    },
    shrink: {
      0: {
        flexShrink: 0
      },
      1: {
        flexShrink: 1
      }
    }
  }
})

type BoxProps = StyledProps<typeof Box>

Box.displayName = 'Box'

export default Box

export type { BoxProps }
