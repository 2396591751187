import { useContext, useMemo } from 'react'

import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import { useLocaleListQuery } from 'generated/schema'
import WorkspaceContext from 'components/contexts/WorkspaceContext'

type Locale = {
  allowFallback: boolean,
  fallbacks: string[],
  identifier: string,
  isDefault: boolean,
  name?: string
}

function useActiveLocales() {
  const { currentWorkspace } = useContext(WorkspaceContext)!

  const { data: localesData, loading: localesLoading, error: localesError } = useLocaleListQuery({
    // This list will never change as it is a static list of standard locales
    fetchPolicy: 'cache-first'
  })

  const { locales = [] } = localesData || {}

  const [ activeLocalesWithName, defaultLocale ] = useMemo(() => {
    const activeLocales: Locale[] = (currentWorkspace.locales as Locale[]) || []
    const identifierToLocalesMap = getPropertyToElementMap(locales, 'identifier')

    const sortedActiveLocales = activeLocales
      .map((locale) => ({
        ...locale,
        name: identifierToLocalesMap[locale.identifier]?.name
      }))
      .sort((a, b) => Number(b.isDefault) - Number(a.isDefault))

    // This will be the first locale because of the sorting.
    // `find` is more explicit and returns proper typings
    const defaultActiveLocale = sortedActiveLocales.find((locale) => locale.isDefault)

    return [ sortedActiveLocales, defaultActiveLocale ]
  },
  [ currentWorkspace, locales ])

  return {
    activeLocales: activeLocalesWithName,
    defaultLocale,
    error: localesError,
    loading: localesLoading
  }
}

export default useActiveLocales

export type { Locale }
