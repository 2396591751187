import { string } from 'yup'
import { colord } from 'colord'

const isValidColor = () => string()
  .ensure()
  .test(
    'is-valid-color',
    'must be a valid color',
    // falsy is considered valid, otherwise user is forced to enter something
    // add a `.required` while consuming as needed
    (value) => (value ? colord(value).isValid() : true)
  )

export default isValidColor
