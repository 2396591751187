import React from 'react'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import { useRecoilValue } from 'recoil'

import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { BLOCK_TYPE_TO_ICON_MAP, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { BlockType } from 'components/blocks'
import type { View } from 'generated/schema'

const ViewDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const {
    params: { initialValues } = {}
  } = useRecoilValue<DashboardEditorView<Views.VIEW_DETAILS>>(dashboardEditorState)

  if (!initialValues) return null

  const isStackedView = 'component' in initialValues

  if (isStackedView) {
    const { style, title } = initialValues

    return (
      <>
        <DashboardEditorHeader
          subtitle={`View: ${title}`}
          heading="Dashboard Editor"
          onClose={onClose}
        />
        <DashboardEditorBody>
          <Flex gap={16} direction="column">
            <Text
              color="dark500"
              fontSize={10}
              fontWeight="bold"
              textTransform="uppercase"
            >
              Name
            </Text>
            <Text>{title}</Text>
          </Flex>
          <Flex gap={16} direction="column">
            <Text
              color="dark500"
              fontSize={10}
              fontWeight="bold"
              textTransform="uppercase"
            >
              Default Style
            </Text>
            <Flex gap={6} alignItems="center">
              <Icon name="page-outline" />
              <Text>{startCase(lowerCase(style)) || 'Page'}</Text>
            </Flex>
          </Flex>
        </DashboardEditorBody>
      </>
    )
  }

  const { name, app, resource } = initialValues
  const viewName = name || app?.name || resource?.name
  return (
    <>
      <DashboardEditorHeader
        subtitle={`View: ${viewName}`}
        heading="Dashboard Editor"
        onEdit={() => openDashboardEditorView({
          target: Views.EDIT_VIEW,
          params: {
            initialValues
          }
        })}
        onClose={onClose}
      />
      <DashboardEditorBody>
        <Flex gap={16} direction="column">
          <Text
            color="dark500"
            fontSize={10}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Name
          </Text>
          <Text>{viewName}</Text>
        </Flex>
        <Flex gap={16} direction="column">
          <Text
            color="dark500"
            fontSize={10}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Default Style
          </Text>
          <Flex gap={6} alignItems="center">
            <Icon name="page-outline" />
            <Text>Page</Text>
          </Flex>
        </Flex>
        <Blocks initialValues={initialValues} />
        <Operations />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const Blocks = ({ initialValues }: {initialValues: View}) => {
  const { openDashboardEditorView, selectBlock } = useDashboard()

  const { blocks } = initialValues as View

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Blocks
      </Text>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={blocks}
      >
        <Flex direction="column" gap={2}>
          {blocks?.map((block) => {
            const onClick = () => {
              selectBlock(block.id)
              openDashboardEditorView({
                target: Views.EDIT_BLOCK
              })
            }

            return (
              <MediaCard
                compact
                onClick={onClick}
                key={block.id}
                media={BLOCK_TYPE_TO_ICON_MAP[block.type as BlockType]}
                title={startCase(block.type)}
                height={64}
                width="full"
                actions={[ {
                  description: '',
                  icon: 'arrow-right',
                  onClick,
                  isIconAlwaysVisible: true
                } ]}
              />
            )
          })}
        </Flex>
      </DashboardEditorLoader>
    </Flex>
  )
}

const Operations = () => (
  <Flex gap={16} direction="column">
    <Text
      color="dark500"
      fontSize={10}
      fontWeight="bold"
      textTransform="uppercase"
    >
      Operations
    </Text>
    <DashboardEditorLoader
      empty={{
        variant: 'simple',
        element: (
          <Flex alignItems="center" direction="column">
            <Text fontSize={14} color="dark500">Nothing to show here.</Text>
          </Flex>
        )
      }}
      data={[]}
    >
      <Flex />
    </DashboardEditorLoader>
  </Flex>
)

const actions = [
  {
    title: 'Add Block',
    identifier: Views.ADD_BLOCK,
    media: 'block'
  },
  {
    title: 'Add Operation',
    identifier: Views.ADD_VIEW_OPERATION,
    media: 'advance'
  }
]

const Actions = () => {
  const { openDashboardEditorView } = useDashboard()

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const { media, title, identifier } = action

          const onClick = () => openDashboardEditorView({
            target: identifier,
            params: {}
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              media={media}
              title={title}
              height={64}
              width="full"
              onClick={onClick}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                isIconAlwaysVisible: true,
                onClick
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default ViewDetailsView
