import { useEffect } from 'react'

type fn = () => void

const useComponentDidMount = (onMountHandler: () => void | fn) => {
  useEffect(() => {
    const unMountFn = onMountHandler()

    return unMountFn
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useComponentDidMount
