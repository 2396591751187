const LARGE_HORIZONTAL_PADDING = 245
const SMALL_HORIZONTAL_PADDING = 80

const VERTICAL_PADDING = 120

export {
  LARGE_HORIZONTAL_PADDING,
  SMALL_HORIZONTAL_PADDING,
  VERTICAL_PADDING
}
