import arrayMutators from 'final-form-arrays'
import React, { useContext, useRef } from 'react'
import { Form } from 'react-final-form'

import ActionIcon from 'components/icons/ActionIcon'
import Button from 'components/buttons/Button'
import Divider from 'components/divider/Divider'
import FieldArray from 'components/form/FieldArray'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Icon from 'components/icons/Icon'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import Text from 'components/typography/Text'
import useRedirectToMenuElement from 'hooks/useRedirectToMenuElement'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { styled } from 'styles/stitches'
import {
  GenerateMenuElementsInput,
  DashboardsListDocument,
  Resource,
  ResourcesListDocument,
  ResourcesListQuery,
  ResourcesListQueryVariables,
  useGenerateMenuElementsMutation
} from 'generated/schema'
import type { ViewProps } from 'components/views'
import type { FieldArrayChildrenProps } from 'components/form/FieldArray'

type Params = {
  initialValues: GenerateMenuElementsInput
}

const RepeatedItem = styled(Flex, {
  alignItems: 'center',
  gap: 14,
  grow: 1,
  height: 50,
  paddingX: 16,
  backgroundColor: 'light100',
  borderRadius: 6,

  '& [data-icon]': {
    color: '$dark400'
  }
})

const ResourceMenuMultiSelect = () => {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const resourceFieldsRef = useRef<FieldArrayChildrenProps<Resource>>()

  return (
    <FieldArray name="resourceMenuStubs" fieldsRef={resourceFieldsRef}>
      {({ keys, fields }) => {
        const getOptionDisabled = (option: Resource) => (fields.value
          ? fields.value.findIndex((f) => f?.id === option.id) !== -1
          : false)

        const getOptionIcon = (option: Resource) => option.app?.icon || `app-${option.app?.identifier || 'bridge'}`

        return (
          <>
            <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
              isSearchable
              preload
              setValueAsObject
              name="resourceId"
              label="Resource"
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              iconKey="app.identifier"
              valueKey="id"
              options={[]}
              isOptionDisabled={getOptionDisabled}
              getOptionLabel={(option: Resource) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
              getOptionIcon={getOptionIcon}
              input={{
                onChange: (value: Resource) => fields.push(value)
              }}
              query={ResourcesListDocument}
              queryOptions={{
                variables: {
                  filter: {
                    workspaceId: { eq: currentWorkspace.id }
                  },
                  order: [ {
                    name: 'asc'
                  } ]
                }
              }}
              dataKey="resourcesList"
              keys={[ 'name', 'identifier' ]}
            />
            <Flex direction="column">
              {keys.map((key, index) => {
                const resource = fields.value[index]
                const onRemove = () => fields.remove(index)

                return (
                  <>
                    <RepeatedItem key={key} alignItems="center" justifyContent="space-between">
                      <Flex gap={12} alignItems="center">
                        <Icon data-icon name={resource.app?.icon || `app-${resource.app?.identifier || 'bridge'}`} />
                        <Flex gap={4} alignItems="center">
                          <Text fontSize={14} fontWeight="semibold">{resource.app?.name || (resource.workspaceId ? 'Workspace' : 'Schema')}</Text>
                          <Icon data-icon name="arrow-right" size={8} />
                          <Text fontSize={14} color="dark700">{resource.name}</Text>
                        </Flex>
                      </Flex>
                      <ActionIcon
                        name="trash"
                        size={16}
                        onClick={onRemove}
                      />
                    </RepeatedItem>
                    {index !== keys.length - 1 && <Divider spacing={0} variant="ruler" />}
                  </>
                )
              })}
            </Flex>
          </>
        )
      }}
    </FieldArray>
  )
}

function AddResourceItemView({
  onRequestClose,
  params: { initialValues },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const redirect = useRedirectToMenuElement()

  const [ addToDashboard ] = useGenerateMenuElementsMutation({
    onCompleted: (data) => {
      onRequestClose()
      const menuElement = data.generateMenuElements[0]
      redirect(menuElement)
    },
    refetchQueries: [ DashboardsListDocument ],
    awaitRefetchQueries: true
  })

  const handleAddToDashboard = useSubmitHandler(addToDashboard, {
    successAlert: { message: 'Successfully added to dashboard.' }
  })

  const handleSubmit = (values: GenerateMenuElementsInput) => {
    const { resourceMenuStubs = [] } = values
    return handleAddToDashboard({
      ...values,
      resourceMenuStubs: resourceMenuStubs.map((r) => ({ id: r.id }))
    })
  }

  const title = isUpdating ? 'Update Resource Menu Item' : 'Add Resource Menu Item'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            keepDirtyOnReinitialize
            mutators={{
              ...arrayMutators
            }}
            initialValues={{
              ...initialValues,
              resourceMenuStubs: []
            }}
            onSubmit={handleSubmit}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex as="form" direction="column" gap={32} onSubmit={handleSubmit}>
                    <FormField alwaysDirty name="position" component="input" type="hidden" />
                    <ResourceMenuMultiSelect />
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

AddResourceItemView.defaultStyle = 'DIALOG' as const

export default AddResourceItemView
