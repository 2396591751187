import get from 'lodash/get'
import { object } from 'yup'

import BaseModel from 'models/BaseModel'
import convertToArray from 'lib/convertToArray'
import FieldModel from 'models/Field'
import isIdentifier from 'lib/formValidators/isIdentifier'
import type {
  ContentTypeFragmentFragment as ContentTypeFragment,
  CustomContent as ContentGQLType,
  ContentVersionsListQuery,
  FieldsListQuery
} from 'generated/schema'
import type { ChipProps } from 'components/chip/Chip'

enum ContentVersionStatus {
  // publishing workflow enabled
  PUBLISHED = 'Published',
  CURRENT_DRAFT = 'Current Draft',
  OLDER_DRAFT = 'Older Draft',
  // publishing workflow disabled
  CURRENT = 'Current',
  OLDER = 'Older'
}

const contentVersionStatusVariants: Record<string, ChipProps['variant']> = {
  // publishing workflow enabled
  [ContentVersionStatus.PUBLISHED]: 'positive',
  [ContentVersionStatus.CURRENT_DRAFT]: 'neutral',
  [ContentVersionStatus.OLDER_DRAFT]: 'light',
  // publishing workflow disabled
  [ContentVersionStatus.CURRENT]: 'neutral',
  [ContentVersionStatus.OLDER]: 'light'
}

type ContentVersion = ContentVersionsListQuery['contentVersionsList'][0] & {
  index: number,
  status: ContentVersionStatus,
  name: string
}

type FieldsList = FieldsListQuery['fieldsList']

type ResolveTitleOptions = {
  activeLocale?: string,
  defaultLocale?: string,
  fallbackTitle?: string
}

class Content extends BaseModel {
  static schema = object({
    identifier: isIdentifier()
  })

  static resolveTitle = (
    fieldsList?: FieldsList,
    data?: ContentGQLType['data'],
    contentType?: ContentTypeFragment,
    options?: ResolveTitleOptions
  ) => {
    if (!fieldsList || !data || !contentType) {
      return ''
    }

    const {
      activeLocale = Content.fallbackLocale,
      defaultLocale = Content.fallbackLocale,
      fallbackTitle
    } = options || {}

    const {
      actualTitleField, potentialTitleField
    } = FieldModel.getTitleField(fieldsList, contentType)

    const titleField = actualTitleField || potentialTitleField

    if (!titleField && fallbackTitle) {
      return fallbackTitle
    }

    const locale = titleField?.isTranslatable ? activeLocale : defaultLocale

    const value = titleField ? get(data, `${titleField.id}.${locale}`) : ''

    const title = convertToArray(value)

    return title.filter(Boolean).join(', ')
  }

  static fallbackLocale = 'en_US'
}

export type {
  ContentVersion
}

export {
  ContentVersionStatus,
  contentVersionStatusVariants
}

export default Content
