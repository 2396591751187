import tinykeys from 'tinykeys'
import { useEffect } from 'react'

function useHotKey(params: Parameters<typeof tinykeys>[1]) {
  useEffect(() => {
    const unsubscribe = tinykeys(window, params)
    return () => {
      unsubscribe()
    }
  }, [ params ])
}

export default useHotKey
