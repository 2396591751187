import { createStyled } from '@stitches/react'
import type { CSSProperties } from 'react'

import colors from 'styles/primitives/colors'
import radii from 'styles/primitives/radii'
import spaces from 'styles/primitives/spaces'
import typography from 'styles/primitives/typography'
import zIndices from 'styles/primitives/zIndices'

type VariantKeys = readonly (symbol | string | number)[]
type ThemeVariantKeys = keyof typeof theme
type ValueGetter<T> = (token: T) => any

const theme = {
  borderStyles: {},
  borderWidths: {},
  colors: {
    ...colors
  },
  fonts: {
    ...typography.fontFamilies
  },
  fontSizes: {
    ...typography.fontSizes
  },
  fontWeights: {
    ...typography.fontWeights
  },
  letterSpacings: {
    ...typography.letterSpacings
  },
  lineHeights: {
    ...typography.lineHeights
  },
  radii: {
    ...radii
  },
  shadows: {},
  sizes: {},
  space: {
    ...spaces
  },
  transitions: {},
  zIndices: {
    ...zIndices
  }
}

const { styled, css } = createStyled({
  tokens: theme,
  utils: {
    // mixins,
    paddingX: (value) => ({
      paddingLeft: value,
      paddingRight: value
    }),
    paddingY: (value) => ({
      paddingTop: value,
      paddingBottom: value
    }),
    marginX: (value) => ({
      marginLeft: value,
      marginRight: value
    }),
    marginY: (value) => ({
      marginTop: value,
      marginBottom: value
    }),
    size: (values: (string | number)[]) => ({ width: values[0], height: values[1] || values[0] }),
    hidden: (value: boolean) => (value
      ? ({
        border: 0,
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: 1
      }) : {}),
    truncate: (value: boolean) => (value ? ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }) : {}),
    fullscreen: (value: boolean) => (value ? ({
      bottom: 0,
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0
    }) : {})
  }
})

const stringifiedCss: typeof css = Object.assign(
  (...styles: Parameters<typeof css>) => css(...styles).toString(),
  css
)

const defaultValueGetter = <T>(token: T) => token

const generateVariants = <T extends VariantKeys, P extends keyof CSSProperties>(
  values: T,
  property?: P,
  selector?: string,
  getValue: ValueGetter<T[number]> = defaultValueGetter
) => (
    values.reduce((acc, token) => ({
      ...acc,
      [token]: property
        ? {
          ...(typeof selector === 'string'
            ? { [selector]: { [property]: getValue(token) } }
            : { [property]: getValue(token) })
        }
        : {}
    }),
      {} as Record<T[number], Record<string, any>>))

const generateThemeVariants = <K extends ThemeVariantKeys, P extends keyof CSSProperties>(
  key: K,
  property?: P,
  selector?: string,
  getValue?: ValueGetter<keyof typeof theme[K]>
) => (
    generateVariants(
      Object.keys(theme[key]) as (keyof typeof theme[K])[],
      property,
      selector,
      getValue
    )
  )

export { styled, stringifiedCss as css, theme, generateVariants, generateThemeVariants }
