import camelCase from 'lodash/camelCase'
import React from 'react'
import startCase from 'lodash/startCase'
import { ErrorBoundary } from 'react-error-boundary'

import EmbeddedField from 'components/contentEditors/generic/fields/EmbeddedField'
import FieldModel, { FieldIdentifier } from 'models/Field'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import JsonField from 'components/contentEditors/generic/fields/JsonField'
import ReferenceAttribute from 'components/contentEditors/generic/fields/ReferenceAttribute'
import RepeatedAttribute from 'components/resource/RepeatedAttribute'
import reportError from 'lib/reportError'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import {
  CheckboxField,
  ColorField,
  DateTimeField,
  DropdownField,
  DurationField,
  EmailField,
  MarkdownField,
  MediaField,
  NumberField,
  PasswordField,
  PhoneField,
  RadioField,
  SwitchField,
  TextField
} from 'components/contentEditors/generic/fields'
import { MEDIA_TYPE_OPTIONS } from 'components/contentEditors/generic/fields/fieldProps'
import { RESERVED_ATTRIBUTES, ValidationKind } from 'models/Attribute'
import { Attribute, Parameter, useAttributesListQuery, useDataTypesListQuery } from 'generated/schema'
import type { Locale } from 'hooks/useActiveLocales'

type GenericParamFieldsProps = {
  isDefaultOpened?: boolean,
  prefix?: string,
  currentLocale?: Locale,
  defaultLocale?: Locale,
  parameters: Parameter[],
  resourceId?: string,
  targetEnvironmentId?: string
}

type RenderAttributeOptions = {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  prefix?: string,
  isFirstField?: boolean,
  index?: number,
  resourceId?: string,
  targetEnvironmentId?: string
}

const getAttributeName = ({
  prefix,
  currentLocale,
  defaultLocale,
  identifier: _identifier,
  index,
  isArray = false,
  resourceId
}: Attribute & RenderAttributeOptions) => {
  const identifier = resourceId ? camelCase(_identifier) : _identifier
  const suffix = currentLocale?.identifier || defaultLocale?.identifier

  if (isArray) return [ prefix, identifier, suffix, index?.toString() ].filter(Boolean).join('.')

  return [ prefix, identifier, suffix ].filter(Boolean).join('.')
}

const getDateTimeSettings = (attribute: Attribute) => {
  const dateFormat = attribute.fieldTypeSettings?.date_format || 'YYYY/MM/DD'
  const timeFormat = attribute.fieldTypeSettings?.time_format || 'HH:MM A'

  if (attribute.dataType.kind === 'TIMESTAMP') {
    return {
      format: dateFormat === 'AUTO'
        ? undefined
        : `${dateFormat} ${timeFormat}`,
      saveFormat: attribute.dataType.kind,
      withTime: true
    }
  }

  if (attribute.dataType.kind === 'TIME') {
    return {
      format: dateFormat === 'AUTO'
        ? undefined
        : timeFormat,
      saveFormat: attribute.dataType.kind,
      withTime: true
    }
  }

  return {
    format: dateFormat === 'AUTO'
      ? undefined
      : dateFormat,
    saveFormat: attribute.dataType.kind
  }
}

const parseDimensionSettings = (settings: any) => {
  if (settings.width) {
    return ` width ${settings.width}px`
  }
  if (settings.min_width) {
    return ` minimum width ${settings.min_width}px`
  }
  if (settings.max_width) {
    return ` maximum width ${settings.max_width}px`
  }
  if (settings.height) {
    return ` height ${settings.height}px`
  }
  if (settings.min_height) {
    return ` minimum height ${settings.min_height}px`
  }
  if (settings.max_height) {
    return ` maximum height ${settings.max_height}px`
  }

  if (settings.aspect_ratio) {
    return ` aspect ratio ${settings.aspect_ratio}`
  }

  if (settings.min_aspect_ratio) {
    return ` minimum aspect ratio ${settings.min_aspect_ratio}`
  }

  if (settings.max_aspect_ratio) {
    return ` maximum aspect ratio ${settings.max_aspect_ratio}`
  }

  if (settings.duration) {
    return ` duration ${settings.duration} seconds`
  }

  if (settings.min_duration) {
    return ` minimum duration ${settings.min_duration} seconds`
  }

  if (settings.max_duration) {
    return ` maximum duration ${settings.max_duration} seconds`
  }

  if (settings.min_size) {
    const formattedSize = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow'
    }).format(settings.min_size)

    return ` minimum size ${formattedSize}`
  }

  if (settings.max_size) {
    const formattedSize = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow'
    }).format(settings.max_size)

    return ` maximum size ${formattedSize}`
  }

  return ''
}

const generateFileValidationHint = (attribute: Attribute) => {
  const { validations } = attribute

  return validations
    .filter((v) => [
      ValidationKind.FILE_DIMENSIONS,
      ValidationKind.FILE_SIZE,
      ValidationKind.FILE_DURATION,
      ValidationKind.FILE_TYPE
    ].includes(v.kind))
    .map((v) => {
      let hint = ''
      switch (v.kind) {
        case ValidationKind.FILE_DIMENSIONS:
          hint = 'File dimensions must have'
          hint += parseDimensionSettings(v.settings)

          return hint

        case ValidationKind.FILE_SIZE:
          hint = 'File must be of'
          hint += parseDimensionSettings(v.settings)

          return hint

        case ValidationKind.FILE_DURATION:
          hint = 'File must be of'
          hint += parseDimensionSettings(v.settings)

          return hint

        case ValidationKind.FILE_TYPE:
          hint = 'File type must be one of '
          hint += v.settings.allowed_mime_types.join(', ')
          return hint
      }

      return hint
    })
    .filter(Boolean)
    .join('\n')
}

const getCommonProps = (
  attribute: Attribute, // | Field
  options?: RenderAttributeOptions
) => {
  const name = getAttributeName({
    ...attribute,
    ...options
  })

  const {
    currentLocale
    // isFirstField
  } = options || {}

  const {
    identifier,
    name: label,
    id,
    settings,
    fieldTypeSettings,
    isNullable,
    validations
  } = attribute

  const isRequired = validations?.some((v) => v.kind === ValidationKind.PRESENCE)

  return {
    alwaysDirty: true,
    // autoFocus causes UI layout shift and other glitches with dropdowns, embedded drawers
    // autoFocus: isFirstField,
    defaultValue: (attribute.defaultValue?.type === 'fixed' ? attribute.defaultValue?.value : null) ?? undefined,
    isTranslatable: attribute.isTranslatable,
    key: id,
    name,
    label: attribute.fieldTypeSettings?.label ?? label ?? startCase(identifier),
    settings: {
      ...settings,
      ...fieldTypeSettings,
      ...attribute.dataType?.settings,
      hasFallbackLocale: currentLocale?.allowFallback,
      checkRequired: fieldTypeSettings?.checkRequired || isRequired
    },
    ...({
      checkRequired: fieldTypeSettings?.checkRequired || isRequired,
      placeholder: fieldTypeSettings?.placeholder,
      helpText: generateFileValidationHint(attribute)
      || fieldTypeSettings?.helpText
      || fieldTypeSettings?.help_text
    }),
    isNullable,
    size: 'small',
    shouldValidate: true,
    disabled: (attribute.resourceId && RESERVED_ATTRIBUTES.includes(attribute.identifier))
    || [
      attribute?.resource?.creationTimestampAttributeId,
      attribute?.resource?.updationTimestampAttributeId,
      attribute?.resource?.deletionTimestampAttributeId
    ].filter(Boolean).includes(attribute.id)
  }
}

const renderField = (
  attribute: Attribute,
  options?: RenderAttributeOptions
) => {
  const commonProps = getCommonProps(attribute, options)

  switch (attribute.fieldType) {
    case FieldIdentifier.DROPDOWN:
      return (
        <DropdownField
          {...commonProps}
          field={{
            id: attribute.id,
            settings: {
              ...attribute.settings,
              ...attribute.fieldTypeSettings
            }
          } as Field}
          isArray={attribute.isArray}
          isClearable
          metaKey="value"
        />
      )

    case FieldIdentifier.MEDIA:
    case FieldIdentifier.FILE:
      return (
        <MediaField
          {...commonProps}
          settings={{
            ...commonProps.settings,
            fileTypeCategory: (
              commonProps.settings.fileTypeCategory
              // @ts-ignore
              || MEDIA_TYPE_OPTIONS[attribute.dataType.kind]
            )
          }}
          isArray={attribute.isArray}
          resource={options?.resourceId || attribute.resourceId}
          targetEnvironment={options?.targetEnvironmentId}
          // @ts-ignore
          attribute={attribute.__typename === 'Attribute' ? attribute.id : attribute.attributeId}
        />
      )

    case FieldIdentifier.REFERENCE:
      return (
        <ReferenceAttribute
          attribute={attribute}
          currentLocale={options?.currentLocale?.identifier}
          environmentId={options?.targetEnvironmentId!}
          {...commonProps}
        />
      )

    case FieldIdentifier.CHECKBOX:
      return <CheckboxField {...commonProps} />

    case FieldIdentifier.COLOR:
      return <ColorField {...commonProps} />

    case FieldIdentifier.DATE:
      return (
        <DateTimeField
          {...commonProps}
          settings={{
            ...commonProps.settings,
            ...getDateTimeSettings(attribute)
          }}
        />
      )

    case FieldIdentifier.EMAIL:
      return <EmailField {...commonProps} />

    case FieldIdentifier.JSON:
      return <JsonField {...commonProps} />

    case FieldIdentifier.MARKDOWN:
      return <MarkdownField {...commonProps} />

    case FieldIdentifier.NUMBER:
      return <NumberField {...commonProps} />

    case FieldIdentifier.PASSWORD:
      return <PasswordField {...commonProps} />

    case FieldIdentifier.RADIO:
      return <RadioField {...commonProps} />

    case FieldIdentifier.SWITCH:
      return <SwitchField {...commonProps} />

    case FieldIdentifier.DURATION:
      return <DurationField {...commonProps} />

    case FieldIdentifier.PHONE:
      return <PhoneField {...commonProps} />

    case FieldIdentifier.EMBEDDED:
      return (
        <EmbeddedField
          {...commonProps}
          defaultLocale={options?.defaultLocale!}
          currentLocale={options?.currentLocale!}
          dataType={attribute.dataType}
          targetEnvironmentId={options?.targetEnvironmentId!}
          isArray={attribute.isArray}
          // resourceId={options?.resourceId || attribute.resourceId}
        />
      )

    default:
      return <TextField {...commonProps} />
  }
}

const ErrorFallback = () => (
  <Text>
    An unexpected error occured, our engineering team is looking into it
  </Text>
)

const ParameterField = ({
  prefix = 'data', parameter, index, currentLocale, defaultLocale, resourceId, targetEnvironmentId, isDefaultOpened
}: Omit<GenericParamFieldsProps, 'parameters'> & { parameter: GenericParamFieldsProps['parameters'][number], index: number}) => {
  const attribute = parameter.attribute || parameter

  if (parameter.isArray
    && !FieldModel.hasCustomRepeatedBehavior(parameter.fieldType as FieldIdentifier)) {
    return (
      <RepeatedAttribute
        key={parameter.id}
        attribute={(attribute) as any}
        name={parameter.identifier}
        shouldValidate
        prefix={prefix}
        renderField={renderField}
        currentLocale={currentLocale}
        defaultLocale={defaultLocale}
        resourceId={resourceId}
        targetEnvironmentId={targetEnvironmentId}
      />
    )
  }

  return renderField((attribute) as any, {
    currentLocale,
    defaultLocale,
    prefix,
    isFirstField: index === 0 && !isDefaultOpened,
    resourceId,
    targetEnvironmentId
  })
}

const useInjectAttributes = (parameters: Parameter[]) => {
  const attributeIds = parameters.map((p) => p.attributeId).filter(Boolean)
  const shouldQuery = attributeIds.length > 0 && parameters.some((p) => !p.attribute)
  const { data, loading, error } = useAttributesListQuery({
    skip: !shouldQuery,
    variables: {
      filter: {
        id: {
          in: attributeIds
        }
      }
    }
  })

  if (!shouldQuery) {
    return {
      parameters
    }
  }

  const idToAttributeMap = getPropertyToElementMap(data?.attributesList || [], 'id')

  return {
    error,
    loading,
    parameters: data && parameters.map((p) => ({
      ...p,
      attribute: p.attribute || idToAttributeMap[p.attributeId]
    }))
  }
}

const useInjectDataTypes = (parameters: Parameter[]) => {
  const dataTypeIds = parameters.map((p) => p.attribute?.dataTypeId || p.dataTypeId).filter(Boolean)
  const shouldQuery = dataTypeIds.length > 0
  && parameters.some((p) => !p.attribute?.dataType || !p.dataType)
  const { data, loading, error } = useDataTypesListQuery({
    skip: !shouldQuery,
    variables: {
      filter: {
        id: {
          in: dataTypeIds
        }
      }
    }
  })

  if (!shouldQuery) {
    return {
      parameters
    }
  }

  const idToDataTypeMap = getPropertyToElementMap(data?.dataTypesList || [], 'id')

  return {
    error,
    loading,
    parameters: data && parameters.map((p) => ({
      ...p,
      ...(p.attribute ? {
        attribute: {
          ...p.attribute,
          dataType: p.attribute.dataType || idToDataTypeMap[p.attribute.dataTypeId]
        }
      } : {}),
      dataType: p.dataType || idToDataTypeMap[p.dataTypeId]
    }))
  }
}

const GenericParamFields = ({ parameters, ...rest }: GenericParamFieldsProps) => {
  const {
    parameters: parametersWithAttributes,
    loading: loading1,
    error: error1
  } = useInjectAttributes(parameters)
  const {
    parameters: parametersWithDataTypes,
    loading: loading2,
    error: error2
  } = useInjectDataTypes(parametersWithAttributes || [] as any[])

  return (
    <SectionLoader error={error1 || error2} loading={loading1 || loading2} data={parametersWithDataTypes} empty={{ title: 'There are no data types.' }}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
        {parametersWithDataTypes && parametersWithDataTypes.map((parameter, index) => (
          <ParameterField
            key={parameter.id}
            parameter={parameter}
            index={index}
            {...rest}
          />
        ))}
      </ErrorBoundary>
    </SectionLoader>
  )
}

export { renderField }

export type { GenericParamFieldsProps, RenderAttributeOptions }

export default GenericParamFields
