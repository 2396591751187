import { useEffect, useCallback, useRef } from 'react'
import type { MouseEvent } from 'react'

type TimeoutId = NodeJS.Timeout | null

const DEFAULT_MOUSE_BUTTON = 0

const safeClearTimeout = (timeoutId: TimeoutId) => {
  if (!timeoutId) return

  clearTimeout(timeoutId)
}

function useLongPress(callback: () => void, { disabled = false, duration = 1000 } = {}) {
  const timerId = useRef<TimeoutId>(null)

  const startTimer = useCallback(
    () => {
      safeClearTimeout(timerId.current)

      timerId.current = setTimeout(callback, duration)
    },
    [ callback, duration ]
  )

  const stopTimer = useCallback(
    () => {
      safeClearTimeout(timerId.current)
      timerId.current = null
    },
    []
  )

  const mouseStart = useCallback(
    (event: MouseEvent<any>) => {
      if (event.button !== DEFAULT_MOUSE_BUTTON) return

      event.preventDefault()

      startTimer()
    },
    [ startTimer ]
  )

  // Calling `preventDefault` in `touchStart` doesn't prevent the default click
  // on chromium browsers so there is no custom `touchStart` handler.
  // https://github.com/facebook/react/issues/9809
  // https://developers.google.com/web/updates/2017/01/scrolling-intervention
  // This should be resolved by: https://github.com/facebook/react/issues/2043

  useEffect(
    () => (
      // Noop, effect is only used for cleanup
      () => safeClearTimeout(timerId.current)
    ),
    []
  )

  return disabled ? {} : {
    onMouseDown: mouseStart,
    onMouseUp: stopTimer,
    onMouseLeave: stopTimer,
    onTouchStart: startTimer,
    onTouchEnd: stopTimer
  }
}

export default useLongPress
