import { object, string } from 'yup'

import BaseModel from './BaseModel'
import { MFA_CODE_LENGTH } from './Account'

class AuthModel extends BaseModel {
  static schema = object({
    code: string().min(MFA_CODE_LENGTH).required()
  })
}

export default AuthModel
