import React from 'react'

import type { RendererOptions } from 'components/dataTable/types'
import currency from 'lib/currency'

function CurrencyRenderer({ rowData, dataKey }: RendererOptions) {
  const { currencyCode } = rowData

  const amount = rowData[dataKey]
  const currencyData = currency(amount, currencyCode)

  return <span title={currencyData}>{currencyData}</span>
}

export default CurrencyRenderer
