import { boolean, object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { MenuElementSeparatorStyle, MenuElementTarget } from 'generated/schema'

class MenuElement extends BaseModel {
  static schema = object({
    icon: string().nullable(),
    isSticky: boolean().required(),
    name: string().required(),
    path: string()
      .when('target', {
        is: 'URL',
        then: string().nullable(),
        otherwise: string().required()
      }),
    separatorStyle: string().oneOf<MenuElementSeparatorStyle>([ 'RULER', 'WHITESPACE' ]).default('RULER'),
    target: string().oneOf<MenuElementTarget>([ 'VIEW', 'URL', 'SUBMENU' ]).default('VIEW'),
    url: string()
      .when('target', {
        is: 'URL',
        then: string().required(),
        otherwise: string().nullable()
      })
  })

  /**
   * Checks if menu element is descendent of menu element id
   * @param menuElementId id of possible ancestor menu element
   * @param menuElement menu element to be tested
   */
  static isDescendantOf(menuElementId?: string, menuElement?: ComputedMenuElement) {
    return Boolean(menuElementId && menuElement?.parentIds?.includes(menuElementId))
  }
}

export default MenuElement
