import React from 'react'
import get from 'lodash/get'

import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

const StyledText = styled(Text, {
  color: 'currentColor',
  textAlign: 'right',
  fontVariantNumeric: 'tabular-nums'
})

function NumberRenderer<T extends object>({
  dataKey,
  rowData,
  isArray,
  prefix = '',
  suffix = ''
}: RendererOptions<T>) {
  const key = [ prefix, dataKey, suffix ].filter(Boolean).join('.')
  const data = get(rowData, key)

  if (isArray) {
    const repeatedContent = Array.isArray(data) ? data.join(', ') : data
    return (
      <StyledText as="span" title={repeatedContent} truncate>{repeatedContent}</StyledText>
    )
  }
  return (
    <StyledText as="span" title={data} truncate>{data}</StyledText>
  )
}

export default NumberRenderer
