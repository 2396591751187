import kebabCase from 'lodash/kebabCase'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import { Form, FormProps } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import MediaCard from 'components/mediaCard/MediaCard'
import Parameter from 'models/Parameter'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { CreateRelationshipInput, RelationshipsListDocument, Resource, UpdateRelationshipInput, useAttributesListQuery, useCreateRelationshipMutation, useResourcesListQuery, useUpdateRelationshipMutation, useRelationshipsListQuery, Attribute, RelationshipKind } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { KIND_OPTIONS, Kind } from 'models/Relationship'
import { RELATIONSHIPS_LIST_LIMIT, RESOURCES_LIST_LIMIT } from 'models/Resource'
import { SidePaneFooter } from 'components/sidePane'
import type { ActiveViewProps } from '../DashboardEditor'
import type { ViewParams, Views } from '../constants'

type FormValues = (CreateRelationshipInput | UpdateRelationshipInput) & {
  target?: Resource
}

type Params = ViewParams[Views.CREATE_RELATIONSHIP]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const getPrimaryAttribute = (attributes: Attribute[]) => (
  attributes?.find((attr) => attr.isPrimary)?.id
)

const getName = (kind: Kind, name: string) => {
  if ([ Kind.BELONGS_TO, Kind.HAS_ONE ].includes(kind)) return name
  return pluralize(name)
}

const CreateRelationshipView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {}, resource } = params! as Params
  const { id: resourceId } = resource || {} as Resource
  const { kind, target } = initialValues as FormValues

  const isUpdating = 'id' in initialValues

  const [ targetResource, setTargetResource ] = useState<Resource | null>(target || null)
  const [ selectedKind, setSelectedKind ] = useState<RelationshipKind>(kind || Kind.BELONGS_TO)
  const isBelongsTo = selectedKind === Kind.BELONGS_TO
  const parsedTargetResourceName = targetResource ? getName(selectedKind as Kind, targetResource.name) : ''

  const {
    data: { relationshipsList = [] } = {}
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const {
    data: { resourcesList = [] } = {},
    loading: resourcesLoading
  } = useResourcesListQuery({
    variables: {
      filter: {
        appId: { eq: resource?.appId },
        isReadOnly: { eq: false }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RESOURCES_LIST_LIMIT
    }
  })

  const {
    data: { attributesList: sourceAttributesList = [] } = {},
    loading: sourceAttributesLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      }
    },
    skip: !resourceId
  })

  const {
    data: { attributesList: targetAttributesList = [] } = {},
    loading: targetAttributesLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: targetResource?.id }
      }
    },
    skip: !targetResource?.id
  })

  const [ createRelationship ] = useCreateRelationshipMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ RelationshipsListDocument ]
  })

  const [ updateRelationship ] = useUpdateRelationshipMutation({
    onCompleted: () => stepBackDashboardEditor(),
    refetchQueries: [ RelationshipsListDocument ]
  })

  const handleCreateRelationship = useSubmitHandler(createRelationship, {
    successAlert: { message: 'Relationship Created.' }
  })

  const handleUpdateRelationship = useSubmitHandler(updateRelationship, {
    successAlert: { message: 'Relationship Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    const cleanValues = {
      kind: selectedKind,
      ...values,
      source: undefined,
      target: undefined
    }

    if (isUpdating) {
      return handleUpdateRelationship(
        cleanValues as UpdateRelationshipInput,
        form as FormApi<UpdateRelationshipInput>
      )
    }

    return handleCreateRelationship(cleanValues as CreateRelationshipInput)
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Update' : 'Add'} Relationship`}
        heading={resourceId ? `Resource: ${resource?.name}` : 'Dashboard Editor'}
        onClose={onClose}
      />
      <Form
        decorators={[
          setIdentifier
        ]}
        initialValues={{
          position: generatePosition(getMaxPosition(relationshipsList as any[])),
          ...initialValues as FormValues
        }}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        subscription={{
          submitting: true,
          pristine: true
        }}
        validate={(values) => Parameter.validate(values, [ 'identifier', 'name' ])}
        render={({ handleSubmit, submitting, pristine, form }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                <Flex direction="column" gap={10}>
                  <Text fontSize={10} fontWeight="bold" color="dark500" textTransform="uppercase">Type</Text>
                  {KIND_OPTIONS.map((type) => (
                    <MediaCard
                      compact
                      title={type.name}
                      text={type.description}
                      titlePosition="top"
                      media={type.icon}
                      height={64}
                      active={type.identifier === selectedKind}
                      onClick={() => setSelectedKind(type.identifier)}
                      width="full"
                      disabled={isUpdating}
                    />
                  ))}
                </Flex>
                <FormField
                  label="Source Resource"
                  component={SelectInput}
                  name="source"
                  size="small"
                  labelKey="name"
                  valueKey="id"
                  metaKey="identifier"
                  options={resourcesList}
                  loading={resourcesLoading}
                  defaultValue={resourceId}
                  isDisabled
                />
                <FormField
                  label="Source Attribute"
                  component={SelectInput}
                  name="sourceAttributeId"
                  size="small"
                  variant="light"
                  labelKey="name"
                  metaKey="identifier"
                  valueKey="id"
                  options={sourceAttributesList}
                  loading={sourceAttributesLoading}
                  isDisabled={!isBelongsTo}
                  defaultValue={!isBelongsTo
                    && getPrimaryAttribute(sourceAttributesList as Attribute[])}
                />
                <FormField
                  label="Target Resource"
                  component={SelectInput}
                  name="target"
                  size="small"
                  labelKey="name"
                  valueKey="id"
                  metaKey="identifier"
                  options={resourcesList}
                  loading={resourcesLoading}
                  onChange={(target: Resource) => {
                    setTargetResource(target)
                    form.change('target', target.id)
                  }}
                />
                <FormField
                  label="Target Attribute"
                  name="targetAttributeId"
                  component={SelectInput}
                  defaultValue={getPrimaryAttribute(targetAttributesList as Attribute[])}
                  size="small"
                  variant="light"
                  labelKey="name"
                  metaKey="identifier"
                  valueKey="id"
                  options={targetAttributesList}
                  loading={targetAttributesLoading}
                  isDisabled={isBelongsTo}
                />
                <FormField
                  component={TextInput}
                  name="name"
                  label="Name"
                  size="small"
                  type="text"
                  defaultValue={parsedTargetResourceName}
                />
                <FormField
                  component={TextInput}
                  name="identifier"
                  label="Identifier"
                  size="small"
                  type="text"
                  helpText="Used in code. Avoid modifying this."
                  defaultValue={kebabCase(parsedTargetResourceName)}
                />
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button size="small" type="submit" label="Submit" disabled={submitting || pristine} onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateRelationshipView
