import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'

enum Kind {
  WORKSPACE = 'WORKSPACE',
  APP = 'APP',
  RESOURCE = 'RESOURCE',
}

const KIND_ICON_MAP = {
  [Kind.WORKSPACE]: 'workspace',
  [Kind.APP]: 'app',
  [Kind.RESOURCE]: 'graph'
}

const WORKSPACE_KIND_OPTIONS = [
  { label: 'Workspace', icon: 'workspace', value: Kind.WORKSPACE },
  { label: 'App', icon: 'app', value: Kind.APP }
  // { label: 'Resource', icon: 'graph', value: Kind.RESOURCE }
]

class Role extends BaseModel {
  static schema = object({
    name: string().required()
  })
}

export {
  Kind,
  KIND_ICON_MAP,
  WORKSPACE_KIND_OPTIONS
}

export default Role
