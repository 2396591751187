import React from 'react'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import { Popover, PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'

interface Props {
  onClick: (payload: any) => void,
  selectedText?: string
}

const StyledContainer = styled(Popover, {
  padding: 16
})

const LinkCommand: React.FC<Props> = ({ children, onClick, selectedText = '' }) => (
  <PopoverContainer
    modifiers={[
      {
        name: 'offset',
        options: {
          offset: [ -38, 8 ]
        }
      }
    ]}
  >
    {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
      <div {...otherToggleProps}>
        {children}
      </div>
    )}
    {(popoverProps) => (
      <StyledContainer withArrow {...popoverProps}>
        <Flex
          as="form"
          direction="column"
          gap={8}
          onSubmit={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            const formData = new FormData(e.target)
            onClick({
              linkText: formData.get('linkText'),
              linkUrl: formData.get('linkUrl')
            })
            popoverProps.closePopover()
          }}
        >
          <TextInput
            defaultValue={selectedText}
            input={{}}
            name="linkText"
            label="Link Text"
            size="small"
          />
          <TextInput
            input={{}}
            name="linkUrl"
            label="Link URL"
            size="small"
          />
          <Button label="Add Link" mode="subtle" variant="simple" size="small" type="submit" />
        </Flex>
      </StyledContainer>
    )}
  </PopoverContainer>
)

export default LinkCommand
