import React from 'react'

import Box from 'components/layout/Box'
import Flex, { FlexProps } from 'components/layout/Flex'
import SimpleLoader from 'components/loaders/SimpleLoader'

type ListProps = FlexProps

function List({ children, loading, ...other }: ListProps) {
  /** Todo: Loader can be improved with a linear progess indicator
   * <Flex>
   *   {loading && <LinearProgress />}
   *   {children}
   * <Flex>
   */
  return (
    <Flex direction="column" gap={4} {...other}>
      {loading
        ? <Box alignSelf="center"><SimpleLoader size="large" /></Box>
        : children}
    </Flex>
  )
}

export default List
