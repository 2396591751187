import React from 'react'
import get from 'lodash/get'
import isObject from 'lodash/isObject'

import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import type { RendererOptions } from 'components/dataTable/types'

function ChipRenderer<T>({ dataKey, rowData, prefix = '', suffix = '' }: RendererOptions<T>) {
  const prefixContent = prefix ? `${prefix}.` : ''
  const suffixContent = suffix ? `.${suffix}` : ''
  const value = get(rowData, `${prefixContent}${dataKey}${suffixContent}`)

  if (Array.isArray(value)) {
    const trimmedValue = value.length > 2 ? value.slice(0, 2) : value

    return (
      <Flex gap={6} alignItems="center">
        {trimmedValue.map((label) => (
          <Chip
            key={label}
            label={label}
            title={label}
            variant="light"
          />
        ))}
        {value.length > 2 && <Text as="span" fontSize={12} shrink={0}>+{value.length - 2} more</Text>}
      </Flex>
    )
  }

  if (!value) {
    return <></>
  }

  return (
    <Chip
      label={isObject(value) ? JSON.stringify(value) : value}
      title={value}
      variant="light"
    />
  )
}

export default ChipRenderer
