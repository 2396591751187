import merge from 'lodash/merge'
import { klona } from 'klona'
import { useImperativeHandle, useRef, useState } from 'react'
import { OperationVariables, QueryFunctionOptions, QueryHookOptions, QueryResult, TypedDocumentNode, useQuery } from '@apollo/client'
import type { DocumentNode } from 'graphql'

type UseSearchProps<TData, TVariables = OperationVariables> = {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  queryOptions?: QueryHookOptions<TData, TVariables>,
  onSearch?: () => void,
  keys: string[],
  handlerRef?: React.Ref<QueryResult<TData, TVariables> | undefined>
}

const useSearchText = (initialValue = '') => {
  const [ searchText, setSearchText ] = useState(initialValue)
  const aborterRef = useRef(new AbortController())

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    aborterRef.current.abort()
    aborterRef.current = new AbortController()
    setSearchText(typeof e === 'string' ? e : e.target.value)
  }

  return {
    searchText,
    handleSearch,
    fetchOptions: {
      signal: aborterRef.current.signal
    }
  }
}

function useSearch<TData, TVariables = OperationVariables>({
  query,
  queryOptions = {},
  onSearch,
  keys = [],
  handlerRef
}: UseSearchProps<TData, TVariables>) {
  const { searchText, handleSearch, fetchOptions } = useSearchText('')
  const onCompletedRef = useRef<QueryFunctionOptions<TData, TVariables>['onCompleted']>()

  const { variables = {}, context = {} }: any = klona(queryOptions)

  const searchFilters = (() => {
    if (searchText.length > 0) {
      // special case for searchRecords
      if ('input' in variables) {
        return {
          input: {
            ...variables.input,
            filter: {
              or: keys.map((key) => ({ [key]: { matches: searchText } }))
            }
          }
        }
      }

      return {
        filter: {
          or: keys.map((key) => ({ [key]: { contains: searchText } }))
        }
      }
    }
    return {}
  })()

  const result = useQuery<TData, TVariables>(query, {
    ...queryOptions,
    onCompleted: (d) => {
      onCompletedRef.current?.(d)
      queryOptions.onCompleted?.(d)
    },
    context: merge(context, {
      fetchOptions
    }),
    variables: merge(variables, searchFilters)
  })

  useImperativeHandle(handlerRef, () => result)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string, callback?: QueryFunctionOptions<TData, TVariables>['onCompleted']) => {
    handleSearch(e)
    onCompletedRef.current = callback
    onSearch?.()
  }

  return [ result, handleChange ] as const
}

export { useSearch, useSearchText }

export default useSearch

export type { UseSearchProps }
