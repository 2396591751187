import isEmpty from 'lodash/isEmpty'

import { DataTypeFragmentFragment, FieldType, useFieldTypesListQuery } from 'generated/schema'

const useGetAllowedFieldTypes = () => {
  const { data: { fieldTypes = [] } = {}, loading } = useFieldTypesListQuery()
  return (datatype?: DataTypeFragmentFragment) => {
    if (!datatype || isEmpty(datatype)) {
      return { fieldTypes, loading }
    }

    return {
      fieldTypes: (datatype.allowableFieldTypes || [])?.map((f) => fieldTypes.find(
        (g) => f === (g.identifier)
      )).filter(Boolean) as FieldType[],
      loading
    }
  }
}

export default useGetAllowedFieldTypes
