import React from 'react'

import { colorVars, Palette } from 'styles/theme'
import { styled } from 'styles/stitches'

const ACCENT_INDICATOR_SIZE = 12
const PRIMARY_INDICATOR_SIZE = 22
const SECONDARY_INDICATOR_OFFSET = 2
const SECONDARY_INDICATOR_SIZE = 12
const SECONDARY_INDICATOR_OFFSET_TOP = 8

const StyledPaletteIndicatorContainer = styled('div', {
  height: PRIMARY_INDICATOR_SIZE + ACCENT_INDICATOR_SIZE,
  position: 'relative',
  width: PRIMARY_INDICATOR_SIZE + SECONDARY_INDICATOR_OFFSET + SECONDARY_INDICATOR_SIZE
})

const StyledIndicator = styled('span', {
  borderRadius: '50%',
  display: 'inline-block',
  position: 'absolute',

  variants: {
    variant: {
      primary: {
        size: [ PRIMARY_INDICATOR_SIZE ],
        left: 0,
        top: 0
      },
      secondary: {
        size: [ SECONDARY_INDICATOR_SIZE ],
        left: PRIMARY_INDICATOR_SIZE + SECONDARY_INDICATOR_OFFSET,
        top: SECONDARY_INDICATOR_OFFSET_TOP
      },
      accent: {
        size: [ ACCENT_INDICATOR_SIZE ],
        left: PRIMARY_INDICATOR_SIZE - SECONDARY_INDICATOR_OFFSET_TOP,
        top: PRIMARY_INDICATOR_SIZE
      }
    }
  }
})

function PaletteIndicator({ primary, secondary, accent }: Palette) {
  return (
    <StyledPaletteIndicatorContainer>
      <StyledIndicator
        variant="primary"
        style={{ backgroundColor: primary || colorVars.primary400 }}
      />
      <StyledIndicator
        variant="secondary"
        style={{ backgroundColor: secondary || colorVars.secondary400 }}
      />
      <StyledIndicator
        variant="accent"
        style={{ backgroundColor: accent || colorVars.accent400 }}
      />
    </StyledPaletteIndicatorContainer>
  )
}

export default PaletteIndicator
