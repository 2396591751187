import React, { ChangeEvent, Suspense, useMemo } from 'react'
import get from 'lodash/get'
import { object } from 'yup'
import { useRecoilState } from 'recoil'
import { useFormState } from 'react-final-form'

import BaseModel from 'models/BaseModel'
import componentLoader from 'lib/componentLoader'
import Divider from 'components/divider/Divider'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import isIdentifier from 'lib/formValidators/isIdentifier'
import Loader from 'components/loaders/Loader'
import pascalCase from 'lib/pascalCase'
import SelectInput from 'components/inputs/SelectInput'
import useDashboard from 'hooks/useDashboard'
import type { Element } from 'hooks/useDashboard'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

const INPUT_TYPE_OPTIONS = [
  { label: 'Text', value: 'text-input' },
  // { label: 'Checkbox', value: 'checkbox-input' },
  { label: 'Dropdown', value: 'dropdown-input' },
  { label: 'Date & Time', value: 'date-time-input' },
  { label: 'Number', value: 'number-input' },
  { label: 'Color', value: 'color-input' }
  // { label: 'Button Group', value: 'button-group-input' }
]

const INPUT_SIZE_OPTIONS = [
  { label: 'Small', value: 'small' },
  { label: 'Normal', value: 'normal' },
  { label: 'Large', value: 'large' }
]

const getFieldViewName = (fieldType: string) => {
  if (!fieldType) return ''

  const identifier = fieldType.replace('input', 'field')

  // text-field -> TextFieldView
  return `${pascalCase(identifier)}View`
}

const noop = () => {}

const useMockFieldProps = (type: string, name: string, error: string = '') => {
  const { elementState } = useDashboard()
  const [ element, setElement ] = useRecoilState<Element>(elementState(name))
  let inputProps = {}

  switch (type) {
    case 'checkbox-input':
      inputProps = {
        checked: element.properties.value,
        onChange: (event: ChangeEvent<HTMLInputElement>) => setElement(
          (prev) => ({
            ...prev,
            value: event.target.checked,
            [name]: { value: event.target.checked }
          })
        )
      }
      break
    case 'date-time-input':
      inputProps = { value: element.properties.value,
        onChange: (value: string) => {
          setElement(
            (prev) => ({
              ...prev,
              value,
              [name]: { value }
            })
          )
        } }
      break
    case 'select-input':
      inputProps = {
        value: element.properties.value,
        onChange: (selectedOption: any) => setElement(
          (prev) => ({
            ...prev,
            value: selectedOption,
            [name]: { value: selectedOption }
          })
        )
      }
      break
    case 'number-input':
      inputProps = {
        type: 'number',
        value: element.properties.value,
        onChange: (event: ChangeEvent<HTMLInputElement>) => setElement(
          (prev) => ({
            ...prev,
            value: event.target.value,
            [name]: { value: event.target.value }
          })
        )
      }
      break
    case 'color-input':
      inputProps = {
        value: element.properties.value,
        onChange: (value: any) => setElement(
          (prev) => ({
            ...prev,
            value,
            [name]: { value }
          })
        )
      }
      break
    case 'button-group-input':
      inputProps = {
        name,
        value: element.properties.value,
        onChange: (value: any) => setElement(
          (prev) => ({
            ...prev,
            value,
            [name]: { value }
          })
        )
      }
      break
    default:
      inputProps = {
        type: 'text',
        value: element.properties.value,
        onChange: (event: ChangeEvent<HTMLInputElement>) => setElement(
          (prev) => ({
            ...prev,
            value: event.target.value,
            [name]: { value: event.target.value }
          })
        )
      }
  }

  return {
    inputProps,
    input: {
      name,
      onBlur: noop,
      onFocus: noop,
      ...inputProps
    },
    meta: {
      error,
      pristine: false,
      touched: true
    }
  }
}

const InputElementView = () => {
  const { values } = useFormState({ subscription: { values: true } })
  const fieldType = get(values, 'properties.type')

  const fieldViewName = getFieldViewName(fieldType)
  const fieldViewFileName = pascalCase(fieldViewName)

  const Base = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Base }))
  ), [ fieldViewFileName ])

  const Configurations = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Configurations }))
  ), [ fieldViewFileName ])

  const Preview = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Preview }))
  ), [ fieldViewFileName ])

  const Validations = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Validations }))
  ), [ fieldViewFileName ])

  return (
    <Grid columns={1} gap={24}>
      <FormField
        defaultValue="text-input"
        component={SelectInput}
        name="properties.type"
        label="Input Type"
        size="small"
        options={INPUT_TYPE_OPTIONS}
      />
      <Divider />
      <Suspense fallback={<Loader loading />}>
        <Base disableAutoFocus fieldPrefix="properties." />
        <FormField
          defaultValue="small"
          component={SelectInput}
          name="properties.size"
          label="Input Size"
          size="small"
          options={INPUT_SIZE_OPTIONS}
        />
        <Configurations fieldPrefix="properties." disableLocalization />
        <Preview fieldPrefix="properties." />
        <Validations fieldPrefix="properties." />
      </Suspense>
    </Grid>
  )
}

InputElementView.validate = (values: fieldProps<'text'>) => {
  const schema = {
    identifier: isIdentifier(),
    properties: object({})
  }

  return BaseModel.validateSchema(values, schema)
}

export { useMockFieldProps }

export default InputElementView
