import { gql } from '@apollo/client'

import type { ClientState } from 'client/state/types'

/* Default Data */

const DEFAULT_REFERRER: Referrer = {
  __typename: 'Referrer',
  url: null
}

/* Types */

export type Referrer = {
  __typename: 'Referrer',
  url: string | null
}

export type ReferrerQuery = {
  referrer: Referrer
}

/* Queries */

export const REFERRER_QUERY = gql`
  query ReferrerQuery {
    referrer @client {
      url
    }
  }
`

/* Mutations */

export const SET_REFERRER_MUTATION = gql`
  mutation SetReferrerMutation($url: String!) {
    setReferrer(url: $url) @client
  }
`

/* ClientState */

export default {
  defaults: {
    query: REFERRER_QUERY,
    data: { referrer: DEFAULT_REFERRER }
  },

  resolvers: {
    Mutation: {
      setReferrer: (_, { url }, { cache }) => {
        cache.writeQuery({
          query: REFERRER_QUERY,
          data: { referrer: { ...DEFAULT_REFERRER, url } }
        })

        return null
      }
    }
  }
} as ClientState
