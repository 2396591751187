import { klona } from 'klona'

export type TMap<V> = {
  [key: string]: V
}

export default <T extends {[key: string]: any}>(
  collection: readonly T[],
  property: keyof T
) => collection.reduce((map: TMap<T>, element) => {
  if (element) {
    map[element[property]] = klona(element)
  }
  return map
}, {})
