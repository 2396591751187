import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import Grid from 'components/layout/Grid'
import IconInput from 'components/inputs/IconInput'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import TextInput from 'components/inputs/TextInput'
import updateMenuElements from 'lib/updateMenuElements'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler, { FormPropsWithId } from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { ComputedMenuElement, getInjectRecursiveProperties } from 'lib/generateDashboard'
import { CreateMenuElementInput, UpdateMenuElementInput, useCreateMenuElementMutation, useUpdateMenuElementMutation } from 'generated/schema'
import { LiveMenuEditorOrchestrator } from './AddMenuElementView'
import { MenuElementPositionContext, useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { SidePaneFooter } from 'components/sidePane'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type InitialValues = Partial<ComputedMenuElement & MenuElementPositionContext>

type Params = ViewParams[Views.ADD_MENU_ELEMENT]

function AddSubmenuView() {
  const { push } = useHistory()

  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()

  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params
  const { isSticky = false, id, icon, name, parentId, placement = 'SIDE', position, target = 'SUBMENU', url } = initialValues as InitialValues

  const {
    currentDashboard,
    parentIdToMenuElementsMap
  } = useContext(InternalContext)!

  const { currentWorkspace } = useContext(WorkspaceContext)!

  const dashboardId = currentDashboard?.id
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}

  const [ updateMenuElement ] = useUpdateMenuElementMutation({
    onCompleted: () => stepBackDashboardEditor()
  })

  const [ createMenuElement ] = useCreateMenuElementMutation({
    onCompleted({ createMenuElement: menuElement }) {
      if (menuElement.target !== 'URL') {
        const idToDashboardMap = currentDashboard ? getPropertyToElementMap<typeof currentDashboard>([ currentDashboard ], 'id') : {}

        const idToMenuElementMap = getPropertyToElementMap<typeof menuElement>([ menuElement ], 'id')

        const injectRecursiveProperties = getInjectRecursiveProperties(
          idToMenuElementMap,
          idToDashboardMap,
          currentWorkspace
        )

        const computedMenuElement = injectRecursiveProperties(menuElement)
        push(computedMenuElement.fullPath!)
      }
    }
  })

  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })

  const handleMenuItemFormSubmit = (
    values: FormValues,
    form: FormPropsWithId<FormValues>['form']
  ) => {
    if (id) {
      let newPosition

      if (isSticky === values.isSticky) {
        // no change needed
        newPosition = position
      } else {
        newPosition = values.isSticky
          ? newStickyPosition
          : newNonStickyPosition
      }

      return handleUpdateMenuElementSubmit({
        ...values,
        id,
        position: newPosition
      }, form as FormApi<UpdateMenuElementInput>)
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId,
      kind: 'ITEM',
      position: values.isSticky
        ? newStickyPosition!
        : newNonStickyPosition!
    })
  }

  return (
    <Form
      initialValues={{
        id,
        icon,
        isSticky,
        name,
        parentId,
        placement,
        position,
        target,
        url
      }}
      onSubmit={handleMenuItemFormSubmit}
      subscription={{ submitting: true, pristine: true }}
      validate={(values) => MenuElement.validate(values, [ 'name', 'icon', 'target', 'isSticky', 'url' ])}
      render={({ handleSubmit, submitting, pristine }) => (
        <>
          <DashboardEditorBody>
            <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
              <FormField alwaysDirty name="position" component="input" type="hidden" />
              <Grid alignItems="center" gap={16} columns={1}>
                <Field
                  autoFocus
                  component={TextInput}
                  name="name"
                  label="Label"
                  size="small"
                  type="text"
                />
                <Field
                  component={IconInput}
                  name="icon"
                  label="Choose Icon"
                  size="small"
                  type="text"
                />
              </Grid>
              <input type="submit" style={{ display: 'none' }} />
            </Flex>
            <LiveMenuEditorOrchestrator />
          </DashboardEditorBody>
          <SidePaneFooter variant="small" isSticky>
            <Flex gap={24} direction="row-reverse">
              <Button type="submit" size="small" label="Submit" disabled={submitting || pristine} onClick={handleSubmit} />
              <Button label="Cancel" size="small" variant="outline" mode="subtle" onClick={() => stepBackDashboardEditor()} />
            </Flex>
          </SidePaneFooter>
        </>
      )}
    />
  )
}

export default AddSubmenuView
