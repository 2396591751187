import React, { useState } from 'react'

import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import DataTableBlock from 'components/blocks/DataTableBlock'
import EnvironmentFormView from 'components/views/EnvironmentFormView'
import EnvironmentView from 'components/views/EnvironmentView'
import HintBox from 'components/hints/HintBox'
import SearchBar from 'components/searchbar/SearchBar'
import TextLink from 'components/links/TextLink'
import TitleBlock from 'components/blocks/TitleBlock'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { EnvironmentsListDocument, EnvironmentsListQuery } from 'generated/schema'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { Environment, EnvironmentsListQueryVariables } from 'generated/schema'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = []

function EnvironmentsPage() {
  const { openView } = useViewDispatch()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const [ order, setOrder ] = useState(DEFAULT_ORDER)
  const [ filters, setFilters ] = useState<FilterType>({})

  const queryVariables = {
    limit: pageSize,
    filter: filters,
    page,
    order
  }

  const [ {
    data: { environmentsList = [], environmentsAggregate = {} } = {},
    error,
    loading
  }, handleChange ] = useSearch<EnvironmentsListQuery, EnvironmentsListQueryVariables>({
    query: EnvironmentsListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ 'name' ]
  })

  const openEnvironmentForm = (values?: Environment) => {
    openView({
      title: `${values?.id ? 'Edit' : 'Create'} Environment`,
      component: EnvironmentFormView,
      params: {
        initialValues: {
          ...values
        },
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const columns: Column[] = [
    {
      dataKey: 'name',
      fieldType: 'text-field',
      title: 'Name',
      sortable: true,
      style: { width: 200 },
      renderer: (props) => {
        const LinkRenderer = generateLinkRenderer({
          onClick: () => {
            openView({
              title: 'Environment',
              component: EnvironmentView,
              style: EnvironmentView.defaultStyle,
              params: {
                environmentId: props.rowData.id
              }
            })
          }
        })

        return <LinkRenderer {...props} />
      }
    },
    { dataKey: 'identifier', fieldType: 'text-field', title: 'Identifier', style: { flexGrow: 1 } }
  ]

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: (environment: Environment) => openEnvironmentForm(environment)
    }
  ]

  const dataTableSecondaryElement = (
    <Button icon="add-thin" onClick={() => openEnvironmentForm()} size="small" />
  )

  return (
    <>
      <TitleBlock heading="Environments" />
      <Block direction="column" width={{ md: '100%' }}>
        <HintBox headline="Advanced Feature">
          This feature must be used carefully. Please contact
          {' '}
          <TextLink href="mailto:support@dashx.com">support@dashx.com</TextLink>
          {' '}
          for help.
        </HintBox>
      </Block>
      <DataTableBlock
        actions={actions}
        batchActions={[]}
        columns={columns}
        data={environmentsList as Environment[]}
        defaultOrder={order}
        error={error}
        loading={loading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        primaryElements={<SearchBar placeholder="Search..." onChange={handleChange} />}
        secondaryElements={dataTableSecondaryElement}
        setOrder={setOrder}
        totalRows={environmentsAggregate?.count || 0}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  )
}

export default EnvironmentsPage
