import React from 'react'

import Flex from 'components/layout/Flex'
import SimpleLoader from 'components/loaders/SimpleLoader'
import { styled } from 'styles/stitches'

const StyledAppLoader = styled(Flex, {
  fullscreen: true,
  backgroundColor: 'light400',
  zIndex: 'appScreen'
})

function AppLoader() {
  return (
    <StyledAppLoader alignItems="center" justifyContent="center">
      <SimpleLoader size="large" />
    </StyledAppLoader>
  )
}

export default AppLoader
