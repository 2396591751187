import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import InternalContext from 'components/contexts/InternalContext'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { getRedirectionPath } from 'lib/generateDashboard'
import type { MenuElementFragmentFragment } from 'generated/schema'

function useRedirectToMenuElement() {
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const { currentDashboard } = useContext(InternalContext)!
  const { push, replace } = useHistory()

  return (menuElement: MenuElementFragmentFragment, shouldReplace = false) => {
    const navigate = shouldReplace ? replace : push
    const redirectionPath = getRedirectionPath(menuElement, currentDashboard!, currentWorkspace)
    return redirectionPath ? navigate(redirectionPath) : undefined
  }
}

export default useRedirectToMenuElement
