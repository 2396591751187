import Divider from 'components/divider/Divider'
import { styled } from 'styles/stitches'

const PopoverDivider = styled(Divider, {
  '&::before': {
    backgroundColor: 'light700'
  }
})

PopoverDivider.defaultProps = {
  orientation: 'horizontal'
}

export default PopoverDivider
