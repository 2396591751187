import React from 'react'

import * as mixins from 'styles/mixins'
import Box from 'components/layout/Box'
import Icon from 'components/icons/Icon'
import { styled } from 'styles/stitches'
import type { BoxProps } from 'components/layout/Box'
import type { Rounded } from 'components/form/InputGroup'

type PagerIconButtonProps = BoxProps & {
  className?: string,
  icon: string,
  onClick?: (e: React.MouseEvent) => void | Promise<any>,
  rounded?: Rounded
}

const PAGER_HEIGHT = 40
const PAGER_ICON_BUTTON_PADDING = 12

const StyledPagerButton = styled(Box, {
  background: 'light600',
  borderRadius: 4,
  cursor: 'pointer',
  height: PAGER_HEIGHT,
  padding: PAGER_ICON_BUTTON_PADDING,

  '& [data-icon]': {
    ...mixins.transition('fluid'),

    color: 'dark300'
  },

  '&:hover:not([disabled]) [data-icon], &:focus:not([disabled]) [data-icon]': {
    color: 'dark700'
  },

  '&[disabled]': {
    cursor: 'not-allowed',

    '& [data-icon]': {
      color: 'dark100'
    }
  },

  variants: {
    rounded: {
      all: {},
      left: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      right: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      },
      none: {
        borderRadius: 0
      }
    }
  }
})

const PagerIconButton = React.forwardRef(
  (
    {
      className,

      icon,
      onClick,
      rounded = 'all',

      ...others
    }: PagerIconButtonProps,
    innerRef: typeof others.ref
  ) => (
    <StyledPagerButton
      as="button"
      rounded={rounded}
      className={className}
      onClick={onClick}
      ref={innerRef}
      type="button"
      {...others}
    >
      <Icon data-icon name={icon} size={10} />
    </StyledPagerButton>
  )
)

PagerIconButton.displayName = 'PagerIconButton'

export default PagerIconButton
