import arrayMutators from 'final-form-arrays'
import React from 'react'
import { Form, FormProps, FormSpy } from 'react-final-form'
import { string } from 'yup'
import type { FormApi } from 'final-form'

import AccountTypeModel from 'models/AccountType'
import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import FormValuesField from 'components/form/FormValuesField'
import HintBox from 'components/hints/HintBox'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import Text from 'components/typography/Text'
import ToggleInput from 'components/inputs/ToggleInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { AccountsListDocument, AccountsAggregateDocument, useCreateAccountMutation, useUpdateAccountMutation, AccountKind, GroupsListQuery, GroupMembershipsListQueryVariables, Group, GroupsListDocument, useRoleMembershipsListQuery } from 'generated/schema'
import { Kind } from 'models/Account'
import { RoleMultiSelect } from './AssignRoleView'
import type { AccountsListQueryVariables, CreateAccountInput, UpdateAccountInput } from 'generated/schema'
import type { ViewProps, ViewStyleComponentRenderProps } from 'components/views'

type FormValues = CreateAccountInput | UpdateAccountInput

type Params = {
  initialValues: FormValues,
  kind: AccountKind,
  environmentId: string,
  queryVariables: AccountsListQueryVariables
}

const renderField = AccountTypeModel.renderField()

const SERVICE_ACCOUNT_FIELDS = [ 'name', 'email' ]
const UPDATE_ACCOUNT_FIELDS = [ 'avatar', 'gender', 'date_of_birth', 'phone' ]

const fields = (requiredField: string) => [
  {
    fieldType: 'text-field',
    identifier: 'name',
    isPredefined: true,
    name: 'Name',
    configuration: {
      checkRequired: requiredField === 'name'
    }
  },
  {
    fieldType: 'email-field',
    identifier: 'email',
    isPredefined: true,
    name: 'Email',
    configuration: {
      checkRequired: requiredField === 'email'
    }
  },
  {
    fieldType: 'password-field',
    identifier: 'password',
    isPredefined: true,
    name: 'Password'
  },
  {
    fieldType: 'text-field',
    identifier: 'first_name',
    isPredefined: true,
    name: 'First Name'
  },
  {
    fieldType: 'text-field',
    identifier: 'last_name',
    isPredefined: true,
    name: 'Last Name'
  },
  {
    fieldType: 'file-field',
    identifier: 'avatar',
    isPredefined: true,
    name: 'Avatar',
    showLegacyModal: true,
    configuration: {
      fileTypeCategory: 'image'
    }
  },
  {
    configuration: {
      options: [
        {
          label: 'Female',
          value: 'FEMALE'
        },
        {
          label: 'Male',
          value: 'MALE'
        },
        {
          label: 'Other',
          value: 'OTHER'
        }
      ]
    },
    fieldType: 'dropdown-field',
    identifier: 'gender',
    isPredefined: true,
    name: 'Gender'
  },
  {
    fieldType: 'date-time-field',
    identifier: 'date_of_birth',
    isPredefined: true,
    name: 'Date of Birth'
  },
  {
    fieldType: 'phone-field',
    identifier: 'phone',
    isPredefined: true,
    name: 'Phone'
  }
] as const

const MEMBER_ACCOUNT_FIELDS = fields('email').map((field) => field.identifier !== 'name' && field.identifier).filter(Boolean) as string[]

const Fields = ({ kind, isUpdating = false }: {kind: AccountKind, isUpdating?: boolean}) => {
  const FORM_FIELDS = isUpdating
    ? MEMBER_ACCOUNT_FIELDS
    : MEMBER_ACCOUNT_FIELDS.filter((field) => !UPDATE_ACCOUNT_FIELDS.includes(field))

  const fieldsToRender = (kind === Kind.SERVICE
    ? fields('name').filter((field) => SERVICE_ACCOUNT_FIELDS.includes(field.identifier))
    : fields('email').filter((field) => FORM_FIELDS.includes(field.identifier))
  ).map(renderField)

  return <>{fieldsToRender}</>
}

const SpyingHintBox = () => {
  if (fields.length > 0) return null

  return (
    <FormSpy
      subscription={{ values: true }}
      render={({ values: { hasGuiAccess } }) => {
        // email field will be showing, so no hint box needed
        if (hasGuiAccess) return null

        return (
          <HintBox>
            There are currently no fields.
          </HintBox>
        )
      }}
    />
  )
}

const validate = (values: FormValues) => {
  const { hasGuiAccess, hasApiAccess } = values

  let emailSchema = string().email().nullable(true)
  let nameSchema = string().nullable(true)

  if (hasGuiAccess) {
    emailSchema = emailSchema.required()
  }

  if (hasApiAccess) {
    nameSchema = nameSchema.required()
  }

  const phoneSchema = string().phone().nullable(true)

  return BaseModel.validateSchema(values, {
    name: nameSchema,
    email: emailSchema,
    phone: phoneSchema
  })
}

const Roles = ({ groupIds }: { groupIds: string[] }) => {
  const {
    data: { roleMembershipsList: groupRoleMemberships = [] } = {}
  } = useRoleMembershipsListQuery({
    variables: {
      filter: {
        groupId: { in: groupIds }
      }
    },
    skip: !groupIds.length
  })

  return (
    <Flex direction="column" gap={16}>
      <Text fontWeight="bold">Roles</Text>
      <Flex direction="column" gap={8}>
        <FieldLabel>ASSIGNED VIA GROUPS</FieldLabel>
        {groupRoleMemberships.length > 0 ? groupRoleMemberships.map((membership) => {
          const environmentText = membership.environment ? membership.environment.name : 'All Environment'

          return (
            <Text fontSize={14}>
              <span data-highlight-bold>{membership.role.name}</span>
              {' '} - {' '}
              <span data-highlight-semibold>{environmentText}</span>
              {' '} - via {' '}
              <span data-highlight-semibold>{membership.group?.name}</span>
            </Text>
          )
        }) : (<Text fontSize={14}>No roles assigned via groups.</Text>)}
      </Flex>
      <RoleMultiSelect label="Assigned Directly" fieldName="roleMemberships" />
    </Flex>
  )
}

function AddAccountView({
  onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<Params>) {
  const { initialValues, kind, queryVariables, environmentId } = params
  const isUpdating = 'id' in initialValues

  const [ createAccount ] = useCreateAccountMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      AccountsListDocument,
      AccountsAggregateDocument
    ]
  })

  const handleCreateAccount = useSubmitHandler(createAccount, {
    update: {
      strategy: 'APPEND',
      query: AccountsListDocument,
      dataKey: 'accountsList',
      mutation: 'createAccount',
      queryVariables
    }
  })

  const [ updateAccount ] = useUpdateAccountMutation({ onCompleted: onRequestClose })
  const handleUpdateAccount = useSubmitHandler(updateAccount, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateAccount',
      typename: 'Account',
      override: (values: UpdateAccountInput) => ({
        ...initialFormValues,
        ...values
      })
    }
  })

  const handleCreateAccountFormSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating) {
      return handleUpdateAccount(values as UpdateAccountInput, form as FormApi<UpdateAccountInput>)
    }

    const { roleMemberships, ...rest } = values as CreateAccountInput

    return handleCreateAccount({
      roleMemberships: roleMemberships.map(({ environmentIds, objectIds, id }) => (
        { id, environmentIds: environmentIds || [], objectIds: objectIds || [] }
      )),
      ...rest
    })
  }

  const kindString = kind === Kind.MEMBER ? 'Member' : 'Service'

  const title = isUpdating ? `Edit ${kindString} Account` : `New ${kindString} Account`

  const initialFormValues = {
    environmentId,
    hasGuiAccess: (kind === Kind.MEMBER),
    hasApiAccess: (kind === Kind.SERVICE),
    custom: {},
    ...(!isUpdating && ({
      groupIds: [],
      roleMemberships: []
    })),
    ...initialValues
  }

  const AccountForm = ({
    Header, Body, Footer
  }: ViewStyleComponentRenderProps) => (
    <>
      <Header
        title={title}
        onCloseClick={onRequestClose}
      />
      <Form
        mutators={{
          ...arrayMutators
        }}
        validate={validate}
        onSubmit={handleCreateAccountFormSubmit}
        initialValues={initialFormValues as FormValues}
        keepDirtyOnReinitialize
        subscription={{ submitting: true, values: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine, values }) => (
          <>
            <Body>
              <Flex as="form" onSubmit={handleSubmit} direction="column" gap={24}>
                <FormField name="position" component="input" type="hidden" />

                <Flex direction="column" gap={20}>
                  <SpyingHintBox />
                  <FormValuesField fieldNames={[ 'kind' ]}>
                    {({ kind }) => (
                      <>
                        <Fields kind={kind} isUpdating={isUpdating} />
                        {!isUpdating && (
                          <SearchSelectField<GroupsListQuery, GroupMembershipsListQueryVariables>
                            isSearchable
                            preload
                            isMulti
                            name="groupIds"
                            label="Groups"
                            prependIcon="search"
                            placeholder="Start typing to search"
                            size="small"
                            variant="light"
                            labelKey="name"
                            metaKey="identifier"
                            valueKey="id"
                            options={[]}
                            isOptionDisabled={(option: Group) => {
                              (values as CreateAccountInput).groupIds.includes(option.id)
                            }}
                            getOptionLabel={(option: Group) => option.name}
                            query={GroupsListDocument}
                            queryOptions={{
                              variables: {
                                filter: {
                                  accountKind: { eq: kind }
                                }
                              }
                            }}
                            dataKey="groupsList"
                            keys={[ 'name' ]}
                          />
                        )}
                      </>
                    )}
                  </FormValuesField>
                </Flex>

                <FormValuesField fieldNames={[ 'kind' ]}>
                  {({ kind }) => (
                    <>
                      {[ Kind.SERVICE, Kind.MEMBER ].includes(kind) && (
                        <Flex gap={16} direction="column">
                          <Text fontWeight="bold">Manage Access</Text>
                          {kind === Kind.MEMBER && (
                            <FormField
                              component={ToggleInput}
                              label="Login Access?"
                              helpText="Allow this account to log into this Workspace. For Team members or external members that need login. Requires email address."
                              labelPosition="left"
                              name="hasGuiAccess"
                              type="checkbox"
                            />
                          )}
                          {kind === Kind.SERVICE && (
                            <FormField
                              component={ToggleInput}
                              label="API Access?"
                              helpText="Allow this account programmatic access using API Keys. For Bots, Services, Applications, and some daring humans."
                              labelPosition="left"
                              name="hasApiAccess"
                              type="checkbox"
                            />
                          )}
                        </Flex>
                      )}
                    </>
                  )}
                </FormValuesField>
                {!isUpdating && (
                  <FormValuesField fieldNames={[ 'hasGuiAccess', 'hasApiAccess', 'groupIds' ]}>
                    {({ hasGuiAccess, hasApiAccess, groupIds }) => {
                      if (kind === Kind.MEMBER && !hasGuiAccess) return null
                      if (kind === Kind.SERVICE && !hasApiAccess) return null

                      return (
                        <Roles groupIds={groupIds} />
                      )
                    }}
                  </FormValuesField>
                )}
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </Body>
            <Footer>
              <Flex alignItems="center" direction="row-reverse" grow={1} justifyContent="space-between">
                <Flex gap={14}>
                  <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                </Flex>
              </Flex>
            </Footer>
          </>
        )}
      />
    </>
  )

  return (
    <View contentLabel="Add Account" onRequestClose={onRequestClose} {...other}>
      {AccountForm}
    </View>
  )
}

AddAccountView.defaultStyle = 'PANEL' as const

export default AddAccountView
