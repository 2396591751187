import React, { PropsWithChildren, forwardRef } from 'react'

import Box from 'components/layout/Box'
import SelectInput, { SelectInputProps } from 'components/inputs/SelectInput'
import { colorVars } from 'styles/theme'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'
import type { Environment } from 'generated/schema'

interface SwitcherProps {
  appId: string | null,
  appCategoryId?: string,
  disabled?: boolean,
  wrapper?: React.FC<PropsWithChildren<{}>>
}

type Props = SwitcherProps & SwitcherResultProps & Omit<SelectInputProps<any>, 'input' | 'meta'>

const Wrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => <Box style={{ minWidth: '300px' }}>{children}</Box>

const EnvironmentSwitcher = forwardRef<HTMLInputElement, Props>(({
  name,
  label = 'Environment',
  size = 'small',
  onChange,
  value,
  disabled,
  appCategoryId,
  appId,
  wrapper = Wrapper,

  switcher,
  setSwitcher,
  environmentsList = [],

  setValueAsObject = true,

  ...selectProps
}, ref) => {
  const WrapperComponent = wrapper || Wrapper

  return (
    <WrapperComponent>
      <SelectInput
        // TODO: Discuss
        // isClearable
        isSearchable
        ref={ref}
        isDisabled={disabled}
        isLoading={!!appId && !environmentsList.length}
        name={name}
        label={label}
        size={size}
        prependIcon="search"
        placeholder="Select an environment..."
        options={environmentsList}
        labelKey="name"
        valueKey="id"
        setValueAsObject={setValueAsObject}
        noOptionsMessage={() => 'No environments found. Please configure this app.'}
        variant="light"
        inputIconKey="isLive"
        inputIconColor={(option: Environment) => (option.isLive
          ? colorVars.primary300
          : colorVars.dark300)}
        input={{
          value: value || switcher?.data?.environment,
          onChange: (env: Environment) => {
            onChange?.(env.id)
            if (!disabled && env) {
              setSwitcher({
                id: appCategoryId ?? appId,
                environment: env,
                installation: appId && switcher?.data?.installation
                  ? switcher?.data.installation
                  : null
              })
            }
          }
        }}
        {...selectProps}
      />
    </WrapperComponent>
  )
})

export type { Props as EnvironmentSwitcherProp, SwitcherProps }

export default EnvironmentSwitcher
