import React from 'react'

import type { WorkspaceContextQuery, ThemePalette } from 'generated/schema'

export type WorkspaceContext = {
  changeThemePalette: (palette: ThemePalette) => void,
  currentWorkspace: WorkspaceContextQuery['workspace'],
  resetThemePaletteRef: React.MutableRefObject<() => void>
} | null

export default React.createContext<WorkspaceContext>(null)
