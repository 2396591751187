import React, { useContext, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import CreateAppView from 'components/views/CreateAppView'
import Flex from 'components/layout/Flex'
import InternalContext from 'components/contexts/InternalContext'
import PageLoader from 'components/loaders/PageLoader'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TitleBlock from 'components/blocks/TitleBlock'
import useQueryParams from 'hooks/useQueryParams'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { About, Access, Graph, Integrations, Overview } from 'components/views/InstallationView'
import { APP_LIST_LIMIT } from 'models/App'
import { useInstallationsListQuery, useAppQuery, Installation, App, useCreateInstallationMutation, DashboardsListDocument, InstallationsListDocument, CreateInstallationInput, useMenuElementsListQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { ComputedMenuElement } from 'lib/generateDashboard'

type LocationState = { app?: App, installation?: Installation }

function AppPage() {
  const queryParams = useQueryParams()
  const location = useLocation<LocationState>()
  const { idToMenuElementMap = {} } = useContext(InternalContext)!
  const { openView } = useViewDispatch()
  const { pathname, search } = location
  const { appId } = useParams<{ appId: string }>()
  const id = queryParams.get('id') || appId
  const queryVariables = {
    filter: {
      appId: { eq: id }
    }
  }

  const {
    data: { app } = {},
    loading,
    error
  } = useAppQuery({
    variables: {
      id
    }
  })

  const {
    data: { installationsList = [] } = {},
    loading: installationsListLoading,
    error: installationsListError
  } = useInstallationsListQuery({
    variables: queryVariables
  })

  const refetchQueries = [
    { query: DashboardsListDocument },
    { query: InstallationsListDocument, variables: queryVariables }
  ]

  const installation: Installation | undefined = useMemo(
    () => (installationsList as Installation[]).find((inst) => inst.appId === id),
    [ id, installationsList ]
  )

  const {
    data: { menuElementsList = [] } = {}
  } = useMenuElementsListQuery({
    variables: {
      filter: {
        viewUrn: {
          contains: `app::${id}`
        }
      },
      order: [ { position: 'asc' } ]
    },
    skip: !app
  })

  const childMenuElements = useMemo(() => (
    menuElementsList.filter((menuElement) => menuElement.parentId !== null)
  ), [ menuElementsList ])

  const menuElement: ComputedMenuElement = useMemo(() => {
    const menuElementMap = childMenuElements.filter((childMenuElement) => {
      if (idToMenuElementMap[childMenuElement.id]) return true
      return false
    })
    return idToMenuElementMap[menuElementMap[0]?.id]
  }, [ childMenuElements, idToMenuElementMap ])

  const isInstalled = Boolean(installation)
  const title = app?.name || 'App'

  const [ installApp, { loading: submitting } ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: DashboardsListDocument },
      { query: InstallationsListDocument, variables: queryVariables }
    ]
  })

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'App was installed successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput
  ) => handleInstallApp(values as CreateInstallationInput)

  const onInstallApp = () => {
    handleSubmit({ appId: app?.id, name: app?.name || '', identifier: app?.identifier || '' })
  }

  const isCustomApp = !!app?.workspaceId
  const documentationLink = isCustomApp ? 'https://docs.dashx.com/platform/apps/custom-apps' : `https://docs.dashx.com/platform/apps/${app?.identifier}`
  const hasIntegrations = app && !isCustomApp
    && app.meta.integrationCategoryIds.length > 0
  const isProject = app?.kind === 'PROJECT'

  const onEditApp = () => {
    if (installation) {
      openView({
        title: `Edit ${installation.app.kind === 'PROJECT' ? 'Project' : 'App'}`,
        component: CreateAppView,
        params: {
          initialValues: installation.app,
          queryVariables: {
            limit: APP_LIST_LIMIT,
            order: [ { position: 'asc' } ]
          }
        },
        style: 'PANEL'
      })
    }
  }

  return (
    <PageLoader
      data={app}
      loading={loading || installationsListLoading}
      error={error || installationsListError}
    >
      <TitleBlock
        heading={title}
        hideLastCrumb={false}
        appendStickyCrumb={{
          name: title,
          isActive: true,
          fullPath: pathname + search
        }}
        secondaryElements={(
          <Flex gap={8}>
            <Button label="View Documentation" variant="outline" mode="distinct" size="small" rel="noopener noreferrer" target="_blank" href={documentationLink} />
            {(isProject || isCustomApp) && <Button label={`Edit ${isProject ? 'Project' : 'App'}`} variant="outline" description="Settings" size="small" onClick={onEditApp} />}
            {menuElement && <Button label="Jump to App" size="small" to={menuElement.fullPath} />}
            {!isInstalled && <Button disabled={submitting} label="Install App" size="small" onClick={onInstallApp} />}
          </Flex>
        )}
      />
      <Block direction="column" gap={36} width={{ md: '100%' }}>
        <Tabs>
          {isInstalled ? (
            <>
              <Tab index={0} label="Overview">
                <Overview
                  installation={installation!}
                />
              </Tab>
              {hasIntegrations && (
                <Tab index={1} label="Integrations">
                  <Integrations app={app!} />
                </Tab>
              )}
              <Tab index={hasIntegrations ? 2 : 1} label="Graph">
                <Graph app={app as App} />
              </Tab>
              <Tab index={hasIntegrations ? 3 : 2} label="Access">
                <Access installation={installation!} />
              </Tab>
              {app && (
                <Tab index={hasIntegrations ? 4 : 3} label="About">
                  <About
                    app={app}
                    installation={installation}
                    refetchQueries={refetchQueries}
                    queryVariables={queryVariables}
                  />
                </Tab>
              )}
            </>
          ) : (
            <>
              {app && (
                <Tab index={0} label="About">
                  <About
                    app={app}
                    installation={installation}
                    refetchQueries={refetchQueries}
                    queryVariables={queryVariables}
                  />
                </Tab>
              )}
            </>
          )}
        </Tabs>
      </Block>
    </PageLoader>
  )
}

export default AppPage
