import React from 'react'
import { useRecoilValue } from 'recoil'

import DetailsBlock from 'components/blocks/DetailsBlock'
import JSONParseOr from 'lib/JSONParseOr'
import useDashboard from 'hooks/useDashboard'
import { safeParseLiquid } from 'lib/templater'
import type { BlockProps } from '../Block'

const DetailsBlockWrapper: React.FC<BlockProps> = ({ block, ...props }) => {
  const settings = block?.properties?.data_source_settings
  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)

  return (
    <DetailsBlock
      {...props}
      recordId={JSONParseOr(safeParseLiquid(settings?.record || '', blockProperties))}
      resourceId={settings?.resource}
      operationId={settings?.operation}
      parameters={settings?.parameters}
      {...block.properties}
    />
  )
}

export default DetailsBlockWrapper
