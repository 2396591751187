import React, { memo, useCallback, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import { alertVar } from 'client/state/alert'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'

const ALERT_ICON_SIZE = 22
const ALERT_WIDTH = 450
const CLOSE_ICON_SIZE = 10
const CLOSE_TIMEOUT_IN_MS = 5000

const DEFAULTS = {
  success: {
    icon: 'check-circle',
    title: 'Success!',
    message: 'It worked.'
  },
  failure: {
    icon: 'cross-circle',
    title: 'Uh oh!',
    message: 'Something went wrong.'
  },
  warning: {
    icon: 'alert',
    title: 'Uh oh!',
    message: 'Something went wrong.'
  }
} as const

const StyledAlertContainer = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 'alert'
})

const StyledAlert = styled('div', {
  ...mixins.transition('simple'),

  backgroundColor: '#fff',
  borderRadius: 4,
  borderStyle: 'solid',
  borderWidth: 1,
  maxWidth: '100%',
  opacity: 0,
  overflow: 'hidden',
  paddingY: 20,
  paddingX: 24,
  pointerEvents: 'none',
  position: 'fixed',
  left: '50%',
  bottom: 0,
  transform: 'translateX(-50%)',
  width: ALERT_WIDTH,

  variants: {
    visible: {
      true: {
        bottom: 30,
        opacity: 1,
        pointerEvents: 'auto'
      }
    },
    variant: {
      success: {
        ...mixins.shadow('medium', colorVars.positive300rgb, 0.3),

        borderColor: 'positive100'
      },
      failure: {
        ...mixins.shadow('medium', colorVars.negative300rgb, 0.3),

        borderColor: 'negative200'
      },
      warning: {
        ...mixins.shadow('medium', colorVars.warning300rgb, 0.3),

        borderColor: 'warning200'
      }
    }
  }
})

const StyledIcon = styled(Icon, {
  variants: {
    variant: {
      success: {
        color: 'positive300'
      },
      failure: {
        color: 'negative500'
      },
      warning: {
        color: 'warning500'
      }
    }
  }
})

const StyledDismiss = styled('div', {
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
})

const StyledCloseIcon = styled('div', {
  ...mixins.transition('fluid'),

  color: 'dark700',
  cursor: 'pointer',
  opacity: 0.3,
  zIndex: 'above', // Makes Close button clickable

  '&:hover, &:focus': {
    opacity: 1
  }
})

const messageColorMap = {
  success: 'positive400',
  failure: 'negative400',
  warning: 'warning400'
} as const

function Alert() {
  const alert = useReactiveVar(alertVar)
  const { preventAutoClose = false, icon, title, message, variant, isOpen } = alert
  const messageColor = messageColorMap[variant]

  const closeAlert = useCallback(() => {
    alertVar({ ...alert, isOpen: false })
  }, [ alert ])

  useEffect(() => {
    let closeTimer: ReturnType<typeof setTimeout>

    if (isOpen && !preventAutoClose) {
      closeTimer = setTimeout(closeAlert, CLOSE_TIMEOUT_IN_MS)
    }

    return () => {
      if (closeTimer) {
        clearTimeout(closeTimer)
      }
    }
  }, [ isOpen, closeAlert, preventAutoClose ])

  return (
    <StyledAlertContainer>
      <StyledAlert
        variant={variant}
        visible={isOpen}
        role="alert"
      >
        <StyledDismiss onClick={closeAlert} role="presentation" />

        <Flex alignItems="flex-start" gap={24} justifyContent="space-between">
          <Flex gap={16}>
            <StyledIcon
              variant={variant}
              name={icon || DEFAULTS[variant].icon}
              size={ALERT_ICON_SIZE}
            />

            <Flex direction="column" gap={8}>
              <Text fontSize={18} fontWeight="bold">
                {title || DEFAULTS[variant].title}
              </Text>

              {message && (
                <Text color={messageColor} fontSize={14} lineHeight="cozy">
                  {message || DEFAULTS[variant].message}
                </Text>
              )}
            </Flex>
          </Flex>

          <StyledCloseIcon
            aria-label="Close"
            onClick={closeAlert}
            onKeyPress={closeAlert}
            role="button"
            tabIndex={-1}
          >
            <Icon name="cross" size={CLOSE_ICON_SIZE} />
          </StyledCloseIcon>
        </Flex>
      </StyledAlert>
    </StyledAlertContainer>
  )
}

export { StyledAlertContainer, StyledCloseIcon, StyledIcon, StyledAlert }

export default memo(Alert)
