import React from 'react'
import { Field } from 'react-final-form'
import type { FieldProps } from 'react-final-form'

import SelectInput from 'components/inputs/SelectInput'
import { useCurrenciesQuery } from 'generated/schema'

type CurrencyFieldProps = FieldProps<string, any, HTMLSelectElement>

function CurrencyField(props: CurrencyFieldProps) {
  const { data: { currencies = [] } = {} } = useCurrenciesQuery({
    fetchPolicy: 'cache-first'
  })

  const options = currencies.map(({ code, name, symbol }) => ({
    value: code,
    label: `${code} (${name})`,
    meta: symbol
  }))

  const customFilter = (option: typeof options[number], search: string) => (
    option.label.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Field
      component={SelectInput}
      filterOption={customFilter}
      isVirtual
      options={options}
      {...props}
    />
  )
}

export default CurrencyField
