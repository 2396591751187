import React, { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import BaseLink from 'components/links/BaseLink'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import TopbarItem from 'components/topbar/TopbarItem'
import TopbarMenu from 'components/topbar/TopbarMenu'
import useDashboard from 'hooks/useDashboard'
import { getDefaultIcon } from 'lib/generateDashboard'
import { PopoverContainer } from 'components/popover'
import { Views } from 'components/dashboardEditor/constants'
import type useLongPress from 'hooks/useLongPress'
import type { BaseLinkProps } from 'components/links/BaseLink'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { PopoverToggleProps } from 'components/popover'

type CommonProps = {
  isDragging?: boolean,
  menuElement: ComputedMenuElement,
  popoverToggleProps?: PopoverToggleProps,
  onMenuElementClick?: () => void,
  longPressProps?: ReturnType<typeof useLongPress>
}

type TopbarElementProps = CommonProps & {
  isOver?: boolean
}

type TopbarItemProps = CommonProps

type TopbarLinkItemProps = BaseLinkProps & CommonProps & {
  className?: string
}

const OverHandler = ({ isOver, openPopover }: any) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      openPopover()
    }, 1000)

    if (!isOver) {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
  }, [ isOver, openPopover ])
  return null
}

const TopbarSubmenuItem = ({
  isOver = false,
  isDragging = false,
  menuElement,
  popoverToggleProps: contextMenuPopoverToggleProps,
  longPressProps,
  onMenuElementClick
}: TopbarElementProps) => {
  const { pathname } = useLocation()

  const isSubMenuItemActive = matchPath(pathname, { path: menuElement.fullPath! })

  return (
    <PopoverContainer>
      {({ closePopover, openPopover, ...popoverToggleProps }) => (
        <TopbarItem
          {...popoverToggleProps}
          {...longPressProps}
          isActive={
            !!isSubMenuItemActive
            || popoverToggleProps.isActive
            || contextMenuPopoverToggleProps?.isActive
          }
          isDragging={isDragging}

          onContextMenu={(e: React.MouseEvent) => {
            if (popoverToggleProps.isActive) {
              closePopover()
            }

            if (contextMenuPopoverToggleProps && 'onContextMenu' in contextMenuPopoverToggleProps) {
              contextMenuPopoverToggleProps.onContextMenu(e)
            }
          }}
          onClick={(e: React.MouseEvent) => {
            if (onMenuElementClick) onMenuElementClick()
            else {
              if (contextMenuPopoverToggleProps?.isActive) {
                contextMenuPopoverToggleProps.closePopover()
              }

              if ('onClick' in popoverToggleProps) {
                popoverToggleProps.onClick(e)
              }
            }
          }}
          onKeyDown={(e: React.KeyboardEvent<Element>) => {
            contextMenuPopoverToggleProps?.onKeyDown(e)
            popoverToggleProps.onKeyDown(e)
          }}
        >
          <Icon
            name={menuElement.renderedIcon || getDefaultIcon(menuElement)}
            size={20}
            data-icon
          />
          <OverHandler isOver={isOver} openPopover={openPopover} />
        </TopbarItem>
      )}
      {(popoverProps) => (
        <TopbarMenu
          isActive={!!isSubMenuItemActive}
          rootMenuElement={menuElement}
          withArrow
          {...popoverProps}
        />
      )}
    </PopoverContainer>
  )
}

const TopbarLinkItem = ({
  isDragging = false, menuElement, popoverToggleProps, longPressProps, onMenuElementClick
}: TopbarLinkItemProps) => {
  const { fullPath, renderedIcon, target, url } = menuElement
  const props: BaseLinkProps = {}

  const { dashboardEditorState } = useDashboard()
  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT

  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  if (target === 'URL') {
    props.href = url
  } else {
    props.to = fullPath
    props.exact = true
  }

  return (
    <TopbarItem
      as={isInspecting ? Flex : BaseLink}
      isActive={isActive}
      isDragging={isDragging}
      {...contextMenuProps}
      {...longPressProps}
      {...(isInspecting ? {} : props)}
      onClick={onMenuElementClick}
    >
      <Icon name={renderedIcon || getDefaultIcon(menuElement)} size={20} data-icon />
    </TopbarItem>
  )
}

const TopbarDividerItem = ({
  isDragging, menuElement, popoverToggleProps, longPressProps, onMenuElementClick
}: TopbarItemProps) => {
  const {
    ref, isActive, closePopover, openPopover, ...contextMenuProps
  } = popoverToggleProps || {}

  if (menuElement.separatorStyle === 'RULER') {
    return (
      <TopbarItem
        isDragging={isDragging}
        {...contextMenuProps}
        onClick={onMenuElementClick}
      >
        <Divider key={menuElement.id} orientation="vertical" {...longPressProps} />
      </TopbarItem>
    )
  }

  return (
    <TopbarItem
      isDragging={isDragging}
      {...longPressProps}
    >
      <Divider key={menuElement.id} orientation="vertical" variant="whitespace" {...longPressProps} />
    </TopbarItem>
  )
}

function TopbarElement({
  menuElement, isDragging, isOver, popoverToggleProps, longPressProps, onMenuElementClick
}: TopbarElementProps) {
  if (menuElement.kind === 'ITEM') {
    if (menuElement.target === 'SUBMENU') {
      return (
        <TopbarSubmenuItem
          isDragging={isDragging}
          isOver={isOver}
          menuElement={menuElement}
          popoverToggleProps={popoverToggleProps}
          longPressProps={longPressProps}
          onMenuElementClick={onMenuElementClick}
        />
      )
    }

    if (menuElement.target === 'URL' || menuElement.fullPath) {
      return (
        <TopbarLinkItem
          isDragging={isDragging}
          menuElement={menuElement}
          popoverToggleProps={popoverToggleProps}
          longPressProps={longPressProps}
          onMenuElementClick={onMenuElementClick}
        />
      )
    }
  }

  if (menuElement.kind === 'SEPARATOR') {
    return (
      <TopbarDividerItem
        isDragging={isDragging}
        menuElement={menuElement}
        popoverToggleProps={popoverToggleProps}
        longPressProps={longPressProps}
        onMenuElementClick={onMenuElementClick}
      />
    )
  }

  return null
}

export default TopbarElement
