import React from 'react'

import FormField from 'components/form/FormField'
import RadioInput from 'components/inputs/RadioInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { RadioInputProps } from 'components/inputs/RadioInput'

type RadioFieldProps = Omit<RadioInputProps, 'input' | 'meta'> & fieldProps<'radio'>

const RadioField = ({ name, ...others }: RadioFieldProps) => (
  <FormField
    component={RadioInput}
    name={name}
    type="radio"
    {...others}
  />
)

export default RadioField
