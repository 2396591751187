import composeRefs from '@seznam/compose-react-refs'
import React, { forwardRef, lazy, Suspense, useRef, useState } from 'react'
import { Field } from 'react-final-form'
import { useRect } from '@reach/rect'
import type { FieldRenderProps } from 'react-final-form'
import type { ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import componentLoader from 'lib/componentLoader'
import EmojiPicker from './EmojiPicker'
import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { INDICATOR_HEIGHT } from 'components/passwordStrength/constants'

const ADDON_ICON_SIZE = 16
const ADDON_TEXT_FONT_SIZE = 14
const INPUT_BORDER_RADIUS = 4
const INPUT_BORDER_WIDTH = 1
const INPUT_FONT_SIZE = 14

const INPUT_HEIGHT: { [key: string]: number } = {
  large: 80,
  normal: 60,
  small: 40
}

const INPUT_PADDING_X: { [key: string]: number } = {
  large: 30,
  normal: 20,
  small: 12
}

type StyledInputProps = StyledProps<typeof StyledInput>

type TextInputProps = FieldRenderProps<string, HTMLInputElement> & StyledInputProps & {
  appendIcon?: string,
  appendNode?: ReactNode,
  appendText?: string,
  helpText?: string,
  inline?: boolean,
  isTranslatable?: boolean,
  prependIcon?: string,
  prependNode?: ReactNode,
  prependText?: string,
  type?: string,
  withStrengthIndicator?: boolean,
  disableEmojis?: boolean
}

const sizes = [ 'small', 'normal', 'large' ] as const

const inputSizeVariants = sizes.reduce((acc, size) => ({
  ...acc,
  [size]: {
    height: INPUT_HEIGHT[size],
    paddingLeft: INPUT_PADDING_X[size],
    paddingRight: INPUT_PADDING_X[size]
  }
}), {} as Record<typeof sizes[number], Record<string, string | number>>)

const containerSizeVariants = sizes.reduce((acc, size) => ({
  ...acc,
  [size]: {
    '& [data-addon]': {
      paddingLeft: INPUT_PADDING_X[size],
      paddingRight: INPUT_PADDING_X[size]
    }
  }
}), {} as Record<typeof sizes[number], Record<string, string | number>>)

const wrapper = css({ width: '100%' })

const StyledContainer = styled('div', {
  position: 'relative',

  'fieldset[disabled] &': {
    '& [data-addon]': {
      pointerEvents: 'none'
    }
  },

  '& [data-addon]': {
    bottom: INPUT_BORDER_WIDTH,
    color: 'dark500',
    position: 'absolute',
    top: INPUT_BORDER_WIDTH,
    userSelect: 'none'
  },

  '& [data-addon="prepend"]': {
    left: INPUT_BORDER_WIDTH
  },

  '& [data-addon="append"]': {
    right: INPUT_BORDER_WIDTH
  },

  '& [data-icon]': {
    ...mixins.transition('fluid')
  },

  '& [data-text]': {
    ...mixins.transition('fluid')
  },

  variants: {
    variant: {
      light: {},
      dark: {}
    },

    hasError: {
      error: {
        '&[addon]': {
          color: 'negative500'
        },
        '& [data-icon]': {
          color: 'negative500'
        },

        '& [data-text]': {
          ...mixins.transition('fluid'),

          color: 'negative500'
        },

        '&:hover [data-icon]': {
          color: 'negative500'
        },

        '&:focus-within [data-icon]': {
          color: 'negative500'
        },

        '&:hover [data-text]': {
          color: 'negative500'
        },

        '&:focus-within [data-text]': {
          color: 'negative500'
        }
      },
      none: {
        '& [data-icon]': {
          color: 'dark100'
        },

        '& [data-text]': {
          ...mixins.transition('fluid'),

          color: 'dark500'
        },

        '&:hover:not([disabled]) [data-icon]': {
          color: 'dark300'
        },

        '&:focus-within:not([disabled]) [data-icon]': {
          color: 'dark300'
        },

        '&:hover:not([disabled]) [data-text]': {
          color: 'dark900'
        },

        '&:focus-within:not([disabled]) [data-text]': {
          color: 'dark900'
        }
      }
    },
    display: {
      inline: {
        display: 'inline-block'
      },
      normal: {}
    },
    size: containerSizeVariants
  }
})

const StyledInput = styled('input', {
  ...mixins.transition('fluid'),

  fontFamily: 'normal',
  fontSize: INPUT_FONT_SIZE,
  fontWeight: 'regular',
  lineHeight: 'normal',
  width: '100%',

  '&::placeholder': {
    fontWeight: 'regular'
  },

  '&:read-only': {
    userSelect: 'none'
  },

  '&[disabled]': {
    backgroundColor: 'light400',
    borderColor: 'dark100',
    color: 'dark500'
  },

  'fieldset[disabled] &&': {
    backgroundColor: 'light400',
    borderColor: 'dark100',
    color: 'dark500',

    '&:hover': {
      borderColor: 'dark100'
    }
  },

  variants: {
    size: inputSizeVariants,
    hasError: {
      none: {
        color: 'dark900',

        '&::placeholder': {
          color: 'dark500',
          fontWeight: 'regular'
        },

        '&:focus': {
          '&::placeholder': {
            color: rgba(colorVars.dark500rgb, 0.3)
          }
        }
      },
      error: {
        '&&': {
          backgroundColor: 'negative100',
          borderColor: 'negative300',
          color: 'negative500',

          '&::placeholder': {
            color: 'negative400',
            fontWeight: 'regular'
          },

          '&:hover:not([disabled])': {
            borderColor: 'negative300'
          },

          '&:focus:not([disabled])': {
            borderColor: 'negative300',
            '&::placeholder': {
              color: rgba(colorVars.negative500rgb, 0.3)
            }
          }
        }
      }
    },
    variant: {
      light: {
        backgroundColor: 'light100',
        borderColor: 'dark100',

        '&:hover:not([disabled])': {
          borderColor: 'dark300'
        },
        '&:focus:not([disabled])': {
          borderColor: 'dark300'
        }
      },
      dark: {
        backgroundColor: 'light600',
        borderColor: 'light600',

        '&:hover:not([disabled])': {
          borderColor: 'dark300'
        },
        '&:focus:not([disabled])': {
          borderColor: 'dark300'
        }
      }
    },
    rounded: {
      all: {
        borderRadius: INPUT_BORDER_RADIUS
      },
      left: {
        borderTopLeftRadius: INPUT_BORDER_RADIUS,
        borderBottomLeftRadius: INPUT_BORDER_RADIUS
      },
      right: {
        borderTopRightRadius: INPUT_BORDER_RADIUS,
        borderBottomRightRadius: INPUT_BORDER_RADIUS
      },
      none: {
        borderRadius: 0
      }
    },
    border: {
      solid: {
        borderWidth: INPUT_BORDER_WIDTH,
        borderStyle: 'solid'
      },
      shadow: {
        ...mixins.shadow('xLarge', colorVars.dark1000rgb, 0.1),

        borderStyle: 'none'
      }
    }
  }
})

const StyledStrengthIndicatorPlaceholder = styled(Flex, {
  height: INDICATOR_HEIGHT,
  width: '100%'
})

StyledInput.defaultProps = {
  border: 'solid',
  rounded: 'all',
  type: 'text',
  variant: 'light'
}

StyledContainer.defaultProps = {
  variant: 'light'
}

const PasswordStrength = lazy(() => componentLoader('passwordStrength/PasswordStrength'))

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  appendIcon,
  appendNode,
  appendText,
  helpText,
  inline = false,
  input,
  isTranslatable,
  label,
  meta,
  prependIcon,
  prependNode,
  prependText,
  checkRequired = false,
  size = 'normal',
  type = 'text',
  disableEmojis = false,
  withStrengthIndicator = false,
  ...other
}, innerRef) => {
  const hasAppendEl = Boolean(appendIcon || appendText || appendNode)
  const hasPrependEl = Boolean(prependIcon || prependText || prependNode)
  const appendEl = useRef<HTMLDivElement>(null)
  const prependEl = useRef<HTMLDivElement>(null)
  const appendRect = useRect(appendEl, { observe: hasAppendEl })
  const prependRect = useRect(prependEl, { observe: hasPrependEl })

  const hasError = !!FieldError.getError(meta)

  const [ referenceElement, setReferenceElement ] = useState<HTMLElement | null>(null)

  const renderAddon = (
    position: 'prepend' | 'append', icon?: string, text?: string, node?: ReactNode
  ) => {
    if (!icon && !text && !node) {
      return null
    }

    const addonContent = icon
      ? <Icon data-icon name={icon} size={ADDON_ICON_SIZE} />
      : <Text data-text color="dark500" fontSize={ADDON_TEXT_FONT_SIZE} lineHeight="normal">{text}</Text>

    return (
      <Flex
        alignItems="center"
        data-addon={position}
        ref={position === 'prepend' ? prependEl : appendEl}
        gap={8}
      >
        {addonContent}{node}
      </Flex>
    )
  }

  const prepend = renderAddon('prepend', prependIcon, prependText, prependNode)
  const append = renderAddon('append', appendIcon, appendText, appendNode)

  const isEmojiPickerEnabled = type === 'text' && !disableEmojis

  return (
    <Flex as="label" className={wrapper} direction="column" gap={10} grow={1}>
      {label && (
        <FieldLabel isTranslatable={isTranslatable} checkRequired={checkRequired}>
          {label}
        </FieldLabel>
      )}
      <StyledContainer
        display={inline ? 'inline' : 'normal'}
        hasError={hasError ? 'error' : 'none'}
        size={size}
        variant={other.variant}
      >
        {prepend}
        <StyledInput
          autoComplete="off"
          hasError={hasError ? 'error' : 'none'}
          ref={composeRefs(innerRef, setReferenceElement)}
          size={size}
          style={{
            paddingLeft: hasPrependEl && prependRect?.width,
            paddingRight: hasAppendEl && appendRect?.width
          }}
          type={type}
          {...input}
          {...other}
        />
        {append}
        <FieldError error={FieldError.getError(meta)} />
      </StyledContainer>
      {isEmojiPickerEnabled && (
        <EmojiPicker
          referenceElement={referenceElement}
          onChange={input?.onChange}
          value={input?.value}
        />
      )}
      {helpText && (<InputHelpText helpText={helpText} />)}
      {withStrengthIndicator && (
        <Field
          name={`${input.name}Strength`}
          render={(props) => (
            <Suspense fallback={<StyledStrengthIndicatorPlaceholder />}>
              <PasswordStrength password={input.value} {...props} />
            </Suspense>
          )}
        />
      )}
    </Flex>
  )
})

TextInput.displayName = 'TextInput'

export default TextInput

export { INPUT_HEIGHT, INPUT_PADDING_X, INPUT_FONT_SIZE, INPUT_BORDER_WIDTH }

export type { TextInputProps }
