import React from 'react'

import AddOperationView from 'components/views/AddOperationView'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataListBlock from 'components/blocks/DataListBlock'
import Flex from 'components/layout/Flex'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import parseError from 'lib/parseError'
import Text from 'components/typography/Text'
import useReorder from 'hooks/useReorder'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { BehaviorKind, BehaviorMethod, Kind as OperationKind } from 'models/Operation'
import { Operation, Resource, useOperationsListQuery, Installation, useUpdateOperationMutation, OperationsListDocument } from 'generated/schema'
import { OPERATIONS_LIST_LIMIT } from 'models/Resource'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'

const DEFAULT_TITLE = 'There are no operations.'
const DEFAULT_SUBTITLE = 'Operations allow you to act upon resources.'
const DEFAULT_ERROR_SUBTITLE = 'Please contact support if the problem persists.'

const OperationsList = ({ resource, appId }: { resource: Resource, appId: Installation['appId'] }) => {
  const resourceId = resource.id
  const { openView } = useViewDispatch()
  const { isReadOnly } = resource

  const queryVariables = {
    filter: {
      resourceId: { eq: resourceId },
      appId: appId ? { eq: appId } : undefined
    },
    order: [ {
      position: 'asc'
    } ],
    limit: OPERATIONS_LIST_LIMIT
  }

  const {
    data: { operationsList = [] } = {},
    loading,
    error
  } = useOperationsListQuery({
    variables: queryVariables,
    skip: !resourceId
  })

  const [ updateOpertaion ] = useUpdateOperationMutation()
  const handleUpdateOpertaion = useSubmitHandler(updateOpertaion)

  const reorder = useReorder({
    query: OperationsListDocument,
    variables: queryVariables,
    dataKey: 'opertaionsList',
    callback: handleUpdateOpertaion
  })

  const onAdd = () => openView({
    title: 'Add Operation',
    component: AddOperationView,
    params: {
      initialValues: {
        name: '',
        identifier: '',
        resourceId,
        appId,
        graphqlKind: OperationKind.QUERY,
        behaviorMethod: BehaviorMethod.AGGREGATE,
        behaviorKind: BehaviorKind.DATABASE_QUERY,
        behaviorSettings: {},
        position: generatePosition(getMaxPosition(operationsList as any)),
        parameters: []
      },
      resourceId,
      queryVariables
    },
    style: 'PANEL'
  })

  const onEdit = (operation: any) => {
    openView({
      title: 'Edit Operation',
      component: AddOperationView,
      params: {
        initialValues: operation,
        resourceId,
        queryVariables
      },
      style: 'PANEL'
    })
  }

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' },
    { dataKey: 'kind', slot: 'meta', renderer: ChipRenderer }
  ]

  const isSystem = !resource.workspaceId

  const actions = isReadOnly || isSystem
    ? [ { icon: 'pad-lock', title: 'Read only' } ]
    : [
      {
        icon: 'edit',
        title: 'Edit',
        onClick: onEdit
      }
    ]

  const EmptyState = () => {
    const loaderTitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return alert?.title || alert?.message
      }

      return DEFAULT_TITLE
    })()

    const loaderSubtitle = (() => {
      if (error) {
        const { alert } = parseError(error)

        return (alert?.title && alert?.message) || DEFAULT_ERROR_SUBTITLE
      }

      return DEFAULT_SUBTITLE
    })()

    return (
      <Flex alignItems="center" direction="column" gap={16}>
        <Flex alignItems="center" direction="column" gap={8}>
          <Text fontWeight="bold">{loaderTitle}</Text>
          <Text fontSize={14}>{loaderSubtitle}</Text>
        </Flex>
        {!isReadOnly && <Button label="Create Operation" size="small" mode="subtle" onClick={onAdd} />}
      </Flex>
    )
  }

  return (
    <DataListBlock
      asFragment
      title="Operations"
      actions={actions}
      contents={contents}
      data={operationsList as Operation[]}
      loading={loading}
      error={error}
      selectionMode="none"
      empty={{
        element: <EmptyState />
      }}
      {...(!isReadOnly && { onRowDragEnd: reorder })}
      secondaryElements={!isReadOnly && <Button icon="add-thin" size="small" onClick={onAdd} />}
      width={{ md: '75%' }}
    />
  )
}
export default OperationsList
