import rafSchd from 'raf-schd'
import React, { useEffect } from 'react'
import { useSpring, animated, to } from 'react-spring'

import { css, styled } from 'styles/stitches'
import { colorVars } from 'styles/theme'

// Layers with a positive factor move towards the mouse
// and layers with a negative factor move away from it.
const LAYER_FACTORS = [ 0.025, -0.025, 0.05, -0.05 ]

const SPRING_CONFIG = {
  mass: 10,
  tension: 500,
  friction: 160
}

const StyledDashes = styled('div', {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,

  '& svg': {
    height: '100%',
    width: '100%'
  },

  '& svg rect': {
    fill: 'url(#dashGradient)',
    fillOpacity: 0.4
  },

  '& svg rect[data-accent]': {
    fill: 'url(#dashGradient_accent)',
    fillOpacity: 0.65
  }
})

const classes = {
  gradientStart: css({
    stopColor: colorVars.primary400
  }),
  gradientStart_accent: css({
    stopColor: colorVars.accent400
  }),
  gradientStop: css({
    stopColor: colorVars.dark300
  })
}

const layer = (factor: number) => (x: number, y: number) => `translate(${x * factor}px, ${y * factor}px)`

function Dashes() {
  const [ values, setValues ] = useSpring(() => ({
    x: 0,
    y: 0,
    config: SPRING_CONFIG
  }))

  useEffect(() => {
    const handleMouseMove = rafSchd(({ clientX: x, clientY: y }: MouseEvent) => setValues({
      x: x - window.innerWidth / 2,
      y: y - window.innerHeight / 2
    }))

    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [ setValues ])

  return (
    <StyledDashes>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1105.208 1151.169" preserveAspectRatio="xMidYMid slice">
        <defs>
          <linearGradient id="dashGradient" gradientTransform="rotate(336)">
            <stop className={classes.gradientStart} offset="0%" />
            <stop className={classes.gradientStop} offset="100%" />
          </linearGradient>
          <linearGradient id="dashGradient_accent" gradientTransform="rotate(336)">
            <stop className={classes.gradientStart_accent} offset="0%" />
            <stop className={classes.gradientStop} offset="100%" />
          </linearGradient>
        </defs>

        <g fill="currentColor" transform="rotate(45, -37668.13174908, 18883.65196027)">
          <animated.g
            style={{ transform: to([ values.x, values.y ], layer(LAYER_FACTORS[0])) }}
          >
            <rect data-accent width="107" height="12" opacity=".769" rx="3" transform="translate(-23508, -20473)" />
            <rect width="196" height="58" opacity=".914" rx="8" transform="translate(-23607, -21054)" />
            <rect data-accent width="253" height="30" opacity=".848" rx="8" transform="translate(-23982, -21508)" />
            <rect data-accent width="253" height="44" opacity=".769" rx="8" transform="translate(-23818, -21612)" />
            <rect data-accent width="60" height="7" opacity=".732" rx="2" transform="translate(-23611, -21394)" />
            <rect width="700" height="30" opacity=".821" rx="8" transform="translate(-23875, -21128)" />
          </animated.g>

          <animated.g
            style={{ transform: to([ values.x, values.y ], layer(LAYER_FACTORS[1])) }}
          >
            <rect width="157" height="41" opacity=".949" rx="6" transform="translate(-23599, -21146)" />
            <rect width="174" height="27" opacity=".672" rx="5" transform="translate(-23629, -21227)" />
            <rect width="194" height="34" opacity=".698" rx="8" transform="translate(-23019, -21107)" />
            <rect width="196" height="58" opacity=".749" rx="8" transform="translate(-24277, -21054)" />
            <rect width="322" height="88" rx="8" transform="translate(-23689, -20883)" />
            <rect width="395" height="72" opacity=".85" rx="8" transform="translate(-23625, -20968)" />
          </animated.g>

          <animated.g
            style={{ transform: to([ values.x, values.y ], layer(LAYER_FACTORS[2])) }}
          >
            <rect width="142" height="29" rx="6" transform="translate(-23112, -21171)" />
            <rect width="176" height="12" rx="3" transform="translate(-24179, -21191)" />
            <rect width="194" height="43" rx="8" transform="translate(-24339, -21141)" />
            <rect width="391" height="12" rx="3" transform="translate(-23696, -21191)" />
            <rect width="44" height="12" opacity=".616" rx="3" transform="translate(-23170, -21313)" />
            <rect width="533" height="60" opacity=".94" rx="8" transform="translate(-23809.002, -21000)" />
          </animated.g>

          <animated.g
            style={{ transform: to([ values.x, values.y ], layer(LAYER_FACTORS[3])) }}
          >
            <rect data-accent width="116" height="12" rx="3" transform="translate(-23556, -21313)" />
            <rect data-accent width="180" height="29" opacity=".641" rx="8" transform="translate(-23680, -20567)" />
            <rect data-accent width="325" height="45" opacity=".757" rx="8" transform="translate(-23637, -20725)" />
            <rect width="49" height="27" opacity=".887" rx="5" transform="translate(-23841, -21227)" />
            <rect width="700" height="45" opacity=".777" rx="8" transform="translate(-23742, -21064)" />
          </animated.g>
        </g>
      </svg>
    </StyledDashes>
  )
}

export default Dashes
