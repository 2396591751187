import React from 'react'
import startCase from 'lodash/startCase'
import { Field, Form, FormProps } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator, FormApi } from 'final-form'

import App from 'models/App'
import Button from 'components/buttons/Button'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import IconInput from 'components/inputs/IconInput'
import SelectInput from 'components/inputs/SelectInput'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { App as AppType, useUpdateAppMutation, UpdateAppInput } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type FormValues = UpdateAppInput

type Params = ViewParams[Views.EDIT_PROJECT]

const PLATFORM_OPTIONS: {label: string, value: string}[] = [
  { label: 'Android', value: 'ANDROID' },
  { label: 'Browser', value: 'BROWSER' },
  { label: 'iOS', value: 'IOS' },
  { label: 'Server', value: 'SERVER' }
]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const EditProjectView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params
  const { name = '' } = initialValues as AppType

  const [ updateApp ] = useUpdateAppMutation({
    onCompleted: ({ updateApp }) => {
      openDashboardEditorView({
        target: Views.PROJECT_DETAILS,
        params: { app: updateApp }
      })
    }
  })

  const handleUpdateApp = useSubmitHandler(updateApp, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateApp',
      typename: 'App'
    }
  })

  const handleSubmit = (
    values: FormValues,
    form: FormProps<FormValues>['form']
  ) => handleUpdateApp(values as UpdateAppInput, form as FormApi<any>)

  return (
    <>
      <DashboardEditorHeader
        subtitle="Edit Project"
        heading={name}
        onClose={onClose}
      />
      <Form
        decorators={[
          setIdentifier
        ]}
        initialValues={initialValues as FormValues}
        onSubmit={handleSubmit}
        validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
                <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" />
                <Field
                  component={IconInput}
                  name="icon"
                  label="Icon"
                  placeholder="Choose Icon"
                  size="small"
                  type="text"
                />
                <Field
                  name="summary"
                  label="Summary"
                  helpText="Add notes regarding the project's intended use cases. Supports markdown."
                  component={TextAreaInput}
                  size="small"
                />
                <Field
                  component={TextInput}
                  disabled
                  format={(value) => startCase(value?.toLowerCase())}
                  helpText="If you wish to change this, create a new project."
                  label="Built Using"
                  name="technology"
                  size="small"
                  type="input"
                />
                <Field
                  component={SelectInput}
                  isDisabled
                  isMulti
                  label="Platforms"
                  name="platforms"
                  options={PLATFORM_OPTIONS}
                  size="small"
                />
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default EditProjectView
