import rafSchd from 'raf-schd'
import React, { forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useRect } from '@reach/rect'

import { SidePaneDispatchContext } from 'components/sidePane/SidePane'
import { styled } from 'styles/stitches'
import { SIDE_PANE_PADDING_X } from './constants'

type SidePaneBodyProps = React.PropsWithChildren<{}>

// Added to avoid flicker when sidePanelHeight is equal to document.body.scrollHeight
const SIDEPANE_OVERFLOW_OFFSET = 10

const StyledSidePaneBody = styled('div', {
  padding: SIDE_PANE_PADDING_X,
  maxHeight: '100%',
  variants: {
    overflowed: {
      true: {
        overflowY: 'auto',
        overflowX: 'hidden'
      }
    }
  }
})

const SidePaneBody = forwardRef<HTMLDivElement, SidePaneBodyProps>(({ children }, ref) => {
  const containerRef = useRef<HTMLDivElement>()
  const { height, width } = useRect(containerRef) || {}
  const updateMeasurements = useContext(SidePaneDispatchContext)!

  const [ isOverflown, setIsOverflown ] = useState<boolean>(false)
  const [ hasStickyFooter, setHasStickyFooter ] = useState<boolean>(false)

  const checkSidePaneOverflow = useCallback(() => {
    const sidePanelHeight = containerRef.current?.parentElement?.scrollHeight || 0

    setIsOverflown(sidePanelHeight - SIDEPANE_OVERFLOW_OFFSET > document.body.scrollHeight)
  }, [])

  const measure = useCallback(() => rafSchd(() => {
    if (containerRef.current) {
      updateMeasurements({
        offsetHeight: containerRef.current.offsetHeight,
        scrollHeight: containerRef.current.scrollHeight,
        scrollTop: containerRef.current.scrollTop
      })

      setHasStickyFooter(
        containerRef.current.scrollHeight > containerRef.current.clientHeight
      )
    }

    checkSidePaneOverflow()
  })(), [ checkSidePaneOverflow, updateMeasurements ])

  useEffect(measure, [ height, measure, width ])

  useLayoutEffect(() => {
    const containerEl = containerRef.current
    if (!containerEl) {
      return undefined
    }

    containerEl.addEventListener('scroll', measure, {
      passive: true,
      capture: false
    })

    window.addEventListener('resize', checkSidePaneOverflow)

    return () => {
      containerEl.removeEventListener('scroll', measure)
      window.removeEventListener('resize', checkSidePaneOverflow)
    }
  }, [ checkSidePaneOverflow, measure ])

  return (
    <StyledSidePaneBody
      overflowed={hasStickyFooter || isOverflown}
      ref={(el: HTMLDivElement) => {
        containerRef.current = el
        if (typeof ref === 'function') {
          ref(el)
        }
      }}
    >
      {children}
    </StyledSidePaneBody>
  )
})

export default SidePaneBody
