import React from 'react'
import get from 'lodash/get'

import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import { styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

const StyledFlex = styled(Flex, {
  color: 'currentColor'
})

function IconRenderer<T extends object>({
  dataKey,
  rowData,
  iconMap
}: RendererOptions<T> & {iconMap?: Record<string, string>}) {
  const data = get(rowData, dataKey, '') as string
  const icon = iconMap ? iconMap[data] : data

  return (
    <StyledFlex alignItems="center">
      <Icon name={icon.toLowerCase()} size={16} />
    </StyledFlex>
  )
}

export default IconRenderer
