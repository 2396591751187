import React, { useContext } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import uuid from 'uuid-random'

import DashboardContext from 'components/contexts/DashboardContext'
import useDashboard from 'hooks/useDashboard'
import { blocksByCategory } from 'components/dashboardEditor/AddBlockView'
import { Popover, PopoverBody, PopoverDivider, PopoverItem } from 'components/popover'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { BLOCK_TYPE_TO_ICON_MAP, BLOCK_TYPE_TO_NAME_MAP, Views } from 'components/dashboardEditor/constants'
import type { BlockType } from 'components/blocks'
import type { PopoverProps } from 'components/popover'

type AddBlockMenuProps = PopoverProps

const AddBlockMenu = React.forwardRef<HTMLDivElement, AddBlockMenuProps>(({
  ...menuProps
}, ref) => (
  <Popover autoFocus ref={ref} {...menuProps}>
    <Scrollbars autoHeight autoHeightMin="min(60vh, 720px)" autoHide>
      <PopoverBody css={{ width: 240 }}>
        <Basics />
        <PopoverDivider />
        <Datas />
        <PopoverDivider />
        <Charts />
        <PopoverDivider />
        <Layouts />
        <PopoverDivider />
        <Elements />
      </PopoverBody>
    </Scrollbars>
  </Popover>
))

const AddBlockItem = ({ block }: any) => {
  const { updateBlock, selectBlock, openDashboardEditorView } = useDashboard()
  const { activeUrn } = useDashboardViewContext()
  const { openDashboardEditor } = useContext(DashboardContext)!

  const onAdd = () => {
    const id = uuid()
    updateBlock(activeUrn, {
      id,
      type: block.type,
      properties: block.properties
    } as any, true)
    selectBlock(id)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
    openDashboardEditor()
  }

  return (
    <PopoverItem
      size="small"
      icon={BLOCK_TYPE_TO_ICON_MAP[block.type as BlockType]}
      onClick={onAdd}
      text={BLOCK_TYPE_TO_NAME_MAP[block.type as BlockType]}
    />
  )
}

const withBlockCategory = (category: keyof typeof blocksByCategory) => () => (
  <>
    {blocksByCategory[category].map((block) => <AddBlockItem key={block.type} block={block} />)}
  </>
)

const Basics = withBlockCategory('basics')

const Datas = withBlockCategory('datas')

const Charts = withBlockCategory('charts')

const Layouts = withBlockCategory('layouts')

const Elements = withBlockCategory('elements')

AddBlockMenu.displayName = 'AddBlockMenu'

export default AddBlockMenu
