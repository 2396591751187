import React from 'react'

import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { Views } from '../constants'

const DataSourcesView = () => {
  const { openDashboardEditorView } = useDashboard()
  const handleAddNewDataSource = () => openDashboardEditorView({
    target: Views.CREATE_DATA_SOURCE,
    params: {}
  })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Data Sources
      </Text>
      <Flex direction="column" gap={2}>
        <DashboardEditorLoader
          empty={{
            variant: 'simple',
            element: (
              <Flex alignItems="center" direction="column">
                <Text fontSize={14} color="dark500">Nothing to show here.</Text>
              </Flex>
            )
          }}
          data={[]}
        >
          <div />
        </DashboardEditorLoader>
      </Flex>
    </Flex>
  )
}

export default DataSourcesView
