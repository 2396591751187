import React, { memo } from 'react'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import Text from 'components/typography/Text'
import useHover from 'hooks/useHover'
import { css, styled } from 'styles/stitches'
import { useNestedDataManagerContext } from 'hooks/useDataManagerContext'
import type { Content } from 'components/accordian/Accordian'

type AccordianItemProps = {
  data: any,
  content: Content
}

const DATA_LIST_NORMAL_ITEM_HEIGHT = 50
const DATA_LIST_ITEM_PADDING_X = 16

const StyledAccordianItemIcon = styled(Icon, {
  ...mixins.transition('fluid', 'all'),

  variants: {
    active: {
      true: {
        color: 'primary400'
      },
      false: {
        color: 'dark700'
      }
    }
  }
})

const StyledAccordianItemTitle = styled(Text, {
  ...mixins.transition('fluid', 'all'),

  variants: {
    active: {
      true: {
        color: 'primary400'
      },
      false: {
        color: 'dark700'
      }
    }
  }
})

const AccordianItemHeader = styled(Flex, {
  ...mixins.transition('fluid', 'all'),

  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: DATA_LIST_NORMAL_ITEM_HEIGHT,
  paddingX: DATA_LIST_ITEM_PADDING_X,

  variants: {
    hovered: {
      true: {
        '& [data-icon]': {
          color: 'primary400'
        },
        '& [data-arrow]': {
          color: 'dark700'
        }
      },
      false: {
        '& [data-arrow]': {
          color: 'dark500'
        }
      }
    }
  }

})

const AccordianItemContent = styled(Flex, {
  ...mixins.transition('fastIn', 'all'),

  alignItems: 'center',

  borderLeft: '4px solid light100',
  borderRight: '4px solid light100',
  borderBottom: '4px solid light100',
  borderTop: '1px solid light500',
  borderRadius: 4,
  // transform: 'scaleY(0)',
  transformOrigin: 'top',
  padding: 0,
  display: 'none',
  height: 0,

  variants: {
    open: {
      true: {
        height: '100%',
        padding: '16px 12px',
        // transform: 'scaleY(1)',
        display: 'flex'
      }
    }
  }
})

const StyledAccordianItem = styled(Flex, {
  ...mixins.transition('fastIn', 'background'),

  boxSizing: 'border-box',
  backgroundColor: 'light100',
  borderRadius: 6
})

const getArrowClassName = ({
  isHovered,
  isOpened
}: any) => {
  const getArrowColor = () => {
    if (isHovered || isOpened) {
      return 'dark700'
    }

    return 'dark500'
  }

  return css({
    ...mixins.transition('fluid'),

    color: getArrowColor(),
    transform: isOpened && 'rotate(180deg)'
  })
}

const AccordianItem = React.forwardRef<HTMLDivElement, AccordianItemProps>(({
  content: accordianCotent,
  data
}, ref) => {
  const [ onHoverProps, isHovered, setIsHovered ] = useHover()
  const { icon, title, content } = accordianCotent

  const {
    setCollapsedItemMap,
    collapsedItemMap
  } = useNestedDataManagerContext()

  const isOpened = collapsedItemMap[data.id] || false

  const onRowSelectProps = {
    onClick: () => {
      setCollapsedItemMap({ [data.id]: !isOpened })
    }
  }

  return (
    <StyledAccordianItem
      onFocusCapture={() => setIsHovered(true)}
      onBlurCapture={() => setIsHovered(false)}
      alignItems="center"
      gap={20}
      ref={ref}
      style={{
        width: '100%'
      }}
    >
      <Flex direction="column" basis="100%">
        <AccordianItemHeader
          hovered={isHovered}
          {...onRowSelectProps}
          {...onHoverProps}
        >
          <Flex alignItems="center" gap={12}>
            {icon && (
              <StyledAccordianItemIcon
                data-icon
                name={icon}
                active={isHovered || isOpened}
                size={12}
              />
            )}
            <StyledAccordianItemTitle
              truncate
              alignSelf="center"
              as="div"
              active={isHovered || isOpened}
              fontSize={14}
              fontWeight="bold"
            >
              {title(data)}
            </StyledAccordianItemTitle>
          </Flex>
          <Icon
            data-arrow
            name="arrow-down"
            size={10}
            className={getArrowClassName({ isHovered, isOpened })}
          />
        </AccordianItemHeader>
        <AccordianItemContent
          open={isOpened}
        >
          {content(data)}
        </AccordianItemContent>
      </Flex>
    </StyledAccordianItem>
  )
})

AccordianItem.displayName = 'AccordianItem'

export type { AccordianItemProps }

export default memo(AccordianItem)
