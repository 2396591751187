import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import IconTextButton from 'components/buttons/IconTextButton'
import InternalContext from 'components/contexts/InternalContext'
import MenuElement from 'models/MenuElement'
import TextInput from 'components/inputs/TextInput'
import updateMenuElements from 'lib/updateMenuElements'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { MENU_ELEMENT_OPTIMISTIC_PROPERTIES } from 'lib/generateDashboard'
import { useCreateMenuElementMutation, useDestroyMenuElementMutation, useUpdateMenuElementMutation } from 'generated/schema'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { CreateMenuElementMutationVariables, CreateMenuElementInput, UpdateMenuElementInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { ViewProps } from 'components/views'

type FormValues = CreateMenuElementInput | UpdateMenuElementInput

type Params = Partial<ComputedMenuElement>

function AddMenuGroupView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { parentIdToMenuElementsMap } = useContext(InternalContext)!
  const { dashboardId, id, name = '', parentId, placement, position } = params
  const [ createMenuElement ] = useCreateMenuElementMutation({ onCompleted: onRequestClose })
  const [ updateMenuElement ] = useUpdateMenuElementMutation({ onCompleted: onRequestClose })
  const [ destroyMenuElement ] = useDestroyMenuElementMutation({ onCompleted: onRequestClose })
  const handleCreateMenuElementSubmit = useSubmitHandler(createMenuElement, {
    optimisticResponse: {
      response: 'CREATE',
      mutation: 'createMenuElement',
      typename: 'MenuElement',
      defaultValue: MENU_ELEMENT_OPTIMISTIC_PROPERTIES,
      override: (input: CreateMenuElementMutationVariables['input']) => ({
        parentId: input.parentId || null
      })
    },
    update: updateMenuElements('CREATE', parentIdToMenuElementsMap, dashboardId)
  })
  const handleUpdateMenuElementSubmit = useSubmitHandler(updateMenuElement, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('UPDATE', parentIdToMenuElementsMap, dashboardId)
  })
  const handleDestroyMenuElementSubmit = useSubmitHandler(destroyMenuElement, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('DESTROY', parentIdToMenuElementsMap, dashboardId)
  })

  const confirm = useConfirmation()

  const onConfirmDestroy = () => handleDestroyMenuElementSubmit({ id })

  const handleMenuGroupFormSubmit = (values: FormValues, form: FormPropsWithId<UpdateMenuElementInput>['form']) => {
    if (id) {
      return handleUpdateMenuElementSubmit({
        ...values,
        id
      }, form)
    }

    return handleCreateMenuElementSubmit({
      ...(values as CreateMenuElementInput),
      dashboardId,
      kind: 'GROUP'
    })
  }

  const title = id ? 'Update Menu Header' : 'Add Menu Header'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header
            title={title}
            onCloseClick={onRequestClose}
          />
          <Form
            onSubmit={handleMenuGroupFormSubmit}
            initialValues={{
              id,
              name,
              parentId,
              placement,
              position
            }}
            validate={(values) => MenuElement.validate(values, [ 'name' ])}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <form onSubmit={handleSubmit}>
                    <Field
                      autoFocus
                      component={TextInput}
                      name="name"
                      label="Label"
                      size="small"
                      type="text"
                    />
                  </form>
                </Body>
                <Footer>
                  <Footer.Left>
                    {id && (
                      <IconTextButton
                        label="Delete"
                        name="trash"
                        onClick={() => confirm(
                          { action: 'delete', onConfirmClick: onConfirmDestroy, recordType: 'Menu Header' }
                        )}
                      />
                    )}
                  </Footer.Left>
                  <Footer.Right>
                    <Button label="Cancel" onClick={onRequestClose} variant="outline" mode="subtle" />
                    <Button type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Footer.Right>
                </Footer>
              </>
            )}
          />
        </>
      )}
    </View>
  )
}

AddMenuGroupView.defaultStyle = 'DIALOG' as const

export default AddMenuGroupView
