export const colors = [
  '#FFD36F',
  '#BA8FF5',
  '#8FB2F4',
  '#FD949F',
  '#F296EB',
  '#BDC2D1',
  '#FFEF71',
  '#6EDDE2',
  '#FEB289',
  '#67EBB4'
]
