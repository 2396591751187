import React, { useMemo } from 'react'

import { colord } from 'colord'

import Chip from 'components/chip/Chip'
import ColorInput from 'components/inputs/ColorInput'
import colors from 'styles/primitives/colors'
import ConditionalField from 'components/form/ConditionalField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import ToggleInput from 'components/inputs/ToggleInput'
import { parseAndRenderSync } from 'lib/templater'
import type { Attribute } from 'generated/schema'

const MAX_WIDTH = 108

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any,
  rowData: any
}

const generateRandomColor = () => {
  const red = Math.floor(Math.random() * 256)
  const green = Math.floor(Math.random() * 256)
  const blue = Math.floor(Math.random() * 256)

  return `rgb(${red}, ${green}, ${blue})`
}

const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str, ...params)
  } catch (e) {
    console.error(e)

    return str
  }
}

const Renderer = ({ displaySettings, data, rowData }: RendererProps) => {
  const { use_random_color: useRandomColor, color = colors.dark700 } = displaySettings
  const isArrayContent = Array.isArray(data)
  const arrayLength = data?.length
  const isStringContent = typeof data === 'string'
  const parsedColor = safeParseLiquid(color, { record: rowData })
  const bgColor = useMemo(() => {
    if (!useRandomColor) return parsedColor
    if (!arrayLength) return generateRandomColor()
    return Array.from(new Array(arrayLength)).map(generateRandomColor)
  }, [ useRandomColor, parsedColor, arrayLength ])

  return (
    <Flex gap={4}>
      {isArrayContent && data.map((label: string, i: number) => (
        <Chip
          key={label}
          label={label}
          color={null}
          css={{
            ...(bgColor ? {
              color: colord(bgColor[i]).brightness() < 0.6
                ? colord(bgColor[i]).lighten(0.65).toHex()
                : colord(bgColor[i]).darken(0.55).toHex(),
              backgroundColor: bgColor[i]
            } : {}),
            maxWidth: MAX_WIDTH
          }}
        />
      ))}
      {isStringContent && (
        <Chip
          key={data}
          label={data}
          color={null}
          css={{
            ...(bgColor ? {
              color: colord(bgColor as string).brightness() < 0.6
                ? colord(bgColor as string).lighten(0.65).toHex()
                : colord(bgColor as string).darken(0.55).toHex(),
              backgroundColor: bgColor
            } : {}),
            maxWidth: MAX_WIDTH
          }}
        />
      )}
    </Flex>
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}use_random_color`}
      component={ToggleInput}
      label="Use random colors?"
      type="checkbox"
    />
    <ConditionalField when={`${fieldPrefix}use_random_color`} is={false}>
      <FormField
        label="Color"
        size="small"
        component={ColorInput}
        name={`${fieldPrefix}color`}
      />
    </ConditionalField>
  </Grid>
)

export default {
  Configurations
}

export { Renderer as ChipRenderer }
