import camelCase from 'lodash/camelCase'
import React from 'react'

import type { ViewProps } from 'components/views'
import type { Relationship, Resource } from 'generated/schema'
import GenericResourceRecordsList from './GenericResourceRecordsList'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'

type Params = {
  relationship: Relationship,
  record: Readonly<Record<any, any>>,
  resource: Resource,
  switcher?: SwitcherResultProps['switcher']
}

function RelatedResourceRecordsView({
  closeView,
  onRequestClose,
  params: {
    resource,
    record,
    relationship,
    switcher
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const title = resource?.name || 'Resource'

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <GenericResourceRecordsList
              resource={resource}
              switcher={switcher}
              relationshipFilter={{
                [relationship.targetAttribute.identifier]: {
                  eq: record.data[relationship.sourceAttribute.identifier]?.en_US
                }
              }}
              initialValues={{
                [camelCase(relationship.targetAttribute.identifier)]: record.data[
                  relationship.sourceAttribute.identifier
                ]
              }}
            />
          </Body>
        </>
      )}
    </View>
  )
}

RelatedResourceRecordsView.defaultStyle = 'PANEL' as const

export default RelatedResourceRecordsView
