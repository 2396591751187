import path from 'path'

import isRetina from 'lib/isRetina'

const retinaImageSuffix = '@2x'
const req = require.context('../assets/images', true, /\.(png|jpe?g)$/)
const reqCard = require.context('../assets/images/cards', true, /\.(png|jpe?g)$/)

function resolveImage(source: string) {
  let filename = source

  if (isRetina()) {
    const extension = path.extname(source)

    filename = filename.replace(extension, '')
    filename = `${filename}${retinaImageSuffix}${extension}`
  }

  return req(`./${filename}`)
}

function resolveCardImage(source: string) {
  let filename = source

  if (isRetina()) {
    const extension = path.extname(source)

    filename = filename.replace(extension, '')
    filename = `${filename}${retinaImageSuffix}${extension}`
  }

  return reqCard(`./${filename}`)
}

export { resolveImage, resolveCardImage }
