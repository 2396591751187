import React from 'react'

import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Attribute } from 'generated/schema'
import type { FontWeight } from 'styles/theme'

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Image = styled(Flex, {
  maxHeight: 450,
  objectFit: 'contain'
})

const isImage = (mime?: string) => mime && mime.match(/^image\/[-\w+.]+$/i) != null
const isAudio = (mime?: string) => mime && mime.match(/^audio\/[-\w+.]+$/i) != null
const isVideo = (mime?: string) => mime && mime.match(/^video\/[-\w+.]+$/i) != null

const AudioRenderer = ({ name, url, fontWeight }: {
  name: string,
  url: string,
  fontWeight: FontWeight
}) => (
  <Text fontSize={14} fontWeight={fontWeight}>{name}</Text>
)

const VideoRenderer = ({ name, url, fontWeight }: {
  name: string,
  url: string,
  fontWeight: FontWeight
}) => (
  <Text fontSize={14} fontWeight={fontWeight}>{name}</Text>
)

const ImageRenderer = ({ name, url }: { name: string, url: string }) => (
  <Image as="img" src={url} alt={name} />
)

const Renderer = ({ displaySettings, data }: RendererProps) => {
  const { name, url, mime_type: mimeType } = data || {}
  const { fontWeight = 'regular' } = displaySettings

  return (
    <>
      {isAudio(mimeType) && <AudioRenderer name={name} url={url} fontWeight={fontWeight} />}
      {isImage(mimeType) && <ImageRenderer name={name} url={url} />}
      {isVideo(mimeType) && <VideoRenderer name={name} url={url} fontWeight={fontWeight} />}
    </>
  )
}

export { Renderer as MediaRenderer }
