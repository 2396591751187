import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'

class Group extends BaseModel {
  static schema = object({
    environmentId: string().required(),
    name: string().trim().required(),
    accountKind: string().trim().required()
  })
}

export default Group
