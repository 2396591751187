import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import type { formatGroupLabel } from 'react-select/src/builtins'

import SelectInput, { SelectInputProps } from 'components/inputs/SelectInput'
import { useSearch } from 'hooks/useSearch'
import type { UseSearchProps } from 'hooks/useSearch'

type SearchSelectFieldProps<TData, TVariables> = FieldProps<
 any,
 FieldRenderProps<any, HTMLElement>,
 HTMLElement
> & Omit<SelectInputProps<any>, 'input' | 'meta'> & UseSearchProps<TData, TVariables> & {
  dataKey: keyof TData,
  formatGroup?: formatGroupLabel
}

function SearchSelectField<TData, TVariables>({
  preload,
  query,
  queryOptions,
  dataKey,
  keys,
  formatGroup,
  ...otherSelectProps
}: SearchSelectFieldProps<TData, TVariables>) {
  const [ {
    data: preloadData,
    loading,
    error
  }, handleSearch ] = useSearch<TData, TVariables>({ query, queryOptions, keys })

  return (
    <Field
      isLoading={loading}
      hasError={error}
      component={SelectInput}
      noOptionsMessage={() => 'Type to search records'}
      defaultOptions={(formatGroup
        ? formatGroup((preloadData ? preloadData[dataKey] : []) as any)
        : preloadData?.[dataKey]) || []}
      loadOptions={(inputValue: string, callback: any) => {
        handleSearch(
          inputValue,
          (data) => callback(
            formatGroup
              ? formatGroup((data ? data[dataKey] : []) as any)
              : data?.[dataKey]
          )
        )
      }}
      {...otherSelectProps}
    />
  )
}

export type { SearchSelectFieldProps }

export default SearchSelectField
