import React from 'react'
import { Field, Form } from 'react-final-form'

import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import TextAreaInput from 'components/inputs/TextAreaInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { SubmitContactFormInput, useSubmitContactFormMutation } from 'generated/schema'
import type { ViewProps } from 'components/views'

type Params = {
  initialValues: SubmitContactFormInput
}

const TITLE = 'Contact us'

function ContactUsView({
  closeView,
  onRequestClose,
  params: { initialValues },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ submitContactForm ] = useSubmitContactFormMutation({
    onCompleted: onRequestClose
  })
  const handleSubmitContactForm = useSubmitHandler(submitContactForm, {
    successAlert: { message: 'Thanks for reaching out. We will be in touch shortly.' }
  })
  const onSubmit = (values: SubmitContactFormInput) => handleSubmitContactForm(values)

  return (
    <View contentLabel={TITLE} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={TITLE} onCloseClick={onRequestClose} />
          <Body>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              subscription={{
                submitting: true,
                pristine: true
              }}
              render={({ handleSubmit, submitting, pristine }) => (
                <Flex direction="column" gap={16} as="form" onSubmit={handleSubmit}>
                  <Field
                    checkRequired
                    component={TextAreaInput}
                    placeholder="Please describe your issue briefly."
                    label="Message"
                    type="hidden"
                    name="message"
                  />
                  <Flex gap={24} direction="row-reverse">
                    <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                  </Flex>
                </Flex>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export default ContactUsView
