import React from 'react'

import AccountsView from 'components/views/AccountsView'
import { Kind } from 'models/Account'
import type { DashboardViewComponentProps } from './DashboardPage'

const AccountsPage = ({ appId }: DashboardViewComponentProps) => (
  <AccountsView
    kinds={[ Kind.MEMBER, Kind.SERVICE ]}
    appId={appId}
  />
)

export default AccountsPage
