import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'
import type { ContactKind as Kind, ContactStatus as Status } from 'generated/schema'

type KindOption = {
  label: string,
  value: Kind
}

enum ContactKind {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WEB = 'WEB'
}

type StatusOption = {
  label: string,
  value: Status
}

enum ContactStatus {
  SUBSCRIBED = 'SUBSCRIBED',
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  BLACKLISTED = 'BLACKLISTED',
  REVOKED = 'REVOKED',
}

const KIND_ICON_MAP = {
  [ContactKind.EMAIL]: 'email',
  [ContactKind.PHONE]: 'phone',
  [ContactKind.IOS]: 'apple',
  [ContactKind.ANDROID]: 'android',
  [ContactKind.WEB]: 'widget'
}

const KIND_OPTIONS: KindOption[] = [
  { label: 'Email', value: ContactKind.EMAIL },
  { label: 'Phone', value: ContactKind.PHONE },
  { label: 'iOS', value: ContactKind.IOS },
  { label: 'Android', value: ContactKind.ANDROID },
  { label: 'Web', value: ContactKind.WEB }
]

const STATUS_VARIANTS_MAP = {
  [ContactStatus.SUBSCRIBED]: 'positive',
  [ContactStatus.NOT_SUBSCRIBED]: 'light',
  [ContactStatus.UNSUBSCRIBED]: 'warning',
  [ContactStatus.BLACKLISTED]: 'negative',
  [ContactStatus.REVOKED]: 'negative'
} as const

const STATUS_LABELS_MAP = {
  [ContactStatus.SUBSCRIBED]: 'SUBSCRIBED',
  [ContactStatus.NOT_SUBSCRIBED]: 'NOT SUBSCRIBED',
  [ContactStatus.UNSUBSCRIBED]: 'UNSUBSCRIBED',
  [ContactStatus.BLACKLISTED]: 'BLACKLISTED',
  [ContactStatus.REVOKED]: 'REVOKED'
} as const

const STATUS_OPTIONS: StatusOption[] = [
  { label: 'Subscribed', value: ContactStatus.SUBSCRIBED },
  { label: 'Not Subscribed', value: ContactStatus.NOT_SUBSCRIBED },
  { label: 'Unsubscribed', value: ContactStatus.UNSUBSCRIBED },
  { label: 'Blacklisted', value: ContactStatus.BLACKLISTED },
  { label: 'Revoked', value: ContactStatus.REVOKED }
]

class Contact extends BaseModel {
  static schema = object({
    name: string().required(),
    kind: string().required(),
    value: string().required(),
    status: string().required()
  })
}

export {
  ContactKind,
  ContactStatus,
  KIND_ICON_MAP,
  KIND_OPTIONS,
  STATUS_LABELS_MAP,
  STATUS_VARIANTS_MAP,
  STATUS_OPTIONS
}

export default Contact
