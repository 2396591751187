import React from 'react'
import type { ChangeEvent } from 'react'

import SearchBar from 'components/searchbar/SearchBar'
import { SIDE_PANE_PADDING_X } from 'components/sidePane/constants'
import { styled } from 'styles/stitches'

const SIDE_PANE_SEARCH_BAR_PADDING_Y = 17

const StyledSidePaneSearchBar = styled(SearchBar, {
  backgroundColor: 'light100',
  flexShrink: 0,
  paddingY: SIDE_PANE_SEARCH_BAR_PADDING_Y,
  paddingX: SIDE_PANE_PADDING_X,

  '& [data-icon]': {
    color: 'dark900'
  },

  '& input': {
    backgroundColor: 'light100',
    color: 'dark900',

    '&::placeholder': {
      color: 'dark200'
    }
  }
})

type SidePaneSearchBarProps = {
  placeholder: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function SidePaneSearchBar({ placeholder, onChange }: SidePaneSearchBarProps) {
  return (
    <StyledSidePaneSearchBar
      placeholder={placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e)}
    />
  )
}

export default SidePaneSearchBar
