import { gql } from '@apollo/client'

import dayjs from 'dayjs'

import { SERVER_DATETIME_FORMAT } from 'components/inputs/DateInput/DateInput'
import type { ClientState } from 'client/state/types'
import type { Scalars } from 'generated/schema'

/* Default Data */

const DEFAULT_QUERY_HISTORY: Query[] = []

/* Types */

export type Query = {
  __typename: 'QueryHistory',
  integrationId: Scalars['ID'],
  query: string,
  data: Scalars['JSON'],
  columns: Scalars['JSON'],
  executedAt: Scalars['Timestamp']
}

export type QueryInput = Pick<Query, 'integrationId' | 'query' | 'data' | 'columns' | 'executedAt'>

export type QueryHistory = {
  queryHistory: Query[]
}

/* Queries */

export const QUERY_HISTORY_QUERY = gql`
  query QueryHistory {
    queryHistory @client {
      integrationId
      query
      data
      columns
      executedAt
    }
  }
`

/* Mutations */

export const SAVE_QUERY_MUTATION = gql`
  mutation SaveQueryMutation($integrationId: ID!, $query: String!, $data: JSON!, $columns: JSON!, $executedAt: DateTime!) {
    saveQuery(integrationId: $integrationId, query: $query, data: $data, columns: $columns, executedAt: $executedAt) @client
  }
`

/* ClientState */

export default {
  defaults: {
    query: QUERY_HISTORY_QUERY,
    data: { queryHistory: DEFAULT_QUERY_HISTORY }
  },

  resolvers: {
    Mutation: {
      saveQuery: (_, {
        integrationId,
        query,
        data = [],
        columns = [],
        executedAt = dayjs().utc().format(`${SERVER_DATETIME_FORMAT}Z`)
      }, { cache }) => {
        const cacheData = cache.readQuery({ query: QUERY_HISTORY_QUERY })
        const { queryHistory } = cacheData

        cache.writeQuery({
          query: QUERY_HISTORY_QUERY,
          data: {
            queryHistory: queryHistory.concat({
              integrationId,
              query,
              data,
              columns,
              executedAt
            })
          }
        })

        return null
      }
    }
  }
} as ClientState
