import React from 'react'

import TitleBlock from 'components/blocks/TitleBlock'
import { RenderElement } from 'components/elementViews/GenericElement'
import type { BlockProps } from '../Block'

const TitleBlockWrapper: React.FC<BlockProps> = ({ block, ...rest }) => (
  <TitleBlock
    {...rest}
    {...block.properties}
    primaryElements={(block.properties.primary_elements || [])
      .map((element: any) => <RenderElement {...element} />)}
    secondaryElements={(block.properties.secondary_elements || [])
      .map((element: any) => <RenderElement {...element} />)}
  />
)

export default TitleBlockWrapper
