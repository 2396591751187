import capitalize from 'lodash/capitalize'
import React, { forwardRef, memo, useContext, useEffect, useMemo, useRef } from 'react'
import { CSS } from '@dnd-kit/utilities'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useSortable } from '@dnd-kit/sortable'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex, { FlexProps } from 'components/layout/Flex'
import InspectWrapper from 'components/wrappers/InspectWrapper'
import InternalContext from 'components/contexts/InternalContext'
import SidebarPrimaryElement from 'components/menuelements/SidebarPrimaryElement'
import SidebarSecondaryElement from 'components/menuelements/SidebarSecondaryElement'
import TopbarElement from 'components/menuelements/TopbarElement'
import TopbarSubMenuElement from 'components/menuelements/TopbarSubMenuElement'
import updateMenuElements from 'lib/updateMenuElements'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard from 'hooks/useDashboard'
import useHover from 'hooks/useHover'
import useLongPress from 'hooks/useLongPress'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { ComputedMenuElement, PERSONAL_DASHBOARD, WORKSPACE_DASHBOARD } from 'lib/generateDashboard'
import { colorVars } from 'styles/theme'
import { Popover, PopoverContainer, PopoverItem } from 'components/popover'
import { SIDEBAR_PRIMARY_BACKGROUND_COLOR, SIDEBAR_SECONDARY_BACKGROUND_COLOR, SIDEBAR_SECONDARY_WIDTH } from 'components/sidebar/constants'
import { styled } from 'styles/stitches'
import { TOP_MENU_WIDTH } from 'components/topbar/TopbarMenu'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'
import { MenuElementKind, useDestroyMenuElementMutation } from 'generated/schema'
import { useMenuElementPositionContext } from 'components/contexts/MenuElementPositionContext'
import { Views } from 'components/dashboardEditor/constants'
import type { PopoverToggleProps } from 'components/popover'

type GenericMenuElementProps = FlexProps & {
  menuElement: ComputedMenuElement,
  onMenuElementClick?:
  (menuElement: ComputedMenuElement, e?: React.MouseEvent<HTMLElement>) => void,
  isDragging?: boolean
}

const StyledGenericMenuElement = styled(Flex, {
  userSelect: 'none',
  whiteSpace: 'nowrap',
  willChange: 'transform',
  border: '1px solid transparent',

  variants: {
    dragging: {
      true: {
        ...mixins.shadow('medium', colorVars.dark1000rgb, 0.0784),
        borderRadius: 4
      }
    },
    kind: {
      item: {
        // backgroundColor: 'light100'
      },
      group: {
        // backgroundColor: 'light100'
      },
      separator: {
        // backgroundColor: 'light100'
      }
    },
    variant: {
      sidebarPrimary: {
        width: '100%'
      },
      sidebarSecondary: {
        backgroundColor: SIDEBAR_SECONDARY_BACKGROUND_COLOR,
        width: SIDEBAR_SECONDARY_WIDTH
      },
      topbar: {
        height: TOPBAR_HEIGHT
      },
      topbarSubMenu: {
        width: TOP_MENU_WIDTH
      }
    },
    ghostMode: {
      true: {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          inset: 4,
          border: '2px dashed dark700',
          borderRadius: 4,
          opacity: 0.5
        }
      }
    },
    inspectMode: {
      none: {},
      primary: {
        '&:hover': {
          border: '1px solid light700'
        }
      },
      secondary: {
        '&:hover': {
          border: '1px solid primary400'
        }
      }
    }
  }
})

StyledGenericMenuElement.compoundVariant({
  dragging: true,
  variant: 'sidebarPrimary'
}, {
  backgroundColor: SIDEBAR_PRIMARY_BACKGROUND_COLOR
})

StyledGenericMenuElement.compoundVariant({
  dragging: true,
  kind: 'item'
}, {
  backgroundColor: 'light100'
})

StyledGenericMenuElement.compoundVariant({
  dragging: true,
  kind: 'group'
}, {
  backgroundColor: 'light100'
})

StyledGenericMenuElement.compoundVariant({
  dragging: true,
  kind: 'separator'
}, {
  backgroundColor: 'light100',
  opacity: 0.7
})

const getKind = (kind: MenuElementKind) => {
  if (kind === 'GROUP') return 'group'
  if (kind === 'ITEM') return 'item'
  if (kind === 'SEPARATOR') return 'separator'
  return undefined
}

const MenuElementWrapper = ({
  popoverToggleProps, children
}: { popoverToggleProps: PopoverToggleProps, children: React.ReactElement | null }) => {
  const longPressProps = useLongPress(popoverToggleProps.openPopover)
  if (!children) return null
  return React.cloneElement(children, { popoverToggleProps, longPressProps })
}

const GenericMenuElement = memo(({
  menuElement,
  onMenuElementClick,
  isDragging
}: GenericMenuElementProps) => {
  const confirm = useConfirmation()
  const { parentIdToMenuElementsMap = {}, currentDashboard } = useContext(InternalContext) || {}
  const { newNonStickyPosition, newStickyPosition } = useMenuElementPositionContext() || {}
  const { openDashboardEditor } = useContext(DashboardContext)!
  const location = useLocation()
  const { push } = useHistory()
  const {
    openDashboardEditorView,
    draggedMenuState,
    draggedAppState,
    draggedResourceState,
    dashboardEditorState,
    selectMenu
  } = useDashboard()

  const draggedMenu = useRecoilValue(draggedMenuState)
  const draggedApp = useRecoilValue(draggedAppState)
  const draggedResource = useRecoilValue(draggedResourceState)

  const draggedElementId = draggedMenu?.id || draggedApp?.id || draggedResource?.id

  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isOver
  } = useSortable({ id: menuElement.id, data: menuElement })

  // wait for 1 second and if isOver is still true, then select the menu element
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (menuElement.kind === 'ITEM' && menuElement.target === 'SUBMENU') {
        if (menuElement.placement === 'SIDE' && !location.pathname.includes(menuElement.fullPath!)) {
          push(menuElement.fullPath!)
        }
      }
    }, 1000)

    if (!isOver) {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
  }, [ isOver, menuElement, push, location.pathname ])

  const [ destroyMenuElement ] = useDestroyMenuElementMutation()
  const handleDestroyMenuElementSubmit = useSubmitHandler(destroyMenuElement, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyMenuElement',
      typename: 'MenuElement'
    },
    update: updateMenuElements('DESTROY', parentIdToMenuElementsMap, menuElement.dashboardId)
  })

  const handleDestroyMenuElement = (id: string) => {
    const currentDashboardIdentifier = currentDashboard?.identifier

    if (location.pathname === menuElement.fullPath) {
      if (menuElement.parentId === null) {
        push(`/~${currentDashboardIdentifier}`)
      } else {
        const parentMenuElement = parentIdToMenuElementsMap[menuElement.parentId]?.[0]
        const parentFullPath = parentMenuElement?.fullPath || `~${currentDashboardIdentifier}`
        push(parentFullPath)
      }

      return handleDestroyMenuElementSubmit({ id })
    }

    return handleDestroyMenuElementSubmit({ id })
  }

  const openDeleteDialog = () => {
    confirm({
      action: 'delete',
      onConfirmClick: () => handleDestroyMenuElement(menuElement.id),
      recordType: 'Menu Element',
      recordDescription: menuElement.name
    })
  }

  const openEditMenuItemView = () => {
    const initialValues = {
      ...menuElement,
      newNonStickyPosition,
      newStickyPosition
    }

    openDashboardEditorView({
      target: Views.ADD_MENU_ELEMENT,
      params: { initialValues, parentView: Views.ACTIONS }
    })
    openDashboardEditor()
    selectMenu(menuElement)
  }

  const openEditMenuItemViewRef = useRef(openEditMenuItemView)
  useEffect(() => {
    openEditMenuItemViewRef.current = openEditMenuItemView
  })

  const isSidebarPrimary = menuElement.placement === 'SIDE' && !menuElement.parentId
  const isSidebarSecondary = menuElement.placement === 'SIDE' && !!menuElement.parentId
  const isTopbar = menuElement.placement === 'TOP' && !menuElement.parentId
  const isTopbarSubMenu = menuElement.placement === 'TOP' && !!menuElement.parentId

  const getVariant = () => {
    if (isSidebarPrimary) {
      return 'sidebarPrimary'
    }
    if (isSidebarSecondary) {
      return 'sidebarSecondary'
    }
    if (isTopbar) {
      return 'topbar'
    }
    if (isTopbarSubMenu) {
      return 'topbarSubMenu'
    }

    return undefined
  }

  const getInspectMode = () => {
    if (isInspecting) {
      if (isSidebarPrimary) return 'primary'
      return 'secondary'
    }

    return 'none'
  }

  const renderMenuElement = useMemo(() => {
    const handleInspect = () => {
      openEditMenuItemViewRef.current()
    }

    if (isSidebarPrimary) {
      return (
        <SidebarPrimaryElement
          menuElement={menuElement}
          isDragging={isDragging}
          onMenuElementClick={isInspecting ? handleInspect : onMenuElementClick}
        />
      )
    }
    if (isSidebarSecondary) {
      return (
        <SidebarSecondaryElement
          isFooter={!!menuElement.isSticky}
          menuElement={menuElement}
          onMenuElementClick={isInspecting ? handleInspect : onMenuElementClick}
        />
      )
    }
    if (isTopbar) {
      return (
        <TopbarElement
          menuElement={menuElement}
          isDragging={isDragging}
          onMenuElementClick={isInspecting ? handleInspect : undefined}
        />
      )
    }
    if (isTopbarSubMenu) {
      return (
        <TopbarSubMenuElement
          isDragging={isDragging}
          isOver={isOver}
          isFooter={!!menuElement.isSticky}
          menuElement={menuElement}
          onMenuElementClick={isInspecting ? handleInspect : onMenuElementClick}
        />
      )
    }

    return null
  }, [
    isSidebarPrimary,
    isSidebarSecondary,
    isTopbar,
    isTopbarSubMenu,
    menuElement,
    isDragging,
    isOver,
    isInspecting,
    onMenuElementClick
  ])

  const [ onHoverProps, isHovered, setIsHovered ] = useHover({
    onHoverIn: () => selectMenu(menuElement),
    onHoverOut: () => selectMenu(null),
    pausedRef: { current: !isInspecting }
  })

  const isSystemMenuElement = [ WORKSPACE_DASHBOARD.id, PERSONAL_DASHBOARD.id ]
    .includes(menuElement.dashboardId)

  return (
    <StyledGenericMenuElement
      className={`tg--${menuElement.name?.toLowerCase()}`}
      dragging={isDragging}
      ghostMode={draggedElementId === menuElement.id && !isDragging}
      inspectMode={getInspectMode()}
      grow={1}
      direction="column"
      ref={setNodeRef}
      variant={getVariant()}
      kind={getKind(menuElement.kind)}
      style={{
        transform: CSS.Transform.toString(transform),
        transition
      }}
      {...(!isSystemMenuElement ? attributes : {})}
      {...(!isSystemMenuElement ? listeners : {})}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      {...onHoverProps}
    >
      {isInspecting && (
        <InspectWrapper
          isHovered={isHovered}
          params={menuElement}
          type="MenuElement"
        />
      )}
      <PopoverContainer openOn="contextMenu">
        {(toggleProps) => (
          // don't show context menu for client-only menu elements
          !isSystemMenuElement
            ? (
              <MenuElementWrapper popoverToggleProps={toggleProps}>
                {renderMenuElement}
              </MenuElementWrapper>
            ) : renderMenuElement
        )}
        {(popoverProps) => (
          <Popover autoFocus {...popoverProps}>
            <PopoverItem
              size="small"
              onClick={openEditMenuItemView}
              text={`Edit Menu ${capitalize(menuElement.kind)}`}
            />
            <PopoverItem
              size="small"
              onClick={openDeleteDialog}
              text={`Delete Menu ${capitalize(menuElement.kind)}`}
            />
          </Popover>
        )}
      </PopoverContainer>
    </StyledGenericMenuElement>
  )
})

const withLiveMenuElement = (Component: React.ComponentType<GenericMenuElementProps>) => {
  const WithLiveMenuElement = forwardRef<HTMLElement, GenericMenuElementProps>((props, ref) => {
    const { menuElement } = props
    const { selectedMenuState } = useDashboard()
    const liveMenuElement = useRecoilValue(selectedMenuState)

    const menuElementOrLiveMenuElement = liveMenuElement && liveMenuElement.id === menuElement.id
      ? liveMenuElement
      : menuElement

    return (
      <Component
        {...props}
        ref={ref}
        menuElement={menuElementOrLiveMenuElement}
      />
    )
  })

  return WithLiveMenuElement
}

GenericMenuElement.displayName = 'GenericMenuElement'

export type { GenericMenuElementProps }

export default withLiveMenuElement(GenericMenuElement)
