import last from 'lodash/last'
import React, { useState } from 'react'
import { Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import AddParameterView from './AddParameterView'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import generatePosition from 'lib/generatePosition'
import Grid from 'components/layout/Grid'
import IconInput from 'components/inputs/IconInput'
import OperationModel, { ACTS_ON_OPTIONS, BEHAVIOR_METHOD_OPTIONS, KIND_OPTIONS } from 'models/Operation'
import Portal from 'components/portal/Portal'
import SelectInput from 'components/inputs/SelectInput'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import Toolbar from 'components/toolbar/Toolbar'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { css } from 'styles/stitches'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { OperationsListDocument, OperationsListQueryVariables, CreateOperationInput, UpdateOperationInput, useCreateOperationMutation, useUpdateOperationMutation, Operation, useParametersListQuery, useDestroyParameterMutation, ParametersListDocument, Parameter, ParametersListQuery, Resource, CreateParameterInput } from 'generated/schema'
import { PARAMETERS_LIST_LIMIT } from 'models/Resource'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Content } from 'components/dataList/DataList'
import type { ViewProps } from 'components/views'

type FormValues = CreateOperationInput | UpdateOperationInput

type Params = {
  initialValues: FormValues,
  resourceId: Resource['id'],
  queryVariables: OperationsListQueryVariables
}

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')
const getMaxPosition = (parametersList: ParametersListQuery['parametersList']) => Math.max(
  parametersList[0]?.position || 0, last(parametersList)?.position || 0
)

const Parameters = ({ operationId }: { operationId: Operation['id'], resourceId: Resource['id'] }) => {
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      operationId: { eq: operationId }
    },
    limit: PARAMETERS_LIST_LIMIT
  }

  const {
    data: { parametersList = [] } = {},
    loading,
    error
  } = useParametersListQuery({
    variables: queryVariables,
    skip: !operationId
  })

  const [ destroyParameter ] = useDestroyParameterMutation({
    refetchQueries: [
      { query: ParametersListDocument, variables: queryVariables }
    ]
  })

  const handleDestroyParameter = useSubmitHandler(destroyParameter, {
    successAlert: { message: 'Parameter Deleted.' }
  })

  const onEdit = (parameter: Parameter) => openView({
    title: 'Update Parameter',
    component: AddParameterView,
    params: {
      initialValues: parameter,
      attribute: parameter.attribute,
      operationId,
      queryVariables
    },
    style: 'PANEL'
  })

  const onAdd = () => openView({
    title: 'Edit Parameter',
    component: AddParameterView,
    params: {
      initialValues: {
        name: '',
        identifier: '',
        isNullable: false,
        isArray: false,
        validations: [],
        position: generatePosition(getMaxPosition(parametersList as any[])),
        operationId
      } as CreateParameterInput,
      operationId,
      queryVariables
    },
    style: 'PANEL'
  })

  const openConfirmationDialog = (attribute: Parameter) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => {
        handleDestroyParameter({ id: attribute.id })
      },
      recordType: 'Parameter',
      recordDescription: attribute.name
    })
  }

  const contents: Content[] = [
    { dataKey: 'name', slot: 'primary' },
    { dataKey: 'identifier', slot: 'secondary' },
    { dataKey: 'fieldType', slot: 'meta', renderer: ChipRenderer }
  ]

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: (attribute: Parameter) => onEdit(attribute)
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: openConfirmationDialog,
      visibilityFilter: (attribute: Record<any, any>) => !attribute.system_identifier
    }
  ]

  const secondaryElements = (
    <Flex gap={16} alignItems="center">
      <Button icon="add-thin" size="small" onClick={onAdd} />
    </Flex>
  )

  return (
    <>
      <Toolbar
        title="Parameters"
        secondaryElements={secondaryElements}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={parametersList as Parameter[]}
        loading={loading}
        error={error}
        selectionMode="none"
        onRowDragEnd={() => {}}
      />
    </>
  )
}

function AddOperationView({
  closeView,
  onRequestClose,
  params: { initialValues, resourceId, queryVariables },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const title = `${isUpdating ? 'Update' : 'Add'} Operation`
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)

  const [ createOperation ] = useCreateOperationMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: OperationsListDocument, variables: queryVariables } ]
  })

  const [ updateOperation ] = useUpdateOperationMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ { query: OperationsListDocument, variables: queryVariables } ]
  })

  const handleCreateOperation = useSubmitHandler(createOperation, {
    successAlert: { message: 'Operation Created.' }
  })

  const handleUpdateOperation = useSubmitHandler(updateOperation, {
    successAlert: { message: 'Operation Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating) {
      return handleUpdateOperation(
        values as UpdateOperationInput,
        form as FormApi<UpdateOperationInput>
      )
    }
    return handleCreateOperation(values as CreateOperationInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Tabs
            tabListBackground="light"
            onChange={setCurrentIndex}
            panelWrapper={Body}
            tabListClassName={classes.tabsList}
          >
            <Tab label="Overview" index={0}>
              <Form
                decorators={[
                  setIdentifier
                ]}
                initialValues={initialValues}
                keepDirtyOnReinitialize
                onSubmit={handleSubmit}
                subscription={{
                  submitting: true,
                  pristine: true
                }}
                validate={(values) => OperationModel.validate(values, [ 'identifier', 'name' ])}
                render={({ handleSubmit, submitting, pristine }) => (
                  <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
                    <Grid columns={2} columnGap={8} rowGap={8}>
                      <FormField
                        component={TextInput}
                        name="name"
                        label="Name"
                        size="small"
                        type="text"
                      />
                      <FormField
                        component={TextInput}
                        name="identifier"
                        label="Identifier"
                        size="small"
                        type="text"
                      />
                      <FormField
                        component={IconInput}
                        name="icon"
                        label="Choose Icon"
                        size="small"
                        type="text"
                      />
                      <FormField
                        component={SelectInput}
                        name="kind"
                        label="Kind"
                        size="small"
                        options={KIND_OPTIONS}
                      />
                      <FormField
                        component={SelectInput}
                        name="behaviorMethod"
                        label="Behavior"
                        size="small"
                        options={BEHAVIOR_METHOD_OPTIONS}
                      />
                      <FormField
                        component={SelectInput}
                        name="actsOn"
                        label="Acts On"
                        size="small"
                        options={ACTS_ON_OPTIONS}
                      />
                    </Grid>
                    <FormField
                      name="description"
                      component={TextAreaInput}
                      label="Description"
                      type="text"
                    />
                    {footerEl && (
                      <Portal target={footerEl}>
                        <Button type="submit" label="Save" disabled={submitting || pristine} onClick={handleSubmit} />
                      </Portal>
                    )}
                  </Flex>
                )}
              />
            </Tab>
            {isUpdating && (
              <Tab label="Parameters" index={1}>
                <Parameters operationId={initialValues.id} resourceId={resourceId} />
              </Tab>
            )}
          </Tabs>
          <Footer>
            <Flex gap={24} direction="row-reverse">
              {currentIndex === 0 && <Flex direction="row-reverse" ref={setFooterEl} />}
            </Flex>
          </Footer>
        </>
      )}
    </View>
  )
}

export default AddOperationView
