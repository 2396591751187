import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'

const PARAGRAPH_VERTICAL_MARGIN = 36

const Paragraph = styled(Text, {
  marginBottom: PARAGRAPH_VERTICAL_MARGIN
})

Paragraph.defaultProps = {
  color: 'dark900',
  fontSize: 14,
  lineHeight: 'cozy'
}

export default Paragraph
