import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import type { DraggableChildrenFn, DroppableProvided } from 'react-beautiful-dnd'

import Button from 'components/buttons/Button'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import type { Attribute } from 'generated/schema'
import type { RenderAttributeOptions } from './GenericParamFields'
import type { Locale } from 'hooks/useActiveLocales'

type RepeatedContainerProps = {
  name: string,
  shouldValidate?: boolean,
  prefix?: string,
  attribute: Attribute,
  currentLocale?: Locale,
  defaultLocale?: Locale,
  renderField: (attribute: Attribute, options: RenderAttributeOptions) => React.ReactNode,
  resourceId?: string,
  targetEnvironmentId?: string
}

function RepeatedAttribute({
  attribute,
  name,
  shouldValidate,
  prefix,
  currentLocale,
  defaultLocale,
  renderField,
  resourceId,
  targetEnvironmentId
}: RepeatedContainerProps) {
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  const suffix = currentLocale?.identifier || defaultLocale?.identifier
  const fieldName = [ prefix, name, suffix ].filter(Boolean).join('.')

  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <FieldGroup
        {...provided}
        isDraggable
        onClick={() => fieldsRef.current?.fields.remove(rubric.source.index)}
      >
        {/* name and helpText are stripped so child doesn't render them */}
        {renderField({ ...attribute, name: '', settings: { ...attribute.settings, helpText: '' } }, {
          prefix,
          currentLocale,
          defaultLocale,
          index: rubric.source.index,
          resourceId,
          targetEnvironmentId
        })}
      </FieldGroup>
    </div>
  )

  const onDragEnd = useReorderFieldArray(fieldsRef)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={droppableId.current}
        renderClone={renderDraggableChildren}
      >
        {(droppableProvided: DroppableProvided) => (
          <Flex
            direction="column"
            gap={10}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {attribute.name && (
              <FieldLabel isTranslatable={attribute.isTranslatable}>
                {attribute.name}
              </FieldLabel>
            )}

            <FieldArray
              name={fieldName}
              fieldsRef={fieldsRef}
              settings={attribute?.settings}
              shouldValidate={shouldValidate}
            >
              {({ keys }) => keys.map((key, index) => (
                <Draggable
                  disableInteractiveElementBlocking
                  draggableId={key}
                  index={index}
                  key={key}
                >
                  {renderDraggableChildren}
                </Draggable>
              ))}
            </FieldArray>
            {droppableProvided.placeholder}

            {attribute.settings?.helpText
             && <InputHelpText helpText={attribute.settings.helpText} />}

            <Button
              icon="add-thin"
              onClick={() => fieldsRef.current?.fields.push(undefined)}
              size="small"
              name={name}
            />
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default RepeatedAttribute
